import React from 'react';

import {
  ActionResponse,
  getValuesByCacheKey,
  hasOAuthAppCredential,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionButtonStyle,
  ActionConfig,
  ActionStepParameters,
  EnumInputValue,
  EnumSection,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions/';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import authConfig, { authInput } from '../shared/authConfig';
import { GetFieldResponse } from '../shared/types';

import { getFields, getRecordType } from './sources';
import supportedOperators from './supportedOperators';

const recordTypesInput: SidebarInput = {
  id: 'recordType',
  type: SidebarInputType.NativeDynamicEnumInput,
  title: 'Record type',
  placeholder: 'Select a Record type',
  required: true,
  source: getRecordType,
  getValues: getValuesByCacheKey,
};

const oAuthRecordTypesInput: SidebarInput = {
  id: 'recordType',
  type: SidebarInputType.EditableDynamicEnum,
  title: 'Record type',
  placeholder: 'choose a record type or enter a custom record type',
  required: true,
  source: {
    ...getRecordType,
    getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
      return {
        intent: Intent.GET_RECORD_TYPES,
        actionType: Action.SALESFORCE,
        credentials: options.credentials,
        actionParameters: [{ key: 'isOAuthApp', source: { type: 'VALUE', value: true } }],
      };
    },
    mapRefreshToValues: (response: ActionResponse<EnumSection[]>): EnumInputValue[] => {
      return response?.result && response.result.output[0].items;
    },
  },
  getValues: getValuesByCacheKey,
};

const recordIdInput: SidebarInput = {
  id: 'recordId',
  title: 'Record ID',
  required: true,
  type: SidebarInputType.TextArea,
  lines: 1,
};

const config: ActionConfig = {
  actionType: Action.SALESFORCE,
  name: 'Salesforce',
  description: 'Find, create, and update records in Salesforce.',
  icon: iconSvg,
  provider: ProviderType.SALESFORCE,
  providerDataKeyLabelMap: { instanceUrl: 'Instance URL' },
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        authInput,
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.SALESFORCE,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.CREATE_RECORD,
                    label: 'Create record',
                  },
                  {
                    value: Intent.UPDATE_RECORD,
                    label: 'Update record',
                  },
                  {
                    value: Intent.DELETE_RECORD,
                    label: 'Delete record',
                  },
                  {
                    value: Intent.GET_RECORD_BY_ID,
                    label: 'Get record by ID',
                  },
                  {
                    value: Intent.SEARCH_RECORDS,
                    label: 'Search records',
                  },
                  {
                    value: Intent.WRITE_SOQL_QUERY,
                    label: 'Search records by SOQL query',
                  },
                  {
                    value: Intent.GET_RECORD_METADATA,
                    label: 'Get record metadata',
                  },
                  { value: Intent.GET_CONTACT_BY_VIEW_ID, label: 'Get records by List View' },
                ],
              },
            ],
          }
        : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      if (
        parameters.credentials.length > 0 &&
        ![Intent.WRITE_SOQL_QUERY, Intent.UPDATE_RECORD].includes(parameters.intent as Intent)
      ) {
        return {
          inputs: [
            hasOAuthAppCredential(parameters, credentials)
              ? oAuthRecordTypesInput
              : recordTypesInput,
          ],
        };
      }
      if (
        parameters.credentials.length > 0 &&
        [Intent.UPDATE_RECORD].includes(parameters.intent as Intent)
      ) {
        return {
          inputs: [
            hasOAuthAppCredential(parameters, credentials)
              ? oAuthRecordTypesInput
              : recordTypesInput,
            recordIdInput,
          ],
        };
      } else {
        return { inputs: [] };
      }
    },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      const fieldsInput: SidebarInput[] = Object.entries<GetFieldResponse>(
        pickValueSourceByKey<DataType.ANY>(parameters.actionParameters, 'cachedFields')?.value ||
          [],
      ).map(([_, field]: [string, GetFieldResponse]) =>
        parameters.intent === Intent.CREATE_RECORD
          ? {
              id: `field-${field.value}`,
              title: field.label,
              required: field.required,
              type: SidebarInputType.TextArea,
              lines: 1,
            }
          : {
              id: `field-${field.value}`,
              title: field.label,
              required: field.required ? !field.required : field.required,
              type: SidebarInputType.TextArea,
              lines: 1,
            },
      );

      const filterInput: SidebarInput = {
        id: 'filterFormula',
        type: SidebarInputType.DynamicConditional,
        title: 'Filter search',
        subtitle: 'Search for records that match specified filters.',
        source: {
          ...getFields,
          cacheKey: 'filterCachedFields',
          getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
            const recordType = pickValueSourceByKey(options.actionParameters, 'recordType');
            return {
              actionType: Action.SALESFORCE,
              intent: Intent.GET_RECORD_TYPE_FIELDS,
              credentials: options.credentials,
              actionParameters: [
                {
                  key: 'recordType',
                  source: {
                    type: 'VALUE',
                    value: recordType?.value,
                  },
                },
                {
                  key: 'getAll',
                  source: {
                    type: 'VALUE',
                    value: true,
                  },
                },
              ],
            };
          },
        },
        getValues: getValuesByCacheKey,
        supportedOperators: supportedOperators,
        required: false,
      };

      const OAuthFilterInput: SidebarInput = {
        id: 'filterFormula',
        type: SidebarInputType.Conditional,
        title: 'Filter search',
        subtitle: 'Search for records that match specified filters.',
        supportedOperators: supportedOperators,
        required: false,
      };

      switch (parameters.intent) {
        case Intent.CREATE_RECORD:
          return {
            inputs: hasOAuthAppCredential(parameters, credentials)
              ? [
                  {
                    id: 'createRecordPayload',
                    type: SidebarInputType.Code,
                    language: 'json',
                    title: 'Record JSON',
                    required: true,
                    placeholder: `{
"Name": "Awesome Corporation"
}`,
                    useLightTheme: true,
                  },
                ]
              : [...fieldsInput],
            buttons: hasOAuthAppCredential(parameters, credentials)
              ? []
              : [
                  {
                    id: 'refreshFields',
                    type: SidebarInputType.ActionButton,
                    title: 'Refresh Fields',
                    style: ActionButtonStyle.DEFAULT,
                    source: getFields,
                    hidden: true,
                  },
                ],
          };
        case Intent.UPDATE_RECORD:
          return {
            inputs: [
              ...(hasOAuthAppCredential(parameters, credentials)
                ? [
                    {
                      id: 'updateRecordPayload',
                      type: SidebarInputType.Code,
                      language: 'json',
                      title: 'Record JSON',
                      required: true,
                      placeholder: `{
"Name": "Awesome Corporation"
}`,
                      useLightTheme: true,
                    } as SidebarInput,
                  ]
                : fieldsInput),
            ],
            buttons: hasOAuthAppCredential(parameters, credentials)
              ? []
              : [
                  {
                    id: 'refreshFields',
                    type: SidebarInputType.ActionButton,
                    title: 'Refresh Fields',
                    style: ActionButtonStyle.DEFAULT,
                    source: getFields,
                    hidden: true,
                  },
                ],
          };
        case Intent.DELETE_RECORD:
          return {
            inputs: [recordIdInput],
          };
        case Intent.GET_RECORD_BY_ID:
          return {
            inputs: [recordIdInput],
          };
        case Intent.SEARCH_RECORDS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials) ? OAuthFilterInput : filterInput,
            ],
          };
        case Intent.WRITE_SOQL_QUERY:
          return {
            inputs: [
              {
                id: 'query',
                type: SidebarInputType.Code,
                language: 'sql',
                title: 'SOQL Query',
                subtitle: (
                  <>
                    Write a query using Salesforce Object Query Language (SOQL). Supports{' '}
                    <a
                      href="https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql.htm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SOQL SELECT Syntax.
                    </a>
                  </>
                ),
                placeholder: `SELECT Id, Name
FROM Account
WHERE Name = 'Forge Technology, Inc.'
                `,
                required: true,
                useLightTheme: true,
                lines: 3,
              },
            ],
          };
        case Intent.GET_CONTACT_BY_VIEW_ID:
          return {
            inputs: [
              {
                id: 'listViewId',
                type: SidebarInputType.TextArea,
                title: 'List View ID',
                lines: 1,
                required: true,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};
export { authConfig };

export default config;
