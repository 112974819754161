import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class MicroserviceError extends ParagonError {
  name: string = 'MicroserviceError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class HermesTokenInvalidError extends MicroserviceError {
  constructor(meta: { token: string }) {
    super({
      code: ERROR_CODE.HERMES_ACCESS_TOKEN_INVALID,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Invalid access token.',
      meta,
    });
  }
}

export class RateLimitTooManyRequestsError extends MicroserviceError {
  constructor(meta: { ip: string; method: string; path: string }) {
    super({
      code: ERROR_CODE.RATE_LIMIT_TOO_MANY_REQUESTS,
      httpStatus: HttpStatus.TOO_MANY_REQUESTS,
      message: `Too many request from ip: ${meta.ip}`,
      meta,
    });
  }
}

export class HerculesAlertSenderNotConfiguredError extends MicroserviceError {
  constructor(meta: { projectId: string; workflowId?: string }) {
    super({
      code: ERROR_CODE.HERCULES_ALERT_SENDER_NOT_CONFIGURED,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Unable to send alerts, alert sender not configured properly.',
      meta,
    });
  }
}

export class HerculesAWSCredentialsNotConfiguredError extends MicroserviceError {
  constructor() {
    super({
      code: ERROR_CODE.HERCULES_AWS_CREDENTIALS_NOT_CONFIGURED,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'AWS credentials missing.',
      meta: {},
    });
  }
}

export class HerculesECSError extends MicroserviceError {
  constructor(error: string | Error, meta: Object = {}) {
    super({
      code: ERROR_CODE.HERCULES_ECS_SERVICE_ERROR,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: typeof error === 'string' ? error : error.message,
      meta,
    });
  }
}

export class HerculesProtectedQueueDeregisterError extends MicroserviceError {
  constructor(meta: { queue: string }) {
    super({
      code: ERROR_CODE.HERCULES_PROTECTED_QUEUE_DEREGISTER_ERROR,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Not allowed to deregister protected queue.',
      meta,
    });
  }
}
