import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MAGENTO;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'ADOBE_COMMERCE_TEST_CONNECTION',
  //customers
  CREATE_CUSTOMER = 'ADOBE_COMMERCE_CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'ADOBE_COMMERCE_UPDATE_CUSTOMER',
  GET_CUSTOMER_BY_ID = 'ADOBE_COMMERCE_GET_CUSTOMER_BY_ID',
  SEARCH_CUSTOMERS = 'ADOBE_COMMERCE_SEARCH_CUSTOMERS',
  DELETE_CUSTOMER = 'ADOBE_COMMERCE_DELETE_CUSTOMER',
  //orders
  CREATE_ORDER = 'ADOBE_COMMERCE_CREATE_ORDER',
  UPDATE_ORDER = 'ADOBE_COMMERCE_UPDATE_ORDER',
  GET_ORDER_BY_ID = 'ADOBE_COMMERCE_GET_ORDER_BY_ID',
  SEARCH_ORDERS = 'ADOBE_COMMERCE_SEARCH_ORDERS',
  DELETE_ORDER = 'ADOBE_COMMERCE_DELETE_ORDER',
  //products
  CREATE_PRODUCT = 'ADOBE_COMMERCE_CREATE_PRODUCT',
  UPDATE_PRODUCT = 'ADOBE_COMMERCE_UPDATE_PRODUCT',
  GET_PRODUCT_BY_ID = 'ADOBE_COMMERCE_GET_PRODUCT_BY_ID',
  SEARCH_PRODUCTS = 'ADOBE_COMMERCE_SEARCH_PRODUCTS',
  DELETE_PRODUCT = 'ADOBE_COMMERCE_DELETE_PRODUCT',
}

/** Combination of ActionIntent and ConnectIntent **/
type IntentMap =
  | Intent.TEST_CONNECTION
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID
  | Intent.CREATE_CUSTOMER
  | Intent.UPDATE_CUSTOMER
  | Intent.GET_CUSTOMER_BY_ID
  | Intent.SEARCH_CUSTOMERS
  | Intent.DELETE_CUSTOMER
  | Intent.CREATE_ORDER
  | Intent.UPDATE_ORDER
  | Intent.GET_ORDER_BY_ID
  | Intent.SEARCH_ORDERS
  | Intent.DELETE_ORDER
  | Intent.CREATE_PRODUCT
  | Intent.UPDATE_PRODUCT
  | Intent.GET_PRODUCT_BY_ID
  | Intent.SEARCH_PRODUCTS
  | Intent.DELETE_PRODUCT;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  STORE_DOMAIN = 'ADOBE_COMMERCE_STORE_DOMAIN',
  USERNAME = 'ADOBE_COMMERCE_USERNAME',
  PASSWORD = 'ADOBE_COMMERCE_PASSWORD',
  API_TOKEN = 'ADOBE_COMMERCE_API_TOKEN',
}

export type Credentials = {
  [CredentialKeys.STORE_DOMAIN]: string;
  [CredentialKeys.USERNAME]: string;
  [CredentialKeys.PASSWORD]: string;
  [CredentialKeys.API_TOKEN]: string;
};
