import { StepType } from '@shared/entities/sdk/step/step.types';
import { default as AirtableConnectConfig } from '@shared/integrations/airtable/frontend/connect';
import { default as AmazonS3ConnectConfig } from '@shared/integrations/amazons3/frontend/connect';
import { default as AsanaConnectConfig } from '@shared/integrations/asana/frontend/connect';
import { default as AzureDevOpsConnectConfig } from '@shared/integrations/azuredevops/frontend/connect';
import { default as BambooHrConnectConfig } from '@shared/integrations/bamboohr/frontend/connect';
import { default as BigQueryConnectConfig } from '@shared/integrations/bigquery/frontend/connect';
import { default as CalendlyConnectConfig } from '@shared/integrations/calendly/frontend/connect';
import { default as ClickUpConnectConfig } from '@shared/integrations/clickup/frontend/connect';
import { default as CloseConnectConfig } from '@shared/integrations/close/frontend/connect';
import { default as DocuSignConnectConfig } from '@shared/integrations/docusign/frontend/connect';
import { default as DropboxConnectConfig } from '@shared/integrations/dropbox/frontend/connect';
import { default as DynamicsBusinessCentralConnectConfig } from '@shared/integrations/dynamicsbusinesscentral/frontend/connect';
import { default as DynamicsFinanceConnectConfig } from '@shared/integrations/dynamicsfinance/frontend/connect';
import { default as EloquaConfig } from '@shared/integrations/eloqua/frontend/connect';
import { default as FacebookAdsConfig } from '@shared/integrations/facebookAds/frontend/connect';
import { default as FreshdeskConfig } from '@shared/integrations/freshdesk/frontend/connect';
import { default as GithubConnectConfig } from '@shared/integrations/github/frontend/connect';
import { default as GmailConnectConfig } from '@shared/integrations/gmail/frontend/connect';
import { default as GongConnectConfig } from '@shared/integrations/gong/frontend/connect';
import { default as GoogleAdManagerConnectConfig } from '@shared/integrations/googleadmanager/frontend/connect';
import { default as GoogleAdsConnectConfig } from '@shared/integrations/googleads/frontend/connect';
import { default as GoogleAnalyticsConnectConfig } from '@shared/integrations/googleanalytics/frontend/connect';
import { default as GoogleCalendarConnectConfig } from '@shared/integrations/googleCalendar/frontend/connect';
import { default as GoogleCampaignManagerConfig } from '@shared/integrations/googlecampaignmanager/frontend/connect';
import { default as GoogleDriveConnectConfig } from '@shared/integrations/googledrive/frontend/connect';
import { default as GoogleSearchConsoleConfig } from '@shared/integrations/googlesearchconsole/frontend/connect';
import { default as GoogleSheetsConfig } from '@shared/integrations/googlesheets/frontend/connect';
import { default as GreenhouseConnectConfig } from '@shared/integrations/greenhouse/frontend/connect';
import { default as GustoConnectConfig } from '@shared/integrations/gusto/frontend/connect';
import { default as HubspotConnectConfig } from '@shared/integrations/hubspot/frontend/connect';
import { default as ImanageConnectConfig } from '@shared/integrations/imanage/frontend/connect';
import { default as IntercomConnectConfig } from '@shared/integrations/intercom/frontend/connect';
import { default as JiraConnectConfig } from '@shared/integrations/jira/frontend/connect';
import { default as KlaviyoConnectConfig } from '@shared/integrations/klaviyo/frontend/connect';
import { default as LeverConnectConfig } from '@shared/integrations/lever/frontend/connect';
import { default as LinearConnectConfig } from '@shared/integrations/linear/frontend/connect';
import { default as MagentoConnectConfig } from '@shared/integrations/magento/frontend/connect';
import { default as MailchimpConnectConfig } from '@shared/integrations/mailchimp/frontend/connect';
import { default as MarketoConfig } from '@shared/integrations/marketo/frontend/connect';
import { default as MicrosoftDynamicsConfig } from '@shared/integrations/microsoftDynamics/frontend/connect';
import { default as MicrosoftTeamsConfig } from '@shared/integrations/microsoftTeams/frontend/connect';
import { default as MixpanelConnectConfig } from '@shared/integrations/mixpanel/frontend/connect';
import { default as MondayConfig } from '@shared/integrations/monday/frontend/connect';
import { default as NetSuiteConfig } from '@shared/integrations/netsuite/frontend/connect';
import { default as OracleFinancialsCloudConfig } from '@shared/integrations/oraclefinancialscloud/frontend/connect';
import { default as OutlookConnectConfig } from '@shared/integrations/outlook/frontend/connect';
import { default as OutreachConfig } from '@shared/integrations/outreach/frontend/connect';
import { default as PandaDocConnectConfig } from '@shared/integrations/pandadoc/frontend/connect';
import { default as PardotConfig } from '@shared/integrations/pardot/frontend/connect';
import { default as PipedriveConfig } from '@shared/integrations/pipedrive/frontend/connect';
import { default as PowerBiConfig } from '@shared/integrations/powerbi/frontend/connect';
import { default as ProductboardConnectConfig } from '@shared/integrations/productboard/frontend/connect';
import { default as QuickbooksConnectConfig } from '@shared/integrations/quickbooks/frontend/connect';
import { default as SageAccountingConnectConfig } from '@shared/integrations/sageaccounting/frontend/connect';
import { default as SageIntacctConnectConfig } from '@shared/integrations/sageintacct/frontend/connect';
import { default as SailthruConfig } from '@shared/integrations/sailthru/frontend/connect';
import { default as SalesforceConnectConfig } from '@shared/integrations/salesforce/frontend/connect';
import { default as SalesloftConnectConfig } from '@shared/integrations/salesloft/frontend/connect';
import { default as SapConnectConfig } from '@shared/integrations/saps4hana/frontend/connect';
import { default as SapSuccessFactorsConnectConfig } from '@shared/integrations/sapsuccessfactors/frontend/connect';
import { default as SegmentConnectConfig } from '@shared/integrations/segment/frontend/connect';
import { default as ServiceNowConnectConfig } from '@shared/integrations/servicenow/frontend/connect';
import { default as SharePointConnectConfig } from '@shared/integrations/sharepoint/frontend/connect';
import { default as ShopifyConnectConfig } from '@shared/integrations/shopify/frontend/connect';
import { default as SlackConnectConfig } from '@shared/integrations/slack/frontend/connect';
import { default as StripeConfig } from '@shared/integrations/stripe/frontend/connect';
import { default as TableauConnectConfig } from '@shared/integrations/tableau/frontend/connect';
import { default as TrelloConnectConfig } from '@shared/integrations/trello/frontend/connect';
import { default as TwitterConnectConfig } from '@shared/integrations/twitter/frontend/connect';
import { default as WooCommerceConnectConfig } from '@shared/integrations/woocommerce/frontend/connect';
import { default as XeroConfig } from '@shared/integrations/xero/frontend/connect';
import { default as ZendeskConnectConfig } from '@shared/integrations/zendesk/frontend/connect';
import { default as ZohoCrmConnectConfig } from '@shared/integrations/zohocrm/frontend/connect';
import { default as ZoomConfig } from '@shared/integrations/zoom/frontend/connect';
import { ActionConfig, ActionStepView } from '@shared/types/sdk/actions';

import { VisibleConnectAction } from '.';

const configs: ActionConfig[] = [
  AirtableConnectConfig,
  AsanaConnectConfig,
  GithubConnectConfig,
  GongConnectConfig,
  GoogleAnalyticsConnectConfig,
  GoogleCalendarConnectConfig,
  HubspotConnectConfig,
  IntercomConnectConfig,
  JiraConnectConfig,
  KlaviyoConnectConfig,
  SalesforceConnectConfig,
  ShopifyConnectConfig,
  SlackConnectConfig,
  QuickbooksConnectConfig,
  GoogleDriveConnectConfig,
  ZendeskConnectConfig,
  GoogleSheetsConfig,
  MicrosoftTeamsConfig,
  ZoomConfig,
  MarketoConfig,
  MicrosoftDynamicsConfig,
  PardotConfig,
  XeroConfig,
  OutreachConfig,
  GoogleCampaignManagerConfig,
  FacebookAdsConfig,
  MailchimpConnectConfig,
  StripeConfig,
  TrelloConnectConfig,
  AzureDevOpsConnectConfig,
  OracleFinancialsCloudConfig,
  MondayConfig,
  GoogleSearchConsoleConfig,
  ClickUpConnectConfig,
  SharePointConnectConfig,
  OutlookConnectConfig,
  ServiceNowConnectConfig,
  EloquaConfig,
  NetSuiteConfig,
  DynamicsBusinessCentralConnectConfig,
  PipedriveConfig,
  SageIntacctConnectConfig,
  LinearConnectConfig,
  DynamicsFinanceConnectConfig,
  SageAccountingConnectConfig,
  WooCommerceConnectConfig,
  ImanageConnectConfig,
  BambooHrConnectConfig,
  GmailConnectConfig,
  GoogleAdManagerConnectConfig,
  DropboxConnectConfig,
  MagentoConnectConfig,
  ProductboardConnectConfig,
  DocuSignConnectConfig,
  SapConnectConfig,
  GoogleAdsConnectConfig,
  GreenhouseConnectConfig,
  LeverConnectConfig,
  CalendlyConnectConfig,
  ZohoCrmConnectConfig,
  SalesloftConnectConfig,
  TableauConnectConfig,
  FreshdeskConfig,
  TwitterConnectConfig,
  BigQueryConnectConfig,
  PowerBiConfig,
  MixpanelConnectConfig,
  SapSuccessFactorsConnectConfig,
  AmazonS3ConnectConfig,
  SailthruConfig,
  PandaDocConnectConfig,
  SegmentConnectConfig,
  GustoConnectConfig,
  CloseConnectConfig,
];

export const ActionSteps: Record<VisibleConnectAction, ActionStepView> = configs.reduce(
  (
    accumulator: Partial<{ [type in VisibleConnectAction]: ActionStepView }>,
    config: ActionConfig,
  ) => {
    const { actionType, icon, name: title, description, provider } = config;
    return {
      ...accumulator,
      [actionType]: {
        id: actionType as string,
        stepType: StepType.ACTION,
        icon,
        title,
        description,
        config,
        provider,
        initialParameters: {
          actionType,
          credentials: [],
          actionParameters: [],
        },
      },
    };
  },
  {},
) as Record<string, ActionStepView>;
