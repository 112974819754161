import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.ZOHO_CRM,
  provider: ProviderType.ZOHO_CRM,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Zoho CRM account',
  icon: iconSvg,
  description: 'Connect to your users’ Zoho CRM accounts',
  sidebarSections: [],
};

export default authConfig;
