import { hasImpersonatedUserCredential } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig, { authInput } from './authConfig';
import {
  apiKeyInput,
  apiTokenInput,
  boardInput,
  cardIdInput,
  createUpdateCardFieldInputs,
  intentTypeInput,
  searchQuery,
} from './common';

const config: ActionConfig = {
  actionType: Action.TRELLO,
  name: 'Trello',
  description: 'Connect your Trello account and manage your boards, list, and cards.',
  icon: iconSvg,
  provider: ProviderType.TRELLO,
  sidebarSections: [
    (parameters: ActionStepParameters) => ({
      inputs: [
        authInput,
        ...(hasImpersonatedUserCredential(parameters)
          ? ([
              {
                ...apiKeyInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.TRELLO_API_KEY],
                providerType: ProviderType.TRELLO,
                hideTokenDropDown: true,
              },
              {
                ...apiTokenInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.TRELLO_API_TOKEN],
                providerType: ProviderType.TRELLO,
                hideTokenDropDown: true,
              },
            ] as UserSuppliedCredentialInput[])
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length ? intentTypeInput : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEARCH_CARDS: {
          return { inputs: [searchQuery] };
        }

        case Intent.GET_CARDS_IN_BOARD: {
          return {
            inputs: [boardInput],
          };
        }

        case Intent.CREATE_CARD:
        case Intent.UPDATE_CARD:
          return {
            inputs: [
              ...(parameters.intent === Intent.CREATE_CARD
                ? createUpdateCardFieldInputs(false)
                : createUpdateCardFieldInputs(false, true)),
            ],
          };

        case Intent.DELETE_CARD: {
          return { inputs: [{ ...cardIdInput, subtitle: 'The ID of the card to delete.' }] };
        }

        case Intent.SEARCH_BOARDS: {
          return {
            inputs: [
              {
                ...searchQuery,
                subtitle: 'Search for boards by a phrase in their title or other properties.',
              },
            ],
          };
        }

        case Intent.GET_LISTS_IN_BOARD: {
          return {
            inputs: [
              {
                ...boardInput,
                subtitle:
                  'Select a Board to get lists from. Use Connect Portal Workflow Settings to allow users to select a Trello Board.',
              },
            ],
          };
        }

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;

export { authConfig };
