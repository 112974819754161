import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import authConfig, { authInput } from '../shared/authConfig';

import {
  additionalCampaignElementInput,
  additionalContactFieldsInput,
  campaignEmailInput,
  campaignFilterInput,
  campaignIdInput,
  campaignNameInput,
  campaignStatusInput,
  companyInput,
  contactFilterInput,
  contactIdInput,
  emailContactsInput,
  emailFilterInput,
  emailIdInput,
  emailNameInput,
  firstNameInput,
  htmlContentInput,
  lastNameInput,
  previewTextInput,
  replyToEmailInput,
  scheduledDateInput,
  segmentInput,
  senderEmailInput,
  senderNameInput,
  subjectLineInput,
} from './common';

const config: ActionConfig = {
  actionType: Action.ELOQUA,
  name: 'Oracle Eloqua',
  description: "Connect to your users' Eloqua accounts",
  icon: iconSvg,
  provider: ProviderType.ELOQUA,
  sidebarSections: [
    (_parameters: ActionStepParameters, _credentials: Record<string, ICredential>) => ({
      inputs: [authInput],
    }),
    (_parameters: ActionStepParameters): SidebarSection => ({ inputs: [] }),
    (parameters: ActionStepParameters): SidebarSection => {
      const email: SidebarInput = {
        ...campaignEmailInput,
        subtitle: 'Specify an Email to use as the first element of this campaign.',
      };
      switch (parameters.intent) {
        case Intent.ACTIVATE_CAMPAIGN:
          return {
            inputs: [
              { ...campaignIdInput, subtitle: 'ID of the campaign to activate.' },
              scheduledDateInput,
            ],
          };
        case Intent.CREATE_CAMPAIGN:
          return {
            inputs: [
              campaignNameInput,
              { ...segmentInput, subtitle: 'The Segment that this campaign will be target.' },
              campaignStatusInput,
              email,
              additionalCampaignElementInput,
            ],
          };
        case Intent.CREATE_CONTACT:
          return {
            inputs: [
              { ...email, subtitle: '', required: true },
              firstNameInput,
              lastNameInput,
              companyInput,
              {
                ...additionalContactFieldsInput,
                subtitle: 'Specify additional contact fields as JSON.',
              },
            ],
          };
        case Intent.CREATE_EMAIL:
          return {
            inputs: [
              emailNameInput,
              senderNameInput,
              senderEmailInput,
              replyToEmailInput,
              subjectLineInput,
              previewTextInput,
              htmlContentInput,
            ],
          };
        case Intent.GET_CAMPAIGN_BY_ID:
          return {
            inputs: [{ ...campaignIdInput, subtitle: 'ID of the campaign to get.' }],
          };
        case Intent.SEARCH_CAMPAIGNS:
          return { inputs: [campaignFilterInput] };
        case Intent.SEARCH_CONTACTS:
          return { inputs: [contactFilterInput] };
        case Intent.SEARCH_EMAIL:
          return { inputs: [emailFilterInput] };
        case Intent.SEND_EMAIL_DEPLOYMENT:
          return {
            inputs: [
              { ...emailNameInput, subtitle: 'The name of this email deployment.' },
              emailContactsInput,
              {
                ...email,
                required: true,
                subtitle:
                  'The Email to send. Use Connect Portal User Settings to allow your users to select an Email.',
              },
            ],
          };
        case Intent.UPDATE_CAMPAIGN:
          return {
            inputs: [
              campaignIdInput,
              ...[
                campaignNameInput,

                { ...segmentInput, subtitle: 'The Segment that this campaign will be target.' },
                campaignStatusInput,
                email,
                additionalCampaignElementInput,
              ].map((item: SidebarInput) => ({ ...item, required: false })),
            ],
          };
        case Intent.UPDATE_CONTACT:
          return {
            inputs: [
              contactIdInput,
              ...[
                { ...email, subtitle: '', required: true },
                firstNameInput,
                lastNameInput,
                companyInput,
                {
                  ...additionalContactFieldsInput,
                  subtitle: 'Specify additional contact fields as JSON.',
                },
              ].map((item: SidebarInput) => ({ ...item, required: false })),
            ],
          };
        case Intent.UPDATE_EMAIL:
          return {
            inputs: [
              emailIdInput,
              ...[
                emailNameInput,
                senderNameInput,
                senderEmailInput,
                replyToEmailInput,
                subjectLineInput,
                previewTextInput,
                htmlContentInput,
              ].map((item: SidebarInput) => ({ ...item, required: false })),
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export { authConfig };

export default config;
