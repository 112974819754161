import { Stripe } from 'stripe';

import { MIN_INTEGRATION_QUANTITY } from '@shared/types/sdk/billing';
import { BillingPlan, ConnectBillingPlan } from '@shared/types/sdk/stripe';

import { State } from '../store/types';

export function getBillingPlanFromState(state: State): BillingPlan;
export function getBillingPlanFromState(
  state: State,
  fallbackOnDefaultPlan: boolean,
): BillingPlan | undefined;
export function getBillingPlanFromState(
  state: State,
  fallbackOnDefaultPlan: boolean = true,
): BillingPlan | undefined {
  const { isConnectUi } = state.navigation;
  const userPlan: BillingPlan | undefined = isConnectUi
    ? ((state.billing.subscription?.plan?.product as Stripe.Product)?.metadata[
        'plan'
      ] as ConnectBillingPlan)
    : state.auth.usage?.plan;

  if (userPlan || !fallbackOnDefaultPlan) {
    return userPlan;
  }

  return isConnectUi ? BillingPlan.ConnectBasic : BillingPlan.ClassicFree;
}

/**
 * @returns minimum integration count that is shown in billing ui
 * @param integrationCount
 */
export function getMinIntegrationCount(integrationCount: number): number {
  return Math.max(MIN_INTEGRATION_QUANTITY, integrationCount);
}

/**
 * get card info from stripe customer source
 * @param subscription
 * @returns
 */
export function getCardInfoFromSource(
  subscription: Stripe.Subscription | undefined,
): Stripe.CustomerSource | undefined {
  if (!subscription) {
    return undefined;
  }

  const source: Stripe.CustomerSource = subscription.default_source as Stripe.CustomerSource;

  switch (source?.object) {
    case 'source':
      return source.card ? (source.card as Stripe.Card) : source;
    default:
      return source;
  }
}
