import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'read' | 'write' | 'impersonate';

export const scopes: Record<ScopeName, ScopeValue> = {
  read: {
    name: 'read',
    description: 'gives access to GET endpoints. Includes permission to sideload related resources',
  },
  write: {
    name: 'write',
    description: 'gives access to POST, PUT, and DELETE endpoints',
  },
  impersonate: {
    name: 'impersonate',
    description: 'allows Zendesk Support admins to make requests on behalf of end users',
  },
};

export const requiredScopes: ScopeName[] = ['read'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Zendesk',
  description: 'Sync tickets from Zendesk',
  authUrl: 'https://__ZENDESK_SUB_DOMAIN__.zendesk.com/oauth/authorizations/new',
  accessTokenUrl: 'https://__ZENDESK_SUB_DOMAIN__.zendesk.com/oauth/tokens',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/zendesk`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: [
      {
        name: 'read',
        description:
          'gives access to GET endpoints. Includes permission to sideload related resources',
      },
      {
        name: 'write',
        description: 'gives access to POST, PUT, and DELETE endpoints',
      },
      {
        name: 'impersonate',
        description: 'allows Zendesk Support admins to make requests on behalf of end users',
      },
    ],
  },
  documentationLink: 'https://developer.zendesk.com/rest_api/docs/zendesk-apis/resources',
  endUserSuppliedValues: [
    {
      type: SidebarInputType.ValueText,
      id: 'ZENDESK_SUB_DOMAIN',
      title: 'Enter your Zendesk subdomain',
      subtitle: 'Enter your Zendesk subdomain, e.g. https://<subdomain>.zendesk.com.',
      placeholder: 'subdomain',
      suffixLabel: '.zendesk.com',
      required: true,
    },
  ],
};
