import {
  CombinedState,
  Dispatch,
  MiddlewareAPI,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

import { BillingPlan } from '@shared/types/sdk/stripe';
import { MigrationType } from '@shared/types/sdk/version';
import featureRestrictions, {
  WorkflowFeatureRestriction,
} from '@shared/workflow/sdk/featureRestrictions';

import { sanitizeStep } from '../utils';
import { getBillingPlanFromState } from '../utils/billing';

import { stateMachineUpdater, stepValidator } from './middlewares';
import { updateIntegrationConfig } from './operations/entities/integrationConfigs';
import { updateStep } from './operations/entities/step';
import { getStepVariables } from './operations/entities/variables';
import { saveWorkflowVersion } from './operations/entities/version';
import * as auth from './reducers/auth';
import * as billing from './reducers/billing';
import * as confirm from './reducers/confirm';
import * as entities from './reducers/entities';
import { NO_OP_ACTION_STEP_ID } from './reducers/entities/step';
import * as integrationWizard from './reducers/integrationWizard';
import * as navigation from './reducers/navigation';
import * as workflowDashboard from './reducers/workflowDashboard';
import * as workflowEditor from './reducers/workflowEditor';
import * as workflowExecution from './reducers/workflowExecution';
import { Action, State } from './types';

const deepEqual = require('fast-deep-equal/es6');

const AUTO_SAVE_COUNT_THRESHOLD = 10;

export const defaultInitialState: State = {
  auth: auth.initialState,
  entities: entities.initialState,
  workflowDashboard: workflowDashboard.initialState,
  workflowEditor: workflowEditor.initialState,
  navigation: navigation.initialState,
  workflowExecution: workflowExecution.initialState,
  confirm: confirm.initialState,
  billing: billing.initialState,
  integrationWizard: integrationWizard.initialState,
};

const reducers = combineReducers({
  auth: auth.handler,
  entities: entities.handler,
  workflowDashboard: workflowDashboard.handler,
  workflowEditor: workflowEditor.handler,
  navigation: navigation.handler,
  workflowExecution: workflowExecution.handler,
  confirm: confirm.handler,
  billing: billing.handler,
  integrationWizard: integrationWizard.handler,
});

export function rootReducer(
  state: State = defaultInitialState,
  action: Action,
): CombinedState<State> {
  if (action.type === 'LOGOUT') {
    return defaultInitialState;
  }
  return reducers(state, action);
}

const UPDATE_DEBOUNCE_INTERVAL = 2000;

/**
 * A DebouncedUpdate is a tuple with a timeout ID and a flush function
 */
type DebouncedUpdate = [number, () => void | Promise<void>];

/**
 * Global map of step IDs to timeout IDs, for debouncing step updates
 */
export const pendingUpdates: Record<string, DebouncedUpdate> = {};

let debounceVersionTimeoutId: number | undefined = undefined;
let debounceIntegrationConfigTimeoutId: number | undefined = undefined;

export async function flushPendingUpdates(): Promise<void> {
  await Promise.all(
    Object.keys(pendingUpdates).map((stepId: string) => {
      const [, flush] = pendingUpdates[stepId];
      return flush();
    }),
  );
}

const stepUpdater =
  (store: MiddlewareAPI<Dispatch<Action>, State>) =>
  (next: Dispatch<Action>) =>
  async (action: Action) => {
    const oldState = store.getState();
    next(action);
    const newState = store.getState();
    if (['STEP_ENTITY_REORDER_REQUEST', 'STEP_ENTITY_REORDER_SUCCESS'].includes(action.type)) {
      return newState;
    }

    for (const stepId of Object.keys(newState.entities.steps.entities)) {
      if (stepId === NO_OP_ACTION_STEP_ID) {
        continue;
      }
      const oldStep = oldState.entities.steps.entities[stepId];
      const newStep = newState.entities.steps.entities[stepId];

      // if the step has the `stepId` prop it's a migration & we shouldn't use it to update the stpe
      const isStepExecution =
        (oldStep && oldStep.hasOwnProperty('stepId')) ||
        (newStep && newStep.hasOwnProperty('stepId'));
      if (oldStep && !deepEqual(sanitizeStep(oldStep), sanitizeStep(newStep)) && !isStepExecution) {
        const [timeoutId] = pendingUpdates[newStep.id] || [];
        if (timeoutId !== undefined) {
          clearTimeout(timeoutId);
        }

        const update = async () => {
          await Promise.all([
            updateStep(
              newState.navigation.projectId!,
              newState.navigation.workflowId!,
              newStep,
            )(store.dispatch),
            getStepVariables(
              newState.navigation.projectId!,
              newState.navigation.workflowId!,
              newStep.id,
            )(store.dispatch),
          ]);
          delete pendingUpdates[newStep.id];
          store.dispatch({ type: 'INCREMENT_AUTO_SAVE_COUNT' });
        };
        const delayedUpdate = setTimeout(() => {
          update().finally(() => {});
        }, UPDATE_DEBOUNCE_INTERVAL) as unknown as number;
        pendingUpdates[newStep.id] = [
          delayedUpdate,
          () => {
            clearTimeout(delayedUpdate);
            return update();
          },
        ];
      }
    }
  };

const featureRestrictionValidator =
  (store: MiddlewareAPI<Dispatch<Action>, State>) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    // The state has already been updated with an action prior to UPDATE_START, so we don't have
    // to run next(action) before this.
    const state = store.getState();
    if (action.type === 'STEP_ENTITY_UPDATE_START' && state.navigation.workflowId) {
      const seenRestrictions = state.workflowEditor.dismissedFeatureRestrictions;
      const currentWorkflow = state.entities.workflows.entities[state.navigation.workflowId];
      const steps = currentWorkflow.stepIds.map(
        (stepId: string) => state.entities.steps.entities[stepId],
      );
      const plan: BillingPlan = getBillingPlanFromState(state);
      const [firstApplicableRestriction] = featureRestrictions(
        steps,
        { ...currentWorkflow, steps },
        plan,
      ).filter(
        (restriction: WorkflowFeatureRestriction) => !seenRestrictions.includes(restriction),
      );

      if (firstApplicableRestriction) {
        store.dispatch({
          type: 'SHOW_FEATURE_RESTRICTION',
          restriction: firstApplicableRestriction,
        });
      }
    }
    return next(action);
  };

const autoSaveVersion =
  (store: MiddlewareAPI<Dispatch<Action>, State>) =>
  (next: Dispatch<Action>) =>
  async (action: Action) => {
    const autoSaveCount = store.getState().workflowEditor.autoSaveCount;
    const { projectId, workflowId } = store.getState().navigation;
    next(action);
    if (autoSaveCount >= AUTO_SAVE_COUNT_THRESHOLD && projectId && workflowId) {
      store.dispatch({ type: 'RESET_AUTO_SAVE_COUNT' });
      if (debounceVersionTimeoutId) {
        clearTimeout(debounceVersionTimeoutId);
      }
      debounceVersionTimeoutId = setTimeout(() => {
        saveWorkflowVersion(
          projectId,
          workflowId,
          undefined,
          MigrationType.AUTOSAVED,
        )(store.dispatch, store.getState).finally(() => {});
      }, UPDATE_DEBOUNCE_INTERVAL) as unknown as number;
    }
  };

const autoUpdateIntegrationConfig =
  (store: MiddlewareAPI<Dispatch<Action>, State>) =>
  (next: Dispatch<Action>) =>
  async (action: Action) => {
    const { projectId, integrationId } = store.getState().navigation;
    next(action);

    if (
      action.type === 'SET_PORTAL_ACCENT_COLOR' ||
      action.type === 'SET_PORTAL_DESCRIPTION' ||
      action.type === 'SET_PORTAL_OVERVIEW' ||
      action.type === 'SET_PORTAL_PARAGON_LINK' ||
      action.type === 'SET_PORTAL_WORKFLOW_DESCRIPTION' ||
      action.type === 'DISABLE_PORTAL_WORKfLOW' ||
      action.type === 'SET_PORTAL_WORKFLOW_VISIBILITY' ||
      action.type === 'SET_PORTAL_WORKFLOW_DEFAULT_ENABLED' ||
      action.type === 'REORDER_PORTAL_WORKFLOW_ITEMS' ||
      action.type === 'ADD_PORTAL_WORKFLOW_SETTING' ||
      action.type === 'UPDATE_PORTAL_WORKFLOW_SETTING' ||
      action.type === 'ADD_PORTAL_SHARED_SETTING' ||
      action.type === 'UPDATE_PORTAL_SHARED_SETTING' ||
      action.type === 'REORDER_PORTAL_WORKFLOW_SETTINGS' ||
      action.type === 'REORDER_PORTAL_SHARED_SETTINGS' ||
      action.type === 'DELETE_PORTAL_WORKFLOW_SETTING' ||
      action.type === 'DELETE_PORTAL_SHARED_SETTING'
    ) {
      const integrationConfigs =
        store.getState().entities.integrationConfigs.entities[action.configId];
      if (debounceIntegrationConfigTimeoutId) {
        clearTimeout(debounceIntegrationConfigTimeoutId);
      }

      debounceIntegrationConfigTimeoutId = setTimeout(() => {
        if (projectId && integrationId) {
          updateIntegrationConfig(
            projectId,
            integrationId,
            action.configId,
            integrationConfigs.values,
          )(store.dispatch).finally(() => {});
        }
      }, UPDATE_DEBOUNCE_INTERVAL) as unknown as number;
    }
  };

const enhancer = composeWithDevTools(
  applyMiddleware(
    ReduxThunk,
    featureRestrictionValidator,
    stepUpdater,
    stateMachineUpdater,
    autoSaveVersion,
    autoUpdateIntegrationConfig,
    stepValidator,
  ),
);

export function initializeStore(
  initialState: State = defaultInitialState,
): ReturnType<typeof createStore> {
  //@ts-ignore TODO : check whytype  error occuring
  return createStore(rootReducer, initialState, enhancer);
}
