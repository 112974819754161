import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { ZendeskUser } from '../shared/types';

export const getEndUsers: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  title: 'End User',
  subtitle: 'The user who requested this ticket',
  cacheKey: 'zendeskEndUsers',
  mapRefreshToValues: (response: ActionResponse<ZendeskUser[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((requester: ZendeskUser) => {
        return { label: requester.name, value: requester.id.toString() };
      })
    );
  },
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_USERS,
      actionType: Action.ZENDESK,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'getUserQuery',
          source: {
            type: 'VALUE',
            value: 'role=end-user',
          },
        },
      ],
    };
  },
};

export const getAssignees: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Assignee',
  subtitle: 'Zendesk agent that Tickets can be assigned to',
  cacheKey: 'zendeskAgentUsers',
  mapRefreshToValues: (response: ActionResponse<ZendeskUser[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((assignee: ZendeskUser) => ({
        label: assignee.name,
        value: assignee.id.toString(),
      }))
    );
  },
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_USERS,
      actionType: Action.ZENDESK,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'getUserQuery',
          source: {
            type: 'VALUE',
            value: 'role=agent',
          },
        },
      ],
    };
  },
};
