import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'openid' | 'offline_access' | 'email' | 'profile' | 'user_impersonation';

export const scopes: Record<ScopeName, ScopeValue> = {
  openid: {
    label: 'openid',
    name: 'openid',
    required: true,
    description: 'used to acquire ID tokens.',
  },
  email: {
    label: 'email',
    name: 'email',
    description: 'gives access user email',
    required: true,
  },
  profile: {
    label: 'profile',
    name: 'profile',
    description: 'gives access user email',
    required: true,
  },
  offline_access: {
    label: 'offline_access',
    name: 'offline_access',
    description: 'used to acquire refresh token',
    required: true,
  },
  user_impersonation: {
    label: 'user_impersonation',
    name: 'user_impersonation',
    description:
      'used to delegate with the permission to act as a signed-in user when it makes calls to the target resource',
    required: true,
  },
};

export const requiredScopes: ScopeName[] = [
  'openid',
  'offline_access',
  'email',
  'profile',
  'user_impersonation',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Dynamics 365 Sales',
  description: 'Access users Dynamics 365 Sales records',
  authUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  accessTokenUrl:
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize/organizations/oauth2/v2.0/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/microsoft-dynamics-365`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.microsoft.com/en-us/dynamics365/sales-enterprise/help-hub',
  endUserSuppliedValues: [
    {
      type: SidebarInputType.ValueText,
      id: 'MICROSOFT_DYNAMICS_SUB_DOMAIN',
      title: 'Enter your Dynamics 365 Sales subdomain',
      subtitle: `Your subdomain can be identified from your Dynamics 365 account's URL.`,
      placeholder: 'subdomain',
      suffixLabel: '.dynamics.com',
      required: true,
    },
  ],
};
