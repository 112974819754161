import {
  Cipher,
  createCipheriv,
  createDecipheriv,
  createHash,
  createHmac,
  generateKeyPair,
} from 'crypto';

import { KeyPair } from '@shared/entities/sdk/license';
import { GenericReject, GenericResolve } from '@shared/types/sdk/generic';

const ALGORITHM = {
  /**
   * GCM is an authenticated encryption mode that
   * not only provides confidentiality but also
   * provides integrity in a secured way
   * */

  BLOCK_CIPHER: 'aes-256-cbc',

  /**
   * NIST recommends 96 bits or 12 bytes IV for GCM
   * to promote interoperability, efficiency, and
   * simplicity of design
   */
  IV_BYTE_LEN: 16,
};

/**
 * creates a public + private key combination
 *
 * @export
 * @param {string} passphrase
 * @returns {Promise<KeyPair>}
 */
export async function createKeyPair(passphrase?: string): Promise<KeyPair> {
  return new Promise((resolve: GenericResolve, reject: GenericReject) => {
    generateKeyPair(
      'rsa',
      {
        modulusLength: 4096,
        publicKeyEncoding: {
          type: 'spki',
          format: 'pem',
        },
        privateKeyEncoding: {
          type: 'pkcs8',
          format: 'pem',
          ...(passphrase
            ? {
                cipher: 'aes-256-cbc',
                passphrase,
              }
            : {}),
        },
      },
      (err: Error, publicKey: string, privateKey: string) => {
        if (err) {
          return reject(err);
        }
        resolve({ privateKey, publicKey });
      },
    );
  });
}

/**
 * hashes a string
 *
 * @private
 * @param {string} value
 * @returns {string}
 * @memberof AnalyticsProxyService
 */
export function hash(value: string): string {
  if (value == undefined || value === null) {
    return value;
  }

  return createHmac('sha256', value).digest('hex').substr(0, 10);
}

/**
 * Convert a string into MD5 hash
 * @param {string} value
 * @returns {string}
 */
export function hashMD5(value: string): string {
  if (value == undefined || value === null) {
    return value;
  }
  return createHash('md5').update(value).digest('hex');
}

/**
 * generates an initialization vector for encrypting / decrypting a key
 * to really be secure, the IV should not be static but for the uses
 *
 * @returns {Buffer}
 */
function getIV(): Buffer {
  return Buffer.from(
    formatEncryptionKey(ALGORITHM.BLOCK_CIPHER).substring(0, ALGORITHM.IV_BYTE_LEN),
  );
}

/**
 * given a key, it formats it to a string with 32 characters
 * the key length is important for the ciphers but it's reasonably secure for our use cases
 *
 * @param {string} key
 * @returns {string}
 */
function formatEncryptionKey(key: string): string {
  return createHash('sha256').update(key).digest('base64').substr(0, 32);
}

/**
 * encrypts a value
 *
 * @param {string} input
 * @param {string} key
 * @returns {string}
 */
export function encrypt(input: string, key: string): string {
  const iv: Buffer = getIV();
  const formattedKey: string = formatEncryptionKey(key);
  const cipher: Cipher = createCipheriv(ALGORITHM.BLOCK_CIPHER, formattedKey, iv);
  return cipher.update(input, 'utf8', 'hex') + cipher.final('hex');
}

/**
 * decrypts a value
 *
 * @param {string} input
 * @param {string} key
 * @returns {string}
 */
export function decrypt(input: string, key: string): string {
  const iv: Buffer = getIV();
  const formattedKey: string = formatEncryptionKey(key);
  const decipher = createDecipheriv(ALGORITHM.BLOCK_CIPHER, formattedKey, iv);
  return decipher.update(input, 'hex', 'utf8') + decipher.final('utf8');
}

/**
 * return a sha256 hash for an object
 *
 * @export
 * @param {Record<string, unknown>} obj
 * @return {*}  {string}
 */
export function hashObject(obj: Record<string, unknown>): string {
  return hash(JSON.stringify(obj));
}
