import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class OrganizationError extends ParagonError {
  name: string = 'OrganizationError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class UserHasNoOrganizationError extends OrganizationError {
  name: string = 'UserHasNoOrganizationError';

  constructor(meta: { userId: string }) {
    super({
      code: ERROR_CODE.ORGANIZATION_FOUND_FOR_USER,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find organization for user. Please contact support@useparagon.com.',
      meta,
    });
  }
}

export class OrganizationNotFoundFromIdError extends OrganizationError {
  name: string = 'OrganizationNotFoundFromIdError';

  constructor(meta: { organizationId: string }) {
    super({
      code: ERROR_CODE.ORGANIZATION_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find organization.',
      meta,
    });
  }
}
