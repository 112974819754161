import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  assigneesInput,
  bodyInput,
  discussionCategoryNameInput,
  draftInput,
  intentTypeInput,
  issueBodyInput,
  issueFilterInput,
  issueNumberInput,
  issueTitleInput,
  lockReasonInput,
  ownerInput,
  prereleaseInput,
  releaseIdInput,
  releaseNotesInput,
  repositoryInput,
  stateInput,
  tagNameInput,
  targetInput,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.GITHUB,
  name: 'GitHub',
  description: `Connect to your users' GitHub accounts`,
  icon: iconSvg,
  provider: ProviderType.GITHUB,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_ISSUE:
          return {
            inputs: [ownerInput, repositoryInput, issueTitleInput, issueBodyInput, assigneesInput],
          };
        case Intent.UPDATE_ISSUE:
          return {
            inputs: [
              ownerInput,
              repositoryInput,
              issueNumberInput,
              { ...issueTitleInput, subtitle: 'Specify the title of the issue to update.' },
              { ...issueBodyInput, subtitle: 'Specify the body contents of the issue to update.' },
              assigneesInput,
              stateInput,
            ],
          };
        case Intent.LOCK_ISSUE:
          return {
            inputs: [
              ownerInput,
              repositoryInput,
              { ...issueNumberInput, subtitle: 'Specify the number of the issue to lock.' },
              lockReasonInput,
            ],
          };
        case Intent.GET_ISSUE_BY_NUMBER:
          return {
            inputs: [
              ownerInput,
              repositoryInput,
              { ...issueNumberInput, subtitle: 'Specify the number of the issue to be fetch.' },
            ],
          };
        case Intent.SEARCH_ISSUE:
          return {
            inputs: [ownerInput, repositoryInput, issueFilterInput],
          };
        case Intent.CREATE_RELEASE:
          return {
            inputs: [
              ownerInput,
              repositoryInput,
              tagNameInput,
              targetInput,
              bodyInput,
              draftInput,
              prereleaseInput,
              discussionCategoryNameInput,
              releaseNotesInput,
            ],
          };
        case Intent.UPDATE_RELEASE:
          return {
            inputs: [
              ownerInput,
              repositoryInput,
              releaseIdInput,
              {
                ...tagNameInput,
                required: false,
                subtitle: 'Specify the name of the release tag to be updated.',
              },
              targetInput,
              bodyInput,
              draftInput,
              prereleaseInput,
              discussionCategoryNameInput,
              releaseNotesInput,
            ],
          };

        case Intent.GET_RELEASE_BY_ID:
          return {
            inputs: [
              ownerInput,
              repositoryInput,
              {
                ...releaseIdInput,
                subtitle: 'Specify the release ID of the release to fetch.',
              },
            ],
          };
        case Intent.GET_RELEASE_BY_TAG_NAME:
          return {
            inputs: [
              ownerInput,
              repositoryInput,
              {
                ...tagNameInput,
                subtitle: 'Specify the tag of the release to fetch.',
                required: true,
              },
            ],
          };
        case Intent.DELETE_RELEASE:
          return {
            inputs: [
              ownerInput,
              repositoryInput,
              {
                ...releaseIdInput,
                subtitle: 'Specify the ID of the release to delete.',
              },
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };

export default connectConfig;
