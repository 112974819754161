import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { RecordType } from '../shared/types';

import authConfig from './authConfig';
import {
  commonIntentInput,
  componentIdInput,
  componentInputs,
  featureIdInput,
  featureInputs,
  productIdInput,
  recordTypeInput,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.PRODUCTBOARD,
  name: 'Productboard',
  description: `Connect to your users' Productboard accounts`,
  icon: iconSvg,
  provider: ProviderType.PRODUCTBOARD,
  sidebarSections: [
    commonIntentInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_FEATURE:
        case Intent.UPDATE_FEATURE:
          return { inputs: featureInputs(parameters.intent === Intent.CREATE_FEATURE) };

        case Intent.GET_FEATURE_BY_ID:
          return {
            inputs: [
              featureIdInput('Specify the feature ID of the feature to fetch.'),
              recordTypeInput(RecordType.FEATURES),
            ],
          };

        case Intent.DELETE_FEATURE:
          return {
            inputs: [
              featureIdInput('Specify the feature ID of the feature to delete.'),
              recordTypeInput(RecordType.FEATURES),
            ],
          };

        case Intent.CREATE_COMPONENT:
        case Intent.UPDATE_COMPONENT:
          return {
            inputs: componentInputs(parameters.intent === Intent.CREATE_COMPONENT),
          };

        case Intent.GET_COMPONENT_BY_ID:
          return {
            inputs: [
              componentIdInput('Specify the component ID of the feature to fetch.'),
              recordTypeInput(RecordType.COMPONENTS),
            ],
          };

        case Intent.GET_PRODUCT_BY_ID:
          return {
            inputs: [productIdInput, recordTypeInput(RecordType.PRODUCTS)],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };

export default connectConfig;
