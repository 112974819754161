import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const getLists: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'subscriberLists',
  title: 'List',
  subtitle: 'List to add subscribers to',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_LISTS,
      actionType: Action.KLAVIYO,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: function (response: ActionResponse<Record<string, any>[]>): EnumInputValue[] {
    return (
      response?.result &&
      response.result.output.map((list: Record<string, any>) => ({
        label: list.name,
        value: list.id,
      }))
    );
  },
};

export const getCampaigns: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'allCampaigns',
  title: 'Campaign',
  subtitle: 'Campaign to send',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_CAMPAIGN,
      actionType: Action.KLAVIYO,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: function (response: ActionResponse<Record<string, any>>): EnumInputValue[] {
    return (
      response?.result &&
      response.result.output.map((campaign: Record<string, any>) => ({
        label: campaign.name,
        value: campaign.id,
      }))
    );
  },
};

export const getTemplates: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'templates',
  title: 'Template',
  subtitle: 'Template to send a campaign with',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_TEMPLATES,
      actionType: Action.KLAVIYO,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: function (response: ActionResponse<Record<string, any>>): EnumInputValue[] {
    return (
      response?.result &&
      response.result.output.map((templates: Record<string, any>) => ({
        label: templates.name,
        value: templates.id,
      }))
    );
  },
};
