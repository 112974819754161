import { darken } from 'polished';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { COLORS } from '../utils/constants';

import Icon, { IconProps } from './Icon';

export type Props = {
  className?: string;
  borderColor?: string;
  color?: string;
  disabledColor?: string;
  titleColor?: string;
  disabledTitleColor?: string;
  shadowColor?: string;
  shadowSize?: string;
  disabled?: boolean;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  IconComponent?: React.ComponentType<IconProps>;
  tabIndex?: number;
  hidden?: boolean;
  width?: number;
  height?: number;
};

const ButtonRoot = styled.button<Props>`
  display: ${(props: Props) => (props.hidden ? 'none' : 'flex')};
  ${(props: Props) => (props.hidden ? '' : { justifyContent: 'center' })}
  ${(props: Props) => (props.width ? { width: props.width } : '')}
  ${(props: Props) => (props.height ? { height: props.height } : '')}
  align-items: center;
  color: ${(props: Props) => props.titleColor || '#fafafa'};
  font-weight: 600;
  font-size: 13px;
  height: 30px;
  border: ${(props: Props) => (props.borderColor ? `1px solid ${props.borderColor}` : '0')};
  border-radius: 2px;
  background-color: ${(props: Props) => props.color || 'transparent'};
  padding: 0 ${(props: Props) => (props.icon ? 10 : 14)}px;
  cursor: pointer;
  outline: 0;

  & + & {
    margin-left: 10px;
  }

  ${(props: Props) =>
    props.shadowColor
      ? `
  box-shadow: 0 ${props.shadowSize || '1.5px'} 0 ${props.shadowColor};
  transform: translateY(0);
  transition: 0.1s ease box-shadow, 0.1s ease transform;

  &:active {
    box-shadow: 0 0 0 ${props.shadowColor};
    transform: translateY(2px);
  }`
      : ''}

  &:disabled {
    background-color: ${(props: Props) => props.disabledColor || '#fafafa'};
    color: ${(props: Props) => props.disabledTitleColor || COLORS.PLACEHOLDER};
    box-shadow: none;
    cursor: not-allowed;
  }
`;

export type ButtonIconProps = {
  as?: (props: ButtonIconProps) => JSX.Element;
  hasTitle: boolean;
};

export const ButtonIcon = styled(Icon)<ButtonIconProps>`
  margin-right: ${(props: ButtonIconProps) => (props.hasTitle ? 6 : 0)}px;
`;

const Button = React.forwardRef((props: Props, forwardedRef: React.Ref<HTMLButtonElement>) => {
  const IconComponent = props.IconComponent || ButtonIcon;
  const elementRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (forwardedRef) {
      if (typeof forwardedRef === 'function') {
        forwardedRef(elementRef.current);
      } else {
        // hack
        (forwardedRef as { current: HTMLButtonElement | null }).current = elementRef.current;
      }
    }
  }, []);

  return (
    <ButtonRoot {...props} ref={elementRef}>
      {props.icon && props.icon !== 'blank' && (
        <IconComponent name={props.icon} hasTitle={!!props.title} />
      )}
      {props.title}
    </ButtonRoot>
  );
});

export const PrimaryButton = styled(Button).attrs({
  color: COLORS.SELECTED,
  titleColor: COLORS.WHITE,
  shadowColor: darken(0.1, COLORS.SELECTED),
  shadowSize: '3px',
})`
  font-size: 14px;
  padding: 18px;
`;

export default Button;
