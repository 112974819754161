import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'api:calls:read:transcript'
  | 'api:provisioning:read'
  | 'api:workspaces:read'
  | 'api:meetings:user:delete'
  | 'api:crm:register'
  | 'api:crm:get-objects'
  | 'api:data-privacy:delete'
  | 'api:crm:schema'
  | 'api:crm:upload'
  | 'api:meetings:integration:status'
  | 'api:calls:read:extensive'
  | 'api:meetings:user:update'
  | 'api:settings:scorecards:read'
  | 'api:stats:scorecards'
  | 'api:stats:interaction'
  | 'api:stats:user-actions'
  | 'api:calls:read:basic'
  | 'api:calls:read:media-url'
  | 'api:crm:integrations:read'
  | 'api:library:read'
  | 'api:data-privacy:read'
  | 'api:users:read'
  | 'api:logs:read'
  | 'api:calls:create'
  | 'api:meetings:user:create'
  | 'api:stats:user-actions:detailed'
  | 'api:provisioning:read-write'
  | 'api:engagement-data:write'
  | 'api:permission-profile:read'
  | 'api:permission-profile:write'
  | 'api:crm-calls:manual-association:read';

export const scopes: Record<ScopeName, ScopeValue> = {
  'api:calls:create': {
    name: 'api:calls:create',
    description: 'Create a call in Gong',
  },
  'api:calls:read:transcript': {
    name: 'api:calls:read:transcript',
    description: 'Retrieve a call transcript',
  },
  'api:provisioning:read': {
    name: 'api:provisioning:read',
    description: 'Auto provision users and groups using SCIM protocol - Read Only',
  },
  'api:workspaces:read': {
    name: 'api:workspaces:read',
    description: 'Retrieve a list of all workspaces',
  },
  'api:meetings:user:delete': {
    name: 'api:meetings:user:delete',
    description: 'Delete meetings',
  },
  'api:crm:register': {
    name: 'api:crm:register',
    description: 'Register a generic CRM integration',
  },
  'api:crm:get-objects': {
    name: 'api:crm:get-objects',
    description: 'Get CRM objects',
  },
  'api:data-privacy:delete': {
    name: 'api:data-privacy:delete',
    description: 'Delete all references to a given email address or phone call',
  },
  'api:crm:schema': {
    name: 'api:crm:schema',
    description: 'Upload CRM schema fields to Gong',
  },
  'api:crm:upload': {
    name: 'api:crm:upload',
    description: 'Upload a CRM data file to Gong',
  },
  'api:meetings:integration:status': {
    name: 'api:meetings:integration:status',
    description: 'Validate the integration status for managing meetings with Gong',
  },
  'api:calls:read:extensive': {
    name: 'api:calls:read:extensive',
    description:
      'Retrieve basic call data such as title, duration, and spoken language, and extended data such as interaction stats, list of speakers, and main topics of conversation',
  },
  'api:meetings:user:update': {
    name: 'api:meetings:user:update',
    description: 'Update Gong meetings',
  },
  'api:settings:scorecards:read': {
    name: 'api:settings:scorecards:read',
    description: 'Retrieve scorecard details such as ID, name, and status (enabled/disabled)',
  },
  'api:stats:scorecards': {
    name: 'api:stats:scorecards',
    description:
      "Retrieve scorecard statistics such as name, matching call ID, and a list of scorecard's answers",
  },
  'api:stats:interaction': {
    name: 'api:stats:interaction',
    description:
      'Retrieve user interaction statistics including various metrics such as "Talk Ratio" and "Interactivity"',
  },
  'api:stats:user-actions': {
    name: 'api:stats:user-actions',
    description:
      'Retrieve user action statistics, such as number of feedback requests or number of call shares',
  },
  'api:calls:read:basic': {
    name: 'api:calls:read:basic',
    description: 'Retrieve basic call data such as title, duration, and spoken language',
  },
  'api:calls:read:media-url': {
    name: 'api:calls:read:media-url',
    description:
      'Retrieve a URL that can be used to play and download the call media (audio or video). CAUTION: The call media may contain PII and biometric data that should be handled according to the respective data protection & privacy laws and agreements you are bound to',
  },
  'api:crm:integrations:read': {
    name: 'api:crm:integrations:read',
    description: 'Read CRM integrations',
  },
  'api:library:read': {
    name: 'api:library:read',
    description:
      'Retrieve Library folders details such as ID, name, last updated by and a list of calls in it',
  },
  'api:data-privacy:read': {
    name: 'api:data-privacy:read',
    description:
      'Retrieve a list of all references to either a specific email address or phone call',
  },
  'api:users:read': {
    name: 'api:users:read',
    description: 'Retrieve user data such as ID, name, and phone number',
  },
  'api:logs:read': {
    name: 'api:logs:read',
    description: 'Retrieve logs',
  },
  'api:meetings:user:create': {
    name: 'api:meetings:user:create',
    description: 'Create Gong meetings',
  },
  'api:stats:user-actions:detailed': {
    name: 'api:stats:user-actions:detailed',
    description:
      'Retrieve detailed user action statistics, such as a list of feedback requests or number of call shares',
  },
  'api:provisioning:read-write': {
    name: 'api:provisioning:read-write',
    description: 'Auto provision users and groups using SCIM protocol',
  },
  'api:engagement-data:write': {
    name: 'api:engagement-data:write',
    description: 'Send user engagement data events to Gong',
  },
  'api:permission-profile:read': {
    name: 'api:permission-profile:read',
    description: 'Read permission profiles',
  },
  'api:permission-profile:write': {
    name: 'api:permission-profile:write',
    description: 'Update permission profile',
  },
  'api:crm-calls:manual-association:read': {
    name: 'api:crm-calls:manual-association:read',
    description: 'Read calls that were manually associated with CRM data',
  },
};

export const requiredScopes: ScopeName[] = [
  'api:users:read',
  'api:calls:read:basic',
  'api:calls:create',
  'api:logs:read',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Gong',
  description: 'Sync files and documents with gong',
  authUrl: 'https://app.gong.io/oauth2/authorize',
  accessTokenUrl: 'https://app.gong.io/oauth2/generate-token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/gong`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://help.gong.io/hc/en-us/articles/360056677792',
};
