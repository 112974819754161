import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.PIPEDRIVE,
  provider: ProviderType.PIPEDRIVE,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Pipedrive account',
  icon: iconSvg,
  description: "Connect to your users' Pipedrive accounts",
  sidebarSections: [],
};

export default authConfig;
