import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  billingInfo,
  conditionalInputMapper,
  customerId,
  email,
  firstName,
  hiddenInputMapper,
  intentSelector,
  lastName,
  orderAdditionalFields,
  orderId,
  orderLineItems,
  orderStatus,
  password,
  paymentId,
  productAdditionalFields,
  productDescription,
  productId,
  productName,
  productStatus,
  productType,
  responseOrder,
  setAsPaid,
  shippingInfo,
  username,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.WOOCOMMERCE,
  name: 'WooCommerce',
  description: "Connect to your users' WooCommerce accounts",
  icon: iconSvg,
  provider: ProviderType.WOOCOMMERCE,
  sidebarSections: [
    intentSelector,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.UPDATE_CUSTOMER:
          return { inputs: [customerId] };

        case Intent.UPDATE_ORDER:
          return { inputs: [orderId] };

        case Intent.UPDATE_PRODUCT:
          return { inputs: [productId] };

        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.DELETE_ORDER:
        case Intent.GET_ORDER_BY_ID:
          return {
            inputs: [
              hiddenInputMapper[parameters.intent],
              {
                ...orderId,
                subtitle:
                  parameters.intent === Intent.DELETE_ORDER
                    ? 'Specify the record ID of the Order to Delete.'
                    : 'Specify the record ID of the Order.',
              },
            ],
          };
        case Intent.DELETE_CUSTOMER:
        case Intent.GET_CUSTOMER_BY_ID:
          return {
            inputs: [
              hiddenInputMapper[parameters.intent],
              {
                ...customerId,
                subtitle:
                  parameters.intent === Intent.DELETE_CUSTOMER
                    ? 'Specify the record ID of the Customer to Delete.'
                    : 'Specify the record ID of the Customer.',
              },
            ],
          };
        case Intent.DELETE_PRODUCT:
        case Intent.GET_PRODUCT_BY_ID:
          return {
            inputs: [
              hiddenInputMapper[parameters.intent],
              {
                ...productId,
                subtitle:
                  parameters.intent === Intent.DELETE_PRODUCT
                    ? 'Specify the record ID of the Product to Delete.'
                    : 'Specify the record ID of the Product.',
              },
            ],
          };

        case Intent.SEARCH_CUSTOMERS:
        case Intent.SEARCH_ORDERS:
        case Intent.SEARCH_PRODUCTS:
          return {
            inputs: [
              hiddenInputMapper[parameters.intent],
              conditionalInputMapper[parameters.intent],
              {
                ...responseOrder,
                subtitle:
                  parameters.intent !== Intent.SEARCH_CUSTOMERS
                    ? 'Default order is desc'
                    : responseOrder.subtitle,
              },
            ],
          };

        case Intent.CREATE_CUSTOMER:
        case Intent.UPDATE_CUSTOMER:
          return {
            inputs: [
              hiddenInputMapper[parameters.intent],
              { ...email, required: parameters.intent === Intent.CREATE_CUSTOMER },
              firstName,
              lastName,
              ...(parameters.intent === Intent.CREATE_CUSTOMER ? [username, password] : []),
              billingInfo,
              shippingInfo,
            ],
          };

        case Intent.CREATE_ORDER:
        case Intent.UPDATE_ORDER:
          return {
            inputs: [
              hiddenInputMapper[parameters.intent],
              { ...orderLineItems, required: parameters.intent === Intent.CREATE_ORDER },
              paymentId,
              setAsPaid,
              {
                ...customerId,
                id: 'customerRelatedWithOrder',
                placeholder: '25',
                subtitle: 'Customer ID who owns the order. 0 for guests. Default is 0.',
                required: false,
              },
              orderStatus,
              orderAdditionalFields,
            ],
          };

        case Intent.CREATE_PRODUCT:
        case Intent.UPDATE_PRODUCT:
          return {
            inputs: [
              hiddenInputMapper[parameters.intent],
              ...(parameters.intent === Intent.UPDATE_CUSTOMER ? [productId] : []),
              productName,
              productDescription,
              productType,
              productStatus,
              productAdditionalFields,
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
