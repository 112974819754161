import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SEGMENT;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SEGMENT_TEST_CONNECTION',
}

type IntentMap =
  | Intent.TEST_CONNECTION
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  SEGMENT_API_KEY = 'SEGMENT_API_KEY',
}

export type Credentials = {
  [CredentialKeys.SEGMENT_API_KEY]: string;
};
