import { Lens } from 'monocle-ts';

import { ITeamMemberExpanded } from '@shared/entities/sdk/team/team.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<ITeamMemberExpanded> = {
  entities: {},
  processing: false,
  errorMessage: undefined,
};

export function handler(
  state: EntitiesState<ITeamMemberExpanded> = initialState,
  action: Action,
): EntitiesState<ITeamMemberExpanded> {
  switch (action.type) {
    case 'TEAM_MEMBER_ENTITY_GET_START':
    case 'TEAM_MEMBER_ENTITY_UPDATE_START':
    case 'TEAM_MEMBER_ENTITY_REMOVE_START':
      return {
        ...state,
        processing: true,
      };
    case 'TEAM_MEMBER_ENTITY_GET_FAILURE':
    case 'TEAM_MEMBER_ENTITY_UPDATE_FAILURE':
    case 'TEAM_MEMBER_ENTITY_REMOVE_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'TEAM_MEMBER_ENTITY_GET_SUCCESS':
    case 'TEAM_MEMBER_ENTITY_REMOVE_SUCCESS':
      return {
        ...state,
        entities: { ...indexBy('id', action.teamMembers) },
        processing: false,
        errorMessage: undefined,
      };

    case 'TEAM_MEMBER_ENTITY_UPDATE_SUCCESS':
      return {
        ...state,
        ...Lens.fromPath<EntitiesState<ITeamMemberExpanded>>()([
          'entities',
          action.teamMember.id,
        ]).modify((member: ITeamMemberExpanded) => ({ ...member, ...action.teamMember }))(state),
        processing: false,
        errorMessage: undefined,
      };

    default:
      return state;
  }
}
