import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  IntentInputDesignType,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { WorkflowType } from '@shared/types/sdk/execution';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const triggerConfig: ActionConfig = {
  actionType: Action.OUTREACH,
  name: 'Outreach',
  description: 'Trigger when CRM records are created or updated in Outreach',
  icon: iconSvg,
  provider: ProviderType.OUTREACH,
  sidebarSections: [
    (
      parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      if (parameters.credentials.length === 0 && workflowType === WorkflowType.CLASSIC) {
        return { inputs: [] };
      }

      return {
        inputs: [
          {
            id: 'intent',
            title: 'Choose a Trigger Event',
            type: SidebarInputType.Intent,
            designType: IntentInputDesignType.WorkflowSettingsDesign,
            icon: iconSvg,
            values: [
              {
                value: Intent.TRIGGER_ACCOUNT_CREATED,
                label: 'New Account',
                subtitle: 'Trigger when a new Account is created',
              },
              {
                value: Intent.TRIGGER_ACCOUNT_UPDATED,
                label: 'Account Updated',
                subtitle: 'Trigger when an Account is updated',
              },
              {
                value: Intent.TRIGGER_OPPORTUNITY_CREATED,
                label: 'New Opportunity',
                subtitle: 'Trigger when a new Opportunity is created',
              },
              {
                value: Intent.TRIGGER_OPPORTUNITY_UPDATED,
                label: 'Opportunity Updated',
                subtitle: 'Trigger when an Opportunity is updated',
              },
              {
                value: Intent.TRIGGER_PROSPECT_CREATED,
                label: 'New Prospect',
                subtitle: 'Trigger when a new Prospect is created',
              },
              {
                value: Intent.TRIGGER_PROSPECT_UPDATED,
                label: 'Prospect Updated',
                subtitle: 'Trigger when a Prospect is updated',
              },
            ],
          },
        ],
      };
    },
  ],
};

export default triggerConfig;
