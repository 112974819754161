import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { GoogleSheetColumn } from '../shared/types';

export const getSpreadSheets: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'spreadSheets',
  title: 'Spreadsheet',
  subtitle: 'A user’s spreadsheet to get row data from',
  refreshDependencies: [(options: ActionStepParameters) => options.credentials[0]],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response.result.output.map((spreadSheet: any) => ({
      label: spreadSheet.name,
      value: spreadSheet.id,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.GOOGLE_SHEETS,
      intent: Intent.GET_SPREADSHEETS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getWorkSheets: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'worksheetId',
  title: 'Worksheet',
  // added more refresh dependencies so that it can continue to support the previous workflows and cache keys acts as refresh dependencies for connect.
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials[0],
    'spreadsheetId', // reference to input for spreadsheet used in classic
    'spreadSheets', // cacheKey for getSpreadsheets source
  ],
  hideFromConnectFieldTypes: true,
  subtitle: 'A worksheet of a spreadsheet to get rows from',
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((workSheet: any) => ({
        label: workSheet,
        value: workSheet,
      }))
    );
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const spreadSheet =
      pickValueSourceByKey(options.actionParameters, 'spreadsheetId') ??
      pickValueSourceByKey(options.actionParameters, 'spreadSheets');

    return {
      actionType: Action.GOOGLE_SHEETS,
      intent: Intent.GET_WORKSHEETS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'spreadsheetId',
          source: {
            type: 'VALUE',
            value: spreadSheet && spreadSheet.value,
          },
        },
      ],
    };
  },
};

export const getColumns: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Column',
  cacheKey: 'cachedColumns',
  mapRefreshToValues: (response: ActionResponse<GoogleSheetColumn[]>): any => {
    return (
      response?.result &&
      response.result.output.map((googleSheetColumn: GoogleSheetColumn) => ({
        label: googleSheetColumn.label,
        value: googleSheetColumn.value,
      }))
    );
  },
  subtitle: 'A column of a table from a worksheet',
  // added more refresh dependencies so that it can continue to support the previous workflows and cache keys acts as refresh dependencies for connect.
  refreshDependencies: [
    'spreadsheetId', // reference to input for spreadsheet used in classic
    'spreadSheets', // cacheKey for getSpreadsheets source
    'worksheet', // reference to input for worksheet used in classic
    'worksheetId', // cacheKey for getWorksheets source
  ],
  hideFromConnectFieldTypes: true,
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const spreadSheet =
      pickValueSourceByKey(options.actionParameters, 'spreadsheetId') ??
      pickValueSourceByKey(options.actionParameters, 'spreadSheets');
    const worksheet =
      pickValueSourceByKey(options.actionParameters, 'worksheet') ??
      pickValueSourceByKey(options.actionParameters, 'worksheetId');

    return {
      actionType: Action.GOOGLE_SHEETS,
      intent: Intent.GET_COLUMNS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'spreadsheetId',
          source: {
            type: 'VALUE',
            value: spreadSheet && spreadSheet.value,
          },
        },
        {
          key: 'worksheet',
          source: {
            type: 'VALUE',
            value: worksheet && worksheet.value,
          },
        },
      ],
    };
  },
};

export const columnMapper: ComboInputDataSource = {
  id: 'columnMapper',
  type: DataSourceType.COMBO_INPUT,
  title: 'Column Mapping',
  mainInputSource: getSpreadSheets,
  dependentInputSource: getWorkSheets,
  fieldSource: getColumns,
  subtitle: 'Allow users to define an object mapping with worksheet columns',
};
