import { Operator } from '@shared/types/sdk/resolvers';

const supportedOperators: Operator[] = [
  Operator.StringContains,
  Operator.StringDoesNotContain,
  Operator.StringStartsWith,
  Operator.StringEndsWith,
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.NumberLessThan,
  Operator.NumberEquals,
  Operator.NumberGreaterThan,
  Operator.NumberDoesNotEqual,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberLessThanOrEqualTo,
  Operator.ArrayIsIn,
  Operator.DateTimeEquals,
  Operator.DateTimeBefore,
  Operator.DateTimeAfter,
];

export default supportedOperators;
