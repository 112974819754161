import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import googleIconSvg from './googleIcon.svg';

export type ScopeName =
  | 'email'
  | 'https://www.googleapis.com/auth/drive'
  | 'https://www.googleapis.com/auth/drive.appdata'
  | 'https://www.googleapis.com/auth/drive.file'
  | 'https://www.googleapis.com/auth/drive.metadata'
  | 'https://www.googleapis.com/auth/drive.metadata.readonly'
  | 'https://www.googleapis.com/auth/drive.photos.readonly'
  | 'https://www.googleapis.com/auth/drive.readonly'
  | 'https://www.googleapis.com/auth/spreadsheets'
  | 'https://www.googleapis.com/auth/calendar.readonly'
  | 'https://www.googleapis.com/auth/calendar'
  | 'https://www.googleapis.com/auth/calendar.events'
  | 'https://www.googleapis.com/auth/calendar.events.readonly'
  | 'https://www.googleapis.com/auth/calendar.settings.readonly'
  | 'https://www.googleapis.com/auth/dfatrafficking'
  | 'https://www.googleapis.com/auth/dfareporting'
  | 'https://www.googleapis.com/auth/ddmconversions'
  | 'https://www.googleapis.com/auth/analytics.readonly'
  | 'https://www.googleapis.com/auth/analytics'
  | 'https://www.googleapis.com/auth/analytics.edit'
  | 'https://www.googleapis.com/auth/webmasters'
  | 'https://www.googleapis.com/auth/webmasters.readonly'
  | 'https://www.googleapis.com/auth/gmail.send'
  | 'https://www.googleapis.com/auth/gmail.compose'
  | 'https://www.googleapis.com/auth/gmail.readonly'
  | 'https://www.googleapis.com/auth/contacts'
  | 'https://mail.google.com';

export const requiredScopes: ScopeName[] = ['email', 'https://www.googleapis.com/auth/drive'];

export const scopes: Record<ScopeName, ScopeValue> = {
  email: {
    label: 'email',
    name: 'email',
    description: 'see email address',
    required: true,
  },
  'https://www.googleapis.com/auth/drive': {
    label: 'drive',
    name: 'https://www.googleapis.com/auth/drive',
    description: 'See, edit, create, and delete all of your Google Drive files',
    required: true,
  },
  'https://www.googleapis.com/auth/drive.appdata': {
    label: 'drive.appdata',
    name: 'https://www.googleapis.com/auth/drive.appdata',
    description: 'View and manage its own configuration data in your Google Drive',
  },
  'https://www.googleapis.com/auth/drive.file': {
    label: 'drive.file',
    name: 'https://www.googleapis.com/auth/drive.file',
    description:
      'View and manage Google Drive files and folders that you have opened or created with this app',
  },
  'https://www.googleapis.com/auth/drive.metadata': {
    label: 'drive.metadata',
    name: 'https://www.googleapis.com/auth/drive.metadata',
    description: 'View and manage metadata of files in your Google Drive',
  },
  'https://www.googleapis.com/auth/drive.metadata.readonly': {
    label: 'drive.metadata.readonly',
    name: 'https://www.googleapis.com/auth/drive.metadata.readonly',
    description: 'View metadata for files in your Google Drive',
  },
  'https://www.googleapis.com/auth/drive.photos.readonly': {
    label: 'drive.photos.readonly',
    name: 'https://www.googleapis.com/auth/drive.photos.readonly',
    description: 'View the photos, videos and albums in your Google Photos',
  },
  'https://www.googleapis.com/auth/drive.readonly': {
    label: 'drive.readonly',
    name: 'https://www.googleapis.com/auth/drive.readonly',
    description: "Modify your Google Apps Script scripts' behavior",
  },
  'https://www.googleapis.com/auth/spreadsheets': {
    label: 'spreadsheets',
    name: 'https://www.googleapis.com/auth/spreadsheets',
    description: 'See, edit, create, and delete your spreadsheets in Google Drive',
    required: true,
  },
  'https://www.googleapis.com/auth/calendar': {
    label: 'calendar',
    name: 'https://www.googleapis.com/auth/calendar',
    description: 'read/write access to Calendars',
  },
  'https://www.googleapis.com/auth/calendar.readonly': {
    label: 'calendar.readonly',
    name: 'https://www.googleapis.com/auth/calendar.readonly',
    description: 'read-only access to Calendars',
  },
  'https://www.googleapis.com/auth/calendar.events': {
    label: 'calendar.events',
    name: 'https://www.googleapis.com/auth/calendar.events',
    description: 'read/write access to Events',
  },
  'https://www.googleapis.com/auth/calendar.events.readonly': {
    label: 'calendar.events.readonly',
    name: 'https://www.googleapis.com/auth/calendar.events.readonly',
    description: 'read-only access to Events',
  },
  'https://www.googleapis.com/auth/calendar.settings.readonly': {
    label: 'calendar.settings.readonly',
    name: 'https://www.googleapis.com/auth/calendar.settings.readonly',
    description: 'read-only access to Settings',
  },
  'https://www.googleapis.com/auth/dfatrafficking': {
    label: 'Trafficking',
    name: 'https://www.googleapis.com/auth/dfatrafficking',
    description: 'read/write access to Events',
  },
  'https://www.googleapis.com/auth/dfareporting': {
    label: 'Reporting',
    name: 'https://www.googleapis.com/auth/dfareporting',
    description: 'Read/write access to Campaign Manager 360 reporting.',
  },
  'https://www.googleapis.com/auth/ddmconversions': {
    label: 'Conversion',
    name: 'https://www.googleapis.com/auth/ddmconversions',
    description: 'Read/write access to Campaign Manager 360 offline conversions.',
  },
  'https://www.googleapis.com/auth/analytics': {
    label: 'analytics',
    name: 'https://www.googleapis.com/auth/analytics',
    description: 'Access to the Analytics API.',
  },
  'https://www.googleapis.com/auth/analytics.edit': {
    label: 'analytics.edit',
    name: 'https://www.googleapis.com/auth/analytics.edit',
    description: 'Read/write access to the Analytics API.',
  },
  'https://www.googleapis.com/auth/analytics.readonly': {
    label: 'analytics.readonly',
    name: 'https://www.googleapis.com/auth/analytics.readonly',
    description: 'Read only access to the Analytics API.',
  },
  'https://www.googleapis.com/auth/webmasters': {
    label: 'Google Search Console API Read/write access',
    name: 'https://www.googleapis.com/auth/webmasters',
    description: 'Webmasters read/write access',
  },
  'https://www.googleapis.com/auth/webmasters.readonly': {
    label: 'Google Search Console API Read-only access',
    name: 'https://www.googleapis.com/auth/webmasters.readonly',
    description: 'Webmasters read only access',
  },
  'https://www.googleapis.com/auth/gmail.send': {
    label: 'gmail.send',
    name: 'https://www.googleapis.com/auth/gmail.send',
    description: 'Send messages only. No read or modify privileges on mailbox.',
  },
  'https://www.googleapis.com/auth/gmail.compose': {
    label: 'gmail.compose',
    name: 'https://www.googleapis.com/auth/gmail.compose',
    description: 'Create, read, update, and delete drafts. Send messages and drafts.',
  },
  'https://www.googleapis.com/auth/gmail.readonly': {
    label: 'gmail.readonly',
    name: 'https://www.googleapis.com/auth/gmail.readonly',
    description: 'Read all resources and their metadata—no write operations.',
  },
  'https://www.googleapis.com/auth/contacts': {
    label:
      'Requests that your app be given read and write access to the authenticated user’s Google Contacts',
    name: 'https://www.googleapis.com/auth/contacts',
    description: 'See, edit, download, and permanently delete your contacts',
  },
  'https://mail.google.com': {
    label: 'Full access to the Gmail account',
    name: 'https://mail.google.com',
    description:
      'Full access to the account, including permanent deletion of threads and messages. This scope should only be requested if your application needs to immediately and permanently delete threads and messages. All other actions can be performed with less permissive scopes',
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Google',
  description:
    'Access a user’s Google Analytics, Google Sheets, Google Search Console, Google Campaign Manager, Drive, Gmail and Calendar.',
  authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
  accessTokenUrl: 'https://oauth2.googleapis.com/token',
  iconSvg: googleIconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/google`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/google',
};
