import React from 'react';

import {
  getValuesByCacheKey,
  hasOAuthAppCredential,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionButtonStyle,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import {
  additionalFieldsInput,
  companyIdInput,
  contactIdInput,
  customObjectNameInput,
  customObjectPropertyInput,
  engagementFilterInputConnect,
  engagementTypeInput,
  engagementTypeMetadataInputs,
  ownerIdInput,
} from '../shared/defaultRecordTypes';
import {
  EngagementType,
  ExtraRecordType,
  GetRecordFieldResponse,
  RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
  RecordType,
} from '../shared/types';

import authConfig, { authInput } from './authConfig';
import { getCustomObjectTypes, getFields } from './sources';
import supportedOperators from './supportedOperators';

export const hubspotIntentsShownInDropdown = [
  {
    value: Intent.CREATE_RECORD,
    label: 'Create record',
  },
  {
    value: Intent.UPDATE_RECORD,
    label: 'Update record',
  },
  {
    value: Intent.DELETE_RECORD,
    label: 'Delete record by ID',
  },
  {
    value: Intent.GET_RECORD_BY_ID,
    label: 'Get record by ID',
  },
  {
    value: Intent.SEARCH_RECORDS,
    label: 'Search records',
  },
];

const recordPayLoadJson: SidebarInput = {
  id: 'createRecordPayLoad',
  type: SidebarInputType.Code,
  language: 'json',
  title: 'Record properties JSON',
  required: true,
  placeholder: `{
    "property_number": "17",
    "property_dropdown": "choice_b",
    "property_radio": "option_1",
    "property_string": "value",
    "property_date": "1572480000000"
}`,
  useLightTheme: true,
};

const recordTypes = [
  {
    value: RecordType.COMPANY,
    label: 'Company',
  },
  {
    value: RecordType.CONTACTS,
    label: 'Contact',
  },
  {
    value: RecordType.DEALS,
    label: 'Deals',
  },
  {
    value: RecordType.LINEITEMS,
    label: 'Line Items',
  },
  {
    value: RecordType.PRODUCTS,
    label: 'Products',
  },
  {
    value: RecordType.TICKETS,
    label: 'Tickets',
  },
  {
    value: ExtraRecordType.ENGAGEMENTS,
    label: 'Engagements',
  },
  {
    value: RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
    label: 'Custom Object',
  },
];

const subtitleForRecordIdInput = (action: string): React.ReactNode => (
  <>
    We recommend that you add a <strong>Search records</strong> HubSpot Step before this step to get
    the ID of the record you want to {action}.
  </>
);

const recordTypeInput: SidebarInput = {
  id: 'recordType',
  type: SidebarInputType.Enum,
  title: 'Record type',
  required: true,
  getValues: () => recordTypes,
};

const recordIdInput: SidebarInput = {
  id: 'recordId',
  type: SidebarInputType.TextArea,
  title: 'Record ID',
  required: true,
  lines: 1,
};

const limitInput: SidebarInput = {
  id: 'limit',
  type: SidebarInputType.Text,
  required: false,
  title: 'Limit records',
  subtitle: 'You can limit the maximum number of records that get returned from hubspot.',
};

const customObjectDynamicInput: SidebarInput = {
  ...customObjectNameInput,
  subtitle: 'Select a list to send this campaign to.',
  type: SidebarInputType.EditableDynamicEnum,
  source: getCustomObjectTypes,
  placeholder: '',
  getValues: getValuesByCacheKey,
};

const config: ActionConfig = {
  actionType: Action.HUBSPOT,
  name: 'HubSpot',
  description: 'Find, create, and update records in HubSpot.',
  icon: iconSvg,
  provider: ProviderType.HUBSPOT,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        authInput,
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.HUBSPOT,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection => {
      return parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: hubspotIntentsShownInDropdown,
              },
            ],
          }
        : {
            inputs: [],
          };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (!parameters.intent) {
        return { inputs: [] };
      }
      return {
        inputs: [recordTypeInput],
      };
    },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      const fieldsInput: SidebarInput[] = Object.entries<GetRecordFieldResponse>(
        pickValueSourceByKey<DataType.ANY>(parameters.actionParameters, 'cachedFields')?.value ||
          [],
      ).map(([_, field]: [string, GetRecordFieldResponse]): SidebarInput => {
        if ((field.fieldType == 'radio' || field.fieldType == 'select') && field.options) {
          return {
            id: `field-${field.value}`,
            title: field.label,
            required: field.required,
            type: SidebarInputType.Enum,
            getValues: () => field.options ?? [],
          };
        } else {
          return {
            id: `field-${field.value}`,
            title: field.label,
            required: field.required,
            type: SidebarInputType.TextArea,
            lines: 1,
          };
        }
      });

      const selectedEngagementType = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'engagementType',
      )?.value;
      const recordType = pickValueSourceByKey(parameters.actionParameters, 'recordType')?.value;
      const isEngagmentRecordType: boolean = recordType === ExtraRecordType.ENGAGEMENTS;
      const isCustomObjectRecordType: boolean = recordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME;

      switch (parameters.intent) {
        case Intent.CREATE_RECORD:
          if (isEngagmentRecordType) {
            return {
              inputs: [
                engagementTypeInput,
                contactIdInput,
                companyIdInput,
                ownerIdInput,
                ...(engagementTypeMetadataInputs[selectedEngagementType as EngagementType] ?? []),
              ],
            };
          }
          if (isCustomObjectRecordType) {
            return {
              inputs: [
                customObjectDynamicInput,
                {
                  ...customObjectPropertyInput,
                  subtitle: 'Specify custom object fields that should be updated in JSON below. ',
                },
              ],
            };
          }

          return {
            inputs: hasOAuthAppCredential(parameters, credentials)
              ? [
                  {
                    ...recordPayLoadJson,
                    ...{ id: 'createRecordPayLoad', title: 'Record properties JSON' },
                  },
                ]
              : [...fieldsInput, additionalFieldsInput],
            buttons: hasOAuthAppCredential(parameters, credentials)
              ? []
              : [
                  {
                    id: 'refreshFields',
                    type: SidebarInputType.ActionButton,
                    title: 'Refresh Fields',
                    style: ActionButtonStyle.DEFAULT,
                    hidden: true,
                    source: getFields,
                  },
                ],
          };
        case Intent.UPDATE_RECORD:
          if (isEngagmentRecordType) {
            return {
              inputs: [
                engagementTypeInput,
                recordIdInput,
                contactIdInput,
                companyIdInput,
                ownerIdInput,
                ...(engagementTypeMetadataInputs[selectedEngagementType as EngagementType] ?? []),
              ],
            };
          }
          if (isCustomObjectRecordType) {
            return {
              inputs: [
                customObjectDynamicInput,
                {
                  ...customObjectPropertyInput,
                  subtitle: 'Specify custom object fields that should be updated in JSON below. ',
                  required: false,
                },
              ],
            };
          }

          return {
            inputs: hasOAuthAppCredential(parameters, credentials)
              ? [
                  { ...recordIdInput, ...{ subtitle: subtitleForRecordIdInput('update') } },
                  { ...recordPayLoadJson, ...{ id: 'updateRecordPayLoad', title: 'Record JSON' } },
                ]
              : [
                  { ...recordIdInput, ...{ subtitle: subtitleForRecordIdInput('update') } },
                  ...fieldsInput,
                  additionalFieldsInput,
                ],
            buttons: hasOAuthAppCredential(parameters, credentials)
              ? []
              : [
                  {
                    id: 'refreshFields',
                    type: SidebarInputType.ActionButton,
                    title: 'Refresh Fields',
                    style: ActionButtonStyle.DEFAULT,
                    source: getFields,
                    hidden: true,
                  },
                ],
          };
        case Intent.GET_RECORD_BY_ID:
          return {
            inputs: [
              ...(isCustomObjectRecordType ? [customObjectDynamicInput] : []),
              recordIdInput,
            ],
          };
        case Intent.DELETE_RECORD:
          return {
            inputs: [
              ...(isCustomObjectRecordType ? [customObjectDynamicInput] : []),
              { ...recordIdInput, ...{ subtitle: subtitleForRecordIdInput('delete') } },
            ],
          };
        case Intent.SEARCH_RECORDS:
          if (isEngagmentRecordType) {
            return { inputs: [engagementFilterInputConnect] };
          }
          if (isCustomObjectRecordType) {
            return {
              inputs: [
                customObjectDynamicInput,
                {
                  id: 'filterFormula',
                  title: 'Filter search',
                  type: SidebarInputType.Conditional,
                  subtitle: 'Search for Records that match specified filters.',
                  placeholder: 'field',
                  supportedOperators,
                  required: false,
                },
              ],
            };
          }

          return {
            inputs: hasOAuthAppCredential(parameters, credentials)
              ? [
                  {
                    id: 'filterFormula',
                    type: SidebarInputType.Conditional,
                    title: 'Filter search',
                    subtitle: 'Search for records that match specified filters.',
                    supportedOperators: supportedOperators,
                    required: false,
                  },
                  limitInput,
                ]
              : [
                  {
                    id: 'filterFormula',
                    type: SidebarInputType.DynamicConditional,
                    title: 'Filter search',
                    subtitle: 'Search for records that match specified filters.',
                    source: getFields,
                    getValues: getValuesByCacheKey,
                    supportedOperators: supportedOperators,
                    required: false,
                  },
                  limitInput,
                ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
export { authConfig };
