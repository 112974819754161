import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.AIRTABLE;
  sidebarSections: (SidebarSection | ((parameters: ActionStepParameters) => SidebarSection))[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'AIRTABLE_TEST_CONNECTION',
  FETCH_COLUMNS_FOR_BASE = 'AIRTABLE_FETCH_COLUMNS',
  FETCH_RECORDS = 'AIRTABLE_FETCH_RECORDS',
  CREATE_RECORD = 'AIRTABLE_CREATE_RECORD',
  UPDATE_RECORD = 'AIRTABLE_UPDATE_RECORD',
  FETCH_TABLES = 'AIRTABLE_FETCH_TABLES',
  FETCH_VIEWS = 'AIRTABLE_FETCH_VIEWS',
  FETCH_BASES = 'AIRTABLE_FETCH_BASES',
}

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FETCH_COLUMNS_FOR_BASE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FETCH_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FETCH_TABLES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FETCH_VIEWS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FETCH_BASES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  AIRTABLE_API_KEY: string;
  AIRTABLE_BASE_ID: string;
};
