import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.MAILCHIMP,
  provider: ProviderType.MAILCHIMP,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Mailchimp account',
  icon: iconSvg,
  description: 'Mailchimp',
  sidebarSections: [],
};

export const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Mailchimp account',
  placeholder: 'connect to Mailchimp',
  type: SidebarInputType.Auth,
  config: authConfig,
};

export default authConfig;
