import { Operator, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { InnerResolvedCondition } from '../../shared';

/*
 * @summary converts conditional input to filter formula for Sage Intacct
 * @param condition
 * @returns
 */
export default function conditionsToFilterSageIntacctExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    const conditions: string[] = condition.conditions.map((condition: ResolvedConditionWrapper) => {
      return conditionsToFilterSageIntacctExpression(condition);
    });
    const joinCondition: string = conditions.join('');

    if (condition.join === 'OR') {
      return conditions.length > 1 ? `<or> ${joinCondition} </or>` : joinCondition;
    }

    return conditions.length > 1 ? `<and> ${joinCondition} </and>` : joinCondition;
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: InnerResolvedCondition = condition;

    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `<equalto>
      <field>${innerCondition.variable}</field>
      <value>${innerCondition.argument}</value>
  </equalto>`;

      case Operator.StringDoesNotExactlyMatch:
        return `<notequalto>
      <field>${innerCondition.variable}</field>
      <value>${innerCondition.argument}</value>
  </notequalto>`;

      case Operator.StringLessThan:
        return `<lessthan>
      <field>${innerCondition.variable}</field>
      <value>${innerCondition.argument}</value>
  </lessthan>`;

      case Operator.NumberLessThanOrEqualTo:
        return `<lessthanorequalto>
      <field>${innerCondition.variable}</field>
      <value>${innerCondition.argument}</value>
  </lessthanorequalto>`;

      case Operator.StringGreaterThan:
        return `<greaterthan>
      <field>${innerCondition.variable}</field>
      <value>${innerCondition.argument}</value>
  </greaterthan>`;

      case Operator.NumberGreaterThanOrEqualTo:
        return `<greaterthanorequalto>
      <field>${innerCondition.variable}</field>
      <value>${innerCondition.argument}</value>
  </greaterthanorequalto>`;

      case Operator.IsNull:
        return `<isnull>
      <field>${innerCondition.variable}</field>
  </isnull>`;

      case Operator.IsNotNull:
        return `<isnotnull>
      <field>${innerCondition.variable}</field>
  </isnotnull>`;

      case Operator.StringContains:
        return `<like>
      <field>${innerCondition.variable}</field>
      <value>%${innerCondition.argument}%</value>
  </like>`;

      case Operator.StringDoesNotContain:
        return `<notlike>
      <field>${innerCondition.variable}</field>
      <value>%${innerCondition.argument}%</value>
  </notlike>`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Sage Intacct`);
    }
  }
  return '';
}
