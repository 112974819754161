import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.AMAZON_S3,
  name: 'Amazon S3',
  description:
    'Connect to your Amazon S3 account to manage your buckets, objects, and jobs in Amazon S3.',
  icon: iconSvg,
  provider: ProviderType.AMAZON_S3,
  sidebarSections: [],
};

export { authConfig };
export default connectConfig;
