import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

const escapeSingleQuote = (value?: string) => (value || '').replace("'", "''");

export default function conditionsToFilterExpression(condition: ResolvedConditionWrapper): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterExpression(condition);
      })
      .join(condition.join === 'OR' ? ' or ' : ' and ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      //string operators
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} eq '${escapeSingleQuote(innerCondition.argument)}'`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable} ne '${escapeSingleQuote(innerCondition.argument)}'`;
      case Operator.StringContains:
        return `contains(${innerCondition.variable},'${escapeSingleQuote(
          innerCondition.argument,
        )}')`;
      // number and date operators
      case Operator.NumberLessThan:
        return `${innerCondition.variable} lt ${innerCondition.argument}`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} lt '${new Date(innerCondition.argument).toISOString()}'`;
      case Operator.NumberEquals:
        return `${innerCondition.variable} eq ${innerCondition.argument}`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} eq '${new Date(innerCondition.argument).toISOString()}'`;
      case Operator.NumberGreaterThan:
        return `${innerCondition.variable} gt ${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} gt '${new Date(innerCondition.argument).toISOString()}'`;
      case Operator.NumberDoesNotEqual:
        return `${innerCondition.variable} ne ${innerCondition.argument}`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `${innerCondition.variable} ge ${innerCondition.argument}`;
      case Operator.NumberLessThanOrEqualTo:
        return `${innerCondition.variable} le ${innerCondition.argument}`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable} eq true`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} eq false`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Microsoft Dynamics`);
    }
  }
  return '';
}
