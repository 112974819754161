import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class WorkflowError extends ParagonError {
  name: string = 'WorkflowError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class WorkflowNotFoundFromIdError extends WorkflowError {
  name: string = 'WorkflowNotFoundFromIdError';

  constructor(meta: {
    workflowId: string;
    projectId?: string;
    teamId?: string;
    withDeleted?: boolean;
  }) {
    super({
      code: ERROR_CODE.WORKFLOW_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: `Workflow ${meta.workflowId} is not a valid workflow in this project.`,
      meta,
    });
  }
}

export class WorkflowMissingTriggerError extends WorkflowError {
  name: string = 'WorkflowMissingTriggerError';

  constructor(meta: { workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_MISSING_TRIGGER,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Workflow missing trigger step.',
      meta,
    });
  }
}

export class WorkflowOnlyHasTriggerError extends WorkflowError {
  name: string = 'WorkflowOnlyHasTriggerError';

  constructor(meta: { workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_ONLY_HAS_TRIGGER,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'You cannot deploy an empty workflow.',
      meta,
    });
  }
}

export class UnauthorizedWorkflowAccessError extends WorkflowError {
  name: string = 'UnauthorizedWorkflowAccessError';

  constructor(meta: { workflowId: string; projectId?: string; teamId?: string }) {
    super({
      code: ERROR_CODE.UNAUTHORIZED_WORKFLOW_ACCESS,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: "You don't have permission to view or edit this workflow.",
      meta,
    });
  }
}

export class WorkflowMissingIntegrationError extends WorkflowError {
  name: string = 'WorkflowMissingIntegrationError';

  constructor(meta: { workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_MISSING_INTEGRATION,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'This workflow has no integrations associated with it.',
      meta,
    });
  }
}

export class WorkflowMigrationNotFoundError extends WorkflowError {
  name: string = 'WorkflowMigrationNotFoundError';

  constructor(meta: { workflowMigrationId: string; workflowId?: string; projectId?: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_MIGRATION_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find workflow version.',
      meta,
    });
  }
}

export class WorkflowVersionNotFoundError extends WorkflowError {
  name: string = 'WorkflowVersionNotFoundError';

  constructor(meta: { workflowVersionId: string; workflowId?: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_VERSION_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find workflow version.',
      meta,
    });
  }
}

export class WorkflowStepNotFoundFromStepIdError extends WorkflowError {
  name: string = 'WorkflowStepNotFoundFromStepIdError';

  constructor(meta: { stepId: string; workflowId?: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_STEP_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find workflow step.',
      meta,
    });
  }
}

export class WorkflowStepMissingDescriptionError extends WorkflowError {
  name: string = 'WorkflowStepMissingDescriptionError';

  constructor(meta: { stepId: string; workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_STEP_MISSING_DESCRIPTION,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Workflow step missing `description`.',
      meta,
    });
  }
}

export class WorkflowStepMissingParametersError extends WorkflowError {
  name: string = 'WorkflowStepMissingParametersError';

  constructor(meta: { stepId: string; workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_STEP_MISSING_PARAMETERS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Workflow step missing `parameters`.',
      meta,
    });
  }
}

export class WorkflowStepMissingTypeError extends WorkflowError {
  name: string = 'WorkflowStepMissingTypeError';

  constructor(meta: { stepId: string; workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_STEP_MISSING_TYPE,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Workflow step missing `type`.',
      meta,
    });
  }
}

export class WorkflowStepMissingTargetParentStepIdError extends WorkflowError {
  name: string = 'WorkflowStepMissingTargetParentStepIdError';

  constructor(meta: { stepId: string; workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_STEP_MISSING_TARGET_PARENT_STEP_ID,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Workflow step missing target step id.',
      meta,
    });
  }
}

export class WorkflowStepInvalidConditionalChoiceError extends WorkflowError {
  name: string = 'WorkflowStepInvalidConditionalChoiceError';

  constructor(meta: { stepId: string; workflowId: string; choice: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_STEP_INVALID_CONDITIONAL_CHOICE,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Workflow step has invalid conditional choice.',
      meta,
    });
  }
}

export class WorkflowStepUnknownStepIdError extends WorkflowError {
  name: string = 'WorkflowStepUnknownStepIdError';

  constructor(meta: { stepId: string; workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_STEP_UNKNOWN_STEP_ID,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Failed to find step id in workflow.',
      meta,
    });
  }
}

export class WorkflowTagNotFoundFromIdError extends WorkflowError {
  name: string = 'WorkflowTagNotFoundFromIdError';

  constructor(meta: { workflowTagId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_TAG_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find workflow tag.',
      meta,
    });
  }
}

export class WorkflowMissingOwnerDataError extends WorkflowError {
  name: string = 'WorkflowMissingOwnerDataError';

  constructor(meta: { workflowId: string; ownerId?: string | null; projectId?: string | null }) {
    super({
      code: ERROR_CODE.WORKFLOW_OWNER_ID_NOT_FOUND,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Unable to find projectId/ownerId for workflow',
      meta,
    });
  }
}

export class WorkflowOauthTriggerFailureError extends WorkflowError {
  name: string = 'WorkflowOauthTriggerFailureError';

  constructor(meta: { workflowId: string; projectId?: string | null }) {
    super({
      code: ERROR_CODE.WORKFLOW_OAUTH_TRIGGER_FAILED_ERROR,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Unable to complete oauth request',
      meta,
    });
  }
}
