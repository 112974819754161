import conditionsToFormula from '@shared/integrations/airtable/backend/conditionsToFormula';
import conditionsToFilterAzureFormula from '@shared/integrations/azuredevops/backend/conditionToFilterAzureDevopsExpression';
import conditionToFilterCalendlyExpression from '@shared/integrations/calendly/backend/conditionToFilterCalendlyExpression';
import conditionToFilterClickUpExpression from '@shared/integrations/clickup/backend/conditionToFilterClickUpExpression';
import conditionToFilterDocuSignExpression from '@shared/integrations/docusign/backend/conditionToFilterDocuSignExpression';
import conditionToFilterDynamicsFinanceExpression from '@shared/integrations/dynamicsfinance/backend/conditionToFilterDynamicsFinanceExpression';
import conditionsToFilterExpression from '@shared/integrations/dynamodb/backend/conditionsToFilterExpression';
import conditionsToFilterEloquaExpression from '@shared/integrations/eloqua/backend/conditionsToFilterEloquaExpression';
import conditionToFilterGitHubExpression from '@shared/integrations/github/backend/conditionToFilterGitHubExpression';
import conditionsToGmailFilterFormula from '@shared/integrations/gmail/backend/conditionsToFilterGmailFormula';
import { default as conditionsToCampaignManagerFilterFormula } from '@shared/integrations/googlecampaignmanager/backend/conditionToFilterExpression';
import conditionsToGoogleSheetFormula from '@shared/integrations/googlesheets/backend/conditionsToGoogleSheetFormula';
import conditionsToHubspotFilterFormula from '@shared/integrations/hubspot/backend/conditionsToFilterFormula';
import conditionsToIntercomFilterFormula from '@shared/integrations/intercom/backend/conditionsToFilterFormula';
import conditionsToJQL from '@shared/integrations/jira/backend/conditionsToJQL';
import conditionsToFilterLinearFormula from '@shared/integrations/linear/backend/conditionsToFilterLinearFormula';
import conditionToFilterMagentoExpression from '@shared/integrations/magento/backend/conditionToFilterAdobeCommerceExpression';
import conditionsToFilterMailchimpExpression from '@shared/integrations/mailchimp/backend/conditionToFilterMailchimpExpression';
import { default as conditionsToMarketoFilterFormula } from '@shared/integrations/marketo/backend/conditionToFilterExpression';
import conditionsToMicrosoftDynamicsFilterFormula from '@shared/integrations/microsoftDynamics/backend/conditionToFilterExpression';
import conditionsToQuery from '@shared/integrations/mongodb/backend/conditionsToQuery';
import conditionsToMySql from '@shared/integrations/mysql/backend/conditionsToMySql';
import conditionsToFilterNetSuiteExpression from '@shared/integrations/netsuite/backend/conditionToFilterNetsuiteExpression';
import conditionsToFilterOracleExpression from '@shared/integrations/oraclefinancialscloud/backend/conditionToFilterExpression';
import conditionsToFilterOutlookExpression from '@shared/integrations/outlook/backend/conditionToFilterOutlookExpression';
import conditionsToOutreachFilterFormula from '@shared/integrations/outreach/backend/conditionsToOutreachFilterFormula';
import conditionsToPardotFormula from '@shared/integrations/pardot/backend/conditionsToFilterExpressionPardot';
import conditionsToPipedriveFormula from '@shared/integrations/pipedrive/backend/conditionToFilterPipedriveExpression';
import conditionsToSql from '@shared/integrations/postgres/backend/conditionsToSql';
import conditionsToFilterExpressionQuickbooks from '@shared/integrations/quickbooks/backend/conditionsToFilterExpressionQuickbooks';
import conditionsToFilterSageIntacctExpression from '@shared/integrations/sageintacct/backend/conditionsToFilterSageIntacctExpression';
import conditionsToSOQL from '@shared/integrations/salesforce/backend/conditionsToSOQL';
import conditionToFilterSapS4HanaExpression from '@shared/integrations/saps4hana/backend/conditionToFilterSapHanaExpression';
import conditionToFilterSharePointExpression from '@shared/integrations/sharepoint/backend/conditionToFilterSharePointExpression';
import conditionsToShopifyFilterFormula from '@shared/integrations/shopify/backend/conditionsToFilterFormula';
import conditionsToFilterWoocommerceFormula from '@shared/integrations/woocommerce/backend/conditionToFilterWooCommerceExpression';
import conditionsToFilterXeroExpression from '@shared/integrations/xero/backend/conditionToFilterXeroExpression';
import conditionsToCOQL from '@shared/integrations/zohocrm/backend/conditionsToCOQL';
import { Action } from '@shared/types/sdk/actions';
import { ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * A ConditionSerializer is an action-specific type that transforms a Paragon representation for a
 * condition (as a ResolvedConditionWrapper) and returns a vendor-specific representation for that
 * conditional (i.e., from a ResolvedConditionWrapper -> SQL query, in the case of a Postgres
 * action).
 */
export type ConditionSerializer<T = any> = (condition: ResolvedConditionWrapper, key: string) => T;

const conditionSerializers: Partial<{ [type in Action]: ConditionSerializer }> = {
  [Action.ELOQUA]: conditionsToFilterEloquaExpression,
  [Action.AIRTABLE]: conditionsToFormula,
  [Action.MONGODB]: conditionsToQuery,
  [Action.MYSQL]: conditionsToMySql,
  [Action.POSTGRES]: conditionsToSql,
  [Action.GOOGLE_SHEETS]: conditionsToGoogleSheetFormula,
  [Action.SALESFORCE]: conditionsToSOQL,
  [Action.DYNAMODB]: conditionsToFilterExpression,
  [Action.QUICKBOOKS]: conditionsToFilterExpressionQuickbooks,
  [Action.SHOPIFY]: conditionsToShopifyFilterFormula,
  [Action.JIRA]: conditionsToJQL,
  [Action.HUBSPOT]: conditionsToHubspotFilterFormula,
  [Action.INTERCOM]: conditionsToIntercomFilterFormula,
  [Action.MARKETO]: conditionsToMarketoFilterFormula,
  [Action.MICROSOFT_DYNAMICS]: conditionsToMicrosoftDynamicsFilterFormula,
  [Action.PARDOT]: conditionsToPardotFormula,
  [Action.XERO]: conditionsToFilterXeroExpression,
  [Action.GOOGLE_CAMPAIGN_MANAGER]: conditionsToCampaignManagerFilterFormula,
  [Action.OUTREACH]: conditionsToOutreachFilterFormula,
  [Action.MAILCHIMP]: conditionsToFilterMailchimpExpression,
  [Action.ORACLE_FINANCIALS_CLOUD]: conditionsToFilterOracleExpression,
  [Action.AZURE_DEVOPS]: conditionsToFilterAzureFormula,
  [Action.CLICKUP]: conditionToFilterClickUpExpression,
  [Action.GITHUB]: conditionToFilterGitHubExpression,
  [Action.SHAREPOINT]: conditionToFilterSharePointExpression,
  [Action.OUTLOOK]: conditionsToFilterOutlookExpression,
  [Action.PIPEDRIVE]: conditionsToPipedriveFormula,
  [Action.SAGE_INTACCT]: conditionsToFilterSageIntacctExpression,
  [Action.NETSUITE]: conditionsToFilterNetSuiteExpression,
  [Action.DYNAMICS_BUSINESS_CENTRAL]: conditionsToMicrosoftDynamicsFilterFormula,
  [Action.DYNAMICS_365_FINANCE]: conditionToFilterDynamicsFinanceExpression,
  [Action.LINEAR]: conditionsToFilterLinearFormula,
  [Action.GMAIL]: conditionsToGmailFilterFormula,
  [Action.MAGENTO]: conditionToFilterMagentoExpression,
  [Action.CALENDLY]: conditionToFilterCalendlyExpression,
  [Action.WOOCOMMERCE]: conditionsToFilterWoocommerceFormula,
  [Action.ZOHO_CRM]: conditionsToCOQL,
  [Action.DOCUSIGN]: conditionToFilterDocuSignExpression,
  [Action.SAP_S4HANA]: conditionToFilterSapS4HanaExpression,
};

export default conditionSerializers;
