import { IBaseEntity } from '../base.interface';
import { IOrganization } from '../organization';
import { ITeam, ITeamMember, TeamMemberRole } from '../team/team.interface';

export enum OnboardingStage {
  ADD_TEAM_DETAILS = 'ADD_TEAM_DETAILS',
  PROMPT_TUTORIAL = 'PROMPT_TUTORIAL',
  IN_TUTORIAL = 'IN_TUTORIAL',
  COMPLETED = 'COMPLETED',
  COMPLETED_SKIPPED_TUTORIAL = 'COMPLETED_SKIPPED_TUTORIAL',
}

export const PASSWORD_VALIDATION_REGEX =
  /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]^_{|}~])(?=.{8,}).*)$/;

export interface IUser extends IBaseEntity {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  password: string;
  active: boolean;
  teamMembers: ITeamMember[];
  onboardingStage: OnboardingStage;
}

export interface IUserSanitized extends Omit<IUser, 'password'> {
  impersonated?: boolean;
}

export interface ILoggedInUser extends Omit<IUserSanitized, 'teamMembers'> {
  teams: Record<string, TeamMemberRole>;
}

export interface IUserWithJWTParams extends IUserSanitized {
  iat: number;
  exp: number;
}

export interface IUserWithAccessToken {
  user: IUserSanitized;
  accessToken: string;
}

export interface IUserWithOrganization extends IUserWithAccessToken {
  organization: IOrganization;
  team: ITeam;
}
