import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'admin';

export const scopes: Record<ScopeName, ScopeValue> = {
  admin: {
    label: 'admin',
    name: 'admin',
    required: true,
    description: 'Grant full access',
  },
};

export const requiredScopes: ScopeName[] = ['admin'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'iManage',
  description: 'Sync files and documents with iManage',
  authUrl: 'https://cloudimanage.com/auth/oauth2/authorize',
  accessTokenUrl: 'https://cloudimanage.com/auth/oauth2/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/imanage`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/imanage',
};
