import axios, { AxiosError, AxiosInstance } from 'axios';

import { generateQueryStringFromObject } from '@shared/actions/sdk/utils';
import { Operator } from '@shared/types/sdk/resolvers';

import { Credentials } from '../configs';

import {
  AZURE_DEVOPS_AUTH_URL,
  Environment,
  RefreshTokenRequestResponse,
  TokenResponse,
  UpdatedCredentials,
} from '.';

/**
 *
 * @param error
 * @param msg
 * @returns
 */
export const getFormattedErrorMessage = (error: AxiosError, msg: string = ''): string => {
  return `Azure DevOps API Error: ${msg} ${
    error.response ? JSON.stringify(error.response?.data) : error
  }`;
};

/**
 *
 * @summary return basic axios instance
 * @param baseUrl - domain url
 * @param headers - headers to pass
 */
export function getAxiosInstance(baseUrl: string, headers: Record<string, string>): AxiosInstance {
  return axios.create({
    baseURL: baseUrl,
    headers: headers,
  });
}

export function getAzureDevOpsClient(
  url: string,
  accessToken: string,
  isPersonalAccessToken: boolean,
): AxiosInstance {
  return getAxiosInstance(url, {
    Authorization: isPersonalAccessToken
      ? `Basic ${Buffer.from(':' + accessToken).toString('base64')}`
      : `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  });
}

export const isAccessTokenValid = async (credentials: Credentials): Promise<boolean> => {
  try {
    const response = await getAxiosInstance(`${AZURE_DEVOPS_AUTH_URL}`, {
      Authorization: `Bearer ${credentials.AZURE_DEVOPS_ACCESS_TOKEN}`,
      Accept: 'application/json',
    }).get('/_apis/profile/profiles/me?api-version=5.0');
    return response.status !== 200 ? false : true;
  } catch (_error) {
    return false;
  }
};

/**
 * return newly generated access token for azure devops generated from refresh token.
 * @param credentials
 * @param environment
 */
export async function getAccessTokenFromRefreshToken(
  credentials: Credentials,
  environment: Environment,
): Promise<TokenResponse> {
  if (await isAccessTokenValid(credentials)) {
    return {
      accessToken: credentials.AZURE_DEVOPS_ACCESS_TOKEN,
      refreshToken: credentials.AZURE_DEVOPS_REFRESH_TOKEN as string,
      tokenUpdated: false,
    };
  }

  try {
    const response = await getAxiosInstance(`${AZURE_DEVOPS_AUTH_URL}`, {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    }).post<RefreshTokenRequestResponse>(
      `/oauth2/token`,
      generateQueryStringFromObject(
        {
          client_assertion_type: 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
          client_assertion: environment.AZURE_DEVOPS_CLIENT_SECRET,
          grant_type: 'refresh_token',
          assertion: credentials.AZURE_DEVOPS_REFRESH_TOKEN,
          redirect_uri: credentials.redirectUrl,
        },
        { urlencodedValues: true, addPrefix: false },
      ),
    );

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      tokenUpdated:
        credentials.AZURE_DEVOPS_ACCESS_TOKEN !== response.data.access_token ||
        credentials.AZURE_DEVOPS_REFRESH_TOKEN !== response.data.refresh_token,
    };
  } catch (err) {
    throw getFormattedErrorMessage(err);
  }
}

export const getRequestEssentials = async (credentials: Credentials, environment: Environment) => {
  let apiUrl = credentials.AZURE_DEVOPS_SERVER_URL as string;
  let accessToken = credentials.AZURE_DEVOPS_ACCESS_TOKEN;
  let updateCredentialValues: UpdatedCredentials | undefined;
  if (credentials.organizationId) {
    apiUrl = `https://dev.azure.com/${credentials.organizationId}`;
    const tokenResponse = await getAccessTokenFromRefreshToken(credentials, environment);
    if (tokenResponse.tokenUpdated) {
      accessToken = tokenResponse.accessToken;
      updateCredentialValues = {
        accessToken: tokenResponse.accessToken,
        refreshToken: tokenResponse.refreshToken,
      };
    }
  }
  return {
    apiUrl,
    accessToken,
    updateCredentialValues,
    isPersonalAccessToken: !!credentials.AZURE_DEVOPS_SERVER_URL,
  };
};

export const azureDevopsSupportedOperators: Operator[] = [
  Operator.NumberLessThan,
  Operator.NumberEquals,
  Operator.NumberGreaterThan,
  Operator.NumberDoesNotEqual,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberLessThanOrEqualTo,
  Operator.StringIsIn,
  Operator.StringIsNotIn,
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.StringContains,
  Operator.StringDoesNotContain,
  Operator.BooleanTrue,
  Operator.BooleanFalse,
];

export const inputFieldMap = {
  area: 'System.AreaPath',
  assignee: 'System.AssignedTo',
  description: 'System.Description',
  iteration: 'System.IterationPath',
  reason: 'System.Reason',
  state: 'System.State',
  title: 'System.Title',
  activity: 'Microsoft.VSTS.Common.Activity',
  businessValue: 'Microsoft.VSTS.Common.BusinessValue',
  priority: 'Microsoft.VSTS.Common.Priority',
  resolvedReason: 'Microsoft.VSTS.Common.ResolvedReason',
  risk: 'Microsoft.VSTS.Common.Risk',
  severity: 'Microsoft.VSTS.Common.Severity',
  timeCriticality: 'Microsoft.VSTS.Common.TimeCriticality',
  effort: 'Microsoft.VSTS.Scheduling.Effort',
  startDate: 'Microsoft.VSTS.Scheduling.StartDate',
  storyPoints: 'Microsoft.VSTS.Scheduling.StoryPoints',
  targetDate: 'Microsoft.VSTS.Scheduling.TargetDate',
  reproSteps: 'Microsoft.VSTS.TCM.ReproSteps',
  systemInfo: 'Microsoft.VSTS.TCM.SystemInfo',
};
