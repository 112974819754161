import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig } from '@shared/types/sdk/actions';

import iconSvg from '../configs/icon.svg';

import { companyKeyInput, companySecretInput } from './common';

const authConfig: ActionConfig = {
  actionType: Action.SAILTHRU,
  provider: ProviderType.SAILTHRU,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Sailthru account',
  icon: iconSvg,
  description: 'Sailthru',
  sidebarSections: [
    {
      inputs: [companyKeyInput, companySecretInput],
      buttons: [],
    },
  ],
  hideOAuthApps: true,
};

export default authConfig;
