import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { StepValidationError } from '@shared/types/sdk/errors';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { WorkflowVariables } from '@shared/types/sdk/resolvers';
import { Step } from '@shared/types/sdk/steps';

import { StepResolver } from '../resolvers/abstract.resolver';
import { STEP_RESOLVER_MAP } from '../resolvers/resolvers.utils';

/**
 * a validator for a step
 *
 * @export
 * @abstract
 * @class StepValidator
 * @template S
 */
export abstract class StepValidator<S extends Step, O> {
  /**
   * the type of step the action is supposed to validate
   *
   * @abstract
   * @returns {StepType}
   * @memberof StepValidator
   */
  abstract get stepType(): S['type'];

  get resolver(): StepResolver<Step, O> {
    return STEP_RESOLVER_MAP[this.stepType]();
  }

  /**
   * resolves the variables for a step
   *
   * @param {Step} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {O}
   * @memberof StepValidator
   */
  resolveVariables(
    step: Step,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): O {
    return this.resolver.resolveInputParametersFromStep(
      step,
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
    );
  }

  /**
   * resolves the variables in a step and validates its configuration
   *
   * @param {S} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {S}
   * @memberof StepValidator
   */
  resolveAndValidate(
    step: S,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): S {
    let errorMessage: string | undefined;

    try {
      if (step.type !== this.stepType) {
        throw new Error(`Current step is not ${this.stepType}`);
      }

      this.validate(step, secrets, cachedConnectCredential, variables, context, cachedPersona);
    } catch (e) {
      errorMessage = e.message;
    }

    if (errorMessage) {
      throw new StepValidationError({
        stepId: step.id,
        message: errorMessage,
      });
    }

    return step;
  }

  /**
   * validates that a step is valid
   *
   * @abstract
   * @param {Step} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {S}
   * @memberof StepValidator
   */
  abstract validate(
    step: S,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): S;
}
