import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

type ScopeName = 'read' | 'write' | 'issues:create' | 'comments:create';

export const requiredScopes: ScopeName[] = ['read', 'write', 'issues:create', 'comments:create'];

export const scopes: Record<ScopeName, ScopeValue> = {
  read: {
    name: 'read',
    description: `(Default) Read access for the user's account. This scope will always be present.`,
  },
  write: {
    name: 'write',
    description: `Write access for the user's account.`,
  },
  'issues:create': {
    name: 'issues:create',
    description: `Allows creating new issues and their attachments.`,
  },
  'comments:create': {
    name: 'comments:create',
    description: `Allows creating new issue comments`,
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Linear',
  description: 'Connect to your user’s Linear accounts',
  authUrl: 'https://linear.app/oauth/authorize',
  accessTokenUrl: 'https://api.linear.app/oauth/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/linear`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink:
    'https://developers.linear.app/docs/oauth/authentication#2.-redirect-user-access-requests-to-linear',
};
