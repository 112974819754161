import { Dispatch } from 'redux';

import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { debug, warn } from '@shared/logger/sdk/legacy';
import { ActionIntent, Action as ActionType } from '@shared/types/sdk/actions';
import { ActionTriggerOutput } from '@shared/types/sdk/execution';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';
import { Step } from '@shared/types/sdk/steps';

import { Zeus } from '../../services/zeus';
import { getErrorMessage } from '../../utils';
import { getToken } from '../../utils/auth';
import { Action, State } from '../types';

export const execute =
  (
    workflowId: string,
    step: Step,
    action: ActionType,
    intent: ActionIntent,
    parameters: KeyedSource<DataType.ANY>[],
    credentials: string[],
  ) =>
  async (dispatch: Dispatch<Action>, getState: () => State) => {
    const payload = {
      workflowId,
      step,
      action,
      intent,
      parameters,
      credentials,
    };

    debug('action > execute', payload);
    dispatch({ type: 'EXECUTE_ACTION_START', payload });

    const token: string = getToken();
    const api = new Zeus(token);

    try {
      const entities = getState().entities;
      const workflow = entities.workflows.entities[workflowId];
      const ownerId: string = entities.projects.entities[workflow.projectId].ownerId;

      const response = await api.triggerAction(
        action,
        credentials,
        intent,
        parameters,
        workflowId,
        ownerId,
      );

      if (response.ok) {
        const json = await response.json();
        debug('action > execute > success', json);
        dispatch({ type: 'EXECUTE_ACTION_SUCCESS', payload: json });
        return json;
      } else {
        warn('action > execute > failed', {
          text: await response.text(),
          status: response.status,
        });
        const message: string | undefined = await getErrorMessage(response);
        dispatch({ type: 'EXECUTE_ACTION_FAILURE', message });
        throw new Error(message || 'Something went wrong.');
      }
    } catch (e) {
      dispatch({ type: 'EXECUTE_ACTION_FAILURE', message: e.message });
    }
  };

export const getActionTriggerInputs =
  (
    workflowId: string,
    action: ActionType,
    intent: ActionIntent,
    parameters: KeyedSource<DataType.ANY>[],
    credentials: string[],
    isConnectWorkflow?: boolean,
    integrationId?: string,
    cachedConnectCredential?: CachedConnectCredential,
    credentialId?: string,
  ) =>
  async (dispatch: Dispatch<Action>, getState: () => State) => {
    const token: string = getToken();
    const api = new Zeus(token);

    dispatch({ type: 'FETCH_ACTION_TRIGGER_INPUTS_START' });

    try {
      const entities = getState().entities;
      const workflow = entities.workflows.entities[workflowId];
      const ownerId: string = entities.projects.entities[workflow.projectId].ownerId;
      const cachedPersona: CachedPersona = entities.personas.previewPersona ?? {
        meta: null,
      };

      const response = await api.getActionTriggerInputs(
        action,
        credentials,
        intent,
        parameters,
        workflowId,
        ownerId,
        isConnectWorkflow,
        integrationId,
        cachedConnectCredential,
        credentialId,
        cachedPersona,
      );

      if (response.ok) {
        const inputs: ActionTriggerOutput[] = await response.json();

        dispatch({ type: 'FETCH_ACTION_TRIGGER_INPUTS_SUCCESS', data: inputs, workflowId });
      } else {
        dispatch({
          type: 'FETCH_ACTION_TRIGGER_INPUTS_FAILURE',
          message: await getErrorMessage(response),
        });
      }
    } catch (e) {
      dispatch({ type: 'FETCH_ACTION_TRIGGER_INPUTS_FAILURE', message: e.message });
    }
  };
