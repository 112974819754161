import React from 'react';

import {
  IntegrationConnectInput,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const viewInput: SidebarInput = {
  id: 'view',
  title: 'View',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: '{{settings.view}}',
  subtitle:
    'The view to run a report on. Use Connect Portal User Settings to allow users to select a Google Analytics View.',
};

export const webPropertyInput: SidebarInput = {
  id: 'webPropertyId',
  title: 'Property',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: '{{settings.property}}',
  subtitle:
    'Property to fetch list of custom dimensions for. Use Connect Portal User Settings to allow users to select a Google Analytics Property.',
};

export const postOAuthAccountIdInput: IntegrationConnectInput = {
  id: 'accountId',
  title: 'Choose a Google Analytics account to use',
  placeholder: 'Account',
  type: SidebarInputType.DynamicEnum,
  required: true,
  sourceType: 'userProfiles',
  fallbackText:
    'User does not have any Google Analytics account or if you are using your own oauth app please make sure you have enabled "Google Analytics API" and "Google Analytics Reporting API"',
};

export const metricInput: SidebarInput = {
  id: 'metrics',
  title: 'Metric',
  type: SidebarInputType.EditableEnum,
  required: true,
  subtitle: (
    <>
      The quantitative measurement for this report.{' '}
      <a
        href="https://support.google.com/analytics/answer/9143382?hl=en&ref_topic=11151952"
        target="_blank"
        rel="noreferrer"
      >
        See the docs
      </a>{' '}
      for more on metric expressions.
    </>
  ),
  getValues: () => [
    { label: 'Users', value: 'ga:users' },
    { label: 'New Users', value: 'ga:newUsers' },
    { label: '% New Sessions ', value: 'ga:percentNewSessions' },
    { label: '1 Day Active Users', value: 'ga:1dayUsers' },
    { label: '7 Day Active Users', value: 'ga:7dayUsers' },
    { label: 'Sessions', value: 'ga:sessions' },
    { label: 'Bounces', value: 'ga:bounces' },
    { label: 'Bounce Rate', value: 'ga:bounceRate' },
    { label: 'Session Duration ', value: 'ga:sessionDuration' },
    { label: 'Hits', value: 'ga:hits' },
    { label: 'Organic Searches', value: 'ga:organicSearches' },
    { label: 'Engagement Rate', value: 'ga:engagementRate' },
    { label: 'Goal Completions', value: 'ga:goalCompletionsAll' },
  ],
};

export const realTimeMetricInput: SidebarInput = {
  id: 'metrics',
  title: 'Metric',
  type: SidebarInputType.EditableEnum,
  required: true,
  subtitle: 'The metrics to receive in realtime.',
  enableMultiSelect: true,
  getValues: () => [
    { label: 'Active Users', value: 'rt:activeUsers' },
    { label: 'Page Views', value: 'rt:pageviews' },
    { label: 'Value for All Goals', value: 'rt:goalValueAll' },
    { label: 'Completions for All Goals', value: 'rt:goalCompletionsAll' },
  ],
};

export const startDateInput: SidebarInput = {
  id: 'startDate',
  title: 'Start Date',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '2022-01-31',
  subtitle: 'If not provided, report results will begin from 7 days ago.',
};

export const endDateInput: SidebarInput = {
  id: 'endDate',
  title: 'End Date',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '2022-02-01',
  subtitle: 'If not provided, report results will end from yesterday’s date.',
};

export const dimensionsInput: SidebarInput = {
  id: 'dimensions',
  title: 'Dimensions',
  type: SidebarInputType.TextArea,
  lines: 4,
  required: false,
  placeholder: '[{ "name": "ga:city" }]',
  subtitle: (
    <>
      Characteristics about sessions or users to include in the report.{' '}
      <a
        href="https://support.google.com/analytics/answer/9143382?hl=en&ref_topic=11151952"
        target="_blank"
        rel="noreferrer"
      >
        See the docs
      </a>{' '}
      for more on dimensions.
    </>
  ),
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  title: 'Additional Query JSON',
  type: SidebarInputType.Code,
  lines: 5,
  required: false,
  language: 'json',
  useLightTheme: true,
  placeholder: `{
  "dimensionFilterClauses": [],
  "metricFilterClauses": [],
  "filtersExpression": "",
  "orderBys": [],
  "segments": [],
  "pivots": [],
  "cohortGroup": {
    "cohorts": [],
    "lifetimeValue": true
  },
}`,
  subtitle: (
    <>
      Include additional parameters for the report query as JSON.{' '}
      <a
        href="https://support.google.com/analytics/answer/9143382?hl=en&ref_topic=11151952"
        target="_blank"
        rel="noreferrer"
      >
        See the docs
      </a>{' '}
      for the full reference on available options.
    </>
  ),
};

export const intentSelector: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Reports',
          items: [
            { label: 'Run Report', value: Intent.RUN_REPORT },
            { label: 'Get Realtime Data', value: Intent.GET_REALTIME_DATA },
          ],
        },
        {
          title: 'Configuration',
          items: [
            { label: 'Get Custom Metrics', value: Intent.GET_CUSTOM_METRICS },
            { label: 'Get Custom Dimensions', value: Intent.GET_CUSTOM_DIMENSIONS },
          ],
        },
      ],
    },
  ],
};
