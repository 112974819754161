import { getArrayOfStringsFromCommaSeparatedList } from '@shared/actions/sdk/utils';
import { Operator, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { InnerResolvedCondition, ResponseObjectType } from '../../shared/types';

export enum FilterOverrideField {
  PROJECT = 'project',
}

/**
 * Override the condition, if they have more depth property for FilterOverrideField
 * @param variable key matched to be FilterOverrideField
 * @param resolvedOperatorQuery query that generated with filter formula
 * @returns Object that have depth condition
 */
export const wrapOperatorQueryBasedOnVariable = (
  variable: string,
  resolvedOperatorQuery: ResponseObjectType,
): ResponseObjectType => {
  if (variable === FilterOverrideField.PROJECT) {
    return {
      [FilterOverrideField.PROJECT]: {
        id: resolvedOperatorQuery[FilterOverrideField.PROJECT],
      },
    };
  } else {
    return resolvedOperatorQuery;
  }
};

/**
 * Operators object
 */
const operatorToLinearOperatorMap: Record<string, string> = {
  [Operator.StringExactlyMatches]: 'eq',
  [Operator.StringDoesNotExactlyMatch]: 'neq',
  [Operator.StringIsIn]: 'in',
  [Operator.StringIsNotIn]: 'nin',
  [Operator.StringStartsWith]: 'startsWith',
  [Operator.StringDoesNotStartWith]: 'notStartsWith',
  [Operator.StringEndsWith]: 'endsWith',
  [Operator.StringDoesNotEndWith]: 'notEndsWith',
  [Operator.StringContains]: 'contains',
  [Operator.StringDoesNotContain]: 'notContains',
  [Operator.StringGreaterThan]: 'gt',
  [Operator.StringLessThan]: 'lt',
  [Operator.NumberGreaterThanOrEqualTo]: 'gte',
  [Operator.NumberLessThanOrEqualTo]: 'lte',
  [Operator.NumberGreaterThan]: 'gt',
  [Operator.NumberLessThan]: 'lt',
  [Operator.DateTimeAfter]: 'gt',
  [Operator.DateTimeBefore]: 'lt',
};

/*
 * @summary converts conditional input to filter formula for Linear integration
 * @param condition
 * @returns
 */
export default function conditionsToFilterLinearFormula(
  condition: ResolvedConditionWrapper,
): ResponseObjectType | undefined {
  if (condition.type === 'JOIN') {
    const conditions: (ResponseObjectType | undefined)[] = condition.conditions.map(
      (condition: ResolvedConditionWrapper) => {
        return conditionsToFilterLinearFormula(condition);
      },
    );

    return condition.join === 'OR' ? { or: conditions } : { and: conditions };
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: InnerResolvedCondition = condition;
    let resolvedOperatorQuery: ResponseObjectType | undefined;

    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
      case Operator.StringDoesNotExactlyMatch:
      case Operator.StringStartsWith:
      case Operator.StringDoesNotStartWith:
      case Operator.StringEndsWith:
      case Operator.StringDoesNotEndWith:
      case Operator.StringContains:
      case Operator.StringDoesNotContain:
      case Operator.StringGreaterThan:
      case Operator.StringLessThan:
      case Operator.NumberGreaterThanOrEqualTo:
      case Operator.NumberLessThanOrEqualTo:
      case Operator.NumberGreaterThan:
      case Operator.NumberLessThan:
      case Operator.DateTimeAfter:
      case Operator.DateTimeBefore:
        resolvedOperatorQuery = {
          [innerCondition.variable]: {
            [operatorToLinearOperatorMap[innerCondition.operator]]: innerCondition.argument,
          },
        };
        break;

      case Operator.StringIsIn:
      case Operator.StringIsNotIn:
        resolvedOperatorQuery = {
          [innerCondition.variable]: {
            [operatorToLinearOperatorMap[innerCondition.operator]]:
              getArrayOfStringsFromCommaSeparatedList(innerCondition.argument),
          },
        };
        break;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Linear integration`);
    }

    return wrapOperatorQueryBasedOnVariable(innerCondition.variable, resolvedOperatorQuery);
  }

  return undefined;
}
