import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MICROSOFT_TEAMS;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'TEAMS_TEST_CONNECTION',
  SEND_MESSAGE_IN_CHANNEL = 'TEAMS_SEND_MESSAGE_IN_CHANNEL',
  SEND_MESSAGE_IN_CHAT = 'TEAMS_SEND_MESSAGE_IN_CHAT',
  CHANNEL_LIST = 'TEAMS_CHANNEL_LIST',
  MEMBER_LIST = 'TEAMS_MEMBER_LIST',
  TEAM_LIST = 'TEAMS_JOINED_TEAM_LIST',
  GET_USER_BY_EMAIL = 'TEAMS_GET_USER_BY_EMAIL',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_MESSAGE_IN_CHAT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_MESSAGE_IN_CHANNEL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEAM_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_USER_BY_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CHANNEL_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.MEMBER_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  MICROSOFT_TEAMS_ACCESS_TOKEN: string;
  MICROSOFT_TEAMS_REFRESH_TOKEN: string;
  MICROSOFT_TEAMS_BOT_TOKEN: string;
  SERVICE_URL_REGION: string;
  SCOPES?: string;
  USER_ID?: string;
};

export const MICROSOFT_AUTH_URL = 'https://login.microsoftonline.com';
