import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * Combine the conditions
 * @param condition
 * @returns
 */
export default function conditionToFilterDocuSignExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionToFilterDocuSignExpression(condition);
      })
      .join('&');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    if (innerCondition.operator === Operator.StringExactlyMatches) {
      return `${innerCondition.variable}=${innerCondition.argument}`;
    }

    throw new Error(`${innerCondition.operator} operator not supported for DocuSign.`);
  }
  return '';
}
