import { plainToClass } from 'class-transformer';
import { Dispatch } from 'redux';

import { CloneableWorkflowDependency } from '@shared/entities/sdk';
import { debug, error as logError } from '@shared/logger/sdk/legacy';
import { Step } from '@shared/types/sdk/steps';
import { getTriggerStep } from '@shared/workflow/sdk/workflow.utils';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { Action, IntegrationConfigEntity, State, WorkFlow, WorkflowMigration } from '../../types';

/**
 * connect portal config workflow metadata info text
 */
const DEFAULT_CONNECT_WORKFLOW_INFO_TEXT = 'Add a user-facing description of this workflow';

export const getWorkFlows =
  (projectId: string, includeDeleted: boolean = false, integrationId?: string) =>
  async (dispatch: Dispatch<Action>): Promise<[WorkFlow[] | undefined, string | undefined]> => {
    dispatch({ type: 'WORKFLOW_ENTITY_FETCH_START' });

    const response = await api.get(
      `/projects/${projectId}/workflows?includeDeleted=${includeDeleted}${
        integrationId ? `&integrationId=${integrationId}` : ''
      }`,
    );
    debug('got workflows from api', response);
    if (response.ok) {
      let workflows: WorkFlow[] = await response.json();

      workflows = workflows
        .map((workflow: WorkFlow) => {
          const triggerStep = getTriggerStep(workflow.steps);
          if (!triggerStep) {
            return undefined;
          }
          return {
            ...workflow,
            steps: [triggerStep],
          };
        })
        .filter(
          (workflowOrUndefined: WorkFlow | undefined): workflowOrUndefined is WorkFlow =>
            workflowOrUndefined !== undefined,
        );

      dispatch({
        type: 'WORKFLOW_ENTITY_FETCH_SUCCESS',
        workflows,
      });

      return [workflows, undefined];
    } else {
      const message: string = (await getErrorMessage(response)) as string;
      dispatch({
        type: 'WORKFLOW_ENTITY_FETCH_FAILED',
        message,
      });
      return [undefined, message];
    }
  };

export const getWorkflowById =
  (projectId: string, workflowId: string) =>
  async (dispatch: Dispatch<Action>): Promise<[WorkFlow | undefined, string | undefined]> => {
    dispatch({ type: 'GET_ONE_WORKFLOW_REQUEST' });

    const response = await api.get(`/projects/${projectId}/workflows/${workflowId}`);
    debug('getWorkflowById > response.ok', response.ok);

    if (response.ok) {
      const workflow: WorkFlow = await response.json();
      debug('getWorkflowById > workflow', workflow);
      dispatch({ type: 'GET_ONE_WORKFLOW_SUCCESS', payload: workflow });
      return [workflow, undefined];
    } else {
      const message: string = (await getErrorMessage(response))!;
      dispatch({
        type: 'GET_ONE_WORKFLOW_FAILURE',
        message,
      });

      return [undefined, message];
    }
  };

export const createWorkflow =
  (
    projectId: string,
    description: string = 'My new workflow',
    integrationId: string | null = null,
  ) =>
  async (dispatch: Dispatch<Action>, getState: () => State) => {
    dispatch({ type: 'WORKFLOW_ENTITY_CREATION_STARTED' });
    const response = await api.post(`/projects/${projectId}/workflows`, {
      description,
      integrationId,
    });

    if (response.ok) {
      const workflow: WorkFlow = await response.json();
      dispatch({
        type: 'WORKFLOW_ENTITY_CREATION_SUCCESS',
        payload: workflow,
      });
      // dispatch this so that redux has a state where it knows which workflow has been created recently
      dispatch({
        type: 'SET_WORKFLOW_ENTITY_CREATED_WORKFLOW_ID',
        workflowId: workflow.id,
      });
      dispatch({
        type: 'SET_WORKFLOW_CREATE_PROCESSING',
        processing: false,
      });
      // if connect workflow then add description to integrationconfig
      if (integrationId) {
        const state = getState();
        const config = Object.values(state.entities.integrationConfigs.entities).find(
          (config: IntegrationConfigEntity) => config.integrationId == integrationId,
        )!;
        dispatch({
          type: 'SET_PORTAL_WORKFLOW_DESCRIPTION',
          configId: config.id,
          workflowId: workflow.id,
          description: DEFAULT_CONNECT_WORKFLOW_INFO_TEXT,
        });
      }
    } else {
      dispatch({
        type: 'WORKFLOW_ENTITY_CREATION_FAILED',
        message: await getErrorMessage(response),
      });
      dispatch({
        type: 'SET_WORKFLOW_CREATE_PROCESSING',
        processing: false,
      });
    }
  };

export const setDescription =
  (projectId: string, workflowId: string, description: string) => async (dispatch: Dispatch) => {
    dispatch({ type: 'SET_WORKFLOW_DESCRIPTION_START', payload: { workflowId } });
    try {
      const response = await api.patch(`/projects/${projectId}/workflows/${workflowId}`, {
        description,
      });

      if (response.ok) {
        dispatch({
          type: 'SET_WORKFLOW_DESCRIPTION_SUCCESS',
          payload: { workflowId, description },
        });
      } else {
        dispatch({
          type: 'SET_WORKFLOW_DESCRIPTION_FAILED',
          payload: { workflowId, message: await getErrorMessage(response) },
        });
      }
    } catch (error) {
      dispatch({
        type: 'SET_WORKFLOW_DESCRIPTION_FAILED',
        payload: { workflowId, message: await getErrorMessage(error.message) },
      });
    }
  };

export const getMigrations = (projectId: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'GET_PROJECT_MIGRATIONS_START' });

  const response = await api.get(`/projects/${projectId}/workflows/migrations/latest`);
  if (response.ok) {
    const body: object[] = await response.json();
    const migrations: WorkflowMigration[] = plainToClass(WorkflowMigration, body);

    dispatch({
      type: 'GET_PROJECT_MIGRATIONS_SUCCESS',
      payload: { migrations },
    });
  } else {
    dispatch({
      type: 'GET_PROJECT_MIGRATIONS_FAILED',
      payload: { message: await getErrorMessage(response) },
    });
  }
};

export const deleteWorkflow =
  (projectId: string, workflowId: string) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'WORKFLOW_ENTITY_DELETE_START' });

    const response = await api.del(`/projects/${projectId}/workflows/${workflowId}`, {});

    if (response.ok) {
      dispatch({
        type: 'WORKFLOW_ENTITY_DELETE_SUCCESS',
        payload: { workflowId },
      });
    } else {
      dispatch({
        type: 'WORKFLOW_ENTITY_DELETE_FAILED',
        payload: { message: await getErrorMessage(response) },
      });
    }
  };

export const duplicateWorkflow =
  (projectId: string, workflowId: string) => async (dispatch: Dispatch<Action>) => {
    // not added any REQEUST/FAILURE/SUCCESS actions because it was removed previously due to race conditions
    try {
      const response = await api.post(
        `/projects/${projectId}/workflows/${workflowId}/duplicate`,
        {},
      );

      if (response.ok) {
        const workflow: WorkFlow = await response.json();
        dispatch({
          type: 'WORKFLOW_ENTITY_CREATION_SUCCESS',
          payload: workflow,
        });
        // dispatch this so that redux has a state where it knows which workflow has been created recently
        dispatch({
          type: 'SET_WORKFLOW_ENTITY_DUPLICATED_WORKFLOW_ID',
          workflowId: workflow.id,
        });
      }
    } catch (error) {
      logError('Failed to create duplicate workflow', error.message);
    }
  };

export const cloneWorkflow =
  (
    projectId: string,
    targetWorkflowId: string,
    sourceWorkflowId: string,
    dependency?: CloneableWorkflowDependency,
  ) =>
  async (dispatch: Dispatch<Action>): Promise<Step | undefined> => {
    dispatch({ type: 'CLONE_WORKFLOW_START' });
    try {
      const response = await api.post(
        `/projects/${projectId}/workflows/${targetWorkflowId}/clone`,
        {
          sourceWorkflowId,
          dependency,
        },
      );
      if (response.ok) {
        const workflow = await response.json();

        dispatch({
          type: 'STEP_ENTITY_FETCH_SUCCESS',
          payload: workflow.steps,
        });
        dispatch({
          type: 'CLONE_WORKFLOW_SUCCESS',
          payload: workflow,
        });

        return getTriggerStep(workflow.steps);
      } else {
        dispatch({
          type: 'CLONE_WORKFLOW_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (error) {
      dispatch({
        type: 'CLONE_WORKFLOW_FAILED',
        message: error.message,
      });
    }
  };
