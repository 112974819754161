import React from 'react';

import {
  createTestConnectionButton,
  hasImpersonatedUserCredential,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { CredentialKeys, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  additionalFieldsInput,
  companyInput,
  customObjectNameInput,
  cutomObjectRecordFieldsInput,
  dynamicListInput,
  emailInput,
  firstNameInput,
  intentInput,
  lastNameInput,
  leadDynamicFilterInput,
  leadFilterInput,
  leadIdInput,
  leadIdsInput,
  listInput,
  phoneInput,
  titleInput,
  websiteInput,
} from './shared/inputs';

export const LinkWrapper = ({ text }: { text: string }) => (
  <a
    href="https://developers.marketo.com/rest-api/authentication/#creating_an_access_token"
    target="_blank"
    rel="noreferrer"
  >
    {text}
  </a>
);

const clientIdInput: SidebarInput = {
  id: CredentialKeys.CLIENT_ID,
  title: 'Enter your Marketo Client ID',
  subtitle: (
    <>
      Your Client ID can be found in the <LinkWrapper text="Admin > LaunchPoint menu" />.
    </>
  ),
  type: SidebarInputType.ValueText,
  placeholder: 'Marketo Client ID',
  required: true,
};

const clientSecretInput: SidebarInput = {
  id: CredentialKeys.CLIENT_SECRET,
  title: 'Enter your Marketo Client Secret',
  subtitle: (
    <>
      Your Client Secret can be found in the <LinkWrapper text="Admin > LaunchPoint menu" />.
    </>
  ),
  type: SidebarInputType.ValueText,
  placeholder: 'Marketo Client Secret',
  required: true,
};

const endpointInput: SidebarInput = {
  id: CredentialKeys.ENDPOINT,
  title: 'Enter your Marketo Endpoint URL',
  subtitle: (
    <>
      Your Endpoint URL can be found in the <LinkWrapper text="Admin > Web Services menu" />.
    </>
  ),
  type: SidebarInputType.ValueText,
  placeholder: 'https://064-CCJ-768.mktorest.com/rest',
  required: true,
};

const identityInput: SidebarInput = {
  id: CredentialKeys.IDENTITY,
  title: 'Enter your Marketo Identity URL',
  subtitle: (
    <>
      Your Identity URL can be found in the <LinkWrapper text="Admin > Web Services menu" />.
    </>
  ),
  type: SidebarInputType.ValueText,
  placeholder: 'https://064-CCJ-768.mktorest.com/identity',
  required: true,
};

export const authConfig: ActionConfig = {
  actionType: Action.MARKETO,
  scheme: AuthenticationScheme.BASIC,
  provider: ProviderType.MARKETO,
  name: 'Connect your Marketo account',
  description: 'Add Marketo to Paragon',
  icon: iconSvg,
  supportsImpersonatedCredentialSource: true,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this Marketo account',
          placeholder: 'eg. Production Testing etc.',
          type: SidebarInputType.ValueText,
        },
        clientIdInput,
        clientSecretInput,
        endpointInput,
        identityInput,
      ],
      buttons: [createTestConnectionButton(Action.MARKETO, Object.values(CredentialKeys))],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.MARKETO,
  name: 'Marketo',
  description: 'Choose marketo account',
  icon: iconSvg,
  provider: ProviderType.MARKETO,
  providerDataKeyLabelMap: { endpointUrl: 'Endpoint URL', identityUrl: 'Identity URL' },
  sidebarSections: [
    (parameters: ActionStepParameters) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Marketo account',
          placeholder: 'connect to Marketo',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasImpersonatedUserCredential(parameters)
          ? ([
              {
                ...clientIdInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.MARKETO_CLIENT_ID],
                providerType: ProviderType.MARKETO,
                hideTokenDropDown: true,
              },
              {
                ...clientSecretInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.MARKETO_CLIENT_SECRET],
                providerType: ProviderType.MARKETO,
                hideTokenDropDown: true,
              },
              {
                ...endpointInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.MARKETO_ENDPOINT],
                providerType: ProviderType.MARKETO,
                hideTokenDropDown: true,
              },
              {
                ...identityInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.MARKETO_IDENTITY],
                providerType: ProviderType.MARKETO,
                hideTokenDropDown: true,
              },
            ] as UserSuppliedCredentialInput[])
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials.length ? { inputs: [intentInput] } : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_OR_UPDATE_LEAD:
          return {
            inputs: [
              emailInput,
              firstNameInput,
              lastNameInput,
              companyInput,
              titleInput,
              phoneInput,
              websiteInput,
              additionalFieldsInput,
            ],
          };
        case Intent.GET_LEADS:
          return {
            inputs: [
              hasImpersonatedUserCredential(parameters) ? leadFilterInput : leadDynamicFilterInput,
            ],
          };
        case Intent.GET_LEAD_BY_ID:
          return { inputs: [leadIdInput] };
        case Intent.ADD_LEADS_TO_LIST:
          return {
            inputs: [
              hasImpersonatedUserCredential(parameters) ? listInput : dynamicListInput,
              leadIdsInput,
            ],
          };
        case Intent.CREATE_CUSTOM_OBJECT:
          return {
            inputs: [
              {
                ...customObjectNameInput,
                subtitle: 'The API Name of the Object to create.',
                placeholder: 'CustomObjectName_c',
              },
              { ...cutomObjectRecordFieldsInput, subtitle: '' },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
