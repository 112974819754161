import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions/';

import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.ELOQUA,
  provider: ProviderType.ELOQUA,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Eloqua account',
  icon: iconSvg,
  description: "Connect to your users' Eloqua accounts",
  sidebarSections: [],
};

export default authConfig;

const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Eloqua account',
  placeholder: 'connect to Eloqua',
  type: SidebarInputType.Auth,
  config: authConfig,
};

export { authInput };
