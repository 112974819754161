import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  endTimeInput,
  eventTypeInput,
  intentTypeInput,
  reasonInput,
  searchFilterInput,
  startTimeInput,
  uuidInput,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.CALENDLY,
  name: 'Calendly',
  description: `Connect to your users' Calendly accounts`,
  icon: iconSvg,
  provider: ProviderType.CALENDLY,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.GET_EVENT_TYPES:
          return {
            inputs: [uuidInput],
          };
        case Intent.GET_AVAILABLE_TIMES_FOR_EVENT_TYPE:
          return {
            inputs: [eventTypeInput, startTimeInput, endTimeInput],
          };
        case Intent.SEARCH_EVENTS:
          return {
            inputs: [searchFilterInput],
          };
        case Intent.GET_EVENT_BY_ID:
          return {
            inputs: [
              {
                ...uuidInput,
                subtitle: 'Specify the Event UUID of the scheduled event to fetch.',
              },
            ],
          };
        case Intent.GET_EVENT_INVITEES:
          return {
            inputs: [
              {
                ...uuidInput,
                subtitle:
                  'Specify the Event UUID of the scheduled event to fetch associated invitees.',
              },
            ],
          };
        case Intent.CANCEL_EVENT:
          return {
            inputs: [
              {
                ...uuidInput,
                subtitle: 'Specify the Event UUID of the scheduled event to cancel.',
              },
              reasonInput,
            ],
          };

        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };

export default connectConfig;
