import { Dispatch } from 'redux';

import { ModalConfig } from '@shared/types/sdk/connect';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { Action, State } from '../../types';

export const createIntegrationConfig =
  (projectId: string, integrationId: string, values: ModalConfig) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'INTEGRATION_CONFIG_ENTITY_CREATE_START' });

    try {
      const uri: string = `/projects/${projectId}/integrations/${integrationId}/configs`;
      const response = await api.post(uri, { values });
      if (response.ok) {
        const integrationConfigEntity = await response.json();

        dispatch({
          type: 'INTEGRATION_CONFIG_ENTITY_CREATE_SUCCESS',
          integrationConfigEntity,
        });
      } else {
        dispatch({
          type: 'INTEGRATION_CONFIG_ENTITY_CREATE_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (err) {
      dispatch({
        type: 'INTEGRATION_CONFIG_ENTITY_CREATE_FAILED',
        message: err.message,
      });
    }
  };

export const getIntegrationConfigs =
  (projectId: string, integrationId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'INTEGRATION_CONFIG_ENTITY_FETCH_START' });

    try {
      const uri: string = `/projects/${projectId}/integrations/${integrationId}/configs`;
      const response: Response = await api.get(uri);
      if (response.ok) {
        const integrationConfigs = await response.json();
        dispatch({
          type: 'INTEGRATION_CONFIG_ENTITY_FETCH_SUCCESS',
          integrationConfigs,
        });
      } else {
        const errorMessage = await getErrorMessage(response);
        dispatch({
          type: 'INTEGRATION_CONFIG_ENTITY_FETCH_FAILED',
          message: errorMessage,
        });
      }
    } catch (err) {
      dispatch({
        type: 'INTEGRATION_CONFIG_ENTITY_FETCH_FAILED',
        message: err.message,
      });
    }
  };

export const getIntegrationConfigById =
  (projectId: string, integrationId: string, configId?: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'INTEGRATION_CONFIG_ENTITY_FETCH_START' });

    try {
      const uri: string = `/projects/${projectId}/integrations/${integrationId}/configs/${configId}`;
      const response: Response = await api.get(uri);
      if (response.ok) {
        const config = await response.json();
        dispatch({
          type: 'INTEGRATION_CONFIG_ENTITY_FETCH_SUCCESS',
          integrationConfigs: [config],
        });
      } else {
        const errorMessage = await getErrorMessage(response);
        dispatch({
          type: 'INTEGRATION_CONFIG_ENTITY_FETCH_FAILED',
          message: errorMessage,
        });
      }
    } catch (err) {
      dispatch({
        type: 'INTEGRATION_CONFIG_ENTITY_FETCH_FAILED',
        message: err.message,
      });
    }
  };

export const updateIntegrationConfig =
  (
    projectId: string,
    integrationId: string,
    integrationConfigId: string,
    values: Record<string, any>,
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'INTEGRATION_CONFIG_ENTITY_UPDATE_START' });
    try {
      const response = await api.patch(
        `/projects/${projectId}/integrations/${integrationId}/configs/${integrationConfigId}`,
        { values },
      );
      if (response.ok) {
        const integrationConfigEntity = await response.json();
        dispatch({
          type: 'INTEGRATION_CONFIG_ENTITY_UPDATE_SUCCESS',
          integrationConfigEntity,
        });
      } else {
        dispatch({
          type: 'INTEGRATION_CONFIG_ENTITY_UPDATE_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (err) {
      dispatch({
        type: 'INTEGRATION_CONFIG_ENTITY_UPDATE_FAILED',
        message: err.message,
      });
    }
  };

export const reorderIntegrationWorkflows =
  (integrationConfigId: string, sourceIndex: number, destinationIndex: number) =>
  (dispatch: Dispatch<Action>, getState: () => State): void => {
    dispatch({
      type: 'REORDER_PORTAL_WORKFLOW_ITEMS',
      configId: integrationConfigId,
      sourceIndex,
      destinationIndex,
      workflowState: getState().entities.workflows.entities,
    });
  };
