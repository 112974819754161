import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedRedirectParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { RedirectStep, StepType } from '@shared/types/sdk/steps';
import { isValidUrl } from '@shared/utils/sdk/http';

import { StepValidator } from './abstract.validator';
import { shouldAssertValue } from './validator.utils';

/**
 * validates that a redirect step is proper configured
 *
 * @export
 * @class RedirectStepValidator
 * @extends {StepValidator<RedirectStep, ResolvedRedirectParameters>}
 */
export default class RedirectStepValidator extends StepValidator<
  RedirectStep,
  ResolvedRedirectParameters
> {
  stepType: RedirectStep['type'] = StepType.REDIRECT;

  /**
   * validates that a step is valid
   *
   * @param {RedirectStep} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {RedirectStep}
   * @memberof RedirectStepValidator
   */
  validate(
    step: RedirectStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona,
  ): RedirectStep {
    const { url }: ResolvedRedirectParameters = this.resolveVariables(
      step,
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
    );

    if (shouldAssertValue(step.parameters.url, url)) {
      if (!url) {
        throw new Error('You must specify a redirect url for this step.');
      } else if (!isValidUrl(url)) {
        throw new Error(`${url} is not a valid url.`);
      }
    }

    return step;
  }
}
