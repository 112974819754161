import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  boardInput,
  columnValuesInput,
  externalIdInput,
  getItemFieldInputsConnect,
  intentTypeInput,
  itemIdInput,
  memberIdInput,
  nameInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.MONDAY,
  name: 'Monday.com',
  description: 'Connect your Monday.com account to manage their items and boards in Monday.com.',
  icon: iconSvg,
  provider: ProviderType.MONDAY,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_ITEM:
        case Intent.UPDATE_ITEM:
          return {
            inputs: getItemFieldInputsConnect(parameters.intent === Intent.UPDATE_ITEM),
          };

        case Intent.GET_ITEM_BY_ID: {
          return { inputs: [itemIdInput] };
        }

        case Intent.GET_ITEM_BY_EXTERNAL_ID:
          return {
            inputs: [
              boardInput,
              {
                ...externalIdInput,
                subtitle:
                  'The ID that this task is associated or synced with, from your application.',
                required: true,
              },
            ],
          };

        case Intent.SEARCH_ITEMS:
          return {
            inputs: [{ ...boardInput, required: false }],
          };

        case Intent.DELETE_ITEM:
          return { inputs: [{ ...itemIdInput, subtitle: 'The ID of the item to delete.' }] };

        case Intent.ARCHIVE_ITEM:
          return {
            inputs: [
              {
                ...itemIdInput,
                subtitle: 'The ID of the item to archive.',
              },
            ],
          };

        case Intent.CREATE_SUBITEM:
          return {
            inputs: [
              nameInput,
              { ...itemIdInput, id: 'parentItemId', title: 'Parent Item ID', subtitle: '' },
              columnValuesInput,
            ],
          };

        case Intent.SEARCH_USERS:
          return { inputs: [memberIdInput] };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
