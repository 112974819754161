import { hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  addressInput,
  agendaInput,
  cityInput,
  countryInput,
  durationInput,
  emailInput,
  enableWaitingRoomInput,
  firstNameInput,
  intentSection,
  jobTitleInput,
  lastNameInput,
  meetingIdInput,
  meetingNameInput,
  organizationInput,
  passwordInput,
  phoneInput,
  registrantIdInput,
  registrantStatusInput,
  searchFromInput,
  searchUptoInput,
  sendConfirmationMailInput,
  startTimeInput,
  stateInput,
  timezoneInput,
  trashInput,
  trashTypeInput,
  zipInput,
} from './commonInputs';

export const authConfig: ActionConfig = {
  actionType: Action.ZOOM,
  scheme: AuthenticationScheme.OAUTH,
  provider: ProviderType.ZOOM,
  name: 'Connect your Zoom account',
  description: 'Add Zoom to Paragon',
  icon: iconSvg,
  sidebarSections: [],
};

const config: ActionConfig = {
  actionType: Action.ZOOM,
  name: 'Zoom',
  description: 'Sync meetings with Zoom',
  icon: iconSvg,
  provider: ProviderType.ZOOM,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Zoom account',
          placeholder: 'connect to Zoom',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.ZOOM,
                supportedTokenTypes: [TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials.length ? intentSection : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_MEETING:
          return {
            inputs: [
              meetingNameInput,
              startTimeInput,
              durationInput,
              timezoneInput,
              agendaInput,
              passwordInput,
              enableWaitingRoomInput,
              sendConfirmationMailInput,
            ],
          };
        case Intent.UPDATE_MEETING:
          return {
            inputs: [
              { ...meetingIdInput, subtitle: 'The ID of the Meeting to update.' },
              { ...meetingNameInput, required: false },
              { ...startTimeInput, required: false },
              { ...durationInput, required: false },
              { ...timezoneInput, required: false },
              agendaInput,
            ],
          };
        case Intent.GET_MEETING_BY_ID:
          return { inputs: [meetingIdInput] };
        case Intent.DELETE_MEETING:
          return { inputs: [{ ...meetingIdInput, subtitle: 'The ID of the Meeting to delete.' }] };
        case Intent.ADD_MEETING_REGISTRANT:
          return {
            inputs: [
              { ...meetingIdInput, subtitle: 'The ID of the Meeting to add registrants to.' },
              emailInput,
              firstNameInput,
              lastNameInput,
              addressInput,
              cityInput,
              countryInput,
              zipInput,
              stateInput,
              phoneInput,
              organizationInput,
              jobTitleInput,
            ],
          };
        case Intent.GET_MEETING_REGISTRANT:
          return {
            inputs: [
              { ...meetingIdInput, subtitle: 'The ID of the Meeting whose Registrants to get.' },
              registrantStatusInput,
            ],
          };
        case Intent.DELETE_MEETING_REGISTRANT:
          return {
            inputs: [
              { ...meetingIdInput, subtitle: 'The ID of the Meeting to delete a Registrant from.' },
              registrantIdInput,
            ],
          };
        case Intent.GET_RECORDING_BY_MEETING_ID:
          return {
            inputs: [meetingIdInput],
          };
        case Intent.SEARCH_RECORDING:
          return {
            inputs: [
              searchFromInput,
              searchUptoInput,
              { ...meetingIdInput, required: false, placeholder: '6840331990' },
              trashInput,
              trashTypeInput,
            ],
          };
        case Intent.GET_MEETING:
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
