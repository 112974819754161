import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Employees',
          items: [
            {
              value: Intent.CREATE_EMPLOYEE,
              label: 'Create Employee',
            },
            {
              value: Intent.UPDATE_EMPLOYEE,
              label: 'Update Employee',
            },
            {
              value: Intent.GET_EMPLOYEE_BY_ID,
              label: 'Get Employee by ID',
            },
            {
              value: Intent.GET_EMPLOYEE_DIRECTORY,
              label: 'Get Employee Directory',
            },
          ],
        },
        {
          title: 'Time Off',
          items: [
            {
              value: Intent.CREATE_TIME_OFF_REQUEST,
              label: 'Create a Time Off Request',
            },
            {
              value: Intent.CHANGE_REQUEST_STATUS,
              label: 'Change a Request Status',
            },
            {
              value: Intent.ADJUST_TIME_OFF_BALANCE_FOR_EMPLOYEE,
              label: 'Adjust Time Off Balance for an Employee',
            },
            {
              value: Intent.GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE,
              label: 'Get Time Off Requests for an Employee',
            },
            {
              value: Intent.GET_TIME_OFF_TYPES,
              label: 'Get Time Off Types',
            },
          ],
        },
        {
          title: 'Custom Fields',
          items: [
            {
              value: Intent.GET_FIELD_LIST,
              label: 'Get Field List',
            },
            {
              value: Intent.ADD_LIST_FIELD_VALUE,
              label: 'Add List Field Values',
            },
          ],
        },
      ],
    },
  ],
};

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  title: 'First Name',
  subtitle: 'Specify the first name of the employee to create.',
  type: SidebarInputType.TextArea,
  placeholder: 'John',
  lines: 1,
  required: true,
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  title: 'Last Name',
  subtitle: 'Specify the last name of the employee to create.',
  type: SidebarInputType.TextArea,
  placeholder: 'Doe',
  lines: 1,
  required: true,
};

export const dateOfBirthInput: SidebarInput = {
  id: 'dateOfBirth',
  title: 'Date of Birth',
  type: SidebarInputType.TextArea,
  placeholder: 'yyyy-mm-dd',
  lines: 1,
  required: false,
};

export const employeeNumberInput: SidebarInput = {
  id: 'employeeNumber',
  title: 'Employee Number',
  type: SidebarInputType.TextArea,
  placeholder: '5',
  lines: 1,
  required: false,
};

export const genderInput: SidebarInput = {
  id: 'gender',
  title: 'Gender',
  subtitle: 'Specify the plain text name of the gender.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const socialSecurityInput: SidebarInput = {
  id: 'socialSecurity',
  title: 'Social Security Number',
  subtitle: 'Specify the social security number of the employee to create.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const maritalStatusInput: SidebarInput = {
  id: 'maritalStatus',
  title: 'Marital Status',
  subtitle: 'Specify the plain text status of marriage.',
  type: SidebarInputType.TextArea,
  placeholder: 'Single',
  lines: 1,
  required: false,
};

export const hireDateInput: SidebarInput = {
  id: 'hireDate',
  title: 'Hire Date',
  subtitle: 'Specify the date of hire for the employee to create.',
  type: SidebarInputType.TextArea,
  placeholder: 'yyyy-mm-dd',
  lines: 1,
  required: false,
};

export const addressInput: SidebarInput = {
  id: 'address',
  title: 'Address Line 1',
  type: SidebarInputType.TextArea,
  placeholder: '2000 Oak Street',
  lines: 1,
  required: false,
};

export const cityInput: SidebarInput = {
  id: 'city',
  title: 'City',
  type: SidebarInputType.TextArea,
  placeholder: 'San Francisco',
  lines: 1,
  required: false,
};

export const stateInput: SidebarInput = {
  id: 'state',
  title: 'State',
  subtitle: 'Specify the full name of the state or province.',
  type: SidebarInputType.TextArea,
  placeholder: 'California',
  lines: 1,
  required: false,
};

export const countryInput: SidebarInput = {
  id: 'country',
  title: 'Country',
  subtitle: 'Specify the name of the country. Must exist in the BambooHR country list.',
  type: SidebarInputType.TextArea,
  placeholder: 'United States',
  lines: 1,
  required: false,
};

export const employmentHistoryInput: SidebarInput = {
  id: 'employmentHistory',
  title: 'Employment History Status',
  subtitle: "The employee's employment status as used to filter employees in BambooHR.",
  type: SidebarInputType.EditableEnum,
  required: false,
  defaultValue: undefined,
  getValues: () => [
    { label: 'Contractor', value: 'contractor' },
    { label: 'Full-Time', value: 'full-time' },
    { label: 'Furloughed', value: 'furloughed' },
    { label: 'Intern', value: 'intern' },
    { label: 'Part-Time', value: 'part-time' },
    { label: 'Terminated', value: 'terminated' },
  ],
};

export const exemptInput: SidebarInput = {
  id: 'exempt',
  title: 'Exempt',
  subtitle: 'Specify the FLSA Overtime Status of the employee.',
  type: SidebarInputType.EditableEnum,
  required: false,
  defaultValue: undefined,
  getValues: () => [
    { label: 'Exempt', value: 'exempt' },
    { label: 'Non-exempt', value: 'non exempt' },
  ],
};

export const payTypeInput: SidebarInput = {
  id: 'payType',
  title: 'Pay Type',
  subtitle: 'Specify the employee’s current pay type.',
  type: SidebarInputType.EditableEnum,
  required: false,
  defaultValue: undefined,
  getValues: () => [
    { label: 'Hourly', value: 'hourly' },
    { label: 'Salary', value: 'salary' },
    { label: 'Commission', value: 'commision' },
    { label: 'Exception Hourly', value: 'exception hourly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Piece Rate', value: 'piece rate' },
    { label: 'Contract', value: 'contract' },
    { label: 'Daily', value: 'daily' },
    { label: 'Pro Rata', value: 'pro rata' },
  ],
};

export const payRateInput: SidebarInput = {
  id: 'payRate',
  title: 'Pay Rate',
  subtitle: 'Specify the employee’s current pay rate.',
  type: SidebarInputType.TextArea,
  placeholder: '8.25',
  lines: 1,
  required: false,
};

export const payPerInput: SidebarInput = {
  id: 'payPer',
  title: 'Pay Per',
  subtitle: 'Specify the employee’s current pay per.',
  type: SidebarInputType.EditableEnum,
  required: false,
  defaultValue: undefined,
  getValues: () => [
    { label: 'Hour', value: 'hour' },
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Quater', value: 'quater' },
    { label: 'Year', value: 'year' },
  ],
};

export const locationInput: SidebarInput = {
  id: 'location',
  title: 'Location',
  subtitle:
    "Specify the employee's current location. The provided location must exist in the BambooHR instance’s location table.",
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const departmentInput: SidebarInput = {
  id: 'department',
  title: 'Department',
  subtitle:
    "Specify the employee's current department. The provided department must exist in the BambooHR instance’s department table.",
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const divisionInput: SidebarInput = {
  id: 'division',
  title: 'Division',
  subtitle:
    "Specify the employee's current division. The provided division must exist in the BambooHR instance’s division table.",
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const employeeIdInput: SidebarInput = {
  id: 'employeeId',
  title: 'Employee ID',
  subtitle: 'Specify the ID of the employee to update.',
  type: SidebarInputType.TextArea,
  placeholder: '45',
  lines: 1,
  required: true,
};

export const startDateInput: SidebarInput = {
  id: 'startDate',
  title: 'Start Date',
  type: SidebarInputType.TextArea,
  placeholder: 'yyyy-mm-dd',
  lines: 1,
  required: true,
};

export const endDateInput: SidebarInput = {
  id: 'endDate',
  title: 'End Date',
  type: SidebarInputType.TextArea,
  placeholder: 'yyyy-mm-dd',
  lines: 1,
  required: true,
};

export const statusInput: SidebarInput = {
  id: 'status',
  title: 'Status',
  subtitle: 'Specify the status of this time off request.',
  type: SidebarInputType.EditableEnum,
  required: true,
  defaultValue: undefined,
  getValues: () => [
    { label: 'Approved', value: 'approved' },
    { label: 'Denied', value: 'denied' },
    { label: 'Requested', value: 'requested' },
  ],
};

export const amountInput: SidebarInput = {
  id: 'amount',
  title: 'Amount',
  subtitle:
    'Specify the amount of days/hours to update the employee’s balance by. The unit will be based on the settings of the time off type.',
  type: SidebarInputType.TextArea,
  placeholder: '1',
  lines: 1,
  required: false,
};

export const employeeNoteInput: SidebarInput = {
  id: 'employeeNote',
  title: 'Employee Note',
  subtitle: 'Specify an employee note to attach to the time off request in this free-text space.',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
};

export const managerNoteInput: SidebarInput = {
  id: 'managerNote',
  title: 'Manager Note',
  subtitle: 'Specify a manager note to attach to the time off request in this free-text space.',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
};

export const requestIdInput: SidebarInput = {
  id: 'requestId',
  title: 'Request ID',
  subtitle: 'Specify the request ID of the time off request to update.',
  placeholder: '12',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const dateInput: SidebarInput = {
  id: 'date',
  title: 'Date',
  subtitle: 'Specify the date adjustment. Accepts ISO 8601 date format.',
  type: SidebarInputType.TextArea,
  placeholder: 'yyyy-mm-dd',
  lines: 1,
  required: true,
};

export const timeOffTypeIdInput: SidebarInput = {
  id: 'timeOffTypeId',
  title: 'Time Off Type ID',
  subtitle: 'Specify the associated time off type ID to add a balance adjustment for.',
  type: SidebarInputType.TextArea,
  placeholder: '11',
  lines: 1,
  required: true,
};

export const noteInput: SidebarInput = {
  id: 'note',
  title: 'Note',
  subtitle: 'Specify a note to attach to this adjustment.',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
};

export const upsertEmployeeFields = (isCreateEmployee: boolean): SidebarInput[] => [
  ...(isCreateEmployee ? [] : [employeeIdInput]),
  ...(isCreateEmployee
    ? [firstNameInput, lastNameInput]
    : [
        {
          ...firstNameInput,
          subtitle: 'Specify the first name of the employee to update.',
          required: false,
        },
        {
          ...lastNameInput,
          subtitle: 'Specify the last name of the employee to update.',
          required: false,
        },
      ]),
  dateOfBirthInput,
  employeeNumberInput,
  genderInput,
  ...(isCreateEmployee
    ? [socialSecurityInput]
    : [
        {
          ...socialSecurityInput,
          subtitle: 'Specify the social security number of the employee to update.',
          required: false,
        },
      ]),
  maritalStatusInput,
  ...(isCreateEmployee
    ? [hireDateInput]
    : [
        {
          ...hireDateInput,
          subtitle: 'Specify the date of hire for the employee to update.',
          required: false,
        },
      ]),
  addressInput,
  cityInput,
  stateInput,
  countryInput,
  employmentHistoryInput,
  exemptInput,
  payTypeInput,
  payRateInput,
  payPerInput,
  locationInput,
  departmentInput,
  divisionInput,
  additionalFieldInput,
];

export const additionalFieldInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  lines: 4,
  language: 'json',
  required: false,
  title: 'Additional Fields',
  placeholder: `{
  jobTitle: "Accountant",
  nickname: "John",
  address2: "Seattle"
}`,
  useLightTheme: true,
};

export const listFieldIdInput: SidebarInput = {
  id: 'listFieldId',
  title: 'List Field ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const customFieldOptions: SidebarInput = {
  id: 'customFieldOptions',
  type: SidebarInputType.Code,
  lines: 4,
  language: 'json',
  required: true,
  title: 'Custom Fields Options',
  placeholder: `[
  {
    value: "New Value",
    archived: "yes"
  },
]`,
  useLightTheme: true,
};
