import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const listIdInput: SidebarInput = {
  id: 'listId',
  title: 'List ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  subtitle:
    'The ID of the list that this subscriber will be added to. Use Connect Portal Workflow Settings to allow users to select a list.',
};

const listNameInput: SidebarInput = {
  id: 'listName',
  title: 'List name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const emailInput: SidebarInput = {
  id: 'email',
  title: 'Email address',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const phoneNumberInput: SidebarInput = {
  id: 'phoneNumber',
  title: 'Phone number',
  subtitle: 'Required if Email Address is not provided.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const customPropertiesInput: SidebarInput = {
  id: 'customProperties',
  title: 'Custom properties',
  type: SidebarInputType.Code,
  placeholder: `{
"sms_consent": true,
"example_property": "example value"
}`,
  required: false,
  language: 'json',
  useLightTheme: true,
  lines: 4,
};

const CampaignName: SidebarInput = {
  id: 'campaignName',
  title: 'Campaign name ',
  type: SidebarInputType.TextArea,
  subtitle: '',
  lines: 1,
  required: false,
};

const personInput: SidebarInput = {
  id: 'personId',
  title: 'Person ID',
  subtitle: 'The ID of the person whose profile will be retrieved.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const firstNameInput: SidebarInput = {
  id: 'firstName',
  title: 'First name',
  placeholder: 'George',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const lastNameInput: SidebarInput = {
  id: 'lastName',
  title: 'Last name',
  placeholder: 'Washington',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const titleInput: SidebarInput = {
  id: 'title',
  title: 'Title',
  placeholder: 'President',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const organizationInput: SidebarInput = {
  id: 'organization',
  title: 'Organization',
  placeholder: 'U.S. Government',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const cityInput: SidebarInput = {
  id: 'city',
  title: 'City',
  placeholder: 'Mount Vernon',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const regionInput: SidebarInput = {
  id: 'region',
  title: 'Region',
  placeholder: 'Virginia',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const zipInput: SidebarInput = {
  id: 'zip',
  title: 'Zip',
  placeholder: '22121',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const countryInput: SidebarInput = {
  id: 'country',
  title: 'Country',
  placeholder: 'United States',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const timezoneInput: SidebarInput = {
  id: 'timezone',
  title: 'Timezone',
  placeholder: 'US/Eastern',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const templateIdInput: SidebarInput = {
  id: 'template',
  title: 'Template ',
  subtitle:
    'The ID of the email template object that will be the content of this campaign. Use Connect Portal Workflow Settings to allow users to select an template.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const config: ActionConfig = {
  actionType: Action.KLAVIYO,
  name: 'Klaviyo',
  description: 'Manage subscribers in Klaviyo.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              title: 'Campaigns',
              items: [
                {
                  value: Intent.CREATE_CAMPAIGN,
                  label: 'Create Campaign',
                },
                {
                  value: Intent.GET_CAMPAIGN,
                  label: 'Get Campaigns',
                },
                {
                  value: Intent.SEND_CAMPAIGN,
                  label: 'Send Campaign',
                },
              ],
            },
            {
              title: 'Lists',
              items: [
                {
                  value: Intent.CREATE_LIST,
                  label: 'Create List',
                },

                {
                  value: Intent.ADD_SUBSCRIBER_TO_LIST,
                  label: 'Add subscriber to list',
                },
                {
                  value: Intent.UNSUBSCRIBE_FROM_LIST,
                  label: 'Remove Subscriber from list',
                },
                {
                  value: Intent.GET_LISTS,
                  label: 'Get Lists and Segments',
                },
                {
                  value: Intent.GET_LIST_SUBSCRIBER,
                  label: 'Get List or Segment Subscribers',
                },
              ],
            },
            {
              title: 'Profiles',
              items: [
                {
                  value: Intent.GET_PROFILE,
                  label: 'Get Profile',
                },
                {
                  value: Intent.UPDATE_PROFILE,
                  label: 'Update Profile',
                },
              ],
            },
            {
              title: 'Templates',
              items: [
                {
                  value: Intent.CREATE_TEMPLATE,
                  label: 'Create Template',
                },
                {
                  value: Intent.GET_TEMPLATES,
                  label: 'Get Templates',
                },
              ],
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_LIST:
          return {
            inputs: [listNameInput],
          };
        case Intent.GET_LISTS:
          return {
            inputs: [
              {
                ...listNameInput,
                ...{
                  title: 'List or Segment name',
                  subtitle: 'Only return lists and segments that match the specified name.',
                  required: false,
                },
              },
            ],
          };
        case Intent.ADD_SUBSCRIBER_TO_LIST:
          return {
            inputs: [
              listIdInput,
              emailInput,
              { ...phoneNumberInput, required: false },
              firstNameInput,
              lastNameInput,
              titleInput,
              organizationInput,
              cityInput,
              regionInput,
              zipInput,
              countryInput,
              timezoneInput,
              customPropertiesInput,
            ],
          };
        case Intent.GET_LIST_SUBSCRIBER:
          return {
            inputs: [
              { ...listIdInput, title: 'List or Segment ID' },
              {
                ...emailInput,
                ...{
                  id: 'emails',
                  required: false,
                  title: 'Emails',
                  subtitle:
                    'Return subscribers with the email(s) provided. Accepts an array or comma-separated list of email addresses. ',
                },
              },
              {
                ...phoneNumberInput,
                ...{
                  id: 'phoneNumbers',
                  title: 'Phone numbers',
                  required: false,
                  subtitle:
                    'Return subscribers with the phone number(s) provided. Accepts an array or comma-separated list of phone numbers.',
                },
              },
              {
                id: 'pushTokens',
                title: 'Push tokens',
                subtitle:
                  'Return subscribers with the push token(s) provided. Accepts an array or comma-separated list of push tokens.',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: false,
              },
            ],
          };
        case Intent.UNSUBSCRIBE_FROM_LIST:
          return {
            inputs: [
              listIdInput,
              {
                ...emailInput,
                subtitle: 'The email address of the profile to unsubscribe.',
              },
            ],
          };
        case Intent.CREATE_CAMPAIGN:
          return {
            inputs: [
              listIdInput,
              templateIdInput,
              {
                id: 'subject',
                title: 'Subject ',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: true,
              },
              {
                id: 'email',
                title: 'From email ',
                type: SidebarInputType.TextArea,
                subtitle:
                  'The email address campaign emails will be sent from. Use Connect Portal Workflow Settings to allow users to enter an email address.',
                lines: 1,
                required: true,
              },
              {
                id: 'senderName',
                title: 'From name ',
                type: SidebarInputType.TextArea,
                subtitle:
                  "The name or label associated with the email address you're sending from. Use Connect Portal Workflow Settings to allow users to enter an name.",
                lines: 1,
                required: true,
              },
              {
                ...CampaignName,
                subtitle: 'If not specified, this will default to the subject of the campaign.',
              },
              {
                id: 'smartSending',
                title: 'Use smart sending ',
                type: SidebarInputType.Enum,
                defaultValue: 'True',
                subtitle:
                  'Limits the number of emails sent to an individual within a short period.',
                required: true,
                getValues: () => {
                  return [
                    { label: 'True', value: 'True' },
                    { label: 'False', value: 'False' },
                  ];
                },
              },
              {
                id: 'addGoogleAnalytics',
                title: 'Add Google Analytics',
                type: SidebarInputType.Enum,
                defaultValue: 'False',
                subtitle: 'Adds Google Analytics tracking tags to links.',
                required: true,
                getValues: () => {
                  return [
                    { label: 'True', value: 'True' },
                    { label: 'False', value: 'False' },
                  ];
                },
              },
            ],
          };

        case Intent.GET_CAMPAIGN:
          return {
            inputs: [
              {
                ...CampaignName,
                subtitle: 'Only return campaigns that match the specified name.',
              },
            ],
          };

        case Intent.SEND_CAMPAIGN:
          return {
            inputs: [
              {
                ...CampaignName,
                title: 'Campaign ID',
                subtitle:
                  'The ID of the campaign that will be sent. Use Connect Portal Workflow Settings to allow users to select a campaign.',
                required: true,
              },
            ],
          };

        case Intent.GET_TEMPLATES:
          return {
            inputs: [
              {
                id: 'templateName',
                title: 'Template name ',
                type: SidebarInputType.TextArea,
                subtitle: 'Only return templates that match the specified name.',
                lines: 1,
                required: false,
              },
            ],
          };

        case Intent.CREATE_TEMPLATE:
          return {
            inputs: [
              {
                id: 'name',
                title: 'Template name ',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: true,
              },
              {
                id: 'html',
                title: 'Template HTML ',
                type: SidebarInputType.TextArea,
                lines: 4,
                required: true,
              },
            ],
          };

        case Intent.GET_PROFILE:
          return {
            inputs: [personInput],
          };

        case Intent.UPDATE_PROFILE:
          return {
            inputs: [
              personInput,
              { ...emailInput, required: false },
              { ...phoneNumberInput, required: false },
              firstNameInput,
              lastNameInput,
              titleInput,
              organizationInput,
              cityInput,
              regionInput,
              zipInput,
              countryInput,
              timezoneInput,
              customPropertiesInput,
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
