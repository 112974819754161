import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type Credentials = {
  name: string;
  MAILCHIMP_ACCESS_TOKEN: string;
  MAILCHIMP_DATA_CENTER: string;
};

export type Environment = {
  MAILCHIMP_CLIENT_ID: string;
  MAILCHIMP_CLIENT_SECRET: string;
};

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MAILCHIMP;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.MAILCHIMP;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'MAILCHIMP_TEST_CONNECTION',
  CREATE_CAMPAIGN = 'MAILCHIMP_CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN = 'MAILCHIMP_UPDATE_CAMPAIGN',
  SEND_CAMPAIGN = 'MAILCHIMP_SEND_CAMPAIGN',
  SEARCH_CAMPAIGNS = 'MAILCHIMP_SEARCH_CAMPAIGNS',
  GET_CAMPAIGN_BY_ID = 'MAILCHIMP_GET_CAMPAIGN_BY_ID',
  DELETE_CAMPAIGN_BY_ID = 'MAILCHIMP_DELETE_CAMPAIGN_BY_ID',
  CREATE_LIST = 'MAILCHIMP_CREATE_LIST',
  GET_LIST_BY_ID = 'MAILCHIMP_GET_LIST_BY_ID',
  SEARCH_LISTS = 'MAILCHIMP_SEARCH_LISTS',
  ADD_CONTACT_TO_LIST = 'MAILCHIMP_ADD_CONTACT_TO_LIST',
  UPDATE_CONTACT_TO_LIST = 'MAILCHIMP_UPDATE_CONTACT_TO_LIST',
  GET_CONTACTS_FROM_LIST = 'MAILCHIMP_GET_CONTACTS_FROM_LIST',
  GET_TEMPLATES = 'MAILCHIMP_GET_TEMPLATES',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_CAMPAIGNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CAMPAIGN_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_CAMPAIGN_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LIST_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_LISTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_CONTACT_TO_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CONTACT_TO_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CONTACTS_FROM_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TEMPLATES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );
