import { Lens } from 'monocle-ts';

import { IConnectEvent } from '@shared/entities/sdk/event';

import { indexBy } from '../../../utils';
import { Action, ConnectEventEntities, EntitiesState } from '../../types';

export const initialState: ConnectEventEntities = {
  entities: {},
  errorMessage: undefined,
  processing: false,
  newlyCreatedAppEvent: undefined,
};

export function handler(
  state: ConnectEventEntities = initialState,
  action: Action,
): ConnectEventEntities {
  switch (action.type) {
    case 'APP_EVENTS_FETCH_START':
    case 'APP_EVENT_CREATE_START':
    case 'APP_EVENT_UPDATE_START':
    case 'APP_EVENT_DELETE_START':
      return {
        ...state,
        processing: true,
      };
    case 'APP_EVENTS_FETCH_SUCCESS':
      return {
        ...Lens.fromProp<EntitiesState<IConnectEvent>>()('entities').modify(
          (entities: Record<string, IConnectEvent>) => {
            return {
              ...entities,
              ...indexBy('id', action.payload),
            };
          },
        )(state),
        processing: false,
      };
    case 'APP_EVENT_CREATE_SUCCESS':
    case 'APP_EVENT_UPDATE_SUCCESS':
      return {
        ...Lens.fromProp<EntitiesState<IConnectEvent>>()('entities').modify(
          (entities: Record<string, IConnectEvent>) => {
            return {
              ...entities,
              ...{ [action.payload.id]: action.payload },
            };
          },
        )(state),
        processing: false,
        newlyCreatedAppEvent:
          action.type === 'APP_EVENT_CREATE_SUCCESS' ? action.payload.id : undefined,
      };
    case 'APP_EVENT_DELETE_SUCCESS':
      const { [action.id]: deletedEvent, ...remainingEvents } = state.entities;
      return {
        ...state,
        entities: remainingEvents,
        processing: false,
        errorMessage: undefined,
      };
    case 'APP_EVENTS_FETCH_FAILURE':
    case 'APP_EVENT_CREATE_FAILURE':
    case 'APP_EVENT_UPDATE_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'APP_EVENT_CLEAR_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: undefined,
      };

    case 'REMOVE_NEWLY_CREATED_APP_EVENT':
      return {
        ...state,
        newlyCreatedAppEvent: undefined,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
