import { TestDestinationResult } from '@shared/pheme/types/response';
import { IEventDestination } from '@shared/postgres-pheme/entities/eventDestination/eventDestination.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export type EventDestinationAction =
  | {
      type: 'DESTINATION_ENTITY_FETCH_START';
    }
  | {
      type: 'DESTINATION_ENTITY_FETCH_FAILURE';
      message: string;
    }
  | {
      type: 'DESTINATION_ENTITY_FETCH_SUCCESS';
      payload: IEventDestination[];
    }
  | {
      type: 'DESTINATION_ENTITY_CREATE_START';
    }
  | {
      type: 'DESTINATION_ENTITY_CREATE_FAILURE';
      message: string;
    }
  | {
      type: 'DESTINATION_ENTITY_CREATE_SUCCESS';
      payload: IEventDestination;
    }
  | {
      type: 'DESTINATION_ENTITY_UPDATE_START';
    }
  | {
      type: 'DESTINATION_ENTITY_UPDATE_FAILURE';
      message: string;
    }
  | {
      type: 'DESTINATION_ENTITY_UPDATE_SUCCESS';
      payload: IEventDestination;
    }
  | {
      type: 'DESTINATION_ENTITY_DELETE_START';
    }
  | {
      type: 'DESTINATION_ENTITY_DELETE_FAILURE';
      message: string;
    }
  | {
      type: 'DESTINATION_ENTITY_DELETE_SUCCESS';
      payload: {
        id: string;
      };
    }
  | {
      type: 'DESTINATION_ENTITY_ENABLE_START';
    }
  | {
      type: 'DESTINATION_ENTITY_ENABLE_FAILURE';
      message: string;
    }
  | {
      type: 'DESTINATION_ENTITY_ENABLE_SUCCESS';
      payload: IEventDestination;
    }
  | {
      type: 'DESTINATION_ENTITY_DISABLE_START';
    }
  | {
      type: 'DESTINATION_ENTITY_DISABLE_FAILURE';
      message: string;
    }
  | {
      type: 'DESTINATION_ENTITY_DISABLE_SUCCESS';
      payload: IEventDestination;
    }
  | {
      type: 'DESTINATION_REMOVE_ERROR_MESSAGE';
    }
  | { type: 'DESTINATION_TEST_START' }
  | {
      type: 'DESTINATION_TEST_SUCCESS';
      payload: TestDestinationResult;
    }
  | {
      type: 'DESTINATION_TEST_FAILURE';
      payload: TestDestinationResult;
    }
  | {
      type: 'CLEAR_DESTINATION_TEST';
    }
  | {
      type: 'DESTINATION_SET_ERROR_MESSAGE';
      payload: string;
    };

export enum TestStatus {
  SUCCEEDED = 'succeded',
  FAILED = 'failed',
  EXECUTING = 'executing',
}

export type DestinationEntities = EntitiesState<IEventDestination> & {
  testResult?: {
    state: TestStatus;
    result?: TestDestinationResult;
  };
};

export const initialState: DestinationEntities = {
  entities: {},
  processing: false,
  errorMessage: undefined,
  testResult: undefined,
};

export function handler(
  state: DestinationEntities = initialState,
  action: Action,
): DestinationEntities {
  switch (action.type) {
    case 'DESTINATION_ENTITY_FETCH_START':
    case 'DESTINATION_ENTITY_CREATE_START':
    case 'DESTINATION_ENTITY_UPDATE_START':
    case 'DESTINATION_ENTITY_DELETE_START':
    case 'DESTINATION_ENTITY_ENABLE_START':
    case 'DESTINATION_ENTITY_DISABLE_START':
      return {
        ...state,
        processing: true,
      };

    case 'DESTINATION_ENTITY_FETCH_FAILURE':
    case 'DESTINATION_ENTITY_CREATE_FAILURE':
    case 'DESTINATION_ENTITY_UPDATE_FAILURE':
    case 'DESTINATION_ENTITY_DELETE_FAILURE':
    case 'DESTINATION_ENTITY_ENABLE_FAILURE':
    case 'DESTINATION_ENTITY_DISABLE_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'DESTINATION_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        entities: { ...indexBy('id', action.payload) },
        processing: false,
        errorMessage: undefined,
      };

    case 'DESTINATION_ENTITY_CREATE_SUCCESS':
    case 'DESTINATION_ENTITY_UPDATE_SUCCESS':
    case 'DESTINATION_ENTITY_ENABLE_SUCCESS':
    case 'DESTINATION_ENTITY_DISABLE_SUCCESS':
      return {
        ...state,
        entities: { ...state.entities, ...indexBy('id', [action.payload]) },
        processing: false,
        errorMessage: undefined,
      };

    case 'DESTINATION_ENTITY_DELETE_SUCCESS':
      return {
        ...state,
        entities: indexBy(
          'id',
          Object.values(state.entities).filter(({ id }) => id !== action.payload.id),
        ),
        processing: false,
        errorMessage: undefined,
      };

    case 'DESTINATION_SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };

    case 'DESTINATION_REMOVE_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: undefined,
      };

    case 'DESTINATION_TEST_START':
      return {
        ...state,
        testResult: {
          state: TestStatus.EXECUTING,
          result: undefined,
        },
      };

    case 'DESTINATION_TEST_SUCCESS':
      return {
        ...state,
        testResult: {
          state: TestStatus.SUCCEEDED,
          result: action.payload,
        },
      };

    case 'DESTINATION_TEST_FAILURE':
      return {
        ...state,
        testResult: {
          state: TestStatus.FAILED,
          result: action.payload,
        },
      };

    case 'CLEAR_DESTINATION_TEST':
      return {
        ...state,
        testResult: undefined,
      };

    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;

    default:
      return state;
  }
}
