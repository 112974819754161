import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { GetAllSitesResponse, SiteEntityType } from '../shared/types';

export const getSites: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Site',
  subtitle: 'Google Search Console site to query data about',
  cacheKey: 'sites',
  mapRefreshToValues: (response: ActionResponse<GetAllSitesResponse>): EnumInputValue[] => {
    return response?.result.output.siteEntry
      ? response.result.output.siteEntry.map((site: SiteEntityType) => ({
          label: site.siteUrl as string,
          value: site.siteUrl,
        }))
      : [];
  },
  refreshDependencies: [],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => ({
    actionType: Action.GOOGLE_SEARCH_CONSOLE,
    intent: Intent.GET_ALL_SITES,
    credentials: options.credentials,
    actionParameters: [],
  }),
};
