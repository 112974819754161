import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.BAMBOO_HR;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'BAMBOO_HR_TEST_CONNECTION',
  CREATE_EMPLOYEE = 'BAMBOO_HR_CREATE_EMPLOYEE',
  UPDATE_EMPLOYEE = 'BAMBOO_HR_UPDATE_EMPLOYEE',
  GET_EMPLOYEE_BY_ID = 'BAMBOO_HR_GET_EMPLOYEE_BY_ID',
  GET_EMPLOYEE_DIRECTORY = 'BAMBOO_HR_GET_EMPLOYEE_DIRECTORY',
  CREATE_TIME_OFF_REQUEST = 'BAMBOO_HR_CREATE_TIME_OFF_REQUEST',
  CHANGE_REQUEST_STATUS = 'BAMBOO_HR_CHANGE_REQUEST_STATUS',
  ADJUST_TIME_OFF_BALANCE_FOR_EMPLOYEE = 'BAMBOO_HR_ADJUST_TIME_OFF_BALANCE_FOR_EMPLOYEE',
  GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE = 'BAMBOO_HR_GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE',
  GET_EMPLOYEE_FIELD_TYPES = 'BAMBOO_HR_GET_EMPLOYEE_FIELD_TYPES',
  GET_TIME_OFF_TYPES = 'BAMBOO_HR_GET_TIME_OFF_TYPES',
  GET_FIELD_LIST = 'BAMBOO_HR_GET_FIELD_LIST',
  ADD_LIST_FIELD_VALUE = 'BAMBOO_HR_ADD_LIST_FIELD_VALUE',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_EMPLOYEE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_EMPLOYEE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_EMPLOYEE_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_EMPLOYEE_DIRECTORY;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_TIME_OFF_REQUEST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CHANGE_REQUEST_STATUS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADJUST_TIME_OFF_BALANCE_FOR_EMPLOYEE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_EMPLOYEE_FIELD_TYPES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TIME_OFF_TYPES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_FIELD_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_LIST_FIELD_VALUE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ACCESS_TOKEN = 'BAMBOO_HR_ACCESS_TOKEN',
  DOMAIN = 'BAMBOO_HR_DOMAIN',
  API_KEY = 'BAMBOO_HR_API_KEY',
  USER_ID = 'BAMBOO_HR_USER_ID',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.DOMAIN]: string;
  [CredentialKeys.USER_ID]: string;
  [CredentialKeys.API_KEY]: string;
};
