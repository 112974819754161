import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs/config';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Opportunity',
          items: [
            {
              value: Intent.CREATE_OPPORTUNITY,
              label: 'Create a Opportunity',
            },
            {
              value: Intent.GET_OPPORTUNITY_BY_ID,
              label: 'Get Opportunity by ID',
            },
            {
              value: Intent.GET_OPPORTUNITIES,
              label: 'Get Opportunities',
            },
          ],
        },
        {
          title: 'Contact',
          items: [
            {
              value: Intent.UPDATE_CONTACT,
              label: 'Update Contact',
            },
            {
              value: Intent.GET_CONTACT_BY_ID,
              label: 'Get Contact by ID',
            },
          ],
        },
        {
          title: 'Posting',
          items: [
            {
              value: Intent.CREATE_POSTING,
              label: 'Create a Posting',
            },
            {
              value: Intent.UPDATE_POSTING,
              label: 'Update Posting',
            },
            {
              value: Intent.GET_POSTING_BY_ID,
              label: 'Get Posting by ID',
            },
            {
              value: Intent.GET_POSTINGS,
              label: 'Get Postings',
            },
          ],
        },
      ],
    },
  ],
};

export const contactInput: SidebarInput = {
  id: 'contact',
  title: 'Contact',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const emailsInput: SidebarInput = {
  id: 'emails',
  title: 'Emails',
  type: SidebarInputType.TextArea,
  placeholder: '["shane@exampleq3.com"]',
  lines: 1,
  required: false,
};

export const followersInput: SidebarInput = {
  id: 'followers',
  title: 'Followers',
  type: SidebarInputType.TextArea,
  subtitle:
    'An array of user IDs that should be added as followers to this Opportunity. The Opportunity creator will always be added as a follower.',
  lines: 1,
  required: false,
};

export const headlineInput: SidebarInput = {
  id: 'headline',
  title: 'Headline',
  type: SidebarInputType.TextArea,
  subtitle:
    'Contact headline, typically a list of previous companies where the contact has worked or schools that the contact has attended This field can also be populated by parsing a provided resume file.',
  lines: 1,
  required: false,
};

export const locationInput: SidebarInput = {
  id: 'location',
  title: 'Location',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const originInput: SidebarInput = {
  id: 'origin',
  title: 'Origin',
  subtitle: 'The way this Opportunity was added to Lever.',
  type: SidebarInputType.Enum,
  required: false,
  getValues: () => [
    { label: 'Agency', value: 'agency' },
    { label: 'Applied', value: 'applied' },
    { label: 'Internal', value: 'internal' },
    { label: 'Referred', value: 'referred' },
    { label: 'Sourced', value: 'sourced' },
    { label: 'University', value: 'university' },
  ],
};

export const performAsInput: SidebarInput = {
  id: 'performAs',
  title: 'Perform As',
  type: SidebarInputType.TextArea,
  subtitle:
    'Perform this create on behalf of a specified user. The creator and the owner of this Opportunity will default to the perform_as user. The owner can be explicitly specified in the request body if you want the owner to be a different person.',
  lines: 1,
  required: false,
};

export const phonesInput: SidebarInput = {
  id: 'phones',
  title: 'Phones',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
  placeholder: `[{
  "value":"(123) 456-7891"
}]`,
};

export const stageInput: SidebarInput = {
  id: 'stage',
  title: 'Stage',
  type: SidebarInputType.TextArea,
  subtitle: `The stage ID of this Opportunity's current stage If omitted, the Opportunity will be placed into the "New Lead" stage.`,
  lines: 1,
  required: false,
};

export const opportunityIdInput: SidebarInput = {
  id: 'opportunityId',
  title: 'Opportunity ID',
  type: SidebarInputType.TextArea,
  subtitle: 'Specify the Opportunity ID to fetch.',
  lines: 1,
};

export const textInput: SidebarInput = {
  id: 'text',
  title: 'Text',
  type: SidebarInputType.TextArea,
  subtitle: 'Title of the job posting',
  lines: 1,
};

export const stateInput: SidebarInput = {
  id: 'state',
  title: 'State',
  subtitle: `Posting's current status.`,
  type: SidebarInputType.Enum,
  required: false,
  defaultValue: undefined,
  getValues: () => [
    { label: 'Published', value: 'published' },
    { label: 'Internal', value: 'internal' },
    { label: 'Closed', value: 'closed' },
    { label: 'Draft', value: 'draft' },
    { label: 'Pending', value: 'pending' },
    { label: 'Rejected', value: 'rejected' },
  ],
};

export const distributionChannelsInput: SidebarInput = {
  id: 'distributionChannels',
  title: 'Distribution Channels',
  type: SidebarInputType.TextArea,
  subtitle: 'Job sites that a published posting appears on. defaults to public and internal',
  lines: 1,
  placeholder: '["internal","public"]',
  required: false,
};

export const ownerInput: SidebarInput = {
  id: 'owner',
  title: 'Owner',
  type: SidebarInputType.TextArea,
  subtitle:
    'The user ID of the posting owner. The posting owner is the individual who is directly responsible for managing all candidates who are applied to that role. If no ID is provided, the posting owner defaults to perform_as.',
  lines: 1,
  required: false,
};

export const hiringManagerInput: SidebarInput = {
  id: 'hiringManager',
  title: 'Hiring Manager',
  type: SidebarInputType.TextArea,
  subtitle: 'The user ID of the hiring manager for the job posting.',
  lines: 1,
  required: false,
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  title: 'Additional Fields',
  required: false,
  placeholder: `{
    "workplaceType": "onsite",
    "tags": ["engineering","high-priority"]
}`,
  useLightTheme: true,
};

export const postingIdInput: SidebarInput = {
  id: 'postingId',
  title: 'Posting ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const contactIdInput: SidebarInput = {
  id: 'contactId',
  title: 'Contact ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: 'ac6102d2-c1cb-42a2-b8a1-5f607e0a46b4',
  required: true,
};

export const catagoriesInput: SidebarInput = {
  id: 'categories',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  title: 'Categories',
  required: false,
  placeholder: `{
  "team": "Platform",
  "department": "Engineering",
  "location": "San Francisco",
  "commitment": "Full-time"
}`,
  useLightTheme: true,
};

export const upsertPostingFields = (isCreatePosting: boolean): SidebarInput[] => [
  ...(isCreatePosting ? [] : [postingIdInput]),
  { ...textInput, required: isCreatePosting },
  { ...catagoriesInput, required: isCreatePosting },
  performAsInput,
  stateInput,
  distributionChannelsInput,
  ...(isCreatePosting ? [ownerInput, hiringManagerInput] : []),
  additionalFieldsInput,
];
