import { getValuesByCacheKey, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionButtonStyle,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { GOOGLE_SHEET_OPERATORS, GoogleSheetColumn } from '../shared/types';

import { getColumns, getSpreadSheets, getWorkSheets } from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.GOOGLE_SHEETS,
  provider: ProviderType.GOOGLE,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Google Sheets account',
  icon: iconSvg,
  description: 'Google Sheets',
  sidebarSections: [],
  hideOAuthApps: true,
};

const spreadSheetInput: SidebarInput = {
  title: 'Spreadsheet',
  id: 'spreadsheetId',
  type: SidebarInputType.DynamicEnum,
  placeholder: 'Choose a spreadsheet',
  required: true,
  source: getSpreadSheets,
  getValues: getValuesByCacheKey,
};

const worksheetInput: SidebarInput = {
  id: 'worksheet',
  title: 'Worksheet',
  type: SidebarInputType.DynamicEnum,
  subtitle: 'Your worksheet must have column headers.',
  placeholder: 'Choose a worksheet',
  required: true,
  source: getWorkSheets,
  getValues: getValuesByCacheKey,
};

const refreshFieldButton: SidebarInput = {
  id: 'refreshFields',
  type: SidebarInputType.ActionButton,
  title: 'Refresh Fields',
  style: ActionButtonStyle.DEFAULT,
  source: getColumns,
};

const filterInput: SidebarInput = {
  id: 'filterFormula',
  type: SidebarInputType.DynamicConditional,
  title: 'Filter records',
  subtitle:
    'You can filter which records get returned from Google Sheets by applying one or more filters below.',
  placeholder: 'filter by field',
  source: getColumns,
  getValues: getValuesByCacheKey,
  supportedOperators: GOOGLE_SHEET_OPERATORS,
  required: false,
};

const config: ActionConfig = {
  actionType: Action.GOOGLE_SHEETS,
  name: 'Google Sheets',
  description: 'Access and update your spreadsheets.',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Google Sheets account',
          placeholder: 'connect to Google Sheets',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.GET_ROW,
                    label: 'Get rows from spreadsheet',
                  },
                  {
                    value: Intent.CREATE_ROW,
                    label: 'Create rows in spreadsheet',
                  },
                  {
                    value: Intent.UPDATE_ROW,
                    label: 'Update rows in spreadsheet',
                  },
                  {
                    value: Intent.CLEAR_ROWS,
                    label: 'Clear rows in spreadsheet',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.GET_ROW:
          return {
            inputs: [
              spreadSheetInput,
              worksheetInput,
              filterInput,
              {
                id: 'range',
                title: 'Range',
                subtitle: 'Only return values from a specified range, e.g. A1:Z10',
                type: SidebarInputType.Text,
                required: false,
              },
            ],
            buttons: [refreshFieldButton],
          };
        case Intent.CREATE_ROW:
          return {
            inputs: [spreadSheetInput, worksheetInput],
          };
        case Intent.UPDATE_ROW:
          return {
            inputs: [spreadSheetInput, worksheetInput, filterInput],
          };
        case Intent.CLEAR_ROWS:
          return {
            inputs: [
              spreadSheetInput,
              worksheetInput,
              {
                id: 'range',
                title: 'Range',
                subtitle: 'Only clear values from a specified range, e.g. A1:Z10',
                type: SidebarInputType.Text,
                required: false,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (![Intent.CREATE_ROW, Intent.UPDATE_ROW].includes(parameters.intent as Intent)) {
        return { inputs: [] };
      }
      return {
        inputs: (
          pickValueSourceByKey<DataType.ANY>(parameters.actionParameters, 'cachedColumns')?.value ||
          []
        ).map((column: GoogleSheetColumn) => ({
          id: `column-${column.label}`,
          title: column.label,
          required: false,
          type: SidebarInputType.TextArea,
          lines: 1,
        })),
        buttons: [refreshFieldButton],
      };
    },
  ],
};

export default config;
