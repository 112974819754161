import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { MD_ENTITY_SPECIFIED_BY_OBJECT_NAME, MdDefaultEntities } from '../shared';

import {
  additionalFieldsInput,
  customMdEntityNameInput,
  entityIdInputFactory,
  getFilterInputConnect,
  inputsForDefaultEntitiesConnect,
  intentInput,
  mdEntityInput,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.MICROSOFT_DYNAMICS,
  name: 'Dynamics 365 Sales',
  description: 'Sync records from Dynamics 365 Sales',
  icon: iconSvg,
  provider: ProviderType.MICROSOFT_DYNAMICS_365,
  sidebarSections: [
    {
      inputs: intentInput,
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedMdEntity:
        | MdDefaultEntities
        | typeof MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'mdEntity',
      )?.value;

      return {
        inputs: [
          mdEntityInput,
          ...(selectedMdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
            ? [customMdEntityNameInput]
            : []),
        ],
      };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedMdEntity:
        | MdDefaultEntities
        | typeof MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'mdEntity',
      )?.value;

      if (selectedMdEntity === undefined) {
        return { inputs: [] };
      }

      switch (parameters.intent as Intent) {
        case Intent.CREATE_RECORD:
          return {
            inputs: [
              ...(selectedMdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
                ? [
                    {
                      ...additionalFieldsInput,
                      title: 'Record Fields',
                      subtitle:
                        'Use Connect Portal Workflow Settings to allow users to select a Field Mapping to specify which fields to update.',
                    },
                  ]
                : inputsForDefaultEntitiesConnect[selectedMdEntity]),
            ],
          };
        case Intent.UPDATE_RECORD:
          return {
            inputs: [
              entityIdInputFactory(selectedMdEntity, 'update'),
              ...(selectedMdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
                ? [
                    {
                      ...additionalFieldsInput,
                      title: 'Record Fields',
                      subtitle:
                        'Use Connect Portal Workflow Settings to allow users to select a Field Mapping to specify which fields to update.',
                    },
                  ]
                : inputsForDefaultEntitiesConnect[selectedMdEntity].map((input: SidebarInput) => ({
                    ...input,
                    required: false,
                    subtitle: input.subtitle?.toString().replace(' This field is required.', ''),
                  }))),
            ],
          };

        case Intent.GET_RECORD_BY_ID:
          return {
            inputs: [entityIdInputFactory(selectedMdEntity, 'fetch')],
          };
        case Intent.SEARCH_RECORDS:
          return {
            inputs: [getFilterInputConnect(selectedMdEntity)],
          };

        case Intent.DELETE_RECORD:
          return { inputs: [entityIdInputFactory(selectedMdEntity, 'delete')] };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
