export default `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.50746 12.0489H9.49253V9.35164H11.9701V8.39261H9.49253V5.69531H8.50746V8.39261H6.05969V9.35164H8.50746V12.0489Z" fill="#E8762D"/>
<path d="M3.94034 15.9455H4.77616V13.5479H6.98512V12.7987H4.77616V10.3711H3.94034V12.7987H1.73138V13.5479H3.94034V15.9455Z" fill="#C72037"/>
<path d="M13.2238 7.28273H14.0597V4.88513H16.2985V4.16585H14.0597V1.73828H13.2238V4.16585H11.0149V4.88513H13.2238V7.28273Z" fill="#5B879B"/>
<path d="M8.65673 17.8311H9.37315V16.1228H10.8955V15.4934H9.37315V13.7852H8.65673V15.4934H7.16418V16.1228H8.65673V17.8311Z" fill="#5C6692"/>
<path d="M3.97019 7.28273H4.74631V4.85516H6.98512V4.16585H4.74631V1.73828H3.97019V4.16585H1.73138V4.85516H3.97019V7.28273Z" fill="#EB9129"/>
<path d="M15.7612 10.8754H16.4776V9.19703H18V8.53769H16.4776V6.85938H15.7612V8.53769H14.2687V9.19703H15.7612V10.8754Z" fill="#5C6692"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.2238 15.9455H14.0597V13.5479H16.2985V12.7987H14.0597V10.3711H13.2238V12.7987H11.0149V13.5479H13.2238V15.9455Z" fill="#1F457E"/>
<path d="M10.7761 2.15784V1.64835H9.28359V0H8.74627V1.64835H7.25374V2.15784H8.74627V3.80619H9.28359V2.15784H10.7761ZM1.49254 10.7592H2.02985V9.11087H3.52239V8.60138H2.02985V6.983H1.49254V8.60138H0V9.14084L1.49254 9.11087V10.7592Z" fill="#7199A6"/>
</svg>`;
