import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { CredentialKeys, Intent } from '../configs';
import { supportedOperators } from '../shared/constants';
import { LinkWrapperType, RecordType } from '../shared/types';

export const domainInput: SidebarInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.STORE_DOMAIN,
  title: 'Enter your Adobe Commerce store domain name',
  placeholder: 'example.com',
  required: true,
};

export const usernameInput: SidebarInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.USERNAME,
  title: 'Username',
  required: true,
};

export const passwordInput: SidebarInput = {
  type: SidebarInputType.Password,
  id: CredentialKeys.PASSWORD,
  title: 'Password',
  required: true,
};

export const LinkWrapper: React.FC<LinkWrapperType> = ({
  clickableText,
  href,
}: LinkWrapperType) => (
  <a href={href} target="_blank" rel="noreferrer">
    {clickableText}
  </a>
);

export const intentSelectorDropdown: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Customers',
          items: [
            {
              value: Intent.CREATE_CUSTOMER,
              label: 'Create Customer',
            },
            {
              value: Intent.UPDATE_CUSTOMER,
              label: 'Update Customer',
            },
            {
              value: Intent.GET_CUSTOMER_BY_ID,
              label: 'Get Customer by ID',
            },
            {
              value: Intent.SEARCH_CUSTOMERS,
              label: 'Search Customers',
            },
            {
              value: Intent.DELETE_CUSTOMER,
              label: 'Delete Customer',
            },
          ],
        },
        {
          title: 'Orders',
          items: [
            {
              value: Intent.CREATE_ORDER,
              label: 'Create Order',
            },
            {
              value: Intent.UPDATE_ORDER,
              label: 'Update Order',
            },
            {
              value: Intent.GET_ORDER_BY_ID,
              label: 'Get Order by ID',
            },
            {
              value: Intent.SEARCH_ORDERS,
              label: 'Search Orders',
            },
          ],
        },
        {
          title: 'Products',
          items: [
            {
              value: Intent.CREATE_PRODUCT,
              label: 'Create Product',
            },
            {
              value: Intent.UPDATE_PRODUCT,
              label: 'Update Product',
            },
            {
              value: Intent.GET_PRODUCT_BY_ID,
              label: 'Get Product by SKU',
            },
            {
              value: Intent.SEARCH_PRODUCTS,
              label: 'Search Products',
            },
            {
              value: Intent.DELETE_PRODUCT,
              label: 'Delete Product',
            },
          ],
        },
      ],
    },
  ],
};

export const recordTypeInput = (record: RecordType): SidebarInput => ({
  id: 'recordType',
  title: 'Record Type',
  type: SidebarInputType.Text,
  required: false,
  overrideValue: record,
  hidden: true,
});

export const firstNameInput = (required: boolean): SidebarInput => ({
  id: 'firstname',
  title: 'First Name',
  required,
  type: SidebarInputType.TextArea,
  lines: 1,
});

export const lastNameInput = (required: boolean): SidebarInput => ({
  id: 'lastname',
  title: 'Last Name',
  required,
  type: SidebarInputType.TextArea,
  lines: 1,
});

export const emailInput = (required: boolean): SidebarInput => ({
  id: 'email',
  title: 'Email Address',
  required,
  type: SidebarInputType.TextArea,
  lines: 1,
});

export const streetAddressInput: SidebarInput = {
  id: 'street',
  title: 'Street Address',
  required: false,
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: '["1700 Sawtelle Blvd."]',
  subtitle: 'Specify the street address as an array.',
};

export const cityInput: SidebarInput = {
  id: 'city',
  title: 'City',
  required: false,
  type: SidebarInputType.TextArea,
  lines: 1,
};

export const postalCodeInput: SidebarInput = {
  id: 'postcode',
  title: 'Postal Code',
  required: false,
  type: SidebarInputType.TextArea,
  lines: 1,
};

export const countryInput: SidebarInput = {
  id: 'country_id',
  title: 'Country',
  required: false,
  type: SidebarInputType.TextArea,
  lines: 1,
  subtitle: 'Specify the country code in ISO_3166-2 format.',
};

export const phoneInput: SidebarInput = {
  id: 'telephone',
  title: 'Phone',
  required: false,
  type: SidebarInputType.TextArea,
  lines: 1,
};

export const customAttributesInput: SidebarInput = {
  id: 'custom_attributes',
  title: 'Custom Attributes',
  required: false,
  type: SidebarInputType.Code,
  language: 'json',
  useLightTheme: true,
  lines: 4,
  subtitle:
    "Specify any custom attributes configured in your users' Adobe Commerce instances as a JSON Object.",
};

export const customerAdditonalFieldsInput: SidebarInput = {
  id: 'customerAdditionalFields',
  title: 'Additional Fields',
  required: false,
  type: SidebarInputType.Code,
  language: 'json',
  useLightTheme: true,
  lines: 4,
  subtitle: (
    <>
      Specify additional fields to include on this Customer as JSON. For example, you may specify a
      different Billing Address, Group ID or Store ID here.{' '}
      <LinkWrapper
        clickableText="See the docs"
        href="https://magento.redoc.ly/2.3.7-admin/tag/customers"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `
  [
    {
      "middlename": "Doe"
    }
  ]`,
};

export const customerIdInput = (subtitle: string): SidebarInput => ({
  id: 'customerId',
  title: 'Customer ID',
  required: true,
  type: SidebarInputType.TextArea,
  lines: 1,
  subtitle,
});

export const customerFilterSearch: SidebarInput = {
  id: 'customerFilterSearch',
  title: 'Filter Search',
  subtitle: 'Filter Search',
  type: SidebarInputType.Conditional,
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Last Name', value: 'lastname' },
    {
      label: 'Email',
      value: 'email',
    },
  ],
};

export const customerEmailInput: SidebarInput = {
  id: 'customer_email',
  title: 'Customer Email',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const baseGrandTotalInput: SidebarInput = {
  id: 'base_grand_total',
  title: 'Base Grand Total',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Specify the base grand total of the order.',
};

export const grandTotalInput: SidebarInput = {
  id: 'grand_total',
  title: 'Grand Total',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Specify the grand total of the order.',
};

export const lineItemsInput: SidebarInput = {
  id: 'items',
  title: 'Line Items',
  type: SidebarInputType.Code,
  language: 'json',
  useLightTheme: true,
  lines: 5,
  required: false,
  subtitle: (
    <>
      Specify the items as a JSON array. View the{' '}
      <LinkWrapper
        clickableText="Adobe Commerce docs"
        href="https://magento.redoc.ly/2.3.7-admin/tag/orderscreate/#operation/salesOrderRepositoryV1SavePut"
      />{' '}
      for a list of the line item properties.
    </>
  ),
  placeholder: `
  [
    {
      "name": "Big Brown Bear Boots",
      "price": 74.99,
      "qty_ordered": 3,
      "sku": "SKU123" 
    }
  ]`,
};

export const paymentInput = (required: boolean): SidebarInput => ({
  id: 'payment',
  title: 'Payment',
  type: SidebarInputType.Code,
  language: 'json',
  useLightTheme: true,
  lines: 5,
  required,
  subtitle: (
    <>
      Specify the payment object. View the{' '}
      <LinkWrapper
        clickableText="Adobe Commerce docs"
        href="https://magento.redoc.ly/2.3.7-admin/tag/orderscreate/#operation/salesOrderRepositoryV1SavePut"
      />{' '}
      for a list of the required payment properties.
    </>
  ),
  placeholder: `
{
  "account_status": null,
  "additional_information": [
      "Check / Money order"
  ],
  "cc_last4": null,
  "method": "checkmo"
}`,
});

export const addressInput: SidebarInput = {
  id: 'addresses',
  title: 'Address',
  type: SidebarInputType.Code,
  language: 'json',
  useLightTheme: true,
  lines: 8,
  required: false,
  subtitle: (
    <>
      Specify an Address object as a JSON array. View the{' '}
      <LinkWrapper
        clickableText="Adobe Commerce docs"
        href="https://magento.redoc.ly/2.3.7-admin/tag/customers#operation/customerAccountManagementV1CreateAccountPost"
      />{' '}
      for a list of the required address properties.
    </>
  ),
  placeholder: `
  [
    {
        "firstname": "Kakashi",
        "lastname": "Hatake",
        "city": "PA",
        "region": "Palau",
        "postcode": "19103",
        "country_id": "US",
        "telephone": "611-634-1181",
        "street": [
            "2354 Jody Road Philadelphia",
            "844 Jefferson Street; 4510 Willis Avenue"
        ]
    }
]`,
};

export const orderAdditionalFields: SidebarInput = {
  id: 'orderAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.Code,
  language: 'json',
  useLightTheme: true,
  lines: 4,
  subtitle: (
    <>
      Specify additional fields to include on this order as JSON. For example, you may specify a tax
      amount here.{' '}
      <LinkWrapper
        clickableText="See the docs"
        href="https://magento.redoc.ly/2.3.7-admin/tag/orderscreate/#operation/salesOrderRepositoryV1SavePut"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `
  [
    {
      "tax_amount": "7.44",
    }
  ]`,
  required: false,
};

export const entityIdInput = (title: string, subtitle: string): SidebarInput => ({
  id: 'entityId',
  title,
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle,
});

export const orderFilterSearch: SidebarInput = {
  id: 'orderFilterSearch',
  title: 'Filter Search',
  subtitle: 'Filter Search',
  type: SidebarInputType.Conditional,
  supportedOperators: [
    ...supportedOperators,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
  ],
  getValues: () => [
    { label: 'Created On', value: 'created_at' },
    {
      label: 'Base Grand Total',
      value: 'base_grand_total',
    },
  ],
};

export const skuInput = (subtitle: string): SidebarInput => ({
  id: 'sku',
  title: 'SKU',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle,
  placeholder: '24-UG06',
});

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: 'Backpack',
};

export const productTypeInput: SidebarInput = {
  id: 'type_id',
  title: 'Product Type',
  type: SidebarInputType.TextArea,
  lines: 1,
  subtitle: 'Specify the Type ID for this product',
  required: false,
};

export const priceInput: SidebarInput = {
  id: 'price',
  title: 'Price',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const visibilityInput: SidebarInput = {
  id: 'visibility',
  title: 'Visibility',
  type: SidebarInputType.EditableEnum,
  getValues: () => [
    { label: 'Not Visible Individually', value: '1' },
    { label: 'Catalog', value: '2' },
    { label: 'Search', value: '3' },
    { label: 'Catalog,Search', value: '4' },
  ],
  required: false,
  subtitle: 'Specify the visibility state for this product',
};

export const productAdditionalFields: SidebarInput = {
  id: 'productAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 6,
  useLightTheme: true,
  subtitle: (
    <>
      Specify additional fields to include on this product as JSON. For example, you may specify a
      custom attributes here.{' '}
      <LinkWrapper
        clickableText="See the docs"
        href="https://magento.redoc.ly/2.3.7-admin/tag/products/#operation/catalogProductRepositoryV1SavePost"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `
  [
    {
      "custom_attributes": [
        {
          "attribute_code": "My attribute",
          "value": "Associated value"
        }
      ],
    }
  ]`,
  required: false,
};

export const productFilterSearch: SidebarInput = {
  id: 'productFilterSearch',
  title: 'Filter Search',
  subtitle: 'Filter Search',
  type: SidebarInputType.Conditional,
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'SKU', value: 'sku' },
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Product Type',
      value: 'type_id',
    },
  ],
};

export const attributeIdInput: SidebarInput = {
  id: 'attribute_set_id',
  title: 'Attribute ID',
  type: SidebarInputType.EditableEnum,
  getValues: () => [
    { label: 'Top', value: '9' },
    { label: 'Default', value: '4' },
    { label: 'Bottom', value: '10' },
    { label: 'Bag', value: '15' },
  ],
  required: false,
  subtitle: 'Specify the Attribute ID for this product',
};

export const productUniqueId: SidebarInput = {
  id: 'id',
  title: 'ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Specify the Unique ID if SKU is not available in products.',
  placeholder: '2345',
};

export const customerInputs = (createCustomerIntent: boolean): SidebarInput[] => [
  recordTypeInput(RecordType.CUSTOMERS),
  ...(!createCustomerIntent
    ? [customerIdInput('Specify the Customer ID of the customer to update.')]
    : []),
  firstNameInput(createCustomerIntent),
  lastNameInput(createCustomerIntent),
  emailInput(createCustomerIntent),
  addressInput,
  customAttributesInput,
  customerAdditonalFieldsInput,
];

export const orderInputs = (createOrderIntent: boolean): SidebarInput[] => [
  recordTypeInput(RecordType.ORDERS),
  ...(!createOrderIntent
    ? [entityIdInput('Entity ID', 'Specify the entity ID of the order to update.')]
    : []),
  customerEmailInput,
  baseGrandTotalInput,
  grandTotalInput,
  lineItemsInput,
  paymentInput(createOrderIntent),
  orderAdditionalFields,
];

export const productInputs = (createProductIntent: boolean): SidebarInput[] => [
  recordTypeInput(RecordType.PRODUCTS),
  skuInput(`Specify the SKU of the product to ${createProductIntent ? 'create' : 'update'}.`),
  ...(createProductIntent ? [productUniqueId] : []),
  nameInput,
  attributeIdInput,
  productTypeInput,
  priceInput,
  visibilityInput,
  productAdditionalFields,
];
