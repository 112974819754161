export default `<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="96" height="96">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 48C0 52.2526 2.94943 55.8163 6.91426 56.7571C10.3435 72.9252 23.0749 85.6565 39.2429 89.0857C40.1837 93.0506 43.7474 96 48 96C52.2526 96 55.8163 93.0506 56.7571 89.0857C72.9251 85.6565 85.6565 72.9252 89.0857 56.7572C93.0505 55.8164 95.9999 52.2527 95.9999 48.0001C95.9999 43.7476 93.0506 40.1839 89.0858 39.243C85.6566 23.0749 72.9252 10.3435 56.7571 6.91426C55.8163 2.94943 52.2526 0 48 0C43.7474 0 40.1837 2.94943 39.2429 6.91426C23.0748 10.3435 10.3435 23.0749 6.91426 39.2429C2.94943 40.1837 0 43.7474 0 48Z" fill="#8DF7DB"/>
</mask>
<g mask="url(#mask0)">
<circle cx="48" cy="48" r="48" fill="url(#paint0_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M52 17.0645C54.9634 15.5918 57 12.5337 57 9C57 4.02944 52.9706 0 48 0C43.0294 0 39 4.02944 39 9C39 12.5337 41.0365 15.5918 44 17.0645V23H52V17.0645Z" fill="#9DF3F9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44 78.9355C41.0366 80.4082 39 83.4663 39 87C39 91.9706 43.0294 96 48 96C52.9706 96 57 91.9706 57 87C57 83.4663 54.9634 80.4082 52 78.9355L52 73L44 73L44 78.9355Z" fill="#82EBF1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0645 44C15.5918 41.0366 12.5337 39 9 39C4.02944 39 -6.10673e-07 43.0294 -3.93402e-07 48C-1.76132e-07 52.9706 4.02944 57 9 57C12.5337 57 15.5918 54.9634 17.0645 52L23 52L23 44L17.0645 44Z" fill="#9DF3F9"/>
<g filter="url(#filter0_f)">
<circle cx="48" cy="50" r="26" fill="black" fill-opacity="0.32"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3172 54.0001C57.6698 58.6609 53.2249 62.0001 48 62.0001C41.3726 62.0001 36 56.6275 36 50.0001C36 43.3727 41.3726 38.0001 48 38.0001C53.2249 38.0001 57.6698 41.3394 59.3172 46.0001H80V54.0001H59.3172Z" fill="black" fill-opacity="0.32"/>
<path d="M87 59.0001C82.0294 59.0001 78 54.9707 78 50.0001C78 45.0296 82.0294 41.0001 87 41.0001C91.9706 41.0001 96 45.0296 96 50.0001C96 54.9707 91.9706 59.0001 87 59.0001Z" fill="black" fill-opacity="0.32"/>
</g>
<g filter="url(#filter1_f)">
<circle cx="48" cy="48.38" r="26" fill="black" fill-opacity="0.24"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3172 52.3801C57.6698 57.0409 53.2249 60.3801 48 60.3801C41.3726 60.3801 36 55.0075 36 48.3801C36 41.7527 41.3726 36.3801 48 36.3801C53.2249 36.3801 57.6698 39.7194 59.3172 44.3801H80V52.3801H59.3172Z" fill="black" fill-opacity="0.24"/>
<path d="M87 57.3801C82.0294 57.3801 78 53.3507 78 48.3801C78 43.4096 82.0294 39.3801 87 39.3801C91.9706 39.3801 96 43.4096 96 48.3801C96 53.3507 91.9706 57.3801 87 57.3801Z" fill="black" fill-opacity="0.24"/>
</g>
<circle cx="48" cy="48" r="26" fill="url(#paint1_linear)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3172 52.0001C57.6698 56.6609 53.2249 60.0001 48 60.0001C41.3726 60.0001 36 54.6275 36 48.0001C36 41.3727 41.3726 36.0001 48 36.0001C53.2249 36.0001 57.6698 39.3394 59.3172 44.0001H80V52.0001H59.3172Z" fill="url(#paint2_linear)"/>
<path d="M87 57.0001C82.0294 57.0001 78 52.9707 78 48.0001C78 43.0296 82.0294 39.0001 87 39.0001C91.9706 39.0001 96 43.0296 96 48.0001C96 52.9707 91.9706 57.0001 87 57.0001Z" fill="url(#paint3_linear)"/>
</g>
</g>
<defs>
<filter id="filter0_f" x="14" y="16" width="90" height="68" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur"/>
</filter>
<filter id="filter1_f" x="21.24" y="21.62" width="75.52" height="53.52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.38" result="effect1_foregroundBlur"/>
</filter>
<linearGradient id="paint0_linear" x1="22.7027" y1="11.3514" x2="72.6487" y2="84.6487" gradientUnits="userSpaceOnUse">
<stop stop-color="#009CA8"/>
<stop offset="1" stop-color="#007D85"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="31.7838" y1="26.2703" x2="66.1622" y2="83.3514" gradientUnits="userSpaceOnUse">
<stop stop-color="#36E3EE"/>
<stop offset="1" stop-color="#00B1BE"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="31.1352" y1="24" x2="75.2433" y2="63.5676" gradientUnits="userSpaceOnUse">
<stop stop-color="#D2F6F9"/>
<stop offset="1" stop-color="#82EBF1"/>
<stop offset="1" stop-color="#82EBF1"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="96.9731" y1="50.919" x2="72.3244" y2="42.1622" gradientUnits="userSpaceOnUse">
<stop stop-color="#9AEEF3"/>
<stop offset="1" stop-color="#82EBF1"/>
</linearGradient>
<clipPath id="clip0">
<rect width="96" height="96" fill="white"/>
</clipPath>
</defs>
</svg>`;
