export const GUSTO_API_VERSION: string = '/v1';

/**
 * Gusto auth api base url
 */
export const GUSTO_AUTH_BASE_URL: string = 'https://api.gusto.com/oauth';

/**
 * Gusto api base url
 */
export const GUSTO_API_BASE_URL: string = `https://api.gusto.com${GUSTO_API_VERSION}`;

/**
 * Gusto demo auth api base url
 */
export const GUSTO_DEMO_AUTH_BASE_URL: string = 'https://api.gusto-demo.com/oauth';

/**
 * Gusto demo api base url
 */
export const GUSTO_DEMO_API_BASE_URL: string = `https://api.gusto-demo.com${GUSTO_API_VERSION}`;
