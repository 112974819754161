import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MARKETO;
  sidebarSections: SidebarSectionFactory[];
  providerDataKeyLabelMap?: Record<keyof ProviderData, string>;
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  ADD_LEADS_TO_LIST = 'MARKETO_ADD_LEADS_TO_LIST',
  CREATE_OR_UPDATE_LEAD = 'MARKETO_CREATE_OR_UPDATE_LEAD',
  GET_FIELDS = 'MARKETO_GET_FIELDS',
  GET_LEADS = 'MARKETO_GET_LEADS',
  GET_LEAD_BY_ID = 'MARKETO_GET_LEAD_BY_ID',
  GET_LISTS = 'MARKETO_GET_LISTS',
  GET_ALL_CUSTOM_OBJECTS = 'MARKETO_GET_ALL_CUSTOM_OBJECTS',
  CREATE_CUSTOM_OBJECT = 'MARKETO_CREATE_CUSTOM_OBJECT',
  GET_CUSTOM_OBJECT_FIELDS = 'MARKETO_GET_CUSTOM_OBJECT_FIELDS',
  TEST_CONNECTION = 'MARKETO_TEST_CONNECTION',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_OR_UPDATE_LEAD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LEADS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LEAD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_LEADS_TO_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LISTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_CUSTOM_OBJECTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CUSTOM_OBJECT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_FIELDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CUSTOM_OBJECT_FIELDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ENDPOINT = 'MARKETO_ENDPOINT',
  IDENTITY = 'MARKETO_IDENTITY',
  CLIENT_ID = 'MARKETO_CLIENT_ID',
  CLIENT_SECRET = 'MARKETO_CLIENT_SECRET',
}

export type Credentials = {
  [CredentialKeys.CLIENT_ID]: string;
  [CredentialKeys.CLIENT_SECRET]: string;
  [CredentialKeys.ENDPOINT]: string;
  [CredentialKeys.IDENTITY]: string;
};

export type ProviderData = {
  endpointUrl: string;
  identityUrl: string;
};
