export default `<svg width="74" height="76" viewBox="0 0 74 76" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M72.3998 35.76L39.7998 3.16L36.6398 0L12.0998 24.54L0.879802 35.76C0.318 36.3225 0.00244141 37.085 0.00244141 37.88C0.00244141 38.675 0.318 39.4375 0.879802 40L23.2998 62.42L36.6398 75.76L61.1798 51.22L61.5598 50.84L72.3998 40C72.9616 39.4375 73.2772 38.675 73.2772 37.88C73.2772 37.085 72.9616 36.3225 72.3998 35.76V35.76ZM36.6398 49.08L25.4398 37.88L36.6398 26.68L47.8398 37.88L36.6398 49.08Z" fill="#2684FF"/>
<path d="M36.6398 26.6801C33.1173 23.1571 31.1317 18.3835 31.1167 13.4016C31.1017 8.41967 33.0585 3.63419 36.5598 0.0900879L12.0498 24.5901L25.3898 37.9301L36.6398 26.6801Z" fill="url(#paint0_linear)"/>
<path d="M47.8696 37.8501L36.6396 49.0801C38.3921 50.8316 39.7824 52.9112 40.7309 55.2001C41.6794 57.489 42.1676 59.9424 42.1676 62.4201C42.1676 64.8978 41.6794 67.3512 40.7309 69.6401C39.7824 71.929 38.3921 74.0086 36.6396 75.7601V75.7601L61.2096 51.1901L47.8696 37.8501Z" fill="url(#paint1_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="34.6398" y1="15.3501" x2="18.9998" y2="30.9901" gradientUnits="userSpaceOnUse">
<stop offset="0.18" stop-color="#0052CC"/>
<stop offset="1" stop-color="#2684FF"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="989.464" y1="2323.07" x2="1529.64" y2="1972.97" gradientUnits="userSpaceOnUse">
<stop offset="0.18" stop-color="#0052CC"/>
<stop offset="1" stop-color="#2684FF"/>
</linearGradient>
</defs>
</svg>`;
