import React from 'react';

import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  LinkWrapper,
  additionalFieldsInput,
  descriptionInput,
  externalIdInput,
  intentTypeInput,
  issueFilterInput,
  issueIdInput,
  parentIdInput,
  projectIdInput,
  queryTermInput,
  teamFilterInput,
  teamIdInput,
  titleInput,
  upsertIssueFields,
  upsertProjectFields,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.LINEAR,
  name: 'Linear',
  description: "Connect to your users' Linear accounts",
  icon: iconSvg,
  provider: ProviderType.LINEAR,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_ISSUE:
        case Intent.UPDATE_ISSUE:
          return {
            inputs: upsertIssueFields(parameters.intent === Intent.UPDATE_ISSUE),
          };

        case Intent.GET_ISSUE_BY_ID:
          return {
            inputs: [{ ...issueIdInput, subtitle: 'Specify the record ID of the issue to fetch.' }],
          };

        case Intent.GET_ISSUE_BY_ISSUE_IDENTIFIER:
          return {
            inputs: [externalIdInput],
          };

        case Intent.SEARCH_ISSUE:
          return {
            inputs: [queryTermInput, issueFilterInput],
          };

        case Intent.DELETE_ISSUE:
          return {
            inputs: [
              { ...issueIdInput, subtitle: 'Specify the record ID of the issue to delete.' },
            ],
          };

        case Intent.ARCHIVE_ISSUE:
          return {
            inputs: [
              { ...issueIdInput, subtitle: 'Specify the record ID of the issue to archive.' },
            ],
          };

        case Intent.CREATE_SUB_ISSUE:
          return {
            inputs: [
              parentIdInput,
              {
                ...teamIdInput,
                subtitle:
                  'Specify the Team ID of the parent for this new sub-issue. Use Connect Portal Workflow Settings to allow users to select a Team ID.',
              },
              titleInput,
              descriptionInput,
              {
                ...additionalFieldsInput,
                subtitle: (
                  <>
                    Specify additional fields to include on this project as JSON. For example, you
                    may associate the issue with a Project Lead here.{' '}
                    <LinkWrapper
                      clickableText="See the docs"
                      href="https://studio.apollographql.com/public/Linear-API/schema/reference/objects/Project?variant=current"
                    />{' '}
                    for available fields.
                  </>
                ),
                placeholder: `{
  lead: <Linear User ID>
}`,
              },
            ],
          };

        case Intent.CREATE_PROJECT:
        case Intent.UPDATE_PROJECT:
          return {
            inputs: upsertProjectFields(parameters.intent === Intent.UPDATE_PROJECT),
          };

        case Intent.GET_PROJECT_BY_ID:
          return {
            inputs: [
              {
                ...projectIdInput,
                subtitle: 'Specify the Project ID of the project to fetch.',
                placeholder: 'a6634484-6061-4ac7-9739-7dc5e52c796b',
              },
            ],
          };

        case Intent.DELETE_PROJECT:
          return {
            inputs: [
              {
                ...projectIdInput,
                subtitle: 'Specify the Project ID of the project to delete.',
                placeholder: 'a6634484-6061-4ac7-9739-7dc5e52c796b',
              },
            ],
          };

        case Intent.SEARCH_TEAMS:
          return {
            inputs: [teamFilterInput],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
