import { ScopeName } from '@shared/types/sdk/actions';

export const requiredScopes: ScopeName[] = [
  'email',
  'https://www.googleapis.com/auth/gmail.send',
  'https://www.googleapis.com/auth/gmail.compose',
  'https://www.googleapis.com/auth/gmail.readonly',
  'https://www.googleapis.com/auth/contacts',
  'https://mail.google.com',
];
