import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GMAIL;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'GMAIL_TEST_CONNECTION',
  SEND_EMAIL = 'GMAIL_SEND_EMAIL',
  GET_EMAIL_BY_ID = 'GMAIL_GET_EMAIL_BY_ID',
  SEARCH_FOR_EMAIL = 'GMAIL_SEARCH_FOR_EMAIL',
  DELETE_EMAIL = 'GMAIL_DELETE_EMAIL',
  CREATE_A_CONTACT = 'GMAIL_CREATE_A_CONTACT',
  GET_CONTACT_BY_RESOURCE_NAME = 'GMAIL_GET_CONTACT_BY_RESOURCE_NAME',
  SEARCH_FOR_CONTACT = 'GMAIL_SEARCH_FOR_CONTACT',
  DELETE_CONTACT = 'GMAIL_DELETE_CONTACT',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_EMAIL_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_FOR_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_A_CONTACT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CONTACT_BY_RESOURCE_NAME;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_FOR_CONTACT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_CONTACT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  REFRESH_TOKEN = 'GMAIL_REFRESH_TOKEN',
  ACCESS_TOKEN = 'GMAIL_ACCESS_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
};
