import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class UserError extends ParagonError {
  name: string = 'UserError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class UserWithEmailNotFoundError extends UserError {
  name: string = 'UserWithEmailNotFoundError';

  constructor(meta: { email: string }) {
    super({
      code: ERROR_CODE.USER_WITH_EMAIL_NOT_FOUND_ERROR,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'No user with that email exists.',
      meta,
    });
  }
}

export class UserWithIdNotFoundError extends UserError {
  name: string = 'UserWithIdNotFoundError';

  constructor(meta: { userId: string; email?: string }) {
    super({
      code: ERROR_CODE.USER_WITH_EMAIL_NOT_FOUND_ERROR,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'No user with that id exists.',
      meta,
    });
  }
}

export class UserWithEmailAlreadyExistsError extends UserError {
  name: string = 'UserWithEmailAlreadyExistsError';

  constructor(meta: { email: string }) {
    super({
      code: ERROR_CODE.USER_WITH_EMAIL_ALREADY_EXISTS_ERROR,
      httpStatus: HttpStatus.BAD_REQUEST,
      message:
        'A user with that email already exists. Please login to your account or reset your password.',
      meta,
    });
  }
}

export class UserSignupWithEmailFailedError extends UserError {
  name: string = 'UserSignupWithEmailFailedError';

  constructor(meta: { email: string; inviteId?: string }) {
    super({
      code: ERROR_CODE.USER_SIGNUP_WITH_EMAIL_FAILED,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Signup failed. Please try again.',
      meta,
    });
  }
}

export class UserSignupFailedWithMissingRequiredFieldsError extends UserError {
  name: string = 'UserSignupFailedWithMissingRequiredFieldsError';

  constructor(meta: { email?: string; firstName?: string; lastName?: string }) {
    super({
      code: ERROR_CODE.USER_SIGNUP_MISSING_REQUIRED_FIELDS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Signup failed. missing required fields',
      meta,
    });
  }
}

export class UserSignupWithEmailFailedForEnterpriseError extends UserError {
  name: string = 'UserSignupWithEmailFailedForEnterpriseError';

  constructor(meta: { email: string; projectId?: string }) {
    super({
      code: ERROR_CODE.USER_SIGNUP_WITH_EMAIL_ENTERPRISE_FAILED,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'You must be invited by email to create an account on enterprise installations.',
      meta,
    });
  }
}

export class InvalidLoginCredentialsError extends UserError {
  name: string = 'InvalidLoginCredentialsError';

  constructor(meta: { email: string; impersonating?: string }) {
    super({
      code: ERROR_CODE.INVALID_LOGIN_CREDENTIALS,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Invalid login credentials.',
      meta,
    });
  }
}

export class UserMissingTeamError extends UserError {
  name: string = 'UserMissingTeamError';

  constructor(meta: { userId: string } | { email: string }) {
    super({
      code: ERROR_CODE.USER_MISSING_TEAM,
      httpStatus: HttpStatus.FORBIDDEN,
      message: "Your account isn't associated with any teams. Please contact support.",
      meta,
    });
  }
}

export class UserAccountDisabledError extends UserError {
  name: string = 'UserAccountDisabledError';

  constructor(meta: { userId: string; email?: string } | { email: string; userId?: string }) {
    super({
      code: ERROR_CODE.USER_MISSING_TEAM,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message:
        'Your account has been disabled. Please contact support@useparagon.com if you have any questions.',
      meta,
    });
  }
}

export class ResetPasswordLinkInvalidError extends UserError {
  name: string = 'ResetPasswordLinkInvalidError';

  constructor(meta: { error?: Error; email?: string }) {
    super({
      code: ERROR_CODE.RESET_PASSWORD_INVALID,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Reset password link is invalid.',
      meta,
    });
  }
}

export class ResetPasswordLinkExpiredError extends UserError {
  name: string = 'ResetPasswordLinkExpiredError';

  constructor(meta: { expiration: number }) {
    super({
      code: ERROR_CODE.RESET_PASSWORD_LINK_EXPIRED,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Reset password link has expired. Please request a new one.',
      meta: {
        ...meta,
        now: Date.now(),
      },
    });
  }
}
