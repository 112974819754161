import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class ConnectEventError extends ParagonError {
  name: string = 'ConnectEventError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class ConnectEventNotFoundFromIdError extends ConnectEventError {
  name: string = 'ConnectEventNotFoundFromIdError';

  constructor(meta: { eventId: string }) {
    super({
      code: ERROR_CODE.CONNECT_EVENT_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find event.',
      meta,
    });
  }
}

export class ConnectEventNotFoundFromNameError extends ConnectEventError {
  name: string = 'ConnectEventNotFoundFromNameError';

  constructor(meta: { eventName: string; projectId: string; personaId?: string }) {
    super({
      code: ERROR_CODE.CONNECT_EVENT_NOT_FOUND_FROM_NAME,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find event.',
      meta,
    });
  }
}

export class ConnectEventArrayEmptyError extends ConnectEventError {
  name: string = 'ConnectEventArrayEmptyError';

  constructor(meta: { projectId: string; personaId: string }) {
    super({
      code: ERROR_CODE.CONNECT_EVENT_ARRAY_EMPTY,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'No events sent.',
      meta,
    });
  }
}

export class ConnectEventArrayTooLargeError extends ConnectEventError {
  name: string = 'ConnectEventArrayTooLargeError';

  constructor(meta: { projectId: string; personaId: string; numEvents: number; limit: number }) {
    super({
      code: ERROR_CODE.CONNECT_EVENT_ARRAY_TOO_LARGE,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'You can only send upto 1,000 events at once.',
      meta,
    });
  }
}

export class DuplicateEventError extends ConnectEventError {
  name: string = 'DuplicateEventError';

  constructor(meta: { eventName: string }) {
    super({
      code: ERROR_CODE.CONNECT_DUPLICATE_EVENT,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Event "${meta.eventName}" already exists.`,
      meta,
    });
  }
}
