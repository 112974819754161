import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.ONESIGNAL;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.ONESIGNAL;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'ONESIGNAL_TEST_CONNECTION',
  SEND_TO_SEGMENT = 'ONESIGNAL_SEND_TO_SEGMENT',
  SEND_TO_PLAYER_ID = 'ONESIGNAL_SEND_TO_PLAYER_ID',
  SEND_TO_EXTERNAL_ID = 'ONESIGNAL_SEND_TO_EXTERNAL_ID',
  SEND_USING_FILTERS = 'ONESIGNAL_SEND_USING_FILTERS',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_TO_SEGMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_TO_PLAYER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_TO_EXTERNAL_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_USING_FILTERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  APP_ID: string;
  REST_API_KEY: string;
};
