import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'openid'
  | 'offline_access'
  | 'profile'
  | 'email'
  | 'accounting.transactions'
  | 'accounting.settings'
  | 'accounting.contacts'
  | 'accounting.reports.read'
  | 'files'
  | 'assets'
  | 'project';

export const scopes: Record<ScopeName, ScopeValue> = {
  openid: {
    name: 'openid',
    description: 'your application intends to use the user’s identity',
  },
  offline_access: {
    name: 'offline_access',
    description: 'To get a refresh token',
  },
  profile: {
    name: 'profile',
    description: 'first name, last name and xero user id',
  },
  email: {
    name: 'email',
    description: 'email address',
  },
  files: {
    name: 'files',
    description: 'View and manage your file library	',
  },
  assets: {
    name: 'assets',
    description: 'View and manage your fixed assets',
  },
  project: {
    name: 'project',
    description: 'View and manage your projects',
  },
  'accounting.transactions': {
    name: 'accounting.transactions',
    description: '	View and manage your business transactions',
  },
  'accounting.settings': {
    name: 'accounting.settings',
    description: 'View and manage your organisation settings',
  },
  'accounting.contacts': {
    name: 'accounting.contacts',
    description: 'View and manage your contacts',
  },
  'accounting.reports.read': {
    name: 'accounting.reports.read',
    description: 'Allows read access to accounting reports',
  },
};

export const requiredScopes: ScopeName[] = [
  'openid',
  'offline_access',
  'profile',
  'email',
  'accounting.transactions',
  'accounting.settings',
  'accounting.contacts',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Xero',
  description: 'Connect to your users’ Xero accounts',
  authUrl: 'https://login.xero.com/identity/connect/authorize',
  accessTokenUrl: 'https://identity.xero.com/connect/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/xero`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://developer.xero.com/documentation/api/accounting/overview',
};
