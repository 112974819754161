import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { ActionStepParameters, Intent } from '../configs';
import { List, Member, Space, Status, Teams } from '../shared';

const spaceCacheKey = 'spaceCacheKey';
const listCacheKey = 'listCacheKey';
const memberCacheKey = 'memberCacheKey';
const statusCacheKey = 'statusCacheKey';

export const getSpace: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: spaceCacheKey,
  title: 'Space',
  subtitle: 'ClickUp space to organize lists in',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<any>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((space: Space) => ({ label: space.name, value: space.id }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.CLICKUP,
      intent: Intent.GET_SPACE,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getList: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: listCacheKey,
  title: 'List',
  subtitle: 'ClickUp list to organize tasks in',
  refreshDependencies: [spaceCacheKey],
  hideFromConnectFieldTypes: true,
  mapRefreshToValues: (response: ActionResponse<any>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((list: List) => ({ label: list.name, value: list.id }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const space = pickValueSourceByKey(options.actionParameters, spaceCacheKey);
    return {
      actionType: Action.CLICKUP,
      intent: Intent.GET_LIST,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'spaceId',
          source: {
            type: 'VALUE',
            value: space?.value,
          },
        },
      ],
    };
  },
};

export const getStatus: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: statusCacheKey,
  title: 'Status',
  subtitle: 'ClickUp status to set for tasks',
  refreshDependencies: [spaceCacheKey],
  hideFromConnectFieldTypes: true,
  mapRefreshToValues: (response: ActionResponse<any>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((status: Status) => ({
          label: status.status,
          value: status.status,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const space = pickValueSourceByKey(options.actionParameters, spaceCacheKey);
    return {
      actionType: Action.CLICKUP,
      intent: Intent.GET_STATUS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'spaceId',
          source: {
            type: 'VALUE',
            value: space?.value,
          },
        },
      ],
    };
  },
};

export const getMember: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: memberCacheKey,
  title: 'Member',
  subtitle: 'ClickUp user to assign tasks to',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<any>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((member: Member) => ({
          label: member.user.username,
          value: member.user.id,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.CLICKUP,
      intent: Intent.GET_MEMBER,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const comboListClickUpFields: ComboInputDataSource = {
  id: 'comboFields',
  type: DataSourceType.COMBO_INPUT,
  title: 'List',
  subtitle: 'ClickUp list to organize tasks in',
  mainInputSource: getSpace,
  dependentInputSource: getList,
};

export const comboStatusClickUpFields: ComboInputDataSource = {
  id: 'comboStatusFields',
  type: DataSourceType.COMBO_INPUT,
  title: 'Status',
  subtitle: 'ClickUp status to set for tasks',
  mainInputSource: getSpace,
  dependentInputSource: getStatus,
};

export const getTeams: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'getTeams',
  title: 'Choose a ClickUp workspace to use',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  hideFromConnectFieldTypes: true,
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_TEAMS,
      actionType: Action.CLICKUP,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: function (response: ActionResponse<any>): EnumInputValue[] {
    return response?.result
      ? response.result.output.map((team: Teams) => ({
          label: team.name,
          value: team.id,
        }))
      : [];
  },
};
