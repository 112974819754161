import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MICROSOFT_DYNAMICS;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'MICROSOFT_DYNAMICS_TEST_CONNECTION',
  CREATE_RECORD = 'MICROSOFT_DYNAMICS_CREATE_RECORD',
  UPDATE_RECORD = 'MICROSOFT_DYNAMICS_UPDATE_RECORD',
  GET_RECORD_BY_ID = 'MICROSOFT_DYNAMICS_GET_RECORD_BY_ID',
  SEARCH_RECORDS = 'MICROSOFT_DYNAMICS_SEARCH_RECORDS',
  DELETE_RECORD = 'MICROSOFT_DYNAMICS_DELETE_RECORD',
  GET_FIELDS_FOR_ENTITY = 'MICROSOFT_DYNAMICS_GET_FIELDS_FOR_ENTITY',
  GET_ALL_CUSTOM_ENTITIES = 'MICROSOFT_DYNAMICS_GET_ALL_CUSTOM_ENTITIES',
  GET_ALL_CUSTOMERS = 'MICROSOFT_DYNAMICS_GET_ALL_CUSTOMERS',
  GET_ALL_OWNERS = 'MICROSOFT_DYNAMICS_GET_ALL_OWNERS',
  GET_ALL_PROCESS_STAGES = 'MICROSOFT_DYNAMICS_GET_ALL_PROCESS_STAGES',
  GET_ALL_TRANSACTION_CURRENCIES = 'MICROSOFT_DYNAMICS_GET_ALL_TRANSACTION_CURRENCIES',

  TRIGGER_RECORD_CREATED = 'MICROSOFT_DYNAMICS_TRIGGER_RECORD_CREATED',
  TRIGGER_RECORD_UPDATED = 'MICROSOFT_DYNAMICS_TRIGGER_RECORD_UPDATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_FIELDS_FOR_ENTITY;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_CUSTOM_ENTITIES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_CUSTOMERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_OWNERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_PROCESS_STAGES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_TRANSACTION_CURRENCIES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  MICROSOFT_DYNAMICS_ACCESS_TOKEN: string;
  MICROSOFT_DYNAMICS_REFRESH_TOKEN: string;
  MICROSOFT_DYNAMICS_SUB_DOMAIN: string;
  userAccountId: string;
};
