import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

function formatDate(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export default function conditionsToGoogleSheetFormula(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToGoogleSheetFormula(condition);
      })
      .join(condition.join === 'OR' ? ' OR ' : ' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      case Operator.StringContains:
        return `${innerCondition.variable} like '%${innerCondition.argument}%'`;
      case Operator.StringDoesNotContain:
        return `not ${innerCondition.variable} like '%${innerCondition.argument}%'`;
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} = '${innerCondition.argument}'`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable} != '${innerCondition.argument}'`;
      case Operator.StringStartsWith:
        return `${innerCondition.variable} starts with '${innerCondition.argument}'`;
      case Operator.StringDoesNotStartWith:
        return `not ${innerCondition.variable} starts with '${innerCondition.argument}'`;
      case Operator.StringEndsWith:
        return `${innerCondition.variable} ends with '${innerCondition.argument}'`;
      case Operator.StringDoesNotEndWith:
        return `not ${innerCondition.variable} ends with '${innerCondition.argument}'`;
      case Operator.NumberLessThan:
        return `${innerCondition.variable} < ${innerCondition.argument}`;
      case Operator.NumberEquals:
        return `${innerCondition.variable} = ${innerCondition.argument}`;
      case Operator.NumberGreaterThan:
        return `${innerCondition.variable} > ${innerCondition.argument}`;
      case Operator.NumberDoesNotEqual:
        return `${innerCondition.variable} != ${innerCondition.argument}`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `${innerCondition.variable} >= ${innerCondition.argument}`;
      case Operator.NumberLessThanOrEqualTo:
        return `${innerCondition.variable} <= ${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} > date '${formatDate(
          new Date(innerCondition.argument),
        )}'`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} < date '${formatDate(
          new Date(innerCondition.argument),
        )}'`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} = date '${formatDate(
          new Date(innerCondition.argument),
        )}'`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable} = true`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} = false`;
      case Operator.IsNotNull:
        return `${innerCondition.variable} is not null`;
      case Operator.IsNull:
        return `${innerCondition.variable} is null`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for Google Sheet`);
    }
  }
  return '';
}
