import { EnumInputValue } from '@shared/types/sdk/actions';

import IntercomClient from './intercomClient';
import {
  ContactDTO,
  CreateContactDTO,
  MessageDTO,
  Role,
  SendMessageDTO,
  UpdateContactDTO,
} from './types';

/**
 * build contact dto
 * @param param0
 */
export const buildContactDTO = ({
  role,
  email,
  phone,
  name,
  avatar,
  ownerId,
  externalId,
  signedUpDate,
  lastSeenDate,
  unsubscribeFromEmail,
  customAttribute,
}: CreateContactDTO | Partial<UpdateContactDTO>): Partial<ContactDTO> => {
  return {
    role,
    email,
    phone,
    name,
    avatar,
    owner_id: ownerId,
    external_id: externalId,
    signed_up_at: buildUnixDateTimeStamp(signedUpDate),
    last_seen_at: buildUnixDateTimeStamp(lastSeenDate),
    unsubscribed_from_emails: unsubscribeFromEmail,
    custom_attributes: customAttribute ? JSON.parse(customAttribute) : undefined,
  };
};

/**
 * crete message dto from sendmessagedto that will passed to intercom api
 * @param param0
 */
export const buildMessageDTO = ({
  recipientContactType,
  recepientContactId,
  subject,
  body,
  template,
  fromId,
}: SendMessageDTO): Partial<MessageDTO> => {
  return {
    message_type: 'email',
    subject,
    body,
    template,
    from: {
      type: 'admin',
      id: fromId,
    },
    to: {
      type: recipientContactType,
      id: recepientContactId,
    },
  };
};

/**
 * create intercom api client and set access token in it
 * so that to make request we dont have to pass access token in axios header
 * @param token
 */
export const getClient = (token: string): IntercomClient => {
  const client = new IntercomClient();
  client.setAccessToken(token);
  return client;
};

/**
 * return unix based timestam as intercom expect timestamp
 * @param dateString
 */
export const buildUnixDateTimeStamp = (dateString: string | undefined): number | undefined => {
  if (!dateString) {
    return undefined;
  }
  const dateTimeStamp: number = Number(dateString);
  return isNaN(dateTimeStamp) ? new Date(dateString).getTime() / 1000 : dateTimeStamp;
};

/**
 * filterout object's keys which has value as undefined , null , empty string
 * @param obj
 */
export const filterOutEmptyKeysFromObject = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce((accumulator: Record<string, any>, key: string) => {
    const value = obj[key];
    const typeOfValue = typeof obj[key];
    if (typeOfValue == 'object' && value) {
      return { ...accumulator, ...{ [key]: filterOutEmptyKeysFromObject(value) } };
    } else if (
      typeOfValue == 'undefined' ||
      (typeOfValue == 'object' && !value) ||
      (typeOfValue == 'string' && !value)
    ) {
      return accumulator;
    }
    return { ...accumulator, ...{ [key]: value } };
  }, {});
};

/**
 * Roles for contacts
 */
export const roleValues = [
  { label: 'User', value: Role.USER },
  { label: 'Lead', value: Role.LEAD },
];

/**
 * Properties for contacts
 */
export const contactFields: EnumInputValue<string>[] = [
  { label: 'id', value: 'id' },
  { label: 'externalId', value: 'external_id' },
  { label: 'role', value: 'role' },
  { label: 'email', value: 'email' },
  { label: 'phone', value: 'phone' },
  { label: 'name', value: 'name' },
  { label: 'avatar', value: 'avatar' },
  { label: 'ownerId', value: 'owner_id' },
  { label: 'created at', value: 'created_at' },
  { label: 'updated at', value: 'updated_at' },
  { label: 'last seen at', value: 'last_seen_at' },
  { label: 'signed up at', value: 'signed_up_at' },
];
