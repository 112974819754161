import { CredentialValues } from '@shared/entities/sdk';

import { CredentialKeys } from '../configs';

export type Environment = {
  SHAREPOINT_CLIENT_ID: string;
  SHAREPOINT_CLIENT_SECRET: string;
  SHAREPOINT_SCOPES: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: string;
};

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
};

export type SharePointUserData = {
  '@odata.context': string;
  businessPhones: [string];
  displayName: string;
  givenName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
  id: string;
};

export enum DataType {
  BOOLEAN = 'Boolean',
  CHOICE = 'Choice',
  CURRENCY = 'Currency',
  DATETIME = 'DateTime',
  GEOLOCATION = 'Geolocation',
  NUMBER = 'Number',
  TEXT = 'Text',
  HYPERLINKORPICTURE = 'HyperlinkOrPicture',
}

export enum DataTypeValue {
  BOOLEAN = 'boolean',
  CHOICE = 'choice',
  CURRENCY = 'currency',
  DATETIME = 'dateTime',
  GEOLOCATION = 'geolocation',
  NUMBER = 'number',
  TEXT = 'text',
  HYPERLINKORPICTURE = 'hyperlinkOrPicture',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
  [CredentialKeys.SCOPES]?: string;
};

export type TokenExchangeResponse = {
  accessToken: string;
  refreshToken?: string;
  updateCredentialValues: CredentialValues | undefined;
};

export type GetUserFollowedSitesResponse = {
  '@odata.context': string;
  value: [GetUserFollowedSite];
};

export type GetUserFollowedSite = {
  description: string;
  id: string;
  lastModifiedDateTime: string;
  webUrl: string;
  displayName: string;
  sharepointIds: {
    listItemId: string;
    siteId: string;
    siteUrl: string;
    webId: string;
  };
  siteCollection: {
    hostname: string;
  };
};
export type GetListResponse = {
  '@odata.context': string;
  value: [GetList];
};

export type GetList = {
  '@odata.etag': string;
  createdDateTime: string;
  description: string;
  eTag: string;
  id: string;
  lastModifiedDateTime: string;
  name: string;
  webUrl: string;
  displayName: string;
  createdBy: CreatedBy;
  lastModifiedBy: LastModifiedBy;
  parentReference: {
    siteId: string;
  };
  list: {
    contentTypesEnabled: boolean;
    hidden: boolean;
    template: string;
  };
};
export type CreatedBy = {
  user: {
    email: string;
    id: string;
    displayName: string;
  };
};

export type GetColumnResponse = {
  '@odata.context': string;
  value: [GetColumn];
};

export type LastModifiedBy = {
  user: {
    email: string;
    id: string;
    displayName: string;
  };
};

export type GetColumn = {
  columnGroup: string;
  description: string;
  displayName: string;
  enforceUniqueValues: boolean;
  hidden: boolean;
  id: string;
  indexed: boolean;
  name: string;
  readOnly: boolean;
  required: true;
  text: {
    allowMultipleLines: boolean;
    appendChangesToExistingText: boolean;
    linesForEditing: number;
    maxLength: number;
  };
};

export type CreateItemDTO = {
  title: string;
  listId: string;
  fields?: string;
};

export type UpdateItemDTO = { itemId: string; title: string } & Partial<CreateItemDTO>;

export type CreateListDTO = {
  name: string;
  description?: string;
};

export type CreateItemResponse = {
  '@odata.context': string;
  '@odata.etag': string;
  createdDateTime: string;
  eTag: string;
  id: '7';
  lastModifiedDateTime: string;
  webUrl: string;
  createdBy: CreatedBy;
  lastModifiedBy: {
    application: {
      id: string;
      displayName: string;
    };
    user: {
      email: string;
      id: string;
      displayName: string;
    };
  };
  parentReference: {
    siteId: string;
  };
  contentType: {
    id: string;
    name: string;
  };
  'fields@odata.context': string;
  fields: Record<string, any>;
};

export type GetItemByIdResponse = CreateItemResponse;

export type CreateListResponse = {
  name: string;
  displayName: string;
  list: {
    contentTypesEnabled: boolean;
    hidden: boolean;
    template: string;
  };
} & Partial<CreateItemResponse>;

export type CreateListColumnDTO = {
  listId: string;
  displayName: string;
  apiName: string;
  description?: string;
  dataType: string;
  allowTextEntry?: boolean;
  choices?: string;
  displayAs?: string;
  format?: string;
  maximum?: string;
  minimum?: string;
  linesForEditing?: string;
  maxLength?: string;
  textType?: string;
  allowMultipleLines?: boolean;
  defaultValue?: string;
  hidden?: boolean;
  readOnly?: boolean;
};

export type CreateListColumnResponse = {
  '@odata.context': string;
  columnGroup: string;
  description: string;
  displayName: string;
  enforceUniqueValues: boolean;
  hidden: boolean;
  id: string;
  indexed: boolean;
  name: string;
  readOnly: boolean;
  required: boolean;
  dateTime?: Record<string, any>;
  text?: Record<string, any>;
  boolean?: Record<string, any>;
  number?: Record<string, any>;
  choice?: Record<string, any>;
  currency?: Record<string, any>;
  geolocation?: Record<string, any>;
  hyperlinkOrPicture?: Record<string, any>;
};

export type GetDefaultSiteResponse = {
  '@odata.context': string;
  createdDateTime: string;
  description: string;
  id: string;
  lastModifiedDateTime: string;
  name: string;
  webUrl: string;
  displayName: string;
  root: {};
  siteCollection: {
    hostname: string;
  };
};

export type searchSitesResponse = {
  '@odata.context': string;
  value: [SearchSite];
};

export type SearchSite = {
  createdDateTime: string;
  description: string;
  id: string;
  lastModifiedDateTime: string;
  name: string;
  webUrl: string;
  displayName: string;
  root: {};
  siteCollection: {
    hostname: string;
  };
};

export type GetSubsiteResponse = {
  '@odata.context': string;
  value: [GetSubsite];
};

export type GetSubsite = Partial<SearchSite>;
