import React from 'react';

import {
  ActionResponse,
  PaginatedActionResponse,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  FieldMapperDataSource,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import {
  CustomObject,
  CustomObjectField,
  LeadAttribute,
  LeadField,
  List,
  MarketoApiResponse,
} from '../shared/types';

export const getLists: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'lists',
  title: 'List',
  subtitle: 'List that leads should be added to',
  refreshDependencies: [],
  supportPagination: true,
  mapRefreshToValues: (
    response: ActionResponse<PaginatedActionResponse<List[]>>,
  ): EnumInputValue[] => {
    return response.result.output?.records?.map((list: List) => ({
      label: list.name,
      value: list.id,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MARKETO,
      intent: Intent.GET_LISTS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getFields: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'fields',
  title: 'Field',
  refreshDependencies: [],
  mapRefreshToValues: (
    response: ActionResponse<MarketoApiResponse<LeadAttribute>>,
  ): EnumInputValue[] => {
    const attributes = response.result.output.result[0];
    const searchableFields = attributes.searchableFields.flatMap((field: string[]) => field);
    return attributes.fields
      .filter((field: LeadField) => searchableFields.includes(field.name))
      .map((field: LeadField) => ({
        label: field.displayName,
        value: field.name,
      }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MARKETO,
      intent: Intent.GET_FIELDS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  hideFromConnectFieldTypes: true,
};

export const getCustomObjectFields: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'customObjectFields',
  title: 'Custom Object fields',
  refreshDependencies: ['customObjectName'],
  hideFromConnectFieldTypes: true,
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const recordType =
      pickValueSourceByKey(options.actionParameters, 'customObjectName') ??
      // Fallback on cacheKey for getAllCustomObjects, the underlying data source
      pickValueSourceByKey(options.actionParameters, 'customObjects');

    return {
      actionType: Action.MARKETO,
      intent: Intent.GET_CUSTOM_OBJECT_FIELDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'customObjectName',
          source: {
            type: 'VALUE',
            value: recordType?.value,
          },
        },
      ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<CustomObjectField[]>): EnumInputValue[] => {
    return response.result.output.map((field: CustomObjectField) => ({
      label: field.displayName,
      value: field.name,
    }));
  },
};

export const getAllCustomObjects: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'customObjects',
  title: 'Custom Objects',
  refreshDependencies: [],
  hideFromConnectFieldTypes: true,
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MARKETO,
      intent: Intent.GET_ALL_CUSTOM_OBJECTS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<CustomObject[]>): EnumInputValue[] => {
    return response.result.output.map((customObject: CustomObject) => ({
      label: customObject.displayName,
      value: customObject.name,
    }));
  },
};

export const mapMarketoFields: FieldMapperDataSource = {
  id: 'customObjectMapping',
  type: DataSourceType.FIELD_MAPPER,
  title: 'Field Mapping',
  subtitle: 'Allows users to define a field mapping',
  instructionalText: (
    <>
      Let users define a field mapping. Add a label for each property that should be mapped to a
      Marketo object field.{' '}
      <a
        target="_blank"
        href="https://developers.marketo.com/rest-api/lead-database/custom-objects/"
        rel="noreferrer"
      >
        Read the docs
      </a>
    </>
  ),
  recordSource: getAllCustomObjects,
  fieldSource: getCustomObjectFields,
};
