import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Issues',
          items: [
            {
              value: Intent.CREATE_ISSUE,
              label: 'Create Issue',
            },
            {
              value: Intent.UPDATE_ISSUE,
              label: 'Update Issue ',
            },
            {
              value: Intent.GET_ISSUE_BY_NUMBER,
              label: 'Get Issue by Number',
            },
            {
              value: Intent.LOCK_ISSUE,
              label: 'Lock Issue',
            },
            {
              value: Intent.SEARCH_ISSUE,
              label: 'Search Issue',
            },
          ],
        },
        {
          title: 'Releases',
          items: [
            {
              value: Intent.CREATE_RELEASE,
              label: 'Create Release',
            },
            {
              value: Intent.UPDATE_RELEASE,
              label: 'Update Release',
            },
            {
              value: Intent.GET_RELEASE_BY_ID,
              label: 'Get Release by ID',
            },
            {
              value: Intent.GET_RELEASE_BY_TAG_NAME,
              label: 'Get Release by Tag Name',
            },
            {
              value: Intent.DELETE_RELEASE,
              label: 'Delete Release',
            },
          ],
        },
      ],
    },
  ],
};

export const ownerInput: SidebarInput = {
  id: 'owner',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Owner',
  required: true,
  subtitle: 'Specify the name of the account owner of the associated repository for this issue.',
  placeholder: `abc`,
};

export const repositoryInput: SidebarInput = {
  id: 'repo',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Repository',
  required: true,
  subtitle: 'Specify the name of the associated repository for this issue.',
};

export const issueTitleInput: SidebarInput = {
  id: 'title',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Issue Title',
  required: true,
  subtitle: 'Specify the title of the issue to create.',
};

export const issueBodyInput: SidebarInput = {
  id: 'body',
  type: SidebarInputType.TextArea,
  lines: 3,
  title: 'Issue Body',
  required: false,
  subtitle: 'Specify the body contents of the issue to create.',
};

export const assigneesInput: SidebarInput = {
  id: 'assignees',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Assignees',
  required: false,
  subtitle: "Specify the assignee(s)' GitHub login as an array of strings for this issue.",
  placeholder: '["octocat"]',
};

export const issueNumberInput: SidebarInput = {
  id: 'issue_number',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Issue Number',
  required: true,
  subtitle: 'Specify the number of the issue to update.',
};

export const lockReasonInput: SidebarInput = {
  id: 'lock_reason',
  type: SidebarInputType.Enum,
  title: 'Lock Reason',
  required: true,
  getValues: () => [
    { value: 'off-topic', label: 'Off-topic' },
    { value: 'too heated', label: 'Too heated' },
    { value: 'resolved', label: 'Resolved' },
    { value: 'spam', label: 'Spam' },
  ],
  subtitle: 'Specify a reason for locking the issue or pull request conversation.',
};

export const stateInput: SidebarInput = {
  id: 'state',
  type: SidebarInputType.Enum,
  title: 'State',
  required: false,
  getValues: () => [
    { value: 'open', label: 'Open' },
    { value: 'closed', label: 'Closed' },
  ],
  subtitle: 'Specify the updated state of the issue.',
};

export const tagNameInput: SidebarInput = {
  id: 'tag_name',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  placeholder: 'v1.0.0',
  required: true,
  subtitle: 'Specify the name of the release tag to be created.',
};

export const targetInput: SidebarInput = {
  id: 'target_commitish',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Target',
  placeholder: 'master',
  required: false,
  subtitle:
    'Specify the target of the release. This can either be a branch name or a commit SHA. Defaults to the main branch.',
};

export const bodyInput: SidebarInput = {
  id: 'body',
  type: SidebarInputType.TextArea,
  lines: 2,
  title: 'Body',
  required: false,
  subtitle: 'Specify a description for this release.',
};

export const draftInput: SidebarInput = {
  id: 'draft',
  type: SidebarInputType.Enum,
  title: 'Draft',
  required: false,
  getValues: () => [
    { value: 'true', label: 'True' },
    { value: 'false', label: 'False' },
  ],
  subtitle: 'Specify whether the created release should be a draft (unpublished) release.',
};

export const prereleaseInput: SidebarInput = {
  id: 'prerelease',
  type: SidebarInputType.Enum,
  title: 'Prerelease',
  required: false,
  getValues: () => [
    { value: 'true', label: 'Pre Release' },
    { value: 'false', label: 'Full Release' },
  ],
  subtitle: 'Specify whether the created release should be a prerelease.',
};

export const discussionCategoryNameInput: SidebarInput = {
  id: 'discussion_category_name',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Discussion Category Name',
  required: false,
  subtitle:
    'If specified, a discussion of the specified category is created and linked to the release. The value must be a category that already exists in the repository.',
};

export const releaseNotesInput: SidebarInput = {
  id: 'generate_release_notes',
  type: SidebarInputType.Enum,
  title: 'Release Notes',
  required: false,
  getValues: () => [
    { value: 'true', label: 'True' },
    { value: 'false', label: 'False' },
  ],
  subtitle:
    'Specify whether the created release should automatically create release notes using the provided name and body specified.',
};

export const releaseIdInput: SidebarInput = {
  id: 'id',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Release ID',
  required: true,
  subtitle: 'Specify the ID of the release to update.',
};

export const issueFilterInput: SidebarInput = {
  id: 'filter',
  title: 'Filter search',
  subtitle: 'Search for issue that match specified filters.',
  type: SidebarInputType.Conditional,
  required: true,
  supportedOperators: [Operator.StringExactlyMatches],
  getValues: () => [
    { value: 'assignee', label: 'Assignee' },
    { value: 'creator', label: 'Creator' },
    { value: 'mentioned', label: 'Mentioned' },
    { value: 'labels', label: 'Labels' },
  ],
};
