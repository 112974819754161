import { NestedData } from './types';

export function isBufferObject(input: any): boolean {
  return input?.type === 'Buffer' && Array.isArray(input?.data);
}

export function flattenVariables(variables: object, path: string[] = []): NestedData[] {
  return Object.entries(variables).flatMap(([name, data]: [string, any]) => {
    const newPath = [...path, name];
    const nestedData = { path: newPath, data };
    if (typeof data === 'object' && !isBufferObject(data) && data != null) {
      return [nestedData, ...flattenVariables(data, newPath)];
    }
    return [nestedData];
  });
}
