import cookie from 'js-cookie';

import { warn } from '@shared/logger/sdk/legacy';
import { UTMParameter } from '@shared/types/sdk/analytics';
import { isBrowser } from '@shared/types/sdk/environment/utils';

export enum Cookie {
  UTMParameters = 'utm',
}

/**
 * a utility class for working with cookies
 *
 * @class CookieService
 */
class CookieService {
  get isMarketingCookiesEnabled(): boolean {
    return isBrowser() && window.Cookiebot ? window.Cookiebot.consent.marketing : true;
  }

  /**
   * if cookies are available, gets utm parameters that were cached in the cookies
   *
   * @export
   * @returns {Partial<Record<UTMParameter, string>>}
   */
  get utm(): Partial<Record<UTMParameter, string>> {
    let utmParameters: Partial<Record<UTMParameter, string>> = {};

    if (!this.isMarketingCookiesEnabled) {
      return utmParameters;
    }

    try {
      const stringifiedUTM: string | undefined = cookie.get(Cookie.UTMParameters);
      if (stringifiedUTM) {
        utmParameters = JSON.parse(stringifiedUTM);
      }
    } catch (e) {
      warn('Unable to parse UTM cookies.', e);
    }

    return utmParameters;
  }

  /**
   * if cookies are available, it caches the utm parameters in the cookies
   *
   * @memberof CookieService
   */
  set utm(params: Partial<Record<UTMParameter, string>>) {
    if (!this.isMarketingCookiesEnabled || !Object.keys(params).length) {
      return;
    }

    const newValue: Partial<Record<UTMParameter, string>> = {
      ...this.utm,
      ...params,
    };
    cookie.set(Cookie.UTMParameters, newValue);
  }
}

export default new CookieService();
