import { Dispatch } from 'redux';

import { Step, Workflow } from '@shared/types/sdk/steps';
import { MigrationType, WorkflowVersion } from '@shared/types/sdk/version';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { Action, State, StepMigration, WorkflowMigraton } from '../../types';

import { getWorkflowById } from './workflow';

const PAGE_SIZE = 25;

export enum VersionFilter {
  AUTOSAVED = 'migrationType||eq||AUTOSAVED',
  SAVED = 'migrationType||eq||SAVED',
  DEPLOYED = 'migrationType||eq||DEPLOYED',
  NAMED = 'name||notnull',
}

export const getWorkflowVersions =
  (projectId: string, workflowId: string, page: number, filter?: VersionFilter) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'VERSION_ENTITY_FETCH_START' });

    let response: Response;
    try {
      let uri: string = `/projects/${projectId}/workflows/${workflowId}/versions?limit=${PAGE_SIZE}&page=${page}`;
      if (filter) {
        uri = `${uri}&filter=${filter}`;
      }
      response = await api.get(uri);
      if (response.ok) {
        const versions: {
          data: WorkflowVersion[];
          extras: WorkflowVersion[];
          pageCount: number;
        } = await response.json();
        dispatch({
          type: 'VERSION_ENTITY_FETCH_SUCCESS',
          versions: versions.data,
          pageCount: versions.pageCount,
          replace: filter ? true : false,
        });

        if (versions.extras && versions.extras.length) {
          dispatch({
            type: 'VERSION_ENTITY_EXTRAS_FETCH',
            extras: versions.extras,
          });
        }
      } else {
        dispatch({
          type: 'VERSION_ENTITY_FETCH_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (err) {
      dispatch({
        type: 'VERSION_ENTITY_FETCH_FAILED',
        message: err.message,
      });
    }
  };

export const updateWorkflowVersionName =
  (projectId: string, workflowId: string, versionId: string, name: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'VERSION_ENTITY_FETCH_START' });

    let response: Response;
    try {
      dispatch({
        type: 'VERSION_ENTITY_UPDATE_NAME',
        id: versionId,
        name,
      });
      response = await api.patch(
        `/projects/${projectId}/workflows/${workflowId}/versions/${versionId}`,
        { name },
      );
      if (!response.ok) {
        dispatch({
          type: 'VERSION_ENTITY_FETCH_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (err) {
      dispatch({
        type: 'VERSION_ENTITY_FETCH_FAILED',
        message: err.message,
      });
    }
  };

export const saveWorkflowVersion =
  (
    projectId: string,
    workflowId: string,
    name: string | undefined,
    migrationType?: MigrationType,
  ) =>
  async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
    dispatch({ type: 'VERSION_ENTITY_FETCH_START' });

    let response: Response;
    try {
      response = await api.post(`/projects/${projectId}/workflows/${workflowId}/versions/`, {
        name,
        migrationType,
      });
      if (response.ok) {
        const version: WorkflowVersion = await response.json();
        const state: State = getState();
        if (state.entities.versions.extras && state.entities.versions.extras.length > 0) {
          return;
        }
        dispatch({
          type: 'VERSION_ENTITY_UPDATE_VERSION',
          version,
        });
      } else {
        dispatch({
          type: 'VERSION_ENTITY_FETCH_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (err) {
      dispatch({
        type: 'VERSION_ENTITY_FETCH_FAILED',
        message: err.message,
      });
    }
  };

export const restoreWorkflowVersion =
  (projectId: string, workflowId: string, versionId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'VERSION_ENTITY_FETCH_START' });

    let response: Response;
    try {
      response = await api.get(
        `/projects/${projectId}/workflows/${workflowId}/versions/${versionId}/restore`,
      );
      if (response.ok) {
        await response.json();

        await getWorkflowById(projectId, workflowId)(dispatch);
      } else {
        dispatch({
          type: 'VERSION_ENTITY_FETCH_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (err) {
      dispatch({
        type: 'VERSION_ENTITY_FETCH_FAILED',
        message: err.message,
      });
    }
  };

export const getWorkflowVersion =
  (projectId: string, workflowId: string, versionId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'VERSION_ENTITY_FETCH_START' });

    let response: Response;
    try {
      response = await api.get(
        `/projects/${projectId}/workflows/${workflowId}/versions/${versionId}`,
      );
      if (response.ok) {
        const migration: WorkflowMigraton = await response.json();

        const steps: Step[] = migration.steps.map((stepMigration: StepMigration) => ({
          ...stepMigration,
          id: stepMigration.stepId,
          workflowId: migration.workflowId,
        }));

        const workflow: Workflow = {
          ...migration.workflow,
          id: migration.workflowId,
          ...{ steps: [...steps] },
        };

        dispatch({
          type: 'STEP_ENTITY_FETCH_SUCCESS',
          payload: [...steps.map((step: any) => ({ ...step }))],
        });
        dispatch({ type: 'GET_ONE_WORKFLOW_SUCCESS', payload: workflow });
      } else {
        dispatch({
          type: 'VERSION_ENTITY_FETCH_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (err) {
      dispatch({
        type: 'VERSION_ENTITY_FETCH_FAILED',
        message: err.message,
      });
    }
  };
