import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.DYNAMICS_365_FINANCE;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'DYNAMICS_365_FINANCE_TEST_CONNECTION',
  GET_ACCOUNTS = 'DYNAMICS_365_FINANCE_GET_ACCOUNTS',
  CREATE_VENDOR = 'DYNAMICS_365_FINANCE_CREATE_VENDOR',
  UPDATE_VENDOR = 'DYNAMICS_365_FINANCE_UPDATE_VENDOR',
  GET_VENDOR_BY_ID = 'DYNAMICS_365_FINANCE_GET_VENDOR_BY_ID',
  SEARCH_VENDORS = 'DYNAMICS_365_FINANCE_SEARCH_VENDORS',
  CREATE_BILL = 'DYNAMICS_365_FINANCE_CREATE_BILL',
  UPDATE_BILL = 'DYNAMICS_365_FINANCE_UPDATE_BILL',
  GET_BILL_BY_ID = 'DYNAMICS_365_FINANCE_GET_BILL_BY_ID',
  SEARCH_BILLS = 'DYNAMICS_365_FINANCE_SEARCH_BILLS',
  DELETE_BILL = 'DYNAMICS_365_FINANCE_DELETE_BILL',
  CREATE_BILL_LINE_ITEM = 'DYNAMICS_365_FINANCE_CREATE_BILL_LINE_ITEM',
  UPDATE_BILL_LINE_ITEM = 'DYNAMICS_365_FINANCE_UPDATE_BILL_LINE_ITEM',
  GET_BILL_LINE_ITEM_BY_ID = 'DYNAMICS_365_FINANCE_GET_BILL_LINE_ITEM_BY_ID',
  SEARCH_BILL_LINE_ITEMS = 'DYNAMICS_365_FINANCE_SEARCH_BILL_LINE_ITEMS',
  DELETE_BILL_LINE_ITEM = 'DYNAMICS_365_FINANCE_DELETE_BILL_LINE_ITEM',
  CREATE_CUSTOMER = 'DYNAMICS_365_FINANCE_CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'DYNAMICS_365_FINANCE_UPDATE_CUSTOMER',
  GET_CUSTOMER_BY_ID = 'DYNAMICS_365_FINANCE_GET_CUSTOMER_BY_ID',
  SEARCH_CUSTOMERS = 'DYNAMICS_365_FINANCE_SEARCH_CUSTOMERS',
  DELETE_CUSTOMER = 'DYNAMICS_365_FINANCE_DELETE_CUSTOMER',
  CREATE_INVOICE = 'DYNAMICS_365_FINANCE_CREATE_INVOICE',
  UPDATE_INVOICE = 'DYNAMICS_365_FINANCE_UPDATE_INVOICE',
  GET_INVOICE_BY_ID = 'DYNAMICS_365_FINANCE_GET_INVOICE_BY_ID',
  SEARCH_INVOICES = 'DYNAMICS_365_FINANCE_SEARCH_INVOICES',
  DELETE_INVOICE = 'DYNAMICS_365_FINANCE_DELETE_INVOICE',
  CREATE_PAYMENT_JOURNAL = 'DYNAMICS_365_FINANCE_CREATE_PAYMENT_JOURNAL',
  UPDATE_PAYMENT_JOURNAL = 'DYNAMICS_365_FINANCE_UPDATE_PAYMENT_JOURNAL',
  GET_PAYMENT_JOURNAL_BY_ID = 'DYNAMICS_365_FINANCE_GET_PAYMENT_JOURNAL_BY_ID',
  SEARCH_PAYMENT_JOURNALS = 'DYNAMICS_365_FINANCE_SEARCH_PAYMENT_JOURNALS',
  DELETE_PAYMENT_JOURNAL = 'DYNAMICS_365_FINANCE_DELETE_PAYMENT_JOURNAL',
  CREATE_PAYMENT_JOURNAL_LINE_ITEM = 'DYNAMICS_365_FINANCE_CREATE_PAYMENT_JOURNAL_LINE_ITEM',
  UPDATE_PAYMENT_JOURNAL_LINE_ITEM = 'DYNAMICS_365_FINANCE_UPDATE_PAYMENT_JOURNAL_LINE_ITEM',
  GET_PAYMENT_JOURNAL_LINE_ITEM_BY_ID = 'DYNAMICS_365_FINANCE_GET_PAYMENT_JOURNAL_LINE_ITEM_BY_ID',
  SEARCH_PAYMENT_JOURNAL_LINE_ITEMS = 'DYNAMICS_365_FINANCE_SEARCH_PAYMENT_JOURNAL_LINE_ITEMS',
  DELETE_PAYMENT_JOURNAL_LINE_ITEM = 'DYNAMICS_365_FINANCE_DELETE_PAYMENT_JOURNAL_LINE_ITEM',
}
export type IntentActionStep =
  | Intent.TEST_CONNECTION
  | Intent.GET_ACCOUNTS
  | Intent.CREATE_VENDOR
  | Intent.UPDATE_VENDOR
  | Intent.GET_VENDOR_BY_ID
  | Intent.SEARCH_VENDORS
  | Intent.CREATE_BILL
  | Intent.UPDATE_BILL
  | Intent.GET_BILL_BY_ID
  | Intent.SEARCH_BILLS
  | Intent.DELETE_BILL
  | Intent.CREATE_BILL_LINE_ITEM
  | Intent.UPDATE_BILL_LINE_ITEM
  | Intent.GET_BILL_LINE_ITEM_BY_ID
  | Intent.SEARCH_BILL_LINE_ITEMS
  | Intent.DELETE_BILL_LINE_ITEM
  | Intent.CREATE_CUSTOMER
  | Intent.UPDATE_CUSTOMER
  | Intent.GET_CUSTOMER_BY_ID
  | Intent.SEARCH_CUSTOMERS
  | Intent.DELETE_CUSTOMER
  | Intent.CREATE_INVOICE
  | Intent.UPDATE_INVOICE
  | Intent.GET_INVOICE_BY_ID
  | Intent.SEARCH_INVOICES
  | Intent.DELETE_INVOICE
  | Intent.CREATE_PAYMENT_JOURNAL
  | Intent.UPDATE_PAYMENT_JOURNAL
  | Intent.GET_PAYMENT_JOURNAL_BY_ID
  | Intent.SEARCH_PAYMENT_JOURNALS
  | Intent.DELETE_PAYMENT_JOURNAL
  | Intent.CREATE_PAYMENT_JOURNAL_LINE_ITEM
  | Intent.UPDATE_PAYMENT_JOURNAL_LINE_ITEM
  | Intent.GET_PAYMENT_JOURNAL_LINE_ITEM_BY_ID
  | Intent.SEARCH_PAYMENT_JOURNAL_LINE_ITEMS
  | Intent.DELETE_PAYMENT_JOURNAL_LINE_ITEM;

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: IntentActionStep;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ACCESS_TOKEN = 'DYNAMICS_365_FINANCE_ACCESS_TOKEN',
  REFRESH_TOKEN = 'DYNAMICS_365_FINANCE_REFRESH_TOKEN',
  USER_ACCOUNT_ID = 'USER_ACCOUNT_ID',
  BASE_URI = 'DYNAMICS_365_FINANCE_BASE_URI',
  DEFAULT_COMPANY = 'DYNAMICS_365_FINANCE_DEFAULT_COMPANY',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.BASE_URI]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
  [CredentialKeys.USER_ACCOUNT_ID]: string;
  [CredentialKeys.DEFAULT_COMPANY]: string;
};

export enum EnvironmentKeys {
  DYNAMICS_365_FINANCE_CLIENT_ID = 'DYNAMICS_365_FINANCE_CLIENT_ID',
  DYNAMICS_365_FINANCE_CLIENT_SECRET = 'DYNAMICS_365_FINANCE_CLIENT_SECRET',
  DYNAMICS_365_FINANCE_SCOPES = 'DYNAMICS_365_FINANCE_SCOPES',
}

export type Environment = {
  [EnvironmentKeys.DYNAMICS_365_FINANCE_CLIENT_ID]: string;
  [EnvironmentKeys.DYNAMICS_365_FINANCE_CLIENT_SECRET]: string;
  [EnvironmentKeys.DYNAMICS_365_FINANCE_SCOPES]: string;
};
