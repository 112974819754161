import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { ListFilesItem } from '../shared/types';

export const getFolders: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Folder',
  subtitle: 'Dropbox Folder to save files to.',
  cacheKey: 'folders',
  mapRefreshToValues: (response: ActionResponse<ListFilesItem[]>): EnumInputValue[] => {
    const resolved = (response?.result?.output || []).map((item: ListFilesItem) => ({
      label: item.path_display,
      value: item.id,
    }));
    return resolved;
  },
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
  ],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => ({
    actionType: Action.DROPBOX,
    intent: Intent.LIST_FOLDERS,
    credentials: options.credentials,
    actionParameters: [],
  }),
};
