import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.PRODUCTBOARD;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'PRODUCTBOARD_TEST_CONNECTION',
  CREATE_FEATURE = 'PRODUCTBOARD_CREATE_FEATURE',
  UPDATE_FEATURE = 'PRODUCTBOARD_UPDATE_FEATURE',
  GET_FEATURE_BY_ID = 'PRODUCTBOARD_GET_FEATURE_BY_ID',
  DELETE_FEATURE = 'PRODUCTBOARD_DELETE_FEATURE',
  GET_COMPONENTS = 'PRODUCTBOARD_GET_COMPONENTS',
  CREATE_COMPONENT = 'PRODUCTBOARD_CREATE_COMPONENT',
  UPDATE_COMPONENT = 'PRODUCTBOARD_UPDATE_COMPONENT',
  GET_COMPONENT_BY_ID = 'PRODUCTBOARD_GET_COMPONENT_BY_ID',
  GET_PRODUCT_BY_ID = 'PRODUCTBOARD_GET_PRODUCT_BY_ID',
  GET_PRODUCTS = 'PRODUCTBOARD_GET_PRODUCTS',
}

export type IntentActionStep =
  | Intent.CREATE_FEATURE
  | Intent.UPDATE_FEATURE
  | Intent.GET_FEATURE_BY_ID
  | Intent.DELETE_FEATURE
  | Intent.CREATE_COMPONENT
  | Intent.UPDATE_COMPONENT
  | Intent.GET_COMPONENT_BY_ID
  | Intent.GET_PRODUCT_BY_ID
  | Intent.GET_PRODUCTS
  | Intent.GET_COMPONENTS;

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: IntentActionStep;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ACCESS_TOKEN = 'PRODUCTBOARD_ACCESS_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
};
