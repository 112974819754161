import * as Sentry from '@sentry/node';
import { Integrations as SentryIntegrations } from '@sentry/tracing';
import { NextPageContext } from 'next';
import withRedux from 'next-redux-wrapper';
import App, { AppContext, AppInitialProps } from 'next/app';
import Router, { NextRouter, withRouter } from 'next/router';
import React, { ErrorInfo } from 'react';
import { resetServerContext } from 'react-beautiful-dnd';
import 'react-datepicker/dist/react-datepicker.css';
import ReactGA from 'react-ga';
import { HotKeys, configure as configureHotKey } from 'react-hotkeys';
import Intercom from 'react-intercom';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import styled, { createGlobalStyle } from 'styled-components';

import { IProject } from '@shared/entities/sdk/project/project.interface';
import { LEVEL, configure, danger, setEnv, subscribe } from '@shared/logger/sdk/legacy';
import sentry, { report } from '@shared/logger/sdk/sentry';
import { IntercomUserAttributes, UTMParameter } from '@shared/types/sdk/analytics';
import { ParagonService } from '@shared/types/sdk/atlas';
import {
  COLORS,
  fadeInEntrance,
  popInEntrance,
  slideDownEntrance,
} from '@shared/ui/sdk/utils/constants';
import ContentCdnPathContext from '@shared/ui/sdk/utils/ContentCdnPathContext';
import { getUTMParameters } from '@shared/utils/sdk/http';

import Confirm from '../components/Confirm';
import ErrorToast from '../components/ErrorToast';
import Impersonating from '../components/Impersonating';
import SegmentSnippet from '../components/SegmentSnippet';
import { TaskLimitReached } from '../components/TaskLimitReached';
import { initializeStore } from '../store';
import { State } from '../store/types';
import { getStaticContentPath, pushGtmEvent } from '../utils';
import config from '../utils/config';
import { KEY_SHORTCUT_MAP } from '../utils/constants';
import { useProjectTypeRedirect } from '../utils/hooks';

const { GOOGLE_ANALYTICS_TRACKING_ID, CDN_PUBLIC_URL, GOOGLE_TAG_MANAGER_ID } = config;

const appName: ParagonService = ParagonService.DASHBOARD;
sentry({
  dsn: config.SENTRY_DSN,
  release: config.VERSION,
  environment: config.PLATFORM_ENV,
  serverName: appName,
  integrations: [new SentryIntegrations.BrowserTracing()],
});
subscribe([LEVEL.ERROR, LEVEL.FATAL], report);
configure(appName);
setEnv(config as Record<string, any>);

export type Props = AppInitialProps & {
  store: Store<State>;
  router: NextRouter;
};

type AppState = {
  errorMessage: string | null;
};

export interface Context extends NextPageContext {
  store: Store<State>;
}

const HotkeyComponent = styled.div`
  &:focus {
    outline: 0;
  }
  height: 100vh;
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Monaco';
    font-style: normal;
    font-weight: normal;
    src: local('Monaco'), url('${getStaticContentPath('public/fonts/Monaco.woff')}') format('woff');
  }

  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, button:disabled {
    font-family: 'Inter', sans-serif;
    color: ${COLORS.TEXT};
  }

  * {
    box-sizing: border-box;
    line-height: 1;
  }

  body,
  #__next {
    height: 100%;
    overflow-x: hidden;
  }

  button {
    background-color: transparent;
  }

  a:focus,
  button:focus {
    outline: none;
  }

  a {
    &,
    &:visited {
      color: ${COLORS.PRIMARY};
    }
  }

  input[type="text"][disabled] {
    cursor: not-allowed;
  }

  ${slideDownEntrance}
  ${fadeInEntrance}
  ${popInEntrance}
`;

configureHotKey({ defaultComponent: HotkeyComponent });

// tslint:disable-next-line:function-name
function ProjectTypeRedirect(): JSX.Element {
  useProjectTypeRedirect();
  return <></>;
}

export class MyApp extends App<Props> {
  activeError: number | null = null;
  state: AppState = {
    errorMessage: null,
  };

  // tslint:disable-next-line:function-name
  static async getInitialProps({ Component, ctx }: AppContext): Promise<AppInitialProps> {
    return {
      pageProps: Component.getInitialProps ? await Component.getInitialProps(ctx) : {},
    };
  }

  handleError(error: Error): void {
    const errorMessage: string = error?.message ? error.message : 'application error';
    danger(errorMessage, error);
    if (this.activeError) {
      // Cancel timeout if one from a previous error is active
      clearTimeout(this.activeError);
    }

    this.activeError = setTimeout(() => {
      this.setState({
        errorMessage: null,
      });
      this.activeError = null;
    }, 5000) as unknown as number;

    this.setState({
      errorMessage,
    });
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.handleError(error);

    Sentry.withScope((scope: Sentry.Scope) => {
      Object.keys(errorInfo).forEach((key: string) => {
        scope.setExtra(key, errorInfo[key as keyof React.ErrorInfo]);
      });
      scope.setTag('environment', config.PLATFORM_ENV);
      scope.setTag('release', config.VERSION);
      scope.setTag('serverName', appName);

      Sentry.captureException(error);
    });
  }

  componentDidMount(): void {
    const { router } = this.props;

    if (router.query.alert) {
      alert(router.query.alert);
    }

    window.addEventListener('error', (event: ErrorEvent) => {
      const error = event.error || new Error(event.message);
      this.handleError(error);
    });

    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
      this.handleError(new Error(event.reason));
    });

    this.updateNavigationState();
    Router.events.on('routeChangeComplete', (url: string) => {
      if (window.Cookiebot && window.Cookiebot.consent.marketing) {
        pushGtmEvent('pageview', { page: url });
      }
      this.updateNavigationState();
    });

    if (GOOGLE_ANALYTICS_TRACKING_ID && window.Cookiebot) {
      window.Cookiebot.onaccept = () => {
        if (window.Cookiebot.consent.statistics) {
          ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
        }
      };
    }
  }

  updateNavigationState(): void {
    const { router, store } = this.props;
    const utmParameters: Partial<Record<UTMParameter, string>> = getUTMParameters(
      this.props.router.asPath,
    );
    store.dispatch({
      type: 'NAVIGATE',
      projectId: router.query.projectId,
      workflowId: router.query.workflowId,
      memberId: router.query.memberId,
      versionId: router.query.versionId,
      executionId: router.query.executionId,
      integrationId: router.query.integrationId,
      isConnectUi: router.asPath.indexOf('/connect') > -1 || router.query.connect === 'true',
      ...(Object.keys(utmParameters).length ? { utmParameters } : {}),
    });
  }

  render(): JSX.Element {
    resetServerContext();
    const { Component, pageProps, store } = this.props;
    const { errorMessage } = this.state;
    const { INTERCOM_APP_ID } = config;
    const state: State = store.getState();
    const { utmParameters } = state.navigation;
    const { user, newUser } = state.auth;

    const { impersonated } = user ?? {
      impersonated: false,
    };

    const defaultTeam = user && state.entities.teams.entities[Object.keys(user.teams)[0]];
    const classicProject: IProject | undefined = Object.values(
      state.entities.projects.entities,
    ).find((project: IProject) => project.isConnectProject);
    const connectProject: IProject | undefined = Object.values(
      state.entities.projects.entities,
    ).find((project: IProject) => project.isConnectProject);
    const intercomUserAttributes: Partial<IntercomUserAttributes> = {
      user_id: user?.id,
      email: user?.email,
      name: `${user?.firstName} ${user?.lastName}`.trim() || undefined,
      company: defaultTeam && {
        company_id: defaultTeam.id,
        name: defaultTeam.name,
      },
      'Onboarding Stage': user?.onboardingStage,
      'Project Type': classicProject?.projectType || connectProject?.projectType,
      'Project Use Case': classicProject?.projectPurpose || connectProject?.projectPurpose,
      'Classic Project Id': classicProject?.id,
      'Connect Project Id': connectProject?.id,
      // We only want to update the utm parameters on Intercom if the user just signed up.
      // This allows operations to track conversions of campaigns
      ...(newUser ? utmParameters : {}),
    };

    return (
      <>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<frame src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        ></noscript>
        <ContentCdnPathContext.Provider value={CDN_PUBLIC_URL || null}>
          <Provider store={store}>
            <ProjectTypeRedirect />
            <TaskLimitReached />
            <HotKeys keyMap={KEY_SHORTCUT_MAP}>
              <Component {...pageProps} />
            </HotKeys>
            <ErrorToast errorMessage={errorMessage ? 'Something went wrong' : null} />
            <Confirm />
            {impersonated && <Impersonating />}
            {user && INTERCOM_APP_ID && !impersonated && (
              <Intercom appID={INTERCOM_APP_ID} {...intercomUserAttributes} />
            )}
            <GlobalStyle />
            <script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid={config.COOKIE_CONSENT_SDK_ID}
              data-blockingmode="auto"
              type="text/javascript"
            ></script>
            <script
              data-cookieconsent="statistics"
              src="https://assets.calendly.com/assets/external/widget.js"
              type="text/plain"
              async
            ></script>
            <SegmentSnippet />
          </Provider>
        </ContentCdnPathContext.Provider>
      </>
    );
  }
}

export default withRedux(initializeStore)(withRouter(MyApp));
