import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.DYNAMODB;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'DYNAMODB_TEST_CONNECTION',
  LIST_TABLES = 'DYNAMODB_LIST_TABLES',
  GET_INDEXED_TABLE_KEYS = 'DYNAMODB_GET_INDEXED_TABLE_KEYS',
  SCAN = 'DYNAMODB_SCAN',
  QUERY = 'DYNAMODB_QUERY',
  CREATE_RECORD = 'DYNAMODB_CREATE_RECORD',
  UPDATE_RECORD = 'DYNAMODB_UPDATE_RECORD',
  DELETE_RECORD = 'DYNAMODB_DELETE_RECORD',
  GET_RECORD = 'DYNAMODB_GET_RECORD',
}

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_TABLES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_INDEXED_TABLE_KEYS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SCAN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.QUERY;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  AWS_REGION = 'AWS_REGION',
  AWS_ACCESS_KEY_ID = 'AWS_ACCESS_KEY_ID',
  AWS_SECRET_ACCESS_KEY = 'AWS_SECRET_ACCESS_KEY',
}

export type Credentials = {
  [CredentialKeys.AWS_REGION]: string;
  [CredentialKeys.AWS_ACCESS_KEY_ID]: string;
  [CredentialKeys.AWS_SECRET_ACCESS_KEY]: string;
};
