import {
  ActionResponse,
  createTestConnectionButton,
  getValuesByCacheKey,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';
import { DataType, KeyedSource, Operator } from '@shared/types/sdk/resolvers';

import { CredentialKeys, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const getTables: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Table',
  cacheKey: 'tables',
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
  ],
  mapRefreshToValues: (response: ActionResponse<string[]>): EnumInputValue[] => {
    return response.result.output.map((tableName: string) => ({
      label: tableName,
      value: tableName,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.DYNAMODB,
      intent: Intent.LIST_TABLES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

const getPrimaryPartitionKeys: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Primary key (Partition)',
  cacheKey: 'partitionKeys',
  refreshDependencies: ['tableName'],
  getRefreshActionParameters: (options: ActionStepParameters) => ({
    actionType: Action.DYNAMODB,
    actionParameters: [
      { key: 'tableName', source: pickValueSourceByKey(options.actionParameters, 'tableName') },
      { key: 'keyType', source: { type: 'VALUE', value: 'HASH' } },
    ] as KeyedSource<DataType.ANY>[],
    credentials: options.credentials,
    intent: Intent.GET_INDEXED_TABLE_KEYS,
  }),
  mapRefreshToValues: (response: any) => {
    return Object.keys(response.result.output).map((keyName: string) => ({
      label: keyName,
      value: keyName,
    }));
  },
};

const getPrimarySortKeys: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Primary key (Sort)',
  cacheKey: 'sortKeys',
  refreshDependencies: ['tableName'],
  getRefreshActionParameters: (options: ActionStepParameters) => ({
    actionType: Action.DYNAMODB,
    actionParameters: [
      { key: 'tableName', source: pickValueSourceByKey(options.actionParameters, 'tableName') },
      { key: 'keyType', source: { type: 'VALUE', value: 'RANGE' } },
    ] as KeyedSource<DataType.ANY>[],
    credentials: options.credentials,
    intent: Intent.GET_INDEXED_TABLE_KEYS,
  }),
  mapRefreshToValues: (response: any) => {
    return Object.keys(response.result.output).map((keyName: string) => ({
      label: keyName,
      value: keyName,
    }));
  },
};

export const authConfig: ActionConfig = {
  actionType: Action.NONE,
  provider: ProviderType.AWS,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your AWS account',
  description: 'Add DynamoDB to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name your AWS account',
          subtitle: 'Label this AWS account for use in your Paragon workflows.',
          placeholder: 'e.g. Production, Testing, etc.',
          type: SidebarInputType.ValueText,
        },
        {
          id: CredentialKeys.AWS_REGION,
          title: 'AWS Region',
          placeholder: 'us-east-1',
          type: SidebarInputType.ValueText,
        },
        {
          id: CredentialKeys.AWS_ACCESS_KEY_ID,
          title: 'AWS Access Key ID',
          subtitle:
            'We recommend using an access key with the minimum permissions necessary to access your DynamoDB.',
          placeholder: 'AKXXXXXXXXXXXXXXXXXX',
          type: SidebarInputType.ValueText,
        },
        {
          id: CredentialKeys.AWS_SECRET_ACCESS_KEY,
          title: 'AWS Secret Access Key',
          type: SidebarInputType.ValueText,
        },
      ],
      buttons: [createTestConnectionButton(Action.DYNAMODB, Object.values(CredentialKeys))],
    },
  ],
};

const tableInput: SidebarInput = {
  id: 'tableName',
  type: SidebarInputType.DynamicEnum,
  title: 'Table',
  required: true,
  source: getTables,
  getValues: getValuesByCacheKey,
};

const partitionKeyInput: SidebarInput = {
  id: 'partitionKeyExpression',
  type: SidebarInputType.DynamicConditional,
  required: true,
  title: 'Primary key (Partition)',
  source: getPrimaryPartitionKeys,
  getValues: getValuesByCacheKey,
  supportedOperators: [Operator.StringExactlyMatches, Operator.NumberEquals],
  disableMultipleConditions: true,
};

const sortKeyInput: SidebarInput = {
  id: 'sortKeyExpression',
  type: SidebarInputType.DynamicConditional,
  required: false,
  title: 'Primary key (Sort)',
  subtitle: 'If your primary key is a composite key, specify your sort key to narrow your query.',
  source: getPrimarySortKeys,
  getValues: getValuesByCacheKey,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringStartsWith,
    Operator.NumberLessThan,
    Operator.NumberEquals,
    Operator.NumberGreaterThan,
    Operator.NumberGreaterThanOrEqualTo,
    Operator.NumberLessThanOrEqualTo,
  ],
  disableMultipleConditions: true,
};

const filterInput: SidebarInput = {
  id: 'filterExpression',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter expression',
  subtitle:
    'Search for objects that match specified filters. This action will still perform a full scan of the table.',
  placeholder: 'filter by field',
  supportedOperators: [
    Operator.StringContains,
    Operator.StringDoesNotContain,
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.StringStartsWith,
    Operator.StringDoesNotStartWith,
    Operator.NumberLessThan,
    Operator.NumberEquals,
    Operator.NumberGreaterThan,
    Operator.NumberDoesNotEqual,
    Operator.NumberGreaterThanOrEqualTo,
    Operator.NumberLessThanOrEqualTo,
    Operator.BooleanTrue,
    Operator.BooleanFalse,
    Operator.IsNotNull,
    Operator.IsNull,
  ],
};

const config: ActionConfig = {
  actionType: Action.DYNAMODB,
  name: 'DynamoDB',
  description: 'Query and update objects in DynamoDB.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect your AWS account',
          placeholder: 'choose your AWS account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                required: true,
                values: [
                  {
                    value: Intent.SCAN,
                    label: 'Scan',
                  },
                  {
                    value: Intent.QUERY,
                    label: 'Query',
                  },
                  {
                    value: Intent.GET_RECORD,
                    label: 'Get item',
                  },
                  {
                    value: Intent.CREATE_RECORD,
                    label: 'Put item',
                  },
                  {
                    value: Intent.UPDATE_RECORD,
                    label: 'Update item',
                  },
                  {
                    value: Intent.DELETE_RECORD,
                    label: 'Delete item',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.SCAN:
          return {
            inputs: [tableInput, filterInput],
          };
        case Intent.QUERY:
          return {
            inputs: [tableInput, partitionKeyInput, sortKeyInput, filterInput],
          };
        case Intent.CREATE_RECORD:
          return {
            inputs: [
              tableInput,
              {
                id: 'item',
                title: 'Object',
                type: SidebarInputType.Code,
                language: 'json',
                placeholder: `{
  "id": 123,
  "name": "Ethan Lee",
  "details": {
    "pronouns": "he/him"
  },
  "profilePicture": {
    "B": "{{1.request.image}}"
  }
}`,
              },
            ],
          };
        case Intent.UPDATE_RECORD:
          return {
            inputs: [
              tableInput,
              partitionKeyInput,
              {
                ...sortKeyInput,
                supportedOperators: [Operator.StringExactlyMatches, Operator.NumberEquals],
              },
              {
                id: 'updateParam',
                title: 'Object',
                subtitle: 'Specify a partial update object to be set into the item contents.',
                type: SidebarInputType.Code,
                language: 'json',
                placeholder: `{
  "company": "Paragon",
  "title": "Engineer"
}`,
              },
            ],
          };
        case Intent.DELETE_RECORD:
          return {
            inputs: [
              tableInput,
              partitionKeyInput,
              {
                ...sortKeyInput,
                supportedOperators: [Operator.StringExactlyMatches, Operator.NumberEquals],
              },
            ],
          };
        case Intent.GET_RECORD:
          return {
            inputs: [
              tableInput,
              partitionKeyInput,
              {
                ...sortKeyInput,
                supportedOperators: [Operator.StringExactlyMatches, Operator.NumberEquals],
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
