import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedOAuthParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { OAuthInput, OAuthStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';

/**
 * a resolver for an oauth step's variables
 *
 * @export
 * @class OAuthStepResolver
 * @extends {StepResolver<OAuthStep, ResolvedOAuthParameters>}
 */
export class OAuthStepResolver extends StepResolver<OAuthStep, ResolvedOAuthParameters> {
  /**
   * resolves the input parameters for the step
   *
   * @param {OAuthStep['parameters']} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedOAuthParameters}
   * @memberof OAuthStepResolver
   */
  resolveInputParameters(
    _parameters: OAuthStep['parameters'],
    _secrets: Record<string, string>,
    _cachedConnectCredential: CachedConnectCredential | null,
    _variables: WorkflowVariables,
    context: WorkflowExecutionContext,
  ): ResolvedOAuthParameters {
    const triggerInput: OAuthInput = context.triggerInput as OAuthInput;
    return { authorizationCode: triggerInput.code, query: triggerInput.query };
  }

  getRequiredStepIdsForVariableResolution(_: OAuthStep['parameters']): string[] {
    return [];
  }
}
