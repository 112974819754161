export default `<svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.5344 4.96552C32.8487 0.42517 40.0069 0.26059 44.523 4.59781C45.3669 5.40838 46.0808 6.34531 46.6397 7.37552C48.4067 6.58138 50.3216 6.17452 52.2572 6.18226C59.9995 6.18226 66.2757 12.4923 66.2757 20.2762C66.2757 28.06 59.9996 34.3701 52.2572 34.3701C51.3388 34.3683 50.423 34.2731 49.5239 34.0863C47.7248 37.3112 44.3364 39.311 40.6587 39.3181C39.1216 39.3248 37.6041 38.9697 36.2283 38.2802C33.7152 44.2041 26.9014 46.958 21.0093 44.4317C18.0804 43.1755 15.794 40.7644 14.6861 37.7636C13.9579 37.9202 13.2154 37.9986 12.4707 37.9967C6.45847 37.9185 1.64747 32.9556 1.72517 26.9109C1.77418 23.0774 3.81487 19.5489 7.10456 17.6098C6.41624 16.028 6.06572 14.3185 6.07614 12.5919C6.06429 5.6492 11.6527 0.0117616 18.5579 1.79811e-05C22.4667 -0.00661971 26.1534 1.82479 28.523 4.95004" fill="#139CD8"/>
<path d="M11.1146 23.5277C11.0875 23.58 11.0985 23.6443 11.1414 23.684C11.2674 23.7622 11.3705 23.8404 11.4966 23.9185C12.0843 24.2342 12.7428 24.3866 13.4062 24.3605C14.7048 24.3605 15.5182 23.6568 15.5182 22.5112V22.4839C15.5182 21.4167 14.6016 21.0493 13.7348 20.7638L13.6392 20.7247C13.0013 20.5177 12.4439 20.3338 12.4439 19.8921V19.8648C12.4439 19.5012 12.776 19.2119 13.2838 19.2119C13.8621 19.2196 14.4297 19.3714 14.9378 19.6536C14.9907 19.6921 15.0638 19.6798 15.1019 19.6263V19.599C15.1287 19.5208 15.3579 18.9226 15.3579 18.8679C15.3734 18.8103 15.3541 18.7486 15.3081 18.7116C14.7385 18.3659 14.0876 18.1861 13.4252 18.1915H13.2992C12.1036 18.1915 11.2633 18.9225 11.2633 19.9898V20.0171C11.2633 21.1391 12.18 21.5027 13.0471 21.7374L13.173 21.7881C13.811 21.9953 14.3457 22.1516 14.3457 22.5973V22.6248C14.3457 23.0431 13.9903 23.356 13.4291 23.356C12.8335 23.3615 12.2495 23.1887 11.7485 22.8593C11.6453 22.8084 11.5957 22.7537 11.5462 22.7302C11.4934 22.6917 11.42 22.704 11.3818 22.7575V22.7848L11.1146 23.5277Z" fill="white"/>
<path d="M30.4048 23.5277C30.3774 23.58 30.3884 23.6443 30.4313 23.684C30.5577 23.7622 30.6604 23.8404 30.7867 23.9185C31.3745 24.2342 32.0329 24.3866 32.696 24.3605C33.9946 24.3605 34.8082 23.6568 34.8082 22.5112V22.4839C34.8082 21.4167 33.8916 21.0493 33.0245 20.7638L32.9328 20.7247C32.295 20.5177 31.7375 20.3338 31.7375 19.8921V19.8648C31.7375 19.5012 32.0695 19.2119 32.5778 19.2119C33.1556 19.2196 33.7232 19.3714 34.2314 19.6536C34.2843 19.6921 34.3573 19.6798 34.3954 19.6263V19.599C34.4222 19.5208 34.6515 18.9226 34.6515 18.8679C34.667 18.8103 34.6477 18.7486 34.6018 18.7116C34.0321 18.366 33.3811 18.1863 32.7189 18.1915H32.593C31.3974 18.1915 30.5575 18.9225 30.5575 19.9898V20.0171C30.5575 21.1391 31.4738 21.5027 32.3409 21.7374L32.4668 21.7881C33.1047 21.9953 33.6394 22.1516 33.6394 22.5973V22.6248C33.6394 23.0431 33.2843 23.356 32.7228 23.356C32.1275 23.3615 31.5433 23.1887 31.0423 22.8593C30.9394 22.8084 30.8896 22.7537 30.8401 22.7302C30.7871 22.6917 30.7137 22.704 30.6756 22.7575V22.7848L30.4048 23.5277Z" fill="white"/>
<path d="M44.7825 20.1417C44.6817 19.7929 44.5141 19.467 44.2891 19.1821C44.051 18.9101 43.7601 18.6894 43.4344 18.5332C42.6708 18.2233 41.8163 18.2233 41.0528 18.5332C40.7267 18.6893 40.4359 18.9101 40.1981 19.1821C39.9632 19.4606 39.7946 19.7887 39.7044 20.1417C39.4984 20.9222 39.4984 21.743 39.7044 22.5234C39.8052 22.8723 39.9728 23.198 40.1981 23.4831C40.4359 23.755 40.7268 23.9758 41.0528 24.1319C41.8163 24.442 42.6707 24.442 43.4344 24.1319C43.7658 23.9853 44.0586 23.7629 44.2891 23.4831C44.524 23.2048 44.6926 22.8766 44.7825 22.5234C44.8882 22.1348 44.9406 21.7333 44.9379 21.3307C44.9426 20.9277 44.8903 20.5261 44.7825 20.1378V20.1417ZM43.6675 21.3306C43.694 21.856 43.5685 22.3781 43.3063 22.8343C43.0675 23.1952 42.6486 23.394 42.2183 23.3512C41.7908 23.3782 41.3794 23.1828 41.1304 22.8343C40.647 21.8915 40.647 20.7736 41.1304 19.8308C41.3689 19.47 41.7878 19.2711 42.2183 19.3139C42.6457 19.2866 43.0571 19.482 43.3063 19.8308C43.5718 20.2857 43.6974 20.8086 43.6675 21.3345" fill="white"/>
<path d="M53.7946 23.1856C53.7815 23.1302 53.7268 23.096 53.6716 23.1081C53.5059 23.175 53.3344 23.2269 53.1598 23.2632C52.9646 23.2938 52.7678 23.3108 52.5705 23.3135C52.1051 23.3405 51.6493 23.1719 51.3117 22.8481C50.9658 22.4204 50.8001 21.8731 50.8498 21.3235C50.8302 20.8092 50.9745 20.3021 51.2615 19.8764C51.555 19.4974 52.0182 19.2931 52.4936 19.3331C52.884 19.3268 53.2732 19.3791 53.6484 19.4884C53.7043 19.4988 53.7589 19.4654 53.7756 19.4107C53.8794 19.1508 53.9564 18.9453 54.0566 18.6622C54.0717 18.6076 54.0409 18.5507 53.9871 18.534C53.7461 18.4493 53.4972 18.3881 53.2443 18.3517C52.9722 18.3194 52.6982 18.3025 52.4244 18.3013C52.0022 18.2937 51.5829 18.373 51.1923 18.534C50.8495 18.6804 50.5428 18.9016 50.2954 19.1819C50.0563 19.4636 49.8733 19.7889 49.7564 20.1401C49.6298 20.5241 49.5686 20.9268 49.5754 21.3312C49.5399 22.1284 49.7961 22.9113 50.2954 23.531C50.8682 24.1146 51.6657 24.4167 52.4782 24.3571C53.0134 24.3635 53.5453 24.2673 54.0452 24.0739C54.0867 24.0462 54.1065 23.9949 54.0951 23.946L53.7946 23.1856Z" fill="white"/>
<path d="M59.4402 19.9382C59.3434 19.6107 59.1771 19.3089 58.9525 19.0537C58.7581 18.8179 58.5123 18.6312 58.2347 18.5082C57.8933 18.3678 57.528 18.2975 57.1597 18.3016C56.7385 18.2915 56.32 18.3712 55.9311 18.5354C55.6051 18.6853 55.3164 18.9077 55.0865 19.1861C54.8569 19.4793 54.6837 19.8136 54.5759 20.1719C54.471 20.5616 54.4194 20.964 54.4224 21.3681C54.4137 21.7744 54.4747 22.1791 54.6027 22.5644C54.699 22.9263 54.8837 23.2572 55.1403 23.5268C55.405 23.7925 55.7181 24.0043 56.0617 24.1503C56.4932 24.319 56.9558 24.3894 57.4171 24.3567C58.0603 24.3956 58.7026 24.2712 59.2866 23.9944C59.3364 23.967 59.3635 23.9438 59.3133 23.8112L59.0563 23.1098C59.0451 23.0641 58.9992 23.0359 58.9539 23.0472C58.9537 23.0472 58.9531 23.0475 58.9525 23.0477H58.9258C58.4417 23.2592 57.9165 23.3563 57.39 23.3321C56.9249 23.3516 56.4689 23.1945 56.1117 22.8919C55.8129 22.5491 55.6574 22.102 55.6778 21.6448H59.3901C59.4453 21.6471 59.4916 21.6034 59.4939 21.5475C59.4939 21.5475 59.4939 21.5475 59.4939 21.5475C59.5707 21.5202 59.6972 20.7683 59.4441 19.9383L59.4402 19.9382ZM55.7585 20.7174C55.793 20.4026 55.8981 20.1001 56.0655 19.833C56.2901 19.4699 56.6921 19.2606 57.1135 19.2874C57.5331 19.2704 57.9307 19.4774 58.1618 19.833C58.319 20.1013 58.4074 20.4053 58.4188 20.7174H55.7585Z" fill="white"/>
<path d="M29.5116 19.9381C29.4142 19.6107 29.2466 19.3088 29.021 19.0537C28.8253 18.8179 28.5782 18.6312 28.2988 18.5082C27.9554 18.3677 27.5876 18.2974 27.2173 18.3016C26.7936 18.2916 26.3727 18.3712 25.9814 18.5354C25.6534 18.6853 25.363 18.9076 25.1317 19.1861C24.9008 19.4793 24.7264 19.8138 24.6181 20.1719C24.5125 20.5617 24.4608 20.964 24.4636 21.3681C24.4549 21.7744 24.5162 22.1791 24.645 22.5644C24.7415 22.9262 24.9273 23.2574 25.1857 23.5268C25.4522 23.7925 25.7671 24.0043 26.1126 24.1503C26.5468 24.319 27.0122 24.3894 27.476 24.3567C28.123 24.3956 28.7696 24.2712 29.3571 23.9944C29.4072 23.967 29.4344 23.9438 29.384 23.8112L29.0943 23.1177C29.0831 23.0718 29.0372 23.0438 28.9915 23.0551C28.9909 23.0551 28.9904 23.0551 28.9901 23.0554H28.963C28.476 23.2669 27.9478 23.3642 27.418 23.3396C26.95 23.3595 26.4916 23.2026 26.1319 22.8995C25.8314 22.5569 25.6749 22.1098 25.6956 21.6527H29.4303C29.4857 21.6548 29.5325 21.6113 29.5344 21.5555C29.5344 21.5554 29.5344 21.5554 29.5344 21.5554C29.6116 21.528 29.7392 20.7761 29.4843 19.9462L29.5116 19.9381ZM25.7766 20.7252C25.8116 20.4105 25.9171 20.108 26.0856 19.8407C26.3114 19.4777 26.7161 19.2682 27.1402 19.2952C27.562 19.2781 27.962 19.4852 28.1946 19.8407C28.353 20.109 28.442 20.4132 28.4535 20.7252H25.7766Z" fill="white"/>
<path d="M19.2003 20.4953C19.0459 20.4953 18.8644 20.4677 18.6098 20.4677C18.2969 20.4722 17.9859 20.5158 17.6835 20.5971C17.4067 20.6705 17.145 20.7941 16.9116 20.9619C16.6799 21.119 16.4934 21.3354 16.3712 21.5894C16.2372 21.8568 16.1669 22.1525 16.1665 22.4524C16.1537 22.751 16.2163 23.0479 16.3479 23.3153C16.459 23.554 16.6277 23.7605 16.8383 23.9152C17.0704 24.0802 17.3331 24.1961 17.61 24.2564C17.9297 24.3345 18.258 24.3701 18.5867 24.3624C18.9663 24.3672 19.3452 24.3317 19.7175 24.2564C20.0764 24.2056 20.5395 24.0994 20.671 24.0721C20.7591 24.0531 20.8455 24.027 20.9294 23.9937C20.9845 23.9801 21.0187 23.9243 21.0067 23.8681V20.3933C21.0586 19.7744 20.843 19.1629 20.4163 18.7186C19.9206 18.3417 19.3087 18.1566 18.6908 18.1968C18.3534 18.1932 18.0166 18.2288 17.6873 18.3028C17.2808 18.3753 16.8893 18.517 16.5296 18.7225C16.4818 18.7597 16.47 18.8277 16.5025 18.8794L16.7612 19.6129C16.7724 19.659 16.8182 19.6873 16.8638 19.6759C16.8644 19.6758 16.8648 19.6757 16.8653 19.6756H16.8924C16.9117 19.673 16.9296 19.6631 16.9425 19.6482C17.4454 19.4028 17.9941 19.269 18.5521 19.2559C18.8774 19.233 19.2019 19.3153 19.4782 19.4914C19.6829 19.6483 19.787 19.8836 19.787 20.4053V20.5622L19.1965 20.5112L19.2003 20.4953ZM17.7297 23.178C17.6452 23.1159 17.5756 23.0354 17.5252 22.9428C17.2945 22.5366 17.3804 22.0213 17.7297 21.715C18.0466 21.4966 18.4309 21.403 18.8104 21.4522C19.1464 21.4533 19.4817 21.4795 19.814 21.5307V23.2564C19.4831 23.3253 19.1478 23.3685 18.8104 23.3859C18.4376 23.4429 18.0563 23.3696 17.7297 23.178Z" fill="white"/>
<path d="M49.3473 18.5422C49.3695 18.4955 49.3505 18.4394 49.3048 18.4165C49.105 18.3524 48.8973 18.3167 48.6878 18.3105C48.3625 18.2768 48.0339 18.331 47.7356 18.4677C47.4959 18.5723 47.2844 18.7339 47.1187 18.9393V18.4677C47.1151 18.4108 47.0704 18.3654 47.0144 18.3615H46.012C45.9562 18.3654 45.9117 18.4108 45.9081 18.4677V24.1498C45.9117 24.2069 45.9562 24.2521 46.012 24.2562H46.9877C47.0435 24.2521 47.088 24.2069 47.0916 24.1498V21.3206C47.0857 20.9845 47.1288 20.6496 47.219 20.3264C47.2782 20.1146 47.3945 19.9241 47.5542 19.7762C47.6819 19.6419 47.8412 19.5432 48.017 19.4893C48.1762 19.4414 48.3407 19.4151 48.5068 19.4109C48.6457 19.4126 48.784 19.4297 48.9192 19.4619C48.9818 19.4689 49.0385 19.4233 49.0466 19.3597C49.1236 19.175 49.3049 18.6524 49.3551 18.5462" fill="white"/>
<path d="M40.1177 15.9422L39.7614 15.8644C39.5934 15.8322 39.423 15.8152 39.252 15.8139C38.6759 15.7721 38.1091 15.9797 37.6926 16.3855C37.2877 16.8707 37.0306 17.4651 36.9533 18.0961L36.9036 18.3566H36.0607C35.9999 18.3586 35.9479 18.4015 35.934 18.4616L35.8077 19.2391C35.7983 19.2991 35.8371 19.3556 35.8957 19.3674H36.7539L35.911 24.0561C35.8652 24.3742 35.7881 24.6867 35.6811 24.9894C35.6267 25.1842 35.54 25.3686 35.4246 25.5336C35.3449 25.6554 35.2277 25.7472 35.0911 25.7941C34.9512 25.838 34.8043 25.855 34.6584 25.8449C34.5817 25.8449 34.4553 25.8178 34.3518 25.8178C34.2804 25.8031 34.212 25.7767 34.1489 25.7401C34.1003 25.7242 34.0472 25.7452 34.0223 25.7905C33.9956 25.8683 33.7658 26.4904 33.7427 26.5407C33.7147 26.5977 33.7369 26.6669 33.7924 26.6962C33.9013 26.7388 34.0126 26.7737 34.1259 26.8013C34.3017 26.8415 34.4821 26.8585 34.6623 26.8517C34.9754 26.8576 35.2873 26.8049 35.5818 26.6962C35.8472 26.5897 36.0834 26.4207 36.2715 26.2025C36.4827 25.9598 36.6479 25.6785 36.7578 25.3743C36.9 24.9597 37.0116 24.5347 37.0913 24.1031L37.9342 19.3092H39.1447C39.2055 19.3073 39.2575 19.2643 39.2714 19.2042L39.3977 18.4267C39.4071 18.3668 39.3683 18.3101 39.3097 18.2984H38.095C38.13 18.0062 38.1981 17.7193 38.2982 17.443C38.3493 17.2931 38.4377 17.1593 38.5549 17.0542C38.6445 16.9682 38.7483 16.8984 38.8614 16.8483C38.996 16.817 39.1334 16.8 39.2715 16.7977C39.3747 16.7977 39.5013 16.8249 39.5779 16.8249C39.6404 16.8303 39.7014 16.8475 39.7578 16.8754C39.8844 16.9026 39.8844 16.8754 39.9111 16.8249L40.191 16.0473C40.2176 15.9967 40.141 15.9696 40.1143 15.9424" fill="white"/>
<path d="M23.4929 24.1515C23.4893 24.2079 23.4447 24.2526 23.3888 24.2566H22.4115C22.3555 24.2526 22.311 24.2079 22.3074 24.1515V16.1322C22.311 16.076 22.3555 16.0312 22.4115 16.0273H23.3656C23.4216 16.0312 23.466 16.076 23.4697 16.1322V24.1592L23.4929 24.1515Z" fill="white"/>
<path d="M2.15584 54.3092C2.02458 54.44 1.88654 54.5636 1.74245 54.6801V52.7167H0V67.9992H1.74741V62.3954C4.26244 65.3037 7.16155 65.0117 9.0371 63.8345C11.3356 62.4053 12.3203 59.3242 11.4984 56.4804C10.2237 52.2914 5.00085 50.8522 2.15584 54.3092ZM5.35055 62.9791C3.05191 62.9791 1.64407 61.0007 1.62445 58.5279C1.60452 56.0551 3.51455 54.0122 5.80338 53.9925C8.09189 53.9729 9.90321 55.9164 9.90321 58.5179C9.88188 60.998 7.86332 62.9909 5.39506 62.9695H5.39474H5.34549L5.35055 62.9791Z" fill="#7D888E"/>
<path d="M64.3037 49.1562V52.6183H67.9708V54.2009H64.3971C64.3724 54.5816 64.3332 54.8586 64.3332 55.1901C64.3332 57.0101 64.402 58.835 64.3332 60.6303C64.2248 62.7719 65.3177 63.2269 67.2863 62.8312C67.4532 62.7854 67.6176 62.7308 67.7786 62.6681C68.1872 64.0083 67.9311 64.4139 66.602 64.5819C63.831 64.9131 62.5018 63.7361 62.4972 60.9223V54.2751L60.9222 54.2009V52.717L62.4872 52.6183V49.1562H64.3037Z" fill="#7D888E"/>
<path d="M28.1599 64.3677H26.3977V52.7157H28.1255V55.4062C29.3411 53.5616 30.6897 52.3845 32.8458 52.4735V54.2886C29.351 54.9465 28.1551 56.435 28.1551 60.0751C28.1599 61.4646 28.1599 62.8792 28.1599 64.3677Z" fill="#7D888E"/>
<path d="M43.6598 47.8645V54.6401C40.5886 51.8754 37.8121 51.6726 35.4594 53.8634C33.1067 56.0546 32.8997 59.9964 34.8888 62.5632C35.6845 63.5987 36.8323 64.3028 38.1126 64.5416C40.3421 64.9918 42.0896 64.1163 43.6501 62.1725V64.3338H45.3383V47.8594H43.6598V47.8645ZM39.4316 63.0528C37.1331 62.9984 35.3514 60.9558 35.4007 58.4184C35.4499 55.8813 37.1677 53.9675 39.5008 53.9971C41.834 54.0267 43.709 56.0396 43.6748 58.6114C43.655 61.0595 41.7008 63.1022 39.4366 63.0528H39.4316Z" fill="#7D888E"/>
<path d="M23.3508 56.1799C23.2475 54.3944 22.1549 53.2123 20.4517 52.7525C18.3561 52.218 16.1382 52.4768 14.2204 53.4795L14.7125 54.9284C16.7749 54.157 17.8674 54.0036 19.2162 54.1719C20.7324 54.3648 22.0761 55.8186 21.5592 57.5447L21.0277 57.4312C19.0588 57.0603 17.1144 56.8424 15.239 57.713C13.4693 58.4667 12.6433 60.5191 13.3934 62.2972C13.6573 62.9226 14.0967 63.4572 14.6582 63.8356C15.638 64.4922 16.8137 64.7876 17.9857 64.6715C19.399 64.5629 20.7193 63.9218 21.6822 62.8762L21.7659 64.3601H23.4051C23.4051 64.0981 23.4689 63.8655 23.4639 63.6773C23.4493 61.1701 23.4985 58.6527 23.3508 56.1799ZM19.989 62.7278C18.547 63.4252 17.0752 63.5288 15.7216 62.5203C14.8284 61.7879 14.6954 60.467 15.4239 59.5696C15.5571 59.4062 15.7136 59.2638 15.8887 59.1472C17.5352 58.3829 19.4068 58.2648 21.1357 58.8157C21.4293 58.9509 21.6422 59.2165 21.7117 59.533C21.9624 60.8683 21.2448 62.1971 19.9938 62.7132L19.989 62.7278Z" fill="#7D888E"/>
<path d="M53.8833 52.3602C50.4859 52.3034 47.6854 55.0249 47.6291 58.4386C47.5724 61.8524 50.2809 64.6662 53.6786 64.723C57.0735 64.7797 59.8725 62.0624 59.9328 58.6512V58.6116C59.9958 55.2307 57.3193 52.4384 53.9542 52.3752C53.9518 52.3752 53.9495 52.3751 53.9474 52.3751L53.8833 52.3602ZM53.8833 63.0874C51.4862 63.0874 49.5861 61.0548 49.5961 58.4731C49.6058 55.8913 51.432 53.9676 53.8638 53.9872C56.2953 54.0072 58.1312 56.0053 58.1312 58.5324C58.1312 61.0597 56.2656 63.0778 53.8784 63.0874H53.8833Z" fill="#7D888E"/>
</svg>

`;
