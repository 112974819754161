import { IOrganization } from '@shared/entities/sdk/';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<IOrganization> = {
  entities: {},
  processing: false,
  errorMessage: undefined,
};

export function handler(
  state: EntitiesState<IOrganization> = initialState,
  action: Action,
): EntitiesState<IOrganization> {
  switch (action.type) {
    case 'ORGANIZATION_ENTITY_GET_START':
    case 'ORGANIZATION_ENTITY_UPDATE_START':
      return {
        ...state,
        processing: true,
      };
    case 'ORGANIZATION_ENTITY_UPDATE_FAILURE':
    case 'ORGANIZATION_ENTITY_GET_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'ORGANIZATION_ENTITY_GET_SUCCESS':
      return {
        ...state,
        entities: { ...indexBy('id', action.organizations) },
        processing: false,
        errorMessage: undefined,
      };

    case 'ORGANIZATION_ENTITY_UPDATE_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.organization.id]: action.organization,
        },
        processing: false,
        errorMessage: undefined,
      };
    case 'LEAD_QUALIFICATION_SUBMIT_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          [action.updatedOrganization.id]: {
            ...state.entities[action.updatedOrganization.id],
            ...action.updatedOrganization,
          },
        },
      };
    default:
      return state;
  }
}
