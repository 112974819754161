import { IProject } from '@shared/entities/sdk/project';

export enum MigrationType {
  AUTOSAVED = 'AUTOSAVED',
  SAVED = 'SAVED',
  DEPLOYED = 'DEPLOYED',
  CURRENT = 'CURRENT',
}

export type WorkflowVersion = {
  id: string;
  name: string;
  migrationType: MigrationType;
  dateCreated: Date;
  workflowId: string;
  project?: IProject;
  author?: {
    id: string;
    firstName: string;
    lastName: string;
  };
};
