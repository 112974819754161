import { indexBy } from '../../../utils';
import { Action, VersionEntityState } from '../../types';

export const initialState: VersionEntityState = {
  entities: {},
  errorMessage: undefined,
  processing: false,
  pageCount: 0,
  extras: [],
};

export function handler(
  state: VersionEntityState = initialState,
  action: Action,
): VersionEntityState {
  switch (action.type) {
    case 'VERSION_ENTITY_FETCH_START':
      return {
        ...state,
        processing: true,
      };
    case 'VERSION_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        entities: action.replace
          ? { ...indexBy('id', action.versions) }
          : { ...state.entities, ...indexBy('id', action.versions) },
        processing: false,
        errorMessage: undefined,
        pageCount: action.pageCount,
      };
    case 'VERSION_ENTITY_FETCH_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'VERSION_ENTITY_UPDATE_VERSION':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          [action.version.id]: action.version,
        },
      };
    case 'VERSION_ENTITY_UPDATE_NAME':
      if (!state.entities[action.id]) {
        return state;
      }
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          [action.id]: {
            ...state.entities[action.id],
            name: action.name,
          },
        },
      };
    case 'VERSION_ENTITY_EXTRAS_FETCH':
      return {
        ...state,
        extras: action.extras,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
