import { transparentize } from 'polished';
import React, { useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import Button from '@shared/ui/sdk/components/Button';
import {
  MODAL_CONTENT_POPUP_STYLE,
  MODAL_OVERLAY_POPUP_STYLE,
} from '@shared/ui/sdk/components/Modal';
import { COLORS } from '@shared/ui/sdk/utils/constants';

import { ConfirmationOptions, MessagePart, State, useDispatch, useSelector } from '../store/types';

const ConfirmContainer = styled.div`
  width: 324px;
  min-height: 158px;
  background-color: ${COLORS.WHITE};
  border-radius: 3px;
  outline: none;
`;

const ConfirmationBodyContainer = styled.div`
  min-height: 106px;
  width: 100%;
  padding: 22px 26px 22px 26px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.BORDER};
`;

const ConfirmationTitleContainer = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.BLACK};
`;

const ConfirmationMessageContainer = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  color: ${COLORS.GREY};
  margin-top: 13px;
  b {
    font-weight: 500;
    color: ${COLORS.BLACK};
  }
`;

const ConfirmationFooterContainer = styled.div`
  height: 52px;
  width: 100%;
  padding: 12px 16px 12px 16px;
  display: flex;
  justify-content: flex-end;
`;

const NoButton = styled(Button)`
  background: ${COLORS.HOVER};
  border: 1px solid ${COLORS.BORDER};
  border-radius: 2px;
  color: ${COLORS.GREY};
  height: 26px;
  &:focus {
    box-shadow: 0 0 0 2px ${transparentize(0.6, COLORS.FOCUS)};
  }
`;

const YesButton = styled(Button)<{ color: string }>`
  background: ${(props) => (props ? props.color : COLORS.PRIMARY)};
  border-radius: 2px;
  color: ${COLORS.WHITE};
  height: 26px;
  &:focus {
    box-shadow: 0 0 0 2px ${transparentize(0.6, COLORS.FOCUS)};
  }
`;

const ConfirmationFooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

// confirmation inner is separate because ref.current is null when try to bind inside popup
// and we need it to when someone press enter
const Confirm: React.FC = () => {
  const dispatch = useDispatch();
  const key: string | undefined = useSelector((state: State) => state.confirm.active);
  const activeConfirmation: ConfirmationOptions | undefined = useSelector((state: State) =>
    state.confirm.active ? state.confirm.confirmations[state.confirm.active] : undefined,
  );

  if (!key || !activeConfirmation) {
    return null;
  }

  return (
    <Popup
      open={true}
      onClose={() => dispatch({ type: 'SET_CONFIRMATION_RESULT', result: false, key })}
      contentStyle={{
        ...MODAL_CONTENT_POPUP_STYLE,
        height: '158px',
        width: '324px',
        minWidth: '324px',
        borderRadius: '3px',
        padding: '0px',
      }}
      overlayStyle={MODAL_OVERLAY_POPUP_STYLE}
    >
      <ConfirmInner />
    </Popup>
  );
};

export const ConfirmInner = () => {
  const dispatch = useDispatch();
  const noButtonRef = useRef<HTMLButtonElement>(null);
  const key: string = useSelector((state: State) => state.confirm.active)!;
  const activeConfirmation: ConfirmationOptions = useSelector((state: State) =>
    state.confirm.active ? state.confirm.confirmations[state.confirm.active] : undefined,
  )!;

  const buildMessage = (parts: MessagePart[]): JSX.Element => {
    return (
      <p>
        {parts.map((part: MessagePart) =>
          part.type === 'BOLD' ? <b> {part.message}</b> : part.message,
        )}
      </p>
    );
  };

  const handleNo = (): void => {
    dispatch({ type: 'SET_CONFIRMATION_RESULT', key, result: false });
  };

  const handleYes = (): void => {
    dispatch({ type: 'SET_CONFIRMATION_RESULT', key, result: true });
  };

  useEffect(() => {
    if (activeConfirmation && noButtonRef.current) {
      noButtonRef.current.focus();
    }
  }, [activeConfirmation]);

  return (
    <ConfirmContainer>
      <ConfirmationBodyContainer>
        <ConfirmationTitleContainer>{activeConfirmation.title}</ConfirmationTitleContainer>
        <ConfirmationMessageContainer>
          {Array.isArray(activeConfirmation.message)
            ? buildMessage(activeConfirmation.message)
            : activeConfirmation.message}
        </ConfirmationMessageContainer>
      </ConfirmationBodyContainer>
      <ConfirmationFooterContainer>
        <ConfirmationFooterButtonContainer>
          <NoButton title={activeConfirmation.noText} onClick={handleNo} ref={noButtonRef} />
          {!activeConfirmation.disableYesButton && (
            <YesButton
              title={activeConfirmation.yesText}
              onClick={handleYes}
              data-testid="confirm-yes-btn"
              color={activeConfirmation.yesButtonColor ?? COLORS.PRIMARY}
            />
          )}
        </ConfirmationFooterButtonContainer>
      </ConfirmationFooterContainer>
    </ConfirmContainer>
  );
};

export default Confirm;
