import { BillingPlan } from '../stripe';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class BillingError extends ParagonError {
  name: string = 'BillingError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class GlobalBillingSyncFailedError extends BillingError {
  name: string = 'GlobalBillingSyncFailedError';

  constructor(meta: { numSuccessful: number; numFailed: number }) {
    super({
      code: ERROR_CODE.GLOBAL_BILLING_SYNC,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Failed global billing sync.',
      meta,
    });
  }
}

export class StripeCustomerNotFoundError extends BillingError {
  name: string = 'StripeCustomerNotFoundError';

  constructor(meta: { subscriptionId: string; stripeCustomerId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.STRIPE_CUSTOMER_NOT_FOUND,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Unable to find associated Stripe customer id.',
      meta,
    });
  }
}

export class StripeCustomerDeletedError extends BillingError {
  name: string = 'StripeCustomerDeletedError';

  constructor(meta: { stripeCustomerId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.STRIPE_CUSTOMER_DELETED,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Stripe customer has been deleted.',
      meta,
    });
  }
}

export class StripeProductDeletedError extends BillingError {
  name: string = 'StripeProductDeletedError';

  constructor(meta: { stripeSubscriptionId: string; stripeProductId: string }) {
    super({
      code: ERROR_CODE.STRIPE_PRODUCT_DELETED,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Stripe product has been deleted.',
      meta,
    });
  }
}

export class StripeWebhookMissingAuthorizationHeaderError extends BillingError {
  name: string = 'StripeWebhookMissingAuthorizationHeaderError';

  constructor(meta: { payload: any }) {
    super({
      code: ERROR_CODE.STRIPE_WEBHOOK_MISSING_AUTHORIZATION_HEADER,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Invalid request.',
      meta,
    });
  }
}

export class PreferredSubscriptionNotFoundError extends BillingError {
  name: string = 'PreferredSubscriptionNotFoundError';

  constructor(meta: { stripeCustomerId: string; stripeEvent?: object }) {
    super({
      code: ERROR_CODE.PREFERRED_STRIPE_SUBSCRIPTION_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find subscription.',
      meta,
    });
  }
}

export class IntegrationCheckoutMinimumError extends BillingError {
  name: string = 'IntegrationCheckoutMinimumError';

  constructor(meta: { projectId: string; priceId: string; quantity: number; minimum: number }) {
    super({
      code: ERROR_CODE.INTEGRATION_NOT_FOUND_FOR_CUSTOM_INTEGRATION,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `You cannot checkout with less than ${meta.minimum} integrations.`,
      meta,
    });
  }
}

export class SubscriptionNotFoundForProjectError extends BillingError {
  name: string = 'SubscriptionNotFoundForProjectError';

  constructor(meta: { projectId: string; organizationId: string }) {
    super({
      code: ERROR_CODE.SUBSCRIPTION_NOT_FOUND_FOR_PROJECT,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find subscription.',
      meta,
    });
  }
}

export class SubscriptionNotFoundFromIdError extends BillingError {
  name: string = 'SubscriptionNotFoundFromIdError';

  constructor(meta: { subscriptionId: string }) {
    super({
      code: ERROR_CODE.SUBSCRIPTION_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find subscription.',
      meta,
    });
  }
}

export class SubscriptionPlanUnsupportedFeatureError extends BillingError {
  name: string = 'SubscriptionPlanUnsupportedFeatureError';

  constructor({
    message,
    ...meta
  }: {
    projectId: string;
    plan: BillingPlan;
    subscriptionId: string;
    message?: string;
  }) {
    super({
      code: ERROR_CODE.SUBSCRIPTION_PLAN_UNSUPPORTED_FEATURE,
      httpStatus: HttpStatus.PAYMENT_REQUIRED,
      message:
        message ?? 'Insufficient plan. Contact sales@useparagon.com to learn more or upgrade.',
      meta,
    });
  }
}
