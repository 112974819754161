import * as util from 'util';

import * as stackTraceParser from 'stacktrace-parser';

import { isBrowser } from '@shared/types/sdk/environment/utils';

import { LEVEL, SubscriptionHandler, SubscriptionLedger, getEnv, setEnv, shouldLog } from './utils';

export { LEVEL, setEnv };

let app: string = 'logger';

const COLORS: Record<string, string> = {
  RESET: '\x1b[0m',
  UNDERSCORE: '\x1b[4m',
  ITALIC: '\x1b[34m',
  BACKGROUND: '\x1b[40m',
  [LEVEL.TRACE]: '\x1b[36m', // cyan
  [LEVEL.DEBUG]: '\x1b[32m', // green
  [LEVEL.INFO]: '\x1b[34m', // blue
  [LEVEL.WARN]: '\x1b[33m', // yellow
  [LEVEL.ERROR]: '\x1b[31m', // red
  [LEVEL.FATAL]: '\x1b[31m', // red
};

export function isJSON(obj: any): boolean {
  if (typeof obj === 'number') {
    return false;
  } else if (typeof obj === 'boolean') {
    return false;
  } else if (typeof obj === 'function') {
    return false;
  } else if (typeof obj === 'string') {
    try {
      JSON.parse(obj);
    } catch (e) {
      return false;
    }
  } else {
    try {
      JSON.parse(JSON.stringify(obj));
    } catch (e) {
      return false;
    }
  }

  return true;
}

export function inspect(value: any): string {
  const config = process.stdout.isTTY
    ? {
        colors: true,
      }
    : {
        // try to fit on a single line
        breakLength: Infinity,
        compact: true,
      };

  return util.inspect(value, {
    depth: null,
    ...config,
  });
}

function dump(obj: any): string {
  if (obj === undefined) {
    return `${getColor(COLORS.DANGER)} undefined ${getColor(COLORS.RESET)}`;
  } else if (typeof obj === 'boolean') {
    return `${getColor(COLORS.ITALIC)} ${obj} ${getColor(COLORS.RESET)}`;
  } else if (typeof obj === 'string' && obj === '') {
    return `${getColor(COLORS.ITALIC)} (empty string) ${getColor(COLORS.RESET)}`;
  } else if (typeof obj === 'string') {
    return obj;
  } else if (obj instanceof Error) {
    return obj.stack || obj.message || (obj.toString ? obj.toString() : `${obj}`);
  } else if (isBrowser()) {
    // the browser console has a great process for inspecting objects
    // so we'll leave it to that for a better developer experience
    return obj;
  } else if (isJSON(obj)) {
    return getEnv().LOG_EXPAND === 'true' ? obj : inspect(obj);
  } else {
    return obj;
  }
}

type Trace = {
  methodName: string;
  lineNumber: string;
  filePath: string;
};

type TraceCallback = {
  (trace: Trace): void;
};

function cleanFilePath(file: string): string {
  const pathPrefixes: string[] = [
    'http://localhost:4000/_next/static/development/',
    'http://localhost:4000/.next/static/development/',
    '/usr/src/app',
    '/dist/src',
    '/_next/server/static/development',
    'webpack:///',
    'webpack://',
  ];
  let filePath: string = file || '';
  filePath = filePath.replace(process.cwd(), '').replace('(', '').replace(')', '');
  pathPrefixes.forEach((str: string) => (filePath = filePath.replace(new RegExp(`^(${str})`), '')));

  return filePath;
}

function getTraceFromBrowser(e: Error, traverse: number, callback: TraceCallback): void {
  const { mapStackTrace } = require('sourcemapped-stacktrace');

  // @ts-ignore
  mapStackTrace(
    e.stack,
    (mappedStack: string[]) => {
      const item: string = mappedStack[traverse].trim().replace('at ', '');
      let split: string[] = item.split(' ');
      const methodName: string = split[0];
      const fileMeta: string = split[1];
      const cleanedFileMeta: string = cleanFilePath(fileMeta);
      split = cleanedFileMeta.split(':');
      const filePath = split[split.length - 3];
      const lineNumber: string = split[split.length - 2];

      callback({
        methodName,
        lineNumber,
        filePath,
      });
    },
    { cacheGlobally: true },
  );
}

function getTraceFromServer(e: Error, traverse: number, callback: TraceCallback): void {
  const stack: stackTraceParser.StackFrame[] = stackTraceParser.parse(e.stack || '');
  const trace: stackTraceParser.StackFrame = stack[traverse];
  const methodName: string = trace.methodName;
  const lineNumber: string = `${trace.lineNumber}` || '?';
  const filePath: string = cleanFilePath(trace.file || '');

  callback({
    methodName,
    lineNumber,
    filePath,
  });
}

function getTraceFromError(e: Error, traverse: number, callback: TraceCallback): void {
  if (!isBrowser()) {
    return getTraceFromServer(e, traverse, callback);
  }

  return getTraceFromBrowser(e, traverse, callback);
}

function getColor(color: string): string {
  return getEnv().LOG_WITH_COLORS === 'true' ? color : '';
}

// level is the log level
// traverse is how many function calls back to log the stacktrace from
// args are what should be logged
function print(level: LEVEL, traverse: number = 0, message: string, ...args: any[]): void {
  Object.keys(subscriptions[level]).map((id: string) =>
    subscriptions[level][id].handler(message, ...args),
  );

  if (!shouldLog(level)) {
    return;
  }

  const e: Error = new Error('dummy error');
  getTraceFromError(e, 2 + traverse, (trace: Trace) => {
    const { lineNumber, filePath } = trace;
    const output: any[] = [message];

    for (const arg of args) {
      output.push(dump(arg));
    }

    // eslint-disable-next-line no-console
    console.log(
      `${getColor(COLORS.BACKGROUND)}${getColor(COLORS.UNDERSCORE)}${getColor(COLORS[level])}`,
      `${level} ${app}: ${filePath} (ln ${lineNumber}) >`,
      getColor(COLORS.RESET),
      ...output,
      getColor(COLORS.RESET),
    );
  });
}

const subscriptions: SubscriptionLedger = {
  [LEVEL.TRACE]: {},
  [LEVEL.DEBUG]: {},
  [LEVEL.INFO]: {},
  [LEVEL.WARN]: {},
  [LEVEL.ERROR]: {},
  [LEVEL.FATAL]: {},
};
export const subscribe = (levels: LEVEL[], handler: SubscriptionHandler): string => {
  const id: string =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  levels.forEach(
    (level: LEVEL) =>
      (subscriptions[level][id] = {
        id,
        handler,
      }),
  );

  return id;
};

export const unsubscribe = (level: LEVEL, id: string): void => {
  delete subscriptions[level][id];
};

export const configure = (_app: string): void => {
  app = _app;
};

export const log = (level: LEVEL, traverse: number, message: string, ...args: any[]) =>
  print(level, traverse, message, ...args);

export const trace = (message: string, ...args: any[]) => print(LEVEL.TRACE, 0, message, ...args);
export const debug = (message: string, ...args: any[]) => print(LEVEL.DEBUG, 0, message, ...args);
export const info = (message: string, ...args: any[]) => print(LEVEL.INFO, 0, message, ...args);
export const warn = (message: string, ...args: any[]) => print(LEVEL.WARN, 0, message, ...args);
export const error = (message: string, ...args: any[]) => print(LEVEL.ERROR, 0, message, ...args);
export const fatal = (message: string, ...args: any[]) => print(LEVEL.FATAL, 0, message, ...args);
export const danger = error;
export const panic = fatal;

// used to prevent breaking imports
export const startContext = () => {};
export const setContext = () => {};
