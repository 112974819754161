import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Dropbox',
  description: 'Access, create, and update their files in Dropbox',
  authUrl: 'https://www.dropbox.com/oauth2/authorize',
  accessTokenUrl: 'https://api.dropboxapi.com/oauth2/token',
  iconSvg,
  clientIdLabel: 'App Key',
  clientSecretLabel: 'App Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'App Key',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/dropbox`,
      documentationLinkLabel: 'How to obtain a App Key?',
    },
    { type: SidebarInputType.ValueText, title: 'App Secret', id: 'clientSecret' },
  ],
  scopes: { values: [], notAllowed: true },
  documentationLink: 'https://developers.dropbox.com/oauth-guide',
};
