import jshint from 'jshint';

import { ResolvedFunctionParameters } from '@shared/types/sdk/resolvers';
import { FunctionStep, StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';

/**
 * validates that an event step is properly configured
 */
export default class FunctionStepValidator extends StepValidator<
  FunctionStep,
  ResolvedFunctionParameters
> {
  stepType: FunctionStep['type'] = StepType.FUNCTION;

  /**
   * validates that a step is valid
   *
   * @param {FunctionStep} step
   * @returns {FunctionStep}
   * @memberof FunctionStepValidator
   */
  validate(step: FunctionStep): FunctionStep {
    // Rules can be found here: https://github.com/jshint/jshint/blob/b6664777527f3225cdf5e809857a67b90d4b1796/examples/.jshintrc
    const isValid = jshint.JSHINT(step.parameters.code, {
      // ---- Enforcing settings
      bitwise: true, // true: Prohibit bitwise operators (&, |, ^, etc.)
      camelcase: false, // true: Identifiers must be in camelCase
      curly: false, // true: Require {} for every new block or scope
      eqeqeq: false, // true: Require triple equals (===) for comparison
      forin: false, // true: Require filtering for..in loops with obj.hasOwnProperty()
      freeze: true, // true: prohibits overwriting prototypes of native objects such as Array, Date etc.
      immed: false, // true: Require immediate invocations to be wrapped in parens e.g. `(function () { } ());`
      latedef: false, // true: Require variables/functions to be defined before being used
      newcap: false, // true: Require capitalization of all constructor functions e.g. `new F()`
      noarg: true, // true: Prohibit use of `arguments.caller` and `arguments.callee`
      noempty: false, // true: Prohibit use of empty blocks
      nonbsp: false, // true: Prohibit "non-breaking whitespace" characters.
      nonew: false, // true: Prohibit use of constructors for side-effects (without assignment)
      plusplus: false, // true: Prohibit use of `++` and `--`
      quotmark: false, // Quotation mark consistency:
      undef: true, // true: Require all non-global variables to be declared (prevents global leaks)
      unused: false, // Unused variables:
      strict: false, // true: Requires all functions run in ES5 Strict Mode
      maxparams: false, // {int} Max number of formal params allowed per function
      maxdepth: false, // {int} Max depth of nested blocks (within functions)
      maxstatements: false, // {int} Max number statements per function
      maxcomplexity: false, // {int} Max cyclomatic complexity per function
      maxlen: false, // {int} Max number of characters per line
      varstmt: false, // true: Disallow any var statements. Only `let` and `const` are allowed.

      // ---- Relaxing settings
      asi: true, // true: Tolerate Automatic Semicolon Insertion (no semicolons)
      boss: false, // true: Tolerate assignments where comparisons would be expected
      debug: false, // true: Allow debugger statements e.g. browser breakpoints.
      eqnull: true, // true: Tolerate use of `== null`
      esversion: 11, // {int} Specify the ECMAScript version to which the code must adhere.
      moz: false, // true: Allow Mozilla specific syntax (extends and overrides esnext features)
      evil: false, // true: Tolerate use of `eval` and `new Function()`
      expr: false, // true: Tolerate `ExpressionStatement` as Programs
      funcscope: true, // true: Tolerate defining variables inside control statements
      globalstrict: false, // true: Allow global "use strict" (also enables 'strict')
      iterator: false, // true: Tolerate using the `__iterator__` property
      lastsemic: true, // true: Tolerate omitting a semicolon for the last statement of a 1-line block
      laxbreak: false, // true: Tolerate possibly unsafe line breakings
      laxcomma: true, // true: Tolerate comma-first style coding
      loopfunc: true, // true: Tolerate functions being defined in loops
      multistr: true, // true: Tolerate multi-line strings
      noyield: false, // true: Tolerate generator functions with no yield statement in them.
      notypeof: false, // true: Tolerate invalid typeof operator values
      proto: false, // true: Tolerate using the `__proto__` property
      scripturl: false, // true: Tolerate script-targeted URLs
      shadow: false, // true: Allows re-define variables later in code e.g. `var x=1; x=2;`
      sub: true, // true: Tolerate using `[]` notation when it can still be expressed in dot notation
      supernew: false, // true: Tolerate `new function () { ... };` and `new Object;`
      validthis: false, // true: Tolerate using this in a non-constructor function

      // ---- Environment
      browser: true,
      node: true,
    });

    if (!isValid) {
      throw new Error(jshint.JSHINT.errors[0].reason);
    }

    return step;
  }
}
