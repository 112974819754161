import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.TWITTER,
  provider: ProviderType.TWITTER,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Twitter account',
  icon: iconSvg,
  description: "Connect to your users' Twitter account to manage their tweets.",
  sidebarSections: [],
  hideOAuthApps: true,
};

export default authConfig;
