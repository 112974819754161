/**
 * Twitter api base url
 */
export const TWITTER_API_BASE_URL: string = 'https://api.twitter.com';

/**
 * Twitter auth api base url
 */
export const TWITTER_AUTH_BASE_URL: string = `${TWITTER_API_BASE_URL}/oauth/authorize`;

/**
 * Twitter token api url
 */
export const TWITTER_TOKEN_API_URL: string = `${TWITTER_API_BASE_URL}/oauth/access_token`;

// User API Endpoint
export const USER_ENDPOINT = '/2/users/me';
