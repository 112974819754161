import { CredentialKeys } from '../configs';

export type Environment = {
  [CredentialKeys.CLIENT_ID]: string;
  [CredentialKeys.CLIENT_SECRET]: string;
  [CredentialKeys.SCOPES]?: string;
};

export enum AdCreativeType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  LINK = 'LINK',
  LEAD_GEN_FORM_ID = 'LEAD_GEN_FORM_ID',
}

export enum CampaignObjective {
  APP_INSTALLS = 'APP_INSTALLS',
  BRAND_AWARENESS = 'BRAND_AWARENESS',
  CONVERSIONS = 'CONVERSIONS',
  EVENT_RESPONSES = 'EVENT_RESPONSES',
  LEAD_GENERATION = 'LEAD_GENERATION',
  LINK_CLICKS = 'LINK_CLICKS',
  LOCAL_AWARENESS = 'LOCAL_AWARENESS',
  MESSAGES = 'MESSAGES',
  OFFER_CLAIMS = 'OFFER_CLAIMS',
  PAGE_LIKES = 'PAGE_LIKES',
  POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  PRODUCT_CATALOG_SALES = 'PRODUCT_CATALOG_SALES',
  REACH = 'REACH',
  STORE_VISITS = 'STORE_VISITS',
  VIDEO_VIEWS = 'VIDEO_VIEWS',
}

export enum CampaignStatus {
  PAUSED = 'PAUSED',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}

export enum DefaultEventName {
  PURCHASE = 'PURCHASE',
  LEAD = 'LEAD',
}

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, any>;
  FACEBOOK_ADS_ACCOUNT_ID: string;
};

export type CodeExchangeResponse = { access_token: string };

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
  FACEBOOK_ADS_ACCOUNT_ID: string;
};

export type UserInfoResponse = { name: string; email?: string; id: string };

export type ExchangeCredentialResponse = { access_token: string };

export type GeneralFacebookAPIResponse = {
  data: GeneralFacebookAPIResponseEntity[];
  paging: { next: string | undefined };
};

export type GeneralFacebookAPIResponseEntity = {
  id: string;
  account_id: string;
  name: string;
  campaign_id?: string;
  status: string;
};

export type GetCampaignDTO = {};

export type CreateCampaignDTO = {
  name: string;
  objective: string;
  status: string;
};

export type UpdateCampaignDTO = {
  campaignId: string;
  name: string;
  objective: string;
  status: string;
};

export type GetCampaignByIdDTO = {
  campaignId: string;
};

export type CreateAdSetDTO = {
  name: string;
  campaignId: string;
  status: string;
  optimizationGoal?: string;
  billingEvent?: string;
  bidAmount: string;
  dailyBudget: string;
  startTime: string;
  targeting: string | object;
  promotedObject?: string | object;
};

export type UpdateAdSetDTO = {
  adSetId: string;
  name: string;
  campaignId: string;
  status: string;
  optimizationGoal: string;
  billingEvent: string;
  bidAmount: string;
  dailyBudget: string;
  startTime: string;
  targeting: string;
};

export type GetAdSetByIdDTO = {
  adSetId: string;
};

export type SendConversionEventDTO = {
  pixel: string;
  eventName?: string;
  eventTime?: string;
  eventId: string;
  eventSourceUrl: string;
  customerEmail: string;
  customerFirstName: string;
  customerLastName: string;
  customerCity: string;
  customerState: string;
  customerCountryCode: string;
  customerExternalId: string;
  clickId: string;
  browserId: string;
  contentCategory: string;
  contentId: string;
  contentName: string;
  customerClientIpAddress: string;
  customerClientUserAgent: string;
  actionSource: string;
  currency?: string;
  value?: string;
};

export type AdCreativeDTO = {
  imageURL?: string;
  linkURL?: string;
  linkTitle?: string;
  linkDescription?: string;
  message?: string;
  additionalField?: string | object;
  leadGenFormId?: string;
  adCreativeType: string;
  callToActionType?: string;
};

export type CreateAdCreativeDTO = {
  name: string;
  page: string;
  adCreativeJson: string | object;
};

export type CreateAdCreativeJsonDTO = {
  name: string;
  picture?: string;
  link?: string;
  linkTitle?: string;
  description?: string;
  message?: string;
  callToActionType?: string;
  lead_gen_form_id?: string;
};

export type CreateLeadGenFormDTO = {
  name: string;
  page: string;
  followUpActionText: string;
  followUpActionUrl: string;
  questionJson: string | object;
  privacyPolicyUrl: string;
  contextCard?: string | object;
};

export type FilterResponseEntity = {
  operator: string;
  value: string;
  variable: string;
};

export type CreateUpdateAdDTO = {
  name: string;
  status: string;
  adSetId?: string;
  adCreativeId: string;
  adId?: string;
};

export type FilterResponse = FilterResponseEntity[];
