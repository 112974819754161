import React from 'react';

import {
  FIELDS_PREFIX,
  createTestConnectionButton,
  keyNameToFriendlyName,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionButtonStyle,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';
import { DataType, KeyedSource, ValueSource } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { Operation, OperationOption, operationOptions, operations } from '../shared/types';

import { OperationOptionInputs, collectionNameInput, queryOptionsInput } from './shared/inputs';

export const authConfig: ActionConfig = {
  actionType: Action.NONE,
  provider: ProviderType.MONGODB,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your MongoDB database',
  description: 'Add MongoDB to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name',
          subtitle: 'Label this database for use in your Paragon workflows.',
          placeholder: 'name this database',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_DATABASE',
          title: 'Database Name',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_HOST',
          title: 'Host',
          placeholder: 'mongodb.example.com',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_PORT',
          title: 'Port',
          placeholder: '27017',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_USER',
          title: 'User',
          subtitle: 'Specify a username and a password with access privileges to this database.',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_PASSWORD',
          title: 'Password',
          type: SidebarInputType.ValueText,
          inputType: 'password',
        },
      ],
      buttons: [
        createTestConnectionButton(Action.MONGODB, [
          'MONGODB_DATABASE',
          'MONGODB_HOST',
          'MONGODB_PORT',
          'MONGODB_USER',
          'MONGODB_PASSWORD',
        ]),
      ],
    },
  ],
};

const getEnumValues = (
  columns:
    | {
        [key: string]: DataType;
      }
    | undefined,
): EnumInputValue[] => {
  if (!columns) {
    return [];
  }
  return Object.keys(columns).map((fieldName: string) => ({
    label: fieldName,
    value: fieldName,
  }));
};

const fetchColumnsAction: DynamicDataSource<{ [key: string]: DataType }> = {
  type: DataSourceType.DYNAMIC,
  title: 'Column',
  cacheKey: 'columns',
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => ({
    actionType: Action.MONGODB,
    intent: Intent.INFER_COLLECTION_SCHEMA,
    credentials: options.credentials,
    actionParameters: options.actionParameters.filter(
      (value: KeyedSource<DataType.ANY>) => value.key === 'collectionName',
    ),
  }),
  mapRefreshToValues: (response: any): { [key: string]: DataType } => {
    return response?.result?.output || {};
  },
  refreshDependencies: ['collectionName'],
};

const config: ActionConfig = {
  actionType: Action.MONGODB,
  name: 'MongoDB',
  description: 'Query or update records in your MongoDB database.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect to a MongoDB database',
          placeholder: 'choose a database',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.WRITE_QUERY,
                    label: 'Write MongoDB Query',
                  },
                  {
                    value: Intent.FIND_RECORDS,
                    label: 'Find Documents',
                  },
                  {
                    value: Intent.FIND_RECORD_BY_ID,
                    label: 'Find Document by ID',
                  },
                  {
                    value: Intent.CREATE_RECORD,
                    label: 'Create Document',
                  },
                  {
                    value: Intent.UPDATE_RECORDS,
                    label: 'Update Documents',
                  },
                  {
                    value: Intent.UPDATE_RECORD_BY_ID,
                    label: 'Update Document by ID',
                  },
                  {
                    value: Intent.DELETE_RECORD_BY_ID,
                    label: 'Delete Document by ID',
                  },
                  {
                    value: Intent.DELETE_RECORDS,
                    label: 'Delete Documents',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters) =>
      parameters.credentials?.length
        ? {
            inputs: [collectionNameInput],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.FIND_RECORDS:
        case Intent.UPDATE_RECORDS:
        case Intent.DELETE_RECORDS:
          return {
            inputs: [queryOptionsInput],
          };
        case Intent.FIND_RECORD_BY_ID:
        case Intent.UPDATE_RECORD_BY_ID:
        case Intent.DELETE_RECORD_BY_ID:
          return {
            inputs: [
              {
                id: 'documentId',
                title: 'Document ID',
                type: SidebarInputType.Text,
                required: true,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if ([Intent.WRITE_QUERY].includes(parameters.intent as Intent)) {
        const operationSource = pickValueSourceByKey(parameters.actionParameters, 'operation');

        const operation: Operation | undefined = operationSource
          ? (operationSource.value as Operation)
          : undefined;

        const options = operation ? operationOptions[operation] : [];
        return {
          inputs: [
            {
              id: 'operation',
              title: 'Operation',
              type: SidebarInputType.Enum,
              getValues: (_parameters: ActionStepParameters) => {
                return operations.map((operation: Operation) => ({
                  value: operation,
                  label: operation,
                }));
              },
            },
            ...options.map((option: OperationOption) => OperationOptionInputs[option]),
          ],
        };
      } else {
        return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (
        [Intent.CREATE_RECORD, Intent.UPDATE_RECORD_BY_ID, Intent.UPDATE_RECORDS].includes(
          parameters.intent as Intent,
        )
      ) {
        return {
          inputs: (
            getEnumValues(
              (
                parameters.actionParameters.find(
                  (source: KeyedSource<DataType.ANY>) => source.key === 'columns',
                )?.source as ValueSource
              )?.value,
            ) || []
          )
            .filter(({ label }: EnumInputValue) => label !== '_id')
            .map(({ label }: EnumInputValue) => ({
              id: `${FIELDS_PREFIX}${label}`,
              title: keyNameToFriendlyName(label),
              subtitle: (
                <>
                  This value will be saved as <code>{label}</code> in the resulting document.
                </>
              ),
              type: SidebarInputType.TextArea,
              lines: 1,
              required: false,
            })),
          buttons: [
            {
              id: 'refreshFields',
              type: SidebarInputType.ActionButton,
              title: 'Refresh Fields',
              style: ActionButtonStyle.DEFAULT,
              source: fetchColumnsAction,
            },
          ],
        };
      }
      return { inputs: [] };
    },
  ],
};

export default config;
