import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.SAGE_ACCOUNTING,
  provider: ProviderType.SAGE_ACCOUNTING,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Sage Accounting accounts',
  icon: iconSvg,
  description:
    'Sage Accounting accounts to manage their invoicing, cashflow, payments, tax, and more.',
  sidebarSections: [],
};

export const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Sage Accounting accounts',
  placeholder: 'connect to Sage Accounting accounts',
  type: SidebarInputType.Auth,
  config: authConfig,
};

export default authConfig;
