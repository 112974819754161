export default `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.7707 21.8391C21.9615 20.2493 21.9043 20.0162 23.0884 20.2737L23.389 20.3001C24.2995 20.3416 25.4905 20.1537 26.1902 19.8286C27.6965 19.1297 28.5896 17.9628 27.1044 18.2694C23.7165 18.9683 23.4836 17.8212 23.4836 17.8212C27.061 12.5132 28.5565 5.77517 27.2657 4.12585C23.7449 -0.372908 17.6505 1.75445 17.5485 1.80969L17.516 1.81568C16.8466 1.67679 16.0975 1.5938 15.2559 1.5802C13.7228 1.55501 12.5594 1.98212 11.677 2.65146C11.677 2.65146 0.804906 -1.8277 1.31069 8.28445C1.41827 10.4356 4.39413 24.5618 7.94341 20.295C9.24066 18.7348 10.4943 17.4156 10.4943 17.4156C11.1168 17.8292 11.8622 18.0401 12.6438 17.9644L12.7043 17.9128C12.6853 18.1066 12.6937 18.296 12.7284 18.5203C11.8143 19.542 12.0829 19.7213 10.2549 20.0976C8.40542 20.4788 9.49197 21.1574 10.2012 21.3347C11.0612 21.5498 13.0507 21.8545 14.3954 19.9725L14.3419 20.1872C14.7005 20.4742 14.6763 22.2497 14.7271 23.5182C14.7781 24.7868 14.863 25.9706 15.1216 26.6686C15.3802 27.3664 15.6853 29.1642 18.0878 28.6493C20.0956 28.219 21.6308 27.5998 21.7707 21.839" fill="black"/>
<path d="M21.7707 21.8391C21.9615 20.2493 21.9043 20.0162 23.0884 20.2737L23.389 20.3001C24.2995 20.3416 25.4905 20.1537 26.1902 19.8286C27.6965 19.1297 28.5896 17.9628 27.1044 18.2694C23.7165 18.9683 23.4836 17.8212 23.4836 17.8212C27.061 12.5132 28.5565 5.77517 27.2657 4.12585C23.7449 -0.372908 17.6505 1.75445 17.5485 1.80969L17.516 1.81568C16.8466 1.67679 16.0975 1.5938 15.2559 1.5802C13.7228 1.55501 12.5594 1.98212 11.677 2.65146C11.677 2.65146 0.804906 -1.8277 1.31069 8.28445C1.41827 10.4356 4.39413 24.5618 7.94341 20.295C9.24066 18.7348 10.4943 17.4156 10.4943 17.4156C11.1168 17.8292 11.8622 18.0401 12.6438 17.9644L12.7043 17.9128C12.6853 18.1066 12.6937 18.296 12.7284 18.5203C11.8143 19.542 12.0829 19.7213 10.2549 20.0976C8.40542 20.4788 9.49197 21.1574 10.2012 21.3347C11.0612 21.5498 13.0507 21.8545 14.3954 19.9725L14.3419 20.1872C14.7005 20.4742 14.6763 22.2497 14.7271 23.5182C14.7781 24.7868 14.863 25.9706 15.1216 26.6686C15.3802 27.3664 15.6853 29.1642 18.0878 28.6493C20.0956 28.219 21.6308 27.5998 21.7707 21.839" stroke="black" stroke-width="1.38472"/>
<path d="M27.1043 18.2693C23.7161 18.9682 23.4832 17.821 23.4832 17.821C27.0606 12.5127 28.5561 5.77454 27.2655 4.12542C23.7447 -0.373068 17.6501 1.75443 17.5484 1.80966L17.5156 1.81552C16.8462 1.67656 16.0971 1.59378 15.2551 1.58004C13.722 1.55491 12.559 1.98196 11.6766 2.65116C11.6766 2.65116 0.804298 -1.82779 1.30995 8.28429C1.41752 10.4356 4.39332 24.5618 7.94274 20.295C9.24005 18.7348 10.4935 17.4156 10.4935 17.4156C11.1161 17.8292 11.8614 18.0401 12.6428 17.9643L12.7035 17.9128C12.6846 18.1065 12.6932 18.296 12.7278 18.5203C11.8134 19.5419 12.0821 19.7213 10.2542 20.0976C8.40461 20.4787 9.49116 21.1574 10.2006 21.3347C11.0606 21.5498 13.0503 21.8545 14.3947 19.9725L14.3411 20.1872C14.6993 20.4742 14.9509 22.0538 14.9087 23.4857C14.8666 24.9176 14.8384 25.9007 15.1207 26.6686C15.403 27.4365 15.6844 29.1642 18.0873 28.6493C20.0952 28.219 21.1356 27.104 21.2804 25.244C21.3831 23.9217 21.6156 24.1172 21.6302 22.935L21.8167 22.3753C22.0317 20.5829 21.8508 20.0046 23.0879 20.2736L23.3885 20.3C24.299 20.3414 25.4906 20.1535 26.19 19.8285C27.6959 19.1296 28.5891 17.9627 27.1042 18.2693H27.1043Z" fill="#737C86"/>
<path d="M14.5405 19.2969C14.4472 22.6322 14.5639 25.9907 14.8903 26.8069C15.2168 27.6232 15.9157 29.2108 18.3188 28.696C20.3265 28.2656 21.0569 27.4326 21.3739 25.5941C21.6074 24.2414 22.0575 20.4847 22.1152 19.715" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.66 2.57682C11.66 2.57682 0.780358 -1.86987 1.28615 8.24222C1.39372 10.3935 4.36972 24.5202 7.91907 20.2532C9.21611 18.6928 10.3891 17.4689 10.3891 17.4689" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.5366 1.76531C17.16 1.88339 23.5886 -0.58473 27.242 4.08357C28.5325 5.73275 27.037 12.4709 23.4596 17.7794" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.4597 17.7792C23.4597 17.7792 23.6928 18.9267 27.0811 18.2273C28.5657 17.9207 27.6723 19.0878 26.1666 19.787C24.9309 20.3605 22.1606 20.5075 22.1153 19.715C21.9989 17.6704 23.5734 18.2915 23.4598 17.7792C23.3571 17.3178 22.6529 16.865 22.1871 15.7357C21.7805 14.7501 16.6102 7.19146 23.621 8.31404C23.8778 8.2609 21.7925 1.64549 15.2314 1.53792C8.67172 1.43035 8.88692 9.60482 8.88692 9.60482" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="bevel"/>
<path d="M12.7041 18.4785C11.7896 19.5 12.0585 19.6794 10.2305 20.0558C8.38092 20.4371 9.4676 21.1155 10.1768 21.2928C11.0368 21.508 13.0265 21.8127 14.3709 19.9303C14.7803 19.3571 14.3685 18.4426 13.8061 18.2095C13.5344 18.0969 13.1711 17.9559 12.7041 18.4785V18.4785Z" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.6442 18.4606C12.5521 17.86 12.8416 17.1453 13.1518 16.3091C13.6181 15.0545 14.6938 13.7997 13.8333 9.81989C13.1918 6.8542 8.88948 9.20269 8.88678 9.60482C8.88416 10.0068 9.08138 11.6431 8.81491 13.5485C8.46721 16.0349 10.397 18.1377 12.6192 17.9226" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6204 9.54449C11.601 9.68183 11.8718 10.0483 12.225 10.0973C12.5775 10.1465 12.8793 9.86006 12.8984 9.72285C12.9175 9.58551 12.647 9.43422 12.2937 9.38505C11.941 9.33581 11.6391 9.40748 11.6204 9.54449H11.6204Z" fill="white" stroke="white" stroke-width="0.153857"/>
<path d="M22.3591 9.26455C22.3783 9.4019 22.1078 9.76839 21.7545 9.81736C21.4017 9.86653 21.1 9.58013 21.0806 9.44292C21.062 9.30557 21.3325 9.15429 21.6854 9.10512C22.0383 9.05595 22.34 9.12755 22.3591 9.26462V9.26455Z" fill="white" stroke="white" stroke-width="0.0769283"/>
<path d="M23.621 8.31409C23.6792 9.39141 23.389 10.1252 23.3524 11.272C23.2983 12.939 24.1472 14.847 22.868 16.7573" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
