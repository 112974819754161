import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * @param condition
 * @returns condition for filter outlook
 */

export default function conditionsToFilterOutlookExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterOutlookExpression(condition);
      })
      .join(condition.join === 'OR' ? ' OR ' : ' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} eq '${innerCondition.argument}'`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable} ne '${innerCondition.argument}'`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} gt '${new Date(innerCondition.argument).toISOString()}'`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} lt '${new Date(innerCondition.argument).toISOString()}'`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} eq '${new Date(innerCondition.argument).toISOString()}'`;
      case Operator.StringStartsWith:
        return `startsWith(${innerCondition.variable}, '${innerCondition.argument}')`;
      case Operator.StringEndsWith:
        return `endsWith(${innerCondition.variable}, '${innerCondition.argument}')`;
      case Operator.StringContains:
        return `contains(${innerCondition.variable}, '${innerCondition.argument}')`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable} eq true`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} eq false`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Outlook`);
    }
  }
  return '';
}
