export default `<svg width="101" height="34" viewBox="0 0 101 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 33V18H4.05882L10.7227 27.7872V18H14V33H10.9412L4.27731 23.2128V33H1Z" fill="#403C38"/>
<path d="M17 33V18H27.766V20.766H20.6277V24.0638H27.1809V26.8298H20.6277V30.3404H28V32.8936L17 33Z" fill="#403C38"/>
<path d="M33.8319 33V20.6596H29V18H42V20.766H37.1681V33H33.8319Z" fill="#403C38"/>
<path d="M44.1051 33.1182V31.3545C45.4717 32.0159 46.9435 32.3466 48.4152 32.3466C49.3613 32.4568 50.3074 32.1261 51.0433 31.6852C51.674 31.2443 51.9894 30.4727 51.9894 29.7011C51.9894 29.1499 51.7791 28.5988 51.3586 28.1579C50.7279 27.6067 49.992 27.1658 49.2562 26.9453L47.6793 26.284C46.6281 25.9533 45.682 25.2919 44.841 24.5203C44.3154 23.8589 44 23.0873 44 22.2055C44 20.993 44.5256 19.8907 45.3666 19.1191C46.523 18.3474 47.7844 17.9065 49.151 18.0168C49.8869 18.0168 50.5177 18.127 51.2535 18.2372C51.8843 18.3474 52.515 18.5679 53.0406 18.8986V20.6623C51.8843 20.1111 50.5177 19.7804 49.2562 19.6702C47.0486 19.6702 45.8922 20.4418 45.8922 21.985C45.8922 22.5362 46.1025 22.9771 46.523 23.3078C47.1537 23.8589 47.8896 24.1896 48.6254 24.5203L50.2023 25.1817C51.2535 25.5123 52.3048 26.1737 53.1457 27.0556C53.6714 27.7169 53.9867 28.5988 53.9867 29.5908C54.0919 30.8034 53.5662 32.0159 52.6201 32.7875C51.4638 33.6693 50.0972 34 48.7306 34C48.2049 34 47.6793 34 47.1537 33.8898C46.6281 33.7795 46.1025 33.6693 45.5769 33.5591C44.841 33.5591 44.4205 33.3386 44.1051 33.1182Z" fill="#403C38"/>
<path d="M57 26.5556V18H58.9636V26.5556C58.9636 28.4444 59.2909 29.8889 59.9455 30.7778C61.4727 32.4444 64.0909 32.6667 65.7273 31.1111C65.8364 31 65.9455 30.8889 66.0545 30.7778C66.7091 29.8889 67.0364 28.4444 67.0364 26.5556V18H69V26.6667C69 29.2222 68.4546 31 67.4727 32.2222C66.4909 33.4444 64.9636 34 63 34C61.0364 34 59.5091 33.4444 58.5273 32.2222C57.5455 31 57 29.1111 57 26.5556Z" fill="#403C38"/>
<path d="M72 33V18H74V33H72Z" fill="#403C38"/>
<path d="M81.9346 33V19.5957H77V18H89V19.5957H83.9533V32.8936H81.9346V33Z" fill="#403C38"/>
<path d="M91 33V18H100.775V19.5957H93.0225V24.4894H100.101V26.0851H93.0225V31.1915H101V32.7872H91V33Z" fill="#403C38"/>
<path d="M45.3114 7.86213H49.7936L47.4345 3.93973L43.0703 11H41.065L46.3729 2.55183C46.7858 1.94843 47.5525 1.82773 48.1422 2.25013C48.2602 2.31043 48.3781 2.37083 48.4371 2.49153L53.745 11H51.7398L50.7962 9.43103H46.255L45.3114 7.86213ZM65.8942 9.43103V2.06903H64.1839V10.1552C64.1839 10.3966 64.3018 10.5776 64.4198 10.7586C64.5967 10.9397 64.7736 11 65.0095 11H72.6765L73.6791 9.43103H65.8942ZM38.1162 8.10353C39.7676 8.10353 41.065 6.77593 41.065 5.08633C41.065 3.39663 39.7676 2.06903 38.1162 2.06903H30.7441V11H32.4545V3.63803H37.9983C38.765 3.69833 39.3547 4.36213 39.3547 5.14663C39.2957 5.87073 38.765 6.47423 37.9983 6.53453H33.2801L38.2931 11H40.7112L37.3495 8.10353H38.1162ZM20.4232 11H25.4952C27.9133 11 29.8595 9.00863 29.8595 6.53453C29.8595 4.06043 27.9133 2.06903 25.4952 2.06903H20.4232C18.0052 2.06903 16 4.06043 16 6.53453C16 9.00863 18.0052 11 20.4232 11ZM25.3773 9.43103H20.4822C18.9488 9.37073 17.7103 8.04313 17.7693 6.41383C17.8283 4.90523 19.0078 3.69833 20.4822 3.63803H25.3773C26.9107 3.57763 28.2671 4.84493 28.3261 6.41383C28.3851 7.98283 27.1466 9.37073 25.6132 9.43103C25.4952 9.43103 25.4363 9.43103 25.3773 9.43103ZM57.2836 11H62.4735L63.4761 9.43103H57.4016C55.8682 9.49143 54.5117 8.22423 54.4527 6.65523C54.3938 5.08633 55.6323 3.69833 57.1657 3.63803C57.2246 3.63803 57.2836 3.63803 57.3426 3.63803H62.2966L63.2992 2.06903H57.2836C54.8656 2.06903 52.9193 4.06043 52.9193 6.53453C52.9193 9.00863 54.8656 11 57.2836 11ZM77.8664 9.43103C76.6279 9.43103 75.5074 8.58623 75.1535 7.31903H82.3487L83.3513 5.75003H75.1535C75.5074 4.48283 76.6279 3.63803 77.8664 3.63803H82.8205L83.8231 2.06903H77.8075C75.3894 2.06903 73.4432 4.06043 73.4432 6.53453C73.4432 9.00863 75.3894 11 77.8075 11H82.9974L84 9.43103H77.8664Z" fill="#C74634"/>
</svg>
`;
