import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * return unix timestamp
 * @param date
 * @returns
 */
export const getFormattedDate = (date: Date): number => Math.floor(new Date(date).getTime() / 1000);

/**
 * create search string which is to sent with fetching resource from eloqua api
 * @param condition
 * @returns
 */
export default function conditionsToSOQL(condition: ResolvedConditionWrapper): string {
  if (condition.type === 'JOIN') {
    const conditions: string[] = condition.conditions.map((condition: ResolvedConditionWrapper) => {
      return conditionsToSOQL(condition);
    });
    return conditions.join('');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable}='${innerCondition.argument}'`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable}!='${innerCondition.argument}'`;
      case Operator.NumberLessThan:
        return `${innerCondition.variable}<'${innerCondition.argument}'`;
      case Operator.NumberEquals:
        return `${innerCondition.variable}=${innerCondition.argument}`;
      case Operator.NumberGreaterThan:
        return `${innerCondition.variable}>${innerCondition.argument}`;
      case Operator.NumberDoesNotEqual:
        return `${innerCondition.variable}!=${innerCondition.argument}`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `${innerCondition.variable}>=${innerCondition.argument}`;
      case Operator.NumberLessThanOrEqualTo:
        return `${innerCondition.variable}<=${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable}>'${getFormattedDate(
          new Date(innerCondition.argument),
        )}'`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable}<'${getFormattedDate(
          new Date(innerCondition.argument),
        )}'`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable}='${getFormattedDate(
          new Date(innerCondition.argument),
        )}'`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for eloqua`);
    }
  }
  return '';
}
