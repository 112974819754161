import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class PersonaError extends ParagonError {
  name: string = 'PersonaError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class PersonaAuthFailedNoSigningKeyError extends PersonaError {
  name = 'PersonaAuthFailedNoSigningKeyError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.PERSONA_AUTH_FAILED_NO_SIGNING_KEY,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Signing key not found.',
      meta,
    });
  }
}

export class PersonaNotFoundFromIdError extends PersonaError {
  name: string = 'PersonaNotFoundFromIdError';

  constructor(meta: { personaId: string }) {
    super({
      code: ERROR_CODE.PERSONA_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find connected user.',
      meta,
    });
  }
}

export class PersonaNotFoundFromEndUserIdError extends PersonaError {
  name: string = 'PersonaNotFoundFromEndUserIdError';

  constructor(meta: { endUserId?: string; projectId: string }) {
    super({
      code: ERROR_CODE.PERSONA_NOT_FOUND_FROM_END_USER_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find connected user.',
      meta,
    });
  }
}
