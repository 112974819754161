import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedRequestParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import {
  RequestAuthorization,
  RequestAuthorizationType,
  RequestStep,
} from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import {
  getRequirdStepIdForTokenizedSource,
  getRequiredStepIdToResolveKeyedSources,
  resolveKeyedSources,
  resolveSources,
} from './resolvers.utils';

/**
 * a resolver for a request step's variables
 *
 * @export
 * @class RequestStepResolver
 * @extends {StepResolver<RequestStep, ResolvedRequestParameters>}
 */
export class RequestStepResolver extends StepResolver<RequestStep, ResolvedRequestParameters> {
  /**
   * resolves the input parameters for the step
   *
   * @param {RequestStep['parameters']} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedRequestParameters}
   * @memberof RequestStepResolver
   */
  resolveInputParameters(
    parameters: RequestStep['parameters'],
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedRequestParameters {
    const resolved = resolveKeyedSources(
      variables,
      resolveSources(
        variables,
        parameters,
        secrets,
        cachedConnectCredential,
        context,
        cachedPersona,
        'url',
        'rawBody',
      ),
      secrets,
      cachedConnectCredential,
      context,
      cachedPersona,
      'params',
      'headers',
      'body',
    );

    return {
      ...resolved,
      authorization: this.resolveAuthorizationParameters(
        parameters.authorization,
        secrets,
        cachedConnectCredential,
        variables,
        context,
        cachedPersona,
      ),
    };
  }

  resolveAuthorizationParameters(
    authorization: RequestAuthorization | undefined,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedRequestParameters['authorization'] {
    if (!authorization) {
      return { type: RequestAuthorizationType.NONE };
    }
    switch (authorization.type) {
      case RequestAuthorizationType.BASIC:
        return resolveSources(
          variables,
          authorization,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
          'username',
          'password',
        );
      case RequestAuthorizationType.BEARER_TOKEN:
        return resolveSources(
          variables,
          authorization,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
          'token',
        );
      case RequestAuthorizationType.NONE:
      default:
        return { type: RequestAuthorizationType.NONE };
    }
  }

  getRequiredStepIdsForVariableResolution(parameters: RequestStep['parameters']): string[] {
    const stepIdsRequiredForAuthorization: string[] = [];

    if (parameters.authorization) {
      const authorization = parameters.authorization;
      switch (authorization.type) {
        case RequestAuthorizationType.AUTH_HEADER:
          stepIdsRequiredForAuthorization.push(
            ...getRequiredStepIdToResolveKeyedSources(authorization.headers),
          );
          break;
        case RequestAuthorizationType.BASIC:
          stepIdsRequiredForAuthorization.push(
            ...getRequirdStepIdForTokenizedSource(authorization.username),
            ...getRequirdStepIdForTokenizedSource(authorization.password),
          );
          break;
        case RequestAuthorizationType.BEARER_TOKEN:
          stepIdsRequiredForAuthorization.push(
            ...getRequirdStepIdForTokenizedSource(authorization.token),
          );
          break;
        case RequestAuthorizationType.QUERY_PARAMS:
          stepIdsRequiredForAuthorization.push(
            ...getRequiredStepIdToResolveKeyedSources(authorization.params),
          );
          break;
      }
    }

    return [
      ...getRequiredStepIdToResolveKeyedSources(parameters.params),
      ...getRequiredStepIdToResolveKeyedSources(parameters.headers),
      ...getRequirdStepIdForTokenizedSource(parameters.url),
      ...getRequiredStepIdToResolveKeyedSources(parameters.body),
      ...(parameters.rawBody ? getRequirdStepIdForTokenizedSource(parameters.rawBody) : []),
      ...stepIdsRequiredForAuthorization,
    ];
  }
}
