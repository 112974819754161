import { DynamoDB } from 'aws-sdk/clients/all';

import { ConditionSerializer } from '@shared/actions/sdk/conditionSerializers';
import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { reservedDyanmoKeywords } from '../shared/reservedKeywords';
import { DynamoDbFilterExpression } from '../shared/types';

/**
 * Create a unique key for an object using a prefix string and the current number of keys stored
 * in the object.
 */
const generateUniqueKeyForObject = (obj: object, prefixName: string): string => {
  return `:${prefixName}${Object.keys(obj).length}`;
};

/**
 * Create a DynamoDB filter expression and name/value maps for the expression from a Paragon
 * condition object.
 *
 * @param condition - A ResolvedConditionWrapper to convert into a DynamoDB filter expression
 * @param keyName - A key name for the source that holds this ResolvedConditionWrapper. This will
 * be used to prefix the unique keys for expression attribute names and values.
 * @param expressionAttributeNames - An object to represent the current state of the
 * ExpressionAttributeNameMap for this DynamoDB filter expression.
 * @param expressionAttributeValues - An object to represent the current state of the
 * ExpressionAttributeValueMap for this DynamoDB filter expression.
 */
const parseCondition = (
  condition: ResolvedConditionWrapper,
  keyName: string,
  expressionAttributeNames: DynamoDB.ExpressionAttributeNameMap = {},
  expressionAttributeValues: DynamoDB.ExpressionAttributeValueMap = {},
): string => {
  if (condition.type === 'JOIN') {
    const result = condition.conditions.map((condition: ResolvedConditionWrapper) =>
      parseCondition(condition, keyName, expressionAttributeNames, expressionAttributeValues),
    );
    return result.length > 1
      ? `(${result.join(condition.join === 'OR' ? ' OR ' : ' AND ')})`
      : result.join(condition.join === 'OR' ? ' OR ' : ' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    let conditionKeyName: string = innerCondition.variable;
    if (
      reservedDyanmoKeywords.some((k: string) => k.toLowerCase() === conditionKeyName.toLowerCase())
    ) {
      // if keyname is reserved keyword
      conditionKeyName = `#${conditionKeyName}`;
      expressionAttributeNames[conditionKeyName] = innerCondition.variable;
    }
    const attributeValueKeyName: string = generateUniqueKeyForObject(
      expressionAttributeValues,
      keyName,
    );
    switch (innerCondition.operator) {
      case Operator.StringContains:
        expressionAttributeValues[attributeValueKeyName] = { S: innerCondition.argument };
        return `contains(${conditionKeyName},${attributeValueKeyName})`;
      case Operator.StringDoesNotContain:
        expressionAttributeValues[attributeValueKeyName] = { S: innerCondition.argument };
        return `not contains(${conditionKeyName},${attributeValueKeyName})`;
      case Operator.StringExactlyMatches:
        expressionAttributeValues[attributeValueKeyName] = { S: innerCondition.argument };
        return `${conditionKeyName} = ${attributeValueKeyName}`;
      case Operator.StringDoesNotExactlyMatch:
        expressionAttributeValues[attributeValueKeyName] = { S: innerCondition.argument };
        return `${conditionKeyName} <> ${attributeValueKeyName}`;
      case Operator.StringStartsWith:
        expressionAttributeValues[attributeValueKeyName] = { S: innerCondition.argument };
        return `begins_with(${conditionKeyName},${attributeValueKeyName})`;
      case Operator.StringDoesNotStartWith:
        expressionAttributeValues[attributeValueKeyName] = { S: innerCondition.argument };
        return `not begins_with(${conditionKeyName},${attributeValueKeyName})`;
      case Operator.NumberLessThan:
        expressionAttributeValues[attributeValueKeyName] = { N: String(innerCondition.argument) };
        return `${conditionKeyName} < ${attributeValueKeyName}`;
      case Operator.NumberEquals:
        expressionAttributeValues[attributeValueKeyName] = { N: String(innerCondition.argument) };
        return `${conditionKeyName} = ${attributeValueKeyName}`;
      case Operator.NumberGreaterThan:
        expressionAttributeValues[attributeValueKeyName] = { N: String(innerCondition.argument) };
        return `${conditionKeyName} > ${attributeValueKeyName}`;
      case Operator.NumberDoesNotEqual:
        expressionAttributeValues[attributeValueKeyName] = { N: String(innerCondition.argument) };
        return `${conditionKeyName} <> ${attributeValueKeyName}`;
      case Operator.NumberGreaterThanOrEqualTo:
        expressionAttributeValues[attributeValueKeyName] = { N: String(innerCondition.argument) };
        return `${conditionKeyName} >= ${attributeValueKeyName}`;
      case Operator.NumberLessThanOrEqualTo:
        expressionAttributeValues[attributeValueKeyName] = { N: String(innerCondition.argument) };
        return `${conditionKeyName} <= ${attributeValueKeyName}`;
      case Operator.BooleanTrue:
        expressionAttributeValues[attributeValueKeyName] = { BOOL: true };
        return `${conditionKeyName} = ${attributeValueKeyName}`;
      case Operator.BooleanFalse:
        expressionAttributeValues[attributeValueKeyName] = { BOOL: false };
        return `${conditionKeyName} = ${attributeValueKeyName}`;
      case Operator.IsNotNull:
        return `attribute_exists(${conditionKeyName})`;
      case Operator.IsNull:
        return `attribute_not_exists(${conditionKeyName})`;
      default:
        throw new Error(`${innerCondition.operator} not supported by DynamoDB`);
    }
  }
  return '';
};

/**
 * A ConditionSerializer to generate a DynamoDB filter expression, with name and value attribute
 * maps.
 */
const conditionsToFilterExpression: ConditionSerializer<DynamoDbFilterExpression> = (
  condition: ResolvedConditionWrapper,
  key: string,
): DynamoDbFilterExpression => {
  const expressionAttributeNames: DynamoDB.ExpressionAttributeNameMap = {};
  const expressionAttributeValues: DynamoDB.ExpressionAttributeValueMap = {};
  const expression: string = parseCondition(
    condition,
    key,
    expressionAttributeNames,
    expressionAttributeValues,
  );

  return {
    expression,
    expressionAttributeNames,
    expressionAttributeValues,
  };
};

export default conditionsToFilterExpression;
