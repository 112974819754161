import { Lens } from 'monocle-ts';

import { Action, ConfirmState, ConfirmationOptions } from '../types';

export const initialState: ConfirmState = {
  confirmations: {},
};

export function handler(state: ConfirmState = initialState, action: Action): ConfirmState {
  switch (action.type) {
    case 'SET_CONFIRMATION_OPTIONS':
      return Lens.fromProp<ConfirmState>()('confirmations').modify(
        (confirmations: Record<string, ConfirmationOptions>) => ({
          ...confirmations,
          [action.key]: action.payload,
        }),
      )(state);

    case 'SET_CONFIRMATION_RESULT':
      return Lens.fromPath<ConfirmState>()(['confirmations', action.key]).modify(
        (options: ConfirmationOptions) => ({ ...options, result: action.result }),
      )(state);

    case 'REMOVE_CONFIRMATION_KEY':
      const { [action.key]: value, ...rest } = state.confirmations;
      return {
        ...state,
        confirmations: {
          ...rest,
        },
      };

    case 'SET_CONFIRMATION_ACTIVE_KEY':
      return {
        ...state,
        active: action.key,
      };

    default:
      return state;
  }
}
