import React from 'react';

import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs';
import iconSvg from '../configs/icon.svg';

import { getUsersInput } from './input';

const authConfig: ActionConfig = {
  actionType: Action.GREENHOUSE,
  provider: ProviderType.GREENHOUSE,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Greenhouse account',
  icon: iconSvg,
  description: 'Access, create and update your data in Greenhouse.',
  sidebarSections: [
    {
      inputs: [
        {
          id: CredentialKeys.GREENHOUSE_API_KEY,
          type: SidebarInputType.ValueText,
          required: true,
          title: 'Greenhouse API Key',
          subtitle: (
            <>
              You can generate a Harvest API Key in the Dev Center of your Greenhouse Configure
              screen. This key should include all permissions for your Greenhouse account.{' '}
              <a
                href="https://support.greenhouse.io/hc/en-us/articles/202842799-Generate-API-key-for-Greenhouse-Recruiting"
                target="_blank"
                rel="noreferrer"
              >
                Learn more.
              </a>
            </>
          ),
        },
      ],
      buttons: [createTestConnectionButton(Action.GREENHOUSE, Object.values(CredentialKeys))],
    },
  ],
  postOAuthPrompt: [getUsersInput],
};

export default authConfig;
