import { TeamMemberRole } from '@shared/entities/sdk/team/team.interface';
import { DataType, KeyedSource, TokenizedSource } from '@shared/types/sdk/resolvers';
import { DelayUnit, StepType, Weekday } from '@shared/types/sdk/steps';

export enum SHORTCUT_OPERATIONS {
  SAVE = 'SAVE',
}

export const KEY_SHORTCUT_MAP: Record<string, string | string[]> = {
  [SHORTCUT_OPERATIONS.SAVE]: ['command+shift+s', 'ctrl+shift+s'],
};

export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred. Please try again.';

export const REMEMBER_ME_EXPIRY_DAYS = 30;

export const NAVBAR_HEIGHT = '50px';

export const DEFAULT_HOUR = 9;
export const DEFAULT_WEEKDAY = Weekday.MONDAY;

export const DEFAULT_CODE = 'function yourFunction(parameters, libraries) {\n\n}';

export const DEFAULT_CONDITIONAL_OPERATOR = '$stringContains';

export const DEFAULT_IDLE_TIME_FOR_AUTOSAVE = 1000;

export const WEEKDAY_LABELS: Record<Weekday, string> = {
  [Weekday.MONDAY]: 'Monday',
  [Weekday.TUESDAY]: 'Tuesday',
  [Weekday.WEDNESDAY]: 'Wednesday',
  [Weekday.THURSDAY]: 'Thursday',
  [Weekday.FRIDAY]: 'Friday',
  [Weekday.SATURDAY]: 'Saturday',
  [Weekday.SUNDAY]: 'Sunday',
};

type DataTypeInfo = {
  label: string;
};

export const DATA_TYPE_INFO: Record<DataType, DataTypeInfo> = {
  [DataType.STRING]: { label: 'string' },
  [DataType.NUMBER]: { label: 'number' },
  [DataType.DATE]: { label: 'date' },
  [DataType.BOOLEAN]: { label: 'boolean' },
  [DataType.EMAIL]: { label: 'email address' },
  [DataType.OBJECT]: { label: 'JSON object' },
  [DataType.ARRAY]: { label: 'JSON array' },
  [DataType.ANY]: { label: 'any' },
  [DataType.FILE]: { label: 'file' },
  [DataType.NON_DECIMAL]: { label: 'Non decimal' },
};

export const DATA_TYPE_ZERO_VALUES: Record<DataType, any> = {
  [DataType.STRING]: '',
  [DataType.NUMBER]: 0,
  [DataType.DATE]: new Date(),
  [DataType.BOOLEAN]: false,
  [DataType.EMAIL]: '',
  [DataType.OBJECT]: {},
  [DataType.ARRAY]: [],
  [DataType.ANY]: '',
  [DataType.FILE]: '',
  [DataType.NON_DECIMAL]: 0,
};

export const EMPTY_TOKENIZED_SOURCE: TokenizedSource<DataType.STRING> = {
  type: 'TOKENIZED',
  parts: [
    {
      dataType: DataType.STRING,
      type: 'VALUE',
      value: '',
    },
  ],
};

export const EMPTY_KEYED_SOURCE: KeyedSource<DataType.STRING> = {
  key: '',
  source: {
    type: 'VALUE',
    dataType: DataType.STRING,
    value: '',
  },
};

export const DEFAULT_CONDITIONAL_CHOICE: number = 0;

export type ResponseStatusCode = {
  value: string;
  label: string;
};

export const RESPONSE_STATUS_CODES: ResponseStatusCode[] = [
  { value: '100', label: '100 Continue' },
  { value: '102', label: '102 Processing' },
  { value: '200', label: '200 OK' },
  { value: '201', label: '201 Created' },
  { value: '202', label: '202 Accepted' },
  { value: '204', label: '204 No Content' },
  { value: '304', label: '304 Not Modified' },
  { value: '400', label: '400 Bad Request' },
  { value: '401', label: '401 Unauthorized' },
  { value: '403', label: '403 Forbidden' },
  { value: '404', label: '404 Not Found' },
  { value: '413', label: '413 Payload Too Large' },
];

// Use utils/getStepLabel for retrieving a label
export const STEP_TYPE_LABELS: Partial<Record<StepType, string>> = {
  [StepType.UNSELECTED_TRIGGER]: 'Choose a Trigger',
  [StepType.ENDPOINT]: 'Trigger',
  [StepType.CRON]: 'Trigger',
  [StepType.OAUTH]: 'OAuth Trigger',
  [StepType.REDIRECT]: 'Redirect',
  [StepType.REQUEST]: 'Request',
  [StepType.RESPONSE]: 'Response',
  [StepType.FUNCTION]: 'Function',
  [StepType.IFELSE]: 'Conditional',
  [StepType.MAP]: 'Fan Out',
  [StepType.DELAY]: 'Delay',
  [StepType.EVENT]: 'App Event',
  [StepType.INTEGRATION_ENABLED]: 'Integration Enabled',
  [StepType.CUSTOM_INTEGRATION_REQUEST]: 'API Request',
};

export const DELAY_STEP_UNIT_LABELS: Record<DelayUnit, string> = {
  [DelayUnit.DAYS]: 'days',
  [DelayUnit.HOURS]: 'hours',
  [DelayUnit.MINUTES]: 'minutes',
  [DelayUnit.SECONDS]: 'seconds',
};

export const TERMS_OF_SERVICE_LINK: string = 'https://www.useparagon.com/terms-of-service';
export const PRIVACY_AND_POLICY_LINK: string = 'https://www.useparagon.com/privacy';
export const TASKS_DOCUMENTATION: string = 'https://docs.useparagon.com/billing/tasks';
export const FUNCTION_LIBRARIES_URL: string =
  'https://docs.useparagon.com/building-workflows/functions#using-npm-modules-in-functions';

export const DATA_SECURITY_DOC_LINK: string = 'https://docs.useparagon.com/security';

export const USER_METADATA_DOC: string =
  'https://docs.useparagon.com/api/users#associate-connected-user-with-metadata';

export const DYNAMIC_FIELD_MAPPING_DOC: string =
  'https://docs.useparagon.com/connect-portal/field-mapping#dynamic-fields';

export const AUTO_RETRY_STEP_DOC: string =
  'https://docs.useparagon.com/building-workflows/auto-retries';
export const IGNORE_REQUEST_FAILURE_DOC: string =
  'https://docs.useparagon.com/building-workflows/making-api-requests#handling-request-failures';

export const RESPONSE_STEP_FILE_DOC: string =
  'https://docs.useparagon.com/v/connect/workflows/sending-a-response';

export const VERSION_HISTORY_DOC_LINK: string =
  'https://docs.useparagon.com/building-workflows/version-history/';
export const TRUSTED_PARAGON_STATIC_IPS: string[] = ['18.205.92.162', '54.235.127.238'];

export const INACTIVE_USER_ERROR_MESSAGE: string =
  'Your account has been disabled. Please contact support@useparagon.com if you have questions.';

export const HIDDEN_VALUE: string = '••••••••••••••••••';

export const INVITATION_EXPIRATION_THRESOLD: number = 30 * 24 * 60 * 60; //30 days

export const TeamMemberRoleLabel: Record<TeamMemberRole, string> = {
  ADMIN: 'Admin',
  MEMBER: 'Member',
};

/**
 * IntegrationType is for distinguishing types of tabs/categories of Credential entities within
 * Integrations Manager pages.
 */
export enum IntegrationType {
  ACCOUNT = 'account',
  OAUTH_APP = 'oauth_app',
}

export const NO_OP_VERSION_ID: string = 'current_version';

export const CONNECT_PORTAL_DISPLAY_LINK: string =
  'https://docs.useparagon.com/v/connect/getting-started/displaying-the-connect-portal';

export const SIGNING_KEY_DOCS_LINK: string =
  'https://docs.useparagon.com/v/connect/getting-started/installing-the-connect-sdk';

export const BYO_DOCS_LINK: string =
  'https://docs.useparagon.com/v/connect/resources/custom-integrations';
