import { Operator } from '@shared/types/sdk/resolvers';

/**
 * DocuSign auth api base url
 */
export const DOCUSIGN_AUTH_BASE_URL: string = 'https://account.docusign.com/oauth';

/**
 * DocuSign auth api base url
 */
export const DOCUSIGN_DEVELOPMENT_AUTH_BASE_URL: string = 'https://account-d.docusign.com/oauth';

/**
 * DocuSign api base url
 */
export const DOCUSIGN_API_BASE_URL: string = 'https://api.docusignapi.com';

/**
 * DocuSign api version
 */
export const DOCUSIGN_API_VERSION: string = '2.1';

/**
 * DocuSign Supported operator for filter
 */
export const docuSignSupportedOperators: Operator[] = [Operator.StringExactlyMatches];

export const ENVELOPE_RESPONSE_FIELDS: string =
  'recipients,powerform,folders,custom_fields,documents,attachments,extensions,payment_tabs';
