import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { supportedOperators } from '../shared/constant';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Email',
          items: [
            {
              value: Intent.SEND_EMAIL,
              label: 'Send Email',
            },
            {
              value: Intent.GET_EMAIL_BY_ID,
              label: 'Get Email by ID',
            },
            {
              value: Intent.SEARCH_FOR_EMAIL,
              label: 'Search for Email',
            },
            {
              value: Intent.DELETE_EMAIL,
              label: 'Delete Email',
            },
          ],
        },
        {
          title: 'Contacts',
          items: [
            {
              value: Intent.CREATE_A_CONTACT,
              label: 'Create a Contact',
            },
            {
              value: Intent.GET_CONTACT_BY_RESOURCE_NAME,
              label: 'Get Contact by Resource Name',
            },
            {
              value: Intent.SEARCH_FOR_CONTACT,
              label: 'Search for Contact',
            },
            {
              value: Intent.DELETE_CONTACT,
              label: 'Delete Contact',
            },
          ],
        },
      ],
    },
  ],
};

export const toRecipientsInput: SidebarInput = {
  id: 'toRecipients',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  title: 'To',
  required: true,
  subtitle: 'Specify the recipients as either a single string or a JSON array.',
  placeholder: `[
  "recipient1@domain.com",
  "recipient2@domain.com"
]`,
  useLightTheme: true,
};

export const fromInput: SidebarInput = {
  id: 'from',
  title: 'From',
  subtitle: 'Specify the email of the sender.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const subjectInput: SidebarInput = {
  id: 'subject',
  title: 'Subject',
  subtitle: 'Specify the subject of the message.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const messageContentInput: SidebarInput = {
  id: 'messageContent',
  title: 'Message Content',
  subtitle: 'Specify the content of the email message as plain text or HTML.',
  type: SidebarInputType.TextArea,
  lines: 4,
  required: true,
};

export const additionalHeadersInput: SidebarInput = {
  id: 'additionalHeaders',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  title: 'Additional Headers',
  required: false,
  subtitle: 'Specify any additional header fields here.',
  placeholder: `{
  "reply-to": "Sender Name <sender@domain.com>",
}`,
  useLightTheme: true,
};

export const userIdInput: SidebarInput = {
  id: 'userId',
  title: 'User ID',
  subtitle: 'Specify the user’s email address.',
  type: SidebarInputType.TextArea,
  placeholder: 'user@domain.com',
  lines: 1,
  required: true,
};

export const messageIdInput: SidebarInput = {
  id: 'messageId',
  title: 'Message ID',
  subtitle: 'Specify the ID of the message to retrieve.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const emailFilterInput: SidebarInput = {
  id: 'emailFilterFormula',
  title: 'Filter search',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: false,
  getValues: () => [
    { label: 'From', value: 'from' },
    { label: 'To', value: 'to' },
    { label: 'Date', value: 'date' },
    { label: 'Label', value: 'label' },
    { label: 'Subject', value: 'subject' },
    { label: 'Cc', value: 'cc' },
    { label: 'Bcc', value: 'bcc' },
    { label: 'Category', value: 'category' },
    { label: 'Delivered To', value: 'delivered:to' },
    { label: 'Size', value: 'size' },
    { label: 'File Name', value: 'filename' },
    { label: 'Older Than', value: 'older_than' },
    { label: 'Newer Than', value: 'newer_than' },
    { label: 'Mailing List', value: 'list' },
    { label: 'Is Important', value: 'is:important' },
    { label: 'Is Unread', value: 'is:unread' },
    { label: 'Is Snoozed', value: 'is:snoozed' },
    { label: 'Is Starred', value: 'is:starred' },
    { label: 'Is Read', value: 'is:read' },
    { label: 'Has Drive', value: 'has:drive' },
    { label: 'Has Docunment', value: 'has:document' },
    { label: 'Has Spreadsheet', value: 'has:spreadsheet' },
    { label: 'Has Presentation', value: 'has:presentation' },
    { label: 'Has Attachment', value: 'has:attachment' },
    { label: 'Has Youtube Video', value: 'has:youtube' },
    { label: 'Has User Labels', value: 'has:userlabels' },
  ],
};

export const familyNameInput: SidebarInput = {
  id: 'familyName',
  title: 'Family Name',
  subtitle: 'Specify the Family Name of the Contact to create.',
  placeholder: 'Doe',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const givenNameInput: SidebarInput = {
  id: 'givenName',
  title: 'Given Name',
  subtitle: 'Specify the Given Name of the Contact to create.',
  placeholder: 'John',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const emailInput: SidebarInput = {
  id: 'email',
  title: 'Email',
  subtitle: 'Specify the Email Address of the Contact to create.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const LinkWrapper = (): JSX.Element => (
  <a
    href="https://developers.google.com/people/api/rest/v1/people/createContact"
    target="_blank"
    rel="noreferrer"
  >
    See the docs
  </a>
);

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Additional Fields',
  required: false,
  subtitle: (
    <>
      Specify additional fields to include on this Contact as JSON. For example, you may specify an
      address here. <LinkWrapper /> for available fields.
    </>
  ),
  placeholder: `{
  "addresses": [
    {
      "streetAddress": "1000 North St.",
      "city": "Los Angeles"
    }
  ]
}`,
  useLightTheme: true,
};

export const resourceNameInput: SidebarInput = {
  id: 'resourceName',
  title: 'Resource Name',
  subtitle: 'Specify the resource name of the contact to fetch.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const searchTermInput: SidebarInput = {
  id: 'searchTerm',
  title: 'Term',
  subtitle:
    'Specify a search term to search for near or exact matches on the names, nickNames, emailAddresses, phoneNumbers, or organizations Contact properties.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};
