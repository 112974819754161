export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, any>;
  ZENDESK_SUB_DOMAIN: string;
};

export type Environment = {
  ZENDESK_CLIENT_ID: string;
  ZENDESK_CLIENT_SECRET: string;
};

export type ExchangeCredentialDTO = {
  redirectUrl: string;
  code: string;
  ZENDESK_SUB_DOMAIN: string;
};

export type LoggedInUserResponse = {
  user: {
    email: string;
    id: string;
  };
};

export type AuthenticationResponse = {
  access_token: string;
};

export type ZendeskUser = {
  name: string;
  id: number;
};

export type GetUsersDTO = {
  getUserQuery: string;
};

export type ZendeskPaginationResponse = {
  data: {
    meta: {
      has_more: boolean;
    };
    links: {
      next: string | null;
      prev: string | null;
    };
    users: Record<string, any>[];
    [key: string]: any;
  };
};

export type SearchResponse<T extends object> = {
  data: {
    results: T[];
    next_page: string;
    prev_page: string;
  };
};

export type GetTicketsByIdDTO = {
  ticketId: string;
};

type CreateUpdateCommonFields = TicketMetaDataFields & {
  ticketSubject: string;
  requesterId?: number;
  recipientEmail?: string;
  assigneeId?: number;
  ticketDueAt?: string;
  ticketTags?: string;
  ticketExternalId?: string;
  ticketCustomFields?: string;
  requesterName: string;
  requesterEmail: string;
};

export type CreateTicketDTO = {
  ticketDescription: string;
} & CreateUpdateCommonFields;

export type AddCommentDTO = {
  ticketId: number;
  commentBody: string;
  isPublic?: boolean;
};

export type UpdateTicketDTO = {
  ticketId: number;
} & CreateUpdateCommonFields;

export type SearchTicketDTO = TicketMetaDataFields & {
  ticketDescription?: string;
  ticketSubject?: string;
  requesterId?: number;
  recipientEmail?: string;
  assigneeId?: number;
  ticketDueAt?: string;
  ticketTags?: string;
  ticketExternalId?: string;
  ticketCustomFields?: string;
  'createdDate-left'?: string;
  'createdDate-right'?: string;
  'updatedDate-left'?: string;
  'updatedDate-right'?: string;
  'dueDate-left'?: string;
  'dueDate-right'?: string;
};

type TicketMetaDataFields = {
  ticketType?: 'problem' | 'incident' | 'question' | 'task';
  ticketPriority?: 'urgent' | 'high' | 'normal' | 'low';
  ticketStatus?: 'new' | 'open' | 'pending' | 'hold' | 'solved' | 'closed';
};

export type DateOperator = {
  label: string;
  symbol: string;
  value: string;
};

export enum UserRole {
  END_USER = 'end-user',
  AGENT = 'agent',
  ADMIN = 'admin',
}

export type CreateUserDTO = {
  name: string;
  email?: string;
  phone?: string;
  role?: UserRole;
  externalId?: string;
  details?: string;
  notes?: string;
};

export type UpdateUserDTO = {
  userId: string;
} & Partial<CreateUserDTO>;

export type UserResponse = {
  user: {
    id: number;
    name: string;
    email?: string;
    phone?: string;
    role: UserRole;
    external_id?: string;
    details?: string;
    notes?: string;
    [key: string]: any;
  };
};

export type Ticket = {
  id: number;
  created_at: string;
  updated_at: string;
};
