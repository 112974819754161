import { IBaseEntity } from '@shared/entities/sdk/base.interface';

import { IConnectCredential } from '../credential';

/**
 * users information such as email, name and some other property that persona may have
 */
export type PersonaMeta = {
  Name?: string;
  Email?: string;
} & { [key: string]: unknown };

export type PersonaUsage = {
  /**
   * Connected persona count including disabled personas
   */
  connectedCount: number;
  /**
   * Allowed limit of connecting personas as per billing plan
   *
   * _undefined_ == "UNLIMITED"
   */
  allowedLimit?: number;
};

/**
 *  status for persona state . A persona can be disabled, connected or it may have workflow / account errors
 *  used in persona filters for CUD
 */
export enum PersonaStatus {
  Connected = 'CONNECTED',
  WorkflowErrors = 'WORKFLOW_ERRORS',
  AccountErrors = 'ACCOUNT_ERRORS',
  Disabled = 'DISABLED',
}

export enum PersonaIntegrationFilterOptions {
  NO_INTEGRATION = 'no-integration',
}

/**
 * Integration ID to select or alternative option.
 */
export type PersonaIntegrationFilterOption = string | PersonaIntegrationFilterOptions;

export type PersonaFilter = {
  integration?: PersonaIntegrationFilterOption;
  name?: string;
  status?: PersonaStatus;
  dateLastActiveStart?: Date;
  dateLastActiveEnd?: Date;
  after?: string;
  endUserId?: string;
};

export interface IPersona extends IBaseEntity {
  projectId: string;
  endUserId: string;
  meta: PersonaMeta;
  /**
   * timestamp value for the latest workflow execution by persona. Will be populated
   * later on by chronos
   */
  dateLastActive: Date;
  /**
   * boolean value that tells wether there are integration errors for persona.
   * In a future work effort this would be populated by the chronos service.
   */
  hasIntegrationErrors: boolean;

  /**
   * boolean value that tells if persona has any failed workflows. This will be
   * populated by chronos work effort later
   */
  hasWorkflowErrors: boolean;
  /**
   * boolean value . A persona may be disabled from connected users dashboard and
   * will not be able to perform any activity
   */
  active: boolean;
  connectCredentials?: IConnectCredential[];
}

export interface IPersonaWithIntegrationIds extends IPersona {
  integrations?: string[];
}

export type CachedPersona = {
  meta: Partial<PersonaMeta> | null;
};
