import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

export default function conditionsToFilterExpression(condition: ResolvedConditionWrapper): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterExpression(condition);
      })
      .join('');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringIsIn:
        return `filterType=${innerCondition.variable}&filterValues=${innerCondition.argument.join(
          ',',
        )}`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for Marketo`);
    }
  }
  return '';
}
