export enum NODE_ENV {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  TEST = 'test',
}

export enum PLATFORM_ENV {
  PRODUCTION = 'production',
  PRODUCTION_MIRROR_1 = 'p-m1',
  STAGING = 'staging',
  STAGING_MIRROR_1 = 's-m1',
  DEVELOPMENT = 'dev',
  TEST = 'test',
  UNO = 'uno',
  DOS = 'dos',
  TRES = 'tres',
  CUATRO = 'cuatro',
  CINCO = 'cinco',
  SEIS = 'seis',
  SIETE = 'siete',
  OCHO = 'ocho',
  NUEVE = 'nueve',
  DIEZ = 'diez',
  ONCE = 'once',
  DOCE = 'doce',
  TRECE = 'trece',
  CATORCE = 'catorce',
  QUINCE = 'quince',
  DIECISEIS = 'dieciseis',
  DIECISIETE = 'diecisiete',
  DIECIOCHO = 'dieciocho',
  DIECINUEVE = 'diecinueve',
  VEINTE = 'veinte',
  SANDBOX = 'sandbox',
  RELEASE = 'release',
  ENTERPRISE = 'enterprise',
}

export const QA_ENVS: PLATFORM_ENV[] = [
  PLATFORM_ENV.STAGING,
  PLATFORM_ENV.UNO,
  PLATFORM_ENV.DOS,
  PLATFORM_ENV.TRES,
  PLATFORM_ENV.CUATRO,
  PLATFORM_ENV.CINCO,
  PLATFORM_ENV.SEIS,
  PLATFORM_ENV.SIETE,
  PLATFORM_ENV.OCHO,
  PLATFORM_ENV.NUEVE,
  PLATFORM_ENV.DIEZ,
  PLATFORM_ENV.ONCE,
  PLATFORM_ENV.DOCE,
  PLATFORM_ENV.TRECE,
  PLATFORM_ENV.CATORCE,
  PLATFORM_ENV.QUINCE,
  PLATFORM_ENV.DIECISEIS,
  PLATFORM_ENV.DIECISIETE,
  PLATFORM_ENV.DIECIOCHO,
  PLATFORM_ENV.DIECINUEVE,
  PLATFORM_ENV.VEINTE,
];

export const PRODUCTION_PLATFORM_ENVS: PLATFORM_ENV[] = [
  PLATFORM_ENV.PRODUCTION,
  PLATFORM_ENV.PRODUCTION_MIRROR_1,
  PLATFORM_ENV.ENTERPRISE,
];

export const STAGING_PLATFORM_ENVS: PLATFORM_ENV[] = [
  PLATFORM_ENV.STAGING,
  PLATFORM_ENV.STAGING_MIRROR_1,
];

export const PRODUCTION_AND_STAGING_PLATFORM_ENVS: PLATFORM_ENV[] = [
  ...PRODUCTION_PLATFORM_ENVS,
  ...STAGING_PLATFORM_ENVS,
];

export const DEV_PLATFORM_ENVS: PLATFORM_ENV[] = Object.values(PLATFORM_ENV).filter(
  (env: PLATFORM_ENV) => !PRODUCTION_PLATFORM_ENVS.includes(env),
);

export enum HOST_ENV {
  AWS_ECS = 'AWS_ECS',
  AWS_EC2 = 'AWS_EC2',
  AWS_K8 = 'AWS_K8',
  AZURE_K8 = 'AZURE_K8',
  AZURE_VIRTUAL_MACHINE = 'AZURE_VIRTUAL_MACHINE',
  GCP_COMPUTE = 'GCP_COMPUTE',
  GCP_K8 = 'GCP_K8',
  LOCAL = 'LOCAL',
}

export enum CI_TRIGGER_EVENT {
  DEPLOYMENT = 'deployment',
  PUSH = 'push',
  PULL_REQUEST = 'pull_request',
  CRON = 'schedule',
  WORKFLOW_DISPATCH = 'workflow_dispatch',
}

export enum MINIO_MODE {
  SERVER = 'server',
  GATEWAY_S3 = 'gateway-s3',
  GATEWAY_AZURE = 'gateway-azure',
  GATEWAY_GCP = 'gateway-gcp',
}

export const NODE_VERSION: string = '14.17.6';

export enum ValidateEnvironmentMode {
  AllRequired = 'all_required',
  AllOptional = 'all_optional',
  Default = 'default',
}

export enum ENVIRONMENT_VARIABLES {
  // used in the ci / cd environment to build images & provision environments
  PARAGON_ENV_PATH = 'PARAGON_ENV_PATH',
  GITHUB_PERSONAL_TOKEN = 'GITHUB_PERSONAL_TOKEN',
  GIT_SHA = 'GIT_SHA',
  ECR_REPOSITORY = 'ECR_REPOSITORY',
  ECR_REGISTRY = 'ECR_REGISTRY',
  SERVICE = 'SERVICE',
  BRANCH = 'BRANCH',
  PRIVATE_DNS_HOST = 'PRIVATE_DNS_HOST',
  CERBERUS_DNS_TARGET = 'CERBERUS_DNS_TARGET',
  CHRONOS_DNS_TARGET = 'CHRONOS_DNS_TARGET',
  CONNECT_DNS_TARGET = 'CONNECT_DNS_TARGET',
  EMBASSY_DNS_TARGET = 'EMBASSY_DNS_TARGET',
  HADES_DNS_TARGET = 'HADES_DNS_TARGET',
  HERCULES_DNS_TARGET = 'HERCULES_DNS_TARGET',
  HERMES_DNS_TARGET = 'HERMES_DNS_TARGET',
  PASSPORT_DNS_TARGET = 'PASSPORT_DNS_TARGET',
  PLATO_DNS_TARGET = 'PLATO_DNS_TARGET',
  PHEME_DNS_TARGET = 'PHEME_DNS_TARGET',
  ZEUS_DNS_TARGET = 'ZEUS_DNS_TARGET',
  DASHBOARD_DNS_TARGET = 'DASHBOARD_DNS_TARGET',
  GRAFANA_DNS_TARGET = 'GRAFANA_DNS_TARGET',
  CDN_DNS_TARGET = 'CDN_DNS_TARGET',
  MINIO_DNS_TARGET = 'MINIO_DNS_TARGET',
  STATUS_PAGE_DNS_TARGET = 'STATUS_PAGE_DNS_TARGET',
  CLOUDFLARE_API_TOKEN = 'CLOUDFLARE_API_TOKEN',
  CLOUDFLARE_ZONE_ID = 'CLOUDFLARE_ZONE_ID',
  PARAGON_DOMAIN = 'PARAGON_DOMAIN',
  AWS_REGION = 'AWS_REGION',
  CI = 'CI',
  CI_COMMIT_AUTHOR = 'CI_COMMIT_AUTHOR',
  CI_DEPLOYMENT_ID = 'CI_DEPLOYMENT_ID',
  CI_TRIGGER_PAYLOAD = 'CI_TRIGGER_PAYLOAD',
  CI_TRIGGER_EVENT = 'CI_TRIGGER_EVENT',
  CI_SOURCE_BRANCH = 'CI_SOURCE_BRANCH',
  CI_TARGET_BRANCH = 'CI_TARGET_BRANCH',
  CI_GITHUB_USERNAME = 'CI_GITHUB_USERNAME',
  CI_GITHUB_TOKEN = 'CI_GITHUB_TOKEN',
  CI_JIRA_EMAIL = 'CI_JIRA_EMAIL',
  CI_JIRA_TOKEN = 'CI_JIRA_TOKEN',
  CI_AIRTABLE_API_KEY = 'CI_AIRTABLE_API_KEY',
  CI_SLACK_TOKEN = 'CI_SLACK_TOKEN',
  CI_BUILD_SUCCESS = 'CI_BUILD_SUCCESS',
  CI_ENTERPRISE_BUILD_SUCCESS = 'CI_ENTERPRISE_BUILD_SUCCESS',
  CI_COMPILE_SUCCESS = 'CI_COMPILE_SUCCESS',
  CI_LINT_SUCCESS = 'CI_LINT_SUCCESS',
  CI_TEST_SUCCESS = 'CI_TEST_SUCCESS',
  CI_PREFLIGHT_SUCCESS = 'CI_PREFLIGHT_SUCCESS',
  CI_AWS_ACCESS_KEY_ID = 'CI_AWS_ACCESS_KEY_ID',
  CI_AWS_SECRET_ACCESS_KEY = 'CI_AWS_SECRET_ACCESS_KEY',
  CI_AWS_BUCKET = 'CI_AWS_BUCKET',
  CI_UPLOAD_DIRECTORY = 'CI_UPLOAD_DIRECTORY',
  CI_UPLOAD_PREFIX = 'CI_UPLOAD_PREFIX',
  CI_UPLOAD_DELETE_REMOVED = 'CI_UPLOAD_DELETE_REMOVED',
  CI_TERRAFORM_API_KEY = 'CI_TERRAFORM_API_KEY',
  CI_TERRAFORM_CREATE_INFRA = 'CI_TERRAFORM_CREATE_INFRA',
  CI_IP_ADDRESSES = 'CI_IP_ADDRESSES',
  CI_RUNNER_APP_ID = 'CI_RUNNER_APP_ID',
  CI_RUNNER_CLIENT_ID = 'CI_RUNNER_CLIENT_ID',
  CI_RUNNER_CLIENT_SECRET = 'CI_RUNNER_CLIENT_SECRET',
  CI_RUNNER_PRIVATE_KEY = 'CI_RUNNER_PRIVATE_KEY',
  CI_BASTION_TUNNEL_HOST = 'CI_BASTION_TUNNEL_HOST',
  CI_BASTION_TUNNEL_PORT = 'CI_BASTION_TUNNEL_PORT',
  CI_BASTION_REMOTE_HOST = 'CI_BASTION_REMOTE_HOST',
  CI_BASTION_PUBLIC_KEY = 'CI_BASTION_PUBLIC_KEY',
  CI_BASTION_PRIVATE_KEY = 'CI_BASTION_PRIVATE_KEY',
  CI_BASTION_PRIVATE_KEY_PASSWORD = 'CI_BASTION_PRIVATE_KEY_PASSWORD',
  CI_QA_DEPLOYMENT = 'CI_QA_DEPLOYMENT',

  // used for provisioning postgres instances
  ATLAS_PROVISION_POSTGRES_HOST = 'ATLAS_PROVISION_POSTGRES_HOST',
  ATLAS_PROVISION_POSTGRES_PORT = 'ATLAS_PROVISION_POSTGRES_PORT',
  ATLAS_PROVISION_POSTGRES_USER = 'ATLAS_PROVISION_POSTGRES_USER',
  ATLAS_PROVISION_POSTGRES_PASS = 'ATLAS_PROVISION_POSTGRES_PASS',
  ATLAS_PROVISION_POSTGRES_DB = 'ATLAS_PROVISION_POSTGRES_DB',

  OPERATIONS_POSTGRES_IP_WHITELIST = 'OPERATIONS_POSTGRES_IP_WHITELIST',

  // Used for protecting all internal endpoints/data
  ADMIN_BASIC_AUTH_USERNAME = 'ADMIN_BASIC_AUTH_USERNAME',
  ADMIN_BASIC_AUTH_PASSWORD = 'ADMIN_BASIC_AUTH_PASSWORD',

  // helpers for mapping to one or more env variables
  POSTGRES_HOST = 'POSTGRES_HOST',
  POSTGRES_PORT = 'POSTGRES_PORT',
  POSTGRES_USERNAME = 'POSTGRES_USERNAME',
  POSTGRES_PASSWORD = 'POSTGRES_PASSWORD',
  POSTGRES_DATABASE = 'POSTGRES_DATABASE',
  REDIS_URL = 'REDIS_URL',
  REDIS_CLUSTER_URL = 'REDIS_CLUSTER_URL',
  S3_ACCESS_KEY_ID = 'S3_ACCESS_KEY_ID',
  S3_SECRET_ACCESS_KEY = 'S3_SECRET_ACCESS_KEY',
  S3_BUCKET = 'S3_BUCKET',
  DOMAIN = 'DOMAIN',

  // used in all services
  NODE_ENV = 'NODE_ENV',
  PLATFORM_ENV = 'PLATFORM_ENV',
  HOST_ENV = 'HOST_ENV', // currently only in hercules but can be added elsewhere
  VERSION = 'VERSION',
  PORT = 'PORT',
  LOG_LEVEL = 'LOG_LEVEL',
  LOG_EXPAND = 'LOG_EXPAND',
  LOG_TYPEORM = 'LOG_TYPEORM',
  VOLUME_PATH = 'VOLUME_PATH',
  HTTP_PROXY = 'HTTP_PROXY',

  // used in some services
  CDN_PUBLIC_URL = 'CDN_PUBLIC_URL',

  // Enterprise
  ORGANIZATION = 'ORGANIZATION',
  LICENSE = 'LICENSE',
  ANALYTICS_ENABLED = 'ANALYTICS_ENABLED',
  PASSWORD_VALIDATION_ENABLED = 'PASSWORD_VALIDATION_ENABLED',

  // cerberus specific config
  CERBERUS_PORT = 'CERBERUS_PORT',
  CERBERUS_POSTGRES_HOST = 'CERBERUS_POSTGRES_HOST',
  CERBERUS_POSTGRES_PORT = 'CERBERUS_POSTGRES_PORT',
  CERBERUS_POSTGRES_USERNAME = 'CERBERUS_POSTGRES_USERNAME',
  CERBERUS_POSTGRES_PASSWORD = 'CERBERUS_POSTGRES_PASSWORD',
  CERBERUS_POSTGRES_DATABASE = 'CERBERUS_POSTGRES_DATABASE',

  // connect specific config
  /**
   * Used in development only. Refers to the webpack-dev-server that will run to build the SDK
   * from @paragon/connect.
   */
  CONNECT_PORT = 'CONNECT_PORT',

  // embassy specific config
  EMBASSY_PORT = 'EMBASSY_PORT',
  EMBASSY_JWT_SECRET = 'EMBASSY_JWT_SECRET',

  // hercules specific config
  LOG_HERCULES_MONITOR = 'LOG_HERCULES_MONITOR',
  HERCULES_PORT = 'HERCULES_PORT',
  /**
   * how often old jobs are cleaned up from the bull queues (in milliseconds)
   */
  HERCULES_QUEUE_CLEANUP_FREQUENCY = 'HERCULES_QUEUE_CLEANUP_FREQUENCY',
  HERCULES_QUEUE_ID = 'HERCULES_QUEUE_ID',
  HERCULES_QUEUE_DISABLED = 'HERCULES_QUEUE_DISABLED',
  HERCULES_LARGE_ARRAY_THRESHOLD = 'HERCULES_LARGE_ARRAY_THRESHOLD',
  HERCULES_TESTING_ARRAY_LIMIT = 'HERCULES_TESTING_ARRAY_LIMIT',
  HERCULES_CLUSTER_DISABLED = 'HERCULES_CLUSTER_DISABLED',
  HERCULES_CLUSTER_MAX_INSTANCES = 'HERCULES_CLUSTER_MAX_INSTANCES',
  HERCULES_MONITOR_SELF_DISABLED = 'HERCULES_MONITOR_SELF_DISABLED',
  HERCULES_MONITOR_GLOBAL_DISABLED = 'HERCULES_MONITOR_GLOBAL_DISABLED',
  HERCULES_MONITOR_TERMINATE_UNHEALTHY_SERVERS = 'HERCULES_MONITOR_TERMINATE_UNHEALTHY_SERVERS',
  HERCULES_MONITOR_SHUTDOWN_ON_EMPTY_QUEUE = 'HERCULES_MONITOR_SHUTDOWN_ON_EMPTY_QUEUE',
  HERCULES_MONITOR_SHUTDOWN_GRACE_MILLISECONDS = 'HERCULES_MONITOR_SHUTDOWN_GRACE_MILLISECONDS',
  HERCULES_MONITOR_HEARTBEAT_EXPIRATION_SECONDS = 'HERCULES_MONITOR_HEARTBEAT_EXPIRATION_SECONDS',
  HERCULES_MONITOR_PROVISION_EXPIRATION_SECONDS = 'HERCULES_MONITOR_PROVISION_EXPIRATION_SECONDS',
  HERCULES_MONITOR_INSTANCE_LOCK_EXPIRATION_MILLISECONDS = 'HERCULES_MONITOR_INSTANCE_LOCK_EXPIRATION_MILLISECONDS',
  HERCULES_MONITOR_GLOBAL_LOCK_EXPIRATION_MILLISECONDS = 'HERCULES_MONITOR_GLOBAL_LOCK_EXPIRATION_MILLISECONDS',
  HERCULES_PARALLEL_PROCESSING_COUNT = 'HERCULES_PARALLEL_PROCESSING_COUNT',
  PLATO_PARALLEL_PROCESSING_COUNT = 'PLATO_PARALLEL_PROCESSING_COUNT',
  HERCULES_FUNCTION_WORKER_ENABLED = 'HERCULES_FUNCTION_WORKER_ENABLED',

  // hermes specific config
  HERMES_PORT = 'HERMES_PORT',

  // hercules + hermes shared execution config
  HERMES_ALERT_PREFIX = 'HERMES_ALERT_PREFIX',
  HERMES_POSTGRES_HOST = 'HERMES_POSTGRES_HOST',
  HERMES_POSTGRES_PORT = 'HERMES_POSTGRES_PORT',
  HERMES_POSTGRES_USERNAME = 'HERMES_POSTGRES_USERNAME',
  HERMES_POSTGRES_PASSWORD = 'HERMES_POSTGRES_PASSWORD',
  HERMES_POSTGRES_DATABASE = 'HERMES_POSTGRES_DATABASE',
  TASK_HISTORY_LOGS_DISABLED = 'TASK_HISTORY_LOGS_DISABLED',

  // passport specific config
  PASSPORT_PORT = 'PASSPORT_PORT',

  // zeus specific config
  ZEUS_PORT = 'ZEUS_PORT',
  ZEUS_POSTGRES_HOST = 'ZEUS_POSTGRES_HOST',
  ZEUS_POSTGRES_PORT = 'ZEUS_POSTGRES_PORT',
  ZEUS_POSTGRES_USERNAME = 'ZEUS_POSTGRES_USERNAME',
  ZEUS_POSTGRES_PASSWORD = 'ZEUS_POSTGRES_PASSWORD',
  ZEUS_POSTGRES_DATABASE = 'ZEUS_POSTGRES_DATABASE',
  ZEUS_JWT_SECRET = 'ZEUS_JWT_SECRET',
  ONBOARDING_POSTGRES_HOST = 'ONBOARDING_POSTGRES_HOST',
  ONBOARDING_POSTGRES_PORT = 'ONBOARDING_POSTGRES_PORT',
  ONBOARDING_POSTGRES_USERNAME = 'ONBOARDING_POSTGRES_USERNAME',
  ONBOARDING_POSTGRES_PASSWORD = 'ONBOARDING_POSTGRES_PASSWORD',
  ONBOARDING_POSTGRES_DATABASE = 'ONBOARDING_POSTGRES_DATABASE',

  // dashboard specific config
  DASHBOARD_PORT = 'DASHBOARD_PORT',
  BASIC_AUTH_USER = 'BASIC_AUTH_USER',
  BASIC_AUTH_PASSWORD = 'BASIC_AUTH_PASSWORD',

  // cerberus networking config
  CERBERUS_ACCESS_TOKEN = 'CERBERUS_ACCESS_TOKEN',
  CERBERUS_PUBLIC_URL = 'CERBERUS_PUBLIC_URL',
  CERBERUS_PRIVATE_URL = 'CERBERUS_PRIVATE_URL',

  // chronos networking config
  CHRONOS_PORT = 'CHRONOS_PORT',
  CHRONOS_PUBLIC_URL = 'CHRONOS_PUBLIC_URL',
  CHRONOS_PRIVATE_URL = 'CHRONOS_PRIVATE_URL',

  // connect networking config
  CONNECT_PUBLIC_URL = 'CONNECT_PUBLIC_URL',
  CONNECT_PRIVATE_URL = 'CONNECT_PRIVATE_URL',

  // dashboard networking config
  DASHBOARD_PUBLIC_URL = 'DASHBOARD_PUBLIC_URL',
  DASHBOARD_PRIVATE_URL = 'DASHBOARD_PRIVATE_URL',

  // embassy networking config
  EMBASSY_PUBLIC_URL = 'EMBASSY_PUBLIC_URL',
  EMBASSY_PRIVATE_URL = 'EMBASSY_PRIVATE_URL',

  // hades networking config
  HADES_PORT = 'HADES_PORT',
  HADES_ACCESS_TOKEN = 'HADES_ACCESS_TOKEN',
  HADES_PUBLIC_URL = 'HADES_PUBLIC_URL',
  HADES_PRIVATE_URL = 'HADES_PRIVATE_URL',

  // hercules networking config
  HERCULES_PUBLIC_URL = 'HERCULES_PUBLIC_URL',
  HERCULES_PRIVATE_URL = 'HERCULES_PRIVATE_URL',

  // hermes networking config
  HERMES_ACCESS_TOKEN = 'HERMES_ACCESS_TOKEN',
  HERMES_PUBLIC_URL = 'HERMES_PUBLIC_URL',
  HERMES_PRIVATE_URL = 'HERMES_PRIVATE_URL',

  // passport networking config
  PASSPORT_PUBLIC_URL = 'PASSPORT_PUBLIC_URL',
  PASSPORT_PRIVATE_URL = 'PASSPORT_PRIVATE_URL',

  /**
   * the production passport url, which is typically `https://passport.useparagon.com`
   * used in conjunction with environment-specific passports so all internal apps can use a single redirect url
   * and then redirect to the environment-specific passport
   */
  PASSPORT_PRODUCTION_URL = 'PASSPORT_PRODUCTION_URL',

  // plato networking config
  PLATO_PORT = 'PLATO_PORT',
  PLATO_PUBLIC_URL = 'PLATO_PUBLIC_URL',
  PLATO_PRIVATE_URL = 'PLATO_PRIVATE_URL',
  // pheme networking config
  PHEME_ACCESS_TOKEN = 'PHEME_ACCESS_TOKEN',
  PHEME_PRIVATE_URL = 'PHEME_PRIVATE_URL',
  PHEME_PORT = 'PHEME_PORT',
  PHEME_PUBLIC_URL = 'PHEME_PUBLIC_URL',

  // zeus networking config
  ZEUS_ACCESS_TOKEN = 'ZEUS_ACCESS_TOKEN',
  ZEUS_PUBLIC_URL = 'ZEUS_PUBLIC_URL',
  ZEUS_PRIVATE_URL = 'ZEUS_PRIVATE_URL',

  // beethoven config
  BEETHOVEN_POSTGRES_HOST = 'BEETHOVEN_POSTGRES_HOST',
  BEETHOVEN_POSTGRES_PORT = 'BEETHOVEN_POSTGRES_PORT',
  BEETHOVEN_POSTGRES_USERNAME = 'BEETHOVEN_POSTGRES_USERNAME',
  BEETHOVEN_POSTGRES_PASSWORD = 'BEETHOVEN_POSTGRES_PASSWORD',
  BEETHOVEN_POSTGRES_DATABASE = 'BEETHOVEN_POSTGRES_DATABASE',

  /**
   * pheme db config HOST ,PORT ,USERNAME , PASSWORD will be overrided by hermes db config
   * only adding those to make them configurable
   */
  PHEME_POSTGRES_HOST = 'PHEME_POSTGRES_HOST',
  PHEME_POSTGRES_PORT = 'PHEME_POSTGRES_PORT',
  PHEME_POSTGRES_USERNAME = 'PHEME_POSTGRES_USERNAME',
  PHEME_POSTGRES_PASSWORD = 'PHEME_POSTGRES_PASSWORD',
  PHEME_POSTGRES_DATABASE = 'PHEME_POSTGRES_DATABASE',

  // redis config
  DEBUG_REDIS = 'DEBUG_REDIS',
  CACHE_REDIS_URL = 'CACHE_REDIS_URL',
  SYSTEM_REDIS_URL = 'SYSTEM_REDIS_URL',
  QUEUE_REDIS_URL = 'QUEUE_REDIS_URL',
  WORKFLOW_REDIS_URL = 'WORKFLOW_REDIS_URL',
  CACHE_REDIS_CLUSTER_ENABLED = 'CACHE_REDIS_CLUSTER_ENABLED',
  SYSTEM_REDIS_CLUSTER_ENABLED = 'SYSTEM_REDIS_CLUSTER_ENABLED',
  QUEUE_REDIS_CLUSTER_ENABLED = 'QUEUE_REDIS_CLUSTER_ENABLED',
  WORKFLOW_REDIS_CLUSTER_ENABLED = 'WORKFLOW_REDIS_CLUSTER_ENABLED',

  // minio networking config
  MINIO_MODE = 'MINIO_MODE',
  MINIO_PORT = 'MINIO_PORT',
  MINIO_NGINX_PROXY = 'MINIO_NGINX_PROXY',
  MINIO_BROWSER = 'MINIO_BROWSER',
  MINIO_INSTANCE_COUNT = 'MINIO_INSTANCE_COUNT',
  MINIO_PUBLIC_URL = 'MINIO_PUBLIC_URL',
  MINIO_PRIVATE_URL = 'MINIO_PRIVATE_URL',
  MINIO_ROOT_USER = 'MINIO_ROOT_USER',
  MINIO_ROOT_PASS = 'MINIO_ROOT_PASS',
  MINIO_SYSTEM_BUCKET = 'MINIO_SYSTEM_BUCKET',
  MINIO_PUBLIC_BUCKET = 'MINIO_PUBLIC_BUCKET',
  MINIO_MICROSERVICE_USER = 'MINIO_MICROSERVICE_USER',
  MINIO_MICROSERVICE_PASS = 'MINIO_MICROSERVICE_PASS',
  MINIO_REGION = 'MINIO_REGION',

  // auth for 3rd party services by the microservices
  SEGMENT_API_KEY = 'SEGMENT_API_KEY',
  SENDGRID_API_KEY = 'SENDGRID_API_KEY',
  SENDGRID_FROM_ADDRESS = 'SENDGRID_FROM_ADDRESS',
  RESET_PASSWORD_TEMPLATE_ID = 'RESET_PASSWORD_TEMPLATE_ID',
  RESET_PASSWORD_TOKEN_ENCRYPTION_KEY = 'RESET_PASSWORD_TOKEN_ENCRYPTION_KEY',
  SENDGRID_TEAM_INVITATION_TEMPLATE_ID = 'SENDGRID_TEAM_INVITATION_TEMPLATE_ID',
  GOOGLE_ANALYTICS_TRACKING_ID = 'GOOGLE_ANALYTICS_TRACKING_ID',
  INTERCOM_APP_ID = 'INTERCOM_APP_ID',
  INTERCOM_APP_TOKEN = 'INTERCOM_APP_TOKEN',
  COOKIE_CONSENT_SDK_ID = 'COOKIE_CONSENT_SDK_ID',
  HEADWAY_ACCOUNT_ID = 'HEADWAY_ACCOUNT_ID',
  SEGMENT_ANALYTICS_JS_WRITE_KEY = 'SEGMENT_ANALYTICS_JS_WRITE_KEY',
  STRIPE_PUBLIC_KEY = 'STRIPE_PUBLIC_KEY',
  GOOGLE_TAG_MANAGER_ID = 'GOOGLE_TAG_MANAGER_ID',

  // auth for 3rd party services used in actions
  GOOGLE_CLIENT_ID = 'GOOGLE_CLIENT_ID',
  GOOGLE_CLIENT_SECRET = 'GOOGLE_CLIENT_SECRET',
  SLACK_APP_CLIENT_ID = 'SLACK_APP_CLIENT_ID',
  SLACK_APP_SECRET_ID = 'SLACK_APP_SECRET_ID',
  AIRTABLE_CLIENT_SECRET = 'AIRTABLE_CLIENT_SECRET',
  SALESFORCE_CLIENT_ID = 'SALESFORCE_CLIENT_ID',
  SALESFORCE_CLIENT_SECRET = 'SALESFORCE_CLIENT_SECRET',
  HUBSPOT_CLIENT_ID = 'HUBSPOT_CLIENT_ID',
  HUBSPOT_CLIENT_SECRET = 'HUBSPOT_CLIENT_SECRET',
  JIRA_CLIENT_ID = 'JIRA_CLIENT_ID',
  JIRA_CLIENT_SECRET = 'JIRA_CLIENT_SECRET',
  ASANA_CLIENT_ID = 'ASANA_CLIENT_ID',
  ASANA_CLIENT_SECRET = 'ASANA_CLIENT_SECRET',
  SENTRY_DSN = 'SENTRY_DSN',
  STRIPE_API_KEY = 'STRIPE_API_KEY',
  STRIPE_WEBHOOK_SIGNING_SECRET = 'STRIPE_WEBHOOK_SIGNING_SECRET',
  INTERCOM_CLIENT_ID = 'INTERCOM_CLIENT_ID',
  INTERCOM_CLIENT_SECRET = 'INTERCOM_CLIENT_SECRET',
  QUICKBOOKS_CLIENT_ID = 'QUICKBOOKS_CLIENT_ID',
  QUICKBOOKS_CLIENT_SECRET = 'QUICKBOOKS_CLIENT_SECRET',
  QUICKBOOKS_CLIENT_ID_SANDBOX = 'QUICKBOOKS_CLIENT_ID_SANDBOX',
  QUICKBOOKS_CLIENT_SECRET_SANDBOX = 'QUICKBOOKS_CLIENT_SECRET_SANDBOX',
  SHOPIFY_CLIENT_ID = 'SHOPIFY_CLIENT_ID',
  SHOPIFY_CLIENT_SECRET = 'SHOPIFY_CLIENT_SECRET',
  ZENDESK_CLIENT_ID = 'ZENDESK_CLIENT_ID',
  ZENDESK_CLIENT_SECRET = 'ZENDESK_CLIENT_SECRET',
  MICROSOFT_TEAMS_CLIENT_ID = 'MICROSOFT_TEAMS_CLIENT_ID',
  MICROSOFT_TEAMS_CLIENT_SECRET = 'MICROSOFT_TEAMS_CLIENT_SECRET',
  MICROSOFT_TEAMS_BOT_ID = 'MICROSOFT_TEAMS_BOT_ID',
  ZOOM_CLIENT_ID = 'ZOOM_CLIENT_ID',
  ZOOM_CLIENT_SECRET = 'ZOOM_CLIENT_SECRET',
  MICROSOFT_DYNAMICS_CLIENT_ID = 'MICROSOFT_DYNAMICS_CLIENT_ID',
  MICROSOFT_DYNAMICS_CLIENT_SECRET = 'MICROSOFT_DYNAMICS_CLIENT_SECRET',
  PARDOT_CLIENT_ID = 'PARDOT_CLIENT_ID',
  PARDOT_CLIENT_SECRET = 'PARDOT_CLIENT_SECRET',
  XERO_CLIENT_ID = 'XERO_CLIENT_ID',
  XERO_CLIENT_SECRET = 'XERO_CLIENT_SECRET',
  OUTREACH_CLIENT_ID = 'OUTREACH_CLIENT_ID',
  OUTREACH_CLIENT_SECRET = 'OUTREACH_CLIENT_SECRET',
  FACEBOOK_CLIENT_ID = 'FACEBOOK_CLIENT_ID',
  FACEBOOK_CLIENT_SECRET = 'FACEBOOK_CLIENT_SECRET',
  MAILCHIMP_CLIENT_ID = 'MAILCHIMP_CLIENT_ID',
  MAILCHIMP_CLIENT_SECRET = 'MAILCHIMP_CLIENT_SECRET',
  TRELLO_APP_NAME = 'TRELLO_APP_NAME',
  AZURE_DEVOPS_CLIENT_ID = 'AZURE_DEVOPS_CLIENT_ID',
  AZURE_DEVOPS_CLIENT_SECRET = 'AZURE_DEVOPS_CLIENT_SECRET',
  CLICKUP_CLIENT_ID = 'CLICKUP_CLIENT_ID',
  CLICKUP_CLIENT_SECRET = 'CLICKUP_CLIENT_SECRET',
  SHAREPOINT_CLIENT_ID = 'SHAREPOINT_CLIENT_ID',
  SHAREPOINT_CLIENT_SECRET = 'SHAREPOINT_CLIENT_SECRET',
  OUTLOOK_CLIENT_ID = 'OUTLOOK_CLIENT_ID',
  OUTLOOK_CLIENT_SECRET = 'OUTLOOK_CLIENT_SECRET',
  ELOQUA_CLIENT_ID = 'ELOQUA_CLIENT_ID',
  ELOQUA_CLIENT_SECRET = 'ELOQUA_CLIENT_SECRET',
  NETSUITE_CLIENT_ID = 'NETSUITE_CLIENT_ID',
  NETSUITE_CLIENT_SECRET = 'NETSUITE_CLIENT_SECRET',
  DYNAMICS_BUSINESS_CENTRAL_CLIENT_ID = 'DYNAMICS_BUSINESS_CENTRAL_CLIENT_ID',
  DYNAMICS_BUSINESS_CENTRAL_CLIENT_SECRET = 'DYNAMICS_BUSINESS_CENTRAL_CLIENT_SECRET',
  PIPEDRIVE_CLIENT_ID = 'PIPEDRIVE_CLIENT_ID',
  PIPEDRIVE_CLIENT_SECRET = 'PIPEDRIVE_CLIENT_SECRET',
  SAGE_INTACCT_SENDER_ID = 'SAGE_INTACCT_SENDER_ID',
  SAGE_INTACCT_SENDER_PASSWORD = 'SAGE_INTACCT_SENDER_PASSWORD',
  LINEAR_CLIENT_ID = 'LINEAR_CLIENT_ID',
  LINEAR_CLIENT_SECRET = 'LINEAR_CLIENT_SECRET',
  DYNAMICS_365_FINANCE_CLIENT_ID = 'DYNAMICS_365_FINANCE_CLIENT_ID',
  DYNAMICS_365_FINANCE_CLIENT_SECRET = 'DYNAMICS_365_FINANCE_CLIENT_SECRET',
  SAGE_ACCOUNTING_CLIENT_ID = 'SAGE_ACCOUNTING_CLIENT_ID',
  SAGE_ACCOUNTING_CLIENT_SECRET = 'SAGE_ACCOUNTING_CLIENT_SECRET',
  IMANAGE_CLIENT_ID = 'IMANAGE_CLIENT_ID',
  IMANAGE_CLIENT_SECRET = 'IMANAGE_CLIENT_SECRET',
  GONG_CLIENT_ID = 'GONG_CLIENT_ID',
  GONG_CLIENT_SECRET = 'GONG_CLIENT_SECRET',
  BAMBOO_HR_CLIENT_ID = 'BAMBOO_HR_CLIENT_ID',
  BAMBOO_HR_CLIENT_SECRET = 'BAMBOO_HR_CLIENT_SECRET',
  BAMBOO_HR_APPLICATION_KEY = 'BAMBOO_HR_APPLICATION_KEY',
  GITHUB_CLIENT_ID = 'GITHUB_CLIENT_ID',
  GITHUB_CLIENT_SECRET = 'GITHUB_CLIENT_SECRET',
  GOOGLE_AD_MANAGER_CLIENT_ID = 'GOOGLE_AD_MANAGER_CLIENT_ID',
  GOOGLE_AD_MANAGER_CLIENT_SECRET = 'GOOGLE_AD_MANAGER_CLIENT_SECRET',
  DROPBOX_CLIENT_ID = 'DROPBOX_CLIENT_ID',
  DROPBOX_CLIENT_SECRET = 'DROPBOX_CLIENT_SECRET',
  DOCUSIGN_INTEGRATION_KEY = 'DOCUSIGN_INTEGRATION_KEY',
  DOCUSIGN_SECRET_KEY = 'DOCUSIGN_SECRET_KEY',
  DOCUSIGN_DEVELOPMENT_INTEGRATION_KEY = 'DOCUSIGN_DEVELOPMENT_INTEGRATION_KEY',
  DOCUSIGN_DEVELOPMENT_SECRET_KEY = 'DOCUSIGN_DEVELOPMENT_SECRET_KEY',
  GOOGLE_ADS_CLIENT_ID = 'GOOGLE_ADS_CLIENT_ID',
  GOOGLE_ADS_CLIENT_SECRET = 'GOOGLE_ADS_CLIENT_SECRET',
  GOOGLE_ADS_DEVELOPER_TOKEN = 'GOOGLE_ADS_DEVELOPER_TOKEN',
  ZOHO_CRM_CLIENT_ID = 'ZOHO_CRM_CLIENT_ID',
  ZOHO_CRM_CLIENT_SECRET = 'ZOHO_CRM_CLIENT_SECRET',
  SALESLOFT_CLIENT_ID = 'SALESLOFT_CLIENT_ID',
  SALESLOFT_CLIENT_SECRET = 'SALESLOFT_CLIENT_SECRET',
  TABLEAU_CLIENT_ID = 'TABLEAU_CLIENT_ID',
  TABLEAU_CLIENT_SECRET_ID = 'TABLEAU_CLIENT_SECRET_ID',
  TABLEAU_CLIENT_SECRET_VALUE = 'TABLEAU_CLIENT_SECRET_VALUE',
  LEVER_CLIENT_ID = 'LEVER_CLIENT_ID',
  LEVER_CLIENT_SECRET = 'LEVER_CLIENT_SECRET',
  LEVER_SANDBOX_CLIENT_ID = 'LEVER_SANDBOX_CLIENT_ID',
  LEVER_SANDBOX_CLIENT_SECRET = 'LEVER_SANDBOX_CLIENT_SECRET',
  CALENDLY_CLIENT_ID = 'CALENDLY_CLIENT_ID',
  CALENDLY_CLIENT_SECRET = 'CALENDLY_CLIENT_SECRET',
  TWITTER_CONSUMER_API_KEY = 'TWITTER_CONSUMER_API_KEY',
  TWITTER_CONSUMER_SECRET_KEY = 'TWITTER_CONSUMER_SECRET_KEY',
  BIGQUERY_CLIENT_ID = 'BIGQUERY_CLIENT_ID',
  BIGQUERY_CLIENT_SECRET = 'BIGQUERY_CLIENT_SECRET',
  POWER_BI_CLIENT_ID = 'POWER_BI_CLIENT_ID',
  POWER_BI_CLIENT_SECRET = 'POWER_BI_CLIENT_SECRET',
  GUSTO_CLIENT_ID = 'GUSTO_CLIENT_ID',
  GUSTO_CLIENT_SECRET = 'GUSTO_CLIENT_SECRET',
  GUSTO_DEMO_CLIENT_ID = 'GUSTO_DEMO_CLIENT_ID',
  GUSTO_DEMO_CLIENT_SECRET = 'GUSTO_DEMO_CLIENT_SECRET',

  // used for building 3rd party images for monitoring services
  MONITOR_PGADMIN_HOST = 'MONITOR_PGADMIN_HOST',
  MONITOR_PGADMIN_PORT = 'MONITOR_PGADMIN_PORT',
  MONITOR_PGADMIN_EMAIL = 'MONITOR_PGADMIN_EMAIL',
  MONITOR_PGADMIN_PASSWORD = 'MONITOR_PGADMIN_PASSWORD',
  MONITOR_PGADMIN_SSL_MODE = 'MONITOR_PGADMIN_SSL_MODE',
  MONITOR_GRAFANA_HOST = 'MONITOR_GRAFANA_HOST',
  MONITOR_GRAFANA_PORT = 'MONITOR_GRAFANA_PORT',
  MONITOR_GRAFANA_SERVER_DOMAIN = 'MONITOR_GRAFANA_SERVER_DOMAIN',
  MONITOR_GRAFANA_SECURITY_ADMIN_USER = 'MONITOR_GRAFANA_SECURITY_ADMIN_USER',
  MONITOR_GRAFANA_SECURITY_ADMIN_PASSWORD = 'MONITOR_GRAFANA_SECURITY_ADMIN_PASSWORD',
  MONITOR_GRAFANA_AUTH_GITHUB_CLIENT_ID = 'MONITOR_GRAFANA_AUTH_GITHUB_CLIENT_ID',
  MONITOR_GRAFANA_AUTH_GITHUB_CLIENT_SECRET = 'MONITOR_GRAFANA_AUTH_GITHUB_CLIENT_SECRET',
  MONITOR_GRAFANA_SLACK_CANARY_CHANNEL = 'MONITOR_GRAFANA_SLACK_CANARY_CHANNEL',
  MONITOR_GRAFANA_SLACK_CANARY_WEBHOOK_URL = 'MONITOR_GRAFANA_SLACK_CANARY_WEBHOOK_URL',
  MONITOR_GRAFANA_SLACK_CANARY_BETA_WEBHOOK_URL = 'MONITOR_GRAFANA_SLACK_CANARY_BETA_WEBHOOK_URL',
  MONITOR_GRAFANA_SLACK_CANARY_BETA_CHANNEL = 'MONITOR_GRAFANA_SLACK_CANARY_BETA_CHANNEL',
  MONITOR_GRAFANA_AWS_ACCESS_ID = 'MONITOR_GRAFANA_AWS_ACCESS_ID',
  MONITOR_GRAFANA_AWS_SECRET_KEY = 'MONITOR_GRAFANA_AWS_SECRET_KEY',
  MONITOR_PROMETHEUS_HOST = 'MONITOR_PROMETHEUS_HOST',
  MONITOR_PROMETHEUS_PORT = 'MONITOR_PROMETHEUS_PORT',
  MONITOR_BULL_EXPORTER_HOST = 'MONITOR_BULL_EXPORTER_HOST',
  MONITOR_BULL_EXPORTER_PORT = 'MONITOR_BULL_EXPORTER_PORT',
  MONITOR_REDIS_EXPORTER_HOST = 'MONITOR_REDIS_EXPORTER_HOST',
  MONITOR_REDIS_EXPORTER_PORT = 'MONITOR_REDIS_EXPORTER_PORT',
  MONITOR_POSTGRES_EXPORTER_HOST = 'MONITOR_POSTGRES_EXPORTER_HOST',
  MONITOR_POSTGRES_EXPORTER_PORT = 'MONITOR_POSTGRES_EXPORTER_PORT',
  MONITOR_POSTGRES_EXPORTER_SSL_MODE = 'MONITOR_POSTGRES_EXPORTER_SSL_MODE',
  MONITOR_REDIS_INSIGHT_HOST = 'MONITOR_REDIS_INSIGHT_HOST',
  MONITOR_REDIS_INSIGHT_PORT = 'MONITOR_REDIS_INSIGHT_PORT',
  MONITOR_CACHE_REDIS_TARGETS = 'MONITOR_CACHE_REDIS_TARGETS',
  MONITOR_QUEUE_REDIS_TARGET = 'MONITOR_QUEUE_REDIS_TARGET',
  MONITOR_GRAFANA_ALB_ARN = 'MONITOR_GRAFANA_ALB_ARN',
  MONITOR_BEETHOVEN_EXPORTER_HOST = 'MONITOR_BEETHOVEN_EXPORTER_HOST',
  MONITOR_BEETHOVEN_EXPORTER_PORT = 'MONITOR_BEETHOVEN_EXPORTER_PORT',
  MONITOR_STATUS_PAGE_PORT = 'MONITOR_STATUS_PAGE_PORT',
  MONITOR_STATUS_PAGE_NAME = 'MONITOR_STATUS_PAGE_NAME',
  MONITOR_STATUS_PAGE_ADMIN_USER = 'MONITOR_STATUS_PAGE_ADMIN_USER',
  MONITOR_STATUS_PAGE_ADMIN_PASSWORD = 'MONITOR_STATUS_PAGE_ADMIN_PASSWORD',
  MONITOR_STATUS_PAGE_PUBLIC_URL = 'MONITOR_STATUS_PAGE_PUBLIC_URL',
  MONITOR_STATUS_PAGE_API_SECRET = 'MONITOR_STATUS_PAGE_API_SECRET',
  MONITOR_STATUS_PAGE_SLACK_WEBHOOK_URL = 'MONITOR_STATUS_PAGE_SLACK_WEBHOOK_URL',

  // e2e test specific environment variables
  E2E_POSTGRES_DATABASE = 'E2E_POSTGRES_DATABASE',
  E2E_POSTGRES_HOST = 'E2E_POSTGRES_HOST',
  E2E_POSTGRES_PORT = 'E2E_POSTGRES_PORT',
  E2E_POSTGRES_USER = 'E2E_POSTGRES_USER',
  E2E_POSTGRES_PASSWORD = 'E2E_POSTGRES_PASSWORD',
  E2E_MYSQL_HOST = 'E2E_MYSQL_HOST',
  E2E_MYSQL_DATABASE = 'E2E_MYSQL_DATABASE',
  E2E_MYSQL_PORT = 'E2E_MYSQL_PORT',
  E2E_MYSQL_USER = 'E2E_MYSQL_USER',
  E2E_MYSQL_PASSWORD = 'E2E_MYSQL_PASSWORD',
  E2E_TWILIO_ACCOUNT_SID = 'E2E_TWILIO_ACCOUNT_SID',
  E2E_TWILIO_AUTH_TOKEN = 'E2E_TWILIO_AUTH_TOKEN',
  E2E_TWILIO_NUMBER = 'E2E_TWILIO_NUMBER',
  E2E_TWILIO_SANDBOX_ACCOUNT_SID = 'E2E_TWILIO_SANDBOX_ACCOUNT_SID',
  E2E_TWILIO_SANDBOX_AUTH_TOKEN = 'E2E_TWILIO_SANDBOX_AUTH_TOKEN',
  E2E_TWILIO_SANDBOX_NUMBER = 'E2E_TWILIO_SANDBOX_NUMBER',
  E2E_TWILIO_RECIPIENT = 'E2E_TWILIO_RECIPIENT',
  E2E_FIREBASE_DATABASE_URL = 'E2E_FIREBASE_DATABASE_URL',
  E2E_FIREBASE_PROJECT_ID = 'E2E_FIREBASE_PROJECT_ID',
  E2E_FIREBASE_SERVICE_ACCOUNT = 'E2E_FIREBASE_SERVICE_ACCOUNT',
  E2E_GOOGLE_CLIENT_ID = 'E2E_GOOGLE_CLIENT_ID',
  E2E_GOOGLE_CLIENT_SECRET = 'E2E_GOOGLE_CLIENT_SECRET',
  E2E_GOOGLE_CLIENT_REFRESH_TOKEN = 'E2E_GOOGLE_CLIENT_REFRESH_TOKEN',
  E2E_STRIPE_SECRET_KEY = 'E2E_STRIPE_SECRET_KEY',
  E2E_HUBSPOT_REFRESH_TOKEN = 'E2E_HUBSPOT_REFRESH_TOKEN',
  E2E_HUBSPOT_CLIENT_ID = 'E2E_HUBSPOT_CLIENT_ID',
  E2E_HUBSPOT_CLIENT_SECRET = 'E2E_HUBSPOT_CLIENT_SECRET',
  E2E_JIRA_CLIENT_ID = 'E2E_JIRA_CLIENT_ID',
  E2E_JIRA_CLIENT_SECRET = 'E2E_JIRA_CLIENT_SECRET',
  E2E_JIRA_REFRESH_TOKEN = 'E2E_JIRA_REFRESH_TOKEN',
  E2E_JIRA_CLOUD_ID = 'E2E_JIRA_CLOUD_ID',
  E2E_JIRA_PROVIDER_ID = 'E2E_JIRA_PROVIDER_ID',
  E2E_QUICKBOOKS_CLIENT_ID = 'E2E_QUICKBOOKS_CLIENT_ID',
  E2E_QUICKBOOKS_CLIENT_SECRET = 'E2E_QUICKBOOKS_CLIENT_SECRET',
  E2E_QUICKBOOKS_REALM_ID = 'E2E_QUICKBOOKS_REALM_ID',
  E2E_QUICKBOOKS_REFRESH_TOKEN = 'E2E_QUICKBOOKS_REFRESH_TOKEN',
  E2E_AWS_ACCESS_KEY_ID = 'E2E_AWS_ACCESS_KEY_ID',
  E2E_AWS_SECRET_ACCESS_KEY = 'E2E_AWS_SECRET_ACCESS_KEY',
  E2E_AWS_REGION = 'E2E_AWS_REGION',
  E2E_SALESFORCE_CLIENT_ID = 'E2E_SALESFORCE_CLIENT_ID',
  E2E_SALESFORCE_CLIENT_SECRET = 'E2E_SALESFORCE_CLIENT_SECRET',
  E2E_SALESFORCE_REFRESH_TOKEN = 'E2E_SALESFORCE_REFRESH_TOKEN',
  E2E_SALESFORCE_PROVIDER_ID = 'E2E_SALESFORCE_PROVIDER_ID',
  E2E_GITHUB_CLIENT_ID = 'E2E_GITHUB_CLIENT_ID',
  E2E_GITHUB_CLIENT_SECRET = 'E2E_GITHUB_CLIENT_SECRET',
  E2E_GITHUB_USERNAME = 'E2E_GITHUB_USERNAME',
  E2E_GITHUB_PASSWORD = 'E2E_GITHUB_PASSWORD',
  E2E_INTERCOM_CLIENT_ID = 'E2E_INTERCOM_CLIENT_ID',
  E2E_INTERCOM_CLIENT_SECRET = 'E2E_INTERCOM_CLIENT_SECRET',
  E2E_INTERCOM_EMAIL = 'E2E_INTERCOM_EMAIL',
  E2E_INTERCOM_PASSWORD = 'E2E_INTERCOM_PASSWORD',
  E2E_BYO_CLIENT_ID = 'E2E_BYO_CLIENT_ID',
  E2E_BYO_CLIENT_SECRET = 'E2E_BYO_CLIENT_SECRET',
  E2E_BYO_EMAIL = 'E2E_BYO_EMAIL',
  E2E_BYO_PASSWORD = 'E2E_BYO_PASSWORD',

  // deprecated
  SMTP_HOST = 'SMTP_HOST',
  SMTP_PORT = 'SMTP_PORT',
  SMTP_USERNAME = 'SMTP_USERNAME',
  SMTP_PASSWORD = 'SMTP_PASSWORD',
}

// this keys should not be used as it can expose our credentials to outside modules
export enum FORBIDDEN_ENVIRONMENT_VARIABLES {
  AWS_ACCESS_KEY_ID = 'AWS_ACCESS_KEY_ID',
  AWS_SECRET_ACCESS_KEY = 'AWS_SECRET_ACCESS_KEY',
}
