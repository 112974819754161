import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Sage Intacct',
  description: 'Connect to your users’ Sage Intacct accounts',
  authUrl: '',
  accessTokenUrl: '',
  iconSvg,
  clientIdLabel: '',
  clientSecretLabel: '',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      id: 'senderId',
      title: 'Sender ID',
      placeholder: 'Web Services Sender ID',
      required: true,
    },
    {
      type: SidebarInputType.ValueText,
      id: 'senderPassword',
      title: 'Sender Password',
      placeholder: 'Web Services Sender Password',
      required: true,
    },
  ],
  scopes: { notAllowed: true, values: [] },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/sage-intacct',
};
