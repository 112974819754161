import { Type } from 'class-transformer';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import 'reflect-metadata';
import { Stripe } from 'stripe';

import {
  ICloneableWorkflow,
  IOrganization,
  IWorkflowTag,
  WorkflowTagEntity,
} from '@shared/entities/sdk';
import {
  IConnectCredential,
  IConnectCredentialWithPersona,
} from '@shared/entities/sdk/credential/connectCredential.interface';
import {
  DecryptedCredential,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { IConnectEvent } from '@shared/entities/sdk/event/connectEvent.interface';
import {
  IStepExecution,
  IWorkflowExecution,
  StepCount,
} from '@shared/entities/sdk/execution/execution.interface';
import { ConnectIntegrationConfigEntity } from '@shared/entities/sdk/integration';
import { ICustomIntegration } from '@shared/entities/sdk/integration/customIntegration.interface';
import {
  IConnectIntegration,
  IDetailedConnectIntegration,
} from '@shared/entities/sdk/integration/integration.interface';
import { IInvite } from '@shared/entities/sdk/invite/invite.interface';
import {
  IPersona,
  IPersonaWithIntegrationIds,
  PersonaUsage,
} from '@shared/entities/sdk/persona/persona.interface';
import { AccountUsageData, IProject } from '@shared/entities/sdk/project/project.interface';
import { ISecret } from '@shared/entities/sdk/secret/secret.interface';
import { ISigningKey } from '@shared/entities/sdk/signingKey/signingKey.interface';
import { ITeam, ITeamMemberExpanded } from '@shared/entities/sdk/team/team.interface';
import { ILoggedInUser } from '@shared/entities/sdk/user/user.interface';
import { IConnectWorkflow } from '@shared/entities/sdk/workflow/workflow.interface';
import { ActionIntent, Action as ActionType, AlertActionResponse } from '@shared/types/sdk/actions';
import { UTMParameter } from '@shared/types/sdk/analytics';
import { SerializedConnectInput } from '@shared/types/sdk/connect';
import { ActionTriggerOutput } from '@shared/types/sdk/execution';
import { IntegrationWizardDTO } from '@shared/types/sdk/integrationWizard';
import {
  CompletedExecution,
  Condition,
  DataType,
  KeyedSource,
  Source,
  TokenizedSource,
} from '@shared/types/sdk/resolvers';
import {
  Continuation,
  DelayUnit,
  DeploymentStatus,
  Deployment as DeploymentType,
  EventStepParameters,
  ExecutionStatus,
  HttpMethod,
  MapVariables,
  RequestAuthorizationType,
  RequestBodyType,
  ResponseTypeEnum,
  StateMachine,
  Step,
  StepInstance,
  StepType,
  TriggerInput,
  TriggerStepType,
  Unit,
  Variables,
  Weekday,
  Workflow,
  WorkflowExecutionUpdate,
  WorkflowLogEntity,
  WorkflowMigration as WorkflowMigrationType,
} from '@shared/types/sdk/steps';
import { ConnectBillingPlanPriceMap } from '@shared/types/sdk/stripe';
import { WorkflowVersion } from '@shared/types/sdk/version';
import { WorkflowFeatureRestriction } from '@shared/workflow/sdk/featureRestrictions';

import { DestinationEntities, EventDestinationAction } from './reducers/entities/destinations';
import { IntegrationWizardAction } from './reducers/integrationWizard';

export type Child = string | JSX.Element;
export type Children = Child | Child[];

export enum SettingsTab {
  ENVIRONMENT_SECRETS = 'Environment Secrets',
  TEAM_MEMBERS = 'Team Members',
  ACCOUNT_DETAILS = 'Account Details',
  USER_AUTHENTICATION = 'User Authentication',
  BILLING_DETAILS = 'Billing',
  MONITORING = 'Monitoring',
}

export type AuthState = {
  newUser: boolean;
  leadQualificationLoading: boolean;
  leadQualificationErrorMessage?: string;
  startTutorialRequestLoading: boolean;
  startTutorialRequestReady?: boolean;
  startTutorialRequestErrorMessage?: string;
  loginLoading: boolean;
  loginError: boolean;
  loginErrorMessage?: string;
  signupLoading: boolean;
  signupError: boolean;
  signupErrorMessage?: string;
  updateErrorMessage?: string;
  accessToken?: string;
  user?: ILoggedInUser;
  getMeFailed: boolean;
  resetPasswordError?: string;
  resetPasswordLoading: boolean;
  usage?: AccountUsageData;
  usageLoading: boolean;
  usageErrorMessage?: string;
};

export type TestWorkflowView = {
  type: 'TEST_WORKFLOW';
};

export type AddStepView = {
  type: 'ADD_STEP';
  parentStepId: string;
  createdStepId?: string;
};

export type EditStepView = {
  type: 'EDIT_STEP';
  stepId: string;
  testShelfOpen: boolean;
  createdStepId?: string;
};

export type ExecutionView = {
  type: 'EXECUTION';
  activeStepId?: string;
  activeStepInstanceId?: string;
};

export type VersionHistoryView = {
  type: 'VERSION_HISTORY';
};

export type SidebarView =
  | AddStepView
  | EditStepView
  | TestWorkflowView
  | ExecutionView
  | VersionHistoryView;

export type SidebarState = {
  open: boolean;
  view?: SidebarView;
};

type ExecutingStepStatus = {
  type: ExecutionStatus.EXECUTING;
};

type WaitingStepStatus = {
  type: ExecutionStatus.WAITING;
};

type SucceededStepStatus = {
  type: ExecutionStatus.SUCCEEDED;
  input: object;
  startTime: number;
  endTime: number;
  continuation: Continuation;
};

type FailedStepStatus = {
  type: ExecutionStatus.FAILED;
  input: object;
  startTime: number;
  endTime: number;
  error: any;
};

export type StepStatus =
  | ExecutingStepStatus
  | SucceededStepStatus
  | FailedStepStatus
  | WaitingStepStatus;

export type TestingStepInstance = {
  stepId: string;
  instanceId: string;
  status: StepStatus;
};

type TestingExecutingStatus = {
  type: ExecutionStatus.EXECUTING;
  abortRequest: () => void;
};

type TestingSucceededStatus = {
  type: ExecutionStatus.SUCCEEDED;
};

type TestingFailedStatus = {
  type: ExecutionStatus.FAILED;
  errorMessage?: string;
};

export type TestingStatus = TestingExecutingStatus | TestingSucceededStatus | TestingFailedStatus;

export type Testing = {
  status: TestingStatus;
  stepInstances: TestingStepInstance[];
  triggerInputs: Record<string, TriggerInput>;
};

export type IntegrationWizardState = {
  customIntegrationId?: string;
  integrationId?: string;
  values: IntegrationWizardDTO;
  fieldErrors: { [field in keyof IntegrationWizardDTO]: string | undefined };
  errorMessage?: string;
  processing: boolean;
  testRequestData?: unknown;
  testRequestStatus?: ExecutionStatus;
};

export type DeployProgress = 'DEPLOYING' | 'UNDEPLOYING' | 'FETCHING';

export type NavigationState = {
  projectId?: string;
  workflowId?: string;
  activeSettingsTab?: SettingsTab;
  memberId?: string;
  versionId?: string;
  executionId?: string;
  isConnectUi?: boolean;
  integrationId?: string;
  utmParameters?: Partial<Record<UTMParameter, string>>;
};

export type WorkflowEditorState = {
  descriptionEditable: boolean;
  actionTestConnectionStatus?: AlertActionResponse;
  editable: boolean;
  testing?: Testing;
  sidebar: SidebarState;
  deployProgress?: DeployProgress;
  variableMenu: {
    open: boolean;
    selectedStepId?: string;
  };
  editingAppEventId?: string;
  showOnboardingNextSteps?: boolean;
  supportedActions: string[];
  actionTriggerInputs: Record<string, ActionTriggerOutput[]>;
  actionTriggerFetchProcessing: boolean;
  actionTriggerFetchError?: string;
  showFeatureRestriction?: WorkflowFeatureRestriction;
  dismissedFeatureRestrictions: WorkflowFeatureRestriction[];
  stateMachine: StateMachine;
  showWorkflowSavedToast: boolean;
  autoSaveCount: number;
  failedDeploymentErrorMessage: string;
  stepValidations: Record<string, { id: string; error: string }>;
  cloneableWorkflows: ICloneableWorkflow[];
};

export type StepInstanceEntity = Omit<StepInstance, 'step' | 'workflowLog' | 'stepType'> & {
  stepId: string;
  start: string;
  end: string;
  next: string[]; // array of next step instance id
  type: StepType;
};

export type WorkflowExecution = Omit<IWorkflowExecution, 'start' | 'end'> & {
  start: string;
  end: string;
};

export type WorkflowExecutionWithMeta = WorkflowExecution & {
  path: StepCount[];
  tasks?: number;
};

export type StepData = {
  isLargeInput: boolean;
  isLargeOutput: boolean;
  input: object | string;
  output: { message?: string } | string;
};

export type WorkflowExecutionState = {
  processing: boolean;
  processingLog: boolean;
  workflowExecution?: WorkflowExecutionWithMeta;
  path: StepCount[]; // ordered step ids
  stepsData: Record<string, StepData>;
  errorMessage?: string;
};

export type WorkflowMigraton = {
  workflowId: string;
  steps: StepMigration[]; // step migration
  workflow: Workflow;
};

export type StepMigration = Step & {
  stepId: string;
};

export type MessagePart = {
  type: 'BOLD' | 'NORMAL';
  message: string;
};

export type ConfirmationOptions = {
  title: string;
  message: string | MessagePart[];
  yesText?: string;
  noText?: string;
  result?: boolean;
  disableYesButton?: boolean;
  yesButtonColor?: string;
};

export type ConfirmState = {
  confirmations: Record<string, ConfirmationOptions>;
  active?: string;
};

export type ConnectBillingState = {
  plans?: ConnectBillingPlanPriceMap;
  subscription?: Stripe.Subscription;
};

export type State = {
  auth: AuthState;
  entities: EntitiesContainerState;
  workflowDashboard: WorkflowDashboardState;
  workflowEditor: WorkflowEditorState;
  navigation: NavigationState;
  workflowExecution: WorkflowExecutionState;
  confirm: ConfirmState;
  billing: ConnectBillingState;
  integrationWizard: IntegrationWizardState;
};

//-------------------------------- Entities from api ------------------------------------//
export type EntitiesState<T> = {
  entities: Record<string, T>;
  errorMessage?: string;
  processing: boolean;
};

// TODO: should be `Workflow` with lowercase `flow`
export type WorkFlow = {
  id: string;
  dateCreated: Date;
  dateUpdated: Date;
  description: string;
  steps: Step[];
  isOnboardingWorkflow?: boolean;
  tags?: WorkflowTagEntity[];
  integrationId?: string;
  integration?: IConnectIntegration;
  projectId: string;
};

export type AddRemoveWorkflowTag = { tag: IWorkflowTag; workflowId: string };

// steps are stored in the `steps` entity, so we remove them from `workflows` entities to prevent
// confusion (since they'll go stale)
export type WorkflowEntity = Omit<WorkFlow, 'steps'> & {
  stepIds: string[];
};

export type WorkflowEntities = EntitiesState<WorkflowEntity> & {
  createdWorkflowId?: string;
  duplicatedWorkflowId?: string;
};

export type WorkflowLogEntities = EntitiesState<WorkflowLogEntity> & {
  loadAttempt: boolean;
  pageCount: number;
};

export type PastStepExecution = Omit<IStepExecution, 'workflowExecution'> & {
  incomplete?: false;
  // `| object` left in for backwards-compatibility for test step executions
  // saved before truncation was introduced
  output: Variables | MapVariables[];
};
export type LiveStepExecution = Partial<IStepExecution> & {
  incomplete: true;
  output?: Variables | MapVariables[];
  abortRequest: () => void;
};
export type StepExecution = PastStepExecution | LiveStepExecution;

export type VersionEntityState = EntitiesState<WorkflowVersion> & {
  pageCount: number;
  extras: WorkflowVersion[];
};

export type IntegrationEntity = IDetailedConnectIntegration;

export type IntegrationConfigEntity = Omit<ConnectIntegrationConfigEntity, 'integration'>;

export type IntegrationEntities = EntitiesState<IntegrationEntity> & {
  activeIntegrationsCount: number;
};

export type ConnectEventEntities = EntitiesState<IConnectEvent> & { newlyCreatedAppEvent?: string };

export type PersonaEntity = IPersona &
  IPersonaWithIntegrationIds & {
    workflowLogs?: WorkflowLogEntity[];
  };

export type PersonaEntities = EntitiesState<PersonaEntity> & {
  total: number;
  usage: PersonaUsage;
  previewPersona?: IPersona;
};

export type EntitiesContainerState = {
  connectCredentials: EntitiesState<IConnectCredentialWithPersona>;
  credentials: EntitiesState<ICredential>;
  migrations: EntitiesState<WorkflowMigrationType | null>;
  projects: EntitiesState<IProject>;
  steps: EntitiesState<Step>;
  stepExecutions: EntitiesState<StepExecution>;
  variables: EntitiesState<CompletedExecution>;
  workflows: WorkflowEntities;
  workflowLogs: WorkflowLogEntities;
  secrets: EntitiesState<ISecret>;
  teams: EntitiesState<ITeam>;
  teamMembers: EntitiesState<ITeamMemberExpanded>;
  invites: EntitiesState<IInvite>;
  versions: VersionEntityState;
  signingKeys: EntitiesState<ISigningKey>;
  integrations: IntegrationEntities;
  integrationConfigs: EntitiesState<IntegrationConfigEntity>;
  decryptedCredentials: EntitiesState<DecryptedCredential>;
  workflowTag: EntitiesState<IWorkflowTag>;
  connectEvent: ConnectEventEntities;
  connectWorkflows: EntitiesState<IConnectWorkflow>;
  organizations: EntitiesState<IOrganization>;
  customIntegrations: EntitiesState<ICustomIntegration>;
  personas: PersonaEntities;
  destinations: DestinationEntities;
};

export type WorkflowDashboardState = {
  createWorkflowProcessing: boolean;
  createdWorkflowId?: string;
};

export class Deployment implements DeploymentType {
  @Type(() => Date)
  readonly dateCreated: Date;
  @Type(() => WorkflowMigration)
  readonly workflowMigrations: WorkflowMigration[];

  constructor(dateCreated: Date, workflowMigrations: WorkflowMigration[]) {
    this.dateCreated = dateCreated;
    this.workflowMigrations = workflowMigrations;
  }
}

export class WorkflowMigration implements WorkflowMigrationType {
  @Type(() => Date)
  readonly dateCreated: Date;
  readonly workflowId: string;
  readonly status: DeploymentStatus;

  constructor(dateCreated: Date, workflowId: string, status: DeploymentStatus) {
    this.dateCreated = dateCreated;
    this.workflowId = workflowId;
    this.status = status;
  }
}

// ----------------------  Actions ----------------------

// TODO: follow `_REQUEST`, `_SUCCESS`, `_FAILURE` async action convention
// TODO: use `payload` for nested objects in actions
export type Action =
  | { type: 'LOGIN_START' }
  | { type: 'LOGIN_COMPLETE'; accessToken: string; user: ILoggedInUser }
  | { type: 'LOGIN_ERROR'; message?: string }
  | { type: 'SIGNUP_START' }
  | { type: 'SIGNUP_COMPLETE'; accessToken: string; user: ILoggedInUser }
  | { type: 'SIGNUP_ERROR'; message?: string }
  | { type: 'RESET_PASSWORD_START' }
  | { type: 'RESET_PASSWORD_SUCCESS' }
  | { type: 'RESET_PASSWORD_ERROR'; message?: string }
  | { type: 'GET_ME_START' }
  | { type: 'GET_ME_COMPLETE'; user: ILoggedInUser }
  | { type: 'GET_ME_ERROR' }
  | { type: 'GET_ME_UPDATE_START' }
  | { type: 'GET_ME_UPDATE_COMPLETE'; user: ILoggedInUser }
  | { type: 'GET_ME_UPDATE_ERROR'; payload: { message: string } }
  | { type: 'LOGOUT' }
  | {
      type: 'NAVIGATE';
      projectId?: string;
      workflowId?: string;
      memberId?: string;
      versionId?: string;
      executionId?: string;
      isConnectUi?: boolean;
      integrationId?: string;
      integrationConfigId?: string;
      utmParameters?: Partial<Record<UTMParameter, string>>;
    }
  | { type: 'OPEN_ADD_STEP'; parentStepId: string }
  | { type: 'OPEN_EDIT_STEP'; stepId: string }
  | { type: 'TOGGLE_TEST_SHELF'; openState?: boolean }
  | { type: 'OPEN_VARIABLE_MENU' }
  | { type: 'CLOSE_VARIABLE_MENU' }
  | { type: 'NAVIGATE_VARIABLE_MENU'; selectedStepId: string }
  | { type: 'OPEN_ONBOARDING_NEXT_STEPS' }
  | { type: 'CLOSE_ONBOARDING_NEXT_STEPS' }
  | { type: 'ADD_STEP'; payload: { stepType: StepType } }
  | { type: 'OPEN_TEST_WORKFLOW_SIDEBAR' }
  | { type: 'OPEN_EXECUTION_SIDEBAR' }
  | { type: 'SET_EXPANDED_STEP_EXECUTION'; activeStepId: string | undefined }
  | {
      type: 'SET_EXPANDED_STEP_INSTANCE_EXECUTION';
      activeStepInstanceId: string | undefined;
    }
  | { type: 'TEST_WORKFLOW_START'; abortRequest: () => void }
  | { type: 'TEST_WORKFLOW_COMPLETE' }
  | { type: 'TEST_WORKFLOW_CANCEL' }
  | { type: 'TEST_STEP_START'; stepIds: string[]; abortRequest: () => void }
  | {
      type: 'TEST_STEP_UPDATE';
      payload: WorkflowExecutionUpdate[];
      stepIds: string[];
    }
  | { type: 'TEST_STEP_COMPLETE'; stepIds: string[] }
  | { type: 'TEST_STEP_CANCEL'; stepIds: string[] }
  | { type: 'DEACTIVATE_SIDEBAR' }
  | { type: 'SET_STEP_DESCRIPTION'; stepId: string; description: string }
  | {
      type: 'SET_TRIGGER_STEP_TYPE';
      stepId: string;
      stepType: TriggerStepType;
      actionType?: ActionType;
      workflowId: string;
      timezone: string;
    }
  | {
      type: 'SET_TRIGGER_SCHEDULER_UNIT';
      stepId: string;
      unit: Unit;
      timezone: string;
    }
  | {
      type: 'SET_TRIGGER_APP_EVENT';
      stepId: string;
      eventId: string;
    }
  | {
      type: 'SET_TRIGGER_APP_EVENT_PARAMETERS';
      stepId: string;
      parameters: Partial<EventStepParameters>;
    }
  | { type: 'SET_TRIGGER_SCHEDULER_MINUTES'; stepId: string; minutes: number }
  | { type: 'SET_TRIGGER_SCHEDULER_SECONDS'; stepId: string; seconds: number }
  | { type: 'SET_TRIGGER_SCHEDULER_HOURS'; stepId: string; hours: number }
  | {
      type: 'SET_TRIGGER_SCHEDULER_HOURS_OFFSET';
      stepId: string;
      offset: number;
    }
  | { type: 'SET_TRIGGER_SCHEDULER_DAYS'; stepId: string; days: number }
  | { type: 'SET_TRIGGER_SCHEDULER_DAY_TIME'; stepId: string; time: number }
  | {
      type: 'SET_TRIGGER_SCHEDULER_DAY_TIMEZONE';
      stepId: string;
      timezone: TokenizedSource<DataType.STRING> | string;
    }
  | { type: 'SET_TRIGGER_SCHEDULER_WEEKDAY'; stepId: string; weekday: Weekday }
  | { type: 'SET_TRIGGER_SCHEDULER_WEEK_TIME'; stepId: string; time: number }
  | {
      type: 'SET_TRIGGER_SCHEDULER_WEEK_TIMEZONE';
      stepId: string;
      timezone: TokenizedSource<DataType.STRING> | string;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_HTTP_METHOD';
      stepId: string;
      httpMethod: HttpMethod;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_PATH';
      stepId: string;
      path: string;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_TEST_OPTION';
      stepId: string;
      allowArbitraryPayload: boolean;
    }
  | { type: 'ADD_TRIGGER_API_ENDPOINT_PARAM_VALIDATION'; stepId: string }
  | {
      type: 'DELETE_TRIGGER_API_ENDPOINT_PARAM_VALIDATION';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_PARAM_VALIDATION_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_PARAM_VALIDATION_REQUIRED';
      stepId: string;
      index: number;
      required: boolean;
    }
  | { type: 'ADD_TRIGGER_API_ENDPOINT_HEADER_VALIDATION'; stepId: string }
  | {
      type: 'DELETE_TRIGGER_API_ENDPOINT_HEADER_VALIDATION';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_HEADER_VALIDATION_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_HEADER_VALIDATION_VALUE';
      stepId: string;
      index: number;
      value: string;
    }
  | { type: 'ADD_TRIGGER_API_ENDPOINT_BODY_VALIDATION'; stepId: string }
  | {
      type: 'DELETE_TRIGGER_API_ENDPOINT_BODY_VALIDATION';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_BODY_VALIDATION_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_BODY_VALIDATION_DATA_TYPE';
      stepId: string;
      index: number;
      dataType: DataType;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_BODY_VALIDATION_REQUIRED';
      stepId: string;
      index: number;
      required: boolean;
    }
  | {
      type: 'SET_TRIGGER_API_ENDPOINT_OBJECT_MAPPING';
      stepId: string;
      objectMapping: TokenizedSource<DataType.STRING>;
    }
  | {
      type: 'ADD_FUNCTION_PARAMETER';
      stepId: string;
    }
  | {
      type: 'DELETE_FUNCTION_PARAMETER';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_FUNCTION_PARAMETER_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_FUNCTION_PARAMETER_SOURCE';
      stepId: string;
      index: number;
      source: Source;
    }
  | {
      type: 'SET_FUNCTION_CODE';
      stepId: string;
      code: string;
    }
  | {
      type: 'PROJECT_ENTITIES_CREATION_STARTED';
    }
  | {
      type: 'PROJECT_ENTITIES_CREATION_SUCCESS';
      projects: IProject[];
    }
  | {
      type: 'PROJECT_ENTITIES_CREATION_FAILED';
      message?: string;
    }
  | {
      type: 'PROJECTS_ENTITY_GET_STARTED';
    }
  | {
      type: 'PROJECTS_ENTITY_GET_SUCCESS';
      projects: IProject[];
    }
  | {
      type: 'PROJECTS_ENTITY_GET_FAILED';
      message?: string;
    }
  | {
      type: 'WORKFLOW_ENTITY_FETCH_START';
    }
  | {
      type: 'WORKFLOW_ENTITY_FETCH_SUCCESS';
      workflows: WorkFlow[];
    }
  | {
      type: 'WORKFLOW_TAG_CREATION_FAILED';
      message?: string;
    }
  | {
      type: 'WORKFLOW_TAG_CREATION_START';
    }
  | {
      type: 'WORKFLOW_TAG_CREATION_SUCCESS';
      payload: WorkflowTagEntity;
    }
  | { type: 'WORKFLOW_TAG_FETCH_START' }
  | {
      type: 'WORKFLOW_TAG_FETCH_SUCCESS';
      payload: Record<string, IWorkflowTag>;
    }
  | {
      type: 'WORKFLOW_TAG_FETCH_FAILED';
      message?: string;
    }
  | {
      type: 'WORKFLOW_ENTITY_FETCH_FAILED';
      message?: string;
    }
  | {
      type: 'WORKFLOW_ENTITY_CREATION_STARTED';
    }
  | {
      type: 'WORKFLOW_ENTITY_CREATION_SUCCESS';
      payload: WorkFlow;
    }
  | {
      type: 'WORKFLOW_ENTITY_CREATION_FAILED';
      message?: string;
    }
  | {
      type: 'SET_WORKFLOW_CREATE_PROCESSING';
      processing: boolean;
    }
  | {
      type: 'CREDENTIALS_ENTITY_FETCH_START';
    }
  | {
      type: 'CREDENTIALS_ENTITY_FETCH_SUCCESS';
      credentials: ICredential[];
    }
  | {
      type: 'CREDENTIALS_ENTITY_FETCH_FAILURE';
      message?: string;
    }
  | {
      type: 'CREDENTIALS_ENTITY_CREATION_START';
    }
  | {
      type: 'CREDENTIALS_ENTITY_CREATION_SUCCESS';
      credential: ICredential;
    }
  | {
      type: 'CREDENTIALS_ENTITY_CREATION_FAILURE';
      message?: string;
    }
  | {
      type: 'CREDENTIALS_ENTITY_UPDATE_START';
      id: string;
    }
  | {
      type: 'CREDENTIALS_ENTITY_UPDATE_SUCCESS';
      credential: ICredential;
    }
  | {
      type: 'CREDENTIALS_ENTITY_UPDATE_FAILURE';
      id: string;
      message?: string;
    }
  | {
      type: 'CREDENTIALS_ENTITY_UPSERT_START';
    }
  | {
      type: 'CREDENTIALS_ENTITY_UPSERT_SUCCESS';
      credential: ICredential;
    }
  | {
      type: 'CREDENTIALS_ENTITY_UPSERT_FAILURE';
      message?: string;
    }
  | {
      type: 'DECRYPTED_CREDENTIAL_FETCH_START';
    }
  | {
      type: 'DECRYPTED_CREDENTIAL_FETCH_SUCCESS';
      credential: DecryptedCredential;
    }
  | {
      type: 'DECRYPTED_CREDENTIAL_FETCH_FAILURE';
      message?: string;
    }
  | {
      type: 'DECRYPTED_CREDENTIAL_RESET_WITH_EMPTY';
    }
  | {
      type: 'CREDENTIALS_ENTITY_DELETE_START';
      id: string;
    }
  | {
      type: 'CREDENTIALS_ENTITY_DELETE_SUCCESS';
      id: string;
    }
  | {
      type: 'CREDENTIALS_ENTITY_DELETE_FAILURE';
      id: string;
      message?: string;
    }
  | {
      type: 'GET_ONE_WORKFLOW_REQUEST';
    }
  | {
      type: 'GET_ONE_WORKFLOW_SUCCESS';
      payload: WorkFlow;
    }
  | {
      type: 'GET_ONE_WORKFLOW_FAILURE';
      message?: string;
    }
  | {
      type: 'WORKFLOW_LOG_ENTITY_FETCH_START';
    }
  | {
      type: 'WORKFLOW_LOG_ENTITY_FETCH_SUCCESS';
      workflowLogs: WorkflowLogEntity[];
    }
  | {
      type: 'WORKFLOW_LOG_ENTITY_FETCH_FAILURE';
      message?: string;
    }
  | {
      type: 'WORKFLOW_LOG_ENTITY_SET_TOTAL_COUNT';
      pageCount: number;
    }
  | {
      type: 'STEP_ENTITY_FETCH_START';
    }
  | {
      type: 'STEP_ENTITY_FETCH_SUCCESS';
      payload: Step[];
    }
  | {
      type: 'STEP_ENTITY_FETCH_FAILED';
      message?: string;
    }
  | {
      type: 'STEP_ENTITY_CREATE_REQUEST';
    }
  | {
      type: 'STEP_ENTITY_CREATE_SUCCESS';
      payload: WorkFlow;
    }
  | {
      type: 'STEP_ENTITY_CREATE_FAILURE';
      message?: string;
    }
  | {
      type: 'STEP_ENTITY_DELETE_REQUEST';
      payload: WorkFlow;
    }
  | {
      type: 'STEP_ENTITY_DELETE_SUCCESS';
      payload: WorkFlow;
    }
  | {
      type: 'STEP_ENTITY_DELETE_FAILURE';
      message?: string;
    }
  | {
      type: 'STEP_ENTITY_UPDATE_START';
    }
  | {
      type: 'STEP_ENTITY_UPDATE_SUCCESS';
    }
  | {
      type: 'STEP_ENTITY_UPDATE_FAILED';
      message?: string;
    }
  | {
      type: 'STEP_ENTITY_CREATE_REQUEST';
    }
  | {
      type: 'STEP_ENTITY_CREATE_SUCCESS';
      payload: WorkFlow;
    }
  | {
      type: 'STEP_ENTITY_CREATE_FAILURE';
      message?: string;
    }
  | {
      type: 'STEP_ENTITY_REORDER_REQUEST';
      payload: WorkFlow;
    }
  | {
      type: 'STEP_ENTITY_REORDER_SUCCESS';
      payload: WorkFlow;
    }
  | {
      type: 'STEP_ENTITY_REORDER_FAILURE';
      message?: string;
    }
  | {
      type: 'STEP_EXECUTION_ENTITY_FETCH_START';
    }
  | {
      type: 'STEP_EXECUTION_ENTITY_FETCH_SUCCESS';
      stepExecutions: PastStepExecution[];
    }
  | {
      type: 'STEP_EXECUTION_ENTITY_FETCH_FAILURE';
      message?: string;
    }
  | {
      type: 'SET_ADD_STEP_CREATED_STEP_ID';
      createdStepId: string;
    }
  | {
      type: 'TEST_WORKFLOW_UPDATE';
      payload: WorkflowExecutionUpdate[];
    }
  | {
      type: 'SET_REQUEST_HTTP_METHOD';
      stepId: string;
      httpMethod: HttpMethod;
    }
  | {
      type: 'SET_REQUEST_URI';
      stepId: string;
      url: TokenizedSource<DataType.STRING>;
    }
  | {
      type: 'ADD_REQUEST_HEADER';
      stepId: string;
    }
  | {
      type: 'DELETE_REQUEST_HEADER';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_REQUEST_HEADER_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_REQUEST_HEADER_SOURCE';
      stepId: string;
      index: number;
      source: Source<DataType.STRING>;
    }
  | {
      type: 'ADD_REQUEST_BODY';
      stepId: string;
    }
  | {
      type: 'DELETE_REQUEST_BODY';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_REQUEST_BODY_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_REQUEST_BODY_SOURCE';
      stepId: string;
      index: number;
      source: Source;
    }
  | {
      type: 'SET_REQUEST_BODY_TYPE';
      stepId: string;
      bodyType: RequestBodyType;
    }
  | {
      type: 'SET_REQUEST_RAW_BODY';
      stepId: string;
      source: TokenizedSource<DataType.STRING>;
    }
  | {
      type: 'SET_REQUEST_AUTHORIZATION_TYPE';
      stepId: string;
      authorizationType: RequestAuthorizationType;
    }
  | {
      type: 'SET_REQUEST_AUTHORIZATION_BEARER_TOKEN';
      stepId: string;
      token: TokenizedSource<DataType.STRING>;
    }
  | {
      type: 'SET_REQUEST_AUTHORIZATION_BASIC_FIELD';
      stepId: string;
      fields: {
        username?: TokenizedSource<DataType.STRING>;
        password?: TokenizedSource<DataType.STRING>;
      };
    }
  | {
      type: 'ADD_REQUEST_PARAM';
      stepId: string;
    }
  | {
      type: 'DELETE_REQUEST_PARAM';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_REQUEST_PARAM_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_REQUEST_PARAM_SOURCE';
      stepId: string;
      index: number;
      source: Source<DataType.STRING>;
    }
  | {
      type: 'SET_ALL_REQUEST_PARAMS';
      stepId: string;
      params: KeyedSource<DataType.STRING>[];
    }
  | {
      type: 'SET_REQUEST_IGNORE_FAILURE';
      stepId: string;
      payload: boolean;
    }
  | {
      type: 'SET_ACTION_IGNORE_FAILURE';
      stepId: string;
      payload: boolean;
    }
  | {
      type: 'SET_REDIRECT_URL';
      stepId: string;
      url: TokenizedSource<DataType.STRING>;
    }
  | {
      type: 'ADD_REDIRECT_URL_PARAM';
      stepId: string;
    }
  | {
      type: 'DELETE_REDIRECT_URL_PARAM';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_REDIRECT_URL_PARAM_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_REDIRECT_URL_PARAM_SOURCE';
      stepId: string;
      index: number;
      source: Source<DataType.ANY>;
    }
  | {
      type: 'SET_RESPONSE_STATUS_CODE';
      stepId: string;
      statusCode: number;
    }
  | {
      type: 'SET_RESPONSE_BODY_TYPE';
      stepId: string;
      responseType: ResponseTypeEnum;
    }
  | {
      type: 'SET_RESPONSE_BODY_FILE';
      stepId: string;
      bodyFile: TokenizedSource<DataType.FILE>;
    }
  | {
      type: 'ADD_RESPONSE_BODY_DATA';
      stepId: string;
    }
  | {
      type: 'DELETE_RESPONSE_BODY_DATA';
      stepId: string;
      index: number;
    }
  | {
      type: 'SET_RESPONSE_BODY_DATA_KEY';
      stepId: string;
      index: number;
      key: string;
    }
  | {
      type: 'SET_RESPONSE_BODY_DATA_SOURCE';
      stepId: string;
      index: number;
      source: Source<DataType.ANY>;
    }
  | {
      type: 'SET_ACTION_INTENT';
      stepId: string;
      intent: ActionIntent;
      stepType: StepType;
    }
  | {
      type: 'SET_ACTION_PARAMETER';
      stepId: string;
      key: string;
      value: Source<DataType.ANY>;
      stepType: StepType;
    }
  | {
      type: 'SET_ACTION_CREDENTIALS';
      stepId: string;
      stepType: StepType;
      credentialIds: string[];
    }
  | {
      type: 'SET_OAUTH_TRIGGER_CREDENTIALS';
      stepId: string;
      provider: ProviderType;
      credentialIds: string[];
    }
  | {
      type: 'SET_ACTION_TEST_CONNECTION_STARTED';
    }
  | {
      type: 'SET_ACTION_TEST_CONNECTION_END';
      payload: AlertActionResponse;
    }
  | {
      type: 'RESET_ACTION_TEST_CONNECTION';
    }
  | {
      type: 'GET_VARIABLES_START';
      payload: { stepId: string };
    }
  | {
      type: 'GET_VARIABLES_COMPLETE';
      payload: { stepId: string; variables: CompletedExecution[] };
    }
  | {
      type: 'GET_VARIABLES_ERROR';
      payload: { message?: string };
    }
  | {
      type: 'ADD_CONDITIONAL_AND_CONDITION';
      stepId: string;
      choiceIndex: number;
      actionParameterKey?: string;
      stepType: StepType;
    }
  | {
      type: 'ADD_CONDITIONAL_OPERATOR_CONDITION';
      stepId: string;
      choiceIndex: number;
      andIndex: number;
      actionParameterKey?: string;
      stepType: StepType;
    }
  | {
      type: 'DELETE_CONDITIONAL_OPERATOR_CONDITION';
      stepId: string;
      choiceIndex: number;
      andIndex: number;
      conditionIndex: number;
      actionParameterKey?: string;
      stepType: StepType;
    }
  | {
      type: 'SET_CONDITIONAL_OPERATOR_CONDITION';
      stepId: string;
      choiceIndex: number;
      andIndex: number;
      conditionIndex: number;
      condition: Condition;
      actionParameterKey?: string;
      stepType: StepType;
    }
  | {
      type: 'SET_MAP_ITERATOR';
      stepId: string;
      iterator: Source<DataType.ARRAY>;
    }
  | {
      type: 'GET_LAST_DEPLOYMENT_START';
    }
  | {
      type: 'GET_LAST_DEPLOYMENT_SUCCESS';
      payload: {
        workflowId: string;
        migration: WorkflowMigration | null;
      };
    }
  | {
      type: 'GET_LAST_DEPLOYMENT_FAILED';
    }
  | {
      type: 'DEPLOY_WORKFLOW_START';
    }
  | {
      type: 'DEPLOY_WORKFLOW_SUCCESS';
      payload: {
        workflowId: string;
        deployment: Deployment;
      };
      message?: string;
    }
  | {
      type: 'DEPLOY_WORKFLOW_FAILED';
      message: string;
    }
  | {
      type: 'UNDEPLOY_WORKFLOW_START';
    }
  | {
      type: 'UNDEPLOY_WORKFLOW_SUCCESS';
      payload: {
        workflowId: string;
      };
      message?: string;
    }
  | {
      type: 'UNDEPLOY_WORKFLOW_FAILED';
      message: string;
    }
  | { type: 'RESET_DEPLOYMENT_ERROR_MESSAGE' }
  | { type: 'SET_WORKFLOW_DESCRIPTION_START'; payload: { workflowId: string } }
  | {
      type: 'SET_WORKFLOW_DESCRIPTION_SUCCESS';
      payload: { workflowId: string; description: string };
    }
  | {
      type: 'SET_WORKFLOW_DESCRIPTION_START';
      payload: { workflowId: string };
    }
  | {
      type: 'GET_PROJECT_MIGRATIONS_START';
    }
  | {
      type: 'GET_PROJECT_MIGRATIONS_FAILED';
      payload: { message: any };
    }
  | {
      type: 'GET_PROJECT_MIGRATIONS_SUCCESS';
      payload: { migrations: WorkflowMigrationType[] };
    }
  | {
      type: 'WORKFLOW_ENTITY_DELETE_START';
    }
  | {
      type: 'WORKFLOW_ENTITY_DELETE_SUCCESS';
      payload: { workflowId: string };
    }
  | {
      type: 'WORKFLOW_ENTITY_DELETE_FAILED';
      payload: { message: any };
    }
  | {
      type: 'SET_WORKFLOW_ENTITY_CREATED_WORKFLOW_ID';
      workflowId: string;
    }
  | {
      type: 'UNSET_WORKFLOW_ENTITY_CREATED_WORKFLOW_ID';
    }
  | {
      type: 'SET_WORKFLOW_ENTITY_DUPLICATED_WORKFLOW_ID';
      workflowId: string;
    }
  | {
      type: 'UNSET_WORKFLOW_ENTITY_DUPLICATED_WORKFLOW_ID';
    }
  | {
      type: 'SET_TESTING_TRIGGER_INPUT';
      payload: {
        workflowId: string;
        triggerInput: TriggerInput;
      };
    }
  | {
      type: 'SET_DELAY_VALUE';
      stepId: string;
      value: Source<DataType.NUMBER>;
    }
  | {
      type: 'SET_DELAY_UNIT';
      stepId: string;
      unit: DelayUnit;
    }
  | {
      type: 'CLEAR_WORKFLOW_EXECUTION';
    }
  | {
      type: 'WORKFLOW_EXECUTION_GET_START';
    }
  | {
      type: 'WORKFLOW_EXECUTION_GET_SUCCESS';
      workflowExecution: WorkflowExecutionWithMeta;
    }
  | {
      type: 'WORKFLOW_EXECUTION_GET_FAILED';
      message?: string;
    }
  | {
      type: 'WORKFLOW_EXECUTION_STEP_DATA_GET_START';
    }
  | {
      type: 'WORKFLOW_EXECUTION_STEP_DATA_GET_SUCCESS';
      stepId: string;
      stepData: StepData;
    }
  | {
      type: 'WORKFLOW_EXECUTION_STEP_DATA_GET_FAILED';
      message?: string;
    }
  | {
      type: 'WORKFLOW_EXECUTION_GET_STEP_EXECUTION_START';
    }
  | {
      type: 'WORKFLOW_EXECUTION_GET_STEP_EXECUTION_SUCCESS';
      executions: IStepExecution[];
    }
  | {
      type: 'WORKFLOW_EXECUTION_GET_STEP_EXECUTION_FAILED';
      message?: string;
    }
  | {
      type: 'EXECUTE_ACTION_START';
      payload: {
        workflowId: string;
        step: Step;
        action: ActionType;
        intent: ActionIntent;
        parameters: KeyedSource<DataType.ANY>[];
        credentials: string[];
      };
    }
  | {
      type: 'EXECUTE_ACTION_SUCCESS';
      payload: any;
    }
  | {
      type: 'EXECUTE_ACTION_FAILURE';
      message: any;
    }
  | {
      type: 'WORKFLOW_EXECUTION_PAUSE_START';
    }
  | {
      type: 'WORKFLOW_EXECUTION_PAUSE_SUCCESS';
    }
  | {
      type: 'WORKFLOW_EXECUTION_PAUSE_FAILED';
      message?: string;
    }
  | {
      type: 'WORKFLOW_EXECUTION_RESUME_START';
    }
  | {
      type: 'WORKFLOW_EXECUTION_RESUME_SUCCESS';
    }
  | {
      type: 'WORKFLOW_EXECUTION_RESUME_FAILED';
      message?: string;
    }
  | {
      type: 'WORKFLOW_EXECUTION_REPLAY_START';
      replayOf: string;
    }
  | {
      type: 'WORKFLOW_EXECUTION_REPLAY_SUCCESS';
    }
  | {
      type: 'WORKFLOW_EXECUTION_REPLAY_FAILURE';
      message?: string;
    }
  | {
      type: 'UPDATE_STEP';
      step: Step;
    }
  | {
      type: 'NAVIGATE_SETTINGS_TAB';
      payload: SettingsTab;
    }
  | {
      type: 'APP_EVENTS_FETCH_START';
    }
  | {
      type: 'APP_EVENTS_FETCH_SUCCESS';
      payload: IConnectEvent[];
    }
  | {
      type: 'APP_EVENTS_FETCH_FAILURE';
      message?: string;
    }
  | {
      type: 'APP_EVENT_CREATE_START';
    }
  | {
      type: 'APP_EVENT_CREATE_SUCCESS';
      payload: IConnectEvent;
    }
  | {
      type: 'APP_EVENT_CREATE_FAILURE';
      message?: string;
    }
  | {
      type: 'APP_EVENT_UPDATE_START';
    }
  | {
      type: 'APP_EVENT_UPDATE_SUCCESS';
      payload: IConnectEvent;
    }
  | {
      type: 'APP_EVENT_UPDATE_FAILURE';
      message?: string;
    }
  | { type: 'REMOVE_NEWLY_CREATED_APP_EVENT' }
  | {
      type: 'APP_EVENT_DELETE_START';
    }
  | {
      type: 'APP_EVENT_DELETE_SUCCESS';
      id: string;
    }
  | {
      type: 'APP_EVENT_DELETE_FAILURE';
      message?: string;
    }
  | {
      type: 'APP_EVENT_CLEAR_ERROR_MESSAGE';
    }
  | {
      type: 'CONNECTED_WORKFLOW_FETCH_START';
    }
  | {
      type: 'CONNECTED_WORKFLOW_FETCH_SUCCESS';
      payload: IConnectWorkflow[];
    }
  | {
      type: 'CONNECTED_WORKFLOW_FETCH_FAILURE';
      message?: string;
    }
  | {
      type: 'SIGNING_KEYS_FETCH_START';
    }
  | {
      type: 'SIGNING_KEYS_FETCH_SUCCESS';
      payload: ISigningKey[];
    }
  | {
      type: 'SIGNING_KEYS_FETCH_FAILURE';
      message?: string;
    }
  | {
      type: 'SIGNING_KEY_CONFIG_CREATE_START';
    }
  | {
      type: 'SIGNING_KEY_CONFIG_CREATE_SUCCESS';
      payload: ISigningKey;
    }
  | {
      type: 'SIGNING_KEY_CONFIG_CREATE_FAILURE';
      message: string;
    }
  | {
      type: 'SIGNING_KEY_CONFIG_UPDATE_START';
    }
  | {
      type: 'SIGNING_KEY_CONFIG_UPDATE_SUCCESS';
      payload: ISigningKey;
    }
  | {
      type: 'SIGNING_KEY_CONFIG_UPDATE_FAILURE';
      message: string;
    }
  | {
      type: 'ENVIRONMENT_SECRETS_FETCH_START';
    }
  | {
      type: 'ENVIRONMENT_SECRETS_FETCH_SUCCESS';
      payload: ISecret[];
    }
  | {
      type: 'ENVIRONMENT_SECRETS_FETCH_FAILURE';
      message?: string;
    }
  | {
      type: 'ENVIRONMENT_SECRETS_CREATION_START';
    }
  | {
      type: 'ENVIRONMENT_SECRETS_CREATION_SUCCESS';
      payload: ISecret;
    }
  | {
      type: 'ENVIRONMENT_SECRETS_CREATION_FAILURE';
      message?: string;
    }
  | {
      type: 'ENVIRONMENT_SECRETS_DELETION_START';
    }
  | {
      type: 'ENVIRONMENT_SECRETS_DELETION_SUCCESS';
      id: string;
    }
  | {
      type: 'ENVIRONMENT_SECRETS_DELETION_FAILURE';
      message?: string;
    }
  | {
      type: 'ENVIRONMENT_SECRETS_REMOVE_ERROR_MESSAGE';
    }
  | {
      type: 'TEAM_ENTITY_GET_START';
    }
  | {
      type: 'TEAM_ENTITY_GET_SUCCESS';
      teams: ITeam[];
    }
  | {
      type: 'TEAM_ENTITY_GET_FAILURE';
      message?: string;
    }
  | {
      type: 'TEAM_ENTITY_UPDATE_START';
    }
  | {
      type: 'TEAM_ENTITY_UPDATE_SUCCESS';
      team: ITeam;
    }
  | {
      type: 'TEAM_ENTITY_UPDATE_FAILURE';
      message?: string;
    }
  | {
      type: 'TEAM_ENTITY_CREATE_START';
    }
  | {
      type: 'TEAM_ENTITY_CREATE_SUCCESS';
      team: ITeam;
    }
  | {
      type: 'TEAM_ENTITY_CREATE_FAILURE';
      message?: string;
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_GET_START';
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_GET_SUCCESS';
      teamMembers: ITeamMemberExpanded[];
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_GET_FAILURE';
      message?: string;
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_UPDATE_START';
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_UPDATE_SUCCESS';
      teamMember: ITeamMemberExpanded;
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_UPDATE_FAILURE';
      message?: string;
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_REMOVE_START';
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_REMOVE_SUCCESS';
      teamMembers: ITeamMemberExpanded[];
    }
  | {
      type: 'TEAM_MEMBER_ENTITY_REMOVE_FAILURE';
      message?: string;
    }
  | {
      type: 'INVITE_ENTITY_GET_START';
    }
  | {
      type: 'INVITE_ENTITY_GET_SUCCESS';
      invites: IInvite[];
    }
  | {
      type: 'INVITE_ENTITY_GET_FAILURE';
      message?: string;
    }
  | {
      type: 'INVITE_ENTITY_REMOVE_START';
    }
  | {
      type: 'INVITE_ENTITY_REMOVE_SUCCESS';
      invites: IInvite[];
    }
  | {
      type: 'INVITE_ENTITY_REMOVE_FAILURE';
      message?: string;
    }
  | {
      type: 'INVITE_ENTITY_REMOVE_ERROR_MESSAGE';
    }
  | {
      type: 'GET_ACCOUNT_USAGE_START';
    }
  | {
      type: 'GET_ACCOUNT_USAGE_FAILURE';
      message?: string;
    }
  | {
      type: 'GET_ACCOUNT_USAGE_SUCCESS';
      usage: AccountUsageData;
    }
  | {
      type: 'LEAD_QUALIFICATION_SUBMIT_START';
    }
  | {
      type: 'LEAD_QUALIFICATION_SUBMIT_SUCCESS';
      updatedOrganization: IOrganization;
      updatedUser: ILoggedInUser;
      updatedProjects: IProject[];
    }
  | {
      type: 'LEAD_QUALIFICATION_SUBMIT_FAILURE';
      message?: string;
    }
  | {
      type: 'START_TUTORIAL_REQUEST_START';
    }
  | {
      type: 'START_TUTORIAL_REQUEST_SUCCESS';
    }
  | {
      type: 'START_TUTORIAL_REQUEST_FAILURE';
      message?: string;
    }
  | {
      type: 'SKIP_TUTORIAL_REQUEST_START';
    }
  | {
      type: 'SKIP_TUTORIAL_REQUEST_SUCCESS';
    }
  | {
      type: 'SKIP_TUTORIAL_REQUEST_FAILURE';
      message?: string;
    }
  | {
      type: 'COMPLETE_TUTORIAL_REQUEST_START';
    }
  | {
      type: 'COMPLETE_TUTORIAL_REQUEST_SUCCESS';
      updatedWorkflow: WorkFlow;
    }
  | {
      type: 'COMPLETE_TUTORIAL_REQUEST_FAILURE';
      message?: string;
    }
  | {
      type: 'SET_CONFIRMATION_OPTIONS';
      key: string;
      payload: ConfirmationOptions;
    }
  | {
      type: 'SET_CONFIRMATION_RESULT';
      key: string;
      result: boolean | undefined;
    }
  | {
      type: 'FETCH_ACTION_TRIGGER_INPUTS_START';
    }
  | {
      type: 'FETCH_ACTION_TRIGGER_INPUTS_SUCCESS';
      workflowId: string;
      data: ActionTriggerOutput[];
    }
  | {
      type: 'FETCH_ACTION_TRIGGER_INPUTS_FAILURE';
      message?: string;
    }
  | {
      type: 'REMOVE_CONFIRMATION_KEY';
      key: string;
    }
  | {
      type: 'SET_CONFIRMATION_ACTIVE_KEY';
      key?: string;
    }
  | {
      type: 'FETCH_SUPPORTED_ACTIONS';
      actions: string[];
    }
  | {
      type: 'SHOW_FEATURE_RESTRICTION';
      restriction: WorkflowFeatureRestriction;
    }
  | {
      type: 'DISMISS_FEATURE_RESTRICTION';
      restriction: WorkflowFeatureRestriction;
    }
  | {
      type: 'RESET_WORKFLOW_EDITOR';
    }
  | {
      type: 'SET_REQUEST_RETRY_ON_FAILURE';
      stepId: string;
      payload: boolean;
    }
  | {
      type: 'SET_FUNCTION_RETRY_ON_FAILURE';
      stepId: string;
      payload: boolean;
    }
  | {
      type: 'SET_ACTION_RETRY_ON_FAILURE';
      stepId: string;
      payload: boolean;
    }
  | {
      type: 'SET_STATE_MACHINE';
      stateMachine: StateMachine;
    }
  | {
      type: 'OPEN_VERSION_HISTORY_SIDEBAR';
    }
  | {
      type: 'SET_WORKFLOW_DESCRIPTION_EDITABLE';
      payload: boolean;
    }
  | {
      type: 'VERSION_ENTITY_FETCH_START';
    }
  | {
      type: 'VERSION_ENTITY_FETCH_SUCCESS';
      versions: WorkflowVersion[];
      pageCount: number;
      replace?: boolean;
    }
  | {
      type: 'VERSION_ENTITY_EXTRAS_FETCH';
      extras: WorkflowVersion[];
    }
  | {
      type: 'VERSION_ENTITY_FETCH_FAILED';
      message?: string;
    }
  | {
      type: 'VERSION_ENTITY_UPDATE_NAME';
      name: string;
      id: string;
    }
  | {
      type: 'VERSION_ENTITY_UPDATE_VERSION';
      version: WorkflowVersion;
    }
  | {
      type: 'TOGGLE_WORKFLOW_EDITOR_EDITABLE_MODE';
      editable: boolean;
    }
  | {
      type: 'TOGGLE_SHOW_WORKFLOW_SAVED_TOAST';
      payload: boolean;
    }
  | {
      type: 'INCREMENT_AUTO_SAVE_COUNT';
    }
  | {
      type: 'RESET_AUTO_SAVE_COUNT';
    }
  | {
      type: 'CLEAR_WORKFLOW_EXECUTION';
    }
  | {
      type: 'INTEGRATION_ENTITY_FETCH_START';
    }
  | {
      type: 'INTEGRATION_ENTITY_FETCH_SUCCESS';
      integrations: IntegrationEntity[];
    }
  | {
      type: 'INTEGRATION_ENTITY_FETCH_FAILED';
      message?: string;
    }
  | {
      type: 'INTEGRATION_ENTITY_CREATE_START';
    }
  | {
      type: 'INTEGRATION_ENTITY_CREATE_SUCCESS';
      integrationEntity: IntegrationEntity;
    }
  | {
      type: 'INTEGRATION_ENTITY_CREATE_FAILED';
      message?: string;
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_FETCH_START';
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_FETCH_SUCCESS';
      integrationConfigs: IntegrationConfigEntity[];
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_FETCH_FAILED';
      message?: string;
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_CREATE_START';
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_CREATE_SUCCESS';
      integrationConfigEntity: IntegrationConfigEntity;
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_CREATE_FAILED';
      message?: string;
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_UPDATE_START';
      message?: string;
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_UPDATE_SUCCESS';
      integrationConfigEntity: IntegrationConfigEntity;
    }
  | {
      type: 'INTEGRATION_CONFIG_ENTITY_UPDATE_FAILED';
      message?: string;
    }
  | {
      type: 'SET_PORTAL_ACCENT_COLOR';
      configId: string;
      accentColor: string;
    }
  | {
      type: 'SET_PORTAL_DESCRIPTION';
      configId: string;
      description: string;
    }
  | {
      type: 'SET_PORTAL_OVERVIEW';
      configId: string;
      overview: string;
    }
  | {
      type: 'SET_PORTAL_PARAGON_LINK';
      configId: string;
      paragonLink: boolean;
    }
  | {
      type: 'SET_PORTAL_WORKFLOW_DESCRIPTION';
      configId: string;
      workflowId: string;
      description: string;
    }
  | {
      type: 'DISABLE_PORTAL_WORKfLOW';
      configId: string;
      workflowId: string;
    }
  | {
      type: 'SET_PORTAL_WORKFLOW_DEFAULT_ENABLED';
      configId: string;
      workflowId: string;
      defaultEnabled: boolean;
    }
  | {
      type: 'SET_PORTAL_WORKFLOW_VISIBILITY';
      configId: string;
      workflowId: string;
      hidden: boolean;
      // Adding this with `Hidden` setting, to keep the backward compatible with PARA-5165
      defaultEnabled?: boolean;
    }
  | {
      type: 'REORDER_PORTAL_WORKFLOW_ITEMS';
      workflowState: Record<string, WorkflowEntity>;
      configId: string;
      sourceIndex: number;
      destinationIndex: number;
    }
  | {
      type: 'ADD_PORTAL_WORKFLOW_SETTING';
      configId: string;
      workflowId: string;
      setting: SerializedConnectInput;
      defaultEnabled?: boolean;
    }
  | {
      type: 'UPDATE_PORTAL_WORKFLOW_SETTING';
      configId: string;
      workflowId: string;
      setting: SerializedConnectInput;
      defaultEnabled?: boolean;
    }
  | {
      type: 'ADD_PORTAL_SHARED_SETTING';
      configId: string;
      setting: SerializedConnectInput;
    }
  | {
      type: 'UPDATE_PORTAL_SHARED_SETTING';
      configId: string;
      setting: SerializedConnectInput;
    }
  | {
      type: 'REORDER_PORTAL_WORKFLOW_SETTINGS';
      configId: string;
      workflowId: string;
      sourceIndex: number;
      destinationIndex: number;
    }
  | {
      type: 'REORDER_PORTAL_SHARED_SETTINGS';
      configId: string;
      sourceIndex: number;
      destinationIndex: number;
    }
  | {
      type: 'DELETE_PORTAL_WORKFLOW_SETTING';
      configId: string;
      workflowId: string;
      settingId: string;
    }
  | {
      type: 'DELETE_PORTAL_SHARED_SETTING';
      configId: string;
      settingId: string;
    }
  | {
      type: 'CLEAR_ENTITY_ERROR_MESSAGE';
    }
  | { type: 'ADD_TAG_TO_WORKFLOW_SUCCESS'; payload: WorkFlow }
  | { type: 'REMOVE_TAG_FROM_WORKFLOW_SUCCESS'; payload: { workflowId: string; tagId: string } }
  | { type: 'CLEAR_ENTITY_ERROR_MESSAGE' }
  | { type: 'CLEAR_ENTITY_ERROR_MESSAGE' }
  | { type: 'INTEGRATION_ENTITY_DELETE_START' }
  | { type: 'INTEGRATION_ENTITY_DELETE_SUCCESS'; id: string }
  | { type: 'INTEGRATION_ENTITY_DELETE_FAILED'; message?: string }
  | {
      type: 'CONNECT_CREDENTIAL_ENTITY_FETCH_START';
    }
  | {
      type: 'CONNECT_CREDENTIAL_ENTITY_FETCH_SUCCESS';
      connectCredentials: IConnectCredentialWithPersona[];
    }
  | {
      type: 'CONNECT_CREDENTIAL_ENTITY_FETCH_FAILURE';
      message?: string;
    }
  | { type: 'INTEGRATION_ENTITY_UPDATE_START' }
  | { type: 'INTEGRATION_ENTITY_UPDATE_SUCCESS'; integrationEntity: IntegrationEntity }
  | { type: 'INTEGRATION_ENTITY_UPDATE_FAILED'; message?: string }
  | { type: 'CONNECT_BILLING_PLANS_FETCH_START' }
  | { type: 'CONNECT_BILLING_PLANS_FETCH_SUCCESS'; payload: any }
  | { type: 'CONNECT_BILLING_PLANS_FETCH_FAILURE'; message: string }
  | { type: 'ACTIVE_INTEGRATIONS_COUNT_FETCH_START' }
  | { type: 'ACTIVE_INTEGRATIONS_COUNT_FETCH_SUCCESS'; payload: number }
  | { type: 'ACTIVE_INTEGRATIONS_COUNT_FETCH_FAILURE'; message?: string }
  | { type: 'CONNECT_BILLING_SUBSCRIPTION_FETCH_START' }
  | { type: 'CONNECT_BILLING_SUBSCRIPTION_FETCH_SUCCESS'; payload: any }
  | { type: 'CONNECT_BILLING_SUBSCRIPTION_FETCH_FAILURE'; message: string }
  | { type: 'RESET_WORKFLOW_LOGS' }
  | {
      type: 'PROJECT_SWITCH_BUTTON_CLICKED';
      projectId?: string;
    }
  | { type: 'SET_STEP_VALIDATION_ERRORS'; payload: { id: string; error: string }[] }
  | {
      type: 'REMOVE_STEP_VALIDATION_ERROR';
      payload: { id: string };
    }
  | {
      type: 'UPDATE_STEP_VALIDATION_ERROR';
      payload: { id: string; error: string };
    }
  | {
      type: 'GET_ALL_VARIABLES_START';
    }
  | { type: 'GET_ALL_VARIABLES_COMPLETE'; payload: { variables: CompletedExecution[] } }
  | { type: 'GET_ALL_VARIABLES_ERROR'; payload: { message: string } }
  | {
      type: 'RESET_CONNECT_WORKFLOWS';
    }
  | {
      type: 'SET_EDITING_EVENT_ID';
      eventId?: string;
    }
  | { type: 'ORGANIZATION_ENTITY_GET_START' }
  | { type: 'ORGANIZATION_ENTITY_UPDATE_START' }
  | { type: 'ORGANIZATION_ENTITY_UPDATE_FAILURE'; message: string }
  | { type: 'ORGANIZATION_ENTITY_GET_FAILURE'; message: string }
  | { type: 'ORGANIZATION_ENTITY_GET_SUCCESS'; organizations: IOrganization[] }
  | { type: 'ORGANIZATION_ENTITY_UPDATE_SUCCESS'; organization: IOrganization }
  | IntegrationWizardAction
  | { type: 'SET_CLONEABLE_WORKFLOWS'; payload: ICloneableWorkflow[] }
  | {
      type: 'CLONE_WORKFLOW_START';
    }
  | {
      type: 'CLONE_WORKFLOW_SUCCESS';
      payload: WorkFlow;
    }
  | {
      type: 'CLONE_WORKFLOW_FAILED';
      message: string;
    }
  | { type: 'PREVIEW_PERSONA_FETCH_START' }
  | {
      type: 'PREVIEW_PERSONA_FETCH_SUCCESS';
      persona: IPersona;
    }
  | { type: 'PREVIEW_PERSONA_FETCH_FAILED'; message?: string }
  | { type: 'PERSONA_ENTITY_RESET' }
  | { type: 'PERSONA_ENTITY_FETCH_START' }
  | {
      type: 'PERSONA_ENTITY_FETCH_SUCCESS';
      payload: { personas: PersonaEntity[]; total?: number };
    }
  | {
      type: 'SET_PERSONA_ENTITY_USAGE';
      payload: { connectedCount: number; allowedLimit?: number };
    }
  | { type: 'PERSONA_ENTITY_FETCH_FAILED'; message?: string }
  | { type: 'PERSONA_ENTITY_UPDATE_START' }
  | {
      type: 'PERSONA_ENTITY_UPDATE_SUCCESS';
      personaEntity: PersonaEntity;
      isPreviewPersona: boolean;
    }
  | { type: 'PERSONA_ENTITY_UPDATE_FAILED'; message?: string }
  | { type: 'PERSONA_ENTITY_DISABLE_START' }
  | {
      type: 'PERSONA_ENTITY_DISABLE_SUCCESS';
      personaEntity: PersonaEntity;
    }
  | { type: 'PERSONA_ENTITY_DISABLE_FAILED'; message?: string }
  | { type: 'PERSONA_ENTITY_ENABLE_START' }
  | {
      type: 'PERSONA_ENTITY_ENABLE_SUCCESS';
      personaEntity: PersonaEntity;
    }
  | { type: 'PERSONA_ENTITY_ENABLE_FAILED'; message?: string }
  | { type: 'PERSONA_WORKFLOW_LOG_FETCH_START' }
  | {
      type: 'PERSONA_WORKFLOW_LOG_FETCH_SUCCESS';
      payload: { workflowLogs: WorkflowLogEntity[]; endUserId: string };
    }
  | { type: 'PERSONA_WORKFLOW_LOG_FETCH_FAILED'; message?: string }
  | { type: 'PERSONA_INTEGRATIONS_FETCH_START' }
  | {
      type: 'PERSONA_INTEGRATIONS_FETCH_SUCCESS';
      payload: { connectCredentials: IConnectCredential[]; personaId: string };
    }
  | { type: 'PERSONA_INTEGRATIONS_FETCH_FAILED'; message?: string }
  | { type: 'PERSONA_WORKFLOW_DISABLE_START' }
  | {
      type: 'PERSONA_WORKFLOW_DISABLE_SUCCESS';
      payload: { connectCredential: IConnectCredential; personaId: string };
    }
  | { type: 'PERSONA_WORKFLOW_DISABLE_FAILED'; message?: string }
  | { type: 'PERSONA_INTEGRATION_UNINSTALL_START' }
  | {
      type: 'PERSONA_INTEGRATION_UNINSTALL_SUCCESS';
      payload: { connectCredentialId: string; personaId: string };
    }
  | { type: 'PERSONA_INTEGRATION_UNINSTALL_FAILED'; message?: string }
  | { type: 'PERSONA_ENTITY_DELETE_START' }
  | {
      type: 'PERSONA_ENTITY_DELETE_SUCCESS';
      personaId: string;
    }
  | { type: 'PERSONA_ENTITY_DELETE_FAILED'; message?: string }
  | EventDestinationAction;

// ----------------------------- Actions -----------------------------

// https://app.gethyperdoc.com/t/ggX26ODro8oRVAOUnaZ3
export const useSelector: TypedUseSelectorHook<State> = useReduxSelector;

// https://app.gethyperdoc.com/t/kcpLtsvaAu8Pm3I83B3o
export type Dispatch = ThunkDispatch<State, any, Action>;
export function useDispatch(): Dispatch {
  return useReduxDispatch();
}
