export default `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="494px" height="386px" viewBox="0 0 494 386" enable-background="new 0 0 494 386" xml:space="preserve">  <image id="image0" width="494" height="386" x="0" y="0"
href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAe4AAAGCBAMAAADUDz1EAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAGFBMVEVncmYA3AAA3ABsb2ts
b2sA3ABsb2v///9GALn0AAAABXRSTlMAPqk5qNK/SawAAAABYktHRAcWYYjrAAAAB3RJTUUH5gUK
ESUii+NlbwAAEvNJREFUeNrtnc1v6jAMwGEFzmVS7x0SdyQQ50kPcQYk7kib+P//hDc+2ib+it2W
Fbr48vQgjv2znbRrkzAYyDKcvs9+ZDodGGU6nd0UU5vaxdyH2Row+2G0CjuZzY+lHGZqb4bvs6Mj
sw+lF46a3hitb3EX9jI/QplpAIYzpHc8KNCBvYPR7+E7sqqNd4BaRU5R35wI6M0FjZknZE+0vwGj
Gi905O+s3vGQWvVKDVAJhL9ZHaMW78UgCuGSFRmDhdchbsmuIeUyNh/EkN5xbzV4UHHL4VaDB91n
wMN6VIn+yFsoUjJ3oMq04Ar3j8e8ph6VcdHvPMgdwlaCv4V6YfrKauqFNNMQdxCbzBIKvs59BKDK
NulDIND7ALfKcBrkVkSPAlBj4yEespiL3Lo8HQYBydT+e+DKwXGVvdHiQeQORQ372qjKr5I2VFOq
5gK3ts4C9y/K6EEHbGpewjONGZZbH/C9hG0pV6czhe+epMZI5yy3wbKUcI0Tvkf2Kj96sVdF+sBx
WywLCbem+z5szNE6Noi0x51Z1PiE13Air5FuZ3atoetw27TZhNdw4lBneDsujBtx2wyz13C7/zXH
d1Vz80bcRu2c4bY7cagbsLxBmZfcNa8+zWe1+u7va5t0uOdWRXpmy2o7YFetXSiOsj3adKFz4ZvN
ZvNAP3YXbqGvhV1wj2lvr4/Oh1Pq8SZZ6KTv5fPf6TvuSLpvKp79U3plyLhwXZ+aMrE+Mg+f7l9V
pUw8Vz5S3MRY8x9ho46csgEE3jNXyoHjjh3eh+IPe/aJ9IHNk/9MANulCn0c6AUReNfDjA76XQ+n
Zs8Nb1eZeYJ04PKE7JJWgSAnqOC4nuSMiQ9ZzfGdwNoH9CpuHLQU2yU1fYEmKGy3eEAfhRv0H7pD
0skQNgN+s4w+phx+I6zKnrF3s++0maGETVRlSgXjENYrWqlqGFUFjs0w5AFoeSAt8I81iHLCTIR2
hhrdTI/hh6nG7i4U2HzAy5BsMGQcZxK3I2ZS6jk3UenkH0O5yu4+5NdAkjeyHjI5WsD/PXZd+zLl
2k6li+2ir/3gi0+jLmOcIBzKWiC7e3XKSO6hThfFLSWypSzzK7j6Qy5v6OkwmzIYnmvDN53uReYi
mN97OmhfQtxctaCZ+4CDIVWa13T3+9xz6DzASXWKN+65UhfGDQXo8dwgRUQB6BSvLfVl7sfoIHf+
CO6xzM2XKpzRD6j2d+qAd8ANr5TA95zXxNyGuw3QGJJlho5a4U4BtxDqud/yQNSOKHruwPX7EdyC
pu/bhTuDH4jixi0X+w6+L26B+01tEWT3YM2S2zqX+06fiTt0y5MHLLtsO5n7AYUuc+/1mujSH0rS
m2AHXixCMfxNbvQ3sn5KRPoh7vYrXebeNeAOmhbGE/0k6Em59VPDTeYWbvNi3kbceUfc1LuLQ6vk
9bnR3fjY+394Ds6EQTE/ElJ/1X6Y23fexJ15/2/G7fflSJ3dJb/KvTNxp7JbmF69TeTx3H5thrld
UxBC806zUdk/Kbfypax2rhtOoYDXdF1xI0OZDjxAPpzOZqpuLNw+56HeshjOkH7RBf9WZKhjbsrd
AJswpNdlwMk33UrutDvuzKCtfKXzEtym1UUN1Rtx11v+xTpumy7SZtjPxG1yHYxxsy9PxG1LuHdb
bBvbz8Ztq1W3h8xs/5m4be4f6gbs+bht5Zozjr0et216KhNeZ33tc3EbNsA5fcwtSs/JbQKvvTK4
Q27WkOkm+6qR9YLbMrnltcv8CbkN4PvaZd4Zt2RnMNWSXxqPlW1b427099hAFuYcE+R93TLv6rlD
+CXDVFjyX8puUHe3xNNyX9FDz432dYd3V8/X1CsaLg8K51I3Y7XN2tzQZgNu44v9yzlZZD/0SukZ
JY/ipq0xUuOV35Ca61K8nJB5r9Lhe4PGgu/lEDf7NqnD92PNBU1iOXCKr6OX5sbrYIbAJzV3g/UO
fsgesdgOCyjrXWBde2+4gfs+t2E11m+ub2lDgBN7z4e8NrdpHZdlM0xr4hf63qu59CHcaN2ecf1a
O5Lx3GLFtblO0/8gfylu/eK7sd/yEN4N9wjxvfC4LYcBGdaf+5HG3M0n9DfF7SvgzrQOgKxB7pzX
9CnxOuzmE9tcMUcI3DsLNxikvC6xmxn11UzerIvgftxV5zuDviIa3ivYEnzUtNDnR8XkCLjHWvug
WtEfNNqAEfuJQncuQ/nrN00fcC5VcwPvEXeuVCT2jwWSNQwMhLmmaOB0NAYeKfUwN2cXvXwj9gvK
Pme6C40cPLh1y+U+6vV+uDP0iZANwK3ULS1L3xcG5LMIgRv+bZegiT0dg0/opNCnIbypdG8dzI/K
U+dM5ar9uwSmLVVu7UDpJvd/C5azQEYcA0J0oBsD5d+hBCT6aK/Ro/f787l6D3im8x9fS5XPHTLM
jSsY3y1Sr+row+4Yl98re5pEMr0Qp4cMNXr0th2MlAfDVXCPFUFzsbWnr5GTG47+fqC7zcZpS8kX
a8AqfQw+HUgK/J3vmnOMACds5bpt+4T/KZ3Mj4BawU0FDYL7aSITTq3MgU/CKS+IrAXC7miOKarK
KvcanjufCR4gBq1SGaFNuNsq6BfDlAPwlx/ol+kpuwzqZnXKvpJkz+M6Vi9rqIPniISzC7EOl91E
U+70uKsLY0pretuD9PMP91Ixld6kiu9h75cswWVGGyd8fqwn+0G9xWvHJisGah/OuFenOyT5oP77
71quO9x11oaGJ2pDR/VVm50jatdsK+HsFUXJ3ezc2Myu2tIAz+vav3M3Oye49nG1jROeNtUdN+Fu
5cDcrIYD+yazQ1pbtfYyaPJHY2o4kDcZJGlt1epPTqM2kW7jAmzf/rg+d50xUnHbtJl3IsbgNT21
Pq1dK4d6PnNPJqze1zQPuDUpY3/XwZTwnOG21lxu1ITr1/Qx43/HwzAdC8/O9J0A6xrnD/B9qDpm
wu+26ItUfB2icL8Ur2wUCc8Z7rBR6Xd61EWaStyGnQagbIKKaFFKqvV8L/8Ol85l+69ZchjGuSFn
uUPDK5W5VS4HX5hqwVHZBPT2A55btpkPZG7NEFes9NKB51hPNpwK3KLuHgfVXGuqBW4acGq0iNbz
gcQt6N6e6vouoaIN3Gpqfzs1C2HnVrUPAs5zhvU8JQYRHqwiuH45oxw/Nnws+AeV1FRj8t7I/5CY
pISRYlloL9W6UDXvomWRm9QtTfkf7yweG/cXcBvI5F+nprRK52Vu6rcfiiaq03HJoKt+uBv0QzGE
oodPcNEfZgV0HVu6U4GxbetPiBcd+Y/8dYdSeZtQDrZ4O6f9eJtUtKcCN7ENepoW+3Cm+l4KpY8a
4b6pgiKx/PZCA9tPJ5k8M/RW/io3mK66due3xLgoszcy/qPc4Ebid7ZNdS/w3nvXtUO/JI8/qfs5
Bd4vp1079Dvy+APKOxV2SwBMd8+m8yy0vrSf0/mQOxgNYvdsOs+O2pMPc2PPTy1DZsbKEHa/prWM
ZiKwezWtlaPYf45FYPdrWqsAnSct9CPSvGtfWxR30i4erE3Va2tfV0A9Sz+C3afhbVlY06fhnRm4
866dbU9M66i6drZFsaS7R2VuSnfetbftiSXdPZrNTenede1te2JJd49uWkzp7tGs9kfT/VcvYqYl
sWnXvrYp+sXfLf/cYdeirfQeXbtvoqz0tGs/2xbdpaxnVX4RzRDv1VxeSHgLl3Zp7YtJcG7rJ7Zm
y0FPRQTPu/bugfL+F7Mtgfd0SquEfj/Sw+s2An//e8m+k/svh1r8Ee5nl+H7/R3RoenPT7+gDP9O
nqNEiRIlSpQoUaJEiRIlSpQoUaJEiRIlSpQoUaJEiRIlSpQoUaJEiRIlSpQoUaJEiRIlSpQoUaJE
iRIlSpQoUaJEiRIlSpQoUaL0TFabzeZf1050INvz+fzdtRORO3JH7sgduSN35I7ckfvR3EnXHKIs
HsOdrLbnRcM+HibJanM+PYR79NPF03InF+cid+SO3JE7ckfuyB25I3fkfjKJ3JE7ckfuyB25I3fk
jtyR+xklctfiTpbLZVPuny6W6vAkhsZS2ybcy835KhsaXcO93GzP907+hXEKg5xFl2t1b/tN96vn
TsDKrmXh8aWl1/dlDdiPXL/fuAI9SJwufiSwbmzltv5eSE2TFdvv6O7LtRPXuU+Ge+R94vfs59Wn
ceQTgMDvJZhkK3cmdez2O+GcO2m4kRcuuJJ7hRvw4KNgFKWOnX4bcRNeOI113CuqBTeljILdVbKm
+i3Bm3CTLlejSMWd0E3oMU43pqtjRbdtgXtEK5ZeqLi5RhRLwjT+0laGY7sW91nq+SuE5HJXnVTT
q2Oer9zvDd0hFaKN23bRkNupuc2/nzuD8gpcNtdw39t8l3VdXKWIEe7E6HrdTlbIJA7R/bq9LMv+
qxl3FdDyhqDousBSXL9HoEScTOEclpSVPjRZdlI6hyvgpl3r+n0mnSisgeEm3a+tKYUt9WGVbm/K
W9EJX3uM/ocnFKDTAAnHvaKcKFzTc5NOJ3TtbimLBcyC6AFZ3eKOjdw0duHFAivQ3CO6oqleipii
CxxVHWux+j/hR3puZljdez5hBZp7TU9KCdU5kSzHH5xE6h4AD6ta3J90exB8gXsr9eJ7k5CVWwXE
/WLChIgYQXW4CYdv3xIKJHdCzAaV41Tp8ncoru9b1j00gmpwk7eSBEqAm0XxzeKs+ia/hA+EGNm5
yX7v/i2QAsk9YowS0RNY7kms/j9h032/gMAYWbi5PxXXyCbPvWa/QdGbsOnG322Z0V2adL4zc9Ph
vDtxQgo8N++eqyOxgOmfuqaAGNXnZqqcqt2RmFWaBVWNGGsfdCSUBvrSyM0/EMFzFc/NB3ACMEUW
cPFcC6WBAmjkPg0GUsffSIHlpnsaAe6JyOKTbkUHwbdG7s/BQOpYyX35O1Lgdr5aiyz+ZU8sjVtP
VZF1wn15Ek73BLm3otGRO1mxN0PPxM1JQlTjWW5dfD0Sp104YtrjRhen2twgK8JrG7e0JwyJ682r
cAdy6HGvZf9einsSsOkO/61sLnkl7nWgC5dbngrgVNEe96QNbnBLsw1xf2/+LR0Q6Q3u+dt95foC
3EJrp3f2b1ui7dNzh3IIrZ10bZ+dO5hDg3t95Q5N/a/Eband0NT/ctyfOsU+cVtqNzT1R+7+cRsW
Gj4B93LlvI31uC1j9sW4y6V4jvSf21uK14zbcGvXNXdwuU4/uekFV2e4QEgJ8jLcLHa/uXnsXnMn
57/JXU1p35tycXyyXPace11Qw1Xx/eYuqhy/Zuw39xoMZImb7uE1uVnsfnMTS89I7nXPuG+TOflV
n7mlNUqYWzCJYvnc3NLijT5zr4Wa9Lgn/eLeCj01417o2nbELZQ5wX0aqOT5ucU3lx536LW/K5a5
oBtukabH3GL1Ym7lRdkyJrrhFjPjcQff5UPzytrokHvB9IK5lRcny5johnur5g4sQcTu6W9Uu+Lm
evG5twGbzuKNYG34R1R3xC0uGwbrmU4DVhLnxINQbYy8Ew864D4buYVBe92j/K/yOORftVPzubkn
gUHrTmbbQG34l5Hn5vYWHHM2Tw6XUBv+dPrc3Elg0Lo5nARidH4hbrCDhPSyMCFvyfjtdZqEgoV7
Kxevi5rIMXrYemQ1t+H6jbbGSSxyjCbECOqAe6HjZjZMkyxyjMB0/2Tc8C5begQJMzyRYgS3WHNL
IR/IvRY6QrV6FpJI7hdkYoRmvQdznxjuE9kH3pO4FpIIWc7CjL6Gsykzu7bF/cU0JNNyX/DyBTth
pv8t+GrNJxzXwuO4uSHE/8V4cxyfR0DbRSwjPuG4bJirSgvcXEjZmzByh/mWTThioY9Tcb5B/WIn
2uLmbtiIAV4u/cC7aykYYgSsueIgRgAzu7bBvZUuZCiD1Wo2z78iGp90cypGaDUBtXWdmWXa4F7j
rleLAXOhdRbxfeNecOFsqU+3JHhCdUv8qTc6tcM9QZ6trh1QR5MU6Vsju2X5L3BzWDXFDOGB0xWD
z4pZXXLUBjccgRdnv6q0uM4Vq9kWa5zEIuHOGqDyTEvoT1k0/1DXsGDgh6urq21wD/zgr8o+7yTf
xbOxEuPr9tXF6aT0yD34bLkcJMtqNS+aJKpDZq7Lw5bOcmd4/Iof/FsBLdrhXpcuLEtnPZLvzWa5
2bgYt7GxWW0cU2XCkSyQF1uu6Tfp7y2WpXMtcY84VxmSBXMqEAfDX6mxfGpantrhphz+5L379L45
BWHIez5m3SsRojXVqh1uIuEL1rt/fqhOcj/ns7w2LhwiIpqfLXHjhC+4L4p5p0T0MkTFacE4QoDT
x6+sCRda4k5Y+1vum+ILcMOjQ2HaMg2BD5fIXw7n9g+dTgLndSdL6kxwby+FtwjX8875hjlfL/E3
4IinZ2vPm/baySdN26W82sLjsKtrq//N5eBt6qBvhxydrA2l6vtbjFDlXPBkcbtcN4hR/SbLy9ni
BovXnpQKyrbJ1bmFosM+y39UZRjafeAK7gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNS0xMFQx
NDozNzozNCswMzowMNUdkw0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDUtMTBUMTQ6Mzc6MzQr
MDM6MDCkQCuxAAAAAElFTkSuQmCC" />
</svg>`;
