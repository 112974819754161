import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GITHUB;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'GITHUB_TEST_CONNECTION',
  CREATE_ISSUE = 'GITHUB_CREATE_ISSUE',
  UPDATE_ISSUE = 'GITHUB_UPDATE_ISSUE',
  LOCK_ISSUE = 'GITHUB_LOCK_ISSUE',
  SEARCH_ISSUE = 'GITHUB_SEARCH_ISSUE',
  GET_ISSUE_BY_NUMBER = 'GITHUB_GET_ISSUE_BY_NUMBER',
  CREATE_RELEASE = 'GITHUB_CREATE_RELEASE',
  UPDATE_RELEASE = 'GITHUB_UPDATE_RELEASE',
  GET_RELEASE_BY_ID = 'GITHUB_GET_RELEASE_BY_ID',
  GET_RELEASE_BY_TAG_NAME = 'GITHUB_GET_RELEASE_BY_TAG_NAME',
  DELETE_RELEASE = 'GITHUB_DELETE_RELEASE',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_ISSUE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_ISSUE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LOCK_ISSUE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_ISSUE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ISSUE_BY_NUMBER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RELEASE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RELEASE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RELEASE_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RELEASE_BY_TAG_NAME;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RELEASE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ACCESS_TOKEN = 'GITHUB_ACCESS_TOKEN',
  REFRESH_TOKEN = 'GITHUB_REFRESH_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
};
