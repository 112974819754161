import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.DROPBOX;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'DROPBOX_TEST_CONNECTION',
  GET_FILE_BY_ID = 'GET_FILE_BY_ID',
  LIST_FILES = 'LIST_FILES',
  LIST_FOLDERS = 'LIST_FOLDERS',
  SAVE_FILE = 'SAVE_FILE',
}

type IntentMap =
  | OAuthIntent.EXCHANGE_AUTH_CREDENTIALS
  | OAuthIntent.REFRESH_CREDENTIAL
  | OAuthIntent.GET_AUTH_ESSENTIALS
  | ConnectIntent.GET_PROVIDER_ID
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | Intent.TEST_CONNECTION
  | Intent.GET_FILE_BY_ID
  | Intent.LIST_FILES
  | Intent.LIST_FOLDERS
  | Intent.SAVE_FILE;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  ACCESS_TOKEN = 'DROPBOX_ACCESS_TOKEN',
  REFRESH_TOKEN = 'DROPBOX_REFRESH_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
};
