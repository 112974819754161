import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { WorkflowVariables } from '@shared/types/sdk/resolvers';
import { IntegrationEnabledInput, IntegrationEnabledStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';

/**
 * a resolver for an integration enabled trigger step's variables
 *
 * @export
 * @class IntegrationEnabledStepResolver
 * @extends {StepResolver<IntegrationEnabledStep, IntegrationEnabledInput>}
 */
export class IntegrationEnabledStepResolver extends StepResolver<
  IntegrationEnabledStep,
  IntegrationEnabledInput
> {
  /**
   * resolves the input parameters for the step
   *
   * @param {ActionStepParameters} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {IntegrationEnabledInput}
   * @memberof IntegrationEnabledStepResolver
   */
  resolveInputParameters(
    _parameters: IntegrationEnabledStep['parameters'],
    _secrets: Record<string, string>,
    _cachedConnectCredential: CachedConnectCredential | null,
    _variables: WorkflowVariables,
    context: WorkflowExecutionContext,
  ): IntegrationEnabledInput {
    return context.triggerInput as IntegrationEnabledInput;
  }

  getRequiredStepIdsForVariableResolution(_: IntegrationEnabledStep['parameters']): string[] {
    return [];
  }
}
