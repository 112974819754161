// TODO: move this file to `@paragon/beethoven` as it's only used by hercules + hermes

import { Schedule, Unit } from '@shared/types/sdk/steps';
import { BillingPlan, ClassicBillingPlan, ConnectBillingPlan } from '@shared/types/sdk/stripe';

const ONE_SECOND: number = 1000;
const ONE_MINUTE: number = 60 * ONE_SECOND;
const ONE_HOUR: number = 60 * ONE_MINUTE;
const ONE_DAY: number = 24 * ONE_HOUR;
const MB_UNIT: number = 1024 * 1024;

export const UNLIMITED: string = 'UNLIMITED';

/**
 * @type ProductLimitationResult
 * @type T - type of usage want to be returned
 */
export type ProductLimitationResult<T> = {
  available: boolean;
  data: T | undefined;
};

/**
 * @summary limitation resolver ex timeoutLimitationResolver, dataSizeResolver
 * @param plan BillingPlan
 * @returns ProductLimitationResult
 * @property available -- cab be used?
 * @property usage information
 */
export type LimitationResolver<T> = (plan: BillingPlan) => ProductLimitationResult<T>;

/**
 * @summary type used to store meta information of plan to store classic limitations
 * @type PlanLimitation
 * @property timeout -- how long a step can process is allowed to execute (in milliseconds)
 * @property outputSize -- data allowed to be flow through a step (in bytes)
 * @property simpleCronTrigger -- the frequency that simple cron triggers run
 * @property versionHistory -- the number of days you can view data
 */
export type PlanLimitation = {
  timeout: number | undefined;
  outputSize: number | undefined;
  cronActionTriggerSchedule: Schedule | undefined;
  simpleCronTrigger: Unit | undefined;
  delay: number;
  fanout: number;
  deployedWorkflows: number | undefined;
  integrations: number | undefined; // integration = credential
  oauthApps: number | undefined;
  stepsPerWorkflow: number | undefined;
  versionHistory: number | undefined;

  /**
   * Max number of connected users allowed for subscription.
   *
   * _undefined_ == "UNLIMITED"
   */
  connectUsersLimitCount: number | undefined;

  // Unit is in day
  taskHistory: number | undefined;
  connectUsersLimitOnDevCredCount: number | undefined;
  teamMembers: number;
};

const freePlanLimitations: PlanLimitation = {
  timeout: 15 * ONE_SECOND,
  outputSize: MB_UNIT,
  cronActionTriggerSchedule: undefined,
  simpleCronTrigger: Unit.DAILY,
  delay: ONE_HOUR,
  fanout: 100,
  deployedWorkflows: 3,
  integrations: 3,
  oauthApps: 0,
  stepsPerWorkflow: 10,
  versionHistory: 0,
  connectUsersLimitCount: 0,
  taskHistory: 7,
  connectUsersLimitOnDevCredCount: 0,
  teamMembers: 3,
};

const starterPlanLimitations: PlanLimitation = {
  timeout: 30 * ONE_SECOND,
  outputSize: 5 * MB_UNIT,
  cronActionTriggerSchedule: { unit: Unit.MINUTES, minutes: 5 },
  simpleCronTrigger: Unit.HOURLY,
  delay: ONE_DAY,
  fanout: 1000,
  deployedWorkflows: 20,
  integrations: 20,
  oauthApps: 0,
  stepsPerWorkflow: 20,
  versionHistory: 7,
  connectUsersLimitCount: 0,
  taskHistory: 7,
  connectUsersLimitOnDevCredCount: 0,
  teamMembers: 10,
};

const businessPlanLimitations: PlanLimitation = {
  timeout: ONE_MINUTE,
  outputSize: 50 * MB_UNIT,
  cronActionTriggerSchedule: { unit: Unit.MINUTES, minutes: 1 },
  simpleCronTrigger: Unit.MINUTES,
  delay: 30 * ONE_DAY,
  fanout: 10000,
  deployedWorkflows: 50,
  integrations: 50,
  oauthApps: 3,
  stepsPerWorkflow: 50,
  versionHistory: 45,
  connectUsersLimitCount: 0,
  taskHistory: 30,
  connectUsersLimitOnDevCredCount: 0,
  teamMembers: 30,
};

const premiumPlanLimitations: PlanLimitation = {
  timeout: 5 * ONE_MINUTE,
  outputSize: 100 * MB_UNIT,
  cronActionTriggerSchedule: { unit: Unit.MINUTES, minutes: 1 },
  simpleCronTrigger: Unit.MINUTES,
  delay: 90 * ONE_DAY,
  fanout: 100000,
  deployedWorkflows: 250,
  integrations: 100,
  oauthApps: 10,
  stepsPerWorkflow: 100,
  versionHistory: 90,
  connectUsersLimitCount: 0,
  taskHistory: 90,
  connectUsersLimitOnDevCredCount: 0,
  teamMembers: 50,
};

const enterprisePlanLimitations: PlanLimitation = {
  timeout: 24 * ONE_HOUR,
  outputSize: 1024 * MB_UNIT,
  cronActionTriggerSchedule: { unit: Unit.MINUTES, minutes: 1 },
  simpleCronTrigger: Unit.SECONDS,
  delay: 366 * ONE_DAY,
  fanout: 1000000,
  deployedWorkflows: 1000,
  integrations: 1000,
  oauthApps: undefined,
  stepsPerWorkflow: 1000,
  versionHistory: undefined,
  connectUsersLimitCount: 0,
  taskHistory: undefined,
  connectUsersLimitOnDevCredCount: 0,
  teamMembers: 100,
};

const trialConnectPlanLimitations: PlanLimitation = {
  timeout: ONE_MINUTE,
  outputSize: 50 * MB_UNIT,
  cronActionTriggerSchedule: { unit: Unit.MINUTES, minutes: 1 },
  simpleCronTrigger: Unit.MINUTES,
  delay: 30 * ONE_DAY,
  fanout: 10000,
  deployedWorkflows: 50,
  integrations: undefined,
  oauthApps: undefined,
  stepsPerWorkflow: 50,
  versionHistory: 45,
  connectUsersLimitCount: 1000,
  taskHistory: 30,
  connectUsersLimitOnDevCredCount: 5,
  teamMembers: 10,
};

const basicConnectPlanLimitations: PlanLimitation = {
  timeout: ONE_MINUTE,
  outputSize: 50 * MB_UNIT,
  cronActionTriggerSchedule: { unit: Unit.MINUTES, minutes: 1 },
  simpleCronTrigger: Unit.MINUTES,
  delay: 30 * ONE_DAY,
  fanout: 10000,
  deployedWorkflows: 50,
  integrations: 3,
  oauthApps: undefined,
  stepsPerWorkflow: 50,
  versionHistory: 45,
  connectUsersLimitCount: 50,
  taskHistory: 30,
  connectUsersLimitOnDevCredCount: 5,
  teamMembers: 10,
};

const proConnectPlanLimitations: PlanLimitation = {
  timeout: ONE_MINUTE,
  outputSize: 100 * MB_UNIT,
  cronActionTriggerSchedule: { unit: Unit.MINUTES, minutes: 1 },
  simpleCronTrigger: Unit.MINUTES,
  delay: 30 * ONE_DAY,
  fanout: 100000,
  deployedWorkflows: 250,
  integrations: 3,
  oauthApps: undefined,
  stepsPerWorkflow: 100,
  versionHistory: 90,
  // NOTE: publicly, we advertise that this limitation on pro is unlimited
  // however we enforce a limit for safety reasons (not having product limitations has hurt us in the past)
  // if a pro user approaches or hits this limit, sales / support can update their Stripe subscription's metadata to overcome this limit on a per-user basis
  connectUsersLimitCount: 10000,
  taskHistory: 90,
  connectUsersLimitOnDevCredCount: 5,
  teamMembers: 50,
};

const enterpriseConnectPlanLimitations: PlanLimitation = {
  timeout: 24 * ONE_HOUR,
  outputSize: 1024 * MB_UNIT,
  cronActionTriggerSchedule: { unit: Unit.MINUTES, minutes: 1 },
  simpleCronTrigger: Unit.SECONDS,
  delay: 366 * ONE_DAY,
  fanout: 1000000,
  deployedWorkflows: 1000,
  integrations: 3,
  oauthApps: undefined,
  stepsPerWorkflow: 1000,
  versionHistory: undefined,
  connectUsersLimitCount: undefined,
  taskHistory: undefined,
  connectUsersLimitOnDevCredCount: undefined,
  teamMembers: 100,
};

/**
 * @var classicPlanLimitations to limitation meta info record
 */
export const classicPlanLimitations: Record<ClassicBillingPlan, PlanLimitation> = {
  [BillingPlan.ClassicFree]: freePlanLimitations,
  [BillingPlan.ClassicStarter]: starterPlanLimitations,
  [BillingPlan.ClassicBusiness]: businessPlanLimitations,
  [BillingPlan.ClassicPremium]: premiumPlanLimitations,
  [BillingPlan.ClassicEnterprise]: enterprisePlanLimitations,
};

/**
 * @var connectPlanLimitations to limitation meta info record
 */
export const connectPlanLimitations: Record<ConnectBillingPlan, PlanLimitation> = {
  [BillingPlan.ConnectTrial]: trialConnectPlanLimitations,
  [BillingPlan.ConnectBasic]: basicConnectPlanLimitations,
  [BillingPlan.ConnectPro]: proConnectPlanLimitations,
  [BillingPlan.ConnectEnterprise]: enterpriseConnectPlanLimitations,
};

/**
 * @var planLimitations to limitation meta info record
 */
export const planLimitations: Record<BillingPlan, PlanLimitation> = {
  ...classicPlanLimitations,
  ...connectPlanLimitations,
};

/**
 * @summary returns schedule for cron based action triggers per plan type
 * @param plan plan in stripe
 * @returns schedule used for plan
 */
export const getCronActionTriggerLimitation: LimitationResolver<Schedule | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<Schedule> => {
  return {
    available: ![BillingPlan.ClassicFree].includes(plan),
    data: planLimitations[plan]?.cronActionTriggerSchedule,
  };
};

/**
 * @summary returns schedule unit for simple cron triggers per plan type
 * @param plan plan in stripe
 * @returns schedule used for plan
 */
export const getSimpleCronTriggerLimitation: LimitationResolver<Unit | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<Unit> => {
  return {
    available: plan in planLimitations,
    data: planLimitations[plan]?.simpleCronTrigger,
  };
};

/**
 * @summary returns timeout usage per plan type
 * @param plan plan in stripe
 * @returns number timeout in millisecond
 */
export const getTimeoutLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number> => {
  const timeout = plan ? planLimitations[plan]?.timeout : undefined;

  return {
    available: true,
    data: timeout,
  };
};

/**
 * @summary returns data size limitation per plan type
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getDataSizeLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan | undefined,
): ProductLimitationResult<number> => {
  const outputSize = plan ? planLimitations[plan]?.outputSize : undefined;

  return {
    available: true,
    data: outputSize,
  };
};

/**
 * @summary returns delay limitation per plan type
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getDelayLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number> => {
  const delay = plan ? planLimitations[plan]?.delay : undefined;

  return {
    available: true,
    data: delay,
  };
};

/**
 * @summary returns fanout limitation per plan type
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getFanoutLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number | undefined> => {
  const fanout = plan ? planLimitations[plan]?.fanout : undefined;

  return {
    available: true,
    data: fanout,
  };
};

/**
 * @summary returns the number of workflows allowed to be created
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getDeployedWorkflowsLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number | undefined> => {
  const deployedWorkflows = plan ? planLimitations[plan]?.deployedWorkflows : undefined;
  return {
    available: true,
    data: deployedWorkflows,
  };
};

/**
 * @summary returns number of steps allowed per workflow
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getStepsPerWorkflowLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number | undefined> => {
  const steps = plan ? planLimitations[plan]?.stepsPerWorkflow : undefined;

  return {
    available: true,
    data: steps,
  };
};

/**
 * @summary returns number of integrations allowed
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getIntegrationsLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number | undefined> => {
  const integrations = plan ? planLimitations[plan]?.integrations : undefined;
  return {
    available: true,
    data: integrations,
  };
};

/**
 * @summary returns number of oauth apps allowed
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getOAuthAppsLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number | undefined> => {
  const apps = plan ? planLimitations[plan].oauthApps : undefined;
  return {
    available: true,
    data: apps,
  };
};

/*
 * @summary returns versionHistory limitation per plan type
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getVersionHistoryLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number | undefined> => {
  const versionHistory = plan ? planLimitations[plan]?.versionHistory : undefined;

  return {
    available: true,
    data: versionHistory,
  };
};

/*
 * @summary returns connect user limitation limitation per plan type
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getConnectUserLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number | undefined> => {
  const _plan = plan ? planLimitations[plan] : undefined;
  const connectUsersLimitCount = _plan?.connectUsersLimitCount;

  return {
    available: true,
    data: connectUsersLimitCount,
  };
};

/*
 * @summary returns taskHistory limitation per plan type
 * @param plan plan in stripe
 * @returns number data size(bytes)
 */
export const getTaskHistoryLimitation: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number | undefined> => {
  return {
    available: true,
    data: plan ? planLimitations[plan]?.taskHistory : undefined,
  };
};

/*
 * @summary returns connect user limitation per plan type when using dev credential
 * @param plan plan in stripe
 * @returns number
 */
export const getConnectUserLimitationOnDevCredential: LimitationResolver<number | undefined> = (
  plan: BillingPlan,
): ProductLimitationResult<number> => {
  const _plan = plan ? planLimitations[plan] : undefined;
  const connectUsersLimitCount = _plan?.connectUsersLimitOnDevCredCount;

  return {
    available: true,
    data: connectUsersLimitCount,
  };
};

/*
 * @summary returns connect user limitation per plan type when using dev credential
 * @param plan plan in stripe
 * @returns number
 */
export const getTeamMemberLimitation: LimitationResolver<number> = (
  plan: BillingPlan,
): ProductLimitationResult<number> => {
  const _plan = plan ? planLimitations[plan] : undefined;
  const teamMemberLimit: number = _plan?.teamMembers ?? 3;

  return {
    available: true,
    data: teamMemberLimit,
  };
};
