import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.GOOGLE_ADS,
  provider: ProviderType.GOOGLE_ADS,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Google Ads accounts',
  icon: iconSvg,
  description:
    "Connect to your users' Google Ads account to manage their ads, campaigns, and conversions.",
  sidebarSections: [],
};

export default authConfig;
