import { isSome } from 'fp-ts/lib/Option';
import { Lens, Optional, Traversal } from 'monocle-ts';
import { indexArray } from 'monocle-ts/lib/Index/Array';

import {
  ActionIntent,
  ActionStep,
  ActionStepParameters,
  Action as ActionType,
  BaseActionStepParameters,
} from '@shared/types/sdk/actions';
import {
  ConditionWrapper,
  DataType,
  JoinedConditions,
  KeyedSource,
  OperatorCondition,
} from '@shared/types/sdk/resolvers';
import {
  BodyValidation,
  CustomIntegrationRequestStep,
  DailySchedule,
  DelayStep,
  EndpointStep,
  EventStep,
  FunctionStep,
  HeaderValidation,
  HourlySchedule,
  HttpMethod,
  MapStep,
  MinutesSchedule,
  OAuthStep,
  ParamValidation,
  RedirectStep,
  RequestAuthorization,
  RequestAuthorizationType,
  ResponseStep,
  Schedule,
  SecondsSchedule,
  Step,
  StepType,
  Time,
  Unit,
  WeeklySchedule,
  RequestStep as _RequestStep,
} from '@shared/types/sdk/steps';

import { getDefaultCondition, getNextDefaultKeyName, indexBy } from '../../../utils';
import {
  DEFAULT_HOUR,
  DEFAULT_WEEKDAY,
  EMPTY_KEYED_SOURCE,
  EMPTY_TOKENIZED_SOURCE,
} from '../../../utils/constants';
import {
  actionConditionsByKey,
  actionParametersLens,
  actionTriggerConditionByKey,
  actionTriggerParameterLens,
  conditionOrLens,
  conditionalAndLens,
  conditionalAndLensByIndex,
  conditionalConditionLensByIndex,
  delayParametersLens,
  endpointParametersLens,
  functionLens,
  joinConditionsByIndex,
  joinConditionsPrism,
  mapLens,
  operatorConditionsPrism,
  parameterLens,
  parametersLens,
  redirectLens,
  requestBodyLens,
  requestBodyLensByIndex,
  requestHeaderLens,
  requestHeadersLens,
  requestLens,
  requestParamLens,
  requestParamsLens,
  responseBodyDataLens,
  responseBodyDataLensById,
  responseLens,
  scheduleLens,
  scheduleTypeLens,
  stepLens,
  stepTypePrism,
} from '../../lenses';
import { Action, EntitiesState, WorkFlow } from '../../types';

type RequestStep = CustomIntegrationRequestStep | _RequestStep;

/**
 * A step ID used for reducer actions that should not be sent back to the rest API.
 * e.g., the default `testStep` of IntegrationSetupModal
 */
export const NO_OP_ACTION_STEP_ID = '__no_op_action_step_id__';
export const NO_OP_ACTION_STEP: ActionStep = {
  id: NO_OP_ACTION_STEP_ID,
  description: '',
  workflowId: '',
  action: ActionType.NONE,
  type: StepType.ACTION,
  next: null,
  parameters: {
    actionType: ActionType.NONE,
    actionParameters: [],
    credentials: [],
    intent: null as unknown as ActionIntent,
  } as ActionStepParameters,
};

export const initialState: EntitiesState<Step> = {
  entities: {
    [NO_OP_ACTION_STEP_ID]: NO_OP_ACTION_STEP,
  },
  errorMessage: undefined,
  processing: false,
};

function createTime(timezone: string): Time {
  return { minutes: DEFAULT_HOUR * 60, timezone };
}

const SCHEDULE_CREATORS: Record<Unit, (timezone: string) => Schedule> = {
  [Unit.SECONDS]: () => ({ unit: Unit.SECONDS, seconds: 1 }),
  [Unit.MINUTES]: () => ({ unit: Unit.MINUTES, minutes: 1 }),
  [Unit.HOURLY]: () => ({ unit: Unit.HOURLY, hours: 1, offset: 0 }),
  [Unit.DAILY]: (timezone: string) => ({
    unit: Unit.DAILY,
    days: 1,
    time: createTime(timezone),
  }),
  [Unit.WEEKLY]: (timezone: string) => ({
    unit: Unit.WEEKLY,
    weekday: DEFAULT_WEEKDAY,
    time: createTime(timezone),
  }),
};

export function handler(
  state: EntitiesState<Step> = initialState,
  action: Action,
): EntitiesState<Step> {
  switch (action.type) {
    case 'WORKFLOW_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          ...indexBy(
            'id',
            action.workflows.flatMap((workflow: WorkFlow) => workflow.steps),
          ),
        },
      };

    case 'STEP_ENTITY_FETCH_START':
      return {
        ...state,
        processing: true,
      };
    case 'GET_ONE_WORKFLOW_SUCCESS':
    case 'STEP_ENTITY_CREATE_SUCCESS':
    case 'STEP_ENTITY_DELETE_REQUEST':
    case 'STEP_ENTITY_DELETE_SUCCESS':
    case 'STEP_ENTITY_REORDER_REQUEST':
    case 'STEP_ENTITY_REORDER_SUCCESS':
    case 'WORKFLOW_ENTITY_CREATION_SUCCESS':
      return {
        ...state,
        processing: true,
        entities: {
          ...state.entities,
          ...indexBy('id', action.payload.steps),
        },
      };

    case 'STEP_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          ...indexBy('id', action.payload),
        },
      };

    case 'STEP_ENTITY_FETCH_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'STEP_ENTITY_UPDATE_START':
      return {
        ...state,
        processing: true,
      };

    case 'STEP_ENTITY_UPDATE_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'SET_TRIGGER_STEP_TYPE':
      return stepLens(action.stepId).modify((step: Step) => {
        switch (action.stepType) {
          case StepType.CRON:
            return {
              ...step,
              type: StepType.CRON,
              parameters: {
                schedule: SCHEDULE_CREATORS.DAILY(action.timezone),
              },
            };
          case StepType.ENDPOINT:
            return {
              ...step,
              type: StepType.ENDPOINT,
              parameters: {
                path: action.workflowId, // TODO change back to 'custom-endpoint'
                httpMethod: HttpMethod.POST,
                paramValidations: [],
                headerValidations: [],
                bodyValidations: [],
                allowArbitraryPayload: false,
              },
            };
          case StepType.OAUTH:
            return {
              ...step,
              type: StepType.OAUTH,
              parameters: {
                actionType: ActionType.NONE,
                credentials: [],
              },
            };
          case StepType.ACTION_TRIGGER:
            return {
              ...step,
              type: StepType.ACTION_TRIGGER,
              parameters: {
                actionType: action.actionType!,
                actionParameters: [],
                credentials: [],
              } as unknown as ActionStepParameters,
            };

          case StepType.EVENT:
            return {
              ...step,
              type: StepType.EVENT,
              parameters: {
                eventId: '',
              },
            };
          case StepType.INTEGRATION_ENABLED:
            return {
              ...step,
              type: StepType.INTEGRATION_ENABLED,
              parameters: {},
            };
        }
        return step;
      })(state);

    case 'SET_STEP_DESCRIPTION':
      return stepLens(action.stepId).modify((step: Step) => ({
        ...step,
        description: action.description,
      }))(state);

    case 'SET_TRIGGER_APP_EVENT':
      return stepLens(action.stepId).modify((step: Step) => {
        return {
          ...step,
          type: StepType.EVENT,
          parameters: {
            ...step.parameters,
            eventId: action.eventId,
          },
        };
      })(state);

    case 'SET_TRIGGER_APP_EVENT_PARAMETERS':
      return stepLens(action.stepId).modify((step: Step) => {
        return {
          ...step,
          type: StepType.EVENT,
          parameters: {
            eventId: (step as EventStep).parameters.eventId,
            ...action.parameters,
          },
        };
      })(state);

    case 'SET_TRIGGER_API_ENDPOINT_OBJECT_MAPPING':
      return endpointParametersLens(action.stepId).modify(
        (parameters: EndpointStep['parameters']) => ({
          ...parameters,
          objectMapping: action.objectMapping,
        }),
      )(state);

    case 'SET_TRIGGER_SCHEDULER_UNIT':
      return scheduleLens(action.stepId).modify(() =>
        SCHEDULE_CREATORS[action.unit](action.timezone),
      )(state);

    case 'SET_TRIGGER_SCHEDULER_MINUTES':
      return scheduleTypeLens(action.stepId, Unit.MINUTES).modify((schedule: MinutesSchedule) => ({
        ...schedule,
        minutes: action.minutes,
      }))(state);

    case 'SET_TRIGGER_SCHEDULER_SECONDS':
      return scheduleTypeLens(action.stepId, Unit.SECONDS).modify((schedule: SecondsSchedule) => ({
        ...schedule,
        seconds: action.seconds,
      }))(state);

    case 'SET_TRIGGER_SCHEDULER_HOURS':
      return scheduleTypeLens(action.stepId, Unit.HOURLY).modify((schedule: HourlySchedule) => ({
        ...schedule,
        hours: action.hours,
      }))(state);

    case 'SET_TRIGGER_SCHEDULER_HOURS_OFFSET':
      return scheduleTypeLens(action.stepId, Unit.HOURLY).modify((schedule: HourlySchedule) => ({
        ...schedule,
        offset: action.offset,
      }))(state);

    case 'SET_TRIGGER_SCHEDULER_DAYS':
      return scheduleTypeLens(action.stepId, Unit.DAILY).modify((schedule: DailySchedule) => ({
        ...schedule,
        days: action.days,
      }))(state);

    case 'SET_TRIGGER_SCHEDULER_DAY_TIME':
      return scheduleTypeLens(action.stepId, Unit.DAILY)
        .composeLens(Lens.fromProp<DailySchedule>()('time'))
        .modify((time: Time) => ({
          ...time,
          minutes: action.time,
        }))(state);

    case 'SET_TRIGGER_SCHEDULER_DAY_TIMEZONE':
      return scheduleTypeLens(action.stepId, Unit.DAILY)
        .composeLens(Lens.fromProp<DailySchedule>()('time'))
        .modify((time: Time) => ({
          ...time,
          timezone: action.timezone,
        }))(state);

    case 'SET_TRIGGER_SCHEDULER_WEEKDAY':
      return scheduleTypeLens(action.stepId, Unit.WEEKLY).modify((schedule: WeeklySchedule) => ({
        ...schedule,
        weekday: action.weekday,
      }))(state);

    case 'SET_TRIGGER_SCHEDULER_WEEK_TIME':
      return scheduleTypeLens(action.stepId, Unit.WEEKLY)
        .composeLens(Lens.fromProp<WeeklySchedule>()('time'))
        .modify((time: Time) => ({
          ...time,
          minutes: action.time,
        }))(state);

    case 'SET_TRIGGER_SCHEDULER_WEEK_TIMEZONE':
      return scheduleTypeLens(action.stepId, Unit.WEEKLY)
        .composeLens(Lens.fromProp<WeeklySchedule>()('time'))
        .modify((time: Time) => ({
          ...time,
          timezone: action.timezone,
        }))(state);

    case 'SET_TRIGGER_API_ENDPOINT_PATH':
      return endpointParametersLens(action.stepId).modify(
        (parameters: EndpointStep['parameters']) => ({
          ...parameters,
          path: action.path,
        }),
      )(state);

    case 'SET_TRIGGER_API_ENDPOINT_HTTP_METHOD':
      return endpointParametersLens(action.stepId).modify(
        (parameters: EndpointStep['parameters']) => ({
          ...parameters,
          httpMethod: action.httpMethod,
        }),
      )(state);

    case 'SET_TRIGGER_API_ENDPOINT_TEST_OPTION':
      return endpointParametersLens(action.stepId).modify(
        (parameters: EndpointStep['parameters']) => ({
          ...parameters,
          allowArbitraryPayload: action.allowArbitraryPayload,
        }),
      )(state);

    case 'ADD_TRIGGER_API_ENDPOINT_PARAM_VALIDATION':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('paramValidations'))
        .modify((validations: ParamValidation[]) => [
          ...validations,
          { key: getNextDefaultKeyName(validations, 'key'), required: true },
        ])(state);

    case 'DELETE_TRIGGER_API_ENDPOINT_PARAM_VALIDATION':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('paramValidations'))
        .modify((validations: ParamValidation[]) => [
          ...validations.slice(0, action.index),
          ...validations.slice(action.index + 1),
        ])(state);

    case 'SET_TRIGGER_API_ENDPOINT_PARAM_VALIDATION_KEY':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('paramValidations'))
        .composeOptional(indexArray<ParamValidation>().index(action.index))
        .modify((validation: ParamValidation) => ({
          ...validation,
          key: action.key,
        }))(state);

    case 'SET_TRIGGER_API_ENDPOINT_PARAM_VALIDATION_REQUIRED':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('paramValidations'))
        .composeOptional(indexArray<ParamValidation>().index(action.index))
        .modify((validation: ParamValidation) => ({
          ...validation,
          required: action.required,
        }))(state);

    case 'ADD_TRIGGER_API_ENDPOINT_HEADER_VALIDATION':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('headerValidations'))
        .modify((validations: HeaderValidation[]) => [
          ...validations,
          {
            key: getNextDefaultKeyName(validations, 'Content-Type'),
            value: '',
          },
        ])(state);

    case 'DELETE_TRIGGER_API_ENDPOINT_HEADER_VALIDATION':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('headerValidations'))
        .modify((validations: HeaderValidation[]) => [
          ...validations.slice(0, action.index),
          ...validations.slice(action.index + 1),
        ])(state);

    case 'SET_TRIGGER_API_ENDPOINT_HEADER_VALIDATION_KEY':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('headerValidations'))
        .composeOptional(indexArray<HeaderValidation>().index(action.index))
        .modify((validation: HeaderValidation) => ({
          ...validation,
          key: action.key,
        }))(state);

    case 'SET_TRIGGER_API_ENDPOINT_HEADER_VALIDATION_VALUE':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('headerValidations'))
        .composeOptional(indexArray<HeaderValidation>().index(action.index))
        .modify((validation: HeaderValidation) => ({
          ...validation,
          value: action.value,
        }))(state);

    case 'ADD_TRIGGER_API_ENDPOINT_BODY_VALIDATION':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('bodyValidations'))
        .modify((validations: BodyValidation[]) => [
          ...validations,
          {
            key: getNextDefaultKeyName(validations, 'key'),
            dataType: DataType.STRING,
            required: true,
          },
        ])(state);

    case 'DELETE_TRIGGER_API_ENDPOINT_BODY_VALIDATION':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('bodyValidations'))
        .modify((validations: BodyValidation[]) => [
          ...validations.slice(0, action.index),
          ...validations.slice(action.index + 1),
        ])(state);

    case 'SET_TRIGGER_API_ENDPOINT_BODY_VALIDATION_KEY':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('bodyValidations'))
        .composeOptional(indexArray<BodyValidation>().index(action.index))
        .modify((validation: BodyValidation) => ({
          ...validation,
          key: action.key,
        }))(state);

    case 'SET_TRIGGER_API_ENDPOINT_BODY_VALIDATION_DATA_TYPE':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('bodyValidations'))
        .composeOptional(indexArray<BodyValidation>().index(action.index))
        .modify((validation: BodyValidation) => ({
          ...validation,
          dataType: action.dataType,
        }))(state);

    case 'SET_TRIGGER_API_ENDPOINT_BODY_VALIDATION_REQUIRED':
      return endpointParametersLens(action.stepId)
        .composeLens(Lens.fromProp<EndpointStep['parameters']>()('bodyValidations'))
        .composeOptional(indexArray<BodyValidation>().index(action.index))
        .modify((validation: BodyValidation) => ({
          ...validation,
          required: action.required,
        }))(state);

    case 'ADD_FUNCTION_PARAMETER':
      return parametersLens(action.stepId).modify((parameters: KeyedSource[]) => [
        ...parameters,
        {
          key: getNextDefaultKeyName(parameters, 'parameter_name'),
          source: { dataType: DataType.ANY, type: 'VALUE', value: '' },
        },
      ])(state);

    case 'DELETE_FUNCTION_PARAMETER':
      return parametersLens(action.stepId).modify((parameters: KeyedSource[]) => [
        ...parameters.slice(0, action.index),
        ...parameters.slice(action.index + 1),
      ])(state);

    case 'SET_FUNCTION_PARAMETER_KEY':
      return parameterLens(action.stepId, action.index).modify((parameter: KeyedSource) => ({
        ...parameter,
        key: action.key,
      }))(state);

    case 'SET_FUNCTION_PARAMETER_SOURCE':
      return parameterLens(action.stepId, action.index).modify((parameter: KeyedSource) => ({
        ...parameter,
        source: action.source,
      }))(state);

    case 'SET_FUNCTION_CODE':
      return functionLens(action.stepId)
        .composeLens(Lens.fromProp<FunctionStep>()('parameters'))
        .modify((parameters: FunctionStep['parameters']) => ({
          ...parameters,
          code: action.code,
        }))(state);

    case 'SET_FUNCTION_RETRY_ON_FAILURE':
      return functionLens(action.stepId)
        .composeLens(Lens.fromProp<FunctionStep>()('parameters'))
        .modify((parameters: FunctionStep['parameters']) => ({
          ...parameters,
          retryOnFailure: action.payload,
        }))(state);

    case 'SET_REQUEST_HTTP_METHOD':
      return requestLens(action.stepId)
        .composeLens(Lens.fromProp<RequestStep>()('parameters'))
        .modify((parameters: RequestStep['parameters']) => ({
          ...parameters,
          httpMethod: action.httpMethod,
        }))(state);

    case 'SET_REQUEST_IGNORE_FAILURE':
      return requestLens(action.stepId)
        .composeLens(Lens.fromProp<RequestStep>()('parameters'))
        .modify((parameters: RequestStep['parameters']) => ({
          ...parameters,
          ignoreFailure: action.payload,
        }))(state);

    case 'SET_REQUEST_RETRY_ON_FAILURE':
      return requestLens(action.stepId)
        .composeLens(Lens.fromProp<RequestStep>()('parameters'))
        .modify((parameters: RequestStep['parameters']) => ({
          ...parameters,
          retryOnFailure: action.payload,
        }))(state);

    case 'SET_REQUEST_URI':
      return requestLens(action.stepId)
        .composeLens(Lens.fromProp<RequestStep>()('parameters'))
        .modify((parameters: RequestStep['parameters']) => ({
          ...parameters,
          url: action.url,
        }))(state);

    case 'ADD_REQUEST_HEADER':
      return requestHeadersLens(action.stepId).modify((headers: KeyedSource<DataType.STRING>[]) => [
        ...headers,
        {
          key: '',
          source: {
            dataType: DataType.STRING,
            type: 'VALUE',
            value: '',
          },
        },
      ])(state);

    case 'DELETE_REQUEST_HEADER':
      const headers = requestHeadersLens(action.stepId).getOption(state);
      if (isSome(headers) && headers.value.length === 1) {
        return requestHeaderLens(action.stepId, action.index).modify(() => EMPTY_KEYED_SOURCE)(
          state,
        );
      }
      return requestHeadersLens(action.stepId).modify((headers: KeyedSource<DataType.STRING>[]) => [
        ...headers.slice(0, action.index),
        ...headers.slice(action.index + 1),
      ])(state);

    case 'SET_REQUEST_HEADER_KEY':
      return requestHeaderLens(action.stepId, action.index).modify(
        (header: KeyedSource<DataType.STRING>) => ({
          ...header,
          key: action.key,
        }),
      )(state);

    case 'SET_REQUEST_HEADER_SOURCE':
      return requestHeaderLens(action.stepId, action.index).modify(
        (header: KeyedSource<DataType.STRING>) => ({
          ...header,
          source: action.source,
        }),
      )(state);

    case 'ADD_REQUEST_PARAM':
      return requestParamsLens(action.stepId).modify((params: KeyedSource<DataType.STRING>[]) => [
        ...params,
        {
          key: '',
          source: { dataType: DataType.STRING, type: 'VALUE', value: '' },
        },
      ])(state);

    case 'DELETE_REQUEST_PARAM':
      const params = requestParamsLens(action.stepId).getOption(state);
      if (isSome(params) && params.value.length === 1) {
        return requestParamLens(action.stepId, action.index).modify(() => EMPTY_KEYED_SOURCE)(
          state,
        );
      }
      return requestParamsLens(action.stepId).modify((params: KeyedSource<DataType.STRING>[]) => [
        ...params.slice(0, action.index),
        ...params.slice(action.index + 1),
      ])(state);

    case 'SET_REQUEST_PARAM_KEY':
      return requestParamLens(action.stepId, action.index).modify(
        (param: KeyedSource<DataType.STRING>) => ({
          ...param,
          key: action.key,
        }),
      )(state);

    case 'SET_REQUEST_PARAM_SOURCE':
      return requestParamLens(action.stepId, action.index).modify(
        (param: KeyedSource<DataType.STRING>) => ({
          ...param,
          source: action.source,
        }),
      )(state);

    case 'SET_ALL_REQUEST_PARAMS':
      return requestParamsLens(action.stepId).modify(() => action.params)(state);

    case 'ADD_REQUEST_BODY':
      return requestBodyLens(action.stepId).modify((body: KeyedSource[]) => [
        ...body,
        {
          key: '',
          source: { dataType: DataType.ANY, type: 'VALUE', value: '' },
        },
      ])(state);

    case 'DELETE_REQUEST_BODY':
      const body = requestBodyLens(action.stepId).getOption(state);
      if (isSome(body) && body.value.length === 1) {
        return requestBodyLensByIndex(action.stepId, action.index).modify(() => EMPTY_KEYED_SOURCE)(
          state,
        );
      }
      return requestBodyLens(action.stepId).modify((body: KeyedSource[]) => [
        ...body.slice(0, action.index),
        ...body.slice(action.index + 1),
      ])(state);

    case 'SET_REQUEST_BODY_KEY':
      return requestBodyLensByIndex(action.stepId, action.index).modify((body: KeyedSource) => ({
        ...body,
        key: action.key,
      }))(state);

    case 'SET_REQUEST_BODY_SOURCE':
      return requestBodyLensByIndex(action.stepId, action.index).modify((body: KeyedSource) => ({
        ...body,
        source: action.source,
      }))(state);

    case 'SET_REQUEST_RAW_BODY':
      return requestLens(action.stepId)
        .composeLens(Lens.fromProp<RequestStep>()('parameters'))
        .composeLens(Lens.fromProp<RequestStep['parameters']>()('rawBody'))
        .modify(() => action.source)(state);

    case 'SET_REQUEST_BODY_TYPE':
      return requestLens(action.stepId)
        .composeLens(Lens.fromProp<RequestStep>()('parameters'))
        .composeLens(Lens.fromProp<RequestStep['parameters']>()('bodyType'))
        .modify(() => action.bodyType)(state);

    case 'SET_REQUEST_AUTHORIZATION_TYPE':
      return requestLens(action.stepId)
        .composePrism(stepTypePrism(StepType.REQUEST))
        .composeLens(Lens.fromProp<_RequestStep>()('parameters'))
        .composeLens(Lens.fromProp<_RequestStep['parameters']>()('authorization'))
        .modify((authorization: RequestAuthorization | undefined) => ({
          username: EMPTY_TOKENIZED_SOURCE,
          password: EMPTY_TOKENIZED_SOURCE,
          token: EMPTY_TOKENIZED_SOURCE,
          params: [{ key: '', source: EMPTY_TOKENIZED_SOURCE }],
          headers: [{ key: '', source: EMPTY_TOKENIZED_SOURCE }],
          ...authorization,
          type: action.authorizationType,
        }))(state);

    case 'SET_REQUEST_AUTHORIZATION_BEARER_TOKEN':
      return requestLens(action.stepId)
        .composePrism(stepTypePrism(StepType.REQUEST))
        .composeLens(Lens.fromProp<_RequestStep>()('parameters'))
        .composeLens(Lens.fromProp<_RequestStep['parameters']>()('authorization'))
        .modify((authorization: RequestAuthorization | undefined) => ({
          ...authorization,
          type: RequestAuthorizationType.BEARER_TOKEN,
          token: action.token,
        }))(state);

    case 'SET_REQUEST_AUTHORIZATION_BASIC_FIELD':
      return requestLens(action.stepId)
        .composePrism(stepTypePrism(StepType.REQUEST))
        .composeLens(Lens.fromProp<_RequestStep>()('parameters'))
        .composeLens(Lens.fromProp<_RequestStep['parameters']>()('authorization'))
        .modify((authorization: RequestAuthorization | undefined) => ({
          type: RequestAuthorizationType.BASIC,
          username: EMPTY_TOKENIZED_SOURCE,
          password: EMPTY_TOKENIZED_SOURCE,
          ...authorization,
          ...action.fields,
        }))(state);

    case 'SET_RESPONSE_STATUS_CODE':
      return responseLens(action.stepId)
        .composeLens(Lens.fromProp<ResponseStep>()('parameters'))
        .modify((parameters: ResponseStep['parameters']) => ({
          ...parameters,
          statusCode: action.statusCode,
        }))(state);

    case 'SET_RESPONSE_BODY_TYPE':
      return responseLens(action.stepId)
        .composeLens(Lens.fromProp<ResponseStep>()('parameters'))
        .modify((parameters: ResponseStep['parameters']) => ({
          ...parameters,
          responseType: action.responseType,
        }))(state);

    case 'SET_RESPONSE_BODY_FILE':
      return responseLens(action.stepId)
        .composeLens(Lens.fromProp<ResponseStep>()('parameters'))
        .modify((parameters: ResponseStep['parameters']) => ({
          ...parameters,
          bodyFile: action.bodyFile,
        }))(state);

    case 'ADD_RESPONSE_BODY_DATA':
      return responseBodyDataLens(action.stepId).modify((bodyData: KeyedSource[]) => [
        ...bodyData,
        {
          key: getNextDefaultKeyName(bodyData, 'body_key'),
          source: { dataType: DataType.ANY, type: 'VALUE', value: '' },
        },
      ])(state);

    case 'DELETE_RESPONSE_BODY_DATA':
      return responseBodyDataLens(action.stepId).modify((bodyData: KeyedSource[]) => [
        ...bodyData.slice(0, action.index),
        ...bodyData.slice(action.index + 1),
      ])(state);

    case 'SET_RESPONSE_BODY_DATA_KEY':
      return responseBodyDataLensById(action.stepId, action.index).modify(
        (bodyData: KeyedSource) => ({
          ...bodyData,
          key: action.key,
        }),
      )(state);

    case 'SET_RESPONSE_BODY_DATA_SOURCE':
      return responseBodyDataLensById(action.stepId, action.index).modify(
        (bodyData: KeyedSource) => ({
          ...bodyData,
          source: action.source,
        }),
      )(state);

    case 'SET_REDIRECT_URL':
      return redirectLens(action.stepId)
        .composeLens(Lens.fromPath<RedirectStep>()(['parameters', 'url']))
        .modify(() => action.url)(state);

    case 'ADD_REDIRECT_URL_PARAM':
      return redirectLens(action.stepId)
        .composeLens(Lens.fromPath<RedirectStep>()(['parameters', 'params']))
        .modify((params: KeyedSource<DataType.ANY>[]) => [
          ...params,
          {
            key: getNextDefaultKeyName(params, 'param'),
            source: { dataType: DataType.ANY, type: 'VALUE', value: '' },
          },
        ])(state);

    case 'DELETE_REDIRECT_URL_PARAM':
      return redirectLens(action.stepId)
        .composeLens(Lens.fromPath<RedirectStep>()(['parameters', 'params']))
        .modify((params: KeyedSource<DataType.ANY>[]) => [
          ...params.slice(0, action.index),
          ...params.slice(action.index + 1),
        ])(state);

    case 'SET_REDIRECT_URL_PARAM_KEY':
      return redirectLens(action.stepId)
        .composeLens(Lens.fromPath<RedirectStep>()(['parameters', 'params']))
        .composeOptional(indexArray<KeyedSource<DataType.ANY>>().index(action.index))
        .modify((source: KeyedSource<DataType.ANY>) => ({ ...source, key: action.key }))(state);

    case 'SET_REDIRECT_URL_PARAM_SOURCE':
      return redirectLens(action.stepId)
        .composeLens(Lens.fromPath<RedirectStep>()(['parameters', 'params']))
        .composeOptional(indexArray<KeyedSource<DataType.ANY>>().index(action.index))
        .modify((source: KeyedSource<DataType.ANY>) => ({ ...source, source: action.source }))(
        state,
      );

    case 'ADD_CONDITIONAL_AND_CONDITION':
      let conditions: Traversal<EntitiesState<Step>, ConditionWrapper[]> = conditionalAndLens(
        action.stepId,
        action.choiceIndex,
      ).asTraversal();

      if (action.actionParameterKey) {
        conditions = (
          action.stepType === StepType.ACTION
            ? actionConditionsByKey(action.stepId, action.actionParameterKey)
            : actionTriggerConditionByKey(action.stepId, action.actionParameterKey)
        )
          .composePrism(joinConditionsPrism)
          .composeLens(Lens.fromProp<JoinedConditions>()('conditions'));
      }

      return conditions.modify((andConditions: ConditionWrapper[]) => [
        ...andConditions,
        {
          type: 'JOIN',
          join: 'AND',
          conditions: [
            {
              type: 'OPERATOR',
              condition: getDefaultCondition(),
            },
          ],
        },
      ])(state);
    case 'ADD_CONDITIONAL_OPERATOR_CONDITION':
      let andCondition: Traversal<
        EntitiesState<Step>,
        JoinedConditions
      > = conditionalAndLensByIndex(
        action.stepId,
        action.choiceIndex,
        action.andIndex,
      ).asTraversal();

      if (action.actionParameterKey) {
        andCondition = (
          action.stepType === StepType.ACTION
            ? actionConditionsByKey(action.stepId, action.actionParameterKey)
            : actionTriggerConditionByKey(action.stepId, action.actionParameterKey)
        )
          .composePrism(joinConditionsPrism)
          .composeLens(Lens.fromProp<JoinedConditions>()('conditions'))
          .composeOptional(indexArray<ConditionWrapper>().index(action.andIndex))
          .composePrism(joinConditionsPrism);
      }

      return andCondition.modify((andCondition: JoinedConditions) => ({
        ...andCondition,
        conditions: [
          ...andCondition.conditions,
          { type: 'OPERATOR', condition: getDefaultCondition() },
        ],
      }))(state);
    case 'DELETE_CONDITIONAL_OPERATOR_CONDITION':
      let orCondition: Traversal<EntitiesState<Step>, JoinedConditions> = conditionOrLens(
        action.stepId,
        action.choiceIndex,
      ).asTraversal();

      if (action.actionParameterKey) {
        orCondition = (
          action.stepType === StepType.ACTION
            ? actionConditionsByKey(action.stepId, action.actionParameterKey)
            : actionTriggerConditionByKey(action.stepId, action.actionParameterKey)
        ).composePrism(joinConditionsPrism);
      }

      return orCondition.modify((orCondition: JoinedConditions) => {
        const andConditionCount: number = orCondition.conditions.length;
        const firstAndConditionLength: number = (orCondition.conditions[0] as JoinedConditions)
          .conditions.length;
        const selectedAndConditionLength: number = (
          orCondition.conditions[action.andIndex] as JoinedConditions
        ).conditions.length;

        // if not the last condition on ui
        const canDeleteCondition: boolean = !(
          andConditionCount === 1 && firstAndConditionLength === 1
        );
        const innerConditions = Lens.fromProp<JoinedConditions>()('conditions')
          .composeOptional(joinConditionsByIndex(action.andIndex))
          .composeLens(Lens.fromProp<JoinedConditions>()('conditions'));

        // if and has only one condition left remove the whole and section
        if (canDeleteCondition && selectedAndConditionLength === 1) {
          return {
            ...orCondition,
            conditions: [
              ...orCondition.conditions.slice(0, action.andIndex),
              ...orCondition.conditions.slice(action.andIndex + 1),
            ],
          };
        } else if (canDeleteCondition) {
          return innerConditions.modify((conditions: ConditionWrapper[]) => [
            ...conditions.slice(0, action.conditionIndex),
            ...conditions.slice(action.conditionIndex + 1),
          ])(orCondition);
        } else {
          return innerConditions.modify((_conditions: ConditionWrapper[]) => [
            { type: 'OPERATOR', condition: getDefaultCondition() },
          ])(orCondition);
        }
      })(state);
    case 'SET_CONDITIONAL_OPERATOR_CONDITION':
      let opCondition: Traversal<
        EntitiesState<Step>,
        OperatorCondition
      > = conditionalConditionLensByIndex(
        action.stepId,
        action.choiceIndex,
        action.andIndex,
        action.conditionIndex,
      ).asTraversal();

      if (action.actionParameterKey) {
        opCondition = (
          action.stepType === StepType.ACTION
            ? actionConditionsByKey(action.stepId, action.actionParameterKey)
            : actionTriggerConditionByKey(action.stepId, action.actionParameterKey)
        )
          .composePrism(joinConditionsPrism)
          .composeLens(Lens.fromProp<JoinedConditions>()('conditions'))
          .composeOptional(indexArray<ConditionWrapper>().index(action.andIndex))
          .composePrism(joinConditionsPrism)
          .composeLens(Lens.fromProp<JoinedConditions>()('conditions'))
          .composeOptional(
            indexArray<ConditionWrapper>()
              .index(action.conditionIndex)
              .composePrism(operatorConditionsPrism),
          );
      }

      return opCondition.modify((operatorCondition: OperatorCondition) => ({
        ...operatorCondition,
        condition: action.condition,
      }))(state);
    case 'SET_MAP_ITERATOR':
      return mapLens(action.stepId).modify((step: MapStep) => ({
        ...step,
        parameters: {
          ...step.parameters,
          iterator: action.iterator,
        },
      }))(state);

    case 'SET_ACTION_INTENT':
      const parameterIntentLens: Optional<
        EntitiesState<Step>,
        ActionStepParameters
      > = action.stepType === StepType.ACTION
        ? actionParametersLens(action.stepId)
        : actionTriggerParameterLens(action.stepId);
      return parameterIntentLens
        .composeLens(Lens.fromProp<ActionStepParameters>()('intent'))
        .set(action.intent)(state);

    case 'SET_ACTION_PARAMETER':
      const actionParameterLens: Optional<
        EntitiesState<Step>,
        ActionStepParameters
      > = action.stepType === StepType.ACTION
        ? actionParametersLens(action.stepId)
        : actionTriggerParameterLens(action.stepId);

      return actionParameterLens
        .composeLens(Lens.fromProp<ActionStepParameters>()('actionParameters'))
        .modify((actionParameters: KeyedSource<DataType.ANY>[]) => {
          // TODO: Create lens/better functional way to do this
          const modifyIndex: number = actionParameters.findIndex(
            (value: KeyedSource) => value.key === action.key,
          );
          const newParam: KeyedSource<DataType.ANY> = {
            key: action.key,
            source: action.value,
          };
          if (modifyIndex > -1) {
            return [
              ...actionParameters.slice(0, modifyIndex),
              newParam,
              ...actionParameters.slice(modifyIndex + 1),
            ];
          } else {
            return [...actionParameters, newParam];
          }
        })(state);

    case 'SET_ACTION_CREDENTIALS':
      switch (action.stepType) {
        case StepType.ACTION:
          return actionParametersLens(action.stepId).modify((parameters: ActionStepParameters) => ({
            ...parameters,
            credentials: action.credentialIds,
          }))(state);
        case StepType.ACTION_TRIGGER:
          return actionTriggerParameterLens(action.stepId).modify(
            (parameters: ActionStepParameters) => ({
              ...parameters,
              credentials: action.credentialIds,
            }),
          )(state);
      }
      return state;

    case 'SET_ACTION_RETRY_ON_FAILURE':
      return actionParametersLens(action.stepId).modify((parameters: ActionStepParameters) => ({
        ...parameters,
        retryOnFailure: action.payload,
      }))(state);

    case 'SET_ACTION_IGNORE_FAILURE':
      return actionParametersLens(action.stepId).modify((parameters: ActionStepParameters) => ({
        ...parameters,
        ignoreFailure: action.payload,
      }))(state);

    case 'SET_OAUTH_TRIGGER_CREDENTIALS':
      return stepLens(action.stepId)
        .composePrism(stepTypePrism(StepType.OAUTH))
        .composeLens(Lens.fromProp<OAuthStep>()('parameters'))
        .modify((parameters: BaseActionStepParameters) => ({
          ...parameters,
          credentials: action.credentialIds,
          provider: action.provider,
        }))(state);

    case 'SET_DELAY_UNIT':
      return delayParametersLens(action.stepId).modify((parameters: DelayStep['parameters']) => ({
        ...parameters,
        unit: action.unit,
      }))(state);

    case 'SET_DELAY_VALUE':
      return delayParametersLens(action.stepId).modify((parameters: DelayStep['parameters']) => ({
        ...parameters,
        value: action.value,
      }))(state);
    case 'UPDATE_STEP':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.step.id]: {
            ...action.step,
          },
        },
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
