import React from 'react';

import {
  ActionResponse,
  getValuesByCacheKey,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DEFAULT_SUPPORTED_OPERATORS, Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './shared/authConfig';

const fetchTablesAction: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Table',
  cacheKey: 'tables',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_TABLES,
      actionType: Action.MYSQL,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<string[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((table_name: string) => ({
        label: table_name,
        value: table_name,
      }))
    );
  },
};

const fetchColumnsAction: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Column',
  cacheKey: 'cachedColumns',
  refreshDependencies: ['table'],
  mapRefreshToValues: (response: any): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const table = pickValueSourceByKey(options.actionParameters, 'table');
    return {
      actionType: Action.MYSQL,
      intent: Intent.GET_COLUMNS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'table',
          source: {
            type: 'VALUE',
            value: table?.value,
          },
        },
      ],
    };
  },
};

const config: ActionConfig = {
  actionType: Action.MYSQL,
  name: 'MySQL',
  description: 'Trigger when a MySQL record is created or updated',
  icon: iconSvg,
  mutedIcon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Choose your MySQL account',
          placeholder: 'Choose your MySQL account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.TRIGGER_ROW_CREATED,
                    label: 'New record created',
                  },
                  {
                    value: Intent.TRIGGER_ROW_UPDATED,
                    label: 'Record updated',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      const tablesInput: SidebarInput = {
        id: 'table',
        type: SidebarInputType.DynamicEnum,
        title: 'Table',
        placeholder: 'Select a table',
        required: true,
        source: fetchTablesAction,
        getValues: getValuesByCacheKey,
      };

      const filterInput: SidebarInput = {
        id: 'filterFormula',
        type: SidebarInputType.DynamicConditional,
        title: 'Filter records',
        subtitle: 'Only trigger when an updated record matches these filters.',
        placeholder: 'filter by field',
        required: false,
        source: fetchColumnsAction,
        getValues: (options: ActionStepParameters) => {
          const columns =
            pickValueSourceByKey(options.actionParameters, 'cachedColumns')?.value || {};
          return Object.entries<string>(columns).map(([columnName]: string[]) => ({
            value: columnName,
            label: columnName,
          }));
        },
        supportedOperators: [
          ...DEFAULT_SUPPORTED_OPERATORS,
          Operator.NumberLessThanOrEqualTo,
          Operator.NumberGreaterThanOrEqualTo,
        ],
      };

      const orderByColumn: SidebarInput = {
        id: 'orderBy',
        type: SidebarInputType.DynamicEnum,
        title: 'Order by',
        placeholder: 'choose a column',
        required: true,
        source: fetchColumnsAction,
        getValues: (options: ActionStepParameters) => {
          const columns =
            pickValueSourceByKey(options.actionParameters, 'cachedColumns')?.value || {};
          return Object.entries<string>(columns).map(([columnName]: string[]) => ({
            value: columnName,
            label: columnName,
          }));
        },
      };

      switch (parameters.intent) {
        case Intent.TRIGGER_ROW_CREATED:
          return {
            inputs: [
              tablesInput,
              {
                ...orderByColumn,
                subtitle: (
                  <>
                    Used to determine new records. This is usually a <code>date_created</code>{' '}
                    column.
                  </>
                ),
              },
              filterInput,
            ],
          };
        case Intent.TRIGGER_ROW_UPDATED:
          return {
            inputs: [
              tablesInput,
              {
                ...orderByColumn,
                subtitle: (
                  <>
                    Used to determine newly updated records. This is usually a{' '}
                    <code>date_updated</code> column.
                  </>
                ),
              },
              filterInput,
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
