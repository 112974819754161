import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'Financials.ReadWrite.All' | 'offline_access';

export const scopes: Record<ScopeName, ScopeValue> = {
  'Financials.ReadWrite.All': {
    label: 'Financials.ReadWrite.All',
    name: 'Financials.ReadWrite.All',
    required: true,
    description: 'Access Dynamics 365 Business Central as the signed-in user',
  },
  offline_access: {
    label: 'offline_access',
    name: 'offline_access',
    required: true,
    description: 'Maintain access to data you have given it access to',
  },
};

export const requiredScopes: ScopeName[] = ['Financials.ReadWrite.All', 'offline_access'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Dynamics 365 Business Central',
  description: 'Sync accounts payable with Dynamics',
  authUrl:
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?resource=https://api.businesscentral.dynamics.com',
  accessTokenUrl:
    'https://login.microsoftonline.com/common/v2.0/oauth2/token?resource=https://api.businesscentral.dynamics.com',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/dynamicsbusinesscentral`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink:
    'https://docs.microsoft.com/en-us/dynamics365/business-central/dev-itpro/developer/devenv-develop-connect-apps#AAD',
};
