import { WorkflowLogEntity } from '@shared/types/sdk/steps';
import { pickLatestExecutions } from '@shared/workflow/sdk';

import { indexBy } from '../../../utils';
import { Action, WorkflowLogEntities } from '../../types';

export const initialState: WorkflowLogEntities = {
  loadAttempt: false,
  entities: {},
  pageCount: 0,
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: WorkflowLogEntities = initialState,
  action: Action,
): WorkflowLogEntities {
  switch (action.type) {
    case 'WORKFLOW_LOG_ENTITY_FETCH_START':
      return {
        ...state,
        loadAttempt: true,
        processing: true,
      };

    case 'WORKFLOW_LOG_ENTITY_FETCH_SUCCESS':
      const latestExecutionsLog: WorkflowLogEntity[] = pickLatestExecutions([
        ...Object.values(state.entities),
        ...action.workflowLogs,
      ]);

      return {
        ...state,
        entities: indexBy('id', latestExecutionsLog),
        processing: false,
        errorMessage: undefined,
      };

    case 'WORKFLOW_LOG_ENTITY_FETCH_FAILURE':
      return {
        ...state,
        errorMessage: action.message,
        processing: false,
      };

    case 'WORKFLOW_LOG_ENTITY_SET_TOTAL_COUNT':
      return {
        ...state,
        pageCount: action.pageCount,
      };
    case 'RESET_WORKFLOW_LOGS':
      return {
        loadAttempt: false,
        entities: {},
        pageCount: 1,
        processing: false,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
