import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GONG;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'GONG_TEST_CONNECTION',
  ADD_CALL = 'GONG_ADD_CALL',
  GET_CALL_BY_ID = 'GONG_GET_CALL_BY_ID',
  SEARCH_CALL = 'GONG_SEARCH_CALL',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_CALL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CALL_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_CALL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ACCESS_TOKEN = 'GONG_ACCESS_TOKEN',
  REFRESH_TOKEN = 'GONG_REFRESH_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
  SCOPES?: string;
};
