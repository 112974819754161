import React from 'react';

import { createTestConnectionButton, getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const twilioDashboardLink = (
  <a href="https://www.twilio.com/console/project/settings" target="_blank" rel="noreferrer">
    account page
  </a>
);

const phoneNumbers: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Phone Number',
  cacheKey: 'from-options',
  refreshDependencies: [],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response.result.output.map((availableNumber: any) => ({
      label: availableNumber.phoneNumber,
      value: availableNumber.phoneNumber,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.TWILIO,
      intent: Intent.FETCH_ALL_NUMBERS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const authConfig: ActionConfig = {
  actionType: Action.NONE,
  provider: ProviderType.TWILIO,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your Twilio account',
  description: 'Add Twilio to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this Twilio account',
          placeholder: 'e.g. Production, Testing, etc.',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'TWILIO_ACCOUNT_SID',
          title: 'Add your Twilio Account SID',
          subtitle: <>To find your API credentials, login to your Twilio {twilioDashboardLink}.</>,
          placeholder: 'Twilio Account SID',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'TWILIO_AUTH_TOKEN',
          title: 'Add your Auth Token',
          subtitle: 'Found directly below your Account SID on your Twilio account page.',
          placeholder: 'Twilio Auth Token',
          type: SidebarInputType.ValueText,
        },
      ],
      buttons: [
        createTestConnectionButton(Action.TWILIO, ['TWILIO_ACCOUNT_SID', 'TWILIO_AUTH_TOKEN']),
      ],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.TWILIO,
  name: 'Twilio',
  description: 'Send text messages with Twilio.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect your Twilio account',
          placeholder: 'choose your Twilio account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.SEND_SMS,
                    label: 'Send SMS',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEND_SMS:
          return {
            inputs: [
              {
                id: 'from',
                type: SidebarInputType.EditableDynamicEnum,
                title: 'From Number',
                placeholder: 'choose a number',
                required: true,
                source: phoneNumbers,
                getValues: getValuesByCacheKey,
              },
              {
                id: 'to',
                type: SidebarInputType.Text,
                title: 'To Number',
                placeholder: 'choose a number',
                required: true,
              },
              {
                id: 'body',
                type: SidebarInputType.TextArea,
                title: 'Message',
                required: true,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
