import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import { GUSTO_AUTH_BASE_URL } from '../shared/constants';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Gusto',
  description: 'Manage their employees, pay roll, and jobs.',
  authUrl: `${GUSTO_AUTH_BASE_URL}/authorize`,
  accessTokenUrl: `${GUSTO_AUTH_BASE_URL}/token`,
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/gusto`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
    {
      type: SidebarInputType.ValueText,
      title: 'Demo Client ID',
      id: 'demoClientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/gusto`,
      documentationLinkLabel: 'How to obtain a Demo Client ID?',
      placeholder: 'Optional',
      required: false,
    },
    {
      type: SidebarInputType.ValueText,
      title: 'Demo Client Secret',
      id: 'demoClientSecret',
      placeholder: 'Optional',
      required: false,
    },
  ],
  scopes: {
    values: [],
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/gusto',
};
