import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'default' | 'openid' | 'email' | 'profile';

export const requiredScopes: ScopeName[] = ['default'];

export const scopes: Record<ScopeName, ScopeValue> = {
  default: {
    name: 'default',
    description:
      'Provides access to all endpoints documented in our API reference. If no scopes are requested, this scope is assumed by default.',
  },
  openid: {
    name: 'openid',
    description:
      'Provides access to OpenID Connect ID tokens and the OpenID Connect user info endpoint.',
  },
  email: {
    name: 'email',
    description:
      "Provides access to the user's email through the OpenID Connect user info endpoint.",
  },
  profile: {
    name: 'profile',
    description:
      "Provides access to the user's name and profile photo through the OpenID Connect user info endpoint.",
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Asana',
  description: 'Manage a user’s Asana tasks and projects.',
  authUrl: 'https://app.asana.com/-/oauth_authorize',
  accessTokenUrl: 'https://app.asana.com/-/oauth_token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/asana`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://developers.asana.com/docs',
};
