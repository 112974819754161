import React from 'react';

import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

export const searchFilterInput: SidebarInput = {
  id: 'filterFormula',
  type: SidebarInputType.Conditional,
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  getValues: () => [
    { label: 'Invoice Id', value: 'InvoiceId' },
    { label: 'Invoice Amount', value: 'InvoiceAmount' },
    { label: 'Invoice Currency', value: 'InvoiceCurrency' },
    { label: 'Invoice Type', value: 'InvoiceType' },
    { label: 'Description', value: 'Description' },
    { label: 'Invoice Number', value: 'InvoiceNumber' },
    { label: 'Invoice Date', value: 'InvoiceDate' },
    { label: 'Invoice Group', value: 'InvoiceGroup' },
    { label: 'Payment Terms', value: 'PaymentTerms' },
    { label: 'Terms Date', value: 'TermsDate' },
    { label: 'Business Unit', value: 'BusinessUnit' },
    { label: 'Supplier', value: 'Supplier' },
    { label: 'Supplier Site', value: 'SupplierSite' },
    { label: 'Legal Entity', value: 'LegalEntity' },
    { label: 'Requester', value: 'Requester' },
    { label: 'Taxation Country', value: 'TaxationCountry' },
  ],

  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.NumberEquals,
    Operator.BooleanTrue,
    Operator.BooleanFalse,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
    Operator.NumberDoesNotEqual,
    Operator.NumberEquals,
    Operator.NumberGreaterThan,
    Operator.NumberGreaterThanOrEqualTo,
    Operator.NumberLessThan,
    Operator.NumberLessThanOrEqualTo,
    Operator.StringContains,
    Operator.StringDoesNotContain,
    Operator.StringStartsWith,
    Operator.StringEndsWith,
    Operator.StringIsIn,
    Operator.StringIsNotIn,
    Operator.BooleanTrue,
    Operator.BooleanFalse,
  ],
  required: false,
};

export const invoiceIdInput: SidebarInput = {
  id: 'invoiceId',
  type: SidebarInputType.TextArea,
  title: 'Invoice Id',
  subtitle: 'The Id of the invoice to update.',
  lines: 1,
  required: true,
};

export const invoiceAmountInput: SidebarInput = {
  id: 'invoiceAmount',
  type: SidebarInputType.TextArea,
  title: 'Invoice Amount',
  subtitle: 'The invoice amount in the specified currency.',
  lines: 1,
  required: true,
};

export const invoiceCurrencyInput: SidebarInput = {
  id: 'invoiceCurrency',
  type: SidebarInputType.TextArea,
  title: 'Invoice Currency',
  subtitle: 'The currency code used for the invoice.',
  lines: 1,
  required: true,
};

export const invoiceTypeInput: SidebarInput = {
  id: 'invoiceType',
  type: SidebarInputType.Enum,
  title: 'Invoice Type',
  defaultValue: 'standard',
  required: false,
  getValues: () => [
    { label: 'Standard', value: 'standard' },
    { label: 'Prepayment', value: 'prepayment' },
    { label: 'Credit Memo', value: 'creditMemo' },
    { label: 'Debit Memo', value: 'debitMemo' },
  ],
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  type: SidebarInputType.TextArea,
  title: 'Description',
  subtitle: 'A statement that describes the invoice.',
  lines: 1,
  required: false,
};

export const invoiceNumberInput: SidebarInput = {
  id: 'invoiceNumber',
  type: SidebarInputType.TextArea,
  title: 'Invoice Number',
  lines: 1,
  required: false,
};

export const invoiceDateInput: SidebarInput = {
  id: 'invoiceDate',
  type: SidebarInputType.TextArea,
  title: 'invoiceDate',
  lines: 1,
  required: false,
};

export const LinkWrapper: React.FC<{ text: string }> = ({ text }: { text: string }) => (
  <a
    href="https://docs.useparagon.com/v/connect/resources/integrations/oracle-financials-cloud"
    target="_blank"
    rel="noreferrer"
  >
    {text}
  </a>
);

export const invoiceLinesInput: SidebarInput = {
  id: 'invoiceLines',
  type: SidebarInputType.Code,
  title: 'Invoice Lines',
  subtitle: (
    <>
      Specify goods, services, and charges in invoice lines. Use the Build Invoice Line step to
      create an invoice lines object. See <LinkWrapper text="the documentation" /> for more details.
    </>
  ),
  language: 'json',
  placeholder: `[
    {
      "Description": "White T-Shirt", 
      "Quantity": "2",
      "UnitPrice": "20.00",
      "DistributionCombination": "...",
    }
  ]
  `,
  lines: 4,
  useLightTheme: true,
  required: false,
};

export const invoiceGroupInput: SidebarInput = {
  id: 'invoiceGroup',
  type: SidebarInputType.TextArea,
  title: 'Invoice Group',
  subtitle:
    'The unique name of the invoice group assigned to a group of invoices used for reporting and payment purposes.',
  lines: 1,
  required: false,
};

export const attachmentsInput: SidebarInput = {
  id: 'attachments',
  type: SidebarInputType.TextArea,
  title: 'Attachments',
  subtitle:
    'A list of files to attach to the invoice header. Specify as an array of Attachment objects.',
  lines: 1,
  required: false,
};

export const paymentTermsInput: SidebarInput = {
  id: 'paymentTerms',
  type: SidebarInputType.TextArea,
  title: 'Payment Terms',
  subtitle:
    'The payment terms used to calculate installments and to calculate due dates, discount dates, and discount amounts for each invoice. If unspecified, it is populated by default from the Supplier Site.',
  lines: 1,
  required: false,
};

export const businessUnitInput: SidebarInput = {
  id: 'businessUnit',
  type: SidebarInputType.TextArea,
  title: 'Business Unit',
  subtitle:
    'The business unit name on the invoice. If unspecified, it is populated with the profile’s default organization.',
  lines: 1,
  required: false,
};

export const termsDateInput: SidebarInput = {
  id: 'termsDate',
  type: SidebarInputType.TextArea,
  title: 'Terms Date',
  subtitle:
    'The date used with payment terms to calculate installment with due date and discount dates. If unspecified, it is populated with the Terms Date Basis from the Supplier Site.',
  lines: 1,
  required: false,
};

export const supplierSiteInput: SidebarInput = {
  id: 'supplierSite',
  type: SidebarInputType.TextArea,
  title: 'Supplier Site',
  subtitle: 'The phsyical location of the supplier where goods and services are rendered.',
  lines: 1,
  required: false,
};

export const supplierInput: SidebarInput = {
  id: 'supplier',
  type: SidebarInputType.TextArea,
  title: 'Supplier',
  subtitle: 'The supplier name on the invoice.',
  lines: 1,
  required: false,
};

export const legalEntityInput: SidebarInput = {
  id: 'legalEntity',
  type: SidebarInputType.TextArea,
  title: 'Legal Entity',
  lines: 1,
  required: false,
};

export const requesterInput: SidebarInput = {
  id: 'requester',
  type: SidebarInputType.TextArea,
  title: 'Requester',
  subtitle:
    'The name of the person who requested the goods or services. The requester is used by the Invoice Approval Workflow process to generate the list of approvers.',
  lines: 1,
  required: false,
};

export const taxationCountryInput: SidebarInput = {
  id: 'taxationCountry',
  type: SidebarInputType.TextArea,
  title: 'Taxation Country',
  subtitle:
    'The country where the transaction took place. If unspecified, it is populated from the setup.',
  lines: 1,
  required: false,
};

export const lineTypeInput: SidebarInput = {
  id: 'lineType',
  type: SidebarInputType.Enum,
  title: 'Line Type',
  defaultValue: 'item',
  required: true,
  getValues: () => [
    { label: 'Item', value: 'item' },
    { label: 'Freight', value: 'freight' },
    { label: 'Miscellaneous', value: 'miscellaneous' },
  ],
};

export const lineAmountInput: SidebarInput = {
  id: 'lineAmount',
  type: SidebarInputType.TextArea,
  title: 'Amount',
  subtitle: 'The line amount in the currency specified by the invoice.',
  lines: 1,
  required: true,
};

export const lineNumberInput: SidebarInput = {
  id: 'lineNumber',
  type: SidebarInputType.TextArea,
  title: 'Line Number',
  subtitle:
    'The country where the transaction took place. If unspecified, it is populated from the setup.',
  lines: 1,
  required: false,
};

export const accountingDateInput: SidebarInput = {
  id: 'accountingDate',
  type: SidebarInputType.TextArea,
  title: 'Accounting Date',
  subtitle: 'If unspecified, this field is populated by default from the invoice header.',
  lines: 1,
  required: false,
};

export const budgetDateInput: SidebarInput = {
  id: 'budgetDate',
  type: SidebarInputType.TextArea,
  title: 'Budget Date',
  subtitle: 'Budgetary Calendar Period Date applicable for funds check.',
  lines: 1,
  required: false,
};

export const taxClassificationInput: SidebarInput = {
  id: 'taxClassification',
  type: SidebarInputType.TextArea,
  title: 'Tax Classification',
  subtitle: 'The tax classification code used for tax calculation.',
  lines: 1,
  required: false,
};

export const withholdInput: SidebarInput = {
  id: 'withholding',
  type: SidebarInputType.TextArea,
  title: 'Withholding',
  subtitle: 'The wittholding tax group name.',
  lines: 1,
  required: false,
};

export const distributionCombinationInput: SidebarInput = {
  id: 'distributionCombination',
  type: SidebarInputType.TextArea,
  title: 'Distribution Combination',
  subtitle:
    'The accounting flexfield to be used for automatic generation of a single distribution.',
  lines: 1,
  required: false,
};

export const prorateAcrossAllItemsInput: SidebarInput = {
  id: 'prorateAcrossAllItemsFlag',
  type: SidebarInputType.BooleanInput,
  title: 'Prorate Across All Items',
  subtitle:
    'Indicates that the Freight or Miscellaneous type line should be prorated across all existing Item type lines. Defaults to false.',
  defaultValue: false,
  required: false,
};

export const buildInvoiceLineItemInputs: SidebarInput[] = [
  lineTypeInput,
  lineAmountInput,
  lineNumberInput,
  { ...descriptionInput, subtitle: 'A statement that describes the invoice line.' },
  accountingDateInput,
  budgetDateInput,
  taxClassificationInput,
  withholdInput,
  distributionCombinationInput,
  prorateAcrossAllItemsInput,
];

export const createInvoiceInputs = (required: boolean): SidebarInput[] => [
  { ...invoiceAmountInput, required },
  { ...invoiceCurrencyInput, required },
  invoiceTypeInput,
  descriptionInput,
  invoiceNumberInput,
  invoiceDateInput,
  invoiceLinesInput,
  { ...invoiceGroupInput, required },
  attachmentsInput,
  paymentTermsInput,
  termsDateInput,
  businessUnitInput,
  { ...supplierInput, required },
  { ...supplierSiteInput, required },
  legalEntityInput,
  requesterInput,
  taxationCountryInput,
];

export const intentInput: SidebarInput = {
  id: 'intent',
  title: 'Choose an action',
  type: SidebarInputType.Intent,
  values: [
    {
      title: 'Invoices',
      items: [
        {
          value: Intent.SEARCH_INVOICES,
          label: 'Search Invoices',
        },
        {
          value: Intent.CREATE_INVOICE,
          label: 'Create Invoice',
        },
        {
          value: Intent.UPDATE_INVOICE,
          label: 'Update Invoice',
        },
        {
          value: Intent.BUILD_INVOICE_LINE_ITEM,
          label: 'Build Invoice Line Item',
        },
        {
          value: Intent.VALIDATE_INVOICE,
          label: 'Validate Invoice',
        },
      ],
    },
  ],
};
