import { Iso, Lens } from 'monocle-ts';

import orderIntegrationWorkflows from '@shared/connect/sdk/helpers/orderIntegrationWorkflows';
import {
  IntegrationWorkflowMeta,
  ModalConfig,
  SerializedConnectInput,
} from '@shared/types/sdk/connect';

import { indexBy, reorderArray } from '../../../utils';
import { Action, EntitiesState, IntegrationConfigEntity } from '../../types';

// optics for integrationConfig
const getIntegrationConfigLens = (configId: string) =>
  Lens.fromPath<EntitiesState<IntegrationConfigEntity>>()(['entities', configId]);

const getPortalConfigLens = (configId: string) =>
  getIntegrationConfigLens(configId).composeLens(
    Lens.fromPath<IntegrationConfigEntity>()(['values']),
  );

const workflowMeta = (configId: string) =>
  getIntegrationConfigLens(configId)
    .composeLens(Lens.fromProp<IntegrationConfigEntity>()('values'))
    .composeLens(Lens.fromProp<ModalConfig>()('workflowMeta'))
    .composeIso(
      new Iso<
        { [id: string]: IntegrationWorkflowMeta } | undefined,
        { [id: string]: IntegrationWorkflowMeta }
      >(
        (meta: { [id: string]: IntegrationWorkflowMeta } | undefined) => meta!,
        (meta: { [id: string]: IntegrationWorkflowMeta }) => meta,
      ),
    );

const integrationSettingsLens = Lens.fromProp<IntegrationWorkflowMeta>()('inputs');

export const initialState: EntitiesState<IntegrationConfigEntity> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<IntegrationConfigEntity> = initialState,
  action: Action,
): EntitiesState<IntegrationConfigEntity> {
  if ('configId' in action && 'workflowId' in action && action.workflowId !== undefined) {
    state = workflowMeta(action.configId).modify(
      (meta: { [id: string]: IntegrationWorkflowMeta } | undefined) => {
        if (!meta) {
          meta = {};
        }
        if (!meta[action.workflowId as string]) {
          meta = {
            ...meta,
            [action.workflowId as string]: {
              id: action.workflowId as string,
              defaultEnabled: false,
              inputs: [],
            },
          };
        }
        return meta;
      },
    )(state);
  }

  switch (action.type) {
    case 'INTEGRATION_CONFIG_ENTITY_FETCH_START':
    case 'INTEGRATION_CONFIG_ENTITY_CREATE_START':
    case 'INTEGRATION_CONFIG_ENTITY_UPDATE_START':
      return {
        ...state,
        processing: true,
      };

    case 'INTEGRATION_CONFIG_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        entities: { ...indexBy('id', action.integrationConfigs) },
        processing: false,
        errorMessage: undefined,
      };

    case 'INTEGRATION_CONFIG_ENTITY_CREATE_SUCCESS':
    case 'INTEGRATION_CONFIG_ENTITY_UPDATE_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.integrationConfigEntity.id]: action.integrationConfigEntity,
        },
        processing: false,
        errorMessage: undefined,
      };

    case 'INTEGRATION_CONFIG_ENTITY_FETCH_FAILED':
    case 'INTEGRATION_CONFIG_ENTITY_CREATE_FAILED':
    case 'INTEGRATION_CONFIG_ENTITY_UPDATE_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'SET_PORTAL_ACCENT_COLOR':
      return getPortalConfigLens(action.configId).modify((config: ModalConfig) => ({
        ...config,
        accentColor: action.accentColor,
      }))(state);

    case 'SET_PORTAL_DESCRIPTION':
      return getPortalConfigLens(action.configId).modify((config: ModalConfig) => ({
        ...config,
        description: action.description,
      }))(state);

    case 'SET_PORTAL_OVERVIEW':
      return getPortalConfigLens(action.configId).modify((config: ModalConfig) => ({
        ...config,
        overview: action.overview,
      }))(state);

    case 'SET_PORTAL_PARAGON_LINK':
      return getPortalConfigLens(action.configId).modify((config: ModalConfig) => ({
        ...config,
        paragonLink: action.paragonLink,
      }))(state);

    case 'SET_PORTAL_WORKFLOW_DESCRIPTION':
      return workflowMeta(action.configId)
        .composeLens(Lens.fromProp<{ [id: string]: IntegrationWorkflowMeta }>()(action.workflowId))
        .modify(
          (workflow: IntegrationWorkflowMeta): IntegrationWorkflowMeta => ({
            ...workflow,
            infoText: action.description,
          }),
        )(state);

    case 'SET_PORTAL_WORKFLOW_VISIBILITY':
      return workflowMeta(action.configId)
        .composeLens(Lens.fromProp<{ [id: string]: IntegrationWorkflowMeta }>()(action.workflowId))
        .modify(
          (workflow: IntegrationWorkflowMeta): IntegrationWorkflowMeta => ({
            defaultEnabled: action.defaultEnabled,
            ...workflow,
            hidden: action.hidden,
          }),
        )(state);

    case 'SET_PORTAL_WORKFLOW_DEFAULT_ENABLED':
      return workflowMeta(action.configId)
        .composeLens(Lens.fromProp<{ [id: string]: IntegrationWorkflowMeta }>()(action.workflowId))
        .modify(
          (workflow: IntegrationWorkflowMeta): IntegrationWorkflowMeta => ({
            ...workflow,
            defaultEnabled: action.defaultEnabled,
          }),
        )(state);

    case 'REORDER_PORTAL_WORKFLOW_ITEMS':
      return workflowMeta(action.configId).modify(
        (workflows: { [id: string]: IntegrationWorkflowMeta }) => {
          const orderedWorkflows = orderIntegrationWorkflows(
            Object.values(action.workflowState),
            workflows,
          );
          const reorderedWorkflows = reorderArray(
            orderedWorkflows,
            action.sourceIndex,
            action.destinationIndex,
          );
          return Object.fromEntries<IntegrationWorkflowMeta>(
            reorderedWorkflows.map((workflow: typeof reorderedWorkflows[number], index: number) => [
              workflow.id,
              { ...workflows[workflow.id], order: index },
            ]),
          );
        },
      )(state);

    case 'ADD_PORTAL_WORKFLOW_SETTING':
      return workflowMeta(action.configId)
        .composeLens(Lens.fromProp<{ [id: string]: IntegrationWorkflowMeta }>()(action.workflowId))
        .modify((workflow: IntegrationWorkflowMeta) => ({
          ...workflow,
          defaultEnabled: action.defaultEnabled,
          inputs: [...(workflow.inputs || []), action.setting],
        }))(state);

    case 'UPDATE_PORTAL_WORKFLOW_SETTING':
      return workflowMeta(action.configId)
        .composeLens(Lens.fromProp<{ [id: string]: IntegrationWorkflowMeta }>()(action.workflowId))
        .modify((workflow: IntegrationWorkflowMeta) => ({
          ...workflow,
          defaultEnabled: action.defaultEnabled,
          inputs: (workflow.inputs || []).map((setting: SerializedConnectInput) =>
            setting.id == action.setting.id ? action.setting : setting,
          ),
        }))(state);

    case 'ADD_PORTAL_SHARED_SETTING':
      return getPortalConfigLens(action.configId).modify((config: ModalConfig) => ({
        ...config,
        sharedMeta: {
          ...config.sharedMeta,
          inputs: [...(config.sharedMeta?.inputs ? config.sharedMeta.inputs : []), action.setting],
        },
      }))(state);

    case 'UPDATE_PORTAL_SHARED_SETTING':
      return getPortalConfigLens(action.configId).modify((config: ModalConfig) => ({
        ...config,
        sharedMeta: {
          ...config.sharedMeta,
          inputs: config.sharedMeta!.inputs!.map((setting: SerializedConnectInput) =>
            setting.id == action.setting.id ? action.setting : setting,
          ),
        },
      }))(state);

    case 'DELETE_PORTAL_WORKFLOW_SETTING':
      return workflowMeta(action.configId)
        .composeLens(Lens.fromProp<{ [id: string]: IntegrationWorkflowMeta }>()(action.workflowId))
        .modify((workflow: IntegrationWorkflowMeta) => ({
          ...workflow,
          inputs: (workflow.inputs || []).filter(
            (setting: SerializedConnectInput) => setting.id !== action.settingId,
          ),
        }))(state);

    case 'DELETE_PORTAL_SHARED_SETTING':
      return getPortalConfigLens(action.configId).modify((config: ModalConfig) => ({
        ...config,
        sharedMeta: {
          ...config.sharedMeta,
          inputs: config.sharedMeta!.inputs!.filter(
            (setting: SerializedConnectInput) => setting.id !== action.settingId,
          ),
        },
      }))(state);

    case 'REORDER_PORTAL_WORKFLOW_SETTINGS':
      return workflowMeta(action.configId)
        .composeLens(Lens.fromProp<{ [id: string]: IntegrationWorkflowMeta }>()(action.workflowId))
        .composeLens(integrationSettingsLens)
        .modify((settings: SerializedConnectInput[]) =>
          reorderArray(settings, action.sourceIndex, action.destinationIndex),
        )(state);

    case 'REORDER_PORTAL_SHARED_SETTINGS':
      return getPortalConfigLens(action.configId).modify((config: ModalConfig) => ({
        ...config,
        sharedMeta: {
          ...config.sharedMeta,
          inputs: reorderArray(
            config.sharedMeta!.inputs as SerializedConnectInput[],
            action.sourceIndex,
            action.destinationIndex,
          ),
        },
      }))(state);

    default:
      return state;
  }
}
