/**
 * Google ad manager base url
 */
export const GOOGLE_AD_MANAGER_BASE_URL: string =
  'https://ads.google.com/apis/ads/publisher/v202205';

/**
 * Generic application name for API header
 */
export const GOOGLE_AD_MANAGER_APPLICATION_NAME: string = 'Paragon Integration';

/**
 * Google ad manager XMLNS attribute value
 */
export const GOOGLE_AD_MANAGER_XMLNS: string = 'https://www.google.com/apis/ads/publisher/v202205';

/**
 * Key for getNetworkCodes source
 */
export const networkCodesKey: string = 'getGoogleAdManagerNetworkCodes';

/**
 * Google ad manage token url
 */
export const GOOGLE_AD_MANAGER_TOKEN_URL: string = 'https://oauth2.googleapis.com/token';

/**
 * Google ad manager auth url
 */
export const GOOGLE_AD_MANAGER_AUTH_URL: string = 'https://accounts.google.com/o/oauth2/v2/auth';
