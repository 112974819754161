import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.BIGQUERY,
  name: 'BigQuery',
  description:
    "Connect to your users' BigQuery account to manage their datasets, tables, and jobs.",
  icon: iconSvg,
  provider: ProviderType.BIGQUERY,
  sidebarSections: [],
};

export { authConfig };
export default connectConfig;
