import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { calendarInput, mailFolderInput } from './common';

const triggerConfig: ActionConfig = {
  actionType: Action.OUTLOOK,
  name: 'Microsoft Outlook',
  description: 'Trigger when events or mail are created or updated in Outlook',
  icon: iconSvg,
  provider: ProviderType.OUTLOOK,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_EVENT_CREATED,
              label: 'Event Created',
            },
            {
              value: Intent.TRIGGER_EVENT_UPDATED,
              label: 'Event Updated',
            },
            {
              value: Intent.TRIGGER_MESSAGE_RECEIVED,
              label: 'New Message',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      switch (parameters.intent as Intent) {
        case Intent.TRIGGER_EVENT_CREATED:
        case Intent.TRIGGER_EVENT_UPDATED:
          return {
            inputs: [calendarInput],
          };
        case Intent.TRIGGER_MESSAGE_RECEIVED:
          return {
            inputs: [mailFolderInput],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default triggerConfig;
