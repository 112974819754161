import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { FilterFormula, FilterFormulaData } from '../shared/types';
import { isValidMySQLIdentifier, wrapWordInInvertedComma } from '../shared/utils';

// TODO: #test - write tests with mysql > conditionsToMySql against live database
// need to ensure the parameters are being formatted properly AND query the correct documents

// {
//   type: 'JOIN',
//   join: 'OR',
//   conditions: [{
//     type: 'JOIN',
//     join: 'AND',
//     conditions: [{
//       operator: '$stringContains',
//       variable: "Name",
//       argument: "boop"
//     }]
//   }]
export function parseCondition(
  condition: ResolvedConditionWrapper,
  data: FilterFormulaData,
): string | undefined {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => parseCondition(condition, data))
      .filter((condition: string | undefined) => condition)
      .join(condition.join === 'OR' ? ' OR ' : ' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    // Check variable is valid
    if (!isValidMySQLIdentifier(innerCondition.variable)) {
      throw new Error(`Invalid column identifier: ${innerCondition.variable}`);
    }

    switch (innerCondition.operator) {
      case Operator.StringContains:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` LIKE CONCAT('%',?,'%')`;

      case Operator.StringDoesNotContain:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` NOT LIKE CONCAT('%',?,'%')`;

      case Operator.StringExactlyMatches:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` = ?`;

      case Operator.StringDoesNotExactlyMatch:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` != ?`;

      case Operator.StringIsIn:
        const strintIsInArgument: string[] = innerCondition.argument as string[];
        const unknowValuesStringIsIn = new Array(strintIsInArgument.length);
        unknowValuesStringIsIn.fill('?');
        wrapWordInInvertedComma(strintIsInArgument).map((arg: string) => data.push(arg));
        return `\`${innerCondition.variable}\` in (${unknowValuesStringIsIn.join(',')})`;

      case Operator.StringIsNotIn:
        const strintIsNotInArgument: string[] = innerCondition.argument as string[];
        const unknowValuesStringIsNotIn = new Array(strintIsNotInArgument.length);
        unknowValuesStringIsNotIn.fill('?');
        wrapWordInInvertedComma(strintIsNotInArgument).map((arg: string) => data.push(arg));
        return `\`${innerCondition.variable}\` not in (${unknowValuesStringIsNotIn.join(',')})`;

      case Operator.StringIsNotIn:
      case Operator.StringStartsWith:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` LIKE ?`;

      case Operator.StringDoesNotStartWith:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` NOT LIKE ?`;

      case Operator.StringEndsWith:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` LIKE CONCAT('%', ?)`;

      case Operator.StringDoesNotEndWith:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` NOT LIKE CONCAT('%', ?)`;

      case Operator.NumberLessThan:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` < ?`;

      case Operator.NumberGreaterThan:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` > ?`;

      case Operator.NumberEquals:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` = ?`;

      case Operator.NumberDoesNotEqual:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` != ?`;

      case Operator.NumberGreaterThanOrEqualTo:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` >= ?`;

      case Operator.NumberLessThanOrEqualTo:
        data.push(innerCondition.argument);
        return `\`${innerCondition.variable}\` <= ?`;

      case Operator.DateTimeAfter:
        data.push(`${new Date(innerCondition.argument).toUTCString()}`);
        return `\`${innerCondition.variable}\` > ?`;

      case Operator.DateTimeBefore:
        data.push(`${new Date(innerCondition.argument).toUTCString()}`);
        return `\`${innerCondition.variable}\` < ?`;

      case Operator.DateTimeEquals:
        data.push(`${new Date(innerCondition.argument).toUTCString()}`);
        return `\`${innerCondition.variable}\` = ?`;

      case Operator.BooleanTrue:
        return `\`${innerCondition.variable}\` true`;
      case Operator.BooleanFalse:
        return `\`${innerCondition.variable}\` false`;
      case Operator.IsNotNull:
        return `\`${innerCondition.variable}\` IS NOT NULL`;
      case Operator.IsNull:
        return `\`${innerCondition.variable}\` IS NULL`;
      case Operator.None:
        return undefined;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for MySQL`);
    }
  }
  return undefined;
}

export default function conditionsToMySql(condition: ResolvedConditionWrapper): FilterFormula {
  const filterData: FilterFormulaData = [];
  const query: string | undefined = parseCondition(condition, filterData);
  if (!query) {
    throw new Error('Unsupported condition type: ' + condition.type);
  }

  const formula: FilterFormula = {
    query,
    data: filterData,
  };
  return formula;
}
