import React from 'react';

import { createTestConnectionButton, getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  DynamicEnumInput,
  EnumInputValue,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const getCollections: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Collection',
  cacheKey: 'collection-options',
  refreshDependencies: [],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response.result.output.map((collection: string) => ({
      label: collection,
      value: collection,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.FIREBASE,
      intent: Intent.FIRESTORE_LIST_COLLECTIONS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const authConfig: ActionConfig = {
  actionType: Action.FIREBASE,
  scheme: AuthenticationScheme.SERVICE_ACCOUNT,
  provider: ProviderType.FIREBASE,
  name: 'Connect your Firebase account',
  description: 'Add Firebase to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name',
          placeholder: 'e.g. Production, Sandbox, etc.',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'FIREBASE_DATABASE_URL',
          title: 'Firebase Database URL',
          subtitle: 'To find your Firebase URL, visit your Firebase project console.',
          placeholder: 'https://your-project-id.firebaseio.com/',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'FIREBASE_PROJECT_ID',
          title: 'Firebase Project ID',
          subtitle: 'To find your Firebase Project ID, visit your Firebase project console.',
          placeholder: 'Firebase Project ID',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'FIREBASE_SERVICE_ACCOUNT',
          title: 'Add your Service Account with Firebase privileges',
          subtitle: 'To find your Service Account, see Firebase documentation.',
          placeholder: `{
  "type": "service_account",
  "project_id": "yourProjectId",
  "private_key_id": "yourPrivateKeyId",
  "private_key": "-----BEGIN PRIVATE KEY-----
111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111
-----END PRIVATE KEY-----"
}`,
          type: SidebarInputType.ValueTextArea,
        },
      ],
      buttons: [
        createTestConnectionButton(Action.FIREBASE, [
          'FIREBASE_DATABASE_URL',
          'FIREBASE_PROJECT_ID',
          'FIREBASE_SERVICE_ACCOUNT',
        ]),
      ],
    },
  ],
};

const FetchCollectionsInput: DynamicEnumInput = {
  id: 'collection',
  type: SidebarInputType.DynamicEnum,
  title: 'Collection',
  placeholder: 'Select a collection',
  required: true,
  source: getCollections,
  getValues: getValuesByCacheKey,
};

const Config: ActionConfig = {
  actionType: Action.FIREBASE,
  name: 'Firebase',
  description: 'Use Firebase services, including Authentication and Firestore.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Firebase account',
          placeholder: 'choose your Firebase',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.FIRESTORE_GET_USER_BY_UID,
                    label: 'Auth (User Management): Get user by UID',
                  },
                  {
                    value: Intent.FIRESTORE_GET_USER_BY_EMAIL,
                    label: 'Auth (User Management): Get user by email',
                  },
                  {
                    value: Intent.FIRESTORE_LIST_USERS,
                    label: 'Auth (User Management): List users',
                  },
                  {
                    value: Intent.FIRESTORE_CREATE_USER,
                    label: 'Auth (User Management): Create user',
                  },
                  {
                    value: Intent.FIRESTORE_UPDATE_USER,
                    label: 'Auth (User Management): Update user',
                  },
                  {
                    value: Intent.FIRESTORE_DELETE_USER,
                    label: 'Auth (User Management): Delete user',
                  },
                  {
                    value: Intent.FIRESTORE_QUERY_DOCUMENTS,
                    label: 'Firestore: Query Firestore',
                  },
                  {
                    value: Intent.FIRESTORE_INSERT_DOCUMENT,
                    label: 'Firestore: Insert document',
                  },
                  {
                    value: Intent.FIRESTORE_UPDATE_DOCUMENT,
                    label: 'Firestore: Update document',
                  },
                  {
                    value: Intent.FIRESTORE_GET_DOCUMENT_BY_ID,
                    label: 'Firestore: Get document by ID',
                  },
                  {
                    value: Intent.FIRESTORE_DELETE_DOCUMENT_BY_ID,
                    label: 'Firestore: Delete document',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.FIRESTORE_GET_USER_BY_UID:
          return {
            inputs: [
              {
                id: 'uid',
                type: SidebarInputType.Text,
                title: 'User UID',
                required: true,
              },
            ],
          };
        case Intent.FIRESTORE_GET_USER_BY_EMAIL:
          return {
            inputs: [
              {
                id: 'email',
                type: SidebarInputType.Text,
                title: 'User Email',
                required: true,
              },
            ],
          };
        case Intent.FIRESTORE_LIST_USERS:
          return {
            inputs: [
              {
                id: 'limit',
                type: SidebarInputType.Text,
                title: 'Limit number of users returned',
                subtitle: <>By default, Firebase limits the number of users returned to 1,000.</>,
                required: false,
              },
            ],
          };
        case Intent.FIRESTORE_CREATE_USER:
          return {
            inputs: [
              {
                id: 'user',
                type: SidebarInputType.Code,
                language: 'json',
                title: 'User Object',
                required: true,
                placeholder: `{
  email: user@example.com,
  emailVerified: false,
  phoneNumber: '+11234567890,
  password: 'secretPassword',
  displayName: 'John Doe',
  photoURL: 'http://www.example.com/12345677/photo.png',
  disabled: false
}`,
              },
            ],
          };
        case Intent.FIRESTORE_UPDATE_USER:
          return {
            inputs: [
              {
                id: 'uid',
                type: SidebarInputType.Text,
                title: 'User UID',
                required: true,
              },
              {
                id: 'update',
                type: SidebarInputType.Code,
                language: 'json',
                title: 'User Object',
                required: true,
                placeholder: `{
    email: user@example.com,
    emailVerified: false,
    phoneNumber: '+11234567890,
    password: 'secretPassword',
    displayName: 'John Doe',
    photoURL: 'http://www.example.com/12345677/photo.png',
    disabled: false
  }`,
              },
            ],
          };
        case Intent.FIRESTORE_DELETE_USER:
          return {
            inputs: [
              {
                id: 'uid',
                type: SidebarInputType.Text,
                title: 'User UID',
                required: true,
              },
            ],
          };
        case Intent.FIRESTORE_QUERY_DOCUMENTS:
          return {
            inputs: [
              FetchCollectionsInput,
              {
                id: 'limit',
                type: SidebarInputType.Text,
                title: 'Limit records',
                required: false,
              },
              {
                id: 'fetchSubcollections',
                type: SidebarInputType.BooleanInput,
                title: 'Include subcollections?',
                subtitle:
                  'Fetches all nested documents within any subcollections included in your query.',
                required: false,
                defaultValue: false,
              },
            ],
          };
        case Intent.FIRESTORE_INSERT_DOCUMENT:
          return {
            inputs: [
              FetchCollectionsInput,
              {
                id: 'uid',
                type: SidebarInputType.Text,
                title: 'Document ID',
                required: false,
              },
              {
                id: 'data',
                type: SidebarInputType.Code,
                language: 'json',
                title: 'Value',
                required: true,
                placeholder: `{
  "stringField": "value",
  "numberField": 24,
  "boolField": true,
  "refField": {
    $ref: "collection/document001"
  }
}`,
              },
            ],
          };
        case Intent.FIRESTORE_UPDATE_DOCUMENT:
          return {
            inputs: [
              FetchCollectionsInput,
              {
                id: 'uid',
                type: SidebarInputType.Text,
                title: 'Document ID',
                required: true,
              },
              {
                id: 'update',
                type: SidebarInputType.Code,
                language: 'json',
                title: 'Value',
                required: true,
                placeholder: `{
    "stringField": "value",
    "numberField": 24,
    "boolField": true,
    "refField": {
      $ref: "collection/document001"
    }
  }`,
              },
            ],
          };
        case Intent.FIRESTORE_GET_DOCUMENT_BY_ID:
          return {
            inputs: [
              FetchCollectionsInput,
              {
                id: 'uid',
                type: SidebarInputType.Text,
                title: 'Document ID',
                required: true,
              },
            ],
          };
        case Intent.FIRESTORE_DELETE_DOCUMENT_BY_ID:
          return {
            inputs: [
              FetchCollectionsInput,
              {
                id: 'uid',
                type: SidebarInputType.Text,
                title: 'Document ID',
                required: true,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default Config;
