import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig, SidebarInput } from '@shared/types/sdk/actions';

import { accountIdInput } from '../configs/common';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.NETSUITE,
  provider: ProviderType.NETSUITE,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your NetSuite account',
  icon: iconSvg,
  description: 'NetSuite',
  sidebarSections: [
    {
      inputs: [accountIdInput as SidebarInput],
      buttons: [],
    },
  ],
  hideOAuthApps: true,
};

export default authConfig;
