import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  IntegrationConnectInput,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

import { getList, getMember, getStatus } from './sources';

export const workspaceInput: IntegrationConnectInput = {
  id: 'teamId',
  title: 'Choose a ClickUp workspace to use',
  placeholder: 'Workspace',
  type: SidebarInputType.DynamicEnum,
  required: true,
  sourceType: 'getTeams',
};

export const listInput: SidebarInput = {
  id: 'listId',
  title: 'List',
  subtitle:
    'Select a List to create this task in. Use Connect Portal User Settings to allow users to select a ClickUp List.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const listDynamicInput: SidebarInput = {
  ...listInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getList,
  subtitle: '',
  placeholder: '',
  getValues: getValuesByCacheKey,
};

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  title: 'Description',
  type: SidebarInputType.TextArea,
  lines: 4,
  required: false,
};

export const statusInput: SidebarInput = {
  id: 'status',
  title: 'Status',
  type: SidebarInputType.TextArea,
  subtitle:
    'Select a Status for this task. Use Connect Portal User Settings to allow users to select a ClickUp Status.',
  required: false,
  lines: 1,
};

export const statusDynamicInput: SidebarInput = {
  id: 'status',
  title: 'Status',
  type: SidebarInputType.EditableDynamicEnum,
  subtitle: '',
  getValues: getValuesByCacheKey,
  source: getStatus,
};

export const assigneesInput: SidebarInput = {
  id: 'assignees',
  title: 'Assignees',
  type: SidebarInputType.TextArea,
  subtitle:
    'Select a Member (or an array of member IDs) to be assigned to this task. Use Connect Portal User Settings to allow users to select a ClickUp Member.',
  lines: 1,
  required: false,
};

export const assigneesDynamicInput: SidebarInput = {
  ...assigneesInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getMember,
  subtitle: '',
  placeholder: '',
  getValues: getValuesByCacheKey,
};

export const dueDateInput: SidebarInput = {
  id: 'dueDate',
  title: 'Due Date',
  type: SidebarInputType.TextArea,
  subtitle: 'Specify a date for this task to be due on.',
  lines: 1,
  required: false,
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.TextArea,
  subtitle: 'Specify additional fields to include on this task as JSON.',
  lines: 4,
  required: false,
};

export const taskIdInput: SidebarInput = {
  id: 'taskId',
  title: 'Task ID',
  type: SidebarInputType.TextArea,
  subtitle: 'The ID of the task to update.',
  lines: 1,
  required: true,
};

export const taskFilterInput: SidebarInput = {
  id: 'taskFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for tasks that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.NumberEquals,
    Operator.BooleanFalse,
    Operator.BooleanTrue,
    Operator.DateTimeEquals,
  ],
  getValues: () => [
    { label: 'Space Ids', value: 'space_ids%5B%5D' },
    { label: 'Project Ids', value: 'project_ids%5B%5D' },
    { label: 'List Ids', value: 'list_ids%5B%5D' },
    { label: 'Statuses', value: 'statuses%5B%5D' },
    { label: 'Include Closed', value: 'include_closed' },
    { label: 'Assignees', value: 'assignees%5B%5D' },
    { label: 'Tags', value: 'tags%5B%5D' },
    { label: 'Due Date Greater Than', value: 'due_date_gt' },
    { label: 'Due Date Less Than', value: 'due_date_lt' },
    { label: 'Date Created Greater Than', value: 'date_created_gt' },
    { label: 'Date Created Less Than', value: 'date_created_lt' },
    { label: 'Date Updated Greater Than', value: 'date_updated_gt' },
    { label: 'Date Updated Less Than', value: 'date_updated_lt' },
    { label: 'Date Created Greater Than', value: 'date_created_gt' },
  ],
};

export const createTaskInputs = (isConnectUi: boolean, isUpdating: boolean): SidebarInput[] => [
  isConnectUi ? listInput : listDynamicInput,
  { ...nameInput, required: !isUpdating },
  descriptionInput,
  isConnectUi ? statusInput : statusDynamicInput,
  isConnectUi ? assigneesInput : assigneesDynamicInput,
  dueDateInput,
  additionalFieldsInput,
];

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Tasks',
          items: [
            {
              value: Intent.SEARCH_TASKS,
              label: 'Search Tasks',
            },
            {
              value: Intent.GET_TASK_IN_LIST,
              label: 'Get Tasks in List',
            },
            {
              value: Intent.CREATE_TASK,
              label: 'Create Task',
            },
            {
              value: Intent.UPDATE_TASK,
              label: 'Update Task',
            },
            {
              value: Intent.DELETE_TASK,
              label: 'Delete Task',
            },
          ],
        },
      ],
    },
  ],
};
