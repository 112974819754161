import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.GOOGLE_AD_MANAGER,
  name: 'Google Ad Manager',
  description:
    "Connect to your users' Google Ad Manager account to manage their ad inventory, orders, reports, and more.",
  icon: iconSvg,
  provider: ProviderType.GOOGLE_AD_MANAGER,
  sidebarSections: [],
};

export { authConfig };
export default connectConfig;
