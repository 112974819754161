import { CredentialValues } from '@shared/entities/sdk';
export const MD_ENTITY_SPECIFIED_BY_OBJECT_NAME = '<specified by entity property>' as const;

export enum MdDefaultEntities {
  'Opportunity' = 'Opportunity',
  'Account' = 'Account',
  'Contact' = 'Contact',
  'Lead' = 'Lead',
  'Calls' = 'Call',
  'Meetings' = 'Meeting',
  'Tasks' = 'Task',
  'Notes' = 'Note',
}

export enum ParticipationTypeMask {
  'from' = 1,
  'to' = 2,
  'requiredattendees' = 5,
}

type DefaultEntitiesMetadata = {
  logicalName: string;
  entitySetName: string;
  associationProperties: string[];
  logicalNameSuffixedAssociationProperties?: string[];
  activityPartyProperties?: string[];
};
export const defaultEntitiesMetadata: Record<MdDefaultEntities, DefaultEntitiesMetadata> = {
  [MdDefaultEntities.Opportunity]: {
    logicalName: 'opportunity',
    entitySetName: 'opportunities',
    associationProperties: ['ownerid', 'transactioncurrencyid'],
    logicalNameSuffixedAssociationProperties: ['customerid'],
  },
  [MdDefaultEntities.Account]: {
    logicalName: 'account',
    entitySetName: 'accounts',
    associationProperties: ['ownerid'],
  },
  [MdDefaultEntities.Contact]: {
    logicalName: 'contact',
    entitySetName: 'contacts',
    associationProperties: [],
  },
  [MdDefaultEntities.Lead]: {
    logicalName: 'lead',
    entitySetName: 'leads',
    associationProperties: [],
  },
  [MdDefaultEntities.Calls]: {
    logicalName: 'phonecall',
    entitySetName: 'phonecalls',
    associationProperties: [],
    logicalNameSuffixedAssociationProperties: ['regardingobjectid'],
    activityPartyProperties: ['to', 'from'],
  },
  [MdDefaultEntities.Meetings]: {
    logicalName: 'appointment',
    entitySetName: 'appointments',
    associationProperties: [],
    logicalNameSuffixedAssociationProperties: ['regardingobjectid'],
    activityPartyProperties: ['requiredattendees'],
  },
  [MdDefaultEntities.Tasks]: {
    logicalName: 'task',
    entitySetName: 'tasks',
    associationProperties: ['ownerid'],
    logicalNameSuffixedAssociationProperties: ['regardingobjectid'],
  },
  [MdDefaultEntities.Notes]: {
    logicalName: 'annotation',
    entitySetName: 'annotations',
    associationProperties: [],
    logicalNameSuffixedAssociationProperties: ['objectid'],
  },
};

export type Environment = {
  MICROSOFT_DYNAMICS_CLIENT_ID: string;
  MICROSOFT_DYNAMICS_CLIENT_SECRET: string;
  MICROSOFT_DYNAMICS_SCOPES: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, any>;
  MICROSOFT_DYNAMICS_SUB_DOMAIN: string;
};

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
  MICROSOFT_DYNAMICS_SUB_DOMAIN: string;
};

export type TokenResponse = {
  access_token: string;
  refresh_token: string;
  error_description?: string;
  id_token: string;
  expires_in: number;
};

export type ExchangeCredentialOutput = {
  name: string;
  MICROSOFT_DYNAMICS_REFRESH_TOKEN: string;
  MICROSOFT_DYNAMICS_ACCESS_TOKEN: string;
  MICROSOFT_DYNAMICS_SUB_DOMAIN: string;
  userAccountId: string;
  expires_in: number;
  issuedAt: number;
};

export type EntityInputs = {
  mdEntity: MdDefaultEntities | typeof MD_ENTITY_SPECIFIED_BY_OBJECT_NAME;
  customMdEntityName?: string;
};

export type CreateRecordDTO = EntityInputs & Record<string, any>;

export type UpdateRecordDTO = { entityId: string } & CreateRecordDTO;

export type GetRecordByIdDTO = EntityInputs & {
  entityId: string;
};

export type DeleteRecordDTO = GetRecordByIdDTO;
export type GetFieldsForEntityDTO = EntityInputs;

export type SearchRecordDTO = EntityInputs & {
  filterFormula?: string;
  limit?: number;
};

export type CreateRecordResponse = Record<string, any>;
export type UpdateRecordResponse = CreateRecordResponse;

export type GetEntityResponse = Record<string, any>;

export type TokenExchangeResponse = {
  accessToken: string;
  refreshToken: string;
  updateCredentialValues: CredentialValues | undefined;
};
export type GetFieldsResponse = {
  Attributes: {
    LogicalName: string;
  }[];
};

export type CustomEntity = {
  LogicalName: string;
  EntitySetName: string;
  IsCustomizable: {
    Value: boolean;
    CanBeChanged: boolean;
  };
  DisplayName: {
    UserLocalizedLabel: {
      Label: string;
    };
    LocalizedLabels: {
      Label: string;
    };
  };
};
export type GetCustomEntitiesResponse = {
  value: CustomEntity[];
};

export type Account = {
  accountid: string;
  name: string;
};

export type Contact = {
  contactid: string;
  fullname: string;
};

export type Lead = {
  leadid: string;
  fullname: string;
};

export type Opportunity = {
  name: string;
  opportunityid: string;
};

export type AssociationProperty = {
  name: string;
  id: string;
  entitySetName: string;
  logicalName: string;
};

export type ProcessStageResponse = {
  id: string;
  name: string;
}[];

export type TransactionCurrency = {
  transactioncurrencyid: string;
  currencyname: string;
};

export type ProcessStage = {
  stagecategory: string;
  processstageid: string;
  stagename: string;
};

export type SystemUser = {
  fullname: string;
  ownerid: string;
  systemuserid: string;
};

export type Team = {
  name: string;
  ownerid: string;
  teamid: string;
};

export type SearchResponse = {
  '@odata.nextLink'?: string;
  value: DynamicsRecord[];
};

export type DynamicsRecord = {
  modifiedon: string;
  createdon: string;
  [key: string]: unknown;
};

export type FilterPropertiesForTrigger = 'createdon' | 'modifiedon';

export type TriggerDTO = EntityInputs & {
  filterFormula?: string;
};

export type DynamicsIdTokenClaims = {
  /**
   * user name
   */
  name: string;
  /**
   * preferred_username contains user's email
   */
  preferred_username: string;
  /**
   * oid is user's account unique identifier in Microsoft identity system
   */
  oid: string;
};

export type PartyActivityObject = {
  [x: string]: any;
  participationtypemask: number;
};

export type ActivityRecords = {
  activityid: string;
  subject: string;
};

export type Note = {
  annotationid: string;
  filename: string;
};
