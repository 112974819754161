import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SALESFORCE;
  sidebarSections: SidebarSectionFactory[];
  providerDataKeyLabelMap?: Record<keyof ProviderData, string>;
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SALESFORCE_TEST_CONNECTION',
  GET_RECORD_TYPES = 'SALESFORCE_GET_RECORD_TYPES',
  GET_RECORD_TYPE_FIELDS = 'SALESFORCE_GET_RECORD_TYPE_FIELDS',
  CREATE_RECORD = 'SALESFORCE_CREATE_RECORD',
  UPDATE_RECORD = 'SALESFORCE_UPDATE_RECORD',
  DELETE_RECORD = 'SALESFORCE_DELETE_RECORD',
  GET_RECORD_BY_ID = 'SALESFORCE_GET_RECORD_BY_ID',
  GET_CAMPAIGNS = 'SALESFORCE_GET_CAMPAIGNS',
  SEARCH_RECORDS = 'SALESFORCE_SEARCH_RECORDS',
  WRITE_SOQL_QUERY = 'SALESFORCE_WRITE_SOQL_QUERY',
  GET_RECORD_METADATA = 'SALESFORCE_GET_RECORD_METADATA',
  GET_FIELD_META_DATA = 'SALESFORCE_GET_FIELD_META_DATA',
  GET_LIST_VIEWS = 'SALESFORCE_GET_LIST_VIEWS',
  GET_CONTACT_BY_VIEW_ID = 'SALESFORCE_GET_CONTACTS_BY_VIEW_ID',
  TRIGGER_RECORD_CREATED = 'SALESFORCE_TRIGGER_RECORD_CREATED',
  TRIGGER_RECORD_UPDATED = 'SALESFORCE_TRIGGER_RECORD_UPDATED',
  TRIGGER_RECORD_DELETED = 'SALESFORCE_TRIGGER_RECORD_DELETED',
  CREATE_CUSTOM_FIELD = 'SALESFORCE_CREATE_CUSTOM_FIELD',
  CREATE_CUSTOM_OBJECT = 'SALESFORCE_CREATE_CUSTOM_OBJECT',
  TRIGGER_CAMPAIGN_MEMBER_ADDED = 'SALESFORCE_TRIGGER_CAMPAIGN_MEMBER_ADDED',
  TRIGGER_CAMPAIGN_OPPORTUNITY_ADDED = 'SALESFORCE_TRIGGER_CAMPAIGN_OPPORTUNITY_ADDED',
}

export const TriggerIntents = [
  Intent.TRIGGER_RECORD_CREATED,
  Intent.TRIGGER_RECORD_UPDATED,
  Intent.TRIGGER_CAMPAIGN_MEMBER_ADDED,
  Intent.TRIGGER_CAMPAIGN_OPPORTUNITY_ADDED,
  Intent.TRIGGER_RECORD_DELETED,
];

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD_TYPES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD_TYPE_FIELDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.WRITE_SOQL_QUERY;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CAMPAIGNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD_METADATA;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LIST_VIEWS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.GET_FIELD_META_DATA; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: Intent.TRIGGER_RECORD_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.CREATE_CUSTOM_FIELD; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.CREATE_CUSTOM_OBJECT; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: Intent.TRIGGER_CAMPAIGN_MEMBER_ADDED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_CAMPAIGN_OPPORTUNITY_ADDED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_DELETED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CONTACT_BY_VIEW_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;

  // this will be there for oauth_enabled app
  OAUTH_ACCESS_TOKEN?: string;
  SCOPES?: string;
};

export type ProviderData = {
  instanceUrl: string;
};
