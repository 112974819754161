import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'Sites.Read.All'
  | 'Sites.ReadWrite.All'
  | 'Sites.Manage.All'
  | 'Sites.FullControl.All'
  | 'Sites.Selected'
  | 'offline_access'
  | 'User.Read'
  | 'User.Read.All'
  | 'User.ReadBasic.All';

export const scopes: Record<ScopeName, ScopeValue> = {
  'Sites.Read.All': {
    label: 'Sites.Read.All',
    name: 'Sites.Read.All',
    required: true,
    description: 'Read items in all site collections.',
  },
  'Sites.ReadWrite.All': {
    label: 'Sites.ReadWrite.All',
    name: 'Sites.ReadWrite.All',
    description: 'Edit or delete items in all site collections',
    required: true,
  },
  'Sites.Manage.All': {
    label: 'Sites.Manage.All',
    name: 'Sites.Manage.All',
    description: 'Create, edit, and delete items and lists in all site collections',
    required: true,
  },
  'Sites.FullControl.All': {
    label: 'Sites.FullControl.All',
    name: 'Sites.FullControl.All',
    description: 'Have full control of all site collections',
    required: true,
  },
  'Sites.Selected': {
    label: 'Sites.Selected',
    name: 'Sites.Selected',
    required: true,
    description: 'Access selected site collections',
  },
  'User.Read': {
    label: 'User.Read',
    name: 'User.Read',
    required: true,
    description: 'Sign in and read user profile',
  },
  'User.Read.All': {
    label: 'User.Read.All',
    name: 'User.Read.All',
    required: true,
    description: 'Read all users full profiles',
  },
  'User.ReadBasic.All': {
    label: 'User.ReadBasic.All',
    name: 'User.ReadBasic.All',
    required: true,
    description: 'Read all users basic profiles',
  },
  offline_access: {
    label: 'offline_access',
    name: 'offline_access',
    required: true,
    description: 'Maintain access to data you have given it access to',
  },
};

export const requiredScopes: ScopeName[] = [
  'Sites.Read.All',
  'Sites.ReadWrite.All',
  'Sites.Manage.All',
  'Sites.FullControl.All',
  'offline_access',
  'User.Read',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'SharePoint',
  description: 'Manage sites and lists in SharePoint',
  authUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0',
  accessTokenUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/sharepoint`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink:
    'https://docs.microsoft.com/en-us/sharepoint/dev/sp-add-ins/sharepoint-net-server-csom-jsom-and-rest-api-index',
};
