import { DEFAULT_SUPPORTED_OPERATORS, Operator } from '@shared/types/sdk/resolvers';

const supportedOperators: Operator[] = DEFAULT_SUPPORTED_OPERATORS.filter(
  (operator: Operator) =>
    ![
      Operator.ArrayIsIn,
      Operator.ArrayIsNotEmpty,
      Operator.ArrayIsEmpty,
      Operator.ArrayIsNotIn,
      Operator.DoesNotExist,
      Operator.Exists,
    ].includes(operator),
);

export default supportedOperators;
