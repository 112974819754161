import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  IntentInputDesignType,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { WorkflowType } from '@shared/types/sdk/execution';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { authInput } from '../shared/authConfig';
import {
  SalesforceStandardRecord,
  defaultSalesforceFieldNames,
  defaultSalesforceFields,
} from '../shared/defaultRecordTypes';
import { RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME } from '../shared/types';

import supportedOperators from './supportedOperators';

const filterInputSubtitle = {
  [Intent.TRIGGER_RECORD_CREATED]: 'Only trigger on new records that match these filters.',
  [Intent.TRIGGER_RECORD_DELETED]: 'Only trigger on deleted records that match these filters.',
  [Intent.TRIGGER_RECORD_UPDATED]: 'Only trigger on updated records that match these filters.',
};

const connectConfig: ActionConfig = {
  actionType: Action.SALESFORCE,
  name: 'Salesforce',
  description: 'Trigger when records are created or updated in Salesforce',
  icon: iconSvg,
  provider: ProviderType.SALESFORCE,
  sidebarSections: [
    (
      _parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      return {
        inputs: workflowType === WorkflowType.CLASSIC ? [authInput] : [],
      };
    },
    (
      parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      if (parameters.credentials.length === 0 && workflowType === WorkflowType.CLASSIC) {
        return { inputs: [] };
      }
      return {
        inputs: [
          {
            id: 'intent',
            title: 'Choose a Trigger Event',
            type: SidebarInputType.Intent,
            designType: IntentInputDesignType.WorkflowSettingsDesign,
            icon: iconSvg,
            values: [
              {
                value: Intent.TRIGGER_RECORD_CREATED,
                label: 'New Record',
                subtitle: 'Trigger when a new Salesforce record is created',
              },
              {
                value: Intent.TRIGGER_RECORD_DELETED,
                label: 'Record Deleted',
                subtitle: 'Trigger when a Salesforce record is deleted',
              },
              {
                value: Intent.TRIGGER_RECORD_UPDATED,
                label: 'Record Updated',
                subtitle: 'Trigger when a Salesforce record is updated',
              },
              {
                value: Intent.TRIGGER_CAMPAIGN_MEMBER_ADDED,
                label: 'Campaign Member Added',
                subtitle: 'Trigger when a contact/lead is added to a campaign',
              },
              {
                value: Intent.TRIGGER_CAMPAIGN_OPPORTUNITY_ADDED,
                label: 'Campaign Opportunity Added',
                subtitle: 'Trigger when an opportunity is added to a campaign',
              },
            ],
          },
        ],
      };
    },
    (
      parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      if (parameters.credentials.length === 0 && workflowType === WorkflowType.CLASSIC) {
        return { inputs: [] };
      }

      //todo check if this input can be reused
      const selectedRecordType:
        | SalesforceStandardRecord
        | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'recordType',
      )?.value;

      if (
        parameters.intent === Intent.TRIGGER_CAMPAIGN_MEMBER_ADDED ||
        parameters.intent === Intent.TRIGGER_CAMPAIGN_OPPORTUNITY_ADDED
      ) {
        return {
          inputs: [
            {
              id: 'campaignId',
              title: 'Campaign',
              subtitle:
                'The campaign to watch for new Campaign Members. Use Connect Portal Workflow Settings to allow users to select a campaign.',
              type: SidebarInputType.TextArea,
              placeholder: '{{settings.campaign}}',
              required: true,
              lines: 1,
            },
            {
              id: 'filterFormula',
              type: SidebarInputType.Conditional,
              title: 'Filter records',
              placeholder: 'filter by column',
              required: false,
              supportedOperators,
              subtitle: 'Only trigger on records that match these filters.',
              ...(parameters.intent === Intent.TRIGGER_CAMPAIGN_MEMBER_ADDED
                ? {
                    getValues: () => [
                      { label: 'Id', value: 'Id' },
                      { label: 'Lead Id', value: 'LeadId' },
                      { label: 'Contact Id', value: 'ContactId' },
                      { label: 'Status', value: 'Status' },
                      { label: 'Type', value: 'Type' },
                      { label: 'Name', value: 'Name' },
                      { label: 'Email', value: 'Email' },
                    ],
                  }
                : {
                    getValues: () => [
                      { label: 'Id', value: 'Id' },
                      { label: 'Name', value: 'Name' },
                      { label: 'Contact Id', value: 'ContactId' },
                      { label: 'Stage', value: 'StageName' },
                      { label: 'Probability', value: 'Probability' },
                      { label: 'Type', value: 'Type' },
                    ],
                  }),
            },
          ],
        };
      }

      return {
        inputs: [
          {
            id: 'recordType',
            title: 'Record type',
            type: SidebarInputType.Enum,
            getValues: () => [
              ...Object.values(SalesforceStandardRecord).map(
                (recordType: SalesforceStandardRecord) => ({
                  label: recordType,
                  value: recordType,
                }),
              ),
              { label: 'Custom Object', value: RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME },
            ],
          },
          ...(selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
            ? [
                {
                  type: SidebarInputType.TextArea,
                  id: 'objectName',
                  title: 'Object Name',
                  subtitle:
                    'The API Name of the Object. Use Connect Portal Workflow Settings to allow users to select a Custom Object.',
                  placeholder: '{{settings.objectType}}',
                  required: true,
                  lines: 1,
                } as SidebarInput,
              ]
            : []),
          {
            id: 'recordsFilterFormula',
            type: SidebarInputType.Conditional,
            required: false,
            title: 'Filter search',
            subtitle: filterInputSubtitle[parameters.intent],
            supportedOperators,
            ...(selectedRecordType && selectedRecordType in defaultSalesforceFields
              ? {
                  getValues: () => [
                    { label: 'Id', value: 'Id' },
                    ...defaultSalesforceFields[selectedRecordType]().map(
                      (item: SidebarInput, index: number) => ({
                        label: item.title,
                        value: defaultSalesforceFieldNames[selectedRecordType][index],
                      }),
                    ),
                  ],
                }
              : {}),
          },
        ],
      };
    },
  ],
};

export default connectConfig;
