import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { AccountQB, Vendor } from '../shared/types';

export const getVendors: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'vendors',
  title: 'Vendor',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_VENDORS,
      actionType: Action.QUICKBOOKS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<{ Vendor: Vendor[] }>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.Vendor.map((vendor: Vendor) => ({
        label: vendor.DisplayName,
        value: vendor.Id,
      }))
    );
  },
};

export const getBillLines: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Account',
  cacheKey: 'accounts',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_ACCOUNTS,
      actionType: Action.QUICKBOOKS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<{ Account: AccountQB[] }>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.Account.map((account: AccountQB) => ({
        label: account.Name,
        value: account.Id,
      }))
    );
  },
};
