import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const getOwners: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'adminIdList',
  title: 'Admin',
  subtitle: 'An Intercom Admin to assign Contacts to',
  refreshDependencies: [],
  getRefreshActionParameters: (options: ActionStepParameters) => ({
    actionType: Action.INTERCOM,
    actionParameters: [],
    credentials: options.credentials,
    intent: Intent.LIST_ALL_ADMINS,
  }),
  mapRefreshToValues: (response: any) => {
    return response.result.output.map((item: EnumInputValue) => ({
      label: item.label,
      value: item.value,
    }));
  },
};
