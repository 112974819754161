import { ENVIRONMENT_VARIABLES } from './enums';

export const CacheRedisVariables = {
  [ENVIRONMENT_VARIABLES.DEBUG_REDIS]: 'string',
  [ENVIRONMENT_VARIABLES.CACHE_REDIS_URL]: 'string',
  [ENVIRONMENT_VARIABLES.CACHE_REDIS_CLUSTER_ENABLED]: 'string',
};

// provided to every service
export const MicroserviceBaseVariablesObject = {
  ...CacheRedisVariables,

  // general config
  [ENVIRONMENT_VARIABLES.GIT_SHA]: 'string',
  [ENVIRONMENT_VARIABLES.NODE_ENV]: 'string',
  [ENVIRONMENT_VARIABLES.PLATFORM_ENV]: 'string',
  [ENVIRONMENT_VARIABLES.HOST_ENV]: 'string',
  [ENVIRONMENT_VARIABLES.SERVICE]: 'string',
  [ENVIRONMENT_VARIABLES.VERSION]: 'string',
  [ENVIRONMENT_VARIABLES.BRANCH]: 'string',
  [ENVIRONMENT_VARIABLES.LOG_LEVEL]: 'string',
  [ENVIRONMENT_VARIABLES.LOG_TYPEORM]: 'string',
  [ENVIRONMENT_VARIABLES.VOLUME_PATH]: 'string',
  [ENVIRONMENT_VARIABLES.HTTP_PROXY]: 'string',
  [ENVIRONMENT_VARIABLES.SENTRY_DSN]: 'string',
  [ENVIRONMENT_VARIABLES.SEGMENT_API_KEY]: 'string',
  [ENVIRONMENT_VARIABLES.ADMIN_BASIC_AUTH_USERNAME]: 'string',
  [ENVIRONMENT_VARIABLES.ADMIN_BASIC_AUTH_PASSWORD]: 'string',

  // on-prem config
  [ENVIRONMENT_VARIABLES.EMBASSY_PUBLIC_URL]: 'string',
  [ENVIRONMENT_VARIABLES.LICENSE]: 'string',
  [ENVIRONMENT_VARIABLES.BASIC_AUTH_USER]: 'string',
  [ENVIRONMENT_VARIABLES.BASIC_AUTH_PASSWORD]: 'string',
};

// hermes + hercules shared variables for executions
export const HermesHerculesExecutionVariables = {
  ...CacheRedisVariables,
  [ENVIRONMENT_VARIABLES.SYSTEM_REDIS_URL]: 'string',
  [ENVIRONMENT_VARIABLES.QUEUE_REDIS_URL]: 'string',
  [ENVIRONMENT_VARIABLES.WORKFLOW_REDIS_URL]: 'string',
  [ENVIRONMENT_VARIABLES.SYSTEM_REDIS_CLUSTER_ENABLED]: 'string',
  [ENVIRONMENT_VARIABLES.QUEUE_REDIS_CLUSTER_ENABLED]: 'string',
  [ENVIRONMENT_VARIABLES.WORKFLOW_REDIS_CLUSTER_ENABLED]: 'string',
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_HOST]: 'string',
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_PORT]: 'string',
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_USERNAME]: 'string',
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_PASSWORD]: 'string',
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_DATABASE]: 'string',
  [ENVIRONMENT_VARIABLES.HERCULES_QUEUE_CLEANUP_FREQUENCY]: 'string',
  [ENVIRONMENT_VARIABLES.TASK_HISTORY_LOGS_DISABLED]: 'string',
};

// beethoven variables for workflow queue
export const BeethovenQueueVariables = {
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_HOST]: 'string',
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_PORT]: 'string',
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_USERNAME]: 'string',
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_PASSWORD]: 'string',
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_DATABASE]: 'string',
};

// configuration for communicating with Minio
export const MinioNetworkingVariables = {
  [ENVIRONMENT_VARIABLES.MINIO_PUBLIC_URL]: 'string',
  [ENVIRONMENT_VARIABLES.MINIO_PRIVATE_URL]: 'string',
  [ENVIRONMENT_VARIABLES.MINIO_MICROSERVICE_USER]: 'string',
  [ENVIRONMENT_VARIABLES.MINIO_MICROSERVICE_PASS]: 'string',
  [ENVIRONMENT_VARIABLES.MINIO_SYSTEM_BUCKET]: 'string',
  [ENVIRONMENT_VARIABLES.MINIO_PUBLIC_BUCKET]: 'string',
};

export const EnvironmentVariableKeys = Object.keys(
  ENVIRONMENT_VARIABLES,
) as ENVIRONMENT_VARIABLES[];
