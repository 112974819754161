import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig } from '@shared/types/sdk/actions';

import iconSvg from '../configs/icon.svg';

import { consumerKeyInput, consumerSecretInput, domainInput } from './inputs';

const authConfig: ActionConfig = {
  actionType: Action.WOOCOMMERCE,
  provider: ProviderType.WOOCOMMERCE,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your WooCommerce account',
  icon: iconSvg,
  description: 'WooCommerce',
  sidebarSections: [
    {
      inputs: [domainInput, consumerKeyInput, consumerSecretInput],
      buttons: [],
    },
  ],
  hideOAuthApps: true,
};

export default authConfig;
