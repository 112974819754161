import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { ActionStepParameters, Intent } from '../configs';
import {
  Board,
  Column,
  GetBoardsResponse,
  GetColumnsResponse,
  GetMembersResponse,
  User,
} from '../shared';

export const getMembers: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'users',
  title: 'User',
  subtitle: 'Monday.com user to assign tasks to',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<GetMembersResponse>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.data.users.map((user: User) => ({
          label: user.name,
          value: user.id,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MONDAY,
      intent: Intent.GET_MEMBERS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getBoards: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'boards',
  title: 'Board',
  subtitle: 'Monday.com board to create items in',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<GetBoardsResponse>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.data.boards.map((board: Board) => ({
          label: board.name,
          value: board.id,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MONDAY,
      intent: Intent.GET_BOARDS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getColumns: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'columns',
  title: 'Columns',
  refreshDependencies: ['boardId'],
  hideFromConnectFieldTypes: true,
  mapRefreshToValues: (response: ActionResponse<GetColumnsResponse>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.data.boards[0].columns.map((column: Column) => ({
          label: column.title,
          value: column.id,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const board =
      pickValueSourceByKey(options.actionParameters, 'boards') ??
      pickValueSourceByKey(options.actionParameters, 'boardId');
    return {
      actionType: Action.MONDAY,
      intent: Intent.GET_COLUMNS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'boardId',
          source: {
            type: 'VALUE',
            value: board?.value,
          },
        },
      ],
    };
  },
};

export const comboColumnFields: ComboInputDataSource = {
  id: 'comboFields',
  type: DataSourceType.COMBO_INPUT,
  title: 'Column',
  subtitle: 'Item columns to update',
  mainInputSource: getBoards,
  dependentInputSource: getColumns,
};
