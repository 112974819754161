import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.FRESHDESK,
  name: 'Freshdesk',
  description: "Connect to your users' Freshdesk accounts",
  icon: iconSvg,
  provider: ProviderType.FRESHDESK,
  sidebarSections: [],
};

export { authConfig };
export default connectConfig;
