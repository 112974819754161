import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  EnumSection,
  FieldMapperDataSource,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import {
  Account,
  AssociationProperty,
  Contact,
  EntityInputs,
  Lead,
  MD_ENTITY_SPECIFIED_BY_OBJECT_NAME,
  MdDefaultEntities,
  Opportunity,
  ProcessStageResponse,
} from '../shared';

export const getFields: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  title: 'Field',
  cacheKey: 'cachedFields',
  refreshDependencies: ['mdEntity'],
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): EnumInputValue[] => {
    return response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const selectedMdEntity: EntityInputs['mdEntity'] = pickValueSourceByKey<DataType.ANY>(
      options.actionParameters,
      'mdEntity',
    )?.value;

    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.GET_FIELDS_FOR_ENTITY,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'mdEntity',
          source: {
            type: 'VALUE',
            value: selectedMdEntity,
          },
        },
      ],
    };
  },
};

export const getCustomObjectFields: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  title: 'Custom object Field',
  cacheKey: 'cachedCustomObjectFields',
  refreshDependencies: ['customMdEntityName'],
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): EnumInputValue[] => {
    return response.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const customMdEntityName =
      pickValueSourceByKey(options.actionParameters, 'customMdEntityName')?.value ??
      // Fallback on cacheKey for getRecordType, the underlying data source
      pickValueSourceByKey(options.actionParameters, 'cachedCustomEntities')?.value;
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.GET_FIELDS_FOR_ENTITY,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'mdEntity',
          source: {
            type: 'VALUE',
            value: MD_ENTITY_SPECIFIED_BY_OBJECT_NAME,
          },
        },
        {
          key: 'customMdEntityName',
          source: {
            type: 'VALUE',
            value: customMdEntityName,
          },
        },
      ],
    };
  },
};

export const getCustomEntities: DynamicDataSource<EnumSection[] | EnumInputValue[]> = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Custom Entity Name',
  cacheKey: 'cachedCustomEntities',
  refreshDependencies: [],
  mapRefreshToValues: (
    response: ActionResponse<EnumSection[]>,
  ): EnumSection[] | EnumInputValue[] => {
    return response?.result.output ?? [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.GET_ALL_CUSTOM_ENTITIES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getCustomers: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Potential customer ID',
  cacheKey: 'cachedCustomers',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<AssociationProperty[]>): EnumInputValue[] => {
    return response.result.output.map((customer) => {
      const value: Omit<AssociationProperty, 'name'> = {
        id: customer.id,
        entitySetName: customer.entitySetName,
        logicalName: customer.logicalName,
      };
      return {
        label: customer.name,
        value: JSON.stringify(value),
      };
    });
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.GET_ALL_CUSTOMERS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getOwners: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Owner (User or Team)',
  subtitle: 'User or Team to be assigned to a record',
  cacheKey: 'cachedOwners',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<AssociationProperty[]>): EnumInputValue[] => {
    return response.result.output.map((owner) => {
      const value: Omit<AssociationProperty, 'name'> = {
        id: owner.id,
        entitySetName: owner.entitySetName,
        logicalName: owner.logicalName,
      };
      return {
        label: owner.name,
        value: JSON.stringify(value),
      };
    });
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.GET_ALL_OWNERS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getAccounts: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Account',
  subtitle: 'Account to associate with a record',
  cacheKey: 'cachedAccounts',
  refreshDependencies: [],
  mapRefreshToValues: (
    response: ActionResponse<AssociationProperty & Account[]>,
  ): EnumInputValue[] => {
    return response.result.output.map((account: Account) => {
      const value: Omit<AssociationProperty, 'name'> = {
        id: account.accountid,
        entitySetName: 'accounts',
        logicalName: 'account',
      };
      return {
        label: account.name,
        value: JSON.stringify(value),
      };
    });
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.SEARCH_RECORDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'mdEntity',
          source: {
            type: 'VALUE',
            value: MdDefaultEntities.Account,
          },
        },
      ],
    };
  },
};

export const getContacts: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Contact',
  subtitle: 'Contact to associate with a record',
  cacheKey: 'cachedContacts',
  refreshDependencies: [],
  mapRefreshToValues: (
    response: ActionResponse<AssociationProperty & Contact[]>,
  ): EnumInputValue[] => {
    return response.result.output.map((contact: Contact) => {
      const value: Omit<AssociationProperty, 'name'> = {
        id: contact.contactid,
        entitySetName: 'contacts',
        logicalName: 'contact',
      };
      return {
        label: contact.fullname,
        value: JSON.stringify(value),
      };
    });
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.SEARCH_RECORDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'mdEntity',
          source: {
            type: 'VALUE',
            value: MdDefaultEntities.Contact,
          },
        },
      ],
    };
  },
};

export const getLeads: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Lead',
  subtitle: 'Lead to associate with a record',
  cacheKey: 'cachedLeads',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<Lead[]>): EnumInputValue[] => {
    return response.result.output.map((lead: Lead) => {
      const value: Omit<AssociationProperty, 'name'> = {
        id: lead.leadid,
        entitySetName: 'leads',
        logicalName: 'lead',
      };
      return {
        label: lead.fullname,
        value: JSON.stringify(value),
      };
    });
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.SEARCH_RECORDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'mdEntity',
          source: {
            type: 'VALUE',
            value: MdDefaultEntities.Lead,
          },
        },
      ],
    };
  },
};

export const getOpportunities: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Opportunity',
  subtitle: 'Opportunity to associate with a record',
  cacheKey: 'cachedOpportunities',
  refreshDependencies: [],
  mapRefreshToValues: (
    response: ActionResponse<AssociationProperty & Opportunity[]>,
  ): EnumInputValue[] => {
    return response.result.output.map((opportunity: Opportunity) => {
      const value: Omit<AssociationProperty, 'name'> = {
        id: opportunity.opportunityid,
        entitySetName: 'opportunities',
        logicalName: 'opportunity',
      };
      return {
        label: opportunity.name,
        value: JSON.stringify(value),
      };
    });
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.SEARCH_RECORDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'mdEntity',
          source: {
            type: 'VALUE',
            value: MdDefaultEntities.Opportunity,
          },
        },
      ],
    };
  },
};

export const getProcessStages: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Process Stage',
  subtitle: 'Stage associated with a process',
  cacheKey: 'cachedProcessStages',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<ProcessStageResponse>): EnumInputValue[] => {
    return response.result.output.map((stage) => ({ label: stage.name, value: stage.id }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.GET_ALL_PROCESS_STAGES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getAllTransactionCurrencies: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: false,
  title: 'Transaction Currency',
  subtitle: 'Currency in which a financial transaction is carried out',
  cacheKey: 'cachedTransactionCurrencies',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<AssociationProperty[]>): EnumInputValue[] => {
    return response.result.output.map((currency) => {
      const value: Omit<AssociationProperty, 'name'> = {
        id: currency.id,
        entitySetName: currency.entitySetName,
        logicalName: currency.logicalName,
      };
      return {
        label: currency.name,
        value: JSON.stringify(value),
      };
    });
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_DYNAMICS,
      intent: Intent.GET_ALL_TRANSACTION_CURRENCIES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const mapDynamicsFields: FieldMapperDataSource = {
  id: 'customObjectMapping',
  type: DataSourceType.FIELD_MAPPER,
  title: 'Field Mapping',
  subtitle: 'Allows users to define a field mapping',
  recordSource: getCustomEntities,
  fieldSource: getCustomObjectFields,
};
