import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.POWER_BI,
  name: 'Power BI',
  description:
    'Connect to your user`s Power BI account to manage their datasets, dataflows, and reports.',
  icon: iconSvg,
  provider: ProviderType.POWER_BI,
  sidebarSections: [],
};

export { authConfig };

export default connectConfig;
