import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { boardInput, getSubtitle } from './common';

const triggerConfig: ActionConfig = {
  actionType: Action.TRELLO,
  name: 'Trello',
  description: 'Trigger when new Cards are created or updated',
  icon: iconSvg,
  provider: ProviderType.TRELLO,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_CARD_CREATED,
              label: 'Card Created',
            },
            {
              value: Intent.TRIGGER_CARD_UPDATED,
              label: 'Card Updated',
            },
            {
              value: Intent.TRIGGER_COMMENT_CREATED,
              label: 'Comment Created',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      switch (parameters.intent as Intent) {
        case Intent.TRIGGER_CARD_CREATED:
        case Intent.TRIGGER_CARD_UPDATED:
        case Intent.TRIGGER_COMMENT_CREATED:
          return {
            inputs: [
              {
                ...boardInput,
                placeholder: '{{settings.board}}',
                ...getSubtitle(parameters.intent as Intent),
              },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default triggerConfig;
