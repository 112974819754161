import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'email' | 'https://www.googleapis.com/auth/dfp';

export const scopes: Record<ScopeName, ScopeValue> = {
  email: {
    label: 'email',
    name: 'email',
    description: 'See the email address for your Google Ads Account.',
    required: true,
  },
  'https://www.googleapis.com/auth/dfp': {
    label: 'View and manage your campaigns on Ad Manager.',
    name: 'https://www.googleapis.com/auth/dfp',
    description: 'View and manage your campaigns on Ad Manager.',
  },
};

export const requiredScopes: ScopeName[] = ['email', 'https://www.googleapis.com/auth/dfp'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Google Ad Manager',
  description: 'Manage your ad inventory, orders, reports and more in Google Ad Manager',
  authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
  accessTokenUrl: 'https://oauth2.googleapis.com/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/google-ad-manager`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/google-ad-manager',
};
