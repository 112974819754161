import { RecordType } from './types';

export const NETSUITE_API_BASE_URL = `https://[Account ID].suitetalk.api.netsuite.com/services/rest/record/v1`;

export const recordIdInput = {
  [RecordType.VENDOR]: 'vendorId',
  [RecordType.BILL]: 'billId',
  [RecordType.ACCOUNTS]: 'accountId',
  [RecordType.TAX_GROUP]: 'taxGroupId',
  [RecordType.PAYMENT_TERMS]: 'termId',
};

export const filterFormulaInput = {
  [RecordType.VENDOR]: 'vendorFilterFormula',
  [RecordType.BILL]: 'billFilterFormula',
  [RecordType.ACCOUNTS]: 'accountFilterFormula',
  [RecordType.PAYMENT_TERMS]: 'termFilterFormula',
  [RecordType.POSTING_PERIOD]: 'postingPeriodFilterFormula',
  [RecordType.TAX_CODE]: 'taxCodeFilterFormula',
};
