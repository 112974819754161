import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'vso.identity'
  | 'vso.profile_write'
  | 'vso.project_write'
  | 'vso.work_write'
  | 'vso.work_full';

export const scopes: Record<ScopeName, ScopeValue> = {
  'vso.identity': {
    label: 'vso.identity',
    name: 'vso.identity',
    required: true,
    description: 'used to acquire ID tokens.',
  },
  'vso.profile_write': {
    label: 'vso.profile_write',
    name: 'vso.profile_write',
    description: 'gives access user email',
    required: true,
  },
  'vso.project_write': {
    label: 'vso.project_write',
    name: 'vso.project_write',
    description: 'gives access user email',
    required: true,
  },
  'vso.work_full': {
    label: 'vso.work_full',
    name: 'vso.work_full',
    description: 'used to acquire refresh token',
    required: true,
  },
  'vso.work_write': {
    label: 'vso.work_write',
    name: 'vso.work_write',
    required: true,
    description: '',
  },
};

export const requiredScopes: ScopeName[] = [
  'vso.identity',
  'vso.profile_write',
  'vso.project_write',
  'vso.work_full',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Azure DevOps',
  description: 'Manage boards and work items in Azure DevOps',
  authUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  accessTokenUrl:
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize/organizations/oauth2/v2.0/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/microsoft-dynamics-365`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.microsoft.com/en-us/rest/api/azure/devops/',
};
