import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  accountFilterInput,
  accountIdInput,
  billFilterInput,
  intentTypeInput,
  paymentTermInput,
  paymentTermsFilterInput,
  recordNumberInput,
  upsertBillFields,
  upsertVendorFields,
  vendorFilterInput,
  vendorIdInput,
  vendorTypesFilterInput,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.SAGE_INTACCT,
  name: 'Sage Intacct',
  description: "Connect to your users' Sage Intacct accounts",
  icon: iconSvg,
  provider: ProviderType.SAGE_INTACCT,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_VENDOR:
        case Intent.UPDATE_VENDOR:
          return {
            inputs: upsertVendorFields(parameters.intent === Intent.CREATE_VENDOR),
          };

        case Intent.DELETE_VENDOR:
          return {
            inputs: [
              {
                ...recordNumberInput,
                subtitle: 'The record number of the  vendor to delete.',
                required: true,
              },
            ],
          };

        case Intent.SEARCH_VENDORS:
          return { inputs: [vendorFilterInput] };

        case Intent.GET_VENDOR_BY_ID:
          return { inputs: [vendorIdInput, recordNumberInput] };

        case Intent.SEARCH_VENDOR_TYPES:
          return { inputs: [vendorTypesFilterInput] };

        case Intent.CREATE_BILL:
        case Intent.UPDATE_BILL:
          return {
            inputs: upsertBillFields(parameters.intent === Intent.CREATE_BILL),
          };

        case Intent.DELETE_BILL:
          return {
            inputs: [
              {
                ...recordNumberInput,
                subtitle: 'The record number of the bill to delete.',
                placeholder: '',
                required: true,
              },
            ],
          };

        case Intent.SEARCH_BILLS:
          return { inputs: [billFilterInput] };

        case Intent.GET_BILL_BY_ID:
          return {
            inputs: [
              {
                ...recordNumberInput,
                subtitle: 'The record number of the bill to get.',
                placeholder: '',
                required: true,
              },
            ],
          };

        case Intent.SEARCH_PAYMENT_TERMS:
          return { inputs: [paymentTermsFilterInput] };

        case Intent.GET_PAYMENT_TERM_BY_ID:
          return {
            inputs: [
              {
                ...paymentTermInput,
                title: 'Payment Term ID',
                subtitle: 'The ID of the term to get.',
                required: true,
              },
            ],
          };

        case Intent.SEARCH_ACCOUNTS:
          return { inputs: [accountFilterInput] };

        case Intent.GET_ACCOUNT_BY_ID:
          return { inputs: [accountIdInput] };

        case Intent.GET_DIMENSIONS:
          return { inputs: [] };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
