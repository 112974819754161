import { CredentialValues } from '@shared/entities/sdk';

export type Environment = {
  PIPEDRIVE_CLIENT_ID: string;
  PIPEDRIVE_CLIENT_SECRET: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, unknown>;
};

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
};

export type TokenExchangeResponse = {
  accessToken: string;
  refreshToken?: string;
  updateCredentialValues: CredentialValues | undefined;
};

export type AuthUserDetails = {
  data: {
    id: number;
    name: string;
    email: string;
  };
};

export type TokenApiResponse = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  scope: string;
  expires_in: number;
  api_domain: string;
};

export enum RecordType {
  ACTIVITIES = 'activities',
  DEALS = 'deals',
  LEADS = 'leads',
  NOTES = 'notes',
  ORGANIZATIONS = 'organizations',
  PERSONS = 'persons',
  USERS = 'users',
}

export enum Type {
  CALL = 'call',
  MEETING = 'meeting',
  TASK = 'task',
  DEADLINE = 'deadline',
  EMAIL = 'email',
  LUNCH = 'lunch',
}

export enum Status {
  OPEN = 'open',
  WON = 'won',
  LOST = 'lost',
  DELETED = 'deleted',
}

export enum ActiveStatus {
  TRUE = 'true',
  FALSE = 'false',
}

export type GetObjectTypesResponse = {
  value: string;
  label: string;
}[];

export type ActivitiesCreateRecordDTO = {
  subject?: string;
  note?: string;
  type?: Type;
  dueDate?: string;
  location?: string;
  done?: string;
  userId?: string;
  dealId?: string;
  leadId?: string;
  personId?: string;
  organizationId?: string;
};

export type DealsCreateRecordDTO = {
  title: string;
  value?: string;
  status?: Status;
  expectedCloseDate?: string;
  userId?: string;
  personId?: string;
  organizationId?: string;
  pipelineId?: string;
  stageId?: string;
  additionalFields?: string;
};

export type LeadsCreateRecordDTO = {
  title: string;
  value?: string;
  expectedCloseDate?: string;
  ownerId?: string;
  personId?: string;
  organizationId?: string;
  additionalFields?: string;
};

export type NotesCreateRecordDTO = {
  content: string;
  leadId?: string;
  personId?: string;
  organizationId?: string;
  dealId?: string;
};

export type OrganizationsCreateRecordDTO = {
  name: string;
  ownerId?: string;
  additionalFields?: string;
};

export type PersonsCreateRecordDTO = {
  name: string;
  email?: string;
  phone?: string;
  organizationId?: string;
  ownerId?: string;
  additionalFields?: string;
};

export type UsersCreateRecordDTO = {
  name: string;
  email: string;
  activeStatus: ActiveStatus;
};

export type CreateRecordTypeDTO =
  | ActivitiesCreateRecordDTO
  | DealsCreateRecordDTO
  | LeadsCreateRecordDTO
  | NotesCreateRecordDTO
  | OrganizationsCreateRecordDTO
  | PersonsCreateRecordDTO
  | UsersCreateRecordDTO;

export type CreateRecordDTO = {
  recordType: string;
  [key: string]: unknown;
};

export type UpdateRecordTypeDTO = CreateRecordTypeDTO & { recordId: string };

export type UpdateRecordDTO = CreateRecordDTO;

export type GetRecordByIdDTO = {
  recordType: RecordType;
  recordId: string;
};

export type DeleteRecordDTO = {
  recordType: string;
  recordId: string;
};

export type GetRecordsDTO = {
  recordType: string;
  term: string;
  recordFilterFormula?: string;
  limit?: number;
  start?: number;
};

export type PipelineData = {
  id: number;
  name: string;
  active: boolean;
  add_time: string;
  update_time: string;
};

export type GetPipelinesResponse = {
  success: boolean;
  data: PipelineData[];
};

export type StageData = PipelineData;

export type GetStageResponse = {
  success: boolean;
  data: StageData[];
};

export type GetObjectData = {
  key: string;
  name: string;
};

export type GetObjectResponse = {
  success: true;
  data: GetObjectData[];
};

export type CreateActivityRecord = {
  id: number;
  type: Type;
  due_date: string;
  due_time: string;
  duration: string;
  subject: string;
  add_time: string;
  update_time: string;
};

export type CreateDealRecord = {
  id: number;
  title: string;
  value: number;
  add_time: string;
  update_time: string;
};

export type CreateLeadRecord = {
  id: string;
  title: string;
  add_time: string;
  update_time: string;
};

export type CreateNoteRecord = {
  id: number;
  content: string;
  add_time: string;
  update_time: string;
};

export type CreateOrganizationRecord = {
  id: number;
  name: string;
  update_time: string;
  add_time: string;
};

export type CreatePersonsRecord = CreateOrganizationRecord;

export type CreateUsersRecord = CreateOrganizationRecord;

export type RecordData =
  | CreateActivityRecord
  | CreateDealRecord
  | CreateLeadRecord
  | CreateNoteRecord
  | CreateOrganizationRecord
  | CreatePersonsRecord
  | CreateUsersRecord;

export type CreateRecordResponse = {
  success: boolean;
  data: RecordData;
};

export type UpdateRecordResponse = CreateRecordResponse;

export type DeleteRecordResponse = {
  success: boolean;
  data: {
    id: number;
  };
};

export type GetObjectFieldsResponse = {
  success: boolean;
  data: {
    id: number;
    key: string;
    name: string;
  }[];
};

export type GetRecordByIdResponse = CreateRecordResponse;

export type SearchRecordResponse = {
  success: boolean;
  data: {
    items: {
      item: {
        id: number;
        type?: RecordType;
        name?: string;
        title?: string;
      };
    }[];
  };
  additional_data: {
    pagination: {
      start: number;
      limit: number;
      more_items_in_collection: boolean;
    };
  };
};

export type BasePaginateParams = {
  limit?: number;
  start?: number;
};

export type PaginateQuery = {
  recordType: string;
  term: string;
} & BasePaginateParams;

export type PaginateResponse<T> = {
  additional_data: {
    pagination: {
      start: number;
      limit: number;
      more_items_in_collection: boolean;
    };
  };
} & { [key: string]: T | T[] } & (T | T[]);

export type BuildRecordPayloadType = { [key: string]: unknown } | undefined;

export type GetRecordByTypeDTO = {
  recordType: string;
} & BasePaginateParams;

export type GetRecordByTypeResponse = {
  success: boolean;
  data: RecordData[];
};

export enum EventAction {
  ADDED = 'added',
  UPDATED = 'updated',
}

export type WebhookPayload = {
  eventAction: EventAction;
};

export type TriggerParameters = {
  recordType: string;
};

export type WebhookResponse = {
  status: string;
  success: boolean;
  data: {
    id: number;
  };
};

/**
 * Pipedrive support object for webhooks subscription
 */
export enum SupportedObjects {
  ACTIVITY = 'activity',
  DEAL = 'deal',
  NOTE = 'note',
  ORGANIZATION = 'organization',
  PERSON = 'person',
}

export type ProcessWebhookRecordMetaData = {
  id: number;
  object: SupportedObjects;
  action: EventAction;
};

export type ProcessWebhookRecordBodyData = {
  meta?: ProcessWebhookRecordMetaData;
};

export type ProcessWebhookRecordDTO = {
  headers: Record<string, unknown>;
  body: ProcessWebhookRecordBodyData;
  action: EventAction;
};
