import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { RecordType } from '../shared';

import authConfig from './authConfig';
import {
  getRecordInputFields,
  intentTypeInput,
  recordFilterInput,
  recordIdInput,
  recordTypeSelector,
  termInput,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.PIPEDRIVE,
  name: 'Pipedrive',
  description: "Connect to your users' Pipedrive accounts",
  icon: iconSvg,
  provider: ProviderType.PIPEDRIVE,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      if (!parameters.intent) {
        return { inputs: [] };
      }
      return {
        inputs: [recordTypeSelector(parameters.intent as Intent)],
      };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const recordType: RecordType = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'recordType',
      )?.value;

      if (!recordType) {
        return { inputs: [] };
      }

      switch (parameters.intent) {
        case Intent.CREATE_RECORD: {
          return { inputs: getRecordInputFields()[recordType] };
        }
        case Intent.UPDATE_RECORD: {
          return { inputs: getRecordInputFields(true)[recordType] };
        }
        case Intent.GET_RECORD_BY_ID: {
          return { inputs: [recordIdInput] };
        }
        case Intent.GET_RECORDS: {
          return {
            inputs: [
              termInput,
              ...(recordType === RecordType.ORGANIZATIONS ? [] : [recordFilterInput]),
            ],
          };
        }
        case Intent.DELETE_RECORD: {
          return { inputs: [{ ...recordIdInput, subtitle: 'The ID of the event to delete.' }] };
        }
        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
