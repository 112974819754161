import React from 'react';

import { EnumInputValue, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

export const ticketTypes: EnumInputValue[] = [
  { value: 'incident', label: 'Incident' },
  { value: 'case', label: 'Case' },
];

export const severityTypes: EnumInputValue[] = [
  { value: 1, label: 'Critical' },
  { value: 2, label: 'High' },
  { value: 3, label: 'Moderate' },
  { value: 4, label: 'Low' },
  { value: 5, label: 'Planning. Incident only.' },
];

export const referredTypes: EnumInputValue[] = [
  { value: 'customer', label: 'Customer' },
  { value: 'customer_contact', label: 'Customer contact' },
];

export const ticketIdInput: SidebarInput = {
  id: 'ticketId',
  type: SidebarInputType.Text,
  title: 'Ticket ID',
  subtitle: 'Specify the sys_id ID of the ticket to fetch.',
  required: true,
  placeholder: '9d385017c611228701d22104cc95c371',
};

export const ticketTypeInput: SidebarInput = {
  id: 'ticketType',
  type: SidebarInputType.EditableEnum,
  title: 'Ticket Type',
  subtitle: 'Specify the type of the ticket to create.',
  required: true,
  getValues: (): EnumInputValue[] => ticketTypes,
  defaultValue: ticketTypes[0].value,
};

export const ticketDescriptionInput: SidebarInput = {
  id: 'description',
  type: SidebarInputType.Text,
  title: 'Full Description',
  subtitle: 'Specify a long-form description of the issue.',
  required: false,
};

export const ticketSeverityInput: SidebarInput = {
  id: 'severity',
  type: SidebarInputType.EditableEnum,
  title: 'Severity',
  subtitle:
    'Specify the severity of the issue described by the trouble ticket. Maps to the Priority field.',
  required: false,
  getValues: (): EnumInputValue[] => severityTypes,
};

export const ticketNameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.Text,
  title: 'Name',
  subtitle:
    'Specify the name of the ticket, typically a short description of the issue. Maps to the Short description field.',
  required: false,
  placeholder: 'Bug report from Customer A',
};

export const ticketShortDescriptionInput: SidebarInput = {
  id: 'shortDescription',
  type: SidebarInputType.Text,
  title: 'Short Description',
  subtitle: 'Specify a one-line description of the issue.',
  required: false,
};

export const ticketStatusInput: SidebarInput = {
  id: 'status',
  type: SidebarInputType.Text,
  title: 'Status',
  subtitle:
    'Specify the current status of the ticket. Maps to the State field. You may provide the choice label or value.',
  required: false,
  placeholder: 'New',
};

export const ticketRelatedPartyIdInput: SidebarInput = {
  id: 'relatedPartyId',
  type: SidebarInputType.Text,
  title: 'Related party ID',
  subtitle: 'Specify the sys_id of the related party associated with this ticket.',
  required: false,
  placeholder: '9d385017c611228701d22104cc95c371',
};

export const ticketReferredTypeInput: SidebarInput = {
  id: 'referredType',
  type: SidebarInputType.EditableEnum,
  title: 'Related Party Type',
  subtitle: 'Specify the type of the related party associated with this ticket.',
  required: false,
  getValues: (): EnumInputValue[] => referredTypes,
};

export const ticketChannelName: SidebarInput = {
  id: 'channelName',
  type: SidebarInputType.Text,
  title: 'Channel Name',
  subtitle: 'Specify the name of the contact method.',
  required: false,
  placeholder: 'Virtual Agent',
};

export const ticketNote: SidebarInput = {
  id: 'note',
  type: SidebarInputType.Code,
  title: 'Notes',
  subtitle: (
    <>
      Specify a list of work notes and comments to add to the ticket.
      <a
        href="https://docs.servicenow.com/bundle/paris-application-development/page/integrate/inbound-rest/concept/trouble-ticket-open-api.html#trouble-ticket-PATCH-trouble_ticket"
        target="_blank"
        rel="noreferrer"
      >
        See the docs
      </a>
      for available properties for a note object.
    </>
  ),
  language: 'json',
  required: false,
  placeholder: `
[
  {
    "text": "Note content",
    "@type": "work_notes"
  }
]`,
  lines: 6,
};
