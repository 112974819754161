import { indexBy } from '../../../utils';
import { Action, IntegrationEntities } from '../../types';

export const initialState: IntegrationEntities = {
  entities: {},
  errorMessage: undefined,
  processing: false,
  activeIntegrationsCount: 0,
};

export function handler(
  state: IntegrationEntities = initialState,
  action: Action,
): IntegrationEntities {
  switch (action.type) {
    case 'INTEGRATION_ENTITY_FETCH_START':
    case 'INTEGRATION_ENTITY_CREATE_START':
    case 'INTEGRATION_ENTITY_DELETE_START':
    case 'INTEGRATION_ENTITY_UPDATE_START':
      return {
        ...state,
        processing: true,
      };
    case 'INTEGRATION_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        entities: { ...state.entities, ...indexBy('id', action.integrations) },
        processing: false,
        errorMessage: undefined,
      };
    case 'INTEGRATION_ENTITY_CREATE_SUCCESS':
    case 'INTEGRATION_ENTITY_UPDATE_SUCCESS':
      return {
        ...state,
        entities: { ...state.entities, [action.integrationEntity.id]: action.integrationEntity },
        processing: false,
        errorMessage: undefined,
      };
    case 'INTEGRATION_ENTITY_DELETE_SUCCESS':
      const { [action.id]: deletedIntegration, ...remainingIntegrations } = state.entities;
      return {
        ...state,
        entities: remainingIntegrations,
        processing: false,
        errorMessage: undefined,
      };
    case 'INTEGRATION_ENTITY_FETCH_FAILED':
    case 'INTEGRATION_ENTITY_CREATE_FAILED':
    case 'INTEGRATION_ENTITY_DELETE_FAILED':
    case 'INTEGRATION_ENTITY_UPDATE_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'ACTIVE_INTEGRATIONS_COUNT_FETCH_SUCCESS':
      return {
        ...state,
        processing: false,
        errorMessage: undefined,
        activeIntegrationsCount: action.payload,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    case 'INTEGRATION_WIZARD_DRAFT_LOAD':
      const { integration } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          // Custom integrations never reach a connected user limit, so setting it to 0 is safe.
          [integration.id]: { ...integration, connectedUserCount: 0 },
        },
      };
    case 'INTEGRATION_WIZARD_SAVE_SUCCESS':
      const customIntegration = action.payload;
      const targetIntegration = Object.values(state.entities).find(
        (integration) => integration.customIntegrationId === customIntegration.id,
      );
      if (!targetIntegration) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [targetIntegration.id]: { ...targetIntegration, customIntegration },
        },
      };

    default:
      return state;
  }
}
