import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MAILGUN;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'MAILGUN_TEST_CONNECTION',
  SEND_EMAIL = 'MAILGUN_SEND_EMAIL',
  LIST_TEMPLATES = 'MAILGUN_LIST_TEMPLATES',
  SEND_TEMPLATE_EMAIL = 'MAILGUN_SEND_TEMPLATE_EMAIL',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.SEND_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_TEMPLATES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_TEMPLATE_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  MAILGUN_API_KEY: string;
  MAILGUN_SENDING_DOMAIN: string;
  EU_HOSTED: string;
};
