import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_SEARCH_CONSOLE;
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_SEARCH_CONSOLE;
};

export enum Intent {
  TEST_CONNECTION = 'GOOGLE_SEARCH_CONSOLE_TEST_CONNECTION',
  QUERY_SEARCH_DATA = 'GOOGLE_SEARCH_CONSOLE_QUERY_SEARCH_DATA',
  GET_ALL_SITES = 'GOOGLE_SEARCH_CONSOLE_GET_ALL_SITES',
  CREATE_SITE = 'GOOGLE_SEARCH_CONSOLE_CREATE_SITE',
  INSPECT_INDEX_INFO = 'GOOGLE_SEARCH_CONSOLE_INSPECT_INDEX_INFO',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | { intent: OAuthIntent.REFRESH_CREDENTIAL; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: Intent.QUERY_SEARCH_DATA;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_SITES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.INSPECT_INDEX_INFO;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_SITE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ACCESS_TOKEN = 'GOOGLE_SEARCH_CONSOLE_ACCESS_TOKEN',
  REFRESH_TOKEN = 'GOOGLE_SEARCH_CONSOLE_REFRESH_TOKEN',
  SCOPES = 'GOOGLE_SEARCH_CONSOLE_SCOPES',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
};
