import { UnselectedTriggerStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';

/**
 * a resolver for an unselected trigger step's variables
 *
 * @export
 * @class UnselectedTriggerStepResolver
 * @extends {StepResolver<UnselectedTriggerStep, ResolvedCronParameters>}
 */
export class UnselectedTriggerStepResolver extends StepResolver<
  UnselectedTriggerStep,
  UnselectedTriggerStep['parameters']
> {
  /**
   * resolves the input parameters for the step
   *
   * @param {ActionStepParameters} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {UnselectedTriggerStep['parameters']}
   * @memberof UnselectedTriggerStepResolver
   */
  resolveInputParameters(
    parameters: UnselectedTriggerStep['parameters'],
  ): UnselectedTriggerStep['parameters'] {
    return parameters;
  }

  getRequiredStepIdsForVariableResolution(_: UnselectedTriggerStep['parameters']): string[] {
    return [];
  }
}
