import React from 'react';

import {
  IntegrationConnectInput,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { CredentialKeys, Intent } from '../configs/config';
import { getUsersKey } from '../shared/constants';
import { LinkWrapperType } from '../shared/types';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Applications',
          items: [
            {
              value: Intent.CREATE_APPLICATION,
              label: 'Create Application',
            },
            {
              value: Intent.UPDATE_APPLICATION,
              label: 'Update Application',
            },
            {
              value: Intent.GET_APPLICATION_BY_ID,
              label: 'Get Application By ID',
            },
            {
              value: Intent.DELETE_APPLICATION,
              label: 'Delete Application',
            },
          ],
        },
        {
          title: 'Candidate',
          items: [
            {
              value: Intent.CREATE_CANDIDATE,
              label: 'Create Candidate',
            },
            {
              value: Intent.UPDATE_CANDIDATE,
              label: 'Update Candidate',
            },
            {
              value: Intent.GET_CANDIDATE_BY_ID,
              label: 'Get Candidate By ID',
            },
            {
              value: Intent.DELETE_CANDIDATE,
              label: 'Delete Candidate',
            },
          ],
        },
        {
          title: 'Job Opening',
          items: [
            {
              value: Intent.CREATE_JOB_OPENING,
              label: 'Create Job Opening',
            },
            {
              value: Intent.UPDATE_JOB_OPENING,
              label: 'Update Job Opening',
            },
            {
              value: Intent.GET_JOB_OPENING_BY_ID,
              label: 'Get Job Opening By ID',
            },
          ],
        },
      ],
    },
  ],
};

export const openingIdInput: SidebarInput = {
  id: 'openingId',
  title: 'Opening ID',
  subtitle:
    'This is a string that contains an opening_id. This may be a blank string. Changing an opening_id may re-trigger approvals. For approvals to start recruiting, this will reset approvals only if the job is in draft mode. If the job is open for hiring, these approvals will not reset. For official job approvals, this will reset approvals only if the job is open.',
  type: SidebarInputType.TextArea,
  placeholder: '4007253006',
  lines: 1,
  required: false,
};

export const openingIdsInput: SidebarInput = {
  id: 'openingIds',
  title: 'Opening IDs',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  placeholder: `[
  {
      "opening_id": "1213"
  },
  {
      "opening_id": null
  }
]`,
  useLightTheme: true,
  required: false,
};

export const openingsInput: SidebarInput = {
  id: 'openings',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  title: 'Openings',
  required: true,
  subtitle: 'This an array of hashes, which can contain an opening ID and custom fields.',
  placeholder: `[
  {
      "opening_id": "abc-123",
      "custom_fields": [ { "id": 123, "value": "jacob" } ]
  }
]`,
  useLightTheme: true,
};

export const candidateIdInput: SidebarInput = {
  id: 'candidateId',
  title: 'Candidate ID',
  subtitle: 'The ID of the candidate to retrieve.',
  type: SidebarInputType.TextArea,
  placeholder: '4981013006',
  lines: 1,
  required: true,
};

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  title: 'First Name',
  subtitle: 'The candidate’s first name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  title: 'Last Name',
  subtitle: 'The candidate’s last name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const companyInput: SidebarInput = {
  id: 'company',
  title: 'Company',
  subtitle: 'The candidate’s company',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const titleInput: SidebarInput = {
  id: 'title',
  title: 'Title',
  subtitle: 'The candidate’s title',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const addressesInput: SidebarInput = {
  id: 'addresses',
  title: 'Addresses',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  placeholder: `[
  {
    "value": "123 Fake St.",
    "type": "home"
  }
]`,
  useLightTheme: true,
  required: false,
};

export const emailAddressesInput: SidebarInput = {
  id: 'emailAddresses',
  title: 'Enail Addresses',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  placeholder: `[
  {
    "value": "john.locke+work@example.com",
    "type": "work"
  }
]`,
  useLightTheme: true,
  required: false,
};

export const phoneNumbersInput: SidebarInput = {
  id: 'phoneNumbers',
  title: 'Phone Numbers',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  placeholder: `[
  {
    "value": "555-1212",
    "type": "mobile"
  }
]`,
  useLightTheme: true,
  required: false,
};

export const websiteAddressesInput: SidebarInput = {
  id: 'websiteAddresses',
  title: 'Website Addresses',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  placeholder: `[
  {
    "value": "johnlocke.example.com",
    "type": "personal"
  }
]`,
  useLightTheme: true,
  required: false,
};

export const sourceIdInput: SidebarInput = {
  id: 'sourceId',
  title: 'Source ID',
  subtitle: 'The ID of the source to be credited for this application',
  type: SidebarInputType.TextArea,
  placeholder: '7',
  lines: 1,
  required: false,
};

export const applicationTypeInput: SidebarInput = {
  id: 'applicationType',
  title: 'Application Type',
  type: SidebarInputType.Enum,
  required: true,
  getValues: () => [
    { label: 'Candidate', value: 'candidate' },
    { label: 'Prospect', value: 'prospect' },
  ],
  defaultValue: 'candidate',
};

export const jobIdInput: SidebarInput = {
  id: 'jobId',
  title: 'Job ID',
  subtitle: 'The ID of the job you want to create an application to for this candidate',
  type: SidebarInputType.TextArea,
  placeholder: '4007253006',
  lines: 1,
  required: true,
};

export const referrerInput: SidebarInput = {
  id: 'referrer',
  title: 'Referrer',
  subtitle: 'An object representing the referrer',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 3,
  placeholder: `{
  "type": "id",
  "value": 770
}`,
  useLightTheme: true,
  required: false,
};

export const applicationIdInput: SidebarInput = {
  id: 'applicationId',
  title: 'Application ID',
  subtitle: 'The ID of the application to retrieve.',
  type: SidebarInputType.TextArea,
  placeholder: '5077244006',
  lines: 1,
  required: true,
};

export const internalOpeningIdInput: SidebarInput = {
  id: 'internalOpeningId',
  title: 'ID',
  subtitle: 'The ID of the opening and this is the immutable internal id.',
  type: SidebarInputType.TextArea,
  placeholder: '4034240006',
  lines: 1,
  required: true,
};

export const customFieldsInput: SidebarInput = {
  id: 'customFields',
  title: 'Custom Fields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  placeholder: `{ 
  "id": 123, 
  "value": "some value" 
}`,
  useLightTheme: true,
  required: false,
};

export const statusInput: SidebarInput = {
  id: 'status',
  title: 'Status',
  type: SidebarInputType.Enum,
  required: true,
  getValues: () => [
    { label: 'Open', value: 'open' },
    { label: 'Closed', value: 'closed' },
  ],
};

export const upsertApplicationInputs = (
  isCandidateAapplicationValue: boolean = true,
): SidebarInput[] => [
  ...(isCandidateAapplicationValue ? [jobIdInput] : []),
  sourceIdInput,
  referrerInput,
  ...(isCandidateAapplicationValue
    ? [
        {
          ...additionalFieldsInput,
          placeholder: `{
"initial_stage_id": 2708728,
}`,
        },
      ]
    : []),
];

export const applicationsInput: SidebarInput = {
  id: 'applications',
  title: 'Applications',
  subtitle: 'An array of application objects.',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 3,
  placeholder: `[
  {
    "job_id": 215725
  }
]`,
  useLightTheme: true,
  required: true,
};

export const LinkWrapper: React.FC<LinkWrapperType> = ({
  clickableText,
  href,
}: LinkWrapperType) => (
  <a href={href} target="_blank" rel="noreferrer">
    {clickableText}
  </a>
);

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Additional Fields',
  subtitle: (
    <>
      Specify additional fields to include on this candidate data as JSON. For example, you may
      associate tags and is_private here.{' '}
      <LinkWrapper
        clickableText="See the docs"
        href="https://developers.greenhouse.io/harvest.html?shell#post-add-candidate"
      />{' '}
      for available fields.
    </>
  ),
  required: false,
  placeholder: `{
  "tags": [ 
    "Walkabout" 
  ],
  "is_private": false
}`,
  useLightTheme: true,
};

export const getUsersInput: IntegrationConnectInput = {
  id: CredentialKeys.GREENHOUSE_USER_ID,
  title: 'Choose a Greenhouse users to use',
  placeholder: 'Users',
  type: SidebarInputType.DynamicEnum,
  required: true,
  sourceType: getUsersKey,
};
