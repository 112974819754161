import {
  AuthenticationScheme,
  ProviderType,
  SupportedOAuthAppProviders,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  oauthConfig as asanaOauthConfig,
  requiredScopes as asanaRequiredScopes,
} from '@shared/integrations/asana/configs/oauth';
import {
  oauthConfig as azureDevOpsOauthConfig,
  requiredScopes as azureDevOpsRequiredScopes,
} from '@shared/integrations/azuredevops/configs/oauth';
import {
  oauthConfig as bambooHrOauthConfig,
  requiredScopes as bambooHrRequiredScopes,
} from '@shared/integrations/bamboohr/configs/oauth';
import {
  oauthConfig as bigQueryOauthConfig,
  requiredScopes as bigQueryRequiredScopes,
} from '@shared/integrations/bigquery/configs/oauth';
import { oauthConfig as calendlyOauthConfig } from '@shared/integrations/calendly/configs/oauth';
import { oauthConfig as clickUpOauthConfig } from '@shared/integrations/clickup/configs/oauth';
import {
  oauthConfig as docuSignOauthConfig,
  requiredScopes as docuSignRequiredScopes,
} from '@shared/integrations/docusign/configs/oauth';
import { oauthConfig as dropboxOauthConfig } from '@shared/integrations/dropbox/configs/oauth';
import {
  oauthConfig as dynamicsBusinessCentralOauthConfig,
  requiredScopes as dynamicsBusinessCentralRequiredScopes,
} from '@shared/integrations/dynamicsbusinesscentral/configs/oauth';
import {
  oauthConfig as dynamicsFinanceOauthConfig,
  requiredScopes as dynamicsFinanceRequiredScopes,
} from '@shared/integrations/dynamicsfinance/configs/oauth';
import {
  oauthConfig as eloquaOauthConfig,
  requiredScopes as eloquaRequiredScopes,
} from '@shared/integrations/eloqua/configs/oauth';
import {
  oauthConfig as facebookAdsOauthConfig,
  requiredScopes as facebookAdsRequiredScopes,
} from '@shared/integrations/facebookAds/configs/oauth';
import { oauthConfig as githubOauthConfig } from '@shared/integrations/github/configs/oauth';
import { requiredScopes as gmailRequiredScopes } from '@shared/integrations/gmail/configs/oauth';
import {
  oauthConfig as gongOauthConfig,
  requiredScopes as gongRequiredScopes,
} from '@shared/integrations/gong/configs/oauth';
import {
  oauthConfig as googleAdManagerOauthConfig,
  requiredScopes as googleAdManagerRequiredScopes,
} from '@shared/integrations/googleadmanager/configs/oauth';
import {
  oauthConfig as googleAdsOauthConfig,
  requiredScopes as googleAdsRequiredScopes,
} from '@shared/integrations/googleads/configs/oauth';
import { requiredScopes as googleAnalyticsRequiredScopes } from '@shared/integrations/googleanalytics/configs/oauth';
import { requiredScopes as googleCalendarRequiredScopes } from '@shared/integrations/googleCalendar/configs/oauth';
import { requiredScopes as googleCampaignManagerRequiredScopes } from '@shared/integrations/googlecampaignmanager/configs/oauth';
import {
  oauthConfig as googleDriveOauthConfig,
  requiredScopes as googleDriveRequiredScopes,
} from '@shared/integrations/googledrive/configs/oauth';
import { requiredScopes as googleSearchConsoleRequiredScopes } from '@shared/integrations/googlesearchconsole/configs/oauth';
import { requiredScopes as googleSheetsRequiredScopes } from '@shared/integrations/googlesheets/configs/oauth';
import { oauthConfig as gustoOauthConfig } from '@shared/integrations/gusto/configs/oauth';
import {
  oauthConfig as hubspotOauthConfig,
  requiredScopes as hubspotRequiredScopes,
} from '@shared/integrations/hubspot/configs/oauth';
import {
  oauthConfig as imanageOauthConfig,
  requiredScopes as imanageRequiredScopes,
} from '@shared/integrations/imanage/configs/oauth';
import { oauthConfig as intercomOauthConfig } from '@shared/integrations/intercom/configs/oauth';
import {
  oauthConfig as jiraOauthConfig,
  requiredScopes as jiraRequiredScopes,
} from '@shared/integrations/jira/configs/oauth';
import {
  oauthConfig as leverOauthConfig,
  requiredScopes as leverRequiredScopes,
} from '@shared/integrations/lever/configs/oauth';
import {
  oauthConfig as linearOauthConfig,
  requiredScopes as linearRequiredScopes,
} from '@shared/integrations/linear/configs/oauth';
import { oauthConfig as mailchimpOauthConfig } from '@shared/integrations/mailchimp/configs/oauth';
import {
  oauthConfig as microsoftDynamicsOauthConfig,
  requiredScopes as microsoftDynamicsRequiredScopes,
} from '@shared/integrations/microsoftDynamics/configs/oauth';
import {
  oauthConfig as microsoftTeamsOauthConfig,
  requiredScopes as microsoftTeamsRequiredScopes,
} from '@shared/integrations/microsoftTeams/configs/oauth';
import {
  oauthConfig as netSuiteOauthConfig,
  requiredScopes as netSuiteRequiredScopes,
} from '@shared/integrations/netsuite/configs/oauth';
import {
  oauthConfig as outlookOauthConfig,
  requiredScopes as outlookRequiredScopes,
} from '@shared/integrations/outlook/configs/oauth';
import {
  oauthConfig as outreachOauthConfig,
  requiredScopes as outreachRequiredScopes,
} from '@shared/integrations/outreach/configs/oauth';
import {
  oauthConfig as pardotOauthConfig,
  requiredScopes as pardotRequiredScopes,
} from '@shared/integrations/pardot/configs/oauth';
import { oauthConfig as pipedriveOauthConfig } from '@shared/integrations/pipedrive/configs/oauth';
import {
  oauthConfig as powerBiOauthConfig,
  requiredScopes as powerBiRequiredScopes,
} from '@shared/integrations/powerbi/configs/oauth';
import {
  oauthConfig as quickbooksOauthConfig,
  requiredScopes as quickbooksRequiredScopes,
} from '@shared/integrations/quickbooks/configs/oauth';
import {
  oauthConfig as sageAccountingOauthConfig,
  requiredScopes as sageAccountingRequiredScopes,
} from '@shared/integrations/sageaccounting/configs/oauth';
import { oauthConfig as sageIntacctOauthConfig } from '@shared/integrations/sageintacct/configs/oauth';
import {
  oauthConfig as salesforceOauthConfig,
  requiredScopes as salesforceRequiredScopes,
} from '@shared/integrations/salesforce/configs/oauth';
import { oauthConfig as salesloftOauthConfig } from '@shared/integrations/salesloft/configs/oauth';
import {
  oauthConfig as sharepointOauthConfig,
  requiredScopes as sharepointRequiredScopes,
} from '@shared/integrations/sharepoint/configs/oauth';
import {
  oauthConfig as shopifyOauthConfig,
  requiredScopes as shopifyRequiredScopes,
} from '@shared/integrations/shopify/configs/oauth';
import {
  oauthConfig as slackOauthConfig,
  requiredScopes as slackRequiredScopes,
} from '@shared/integrations/slack/configs/oauth';
import {
  oauthConfig as tableauOauthConfig,
  requiredScopes as tableauRequiredScopes,
} from '@shared/integrations/tableau/configs/oauth';
import {
  oauthConfig as trelloOauthConfig,
  requiredScopes as trelloRequiredScopes,
} from '@shared/integrations/trello/configs/oauth';
import { oauthConfig as twitterOauthConfig } from '@shared/integrations/twitter/configs/oauth';
import {
  oauthConfig as xeroOauthConfig,
  requiredScopes as xeroRequiredScopes,
} from '@shared/integrations/xero/configs/oauth';
import {
  oauthConfig as zendeskOauthConfig,
  requiredScopes as zendeskRequiredScopes,
} from '@shared/integrations/zendesk/configs/oauth';
import {
  oauthConfig as zohoCrmOauthConfig,
  requiredScopes as zohoCrmRequiredScopes,
} from '@shared/integrations/zohocrm/configs/oauth';
import {
  oauthConfig as zoomOauthConfig,
  requiredScopes as zoomRequiredScopes,
} from '@shared/integrations/zoom/configs/oauth';
import {
  Action,
  IntegrationConnectInput,
  OAuthCredentialValueDefaults,
  ScopeName,
  SidebarInputType,
} from '@shared/types/sdk/actions';

import { VisibleConnectAction } from '.';

const OAUTH_APP_DEFAULTS: {
  [P in SupportedOAuthAppProviders]: OAuthCredentialValueDefaults;
} = {
  [ProviderType.GITHUB]: githubOauthConfig,
  [ProviderType.GOOGLE]: googleDriveOauthConfig,
  [ProviderType.TABLEAU]: tableauOauthConfig,
  [ProviderType.GONG]: gongOauthConfig,
  [ProviderType.SLACK]: slackOauthConfig,
  [ProviderType.SALESFORCE]: salesforceOauthConfig,
  [ProviderType.QUICKBOOKS]: quickbooksOauthConfig,
  [ProviderType.JIRA]: jiraOauthConfig,
  [ProviderType.HUBSPOT]: hubspotOauthConfig,
  [ProviderType.INTERCOM]: intercomOauthConfig,
  [ProviderType.SHOPIFY]: shopifyOauthConfig,
  [ProviderType.ASANA]: asanaOauthConfig,
  [ProviderType.ZENDESK]: zendeskOauthConfig,
  [ProviderType.MICROSOFT]: microsoftTeamsOauthConfig,
  [ProviderType.ZOOM]: zoomOauthConfig,
  [ProviderType.MICROSOFT_DYNAMICS_365]: microsoftDynamicsOauthConfig,
  [ProviderType.PARDOT]: pardotOauthConfig,
  [ProviderType.XERO]: xeroOauthConfig,
  [ProviderType.OUTREACH]: outreachOauthConfig,
  [ProviderType.FACEBOOK]: facebookAdsOauthConfig,
  [ProviderType.MAILCHIMP]: mailchimpOauthConfig,
  [ProviderType.TRELLO]: trelloOauthConfig,
  [ProviderType.AZURE_DEVOPS]: azureDevOpsOauthConfig,
  [ProviderType.CLICKUP]: clickUpOauthConfig,
  [ProviderType.SHAREPOINT]: sharepointOauthConfig,
  [ProviderType.OUTLOOK]: outlookOauthConfig,
  [ProviderType.ELOQUA]: eloquaOauthConfig,
  [ProviderType.NETSUITE]: netSuiteOauthConfig,
  [ProviderType.DYNAMICS_BUSINESS_CENTRAL]: dynamicsBusinessCentralOauthConfig,
  [ProviderType.PIPEDRIVE]: pipedriveOauthConfig,
  [ProviderType.SAGE_INTACCT]: sageIntacctOauthConfig,
  [ProviderType.LINEAR]: linearOauthConfig,
  [ProviderType.DYNAMICS_365_FINANCE]: dynamicsFinanceOauthConfig,
  [ProviderType.SAGE_ACCOUNTING]: sageAccountingOauthConfig,
  [ProviderType.IMANAGE]: imanageOauthConfig,
  [ProviderType.BAMBOO_HR]: bambooHrOauthConfig,
  [ProviderType.GOOGLE_AD_MANAGER]: googleAdManagerOauthConfig,
  [ProviderType.DROPBOX]: dropboxOauthConfig,
  [ProviderType.DOCUSIGN]: docuSignOauthConfig,
  [ProviderType.GOOGLE_ADS]: googleAdsOauthConfig,
  [ProviderType.LEVER]: leverOauthConfig,
  [ProviderType.CALENDLY]: calendlyOauthConfig,
  [ProviderType.ZOHO_CRM]: zohoCrmOauthConfig,
  [ProviderType.TWITTER]: twitterOauthConfig,
  [ProviderType.SALESLOFT]: salesloftOauthConfig,
  [ProviderType.BIGQUERY]: bigQueryOauthConfig,
  [ProviderType.POWER_BI]: powerBiOauthConfig,
  [ProviderType.GUSTO]: gustoOauthConfig,
};

export default OAUTH_APP_DEFAULTS;

/**
 * this maps actions to required scopes name. to mark them as required.
 */
export const actionRequiredScopesMap: Record<VisibleConnectAction, ScopeName[]> = {
  [Action.GITHUB]: [],
  [Action.GOOGLE_ANALYTICS]: googleAnalyticsRequiredScopes,
  [Action.GOOGLE_CALENDAR]: googleCalendarRequiredScopes,
  [Action.GONG]: gongRequiredScopes,
  [Action.ASANA]: asanaRequiredScopes,
  [Action.GOOGLE_DRIVE]: googleDriveRequiredScopes,
  [Action.GOOGLE_SHEETS]: googleSheetsRequiredScopes,
  [Action.HUBSPOT]: hubspotRequiredScopes,
  [Action.INTERCOM]: [],
  [Action.JIRA]: jiraRequiredScopes,
  [Action.STRIPE]: [],
  [Action.SLACK]: slackRequiredScopes,
  [Action.KLAVIYO]: [],
  [Action.QUICKBOOKS]: quickbooksRequiredScopes,
  [Action.SHOPIFY]: shopifyRequiredScopes,
  [Action.SALESFORCE]: salesforceRequiredScopes,
  [Action.ZENDESK]: zendeskRequiredScopes,
  [Action.MICROSOFT_TEAMS]: microsoftTeamsRequiredScopes,
  [Action.MARKETO]: [],
  [Action.ZOOM]: zoomRequiredScopes,
  [Action.MICROSOFT_DYNAMICS]: microsoftDynamicsRequiredScopes,
  [Action.PARDOT]: pardotRequiredScopes,
  [Action.XERO]: xeroRequiredScopes,
  [Action.OUTREACH]: outreachRequiredScopes,
  [Action.GOOGLE_CAMPAIGN_MANAGER]: googleCampaignManagerRequiredScopes,
  [Action.ORACLE_FINANCIALS_CLOUD]: [],
  [Action.TRELLO]: trelloRequiredScopes,
  [Action.FACEBOOK_ADS]: facebookAdsRequiredScopes,
  [Action.MAILCHIMP]: [],
  [Action.AZURE_DEVOPS]: azureDevOpsRequiredScopes,
  [Action.MONDAY]: [],
  [Action.GOOGLE_SEARCH_CONSOLE]: googleSearchConsoleRequiredScopes,
  [Action.CLICKUP]: [],
  [Action.SHAREPOINT]: sharepointRequiredScopes,
  [Action.OUTLOOK]: outlookRequiredScopes,
  [Action.SERVICENOW]: [],
  [Action.ELOQUA]: eloquaRequiredScopes,
  [Action.NETSUITE]: netSuiteRequiredScopes,
  [Action.DYNAMICS_BUSINESS_CENTRAL]: dynamicsBusinessCentralRequiredScopes,
  [Action.PIPEDRIVE]: [],
  [Action.SAGE_INTACCT]: [],
  [Action.LINEAR]: linearRequiredScopes,
  [Action.DYNAMICS_365_FINANCE]: dynamicsFinanceRequiredScopes,
  [Action.SAGE_ACCOUNTING]: sageAccountingRequiredScopes,
  [Action.WOOCOMMERCE]: [],
  [Action.IMANAGE]: imanageRequiredScopes,
  [Action.BAMBOO_HR]: bambooHrRequiredScopes,
  [Action.GMAIL]: gmailRequiredScopes,
  [Action.GOOGLE_AD_MANAGER]: googleAdManagerRequiredScopes,
  [Action.DROPBOX]: [],
  [Action.MAGENTO]: [],
  [Action.PRODUCTBOARD]: [],
  [Action.DOCUSIGN]: docuSignRequiredScopes,
  [Action.SAP_S4HANA]: [],
  [Action.GOOGLE_ADS]: googleAdsRequiredScopes,
  [Action.GREENHOUSE]: [],
  [Action.LEVER]: leverRequiredScopes,
  [Action.CALENDLY]: [],
  [Action.ZOHO_CRM]: zohoCrmRequiredScopes,
  [Action.SALESLOFT]: [],
  [Action.AIRTABLE]: [],
  [Action.TABLEAU]: tableauRequiredScopes,
  [Action.FRESHDESK]: [],
  [Action.TWITTER]: [],
  [Action.BIGQUERY]: bigQueryRequiredScopes,
  [Action.POWER_BI]: powerBiRequiredScopes,
  [Action.MIXPANEL]: [],
  [Action.SAP_SUCCESS_FACTORS]: [],
  [Action.AMAZON_S3]: [],
  [Action.PANDA_DOC]: [],
  [Action.SEGMENT]: [],
  [Action.GUSTO]: [],
  [Action.SAILTHRU]: [],
  [Action.CLOSE]: [],
};

export const authSchemaRequiredInputs: Record<AuthenticationScheme, IntegrationConnectInput[]> = {
  [AuthenticationScheme.BASIC]: [],
  [AuthenticationScheme.IMPERSONATED_APP]: [],
  [AuthenticationScheme.OAUTH]: [],
  [AuthenticationScheme.OAUTH_APP]: [],
  [AuthenticationScheme.SERVICE_ACCOUNT]: [],
  [AuthenticationScheme.OAUTH_CLIENT_CREDENTIAL]: [
    { id: 'clientId', title: 'Client ID', type: SidebarInputType.ValueText, required: true },
    {
      id: 'clientSecret',
      title: 'Client Secret',
      type: SidebarInputType.ValueText,
      required: true,
    },
  ],
};
