import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class SecretError extends ParagonError {
  name: string = 'SecretError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class SecretNotFoundError extends SecretError {
  name: string = 'SecretNotFoundError';

  constructor(meta: { secretId: string }) {
    super({
      code: ERROR_CODE.SECRET_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find secret.',
      meta,
    });
  }
}

export class DeleteSecretError extends SecretError {
  name: string = 'DeleteSecretError';

  constructor(meta: { secretId: string; projectId: string; error: Error }) {
    super({
      code: ERROR_CODE.DELETE_SECRET,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Failed to delete secret.',
      meta,
    });
  }
}

export class DeleteActiveSecretError extends SecretError {
  name: string = 'DeleteActiveSecretError';

  constructor(meta: { secretId: string; projectId: string }) {
    super({
      code: ERROR_CODE.DELETE_ACTIVE_SECRET,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Unable to delete environment secret. One or more workflows are currently using it.',
      meta,
    });
  }
}

export class InvalidSecretKeyError extends SecretError {
  name: string = 'InvalidSecretKeyError';

  constructor(meta: { secretId: string; projectId: string; key: string }) {
    super({
      code: ERROR_CODE.SECRET_MISMATCH,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Invalid key for secret.',
      meta,
    });
  }
}
