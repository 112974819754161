import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.MIXPANEL,
  provider: ProviderType.MIXPANEL,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Mixpanel account',
  icon: iconSvg,
  description: 'Access, create and update their data in Mixpanel.',
  sidebarSections: [
    {
      inputs: [
        {
          id: CredentialKeys.MIXPANEL_CLIENT_USERNAME,
          type: SidebarInputType.ValueText,
          required: true,
          title: 'Mixpanel username',
        },
        {
          id: CredentialKeys.MIXPANEL_CLIENT_PASSWORD,
          type: SidebarInputType.Password,
          required: true,
          title: 'Mixpanel password',
        },
      ],
      buttons: [createTestConnectionButton(Action.MIXPANEL, Object.values(CredentialKeys))],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.MIXPANEL,
  name: 'Mixpanel',
  description: 'Access, create and update data in Mixpanel.',
  icon: iconSvg,
  sidebarSections: [],
};

export { authConfig };

export default config;
