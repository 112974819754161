import { DecryptedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { IntegrationConnectInput } from '@shared/types/sdk/actions';
import { DataType, Source, TokenizedSource, TokenizedValue } from '@shared/types/sdk/resolvers';

/**
 * This resolver is specific to fields of a custom integration, whose sources are not associated
 * with workflows or steps. Each field is resolved from input fields of the Connect Portal or
 * access tokens/API keys.
 *
 * All of this information can be resolved from a decrypted credential.
 */
export function resolveCustomIntegrationSource<
  TInput extends { payload: { [key: string]: string } },
>(
  source: Source<DataType.STRING>,
  inputFields: IntegrationConnectInput[],
  decryptedConnectCredential: TInput | DecryptedConnectCredential | null,
): string {
  if (source.type === 'VALUE') {
    return source.value;
  } else if (
    source.type === 'CONNECT_CREDENTIAL_FIELD' &&
    source.fieldType === 'WORKFLOW_SETTING'
  ) {
    return (
      (decryptedConnectCredential?.payload[source.inputId] as string | null) ??
      `[${
        inputFields.find((input: IntegrationConnectInput) => input.id === source.inputId)?.title
      }]`
    );
  } else if (
    source.type === 'CONNECT_CREDENTIAL_FIELD' &&
    source.fieldType === 'OAUTH_ACCESS_TOKEN'
  ) {
    return (
      (decryptedConnectCredential?.payload.access_token as string | null) ?? '[OAuth Access Token]'
    );
  } else if (source.type === 'TOKENIZED') {
    return source.parts.reduce<string>((result: string, part: TokenizedValue<DataType>): string => {
      try {
        return `${result}${resolveCustomIntegrationSource(
          part as Source<DataType.STRING>,
          inputFields,
          decryptedConnectCredential,
        )}`;
      } catch (err) {
        return err.message;
      }
    }, '');
  }

  throw new Error('Unable to resolve source.');
}

export function partialResolveCustomIntegrationTokenizedSource<
  TInput extends { payload: { [key: string]: string } },
>(
  source: TokenizedSource<DataType.STRING>,
  inputFields: IntegrationConnectInput[],
  decryptedConnectCredential: TInput | DecryptedConnectCredential | null,
): TokenizedSource<DataType.STRING> {
  return {
    ...source,
    parts: source.parts.map(
      (part: TokenizedValue<DataType.STRING>): TokenizedValue<DataType.STRING> => {
        let resolvedPart: string;
        try {
          resolvedPart = resolveCustomIntegrationSource(
            part,
            inputFields,
            decryptedConnectCredential,
          );
        } catch {
          return part;
        }
        return { type: 'VALUE', value: resolvedPart };
      },
    ),
  };
}
