export type { Credentials } from '../configs/config';

export type Environment = {
  DYNAMICS_BUSINESS_CENTRAL_CLIENT_ID: string;
  DYNAMICS_BUSINESS_CENTRAL_CLIENT_SECRET: string;
  DYNAMICS_BUSINESS_CENTRAL_SCOPES: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: string;
};

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
};

export type DynamicsBusinessCentralUserData = {
  userSecurityId: string;
  userName: string;
  displayName: string;
  state: string;
  expiryDate: string;
};

export type GetCompaniesResponse = {
  '@odata.context': string;
  value: Company[];
};

export type Company = {
  id: string;
  systemVersion: string;
  name: string;
  displayName: string;
  businessProfileId: string;
};

export type IdTokenDecodedData = {
  oid: string;
  unique_name: string;
  name: string;
};

export type GetEnvironmentResponse = {
  value: BusinessCentralEnvironment[];
};

export type BusinessCentralEnvironment = {
  aadTenantId: string;
  applicationFamily: string;
  type: string;
  name: string;
  countryCode: string;
  webServiceUrl: string;
  webClientLoginUrl: string;
};

export enum RecordType {
  VENDORS = 'vendors',
  PURCHASE_INVOICES = 'purchaseInvoices',
  PURCHASE_INVOICE_LINES = 'purchaseInvoiceLines',
  TAX_GROUPS = 'taxGroups',
  PAYMENT_TERMS = 'paymentTerms',
  ACCOUNTS = 'accounts',
}

export type VendorCreateUpdateDTO = {
  vendorId?: string;
  vendorNumber?: string;
  vendorName?: string;
  vendorEmail?: string;
  taxRegNumber?: string;
  paymentTermsId?: string; // not accepted by the api as per docs
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  vendorsAdditionalFields?: string | object;
};

export type PurchaseInvoiceCreateUpdateDTO = {
  purchaseInvoiceId?: string;
  purchaseInvoiceVendorId?: string;
  vendorInvoiceNumber?: string;
  invoiceDate?: string;
  postingDate?: string;
  dueDate?: string;
  invoiceStatus?: string;
  purchaseInvoiceAdditionalFields?: string;
};

export type PurchaseInvoiceLineCreateUpdateDTO = {
  parentInvoiceId?: string;
  purchaseInvoiceLineId?: string;
  lineType?: string;
  resourceNumber?: string;
  lineDescription?: string;
  quantity?: string;
  unitCost?: string;
  accountId?: string;
  measureCodeUnit?: string;
  textAreaCode?: string; //verify it
  taxPercent?: string;
  taxGroupCode?: string;
  purchaseInvoiceLineAdditionalFields?: string;
};

export type TaxGroupCreateUpdateDTO = {
  taxGroupId?: string;
  taxGroupCode?: string;
  taxGroupName?: string;
  taxGroupType?: string;
};

export type PaymentTermsCreateUpdateDTO = {
  paymentTermCode?: string;
  paymentTermName?: string;
  dueDateCalculation?: string;
  discountDateCalculation?: string;
  discountPercent?: string;
  paymentTermsId?: string;
};

export type AccumulatedCreateUpdateDTO =
  | PaymentTermsCreateUpdateDTO
  | TaxGroupCreateUpdateDTO
  | PurchaseInvoiceLineCreateUpdateDTO
  | PurchaseInvoiceCreateUpdateDTO
  | VendorCreateUpdateDTO;

export type SearchRecordDTO = {
  recordType: string;
  parentInvoiceId?: string;
  filterFormula?: string;
};

export type DeleteRecordDTO = {
  recordType: RecordType;
  vendorId: string;
  purchaseInvoiceId: string;
  purchaseInvoiceLineId: string;
  taxGroupId: string;
  paymentTermsId: string;
};

export type GetRecordByIdDTO = {
  recordType: RecordType;
  vendorId: string;
  purchaseInvoiceId: string;
  purchaseInvoiceLineId: string;
  taxGroupId: string;
  paymentTermsId: string;
};

export type VendorCreateUpdateAPIPayload = {
  displayName: string;
  id?: string;
  email: string;
  taxRegistrationNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  number: string;
  paymentTermsId: string;
  country: string;
};

export type PurchaseInvoiceCreateUpdateAPIPayload = {
  id?: string;
  vendorId: string;
  vendorInvoiceNumber: string;
  postingDate: string;
  invoiceDate: string;
  dueDate: string;
};

export type PurchaseInvoiceLineCreateUpdateAPIPayload = {
  lineType: string;
  id?: string;
  lineObjectNumber: string;
  description: string;
  quantity: number | string;
  unitCost: number | string;
  accountId: string;
  unitOfMeasureCode: string;
  taxCode: string;
  taxPercent: number | string;
};

export type TaxGroupCreateUpdateAPIPayload = {
  id?: string;
  displayName: string;
  code: string;
};

export type PaymentTermsCreateUpdateAPIPayload = {
  id?: string;
  displayName: string;
  code: string;
  dueDateCalculation: string;
  discountDateCalculation: string;
  discountPercent: number;
};

export type AccumulatedCreateUpdateAPIPayload =
  | PaymentTermsCreateUpdateAPIPayload
  | TaxGroupCreateUpdateAPIPayload
  | PurchaseInvoiceLineCreateUpdateAPIPayload
  | PurchaseInvoiceCreateUpdateAPIPayload
  | VendorCreateUpdateAPIPayload;

export type T = {
  [RecordType.VENDORS]: VendorCreateUpdateAPIPayload;
  [RecordType.TAX_GROUPS]: VendorCreateUpdateAPIPayload;
  [RecordType.PURCHASE_INVOICES]: VendorCreateUpdateAPIPayload;
  [RecordType.PURCHASE_INVOICE_LINES]: VendorCreateUpdateAPIPayload;
  [RecordType.PAYMENT_TERMS]: VendorCreateUpdateAPIPayload;
};
