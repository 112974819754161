import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.STRIPE;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  CREATE_CUSTOMER = 'STRIPE_CREATE_CUSTOMER',
  GET_CUSTOMER_BY_ID = 'STRIPE_GET_CUSTOMER_BY_ID',
  GET_CUSTOMERS = 'STRIPE_GET_CUSTOMERS',
  CREATE_SUBSCRIPTION = 'STRIPE_CREATE_SUBSCRIPTION',
  GET_SUBSCRIPTIONS = 'STRIPE_GET_SUBSCRIPTIONS',
  GET_PRICES = 'STRIPE_GET_PRICES',
  TEST_CONNECTION = 'STRIPE_TEST_CONNECTION',
  UPDATE_CUSTOMER = 'STRIPE_UPDATE_CUSTOMER',
  CREATE_PRODUCT = 'STRIPE_CREATE_PRODUCT',
  GET_PRODUCT_BY_ID = 'STRIPE_GET_PRODUCT_BY_ID',
  GET_PRODUCTS = 'STRIPE_GET_PRODUCTS',
  GET_BALANCE_TRANSACTIONS = 'STRIPE_GET_BALANCE_TRANSACTIONS',
  GET_PLANS = 'STRIPE_GET_PLANS',
}

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent. SendSMSParameters should be
// defined in this file.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.CREATE_CUSTOMER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CUSTOMER_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.GET_CUSTOMERS; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.CREATE_SUBSCRIPTION; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_SUBSCRIPTIONS; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_PRICES; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CUSTOMER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PRODUCT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PRODUCT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_BALANCE_TRANSACTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PLANS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PRODUCTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  STRIPE_API_SECRET_KEY?: string; // used for API key authentication . Non oauth trigger only.
  STRIPE_OAUTH_REFRESH_TOKEN?: string;
  STRIPE_OAUTH_ACCESS_TOKEN?: string;
  SCOPES?: string;
};
