import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedDelayParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { DelayStep, DelayUnit, StepType } from '@shared/types/sdk/steps';
import { convertMillisecondsToReadableForm, timeToMilliseconds } from '@shared/utils/sdk/generic';

import { getDelayLimitation } from '../index';
import { isNumberType } from '../workflow.utils';

import { StepValidator } from './abstract.validator';
import { shouldAssertValue } from './validator.utils';

/**
 * validates that a delay step is properly configured
 *
 * @export
 * @class DelayStepValidator
 * @extends {StepValidator<DelayStep, ResolvedDelayParameters>}
 */
export default class DelayStepValidator extends StepValidator<DelayStep, ResolvedDelayParameters> {
  stepType: DelayStep['type'] = StepType.DELAY;

  /**
   * validates that a step is valid
   *
   * @param {DelayStep} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {DelayStep}
   * @memberof DelayStepValidator
   */
  validate(
    step: DelayStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): DelayStep {
    const { unit, value }: ResolvedDelayParameters = this.resolveVariables(
      step,
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
    );
    const limitation: number | undefined = context.plan && getDelayLimitation(context.plan).data;

    if (!unit) {
      throw new Error('You must specify delay unit for this Step.');
    } else if (!Object.values(DelayUnit).find((delayUnit: string) => delayUnit === unit)) {
      throw new Error(`${unit} is not valid delay unit.`);
    } else if (shouldAssertValue(step.parameters.value, value)) {
      if (!isNumberType(value)) {
        throw new Error('You cannot specify delay as non-number type.');
      } else if (value < 0) {
        throw new Error('You cannot add delay as negative value.');
      } else if (typeof limitation === 'undefined') {
        throw new Error('Delay limitation not found for current billing plan: ' + context.plan);
      } else {
        const milliseconds: number = timeToMilliseconds(unit, value);
        if (limitation < milliseconds) {
          throw new Error(
            `You can only add a maximum delay of ${convertMillisecondsToReadableForm(
              limitation,
            )} on your current billing plan.`,
          );
        }
      }
    }

    return step;
  }
}
