import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SHOPIFY;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SHOPIFY_TEST_CONNECTION',
  GET_CUSTOMERS = 'SHOPIFY_GET_CUSTOMERS',
  SEARCH_CUSTOMERS = 'SHOPIFY_SEARCH_CUSTOMERS',
  CREATE_CUSTOMER = 'SHOPIFY_CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'SHOPIFY_UPDATE_CUSTOMER',
  GET_CUSTOMERS_PROPERTIES = 'SHOPIFY_GET_CUSTOMERS_PROPERTIES',
  GET_ORDERS = 'SHOPIFY_GET_ORDERS',
  CREATE_ORDER = 'SHOPIFY_CREATE_ORDER',
  UPDATE_ORDER = 'SHOPIFY_UPDATE_ORDER',
  GET_ABANDONED_CARTS = 'SHOPIFY_GET_ABANDONED_CARTS',
  GET_PRODUCTS = 'SHOPIFY_GET_PRODUCTS',
  CREATE_PRODUCT = 'SHOPIFY_CREATE_PRODUCT',
  UPDATE_PRODUCT = 'SHOPIFY_UPDATE_PRODUCT',
  TRIGGER_ORDER_CREATED = 'SHOPIFY_TRIGGER_ORDER_CREATED',
  TRIGGER_ORDER_UPDATED = 'SHOPIFY_TRIGGER_ORDER_UPDATED',
  TRIGGER_CUSTOMER_CREATED = 'SHOPIFY_TRIGGER_CUSTOMER_CREATED',
  TRIGGER_CUSTOMER_UPDATED = 'SHOPIFY_TRIGGER_CUSTOMER_UPDATED',
  TRIGGER_PRODUCT_CREATED = 'SHOPIFY_TRIGGER_PRODUCT_CREATED',
  TRIGGER_PRODUCT_UPDATED = 'SHOPIFY_TRIGGER_PRODUCT_UPDATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CUSTOMER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CUSTOMER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CUSTOMERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CUSTOMERS_PROPERTIES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_CUSTOMERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_ORDER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_ORDER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ORDERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PRODUCT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_PRODUCT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PRODUCTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ORDER_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ORDER_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_CUSTOMER_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_CUSTOMER_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_PRODUCT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_PRODUCT_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ABANDONED_CARTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;
  SHOP_NAME: string;
  OAUTH_ACCESS_TOKEN: string;
};
