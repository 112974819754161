import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_CALENDAR;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'GOOGLE_CALENDAR_TEST_CONNECTION',
  CREATE_EVENT = 'GOOGLE_CALENDAR_CREATE_EVENT',
  UPDATE_EVENT = 'GOOGLE_CALENDAR_UPDATE_EVENT',
  LIST_EVENTS = 'GOOGLE_CALENDAR_LIST_EVENTS',
  GET_EVENT_BY_ID = 'GOOGLE_CALENDAR_GET_EVENT_BY_ID',
  DELETE_EVENT = 'GOOGLE_CALENDAR_DELETE_EVENT',
  GET_CALENDARS = 'GOOGLE_CALENDAR_GET_CALENDARS',

  TRIGGER_EVENT_CREATED = 'GOOGLE_CALENDAR_TRIGGER_EVENT_CREATED',
  TRIGGER_EVENT_UPDATED = 'GOOGLE_CALENDAR_TRIGGER_EVENT_UPDATED',
  TRIGGER_EVENT_CANCELLED = 'GOOGLE_CALENDAR_TRIGGER_EVENT_CANCELLED',
  TRIGGER_EVENT_STARTED = 'GOOGLE_CALENDAR_TRIGGER_EVENT_STARTED',
  TRIGGER_EVENT_ENDED = 'GOOGLE_CALENDAR_TRIGGER_EVENT_ENDED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_EVENTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_EVENT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CALENDARS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_EVENT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_EVENT_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_EVENT_CANCELLED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_EVENT_STARTED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_EVENT_ENDED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;
  OAUTH_ACCESS_TOKEN: string;
};
