import { Unit } from '../steps';
import { BillingPlan } from '../stripe';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class ProductLimitationError extends ParagonError {
  name: string = 'ProductLimitationError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({ code, message, meta }: { code: ERROR_CODE; message: string; meta: object }) {
    super(code, HttpStatus.PAYMENT_REQUIRED, message);
    this.message = message;
    this.meta = meta;
  }
}

export class ProductLimitationActionTriggerNotAvailableError extends ProductLimitationError {
  constructor(meta: { workflowId: string; projectId: string }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_ACTION_TRIGGER_NOT_AVAILABLE,
      message:
        'Action triggers are not available for your current billing plan. Please upgrade to use this feature.',
      meta,
    });
  }
}

export class ProductLimitationDataSizeError extends ProductLimitationError {
  constructor(meta: { workflowId: string; projectId: string; size: number; allowedSize: number }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_DATA_SIZE_EXCEEDED,
      message: `Step output size exceeds your plan's limit. Your plan allows for ${Math.round(
        meta.allowedSize / (1024 * 1024),
      )} MB. Object was ${Math.round(meta.size / (1024 * 1024))} MB (${meta.size} bytes).`,
      meta,
    });
  }
}

export class ProductLimitationStepTimeoutError extends ProductLimitationError {
  constructor(meta: {
    workflowId: string;
    projectId: string;
    timeLimit: number;
    attempts: number;
    plan: BillingPlan;
  }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_DATA_SIZE_EXCEEDED,
      message: `Step timed out after ${(meta.timeLimit / 1000).toFixed(
        meta.timeLimit < 1000 ? 1 : 0,
      )} seconds${meta.attempts > 1 ? ` and ${meta.attempts} attempts` : ''}.${
        meta.plan !== BillingPlan.ClassicEnterprise
          ? ' To increase the time out limit, please upgrade your billing plan.'
          : ''
      }`,
      meta,
    });
  }
}

export class ProductLimitationDelayError extends ProductLimitationError {
  constructor(meta: {
    workflowId: string;
    projectId: string;
    delay: string;
    allowedDelay: string;
    plan: BillingPlan;
  }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_DELAY_LIMIT_EXCEEDED,
      message: `Delay exceeds your plan's limit. Your plan allows for ${
        meta.allowedDelay
      }. Delay was ${meta.delay}.${
        meta.plan !== BillingPlan.ClassicEnterprise
          ? ' To increase the delay limit, please upgrade your billing plan.'
          : ''
      }`,
      meta,
    });
  }
}

export class ProductLimitationFanoutError extends ProductLimitationError {
  constructor(meta: {
    workflowId: string;
    projectId: string;
    fanoutLimit: number;
    fanout: number;
    plan: BillingPlan;
  }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_FANOUT_LIMITATION_EXCEEDED,
      message: `Fanout exceeds your plan's limit. Your plan allows fanning over ${
        meta.fanoutLimit
      } items. This fanout had ${meta.fanout} items.${
        meta.plan !== BillingPlan.ClassicEnterprise
          ? ' To increase the fanout limit, please upgrade your billing plan.'
          : ''
      }`,
      meta,
    });
  }
}

export class ProductLimitationWorkflowError extends ProductLimitationError {
  constructor(meta: { projectId: string; workflowLimit: number }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_WORKFLOWS_LIMIT_EXCEEDED,
      message: `Number of workflows exceeds your plan's limit. Your plan allows ${meta.workflowLimit} workflows.`,
      meta,
    });
  }
}

export class ProductLimitationStepsPerWorkflowError extends ProductLimitationError {
  constructor(meta: { projectId: string; workflowId: string; stepsPerWorkflowLimit: number }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_STEPS_PER_WORKFLOW_LIMIT_EXCEEDED,
      message: `Number of steps exceeds your plan's limit. Your plan allows ${meta.stepsPerWorkflowLimit} steps per workflow.`,
      meta,
    });
  }
}

export class ProductLimitationIntegrationError extends ProductLimitationError {
  constructor({ projectId, integrationLimit }: { projectId: string; integrationLimit: number }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_INTEGRATION_LIMIT_EXCEEDED,
      message: `Number of integrations exceeds your plan's limit. Your plan allows ${integrationLimit} integrations.`,
      meta: {
        projectId,
      },
    });
  }
}

export class ProductLimitationActiveConnectIntegrationCountError extends ProductLimitationError {
  constructor(meta: { projectId: string; integrationLimit: number }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_ACTIVE_CONNECT_INTEGRATION_LIMIT_EXCEEDED,
      message: `Number of active integrations exceeds your plan's limit. Your plan allows ${meta.integrationLimit} active integrations. To increase the integration limit, please upgrade your billing plan.`,
      meta,
    });
  }
}

export class ProductLimitationConnectUsersCountError extends ProductLimitationError {
  constructor(meta: { projectId: string; connectUsersLimit: number }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_CONNECT_USER_LIMIT_EXCEEDED,
      message: `Number of connect users exceeds your plan's limit. Your plan allows ${meta.connectUsersLimit} connected users. To increase the number of connect users allowed, please upgrade your billing plan.`,
      meta,
    });
  }
}

export class ProductLimitationCronScheduleError extends ProductLimitationError {
  constructor(meta: { projectId: string; unit: Unit }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_CRON_SCHEDULE_LIMIT_EXCEEDED,
      message: `Invalid schedule for the cron trigger. Your plan allows cron trigger on a ${meta.unit} basis. To increase this limit, , please upgrade your billing plan.`,
      meta,
    });
  }
}

export class ProductLimitationDevCredentialsError extends ProductLimitationError {
  constructor(meta: { projectId: string; personaCount: number; limit: number }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATIONS_DEV_CREDENTIALS_EXCEEDED,
      message: `Connected users limit exceeded for development credentials. Please add your own integration credentials.`,
      meta,
    });
  }
}

export class ProductLimitationWorkflowFeatureRestrictionsError extends ProductLimitationError {
  constructor(meta: { projectId: string; workflowId: string; plan: BillingPlan }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATIONS_WORKFLOW_FEATURE_RESTRICTIONS,
      message: `Workflow has features not supported by ${meta.plan} plan.`,
      meta,
    });
  }
}

export class ProductLimitationTaskLimitReachedError extends ProductLimitationError {
  constructor(meta: { projectId: string; dashboardURL: string }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATIONS_TASK_LIMIT_REACHED,
      message: [
        "You've reached your monthly task limit. Your workflows will be pause duntil you upgrade your plan:",
        `${meta.dashboardURL}/api/billing?projectId=${meta.projectId}`,
      ].join(' '),
      meta,
    });
  }
}

export class ProductLimitationTeamMemberLimitError extends ProductLimitationError {
  constructor(meta: { teamId: string; numCurrentMembers: number; numInvites: number }) {
    super({
      code: ERROR_CODE.PRODUCT_LIMITATION_TEAM_MEMBER_LIMIT_EXCEEDED,
      message: `Allowed number of team members exceeded. To increase this limit, please upgrade your billing plan.`,
      meta,
    });
  }
}
