// tslint:disable: import-name
import React from 'react';

import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

type ScopeName =
  | 'read_content'
  | 'write_content'
  | 'read_themes'
  | 'write_themes'
  | 'read_products'
  | 'write_products'
  | 'read_product_listings'
  | 'read_customers'
  | 'write_customers'
  | 'read_orders'
  | 'write_orders'
  | 'read_all_orders'
  | 'write_order_edits'
  | 'read_draft_orders'
  | 'write_draft_orders'
  | 'read_inventory'
  | 'write_inventory'
  | 'read_locations'
  | 'read_script_tags'
  | 'write_script_tags'
  | 'read_fulfillments'
  | 'write_fulfillments'
  | 'read_assigned_fulfillment_orders'
  | 'write_assigned_fulfillment_orders'
  | 'read_merchant_managed_fulfillment_orders'
  | 'write_merchant_managed_fulfillment_orders'
  | 'read_third_party_fulfillment_orders'
  | 'write_third_party_fulfillment_orders'
  | 'read_shipping'
  | 'write_shipping'
  | 'read_analytics'
  | 'read_users'
  | 'write_users'
  | 'read_checkouts'
  | 'write_checkouts'
  | 'read_reports'
  | 'write_reports'
  | 'read_price_rules'
  | 'write_price_rules'
  | 'read_discounts'
  | 'write_discounts'
  | 'read_marketing_events'
  | 'write_marketing_events'
  | 'read_resource_feedbacks'
  | 'write_resource_feedbacks'
  | 'read_shopify_payments_payouts'
  | 'read_shopify_payments_disputes'
  | 'read_translations'
  | 'write_translations'
  | 'read_locales'
  | 'write_locales'
  | 'read_abandoned_carts';

export const scopes: Record<ScopeName, ScopeValue> = {
  read_content: {
    name: 'read_content',
    description: 'Access to Article, Blog, Comment, Page, and Redirect.',
  },
  write_content: {
    name: 'write_content',
    description: 'Access to Article, Blog, Comment, Page, and Redirect.',
  },
  read_themes: { name: 'read_themes', description: 'Access to Asset and Theme.' },
  write_themes: { name: 'write_themes', description: 'Access to Asset and Theme.' },
  read_products: {
    name: 'read_products',
    description:
      'Access to Product, Product Variant, Product Image, Collect, Custom Collection, and Smart Collection.',
  },
  write_products: {
    name: 'write_products',
    description:
      'Access to Product, Product Variant, Product Image, Collect, Custom Collection, and Smart Collection.',
  },
  read_product_listings: {
    name: 'read_product_listings',
    description: 'Access to Product Listing, and Collection Listing.',
  },
  read_customers: {
    name: 'read_customers',
    description: 'Access to Customer and Saved Search.',
  },
  write_customers: {
    name: 'write_customers',
    description: 'Access to Customer and Saved Search.',
  },
  read_orders: {
    name: 'read_orders',
    description: 'Access to Abandoned checkouts, Customer, Fulfillment, Order, and Transaction.',
  },
  write_orders: {
    name: 'write_orders',
    description: 'Access to Abandoned checkouts, Customer, Fulfillment, Order, and Transaction.',
  },
  read_all_orders: {
    name: 'read_all_orders',
    description:
      'Grants access to all orders rather than the default window of 60 days worth of orders. This OAuth scope is used in conjunction with read_orders, or write_orders. You need to request this scope from your Partner Dashboard before adding it to your app.',
  },
  write_order_edits: {
    name: 'write_order_edits',
    description: 'Access to GraphQL Admin API order editing features.',
  },
  read_draft_orders: { name: 'read_draft_orders', description: 'Access to Draft Order.' },
  write_draft_orders: { name: 'write_draft_orders', description: 'Access to Draft Order.' },
  read_inventory: {
    name: 'read_inventory',
    description: 'Access to Inventory Level and Inventory Item.',
  },
  write_inventory: {
    name: 'write_inventory',
    description: 'Access to Inventory Level and Inventory Item.',
  },
  read_locations: { name: 'read_locations', description: 'Access to Location.' },
  read_script_tags: { name: 'read_script_tags', description: 'Access to Script Tag.' },
  write_script_tags: { name: 'write_script_tags', description: 'Access to Script Tag.' },
  read_fulfillments: {
    name: 'read_fulfillments',
    description: 'Access to Fulfillment Service.',
  },
  write_fulfillments: {
    name: 'write_fulfillments',
    description: 'Access to Fulfillment Service.',
  },
  read_assigned_fulfillment_orders: {
    name: 'read_assigned_fulfillment_orders',
    description:
      'Access to FulfillmentOrder resources assigned to a location managed by your fulfillment service.',
  },
  write_assigned_fulfillment_orders: {
    name: 'write_assigned_fulfillment_orders',
    description:
      'Access to FulfillmentOrder resources assigned to a location managed by your fulfillment service.',
  },
  read_merchant_managed_fulfillment_orders: {
    name: 'read_merchant_managed_fulfillment_orders',
    description: 'Access to FulfillmentOrder resources assigned to merchant-managed locations.',
  },
  write_merchant_managed_fulfillment_orders: {
    name: 'write_merchant_managed_fulfillment_orders',
    description: 'Access to FulfillmentOrder resources assigned to merchant-managed locations.',
  },
  read_third_party_fulfillment_orders: {
    name: 'read_third_party_fulfillment_orders',
    description:
      'Access to FulfillmentOrder resources assigned to a location managed by any fulfillment service.',
  },
  write_third_party_fulfillment_orders: {
    name: 'write_third_party_fulfillment_orders',
    description:
      'Access to FulfillmentOrder resources assigned to a location managed by any fulfillment service.',
  },
  read_shipping: {
    name: 'read_shipping',
    description: 'Access to Carrier Service, Country and Province.',
  },
  write_shipping: {
    name: 'write_shipping',
    description: 'Access to Carrier Service, Country and Province.',
  },
  read_analytics: { name: 'read_analytics', description: 'Access to Analytics API.' },
  read_users: { name: 'read_users', description: 'Access to User.' },
  write_users: { name: 'write_users', description: 'Access to User.' },
  read_checkouts: { name: 'read_checkouts', description: 'Access to Checkouts.' },
  write_checkouts: { name: 'write_checkouts', description: 'Access to Checkouts.' },
  read_reports: { name: 'read_reports', description: 'Access to Reports.' },
  write_reports: { name: 'write_reports', description: 'Access to Reports.' },
  read_price_rules: { name: 'read_price_rules', description: 'Access to Price Rules.' },
  write_price_rules: { name: 'write_price_rules', description: 'Access to Price Rules.' },
  read_discounts: {
    name: 'read_discounts',
    description: 'Access to GraphQL Admin API Discounts features.',
  },
  write_discounts: {
    name: 'write_discounts',
    description: 'Access to GraphQL Admin API Discounts features.',
  },
  read_marketing_events: {
    name: 'read_marketing_events',
    description: 'Access to Marketing Event.',
  },
  write_marketing_events: {
    name: 'write_marketing_events',
    description: 'Access to Marketing Event.',
  },
  read_resource_feedbacks: {
    name: 'read_resource_feedbacks',
    description: 'Access to ResourceFeedback.',
  },
  write_resource_feedbacks: {
    name: 'write_resource_feedbacks',
    description: 'Access to ResourceFeedback.',
  },
  read_shopify_payments_payouts: {
    name: 'read_shopify_payments_payouts',
    description: 'Access to the Shopify Payments Payout, Balance, and Transaction resources.',
  },
  read_shopify_payments_disputes: {
    name: 'read_shopify_payments_disputes',
    description: 'Access to the Shopify Payments Dispute resource.',
  },
  read_translations: {
    name: 'read_translations',
    description: 'Access to Translatable Resource.',
  },
  write_translations: {
    name: 'write_translations',
    description: 'Access to Translatable Resource.',
  },
  read_locales: { name: 'read_locales', description: 'Access to Shop Locale.' },
  write_locales: { name: 'write_locales', description: 'Access to Shop Locale.' },
  read_abandoned_carts: {
    name: 'read_abandoned_carts',
    description: 'Access to Abandoned Carts',
  },
};

export const requiredScopes: ScopeName[] = ['read_products', 'read_orders'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Shopify',
  description: 'Find, create, and update records in Shopify.',
  authUrl: 'https://__SHOP_NAME__.myshopify.com/admin/oauth/authorize',
  accessTokenUrl: 'https://__SHOP_NAME__.myshopify.com/admin/oauth/access_token',
  iconSvg,
  clientIdLabel: 'API key',
  clientSecretLabel: 'API secret key',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'API key',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/shopify`,
      documentationLinkLabel: 'How to obtain a API key?',
    },
    { type: SidebarInputType.ValueText, title: 'API secret key', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://shopify.dev/tutorials/authenticate-with-oauth#scopes',
  endUserSuppliedValues: [
    {
      type: SidebarInputType.ValueText,
      id: 'SHOP_NAME',
      title: 'Enter your Shopify store name',
      subtitle: (
        <>
          Your store name can be identified from your{' '}
          <a
            href="https://help.shopify.com/en/manual/online-store/os/domains"
            target="_blank"
            rel="noreferrer"
          >
            Shopify domain.
          </a>
        </>
      ),
      placeholder: 'store name',
      suffixLabel: '.myshopify.com',
      required: true,
    },
  ],
};
