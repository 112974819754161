import React from 'react';

import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const summaryInput: SidebarInput = {
  id: 'summary',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Summary',
  subtitle:
    'Summary text of the chat message that could be used for push notifications and summary views or fall back views. ',
};

const messageInput: SidebarInput = {
  id: 'message',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 3,
  title: 'Message',
  subtitle: 'Plaintext/HTML representation of the content of the chat message.',
};

const subjectInput: SidebarInput = {
  id: 'subject',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Subject',
  subtitle: 'The subject of the chat message, in plaintext.',
};

const channelInput: SidebarInput = {
  id: 'channel',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Channel',
  placeholder: '{{settings.channel}}',
  subtitle: 'Use Connect Portal Workflow Settings to allow users to select a channel.',
};

const teamInput: SidebarInput = {
  id: 'teamId',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Team ID',
  placeholder: 'e4da8430-de80-4815-9785-9c2441c24a7b',
  subtitle:
    'The ID of the Team to search for users in. Use Connect Portal User Settings to allow your user to select a Team.',
};

const email: SidebarInput = {
  id: 'emailId',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Email',
  placeholder: 'sean@example.com',
  subtitle: 'The email address of the user.',
};

const chatRecipientInput: SidebarInput = {
  id: 'chatRecipient',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Chat Recipient',
  placeholder: '{{settings.member}}',
  subtitle: 'Use Connect Portal Workflow Settings to allow users to select a workspace member.',
};

export const attachmentLayout: SidebarInput = {
  id: 'attachmentLayout',
  type: SidebarInputType.EditableEnum,
  required: false,
  title: 'Attachment Layout',
  subtitle: 'If adding multiple attachments, specify a layout to use when rendering the message.',
  getValues: () => [
    { label: 'Carousel', value: 'carousel' },
    { label: 'List', value: 'list' },
  ],
};

export const attachments: SidebarInput = {
  id: 'attachments',
  type: SidebarInputType.Code,
  language: 'json',
  required: false,
  lines: 10,
  title: 'Chat Recipient',
  subtitle: (
    <>
      A JSON list of attachments, such as images, videos, or rich cards. View the attachment objects
      available in the{' '}
      <a
        href="https://learn.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-connector-api-reference?view=azure-bot-service-4.0#attachment-object"
        target="_blank"
        rel="noreferrer"
      >
        Microsoft Teams docs.
      </a>
    </>
  ),
  useLightTheme: true,
  placeholder: `[
  {
    "contentType": "application/vnd.microsoft.card.adaptive",
    "content": {
      "type": "AdaptiveCard",
      "version": "1.0",
      "body": [
        {
          "type": "TextBlock",
          "text": "Hello World!",
          "size": "large"
        },
        {
          "type": "TextBlock",
          "text": "*Sincerely yours,*"
        },
        {
          "type": "TextBlock",
          "text": "Adaptive Cards",
          "separation": "none"
        }
      ],
      "actions": [
        {
          "type": "Action.OpenUrl",
          "url": "http://adaptivecards.io",
          "title": "Learn More"
        }
      ]
    }
  }
]`,
};

const connectConfig: ActionConfig = {
  actionType: Action.MICROSOFT_TEAMS,
  name: 'Microsoft Teams',
  description: 'Connect your Teams account to sync tickets from your Teams Support account.',
  icon: iconSvg,
  provider: ProviderType.MICROSOFT,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              title: 'Messages',
              items: [
                {
                  value: Intent.SEND_MESSAGE_IN_CHANNEL,
                  label: 'Send message in channel',
                },
                {
                  value: Intent.SEND_MESSAGE_IN_CHAT,
                  label: 'Send message in chat',
                },
                {
                  value: Intent.GET_USER_BY_EMAIL,
                  label: 'Get User by Email',
                },
              ],
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.SEND_MESSAGE_IN_CHANNEL:
          return {
            inputs: [
              channelInput,
              messageInput,
              subjectInput,
              summaryInput,
              attachments,
              attachmentLayout,
            ],
          };
        case Intent.SEND_MESSAGE_IN_CHAT:
          return {
            inputs: [
              chatRecipientInput,
              messageInput,
              subjectInput,
              summaryInput,
              attachments,
              attachmentLayout,
            ],
          };
        case Intent.GET_USER_BY_EMAIL:
          return {
            inputs: [teamInput, email],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
