import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import moment from 'moment';

import {
  generateQueryStringFromObject,
  getAxiosInstance,
  getBasicAuthString,
  handleResponseError,
} from '@shared/actions/sdk/utils';
import { Action } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { CredentialKeys, Credentials } from '../configs';

import {
  AuthUserDetails,
  Environment,
  PIPEDRIVE_AUTH_URL,
  PaginateQuery,
  PaginateResponse,
  TokenApiResponse,
  TokenExchangeResponse,
} from '.';

export const pipedriveSupportedOperators: Operator[] = [Operator.NumberEquals];
/**
 *
 * @param credentials
 * @returns Pipedrive auth user details
 */
export const getPipedriveAuthUserDetails = async (
  apiDomain: string,
  accessToken: string,
): Promise<AuthUserDetails> => {
  try {
    const response: AxiosResponse<AuthUserDetails> = await getPipedriveApiClient(
      apiDomain,
      accessToken,
    ).get<AuthUserDetails>('/users/me');
    return response.data;
  } catch (error) {
    throw getFormattedError(error);
  }
};

/**
 * @param credentials
 * @returns true if access token is valid
 */
export const isAccessTokenValid = async (credentials: Credentials): Promise<boolean> => {
  try {
    await getPipedriveAuthUserDetails(
      credentials.PIPEDRIVE_API_DOMAIN,
      credentials.PIPEDRIVE_ACCESS_TOKEN,
    );
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @summary Gets new access token using refresh token
 * @param PIPEDRIVE_REFRESH_TOKEN
 * @param {Environment}
 * @returns string access token
 */
export async function exchangePipedriveRefreshTokenForAccessToken(
  { PIPEDRIVE_REFRESH_TOKEN }: Credentials,
  { PIPEDRIVE_CLIENT_ID, PIPEDRIVE_CLIENT_SECRET }: Environment,
): Promise<{ accessToken: string; refreshToken: string }> {
  const tokenResponse: AxiosResponse<TokenApiResponse> = await getAxiosInstance(
    PIPEDRIVE_AUTH_URL,
    {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: getBasicAuthString(PIPEDRIVE_CLIENT_ID, PIPEDRIVE_CLIENT_SECRET),
    },
  ).post(
    '/token',
    generateQueryStringFromObject(
      {
        grant_type: 'refresh_token',
        refresh_token: PIPEDRIVE_REFRESH_TOKEN,
      },
      { urlencodedValues: true, addPrefix: false },
    ),
  );
  return {
    accessToken: tokenResponse.data.access_token,
    refreshToken: tokenResponse.data.refresh_token,
  };
}

/**
 * Checks if access token is valid otherwise gets new tokens using refresh token
 * @param credentials
 * @param environment
 */
export async function getAccessTokenFromCredentials(
  credentials: Credentials,
  environment: Environment,
): Promise<TokenExchangeResponse> {
  if (await isAccessTokenValid(credentials)) {
    return {
      accessToken: credentials.PIPEDRIVE_ACCESS_TOKEN,
      refreshToken: credentials.PIPEDRIVE_REFRESH_TOKEN,
      updateCredentialValues: undefined,
    };
  }

  try {
    const { accessToken, refreshToken } = await exchangePipedriveRefreshTokenForAccessToken(
      credentials,
      environment,
    );
    const updateCredentialValues: Credentials = {
      ...credentials,
      [CredentialKeys.ACCESS_TOKEN]: accessToken,
      [CredentialKeys.REFRESH_TOKEN]: refreshToken,
    };

    return { accessToken, updateCredentialValues };
  } catch (err) {
    throw getFormattedError(err);
  }
}

/**
 *
 * @summary return authenticated (axios instance with access token) client
 * @param credentials
 */
export const getPipedriveApiClient = (apiDomain: string, accessToken: string): AxiosInstance => {
  return getAxiosInstance(`${apiDomain}/api/v1`, {
    Accept: '*',
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  });
};

/**
 *
 * @param err Error
 * @returns formatted error for pipedrive error message
 */
export const getFormattedError = (err: Error | AxiosError | any): string => {
  if (err?.response?.data?.error) {
    return `Pipedrive API Error: ${err.response.data.error}`;
  }
  return handleResponseError(err, Action.PIPEDRIVE).message;
};

/**
 * @summary checking date is timestamp or not, means date has only digits, return true
 * @param date
 * @returns boolean result of date
 */
export const isTimeStamp = (date: string): boolean => /^\d+$/.test(date);

/**
 * @summary formatting the date with respect to formatFor
 * @param date date
 * @param formatFor 'date' | 'time'
 * @returns formatted date or time string
 */
export const formatDateTime = (date: string, formatFor: 'date' | 'time'): string => {
  let dateForMoment: string | number = date;

  if (isTimeStamp(date)) {
    // Converts a date (string) to a timestamp (integer).
    dateForMoment = parseInt(date);
  }

  return moment(dateForMoment).format(formatFor === 'date' ? 'YYYY-MM-DD' : 'HH:mm');
};

/**
 * Utility function to fetch data with limit and start
 * @param doRequest API request function from which we need to fetch data
 * @param params API query params
 * @param dataKey Response key in which data will be received
 * @param maxLimit Max record limit for per API request, default 100
 * @returns
 */
export const paginateRequest = async <T>(
  doRequest: (params: PaginateQuery) => Promise<AxiosResponse<PaginateResponse<T>>>,
  params: PaginateQuery,
  dataKey: string,
  maxLimit: number = 100,
): Promise<PaginateResponse<T> | undefined> => {
  let pageCount = 0;
  const queryParams: PaginateQuery = {
    ...params,
    limit: params.limit ? params.limit : maxLimit,
    start: params.start ? params.start : pageCount,
  };

  const firstResponse: PaginateResponse<T> = (await doRequest(queryParams)).data;

  if ((firstResponse.data[dataKey] as T[]).length === 0) {
    return undefined;
  }

  let responseData: T[] = firstResponse.data[dataKey] as T[];
  let paginateResponse: PaginateResponse<T> = firstResponse;
  let hasMoreItems: boolean = paginateResponse.additional_data.pagination.more_items_in_collection;

  // Looping till we fetch items are not empty
  while (hasMoreItems) {
    pageCount++;
    const updatedQueryParams: PaginateQuery = {
      ...queryParams,
      start: pageCount,
    };

    // getting a particular key of data
    paginateResponse = (await doRequest(updatedQueryParams)).data;
    responseData = responseData.concat(paginateResponse.data[dataKey]);
    hasMoreItems = paginateResponse.additional_data.pagination.more_items_in_collection;
  }

  return { ...paginateResponse, data: { [dataKey]: responseData } } as PaginateResponse<T>;
};
