import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.ASANA;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'ASANA_TEST_CONNECTION',
  CREATE_COMMENT = 'ASANA_CREATE_COMMENT',
  CREATE_PROJECT = 'ASANA_CREATE_PROJECT',
  GET_PROJECTS = 'ASANA_GET_PROJECTS',
  GET_PROJECT_BY_ID = 'ASANA_GET_PROJECT_BY_ID',
  CREATE_TASK = 'ASANA_CREATE_TASK',
  UPDATE_TASK = 'ASANA_UPDATE_TASK',
  GET_TASKS = 'ASANA_GET_TASKS',
  GET_TASKS_BY_ID = 'ASANA_GET_TASKS_BY_ID',
  GET_TASK_BY_EXTERNAL_ID = 'ASANA_GET_TASK_BY_EXTERNAL_ID',
  GET_TEAMS = 'ASANA_GET_TEAMS',
  GET_WORKSPACES = 'ASANA_GET_WORKSPACES',
  GET_ASSIGNEE = 'ASANA_GET_ASSIGNEE',
  GET_TAGS = 'ASANA_GET_TAGS',
  GET_SECTIONS = 'ASANA_GET_SECTIONS',

  //triggers event
  TRIGGER_TASK_CREATED = 'ASANA_TRIGGER_TASK_CREATED',
  TRIGGER_TASK_UPDATED = 'ASANA_TRIGGER_TASK_UPDATED',
  TRIGGER_TASK_COMPLETED = 'ASANA_TRIGGER_TASK_COMPLETED',
  TRIGGER_TAG_ADDED = 'ASANA_TRIGGER_TAG_ADDED',
  TRIGGER_PROJECT_CREATED = 'ASANA_TRIGGER_PROJECT_CREATED',
  TRIGGER_COMMENT_CREATED = 'ASANA_TRIGGER_COMMENT_CREATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_COMMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PROJECT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROJECTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROJECT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_TASK;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_TASK;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TASKS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TASKS_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TASK_BY_EXTERNAL_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TEAMS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_WORKSPACES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ASSIGNEE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TAGS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_SECTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_TASK_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_TASK_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_TASK_COMPLETED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_TAG_ADDED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_PROJECT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_COMMENT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  ASANA_OAUTH_REFRESH_TOKEN: string;
  ASANA_OAUTH_ACCESS_TOKEN?: string;
  SCOPES?: string;
};
