import React from 'react';

import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

import { getCampaigns, getPages, getPixels } from './sources';

export const adSetBidAmountInput: SidebarInput = {
  id: 'bidAmount',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Bid Amount',
  subtitle:
    'Set a maximum bid you want to pay for a result based on this ad set’s optimization goal. Value is in cents, for your account’s local currency.',
};

export const adSetDailyBudgetInput: SidebarInput = {
  id: 'dailyBudget',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Daily Budget',
  subtitle: 'Set a daily budget for this ad set to use, in your account’s local currency.',
};

export const adSetIdInput: SidebarInput = {
  id: 'adSetId',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Ad Set ID',
  subtitle: 'The ID of the ad set to get.',
};

export const adIdInput: SidebarInput = {
  id: 'adId',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Ad ID',
  subtitle: 'The ID of the ad to update.',
};

export const adSetStartTimeInput: SidebarInput = {
  id: 'startTime',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Start Time',
  subtitle: 'Specify when this ad set should start running.',
  placeholder: '2021-01-01 12:00:00 AM',
};

export const adSetTargetingInput: SidebarInput = {
  id: 'targeting',
  type: SidebarInputType.Code,
  language: 'json',
  required: true,
  lines: 5,
  title: 'Targeting',
  subtitle: 'Provide a Targeting spec to define the audience for this ad set.',
  placeholder: `{
  "geo_locations": {"countries": ["US"]},
  "interests": [{id: 6003139266461, 'name': 'Movies'}]
}`,
  useLightTheme: true,
};

export const adCreativeJsonInput: SidebarInput = {
  id: 'adCreativeJson',
  type: SidebarInputType.Code,
  language: 'json',
  required: true,
  lines: 4,
  title: 'Ad Creative JSON',
  subtitle: 'Use the Build Ad Creative Object step to generate an object to use as a creative.',
  placeholder: `{
  "link_data": {...},
  "text_data": {...},
}`,
  useLightTheme: true,
};

export const pageDynamicInput: SidebarInput = {
  id: 'page',
  type: SidebarInputType.EditableDynamicEnum,
  title: 'Page',
  placeholder: 'select a page or type {{ to insert a page ID',
  required: true,
  source: getPages,
  getValues: getValuesByCacheKey,
  hideSubtitle: false,
};

export const adCreativePageInput: SidebarInput = {
  id: 'page',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Page',
  subtitle:
    'The Facebook Page that the creative will be uploaded for. Use Connect Portal Workflow Settings to allow users to select a page.',
  placeholder: '{{settings.page}}',
};

export const nameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Name',
  subtitle: 'The name of the campaign.',
};

export const campaignDynamicInput: SidebarInput = {
  id: 'campaignId',
  type: SidebarInputType.EditableDynamicEnum,
  title: 'Campaign ID',
  placeholder: 'select a campaign or type {{ to insert a campaign ID',
  required: true,
  source: getCampaigns,
  getValues: getValuesByCacheKey,
  hideSubtitle: false,
};

export const campaignIdInput: SidebarInput = {
  id: 'campaignId',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Campaign ID',
  subtitle:
    'The ID of the campaign this ad set should be a part of. Use Connect Portal Workflow Settings to allow users to select a campaign.',
};

export const campaignStatusInput: SidebarInput = {
  id: 'status',
  type: SidebarInputType.EditableEnum,
  title: 'Status',
  getValues: (): EnumInputValue[] => [
    { label: 'Paused', value: 'PAUSED' },
    { label: 'Active', value: 'ACTIVE' },
  ],
  defaultValue: 'PAUSED',
  subtitle:
    'This value will default to Paused to prevent your test account from being billed while testing. Change this to Active for production uses.',
};

export const adSetOptimizationGoalInput: SidebarInput = {
  id: 'optimizationGoal',
  type: SidebarInputType.EditableEnum,
  title: 'Optimization Goal',
  getValues: (): EnumInputValue[] => [
    { label: 'App Installs', value: 'APP_INSTALLS' },
    { label: 'Ad Recall Lift', value: 'AD_RECALL_LIFT' },
    { label: 'Engaged Users', value: 'ENGAGED_USERS' },
    { label: 'Event Responses', value: 'EVENT_RESPONSES' },
    { label: 'Impressions', value: 'IMPRESSIONS' },
    { label: 'Lead Generation', value: 'LEAD_GENERATION' },
    { label: 'Quality Lead', value: 'QUALITY_LEAD' },
    { label: 'Link Clicks', value: 'LINK_CLICKS' },
    { label: 'Offsite Conversions', value: 'OFFSITE_CONVERSIONS' },
    { label: 'Page Likes', value: 'PAGE_LIKES' },
    { label: 'Post Engagement', value: 'POST_ENGAGEMENT' },
    { label: 'Quality Call', value: 'QUALITY_CALL' },
    { label: 'Reach', value: 'REACH' },
    { label: 'Landing Page Views', value: 'LANDING_PAGE_VIEWS' },
    { label: 'Visit Instagram Profile', value: 'VISIT_INSTAGRAM_PROFILE' },
    { label: 'Value', value: 'VALUE' },
    { label: 'Video Throughplay', value: 'VALUE_THROUGHPLAY' },
    { label: 'Derived Events', value: 'DERIVED_EVENTS' },
    {
      label: 'App Installs and Offsite Conversions',
      value: 'APP_INSTALLS_AND_OFFSITE_CONVERSIONS',
    },
    { label: 'Conversions', value: 'CONVERSIONS' },
  ],
  defaultValue: 'LINK_CLICKS',
  subtitle: 'Specify the optimization goal that this ad set will use.',
};

export const adSetBillingEventInput: SidebarInput = {
  id: 'billingEvent',
  type: SidebarInputType.EditableEnum,
  title: 'Billing Event',
  getValues: (): EnumInputValue[] => [
    { label: 'App Installs', value: 'APP_INSTALLS' },
    { label: 'Clicks', value: 'CLICKS' },
    { label: 'Impressions', value: 'IMPRESSIONS' },
    { label: 'Link Clicks', value: 'LINK_CLICKS' },
    { label: 'Offer Claims', value: 'OFFER_CLAIMS' },
    { label: 'Page Likes', value: 'PAGE_LIKES' },
    { label: 'Post Engagement', value: 'POST_ENGAGEMENT' },
    { label: 'Video Views', value: 'VIDEO_VIEWS' },
    { label: 'Video Throughplay', value: 'VALUE_THROUGHPLAY' },
  ],
  defaultValue: 'LINK_CLICKS',
  subtitle: 'Specify the billing event that this ad set will use.',
};

export const campaignObjectiveInput: SidebarInput = {
  id: 'objective',
  type: SidebarInputType.EditableEnum,
  title: 'Objective',
  getValues: (): EnumInputValue[] => [
    { label: 'App Installs', value: 'APP_INSTALLS' },
    { label: 'Brand Awareness', value: 'BRAND_AWARENESS' },
    { label: 'Conversions', value: 'CONVERSIONS' },
    { label: 'Event Responses', value: 'EVENT_RESPONSES' },
    { label: 'Lead Generation', value: 'LEAD_GENERATION' },
    { label: 'Link Clicks', value: 'LINK_CLICKS' },
    { label: 'Local Awareness', value: 'LOCAL_AWARENESS' },
    { label: 'Messages', value: 'MESSAGES' },
    { label: 'Offer Claims', value: 'OFFER_CLAIMS' },
    { label: 'Page Likes', value: 'PAGE_LIKES' },
    { label: 'Post Engagement', value: 'POST_ENGAGEMENT' },
    { label: 'Product Catalog Sales', value: 'PRODUCT_CATALOG_SALES' },
    { label: 'Reach', value: 'REACH' },
    { label: 'Store Visits', value: 'STORE_VISITS' },
    { label: 'Video Views', value: 'VIDEO_VIEWS' },
  ],
  defaultValue: 'LINK_CLICKS',
  subtitle: 'Specify the objective for this campaign.',
};

const supportedOperators: Operator[] = [Operator.StringExactlyMatches, Operator.DateTimeEquals];

export const campaignFilterInputOauth: SidebarInput = {
  id: 'campaignFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for campaigns that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Id', value: 'id' },
    { label: 'Name', value: 'name' },
    { label: 'Status', value: 'status' },
    { label: 'Account Id', value: 'account_id' },
  ],
};

export const adSetFilterInputOauth: SidebarInput = {
  id: 'adSetFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for ad sets that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Id', value: 'id' },
    { label: 'Name', value: 'name' },
    { label: 'Status', value: 'status' },
    { label: 'Account Id', value: 'account_id' },
    { label: 'Campaign Id', value: 'campaign_id' },
  ],
};

export const followUpActionTextInput: SidebarInput = {
  id: 'followUpActionText',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Follow Up Action Text',
  subtitle: 'The displayed label on the follow up action when the user completes the form.',
  lines: 1,
};

export const followUpActionUrlInput: SidebarInput = {
  id: 'followUpActionUrl',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Follow-up Action URL',
  subtitle: 'Set a destination for the Follow Up Action Text when clicked.',
  lines: 1,
};

export const questionJsonInput: SidebarInput = {
  id: 'questionJson',
  type: SidebarInputType.Code,
  language: 'json',
  required: true,
  title: 'Questions JSON',
  subtitle: (
    <>
      Specify questions to be included in this form, using the{' '}
      <a
        href="https://developers.facebook.com/docs/marketing-api/guides/lead-ads/create#adding_questions"
        target="_blank"
        rel="noreferrer"
      >
        LeadGenQuestion
      </a>{' '}
      object.
    </>
  ),
  lines: 4,
  useLightTheme: true,
  placeholder: `[
    { "type": "EMAIL" },
    { "type": "CUSTOM" }
  ]`,
};

export const contextCardInput: SidebarInput = {
  id: 'contextCard',
  type: SidebarInputType.Code,
  language: 'json',
  required: false,
  title: 'Form Greeting/Context Card JSON',
  subtitle: (
    <>
      Specify a context card to be included at the beginning of this form, using the{' '}
      <a
        href="https://github.com/nmggithub/fbsdk-ts/blob/master/src/graph-api/types.ts#L2387-L2400"
        target="_blank"
        rel="noreferrer"
      >
        LeadGenContextCard
      </a>{' '}
      object.
    </>
  ),
  lines: 4,
  useLightTheme: true,
  placeholder: `{
  "title": "",
  "style": "PARAGRAPH_STYLE",
  "content": {}
}`,
};

export const privacyPolicyUrlInput: SidebarInput = {
  id: 'privacyPolicyUrl',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Privacy Policy URL',
  subtitle: 'Set a destination for the Privacy Policy.',
  lines: 1,
};

export const eventActionSourceInput: SidebarInput = {
  id: 'actionSource',
  type: SidebarInputType.EditableEnum,
  title: 'Action Source',
  getValues: (): EnumInputValue[] => [
    { label: 'Website', value: 'website' },
    { label: 'Chat', value: 'chat' },
    { label: 'App', value: 'app' },
    { label: 'Email', value: 'email' },
    { label: 'Phone Call', value: 'phone_call' },
    { label: 'System Generated', value: 'system_generated' },
    { label: 'Other', value: 'other' },
  ],
};

export const eventPixelDynamicInput: SidebarInput = {
  id: 'pixel',
  type: SidebarInputType.EditableDynamicEnum,
  title: 'Pixel',
  placeholder: 'select a pixel or type {{ to insert a pixel ID',
  required: true,
  source: getPixels,
  getValues: getValuesByCacheKey,
  hideSubtitle: false,
};

export const eventPixelInput: SidebarInput = {
  id: 'pixel',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Pixel',
  subtitle:
    'The Facebook Pixel that will receive the event. Use Connect Portal Workflow Settings to allow users to select a pixel.',
  placeholder: '{{settings.pixel}}',
  lines: 1,
};

export const eventTimeInput: SidebarInput = {
  id: 'eventTime',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Event Time',
  subtitle:
    'Time when the event occurred (ISO 8601 timestamp). Defaults to the time of the workflow execution if left blank',
  placeholder: '2020-03-31T12:02:00Z',
  lines: 1,
};

export const eventId: SidebarInput = {
  id: 'eventId',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Event ID',
  subtitle: 'Used to remove duplicates when sending the same event from more than one source.',
  lines: 1,
};

export const eventSourceUrlInput: SidebarInput = {
  id: 'eventSourceUrl',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Event Source URL',
  subtitle: 'The URL where the event occurred. Required if the Action Source is Website.',
  lines: 1,
};

export const eventCustomerEmailInput: SidebarInput = {
  id: 'customerEmail',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer Email',
  subtitle:
    'The email of the customer associated with the event, so they can be matched to a Facebook account and used for ads attribution and delivery optimization.',
  lines: 1,
};

export const eventCustomerFirstNameInput: SidebarInput = {
  id: 'customerFirstName',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer first name',
  lines: 1,
};

export const eventCustomerLastNameInput: SidebarInput = {
  id: 'customerLastName',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer last name',
  lines: 1,
};

export const eventCustomerCityInput: SidebarInput = {
  id: 'customerCity',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer city',
  lines: 1,
};

export const eventCustomerStateInput: SidebarInput = {
  id: 'customerState',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer state',
  lines: 1,
};

export const eventCustomerCountryCodeInput: SidebarInput = {
  id: 'customerCountryCode',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer country code',
  lines: 1,
};

export const eventCustomerExternalId: SidebarInput = {
  id: 'customerExternalId',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer External ID',
  subtitle: 'A custom ID for the prospect, often referencing an ID in an external system.',
  lines: 1,
};

export const eventCustomerClientIpAddressInput: SidebarInput = {
  id: 'customerClientIpAddress',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer client IP address',
  subtitle: 'The IP address of the browser corresponding to the event.',
  lines: 1,
};

export const eventCustomerClientUserAgentInput: SidebarInput = {
  id: 'customerClientUserAgent',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Customer client user agent',
  subtitle: 'The user agent for the browser corresponding to the event.',
  lines: 1,
};

export const eventCustomerClickIdInput: SidebarInput = {
  id: 'clickId',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Click ID (fbc)',
  subtitle: 'The Facebook click ID value stored in the _fbc browser cookie.',
  lines: 1,
};

export const eventBrowserIdInput: SidebarInput = {
  id: 'browserId',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Browser ID (fbc)',
  subtitle: 'The Facebook browser ID value stored in the _fbp browser cookie.',
  lines: 1,
};

export const eventValueInput: SidebarInput = {
  id: 'value',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Value',
  subtitle:
    'A numeric value associated with this event. This could be a monetary value or a value in some other metric.',
  lines: 1,
};

export const eventCurrencyInput: SidebarInput = {
  id: 'currency',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Currency',
  subtitle:
    "The currency for the value specified, if applicable. Currency must be a valid ISO 4217 three digit currency code. Example: 'USD'",
  lines: 1,
};

export const eventContentIdInput: SidebarInput = {
  id: 'contentId',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Content ID',
  subtitle:
    'The content IDs associated with the event, such as product SKUs for items in an AddToCart event. Accepts an array of IDs.',
  placeholder: "['ABC123', 'XYZ789']",
  lines: 1,
};

export const eventContentNameInput: SidebarInput = {
  id: 'contentName',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Content Name',
  subtitle: 'The name of the page or product associated with the event.',
  placeholder: 'Lettuce',
  lines: 1,
};

export const eventContentCategoryInput: SidebarInput = {
  id: 'contentCategory',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Content Category',
  subtitle: 'The category of the content associated with the event.',
  placeholder: 'Grocery',
  lines: 1,
};

export const eventNameInput: SidebarInput = {
  id: 'eventName',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Event Name',
  lines: 1,
};

export const adCreativeImageURLInput: SidebarInput = {
  id: 'imageURL',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  title: 'Image URL',
  subtitle: 'This image will be uploaded to your ad account’s image library.',
  placeholder: 'https://example.com/ad.png',
};
export const adCreativeLinkURLInput: SidebarInput = {
  id: 'linkURL',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  title: 'Link URL',
  subtitle: 'The URL used for the CTA in this ad.',
  placeholder: 'https://example.com/promo',
};

export const adCreativeLinkTitleInput: SidebarInput = {
  id: 'linkTitle',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Link Title',
  subtitle: 'A title to use for the link of your ad.',
};

export const adCreativeLinkDescriptionInput: SidebarInput = {
  id: 'linkDescription',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Link Description',
  subtitle: 'A description to use for the link of your ad.',
};

export const adCreativeMessageInput: SidebarInput = {
  id: 'message',
  type: SidebarInputType.TextArea,
  lines: 2,
  required: true,
  title: 'Message',
  subtitle: 'The body of the post included with the image.',
};

export const adCreativeAdditionalFieldInput: SidebarInput = {
  id: 'additionalField',
  type: SidebarInputType.Code,
  lines: 6,
  language: 'json',
  required: true,
  title: 'Additional Fields',
  subtitle: (
    <>
      Specify any other fields that should be included in the post, as fields of
      <a
        href="https://developers.facebook.com/docs/marketing-api/reference/ad-creative-link-data/"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        AdCreativeLinkData
      </a>
      .
    </>
  ),
  placeholder: `{
  "call_to_action": { ... },
  "child_attachments": [{ ... }]
}`,
  useLightTheme: true,
};

export const promotedObjectInput: SidebarInput = {
  id: 'promotedObject',
  type: SidebarInputType.Code,
  lines: 4,
  language: 'json',
  required: false,
  title: 'Promoted Object',
  subtitle: (
    <>
      The object an ad set promotes, such as a Page or app. This field may be required depending on
      your campaign’s Objective.{' '}
      <a
        href="https://developers.facebook.com/docs/marketing-api/reference/ad-promoted-object"
        target="_blank"
        rel="noreferrer"
      >
        Read the docs
      </a>{' '}
      for more details.
    </>
  ),
  placeholder: `{
  "page_id": "11111111111111111"
}`,
  useLightTheme: true,
};

export const adCreativeLeadGenFormIdInput: SidebarInput = {
  id: 'leadGenFormId',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  title: 'Lead Gen Form ID',
  subtitle: 'The ID of the lead gen form to use with this creative. ',
};

export const adCreativeIdInput: SidebarInput = {
  id: 'adCreativeId',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  title: 'Ad Creative ID',
  subtitle: 'The ID of the ad creative to use for this ad.',
};

export const adStatusInput: SidebarInput = {
  id: 'status',
  type: SidebarInputType.EditableEnum,
  title: 'Status',
  getValues: (): EnumInputValue[] => [
    { label: 'Paused', value: 'PAUSED' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Archived', value: 'ARCHIVED' },
    { label: 'Deleted', value: 'DELETED' },
  ],
  defaultValue: 'PAUSED',
  required: true,
  subtitle:
    'This value will default to Paused to prevent your test account from being billed while testing. Change this to Active for production uses.',
};

export const callToActionTypeInput: SidebarInput = {
  id: 'callToActionType',
  type: SidebarInputType.EditableEnum,
  title: 'Call to Action Type',
  getValues: (): EnumInputValue[] => [
    { label: 'Apply Now', value: 'APPLY_NOW' },
    { label: 'Download', value: 'DOWNLOAD' },
    { label: 'Get Quote', value: 'GET_QUOTE' },
    { label: 'Learn More', value: 'LEARN_MORE' },
    { label: 'Sign Up', value: 'SIGN_UP' },
    { label: 'Subscribe', value: 'SUBSCRIBE' },
  ],
  defaultValue: 'SIGN_UP',
  required: false,
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Campaigns',
          items: [
            {
              value: Intent.CREATE_CAMPAIGN,
              label: 'Create Campaign',
            },
            {
              value: Intent.UPDATE_CAMPAIGN,
              label: 'Update Campaign',
            },
            {
              value: Intent.GET_CAMPAIGNS,
              label: 'Get Campaigns',
            },
            {
              value: Intent.GET_CAMPAIGN_BY_ID,
              label: 'Get Campaign by ID',
            },
          ],
        },
        {
          title: 'Ads',
          items: [
            {
              value: Intent.CREATE_AD_SET,
              label: 'Create Ad Set',
            },
            {
              value: Intent.UPDATE_AD_SET,
              label: 'Update Ad Set',
            },
            {
              value: Intent.GET_AD_SETS,
              label: 'Get Ad Sets',
            },
            {
              value: Intent.GET_AD_SET_BY_ID,
              label: 'Get Ad Set by ID',
            },
            {
              value: Intent.CREATE_AD,
              label: 'Create Ad',
            },
            {
              value: Intent.UPDATE_AD,
              label: 'Update Ad',
            },
            {
              value: Intent.GET_AD_BY_ID,
              label: 'Get Ad by ID',
            },
          ],
        },
        {
          title: 'Ad Creatives',
          items: [
            {
              value: Intent.BUILD_AD_CREATIVE_OBJECT,
              label: 'Build Ad Creative Object',
            },
            {
              value: Intent.CREATE_AD_CREATIVE,
              label: 'Create Ad Creative',
            },
            {
              value: Intent.CREATE_LEAD_GEN_FORM,
              label: 'Create Lead Gen Form',
            },
          ],
        },
        {
          title: 'Conversions',
          items: [
            {
              value: Intent.SEND_PURCHASE_EVENT,
              label: 'Send Purchase Event',
            },
            {
              value: Intent.SEND_LEAD_EVENT,
              label: 'Send Lead Event',
            },
            {
              value: Intent.SEND_FUNNEL_EVENT,
              label: 'Send Funnel Event',
            },
          ],
        },
      ],
    },
  ],
};
