import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class SigningKeyError extends ParagonError {
  name: string = 'SigningKeyError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class SigningKeyNotFoundFromIdError extends SigningKeyError {
  name: string = 'SigningKeyNotFoundFromIdError';

  constructor(meta: { signingKeyId: string }) {
    super({
      code: ERROR_CODE.SIGNING_KEY_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find signing key.',
      meta,
    });
  }
}

export class SigningKeyNotFoundForProjectError extends SigningKeyError {
  name: string = 'SigningKeyNotFoundForProjectError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.SIGNING_KEY_NOT_FOUND_FOR_PROJECT,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find signing key.',
      meta,
    });
  }
}
