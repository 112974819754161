import { flow, identity } from 'fp-ts/lib/function';

import { warn } from '@shared/logger/sdk/legacy';
import { ActionResponseStatus } from '@shared/types/sdk/actions';
import {
  ExecutionStatus,
  WorkflowExecutionFailureUpdate,
  WorkflowExecutionStartUpdate,
  WorkflowExecutionSuccessUpdate,
  WorkflowExecutionUpdate,
  WorkflowExecutionWaitingUpdate,
} from '@shared/types/sdk/steps';
import { EMPTY_STATE_MACHINE } from '@shared/workflow/sdk';

import { indexBy } from '../../utils';
import { testingLens, testingStepInstanceStatusLens } from '../lenses';
import { Action, AddStepView, StepStatus, Testing, WorkflowEditorState } from '../types';

export const initialState: WorkflowEditorState = {
  descriptionEditable: true,
  editable: true,
  sidebar: {
    open: false,
  },
  variableMenu: {
    open: false,
  },
  showOnboardingNextSteps: false,
  supportedActions: [],
  actionTriggerInputs: {},
  actionTriggerFetchProcessing: false,
  dismissedFeatureRestrictions: [],
  stateMachine: EMPTY_STATE_MACHINE,
  showWorkflowSavedToast: false,
  autoSaveCount: 0,
  failedDeploymentErrorMessage: '',
  stepValidations: {},
  cloneableWorkflows: [],
};

function getWorkflowExecutionStartUpdate(
  update: WorkflowExecutionStartUpdate,
  state: WorkflowEditorState,
): WorkflowEditorState {
  return {
    ...state,
    testing: {
      ...state.testing!,
      stepInstances: [
        ...state.testing!.stepInstances,
        {
          stepId: update.stepId,
          instanceId: update.instanceId,
          status: {
            type: ExecutionStatus.EXECUTING,
          },
        },
      ],
    },
  };
}

function getWorkflowExecutionWaitingUpdate(
  update: WorkflowExecutionWaitingUpdate,
  state: WorkflowEditorState,
): WorkflowEditorState {
  return testingStepInstanceStatusLens(update.stepId, update.instanceId).modify(
    (status: StepStatus) => ({
      ...status,
      type: ExecutionStatus.WAITING,
    }),
  )(state);
}

function getWorkflowExecutionSuccessUpdate(
  update: WorkflowExecutionSuccessUpdate,
  state: WorkflowEditorState,
): WorkflowEditorState {
  return testingStepInstanceStatusLens(
    update.stepExecution.stepId,
    update.stepExecution.instanceId,
  ).modify((status: StepStatus) => ({
    ...status,
    type: ExecutionStatus.SUCCEEDED,
    input: update.stepExecution.input,
    startTime: update.stepExecution.startTime,
    endTime: update.stepExecution.endTime,
    continuation: update.continuation,
  }))(state);
}

function getWorkflowExecutionFailureUpdate(
  update: WorkflowExecutionFailureUpdate,
  state: WorkflowEditorState,
): WorkflowEditorState {
  return flow(
    testingLens().modify((testing: Testing) => {
      const failedTest: Testing = {
        ...testing,
        status: {
          type: ExecutionStatus.FAILED,
          errorMessage: update.stepExecution?.stepId ? null : update.error,
        },
      };
      return failedTest;
    }),
    update.stepExecution
      ? testingStepInstanceStatusLens(
          update.stepExecution!.stepId,
          update.stepExecution!.instanceId,
        ).modify((status: StepStatus) => ({
          ...status,
          type: ExecutionStatus.FAILED,
          input: update.stepExecution!.input, // FIXME
          startTime: update.stepExecution!.startTime,
          endTime: update.stepExecution!.endTime,
          error: update.error,
        }))
      : identity,
  )(state);
}

export function handler(
  state: WorkflowEditorState = initialState,
  action: Action,
): WorkflowEditorState {
  switch (action.type) {
    case 'RESET_WORKFLOW_EDITOR':
      return initialState;
    case 'OPEN_ADD_STEP':
      return {
        ...state,
        sidebar: {
          open: true,
          view: {
            type: 'ADD_STEP',
            parentStepId: action.parentStepId,
          },
        },
      };

    case 'SET_ADD_STEP_CREATED_STEP_ID':
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          view: {
            ...(state.sidebar.view as AddStepView),
            createdStepId: action.createdStepId,
          },
        },
      };

    case 'OPEN_EDIT_STEP':
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          open: true,
          view: {
            type: 'EDIT_STEP',
            stepId: action.stepId,
            testShelfOpen: false,
          },
        },
      };

    case 'TOGGLE_TEST_SHELF':
      if (state.sidebar.view?.type !== 'EDIT_STEP') {
        return state;
      }
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          view: {
            ...state.sidebar.view,
            testShelfOpen:
              action.openState !== undefined ? action.openState : !state.sidebar.view.testShelfOpen,
          },
        },
      };

    case 'TEST_WORKFLOW_START':
      return {
        ...state,
        testing: {
          status: {
            type: ExecutionStatus.EXECUTING,
            abortRequest: action.abortRequest,
          },
          stepInstances: [],
          triggerInputs: { ...state.testing?.triggerInputs },
        },
        sidebar: {
          open: true,
          view: {
            type: 'TEST_WORKFLOW',
          },
        },
      };

    case 'TEST_WORKFLOW_UPDATE':
      let localState = { ...state };
      const updates = action.payload;
      updates.forEach((update: WorkflowExecutionUpdate) => {
        switch (update.type) {
          case 'START':
            localState = getWorkflowExecutionStartUpdate(
              update as WorkflowExecutionStartUpdate,
              localState,
            );
            break;

          case 'SUCCESS':
            localState = getWorkflowExecutionSuccessUpdate(
              update as WorkflowExecutionSuccessUpdate,
              localState,
            );
            break;
          case 'FAILURE':
            localState = getWorkflowExecutionFailureUpdate(
              update as WorkflowExecutionFailureUpdate,
              localState,
            );
            break;
          case 'WAITING':
            localState = getWorkflowExecutionWaitingUpdate(
              update as WorkflowExecutionWaitingUpdate,
              localState,
            );
            break;
        }
      });
      return { ...localState };

    case 'TEST_WORKFLOW_COMPLETE':
      return {
        ...state,
        testing: {
          ...state.testing!,
          status: {
            type:
              state.testing!.status.type === ExecutionStatus.EXECUTING
                ? ExecutionStatus.SUCCEEDED
                : state.testing!.status.type,
          },
        },
        sidebar: {
          open: true,
          view: {
            type: 'TEST_WORKFLOW',
          },
        },
      };

    case 'TEST_WORKFLOW_CANCEL':
      return {
        ...state,
        testing: {
          ...state.testing!,
          status: {
            type: ExecutionStatus.SUCCEEDED,
          },
        },
      };

    case 'DEACTIVATE_SIDEBAR':
      return {
        ...state,
        editable: true,
        sidebar: {
          ...state.sidebar,
          view: undefined,
          open: false,
        },
        showOnboardingNextSteps: false,
      };

    case 'OPEN_TEST_WORKFLOW_SIDEBAR':
      return {
        ...state,
        sidebar: {
          open: true,
          view: {
            type: 'TEST_WORKFLOW',
          },
        },
      };

    case 'OPEN_EXECUTION_SIDEBAR':
      return {
        ...state,
        editable: false,
        sidebar: {
          open: true,
          view: {
            type: 'EXECUTION',
          },
        },
      };

    case 'SET_EXPANDED_STEP_EXECUTION':
      if (state.sidebar?.view?.type !== 'EXECUTION') {
        warn('SET_EXPANDED_STEP_EXECUTION ignored >', action);
        return state;
      }
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          view: {
            ...state.sidebar.view,
            activeStepId: action.activeStepId,
          },
        },
      };

    case 'SET_EXPANDED_STEP_INSTANCE_EXECUTION':
      if (state.sidebar?.view?.type !== 'EXECUTION') {
        warn('SET_EXPANDED_STEP_EXECUTION ignored >', action);
        return state;
      }
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          view: {
            ...state.sidebar.view,
            activeStepInstanceId: action.activeStepInstanceId,
          },
        },
      };

    case 'GET_LAST_DEPLOYMENT_START':
      return {
        ...state,
        deployProgress: 'FETCHING',
      };

    case 'GET_LAST_DEPLOYMENT_SUCCESS':
    case 'GET_LAST_DEPLOYMENT_FAILED':
      return {
        ...state,
        deployProgress: undefined,
      };

    case 'DEPLOY_WORKFLOW_START':
      return {
        ...state,
        deployProgress: 'DEPLOYING',
      };

    case 'DEPLOY_WORKFLOW_SUCCESS':
    case 'DEPLOY_WORKFLOW_FAILED':
      return {
        ...state,
        deployProgress: undefined,
        ...(action.message ? { failedDeploymentErrorMessage: action.message } : {}),
      };

    case 'UNDEPLOY_WORKFLOW_START':
      return {
        ...state,
        deployProgress: 'UNDEPLOYING',
      };

    case 'UNDEPLOY_WORKFLOW_SUCCESS':
    case 'UNDEPLOY_WORKFLOW_FAILED':
      return {
        ...state,
        deployProgress: undefined,
        ...(action.message ? { failedDeploymentErrorMessage: action.message } : {}),
      };
    case 'SET_TESTING_TRIGGER_INPUT':
      return {
        ...state,
        testing: {
          ...state.testing!,
          triggerInputs: {
            ...state.testing?.triggerInputs,
            [action.payload.workflowId]: action.payload.triggerInput,
          },
        },
      };
    case 'SET_ACTION_TEST_CONNECTION_STARTED':
      return {
        ...state,
        actionTestConnectionStatus: {
          ...state.actionTestConnectionStatus!,
          status: ActionResponseStatus.INFO,
          message: 'Testing connection...',
        },
      };
    case 'SET_ACTION_TEST_CONNECTION_END':
      return {
        ...state,
        actionTestConnectionStatus: {
          ...state.actionTestConnectionStatus!,
          ...action.payload,
        },
      };
    case 'RESET_ACTION_TEST_CONNECTION':
      return {
        ...state,
        actionTestConnectionStatus: undefined,
      };
    case 'OPEN_VARIABLE_MENU':
      return {
        ...state,
        variableMenu: {
          open: true,
          selectedStepId: undefined,
        },
      };
    case 'CLOSE_VARIABLE_MENU':
      return {
        ...state,
        variableMenu: {
          open: false,
          selectedStepId: undefined,
        },
      };
    case 'NAVIGATE_VARIABLE_MENU':
      return {
        ...state,
        variableMenu: {
          ...state.variableMenu,
          selectedStepId: action.selectedStepId,
        },
      };
    case 'OPEN_ONBOARDING_NEXT_STEPS':
      return {
        ...state,
        showOnboardingNextSteps: true,
      };
    case 'CLOSE_ONBOARDING_NEXT_STEPS':
      return {
        ...state,
        showOnboardingNextSteps: false,
      };
    case 'FETCH_SUPPORTED_ACTIONS':
      return {
        ...state,
        supportedActions: [...action.actions],
      };
    case 'FETCH_ACTION_TRIGGER_INPUTS_START':
      return {
        ...state,
        actionTriggerFetchProcessing: true,
      };
    case 'FETCH_ACTION_TRIGGER_INPUTS_SUCCESS':
      return {
        ...state,
        actionTriggerInputs: {
          ...state.actionTriggerInputs,
          [action.workflowId]: action.data,
        },
        actionTriggerFetchProcessing: false,
        actionTriggerFetchError: undefined,
      };
    case 'FETCH_ACTION_TRIGGER_INPUTS_FAILURE':
      return {
        ...state,
        actionTriggerFetchProcessing: false,
        actionTriggerFetchError: action.message,
      };
    case 'SHOW_FEATURE_RESTRICTION':
      return {
        ...state,
        showFeatureRestriction: action.restriction,
      };
    case 'DISMISS_FEATURE_RESTRICTION':
      return {
        ...state,
        showFeatureRestriction: undefined,
        dismissedFeatureRestrictions: [...state.dismissedFeatureRestrictions, action.restriction],
      };
    case 'SET_STATE_MACHINE':
      return {
        ...state,
        stateMachine: action.stateMachine,
      };
    case 'OPEN_VERSION_HISTORY_SIDEBAR':
      return {
        ...state,
        sidebar: {
          open: true,
          view: {
            type: 'VERSION_HISTORY',
          },
        },
      };
    case 'SET_WORKFLOW_DESCRIPTION_EDITABLE':
      return {
        ...state,
        descriptionEditable: action.payload,
      };
    case 'TOGGLE_WORKFLOW_EDITOR_EDITABLE_MODE':
      return {
        ...state,
        editable: action.editable,
      };
    case 'TOGGLE_SHOW_WORKFLOW_SAVED_TOAST':
      return {
        ...state,
        showWorkflowSavedToast: action.payload,
      };
    case 'INCREMENT_AUTO_SAVE_COUNT':
      return {
        ...state,
        autoSaveCount: state.autoSaveCount + 1,
      };
    case 'RESET_AUTO_SAVE_COUNT':
      return {
        ...state,
        autoSaveCount: 0,
      };
    case 'RESET_DEPLOYMENT_ERROR_MESSAGE':
      return {
        ...state,
        failedDeploymentErrorMessage: '',
      };
    case 'SET_STEP_VALIDATION_ERRORS':
      return {
        ...state,
        stepValidations: { ...indexBy('id', action.payload) },
      };
    case 'REMOVE_STEP_VALIDATION_ERROR':
      const { [action.payload.id]: _removed, ...newStepValidations } = state.stepValidations;
      return { ...state, stepValidations: newStepValidations };
    case 'UPDATE_STEP_VALIDATION_ERROR':
      return {
        ...state,
        stepValidations: { ...state.stepValidations, ...indexBy('id', [action.payload]) },
      };
    case 'SET_CLONEABLE_WORKFLOWS':
      return {
        ...state,
        cloneableWorkflows: action.payload,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    case 'SET_EDITING_EVENT_ID':
      return { ...state, editingAppEventId: action.eventId };
    default:
      return state;
  }
}
