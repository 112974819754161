import React from 'react';

import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const slackBlockKitBuilderLink = (
  <>
    <a href="https://app.slack.com/block-kit-builder" target="_blank" rel="noreferrer">
      Block Kit Builder
    </a>
  </>
);

const botNameInput: SidebarInput = {
  id: 'botName',
  type: SidebarInputType.TextArea,
  title: 'Bot name',
  subtitle: 'The name of the bot that sends this message.',
  required: true,
  lines: 1,
};

const emailInput: SidebarInput = {
  id: 'email',
  type: SidebarInputType.TextArea,
  title: 'Email',
  subtitle: 'The email address of a user in the workspace.',
  required: true,
  lines: 1,
};

const botIconInput: SidebarInput = {
  id: 'botIcon',
  type: SidebarInputType.TextArea,
  title: 'Bot icon',
  subtitle: 'Can be either an image URL or an emoji available to your team (e.g. :dog:)',
  required: true,
  lines: 1,
};

const authenticatedUserInput: SidebarInput = {
  id: 'authenticatedUser',
  type: SidebarInputType.BooleanInput,
  title: 'Send as authenticated user',
  subtitle:
    'If set to true, this message will appear to come from your authenticated Slack user instead of your application. Defaults to false.',
  required: false,
};

const connectConfig: ActionConfig = {
  actionType: Action.SLACK,
  name: 'Slack',
  description:
    'Connect to your users’ Slack workspaces to send them messages and notifications in Slack.',
  icon: iconSvg,
  provider: ProviderType.SLACK,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.SEND_MESSAGE,
              label: 'Send message in channel',
            },
            {
              value: Intent.SEND_DIRECT_MESSAGE,
              label: 'Send direct message',
            },
            {
              value: Intent.GET_USER_BY_EMAIL,
              label: 'Get User By Email',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.SEND_MESSAGE:
          return {
            inputs: [
              {
                id: 'channel',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Channel',
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select a channel, or enter a channel name to create a new channel in the user’s workspace.',
                required: true,
                placeholder: '{{settings.channel}}',
              },
            ],
          };
        case Intent.SEND_DIRECT_MESSAGE:
          return {
            inputs: [
              {
                id: 'memberId',
                type: SidebarInputType.TextArea,
                title: 'Recipient',
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select a workspace member to DM. Defaults to the authenticated user if not provided.',
                required: true,
                placeholder: '{{settings.WorkspaceMember}}',
                lines: 1,
              },
            ],
          };
        case Intent.GET_USER_BY_EMAIL:
          return {
            inputs: [emailInput],
          };
        default:
          return { inputs: [] };
      }
    },

    (parameters): SidebarSection => {
      return {
        inputs:
          parameters.intent === Intent.SEND_DIRECT_MESSAGE ||
          parameters.intent === Intent.SEND_MESSAGE
            ? [
                {
                  id: 'message',
                  type: SidebarInputType.TextArea,
                  title: 'Message',
                  required: true,
                },
                botNameInput,
                botIconInput,
                {
                  id: 'blocks',
                  type: SidebarInputType.Code,
                  title: 'Block kit JSON',
                  subtitle: (
                    <>
                      Use the Slack {slackBlockKitBuilderLink} to design your message and copy the
                      Payload here.
                    </>
                  ),
                  required: false,
                  placeholder: `[
{
"text": "I am a test message",
"attachments": [
  {
    "text": "And here’s an attachment!"
  }
]
}
]`,
                  useLightTheme: true,
                  language: 'json',
                },
                authenticatedUserInput,
              ]
            : [],
      };
    },
  ],
};

export default connectConfig;
