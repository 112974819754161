import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.FTP;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'FTP_TEST_CONNECTION',
  LIST_FILES = 'FTP_LIST_FILES',
  DOWNLOAD_FILE = 'FTP_DOWNLOAD_FILE',
  UPLOAD_FILE = 'FTP_UPLOAD_FILE',
  DELETE_FILE = 'FTP_DELETE_FILE',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.LIST_FILES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPLOAD_FILE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DOWNLOAD_FILE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_FILE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type FTPCredentials = {
  PROTOCOL: 'FTP';
  HOST: string;
  PORT: number;
  USERNAME: string;
  PASSWORD: string;
};

export type SFTPCredentials = {
  PROTOCOL: 'SFTP';
  HOST: string;
  PORT: number;
  USERNAME: string;
  PASSWORD?: string;
  PRIVATE_KEY?: string;
  PRIVATE_KEY_PASSWORD?: string;
};

export type Credentials = FTPCredentials | SFTPCredentials;
