import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import {
  CAMPAIGN_CREATE_DATE_FILTER,
  CAMPAIGN_SENT_DATE_FILTER,
  LIST_CAMPAIGN_DATE_FILTER,
  LIST_CREATE_DATE_FILTER,
} from '../../shared';

export const mailchimpFilterKeyMap = {
  [CAMPAIGN_CREATE_DATE_FILTER]: {
    [Operator.DateTimeBefore]: 'before_create_time',
    [Operator.DateTimeAfter]: 'since_create_time',
  },
  [CAMPAIGN_SENT_DATE_FILTER]: {
    [Operator.DateTimeBefore]: 'before_send_time',
    [Operator.DateTimeAfter]: 'since_send_time',
  },
  [LIST_CAMPAIGN_DATE_FILTER]: {
    [Operator.DateTimeBefore]: 'before_campaign_last_sent',
    [Operator.DateTimeAfter]: 'since_campaign_last_sent',
  },
  [LIST_CREATE_DATE_FILTER]: {
    [Operator.DateTimeBefore]: 'before_date_created',
    [Operator.DateTimeAfter]: 'since_date_created',
  },
};

export default function conditionsToFilterMailchimpExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterMailchimpExpression(condition);
      })
      .join('&');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    let filterKey: string = innerCondition.variable;

    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable}=${innerCondition.argument}`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable}!=${innerCondition.argument}`;
      case Operator.NumberEquals:
        return `${innerCondition.variable}=${innerCondition.argument}`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable}=${new Date(innerCondition.argument).toISOString()}`;
      case Operator.DateTimeAfter:
        if (innerCondition.variable in mailchimpFilterKeyMap) {
          filterKey = mailchimpFilterKeyMap[innerCondition.variable][innerCondition.operator];
        }
        return `${filterKey}=${new Date(innerCondition.argument).toISOString()}`;
      case Operator.DateTimeBefore:
        if (innerCondition.variable in mailchimpFilterKeyMap) {
          filterKey = mailchimpFilterKeyMap[innerCondition.variable][innerCondition.operator];
        }
        return `${filterKey}=${new Date(innerCondition.argument).toISOString()}`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Mailchimp`);
    }
  }
  return '';
}
