import { StepType } from '../steps';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class CacheError extends ParagonError {
  name: string = 'CacheError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class CachedStateMachineNotFoundError extends CacheError {
  name: string = 'CachedStateMachineNotFoundError';

  constructor(meta: { workflowId: string }) {
    super({
      code: ERROR_CODE.CACHED_STATE_MACHINE_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cached state machine not found.',
      meta,
    });
  }
}

export class CachedStateMachineTriggerMismatchError extends CacheError {
  name: string = 'CachedStateMachineTriggerMismatchError';

  constructor(meta: {
    workflowId: string;
    projectId?: string;
    expected: StepType;
    actual?: StepType;
  }) {
    super({
      code: ERROR_CODE.CACHED_STATE_MACHINE_TRIGGER_MISMATCH,
      httpStatus: HttpStatus.CONFLICT,
      message: 'Cached state machine trigger does not match expected value.',
      meta,
    });
  }
}

export class CacheMissingOwnerIdError extends CacheError {
  name: string = 'CacheMissingOwnerIdError';

  constructor(meta: { workflowId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CACHE_MISSING_OWNER_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cache missing `ownerId`.',
      meta,
    });
  }
}

export class CacheMissingProjectIdError extends CacheError {
  name: string = 'CacheMissingProjectIdError';

  constructor(meta: { workflowId: string }) {
    super({
      code: ERROR_CODE.CACHE_MISSING_PROJECT_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cache missing `projectId`.',
      meta,
    });
  }
}

export class CacheMissingWorkflowMigrationIdError extends CacheError {
  name: string = 'CacheMissingWorkflowMigrationIdError';

  constructor(meta: { workflowId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CACHE_MISSING_WORKFLOW_MIGRATION_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cache missing `workflowMigrationId`.',
      meta,
    });
  }
}

export class CacheMissingConnectCredentialIdError extends CacheError {
  name: string = 'CacheMissingConnectCredentialIdError';

  constructor(meta: { workflowId: string; projectId: string }) {
    super({
      code: ERROR_CODE.CACHE_MISSING_CONNECT_CREDENTIAL_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cache missing `connectCredentialId`.',
      meta,
    });
  }
}

export class CacheMissingConnectCredentialError extends CacheError {
  name: string = 'CacheMissingConnectCredentialError';

  constructor(meta: { workflowId: string; connectCredentialId?: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CACHE_MISSING_CONNECT_CREDENTIAL,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cache missing connect credential.',
      meta,
    });
  }
}

export class CacheMissingPersonaMeta extends CacheError {
  name: string = 'CacheMissingPersonaMeta';

  constructor(meta: { workflowId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CACHE_MISSING_PERSONA_META,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cache missing persona meta.',
      meta,
    });
  }
}

export class CacheMissingTriggerInputError extends CacheError {
  name: string = 'CacheMissingTriggerInputError';

  constructor(meta: { workflowId: string; executionId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CACHE_MISSING_TRIGGER_INPUT,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cache missing trigger input.',
      meta,
    });
  }
}

export class CacheMissingIntegrationIdError extends CacheError {
  name: string = 'CacheMissingIntegrationIdError';

  constructor(meta: { workflowId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CACHE_MISSING_INTEGRATION_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Cache missing integration id.',
      meta,
    });
  }
}
