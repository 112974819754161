export type SuppliersResponse = {
  d: {
    results: Record<string, any>[];
  };
};

export type SearchRecordResponse = SuppliersResponse;

export type LinkWrapperType = {
  clickableText: string;
  href: string;
};

/**
 * @summmary using whole url after the common base url here, some api use odatav4 as(odata4/sap), some use odatav2 as(odata/sap)
 */
export enum RecordType {
  SUPPLIER = '/odata/sap/API_BUSINESS_PARTNER/A_Supplier',
  CUSTOMER = '/odata/sap/API_BUSINESS_PARTNER/A_Customer',
  SUPPLIER_INVOICE = '/odata/sap/API_SUPPLIERINVOICE_PROCESS_SRV/A_SupplierInvoice',
}

export type PreparePayload = {
  recordId?: string;
  payload: Record<string, any>;
};

export type CreateRecordDTO = CreateSupplierInvoice | UpdateCustomer | UpdateSupplier;

export type GetRecordByIdDTO = GetSupplierInvoice | GetCustomer | GetSupplier;

export type UpdateRecordDTO = CreateRecordDTO;

export type SearchRecordDTO =
  | {
      supplierInvoiceFilterFormula?: string;
    }
  | {
      supplierFilterFormula?: string;
    }
  | {
      customerFilterFormula?: string;
    };

export type CreateSupplierInvoice = {
  CompanyCode: string;
  DocumentDate: string;
  PostingDate: string;
  DocumentCurrency: string;
  InvoiceGrossAmount: string;
  supplierInvoiceAdditionalFields?: string;
};

export type UpdateCustomer = {
  Customer: string;
  AuthorizationGroup: string;
  CustomerClassification: string;
  CustomerCorporateGroup: string;
  CustomerAccountGroup: string;
  customerAdditionalFields: string;
};

export type UpdateSupplier = {
  Supplier: string;
  AlternativePayeeAccountNumber: string;
  AuthorizationGroup: string;
  Customer: string;
  VATRegistration: string;
  FiscalAddress: string;
  supplierAdditionalFields: string;
};

export type GetSupplierInvoice = {
  SupplierInvoice: string;
  FiscalYear: string;
};

export type GetCustomer = {
  Customer: string;
};

export type GetSupplier = {
  Supplier: string;
};

export type MockAPIDataInfo = {
  method: 'get' | 'post' | 'delete' | 'put' | 'patch';
  response: any;
  isError?: boolean;
};
