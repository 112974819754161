import {
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { RecordType, pipedriveSupportedOperators } from '../shared';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          value: Intent.CREATE_RECORD,
          label: 'Create Record',
        },
        {
          value: Intent.UPDATE_RECORD,
          label: 'Update Record',
        },
        {
          value: Intent.GET_RECORD_BY_ID,
          label: 'Get Record by ID',
        },
        {
          value: Intent.GET_RECORDS,
          label: 'Get Records',
        },
        {
          value: Intent.DELETE_RECORD,
          label: 'Delete Record',
        },
      ],
    },
  ],
};

export const RecordTypesEnumInputCommonObjects: EnumInputValue<any>[] = [
  {
    value: RecordType.DEALS,
    label: 'Deals',
  },
  {
    value: RecordType.ORGANIZATIONS,
    label: 'Organizations',
  },
  {
    value: RecordType.PERSONS,
    label: 'Persons',
  },
];

/**
 * Return record types for intent
 * @param intent
 * @returns {SidebarInput}
 */
export const recordTypeSelector = (intent: Intent): SidebarInput => ({
  id: 'recordType',
  type: SidebarInputType.Enum,
  title: 'Record Type',
  required: true,
  getValues: () => {
    switch (intent) {
      case Intent.CREATE_RECORD:
      case Intent.DELETE_RECORD:
      case Intent.GET_RECORD_BY_ID: {
        return [
          {
            value: RecordType.ACTIVITIES,
            label: 'Activities',
          },
          ...RecordTypesEnumInputCommonObjects,
          {
            value: RecordType.LEADS,
            label: 'Leads',
          },
          {
            value: RecordType.NOTES,
            label: 'Notes',
          },
          {
            value: RecordType.USERS,
            label: 'Users',
          },
        ];
      }

      case Intent.UPDATE_RECORD: {
        return [
          {
            value: RecordType.ACTIVITIES,
            label: 'Activities',
          },
          ...RecordTypesEnumInputCommonObjects,
          {
            value: RecordType.LEADS,
            label: 'Leads',
          },
          {
            value: RecordType.NOTES,
            label: 'Notes',
          },
        ];
      }

      case Intent.GET_RECORDS: {
        return [
          ...RecordTypesEnumInputCommonObjects,
          {
            value: RecordType.LEADS,
            label: 'Leads',
          },
        ];
      }

      case Intent.TRIGGER_RECORD_CREATED:
      case Intent.TRIGGER_RECORD_UPDATED: {
        return [
          {
            value: RecordType.ACTIVITIES,
            label: 'Activities',
          },
          ...RecordTypesEnumInputCommonObjects,
          {
            value: RecordType.NOTES,
            label: 'Notes',
          },
        ];
      }

      default:
        return [];
    }
  },
});

export const subjectInput: SidebarInput = {
  id: 'subject',
  title: 'Subject',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const noteInput: SidebarInput = {
  id: 'note',
  title: 'Note',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const typeInput: SidebarInput = {
  id: 'type',
  title: 'Type',
  subtitle: 'Defaults to Call if left blank.',
  type: SidebarInputType.Enum,
  required: false,
  getValues: () => [
    { label: 'Call', value: 'call' },
    { label: 'Meeting', value: 'meeting' },
    { label: 'Task', value: 'task' },
    { label: 'Deadline', value: 'deadline' },
    { label: 'Email', value: 'email' },
    { label: 'Lunch', value: 'lunch' },
  ],
};

export const dueDateInput: SidebarInput = {
  id: 'dueDate',
  title: 'Due Date',
  subtitle: 'Accepts Unix timestamp or ISO8601 date formats.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const expectedCloseDateInput: SidebarInput = {
  ...dueDateInput,
  id: 'expectedCloseDate',
  title: 'Expected Close Date',
};

export const locationInput: SidebarInput = {
  id: 'location',
  title: 'Location',
  subtitle: 'The address of the activity.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const doneInput: SidebarInput = {
  id: 'done',
  title: 'Done',
  type: SidebarInputType.BooleanInput,
  required: false,
};

export const userIdInput: SidebarInput = {
  id: 'userId',
  title: 'User ID',
  subtitle:
    'The ID of the user whom the activity is assigned to. Defaults to the currently authenticated user if left blank.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const recordIdInput: SidebarInput = {
  id: 'recordId',
  title: 'Record ID',
  subtitle: 'The ID of the activity that will be updated.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const ownerInput: SidebarInput = {
  id: 'ownerId',
  title: 'Owner ID',
  subtitle:
    'The ID of the user who the deal will be the owner of the lead. Defaults to the currently authenticated user if left blank.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const dealIdInput: SidebarInput = {
  id: 'dealId',
  title: 'Deal ID',
  subtitle: 'The ID of the deal this activity is associated with.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const leadIdInput: SidebarInput = {
  id: 'leadId',
  title: 'Lead ID',
  subtitle: 'The ID of the Lead this activity is associated with.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const personIdInput: SidebarInput = {
  id: 'personId',
  title: 'Person ID',
  subtitle: 'The ID of the Person this activity is associated with.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const organizationIdInput: SidebarInput = {
  id: 'organizationId',
  title: 'Organization ID',
  subtitle: 'The ID of the Organization this activity is associated with.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const titleInput: SidebarInput = {
  id: 'title',
  title: 'Title',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const valueInput: SidebarInput = {
  id: 'value',
  title: 'Value',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const statusInput: SidebarInput = {
  id: 'status',
  title: 'Status',
  subtitle: 'Defaults to Open if left blank.',
  type: SidebarInputType.Enum,
  defaultValue: 'open',
  required: false,
  getValues: () => [
    { label: 'Open', value: 'open' },
    { label: 'Won', value: 'won' },
    { label: 'Lost', value: 'lost' },
    { label: 'Deleted', value: 'deleted' },
  ],
};

export const pipelineIdInput: SidebarInput = {
  id: 'pipelineId',
  title: 'Pipeline ID',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select which pipeline the deal will be added to. By default, the deal will be added to the first stage of the specified pipeline.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const stageIdInput: SidebarInput = {
  id: 'stageId',
  title: 'Stage ID',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select which stage the deal will be added to. A pipeline will be assigned automatically based on the Stage ID. If left blank, the deal will be placed in the first stage of the default pipeline.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  title: 'Additional Fields',
  subtitle:
    'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which deal fields to update.',
  type: SidebarInputType.Code,
  required: false,
  language: 'json',
  placeholder: `{
"property_number": "17",
"property_dropdown": "choice_b",
"property_radio": "option_1",
"property_string": "value",
"property_date": "1572480000000"
}`,
  lines: 5,
  useLightTheme: true,
};

export const contentInput: SidebarInput = {
  id: 'content',
  title: 'Content',
  subtitle: 'The content of the note. Supports HTML.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const emailInput: SidebarInput = {
  id: 'email',
  title: 'Email',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const phoneInput: SidebarInput = {
  id: 'phone',
  title: 'Phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const activeStatusInput: SidebarInput = {
  id: 'activeStatus',
  title: 'Active Status',
  type: SidebarInputType.Enum,
  defaultValue: 'true',
  required: true,
  getValues: () => [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ],
};

export const termInput: SidebarInput = {
  id: 'term',
  title: 'Term',
  subtitle: 'The search term to look for.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const recordFilterInput: SidebarInput = {
  id: 'recordFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for items that match specified filters.',
  supportedOperators: pipedriveSupportedOperators,
  getValues: () => [
    { label: 'Person Id', value: 'person_id' },
    { label: 'Organization Id', value: 'organization_id' },
  ],
};

/**
 * It returns input based on selected record type
 * @param isUpdate When we select UPDATE_RECORD it return true
 * @returns {Partial<Record<RecordType, SidebarInput[]>>}
 */
export const getRecordInputFields = (
  isUpdate: boolean = false,
): Record<RecordType, SidebarInput[]> | Partial<Record<RecordType.USERS, SidebarInput[]>> => ({
  [RecordType.ACTIVITIES]: [
    ...(isUpdate ? [recordIdInput] : []),
    subjectInput,
    noteInput,
    ...(isUpdate ? [{ ...typeInput, subtitle: '' }] : [typeInput]),
    dueDateInput,
    locationInput,
    doneInput,
    ...(isUpdate
      ? [{ ...userIdInput, subtitle: 'The ID of the user whom the activity is assigned to.' }]
      : [userIdInput]),
    dealIdInput,
    leadIdInput,
    personIdInput,
    organizationIdInput,
  ],
  [RecordType.DEALS]: [
    ...(isUpdate
      ? [{ ...recordIdInput, subtitle: 'The ID of the deal that will be updated.' }]
      : []),
    ...(isUpdate ? [titleInput] : [{ ...titleInput, required: true }]),
    valueInput,
    statusInput,
    expectedCloseDateInput,
    {
      ...userIdInput,
      subtitle: isUpdate
        ? 'The ID of the user who the deal will be assigned to.'
        : 'The ID of the user who the deal will be assigned to. Defaults to the currently authenticated user if left blank.',
    },
    {
      ...personIdInput,
      subtitle: 'The ID of the person who the deal will be assigned to.',
    },
    {
      ...organizationIdInput,
      subtitle: 'The ID of an organization which this deal will be linked to.',
    },
    ...(isUpdate
      ? [
          {
            ...pipelineIdInput,
            subtitle:
              'Use Connect Portal Workflow Settings to allow users to select which pipeline the deal will be added to.',
          },
        ]
      : [pipelineIdInput]),
    ...(isUpdate
      ? [
          {
            ...stageIdInput,
            subtitle:
              'Use Connect Portal Workflow Settings to allow users to select which stage the deal will be added to. A pipeline will be assigned automatically based on the Stage ID.',
          },
        ]
      : [stageIdInput]),
    additionalFieldsInput,
  ],
  [RecordType.LEADS]: [
    ...(isUpdate
      ? [{ ...recordIdInput, subtitle: 'The ID of the lead that will be updated.' }]
      : []),
    ...(isUpdate ? [titleInput] : [{ ...titleInput, required: true }]),
    valueInput,
    expectedCloseDateInput,
    ...(isUpdate
      ? [
          {
            ...ownerInput,
            subtitle: 'The ID of the user who the deal will be the owner of the lead.',
          },
        ]
      : [ownerInput]),
    {
      ...personIdInput,
      subtitle: isUpdate
        ? 'The ID of a person which this lead will be linked to.'
        : 'The ID of a person which this lead will be linked to. This property is required unless Organization ID is specified.',
    },
    {
      ...organizationIdInput,
      subtitle: isUpdate
        ? 'The ID of an organization which this lead will be linked to.'
        : 'The ID of an organization which this lead will be linked to. This property is required unless Person ID is specified.',
    },
    {
      ...additionalFieldsInput,
      subtitle:
        'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which lead fields to update.',
    },
  ],
  [RecordType.NOTES]: [
    ...(isUpdate
      ? [{ ...recordIdInput, subtitle: 'The ID of the note that will be updated.' }]
      : []),
    contentInput,
    {
      ...leadIdInput,
      subtitle: isUpdate
        ? 'The ID of the lead the note will be attached to.'
        : 'The ID of the lead the note will be attached to. Required unless Person ID, Organization ID, or Deal ID is provided.',
    },
    {
      ...personIdInput,
      subtitle: isUpdate
        ? 'The ID of the person the note will be attached to.'
        : 'The ID of the person the note will be attached to. Required unless Lead ID, Organization ID, or Deal ID is provided.',
    },
    {
      ...organizationIdInput,
      subtitle: isUpdate
        ? 'The ID of the organization the note will be attached to.'
        : 'The ID of the organization the note will be attached to. Required unless Lead ID, Person ID, or Deal ID is provided.',
    },
    {
      ...dealIdInput,
      subtitle: isUpdate
        ? 'The ID of the Deal the note will be attached to.'
        : 'The ID of the Deal the note will be attached to. Required unless Lead ID, Person ID, or Organization ID is provided.',
    },
  ],
  [RecordType.ORGANIZATIONS]: [
    ...(isUpdate
      ? [{ ...recordIdInput, subtitle: 'The ID of the organization that will be updated.' }]
      : []),
    nameInput,
    {
      ...ownerInput,
      subtitle: isUpdate
        ? 'The ID of the user who will be marked as the owner of the organization.'
        : 'The ID of the user who will be marked as the owner of the organization. Defaults to the currently authenticated user if left blank.',
    },
    {
      ...additionalFieldsInput,
      subtitle:
        'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which organization fields to update.',
    },
  ],
  [RecordType.PERSONS]: [
    ...(isUpdate
      ? [{ ...recordIdInput, subtitle: 'The ID of the person that will be updated.' }]
      : []),
    { ...nameInput, required: !isUpdate },
    emailInput,
    phoneInput,
    { ...organizationIdInput, subtitle: 'The ID of the organization this person will belong to.' },
    {
      ...ownerInput,
      subtitle: isUpdate
        ? 'The ID of the user who will be marked as the owner of the person.'
        : 'The ID of the user who will be marked as the owner of the person. Defaults to the currently authenticated user if left blank.',
    },
    {
      ...additionalFieldsInput,
      subtitle:
        'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which persons fields to update.',
    },
  ],
  ...(!isUpdate
    ? {
        [RecordType.USERS]: [nameInput, { ...emailInput, required: true }, activeStatusInput],
      }
    : {}),
});
