import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  FieldMapperDataSource,
} from '@shared/types/sdk/actions';

import { ActionStepParameters, Intent } from '../configs';
import { GetColumn, GetList, SearchSite } from '../shared';

const listCacheKey = 'listCacheKey';
const columnCacheKey = 'columnCacheKey';

export const getLists: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: listCacheKey,
  title: 'List',
  subtitle: 'List in a SharePoint site',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<any>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((list: GetList) => ({ label: list.name, value: list.id }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.SHAREPOINT,
      intent: Intent.GET_LISTS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getColumns: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: columnCacheKey,
  title: 'Column',
  subtitle: 'List columns to update',
  refreshDependencies: [listCacheKey],
  hideFromConnectFieldTypes: true,
  mapRefreshToValues: (response: ActionResponse<any>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((column: GetColumn) => ({
          label: column.name,
          value: column.name,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const list = pickValueSourceByKey(options.actionParameters, listCacheKey);
    return {
      actionType: Action.SHAREPOINT,
      intent: Intent.GET_LIST_COLUMNS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'listId',
          source: {
            type: 'VALUE',
            value: list?.value,
          },
        },
      ],
    };
  },
};

export const comboColumnSharePointFields: ComboInputDataSource = {
  id: 'comboColumnFields',
  type: DataSourceType.COMBO_INPUT,
  title: 'Column',
  subtitle: 'List columns to update',
  mainInputSource: getLists,
  dependentInputSource: getColumns,
};

export const mapSharePointFields: FieldMapperDataSource = {
  id: 'customItemMapping',
  type: DataSourceType.FIELD_MAPPER,
  title: 'Custom Item Mapping',
  subtitle: 'Allow users to define a custom item mapping',
  recordSource: getLists,
  fieldSource: getColumns,
};

export const getSites: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'getSites',
  title: 'Choose a SharePoint site to use',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  hideFromConnectFieldTypes: true,
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_SITES,
      actionType: Action.SHAREPOINT,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: function (response: ActionResponse<any>): EnumInputValue[] {
    return response?.result
      ? response.result.output.map((sites: SearchSite) => ({
          label: sites.displayName,
          value: sites.id.split(',')[1].trim(),
        }))
      : [];
  },
};
