export type FeaturesResponse = Record<string, any>;

export type TokenDecodeResponse = {
  user_id: number;
  space_id: string;
  role: string;
};

export enum RecordType {
  FEATURES = 'features',
  COMPONENTS = 'components',
  PRODUCTS = 'products',
}

export type CreateorUpdateFeatureDTO = {
  featureId: string;
  name?: string;
  description?: string;
  type?: string;
  status?: string | object;
  featureParentType?: string;
  parentId?: string;
  email?: string;
};

export type CreateorUpdateComponentDTO = {
  componentId: string;
  name?: string;
  description?: string;
  componentParentType?: string;
  parentId?: string;
  email?: string;
};

export type GetRecordByIdDTO =
  | { featureId: string }
  | { productId: string }
  | {
      componentId: string;
    };

export type DeleteRecordDTO = GetRecordByIdDTO;

export type UpdateRecordDTO = CreateorUpdateFeatureDTO;

export type CreateRecordDTO = CreateorUpdateFeatureDTO | CreateorUpdateComponentDTO;

export type LinkWrapperType = { clickableText: string; href: string };

export type GetRecordByIdResponse = {
  data: Record<string, any>;
};

export type CreateRecordResponse = GetRecordByIdResponse;
export type UpdateRecordResponse = GetRecordByIdResponse;
