import { ActionStep, ActionTriggerStep } from '@shared/types/sdk/actions';
import { DataType, DataTypeValues, KeyedSource } from '@shared/types/sdk/resolvers';
import {
  ActionTriggerVariables,
  ActionVariables,
  BodyValidation,
  ConditionalStep,
  ConditionalVariables,
  CronStep,
  CronVariables,
  CustomIntegrationRequestStep,
  CustomIntegrationRequestVariables,
  DelayStep,
  DelayVariables,
  EndpointStep,
  EndpointVariables,
  EventStep,
  EventVariables,
  FunctionStep,
  FunctionVariables,
  HeaderValidation,
  IntegrationEnabledStep,
  IntegrationEnabledVariables,
  MapStep,
  MapVariables,
  OAuthStep,
  OAuthVariables,
  ParamValidation,
  RedirectStep,
  RedirectVariables,
  RequestStep,
  RequestVariables,
  ResponseStep,
  ResponseTypeEnum,
  ResponseVariables,
  Step,
  StepType,
  Variables,
} from '@shared/types/sdk/steps';

const fromEntries = require('fromentries');

/**
 * Transform a KeyedSource array to an object. For each KeyedSource, if the source is a ValueSource,
 * use its value as the object entry value. Otherwise, try to format the key's value out stored
 * variables from a previous execution. If it's not present there, use a default (zero) value
 *
 * @todo move to packages/workflow
 * @param keyedSources
 * @param storedVariables
 * @param defaultValue
 */
function extractKeyedSourceValues<T extends DataType>(
  keyedSources: KeyedSource<T>[],
  storedVariables: Record<string, DataTypeValues[T]>,
  defaultValue: DataTypeValues[T],
): Record<string, DataTypeValues[T]> {
  return fromEntries(
    keyedSources.map(({ key, source }: KeyedSource<T>) => [
      key,
      source.type === 'VALUE'
        ? source.value
        : key in storedVariables
        ? storedVariables[key]
        : defaultValue,
    ]),
  ) as Record<string, DataTypeValues[T]>;
}

function formatEndpointStepVariables(
  step: EndpointStep,
  storedVariables: EndpointVariables | undefined,
): EndpointVariables {
  const storedParams = storedVariables?.request?.params || {};
  const storedHeaders = storedVariables?.request?.headers || {};
  const storedBody: Record<string, any> = storedVariables?.request?.body || {};
  if (step.parameters.allowArbitraryPayload) {
    return {
      type: StepType.ENDPOINT,
      fanoutStack: storedVariables?.fanoutStack || [],
      request: {
        params: storedParams,
        headers: storedHeaders,
        body: storedBody,
      },
    };
  }

  return {
    type: StepType.ENDPOINT,
    fanoutStack: storedVariables?.fanoutStack || [],
    request: {
      params: fromEntries(
        step.parameters.paramValidations.map(({ key }: ParamValidation) => [
          key,
          storedParams[key] || '',
        ]),
      ),
      headers: fromEntries(
        step.parameters.headerValidations.map(({ key }: HeaderValidation) => [
          key,
          storedHeaders[key] || '',
        ]),
      ),
      body: fromEntries(
        step.parameters.bodyValidations.map(({ key }: BodyValidation) => [
          key,
          key in storedBody ? storedBody[key] : null,
        ]),
      ),
    },
  };
}

function formatFunctionStepVariables(
  _step: FunctionStep,
  storedVariables: FunctionVariables | undefined,
): FunctionVariables {
  return {
    type: StepType.FUNCTION,
    fanoutStack: storedVariables?.fanoutStack || [],
    result: null,
    ...storedVariables,
  };
}

function formatRequestStepVariables(
  _step: RequestStep,
  storedVariables: RequestVariables | undefined,
): RequestVariables {
  return {
    type: StepType.REQUEST,
    fanoutStack: storedVariables?.fanoutStack || [],
    response: {
      statusCode: 200,
      body: {},
      headers: {},
      ...(storedVariables?.response || {}),
    },
  };
}

function formatCustomIntegrationRequestStepVariables(
  _step: CustomIntegrationRequestStep,
  storedVariables: CustomIntegrationRequestVariables | undefined,
): CustomIntegrationRequestVariables {
  return {
    type: StepType.CUSTOM_INTEGRATION_REQUEST,
    fanoutStack: storedVariables?.fanoutStack || [],
    response: {
      statusCode: 200,
      body: {},
      headers: {},
      ...(storedVariables?.response || {}),
    },
  };
}

function formatRedirectStepVariables(
  _step: RedirectStep,
  storedVariables: RedirectVariables | undefined,
): RedirectVariables {
  return {
    type: StepType.REDIRECT,
    fanoutStack: storedVariables?.fanoutStack || [],
    redirectUrl: storedVariables?.redirectUrl || '',
  };
}

function formatResponseStepVariables(
  step: ResponseStep,
  storedVariables: ResponseVariables | undefined,
): ResponseVariables {
  return {
    type: StepType.RESPONSE,
    fanoutStack: storedVariables?.fanoutStack || [],
    statusCode: step.parameters.statusCode,
    ...(step.parameters.responseType === ResponseTypeEnum.FILE
      ? { file: storedVariables?.file }
      : {
          body: extractKeyedSourceValues(
            step.parameters.bodyData,
            storedVariables?.body || {},
            null,
          ),
        }),
  };
}

function formatMapStepVariables(
  step: MapStep,
  storedVariables: MapVariables | undefined,
): MapVariables {
  return {
    type: StepType.MAP,
    fanoutStack: storedVariables?.fanoutStack || [],
    instance:
      step.parameters.iterator.type === 'VALUE' && step.parameters.iterator.value.length > 0
        ? step.parameters.iterator.value[0]
        : null,
    ...storedVariables,
  };
}

function formatActionStepVariables(
  _step: ActionStep,
  storedVariables: ActionVariables,
): ActionVariables {
  return { ...storedVariables, result: storedVariables?.result ?? null, type: StepType.ACTION };
}

function formatActionTriggerStepVariables(
  _step: ActionTriggerStep,
  storedVariables: ActionTriggerVariables,
): ActionTriggerVariables {
  return {
    ...storedVariables,
    result: storedVariables.result ?? null,
    type: StepType.ACTION_TRIGGER,
  };
}

function formatConditionalStepVariables(
  _step: ConditionalStep,
  storedVariables: ConditionalVariables,
): ConditionalVariables {
  return {
    ...storedVariables,
    fanoutStack: storedVariables?.fanoutStack || [],
    type: StepType.IFELSE,
  };
}

function formatOAuthStepVariables(
  _step: OAuthStep,
  storedVariables: OAuthVariables,
): OAuthVariables {
  return { ...storedVariables, type: StepType.OAUTH };
}

function formatEventStepVariables(
  _step: EventStep,
  storedVariables: EventVariables,
): EventVariables {
  return { ...storedVariables, type: StepType.EVENT };
}

function formatIntegrationEnabledVariables(
  _step: IntegrationEnabledStep,
  storedVariables: IntegrationEnabledVariables,
): IntegrationEnabledVariables {
  return { ...storedVariables, type: StepType.INTEGRATION_ENABLED };
}

function formatDelayStepVariables(
  _step: DelayStep,
  storedVariables: DelayVariables,
): DelayVariables {
  return { ...storedVariables, type: StepType.DELAY };
}

function formatCronStepVariables(_step: CronStep, storedVariables: CronVariables): CronVariables {
  return { ...storedVariables, type: StepType.CRON };
}

export function formatStepVariables(step: Step, variables: Variables): Variables | undefined {
  switch (step.type) {
    case StepType.ENDPOINT:
      return formatEndpointStepVariables(step, variables as EndpointVariables);
    case StepType.FUNCTION:
      return formatFunctionStepVariables(step, variables as FunctionVariables);
    case StepType.IFELSE:
      return formatConditionalStepVariables(step, variables as ConditionalVariables);
    case StepType.REQUEST:
      return formatRequestStepVariables(step, variables as RequestVariables);
    case StepType.RESPONSE:
      return formatResponseStepVariables(step, variables as ResponseVariables);
    case StepType.REDIRECT:
      return formatRedirectStepVariables(step, variables as RedirectVariables);
    case StepType.MAP:
      return formatMapStepVariables(step, variables as MapVariables);
    case StepType.ACTION:
      return formatActionStepVariables(step, variables as ActionVariables);
    case StepType.ACTION_TRIGGER:
      return formatActionTriggerStepVariables(step, variables as ActionTriggerVariables);
    case StepType.OAUTH:
      return formatOAuthStepVariables(step, variables as OAuthVariables);
    case StepType.DELAY:
      return formatDelayStepVariables(step, variables as DelayVariables);
    case StepType.UNSELECTED_TRIGGER:
      return undefined;
    case StepType.CRON:
      return formatCronStepVariables(step, variables as CronVariables);
    case StepType.EVENT:
      return formatEventStepVariables(step, variables as EventVariables);
    case StepType.INTEGRATION_ENABLED:
      return formatIntegrationEnabledVariables(step, variables as IntegrationEnabledVariables);
    case StepType.CUSTOM_INTEGRATION_REQUEST:
      return formatCustomIntegrationRequestStepVariables(
        step,
        variables as CustomIntegrationRequestVariables,
      );
  }
}
