import React from 'react';

import { createTestConnectionButton, getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionButtonStyle,
  ActionConfig,
  ActionStepParameters,
  EnumInputValue,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';
import {
  DEFAULT_SUPPORTED_OPERATORS,
  DataType,
  KeyedSource,
  Operator,
  ValueSource,
} from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { FIELDS_PREFIX } from '../shared/constants';

import { baseInput, tableInput, viewInput } from './inputs';
import { getColumns } from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.NONE,
  provider: ProviderType.AIRTABLE,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your Airtable account',
  description: 'Add Airtable to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name your Airtable account',
          subtitle: 'Label this Airtable account for use in your Paragon workflows.',
          placeholder: 'e.g. Production, Testing, etc.',
          type: SidebarInputType.ValueText,
          hiddenInConnect: true,
        },
        {
          id: 'AIRTABLE_API_KEY',
          title: 'Add your Airtable API key',
          subtitle: (
            <>
              To find your Airtable API key, see your{' '}
              <a href="https://airtable.com/account" target="_blank" rel="noreferrer">
                account settings
              </a>
              .
            </>
          ),
          placeholder: 'key123abc',
          type: SidebarInputType.ValueText,
          required: true,
        },
      ],
      buttons: [createTestConnectionButton(Action.AIRTABLE, ['AIRTABLE_API_KEY'])],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.AIRTABLE,
  name: 'Airtable',
  description: 'Find, create, and update records from your Airtable bases.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect your Airtable account',
          placeholder: 'choose your Airtable account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.FETCH_RECORDS,
                    label: 'Get Records',
                  },
                  {
                    value: Intent.CREATE_RECORD,
                    label: 'Create a Record',
                  },
                  {
                    value: Intent.UPDATE_RECORD,
                    label: 'Update a Record',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.FETCH_RECORDS:
          return {
            inputs: [
              baseInput,
              tableInput,
              viewInput,
              {
                id: 'filterFormula',
                type: SidebarInputType.DynamicConditional,
                title: 'Filter records',
                subtitle:
                  'You can filter which records get returned from Airtable by applying one or more filters below.',
                placeholder: 'filter by field',
                source: getColumns,
                getValues: getValuesByCacheKey,
                supportedOperators: [
                  ...DEFAULT_SUPPORTED_OPERATORS,
                  Operator.NumberLessThanOrEqualTo,
                  Operator.NumberGreaterThanOrEqualTo,
                ].filter(
                  // TODO: (PARA-1413) add support for AirTable `ArrayIsEmpty` & `ArrayIsNotEmpty
                  (op: Operator) => ![Operator.ArrayIsEmpty, Operator.ArrayIsNotEmpty].includes(op),
                ),
                required: false,
              },
              {
                id: 'limit',
                type: SidebarInputType.Text,
                title: 'Limit records',
                subtitle:
                  'You can limit the maximum number of records that get returned from Airtable.',
                required: false,
              },
            ],
            buttons: [
              {
                id: 'refreshFields',
                type: SidebarInputType.ActionButton,
                title: 'Refresh Fields',
                style: ActionButtonStyle.DEFAULT,
                source: getColumns,
              },
            ],
          };
        case Intent.CREATE_RECORD:
          return {
            inputs: [baseInput, tableInput],
          };
        case Intent.UPDATE_RECORD:
          return {
            inputs: [
              baseInput,
              tableInput,
              {
                id: 'recordId',
                type: SidebarInputType.Text,
                title: 'Record ID',
                subtitle: (
                  <>
                    We recommend that you add a <strong>Get Records</strong> Airtable Step before
                    this step to get the ID of the record you want to update.
                  </>
                ),
                required: true,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (![Intent.CREATE_RECORD, Intent.UPDATE_RECORD].includes(parameters.intent as Intent)) {
        return { inputs: [] };
      }
      return {
        inputs: (
          (
            parameters.actionParameters.find(
              (source: KeyedSource<DataType.ANY>) => source.key === 'columnNames',
            )?.source as ValueSource
          )?.value || []
        ).map(({ label, value }: EnumInputValue) => ({
          id: `${FIELDS_PREFIX}${label}`,
          title: label,
          subtitle:
            value === DataType.ARRAY ? 'Specify an array of values or linked Record IDs.' : '',
          type: SidebarInputType.TextArea,
          lines: 1,
          required: false,
        })),
        buttons: [
          {
            id: 'refreshFields',
            type: SidebarInputType.ActionButton,
            title: 'Refresh Fields',
            style: ActionButtonStyle.DEFAULT,
            source: getColumns,
          },
        ],
      };
    },
  ],
};

export default config;
