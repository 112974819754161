import { DecryptedCredential } from '@shared/entities/sdk/credential/credential.interface';

import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<DecryptedCredential> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<DecryptedCredential> = initialState,
  action: Action,
): EntitiesState<DecryptedCredential> {
  switch (action.type) {
    case 'DECRYPTED_CREDENTIAL_FETCH_START':
      return {
        ...state,
        processing: true,
      };

    case 'DECRYPTED_CREDENTIAL_FETCH_SUCCESS':
      const newState: EntitiesState<DecryptedCredential> = {
        entities: { [action.credential.id]: action.credential },
        errorMessage: undefined,
        processing: false,
      };
      return {
        ...newState,
        processing: false,
      };

    case 'DECRYPTED_CREDENTIAL_FETCH_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'DECRYPTED_CREDENTIAL_RESET_WITH_EMPTY':
      return {
        entities: {},
        processing: false,
      };

    default:
      return state;
  }
}
