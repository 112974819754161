import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { DelayUnit } from '@shared/types/sdk/steps';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const connectConfig: ActionConfig = {
  actionType: Action.GOOGLE_CALENDAR,
  name: 'Google Calendar',
  description: 'Trigger when events are created or updated in Google Calendar',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_EVENT_CREATED,
              label: 'New Event',
            },
            {
              value: Intent.TRIGGER_EVENT_UPDATED,
              label: 'Event Updated',
            },
            {
              value: Intent.TRIGGER_EVENT_CANCELLED,
              label: 'Event Cancelled',
            },
            {
              value: Intent.TRIGGER_EVENT_STARTED,
              label: 'Event Started',
            },
            {
              value: Intent.TRIGGER_EVENT_ENDED,
              label: 'Event Ended',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      let inputs: SidebarInput[] =
        parameters.intent == Intent.TRIGGER_EVENT_STARTED
          ? [
              {
                id: 'timeBefore',
                title: 'Time Before',
                subtitle: 'How long before an event starts this should be triggered.',
                type: SidebarInputType.TimeConstraintInput,
                units: [DelayUnit.MINUTES],
                required: false,
              },
            ]
          : [];
      switch (parameters.intent as Intent) {
        case Intent.TRIGGER_EVENT_CREATED:
        case Intent.TRIGGER_EVENT_UPDATED:
        case Intent.TRIGGER_EVENT_STARTED:
        case Intent.TRIGGER_EVENT_ENDED:
        case Intent.TRIGGER_EVENT_CANCELLED:
          inputs = [
            ...inputs,
            {
              id: 'calendarId',
              title: 'Calendar',
              type: SidebarInputType.TextArea,
              subtitle:
                'Use Connect Portal Workflow Settings to allow users to select a calendar. Defaults to the user’s primary calendar if left blank.',
              placeholder: '{{settings.calendar}}',
              required: false,
              lines: 1,
            },
          ];
          break;
        default:
          break;
      }
      return { inputs };
    },
  ],
};

export default connectConfig;
