import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.ZENDESK;
  sidebarSections: SidebarSectionFactory[];
  providerDataKeyLabelMap?: Record<keyof ProviderData, string>;
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'ZENDESK_TEST_CONNECTION',
  CREATE_TICKET = 'ZENDESK_CREATE_TICKET',
  UPDATE_TICKET = 'ZENDESK_UPDATE_TICKET',
  ADD_COMMENT_TO_TICKET = 'ZENDESK_ADD_COMMENT_TO_TICKET',
  SEARCH_TICKETS = 'ZENDESK_SEARCH_TICKETS',
  GET_TICKET_BY_ID = 'ZENDESK_GET_TICKET_BY_ID',
  GET_USERS = 'ZENDESK_GET_USERS',
  CREATE_USER = 'ZENDESK_CREATE_USER',
  UPDATE_USER = 'ZENDESK_UPDATE_USER',
  SEARCH_USERS = 'ZENDESK_SEARCH_USERS',
  GET_USER_BY_ID = 'ZENDESK_GET_USER_BY_ID',

  //trigger intents
  TRIGGER_TICKET_CREATED = 'ZENDESK_TRIGGER_TICKET_CREATED',
  TRIGGER_TICKET_UPDATED = 'ZENDESK_TRIGGER_TICKET_UPDATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_TICKET;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_TICKET;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_TICKETS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TICKET_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_USERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_COMMENT_TO_TICKET;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_USER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_USER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_USERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_USER_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_TICKET_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_TICKET_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  ZENDESK_OAUTH_ACCESS_TOKEN: string;
  ZENDESK_SUB_DOMAIN: string;
};

export type ProviderData = {
  subDomain: string;
};
