import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import { accountIdInput } from './common';
import iconSvg from './icon.svg';
('');
export type ScopeName = 'rest_webservices' | 'suite_analytics' | 'restlets';

export const scopes: Record<ScopeName, ScopeValue> = {
  rest_webservices: {
    label: 'rest_webservices',
    name: 'rest_webservices',
    required: true,
    description: 'For Rest WebServices',
  },
  suite_analytics: {
    label: 'suite_analytics',
    name: 'suite_analytics',
    required: true,
    description: 'For Suite Analytics',
  },
  restlets: {
    label: 'restlets',
    name: 'restlets',
    required: true,
    description: 'For Restlets',
  },
};

export const requiredScopes: ScopeName[] = ['rest_webservices', 'suite_analytics', 'restlets'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'NetSuite',
  description: 'Sync purchase orders with NetSuite',
  authUrl: 'https://<accountID>.app.netsuite.com/app/login/oauth2/authorize.nl',
  accessTokenUrl:
    'https://<accountID>.suitetalk.api.netsuite.com/services/rest/auth/oauth2/v1/token',
  iconSvg,
  clientIdLabel: 'Consumer Key',
  clientSecretLabel: 'Consumer Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Consumer Key',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/netsuite`,
      documentationLinkLabel: 'How to obtain a Consumer Key?',
    },
    {
      type: SidebarInputType.ValueText,
      title: 'Consumer Secret',
      id: 'clientSecret',
    },
  ],
  documentationLink:
    'https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/bridgehead_4249032125.html',
  scopes: {
    values: Object.values(scopes),
  },
  endUserSuppliedValues: [accountIdInput],
};
