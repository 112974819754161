import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'Mail.ReadBasic'
  | 'Mail.Send'
  | 'Mail.Read'
  | 'Mail.ReadWrite'
  | 'Calendars.Read'
  | 'offline_access'
  | 'User.Read'
  | 'Calendars.ReadWrite';

export const scopes: Record<ScopeName, ScopeValue> = {
  'Mail.ReadBasic': {
    label: 'Mail.ReadBasic',
    name: 'Mail.ReadBasic',
    required: true,
    description: 'Read user basic mail',
  },
  'Mail.Send': {
    label: 'Mail.Send',
    name: 'Mail.Send',
    description: 'Send mail as a use',
    required: true,
  },
  'Mail.Read': {
    label: 'Mail.Read',
    name: 'Mail.Read',
    description: 'Read user mail',
    required: true,
  },
  'Mail.ReadWrite': {
    label: 'Mail.ReadWrite',
    name: 'Mail.ReadWrite',
    description: 'Read and write access to user mail',
    required: true,
  },
  'Calendars.Read': {
    label: 'Calendars.Read',
    name: 'Calendars.Read',
    required: true,
    description: 'Read user calendars',
  },
  'User.Read': {
    label: 'User.Read',
    name: 'User.Read',
    required: true,
    description: 'Sign in and read user profile',
  },
  'Calendars.ReadWrite': {
    label: 'Calendars.ReadWrite',
    name: 'Calendars.ReadWrite',
    required: true,
    description: 'Have full access to user calendars',
  },
  offline_access: {
    label: 'offline_access',
    name: 'offline_access',
    required: true,
    description: 'Maintain access to data you have given it access to',
  },
};

export const requiredScopes: ScopeName[] = [
  'Mail.ReadBasic',
  'Mail.Send',
  'Mail.Read',
  'Mail.ReadWrite',
  'Calendars.Read',
  'Calendars.ReadWrite',
  'offline_access',
  'User.Read',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Microsoft Outlook',
  description: 'Manage mail and calendar in Outlook',
  authUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0',
  accessTokenUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/outlook`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.microsoft.com/en-us/graph/outlook-calendar-concept-overview',
};
