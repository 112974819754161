import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import * as AirtableConfig from '@shared/integrations/airtable/frontend/config';
import * as AmazonS3ConnectConfig from '@shared/integrations/amazons3/frontend/connect';
import * as AsanaConfig from '@shared/integrations/asana/frontend/config';
import { default as AsanaEventConfig } from '@shared/integrations/asana/frontend/trigger';
import * as AzureDevOpsConfig from '@shared/integrations/azuredevops/frontend/config';
import * as BambooHrConfig from '@shared/integrations/bamboohr/frontend/connect';
import * as BigQueryConfig from '@shared/integrations/bigquery/frontend/connect';
import * as CalendlyConfig from '@shared/integrations/calendly/frontend/connect';
import { default as CalendlyEventConfig } from '@shared/integrations/calendly/frontend/trigger';
import * as ClickUpConfig from '@shared/integrations/clickup/frontend/config';
import { default as ClickUpEventConfig } from '@shared/integrations/clickup/frontend/trigger';
import * as CloseConnectConfig from '@shared/integrations/close/frontend/connect';
import * as DocuSignConnectConfig from '@shared/integrations/docusign/frontend/connect';
import * as DropboxConnectConfig from '@shared/integrations/dropbox/frontend/connect';
import * as DynamicsBusinessCentralConnectConfig from '@shared/integrations/dynamicsbusinesscentral/frontend/connect';
import * as DynamicsFinanceConnectConfig from '@shared/integrations/dynamicsfinance/frontend/connect';
import * as DynamoDBConfig from '@shared/integrations/dynamodb/frontend/config';
import * as EloquaConfig from '@shared/integrations/eloqua/frontend/config';
import * as FacebookAdsConfig from '@shared/integrations/facebookAds/frontend/config';
import * as FirebaseConfig from '@shared/integrations/firebase/frontend/config';
import * as FreshdeskConfig from '@shared/integrations/freshdesk/frontend/connect';
import * as FTPConfig from '@shared/integrations/ftp/frontend/config';
import * as GithubConfig from '@shared/integrations/github/frontend/connect';
import * as GmailConnectConfig from '@shared/integrations/gmail/frontend/connect';
import * as GongConfig from '@shared/integrations/gong/frontend/connect';
import * as GoogleAdManagerConnectConfig from '@shared/integrations/googleadmanager/frontend/connect';
import * as GoogleAdsConnectConfig from '@shared/integrations/googleads/frontend/connect';
import * as GoogleAnalyticsConfig from '@shared/integrations/googleanalytics/frontend/config';
import * as GoogleCalendarConfig from '@shared/integrations/googleCalendar/frontend/config';
import { default as GoogleCalendarEventConfig } from '@shared/integrations/googleCalendar/frontend/trigger';
import * as GoogleCampaignManagerConfig from '@shared/integrations/googlecampaignmanager/frontend/config';
import googleIconSvg from '@shared/integrations/googledrive/configs/googleIcon.svg';
import * as GoogledriveConfig from '@shared/integrations/googledrive/frontend/config';
import * as GoogleSearchConsoleConfig from '@shared/integrations/googlesearchconsole/frontend/config';
import * as GooglesheetsConfig from '@shared/integrations/googlesheets/frontend/config';
import * as GreenhouseConfig from '@shared/integrations/greenhouse/frontend/connect';
import * as GustoConnectConfig from '@shared/integrations/gusto/frontend/connect';
import * as HubspotConfig from '@shared/integrations/hubspot/frontend/config';
import { default as HubspotEventConfig } from '@shared/integrations/hubspot/frontend/trigger';
import * as ImanageConnectConfig from '@shared/integrations/imanage/frontend/connect';
import * as IntercomConfig from '@shared/integrations/intercom/frontend/config';
import * as JiraConfig from '@shared/integrations/jira/frontend/config';
import { default as JiraEventConfig } from '@shared/integrations/jira/frontend/trigger';
import * as KlaviyoConfig from '@shared/integrations/klaviyo/frontend/config';
import * as LeverConnectConfig from '@shared/integrations/lever/frontend/connect';
import * as LinearConnectConfig from '@shared/integrations/linear/frontend/connect';
import { default as LinearEventConfig } from '@shared/integrations/linear/frontend/trigger';
import * as MagentoConnectConfig from '@shared/integrations/magento/frontend/connect';
import * as MailChimpConfig from '@shared/integrations/mailchimp/frontend/config';
import * as MailgunConfig from '@shared/integrations/mailgun/frontend/config';
import * as MarketoConfig from '@shared/integrations/marketo/frontend/config';
import * as MicrosoftDynamicsConfig from '@shared/integrations/microsoftDynamics/frontend/config';
import { default as MicrosoftDynamicsEventConfig } from '@shared/integrations/microsoftDynamics/frontend/trigger';
import * as MicrosoftTeamsConfig from '@shared/integrations/microsoftTeams/frontend/config';
import * as MixpanelConnectConfig from '@shared/integrations/mixpanel/frontend/connect';
import * as MondayConfig from '@shared/integrations/monday/frontend/config';
import { default as MondayEventConfig } from '@shared/integrations/monday/frontend/trigger';
import * as MongoDBConfig from '@shared/integrations/mongodb/frontend/config';
import { default as MongoDBEventConfig } from '@shared/integrations/mongodb/frontend/trigger';
import * as MySQLConfig from '@shared/integrations/mysql/frontend/config';
import { default as MySQLEventConfig } from '@shared/integrations/mysql/frontend/trigger';
import * as NetSuiteConfig from '@shared/integrations/netsuite/frontend/connect';
import * as OneSignalConfig from '@shared/integrations/onesignal/frontend/config';
import * as OracleFinancialsCloudConfig from '@shared/integrations/oraclefinancialscloud/frontend/config';
import * as OutlookConnectConfig from '@shared/integrations/outlook/frontend/connect';
import { default as OutlookEventConfig } from '@shared/integrations/outlook/frontend/trigger';
import * as OutreachConfig from '@shared/integrations/outreach/frontend/config';
import { default as OutreachEventConfig } from '@shared/integrations/outreach/frontend/trigger';
import * as PandaDocConnectConfig from '@shared/integrations/pandadoc/frontend/connect';
import * as PardotConfig from '@shared/integrations/pardot/frontend/config';
import { default as PardotEventConfig } from '@shared/integrations/pardot/frontend/trigger';
import * as PipedriveConnectConfig from '@shared/integrations/pipedrive/frontend/connect';
import { default as PipedriveEventConfig } from '@shared/integrations/pipedrive/frontend/trigger';
import * as PostgresConfig from '@shared/integrations/postgres/frontend/config';
import { default as PostgreSQLEventConfig } from '@shared/integrations/postgres/frontend/trigger';
import * as PowerBiConfig from '@shared/integrations/powerbi/frontend/connect';
import * as ProductboardConnectConfig from '@shared/integrations/productboard/frontend/connect';
import * as QuickbooksConfig from '@shared/integrations/quickbooks/frontend/config';
import { default as QuickbooksEventConfig } from '@shared/integrations/quickbooks/frontend/trigger';
import * as SageAccountingConnectConfig from '@shared/integrations/sageaccounting/frontend/connect';
import * as SageIntacctConnectConfig from '@shared/integrations/sageintacct/frontend/connect';
import * as SailthruConnectConfig from '@shared/integrations/sailthru/frontend/connect';
import * as SalesforceConfig from '@shared/integrations/salesforce/frontend/config';
import { default as SalesforceEventConfig } from '@shared/integrations/salesforce/frontend/trigger';
import * as SalesloftConnectConfig from '@shared/integrations/salesloft/frontend/connect';
import * as SapConnectConfig from '@shared/integrations/saps4hana/frontend/connect';
import * as SapSuccessFactorsConnectConfig from '@shared/integrations/sapsuccessfactors/frontend/connect';
import * as SegmentConnectConfig from '@shared/integrations/segment/frontend/connect';
import * as SendgridConfig from '@shared/integrations/sendgrid/frontend/config';
import * as ServiceNowConnectConfig from '@shared/integrations/servicenow/frontend/connect';
import * as SharePointConfig from '@shared/integrations/sharepoint/frontend/config';
import * as ShopifyConfig from '@shared/integrations/shopify/frontend/config';
import { default as ShopifyEventConfig } from '@shared/integrations/shopify/frontend/trigger';
import * as SlackConfig from '@shared/integrations/slack/frontend/config';
import * as StripeConfig from '@shared/integrations/stripe/frontend/config';
import * as TableauConfig from '@shared/integrations/tableau/frontend/config';
import * as TrelloConfig from '@shared/integrations/trello/frontend/config';
import { default as TrelloEventConfig } from '@shared/integrations/trello/frontend/trigger';
import * as TwilioConfig from '@shared/integrations/twilio/frontend/config';
import * as TwitterConnectConfig from '@shared/integrations/twitter/frontend/connect';
import * as WooCommerceConnectConfig from '@shared/integrations/woocommerce/frontend/connect';
import * as XeroConfig from '@shared/integrations/xero/frontend/config';
import { default as XeroEventConfig } from '@shared/integrations/xero/frontend/trigger';
import * as ZendeskConfig from '@shared/integrations/zendesk/frontend/config';
import { default as ZendeskEventConfig } from '@shared/integrations/zendesk/frontend/trigger';
import * as ZohoCrmConnectConfig from '@shared/integrations/zohocrm/frontend/connect';
import { default as ZohoCrmEventConfig } from '@shared/integrations/zohocrm/frontend/trigger';
import {
  Event as ZoomWebhookEvent,
  default as parseZoomWebhookEvent,
} from '@shared/integrations/zoom/backend/parseWebhookEvent';
import * as ZoomConfig from '@shared/integrations/zoom/frontend/config';
import { default as ZoomEventConfig } from '@shared/integrations/zoom/frontend/trigger';
import {
  Action,
  ActionCategory,
  ActionCategoryDetails,
  ActionConfig,
  ActionStepView,
  BaseActionConfig,
  ConnectActionCategory,
} from '@shared/types/sdk/actions';
import { ParsedWebhookEvent } from '@shared/types/sdk/actions/actionTrigger';
import { StepType } from '@shared/types/sdk/steps';

import { confirmEnding } from './utils';

// Action triggers
// import { default as GooglesheetsEventConfig } from '@shared/integrations/googlesheets/frontend/trigger';

export type ActionConfigModule = {
  default: ActionConfig;
  authConfig: ActionConfig;
};

const configModules: ActionConfigModule[] = [
  SalesforceConfig,
  GithubConfig,
  GongConfig,
  GoogleAnalyticsConfig,
  CalendlyConfig,
  GoogleCalendarConfig,
  FTPConfig,
  GoogledriveConfig,
  TwilioConfig,
  SendgridConfig,
  PostgresConfig,
  MySQLConfig,
  GooglesheetsConfig,
  GreenhouseConfig,
  FirebaseConfig,
  AirtableConfig,
  SlackConfig,
  MongoDBConfig,
  MailChimpConfig,
  MailgunConfig,
  StripeConfig,
  OneSignalConfig,
  DynamoDBConfig,
  QuickbooksConfig,
  ShopifyConfig,
  JiraConfig,
  HubspotConfig,
  KlaviyoConfig,
  IntercomConfig,
  TableauConfig,
  AsanaConfig,
  ZendeskConfig,
  MicrosoftTeamsConfig,
  MarketoConfig,
  ZoomConfig,
  MicrosoftDynamicsConfig,
  PardotConfig,
  XeroConfig,
  OutreachConfig,
  GoogleCampaignManagerConfig,
  TrelloConfig,
  AzureDevOpsConfig,
  OracleFinancialsCloudConfig,
  FacebookAdsConfig,
  MondayConfig,
  GoogleSearchConsoleConfig,
  ClickUpConfig,
  SharePointConfig,
  OutlookConnectConfig,
  ServiceNowConnectConfig,
  EloquaConfig,
  NetSuiteConfig,
  DynamicsBusinessCentralConnectConfig,
  PipedriveConnectConfig,
  SageIntacctConnectConfig,
  LinearConnectConfig,
  DynamicsFinanceConnectConfig,
  SageAccountingConnectConfig,
  WooCommerceConnectConfig,
  ImanageConnectConfig,
  BambooHrConfig,
  GmailConnectConfig,
  GoogleAdManagerConnectConfig,
  DropboxConnectConfig,
  MagentoConnectConfig,
  ProductboardConnectConfig,
  DocuSignConnectConfig,
  SapConnectConfig,
  GoogleAdsConnectConfig,
  ZohoCrmConnectConfig,
  SalesloftConnectConfig,
  LeverConnectConfig,
  FreshdeskConfig,
  TwitterConnectConfig,
  BigQueryConfig,
  PowerBiConfig,
  MixpanelConnectConfig,
  SapSuccessFactorsConnectConfig,
  AmazonS3ConnectConfig,
  SailthruConnectConfig,
  PandaDocConnectConfig,
  SegmentConnectConfig,
  GustoConnectConfig,
  CloseConnectConfig,
];

const configs: ActionConfig[] = configModules.map((config: ActionConfigModule) => ({
  ...config.default,
  provider: config.authConfig.provider,
}));

export const ActionSteps: Record<Action, ActionStepView> = configs.reduce(
  (accumulator: Partial<{ [type in Action]: ActionStepView }>, config: ActionConfig) => {
    const {
      actionType,
      icon,
      name: title,
      description,
      provider,
      providerDataKeyLabelMap,
    } = config;
    return {
      ...accumulator,
      [actionType]: {
        id: actionType as string,
        stepType: StepType.ACTION,
        icon,
        title,
        description,
        config,
        provider,
        providerDataKeyLabelMap,
        initialParameters: {
          actionType,
          credentials: [],
          actionParameters: [],
        },
      },
    };
  },
  {},
) as Record<string, ActionStepView>;

export const AuthConfigs = Object.fromEntries(
  configModules.map((configModule: ActionConfigModule) => [
    configModule.default.actionType,
    normalizeAuthConfig(configModule.authConfig, configModule.default),
  ]),
) as { [actionType: string]: ActionConfig };

export const isBasicSchemeAuth = (action: Action): boolean => {
  return AuthConfigs[action].scheme === AuthenticationScheme.BASIC;
};

export const TriggerSteps: ActionConfig[] = [
  PostgreSQLEventConfig,
  MySQLEventConfig,
  MongoDBEventConfig,
  ZendeskEventConfig,
  XeroEventConfig,
];

export const ClassicTriggerSteps: Partial<Record<Action, ActionConfig>> = {
  [Action.POSTGRES]: PostgreSQLEventConfig,
  [Action.MYSQL]: MySQLEventConfig,
  [Action.MONGODB]: MongoDBEventConfig,
  [Action.ZENDESK]: ZendeskEventConfig,
  [Action.XERO]: XeroEventConfig,
};

export const ConnectTriggerSteps: Partial<Record<Action, ActionConfig>> = {
  // [Action.GOOGLE_SHEETS]: GooglesheetsEventConfig,
  [Action.QUICKBOOKS]: QuickbooksEventConfig,
  [Action.GOOGLE_CALENDAR]: GoogleCalendarEventConfig,
  [Action.HUBSPOT]: HubspotEventConfig,
  [Action.JIRA]: JiraEventConfig,
  [Action.SALESFORCE]: SalesforceEventConfig,
  [Action.MICROSOFT_DYNAMICS]: MicrosoftDynamicsEventConfig,
  [Action.ZENDESK]: ZendeskEventConfig,
  [Action.ZOOM]: ZoomEventConfig,
  [Action.NONE]: undefined,
  [Action.PARDOT]: PardotEventConfig,
  [Action.XERO]: XeroEventConfig,
  [Action.OUTREACH]: OutreachEventConfig,
  [Action.ASANA]: AsanaEventConfig,
  [Action.TRELLO]: TrelloEventConfig,
  [Action.CLICKUP]: ClickUpEventConfig,
  [Action.MONDAY]: MondayEventConfig,
  [Action.SHOPIFY]: ShopifyEventConfig,
  [Action.OUTLOOK]: OutlookEventConfig,
  [Action.PIPEDRIVE]: PipedriveEventConfig,
  [Action.LINEAR]: LinearEventConfig,
  [Action.CALENDLY]: CalendlyEventConfig,
  [Action.ZOHO_CRM]: ZohoCrmEventConfig,
};

const actionSet = new Set(Object.values(Action));
actionSet.delete(Action.NONE);
actionSet.delete(Action.AZURE_DEVOPS);
const pickAndMarkCategorized = (actions: Action[]): Action[] => {
  actions.forEach((action: Action) => actionSet.delete(action));
  return actions;
};
export const ActionStepsByCategory: Record<
  ActionCategory.DATABASES | ActionCategory.MESSAGING | ActionCategory.UNCATEGORIZED,
  { actions: Action[]; details: ActionCategoryDetails }
> = {
  [ActionCategory.DATABASES]: {
    actions: pickAndMarkCategorized([
      Action.POSTGRES,
      Action.MYSQL,
      Action.MONGODB,
      Action.DYNAMODB,
    ]),
    details: {
      label: 'Databases',
    },
  },
  [ActionCategory.MESSAGING]: {
    actions: pickAndMarkCategorized([
      Action.SENDGRID,
      Action.MAILGUN,
      Action.SLACK,
      Action.TWILIO,
      Action.ONESIGNAL,
      Action.KLAVIYO,
      Action.INTERCOM,
    ]),
    details: {
      label: 'Messaging',
    },
  },
  [ActionCategory.UNCATEGORIZED]: {
    actions: [...actionSet.values()],
    details: {
      label: 'Other Integrations',
    },
  },
};

/**
 * Specify overrides for the auth configs for certain ProviderTypes, if the name/icon should
 * be shared for multiple actions that may use the same ProviderType.
 *
 * e.g., Google has one ProviderType but is used across Sheets, Drive, and Calendar which
 * have different specific names/icons associated with their AuthConfigs. We override that here
 * to use a generic Google name and icon.
 */
export const ProviderTypeInfoOverrides: Partial<Record<ProviderType, BaseActionConfig>> = {
  [ProviderType.GOOGLE]: {
    actionType: Action.NONE,
    name: 'Google',
    icon: googleIconSvg,
  },
  [ProviderType.CUSTOM]: {
    actionType: Action.NONE,
    name: 'Custom App',
    // From @microservices/dashboard/public/images/icons/oauth.svg
    icon: '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15" fill="#E2EBF8"/><path d="M15 0C23.2979 0 30 6.70213 30 15C30 23.2979 23.2979 30 15 30C6.70213 30 0 23.2979 0 15C0 6.70213 6.70213 0 15 0ZM15 2.12766C7.87234 2.12766 2.12766 7.87234 2.12766 15C2.12766 22.1277 7.87234 27.8723 15 27.8723C22.1277 27.8723 27.8723 22.1277 27.8723 15C27.8723 7.87234 22.1277 2.12766 15 2.12766Z" fill="#30409A"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.66016C17.4468 7.66016 19.3617 9.68143 19.3617 12.0219C19.3617 14.4687 17.4468 16.4899 15 16.4899C12.5532 16.4899 10.6383 14.4687 10.6383 12.0219C10.6383 9.68143 12.5532 7.66016 15 7.66016Z" fill="#30409A"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15 18.0859C18.4043 18.0859 21.383 20.0008 22.8724 22.8732C20.8511 24.8944 18.0851 26.0647 15 26.0647C11.9149 26.0647 9.14896 24.8944 7.12769 22.8732C8.61705 20.0008 11.5958 18.0859 15 18.0859Z" fill="#30409A"/></svg>',
  },
};

/**
 * Normalize AuthConfig properties to use its top-level config's actionType (and optionally, its
 * name/title).
 *
 * There are other instances where it may be helpful to know the top-level config name given an
 * auth config, i.e., in the Connected Integrations table.
 *
 * @param authConfig The AuthConfig to normalize, with Action.NONE
 * @param rootConfig The top-level config that includes this AuthConfig
 * @param useRootConfigName Replace the authConfig's name with the root level config, since the
 * auth Config is typically worded as "Connect your X provider" rather than the base action name
 * (for use in the IntegrationSetupModal).
 */
export function normalizeAuthConfig(
  authConfig: ActionConfig,
  rootConfig: ActionConfig,
  useRootConfigName: boolean = false,
): ActionConfig {
  return {
    ...authConfig,
    ...(useRootConfigName ? { name: rootConfig.name } : undefined),
    actionType: rootConfig.actionType,
  } as ActionConfig;
}

const authConfigMap = Object.fromEntries(
  configModules.map((config: ActionConfigModule) => [
    config.authConfig.provider,
    // Replace auth config name with the root level config name
    normalizeAuthConfig(config.authConfig, config.default, true),
  ]),
) as { [provider in ProviderType]: ActionConfig };

export const ProviderTypeInfo: {
  [providerTypeKey: string]: BaseActionConfig;
} = Object.fromEntries(
  Object.values(ProviderType).map((provider: ProviderType) => [
    provider,
    ProviderTypeInfoOverrides[provider] || authConfigMap[provider],
  ]),
) as {
  [providerTypeKey: string]: BaseActionConfig;
};

/**
 * ActionsByCategory used in Connect's Integration Catalog
 */
export const ConnectActionsByCategory = <const>{
  [ActionCategory.POPULAR]: {
    actions: [Action.SALESFORCE, Action.HUBSPOT, Action.SLACK, Action.SHOPIFY, Action.TWITTER],
    details: { label: 'Popular' },
  },
  [ActionCategory.NEW_AND_NOTEWORTHY]: {
    actions: [Action.SAP_S4HANA, Action.LEVER, Action.DOCUSIGN, Action.GONG],
    details: { label: 'New and Noteworthy' },
  },
  [ActionCategory.CRM]: {
    actions: [
      Action.SALESFORCE,
      Action.HUBSPOT,
      Action.MICROSOFT_DYNAMICS,
      Action.PIPEDRIVE,
      Action.ZOHO_CRM,
      Action.CLOSE,
    ],
    details: { label: 'CRM' },
  },
  [ActionCategory.SALES]: {
    actions: [Action.OUTREACH, Action.GONG, Action.SALESLOFT],
    details: { label: 'Sales' },
  },
  [ActionCategory.MARKETING_AUTOMATION]: {
    actions: [
      Action.KLAVIYO,
      Action.MARKETO,
      Action.PARDOT,
      Action.MAILCHIMP,
      Action.ELOQUA,
      Action.SAILTHRU,
    ],
    details: { label: 'Marketing Automation' },
  },
  [ActionCategory.ANALYTICS]: {
    actions: [Action.GOOGLE_SEARCH_CONSOLE, Action.GOOGLE_ANALYTICS, Action.MIXPANEL],
    details: { label: 'Analytics' },
  },
  [ActionCategory.ADVERTISING]: {
    actions: [
      Action.GOOGLE_CAMPAIGN_MANAGER,
      Action.FACEBOOK_ADS,
      Action.GOOGLE_ADS,
      Action.GOOGLE_AD_MANAGER,
    ],
    details: { label: 'Advertising' },
  },
  [ActionCategory.E_COMMERCE]: {
    actions: [Action.SHOPIFY, Action.WOOCOMMERCE, Action.MAGENTO],
    details: { label: 'E-commerce' },
  },
  [ActionCategory.TEAM_COMMUNICATION]: {
    actions: [Action.SLACK, Action.MICROSOFT_TEAMS, Action.ZOOM, Action.GMAIL],
    details: { label: 'Team Communication' },
  },
  [ActionCategory.OFFICE_SUITE]: {
    actions: [
      Action.GOOGLE_CALENDAR,
      Action.GOOGLE_DRIVE,
      Action.GOOGLE_SHEETS,
      Action.SHAREPOINT,
      Action.OUTLOOK,
      Action.IMANAGE,
      Action.DROPBOX,
      Action.CALENDLY,
      Action.DOCUSIGN,
      Action.PANDA_DOC,
    ],
    details: { label: 'Office Suite' },
  },
  [ActionCategory.PROJECT_MANAGEMENT]: {
    actions: [
      Action.GITHUB,
      Action.JIRA,
      Action.ASANA,
      Action.TRELLO,
      Action.CLICKUP,
      Action.MONDAY,
      Action.LINEAR,
      Action.AZURE_DEVOPS,
      Action.PRODUCTBOARD,
      Action.AIRTABLE,
    ],
    details: { label: 'Project Management' },
  },
  [ActionCategory.SUPPORT_TICKETING]: {
    actions: [Action.INTERCOM, Action.ZENDESK, Action.SERVICENOW, Action.FRESHDESK],
    details: { label: 'Support Ticketing' },
  },
  [ActionCategory.ACCOUNTING]: {
    actions: [
      Action.NETSUITE,
      Action.SAGE_INTACCT,
      Action.DYNAMICS_BUSINESS_CENTRAL,
      Action.QUICKBOOKS,
      Action.XERO,
      Action.ORACLE_FINANCIALS_CLOUD,
      Action.DYNAMICS_365_FINANCE,
      Action.SAGE_ACCOUNTING,
      Action.SAP_S4HANA,
    ],
    details: { label: 'Accounting' },
  },
  [ActionCategory.PAYMENTS]: {
    actions: [Action.STRIPE],
    details: { label: 'Payments' },
  },
  [ActionCategory.HR]: {
    actions: [Action.BAMBOO_HR, Action.SAP_SUCCESS_FACTORS, Action.GUSTO],
    details: { label: 'HR' },
  },
  [ActionCategory.APPLICANT_TRACKING_SYSTEM]: {
    actions: [Action.LEVER, Action.GREENHOUSE],
    details: { label: 'Applicant Tracking System' },
  },
  [ActionCategory.BUSINESS_INTELLIGENCE]: {
    actions: [Action.TABLEAU, Action.BIGQUERY, Action.AMAZON_S3, Action.SEGMENT, Action.POWER_BI],
    details: { label: 'Business Intelligence' },
  },
  [ActionCategory.SOCIAL_MEDIA]: {
    actions: [Action.TWITTER],
    details: { label: 'Social Media' },
  },
};

export type VisibleConnectAction =
  typeof ConnectActionsByCategory[ConnectActionCategory]['actions'][number];

/**
 * If this variable is throwing a type error, this is a problem with the above constant.
 * Check above to resolve errors.
 */
const _ConnectActionsByCategoryTypeCheck: {
  [category in ConnectActionCategory]: { actions: readonly Action[]; details: { label: string } };
} = ConnectActionsByCategory;
_ConnectActionsByCategoryTypeCheck;

const IntegrationToParseWebhookEventMap: Partial<
  Record<Action, (event: object) => ParsedWebhookEvent>
> = {
  [Action.ZOOM]: (event: object): ParsedWebhookEvent =>
    parseZoomWebhookEvent(event as ZoomWebhookEvent),
};

export const parseWebhookEvent = (
  integration: Action,
  event: any,
): ParsedWebhookEvent | undefined => {
  return IntegrationToParseWebhookEventMap[integration]?.(event);
};

export { default as oauth } from './oauth';

/**
 * if one providerType has many actions then for classic credential
 * we can not know which credential is for which action
 * so pick action by providerType and name stored in credential
 * @param provider
 * @param name
 * @returns
 */
export const getActionFromProvider = (provider: ProviderType, name: string): Action | undefined => {
  const ProviderToActionMap: Record<ProviderType, Action> = Object.fromEntries(
    Object.entries(AuthConfigs).map(([key, value]: [string, ActionConfig]) => [
      [value.provider],
      key,
    ]),
  );

  let action: Action | undefined;

  switch (provider) {
    case ProviderType.GOOGLE:
      if (confirmEnding(name, '(Google Analytics)')) {
        action = Action.GOOGLE_ANALYTICS;
      } else if (confirmEnding(name, '(Calendar)')) {
        action = Action.GOOGLE_CALENDAR;
      } else if (confirmEnding(name, '(Campaign Manager 360)')) {
        action = Action.GOOGLE_CAMPAIGN_MANAGER;
      } else if (confirmEnding(name, '(Drive)')) {
        action = Action.GOOGLE_DRIVE;
      } else if (confirmEnding(name, '(Google Search Console)')) {
        action = Action.GOOGLE_SEARCH_CONSOLE;
      } else if (confirmEnding(name, '(Sheets)')) {
        action = Action.GOOGLE_SHEETS;
      }
      break;
    default:
      action = ProviderToActionMap[provider];
      break;
  }
  return action;
};
