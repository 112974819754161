import { IncomingMessage } from 'http';

import { Dispatch } from 'redux';

import { AccountUsageData } from '@shared/entities/sdk/project/project.interface';
import { TeamMemberRole } from '@shared/entities/sdk/team/team.interface';
import { ILoggedInUser, IUserWithOrganization } from '@shared/entities/sdk/user/user.interface';
import { debug } from '@shared/logger/sdk/legacy';
import { SignupGoogleAnalyticsEvent } from '@shared/types/sdk/analytics';

import * as api from '../../services/api';
import { getErrorMessage, mapTeamMembersToTeam, pushGtmEvent, throttleAction } from '../../utils';
import { setTokenCookie } from '../../utils/auth';
import { Action } from '../types';

import { createProjects } from './entities/project';

export const login =
  (username: string, password: string, impersonate?: string) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'LOGIN_START' });
    try {
      const response = await api.post('/auth/login/email', {
        username: (impersonate ? `${username}:${impersonate}` : username).toLowerCase(),
        password,
      });

      if (response.ok) {
        const { user, accessToken } = await response.json();
        const { teamMembers = [], ...rest } = user;
        const teams: Record<string, TeamMemberRole> = mapTeamMembersToTeam(teamMembers);
        dispatch({
          type: 'LOGIN_COMPLETE',
          user: { ...rest, teams },
          accessToken,
        });
      } else {
        dispatch({ type: 'LOGIN_ERROR', message: await getErrorMessage(response) });
      }
    } catch (error) {
      dispatch({ type: 'LOGIN_ERROR', message: await getErrorMessage(error) });
    }
  };

export const signup =
  (email: string, password: string, firstName: string, lastName: string) =>
  async (dispatch: Dispatch<Action>): Promise<ILoggedInUser> => {
    dispatch({ type: 'SIGNUP_START' });

    try {
      const response = await api.post('/auth/signup/email', {
        email,
        password,
        firstName,
        lastName,
      });

      if (response.ok) {
        const data: IUserWithOrganization = await response.json();
        const { user, accessToken, team, organization } = data;
        const { teamMembers = [], ...rest } = user;
        const teams: Record<string, TeamMemberRole> = mapTeamMembersToTeam(teamMembers);
        pushGtmEvent('User Signup', SignupGoogleAnalyticsEvent);
        const userEnt: ILoggedInUser = {
          ...rest,
          teams,
        };
        /**
         * create project also at signup time
         * due to bug PARA-2917
         */
        await setTokenCookie(accessToken, false);
        void createProjects(organization.name, organization.id, team.id)(dispatch);

        dispatch({ type: 'SIGNUP_COMPLETE', user: userEnt, accessToken });
        return userEnt;
      } else {
        const error = await getErrorMessage(response);

        throw new Error(error);
      }
    } catch (error) {
      dispatch({
        type: 'SIGNUP_ERROR',
        message: error.message,
      });
      throw error;
    }
  };

export const getMe = (req: IncomingMessage | undefined) => async (dispatch: Dispatch<Action>) => {
  req && req;
  dispatch({ type: 'GET_ME_START' });
  try {
    const response = await api.get('/auth/me');
    if (response.ok) {
      const { teamMembers = [], ...user } = await response.json();
      const teams: Record<string, TeamMemberRole> = mapTeamMembersToTeam(teamMembers);
      debug('getMe > response', { ...user, teams });
      dispatch({
        type: 'GET_ME_COMPLETE',
        user: { ...user, teams },
      });
    } else {
      dispatch({ type: 'GET_ME_ERROR' });
    }
  } catch (error) {
    dispatch({ type: 'GET_ME_ERROR' });
  }
};

export const updateMe =
  (user: Partial<ILoggedInUser> & { password?: string }) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'GET_ME_UPDATE_START' });
    try {
      const response = await api.patch('/auth/me', { ...user });
      if (response.ok) {
        const { teamMembers = [], ...user } = await response.json();
        const teams: Record<string, TeamMemberRole> = mapTeamMembersToTeam(teamMembers);

        dispatch({
          type: 'GET_ME_UPDATE_COMPLETE',
          user: { ...user, teams },
        });
      } else {
        const message: string = (await getErrorMessage(response)) || 'Failed to update email.';

        throw new Error(message);
      }
    } catch (error) {
      dispatch({
        type: 'GET_ME_UPDATE_ERROR',
        payload: { message: error.message },
      });
      throw error;
    }
  };

export const logout = () => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'LOGOUT' });
};

export const getPasswordLink = (username: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'RESET_PASSWORD_START' });
  try {
    const response = await api.get(`/auth/resetLink/${username}`);

    if (response.ok) {
      dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
    } else {
      dispatch({ type: 'RESET_PASSWORD_ERROR', message: await getErrorMessage(response) });
    }
  } catch (error) {
    dispatch({ type: 'RESET_PASSWORD_ERROR', message: await getErrorMessage(error) });
  }
};

export const verifyResetPasswordToken =
  (token: string) =>
  async (dispatch: Dispatch<Action>): Promise<{ email?: string; isTokenValid: boolean }> => {
    dispatch({ type: 'RESET_PASSWORD_START' });
    try {
      const response = await api.get(`/auth/token/${token}/verify`);

      if (response.ok) {
        const { email } = await response.json();
        dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
        return { email, isTokenValid: true };
      } else {
        dispatch({ type: 'RESET_PASSWORD_ERROR', message: await getErrorMessage(response) });
        return {
          isTokenValid: false,
        };
      }
    } catch (error) {
      dispatch({ type: 'RESET_PASSWORD_ERROR', message: await getErrorMessage(error) });
      return {
        isTokenValid: false,
      };
    }
  };

export const updateUserPassword =
  (token: string, password: string) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'RESET_PASSWORD_START' });
    try {
      const response = await api.patch(`/auth/password/${token}/update`, {
        password,
      });

      if (response.ok) {
        dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
      } else {
        dispatch({ type: 'RESET_PASSWORD_ERROR', message: await getErrorMessage(response) });
      }
    } catch (error) {
      dispatch({ type: 'RESET_PASSWORD_ERROR', message: await getErrorMessage(error) });
    }
  };

export const accountUsage = (projectId: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'GET_ACCOUNT_USAGE_START' });
  try {
    const response = await api.get(`/projects/${projectId}/account-usage`);
    if (response.ok) {
      const usage: AccountUsageData = await response.json();
      dispatch({ type: 'GET_ACCOUNT_USAGE_SUCCESS', usage });
    } else {
      dispatch({ type: 'GET_ACCOUNT_USAGE_FAILURE', message: await getErrorMessage(response) });
    }
  } catch (error) {
    dispatch({ type: 'GET_ACCOUNT_USAGE_FAILURE', message: await getErrorMessage(error) });
  }
};

// throttle api call for 15secs
export const throttledAccountUsage = throttleAction(accountUsage, 15000);
