import {
  ScopeName as SalesforceScopeName,
  scopes as salesforceScopes,
} from '@shared/integrations/salesforce/configs/oauth';
import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = SalesforceScopeName | 'pardot_api';

export const scopes: Record<ScopeName, ScopeValue> = {
  ...salesforceScopes,
  pardot_api: {
    label: 'Pardot Access',
    name: 'pardot_api',
    required: true,
    description:
      'Allows access to Pardot API services on behalf of the user. Manage the full extent of accessible services in Pardot.',
  },
};

export const requiredScopes: ScopeName[] = ['pardot_api'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Pardot',
  description: 'Sync prospects from Pardot',
  authUrl: 'https://login.salesforce.com//services/oauth2/authorize',
  accessTokenUrl: 'https://login.salesforce.com//services/oauth2/token',
  iconSvg,
  clientIdLabel: 'Consumer Key',
  clientSecretLabel: 'Consumer Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Consumer Key',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/salesforce`,
      documentationLinkLabel: 'How to obtain a Consumer Key?',
    },
    { type: SidebarInputType.ValueText, title: 'Consumer Secret', id: 'clientSecret' },
  ],
  documentationLink:
    'https://developer.salesforce.com/docs/marketing/pardot/guide/overview.html#getting-started',
  scopes: {
    values: Object.values(scopes),
  },
  endUserSuppliedValues: [
    {
      type: SidebarInputType.ValueText,
      id: 'PARDOT_BUSINESS_UNIT_ID',
      title: 'Enter your Pardot Business Unit ID',
      subtitle: `your business unit id can be found in Salesforce Pardot setup`,
      placeholder: 'Pardot Business Unit ID',
      required: true,
    },
  ],
};
