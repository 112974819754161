import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.PARDOT;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'PARDOT_TEST_CONNECTION',
  CREATE_OR_UPDATE_PROSPECT = 'PARDOT_CREATE_OR_UPDATE_PROSPECT',
  SEARCH_PROSPECTS = 'PARDOT_SEARCH_PROSPECTS',
  GET_PROSPECT_BY_ID = 'PARDOT_GET_PROSPECT_BY_ID',
  DELETE_PROSPECT_BY_ID = 'PARDOT_DELETE_PROSPECT_BY_ID',
  ADD_PROSPECT_TO_LIST = 'PARDOT_ADD_PROSPECT_TO_LIST',
  REMOVE_PROSPECT_FROM_LIST = 'PARDOT_REMOVE_PROSPECT_FROM_LIST',
  GET_ALL_LISTS = 'PARDOT_GET_ALL_LISTS',
  GET_ALL_TIMEZONES = 'PARDOT_GET_ALL_TIMEZONES',
  TRIGGER_PROSPECT_CREATED = 'PARDOT_TRIGGER_PROSPECT_CREATED',
  TRIGGER_LIST_MEMBERSHIP_CREATED = 'PARDOT_TRIGGER_LIST_MEMBERSHIP_CREATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_PROSPECT_TO_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_OR_UPDATE_PROSPECT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_PROSPECT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROSPECT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.REMOVE_PROSPECT_FROM_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_PROSPECTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_LISTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_TIMEZONES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_PROSPECT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_LIST_MEMBERSHIP_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  PARDOT_ACCESS_TOKEN: string;
  PARDOT_REFRESH_TOKEN: string;
  SCOPES?: string;
  USER_ID?: string;
  PARDOT_BUSINESS_UNIT_ID: string;
  IS_SANDBOX: boolean;
};
