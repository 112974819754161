import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

export default function conditionsToOutreachFilterFormula(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToOutreachFilterFormula(condition);
      })
      .join('&');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `filter[${innerCondition.variable}][]=${innerCondition.argument}`;
      case Operator.NumberEquals:
        return `filter[${innerCondition.variable}][]=${innerCondition.argument}`;
      case Operator.DateTimeEquals:
        return `filter[${innerCondition.variable}][]=${encodeURIComponent(
          innerCondition.argument.toISOString(),
        )}`;
      case Operator.NumberLessThan:
        return `filter[${innerCondition.variable}][lte]=${innerCondition.argument}`;
      case Operator.DateTimeBefore:
        return `filter[${innerCondition.variable}][lte]=${encodeURIComponent(
          innerCondition.argument.toISOString(),
        )}`;
      case Operator.NumberGreaterThan:
        return `filter[${innerCondition.variable}][gte]=${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `filter[${innerCondition.variable}][gte]=${encodeURIComponent(
          innerCondition.argument.toISOString(),
        )}`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `filter[${innerCondition.variable}][gte]=${innerCondition.argument}`;
      case Operator.NumberLessThanOrEqualTo:
        return `filter[${innerCondition.variable}][lte]=${innerCondition.argument}`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Outreach`);
    }
  }
  return '';
}
