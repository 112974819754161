import React from 'react';

import { IntegrationConnectInput, SidebarInputType } from '@shared/types/sdk/actions';

import { CredentialKeys } from '../configs/config';
import { LinkWrapperType } from '../shared/types';

export const LinkWrapper: React.FC<LinkWrapperType> = ({
  clickableText,
  href,
}: LinkWrapperType) => (
  <a href={href} target="_blank" rel="noreferrer">
    {clickableText}
  </a>
);

export const apiKeyInput: IntegrationConnectInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.API_KEY,
  title: 'API Key',
  required: true,
  subtitle: (
    <>
      Generate an API Key in the{' '}
      <LinkWrapper clickableText="SAP API Business Hub." href="https://api.sap.com/" />
    </>
  ),
};

export const hostUrlInput: IntegrationConnectInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.API_SERVER,
  title: 'SAP SuccessFactor API Server',
  required: true,
  subtitle: (
    <>
      You can find your company&apos;s API server in{' '}
      <LinkWrapper
        href="https://help.sap.com/docs/SAP_SUCCESSFACTORS_PLATFORM/d599f15995d348a1b45ba5603e2aba9b/af2b8d5437494b12be88fe374eba75b6.html?locale=en-US"
        clickableText="List of API Servers in SAP SuccessFactors."
      />
    </>
  ),
  placeholder: 'apisalesdemo8.successfactors.com',
};

export const usernameInput: IntegrationConnectInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.USERNAME,
  title: 'Username',
  required: true,
  subtitle: 'Your login username.',
};

export const passwordInput: IntegrationConnectInput = {
  type: SidebarInputType.Password,
  id: CredentialKeys.PASSWORD,
  title: 'Password',
  required: true,
  subtitle: 'Your login password.',
};
