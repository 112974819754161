import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.TABLEAU;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'TABLEAU_TEST_CONNECTION',
  ADD_DATA_QUALITY_WARNING = 'TABLEAU_ADD_DATA_QUALITY_WARNING',
  UPDATE_DATA_QUALITY_WARNING = 'TABLEAU_UPDATE_DATA_QUALITY_WARNING',
  GET_DATA_QUALITY_WARNING_BY_ID = 'TABLEAU_GET_DATA_QUALITY_WARNING_BY_ID',
  GET_DATA_QUALITY_WARNING_BY_CONTENT = 'TABLEAU_GET_DATA_QUALITY_WARNING_BY_CONTENT',
  LIST_ASSET_ITEMS = 'TABLEAU_LIST_ASSET_ITEMS',
  DELETE_DATA_QUALITY_WARNING_BY_ID = 'TABLEAU_DELETE_DATA_QUALITY_WARNING_BY_ID',
  DELETE_DATA_QUALITY_WARNING_BY_CONTENT = 'TABLEAU_DELETE_DATA_QUALITY_WARNING_BY_CONTENT',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_DATA_QUALITY_WARNING;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_DATA_QUALITY_WARNING;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_DATA_QUALITY_WARNING_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_DATA_QUALITY_WARNING_BY_CONTENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_ASSET_ITEMS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_DATA_QUALITY_WARNING_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_DATA_QUALITY_WARNING_BY_CONTENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  TABLEAU_SITE_ID = 'TABLEAU_SITE_ID',
  TABLEAU_USER_EMAIL = 'TABLEAU_USER_EMAIL',
  TABLEAU_SITE_ACTUAL_ID = 'TABLEAU_SITE_ACTUAL_ID',
  TABLEAU_PERSONAL_ACCESS_TOKEN = 'TABLEAU_PERSONAL_ACCESS_TOKEN',
  TABLEAU_SERVER_NAME = 'TABLEAU_SERVER_NAME',
  TABLEAU_PERSONAL_ACCESS_TOKEN_NAME = 'TABLEAU_PERSONAL_ACCESS_TOKEN_NAME',
  TABLEAU_REQUEST_TOKEN = 'TABLEAU_REQUEST_TOKEN',
}

/**
 * TABLEAU_SITE_ID is siteName.
 * TABLEAU_SITE_ACTUAL_ID is the site id returned from the authenticate request
 */
export type Credentials = {
  [CredentialKeys.TABLEAU_SITE_ID]: string;
  [CredentialKeys.TABLEAU_USER_EMAIL]: string;
  [CredentialKeys.TABLEAU_SITE_ACTUAL_ID]: string;
  [CredentialKeys.TABLEAU_PERSONAL_ACCESS_TOKEN]: string;
  [CredentialKeys.TABLEAU_SERVER_NAME]: string;
  [CredentialKeys.TABLEAU_PERSONAL_ACCESS_TOKEN_NAME]: string;
  [CredentialKeys.TABLEAU_REQUEST_TOKEN]: string;
};
