import React from 'react';

import {
  createTestConnectionButton,
  getValuesByCacheKey,
  hasImpersonatedUserCredential,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { getCampaigns, getLists, getTemplates } from './sources';

const listIdDynamicInput: SidebarInput = {
  id: 'listId',
  title: 'List',
  type: SidebarInputType.DynamicEnum,
  required: true,
  source: getLists,
  getValues: getValuesByCacheKey,
};

const listIdInput: SidebarInput = {
  id: 'listId',
  title: 'List ID',
  type: SidebarInputType.TextArea,
  lines: 1,
};

const listNameInput: SidebarInput = {
  id: 'listName',
  title: 'List name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const emailInput: SidebarInput = {
  id: 'email',
  title: 'Email address',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const phoneNumberInput: SidebarInput = {
  id: 'phoneNumber',
  title: 'Phone number',
  subtitle: 'Required if Email Address is not provided.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const customPropertiesInput: SidebarInput = {
  id: 'customProperties',
  title: 'Custom properties',
  type: SidebarInputType.Code,
  placeholder: `{
"sms_consent": true,
"example_property": "example value"
}`,
  required: false,
  language: 'json',
  useLightTheme: true,
  lines: 4,
};

const CampaignName: SidebarInput = {
  id: 'campaignName',
  title: 'Campaign name ',
  type: SidebarInputType.TextArea,
  subtitle: '',
  lines: 1,
  required: false,
};

const personInput: SidebarInput = {
  id: 'personId',
  title: 'Person ID',
  subtitle: 'The ID of the person whose profile will be retrieved.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const firstNameInput: SidebarInput = {
  id: 'firstName',
  title: 'First name',
  placeholder: 'George',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const lastNameInput: SidebarInput = {
  id: 'lastName',
  title: 'Last name',
  placeholder: 'Washington',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const titleInput: SidebarInput = {
  id: 'title',
  title: 'Title',
  placeholder: 'President',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const organizationInput: SidebarInput = {
  id: 'organization',
  title: 'Organization',
  placeholder: 'U.S. Government',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const cityInput: SidebarInput = {
  id: 'city',
  title: 'City',
  placeholder: 'Mount Vernon',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const regionInput: SidebarInput = {
  id: 'region',
  title: 'Region',
  placeholder: 'Virginia',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const zipInput: SidebarInput = {
  id: 'zip',
  title: 'Zip',
  placeholder: '22121',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const countryInput: SidebarInput = {
  id: 'country',
  title: 'Country',
  placeholder: 'United States',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

const timezoneInput: SidebarInput = {
  id: 'timezone',
  title: 'Timezone',
  placeholder: 'US/Eastern',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};
const CampaignDynamicInput: SidebarInput = {
  id: 'campaignName',
  title: 'Campaign name ',
  type: SidebarInputType.DynamicEnum,
  required: true,
  subtitle: 'Only return campaigns that match the specified name.',
  source: getCampaigns,
  getValues: getValuesByCacheKey,
};
const klaviyoApiKeyInput: SidebarInput = {
  id: 'KLAVIYO_API_KEY',
  title: 'Add your Klaviyo Private API Key',
  subtitle: (
    <>
      Found in your Klaviyo{' '}
      <a href="https://www.klaviyo.com/account#api-keys-tab" target="_blank" rel="noreferrer">
        Account Settings
      </a>
    </>
  ),
  placeholder: 'pk_d7801c10ade7e58f3d4998f911d4dc92d6',
  type: SidebarInputType.ValueText,
  required: true,
};

export const authConfig: ActionConfig = {
  actionType: Action.KLAVIYO,
  provider: ProviderType.KLAVIYO,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your Klaviyo account',
  description: 'Add Klaviyo to Paragon',
  icon: iconSvg,
  supportsImpersonatedCredentialSource: true,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this Klaviyo account',
          placeholder: 'eg. Production Testing etc.',
          type: SidebarInputType.ValueText,
        },
        klaviyoApiKeyInput,
      ],
      buttons: [createTestConnectionButton(Action.KLAVIYO, ['KLAVIYO_API_KEY'])],
    },
  ],
};

const templateIdInput: SidebarInput = {
  id: 'template',
  title: 'Template ',
  subtitle: 'The ID of the email template object that will be the content of this campaign.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

const templateDynamicInput: SidebarInput = {
  id: 'template',
  title: 'Template ',
  subtitle: 'The email template object that will be the content of this campaign.',
  type: SidebarInputType.DynamicEnum,
  required: true,
  source: getTemplates,
  getValues: getValuesByCacheKey,
};

const config: ActionConfig = {
  actionType: Action.KLAVIYO,
  name: 'Klaviyo',
  description: 'Manage subscribers in Klaviyo.',
  icon: iconSvg,
  sidebarSections: [
    (parameters: ActionStepParameters): SidebarSection => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Klaviyo account',
          placeholder: 'Add new account for Klaviyo',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasImpersonatedUserCredential(parameters)
          ? ([
              {
                ...klaviyoApiKeyInput,
                ...{
                  type: SidebarInputType.UserSuppliedCredential,
                  title: 'User’s Klaviyo API Key',
                  subtitle: '',
                  supportedTokenTypes: [TokenType.KLAVIYO_API_KEY],
                  providerType: ProviderType.KLAVIYO,
                  hideTokenDropDown: true,
                },
              },
            ] as SidebarInput[])
          : []),
      ],
    }),

    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    title: 'Campaigns',
                    items: [
                      {
                        value: Intent.CREATE_CAMPAIGN,
                        label: 'Create Campaign',
                      },
                      {
                        value: Intent.GET_CAMPAIGN,
                        label: 'Get Campaigns',
                      },
                      {
                        value: Intent.SEND_CAMPAIGN,
                        label: 'Send Campaign',
                      },
                    ],
                  },
                  {
                    title: 'Lists',
                    items: [
                      {
                        value: Intent.CREATE_LIST,
                        label: 'Create List',
                      },

                      {
                        value: Intent.ADD_SUBSCRIBER_TO_LIST,
                        label: 'Add subscriber to list',
                      },
                      {
                        value: Intent.UNSUBSCRIBE_FROM_LIST,
                        label: 'Remove Subscriber from list',
                      },
                      {
                        value: Intent.GET_LISTS,
                        label: 'Get Lists and Segments',
                      },
                      {
                        value: Intent.GET_LIST_SUBSCRIBER,
                        label: 'Get List or Segment Subscribers',
                      },
                    ],
                  },
                  {
                    title: 'Profiles',
                    items: [
                      {
                        value: Intent.GET_PROFILE,
                        label: 'Get Profile',
                      },
                      {
                        value: Intent.UPDATE_PROFILE,
                        label: 'Update Profile',
                      },
                    ],
                  },
                  {
                    title: 'Templates',
                    items: [
                      {
                        value: Intent.CREATE_TEMPLATE,
                        label: 'Create Template',
                      },
                      {
                        value: Intent.GET_TEMPLATES,
                        label: 'Get Templates',
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      if (parameters.credentials?.length) {
        switch (parameters.intent) {
          case Intent.CREATE_LIST:
            return {
              inputs: [listNameInput],
            };
          case Intent.GET_LISTS:
            return {
              inputs: [
                {
                  ...listNameInput,
                  ...{
                    title: 'List or Segment name',
                    subtitle: 'Only return lists and segments that match the specified name.',
                    required: false,
                  },
                },
              ],
            };
          case Intent.ADD_SUBSCRIBER_TO_LIST:
            return {
              inputs: [
                hasImpersonatedUserCredential(parameters) ? listIdInput : listIdDynamicInput,
                emailInput,
                { ...phoneNumberInput, required: false },
                firstNameInput,
                lastNameInput,
                titleInput,
                organizationInput,
                cityInput,
                regionInput,
                zipInput,
                countryInput,
                timezoneInput,
                customPropertiesInput,
              ],
            };
          case Intent.GET_LIST_SUBSCRIBER:
            return {
              inputs: [
                hasImpersonatedUserCredential(parameters)
                  ? { ...listIdInput, title: 'List or Segment ID' }
                  : { ...listIdDynamicInput, title: 'List or Segment' },
                {
                  ...emailInput,
                  ...{
                    id: 'emails',
                    required: false,
                    title: 'Emails',
                    subtitle:
                      'Return subscribers with the email(s) provided. Accepts an array or comma-separated list of email addresses.',
                  },
                },
                {
                  ...phoneNumberInput,
                  ...{
                    id: 'phoneNumbers',
                    title: 'Phone numbers',
                    required: false,
                    subtitle:
                      'Return subscribers with the phone number(s) provided. Accepts an array or comma-separated list of phone numbers.',
                  },
                },
                {
                  id: 'pushTokens',
                  title: 'Push tokens',
                  subtitle:
                    'Return subscribers with the push token(s) provided. Accepts an array or comma-separated list of push tokens.',
                  type: SidebarInputType.TextArea,
                  lines: 1,
                  required: false,
                },
              ],
            };
          case Intent.UNSUBSCRIBE_FROM_LIST:
            return {
              inputs: [
                hasImpersonatedUserCredential(parameters) ? listIdInput : listIdDynamicInput,
                {
                  ...emailInput,
                  subtitle: 'The email address of the profile to unsubscribe.',
                },
              ],
            };
          case Intent.CREATE_CAMPAIGN:
            return {
              inputs: [
                hasImpersonatedUserCredential(parameters)
                  ? {
                      ...listIdInput,
                      subtitle: 'The ID of the list that this campaign will be sent to.',
                    }
                  : {
                      ...listIdDynamicInput,
                      subtitle: 'The list that this campaign will be sent to.',
                    },
                hasImpersonatedUserCredential(parameters) ? templateIdInput : templateDynamicInput,
                {
                  id: 'subject',
                  title: 'Subject ',
                  type: SidebarInputType.TextArea,
                  lines: 1,
                  required: true,
                },
                {
                  id: 'email',
                  title: 'From email ',
                  type: SidebarInputType.TextArea,
                  subtitle:
                    'The email address your email will be sent from and will be used in the reply-to header.',
                  lines: 1,
                  required: true,
                },
                {
                  id: 'senderName',
                  title: 'From name ',
                  type: SidebarInputType.TextArea,
                  subtitle:
                    "The name or label associated with the email address you're sending from.",
                  lines: 1,
                  required: true,
                },
                {
                  ...CampaignName,
                  subtitle: 'If not specified, this will default to the subject of the campaign.',
                },
                {
                  id: 'smartSending',
                  title: 'Use smart sending ',
                  type: SidebarInputType.Enum,
                  defaultValue: 'True',
                  subtitle:
                    'Limits the number of emails sent to an individual within a short period.',
                  required: true,
                  getValues: () => {
                    return [
                      { label: 'True', value: 'True' },
                      { label: 'False', value: 'False' },
                    ];
                  },
                },
                {
                  id: 'addGoogleAnalytics',
                  title: 'Add Google Analytics',
                  type: SidebarInputType.Enum,
                  defaultValue: 'False',
                  subtitle: 'Adds Google Analytics tracking tags to links.',
                  required: true,
                  getValues: () => {
                    return [
                      { label: 'True', value: 'True' },
                      { label: 'False', value: 'False' },
                    ];
                  },
                },
              ],
            };

          case Intent.GET_CAMPAIGN:
            return {
              inputs: [
                {
                  ...CampaignName,
                  subtitle: 'Only return campaigns that match the specified name.',
                },
              ],
            };

          case Intent.SEND_CAMPAIGN:
            return {
              inputs: [
                hasImpersonatedUserCredential(parameters)
                  ? {
                      ...CampaignName,
                      title: 'Campaign ID',
                      subtitle: 'The ID of the campaign that will be sent.',
                      required: true,
                    }
                  : CampaignDynamicInput,
              ],
            };

          case Intent.GET_TEMPLATES:
            return {
              inputs: [
                {
                  id: 'templateName',
                  title: 'Template name ',
                  type: SidebarInputType.TextArea,
                  subtitle: 'Only return templates that match the specified name.',
                  lines: 1,
                  required: false,
                },
              ],
            };

          case Intent.CREATE_TEMPLATE:
            return {
              inputs: [
                {
                  id: 'name',
                  title: 'Template name ',
                  type: SidebarInputType.TextArea,
                  lines: 1,
                  required: true,
                },
                {
                  id: 'html',
                  title: 'Template HTML ',
                  type: SidebarInputType.TextArea,
                  lines: 4,
                  required: true,
                },
              ],
            };

          case Intent.GET_PROFILE:
            return {
              inputs: [personInput],
            };

          case Intent.UPDATE_PROFILE:
            return {
              inputs: [
                personInput,
                { ...emailInput, required: false },
                { ...phoneNumberInput, required: false },
                firstNameInput,
                lastNameInput,
                titleInput,
                organizationInput,
                cityInput,
                regionInput,
                zipInput,
                countryInput,
                timezoneInput,
                customPropertiesInput,
              ],
            };
          default:
            return { inputs: [] };
        }
      } else {
        return { inputs: [] };
      }
    },
  ],
};

export default config;
