import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  additionalFieldsInput,
  companyInput,
  customObjectNameInput,
  cutomObjectRecordFieldsInput,
  emailInput,
  firstNameInput,
  intentInput,
  lastNameInput,
  leadFilterInput,
  leadIdInput,
  leadIdsInput,
  listInput,
  phoneInput,
  titleInput,
  websiteInput,
} from './shared/inputs';

const connectConfig: ActionConfig = {
  actionType: Action.MARKETO,
  name: 'Marketo',
  description: 'Choose marketo account',
  icon: iconSvg,
  provider: ProviderType.MARKETO,
  sidebarSections: [
    (_parameters: ActionStepParameters): SidebarSection => ({ inputs: [intentInput] }),
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_OR_UPDATE_LEAD:
          return {
            inputs: [
              emailInput,
              firstNameInput,
              lastNameInput,
              companyInput,
              titleInput,
              phoneInput,
              websiteInput,
              additionalFieldsInput,
            ],
          };
        case Intent.GET_LEADS:
          return { inputs: [leadFilterInput] };
        case Intent.GET_LEAD_BY_ID:
          return { inputs: [leadIdInput] };
        case Intent.ADD_LEADS_TO_LIST:
          return {
            inputs: [
              {
                ...listInput,
                subtitle:
                  'The List that the lead(s) should be added to. Use Connect Portal Workflow Settings to allow users to select a List. ',
                placeholder: '{{settings.list}}',
              },
              leadIdsInput,
            ],
          };
        case Intent.CREATE_CUSTOM_OBJECT:
          return {
            inputs: [customObjectNameInput, cutomObjectRecordFieldsInput],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
