import { Operator } from '@shared/types/sdk/resolvers';

import { RecordType } from './types';

export const SAP_API_BASE_URL = 'https://<SAP Host URL>/sap/opu/';
export const SAP_API_SANDBOX_BASE_URL = 'https://sandbox.api.sap.com/s4hanacloud';

export const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.NumberEquals,
  Operator.NumberDoesNotEqual,
  Operator.NumberGreaterThan,
  Operator.NumberLessThan,
  Operator.DateTimeAfter,
  Operator.DateTimeBefore,
  Operator.DateTimeEquals,
  Operator.StringStartsWith,
  Operator.StringEndsWith,
];

export const filterFormulaInput: Record<RecordType, string> = {
  [RecordType.SUPPLIER_INVOICE]: 'supplierInvoiceFilterFormula',
  [RecordType.SUPPLIER]: 'supplierFilterFormula',
  [RecordType.CUSTOMER]: 'customerFilterFormula',
};
