import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@shared/ui/sdk/utils/constants';

type Props = {
  errorMessage: string | null;
  mightResolveOnRefresh?: boolean;
  className?: string;
};

const Toast = styled.div<Props>`
  position: fixed;
  z-index: 1000;
  background-color: ${COLORS.DANGER};
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  max-width: 200px;
  border-radius: 5px;
  top: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px;
  opacity: ${(props: Props) => (props.errorMessage !== null ? '1' : '0')};
  pointer-events: ${(props: Props) => (props.errorMessage !== null ? 'auto' : 'none')};
  transition: 0.2s ease opacity;
`;

export const ToastBoldMessage = styled.p`
  font-weight: bold;
  margin: 0 0 5px 0;
`;

const ErrorToast: React.FC<Props> = ({
  errorMessage,
  mightResolveOnRefresh = true,
  className = '',
}: Props) => (
  <Toast errorMessage={errorMessage} data-testid="error-toast" className={className}>
    <ToastBoldMessage>{errorMessage ? errorMessage : 'Something went wrong'}</ToastBoldMessage>
    {mightResolveOnRefresh && <>Please refresh and try again.</>}
  </Toast>
);

export default ErrorToast;
