import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const connectConfig: ActionConfig = {
  actionType: Action.GOOGLE_DRIVE,
  name: 'Google Drive',
  description:
    'Connect to your users Google Drive account to access create and update their files.',
  icon: iconSvg,
  sidebarSections: [
    (): SidebarSection => ({
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              title: 'Files',
              items: [
                {
                  value: Intent.SAVE_FILE,
                  label: 'Save File',
                },
                {
                  value: Intent.LIST_FILES,
                  label: 'Get File',
                },
              ],
            },
          ],
        },
      ],
    }),

    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SAVE_FILE:
          return {
            inputs: [
              {
                id: 'file',
                type: SidebarInputType.Text,
                title: 'File',
                required: true,
                dataType: DataType.FILE,
              },
              {
                id: 'parentId',
                required: false,
                title: 'Folder',
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which Folder to save files to. Defaults to the user’s root folder if left blank.',
                type: SidebarInputType.TextArea,
                lines: 1,
                placeholder: '{{settings.folder}}',
              },
              {
                id: 'filename',
                type: SidebarInputType.Text,
                title: 'File name',
                subtitle: 'Defaults to origin file name if left blank.',
                required: false,
              },
            ],
          };
        case Intent.LIST_FILES:
          return {
            inputs: [
              {
                id: 'parentId',
                title: 'Folder',
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which Folder to get files from.',
                type: SidebarInputType.TextArea,
                lines: 1,
                placeholder: '{{settings.folder}}',
              },
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default connectConfig;
