import { EnumInputValue, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import {
  adCreativeAdditionalFieldInput,
  adCreativeImageURLInput,
  adCreativeLeadGenFormIdInput,
  adCreativeLinkDescriptionInput,
  adCreativeLinkTitleInput,
  adCreativeLinkURLInput,
  adCreativeMessageInput,
  callToActionTypeInput,
} from '../frontend/common';

import { AdCreativeType } from './types';

export const adCreativeTypeInput: SidebarInput = {
  id: 'adCreativeType',
  type: SidebarInputType.Enum,
  title: 'Type',
  required: true,
  getValues: (): EnumInputValue[] => [
    { label: 'Image', value: AdCreativeType.IMAGE },
    { label: 'Text', value: AdCreativeType.TEXT },
    { label: 'Link', value: AdCreativeType.LINK },
    { label: 'Lead Gen Form', value: AdCreativeType.LEAD_GEN_FORM_ID },
  ],
  defaultValue: AdCreativeType.IMAGE,
  subtitle: 'Select a type of ad creative to build.',
};

export const adCreativeTypeMetadataInputs: Record<AdCreativeType, SidebarInput[]> = {
  [AdCreativeType.IMAGE]: [
    adCreativeImageURLInput,
    adCreativeLinkURLInput,
    adCreativeLinkTitleInput,
    adCreativeLinkDescriptionInput,
    { ...adCreativeMessageInput, required: false },
    adCreativeAdditionalFieldInput,
  ],
  [AdCreativeType.TEXT]: [
    adCreativeMessageInput,
    adCreativeLinkURLInput,
    adCreativeLinkTitleInput,
    adCreativeLinkDescriptionInput,
    adCreativeAdditionalFieldInput,
  ],
  [AdCreativeType.LINK]: [
    { ...adCreativeLinkURLInput, required: true },
    adCreativeLinkTitleInput,
    adCreativeLinkDescriptionInput,
    adCreativeAdditionalFieldInput,
  ],
  [AdCreativeType.LEAD_GEN_FORM_ID]: [
    adCreativeLeadGenFormIdInput,
    callToActionTypeInput,
    { ...adCreativeLinkURLInput, required: true },
    adCreativeLinkTitleInput,
    adCreativeLinkDescriptionInput,
    adCreativeAdditionalFieldInput,
  ],
};
