import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.HUBSPOT;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'HUBSPOT_TEST_CONNECTION',
  CREATE_RECORD = 'HUBSPOT_CREATE_RECORD',
  UPDATE_RECORD = 'HUBSPOT_UPDATE_RECORD',
  DELETE_RECORD = 'HUBSPOT_DELETE_RECORD',
  GET_RECORD_BY_ID = 'HUBSPOT_GET_RECORD_BY_ID',
  SEARCH_RECORDS = 'HUBSPOT_SEARCH_RECORDS',
  GET_RECORD_TYPE_FIELDS = 'HUBSPOT_GET_RECORD_TYPE_FIELDS',
  GET_PROPERTY_META_DATA = 'HUBSPOT_GET_PROPERTY_META_DATA',
  GET_OWNERS = 'HUBSPOT_GET_OWNERS',
  GET_CONTACTS_LISTS = 'HUBSPOT_GET_CONTACT_LISTS',
  GET_CONTACTS_BY_LIST_ID = 'HUBSPOT_GET_CONTACTS_BY_LIST_ID',
  GET_CUSTOM_OBJECTS = 'HUBSPOT_GET_CUSTOM_OBJECTS',
  GET_ALL_OBJECT_TYPES = 'HUBSPOT_GET_ALL_OBJECT_TYPES',

  // Trigger intents
  TRIGGER_RECORD_CREATED = 'HUBSPOT_TRIGGER_RECORD_CREATED',
  TRIGGER_RECORD_UPDATED = 'HUBSPOT_TRIGGER_RECORD_UPDATED',
  TRIGGER_RECORD_DELETED = 'HUBSPOT_TRIGGER_RECORD_DELETED',
}

export const TriggerIntents = [
  Intent.TRIGGER_RECORD_CREATED,
  Intent.TRIGGER_RECORD_UPDATED,
  Intent.TRIGGER_RECORD_DELETED,
];

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD_TYPE_FIELDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.GET_PROPERTY_META_DATA; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_OWNERS; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_CUSTOM_OBJECTS; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_ALL_OBJECT_TYPES; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: Intent.GET_CONTACTS_BY_LIST_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CONTACTS_LISTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_DELETED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;

  // this will be there for oauth_enabled app
  OAUTH_ACCESS_TOKEN?: string;
  SCOPES?: string;
};
