import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.WOOCOMMERCE;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'WOOCOMMERCE_TEST_CONNECTION',
  SEARCH_CUSTOMERS = 'WOOCOMMERCE_SEARCH_CUSTOMERS',
  CREATE_CUSTOMER = 'WOOCOMMERCE_CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'WOOCOMMERCE_UPDATE_CUSTOMER',
  DELETE_CUSTOMER = 'WOOCOMMERCE_DELETE_CUSTOMER',
  GET_CUSTOMER_BY_ID = 'WOOCOMMERCE_GET_CUSTOMER_BY_ID',
  SEARCH_ORDERS = 'WOOCOMMERCE_SEARCH_ORDERS',
  CREATE_ORDER = 'WOOCOMMERCE_CREATE_ORDER',
  UPDATE_ORDER = 'WOOCOMMERCE_UPDATE_ORDER',
  DELETE_ORDER = 'WOOCOMMERCE_DELETE_ORDER',
  GET_ORDER_BY_ID = 'WOOCOMMERCE_GET_ORDER_BY_ID',
  SEARCH_PRODUCTS = 'WOOCOMMERCE_SEARCH_PRODUCTS',
  CREATE_PRODUCT = 'WOOCOMMERCE_CREATE_PRODUCT',
  UPDATE_PRODUCT = 'WOOCOMMERCE_UPDATE_PRODUCT',
  DELETE_PRODUCT = 'WOOCOMMERCE_DELETE_PRODUCT',
  GET_PRODUCT_BY_ID = 'WOOCOMMERCE_GET_PRODUCT_BY_ID',
  GET_PAYMENT_GATEWAYS = 'WOOCOMMERCE_GET_PAYMENT_GATEWAYS',
}

type IntentMap =
  | Intent.TEST_CONNECTION
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID
  | Intent.CREATE_CUSTOMER
  | Intent.CREATE_ORDER
  | Intent.CREATE_PRODUCT
  | Intent.DELETE_CUSTOMER
  | Intent.DELETE_ORDER
  | Intent.DELETE_PRODUCT
  | Intent.GET_CUSTOMER_BY_ID
  | Intent.GET_ORDER_BY_ID
  | Intent.GET_PRODUCT_BY_ID
  | Intent.SEARCH_CUSTOMERS
  | Intent.SEARCH_ORDERS
  | Intent.SEARCH_PRODUCTS
  | Intent.UPDATE_CUSTOMER
  | Intent.UPDATE_ORDER
  | Intent.UPDATE_PRODUCT
  | Intent.GET_PAYMENT_GATEWAYS;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export type Credentials = {
  [CredentialKeys.STORE_DOMAIN]: string;
  [CredentialKeys.CONSUMER_KEY]: string;
  [CredentialKeys.CONSUMER_SECRET]: string;
};

export enum CredentialKeys {
  STORE_DOMAIN = 'WOOCOMMERCE_STORE_DOMAIN',
  CONSUMER_KEY = 'WOOCOMMERCE_CONSUMER_KEY',
  CONSUMER_SECRET = 'WOOCOMMERCE_CONSUMER_SECRET',
}
