import { BadRequestException, RequestTimeoutException } from '@nestjs/common';

import { Action, ActionIntent } from '../actions';
import { HttpMethod, StateMachine, StepType } from '../steps';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';
import { TypeCoercionError } from './primitives';

export class StepFailure extends ParagonError {
  name: string = 'StepFailure';

  constructor(
    public readonly wrappedError: Error,
    public readonly stepId: string,
    public readonly instanceId: string,
    public readonly input: object,
    public readonly startTime: number,
    public readonly endTime: number,
    public readonly code: ERROR_CODE = ERROR_CODE.STEP_FAILURE,
    public readonly httpStatus: HttpStatus = HttpStatus.INTERNAL_SERVER_ERROR,
    logOnInitialization: boolean = true,
  ) {
    super(code, httpStatus, `${wrappedError.message || wrappedError}`, {}, logOnInitialization);
  }
}

export class ActionFailure extends StepFailure {
  name: string = 'ActionFailure';

  constructor(
    public readonly wrappedError: Error,
    public readonly stepId: string,
    public readonly instanceId: string,
    public readonly input: object,
    public readonly startTime: number,
    public readonly endTime: number,
    public readonly action: Action,
    public readonly intent: ActionIntent,
    public readonly code: ERROR_CODE = ERROR_CODE.ACTION_FAILURE,
    public readonly httpStatus: HttpStatus = HttpStatus.INTERNAL_SERVER_ERROR,
  ) {
    super(wrappedError, stepId, instanceId, input, startTime, endTime, code, httpStatus);
  }
}

export class StepExecutionTimeoutError extends StepFailure {
  name: string = 'StepExecutionTimeoutError';
  timeout: number;

  constructor(
    stepId: string,
    instanceId: string,
    input: object,
    startTime: number,
    endTime: number,
    timeout: number,
  ) {
    super(
      new RequestTimeoutException(
        `Step execution timed out after ${(timeout / 1000).toFixed(0)} seconds.`,
      ),
      stepId,
      instanceId,
      input,
      startTime,
      endTime,
      ERROR_CODE.STEP_EXECUTION_TIMEOUT,
      HttpStatus.REQUEST_TIMEOUT,
    );
    this.timeout = timeout;
  }
}

export class InvalidExecutionStateError extends StepFailure {
  name: string = 'InvalidExecutionStateError';

  constructor(
    stepId: string,
    instanceId: string,
    input: object,
    startTime: number,
    endTime: number,
  ) {
    super(
      new Error('Workflow execution in invalid state.'),
      stepId,
      instanceId,
      input,
      startTime,
      endTime,
      ERROR_CODE.INVALID_EXECUTION_STATE,
      HttpStatus.INTERNAL_SERVER_ERROR,
    );
  }
}

export class BadRequestFailure extends StepFailure {
  name: string = 'BadRequestFailure';

  constructor(
    stepId: string,
    instanceId: string,
    input: object,
    startTime: number,
    endTime: number,
    error: BadRequestException,
  ) {
    super(
      error,
      stepId,
      instanceId,
      input,
      startTime,
      endTime,
      ERROR_CODE.BAD_REQUEST_FOR_EXECUTION,
      HttpStatus.BAD_REQUEST,
    );
  }
}

export class InvalidStateMachineError extends ParagonError {
  name: string = 'InvalidStateMachineError';

  constructor(public readonly executionId: string, public readonly stateMachine?: StateMachine) {
    super(
      ERROR_CODE.INVALID_STATE_MACHINE,
      HttpStatus.UNPROCESSABLE_ENTITY,
      `InvalidStateMachineError: Invalid state machine`,
      {
        executionId,
      },
    );
  }
}

export class TypeCoercionStepFailure extends StepFailure {
  name: string = 'TypeCoercionStepFailure';

  constructor(
    stepId: string,
    instanceId: string,
    input: object,
    startTime: number,
    endTime: number,
    error: TypeCoercionError,
  ) {
    super(
      error,
      stepId,
      instanceId,
      input,
      startTime,
      endTime,
      ERROR_CODE.TYPE_COERCION_ERROR,
      HttpStatus.UNPROCESSABLE_ENTITY,
    );
  }
}

export class ConnectProxyFailure extends ParagonError {
  name: string = 'ConnectProxyFailure';

  constructor(
    public readonly wrappedError: Error,
    public readonly instanceId: string,
    public readonly body: any,
    public readonly path: string,
    public readonly method: HttpMethod,
    public readonly startTime: number,
    public readonly endTime: number,
    public readonly action: Action,
    public readonly code: ERROR_CODE = ERROR_CODE.CONNECT_PROXY_FAILURE,
    public readonly httpStatus: HttpStatus = HttpStatus.INTERNAL_SERVER_ERROR,
  ) {
    super(code, httpStatus, wrappedError.message);
  }
}

export class UnknownExecutionError extends ParagonError {
  name: string = 'UnknownExecutionError';

  constructor(meta: {
    executionType: string;
    queue: string;
    projectId?: string;
    executionId?: string;
    workflowId?: string;
    stepId?: string;
  }) {
    super(
      ERROR_CODE.UNKNOWN_EXECUTION_ERROR,
      HttpStatus.BAD_REQUEST,
      'Unable to execution job, unknown execution type.',
      meta,
    );
  }
}

export class UnknownActionError extends ParagonError {
  name: string = 'UnknownActionError';

  constructor(meta: { action: string; actionPath: string; intent: string }) {
    super(
      ERROR_CODE.UNKNOWN_ACTION_ERROR,
      HttpStatus.BAD_REQUEST,
      `No action exists with the name ${meta.action}.`,
      meta,
    );
  }
}

export class ActionConfigNotFoundError extends ParagonError {
  name: string = 'ActionConfigNotFoundError';

  constructor(meta: { action: string; actionPath: string; intent: string; configPath: string }) {
    super(
      ERROR_CODE.ACTION_CONFIG_NOT_FOUND,
      HttpStatus.NOT_FOUND,
      `No config exists for action: ${meta.action}`,
      meta,
    );
  }
}

export class UnknownActionIntentError extends ParagonError {
  name: string = 'UnknownActionIntentError';

  constructor(meta: { action: string; actionPath: string; intent: string }) {
    super(
      ERROR_CODE.ACTION_INTENT_NOT_FOUND,
      HttpStatus.BAD_REQUEST,
      `Intent "${meta.intent}" does not exist for action: ${meta.action}`,
      meta,
    );
  }
}

export class ActionTransformUserSuppliedCredentialNotSupportedError extends ParagonError {
  name: string = 'ActionTransformUserSuppliedCredentialNotSupportedError';

  constructor(meta: { providerType: string }) {
    super(
      ERROR_CODE.ACTION_USER_SUPPLIED_CREDENTIAL_NOT_SUPPORTED,
      HttpStatus.BAD_REQUEST,
      `${meta.providerType}-type user credential inputs are not yet supported in actions`,
      meta,
    );
  }
}

export class ActionProcessorNotSupportedCustomIntegrationError extends ParagonError {
  name: string = 'ActionProcessorNotSupportedCustomIntegrationError';

  constructor(meta: { action: string }) {
    super(
      ERROR_CODE.ACTION_PROCESSOR_NOT_SUPPORTED_CUSTOM_INTEGRATION,
      HttpStatus.INTERNAL_SERVER_ERROR,
      `Unsupported method invoked for "Action.CUSTOM", use proper processor for this action`,
      meta,
    );
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// test execution errors

abstract class TestWorkflowError extends ParagonError {
  name: string = 'TestWorkflowError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class TestWorkflowEmptyStepsError extends TestWorkflowError {
  constructor(meta: { workflowId: string; projectId: string }) {
    super({
      code: ERROR_CODE.TEST_WORKFLOW_EMPTY_STEPS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'No steps provided for testing.',
      meta,
    });
  }
}

export class TestWorkflowMissingConnectIntegrationError extends TestWorkflowError {
  constructor(meta: { workflowId: string; projectId: string }) {
    super({
      code: ERROR_CODE.TEST_WORKFLOW_MISSING_CONNECT_INTEGRAITON,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'No integration id provided for connect workflow.',
      meta,
    });
  }
}

export class TestWorkflowMissingConnectPersonaMetaError extends TestWorkflowError {
  constructor(meta: { workflowId: string; projectId: string; integrationId?: string }) {
    super({
      code: ERROR_CODE.TEST_WORKFLOW_MISSING_CONNECT_PERSONA_META,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'No persona meta provided for connect workflow.',
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// trigger errors

abstract class TriggerError extends ParagonError {
  name: string = 'TriggerError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class IntegrationDoesNotSupportTriggersError extends TriggerError {
  constructor(meta: { integrationId: string; integration: Action }) {
    super({
      code: ERROR_CODE.INTEGRATION_DOES_NOT_SUPPORT_TRIGGERS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `The "${meta.integration}" integration does not support triggers.`,
      meta,
    });
  }
}

export class IntegrationDoesNotSupportWebhooksError extends TriggerError {
  constructor(meta: { integrationId: string; integration: Action }) {
    super({
      code: ERROR_CODE.INTEGRATION_DOES_NOT_SUPPORT_WEBHOOKS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `The "${meta.integration}" integration does not support webhooks.`,
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// processor errors

abstract class ProcessorError extends ParagonError {
  name: string = 'ProcessorError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// endpoint processor errors

export class EndpointProcessorInvalidTypeError extends ProcessorError {
  constructor(meta: { stepId: string; executionId: string; inputStepType: StepType }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_INVALID_TYPE,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Mismatch between step type and trigger data.',
      meta,
    });
  }
}

export class EndpointProcessorInvalidHttpMethodError extends ProcessorError {
  constructor(meta: {
    stepId: string;
    executionId: string;
    method: HttpMethod;
    expected: HttpMethod;
  }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_INVALID_HTTP_METHOD,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Wrong HTTP method. Expected "${meta.expected}". Received: ${meta.method}`,
      meta,
    });
  }
}

export class EndpointProcessorMissingPayloadError extends ProcessorError {
  constructor(meta: { stepId: string; executionId: string }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_INVALID_HTTP_METHOD,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Missing payload for request. You have not sent this step a test request yet.',
      meta,
    });
  }
}

export class EndpointProcessorMissingQueryParamError extends ProcessorError {
  constructor(meta: { stepId: string; executionId: string; param: string }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_MISSING_QUERY_PARAMETER,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Missing required query parameter: ${meta.param}`,
      meta,
    });
  }
}

export class EndpointProcessorInvalidHeaderParamError extends ProcessorError {
  constructor(meta: { stepId: string; executionId: string; param: string }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_INVALID_HEADER_PARAMETER,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Wrong value for header parameter: ${meta.param}`,
      meta,
    });
  }
}

export class EndpointProcessorMissingHeaderParamError extends ProcessorError {
  constructor(meta: { stepId: string; executionId: string; param: string }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_MISSING_HEADER_PARAMETER,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Missing header parameter: ${meta.param}`,
      meta,
    });
  }
}

export class EndpointProcessorMissingBodyParamError extends ProcessorError {
  constructor(meta: { stepId: string; executionId: string; param: string }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_MISSING_BODY_PARAMETER,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Missing body parameter: ${meta.param}`,
      meta,
    });
  }
}

export class EndpointProcessorInvalidBodyParamError extends ProcessorError {
  constructor(meta: { stepId: string; executionId: string; param: string }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_MISSING_BODY_PARAMETER,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Invalid body parameter: ${meta.param}`,
      meta,
    });
  }
}

export class EndpointProcessorFeatureNotEnabledError extends ProcessorError {
  constructor(meta: { methodName: string }) {
    super({
      code: ERROR_CODE.ENDPOINT_PROCESSOR_FEATURE_NOT_IMPLEMENTED,
      httpStatus: HttpStatus.NOT_IMPLEMENTED,
      message: `Method not implemented.`,
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// integration enabled processor errors
export class IntegrationEnabledFeatureNotEnabledError extends ProcessorError {
  constructor(meta: { methodName: string }) {
    super({
      code: ERROR_CODE.INTEGRATION_ENABLED_FEATURE_NOT_IMPLEMENTED,
      httpStatus: HttpStatus.NOT_IMPLEMENTED,
      message: `Method not implemented.`,
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// event processor errors

export class EventProcessorFeatureNotEnabledError extends ProcessorError {
  constructor(meta: { methodName: string }) {
    super({
      code: ERROR_CODE.EVENT_PROCESSOR_FEATURE_NOT_IMPLEMENTED,
      httpStatus: HttpStatus.NOT_IMPLEMENTED,
      message: `Method not implemented.`,
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// function processor errors

export class FunctionProcessorLineError extends ProcessorError {
  constructor(
    wrapperError: Error,
    meta: {
      lineNumber;
      projectId: string;
      workflowId: string;
      stepId: string;
      executionId: string;
    },
  ) {
    super({
      code: ERROR_CODE.FUNCTION_STEP_LINE_ERROR,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: `Line ${meta.lineNumber} with error: ${wrapperError.message}`,
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// custom integration processor errors

export class CustomIntegrationProcessorMissingIntegrationIdError extends ProcessorError {
  constructor(meta: {
    projectId: string;
    workflowId: string;
    stepId: string;
    executionId: string;
  }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_MISSING_INTEGRATION_ID,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: `Missing integrationId in custom integration workflow context.`,
      meta,
    });
  }
}

export class CustomIntegrationProcessorMissingCredentialsError extends ProcessorError {
  constructor(meta: {
    hasDecryptedConnectCredential: boolean;
    hasConnectCredentialId?: boolean;
    hasDecryptedCredential?: boolean;
    projectId?: string;
    workflowId?: string;
    stepId?: string;
    executionId?: string;
  }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_MISSING_CREDENTIALS,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: `Could not find credential info.`,
      meta,
    });
  }
}

export class CustomIntegrationInvalidBaseUrlOverrideError extends ProcessorError {
  constructor(meta: {
    action: string;
    baseUrlOverride: string;
    projectId?: string;
    workflowId?: string;
    stepId?: string;
    executionId?: string;
  }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_INVALID_REQUEST_URL_OVERRIDE,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Provided domain is not a valid domain for current integration.`,
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// cron processor errors

export class CronProcessorFeatureNotEnabledError extends ProcessorError {
  constructor(meta: { methodName: string }) {
    super({
      code: ERROR_CODE.CRON_PROCESSOR_FEATURE_NOT_IMPLEMENTED,
      httpStatus: HttpStatus.NOT_IMPLEMENTED,
      message: `Method not implemented.`,
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// conditional processor errors

export class ConditionalProcessorNoOperatorError extends ProcessorError {
  constructor(meta: {
    projectId: string;
    workflowId?: string;
    stepId?: string;
    executionId?: string;
  }) {
    super({
      code: ERROR_CODE.CONDITIONAL_PROCESSOR_OPERATOR_NOT_SPECIFIED,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `No operator type specified.`,
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// connectAction processor errors
export class ConnectActionProcessorMissingIntegrationIdError extends ProcessorError {
  name = 'ConnectActionProcessorMissingIntegrationIdError';
  constructor() {
    super({
      code: ERROR_CODE.CONNECT_ACTION_PROCESSOR_MISSING_INTEGRATION_ID,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Missing integrationId in workflow context',
      meta: {},
    });
  }
}

export class ConnectActionProcessorDecryptCredentialNotFoundError extends ProcessorError {
  name = 'ConnectActionProcessorDecryptCredentialNotFoundError';
  constructor(meta: { integrationId: string; customIntegrationId?: string }) {
    super({
      code: ERROR_CODE.CONNECT_ACTION_PROCESSOR_DECRYPT_CONNECT_CREDENTIAL_NOT_FOUND,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Could not found decryptedCredential.',
      meta,
    });
  }
}

// ---------------------------------------- //
// ---------------------------------------- //
// connectProxy processor errors
export class ConnectProxyProcessorMissingIntegrationIdError extends ProcessorError {
  name = 'ConnectProxyProcessorMissingIntegrationIdError';
  constructor(meta: { action: Action }) {
    super({
      code: ERROR_CODE.CONNECT_PROXY_PROCESSOR_MISSING_INTEGRATION_ID,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Missing integrationId in workflow context',
      meta,
    });
  }
}

export class ConnectProxyProcessorDecryptConnectCredentialNotFoundError extends ProcessorError {
  name = 'ConnectProxyProcessorDecryptConnectCredentialNotFoundError';
  constructor(meta: { action: Action }) {
    super({
      code: ERROR_CODE.CONNECT_PROXY_PROCESSOR_DECRYPT_CONNECT_CREDENTIAL_NOT_FOUND,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Could not found decrypted connect credential.',
      meta,
    });
  }
}

export class ConnectProxyInvalidDomainError extends ProcessorError {
  name = 'ConnectProxyInvalidDomainError';
  constructor(meta: { action: Action; url: string }) {
    super({
      code: ERROR_CODE.CONNECT_PROXY_PROCESSOR_INVALID_DOMAIN,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'This domain is invalid for the current integration.',
      meta,
    });
  }
}
