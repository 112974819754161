import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedRedirectParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { RedirectStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import {
  getRequirdStepIdForTokenizedSource,
  getRequiredStepIdToResolveKeyedSources,
  resolveKeyedSources,
  resolveSources,
} from './resolvers.utils';

/**
 * a resolver for a redirect step's variables
 *
 * @export
 * @class RedirectStepResolver
 * @extends {StepResolver<RedirectStep, ResolvedRedirectParameters>}
 */
export class RedirectStepResolver extends StepResolver<RedirectStep, ResolvedRedirectParameters> {
  /**
   * resolves the input parameters for the step
   *
   * @param {RedirectStep['parameters']} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedRedirectParameters}
   * @memberof RedirectStepResolver
   */
  resolveInputParameters(
    parameters: RedirectStep['parameters'],
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedRedirectParameters {
    const resolved: ResolvedRedirectParameters = resolveKeyedSources(
      variables,
      resolveSources(
        variables,
        parameters,
        secrets,
        cachedConnectCredential,
        context,
        cachedPersona,
        'url',
      ),
      secrets,
      cachedConnectCredential,
      context,
      cachedPersona,
      'params',
    );

    return resolved;
  }

  getRequiredStepIdsForVariableResolution(parameters: RedirectStep['parameters']): string[] {
    return [
      ...getRequiredStepIdToResolveKeyedSources(parameters.params),
      ...getRequirdStepIdForTokenizedSource(parameters.url),
    ];
  }
}
