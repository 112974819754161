import {
  createTestConnectionButton,
  hasImpersonatedUserCredential,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  contentIdInput,
  contentTypeInput,
  emailInput,
  impersonateContentIdInput,
  impersonatedCredentialsInput,
  isSevereInput,
  messageInput,
  nameInput,
  personalTokenName,
  personalTokenSecret,
  serverNameInput,
  siteIdInput,
  statusInput,
  warningIdInput,
  warningTypeInput,
} from './inputs';

export const authConfig: ActionConfig = {
  actionType: Action.TABLEAU,
  provider: ProviderType.TABLEAU,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your Tableau account',
  icon: iconSvg,
  description: 'Add Tableau to Paragon',
  supportsImpersonatedCredentialSource: true,
  sidebarSections: [
    {
      inputs: [
        nameInput,
        serverNameInput,
        siteIdInput,
        emailInput,
        personalTokenName,
        personalTokenSecret,
      ],
      buttons: [createTestConnectionButton(Action.TABLEAU, Object.values(CredentialKeys))],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.TABLEAU,
  name: 'Tableau',
  description: 'Access and update data in Tableau.',
  icon: iconSvg,
  sidebarSections: [
    (parameters: ActionStepParameters): SidebarSection => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Tableau account',
          placeholder: 'Add new account for Tableau',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasImpersonatedUserCredential(parameters) ? impersonatedCredentialsInput : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection => {
      return parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    label: 'Add data quality warning',
                    value: Intent.ADD_DATA_QUALITY_WARNING,
                  },
                  {
                    label: 'Update data quality warning',
                    value: Intent.UPDATE_DATA_QUALITY_WARNING,
                  },
                  {
                    label: 'Get data quality warning by ID',
                    value: Intent.GET_DATA_QUALITY_WARNING_BY_ID,
                  },
                  {
                    label: 'Get data quality warning by content',
                    value: Intent.GET_DATA_QUALITY_WARNING_BY_CONTENT,
                  },
                  {
                    label: 'Delete data quality warning by ID',
                    value: Intent.DELETE_DATA_QUALITY_WARNING_BY_ID,
                  },
                  {
                    label: 'Delete data quality warning by content',
                    value: Intent.DELETE_DATA_QUALITY_WARNING_BY_CONTENT,
                  },
                ],
              },
            ],
          }
        : {
            inputs: [],
          };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.ADD_DATA_QUALITY_WARNING:
          return {
            inputs: [
              contentTypeInput,
              ...(hasImpersonatedUserCredential(parameters)
                ? [impersonateContentIdInput]
                : [contentIdInput]),
              warningTypeInput,
              statusInput,
              messageInput,
              isSevereInput,
            ],
          };
        case Intent.UPDATE_DATA_QUALITY_WARNING:
          return {
            inputs: [
              warningIdInput,
              { ...warningTypeInput, ...{ required: false } },
              statusInput,
              messageInput,
              isSevereInput,
            ],
          };
        case Intent.GET_DATA_QUALITY_WARNING_BY_ID:
          return {
            inputs: [
              {
                ...warningIdInput,
                subtitle: 'The ID of the data quality warning that will be retrieved.',
              },
            ],
          };
        case Intent.DELETE_DATA_QUALITY_WARNING_BY_CONTENT:
        case Intent.GET_DATA_QUALITY_WARNING_BY_CONTENT:
          return {
            inputs: [
              {
                ...contentTypeInput,
                subtitle: 'The type of asset the data quality warning is attached to.',
              },
              ...(hasImpersonatedUserCredential(parameters)
                ? [
                    {
                      ...impersonateContentIdInput,
                      subtitle:
                        'The unique ID of the asset the data quality warning is attached to.',
                    },
                  ]
                : [
                    {
                      ...contentIdInput,
                      subtitle:
                        'The unique ID of the asset the data quality warning is attached to.',
                    },
                  ]),
            ],
          };
        case Intent.DELETE_DATA_QUALITY_WARNING_BY_ID:
          return {
            inputs: [
              {
                ...warningIdInput,
                subtitle: 'The ID of the data quality warning that will be deleted.',
              },
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
