import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.ZOHO_CRM;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'ZOHO_CRM_TEST_CONNECTION',
  CREATE_RECORD = 'ZOHO_CRM_CREATE_RECORD',
  UPDATE_RECORD = 'ZOHO_CRM_UPDATE_RECORD',
  GET_RECORD_BY_ID = 'ZOHO_CRM_GET_RECORD_BY_ID',
  SEARCH_RECORDS = 'ZOHO_CRM_SEARCH_RECORDS',
  DELETE_RECORD = 'ZOHO_CRM_DELETE_RECORD',
  GET_RECORDS = 'ZOHO_CRM_GET_RECORDS',
  WRITE_COQL_QUERY = 'ZOHO_CRM_WRITE_COQL_QUERY',
  //triggers
  TRIGGER_RECORD_CREATED = 'ZOHO_CRM_TRIGGER_RECORD_CREATED',
  TRIGGER_RECORD_UPDATED = 'ZOHO_CRM_TRIGGER_RECORD_UPDATED',
}

/** Combination of ActionIntent and ConnectIntent **/
type IntentMap =
  | Intent.TEST_CONNECTION
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID
  | OAuthIntent.EXCHANGE_AUTH_CREDENTIALS
  | OAuthIntent.REFRESH_CREDENTIAL
  | OAuthIntent.GET_AUTH_ESSENTIALS
  | Intent.CREATE_RECORD
  | Intent.UPDATE_RECORD
  | Intent.GET_RECORD_BY_ID
  | Intent.SEARCH_RECORDS
  | Intent.DELETE_RECORD
  | Intent.GET_RECORDS
  | Intent.WRITE_COQL_QUERY
  | Intent.TRIGGER_RECORD_CREATED
  | Intent.TRIGGER_RECORD_UPDATED;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  ACCESS_TOKEN = 'ZOHO_CRM_ACCESS_TOKEN',
  REFRESH_TOKEN = 'ZOHO_CRM_REFRESH_TOKEN',
  API_DOMAIN = 'ZOHO_CRM_API_DOMAIN',
  ACCOUNTS_SERVER = 'ZOHO_CRM_ACCOUNTS_SERVER',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
  [CredentialKeys.API_DOMAIN]: string;
  [CredentialKeys.ACCOUNTS_SERVER]: string;
};
