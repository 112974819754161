import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

import { getSites } from './sources';

export const siteUrlInput: SidebarInput = {
  id: 'siteUrl',
  title: 'Site URL',
  subtitle:
    'The URL of the site to add to Search Console. Specify a URL or a Domain Property (e.g., sc-domain:example.com).',
  placeholder: 'https://example.com/',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const siteUrlDynamicInput: SidebarInput = {
  id: 'siteUrl',
  type: SidebarInputType.DynamicEnum,
  title: 'Site URL',
  required: true,
  source: getSites,
  getValues: getValuesByCacheKey,
};

export const startDateInput: SidebarInput = {
  id: 'startDate',
  title: 'Start Date',
  subtitle: 'Request data beginning at this date, inclusive.',
  placeholder: '2022-01-01',
  type: SidebarInputType.TextArea,
  lines: 1,
};

export const endDateInput: SidebarInput = {
  id: 'endDate',
  title: 'End Date',
  subtitle: 'Request data ending at this date, inclusive.',
  placeholder: '2022-01-31',
  type: SidebarInputType.TextArea,
  lines: 1,
};

export const searchDataTypeInput: SidebarInput = {
  id: 'searchType',
  title: 'Type',
  type: SidebarInputType.EditableEnum,
  subtitle: 'Filter results to a specific type of search data. Defaults to all search result data.',
  required: false,
  getValues: () => [
    { label: 'Web', value: 'web' },
    { label: 'Image', value: 'image' },
    { label: 'Video', value: 'video' },
    { label: 'News', value: 'news' },
    { label: 'Discover', value: 'discover' },
    { label: 'Google News', value: 'googleNews' },
  ],
};

export const groupByInput: SidebarInput = {
  id: 'groupBy',
  title: 'Group by',
  type: SidebarInputType.EditableEnum,
  subtitle: 'Group results by properties of search data.',
  placeholder: 'page,device...',
  required: false,
  enableMultiSelect: true,
  getValues: () => [
    { label: 'Country', value: 'country' },
    { label: 'Device', value: 'device' },
    { label: 'Page', value: 'page' },
    { label: 'Query', value: 'query' },
    { label: 'Search Appearance', value: 'searchAppearance' },
    { label: 'Date', value: 'date' },
  ],
};

export const rowLimitInput: SidebarInput = {
  id: 'rowLimit',
  title: 'Row limit',
  type: SidebarInputType.TextArea,
  subtitle: 'Limit query to the specified number of rows, in descending order of number of clicks.',
  required: false,
  lines: 1,
};

export const inspectionUrlInput: SidebarInput = {
  id: 'inspectionUrl',
  title: 'Inspect URL',
  type: SidebarInputType.TextArea,
  placeholder: 'https://example.com/about/info',
  subtitle: 'The full URL path to inspect, which must be under the Site URL specified above.',
  lines: 1,
};

export const filterGroupsInput: SidebarInput = {
  id: 'filterGroups',
  title: 'Filter groups',
  type: SidebarInputType.Conditional,
  subtitle: 'Search for groups that match specified filters.',
  required: false,
  supportedOperators: [
    Operator.StringContains,
    Operator.StringDoesNotContain,
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
  ],
  getValues: () => [
    { label: 'Country', value: 'country' },
    { label: 'Device', value: 'device' },
    { label: 'Page', value: 'page' },
    { label: 'Query', value: 'query' },
    { label: 'Search Appearance', value: 'searchAppearance' },
  ],
};

export const intentSelectorDropdown: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Search',
          items: [
            { value: Intent.QUERY_SEARCH_DATA, label: 'Query Search Data' },
            { value: Intent.INSPECT_INDEX_INFO, label: 'Inspect Index Info for URL' },
          ],
        },
        {
          title: 'Sites',
          items: [
            { value: Intent.GET_ALL_SITES, label: 'Get All Sites' },
            { value: Intent.CREATE_SITE, label: 'Create Site' },
          ],
        },
      ],
    },
  ],
};
