import React from 'react';

import { IntegrationConnectInput, SidebarInputType } from '@shared/types/sdk/actions';

export const LinkWrapper: React.FC<{ text: string; href?: string }> = ({
  text,
  href,
}: {
  text: string;
  href: string;
}) => (
  <a
    href={
      href ||
      'https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_157771482304.html'
    }
    target="_blank"
    rel="noreferrer"
  >
    {text}
  </a>
);

export const accountIdInput: IntegrationConnectInput = {
  type: SidebarInputType.ValueText,
  id: 'NETSUITE_ACCOUNT_ID',
  title: 'NetSuite Account ID',
  subtitle: (
    <>
      Your NetSuite Account ID, which can be found in the URL of your NetSuite instance. Your
      instance must have the <LinkWrapper text="OAuth 2.0" /> feature enabled.
    </>
  ),
  placeholder: 'NetSuite Account ID',
  suffixLabel: '.app.netsuite.com',
  required: true,
};
