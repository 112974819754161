export const SALESFORCE_HOST = 'https://login.salesforce.com';
export const SALESFORCE_SANDBOX_HOST = 'https://test.salesforce.com';

/**
 * this is the maximum number of records that are allowed in a SOQL query when using FIND(ALL)
 */
export const MAX_RECORDS_ALLOWED = 200;

/**
 * A constant that represents a value for the `recordType` property recorded in the UI as
 * "Custom Object".
 *
 * If this field is found in the `recordType` property, the `objectName` property
 * in the step should be used as the name of the object being created/updated/searched.
 */
export const RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME = '<specified by objectName property>' as const;

export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;
  IS_SANDBOX?: boolean;
  OAUTH_ACCESS_TOKEN: string;
  SCOPES?: string;
};

export type Environment = {
  SALESFORCE_CLIENT_ID: string;
  SALESFORCE_CLIENT_SECRET: string;
  SALESFORCE_SCOPES?: string;
};

type IncludingAdditionalFields = {
  /**
   * Additional Fields is an input for Connect-based Salesforce actions where the fields are
   * specified as an object keyed by Salesforce property names at time of step definition (but
   * can also be interpolated with values from the Connect Portal, allowing for the use of a
   * Connect user's custom field names).
   */
  additionalFields?: string;
};

export type CanHaveObjectName<T extends { recordType: string }> =
  | T
  | (T & {
      recordType: typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME;
      objectName?: string;
    });

export type GetAuthUrlDTO = {
  redirectUrl: string;
  sandbox?: boolean;
  state: {
    isSandbox?: boolean;
    [key: string]: any;
  };
};

export type ExchangeCredentialDTO = {
  redirectUrl: string;
  code: string;
  isSandbox?: string;
};

export type GetFieldsDTO = {
  recordType: string;
  getAll?: boolean;
};

export type GetRecordByIdDTO = CanHaveObjectName<{
  recordType: string;
  recordId: string;
}>;

export type SearchRecordDTO = CanHaveObjectName<{
  recordType: string;
  filterFormula?: string;
  limit?: number;
  filterCachedFields?: GetFieldResponse[];
  options?: { queryEndPoint: 'query' | 'queryAll' };
}>;

export type QueryRecordDTO = {
  query: string;
};

export type UpdateRecordDTO = CanHaveObjectName<
  IncludingAdditionalFields & {
    recordType: string;
    recordId: string;
    cachedFields?: GetFieldResponse[];
    updateRecordPayload?: string;
    [key: string]: any;
  }
>;

export type GetFieldResponse = {
  label: string;
  value: string;
  required: boolean;
};

export type CreateRecordDTO = CanHaveObjectName<
  IncludingAdditionalFields & {
    recordType: string;
    cachedFields?: GetFieldResponse[];
    createRecordPayload?: string;
    [key: string]: any;
  }
>;

export type SalesforceAuthResponse =
  | {
      refresh_token: string;
      access_token: string;
      instance_url: string;
    }
  | { error: string; error_description: string };

export type SalesforceRecordTypesResponse = {
  sobjects: SObject[];
};

export type SObject = {
  deprecatedAndHidden: boolean;
  label: string;
  name: string;
};

export type SalesforceSObjectDescribeResponse = {
  fields: RecordTypeField[];
};

export type PicListValue = {
  value: string;
  label: string;
};

export type RecordTypeField = {
  label: string;
  name: string;
  updateable: boolean;
  createable: boolean;
  type: string;
  nillable: boolean;
  defaultedOnCreate: boolean;
  picklistValues: PicListValue[];
};

export type UserInfoResponse = {
  email: string;
  preferred_username: string;
  urls: {
    metadata: string;
    rest: string;
    custom_domain: string;
  };
  user_id: string;
};

export type SalesforceAvailableVersionResponse = {
  version: string;
}[];

export type RecordTriggerDTO = {
  recordType: string;
  objectName?: string;
  recordsFilterFormula?: string;
};

export type CampaignTriggerDTO = {
  campaignId?: string;
  filterFormula?: string;
};

export type TriggerDTO = RecordTriggerDTO | CampaignTriggerDTO;

export type RecordCreatedE2EPayload = {
  new: {
    attributes: {
      type: string;
      url: string;
    };
    Id: string;
    OwnerId: string;
    CreatedDate: string;
    CreatedById: string;
    LastModifiedDate: string;
    LastModifiedById: string;
    SystemModstamp: string;
    LastActivityDate: null;
    LastViewedDate: string;
    LastReferencedDate: string;
  }[];
};

export type RecordUpdatedE2EPayload = RecordCreatedE2EPayload;
export type CreateFieldDTO = CanHaveObjectName<{
  recordType: string;
  label: string;
  type: SalesforceCustomFieldTypes;
  description?: string;
  helperText?: string;
  defaultFieldValue?: string;
  length?: number;
  decimalPlace?: number;
  pickListValues?: string;
  visibleLines?: number;
  defaultCheckboxValue?: string;
}>;

export type CreateCustomObjectDTO = {
  label: string;
  pluralLabel: string;
  description?: string;
  recordName: string;
};

export enum SalesforceCustomFieldTypes {
  CHECKBOX = 'Checkbox',
  CURRENCY = 'Currency',
  DATE = 'Date',
  EMAIL = 'Email',
  MULTI_PICK_LIST = 'MultiselectPicklist',
  NUMBER = 'Number',
  PERCENT = 'Percent',
  PHONE = 'Phone',
  PICK_LIST = 'Picklist',
  TEXT = 'Text',
  TEXTAREA = 'Textarea',
  TEXTAREA_LONG = 'LongTextArea',
  TEXTAREA_RICH = 'Html',
  TIME = 'Time',
  URL = 'Url',
}

/**
 * Standard/common Salesforce profile names used to set fieldLevelPermissions for custom fields.
 * @see https://www.salesforcehacker.com/2013/09/whats-in-name-well-apparently-lot.html
 */
export const ProfilesFullNames: string[] = [
  'Admin',
  'Standard',
  'MarketingProfile',
  'ContractManager',
  'SolutionManager',
  'ReadOnly',
  'CustomerManager',
  'CustomerUser',
  'PlatformPortal',
  'StandardAul',
];

export type FilterPropertiesForTrigger = 'CreatedDate' | 'LastModifiedDate';

export type SalesforceGenericObject = {
  Id: string;
  CreatedDate: string;
  LastModifiedDate: string;
  isDeleted: boolean;
  [key: string]: any;
};

export type SalesforceSearchResponse = {
  totalSize: number;
  done: true;
  records: SalesforceGenericObject[];
  nextRecordsUrl?: string;
};

export type SalesforceExchangeCredentialResponse = {
  name: string;
  IS_SANDBOX: boolean;
  OAUTH_REFRESH_TOKEN?: string;
  OAUTH_ACCESS_TOKEN?: string;
  instanceUrl: string;
  expires_in: number;
  issuedAt: number;
};

export type TokenInfo = {
  active: boolean;
  exp: number;
  iat: number;
  token_type: 'refresh_token';
};

export type ListViewResponse = { listviews: { id: string; label: string }[] };
