import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  createTaskInputs,
  intentTypeInput,
  listInput,
  taskFilterInput,
  taskIdInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.CLICKUP,
  name: 'ClickUp',
  description: 'Connect to your users ClickUp accounts.',
  icon: iconSvg,
  provider: ProviderType.CLICKUP,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEARCH_TASKS:
          return { inputs: [taskFilterInput] };

        case Intent.GET_TASK_IN_LIST:
          return {
            inputs: [
              {
                ...listInput,
                subtitle:
                  'Select a List to get tasks from. Use Connect Portal User Settings to allow users to select a ClickUp List.',
              },
              taskFilterInput,
            ],
          };

        case Intent.CREATE_TASK:
          return {
            inputs: [...createTaskInputs(true, false)],
          };

        case Intent.UPDATE_TASK:
          return {
            inputs: [taskIdInput, ...createTaskInputs(true, true)],
          };

        case Intent.DELETE_TASK:
          return {
            inputs: [{ ...taskIdInput, subtitle: 'The ID of the task to delete.' }],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
