import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.DYNAMICS_BUSINESS_CENTRAL,
  provider: ProviderType.DYNAMICS_BUSINESS_CENTRAL,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Microsoft Dynamics 365 Business Central account',
  icon: iconSvg,
  description: 'Dynamics 365 Business Central',
  sidebarSections: [],
  postOAuthPrompt: [
    {
      id: 'userAccountFields',
      type: SidebarInputType.ComboInput,
      title: 'Choose Dynamic Business Central Environment & Company to use',
      sourceType: 'userAccountFields',
    },
  ],
};

export const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Microsoft Dynamics 365 Business Central account',
  placeholder: 'connect to Microsoft Dynamics 365 Business Central',
  type: SidebarInputType.Auth,
  config: authConfig,
};

export default authConfig;
