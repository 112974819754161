import React from 'react';

import {
  IntegrationConnectInput,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { CredentialKeys, Intent } from '../configs/config';
import { supportedOperators } from '../shared/constants';
import { LinkWrapperType, RecordType } from '../shared/types';

export const LinkWrapper: React.FC<LinkWrapperType> = ({
  clickableText,
  href,
}: LinkWrapperType) => (
  <a href={href} target="_blank" rel="noreferrer">
    {clickableText}
  </a>
);

export const apiKeyInput: IntegrationConnectInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.API_KEY,
  title: 'API Key',
  required: true,
  subtitle: (
    <>
      Generate an API Key in the{' '}
      <LinkWrapper clickableText="SAP API Business Hub." href="https://api.sap.com/" />
    </>
  ),
};

export const hostUrlInput: IntegrationConnectInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.HOST_URL,
  title: 'SAP Host URL',
  required: true,
  subtitle: 'Include a port number if necessary.',
};

export const usernameInput: IntegrationConnectInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.USERNAME,
  title: 'Username',
  required: true,
  subtitle: 'Your login username.',
};

export const passwordInput: IntegrationConnectInput = {
  type: SidebarInputType.Password,
  id: CredentialKeys.PASSWORD,
  title: 'Password',
  required: true,
  subtitle: 'Your login password.',
};

export const intentSelectorDropdown: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Supplier Invoice',
          items: [
            {
              value: Intent.CREATE_SUPPLIER_INVOICE,
              label: 'Create Supplier Invoice',
            },
            {
              value: Intent.GET_SUPPLIER_INVOICE_BY_ID,
              label: 'Get Supplier Invoice By ID',
            },
            {
              value: Intent.SEARCH_SUPPLIER_INVOICES,
              label: 'Search Supplier Invoices',
            },
            {
              value: Intent.DELETE_SUPPLIER_INVOICE,
              label: 'Delete Supplier Invoice',
            },
          ],
        },
        {
          title: 'Supplier',
          items: [
            { value: Intent.GET_SUPPLIER_BY_ID, label: 'Get Supplier By ID' },
            {
              value: Intent.SEARCH_SUPPLIERS,
              label: 'Search Suppliers',
            },
            {
              value: Intent.UPDATE_SUPPLIER,
              label: 'Update Supplier',
            },
          ],
        },
        {
          title: 'Customer',
          items: [
            { value: Intent.GET_CUSTOMER_BY_ID, label: 'Get Customer By ID' },

            {
              value: Intent.SEARCH_CUSTOMERS,
              label: 'Search Customer',
            },
            {
              value: Intent.UPDATE_CUSTOMER,
              label: 'Update Customer',
            },
          ],
        },
      ],
    },
  ],
};

export const supplierInvoiceInput = (subtitle: string): SidebarInput => ({
  id: 'SupplierInvoice',
  title: 'Supplier Invoice',
  subtitle,
  lines: 1,
  required: true,
  type: SidebarInputType.TextArea,
  placeholder: '5100000001',
});

export const fiscalYearInput = (subtitle: string): SidebarInput => ({
  id: 'FiscalYear',
  title: 'Fiscal Year',
  lines: 1,
  required: true,
  type: SidebarInputType.TextArea,
  subtitle,
  placeholder: '2016',
});

export const supplierAccountNumber = (subtitle: string, required: boolean): SidebarInput => ({
  id: 'Supplier',
  type: SidebarInputType.TextArea,
  title: 'Supplier ID',
  lines: 1,
  required,
  subtitle,
  placeholder: '17300001',
});

export const companyCodeInput: SidebarInput = {
  id: 'CompanyCode',
  type: SidebarInputType.TextArea,
  title: 'Company Code',
  lines: 1,
  required: true,
  placeholder: '1710',
};

export const documentDateInput: SidebarInput = {
  id: 'DocumentDate',
  type: SidebarInputType.TextArea,
  title: 'Document Date',
  lines: 1,
  required: true,
  placeholder: '2022-01-01',
};

export const postingDateInput: SidebarInput = {
  id: 'PostingDate',
  type: SidebarInputType.TextArea,
  title: 'Posting Date',
  lines: 1,
  required: true,
  placeholder: '2022-01-01',
};

export const documentCurrencyInput: SidebarInput = {
  id: 'DocumentCurrency',
  type: SidebarInputType.TextArea,
  title: 'Document Currency',
  lines: 1,
  required: true,
  placeholder: 'USD',
};

export const invoiceGrossAmountInput: SidebarInput = {
  id: 'InvoiceGrossAmount',
  type: SidebarInputType.TextArea,
  title: 'Invoice GrossAmount',
  lines: 1,
  required: true,
  placeholder: '1511.6',
};

export const supplierInvoiceAdditionalFieldsInput: SidebarInput = {
  id: 'supplierInvoiceAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 5,
  subtitle: (
    <>
      Specify additional fields to include on this Supplier Invoice as JSON. For example, you may
      specify a NetPaymentDays here.{' '}
      <LinkWrapper
        clickableText="See the docs"
        href="https://api.sap.com/api/API_SUPPLIERINVOICE_PROCESS_SRV/resource"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `
    {
      "CashDiscount1Percent": "0",
      "CashDiscount1Days": "0",
      "CashDiscount2Percent": "0",
      "CashDiscount2Days": "0",
      "NetPaymentDays": "0",
      "PaymentBlockingReason": "s",
      "AccountingDocumentType": "st", 
    }`,
};

export const customerNumberInput = (subtitle: string, required: boolean): SidebarInput => ({
  id: 'Customer',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Customer ID',
  required,
  subtitle,
  placeholder: '202',
});

export const customerAccountGroupInput: SidebarInput = {
  id: 'CustomerAccountGroup',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'CustomerAccountGroup',
  required: true,
  placeholder: 'CUST',
};

export const customerAdditionalFieldsInput: SidebarInput = {
  id: 'customerAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 5,
  subtitle: (
    <>
      Specify additional fields to include on this Customer as JSON. For example, you may specify a
      AuthorizationGroup here.{' '}
      <LinkWrapper
        clickableText="See the docs"
        href="https://api.sap.com/api/API_BUSINESS_PARTNER/resource"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `
    {
    "AuthorizationGroup": "group1",
    "BillingIsBlockedForCustomer": "true",
    "CustomerAccountGroup": "group1",
    "County": "US"
    "OrderIsBlockedForCustomer": "",
    }`,
};

export const supplierAdditionalFieldsInput: SidebarInput = {
  id: 'supplierAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 5,
  subtitle: (
    <>
      Specify additional fields to include on this Supplier as JSON. For example, you may specify a
      AuthorizationGroup here.{' '}
      <LinkWrapper
        clickableText="See the docs"
        href="https://api.sap.com/api/API_BUSINESS_PARTNER/resource"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `
    {
    "AuthorizationGroup": "group1",
    "PaymentIsBlockedForSupplier": true,
    "PostingIsBlocked": true,
    "PurchasingIsBlocked": true,
    "OrderIsBlockedForCustomer": "",
    }`,
};

export const supplierInvoiceFilterInput: SidebarInput = {
  id: 'supplierInvoiceFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: true,
  getValues: () => [
    { label: 'Supplier Invoice', value: 'SupplierInvoice' },
    { label: 'Fiscal Year', value: 'FiscalYear' },
    { label: 'Creation Date', value: 'CreationDate' },
    { label: 'Invoicing Party', value: 'InvoicingParty' },
  ],
};

export const supplierFilterInput: SidebarInput = {
  id: 'supplierFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: true,
  getValues: () => [
    { label: 'Supplier', value: 'Supplier' },
    { label: 'Customer', value: 'Customer' },
    { label: 'Creation Date', value: 'CreationDate' },
    { label: 'Supplier Name', value: 'SupplierName' },
  ],
};

export const customerFilterInput: SidebarInput = {
  id: 'customerFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: true,
  getValues: () => [
    { label: 'Supplier', value: 'Supplier' },
    { label: 'Customer', value: 'Customer' },
    { label: 'Creation Date', value: 'CreationDate' },
    { label: 'Customer Name', value: 'CustomerName' },
  ],
};

export const recordTypeInput = (record: RecordType): SidebarInput => ({
  id: 'recordType',
  title: 'Record Type',
  type: SidebarInputType.Text,
  required: false,
  overrideValue: record,
  hidden: true,
});

export const authorizationGroupInput: SidebarInput = {
  id: 'AuthorizationGroup',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Authorization Group',
  required: false,
};

export const customerClassificationInput: SidebarInput = {
  id: 'CustomerClassification',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Customer Classification',
  required: false,
};

export const customerCorporateGroupInput: SidebarInput = {
  id: 'CustomerCorporateGroup',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Customer CorporateGroup',
  required: false,
};

export const alternativePayeeAccountNumberInput: SidebarInput = {
  id: 'AlternativePayeeAccountNumber',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'AlternativePayee AccountNumber',
  required: false,
};

export const VATRegistrationInput: SidebarInput = {
  id: 'VATRegistration',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'VAT Registration Number',
  required: false,
  placeholder: 'ATU52375709',
};

export const fiscalAddressInput: SidebarInput = {
  id: 'FiscalAddress',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Fiscal Address',
  required: false,
};
