import axios from 'axios';

import {
  filterEmptyProperties,
  generateQueryStringFromObject,
  getArrayOfStringsFromCommaSeparatedList,
  getAxiosInstance,
  handleResponseError,
  isAccessTokenValid,
  withRefreshCredentialHandling,
} from '@shared/actions/sdk/utils';
import { Action } from '@shared/types/sdk/actions';

import { Credentials } from '../configs';

import { Environment, GetAccountBook, PreparePayload, TokenExchangeResponse } from './types';

import {
  CreateOrUpdateAccountDTO,
  CreateOrUpdateBillDTO,
  CreateOrUpdateTaxGroupDTO,
  CreateOrUpdateVendorDTO,
  RecordType,
} from '.';

/**
 * @summary get NetSuite account book response api
 * @param param0
 * @returns
 */
export const getNetSuiteAccountBook = async (credential: Credentials): Promise<GetAccountBook> => {
  try {
    const response = await getNetSuiteApiClient(credential).get<GetAccountBook>(`/accountingBook`);
    return response.data;
  } catch (err) {
    throw handleResponseError(err, Action.NETSUITE);
  }
};

/**
 * @returns NetSuite Axios Instance with authorization header
 * @summary gets axios object that can be used for sending authorized requests to NetSuite rest api
 */
export const getNetSuiteApiClient = (credential: Credentials, headers = {}) => {
  return getAxiosInstance(buildNetSuiteApiBaseUrl(credential.NETSUITE_ACCOUNT_ID), {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${credential.NETSUITE_ACCESS_TOKEN}`,
    ...headers,
  });
};

/**
 * @summary get netsuite token url
 * @param accountID
 * @returns
 */
export const buildNetSuiteTokenUrl = (accountID: string): string =>
  `https://${accountID}.suitetalk.api.netsuite.com/services/rest/auth/oauth2/v1/token`;

/**
 * @summary return netsuite api base url
 * @param accountID
 * @returns
 */
export const buildNetSuiteApiBaseUrl = (accountID: string): string =>
  `https://${accountID}.suitetalk.api.netsuite.com/services/rest/record/v1`;

/**
 * return newly generated access token for netsuite
 * @param credentials
 * @param environment
 */
export async function exchangeNetsuiteRefreshTokenForAccessToken(
  credentials: Credentials,
  { NETSUITE_CLIENT_ID }: Environment,
): Promise<TokenExchangeResponse> {
  try {
    const { data } = await axios.post(
      buildNetSuiteTokenUrl(credentials.NETSUITE_ACCOUNT_ID),
      generateQueryStringFromObject(
        {
          client_id: NETSUITE_CLIENT_ID,
          refresh_token: credentials.NETSUITE_REFRESH_TOKEN,
          grant_type: 'refresh_token',
        },
        {
          urlencodedValues: true,
          addPrefix: false,
        },
      ),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    return data;
  } catch (err) {
    throw handleResponseError(err, Action.NETSUITE);
  }
}

/**
 * @summary prepare payload for create or update records
 * @param recordType
 * @param dto
 * @returns
 */
export const preparePayload = (
  recordType: Omit<RecordType, RecordType.PAYMENT_TERMS>,
  dto:
    | CreateOrUpdateVendorDTO
    | CreateOrUpdateBillDTO
    | CreateOrUpdateAccountDTO
    | CreateOrUpdateTaxGroupDTO,
): PreparePayload => {
  switch (recordType) {
    case RecordType.VENDOR: {
      const {
        vendorId,
        companyName,
        subsidiary,
        isPerson,
        email,
        altEmail,
        defaultTaxReg,
        expenseAccount,
        terms,
        firstName,
        lastName,
        addr1,
        addr2,
        city,
        country,
        zip,
        vendorAdditionalFields,
      } = dto as CreateOrUpdateVendorDTO;
      const additionalFields = vendorAdditionalFields
        ? getArrayOfStringsFromCommaSeparatedList(vendorAdditionalFields).filter(
            (field: Object) => !field.hasOwnProperty('addressBookAddress'),
          )
        : [];
      return {
        recordId: vendorId,
        payload: Object.assign(
          filterEmptyProperties({
            companyName,
            subsidiary,
            ...(isPerson === 'individual'
              ? { isPerson: true, firstName, lastName }
              : { isPerson: false }),
            email,
            altEmail,
            defaultTaxReg,
            expenseAccount,
            terms,
            addressbook: {
              items: [
                {
                  addressBookAddress: {
                    ...(addr1 ? { addr1 } : {}),
                    ...(addr2 ? { addr2 } : {}),
                    ...(city ? { city } : {}),
                    ...(country ? { country } : {}),
                    ...(zip ? { zip } : {}),
                  },
                },
                ...(vendorAdditionalFields?.includes('addressBookAddress')
                  ? getArrayOfStringsFromCommaSeparatedList(vendorAdditionalFields).filter(
                      (field: Object) => field.hasOwnProperty('addressBookAddress'),
                    )
                  : []),
              ],
            },
          }),
          ...additionalFields,
        ),
      };
    }
    case RecordType.BILL: {
      const {
        billId,
        entity,
        tranDate,
        postingPeriod,
        subsidiary,
        tranId,
        dueDate,
        userTotal,
        approvalStatus,
        memo,
        itemsInput,
        billAdditionalFields,
      } = dto as CreateOrUpdateBillDTO;
      const additionalFields = billAdditionalFields
        ? getArrayOfStringsFromCommaSeparatedList(billAdditionalFields)
        : [];
      return {
        recordId: billId,
        payload: Object.assign(
          filterEmptyProperties({
            entity,
            tranDate,
            postingPeriod,
            subsidiary,
            tranId,
            dueDate,
            approvalStatus,
            userTotal: userTotal ? Number(userTotal) : '',
            memo,
            expense: {
              items: getArrayOfStringsFromCommaSeparatedList(itemsInput),
            },
          }),
          ...additionalFields,
        ),
      };
    }
    case RecordType.ACCOUNTS: {
      const {
        acctName,
        acctNumber,
        subsidiaries,
        parent,
        accountId,
        acctType,
        accountAdditionalFields,
      } = dto as CreateOrUpdateAccountDTO;
      const additionalFields = accountAdditionalFields
        ? getArrayOfStringsFromCommaSeparatedList(accountAdditionalFields)
        : [];
      return {
        recordId: accountId,
        payload: Object.assign(
          filterEmptyProperties({
            acctName,
            acctNumber,
            subsidiary: {
              items: subsidiaries
                ? [
                    {
                      id: subsidiaries,
                    },
                  ]
                : [],
            },
            acctType,
            parent,
          }),
          ...additionalFields,
        ),
      };
    }
    default: {
      const { itemId, taxCode, county, taxGroupId } = dto as CreateOrUpdateTaxGroupDTO;
      return {
        recordId: taxGroupId,
        payload: filterEmptyProperties({
          itemId,
          taxItem: {
            items: taxCode
              ? [
                  {
                    taxName: taxCode,
                  },
                ]
              : [],
          },
          county,
        }),
      };
    }
  }
};

/**
 * @summary refresh credentials if current credentials are expired
 * @param credential
 * @param environment
 * @returns
 */
export const refreshCredentialIfExpired = async (
  credential: Credentials,
  environment: Environment,
): Promise<Credentials | undefined> => {
  if (!(await isAccessTokenValid(getNetSuiteAccountBook, [credential]))) {
    const newCredentials = await exchangeNetsuiteRefreshTokenForAccessToken(
      credential,
      environment,
    );
    return {
      ...credential,
      NETSUITE_ACCESS_TOKEN: newCredentials.access_token,
      NETSUITE_REFRESH_TOKEN: newCredentials.refresh_token,
    };
  }
  return undefined;
};

/**
 * wrap intent method with refresh credential functionality
 * @param intentHandler
 * @returns
 */

export const withRefreshCredential = withRefreshCredentialHandling(refreshCredentialIfExpired);
