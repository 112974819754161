import { IncomingMessage } from 'http';

import { ValidationError } from '@nestjs/common';
import { getOrElse, none, some } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import Fuse from 'fuse.js';
import fetch from 'isomorphic-unfetch';
import throttle from 'lodash.throttle';
import moment from 'moment';
import { Prism, Traversal } from 'monocle-ts';
import { NextPageContext } from 'next';
import Router, { NextRouter } from 'next/router';
import { ThunkAction } from 'redux-thunk';
import { Stripe } from 'stripe';

import { ActionSteps, ConnectTriggerSteps, TriggerSteps } from '@shared/actions/sdk';
import {
  CachedConnectCredential,
  IConnectCredential,
  IConnectCredentialWithPersona,
} from '@shared/entities/sdk/credential/connectCredential.interface';
import { PersonaFilter, PersonaStatus } from '@shared/entities/sdk/persona/persona.interface';
import { IStep } from '@shared/entities/sdk/step/step.interface';
import { ITeamMember, TeamMemberRole } from '@shared/entities/sdk/team/team.interface';
import { ILoggedInUser } from '@shared/entities/sdk/user/user.interface';
import { IWorkflow } from '@shared/entities/sdk/workflow/workflow.interface';
import { debug } from '@shared/logger/sdk/legacy';
import { Action, ActionConfig, ActionStep, ActionTriggerStep } from '@shared/types/sdk/actions';
import { EventName, GoogleTagManagerEvent } from '@shared/types/sdk/analytics';
import { InvalidWorkflowDeploymentError, StepValidationError } from '@shared/types/sdk/errors';
import { WorkflowExecutionContext, WorkflowType } from '@shared/types/sdk/execution';
import {
  CompletedExecution,
  Condition,
  DataType,
  FanInStrategy,
  Operator,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import {
  CustomIntegrationRequestStep,
  StateMachine,
  Step,
  StepType,
  StepsThatDontRequireCredentials,
  Variables,
} from '@shared/types/sdk/steps';
import { BillingPlan, ConnectBillingPlan } from '@shared/types/sdk/stripe';
import { CoercedNestedData, NestedData, flattenVariables } from '@shared/ui/sdk/utils';
import { DEFAULT_TIMEZONE, verboseTimezones } from '@shared/utils/sdk';
import { Timezone } from '@shared/utils/sdk/timezones';
import {
  DATA_TYPE_COERCERS,
  getFanInStrategy,
  sortExecutionsByFanout,
} from '@shared/workflow/sdk/resolvers';
import {
  assertWorkflowDeployment,
  assertWorkflowStep,
} from '@shared/workflow/sdk/validators/validator.utils';
import { isNumberType } from '@shared/workflow/sdk/workflow.utils';

import { WorkFlowItemProp } from '../components/WorkflowDashboard/WorkFlows';
import {
  Dispatch,
  EditStepView,
  SidebarView,
  State,
  WorkflowEditorState,
  WorkflowEntity,
} from '../store/types';
import { STEP_TYPE_LABELS } from '../utils/constants';

import { getBillingPlanFromState } from './billing';
import config from './config';

const fromEntries = require('fromentries');

const { GOOGLE_TAG_MANAGER_ID } = config;

// TODO: remove serverGet
export function serverGet(
  _req: IncomingMessage | undefined,
  _path: string,
): ReturnType<typeof fetch> {
  throw new Error('serverGet > deprecated! use api.ts');
}

// TODO: remove serverPost
export function serverPost(
  _req: IncomingMessage | undefined,
  _path: string,
  _body: object,
): ReturnType<typeof fetch> {
  throw new Error('serverPost > deprecated! use api.ts');
}

/**
 * This transforms a validation errors object from a NestJS 400-type response into a
 * human-readable error message. Additional parsing may be used to pick out properties
 * (such as the field names where the error occurred)
 */
export function flattenValidationError(error: ValidationError[] | ValidationError): string {
  if (Array.isArray(error)) {
    return error
      .flatMap((error) => {
        return [error, flattenValidationError(error.children || [])].flatMap((error) =>
          typeof error === 'string' ? error : flattenValidationError(error),
        );
      })
      .filter((errorString: string) => errorString)
      .join(', ');
  }
  return Object.values(error.constraints || {}).join(', ');
}

export async function getErrorMessage(response: Response): Promise<string> {
  const contentType: string | null = response.headers?.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    const body = await response.json();
    if (typeof body.message === 'string') {
      return body.message;
    }

    if (response.status === 400 && Array.isArray(body.message)) {
      return flattenValidationError(body.message);
    }

    return JSON.stringify(body);
  }
  return response.text?.();
}

export function redirect(
  ctx: NextPageContext | null,
  path: string,
  nextPath: string | null = null,
): void {
  if (ctx && ctx.res) {
    ctx.res.writeHead(302, { Location: path }).end();
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    nextPath ? Router.push(nextPath, path) : Router.push(path);
  }
}

export function detectTimezone(): string | undefined {
  const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  debug('detectTimezone >', detectedTimezone);
  if (detectedTimezone) {
    const knownTimezone: Timezone | undefined = verboseTimezones.find(
      ({ tzCode }: Timezone) => tzCode === detectedTimezone,
    );
    if (knownTimezone) {
      return knownTimezone.tzCode;
    }
  }

  return DEFAULT_TIMEZONE?.tzCode;
}

export function formatTime(minutes: number): string {
  return moment().startOf('day').add(minutes, 'minutes').format('h:mma');
}

export function formatDatetime(date: Date): string {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatFrequency(frequency: number, singular: string, plural: string): string {
  return frequency === 1 ? singular : `${frequency.toLocaleString('en-US')} ${plural}`;
}

// https://github.com/gcanti/monocle-ts/issues/22#issuecomment-479763277
export const fromDiscriminatedUnion =
  <U>() =>
  <K extends keyof U, V extends U[K]>(key: K, value?: V): Prism<U, Extract<U, { [_ in K]: V }>> =>
    new Prism(
      // TODO assign parameter types - currently specifying even the correct ones for either
      // function causes a type error
      // tslint:disable-next-line:typedef
      (union) => (value == null || union[key] === value ? some(union) : (none as any)),
      // tslint:disable-next-line:typedef
      (s) => s,
    );

export function sidebarCloseable(workflow: WorkflowEditorState): boolean {
  return !workflow.sidebar.open || workflow.sidebar.view!.type !== 'TEST_WORKFLOW';
}

export type RouteElement =
  | {
      path: string;
      key: string;
      value?: string | string[];
    }
  | {
      key: string;
      value: string | string[];
    };

export type QueryParam = {
  key: string;
  value: string;
};

// getHref('workflow', [
//   { path: 'projects', key: 'projectId', value: 'foo' },
//   {
//     path: 'workflows',
//     key: 'workflowId',
//     value: 'bar',
//   },
//   {path: 'add', key: 'add'},
// ]);
// -> '/workflow?projectId=foo&workflowId=bar&add=true'
export function getHref(
  basePath: string,
  elements: RouteElement[] = [],
  query: QueryParam[] = [],
): string {
  const formattedElements = elements
    .map((element: RouteElement) =>
      'value' in element && element.value === undefined
        ? null
        : `${element.key}=${element.value || 'true'}`,
    )
    .filter((element: string | null) => !!element);

  const formattedQuery = query.map((element: QueryParam) => `${element.key}=${element.value}`);
  const params = [...formattedElements, ...formattedQuery].join('&');
  return `${basePath}?${params}`;
}

// getAs([
//   { path: 'projects', key: 'projectId', value: 'foo' },
//   {
//     path: 'workflows',
//     key: 'workflowId',
//     value: 'bar',
//   },
//   {path: 'add', key: 'add'},
// ]);
// -> '/projects/foo/workflows/bar/add'
export function getAs(elements: RouteElement[] = [], query: QueryParam[] = []): string {
  const basePath = elements
    .map((element: RouteElement) => {
      if ('path' in element) {
        return `/${element.path}${element.value ? `/${element.value}` : ''}`;
      }
      return `/${element.value}`;
    })
    .join('');
  const queryParams = query
    .map((element: QueryParam) => `${element.key}=${element.value}`)
    .join('&');
  return basePath + (queryParams ? `?${queryParams}` : '');
}

export function pushRoute(
  router: NextRouter,
  basePath: string,
  elements: RouteElement[],
  query: QueryParam[] = [],
  options?: {
    shallow: boolean;
  },
): Promise<boolean> {
  return router.push(getHref(basePath, elements, query), getAs(elements, query), {
    shallow: options?.shallow ?? true,
  });
}

export async function replaceRoute(
  router: NextRouter,
  basePath: string,
  elements: RouteElement[],
  query: QueryParam[] = [],
): Promise<void> {
  await router.replace(getHref(basePath, elements, query), getAs(elements, query), {
    shallow: true,
  });
}

/**
 * For routing within different states of the workflow editor. This function should only be called
 * from workflow editor contexts (where router.query.workflowId is _already_ defined) and will
 * throw otherwise.
 *
 * To route _to_ the workflow editor, use pushRoute or RoutedLink.
 *
 * @param elements The RouteElements, **excluding** classic/connect keys, `projectId`, and
 * `workflowId`. These elements will be inferred from the current router state.
 *
 * @example
 * // To reset the workflow editor state, pass an empty elements array:
 * pushRouteInWorkflowEditor(router, []);
 */
export function pushRouteInWorkflowEditor(
  router: NextRouter,
  elements: RouteElement[],
  query: QueryParam[] = [],
  options?: {
    shallow: boolean;
  },
  newWorkflowId?: string,
): Promise<boolean> {
  if (!router.query.workflowId) {
    throw new Error(`Cannot route within workflow editor from another route: ${router.pathname}`);
  }

  const inConnectProject = router.asPath.indexOf('/connect') > -1;
  const appType = inConnectProject ? 'connect' : 'classic';

  return pushRoute(
    router,
    `/${appType}/workflow`,
    [
      { path: appType, key: appType },
      { path: 'projects', key: 'projectId', value: router.query.projectId },
      {
        path: 'workflows',
        key: 'workflowId',
        value: newWorkflowId ? newWorkflowId : router.query.workflowId,
      },
      ...elements,
    ],
    query,
    options,
  );
}

export function redirectToStepId(router: NextRouter, stepId: string): void {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  pushRouteInWorkflowEditor(router, [{ path: 'steps', key: 'stepId', value: stepId }]);
}

export function redirectToWorkflow(router: NextRouter, workflowId: string): void {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  pushRoute(router, '/classic/workflow', [
    { path: 'classic', key: 'classic' },
    { path: 'projects', key: 'projectId', value: router.query.projectId },
    { path: 'workflows', key: 'workflowId', value: workflowId },
  ]);
}

export function getEditingStepId(sidebarView: SidebarView): string {
  return (sidebarView as EditStepView).stepId;
}

export function getTraversal<S, A>(traversable: Traversal<S, A>, s: S): A {
  return pipe(
    traversable.asFold().headOption(s),
    getOrElse((): A => {
      throw new Error('unreachable');
    }),
  );
}

export function indexBy<K extends keyof T, T>(key: K, array: T[]): Record<string, T> {
  return fromEntries(array.map((element: T) => [element[key], element]));
}

export function getDefaultCondition(): Condition {
  return {
    operator: Operator.None,
    variable: { dataType: DataType.ANY, type: 'VALUE', value: null },
  };
}

export function getNextDefaultKeyName(
  currentEntries: { key: string }[],
  defaultKeyPrefix: string,
): string {
  let key = defaultKeyPrefix;
  for (let i = 2; ; i++) {
    if (!currentEntries.find((entry: { key: string }) => entry.key === key)) {
      return key;
    }
    key = `${defaultKeyPrefix}_${i}`;
  }
}

export function getStepLabel(step: Step, customIntegrationTitle?: string): string {
  const label: string | undefined = STEP_TYPE_LABELS[step.type];
  if (step.type === StepType.CUSTOM_INTEGRATION_REQUEST && step.parameters.actionType) {
    const actionTitle: string | undefined =
      step.parameters.actionType !== Action.CUSTOM
        ? ActionSteps?.[step.parameters.actionType]?.title
        : customIntegrationTitle;
    return `${actionTitle || ''} Request`.trim();
  }
  if (label) {
    return label;
  }
  if (step.type === StepType.ACTION) {
    return ActionSteps[step.parameters.actionType]?.title ?? '';
  }
  if (step.type === StepType.ACTION_TRIGGER) {
    const { name = '' } =
      TriggerSteps.find(
        ({ actionType }: ActionConfig) => step.parameters.actionType === actionType,
      ) ||
      ConnectTriggerSteps[step.parameters.actionType] ||
      {};
    return name;
  }
  return 'Unknown';
}

export function getActionStepIcon(
  step: ActionStep | ActionTriggerStep | CustomIntegrationRequestStep,
): string {
  const isConnectIntegrationRequestStep =
    step.type === StepType.CUSTOM_INTEGRATION_REQUEST &&
    step.parameters.actionType &&
    step.parameters.actionType !== Action.CUSTOM;

  if (step.type === StepType.ACTION || isConnectIntegrationRequestStep) {
    return ActionSteps[step.parameters.actionType]?.icon ?? '';
  } else {
    const { icon = '' } =
      TriggerSteps.find(
        ({ actionType }: ActionConfig) => step.parameters.actionType === actionType,
      ) ||
      ConnectTriggerSteps[step.parameters.actionType] ||
      {};
    return icon;
  }
}

export type FlattenedVariables<T> = {
  strategy: FanInStrategy;
  flattenedVariables: CoercedNestedData<T>[];
};

/**
 * gets the flattened variable options for a step
 *
 * @param editingStep
 * @param variableStep
 * @param stateMachine
 * @param variables
 * @param dataType
 */
export function getFlattenedVariablesForStep<T extends DataType>(
  editingStep: Step,
  variableStep: Step,
  stateMachine: StateMachine,
  variables: WorkflowVariables,
  dataType: T,
): FlattenedVariables<T> {
  if (!editingStep || !variableStep) {
    return {
      strategy: FanInStrategy.SINGLE,
      flattenedVariables: [],
    };
  }

  const strategy: FanInStrategy = getFanInStrategy(editingStep, variableStep, stateMachine);
  const filteredExecutions: CompletedExecution[] = Object.values(variables)
    .filter((e: CompletedExecution) => e.stepId === variableStep.id)
    .sort(sortExecutionsByFanout);

  if (!filteredExecutions.length) {
    return {
      strategy,
      flattenedVariables: [],
    };
  }

  const STRINGIFIED_ARRAY_SEPERATOR: string = '__STRINGIFIED_ARRAY_SEPERATOR__';

  let flattened: CoercedNestedData<T>[];
  if (strategy === FanInStrategy.SINGLE || strategy === FanInStrategy.SINGLE_BY_FANOUT) {
    // if we're not fanning in multiple objects,
    // we can find the first instance of an execution and display its output normally
    flattened = flattenVariables(
      filterExecutionVariables<Variables>(filteredExecutions[filteredExecutions.length - 1].output),
    ).map(({ path, data }: NestedData) => ({
      path,
      data: typeof data == 'string' ? data : JSON.stringify(data),
      coercedData: dataType ? DATA_TYPE_COERCERS[dataType](data) : undefined,
    }));
  } else {
    // if we're fanning in multiple objects, we need to display the output as an array
    // so we'll need to go through each execution, build a map with the outputs
    // and at the very end combine each iteration
    flattened = Object.entries(
      filteredExecutions
        .map<Variables>((execution: CompletedExecution) =>
          filterExecutionVariables<Variables>(execution.output),
        )
        .reduce((squashed: Record<string, string[]>, v: Variables) => {
          flattenVariables(v).forEach(({ path, data }: NestedData) => {
            const formattedPath: string = path.join(STRINGIFIED_ARRAY_SEPERATOR);
            if (!squashed[formattedPath]) {
              squashed[formattedPath] = [];
            }
            squashed[formattedPath].push(typeof data == 'string' ? data : JSON.stringify(data));
          });

          return squashed;
        }, {}),
    ).map(([path, dataArray]: [string, string[]]) => ({
      path: path.split(STRINGIFIED_ARRAY_SEPERATOR),
      data: `[${dataArray.join(',')}]`,
      coercedData:
        dataArray.length && dataType ? DATA_TYPE_COERCERS[dataType](dataArray[0]) : undefined,
    }));
  }

  flattened = flattened.filter(
    ({ path }: NestedData) =>
      !(path.length === 1 && (path[0] === 'type' || path[0] === 'fanoutStack')),
  );

  return {
    strategy,
    flattenedVariables: flattened,
  };
}

export function formatDate(date: Date): string {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return `${monthNames[date.getMonth()]} ${date.getDate()},${date.getFullYear()}`;
}

export const mapTeamMembersToTeam = (
  teamMembers: ITeamMember[],
): Record<string, TeamMemberRole> => {
  return teamMembers.reduce(
    (
      teams: Record<string, TeamMemberRole>,
      member: { organizationId: string; role: TeamMemberRole },
    ) => ({
      ...teams,
      [member.organizationId]: member.role,
    }),
    {},
  );
};

export const getTimeSince = (date: Date): string => {
  const seconds: number = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let interval: number = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + ' years';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' months';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' days';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hours';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes';
  }

  return Math.floor(seconds) + ' seconds';
};

/**
 * deletes metadata stored in execution variables
 * this is to prevent them from being displayed in variable menus, test shelves, etc
 * @param input
 */
export function filterExecutionVariables<T = object>(input: T): T {
  function canFilter(item: any): boolean {
    return !['number', 'boolean', 'string', 'undefined'].includes(typeof item);
  }

  function _filter(item: any): T {
    if (!canFilter(item)) {
      return item;
    }

    if (item && 'type' in item) {
      delete item['type'];
    }
    if (item && 'fanoutStack' in item) {
      delete item['fanoutStack'];
    }
    if (item && 'truncated' in item) {
      delete item['truncated'];
    }

    return item;
  }

  if (!canFilter(input)) {
    return input;
  } else if (Array.isArray(input)) {
    const copy: T[] = [...input];
    // @ts-ignore
    return copy.map(_filter);
  } else {
    const copy: T = { ...input };
    return _filter(copy);
  }
}

export function isDuplicatable(step: Step): boolean {
  return step.type != StepType.IFELSE && step.type != StepType.MAP;
}

export function getStaticContentPath(path: string): string {
  const { CDN_PUBLIC_URL } = config;
  const cleanPath: string = path.startsWith('/') ? path.substr(1) : path;
  return `${CDN_PUBLIC_URL ? CDN_PUBLIC_URL : ''}/${cleanPath}`;
}

export function filterWithSearchInput(
  workFlowList: WorkFlowItemProp[],
  searchInput: string,
  workflowTagId?: string,
): WorkFlowItemProp[] {
  const fuse: Fuse<WorkFlowItemProp> = new Fuse(workFlowList, {
    keys: ['description'],
    includeScore: true,
    threshold: 0.3,
  });

  const searchResultArray: Fuse.FuseResult<WorkFlowItemProp>[] = fuse.search(searchInput.trim());

  const workflowSearchResult: WorkFlowItemProp[] =
    searchInput === '' ? workFlowList : searchResultArray.map((searchResult) => searchResult.item);

  return workflowTagId
    ? workflowSearchResult.filter((item) =>
        item.tags.map((item) => item.id).includes(workflowTagId),
      )
    : workflowSearchResult;
}

export function reorderArray<T>(arr: Array<T>, startIndex: number, endIndex: number): Array<T> {
  const result = Array.from(arr);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function getImpersonateParam(path: string): string {
  const queryParams = new URLSearchParams(
    encodeURIComponent(path.replace('/login?', '')).replace('%3D', '='),
  );
  return decodeURIComponent(queryParams.get('impersonate') || '');
}

/**@summary - show disabled actions or not according to user's email
 * @returns boolean
 * @param user
 */
export const mustShowDisabledActions = (user: ILoggedInUser): boolean =>
  user.email === 'demo@useparagon.com';

/**
 * @summary checks if the workflow is enabled
 * @param workflowId
 * @param connectCredentialsEntity entity object from redux store for connectCredentials
 * @param projectId
 * @returns boolean value
 */
export function isWorkflowEnabled(
  workflowId: string,
  connectCredentialsEntity: Record<string, IConnectCredential>,
  projectId: string,
): boolean {
  const isEnabled = Object.values(connectCredentialsEntity).some(
    (connectCredential: IConnectCredential) =>
      connectCredential.projectId === projectId &&
      connectCredential?.config?.configuredWorkflows &&
      connectCredential.config.configuredWorkflows[workflowId]?.enabled,
  );
  return isEnabled;
}

/**
 * check if current step can be executed (if it requires credential then return false else return true)
 * @param currentStep
 * @param workflowId
 * @param connectCredentialsEntity
 * @param projectId
 */
export function canExecuteCurrentStep(
  currentStep: Step | undefined | null,
  workflow: WorkflowEntity | undefined,
  connectCredentialsEntity: Record<string, IConnectCredential>,
): boolean {
  if (!currentStep || !workflow) {
    return false;
  } else if (StepsThatDontRequireCredentials.includes(currentStep.type)) {
    return true;
  }
  return Object.values(connectCredentialsEntity).some(
    (connectCredential: IConnectCredential) =>
      connectCredential.projectId === workflow.projectId &&
      connectCredential.integrationId === workflow.integrationId,
  );
}

/**
 * check if credentials are present for current integration
 * @returns
 */
export function canTestWorkflow(
  workflow?: WorkflowEntity,
  connectCredentialsEntity?: Record<string, IConnectCredential>,
): boolean {
  if (!workflow || !connectCredentialsEntity) {
    return false;
  }

  return Object.values(connectCredentialsEntity).some(
    (connectCredential: IConnectCredential) =>
      connectCredential.projectId === workflow.projectId &&
      connectCredential.integrationId === workflow.integrationId,
  );
}

/**
 * @summary Stripe.Plan contain amount in cents form so convert it to dollars
 * @param plan
 */
export const sanitizePlan = (plan: Stripe.Plan) => ({
  ...plan,
  amount: plan.amount ? plan.amount / 100 : 0,
});

/**
 *
 * @param amount
 */
export const formattedPrice = (amount: number | undefined): string => {
  if (!amount || !isNumberType(amount)) {
    return '';
  }
  return (Math.round(amount) != amount ? amount.toFixed(2) : amount).toLocaleString('en-us');
};

/**
 * plan name map to ui display plan name
 * as connect_enterprise plan should be visible as Enterprise olny
 */
export const ConnectPlansDisplayName: Record<ConnectBillingPlan, string> = {
  [BillingPlan.ConnectTrial]: 'Trial',
  [BillingPlan.ConnectBasic]: 'Basic',
  [BillingPlan.ConnectPro]: 'Pro',
  [BillingPlan.ConnectEnterprise]: 'Enterprise',
};

/**
 * wrapper for datalayer push
 * @param event
 * @param options
 */
export const pushGtmEvent = (event: EventName, options: GoogleTagManagerEvent) => {
  if (GOOGLE_TAG_MANAGER_ID) {
    //@ts-ignore
    window && window.dataLayer && window.dataLayer.push({ ...options, event });
  }
};

/**
 * this will add throttling to async action
 * @param action
 * @param wait
 * @param options
 * @returns
 */
export function throttleAction<
  TActionCreator extends (...args: any[]) => ThunkAction<any, any, any, any>,
>(
  action: TActionCreator,
  wait: number,
  options?: Parameters<typeof throttle>['2'],
): TActionCreator {
  const throttled = throttle(
    (dispatch: Dispatch, actionArgs: Parameters<TActionCreator>) => dispatch(action(...actionArgs)),
    wait,
    options,
  );

  const wrappedThunk =
    (...actionArgs: Parameters<TActionCreator>) =>
    (dispatch: Dispatch) =>
      throttled(dispatch, actionArgs);

  return wrappedThunk as unknown as TActionCreator;
}

export function sanitizeStep(step: Step): object {
  return {
    ...step,
    dateUpdated: undefined,
  };
}

/**
 * gets the cached connect credential used for the current workflow
 *
 * @export
 * @param {State} state
 * @returns {(CachedConnectCredential | undefined)}
 */
export function getCachedConnectCredentialFromState(
  state: State,
): CachedConnectCredential | undefined {
  const workflowId: string | undefined = state.navigation.workflowId;
  const workflow: WorkflowEntity | undefined = workflowId
    ? state.entities.workflows.entities[workflowId]
    : undefined;
  const connectCredential: IConnectCredentialWithPersona | undefined =
    workflow &&
    Object.values(state.entities.connectCredentials.entities).find(
      (credential: IConnectCredentialWithPersona) =>
        credential.integrationId === workflow.integrationId,
    );
  const cachedCredential: CachedConnectCredential | undefined =
    workflow?.integrationId && connectCredential
      ? {
          connectCredentialId: connectCredential.id,
          providerId: connectCredential.providerId,
          personaId: connectCredential.personaId,
          config: connectCredential.config,
          endUserId: connectCredential.persona.endUserId,
          providerData: connectCredential.providerData,
        }
      : undefined;
  return cachedCredential;
}

/**
 * it will assert single step and return error|undefined
 * @param step
 * @param state
 * @returns
 */
export function getStepValidationError(step: Step, state: State): StepValidationError | undefined {
  try {
    const project = state.entities.projects.entities[state.navigation.projectId!];
    const workflow = state.entities.workflows.entities[step.workflowId];
    const cachedCredential: CachedConnectCredential | null =
      getCachedConnectCredentialFromState(state) ?? null;
    const billingPlan: BillingPlan = getBillingPlanFromState(state);

    const previewPersona = state.entities.personas.previewPersona ?? { meta: {} };

    const context = {
      workflowId: workflow.id,
      projectId: project.id,
      integrationId: workflow.integrationId,
      workflowType: workflow.integrationId ? WorkflowType.CONNECT : WorkflowType.CLASSIC,
      stateMachine: state.workflowEditor.stateMachine,
      plan: billingPlan,
    } as WorkflowExecutionContext;

    assertWorkflowStep(
      step,
      {},
      cachedCredential,
      state.entities.variables.entities,
      context,
      previewPersona,
    );
  } catch (error) {
    if (error instanceof StepValidationError) {
      return error;
    }
  }
  return;
}

/**
 * it will assert all workflowsteps and return error | undefined
 * @param workflowId
 * @param state
 * @returns
 */
export function getWorkflowStepsError(
  workflowId: string,
  state: State,
): InvalidWorkflowDeploymentError | void {
  try {
    const workflow = state.entities.workflows.entities[workflowId];
    const steps = workflow.stepIds.map(
      (id: string) => state.entities.steps.entities[id],
    ) as IStep[];
    const project = state.entities.projects.entities[state.navigation.projectId!];
    const billingPlan: BillingPlan = getBillingPlanFromState(state);
    const secrets: Record<string, string> = Object.fromEntries(
      Object.entries(state.entities.secrets.entities).reduce((secretsArr, [_, secret]) => {
        return [...secretsArr, [secret.id, ''], [secret.hash, '']];
      }, []),
    );
    const cachedCredential: CachedConnectCredential | null =
      getCachedConnectCredentialFromState(state) ?? null;
    const previewPersona = state.entities.personas.previewPersona ?? { meta: {} };

    assertWorkflowDeployment(
      { ...(workflow as unknown as IWorkflow), steps },
      secrets,
      cachedCredential,
      state.entities.variables.entities,
      project,
      previewPersona,
      billingPlan,
    );
  } catch (error) {
    return error;
  }
}

/**
 * Creates the query paramters for the provided filter
 * @param filters: PersonaFilter
 * @returns URLSearchParams
 */
export function createPersonasFilterQueryParams(filters: PersonaFilter): URLSearchParams {
  const queryParams = new URLSearchParams();

  if (filters.integration) {
    queryParams.set('integration', filters.integration);
  }

  if (filters.status) {
    queryParams.set('status', filters.status);
  }

  if (filters.name) {
    queryParams.set('name', filters.name);
  }

  if (filters.dateLastActiveStart) {
    queryParams.set(
      'dateLastActiveStart',
      moment(filters.dateLastActiveStart).format('YYYY-MM-DD'),
    );
  }

  if (filters.dateLastActiveEnd) {
    queryParams.set('dateLastActiveEnd', moment(filters.dateLastActiveEnd).format('YYYY-MM-DD'));
  }

  return queryParams;
}

/**
 * Parse the query parameters
 *
 * NOTE: This excludes `after` from the filter since that's intended for the backend only.
 *
 * @returns filters: PersonaFilter
 */

export function parsePersonasFilterQueryParams(queryParams: URLSearchParams): PersonaFilter {
  const filters: PersonaFilter = {
    name: queryParams.get('name') ?? undefined,
    integration: queryParams.get('integration') ?? undefined,
  };

  const status = queryParams.get('status');
  if (status) {
    if (Object.values<string>(PersonaStatus).includes(status)) {
      filters.status = status as PersonaStatus;
    }
  }

  const dateLastActiveStart = queryParams.get('dateLastActiveStart');
  if (dateLastActiveStart) {
    filters.dateLastActiveStart = moment(dateLastActiveStart).toDate();
  }

  const dateLastActiveEnd = queryParams.get('dateLastActiveEnd');
  if (dateLastActiveEnd) {
    filters.dateLastActiveEnd = moment(dateLastActiveEnd).toDate();
  }

  return filters;
}
