import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'accounts.all'
  | 'accounts.read'
  | 'accounts.write'
  | 'accounts.delete'
  | 'opportunities.all'
  | 'opportunities.read'
  | 'opportunities.write'
  | 'opportunities.delete'
  | 'opportunityProspectRoles.read'
  | 'opportunityProspectRoles.write'
  | 'opportunityProspectRoles.all'
  | 'opportunityProspectRoles.delete'
  | 'opportunityStages.all'
  | 'opportunityStages.read'
  | 'opportunityStages.write'
  | 'opportunityStages.delete'
  | 'users.all'
  | 'users.read'
  | 'users.write'
  | 'users.delete'
  | 'webhooks.all'
  | 'webhooks.read'
  | 'webhooks.write'
  | 'webhooks.delete'
  | 'prospects.all'
  | 'prospects.read'
  | 'prospects.write'
  | 'prospects.delete'
  | 'sequences.read';

export const scopes: Record<ScopeName, ScopeValue> = {
  'accounts.all': {
    required: true,
    label: 'All Accounts',
    name: 'accounts.all',
    description: 'All accounts task for outreach',
  },
  'accounts.read': {
    required: true,
    label: 'Read Accounts',
    name: 'accounts.read',
    description: 'Get all account related information',
  },
  'accounts.write': {
    required: true,
    label: 'Write Accounts',
    name: 'accounts.write',
    description: 'Create accounts for outreach',
  },
  'accounts.delete': {
    required: false,
    label: 'Delete Accounts',
    name: 'accounts.delete',
    description: 'Delete account for outreach',
  },
  'opportunities.all': {
    required: true,
    label: 'All Opportunities',
    name: 'opportunities.all',
    description: 'Allows you to add, create and delete Opportunity',
  },
  'opportunities.read': {
    required: false,
    label: 'Read Opportunities',
    name: 'opportunities.read',
    description: 'Allows you to read opportunties',
  },
  'opportunities.write': {
    required: false,
    label: 'Write Opportunities',
    name: 'opportunities.write',
    description: 'Allows you to create opportunites',
  },
  'opportunities.delete': {
    required: false,
    label: 'Delete opportunities',
    name: 'opportunities.delete',
    description: 'Delete all the opportunities',
  },
  'opportunityProspectRoles.read': {
    required: false,
    label: 'Read Opportunity Prospect Roles',
    name: 'opportunityProspectRoles.read',
    description: 'Allows you to request Opportunity Prospect Roles',
  },
  'opportunityProspectRoles.write': {
    required: false,
    label: 'Write Opportunity Prospect Roles',
    name: 'opportunityProspectRoles.write',
    description: 'Allows you to create opportunity prospect roles',
  },
  'opportunityProspectRoles.all': {
    required: false,
    label: 'All Opportunity Prospect Roles',
    name: 'opportunityProspectRoles.all',
    description: 'Allows you to add, create and delete Opportunity Prospect Roles',
  },
  'opportunityProspectRoles.delete': {
    required: false,
    label: 'Delete Opportunity Prospect Roles',
    name: 'opportunityProspectRoles.delete',
    description: 'Allows you to remove Opportunity',
  },
  'opportunityStages.all': {
    required: false,
    label: 'All OpportunityStages',
    name: 'opportunityStages.all',
    description: 'Allows you to create, update and delete Opportunity Stages',
  },
  'opportunityStages.read': {
    required: true,
    label: 'Read OpportunityStages',
    name: 'opportunityStages.read',
    description: 'Allows you to request list of Opportunity Stages along with information',
  },
  'opportunityStages.write': {
    required: false,
    label: 'Write OpportunityStages',
    name: 'opportunityStages.write',
    description: 'Allows you to create Opportunity Stages',
  },
  'opportunityStages.delete': {
    required: false,
    label: 'Delete OpportunityStages',
    name: 'opportunityStages.delete',
    description: 'Allows you to remove Opportunity Stages',
  },
  'users.all': {
    required: false,
    label: 'All Users',
    name: 'users.all',
    description: 'Allows you to create, update and delete Users',
  },
  'users.read': {
    required: true,
    label: 'Read Users',
    name: 'users.read',
    description: 'Allows you to request list of Users along with information',
  },
  'users.write': {
    required: false,
    label: 'Write Users',
    name: 'users.write',
    description: 'Allows you to create Users',
  },
  'users.delete': {
    required: false,
    label: 'Delete Users',
    name: 'users.delete',
    description: 'Allows you to remove Users',
  },
  'webhooks.all': {
    required: false,
    label: 'All Webhooks',
    name: 'webhooks.all',
    description: 'Allows you to create, update and delete Webhooks',
  },
  'webhooks.read': {
    required: false,
    label: 'Read Webhooks',
    name: 'webhooks.read',
    description: 'Allows you to request list of Webhooks along with information',
  },
  'webhooks.write': {
    required: false,
    label: 'Write Webhooks',
    name: 'webhooks.write',
    description: 'Allows you to create Webhooks',
  },
  'webhooks.delete': {
    required: false,
    label: 'Delete Webhooks',
    name: 'webhooks.delete',
    description: 'Allows you to remove Webhooks',
  },
  'prospects.all': {
    required: true,
    label: 'All Prospects',
    name: 'prospects.all',
    description: 'Allows you to create, update and delete Prospects',
  },
  'prospects.read': {
    required: false,
    label: 'Read Prospects',
    name: 'prospects.read',
    description: 'Allows you to request list of Prospects along with information',
  },
  'prospects.write': {
    required: false,
    label: 'Write Prospects',
    name: 'prospects.write',
    description: 'Allows you to create Prospects',
  },
  'prospects.delete': {
    required: false,
    label: 'Delete Prospects',
    name: 'prospects.delete',
    description: 'Allows you to remove Prospects',
  },
  'sequences.read': {
    required: false,
    label: 'Delete Prospects',
    name: 'sequences.read',
    description: 'Allows you to remove Prospects',
  },
};

export const requiredScopes: ScopeName[] = [
  'accounts.all',
  'accounts.read',
  'accounts.write',
  'opportunities.all',
  'opportunityStages.read',
  'users.read',
  'prospects.all',
  'sequences.read',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Outreach',
  description: 'Access a user’s Outreach information.',
  authUrl: 'https://api.outreach.io/oauth/authorize',
  accessTokenUrl: 'https://api.outreach.io/oauth/token',
  iconSvg,
  clientIdLabel: 'Client id',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client Id',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/outreach`,
      documentationLinkLabel: 'How to obtain a client Key?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/outreach',
};
