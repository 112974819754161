import { ENVIRONMENT_VARIABLES } from '@shared/types/sdk/environment';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class ConfigError extends ParagonError {
  name: string = 'ConfigError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class MissingEnvironmentVariablesError extends ConfigError {
  name: string = 'MissingEnvironmentVariablesError';

  // TODO: limit `variables` type to array of `ENVIRONMENT_VARIABLES`
  constructor({ variables }: { variables: (ENVIRONMENT_VARIABLES | string)[] }) {
    super({
      code: ERROR_CODE.MISSING_ENVIRONMENT_VARIABLES_ERROR,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Missing server environment variables.',
      meta: {
        variables,
      },
    });
  }
}

export class OnboardingNotSupportedError extends ConfigError {
  name: string = 'OnboardingNotSupportedError';

  // TODO: limit `variables` type to array of `ENVIRONMENT_VARIABLES`
  constructor() {
    super({
      code: ERROR_CODE.ONBOARDING_NOT_SUPPORTED,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Onboarding is not supported in this installation of Paragon.',
      meta: {},
    });
  }
}

export class MissingCLICommandOptions extends ConfigError {
  constructor({ options }: { options: string[] }) {
    super({
      code: ERROR_CODE.MISSING_CLI_OPTIONS,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Missing CLI required options.',
      meta: {
        options,
      },
    });
  }
}
