import { Operator } from '@shared/types/sdk/resolvers';

export const LINEAR_API_BASE_URL: string = 'https://api.linear.app/graphql';

export const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.StringIsIn,
  Operator.StringIsNotIn,
  Operator.StringStartsWith,
  Operator.StringDoesNotStartWith,
  Operator.StringEndsWith,
  Operator.StringDoesNotEndWith,
  Operator.StringContains,
  Operator.StringDoesNotContain,
  Operator.StringGreaterThan,
  Operator.StringLessThan,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberLessThanOrEqualTo,
  Operator.NumberGreaterThan,
  Operator.NumberLessThan,
  Operator.DateTimeAfter,
  Operator.DateTimeBefore,
];
