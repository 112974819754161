import { Lens } from 'monocle-ts';

import { ITeam } from '@shared/entities/sdk/team/team.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<ITeam> = {
  entities: {},
  processing: false,
  errorMessage: undefined,
};

export function handler(
  state: EntitiesState<ITeam> = initialState,
  action: Action,
): EntitiesState<ITeam> {
  switch (action.type) {
    case 'TEAM_ENTITY_GET_START':
    case 'TEAM_ENTITY_UPDATE_START':
    case 'TEAM_ENTITY_CREATE_START':
      return {
        ...state,
        processing: true,
      };
    case 'TEAM_ENTITY_CREATE_FAILURE':
    case 'TEAM_ENTITY_UPDATE_FAILURE':
    case 'TEAM_ENTITY_GET_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'TEAM_ENTITY_GET_SUCCESS':
      return {
        ...state,
        entities: { ...indexBy('id', action.teams) },
        processing: false,
        errorMessage: undefined,
      };

    case 'TEAM_ENTITY_CREATE_SUCCESS':
      return {
        ...state,
        entities: { ...state.entities, ...indexBy('id', [action.team]) },
        processing: false,
        errorMessage: undefined,
      };

    case 'TEAM_ENTITY_UPDATE_SUCCESS':
      return {
        ...state,
        ...Lens.fromPath<EntitiesState<ITeam>>()(['entities', action.team.id]).modify(
          (team: ITeam) => ({
            ...team,
            ...action.team,
          }),
        )(state),
        processing: false,
        errorMessage: undefined,
      };
    default:
      return state;
  }
}
