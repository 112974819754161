import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  IntentInputDesignType,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { WorkflowType } from '@shared/types/sdk/execution';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { customObjectNameInput } from '../shared/defaultRecordTypes';
import { RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME, RecordType } from '../shared/types';

import { authInput } from './authConfig';

export const recordTypeInput: SidebarInput = {
  id: 'recordType',
  type: SidebarInputType.Enum,
  title: 'Record type',
  required: true,
  getValues: () => [
    {
      value: RecordType.COMPANY,
      label: 'Company',
    },
    {
      value: RecordType.CONTACTS,
      label: 'Contact',
    },
    {
      value: RecordType.DEALS,
      label: 'Deals',
    },
    {
      value: RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
      label: 'Custom Object',
    },
  ],
};

const triggerConfig: ActionConfig = {
  actionType: Action.HUBSPOT,
  name: 'HubSpot',
  description: 'Trigger when CRM records are created or updated in HubSpot',
  icon: iconSvg,
  provider: ProviderType.HUBSPOT,
  sidebarSections: [
    (
      _parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      return {
        inputs: workflowType === WorkflowType.CLASSIC ? [authInput] : [],
      };
    },
    (
      parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      if (parameters.credentials.length === 0 && workflowType === WorkflowType.CLASSIC) {
        return { inputs: [] };
      }
      return {
        inputs: [
          {
            id: 'intent',
            title: 'Choose a Trigger Event',
            type: SidebarInputType.Intent,
            designType: IntentInputDesignType.WorkflowSettingsDesign,
            icon: iconSvg,
            values: [
              {
                value: Intent.TRIGGER_RECORD_CREATED,
                label: 'Record Created',
                subtitle: 'Trigger when a new record is created in HubSpot',
              },
              {
                value: Intent.TRIGGER_RECORD_UPDATED,
                label: 'Record Updated',
                subtitle: 'Trigger when a record is updated in HubSpot',
              },
              {
                value: Intent.TRIGGER_RECORD_DELETED,
                label: 'Record Deleted',
                subtitle: 'Trigger when a record is deleted in HubSpot',
              },
            ],
          },
        ],
      };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (!parameters.intent) {
        return { inputs: [] };
      }
      return {
        inputs: [recordTypeInput],
      };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedRecordType = pickValueSourceByKey(
        parameters.actionParameters,
        'recordType',
      )?.value;

      return {
        inputs:
          selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
            ? [customObjectNameInput]
            : [],
      };
    },
  ],
};

export default triggerConfig;
