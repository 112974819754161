import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * parse filter condtion and return search query string
 * @param condition
 */
const parseCondition = (condition: ResolvedConditionWrapper) => {
  if (condition.type === 'JOIN') {
    const result = condition.conditions.map((condition: ResolvedConditionWrapper) =>
      parseCondition(condition),
    );
    return result.length > 1
      ? `(${result.join(condition.join === 'OR' ? ' OR ' : ' ')})`
      : result.join(condition.join === 'OR' ? ' OR ' : ' ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringContains:
        return `${innerCondition.variable}:\"${innerCondition.argument}\"`;
      case Operator.StringDoesNotContain:
        return `-${innerCondition.variable}:\"${innerCondition.argument}\"`;
      case Operator.StringStartsWith:
        return `${innerCondition.variable}:\"${innerCondition.argument}\"*`;
      case Operator.StringDoesNotStartWith:
        return `-${innerCondition.variable}:\"${innerCondition.argument}\"*`;
      case Operator.NumberLessThan:
        return `${innerCondition.variable}:<${innerCondition.argument}`;
      case Operator.NumberEquals:
        return `${innerCondition.variable}:${innerCondition.argument}`;
      case Operator.NumberGreaterThan:
        return `${innerCondition.variable}:>${innerCondition.argument}`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `${innerCondition.variable}:>=${innerCondition.argument}`;
      case Operator.NumberLessThanOrEqualTo:
        return `${innerCondition.variable}:<=${innerCondition.argument}`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable}:true`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable}:false`;
      case Operator.IsNotNull:
        return `${innerCondition.variable}:*`;
      case Operator.IsNull:
        return `-${innerCondition.variable}:*`;
      default:
        throw new Error(`${innerCondition.operator} not supported by Shopify`);
    }
  }
  return '';
};

/**
 * A ConditionSerializer to generate a shopify filter query
 * @param condition
 */
export default function conditionsToFilterFormula(condition: ResolvedConditionWrapper): string {
  const query: string = parseCondition(condition);
  return query;
}
