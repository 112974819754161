import { Dispatch } from 'redux';

import { IProject } from '@shared/entities/sdk/project/project.interface';
import { debug } from '@shared/logger/sdk/legacy';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { Action } from '../../types';

export const createProjects =
  (title: string, organizationId: string, teamId: string) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'PROJECT_ENTITIES_CREATION_STARTED' });

    try {
      const response = await api.post(
        `/projects?organizationId=${organizationId}&teamId=${teamId}`,
        {
          title,
        },
      );

      if (response.ok) {
        const projects: IProject[] = (await response.json()) as IProject[];
        dispatch({
          type: 'PROJECT_ENTITIES_CREATION_SUCCESS',
          projects,
        });
      } else {
        dispatch({
          type: 'PROJECT_ENTITIES_CREATION_FAILED',
          message: await getErrorMessage(response),
        });
      }
    } catch (e) {
      dispatch({
        type: 'PROJECT_ENTITIES_CREATION_FAILED',
        message: await getErrorMessage(e),
      });
    }
  };

export const getProjects = (teamId: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'PROJECTS_ENTITY_GET_STARTED' });

  const response = await api.get(`/projects?teamId=${teamId}`);
  debug('getProjects > response', response);

  if (response.ok) {
    const projects = await response.json();
    dispatch({ type: 'PROJECTS_ENTITY_GET_SUCCESS', projects });
  } else {
    dispatch({
      type: 'PROJECTS_ENTITY_GET_FAILED',
      message: await getErrorMessage(response),
    });
  }
};
