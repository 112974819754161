import { IntegrationConnectInput, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { CredentialKeys } from '../configs/config';
import { listBucketsKey } from '../shared/constants';

export const regionInput: SidebarInput = {
  id: CredentialKeys.AWS_REGION,
  title: 'AWS Region',
  placeholder: 'us-east-1',
  type: SidebarInputType.ValueText,
  defaultValue: 'us-east-1',
  required: false,
};

export const accessKeyIdInput: SidebarInput = {
  id: CredentialKeys.ACCESS_KEY_ID,
  type: SidebarInputType.ValueText,
  title: 'Access Key ID',
  subtitle:
    'We recommend using an access key with the minimum permissions necessary to access your S3.',
  placeholder: 'AKIAIOSFODNN7EXAMPLE',
  required: true,
};

export const secretAccessKeyInput: SidebarInput = {
  id: CredentialKeys.SECRET_ACCESS_KEY,
  type: SidebarInputType.ValueText,
  title: 'Secret Access Key',
  placeholder: 'wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY',
  required: true,
};

export const listBucketsInput: IntegrationConnectInput = {
  id: CredentialKeys.BUCKET,
  title: 'Choose a Amazon S3 bucket',
  placeholder: 'bucket',
  type: SidebarInputType.DynamicEnum,
  required: true,
  sourceType: listBucketsKey,
};
