import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export enum SalesforceStandardRecord {
  'Opportunity' = 'Opportunity',
  'Account' = 'Account',
  'Contact' = 'Contact',
  'Lead' = 'Lead',
  'Task' = 'Task',
}

export const defaultSalesforceFields: Record<
  SalesforceStandardRecord,
  (intent?: Intent) => SidebarInput[]
> = {
  Opportunity: (intent: Intent) => [
    {
      type: SidebarInputType.TextArea,
      id: 'field-Name',
      title: 'Name',
      subtitle:
        intent === Intent.CREATE_RECORD
          ? 'The Opportunity name. This field is required.'
          : 'The Opportunity name.',
      lines: 1,
      required: true,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-StageName',
      title: 'Stage',
      subtitle:
        'Defaults to the user’s first Opportunity Stage if not provided. Use Connect Portal Workflow Settings to allow users to select an Opportunity Stage.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-CloseDate',
      title: 'Close Date',
      subtitle: 'Defaults to 30 days from the current date if not provided.',
      placeholder: 'YYYY-MM-DD',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-AccountId',
      title: 'Account ID',
      subtitle: 'The Account that the Opportunity belongs to.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Amount',
      title: 'Amount',
      subtitle: 'Estimated total sale amount.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Description',
      title: 'Description',
      subtitle: 'A description of the Opportunity.',
      lines: 3,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-OwnerId',
      title: 'Owner ID',
      subtitle: 'The Salesforce user assigned to work on this Opportunity.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-NextStep',
      title: 'Next Step',
      subtitle: 'Description of next task in closing Opportunity.',
      lines: 1,
      required: false,
    },
  ],
  Account: (intent: Intent) => [
    {
      type: SidebarInputType.TextArea,
      id: 'field-Name',
      title: 'Account Name',
      subtitle:
        intent === Intent.CREATE_RECORD
          ? 'The Account name. This field is required.'
          : 'The Account name.',
      lines: 1,
      required: true,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-OwnerId',
      title: 'Owner ID',
      subtitle:
        'The Salesforce user assigned to this Account. Use Connect Portal Workflow Settings to allow users to select an Owner.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Website',
      title: 'Website',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Phone',
      title: 'Phone',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Description',
      title: 'Description',
      lines: 3,
      required: false,
    },
  ],
  Contact: (intent: Intent) => [
    {
      type: SidebarInputType.TextArea,
      id: 'field-FirstName',
      title: 'First Name',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-LastName',
      title: 'Last Name',
      subtitle: intent === Intent.CREATE_RECORD ? 'This field is required.' : '',
      lines: 1,
      required: true,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-accountId',
      title: 'Account ID',
      subtitle: 'The Account that the Contact belongs to.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Email',
      title: 'Email',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Title',
      title: 'Title',
      subtitle: 'Title of the contact, such as CEO or Vice President.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Description',
      title: 'Description',
      subtitle: 'A description of the Contact.',
      lines: 3,
      required: false,
    },
  ],
  Lead: (intent: Intent) => [
    {
      type: SidebarInputType.TextArea,
      id: 'field-FirstName',
      title: 'First Name',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-LastName',
      title: 'Last Name',
      subtitle: intent === Intent.CREATE_RECORD ? 'This field is required.' : '',
      lines: 1,
      required: true,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Company',
      title: 'Company',
      subtitle: intent === Intent.CREATE_RECORD ? 'This field is required.' : '',
      lines: 1,
      required: true,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Email',
      title: 'Email',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Phone',
      title: 'Phone',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Website',
      title: 'Website',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Title',
      title: 'Title',
      subtitle: 'Title of the contact, such as CEO or Vice President.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Status',
      title: 'Status',
      subtitle:
        'Defaults to the user’s first Lead Status if not provided. Use Connect Portal Workflow Settings to allow users to select an Lead Status.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-Description',
      title: 'Description',
      subtitle: 'A description of the Lead.',
      lines: 3,
      required: false,
    },
  ],
  Task: (intent: Intent) => [
    {
      type: SidebarInputType.TextArea,
      id: 'field-whatId',
      title: 'Related to ID',
      subtitle: 'The ID of the Account or Opportunity this Task is related to.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-whoId',
      title: 'Name ID',
      subtitle: 'The ID of the Contact or Lead this Task is related to.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-subject',
      title: 'Subject',
      lines: 1,
      required: true,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-activityDate',
      title: 'Activity Date',
      placeholder: 'YYYY-MM-DD',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-description',
      title: 'Description',
      subtitle: 'A description of the Task.',
      lines: 3,
      required: false,
    },
    ...(intent !== Intent.UPDATE_RECORD
      ? [
          {
            type: SidebarInputType.Enum,
            id: 'field-taskSubtype',
            title: 'Task Subtype',
            required: true,
            getValues: () => [
              {
                value: 'task',
                label: 'Task',
              },
              {
                value: 'email',
                label: 'Email',
              },
              {
                value: 'listEmail',
                label: 'List Email',
              },
              {
                value: 'call',
                label: 'Call',
              },
            ],
            defaultValue: 'task',
          } as SidebarInput,
        ]
      : []),
    {
      type: SidebarInputType.Enum,
      id: 'field-Status',
      title: 'Status',
      getValues: () => [
        {
          value: 'Not Started',
          label: 'Not Started',
        },
        {
          value: 'In Progress',
          label: 'In Progress',
        },
        {
          value: 'Completed',
          label: 'Completed',
        },
      ],
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-ownerId',
      title: 'Assigned To ID',
      placeholder: '{{settings.TaskAssignee}}',
      subtitle:
        'The Salesforce user assigned to this Task. Use Connect Portal Workflow Settings to allow users to select an Owner.',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-callDurationInSeconds',
      title: 'Call Duration',
      lines: 1,
      required: false,
    },
    {
      type: SidebarInputType.BooleanInput,
      id: 'field-isReminderSet',
      title: 'Reminder Set',
      required: false,
    },
    {
      type: SidebarInputType.TextArea,
      id: 'field-reminderDateTime',
      title: 'Reminder Date/Time',
      placeholder: '2019-09-15T02:06:58.147Z',
      lines: 1,
      required: false,
    },
  ],
};

export const defaultSalesforceFieldNames = Object.fromEntries(
  Object.entries(defaultSalesforceFields).map(
    ([recordType, inputsFactory]: [string, () => SidebarInput[]]) => {
      return [
        recordType,
        inputsFactory().map((input: SidebarInput) => input.id.slice('field-'.length)),
      ];
    },
  ),
) as Record<SalesforceStandardRecord, string[]>;
