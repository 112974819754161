import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { LinkWrapperType, RecordType } from '../shared/types';

export const nameInput = (
  subtitle: string,
  required: boolean,
  placeholder?: string,
): SidebarInput => {
  return {
    id: 'name',
    title: 'Name',
    type: SidebarInputType.TextArea,
    lines: 1,
    required,
    subtitle,
    placeholder,
  };
};

export const descriptionInput = (subtitle: string, required: boolean): SidebarInput => ({
  id: 'description',
  title: 'Description',
  type: SidebarInputType.TextArea,
  lines: 1,
  required,
  subtitle: (
    <>
      Specify a description for this {subtitle}. The format is a limited subset of HTML defined by{' '}
      <LinkWrapper
        href="https://developer.productboard.com/files/schema.xsd"
        clickableText="this
  XML schema."
      />
    </>
  ),
  placeholder: '<p>Custom <s>branding</s> for the agent and user portals.</p>',
});

export const typeInput: SidebarInput = {
  id: 'type',
  title: 'Type',
  type: SidebarInputType.EditableEnum,
  required: true,
  subtitle: 'Specify the type of this feature.',
  getValues: () => [
    { label: 'Feature', value: 'feature' },
    {
      label: 'Subfeature',
      value: 'subfeature',
    },
  ],
};

export const recordTypeInput = (record: RecordType): SidebarInput => ({
  id: 'recordType',
  title: 'Record Type',
  type: SidebarInputType.Text,
  required: false,
  overrideValue: record,
  hidden: true,
});

export const statusInput: SidebarInput = {
  id: 'status',
  title: 'Status',
  type: SidebarInputType.Code,
  useLightTheme: true,
  lines: 4,
  required: true,
  language: 'json',
  placeholder: `{
  "id": "00000000-0000-0000-0000-000000000000",
  "name": "In Progress"
}`,
  subtitle:
    'Specify the status of this feature. It is not possible to specify both ID and name, only one of them can be used.',
};

export const featureParentTypeInput = (required: boolean): SidebarInput => ({
  id: 'featureParentType',
  title: 'Parent Type',
  type: SidebarInputType.EditableEnum,
  required,
  subtitle: 'Specify the type of parent of the feature.',
  getValues: () => [
    { label: 'Feature', value: 'feature' },
    {
      label: 'Component',
      value: 'component',
    },
    { label: 'Product', value: 'product' },
  ],
});

export const componentParentTypeInput: SidebarInput = {
  id: 'componentParentType',
  title: 'Parent Type',
  type: SidebarInputType.EditableEnum,
  required: true,
  subtitle: 'Specify the type of parent of the component.',
  getValues: () => [
    {
      label: 'Component',
      value: 'component',
    },
    { label: 'Product', value: 'product' },
  ],
};

export const parentIdInput = (subtitle: string, required: boolean): SidebarInput => ({
  id: 'parentId',
  title: 'Parent ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required,
  subtitle,
  placeholder: '1d5112af-4195-43d2-9939-ddb9c3de1cd6',
});

export const ownerEmailInput = (subtitle: string): SidebarInput => ({
  id: 'email',
  title: 'Owner Email',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle,
  placeholder: 'user@domain.com',
});

export const featureIdInput = (subtitle: string): SidebarInput => {
  return {
    id: 'featureId',
    title: 'Feature ID',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
    subtitle,
    placeholder: '1d5112af-4195-43d2-9939-ddb9c3de1cd6',
  };
};

export const componentIdInput = (subtitle: string): SidebarInput => {
  return {
    id: 'componentId',
    title: 'Component ID',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
    subtitle,
    placeholder: '1d5112af-4195-43d2-9939-ddb9c3de1cd6',
  };
};

export const productIdInput: SidebarInput = {
  id: 'productId',
  title: 'Product ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Specify the product ID of the product to fetch.',
  placeholder: '1d5112af-4195-43d2-9939-ddb9c3de1cd6',
};

export const LinkWrapper: React.FC<LinkWrapperType> = ({
  clickableText,
  href,
}: LinkWrapperType) => (
  <a href={href} target="_blank" rel="noreferrer">
    {clickableText}
  </a>
);

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Features',
          items: [
            {
              value: Intent.CREATE_FEATURE,
              label: 'Create Feature',
            },
            {
              value: Intent.UPDATE_FEATURE,
              label: 'Update Feature',
            },
            {
              value: Intent.GET_FEATURE_BY_ID,
              label: 'Get Feature by ID',
            },
            {
              value: Intent.DELETE_FEATURE,
              label: 'Delete Feature',
            },
          ],
        },
        {
          title: 'Components',
          items: [
            {
              value: Intent.CREATE_COMPONENT,
              label: 'Create Component',
            },
            {
              value: Intent.UPDATE_COMPONENT,
              label: 'Update Component',
            },
            {
              value: Intent.GET_COMPONENT_BY_ID,
              label: 'Get Component by ID',
            },
          ],
        },
        {
          title: 'Products',
          items: [
            {
              value: Intent.GET_PRODUCT_BY_ID,
              label: 'Get Product by ID',
            },
          ],
        },
      ],
    },
  ],
};

export const featureInputs = (createFeatureIntent: boolean): SidebarInput[] => [
  recordTypeInput(RecordType.FEATURES),
  ...(!createFeatureIntent
    ? [featureIdInput('Specify the feature ID of the feature to update.')]
    : []),
  nameInput('Specify a name for this feature.', createFeatureIntent, 'My feature'),
  descriptionInput('feature', createFeatureIntent),
  ...(createFeatureIntent ? [typeInput] : []),
  createFeatureIntent ? statusInput : { ...statusInput, required: false },
  parentIdInput(
    'Specify the record ID of the parent entity of this feature. This can be either a feature ID, component ID, or product ID.',
    createFeatureIntent,
  ),
  featureParentTypeInput(createFeatureIntent),
  ownerEmailInput(
    'Specify the email of a member to own the feature. A member is a Productboard user that has access to a space.',
  ),
];

export const componentInputs = (createComponentIntent: boolean): SidebarInput[] => [
  recordTypeInput(RecordType.COMPONENTS),
  ...(!createComponentIntent
    ? [componentIdInput('Specify the component ID of the component to update.')]
    : []),
  nameInput('Specify the name of the component to create.', createComponentIntent),
  descriptionInput('component', createComponentIntent),
  ...(createComponentIntent
    ? [
        componentParentTypeInput,
        parentIdInput(
          'Specify the record ID of the parent entity of this feature. This can be either a component ID, or product ID.',
          createComponentIntent,
        ),
      ]
    : []),

  ownerEmailInput(
    'Specify the email of a member to own the component. A member is a Productboard user that has access to a space.',
  ),
];
