import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.CALENDLY;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'CALENDLY_TEST_CONNECTION',
  GET_EVENT_TYPES = 'CALENDLY_GET_EVENT_TYPES',
  GET_AVAILABLE_TIMES_FOR_EVENT_TYPE = 'CALENDLY_GET_AVAILABLE_TIMES_FOR_EVENT_TYPE',
  SEARCH_EVENTS = 'CALENDLY_SEARCH_EVENTS',
  GET_EVENT_BY_ID = 'CALENDLY_GET_EVENT_BY_ID',
  GET_EVENT_INVITEES = 'CALENDLY_GET_EVENT_INVITEES',
  CANCEL_EVENT = 'CALENDLY_CANCEL_EVENT',
  GET_ORGANIZATION = 'CALENDLY_GET_ORGANIZATION',

  TRIGGER_INVITEE_CREATED = 'CALENDLY_TRIGGER_INVITEE_CREATED',
  TRIGGER_INVITEE_CANCELED = 'CALENDLY_TRIGGER_INVITEE_CANCELED',
}

type IntentMap =
  | Intent.TEST_CONNECTION
  | Intent.GET_EVENT_TYPES
  | Intent.GET_AVAILABLE_TIMES_FOR_EVENT_TYPE
  | Intent.SEARCH_EVENTS
  | Intent.GET_EVENT_BY_ID
  | Intent.GET_EVENT_INVITEES
  | Intent.CANCEL_EVENT
  | Intent.TRIGGER_INVITEE_CREATED
  | Intent.TRIGGER_INVITEE_CANCELED
  | Intent.GET_ORGANIZATION
  | OAuthIntent.GET_AUTH_ESSENTIALS
  | OAuthIntent.EXCHANGE_AUTH_CREDENTIALS
  | ConnectIntent.GET_PROVIDER_ID
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  ACCESS_TOKEN = 'CALENDLY_ACCESS_TOKEN',
  REFRESH_TOKEN = 'CALENDLY_REFRESH_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
};
