import React, { useContext } from 'react';
import styled from 'styled-components';

import ContentCdnPathContext from '../utils/ContentCdnPathContext';

export type IconProps = {
  className?: string;
  height?: number;
  name: string;
  width?: number;
  muted?: boolean;
} & React.HTMLAttributes<HTMLImageElement>;

export type IconPropsSrc = IconProps & {
  name: 'svg' | 'absoluteUrl' | 'png';
  src: string;
};

const IconRoot = styled.img<IconProps>`
  width: ${(props: IconProps) => (props.width == undefined ? 'auto' : `${props.width}px`)};
  height: ${(props: IconProps) => (props.height == undefined ? 'auto' : `${props.height}px`)};
  filter: ${(props: IconProps) => (props.muted == undefined || props.muted ? '' : 'grayScale(1)')};
`;

const Icon: React.FC<IconProps | IconPropsSrc> = React.forwardRef(
  (props: IconProps | IconPropsSrc, ref: React.Ref<HTMLImageElement>) => {
    const baseContentPath = useContext(ContentCdnPathContext) || '';

    const formatImageSource = (): string => {
      switch (props.name) {
        case 'svg':
          return `data:image/svg+xml;utf8,${encodeURIComponent((props as IconPropsSrc).src)}`;
        case 'absoluteUrl':
          return (props as IconPropsSrc).src;
        case 'png':
          return `${baseContentPath}/images/${(props as IconPropsSrc).src}.png`;
        default:
          return `${baseContentPath}/images/icons/${props.name}.svg`;
      }
    };

    return (
      <IconRoot
        data-testid={'icon-root'}
        ref={ref}
        {...props}
        src={formatImageSource()}
        alt={`${props.name} icon`}
      />
    );
  },
);

export default Icon;
