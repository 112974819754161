export default `<svg width="97" height="90" viewBox="0 0 97 90" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M75.7325 47.3578C64.107 47.3578 54.6843 56.7809 54.6843 68.4053C54.6843 80.0305 64.107 89.4543 75.7325 89.4543C87.3566 89.4543 96.78 80.0305 96.78 68.4053C96.78 56.7809 87.3566 47.3578 75.7325 47.3578ZM21.0479 47.3593C9.42379 47.3593 0 56.7809 0 68.4064C0 80.0305 9.42379 89.4543 21.0479 89.4543C32.6727 89.4543 42.0968 80.0305 42.0968 68.4064C42.0968 56.7809 32.6727 47.3593 21.0479 47.3593ZM69.4373 21.0475C69.4373 32.672 60.0147 42.0972 48.3906 42.0972C36.7654 42.0972 27.3423 32.672 27.3423 21.0475C27.3423 9.4238 36.7654 0 48.3906 0C60.0147 0 69.4373 9.4238 69.4373 21.0475Z" fill="url(#paint0_radial)"/>
<defs>
<radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(48.39 48.8887) scale(64.1297 59.2754)">
<stop stop-color="#FFB900"/>
<stop offset="0.6" stop-color="#F95D8F"/>
<stop offset="0.9991" stop-color="#F95353"/>
</radialGradient>
</defs>
</svg>`;
