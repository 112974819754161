import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.DYNAMICS_BUSINESS_CENTRAL;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'DYNAMICS_BUSINESS_CENTRAL_TEST_CONNECTION',
  GET_COMPANIES = 'DYNAMICS_BUSINESS_CENTRAL_GET_COMPANIES',
  GET_ENVIRONMENT = 'DYNAMICS_BUSINESS_CENTRAL_GET_ENVIRONMENT',
  CREATE_VENDOR = 'DYNAMICS_BUSINESS_CENTRAL_CREATE_VENDOR',
  UPDATE_VENDOR = 'DYNAMICS_BUSINESS_CENTRAL_UPDATE_VENDOR',
  GET_VENDOR_BY_ID = 'DYNAMICS_BUSINESS_CENTRAL_GET_VENDOR_BY_ID',
  SEARCH_VENDOR = 'DYNAMICS_BUSINESS_CENTRAL_SEARCH_VENDOR',
  DELETE_VENDOR = 'DYNAMICS_BUSINESS_CENTRAL_DELETE_VENDOR',
  CREATE_PURCHASE_INVOICE = 'DYNAMICS_BUSINESS_CENTRAL_CREATE_PURCHASE_INVOICE',
  UPDATE_PURCHASE_INVOICE = 'DYNAMICS_BUSINESS_CENTRAL_UPDATE_PURCHASE_INVOICE',
  POST_PURCHASE_INVOICE = 'DYNAMICS_BUSINESS_CENTRAL_POST_PURCHASE_INVOICE',
  GET_PURCHASE_INVOICE_BY_ID = 'DYNAMICS_BUSINESS_CENTRAL_GET_PURCHASE_INVOICE_BY_ID',
  SEARCH_FOR_PURCHASE_INVOICE = 'DYNAMICS_BUSINESS_CENTRAL_SEARCH_FOR_PURCHASE_INVOICE',
  DELETE_PURCHASE_INVOICE = 'DYNAMICS_BUSINESS_CENTRAL_DELETE_PURCHASE_INVOICE',
  CREATE_PURCHASE_INVOICE_LINE_ITEM = 'DYNAMICS_BUSINESS_CENTRAL_CREATE_PURCHASE_INVOICE_LINE_ITEM',
  UPDATE_PURCHASE_INVOICE_LINE_ITEM = 'DYNAMICS_BUSINESS_CENTRAL_UPDATE_PURCHASE_INVOICE_LINE_ITEM',
  GET_PURCHASE_INVOICE_LINES = 'DYNAMICS_BUSINESS_CENTRAL_GET_PURCHASE_INVOICE_LINES',
  GET_PURCHASE_INVOICE_LINE_ITEM_BY_ID = 'DYNAMICS_BUSINESS_CENTRAL_GET_PURCHASE_INVOICE_LINE_ITEM_BY_ID',
  SEARCH_PURCHASE_INVOICE_LINE_ITEM = 'DYNAMICS_BUSINESS_CENTRAL_SEARCH_PURCHASE_INVOICE_LINE_ITEM',
  DELETE_PURCHASE_INVOICE_LINE_ITEM = 'DYNAMICS_BUSINESS_CENTRAL_DELETE_PURCHASE_INVOICE_LINE_ITEM',
  GET_ACCOUNTS = 'DYNAMICS_BUSINESS_CENTRAL_GET_ACCOUNTS',
  GET_ACCOUNT_BY_ID = 'DYNAMICS_BUSINESS_CENTRAL_GET_ACCOUNT_BY_ID',
  CREATE_TAX_GROUP = 'DYNAMICS_BUSINESS_CENTRAL_CREATE_TAX_GROUP',
  UPDATE_TAX_GROUP = 'DYNAMICS_BUSINESS_CENTRAL_UPDATE_TAX_GROUP',
  GET_TAX_GROUP_BY_ID = 'DYNAMICS_BUSINESS_CENTRAL_GET_TAX_GROUP_BY_ID',
  SEARCH_TAX_GROUP = 'DYNAMICS_BUSINESS_CENTRAL_SEARCH_TAX_GROUP',
  DELETE_TAX_GROUP = 'DYNAMICS_BUSINESS_CENTRAL_DELETE_TAX_GROUP',
  CREATE_PAYMENT_TERM = 'DYNAMICS_BUSINESS_CENTRAL_CREATE_PAYMENT_TERM',
  UPDATE_PAYMENT_TERM = 'DYNAMICS_BUSINESS_CENTRAL_UPDATE_PAYMENT_TERM',
  GET_PAYMENT_TERM_BY_ID = 'DYNAMICS_BUSINESS_CENTRAL_GET_PAYMENT_TERM_BY_ID',
  SEARCH_PAYMENT_TERM = 'DYNAMICS_BUSINESS_CENTRAL_SEARCH_PAYMENT_TERM',
  DELETE_PAYMENT_TERM = 'DYNAMICS_BUSINESS_CENTRAL_DELETE_PAYMENT_TERM',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_VENDOR_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PURCHASE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_PURCHASE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.POST_PURCHASE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PURCHASE_INVOICE_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_FOR_PURCHASE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_PURCHASE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PURCHASE_INVOICE_LINE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_PURCHASE_INVOICE_LINE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PURCHASE_INVOICE_LINE_ITEM_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PURCHASE_INVOICE_LINES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_PURCHASE_INVOICE_LINE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_PURCHASE_INVOICE_LINE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ACCOUNTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ACCOUNT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_TAX_GROUP;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_TAX_GROUP;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TAX_GROUP_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_TAX_GROUP;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_TAX_GROUP;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PAYMENT_TERM_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PAYMENT_TERM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_PAYMENT_TERM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_PAYMENT_TERM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_PAYMENT_TERM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_COMPANIES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ENVIRONMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  CLIENT_ID = 'DYNAMICS_BUSINESS_CENTRAL_CLIENT_ID',
  CLIENT_SECRET = 'DYNAMICS_BUSINESS_CENTRAL_CLIENT_SECRET',
  ACCESS_TOKEN = 'DYNAMICS_BUSINESS_CENTRAL_ACCESS_TOKEN',
  REFRESH_TOKEN = 'DYNAMICS_BUSINESS_CENTRAL_REFRESH_TOKEN',
  SCOPES = 'DYNAMICS_BUSINESS_CENTRAL_SCOPES',
  ENVIRONMENT = 'mainInput',
  COMPANY_ID = 'dependentInput',
}

export type UserAccountFields = {
  [CredentialKeys.COMPANY_ID]: string;
  [CredentialKeys.ENVIRONMENT]: string;
};

export type Credentials = {
  userAccountId: string; //using this in getProvider for user id
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
  userAccountFields: UserAccountFields;
};
