import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  callIDInput,
  callStartDateInput,
  clientUniqueIDInput,
  directionInput,
  downloadMediaURLInput,
  durationInput,
  endDate,
  intentTypeInput,
  partiesInput,
  primaryUserInput,
  purposeInput,
  startDate,
  titleInput,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.GONG,
  name: 'Gong',
  description: `Connect to your users' Gong accounts`,
  icon: iconSvg,
  provider: ProviderType.GONG,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.ADD_CALL:
          return {
            inputs: [
              callStartDateInput,
              clientUniqueIDInput,
              directionInput,
              partiesInput,
              primaryUserInput,
              titleInput,
              downloadMediaURLInput,
              purposeInput,
              durationInput,
            ],
          };
        case Intent.GET_CALL_BY_ID:
          return {
            inputs: [callIDInput],
          };
        case Intent.SEARCH_CALL:
          return {
            inputs: [startDate, endDate],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };
export default connectConfig;
