import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'com.intuit.quickbooks.accounting'
  | 'com.intuit.quickbooks.payment'
  | 'com.intuit.quickbooks.payroll'
  | 'com.intuit.quickbooks.payroll.timetracking'
  | 'openid'
  | 'profile'
  | 'phone'
  | 'address'
  | 'email';

export const scopes: Record<ScopeName, ScopeValue> = {
  'com.intuit.quickbooks.accounting': {
    name: 'com.intuit.quickbooks.accounting',
    description: 'QuickBooks Online API',
  },
  'com.intuit.quickbooks.payment': {
    name: 'com.intuit.quickbooks.payment',
    description: 'QuickBooks Payments API',
  },
  'com.intuit.quickbooks.payroll': {
    name: 'com.intuit.quickbooks.payroll',
    description: 'QuickBooks Payroll API (whitelisted beta apps only)',
  },
  'com.intuit.quickbooks.payroll.timetracking': {
    name: 'com.intuit.quickbooks.payroll.timetracking',
    description:
      'QuickBooks Payroll API for for access to compensation (whitelisted beta apps only)',
  },
  openid: {
    name: 'openid',
    description: 'OpenID Connect processing',
  },
  profile: {
    name: 'profile',
    description: 'user’s given and family names',
  },
  email: {
    name: 'email',
    description: 'user’s email address',
  },
  phone: {
    name: 'phone',
    description: 'user’s phone number',
  },
  address: {
    name: 'address',
    description: 'user’s physical address',
  },
};

export const requiredScopes: ScopeName[] = ['openid', 'email', 'com.intuit.quickbooks.accounting'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'QuickBooks',
  description: 'Access a user’s QuickBooks customers, payments, and more.',
  authUrl: 'https://appcenter.intuit.com/app/connect/oauth2/companyselection',
  accessTokenUrl: 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'prodClientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/quickbooks`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'prodClientSecret' },
    {
      type: SidebarInputType.ValueText,
      title: 'Sandbox Client ID',
      id: 'sandboxClientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/quickbooks`,
      documentationLinkLabel: 'How to obtain a Sandbox Client ID?',
      placeholder: 'Optional',
      required: false,
    },
    {
      type: SidebarInputType.ValueText,
      title: 'Sandbox Client Secret',
      id: 'sandboxClientSecret',
      placeholder: 'Optional',
      required: false,
    },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink:
    'https://developer.intuit.com/app/developer/qbo/docs/develop/authentication-and-authorization/oauth-2.0',
};
