export default `<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2536_2770)">
<mask id="mask0_2536_2770" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6" y="0" width="34" height="46">
<path d="M24.875 2.83093C24.875 1.7954 25.7145 0.955933 26.75 0.955933H38C39.0355 0.955933 39.875 1.7954 39.875 2.83093V44.0809C39.875 45.1164 39.0355 45.9559 38 45.9559H8C6.96448 45.9559 6.125 45.1164 6.125 44.0809V25.3309C6.125 24.2954 6.96448 23.4559 8 23.4559H15.5V14.0809C15.5 13.0454 16.3395 12.2059 17.375 12.2059H24.875V2.83093Z" fill="white"/>
</mask>
<g mask="url(#mask0_2536_2770)">
<path d="M39.875 0.955933V45.9559H24.875V0.955933H39.875Z" fill="url(#paint0_linear_2536_2770)"/>
<g filter="url(#filter0_f_2536_2770)">
<path d="M30.5 14.2684V46.1434H15.5V12.3934H28.625C29.6605 12.3934 30.5 13.2329 30.5 14.2684Z" fill="black" fill-opacity="0.2"/>
</g>
<g filter="url(#filter1_f_2536_2770)">
<path d="M30.5 15.0184V46.8934H15.5V13.1434H28.625C29.6605 13.1434 30.5 13.9829 30.5 15.0184Z" fill="black" fill-opacity="0.18"/>
</g>
<path d="M30.5 14.0809V45.9559H15.5V12.2059H28.625C29.6605 12.2059 30.5 13.0454 30.5 14.0809Z" fill="url(#paint1_linear_2536_2770)"/>
<path d="M6.125 23.4559V45.9559H21.125V25.3309C21.125 24.2954 20.2855 23.4559 19.25 23.4559H6.125Z" fill="url(#paint2_linear_2536_2770)"/>
</g>
</g>
<defs>
<filter id="filter0_f_2536_2770" x="14.7" y="11.5934" width="16.6" height="35.35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_2536_2770"/>
</filter>
<filter id="filter1_f_2536_2770" x="7.5" y="5.14343" width="31" height="49.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_2536_2770"/>
</filter>
<linearGradient id="paint0_linear_2536_2770" x1="22.375" y1="0.955932" x2="42.1556" y2="42.884" gradientUnits="userSpaceOnUse">
<stop stop-color="#E6AD10"/>
<stop offset="1" stop-color="#C87E0E"/>
</linearGradient>
<linearGradient id="paint1_linear_2536_2770" x1="15.4988" y1="12.2059" x2="32.134" y2="44.4682" gradientUnits="userSpaceOnUse">
<stop stop-color="#F6D751"/>
<stop offset="1" stop-color="#E6AD10"/>
</linearGradient>
<linearGradient id="paint2_linear_2536_2770" x1="6.12378" y1="23.4559" x2="15.3164" y2="45.2423" gradientUnits="userSpaceOnUse">
<stop stop-color="#F9E589"/>
<stop offset="1" stop-color="#F6D751"/>
</linearGradient>
<clipPath id="clip0_2536_2770">
<rect width="45" height="45" fill="white" transform="translate(0.5 0.955933)"/>
</clipPath>
</defs>
</svg>
`;
