import { ExecutionStatus } from '@shared/types/sdk/steps';

import { Action, WorkflowExecutionState } from '../types';

export const initialState: WorkflowExecutionState = {
  processing: false,
  processingLog: false,
  stepsData: {},
  path: [],
};

export function handler(
  state: WorkflowExecutionState = initialState,
  action: Action,
): WorkflowExecutionState {
  switch (action.type) {
    case 'CLEAR_WORKFLOW_EXECUTION':
    case 'DEACTIVATE_SIDEBAR':
      return initialState;
    case 'WORKFLOW_EXECUTION_STEP_DATA_GET_START':
      return {
        ...state,
        processingLog: true,
        errorMessage: undefined,
      };
    case 'WORKFLOW_EXECUTION_GET_START':
    case 'WORKFLOW_EXECUTION_PAUSE_START':
    case 'WORKFLOW_EXECUTION_RESUME_START':
    case 'WORKFLOW_EXECUTION_REPLAY_START':
    case 'WORKFLOW_EXECUTION_GET_STEP_EXECUTION_START':
      return {
        ...state,
        processing: true,
        errorMessage: undefined,
      };
    case 'WORKFLOW_EXECUTION_GET_SUCCESS':
      return {
        ...state,
        processing: false,
        workflowExecution: action.workflowExecution,
        path: action.workflowExecution.path,
      };
    case 'WORKFLOW_EXECUTION_GET_STEP_EXECUTION_SUCCESS':
      return {
        ...state,
        workflowExecution: {
          ...state.workflowExecution!,
          steps: [...state.workflowExecution?.steps!, ...action.executions],
        },
      };
    case 'WORKFLOW_EXECUTION_STEP_DATA_GET_FAILED':
      return {
        ...state,
        processingLog: false,
        errorMessage: action.message,
      };
    case 'WORKFLOW_EXECUTION_GET_FAILED':
    case 'WORKFLOW_EXECUTION_RESUME_FAILED':
    case 'WORKFLOW_EXECUTION_PAUSE_FAILED':
    case 'WORKFLOW_EXECUTION_GET_STEP_EXECUTION_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'WORKFLOW_EXECUTION_STEP_DATA_GET_SUCCESS':
      return {
        ...state,
        processingLog: false,
        stepsData: {
          ...state.stepsData,
          [action.stepId]: action.stepData,
        },
      };
    case 'WORKFLOW_EXECUTION_PAUSE_SUCCESS':
      return {
        ...state,
        processing: false,
        workflowExecution: {
          ...state.workflowExecution!,
          status: ExecutionStatus.PAUSED,
        },
      };
    case 'WORKFLOW_EXECUTION_RESUME_SUCCESS':
      return {
        ...state,
        processing: false,
        workflowExecution: {
          ...state.workflowExecution!,
          status: ExecutionStatus.DELAYED,
        },
      };
    case 'WORKFLOW_EXECUTION_REPLAY_SUCCESS':
      return {
        ...state,
        processing: false,
        workflowExecution: {
          ...state.workflowExecution!,
          status: ExecutionStatus.EXECUTING,
          steps: [],
        },
      };
    case 'WORKFLOW_EXECUTION_REPLAY_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
        workflowExecution: {
          ...state.workflowExecution!,
          status: ExecutionStatus.FAILED,
          steps: [],
        },
      };
    case 'CLEAR_WORKFLOW_EXECUTION':
      return {
        ...state,
        workflowExecution: undefined,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
