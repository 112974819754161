import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs/config';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.FRESHDESK,
  provider: ProviderType.FRESHDESK,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Freshdesk account',
  icon: iconSvg,
  description:
    'Increase your team’s productivity by keeping your Freshdesk account up to date - without manual data entry.',
  sidebarSections: [
    {
      inputs: [
        {
          type: SidebarInputType.ValueText,
          id: CredentialKeys.DOMAIN,
          title: 'Domain',
          subtitle: 'Please specify the domain of your Freshdesk account',
          placeholder: 'domain.freshdesk.com',
          required: true,
        },
        {
          type: SidebarInputType.ValueText,
          id: CredentialKeys.API_KEY,
          title: 'API Key',
          subtitle: 'Please specify the API key of your Freshdesk account',
          placeholder: 's1OrurABcoS51sE3Ner',
          required: true,
        },
      ],
    },
  ],
};

export default authConfig;
