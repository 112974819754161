import { DataType } from '../resolvers';

function letterCase(word: string): string {
  return word.length > 0 ? `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}` : word;
}

/**
 * Create a human-readable phrase for the coercion, i.e. "from String to Any"
 */
function createCoercionPhrase(value: any, attemptedCoercionType: DataType): string {
  return `from ${letterCase(typeof value)} to ${letterCase(attemptedCoercionType)}`;
}

export class TypeCoercionError extends Error {
  name: string = 'TypeCoercionError';

  constructor(value: any, attemptedCoercionType: DataType, path?: string[]) {
    super(
      `Could not coerce ${
        path ? `${path.join('.')} (${String(value)})` : String(value)
      } ${createCoercionPhrase(value, attemptedCoercionType)}`,
    );
  }
}
