import React from 'react';

import { Action, SidebarSection } from '@shared/types/sdk/actions';
import { DataType, KeyedSource, Source } from '@shared/types/sdk/resolvers';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  LinkWrapper,
  additionalFieldsInput,
  addressesInput,
  applicationIdInput,
  applicationTypeInput,
  applicationsInput,
  candidateIdInput,
  companyInput,
  customFieldsInput,
  emailAddressesInput,
  firstNameInput,
  intentTypeInput,
  internalOpeningIdInput,
  jobIdInput,
  lastNameInput,
  openingIdInput,
  openingIdsInput,
  openingsInput,
  phoneNumbersInput,
  referrerInput,
  sourceIdInput,
  statusInput,
  titleInput,
  websiteAddressesInput,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.GREENHOUSE,
  name: 'Greenhouse',
  description: 'Access and update data in Greenhouse.',
  icon: iconSvg,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_APPLICATION:
          return {
            inputs: [applicationTypeInput],
          };

        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const applicationTypeSource: Source<DataType.ANY> | undefined =
        parameters.actionParameters.find(
          (keySource: KeyedSource<DataType.ANY>) => keySource.key === 'applicationType',
        )?.source;

      const isCandidateApplicationValue: boolean =
        (applicationTypeSource &&
          applicationTypeSource.type === 'VALUE' &&
          applicationTypeSource.value === 'candidate') ||
        false;

      switch (parameters.intent) {
        case Intent.CREATE_APPLICATION:
        case Intent.UPDATE_APPLICATION:
          return {
            inputs: [
              ...(parameters.intent === Intent.UPDATE_APPLICATION
                ? [
                    { ...applicationIdInput, subtitle: 'The ID of the application to update.' },
                    customFieldsInput,
                  ]
                : [
                    {
                      ...candidateIdInput,
                      subtitle: 'The ID of the existing candidate for application.',
                    },
                  ]),
              ...(isCandidateApplicationValue ? [jobIdInput] : []),
              sourceIdInput,
              referrerInput,
              {
                ...additionalFieldsInput,
                subtitle: (
                  <>
                    Specify additional fields to include on this application data as JSON. For
                    example, you may associate initial_stage_id here.{' '}
                    <LinkWrapper
                      clickableText="See the docs"
                      href="https://developers.greenhouse.io/harvest.html?shell#post-add-application-to-candidate-prospect"
                    />{' '}
                    for available fields.
                  </>
                ),
                placeholder: `{
  "initial_stage_id": 2708728
}`,
              },
            ],
          };

        case Intent.GET_APPLICATION_BY_ID:
        case Intent.DELETE_APPLICATION:
          return {
            inputs: [
              ...(parameters.intent === Intent.GET_APPLICATION_BY_ID
                ? [applicationIdInput]
                : [{ ...applicationIdInput, subtitle: 'The ID of the application to delete.' }]),
            ],
          };

        case Intent.CREATE_CANDIDATE:
        case Intent.UPDATE_CANDIDATE:
          const isCreateCandidate: boolean = parameters.intent === Intent.CREATE_CANDIDATE;

          return {
            inputs: [
              ...(isCreateCandidate
                ? [applicationsInput]
                : [{ ...candidateIdInput, subtitle: 'The ID of the candidate to update.' }]),
              { ...firstNameInput, required: isCreateCandidate },
              { ...lastNameInput, required: isCreateCandidate },
              titleInput,
              companyInput,
              emailAddressesInput,
              addressesInput,
              phoneNumbersInput,
              websiteAddressesInput,
              additionalFieldsInput,
            ],
          };

        case Intent.GET_CANDIDATE_BY_ID:
        case Intent.DELETE_CANDIDATE:
          return {
            inputs: [
              ...(parameters.intent === Intent.GET_CANDIDATE_BY_ID
                ? [candidateIdInput]
                : [{ ...candidateIdInput, subtitle: 'The ID of the candidate to delete.' }]),
            ],
          };

        case Intent.CREATE_JOB_OPENING:
        case Intent.UPDATE_JOB_OPENING:
          return {
            inputs: [
              jobIdInput,
              ...(parameters.intent === Intent.CREATE_JOB_OPENING
                ? [openingsInput, openingIdsInput]
                : [
                    { ...internalOpeningIdInput, placeholder: 'abc-123' },
                    openingIdInput,
                    { ...statusInput, required: false },
                  ]),
              {
                ...customFieldsInput,
                placeholder: `[
  {
      "id": 123,
      "value": "jacob"
  }
]`,
              },
            ],
          };

        case Intent.GET_JOB_OPENING_BY_ID:
          return {
            inputs: [
              {
                ...jobIdInput,
                subtitle: 'The ID of the job for which you want to retrieve openings.',
              },
              internalOpeningIdInput,
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
