import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { FilterResponse, FilterResponseEntity } from '../../shared/types';
/**
 *
 * @param condition
 * @returns string or tuple array like this [["keyValue", value],["keyValue2", value]]
 */
export default function conditionsToFilterGCMExpression(
  condition: ResolvedConditionWrapper,
): FilterResponse | FilterResponseEntity {
  if (condition.type === 'JOIN') {
    return condition.conditions.map((condition: ResolvedConditionWrapper) => {
      return conditionsToFilterGCMExpression(condition);
    }) as [string, any][];
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return [`${innerCondition.variable}`, innerCondition.argument];
      case Operator.BooleanFalse:
        return [`${innerCondition.variable}`, false];
      case Operator.BooleanTrue:
        return [`${innerCondition.variable}`, true];
      default:
        throw new Error(
          `${innerCondition.operator} operator not supported for Google Campaign Manager 360`,
        );
    }
  }
  return [];
}
