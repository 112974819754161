import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';
import { DataType, KeyedSource, Source } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  additionalFieldsInput,
  documentsInput,
  emailBlurbInput,
  emailSubjectInput,
  envelopeCustomFieldOperationInput,
  envelopeFilterInput,
  envelopeIdInput,
  intentTypeInput,
  listCustomFieldsInput,
  recipientsInput,
  statusInput,
  textCustomFieldsInput,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.DOCUSIGN,
  name: 'DocuSign',
  description: "Connect to your users' DocuSign accounts",
  icon: iconSvg,
  provider: ProviderType.DOCUSIGN,

  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_AN_ENVELOPE:
        case Intent.UPDATE_ENVELOPE:
          return {
            inputs: [
              ...(parameters.intent === Intent.CREATE_AN_ENVELOPE
                ? [statusInput]
                : [envelopeIdInput, { ...statusInput, required: false }, emailBlurbInput]),
            ],
          };

        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const statusSource: Source<DataType.ANY> | undefined = parameters.actionParameters.find(
        (keySource: KeyedSource<DataType.ANY>) => keySource.key === 'status',
      )?.source;
      const isSentStatusValue: boolean =
        (statusSource && statusSource.type === 'VALUE' && statusSource.value === 'sent') || false;

      switch (parameters.intent) {
        case Intent.CREATE_AN_ENVELOPE:
        case Intent.UPDATE_ENVELOPE:
          return {
            inputs: [
              emailSubjectInput,
              {
                ...documentsInput,
                required: isSentStatusValue,
              },
              {
                ...recipientsInput,
                required: isSentStatusValue,
              },
              additionalFieldsInput,
            ],
          };

        case Intent.GET_ENVELOPE_BY_ID:
          return {
            inputs: [envelopeIdInput],
          };

        case Intent.SEND_AN_ENVELOPE:
          return {
            inputs: [envelopeIdInput],
          };

        case Intent.SEARCH_ENVELOPES:
          return {
            inputs: [envelopeFilterInput],
          };

        case Intent.ENVELOPE_CUSTOM_FIELDS:
          return {
            inputs: [
              envelopeIdInput,
              envelopeCustomFieldOperationInput,
              listCustomFieldsInput,
              textCustomFieldsInput,
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
