import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.PIPEDRIVE;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'PIPEDRIVE_TEST_CONNECTION',
  CREATE_RECORD = 'PIPEDRIVE_CREATE_RECORD',
  UPDATE_RECORD = 'PIPEDRIVE_UPDATE_RECORD',
  GET_RECORD_BY_ID = 'PIPEDRIVE_GET_RECORD_BY_ID',
  GET_RECORDS = 'PIPEDRIVE_GET_RECORDS',
  DELETE_RECORD = 'PIPEDRIVE_DELETE_RECORD',
  GET_PIPELINES = 'PIPEDRIVE_GET_PIPELINES',
  GET_STAGES = 'PIPEDRIVE_GET_STAGES',
  GET_OBJECT_TYPES = 'PIPEDRIVE_GET_OBJECT_TYPES',
  GET_OBJECT_FIELDS = 'PIPEDRIVE_GET_OBJECT_FIELDS',
  GET_RECORDS_BY_TYPE = 'PIPEDRIVE_GET_RECORDS_BY_TYPE',

  TRIGGER_RECORD_CREATED = 'PIPEDRIVE_TRIGGER_RECORD_CREATED',
  TRIGGER_RECORD_UPDATED = 'PIPEDRIVE_TRIGGER_RECORD_UPDATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PIPELINES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_STAGES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_OBJECT_TYPES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_OBJECT_FIELDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORDS_BY_TYPE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_RECORD_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  API_DOMAIN = 'PIPEDRIVE_API_DOMAIN',
  ACCESS_TOKEN = 'PIPEDRIVE_ACCESS_TOKEN',
  CLIENT_ID = 'PIPEDRIVE_CLIENT_ID',
  CLIENT_SECRET = 'PIPEDRIVE_CLIENT_SECRET',
  REFRESH_TOKEN = 'PIPEDRIVE_REFRESH_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.API_DOMAIN]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
};
