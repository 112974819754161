import { ICustomIntegration } from '@shared/entities/sdk/integration/customIntegration.interface';

import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<ICustomIntegration> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<ICustomIntegration> = initialState,
  action: Action,
): EntitiesState<ICustomIntegration> {
  switch (action.type) {
    case 'INTEGRATION_WIZARD_DRAFT_LOAD':
      const { customIntegration } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          [customIntegration.id]: customIntegration,
        },
      };

    case 'INTEGRATION_WIZARD_SAVE_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
      };

    default:
      return state;
  }
}
