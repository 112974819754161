import React from 'react';

import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  DynamicEnumInput,
  EnumInputValue,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './shared/authConfig';
import {
  collectionNameInput,
  fetchColumnsAction,
  getEnumValues,
  queryOptionsInput,
} from './shared/inputs';

const config: ActionConfig = {
  actionType: Action.MONGODB,
  name: 'MongoDB',
  description: 'Trigger when a MongoDB record is created or updated',
  icon: iconSvg,
  mutedIcon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Choose your MongoDB account',
          placeholder: 'Choose your MongoDB account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.TRIGGER_ROW_CREATED,
                    label: 'New record created',
                  },
                  {
                    value: Intent.TRIGGER_ROW_UPDATED,
                    label: 'Record updated',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      const orderByColumnInput: DynamicEnumInput<{ [key: string]: DataType }> = {
        id: 'orderBy',
        type: SidebarInputType.DynamicEnum,
        title: 'Order by',
        placeholder: 'choose a field',
        required: true,
        source: fetchColumnsAction,
        getValues: (options: ActionStepParameters): EnumInputValue[] => {
          const columns = pickValueSourceByKey(options.actionParameters, 'columns');
          return getEnumValues(columns?.value);
        },
      };

      switch (parameters.intent) {
        case Intent.TRIGGER_ROW_CREATED:
          return {
            inputs: [
              collectionNameInput,
              {
                ...orderByColumnInput,
                subtitle: (
                  <>
                    Used to determine new records. This is usually a <code>date_created</code>{' '}
                    field.
                  </>
                ),
              },
              queryOptionsInput,
            ],
          };
        case Intent.TRIGGER_ROW_UPDATED:
          return {
            inputs: [
              collectionNameInput,
              {
                ...orderByColumnInput,
                subtitle: (
                  <>
                    Used to determine newly updated records. This is usually a{' '}
                    <code>date_updated</code> field.
                  </>
                ),
              },
              queryOptionsInput,
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
