import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { CredentialKeys, Intent } from '../configs';
import { supportedOperators } from '../shared/constants';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Vendors',
          items: [
            {
              value: Intent.CREATE_VENDOR,
              label: 'Create Vendor',
            },
            {
              value: Intent.UPDATE_VENDOR,
              label: 'Update Vendor',
            },
            {
              value: Intent.DELETE_VENDOR,
              label: 'Delete Vendor',
            },
            {
              value: Intent.SEARCH_VENDORS,
              label: 'Search Vendors',
            },
            {
              value: Intent.GET_VENDOR_BY_ID,
              label: 'Get Vendor by ID',
            },
            {
              value: Intent.SEARCH_VENDOR_TYPES,
              label: 'Search Vendor Types',
            },
          ],
        },
        {
          title: 'Bills',
          items: [
            {
              value: Intent.CREATE_BILL,
              label: 'Create Bill',
            },
            {
              value: Intent.UPDATE_BILL,
              label: 'Update Bill',
            },
            {
              value: Intent.DELETE_BILL,
              label: 'Delete Bill',
            },
            {
              value: Intent.SEARCH_BILLS,
              label: 'Search Bills',
            },
            {
              value: Intent.GET_BILL_BY_ID,
              label: 'Get Bill by ID',
            },
          ],
        },

        {
          title: 'Terms',
          items: [
            {
              value: Intent.SEARCH_PAYMENT_TERMS,
              label: 'Search Payment Terms',
            },
            {
              value: Intent.GET_PAYMENT_TERM_BY_ID,
              label: 'Get Payment Term by ID',
            },
          ],
        },
        {
          title: 'General Ledger',
          items: [
            {
              value: Intent.SEARCH_ACCOUNTS,
              label: 'Search Accounts',
            },
            {
              value: Intent.GET_ACCOUNT_BY_ID,
              label: 'Get Account by ID',
            },
            {
              value: Intent.GET_DIMENSIONS,
              label: 'Get Dimensions',
            },
          ],
        },
      ],
    },
  ],
};

export const upsertVendorFields = (isCreateVendorIntent: boolean): SidebarInput[] => [
  ...(isCreateVendorIntent
    ? []
    : [
        {
          ...recordNumberInput,
          subtitle: 'The record number of the vendor to update.',
          required: true,
        },
      ]),
  { ...nameInput, required: isCreateVendorIntent },
  statusInput,
  { ...printAsInput, required: isCreateVendorIntent },
  firstNameInput,
  lastNameInput,
  phoneNumberInput,
  emailInput,
  urlInput,
  addressLine1Input,
  addressLine2Input,
  cityInput,
  stateInput,
  zipInput,
  countryInput,
  vendorTypeInput,
  parentInput,
  glGroupInput,
  taxIdInput,
  form1099NameInput,
  form1099TypeInput,
  form1099BoxInput,
  defaultExpenseAccountInput,
  creditLimitInput,
  onHoldInput,
  doNotPayInput,
  billingCommentsInput,
  taxableInput,
  contactTaxGroupInput,
  preferredPaymentMethodInput,
  paymentPriorityInput,
  billingTypeInput,
  termInput,
  mergePaymentRequestsInput,
  sendPaymentNotificationInput,
  displayTermDiscountInput,
  additionalFieldsInput,
];

export const upsertBillFields = (isCreateBillIntent: boolean): SidebarInput[] => [
  ...(isCreateBillIntent
    ? [
        {
          ...vendorIdInput,
          subtitle: 'The vendor ID this bill is for.',
          required: true,
        },
        dateInput,
      ]
    : [
        {
          ...recordNumberInput,
          subtitle: 'The record number of the bill to update.',
          placeholder: '',
          required: true,
        },
        {
          ...vendorIdInput,
          subtitle: 'The vendor ID this bill is for.',
        },
        { ...dateInput, required: false },
      ]),
  dueDateInput,
  ...(isCreateBillIntent
    ? [billItemsInput]
    : [
        {
          ...billItemsInput,
          subtitle: (
            <>
              Specify Bill Items as a JSON array. If specified, these bill items will replace the
              current items in the bill. See all available fields in{' '}
              <a
                href="https://developer.intacct.com/api/accounts-payable/bills/#:~:text=with%20%23~%23.-,APBILL.APBILLITEMS.APBILLITEM,-Name"
                target="_blank"
                rel="noreferrer"
              >
                the documentation.
              </a>
              .
            </>
          ),
          required: false,
        },
      ]),
  payToInput,
  returnToInput,
  billNumberInput,
  referenceNumberInput,
  paymentTermInput,
  recommentPaymentDateInput,
  onHoldInput,
  attachmentInput,
  descriptionInput,
];

export const companyIdInput: SidebarInput = {
  id: CredentialKeys.COMPANY_ID,
  type: SidebarInputType.ValueText,
  title: 'Company ID',
  subtitle: 'You can find your Company ID in Intacct’s settings under Company information.',
  required: true,
};

export const userIdInput: SidebarInput = {
  id: CredentialKeys.USER_ID,
  type: SidebarInputType.ValueText,
  title: 'User ID',
  subtitle: 'Your Intacct user ID.',
  required: true,
};

export const userPasswordInput: SidebarInput = {
  id: CredentialKeys.USER_PASSWORD,
  type: SidebarInputType.ValueText,
  title: 'User Password',
  subtitle: 'Your Intacct user password.',
  required: true,
};

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  subtitle: 'The name of the vendor.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const statusInput: SidebarInput = {
  id: 'status',
  title: 'Status',
  subtitle: 'Defaults to Active.',
  type: SidebarInputType.EditableEnum,
  defaultValue: 'active',
  required: false,
  getValues: () => [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ],
};

export const printAsInput: SidebarInput = {
  id: 'printAs',
  title: 'Primary Contact: Print As',
  subtitle: 'The display name to print as the contact info for the vendor.',
  type: SidebarInputType.TextArea,
  placeholder: 'Acme Widget Company, Inc.',
  lines: 1,
  required: true,
};

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  title: 'Primary Contact: First Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  title: 'Primary Contact: Last Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const phoneNumberInput: SidebarInput = {
  id: 'phoneNumber',
  title: 'Primary Contact: Phone Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const emailInput: SidebarInput = {
  id: 'email',
  title: 'Primary Contact: Email Address',
  type: SidebarInputType.TextArea,
  placeholder: 'sean@example.com',
  lines: 1,
  required: false,
};

export const urlInput: SidebarInput = {
  id: 'url',
  title: 'Company URL',
  type: SidebarInputType.TextArea,
  placeholder: 'https://example.com',
  lines: 1,
  required: false,
};

export const addressLine1Input: SidebarInput = {
  id: 'addressLine1',
  title: 'Address Line 1',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const addressLine2Input: SidebarInput = {
  id: 'addressLine2',
  title: 'Address Line 2',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const cityInput: SidebarInput = {
  id: 'city',
  title: 'City',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const stateInput: SidebarInput = {
  id: 'state',
  title: 'State',
  subtitle: 'Use state or province.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};
export const zipInput: SidebarInput = {
  id: 'zip',
  title: 'ZIP',
  subtitle: 'Use ZIP or postal code, if applicable.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const countryInput: SidebarInput = {
  id: 'country',
  title: 'Country',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const vendorTypeInput: SidebarInput = {
  id: 'vendorType',
  title: 'Vendor Type',
  subtitle:
    'The name of the Vendor Type. Use Connect Portal User Settings to allow users to specify a Vendor Type.',
  type: SidebarInputType.TextArea,
  placeholder: 'Commercial & Retail Products',
  lines: 1,
  required: false,
};

export const parentInput: SidebarInput = {
  id: 'parent',
  title: 'Parent',
  subtitle: 'The ID of the parent vendor, if applicable.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const glGroupInput: SidebarInput = {
  id: 'glGroup',
  title: 'GL Group',
  subtitle: 'The name of the GL group this vendor should be included in.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const taxIdInput: SidebarInput = {
  id: 'taxId',
  title: 'Tax ID',
  type: SidebarInputType.TextArea,
  placeholder: '12-3456789',
  lines: 1,
  required: false,
};

export const form1099NameInput: SidebarInput = {
  id: 'form1099Name',
  title: 'Form 1099 Name',
  type: SidebarInputType.TextArea,
  placeholder: 'Acme Co Inc.',
  lines: 1,
  required: false,
};

export const form1099TypeInput: SidebarInput = {
  id: 'form1099Type',
  title: 'Form 1099 Type',
  type: SidebarInputType.TextArea,
  placeholder: 'MISC',
  lines: 1,
  required: false,
};

export const form1099BoxInput: SidebarInput = {
  id: 'form1099Box',
  title: 'Form 1099 Box',
  type: SidebarInputType.TextArea,
  placeholder: '3',
  lines: 1,
  required: false,
};

export const defaultExpenseAccountInput: SidebarInput = {
  id: 'defaultExpenseAccount',
  title: 'Default Expense Account',
  subtitle:
    'The GL Account used for expenses from this vendor. Use Connect Portal User Settings to allow your user to select a GL Account.',
  type: SidebarInputType.TextArea,
  placeholder: '1000',
  lines: 1,
  required: false,
};

export const creditLimitInput: SidebarInput = {
  id: 'creditLimit',
  title: 'Credit Limit',
  type: SidebarInputType.TextArea,
  placeholder: '1234.56',
  lines: 1,
  required: false,
};

export const vendorIdInput: SidebarInput = {
  id: 'vendorId',
  title: 'Vendor ID',
  subtitle: 'ID of the vendor to get.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const recordNumberInput: SidebarInput = {
  id: 'recordNumber',
  title: 'Record Number',
  subtitle: 'The record number of the vendor to get.',
  type: SidebarInputType.TextArea,
  placeholder: '123',
  lines: 1,
  required: false,
};

export const accountIdInput: SidebarInput = {
  id: 'accountId',
  title: 'Account ID',
  subtitle: 'The ID of the account to get.',
  type: SidebarInputType.TextArea,
  placeholder: '6700',
  lines: 1,
  required: true,
};

export const onHoldInput: SidebarInput = {
  id: 'onHold',
  title: 'On Hold',
  type: SidebarInputType.BooleanInput,
  defaultValue: false,
  required: false,
};

export const doNotPayInput: SidebarInput = {
  id: 'doNotPay',
  title: 'Do Not Pay',
  type: SidebarInputType.BooleanInput,
  defaultValue: false,
  required: false,
};

export const billingCommentsInput: SidebarInput = {
  id: 'billingComments',
  title: 'Billing Comments',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const taxableInput: SidebarInput = {
  id: 'taxable',
  title: 'Taxable',
  type: SidebarInputType.BooleanInput,
  defaultValue: true,
  required: false,
};

export const contactTaxGroupInput: SidebarInput = {
  id: 'contactTaxGroup',
  title: 'Contact Tax Group',
  subtitle: 'The name of the tax group this vendor should be included in.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const preferredPaymentMethodInput: SidebarInput = {
  id: 'preferredPaymentMethod',
  title: 'Preferred Payment Method',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Check', value: 'Printed Check' },
    { label: 'Credit Card', value: 'Charge Card' },
    { label: 'Record Transfer', value: 'EFT' },
    { label: 'Cash', value: 'Cash' },
    { label: 'ACH / Bank File', value: 'ACH' },
  ],
};

export const paymentPriorityInput: SidebarInput = {
  id: 'paymentPriority',
  title: 'Payment Priority',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Urgent', value: 'Urgent' },
    { label: 'High', value: 'High' },
    { label: 'Normal', value: 'Normal' },
    { label: 'Low', value: 'Low' },
  ],
};

export const billingTypeInput: SidebarInput = {
  id: 'billingType',
  title: 'Billing Type',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Open Item', value: 'openitem' },
    { label: 'Balance Forward', value: 'balanceforward' },
  ],
};

export const termInput: SidebarInput = {
  id: 'term',
  title: 'Term',
  subtitle:
    'The name of the payment term for this vendor. Use Connect Portal User Settings to allow your user to select a term.',
  type: SidebarInputType.TextArea,
  placeholder: 'Net 30',
  lines: 1,
  required: false,
};

export const mergePaymentRequestsInput: SidebarInput = {
  id: 'mergePaymentRequests',
  title: 'Merge Payment Requests',
  type: SidebarInputType.BooleanInput,
  defaultValue: true,
  required: false,
};

export const sendPaymentNotificationInput: SidebarInput = {
  id: 'sendPaymentNotification',
  title: 'Send Automatic Payment Notification',
  type: SidebarInputType.BooleanInput,
  defaultValue: false,
  required: false,
};

export const displayTermDiscountInput: SidebarInput = {
  id: 'displayTermDiscount',
  title: 'Display Term Discount',
  subtitle: 'Determines if term discount appears on the check stub.',
  type: SidebarInputType.BooleanInput,
  defaultValue: false,
  required: false,
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Additional Fields',
  required: false,
  subtitle: 'Specify any additional fields to be included in this vendor.',
  placeholder: `{
  "ACHENABLED": true,
  "ACHBANKROUTINGNUMBER": "xxx",
  "ACHACCOUNTNUMBER": "xxx"
}`,
  useLightTheme: true,
};

export const dateInput: SidebarInput = {
  id: 'date',
  title: 'Date',
  subtitle: 'Transaction date for when this bill was created. Format as MM/DD/YYYY.',
  type: SidebarInputType.TextArea,
  placeholder: '12/15/2030',
  lines: 1,
  required: true,
};

export const dueDateInput: SidebarInput = {
  id: 'dueDate',
  title: 'Due Date',
  subtitle: 'Due date for this bill. Format as MM/DD/YYYY.',
  type: SidebarInputType.TextArea,
  placeholder: '12/15/2030',
  lines: 1,
  required: true,
};

export const billItemsInput: SidebarInput = {
  id: 'billItems',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Bill Items',
  required: true,
  subtitle: (
    <>
      Specify Bill Items as a JSON array. See all available fields in{' '}
      <a
        href="https://developer.intacct.com/api/accounts-payable/bills/#:~:text=with%20%23~%23.-,APBILL.APBILLITEMS.APBILLITEM,-Name"
        target="_blank"
        rel="noreferrer"
      >
        the documentation.
      </a>
    </>
  ),
  placeholder: `[{
  ACCOUNTNO: "1010",
  TRX_AMOUNT: 500.00,
  ENTRYDESCRIPTION: "Bill line 1"
}]`,
  useLightTheme: true,
};

export const payToInput: SidebarInput = {
  id: 'payTo',
  title: 'Pay To',
  subtitle: 'Name of contact to pay to.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const returnToInput: SidebarInput = {
  id: 'returnTo',
  title: 'Return To',
  subtitle: 'Name of contact to return to.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const billNumberInput: SidebarInput = {
  id: 'billNumber',
  title: 'Bill Number',
  subtitle: 'ID for the bill.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const referenceNumberInput: SidebarInput = {
  id: 'referenceNumber',
  title: 'Reference Number',
  subtitle: 'Reference number for the bill.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const paymentTermInput: SidebarInput = {
  id: 'paymentTerm',
  title: 'Payment Term',
  subtitle: 'Use Connect Portal User Settings to allow your user to select a Payment Term.',
  type: SidebarInputType.TextArea,
  placeholder: 'Net 30',
  lines: 1,
  required: false,
};

export const recommentPaymentDateInput: SidebarInput = {
  id: 'recommendPaymentDate',
  title: 'Recommended Payment Date',
  subtitle: 'Format as MM/DD/YYYY.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const attachmentInput: SidebarInput = {
  id: 'attachment',
  title: 'Attachment',
  subtitle: 'Specify an ID for an attachment to include in this bill.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  title: 'Description',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
};

export const billIdInput: SidebarInput = {
  id: 'billId',
  title: 'Bill ID',
  subtitle: 'The record number of the bill to update.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const vendorFilterInput: SidebarInput = {
  id: 'vendorFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: false,
};

export const vendorTypesFilterInput: SidebarInput = {
  id: 'vendorTypesFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: false,
  getValues: () => [
    { label: 'Name', value: 'NAME' },
    { label: 'Status', value: 'STATUS' },
  ],
};

export const paymentTermsFilterInput: SidebarInput = {
  id: 'paymentTermsFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: false,
  getValues: () => [
    { label: 'Name', value: 'NAME' },
    { label: 'Description', value: 'DESCRIPTION' },
  ],
};

export const accountFilterInput: SidebarInput = {
  id: 'accountFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: false,
  getValues: () => [
    { label: 'Account Number', value: 'ACCOUNTNO' },
    { label: 'Account Type', value: 'ACCOUNTTYPE' },
    { label: 'Title', value: 'TITLE' },
    { label: 'System Category', value: 'CATEGORY' },
  ],
};

export const billFilterInput: SidebarInput = {
  id: 'billFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: false,
  getValues: () => [
    { label: 'Vendor ID', value: 'VENDORID' },
    { label: 'Record Number', value: 'RECORDNO' },
    { label: 'Bill Number', value: 'RECORDID' },
    { label: 'Reference Number', value: 'DOCNUMBER' },
    { label: 'Date', value: 'WHENCREATED' },
    { label: 'Due Date', value: 'WHENDUE' },
    { label: 'On Hold', value: 'ONHOLD' },
    { label: 'State', value: 'STATE' },
  ],
};
