import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import {
  RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
  ZohoCrmStandardRecord,
  supportedOperators,
} from '../shared/constants';

import { defaultZohoCrmFields, objectNameInput, recordTypeInput } from './inputs';

const filterInputSubtitle = {
  [Intent.TRIGGER_RECORD_CREATED]: 'Only trigger on new records that match these filters.',
  [Intent.TRIGGER_RECORD_UPDATED]: 'Only trigger on updated records that match these filters.',
};

const connectConfig: ActionConfig = {
  actionType: Action.ZOHO_CRM,
  name: 'Zoho CRM',
  description: 'Trigger when records are created or updated in Zoho CRM',
  icon: iconSvg,
  provider: ProviderType.ZOHO_CRM,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          icon: iconSvg,
          values: [
            {
              value: Intent.TRIGGER_RECORD_CREATED,
              label: 'New Record',
              subtitle: 'Trigger when a new Zoho CRM record is created',
            },
            {
              value: Intent.TRIGGER_RECORD_UPDATED,
              label: 'Record Updated',
              subtitle: 'Trigger when a Zoho CRM record is updated',
            },
          ],
        },
      ],
    },

    (parameters: ActionStepParameters): SidebarSection => {
      const selectedRecordType:
        | ZohoCrmStandardRecord
        | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'recordType',
      )?.value;

      let fieldInputs: SidebarInput[] = [];
      if (selectedRecordType && selectedRecordType in defaultZohoCrmFields) {
        fieldInputs = [
          ...defaultZohoCrmFields[selectedRecordType]?.(parameters.intent === Intent.CREATE_RECORD),
        ];
      }

      const searchInputs = fieldInputs.reduce(
        (accumulator: SidebarInput[], value: SidebarInput): SidebarInput[] => {
          return value.id.includes('AdditionalFields') || value.id.includes('recordId')
            ? accumulator
            : accumulator.concat(value);
        },
        [],
      );

      return {
        inputs: [
          recordTypeInput,
          ...(selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
            ? [objectNameInput(parameters.intent as Intent)]
            : []),
          {
            id: 'recordsFilterFormula',
            type: SidebarInputType.Conditional,
            required: false,
            title: 'Filter search',
            subtitle: filterInputSubtitle[parameters.intent],
            supportedOperators,
            getValues:
              selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
                ? undefined
                : () => [
                    { label: 'Id', value: 'id' },
                    ...searchInputs.map((item: SidebarInput) => ({
                      label: item.title,
                      value: item.id,
                    })),
                  ],
          } as SidebarInput,
        ],
      };
    },
  ],
};

export default connectConfig;
