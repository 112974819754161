import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.AZURE_DEVOPS;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'AZURE_DEVOPS_TEST_CONNECTION',
  SEARCH_WORKITEMS = 'AZURE_DEVOPS_SEARCH_WORKITEMS',
  CREATE_WORKITEM = 'AZURE_DEVOPS_CREATE_WORKITEM',
  UPDATE_WORKITEM = 'AZURE_DEVOPS_UPDATE_WORKITEM',
  DELETE_WORKITEM = 'AZURE_DEVOPS_DELETE_WORKITEM',
  GET_PROJECTS = 'AZURE_DEVOPS_GET_PROJECTS',
  GET_AREA = 'AZURE_DEVOPS_GET_AREA',
  GET_ITERATION = 'AZURE_DEVOPS_GET_ITERATION',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_WORKITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_WORKITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_AREA;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ITERATION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROJECTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_WORKITEMS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_WORKITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  CLIENT_ID = 'AZURE_DEVOPS_CLIENT_ID',
  CLIENT_SECRET = 'AZURE_DEVOPS_CLIENT_SECRET',
  ACCESS_TOKEN = 'AZURE_DEVOPS_ACCESS_TOKEN',
  REFRESH_TOKEN = 'AZURE_DEVOPS_REFRESH_TOKEN',
  SCOPES = 'AZURE_DEVOPS_SCOPES',
}

export type Credentials = {
  name: string;
  organizationId?: string;
  AZURE_DEVOPS_SERVER_URL?: string;
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
  redirectUrl?: string;
};
