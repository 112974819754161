import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';

import iconSvg from './icon.svg';

export type ScopeName = 'read' | 'write';

export const requiredScopes: ScopeName[] = ['read', 'write'];

export const scopes: Record<ScopeName, ScopeValue> = {
  read: {
    name: 'read',
    description: 'reading of boards, organizations, etc. on behalf of the user',
  },
  write: {
    name: 'write',
    description: 'writing of boards, organizations, etc. on behalf of the user',
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Trello',
  description: 'Connect to your users’ Trello accounts',
  authUrl: '',
  accessTokenUrl: '',
  iconSvg,
  clientIdLabel: '',
  clientSecretLabel: '',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      id: 'name',
      title: 'App Name',
      placeholder: 'Specify an app name to appear on Trello',
      required: true,
    },
  ],
  scopes: { values: Object.values(scopes) },
  documentationLink: 'https://developer.atlassian.com/cloud/trello/rest',
};
