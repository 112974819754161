import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_SHEETS;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  GET_SPREADSHEETS = 'GOOGLE_SHEETS_GET_SPREADSHEETS',
  GET_WORKSHEETS = 'GOOGLE_SHEETS_GET_WORKSHEETS',
  GET_COLUMNS = 'GOOGLE_SHEETS_GET_COLUMNS',
  CREATE_ROW = 'GOOGLE_SHEETS_CREATE_ROW',
  GET_ROW = 'GOOGLE_SHEETS_GET_ROW',
  UPDATE_ROW = 'GOOGLE_SHEETS_UPDATE_ROW',
  CLEAR_ROWS = 'GOOGLE_SHEETS_CLEAR_ROWS',
  TEST_CONNECTION = 'GOOGLE_SHEETS_TEST_CONNECTION',
  TRIGGER_ROW_CREATED = 'GOOGLE_SHEETS_TRIGGER_ROW_CREATED',
  TRIGGER_ROW_UPDATED = 'GOOGLE_SHEETS_TRIGGER_ROW_UPDATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_ROW;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ROW;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_ROW;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CLEAR_ROWS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_SPREADSHEETS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_WORKSHEETS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_COLUMNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ROW_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ROW_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;
  OAUTH_ACCESS_TOKEN?: string;
};
