export default `<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2721_2801)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.1816 0.539307H1.81836C1.09063 0.539307 0.5 1.12993 0.5 1.85767V44.2209C0.5 44.9487 1.09063 45.5393 1.81836 45.5393H44.1816C44.9094 45.5393 45.5 44.9487 45.5 44.2209V1.85767C45.5 1.12993 44.9094 0.539307 44.1816 0.539307Z" fill="#55B76F"/>
<path d="M34.3365 8.53381V16.8869C32.9865 15.8147 31.2779 15.1741 29.4202 15.1741C26.7926 15.1741 24.4646 16.4559 23.026 18.4275C22.0381 19.7795 21.5068 21.4112 21.5094 23.0857C21.5094 23.0948 21.5058 23.0892 21.4988 23.0751C21.4946 25.7871 19.2952 27.9844 16.5826 27.9844C13.8734 27.9844 11.6769 25.7934 11.6663 23.0864V23.0491C11.6762 20.3421 13.8741 18.1512 16.5826 18.1512C18.4248 18.1512 20.0307 19.1658 20.8716 20.6662C20.9715 20.3287 21.2042 19.6031 21.5305 18.9647C21.8138 18.4106 22.1787 17.9311 22.3791 17.685C21.6396 16.8863 20.7427 16.2493 19.745 15.814C18.7473 15.3787 17.6704 15.1547 16.5819 15.1558C12.2647 15.1558 8.75467 18.6152 8.6717 22.9134H8.66467V37.6172H11.6649V29.2655C13.0149 30.3377 14.7235 30.9783 16.5798 30.9783C19.2186 30.9783 21.5551 29.6859 22.9923 27.7003C23.9302 26.4094 24.4801 24.8175 24.4801 23.0976C24.4801 23.0836 24.4885 23.1005 24.5026 23.1384C24.5026 23.1201 24.5012 23.1033 24.5012 23.0843C24.5012 20.3688 26.7019 18.168 29.4174 18.168C32.1258 18.168 34.3224 20.3583 34.3337 23.0639V23.1047C34.3224 25.8103 32.1258 28.0005 29.4174 28.0005C27.5738 28.0005 25.9679 26.9852 25.1262 25.4833C25.032 25.8054 24.7958 26.5577 24.4583 27.218C24.184 27.7558 23.8325 28.2234 23.6286 28.4744C24.3679 29.2708 25.2638 29.9058 26.2601 30.3397C27.2563 30.7736 28.3315 30.997 29.4181 30.9958C33.7353 30.9958 37.2453 27.5365 37.3283 23.2383H37.3353V8.53381H34.3365Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2721_2801">
<rect width="45" height="45" fill="white" transform="translate(0.5 0.539307)"/>
</clipPath>
</defs>
</svg>`;
