import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { ActionStepParameters, Intent } from '../configs';
import { Board, List } from '../shared';

export const getLists: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'lists',
  title: 'List',
  refreshDependencies: ['boardId'],
  hideFromConnectFieldTypes: true,
  mapRefreshToValues: (response: ActionResponse<List[]>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((list: List) => ({
          label: list.name,
          value: list.id,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const board =
      pickValueSourceByKey(options.actionParameters, 'boards') ??
      pickValueSourceByKey(options.actionParameters, 'boardId');
    return {
      actionType: Action.TRELLO,
      intent: Intent.GET_LISTS_IN_BOARD,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'boardId',
          source: {
            type: 'VALUE',
            value: board?.value,
          },
        },
      ],
    };
  },
};

export const getBoards: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'boards',
  title: 'Board',
  subtitle: 'A Trello board to manage lists and cards for',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<Board[]>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((board: Board) => ({
          label: board.name,
          value: board.id,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.TRELLO,
      intent: Intent.GET_BOARDS_MEMBER_BELONG_TO,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const comboTrelloFields: ComboInputDataSource = {
  id: 'comboFields',
  type: DataSourceType.COMBO_INPUT,
  title: 'List',
  subtitle: 'A Trello list to manage cards in',
  mainInputSource: getBoards,
  dependentInputSource: getLists,
};
