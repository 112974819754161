import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { RecordType } from '../shared';

import authConfig from './authConfig';
import {
  accountFields,
  accountFilterInput,
  accountIdInput,
  billFields,
  billFilterInput,
  billIdInput,
  commonIntentInput,
  postingPeriodFilterInput,
  recordTypeInput,
  taxCodeFilterInput,
  taxGroupFields,
  taxGroupIdInput,
  termFilterInput,
  termIdInput,
  vendorFields,
  vendorFilterInput,
  vendorIdInput,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.NETSUITE,
  name: 'NetSuite',
  description: "Connect to your users' NetSuite ERP systems",
  icon: iconSvg,
  provider: ProviderType.NETSUITE,
  sidebarSections: [
    commonIntentInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.CREATE_VENDOR:
        case Intent.UPDATE_VENDOR:
          const selectedType: string = pickValueSourceByKey<DataType.ANY>(
            parameters.actionParameters,
            'isPerson',
          )?.value;
          return {
            inputs: [...vendorFields(parameters.intent === Intent.CREATE_VENDOR, selectedType)],
          };

        case Intent.GET_VENDOR_BY_ID:
          return {
            inputs: [
              { ...vendorIdInput, title: 'Vendor', subtitle: 'The ID of the vendor to fetch.' },
              recordTypeInput(RecordType.VENDOR),
            ],
          };

        case Intent.SEARCH_VENDORS:
          return {
            inputs: [vendorFilterInput, recordTypeInput(RecordType.VENDOR)],
          };

        case Intent.DELETE_VENDOR:
          return {
            inputs: [
              { ...vendorIdInput, subtitle: 'The ID of the vendor to delete.' },
              recordTypeInput(RecordType.VENDOR),
            ],
          };

        case Intent.CREATE_BILL:
        case Intent.UPDATE_BILL:
          return {
            inputs: billFields(parameters.intent === Intent.CREATE_BILL),
          };

        case Intent.GET_BILL_BY_ID:
          return {
            inputs: [billIdInput, recordTypeInput(RecordType.BILL)],
          };

        case Intent.SEARCH_BILLS:
          return {
            inputs: [billFilterInput, recordTypeInput(RecordType.BILL)],
          };

        case Intent.DELETE_BILL:
          return {
            inputs: [
              { ...billIdInput, subtitle: 'The ID of the bill to delete.' },
              recordTypeInput(RecordType.BILL),
            ],
          };

        case Intent.CREATE_ACCOUNT:
        case Intent.UPDATE_ACCOUNT:
          return {
            inputs: accountFields(parameters.intent === Intent.CREATE_ACCOUNT),
          };

        case Intent.GET_ACCOUNT_BY_ID:
          return {
            inputs: [
              { ...accountIdInput, subtitle: 'The ID of the account to fetch.' },
              recordTypeInput(RecordType.ACCOUNTS),
            ],
          };

        case Intent.SEARCH_ACCOUNTS:
          return {
            inputs: [accountFilterInput, recordTypeInput(RecordType.ACCOUNTS)],
          };

        case Intent.DELETE_ACCOUNT:
          return {
            inputs: [
              { ...accountIdInput, subtitle: 'The ID of the account to delete.' },
              recordTypeInput(RecordType.ACCOUNTS),
            ],
          };

        case Intent.CREATE_TAX_GROUP:
        case Intent.UPDATE_TAX_GROUP:
          return {
            inputs: taxGroupFields(parameters.intent === Intent.CREATE_TAX_GROUP),
          };

        case Intent.GET_TAX_GROUP_BY_ID:
          return {
            inputs: [taxGroupIdInput, recordTypeInput(RecordType.TAX_GROUP)],
          };

        case Intent.DELETE_TAX_GROUP:
          return {
            inputs: [
              { ...taxGroupIdInput, subtitle: 'The ID of the Tax Group to delete.' },
              recordTypeInput(RecordType.TAX_GROUP),
            ],
          };

        case Intent.SEARCH_PAYMENT_TERMS:
          return {
            inputs: [termFilterInput, recordTypeInput(RecordType.PAYMENT_TERMS)],
          };

        case Intent.GET_PAYMENT_TERM_BY_ID:
          return {
            inputs: [termIdInput, recordTypeInput(RecordType.PAYMENT_TERMS)],
          };

        case Intent.SEARCH_POSTING_PERIODS:
          return {
            inputs: [postingPeriodFilterInput, recordTypeInput(RecordType.POSTING_PERIOD)],
          };

        case Intent.SEARCH_TAX_CODES:
          return {
            inputs: [taxCodeFilterInput, recordTypeInput(RecordType.TAX_CODE)],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
