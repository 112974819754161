import { CredentialKeys } from '../configs';

export type Environment = {
  [CredentialKeys.CLIENT_ID]: string;
  [CredentialKeys.CLIENT_SECRET]: string;
  [CredentialKeys.SCOPES]: string;
};

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]?: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
  [CredentialKeys.SCOPES]?: string;
};

export enum WorkItemType {
  BUG = 'BUG',
  EPIC = 'EPIC',
  FEATURE = 'FEATURE',
  ISSUE = 'ISSUE',
  TASK = 'TASK',
  TEST_CASE = 'TEST CASE',
  USER_STORY = 'USER STORY',
}

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
  AZURE_DEVOPS_ORGANIZATION_ID?: string;
  AZURE_DEVOPS_PAT?: string;
  AZURE_DEVOPS_SERVER_URL?: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: any;
  AZURE_DEVOPS_ORGANIZATION_ID: string;
};

export type TokenResponse = {
  accessToken: string;
  refreshToken: string;
  tokenUpdated: boolean;
};

export type RefreshTokenRequestResponse = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
};

export type CreateUpdateWorkitemDTO = {
  workItemId?: string;
  workItemType: string;
  project: string;
  title: string;
  assignee: string;
  state: string;
  reason: string;
  area: string;
  iteration?: string;
  reproSteps?: string;
  systemInfo?: string;
  resolvedReason?: string;
  storyPoints?: string;
  priority?: string;
  severity?: string;
  activity?: string;
  description?: string;
  risk?: string;
  effort?: string;
  businessValue?: string;
  timeCriticality?: string;
  startDate?: string;
  targetDate?: string;
  additionalFields?: string | object;
};

export type UserInfoResponse = { displayName: string; emailAddress: string; id: string };

export type ClassificationNodeTypeResponse = {
  value: ClassificationNode[];
};

export type ClassificationNode = {
  name: string;
  structureType: 'iteration' | 'area';
  path: string;
};

export type ProjectResponseType = {
  value: {
    name: string;
  }[];
};

export type UpdatedCredentials = { accessToken: string; refreshToken: string } | undefined;

export type GeneralAPIResponse = {
  id: string;
  name: string;
  title: string;
  description: string;
};

export type AreaIterationResponseType = { name: string; path: string };
