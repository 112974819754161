import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { MD_ENTITY_SPECIFIED_BY_OBJECT_NAME, MdDefaultEntities } from '../shared';

import {
  additionalFieldsInput,
  customEntityNameDropdownInput,
  entityIdInputFactory,
  getClassicFilterInput,
  inputsForDefaultEntitiesClassic,
  intentInput,
  mdEntityInput,
} from './inputs';

const authConfig: ActionConfig = {
  actionType: Action.MICROSOFT_DYNAMICS,
  provider: ProviderType.MICROSOFT_DYNAMICS_365,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Microsoft account',
  icon: iconSvg,
  description: 'Dynamics 365 Sales',
  sidebarSections: [
    {
      inputs: [
        {
          id: 'MICROSOFT_DYNAMICS_SUB_DOMAIN',
          title: 'Enter your Dynamics 365 Sales subdomain',
          subtitle:
            'Enter your Dynamics 365 Sales subdomain, e.g. https://<subdomain>.dynamics.com.',
          placeholder: 'subdomain',
          type: SidebarInputType.ValueText,
        },
      ],
      buttons: [],
    },
  ],
  hideOAuthApps: true,
};

const config: ActionConfig = {
  actionType: Action.MICROSOFT_DYNAMICS,
  name: 'Dynamics 365 Sales',
  description: 'Find, create and update Dynamics 365 Sales',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Dynamics 365 Sales account',
          placeholder: 'connect to Dynamics 365 Sales',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: intentInput,
          }
        : { inputs: [] },

    (parameters: ActionStepParameters): SidebarSection => {
      const selectedMdEntity:
        | MdDefaultEntities
        | typeof MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'mdEntity',
      )?.value;

      return {
        inputs: parameters.intent
          ? [
              mdEntityInput,
              ...(selectedMdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
                ? [customEntityNameDropdownInput]
                : []),
            ]
          : [],
      };
    },

    (parameters: ActionStepParameters): SidebarSection => {
      const selectedMdEntity:
        | MdDefaultEntities
        | typeof MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'mdEntity',
      )?.value;

      if (selectedMdEntity === undefined) {
        return { inputs: [] };
      }

      switch (parameters.intent as Intent) {
        case Intent.CREATE_RECORD:
          return {
            inputs: [
              ...(selectedMdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
                ? [
                    {
                      ...additionalFieldsInput,
                      title: 'Record Fields',
                      subtitle:
                        'Use Workflow Settings to allow users to select a Field Mapping to specify which fields to update.',
                    },
                  ]
                : inputsForDefaultEntitiesClassic[selectedMdEntity]!),
            ],
          };
        case Intent.UPDATE_RECORD:
          return {
            inputs: [
              entityIdInputFactory(selectedMdEntity, 'update'),
              ...(selectedMdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
                ? [
                    {
                      ...additionalFieldsInput,
                      title: 'Record Fields',
                      subtitle:
                        'Use Workflow Settings to allow users to select a Field Mapping to specify which fields to update.',
                    },
                  ]
                : inputsForDefaultEntitiesClassic[selectedMdEntity]!.map((input: SidebarInput) => ({
                    ...input,
                    required: false,
                    subtitle: input.subtitle
                      ?.toString()
                      .replace('This field is required.', '')
                      .replace('Connect Portal', ''),
                  }))),
            ],
          };

        case Intent.GET_RECORD_BY_ID:
          return {
            inputs: [entityIdInputFactory(selectedMdEntity, 'fetch')],
          };
        case Intent.SEARCH_RECORDS:
          return { inputs: [getClassicFilterInput(selectedMdEntity)] };

        case Intent.DELETE_RECORD:
          return { inputs: [entityIdInputFactory(selectedMdEntity, 'delete')] };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default config;
