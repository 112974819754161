import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import {
  HubspotStandardRecords,
  additionalFieldsInput,
  companyIdInput,
  contactIdInput,
  customObjectNameInput,
  customObjectPropertyInput,
  defaultHubspotFields,
  engagementFilterInputConnect,
  engagementTypeInput,
  engagementTypeMetadataInputs,
  ownerIdInput,
} from '../shared/defaultRecordTypes';
import {
  EngagementType,
  ExtraRecordType,
  RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
  RecordType,
} from '../shared/types';

import { hubspotIntentsShownInDropdown } from './config';
import supportedOperators from './supportedOperators';

const recordTypeSelector: SidebarInput = {
  id: 'recordType',
  type: SidebarInputType.Enum,
  title: 'Record Type',
  required: true,
  getValues: () => {
    return [
      {
        value: RecordType.COMPANY,
        label: 'Company',
      },
      {
        value: RecordType.CONTACTS,
        label: 'Contact',
      },
      {
        value: RecordType.DEALS,
        label: 'Deals',
      },
      {
        value: ExtraRecordType.ENGAGEMENTS,
        label: 'Engagements',
      },

      {
        value: RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
        label: 'Custom Object',
      },
    ];
  },
};

const recordIdInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  lines: 1,
  id: 'recordId',
  title: 'Record ID',
  required: true,
};

const connectConfig: ActionConfig = {
  actionType: Action.HUBSPOT,
  name: 'HubSpot',
  description: 'Find, create, and update records in HubSpot.',
  icon: iconSvg,
  provider: ProviderType.HUBSPOT,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            ...hubspotIntentsShownInDropdown,
            {
              label: 'Get Contacts by List ID',
              value: Intent.GET_CONTACTS_BY_LIST_ID,
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (!parameters.intent || parameters.intent === Intent.GET_CONTACTS_BY_LIST_ID) {
        return { inputs: [] };
      }
      return {
        inputs: [recordTypeSelector],
      };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const recordType:
        | HubspotStandardRecords
        | ExtraRecordType
        | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'recordType',
      )?.value;

      if (parameters.intent === Intent.GET_CONTACTS_BY_LIST_ID) {
        return {
          inputs: [
            {
              type: SidebarInputType.TextArea,
              lines: 1,
              id: 'listId',
              title: 'List ID',
              subtitle: 'The ID of the list to get contacts from.',
              placeholder: '{{settings.listId}}',
              required: true,
            },
          ],
        };
      } else if (!parameters.intent || !recordType) {
        return { inputs: [] };
      }

      const isEngagmentRecordType: boolean = recordType === ExtraRecordType.ENGAGEMENTS;
      const isCustomObjectRecordType: boolean = recordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME;

      const selectedEngagementType = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'engagementType',
      )?.value;

      switch (parameters.intent) {
        case Intent.CREATE_RECORD:
          return {
            inputs: isCustomObjectRecordType
              ? [customObjectNameInput, customObjectPropertyInput]
              : isEngagmentRecordType
              ? [
                  engagementTypeInput,
                  contactIdInput,
                  companyIdInput,
                  ownerIdInput,
                  ...(engagementTypeMetadataInputs[selectedEngagementType as EngagementType] ?? []),
                ]
              : [...defaultHubspotFields[recordType], additionalFieldsInput],
          };

        case Intent.UPDATE_RECORD:
          return {
            inputs: isCustomObjectRecordType
              ? [
                  customObjectNameInput,
                  { ...recordIdInput, subtitle: 'The ID of the record to update.' },
                  customObjectPropertyInput,
                ]
              : isEngagmentRecordType
              ? [
                  engagementTypeInput,
                  recordIdInput,
                  contactIdInput,
                  companyIdInput,
                  ownerIdInput,
                  ...(engagementTypeMetadataInputs[selectedEngagementType as EngagementType] ?? []),
                ]
              : [
                  recordIdInput,
                  ...defaultHubspotFields[recordType].map((input: SidebarInput) => ({
                    ...input,
                    required: false,
                  })),
                  additionalFieldsInput,
                ],
          };

        case Intent.GET_RECORD_BY_ID:
        case Intent.DELETE_RECORD:
          return {
            inputs: [...(isCustomObjectRecordType ? [customObjectNameInput] : []), recordIdInput],
          };

        case Intent.SEARCH_RECORDS:
          return {
            inputs: isEngagmentRecordType
              ? [engagementFilterInputConnect]
              : [
                  ...(isCustomObjectRecordType ? [customObjectNameInput] : []),
                  {
                    id: 'filterFormula',
                    title: 'Filter search',
                    type: SidebarInputType.Conditional,
                    subtitle: 'Search for Records that match specified filters.',
                    placeholder: 'field',
                    getValues: isCustomObjectRecordType
                      ? undefined
                      : () =>
                          defaultHubspotFields[recordType].map((input: SidebarInput) => ({
                            label: input.title,
                            value: input.id.slice('field-'.length),
                          })),
                    supportedOperators,
                    required: false,
                  },
                ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
