import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

/**
 * this integration use direct trust authentication mechanism for connect which is little bit different from conventional oauth2.0,
 * it does not open popup but require scopes to generate tokens
 */
export type ScopeName =
  | 'tableau:datasources:create'
  | 'tableau:content:read'
  | 'tableau:datasources:update'
  | 'tableau:tasks:run'
  | 'tableau:flows:create'
  | 'tableau:metrics:delete'
  | 'tableau:metrics:download'
  | 'tableau:metrics:update'
  | 'tableau:views:download'
  | 'tableau:workbooks:create'
  | 'tableau:workbooks:download'
  | 'tableau:workbooks:update'
  | 'tableau:workbooks:update'
  | 'tableau:file_uploads:create'
  | 'tableau:datasources:download'
  | 'tableau:users:create'
  | 'tableau:users:delete'
  | 'tableau:users:update'
  | 'tableau:groups:create'
  | 'tableau:groups:delete'
  | 'tableau:users:read'
  | 'tableau:groups:read'
  | 'tableau:groups:update'
  | 'tableau:projects:create'
  | 'tableau:projects:delete'
  | 'tableau:projects:update'
  | 'tableau:permissions:update'
  | 'tableau:permissions:delete'
  | 'tableau:permissions:read'
  | 'tableau:sites:update';

export const requiredScopes: ScopeName[] = ['tableau:content:read'];

export const scopes: Record<ScopeName, ScopeValue> = {
  'tableau:datasources:create': {
    name: 'tableau:datasources:create',
    description:
      'Publish a data source to a site or append data to an existing published data source',
  },
  'tableau:content:read': { name: 'tableau:content:read', description: 'Get data source data' },
  'tableau:datasources:update': {
    name: 'tableau:datasources:update',
    description: 'Update data source data',
  },
  'tableau:tasks:run': { name: 'tableau:tasks:run', description: 'Run extract refresh' },
  'tableau:flows:create': { name: 'tableau:flows:create', description: 'Publish a flow' },
  'tableau:metrics:delete': { name: 'tableau:metrics:delete', description: 'Get a metric' },
  'tableau:metrics:download': {
    name: 'tableau:metrics:download',
    description: 'Get underlying data of a metric in comma-separated value (.csv) format',
  },
  'tableau:metrics:update': {
    name: 'tableau:metrics:update',
    description: 'Update owner, project, suspend status, and name of the metric',
  },
  'tableau:views:download': {
    name: 'tableau:views:download',
    description: 'Get a view rendered in comma-separated value (.csv) format',
  },
  'tableau:workbooks:create': {
    name: 'tableau:workbooks:create',
    description: 'Publish a workbook',
  },
  'tableau:workbooks:download': {
    name: 'tableau:workbooks:download',
    description: 'Get the thumbnail image (.png) of the workbook',
  },
  'tableau:workbooks:update': {
    name: 'tableau:workbooks:update',
    description: 'Modify an existing workbook',
  },
  'tableau:file_uploads:create': {
    name: 'tableau:file_uploads:create',
    description: 'Upload a block of data',
  },
  'tableau:datasources:download': {
    name: 'tableau:datasources:download',
    description: 'Download the data source',
  },
  'tableau:users:create': {
    name: 'tableau:users:create',
    description: 'Add a user and assign the user to a site',
  },
  'tableau:users:delete': {
    name: 'tableau:users:delete',
    description: 'Remove the user from a site',
  },
  'tableau:users:update': { name: 'tableau:users:update', description: 'Modify user access' },
  'tableau:groups:create': { name: 'tableau:groups:create', description: 'Create a group' },
  'tableau:groups:delete': { name: 'tableau:groups:delete', description: 'Delete a group' },
  'tableau:users:read': {
    name: 'tableau:users:read',
    description: 'Get a list of groups that a user belongs to',
  },
  'tableau:groups:read': {
    name: 'tableau:groups:read',
    description: 'Get a list of groups on a site',
  },
  'tableau:groups:update': { name: 'tableau:groups:update', description: 'Add a user to a group' },
  'tableau:projects:create': { name: 'tableau:projects:create', description: 'Create a project' },
  'tableau:projects:delete': { name: 'tableau:projects:delete', description: 'Delete a project' },
  'tableau:projects:update': {
    name: 'tableau:projects:update',
    description: 'Update the name, description, or project hierarchy of the project',
  },
  'tableau:permissions:update': {
    name: 'tableau:permissions:update',
    description: 'Add permissions to a data source for a Tableau Server user or group',
  },
  'tableau:permissions:delete': {
    name: 'tableau:permissions:delete',
    description:
      'Delete default permission capabilities of a user or group, for metric, flow, workbook, data source, data role, or lens resources in a project',
  },
  'tableau:permissions:read': {
    name: 'tableau:permissions:read',
    description: 'Get a list of permissions',
  },
  'tableau:sites:update': { name: 'tableau:sites:update', description: 'Update a site' },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Tableau',
  description: 'Connect to your users’ Tableau accounts',
  authUrl: '',
  accessTokenUrl: '',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/tableau`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret ID', id: 'clientSecret' },
    { type: SidebarInputType.ValueText, title: 'Client Secret Value', id: 'clientSecretValue' },
  ],
  scopes: { values: Object.values(scopes) },
  documentationLink: 'https://help.tableau.com/current/online/en-us/connected_apps_scopes.htm',
};
