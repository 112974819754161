import { Dispatch } from 'react';
import { Stripe } from 'stripe';

import * as api from '../../services/api';
import { getErrorMessage } from '../../utils';
import { Action } from '../types';

/**
 *
 * @param projectId
 */
export const getConnectBillingPlans = (projectId: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'CONNECT_BILLING_PLANS_FETCH_START' });
  try {
    const response = await api.get(`/projects/${projectId}/billing/connect/plans`);
    if (response.ok) {
      const plans = await response.json();
      dispatch({
        type: 'CONNECT_BILLING_PLANS_FETCH_SUCCESS',
        payload: plans,
      });
    } else {
      dispatch({
        type: 'CONNECT_BILLING_PLANS_FETCH_FAILURE',
        message: (await getErrorMessage(response)) || 'Error occured while fetching billing plans.',
      });
    }
  } catch (error) {
    dispatch({
      type: 'CONNECT_BILLING_PLANS_FETCH_FAILURE',
      message: error.message,
    });
  }
};

/**
 *
 * @param projectId
 */
export const getConnectSubscription = (projectId: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'CONNECT_BILLING_SUBSCRIPTION_FETCH_START' });
  try {
    const response = await api.get(`/projects/${projectId}/billing/connect/subscription`);
    if (response.ok) {
      const subscription = await response.json();
      dispatch({
        type: 'CONNECT_BILLING_SUBSCRIPTION_FETCH_SUCCESS',
        payload: subscription,
      });
    } else {
      dispatch({
        type: 'CONNECT_BILLING_SUBSCRIPTION_FETCH_FAILURE',
        message:
          (await getErrorMessage(response)) || 'Error occured while fetching connect subscription.',
      });
    }
  } catch (error) {
    dispatch({
      type: 'CONNECT_BILLING_SUBSCRIPTION_FETCH_FAILURE',
      message: error.message,
    });
  }
};

/**
 *
 * @param projectId
 * @param priceId
 * @param quantity
 */
export const getPreviewInvoice = async (
  projectId: string,
  priceId: string,
  quantity: number,
): Promise<Stripe.Invoice> => {
  try {
    const response = await api.post(`/projects/${projectId}/billing/connect/preview-invoice`, {
      priceId,
      quantity,
    });
    if (response.ok) {
      const upcomingInvoice = await response.json();
      return upcomingInvoice;
    } else {
      const message = await getErrorMessage(response);
      throw new Error(message);
    }
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param projectId
 * @param token
 */
export const updateCard = async (projectId: string, token: string): Promise<void> => {
  try {
    const response = await api.post(`/projects/${projectId}/billing/connect/update-card`, {
      token,
    });
    if (response.ok) {
      return;
    } else {
      const message = await getErrorMessage(response);
      throw new Error(message);
    }
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param projectId
 * @param priceId
 * @param quantity
 * @param token
 */
export const checkoutPayment = async (
  projectId: string,
  priceId: string,
  quantity: number,
  token?: string,
) => {
  try {
    const response = await api.post(`/projects/${projectId}/billing/connect/checkout`, {
      priceId,
      quantity,
      paymentToken: token,
    });
    if (response.ok) {
      return;
    } else {
      const message = await getErrorMessage(response);
      throw new Error(message);
    }
  } catch (error) {
    throw error;
  }
};
