import { DEFAULT_SUPPORTED_OPERATORS, Operator } from '@shared/types/sdk/resolvers';

export default DEFAULT_SUPPORTED_OPERATORS.filter(
  (operator: Operator) =>
    ![
      Operator.StringContains,
      Operator.StringDoesNotContain,
      Operator.ArrayIsIn,
      Operator.ArrayIsNotEmpty,
      Operator.ArrayIsEmpty,
      Operator.ArrayIsNotIn,
      Operator.DateTimeAfter,
      Operator.DateTimeBefore,
      Operator.DateTimeEquals,
      Operator.StringStartsWith,
      Operator.StringDoesNotStartWith,
      Operator.StringEndsWith,
      Operator.StringDoesNotEndWith,
      Operator.BooleanTrue,
      Operator.BooleanFalse,
      Operator.StringIsIn,
      Operator.StringIsNotIn,
      Operator.Exists,
      Operator.DoesNotExist,
    ].includes(operator),
);
