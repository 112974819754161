import React from 'react';

import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import {
  CAMPAIGN_CREATE_DATE_FILTER,
  CAMPAIGN_SENT_DATE_FILTER,
  LIST_CAMPAIGN_DATE_FILTER,
  LIST_CREATE_DATE_FILTER,
} from '../shared';
import { LinkWrapperType } from '../shared/types';

import { getLists, getTemplates } from './sources';

const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.DateTimeBefore,
  Operator.DateTimeAfter,
];

export const LinkWrapper: React.FC<LinkWrapperType> = ({
  clickableText,
  href,
}: LinkWrapperType) => (
  <a href={href} target="_blank" rel="noreferrer">
    {clickableText}
  </a>
);

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Campaigns',
          items: [
            {
              value: Intent.CREATE_CAMPAIGN,
              label: 'Create Campaign',
            },
            {
              value: Intent.UPDATE_CAMPAIGN,
              label: 'Update Campaign',
            },
            {
              value: Intent.SEND_CAMPAIGN,
              label: 'Send Campaign',
            },
            {
              value: Intent.SEARCH_CAMPAIGNS,
              label: 'Search Campaigns',
            },
            {
              value: Intent.GET_CAMPAIGN_BY_ID,
              label: 'Get Campaign by ID',
            },
            {
              value: Intent.DELETE_CAMPAIGN_BY_ID,
              label: 'Delete Campaign by ID',
            },
          ],
        },
        {
          title: 'Lists/Audiences',
          items: [
            {
              value: Intent.CREATE_LIST,
              label: 'Create List',
            },
            {
              value: Intent.GET_LIST_BY_ID,
              label: 'Get List by ID',
            },
            {
              value: Intent.SEARCH_LISTS,
              label: 'Search Lists',
            },
            {
              value: Intent.ADD_CONTACT_TO_LIST,
              label: 'Add Contact to List',
            },
            {
              value: Intent.UPDATE_CONTACT_TO_LIST,
              label: 'Update Contact in List',
            },
            {
              value: Intent.GET_CONTACTS_FROM_LIST,
              label: 'Get Contacts from List',
            },
          ],
        },
      ],
    },
  ],
};

// Campaign Intent Inputs
export const listIdInput: SidebarInput = {
  id: 'listId',
  title: 'List ID',
  subtitle:
    'Select a list to send this campaign to. Use Connect Portal Workflow Settings to allow users to select a List.',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: '{{settings.list}}',
  required: true,
};

export const templateInput: SidebarInput = {
  id: 'templateId',
  title: 'Template ID',
  subtitle:
    'The template to use in the email for this campaign. Use Connect Portal Workflow Settings to select a Template.',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: '{{settings.template}}',
  required: false,
};

export const dynamicListInput: SidebarInput = {
  ...listIdInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getLists,
  subtitle: 'Select a list to send this campaign to.',
  placeholder: '',
  getValues: getValuesByCacheKey,
};

export const dynamicTemplateInput: SidebarInput = {
  ...templateInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getTemplates,
  subtitle: 'The template to use in the email for this campaign.',
  placeholder: '',
  getValues: getValuesByCacheKey,
};

export const campaignFieldsInputs: (
  isConnectUi: boolean,
  isUpdating?: boolean,
) => SidebarInput[] = (isConnectUi: boolean, isUpdating: boolean = false) => {
  return [
    {
      id: 'title',
      title: 'Title',
      subtitle: 'The title to describe this campaign in your Mailchimp account.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: !isUpdating,
    },
    { ...(isConnectUi ? listIdInput : dynamicListInput), required: !isUpdating },
    {
      id: 'fromName',
      title: 'From Name',
      subtitle: 'The “From” name for this campaign (not an email address).',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: !isUpdating,
    },
    {
      id: 'replyTo',
      title: 'Reply-To Email Address',
      subtitle: 'The reply-to address for the email sent with this campaign.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: !isUpdating,
    },
    {
      id: 'subjectLine',
      title: 'Subject Line',
      subtitle: 'The subject line to be used in the email sent with this campaign.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: !isUpdating,
    },
    {
      id: 'previewText',
      title: 'Preview Text',
      subtitle: 'The preview text for the email of this campaign.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    { ...(isConnectUi ? templateInput : dynamicTemplateInput), required: false },
  ];
};

export const campaignIdInput: SidebarInput = {
  id: 'campaignId',
  title: 'Campaign ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const campaignFilterInput: SidebarInput = {
  id: 'campaignFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for campaigns that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Created Date', value: CAMPAIGN_CREATE_DATE_FILTER },
    { label: 'Sent Date', value: CAMPAIGN_SENT_DATE_FILTER },
    { label: 'List ID', value: 'list_id' },
    { label: 'Folder ID', value: 'folder_id' },
    { label: 'Member ID', value: 'member_id' },
  ],
};

// List Intent Inputs
export const listFieldsInput: SidebarInput[] = [
  {
    id: 'fromName',
    title: 'From Name',
    subtitle: 'The default From Name for campaigns sent to this list.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
  {
    id: 'fromEmail',
    title: 'From Email',
    subtitle: 'The default From Email for campaigns sent to this list.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
  {
    id: 'defaultSubject',
    title: 'Default Subject',
    subtitle: 'The default subject line for campaigns sent to this list.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
  {
    id: 'language',
    title: 'Language',
    subtitle: 'The default language for campaigns sent to this list.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
];

// List Intent Inputs
export const listContactFieldsInput: SidebarInput[] = [
  {
    id: 'permissionReminder',
    title: 'Permission Reminder',
    subtitle: 'A description for explaining how contacts got added to this list.',
    type: SidebarInputType.TextArea,
    lines: 3,
    required: true,
  },
  {
    id: 'companyName',
    title: 'Company Name',
    subtitle: 'Part of required contact information displayed in campaign footers.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
  {
    id: 'streetAddress',
    title: 'Street Address',
    subtitle: 'Part of required contact information displayed in campaign footers.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
  {
    id: 'city',
    title: 'City',
    subtitle: 'Part of required contact information displayed in campaign footers.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
  {
    id: 'state',
    title: 'State',
    subtitle: 'Part of required contact information displayed in campaign footers.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
  {
    id: 'zip',
    title: 'Zip Code',
    subtitle: 'Part of required contact information displayed in campaign footers.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
  {
    id: 'countryCode',
    title: 'Country Code',
    subtitle: 'Part of required contact information displayed in campaign footers. Defaults to US.',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
  },
];

export const listNameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  subtitle: 'The name for the list in your Mailchimp account.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const listFilterInput: SidebarInput = {
  id: 'listFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for lists that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators: supportedOperators,
  required: false,
  getValues: () => [
    { label: 'Contains Email', value: 'email' },
    { label: 'Created Date', value: LIST_CREATE_DATE_FILTER },
    { label: 'Campaign Last Sent Date', value: LIST_CAMPAIGN_DATE_FILTER },
  ],
};

// List Contact Intent Inputs
export const contactIdentifierInput: SidebarInput = {
  id: 'listContact',
  title: 'Email Address or Contact ID',
  subtitle: 'The email address or contact ID of the contact to update.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const contactEmailInput: SidebarInput = {
  id: 'emailAddress',
  title: 'Email Address',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const contactFieldsInput: (isUpdating?: boolean) => SidebarInput[] = (
  isUpdating: boolean = false,
) => {
  return [
    {
      id: 'contactStatus',
      title: 'Status',
      subtitle: 'Select a status for the new contact. Defaults to Subscribed.',
      type: SidebarInputType.Enum,
      defaultValue: 'subscribed',
      required: false,
      getValues: () => [
        { label: 'Subscribed', value: 'subscribed' },
        ...(isUpdating ? [{ label: 'Unsubscribed', value: 'unsubscribed' }] : []),
        { label: 'Cleaned', value: 'cleaned' },
        { label: 'Pending', value: 'pending' },
        { label: 'Transactional', value: 'transactional' },
      ],
    },
    {
      id: 'contactMergeFields',
      title: 'Merge Fields',
      subtitle: (
        <>
          A dictionary of merge fields, where keys are the merge tags. See{' '}
          <LinkWrapper
            clickableText="Mailchimp documentation"
            href="https://mailchimp.com/en-gb/help/all-the-merge-tags-cheat-sheet/"
          />{' '}
          for available default merge tags.
        </>
      ),
      type: SidebarInputType.Code,
      required: false,
      language: 'json',
      placeholder: `{
  "FNAME": "Brandon",
  "LNAME": "Foo"
}`,
      lines: 5,
      useLightTheme: true,
    },
  ];
};
