import { Operator } from '@shared/types/sdk/resolvers';

import { RecordType } from './types';

export const ADOBE_COMMERCE_API_BASE_URL = 'https://<instance domain>/rest/default';

export const recordIdInput: Record<RecordType, string> = {
  [RecordType.CUSTOMERS]: 'customerId',
  [RecordType.ORDERS]: 'entityId',
  [RecordType.PRODUCTS]: 'sku',
};

export const filterformulaInput: Record<RecordType, string> = {
  [RecordType.CUSTOMERS]: 'customerFilterSearch',
  [RecordType.ORDERS]: 'orderFilterSearch',
  [RecordType.PRODUCTS]: 'productFilterSearch',
};

export const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.NumberEquals,
  Operator.NumberGreaterThan,
  Operator.NumberLessThan,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberLessThanOrEqualTo,
];

/**
 * @summary fields for each recordType to select in a search response
 */
export const selectedFields: Record<RecordType, string> = {
  [RecordType.CUSTOMERS]:
    'id,group_id,default_billing,default_shipping,created_at,updated_at,created_in,dob,email,firstname,lastname,gender,store_id,website_id,addresses',
  [RecordType.PRODUCTS]:
    'id,sku,name,attribute_set_id,status,visibility,type_id,created_at,updated_at,weight',
  [RecordType.ORDERS]:
    'base_grand_total,base_total_due,created_at,customer_email,entity_id,grand_total,increment_id,protect_code,store_name,tax_amount,total_due,total_item_count,updated_at',
};
