import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'ZohoCRM.modules.READ'
  | 'ZohoCRM.modules.CREATE'
  | 'ZohoCRM.modules.UPDATE'
  | 'ZohoCRM.modules.DELETE'
  | 'ZohoCRM.settings.CREATE'
  | 'ZohoCRM.settings.UPDATE'
  | 'ZohoCRM.settings.READ'
  | 'ZohoCRM.settings.DELETE'
  | 'ZohoCRM.users.READ'
  | 'ZohoCRM.users.CREATE'
  | 'ZohoCRM.users.UPDATE'
  | 'ZohoCRM.users.DELETE'
  | 'ZohoCRM.coql.READ';

export const requiredScopes: ScopeName[] = [
  'ZohoCRM.users.READ',
  'ZohoCRM.modules.READ',
  'ZohoCRM.modules.CREATE',
  'ZohoCRM.modules.UPDATE',
  'ZohoCRM.modules.DELETE',
  'ZohoCRM.settings.CREATE',
  'ZohoCRM.settings.UPDATE',
  'ZohoCRM.settings.READ',
  'ZohoCRM.settings.DELETE',
  'ZohoCRM.coql.READ',
];

export const scopes: Record<ScopeName, ScopeValue> = {
  'ZohoCRM.modules.READ': {
    label: 'ZohoCRM.modules.READ',
    name: 'ZohoCRM.modules.READ',
    description: 'Read all the resources in a module',
  },
  'ZohoCRM.modules.CREATE': {
    label: 'ZohoCRM.modules.CREATE',
    name: 'ZohoCRM.modules.CREATE',
    description: 'Add resources to a module',
  },
  'ZohoCRM.modules.UPDATE': {
    label: 'ZohoCRM.modules.UPDATE',
    name: 'ZohoCRM.modules.UPDATE',
    description: 'Update resources in a module',
  },
  'ZohoCRM.modules.DELETE': {
    label: 'ZohoCRM.modules.DELETE',
    name: 'ZohoCRM.modules.DELETE',
    description: 'Delete resources in a module',
  },
  'ZohoCRM.settings.READ': {
    label: 'ZohoCRM.settings.READ',
    name: 'ZohoCRM.settings.READ',
    description: 'Read metadata',
  },
  'ZohoCRM.settings.CREATE': {
    label: 'ZohoCRM.settings.CREATE',
    name: 'ZohoCRM.settings.CREATE',
    description: 'Create metadata',
  },
  'ZohoCRM.settings.UPDATE': {
    label: 'ZohoCRM.settings.UPDATE',
    name: 'ZohoCRM.settings.UPDATE',
    description: 'Update metadata',
  },
  'ZohoCRM.settings.DELETE': {
    label: 'ZohoCRM.settings.DELETE',
    name: 'ZohoCRM.settings.DELETE',
    description: 'Delete metadata',
  },
  'ZohoCRM.users.READ': {
    label: 'ZohoCRM.users.READ',
    name: 'ZohoCRM.users.READ',
    required: true,
    description: 'Retrieve user data',
  },
  'ZohoCRM.users.CREATE': {
    label: 'ZohoCRM.users.CREATE',
    name: 'ZohoCRM.users.CREATE',
    description: 'Add new user to your organization',
  },
  'ZohoCRM.users.UPDATE': {
    label: 'ZohoCRM.users.UPDATE',
    name: 'ZohoCRM.users.UPDATE',
    description: 'Update details of the user, except the email',
  },
  'ZohoCRM.users.DELETE': {
    label: 'ZohoCRM.users.DELETE',
    name: 'ZohoCRM.users.DELETE',
    description: 'Deactivate the user',
  },
  'ZohoCRM.coql.READ': {
    label: 'ZohoCRM.coql.READ',
    name: 'ZohoCRM.coql.READ',
    description: 'To Fetch data using CRM Object Query Language COQL',
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Zoho CRM',
  description: 'Connect to your users’ Zoho CRM accounts',
  authUrl: 'https://accounts.zoho.com/oauth/v2/auth',
  accessTokenUrl: 'https://accounts.zoho.com/oauth/v2/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/zohocrm`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: { values: Object.values(scopes) },
  documentationLink: 'https://www.zoho.com/crm/developer/docs/api/v3/register-client.html',
};
