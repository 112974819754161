import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.PANDA_DOC,
  name: 'PandaDoc',
  description: `Connect to your users' PandaDoc account to manage their documents, contacts, and templates.`,
  icon: iconSvg,
  provider: ProviderType.PANDA_DOC,
  sidebarSections: [],
};

export { authConfig };
export default connectConfig;
