export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;
  OAUTH_ACCESS_TOKEN: string;
  SHOP_NAME: string;
};
export type Environment = {
  SHOPIFY_CLIENT_ID: string;
  SHOPIFY_CLIENT_SECRET: string;
  SHOPIFY_SCOPES?: string;
};

export enum AbandonedCartStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum ProductStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export enum FulFillmentStatus {
  NULL = 'null',
  PARTIAL = 'partial',
  FULFILLED = 'fulfilled',
  RESTOCKED = 'restocked',
}

export enum FinancialStatus {
  PENDING = 'pending',
  AUTHORIZED = 'authorized',
  PARTIALLY_PAID = 'partially_paid',
  PAID = 'paid',
  PARTIALLY_REFUNDED = 'partially_refunded',
  REFUNDED = 'refunded',
  VOIDED = 'voided',
}

export enum InventoryBehaviour {
  BYPASS = 'bypass',
  DECREMENT_IGNORING_POLICY = 'decrement_ignoring_policy',
  DECREMENT_OBEYING_POLICY = 'decrement_obeying_policy',
}

export enum InventoryPolicy {
  DENY = 'deny',
  CONTINUE = 'continue',
}

export type GetAuthUrlDTO = {
  redirectUrl: string;
  SHOP_NAME: string;
  state: Record<string, any>;
};

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
  SHOP_NAME: string;
};

export type ExchangeCredentialOutput = {
  name: string;
  OAUTH_REFRESH_TOKEN: string;
  OAUTH_ACCESS_TOKEN: string;
  SHOP_NAME: string;
};

export type ShopifyAuthResponse = {
  access_token: string;
  scope: string;
};

export type ShopData = {
  email: string;
  name: string;
};

export type ShopInfoResponse = {
  shop: ShopData;
};

export type CustomerAddressDTO = {
  address1?: string;
  address2?: string;
  city?: string;
  province?: string;
  zip?: string;
  province_code?: string;
  country?: string;
  company?: string;
};

export type ProductImageDTO = {
  src: string;
};

export type CustomerDTO = {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  metafield?: any;
  note?: string;
  addresses?: Array<CustomerAddressDTO>;
  company?: string;
  tags?: string;
  send_email_invite?: boolean;
  created_at?: string;
  updated_at?: string;
};

export type ProductVariantDTO = {
  price?: string;
  inventory_policy?: string;
};

export type ProductDTO = {
  id?: string;
  title?: string;
  body_html?: string;
  product_type?: string;
  tags?: string;
  vendor?: string;
  images?: Array<ProductImageDTO>;
  published?: boolean;
  variants?: Array<ProductVariantDTO>;
  published_scope: string;
  created_at?: string;
  updated_at?: string;
};

export type LineItem = {
  title: string;
  price: string;
  quantity: number;
  varient_id: string;
};

export type OrderDTO = {
  id?: string;
  email?: string;
  financial_status?: string;
  fulfillment_status?: string;
  line_items?: Array<LineItem>;
  send_receipt?: boolean;
  inventory_behaviour?: string;
  note?: string;
  created_at?: string;
  updated_at?: string;
};

export type OrderRequestDTO = {
  order: OrderDTO;
};

export type ProductRequestDTO = {
  product: ProductDTO;
};

export type CustomerRequestDTO = {
  customer: CustomerDTO;
};

export type CustomerResponseDTO = CustomerRequestDTO;

export type ProductResponseDTO = ProductRequestDTO;

export type OrderResponseDTO = OrderRequestDTO;

export type CustomersResponseDTO = {
  customers: CustomerDTO[];
};

export type ProductsResponseDTO = {
  products: ProductDTO[];
};

export type OrdersResponseDTO = {
  orders: OrderDTO[];
};

export type GetCustomersDTO = {
  customerIds?: string | string[];
  createdAtMin?: string;
  createdAtMax?: string;
  updatedAtMin?: string;
  updatedAtMax?: string;
  limit?: number;
};

export type SearchCustomerDTO = {
  filterFormula?: string;
  limit?: number;
};

export type CreateCustomerDTO = {
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
  streetAddressLine1?: string;
  streetAddressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  phone?: string;
  tags?: string;
  note?: string;
  sendEmailInvite?: boolean;
  metafields?: string;
};

export type UpdateCustomerDTO = {
  customerId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  streetAddressLine1?: string;
  streetAddressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  phone?: string;
  tags?: string;
  note?: string;
  sendEmailInvite?: boolean;
  metafields?: string;
};

export type CreateOrderDTO = {
  email: string;
  lineItems: string;
  sendReceipt?: boolean;
  fulfillmentStatus?: string;
  financialStatus?: string;
  inventoryBehaviour?: string;
  note?: string;
};

export type UpdateOrderDTO = {
  orderId: string;
  email?: string;
  lineItems?: string;
  sendReceipt?: boolean;
  fulfillmentStatus?: string;
  financialStatus?: string;
  inventoryBehaviour?: string;
  note?: string;
};

export type GetOrdersDTO = {
  orderIds?: string;
  createdAtMin?: string;
  createdAtMax?: string;
  updatedAtMin?: string;
  updatedAtMax?: string;
  limit?: number;
};

export type CreateProductDTO = {
  title: string;
  productType?: string;
  vendor?: string;
  productDescription?: string;
  tags?: string;
  price?: string;
  inventoryPolicy?: string;
  imageUrl?: string;
  isPublished?: boolean;
  publishToPointToSale?: boolean;
};

export type UpdateProductDTO = {
  productId: string;
  title?: string;
  productType?: string;
  vendor?: string;
  productDescription?: string;
  tags?: string;
  price?: string;
  inventoryPolicy?: string;
  imageUrl?: string;
  isPublished?: boolean;
  publishToPointToSale?: boolean;
};

export type GetProductsDTO = {
  productIds?: string;
  productType?: string;
  title?: string;
  vendor?: string;
  status?: ProductStatus;
  createdAtMin?: string;
  createdAtMax?: string;
  updatedAtMin?: string;
  updatedAtMax?: string;
  limit?: number;
};

export type AbandonedCartDTO = {
  id?: number;
  user_id?: string;
  email?: string;
  total_price?: number;
  shipping_address?: string;
  billing_address?: string;
  closed_at?: string;
  phone?: number;
  customer?: string;
  note?: string;
  created_at?: string;
  updated_at?: string;
};

export type AbandonedCartResponseDTO = {
  abandoned_carts: AbandonedCartDTO[];
};

export type GetAbandonedCartDTO = {
  'createdWithInLast-left'?: string;
  'createdWithInLast-right'?: number;
  createdAtMin?: string;
  createdAtMax?: string;
  createdAfterId?: number;
  status?: AbandonedCartStatus;
  limit?: number;
};

export type UserIdentityResponse = {
  user: CustomerDTO;
};

export type ShopConfigResponse = {
  shop: {
    id: string;
  };
};

export type SubscribeWebhookDTO = {
  address: string;
  topic: string;
};
export type SubscribeWebhookRequest = {
  webhook: {
    topic: string;
    address: string;
    format: string;
  };
};

export type SubscribeWebhookResponse = {
  webhook: {
    id: number;
    address: string;
    topic: string;
    created_at: string;
    updated_at: string;
    format: string;
    fields: [string];
    metafield_namespaces: [string];
    api_version: string;
    private_metafield_namespaces: [string];
  };
};
