import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SAGE_ACCOUNTING;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SAGE_ACCOUNTING_TEST_CONNECTION',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ACCESS_TOKEN = 'SAGE_ACCOUNTING_ACCESS_TOKEN',
  REFRESH_TOKEN = 'SAGE_ACCOUNTING_REFRESH_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
};

export enum EnvironmentKeys {
  SAGE_ACCOUNTING_CLIENT_ID = 'SAGE_ACCOUNTING_CLIENT_ID',
  SAGE_ACCOUNTING_CLIENT_SECRET = 'SAGE_ACCOUNTING_CLIENT_SECRET',
  SAGE_ACCOUNTING_SCOPES = 'SAGE_ACCOUNTING_SCOPES',
}

export type Environment = {
  [EnvironmentKeys.SAGE_ACCOUNTING_CLIENT_ID]: string;
  [EnvironmentKeys.SAGE_ACCOUNTING_CLIENT_SECRET]: string;
  [EnvironmentKeys.SAGE_ACCOUNTING_SCOPES]?: string;
};
