import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs/config';
import { supportedOperators } from '../shared/constants';
import { LinkWrapperType } from '../shared/types';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Issues',
          items: [
            {
              value: Intent.CREATE_ISSUE,
              label: 'Create Issue',
            },
            {
              value: Intent.UPDATE_ISSUE,
              label: 'Update Issue',
            },
            {
              value: Intent.GET_ISSUE_BY_ID,
              label: 'Get Issue by ID',
            },
            {
              value: Intent.GET_ISSUE_BY_ISSUE_IDENTIFIER,
              label: 'Get Issue by Issue Identifier',
            },
            {
              value: Intent.SEARCH_ISSUE,
              label: 'Search Issues',
            },
            {
              value: Intent.DELETE_ISSUE,
              label: 'Delete Issue',
            },
            {
              value: Intent.ARCHIVE_ISSUE,
              label: 'Archive Issue',
            },
            {
              value: Intent.CREATE_SUB_ISSUE,
              label: 'Create Sub-issue',
            },
          ],
        },
        {
          title: 'Projects',
          items: [
            {
              value: Intent.CREATE_PROJECT,
              label: 'Create Project',
            },
            {
              value: Intent.UPDATE_PROJECT,
              label: 'Update Project',
            },
            {
              value: Intent.GET_PROJECT_BY_ID,
              label: 'Get Project by ID',
            },
            {
              value: Intent.DELETE_PROJECT,
              label: 'Delete Project',
            },
          ],
        },
        {
          title: 'Teams',
          items: [
            {
              value: Intent.SEARCH_TEAMS,
              label: 'Search Teams',
            },
          ],
        },
      ],
    },
  ],
};

export const connectPortalListInput: SidebarInput = {
  id: 'teamId',
  title: 'Team Id',
  subtitle:
    "Use Connect Portal User Settings to allow users to select a Linear Team's id.  Leaving this blank will trigger this workflow for every public team.",
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const teamIdInput: SidebarInput = {
  id: 'teamId',
  title: 'Team ID',
  subtitle:
    'Specify the Team ID of the parent for this new issue. Use Connect Portal Workflow Settings to allow users to select a Team ID.',
  type: SidebarInputType.TextArea,
  placeholder: 'a70bdf0f-530a-4887-857d-46151b52b47c',
  lines: 1,
  required: true,
};

export const issueIdInput: SidebarInput = {
  id: 'issueId',
  title: 'Issue ID',
  subtitle: 'Specify the Issue ID of the issue to update.',
  type: SidebarInputType.TextArea,
  placeholder: '90fbc706-18cd-42c9-ae66-6bd344cc8977',
  lines: 1,
  required: true,
};

export const titleInput: SidebarInput = {
  id: 'title',
  title: 'Title',
  subtitle: 'Specify a title for this issue.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  title: 'Description',
  subtitle: 'Specify a description for this issue.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const statusInput: SidebarInput = {
  id: 'statusId',
  title: 'Status',
  subtitle: 'Specify the state or status of this issue.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const priorityInput: SidebarInput = {
  id: 'priority',
  title: 'Priority',
  subtitle: 'Specify the priority of this issue as an integer.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const dueDateInput: SidebarInput = {
  id: 'dueDate',
  title: 'Due Date',
  subtitle: 'Specify the due date of this issue in ISO 8601 format.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const cycleIdInput: SidebarInput = {
  id: 'cycleId',
  title: 'Cycle ID',
  subtitle: 'Specify the cycle associated with this issue.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const LinkWrapper: React.FC<LinkWrapperType> = ({
  clickableText,
  href,
}: LinkWrapperType) => (
  <a href={href} target="_blank" rel="noreferrer">
    {clickableText}
  </a>
);

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  title: 'Additional Fields',
  required: false,
  subtitle: (
    <>
      Specify additional fields to include on this issue as JSON. For example, you may associate an
      assignee or label here.{' '}
      <LinkWrapper
        clickableText="See the docs"
        href="https://studio.apollographql.com/public/Linear-API/schema/reference/objects/Issue?variant=current"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `{
  assigneeId: "a70bdf0f-530a-4887-857d-46151b52b47c",
  labelIds: ["a70bdf0f-530a-4887-857d-46151b52b47c"]
}`,
  useLightTheme: true,
};

export const upsertIssueFields = (isUpdate: boolean = false): SidebarInput[] => [
  ...(isUpdate ? [issueIdInput] : []),
  ...(isUpdate ? [] : [teamIdInput]),
  { ...titleInput, required: !isUpdate },
  descriptionInput,
  statusInput,
  priorityInput,
  dueDateInput,
  cycleIdInput,
  additionalFieldsInput,
];

export const externalIdInput: SidebarInput = {
  id: 'externalId',
  title: 'External ID',
  subtitle: 'Specify the human-readable Issue identifier of the issue to fetch.',
  type: SidebarInputType.TextArea,
  placeholder: 'ABC-1',
  lines: 1,
  required: true,
};

export const issueFilterInput: SidebarInput = {
  id: 'issueFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: false,
  getValues: () => [
    { label: 'Title', value: 'title' },
    { label: 'Number', value: 'number' },
    { label: 'Project', value: 'project' },
    { label: 'Created At', value: 'createdAt' },
  ],
};

export const parentIdInput: SidebarInput = {
  id: 'parentId',
  title: 'Parent ID',
  subtitle: 'Specify the Issue ID for the parent of this new issue.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const projectIdInput: SidebarInput = {
  id: 'projectId',
  title: 'Project ID',
  subtitle: 'Specify the ID of the project to update.',
  type: SidebarInputType.TextArea,
  placeholder: 'a6634484-6061-4ac7-9739-7dc5e52c796b',
  lines: 1,
  required: true,
};

export const projectNameInput: SidebarInput = {
  id: 'projectName',
  title: 'Project Name',
  subtitle: 'Specify the name of the project to create.',
  type: SidebarInputType.TextArea,
  placeholder: 'My Linear Project',
  lines: 1,
  required: true,
};

export const teamIdsInput: SidebarInput = {
  id: 'teamIds',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 3,
  title: 'Team ID',
  required: true,
  subtitle:
    'Specify the team ID(s) this project is associated with as a string or a JSON array. Use Connect Portal User Settings to allow your user to select a Team ID.',
  placeholder: `[ 
  "a70bdf0f-530a-4887-857d-46151b52b47c", 
  "4ac7..."
]`,
  useLightTheme: true,
};

export const upsertProjectFields = (isUpdate: boolean = false): SidebarInput[] => [
  ...(isUpdate ? [projectIdInput] : []),
  ...(isUpdate ? [] : [teamIdsInput]),
  {
    ...projectNameInput,
    subtitle: isUpdate ? 'Specify the name of the project to update.' : projectIdInput.subtitle,
    required: !isUpdate,
  },
  {
    ...descriptionInput,
    title: 'Project Description',
    subtitle: 'Specify a description for this project.',
    lines: 3,
  },
  {
    ...additionalFieldsInput,
    subtitle: (
      <>
        Specify additional fields to include on this project as JSON. For example, you may associate
        the issue with a Linear Project here.{' '}
        <LinkWrapper
          clickableText="See the docs"
          href="https://studio.apollographql.com/public/Linear-API/explorer?variant=current"
        />{' '}
        for available fields.
      </>
    ),
    placeholder: `{
  state: "planned",
  description: ""
}`,
  },
];

export const queryTermInput: SidebarInput = {
  id: 'queryTerm',
  title: 'Query Term',
  subtitle: 'The search term to look for.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const teamFilterInput: SidebarInput = {
  id: 'teamFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for teams that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators,
  required: false,
  getValues: () => [
    { label: 'ID', value: 'id' },
    { label: 'Name', value: 'name' },
  ],
};
