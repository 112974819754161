import React from 'react';

import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { SidebarInput, SidebarInputType, TokenType } from '@shared/types/sdk/actions';

import { CredentialKeys } from '../configs';
import { AssetType, DataQualityWarning } from '../shared/types';

import { assetItems } from './sources';

export const serverNameInput: SidebarInput = {
  id: CredentialKeys.TABLEAU_SERVER_NAME,
  title: 'Add your Tableau Server Name',
  subtitle: 'Found in the site URL, e.g. https://<server-name>/#/site/site-id/views',
  placeholder: 'server-name',
  type: SidebarInputType.ValueText,
  required: true,
};

export const siteIdInput: SidebarInput = {
  id: CredentialKeys.TABLEAU_SITE_ID,
  title: 'Add your Tableau Site ID',
  subtitle: 'Found in the site URL, e.g. https://server-name/#/site/<site-id>/views',
  placeholder: 'site-id',
  type: SidebarInputType.ValueText,
};

export const personalTokenName: SidebarInput = {
  id: CredentialKeys.TABLEAU_PERSONAL_ACCESS_TOKEN_NAME,
  title: 'Add your Tableau Personal Access Token Name',
  subtitle: (
    <>
      Create a Personal Access Token in your{' '}
      <a
        href="https://help.tableau.com/current/pro/desktop/en-us/useracct.htm#create-and-revoke-personal-access-tokens"
        target="_blank"
        rel="noreferrer"
      >
        Tableau Account Settings
      </a>
      .
    </>
  ),
  placeholder: 'Tableau Personal Access Token Name',
  type: SidebarInputType.ValueText,
  hiddenInConnect: true,
};

export const personalTokenSecret: SidebarInput = {
  id: CredentialKeys.TABLEAU_PERSONAL_ACCESS_TOKEN,
  title: 'Add your Tableau Personal Access Token Secret',
  placeholder: 'Tableau Personal Access Token',
  type: SidebarInputType.ValueText,
  hiddenInConnect: true,
};

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name your Tableau account',
  placeholder: 'e.g. Production, Testing, etc.',
  type: SidebarInputType.ValueText,
  required: true,
  hiddenInConnect: true,
};

export const emailInput: SidebarInput = {
  id: CredentialKeys.TABLEAU_USER_EMAIL,
  title: 'Email address of the authenticated Tableau user',
  placeholder: 'testing@example.com',
  type: SidebarInputType.ValueText,
  required: true,
  hiddenInClassic: true,
};

export const impersonatedCredentialsInput: SidebarInput[] = [
  {
    ...serverNameInput,
    ...{
      type: SidebarInputType.UserSuppliedCredential,
      title: 'User’s Tableau server name',
      supportedTokenTypes: [TokenType.TABLEAU_SERVER_NAME],
      providerType: ProviderType.TABLEAU,
      hideTokenDropDown: true,
    },
  },
  {
    ...siteIdInput,
    ...{
      type: SidebarInputType.UserSuppliedCredential,
      title: 'User’s Tableau site ID',
      supportedTokenTypes: [TokenType.TABLEAU_SITE_ID],
      providerType: ProviderType.TABLEAU,
      hideTokenDropDown: true,
    },
  },
  {
    ...personalTokenName,
    ...{
      type: SidebarInputType.UserSuppliedCredential,
      title: 'User’s Tableau personal access token name',
      supportedTokenTypes: [TokenType.TABLEAU_PERSONAL_ACCESS_TOKEN_NAME],
      providerType: ProviderType.TABLEAU,
      hideTokenDropDown: true,
    },
  },
  {
    ...personalTokenSecret,
    ...{
      type: SidebarInputType.UserSuppliedCredential,
      title: 'User’s Tableau personal access token secret',
      supportedTokenTypes: [TokenType.TABLEAU_PERSONAL_ACCESS_TOKEN_SECRET],
      providerType: ProviderType.TABLEAU,
      hideTokenDropDown: true,
    },
  },
];

export const contentTypeInput: SidebarInput = {
  id: 'contentType',
  type: SidebarInputType.Enum,
  title: 'Content type',
  subtitle: 'The type of asset the data quality warning will be added to.',
  getValues: () => [
    {
      label: 'Database',
      value: AssetType.DATABASE,
    },
    {
      label: 'Table',
      value: AssetType.TABLE,
    },
    {
      label: 'Datasource',
      value: AssetType.DATA_SOURCE,
    },
    {
      label: 'Flow',
      value: AssetType.FLOW,
    },
  ],
  required: true,
};

export const contentIdInput: SidebarInput = {
  id: 'contentId',
  type: SidebarInputType.EditableDynamicEnum,
  title: 'Content ID',
  subtitle:
    'The unique ID of the asset the data quality warning will be added to. Only one warning can be added to an asset. If one already exists, use Update data quality warning instead.',
  placeholder: 'choose a contentId or type {{ to insert a content id',
  required: true,
  source: assetItems,
  getValues: getValuesByCacheKey,
};

export const impersonateContentIdInput: SidebarInput = {
  id: 'contentId',
  type: SidebarInputType.TextArea,
  title: 'Content ID',
  subtitle:
    'The unique ID of the asset the data quality warning will be added to. Only one warning can be added to an asset. If one already exists, use Update data quality warning instead.',
  lines: 1,
};

export const warningTypeInput: SidebarInput = {
  id: 'warningType',
  type: SidebarInputType.Enum,
  title: 'Type',
  getValues: () => [
    {
      label: 'Deprecated',
      value: DataQualityWarning.DEPRECATED,
    },
    {
      label: 'Warning',
      value: DataQualityWarning.WARNING,
    },
    {
      label: 'Stale',
      value: DataQualityWarning.STALE,
    },
    {
      label: 'Sensitive Data',
      value: DataQualityWarning.SENSITIVE_DATA,
    },
    {
      label: 'Maintenance',
      value: DataQualityWarning.MAINTENANCE,
    },
  ],
  subtitle: 'Type of data quality warning to apply to the asset.',
  required: true,
};

export const statusInput: SidebarInput = {
  id: 'status',
  type: SidebarInputType.BooleanInput,
  title: 'Status',
  subtitle: 'Status of the data quality warning.',
  required: false,
};

export const messageInput: SidebarInput = {
  id: 'message',
  type: SidebarInputType.TextArea,
  title: 'Message',
  subtitle: 'A custom message to accompany the data quality warning.',
  required: false,
};

export const isSevereInput: SidebarInput = {
  id: 'isSevere',
  type: SidebarInputType.BooleanInput,
  title: 'Is severe',
  subtitle: 'Enables high visibility for the data quality warning.',
  required: false,
};

export const warningIdInput: SidebarInput = {
  id: 'warningId',
  type: SidebarInputType.TextArea,
  title: 'Data Quality Warning ID',
  subtitle: 'The ID of the data quality warning that will be updated.',
  lines: 1,
  required: true,
};
