import React, { useEffect } from 'react';
import styled from 'styled-components';

import { IProject } from '@shared/entities/sdk/project/project.interface';
import { COLORS } from '@shared/ui/sdk/utils/constants';
import { FontPrimary } from '@shared/ui/sdk/utils/styles';

import { throttledAccountUsage } from '../store/operations/auth';
import { AuthState, State, useDispatch, useSelector } from '../store/types';

const Banner = styled.div`
  ${FontPrimary}

  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.WHITE};
  padding: 10px;
  text-align: center;

  a {
    color: ${COLORS.WHITE};
  }
`;

export const TaskLimitReached = () => {
  const dispatch = useDispatch();

  const projectId: string | undefined = useSelector((state: State) => state.navigation.projectId);
  const project: IProject | undefined = useSelector((state: State) =>
    projectId ? state.entities.projects.entities[projectId] : undefined,
  );
  const { usage: accountData, usageLoading }: AuthState = useSelector((state: State) => state.auth);

  useEffect(() => {
    if (project && !project.isConnectProject && !usageLoading && !accountData) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(throttledAccountUsage(project.id));
    }
  }, [project, accountData, usageLoading]);

  if (!accountData || accountData.canExecuteTasks || project?.isConnectProject) {
    return null;
  }

  if (accountData.status == 'active') {
    return (
      <Banner>
        You’ve reached your monthly task limit. Your workflows will be paused until you upgrade your
        plan. <a href={`/api/billing?projectId=${projectId}`}>Upgrade Plan</a>
      </Banner>
    );
  } else {
    return (
      <Banner>
        We were unable to process your payment. To avoid service interruption, please update your
        payment info. <a href={`/api/billing?projectId=${projectId}`}>Update Card</a>
      </Banner>
    );
  }
};
