import { Lens } from 'monocle-ts';

import { IConnectWorkflow } from '@shared/entities/sdk/workflow/workflow.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<IConnectWorkflow> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<IConnectWorkflow> = initialState,
  action: Action,
): EntitiesState<IConnectWorkflow> {
  switch (action.type) {
    case 'CONNECTED_WORKFLOW_FETCH_START':
      return {
        ...state,
        processing: true,
      };
    case 'CONNECTED_WORKFLOW_FETCH_SUCCESS':
      return {
        ...Lens.fromProp<EntitiesState<IConnectWorkflow>>()('entities').modify(
          (entities: Record<string, IConnectWorkflow>) => {
            return {
              ...entities,
              ...indexBy('id', action.payload),
            };
          },
        )(state),
        processing: false,
      };
    case 'CONNECTED_WORKFLOW_FETCH_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'RESET_CONNECT_WORKFLOWS':
      return initialState;

    default:
      return state;
  }
}
