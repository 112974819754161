import { format, parse } from 'url';

import { ResizeObserver } from '@juggle/resize-observer';
import cookie from 'js-cookie';
import JSON5 from 'json5';
import { useRouter } from 'next/router';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import { ActionSteps } from '@shared/actions/sdk';
import { ICredential, IDetailedConnectIntegration, IOrganization } from '@shared/entities/sdk';
import {
  CachedConnectCredential,
  IConnectCredentialWithPersona,
} from '@shared/entities/sdk/credential/connectCredential.interface';
import { IProject } from '@shared/entities/sdk/project/project.interface';
import { ITeam, TeamMemberRole } from '@shared/entities/sdk/team/team.interface';
import { ILoggedInUser, OnboardingStage } from '@shared/entities/sdk/user/user.interface';
import { debug } from '@shared/logger/sdk/legacy';
import {
  Action,
  ActionConfig,
  ActionResponse,
  ActionResponseType,
  ActionStep,
  ActionStepParameters,
  ActionStepView,
  ActionTriggerStep,
  DynamicInput,
  DynamicInputRefreshStatus,
  SidebarInput,
  SidebarSection,
} from '@shared/types/sdk/actions';
import {
  DynamicMappingField,
  DynamicMappingOptions,
  IntegrationSharedMeta,
  IntegrationWorkflowMeta,
  SerializedConnectInput,
} from '@shared/types/sdk/connect';
import { DataType, KeyedSource, ValueSource } from '@shared/types/sdk/resolvers';
import { ExecutionStatus, StateMachine, Step, StepMap, StepType } from '@shared/types/sdk/steps';
import { BillingPlan } from '@shared/types/sdk/stripe';
import { WorkflowVersion } from '@shared/types/sdk/version';
import { COLORS } from '@shared/ui/sdk/utils/constants';
import { isDate } from '@shared/utils/sdk/generic';
import { isTrigger } from '@shared/workflow/sdk';
import featureRestrictions, {
  WorkflowFeatureRestriction,
} from '@shared/workflow/sdk/featureRestrictions';
import {
  getDownstreamSteps,
  getUpstreamSteps,
  workflowStepsToStateMachine,
} from '@shared/workflow/sdk/stateMachine';

import {
  RouteElement,
  getCachedConnectCredentialFromState,
  getEditingStepId,
  getTraversal,
  pushRoute,
  pushRouteInWorkflowEditor,
  replaceRoute,
} from '..';
import { stepLens, stepTypePrism } from '../../store/lenses';
import { execute } from '../../store/operations/actions';
import { getOrganizations } from '../../store/operations/entities/organizations';
import { createProjects, getProjects } from '../../store/operations/entities/project';
import { getWorkflowFromEntity } from '../../store/operations/entities/step';
import { getTeams } from '../../store/operations/entities/team';
import {
  INITIAL_PAGE,
  TaskHistoryFilters,
  getWorkflowLogs,
} from '../../store/operations/entities/workflowLog';
import {
  ConfirmationOptions,
  IntegrationConfigEntity,
  MessagePart,
  NavigationState,
  PastStepExecution,
  State,
  StepExecution,
  WorkflowEntity,
  useDispatch,
  useSelector,
} from '../../store/types';
import { getBillingPlanFromState } from '../billing';

/**
 * custom hook to return projects
 */
export function useProjects(): [
  processing: boolean,
  activeProject?: IProject,
  connectProject?: IProject,
  classicProject?: IProject,
] {
  const team: ITeam | undefined = useCurrentTeam();
  const projects = useSelector((state: State) => Object.values(state.entities.projects.entities));
  const processing = useSelector((state: State) => state.entities.projects.processing);
  const isConnectUiActive: boolean = cookie.get('activeProjectType') === 'connect';
  const isClassicUiActive: boolean = cookie.get('activeProjectType') === 'default';
  if (projects.length > 0 && team) {
    const currentTeamProjects = projects.filter((project) => project.teamId === team.id);
    const connectProject: IProject | undefined = currentTeamProjects.find(
      (project: IProject) => project.isConnectProject,
    );
    const classicProject: IProject | undefined = currentTeamProjects.find(
      (project: IProject) => !project.isConnectProject,
    );
    const activeProject: IProject | undefined = ((): IProject | undefined => {
      if (!connectProject && !classicProject) {
        return undefined;
      } else if (connectProject && (!classicProject || isConnectUiActive)) {
        return connectProject;
      } else if (classicProject && (!connectProject || isClassicUiActive)) {
        return classicProject;
      }

      // ts not recognizing connectProject or classicProject as non undefined here
      if (!connectProject || !classicProject) {
        return connectProject ?? classicProject;
      }

      // With the roll out of Paragon Connect in v2.0.0, we want to default to showing a connect project if the user hasn't actively navigated between projects.
      // A bug was introduced during this roll out (PARA-2908) for classic users that had never used connect but had a connect project created for them on login.

      // Because those classic users had never navigated back and forth between classic and connect projects,
      // when they opened the dashboard for the first time they were directed to their new connect project instead of their existing classic project.

      // If this code below is being called, it means the user has both classic and connect projects available but hasn't navigated between them.
      // In this case, we'll check if the connect project was just created for them (within the last minute).
      // If it was and their classic project has been around longer than that, we'll send them to their older classic project. Otherwise, we'll send them to connect.
      const connectProjectCreatedAt: number = isDate(connectProject.dateCreated)
        ? connectProject.dateCreated.getTime()
        : new Date(connectProject.dateCreated).getTime();
      const classicProjectCreatedAt: number = isDate(classicProject.dateCreated)
        ? classicProject.dateCreated.getTime()
        : new Date(classicProject.dateCreated).getTime();
      const now: number = Date.now();

      if (now - connectProjectCreatedAt < 1000 * 60 && now - classicProjectCreatedAt > 1000 * 60) {
        cookie.set('activeProjectType', 'default');
        return classicProject;
      } else {
        cookie.set('activeProjectType', 'connect');
        return connectProject;
      }
    })();
    return [processing, activeProject, connectProject, classicProject];
  }
  return [processing, undefined, undefined, undefined];
}

/**
 * Custom hook to create projects if user doesn't have projects
 */
export function useCreateProjects(): [
  processing: boolean,
  activeProject?: IProject,
  connectProject?: IProject,
  classicProject?: IProject,
] {
  const dispatch = useDispatch();
  const [checkingProjects, setCheckingProjects] = useState<boolean>(true);
  const projectState = useSelector((state: State) => state.entities.projects);
  const team: ITeam | undefined = useCurrentTeam();
  const [processing, activeProject, connectProject, classicProject] = useProjects();
  const organization: IOrganization | undefined = useCurrentOrganization();
  const teamId: string | undefined = team?.id;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(getOrganizations());
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(getTeams());
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async (): Promise<void> => {
      if (teamId && checkingProjects && !processing) {
        setCheckingProjects(false);
        !Object.keys(projectState.entities).length && (await dispatch(getProjects(teamId)));
      }
    })();
  }, [teamId, projectState.entities, checkingProjects, processing]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async (): Promise<void> => {
      /**
       * not call createProjects api if already made request for it
       * as we are making createProjects request immeditately after signup success
       * in auth/signup Operations
       */
      if (
        !processing &&
        !checkingProjects &&
        Object.keys(projectState.entities).length < 2 &&
        teamId &&
        organization
      ) {
        await dispatch(createProjects(organization.name, organization.id, teamId));
      }
    })();
  }, [checkingProjects, teamId, processing]);

  return [processing, activeProject, connectProject, classicProject];
}

// TODO figure out return type
// tslint:disable-next-line:typedef
export function useEditingStep<T extends StepType>(stepType?: T) {
  const sidebarView = useSelector((state: State) => state.workflowEditor.sidebar.view);

  return useSelector((state: State) => {
    if (!sidebarView) {
      return null;
    }
    const stepId: string = getEditingStepId(sidebarView);
    // TODO there's gotta be a simpler way of getting the value of an Optional
    return getTraversal(
      stepLens(stepId).composePrism(stepTypePrism(stepType)).asTraversal(),
      state.entities.steps,
    );
  });
}

export function useDuplicateWorkflowRedirect(projectId: string): void {
  const router = useRouter();
  const dispatch = useDispatch();
  const duplicatedWorkflowId: string | undefined = useSelector(
    (state: State) => state.entities.workflows.duplicatedWorkflowId,
  );
  const isConnectUI = useSelector((state: State) => state.navigation.isConnectUi ?? false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async (): Promise<void> => {
      if (duplicatedWorkflowId) {
        dispatch({
          type: 'UNSET_WORKFLOW_ENTITY_DUPLICATED_WORKFLOW_ID',
        });
        dispatch({
          type: 'NAVIGATE',
          projectId,
          workflowId: duplicatedWorkflowId,
        });
        /* Fix:PARA-2803 Adding a ternary here as pushRouteInWorkflowEditor is to be used 
          for changes within workflowEditor states. At time of classic duplicate workflow there is 
          no workflowId present in the query params . So it causes the app to crash*/
        router.query.workflowId
          ? await pushRouteInWorkflowEditor(router, [], undefined, undefined, duplicatedWorkflowId)
          : await pushRoute(router, '/workflows', [
              isConnectUI
                ? { path: 'connect', key: 'connect' }
                : { path: 'classic', key: 'classic' },
              { path: 'projects', key: 'projectId', value: projectId },
              { path: 'workflows', key: 'workflowId', value: duplicatedWorkflowId },
            ]);
      }
    })();
  }, [duplicatedWorkflowId]);
}

export function useStepsForWorkflowId(workflowId: string): Step[] {
  const steps: Record<string, Step> = useSelector((state: State) => state.entities.steps.entities);
  const workflows: Record<string, WorkflowEntity | undefined> = useSelector(
    (state: State) => state.entities.workflows.entities,
  );
  const workflow = workflows[workflowId];
  return workflow?.stepIds.map((stepId: string) => steps[stepId]) || [];
}

export function useWorkflowIdForStep(step: Step): string {
  const workflows: WorkflowEntity[] = useSelector((state: State) =>
    Object.values(state.entities.workflows.entities),
  );
  const workflow: WorkflowEntity = workflows.find((w: WorkflowEntity) =>
    w.stepIds.includes(step.id),
  )!;

  if (step.workflowId) {
    return step.workflowId;
  }
  return workflow.id;
}

export function useUpstreamSteps(workflowId: string, stepId?: string): Step[] {
  const sidebarView = useSelector((state: State) => state.workflowEditor.sidebar.view);
  const workflow = useSelector((state: State) =>
    workflowId ? getWorkflowFromEntity(state, workflowId) : undefined,
  );

  if (!stepId) {
    if (!sidebarView) {
      return [];
    }
    stepId = getEditingStepId(sidebarView);
  }

  if (!workflow || !stepId) {
    return [];
  }

  const stateMachine = workflowStepsToStateMachine(Object.values(workflow.steps));
  return getUpstreamSteps(stepId, stateMachine);
}

export function useDownstreamSteps(workflowId: string, stepId?: string): Step[] {
  const sidebarView = useSelector((state: State) => state.workflowEditor.sidebar.view);
  const workflow = useSelector((state: State) =>
    workflowId ? getWorkflowFromEntity(state, workflowId) : undefined,
  );

  if (!stepId) {
    if (!sidebarView) {
      return [];
    }
    stepId = getEditingStepId(sidebarView);
  }

  if (!workflow || !stepId) {
    return [];
  }

  const stateMachine = workflowStepsToStateMachine(Object.values(workflow.steps));
  return getDownstreamSteps(stepId, stateMachine);
}

export type DynamicInputProps = {
  inputRefreshStatus: DynamicInputRefreshStatus;
  refreshDynamicInput: (params: ActionStepParameters, input: DynamicInput) => Promise<void>;
};
export function useDynamicSidebarInput(
  input: DynamicInput,
  section: SidebarSection,
  step: ActionStep | ActionTriggerStep,
  actionParametersOverride?: KeyedSource<DataType.ANY>[],
): DynamicInputProps {
  const dispatch = useDispatch();
  const workflowId = useWorkflowIdForStep(step);
  const [inputRefreshStatus, setInputRefreshStatus] = useState<DynamicInputRefreshStatus>(
    DynamicInputRefreshStatus.PRISTINE,
  );
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const parameters = { ...step.parameters };
  if (actionParametersOverride) {
    parameters.actionParameters = actionParametersOverride;
  }

  const refreshDependencies: any[] | undefined =
    input.source.refreshDependencies &&
    input.source.refreshDependencies.map(
      (dependency: string | ((options: ActionStepParameters) => any)) => {
        if (typeof dependency === 'string') {
          return (
            parameters.actionParameters.find(
              (value: KeyedSource<DataType.ANY>) => value.key === dependency,
            )?.source as ValueSource
          )?.value;
        }
        return dependency(parameters);
      },
    );

  function isTestConnectionButton(input: DynamicInput): boolean {
    return input.id === 'TEST_CONNECTION';
  }

  useEffect(() => {
    if (refreshDependencies) {
      setShouldRefresh(true);
    }
  }, refreshDependencies);

  useEffect(() => {
    if (shouldRefresh) {
      refreshDynamicInput(
        input.source.getRefreshActionParameters(parameters, section.inputs, input as SidebarInput),
        input,
      )
        .then(() => {
          setShouldRefresh(false);
        })
        .catch(() => {
          setShouldRefresh(false);
          setInputRefreshStatus(DynamicInputRefreshStatus.FAILED);
        });
    }
  }, [shouldRefresh]);

  async function refreshDynamicInput(
    params: ActionStepParameters,
    input: DynamicInput,
  ): Promise<void> {
    if (inputRefreshStatus === DynamicInputRefreshStatus.REFRESHING) {
      return debug('refreshInput > already refreshing or refreshed', input.id);
    }

    if (isTestConnectionButton(input)) {
      dispatch({ type: 'SET_ACTION_TEST_CONNECTION_STARTED' });
    }
    setInputRefreshStatus(DynamicInputRefreshStatus.REFRESHING);
    const { actionType, intent, actionParameters, credentials } = params;

    const response = await dispatch(
      execute(workflowId, step, actionType, intent, actionParameters, credentials),
    );
    const values = input.source.mapRefreshToValues(response);
    if (input.source.onRefresh) {
      handleDynamicInputRefreshResponse(input.source.onRefresh(values));
    }

    if (input.source.cacheKey && response) {
      dispatch({
        type: 'SET_ACTION_PARAMETER',
        stepId: step.id,
        key: input.source.cacheKey,
        value: {
          dataType: DataType.ANY,
          type: 'VALUE',
          value: values,
        },
        stepType: step.type,
      });
    }

    setInputRefreshStatus(DynamicInputRefreshStatus.DONE);
  }

  function handleDynamicInputRefreshResponse(intent: ActionResponse): void {
    if (intent.type === ActionResponseType.ALERT) {
      if (isTestConnectionButton(input)) {
        dispatch({ type: 'SET_ACTION_TEST_CONNECTION_END', payload: intent });
      } else {
        alert(intent.message);
      }
    }
    if (intent.type === ActionResponseType.DISPATCH) {
      dispatch({
        type: 'SET_ACTION_PARAMETER',
        stepId: step.id,
        key: intent.id,
        value: {
          ...intent.source,
          dataType: DataType.ANY,
        },
        stepType: step.type,
      });
    }
  }

  return { inputRefreshStatus, refreshDynamicInput };
}

export function useTriggerStep(): Step | undefined {
  return useSelector((state: State) => {
    const stepMap: StepMap = state.entities.steps.entities;
    for (const step of Object.values(stepMap)) {
      // TODO: ensure step has correct workflow id
      if (isTrigger(step)) {
        return step;
      }
    }
  });
}

/**
 * given a step id, returns the latest stored step execution for it
 * NOTE: doesn't make an API call to fetch from server
 * @param stepId id of the step to find step executions for
 * @param filterComplete if set to true, it filters out active step executions
 */
export function useLatestStepExecutionForStep(
  stepId: string,
  filterComplete: boolean = false,
): StepExecution | undefined {
  const latestExecution: StepExecution | undefined = useSelector((state: State) => {
    const stepExecutionEntries: [string, StepExecution][] = Object.entries(
      state.entities.stepExecutions.entities,
    ).filter(([id, e]: [string, StepExecution]) => {
      if (stepId && id !== stepId) {
        return false;
      } else if (filterComplete && (!e.start || e.status === ExecutionStatus.EXECUTING)) {
        return false;
      }

      return true;
    });

    if (!filterComplete) {
      return stepExecutionEntries.length ? stepExecutionEntries[0][1] : undefined;
    }

    return stepExecutionEntries
      .map(([, e]: [string, StepExecution]): PastStepExecution => e as PastStepExecution)
      .sort(
        (e1: PastStepExecution, e2: PastStepExecution) =>
          new Date(e1.start).getTime() - new Date(e2.start).getTime(),
      )
      .reverse()[0];
  });

  return latestExecution;
}

export const useCurrentTeam = (): ITeam | undefined => {
  const activeTeamId = cookie.get('activeTeamId');
  const { projectId } = useSelector((state: State) => state.navigation);
  const currentProject = useSelector((state: State) =>
    projectId ? state.entities.projects.entities[projectId] : undefined,
  );
  const teams: Record<string, ITeam> = useSelector((state: State) => state.entities.teams.entities);
  if (!Object.values(teams).length) {
    return undefined;
  }

  let activeTeam = currentProject
    ? teams[currentProject.teamId]
    : activeTeamId
    ? teams[activeTeamId]
    : undefined;

  // if teamId is not set in cookie then return oldest team
  if (!activeTeam) {
    activeTeam = Object.values(teams).sort(
      (teamA: ITeam, teamB: ITeam) =>
        new Date(teamA.dateCreated).getTime() - new Date(teamB.dateCreated).getTime(),
    )[0];
    cookie.set('activeTeamId', activeTeam.id);
  } else if (activeTeamId !== activeTeam.id) {
    cookie.set('activeTeamId', activeTeam.id);
  }
  return activeTeam;
};

export const useTeamMemberRole = (): TeamMemberRole | undefined => {
  const organization: IOrganization | undefined = useCurrentOrganization();
  const user: ILoggedInUser | undefined = useSelector((state: State) => state.auth.user);
  return organization && user ? user.teams[organization.id] : undefined;
};

export const useCurrentOrganization = (): IOrganization | undefined => {
  const organizations: Record<string, IOrganization> = useSelector(
    (state: State) => state.entities.organizations.entities,
  );

  // one organization per user currently
  return Object.values(organizations)[0];
};

/**
 * Hook to redirect the user to the right screen with respet to their onboardingState.
 * Only use this hook if the requested URL is potentially unintended, i.e. /welcome when they
 * may have already completed the lead qualification form.
 *
 * This hook calls useCreateProjects with the "default" project and returns its result.
 * **Use of this hook requires `withAuth` to wrap around the component.**
 */
export const useOnboardingRedirect = (): ReturnType<typeof useCreateProjects> => {
  const router = useRouter();

  const [processing, project] = useCreateProjects();
  const onboardingStage = useSelector((state: State) => state.auth.user?.onboardingStage);

  useEffect(() => {
    if (project) {
      switch (onboardingStage) {
        case OnboardingStage.ADD_TEAM_DETAILS:
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          router.push('/add-team-details', '/welcome');
          break;

        // The onboarding redirect was disabled in v2.0.0 so new Paragon Connect users
        // aren't mistakenly redirected to classic onboarding when checking out Connect.
        // Leaving this in here for future reuse.

        // case OnboardingStage.ADD_TEAM_DETAILS:
        //   router.push('/add-team-details', '/welcome');
        //   break;
        // case OnboardingStage.PROMPT_TUTORIAL:
        //   if (config.ONBOARDING_SUPPORTED) {
        //     router.push('/classic/start-tutorial', '/classic/get-started');
        //     break;
        //   } else {
        //     router.push(
        //       `/classic/workflow-dashboard?projectId=${project.id}`,
        //       `/classic/projects/${project.id}`,
        //     );
        //     break;
        //   }
        // case OnboardingStage.IN_TUTORIAL:
        // case OnboardingStage.COMPLETED:
        // case OnboardingStage.COMPLETED_SKIPPED_TUTORIAL:
        //   router.push(
        //     `/classic/workflow-dashboard?projectId=${project.id}`,
        //     `/classic/projects/${project.id}`,
        //   );
        //   break;
        default:
          if (project.isConnectProject) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            router.push(
              `/connect/index?projectId=${project.id}`,
              `/connect/projects/${project.id}`,
            );
          } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            router.push(
              `/classic/workflow-dashboard?projectId=${project.id}`,
              `/classic/projects/${project.id}`,
            );
          }
          break;
      }
    }
  }, [processing, onboardingStage]);

  return [processing, project];
};

export const useDebouncedResizeObserver = (
  target: Element | null,
  onObserved: (entries: ResizeObserverEntry[]) => void,
  debounceInterval: number = 500,
): void => {
  const debounceTimer: React.MutableRefObject<number> = useRef(0);
  const resizeObserver: React.MutableRefObject<ResizeObserver | null> = useRef(null);

  useEffect(() => {
    if (target) {
      resizeObserver.current = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        debounceTimer.current && clearTimeout(debounceTimer.current);
        debounceTimer.current = setTimeout(() => {
          onObserved(entries);
        }, debounceInterval) as unknown as number;
      });
      resizeObserver.current.observe(target);
    }
    return () => {
      target && resizeObserver.current?.unobserve(target);
    };
  }, [target]);
};

export const useConfirm = (
  callback: (result: boolean) => void,
  title: string,
  message: string | MessagePart[],
  yesText: string = 'Ok',
  noText: string = 'Cancel',
  yesButtonColor: string = COLORS.PRIMARY,
  disableYesButton: boolean = false,
): [boolean, () => void] => {
  const dispatch = useDispatch();
  const key: MutableRefObject<string> = useRef<string>(v4());
  const confirmState: ConfirmationOptions | undefined = useSelector(
    (state: State) => state.confirm.confirmations[key.current],
  );
  const activeKey: string | undefined = useSelector((state: State) => state.confirm.active);

  useEffect(() => {
    dispatch({
      type: 'SET_CONFIRMATION_OPTIONS',
      key: key.current,
      payload: { title, message, yesText, noText, yesButtonColor, disableYesButton },
    });

    return resetConfirmation;
  }, [title, message, yesText, noText, yesButtonColor, disableYesButton]);

  const setOpen = (): void => {
    dispatch({ type: 'SET_CONFIRMATION_ACTIVE_KEY', key: key.current });
  };

  const resetConfirmation = () => {
    dispatch({ type: 'REMOVE_CONFIRMATION_KEY', key: key.current });
  };

  useEffect(() => {
    if (confirmState && confirmState.result) {
      void callback(confirmState.result);
    }
    return () => {
      dispatch({ type: 'SET_CONFIRMATION_ACTIVE_KEY', key: undefined });
    };
  }, [confirmState]);

  return [confirmState ? key.current === activeKey : false, setOpen];
};

export const useFeatureRestrictionValidators = (): WorkflowFeatureRestriction[] => {
  const workflowId = useSelector((state: State) => state.navigation.workflowId);
  const workflow = useSelector((state: State) =>
    workflowId ? state.entities.workflows.entities[workflowId] : undefined,
  );
  const steps = useSelector((state: State) => {
    return workflow
      ? workflow.stepIds.map((stepId: string) => state.entities.steps.entities[stepId])
      : [];
  });
  const workflowWithSteps = workflow && { ...workflow, steps };
  const plan = useCurrentBillingPlan();

  if (!workflowWithSteps) {
    return [];
  }
  return featureRestrictions(steps, workflowWithSteps, plan);
};

export function useCurrentBillingPlan(): BillingPlan;
export function useCurrentBillingPlan(fallbackOnDefaultPlan: boolean): BillingPlan | undefined;
export function useCurrentBillingPlan(fallbackOnDefaultPlan?: boolean): BillingPlan | undefined {
  return useSelector((state: State) => {
    return getBillingPlanFromState(state, fallbackOnDefaultPlan ?? true);
  });
}

/**
 * Use this hook to track events with Segment's analytics.js. Analytics.js is mounted into the DOM
 * by the SegmentSnippet component.
 *
 * @param analyticsFn A callback that includes the global Segment analytics object. This callback
 * may not run if analytics.js failed to load.
 */
export const useAnalytics = (
  analyticsFn: (analytics: typeof window['analytics']) => void,
  deps?: React.DependencyList,
): void => {
  useEffect(() => {
    if ('analytics' in window) {
      analyticsFn(window.analytics);
    }
  }, deps);
};

/**
 * Use this hook to create a ref for the global Segment analytics.js object.
 *
 * @returns A React ref to the analytics object
 */
// @ts-ignore importing only typedef causing error while build
export const useAnalyticsRef = (): React.MutableRefObject<SegmentAnalytics.AnalyticsJS | null> => {
  // @ts-ignore importing only typedef causing error while build
  const analytics = useRef<SegmentAnalytics.AnalyticsJS | null>(null);
  // @ts-ignore importing only typedef causing error while build
  useAnalytics((globalAnalytics: SegmentAnalytics.AnalyticsJS) => {
    analytics.current = globalAnalytics;
  }, []);
  return analytics;
};

export const useStateMachine = (): StateMachine => {
  return useSelector((state: State) => state.workflowEditor.stateMachine);
};

export const useWorkflow = (): WorkflowEntity | undefined => {
  const workflowId = useSelector((state: State) => state.navigation.workflowId);
  return useSelector((state: State) =>
    workflowId ? state.entities.workflows.entities[workflowId] : undefined,
  );
};

/**
 * get steps of given workflow
 * @param workflowId id of workflow for which to get step
 * @returns
 */
const useWorkflowSteps = (workflowId: string | undefined): Step[] => {
  return useSelector((state: State) =>
    Object.values(state.entities.steps.entities).filter((step) => step.workflowId === workflowId),
  );
};

/**
 * fetches steps for currently opened workflow
 * @returns steps of current workflow
 */
export const useCurrentWorkflowSteps = (): Step[] => {
  const workflowId = useSelector((state: State) => state.navigation.workflowId);

  return useWorkflowSteps(workflowId);
};

export const useWorkflowVersions = (workflowId: string): WorkflowVersion[] => {
  const version = useSelector((state: State) => state.entities.versions.entities);

  return Object.values(version)
    .filter((value: WorkflowVersion) => value.workflowId === workflowId)
    .sort((first: WorkflowVersion, second: WorkflowVersion) =>
      new Date(first.dateCreated) > new Date(second.dateCreated) ? -1 : +1,
    );
};

export const useCheckVersionHistoryOpen = (): boolean => {
  return !!useSelector((state: State) => state.navigation.versionId);
};

export const useUiPrefixRouteElement = (): RouteElement[] => {
  const isConnectUI = useSelector((state: State) => state.navigation.isConnectUi ?? false);
  return isConnectUI
    ? [{ path: 'connect', key: 'connect' }]
    : [{ path: 'classic', key: 'classic' }];
};

export const useEssentialsData = () => {
  const teams = useSelector((state: State) => state.entities.teams.entities);
  const projects = useSelector((state: State) => state.entities.projects.entities);
  const organizations: IOrganization[] = useSelector((state: State) =>
    Object.values(state.entities.organizations.entities),
  );
  const team: ITeam | undefined = useCurrentTeam();
  const dispatch = useDispatch();

  useEffect(() => {
    if (team?.id && Object.values(projects).length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(getProjects(team.id));
    }
  }, [team?.id]);

  useEffect(() => {
    if (Object.values(teams).length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(getTeams());
    }
  }, []);

  useEffect(() => {
    if (organizations.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(getOrganizations());
    }
  }, []);
};

/**
 * Invoking this hook checks the projectId in the router and redirects back to Classic/Connect
 * dashboard if the URL is mismatched with the project type.
 */
export const useProjectTypeRedirect = () => {
  const router = useRouter();
  const project: IProject | undefined = useSelector((state: State) =>
    router.query.projectId
      ? state.entities.projects.entities[router.query.projectId as string]
      : undefined,
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (project) {
        if (!project.isConnectProject && router.asPath.indexOf('/connect') > -1) {
          await replaceRoute(router, '/classic/dashboard', []);
        } else if (project.isConnectProject && router.asPath.indexOf('classic') > -1) {
          await pushRoute(router, '/connect', [
            { path: 'connect', key: 'connect' },
            { path: 'projects', key: 'projectId', value: project.id },
          ]);
        }
      }
    })();
  }, [project?.id, router]);
};

export const useIntegrationWorkflowMeta = (
  integrationId: string,
  workflowId: string,
): IntegrationWorkflowMeta => {
  return (
    useSelector(
      (state: State) =>
        Object.values(state.entities.integrationConfigs.entities).find(
          (config: IntegrationConfigEntity) => config.integrationId === integrationId,
        )?.values?.workflowMeta || {},
    )[workflowId] || { id: workflowId, inputs: [] }
  );
};

export const useIntegrationSharedMeta = (integrationId: string): IntegrationSharedMeta => {
  return useSelector(
    (state: State) =>
      Object.values(state.entities.integrationConfigs.entities).find(
        (config: IntegrationConfigEntity) => config.integrationId === integrationId,
      )?.values?.sharedMeta || {},
  );
};

export const usePreviewConnectCredential = (
  integrationId: string,
): IConnectCredentialWithPersona | null => {
  return useSelector(
    (state: State) =>
      Object.values(state.entities.connectCredentials.entities).find(
        (credential: IConnectCredentialWithPersona) => credential.integrationId === integrationId,
      ) || null,
  );
};

export const usePortalInputs = (
  integrationId: string,
  workflowId: string,
): { workflowInputs: SerializedConnectInput[]; sharedInputs: SerializedConnectInput[] } => {
  const workflowMeta = useIntegrationWorkflowMeta(integrationId, workflowId);
  const sharedMeta = useIntegrationSharedMeta(integrationId);
  return { workflowInputs: workflowMeta?.inputs || [], sharedInputs: sharedMeta?.inputs || [] };
};

export const useCredentialForIntegration = (): ICredential | undefined => {
  const { workflowId } = useSelector(
    (state: State) => state.navigation,
  ) as Required<NavigationState>;
  const workflow = useSelector((state: State) => state.entities.workflows.entities[workflowId]);
  const integration = useSelector((state: State) =>
    workflow.integrationId
      ? state.entities.integrations.entities[workflow.integrationId]
      : undefined,
  );

  const credential = useSelector((state: State) =>
    Object.values(state.entities.credentials.entities).find(
      (credential: ICredential) =>
        credential.provider === ActionSteps[integration?.type ?? Action.NONE]?.config.provider,
    ),
  );

  return credential;
};

export const useCachedConnectCredential = (): CachedConnectCredential | undefined => {
  return getCachedConnectCredentialFromState(useSelector((state: State): State => state));
};

/**
 *
 * @param projectId
 * @param filters
 */
export function useWorkflowLogFilterChange(
  projectId: string | undefined,
  filters: TaskHistoryFilters,
): void {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const url = parse(asPath, true);

  const dispatch = useDispatch();

  const project: IProject | undefined = useSelector((state: State) =>
    projectId ? state.entities.projects.entities[projectId] : undefined,
  );

  const isConnectUI: boolean = useSelector((state) => Boolean(state.navigation.isConnectUi));

  useEffect(() => {
    // not running this if connectUI check and project type not match
    // as for project switching case it was again pushing task-history route PARA-4027
    if (projectId && (project === undefined || project.isConnectProject === isConnectUI)) {
      void dispatch(getWorkflowLogs(projectId, INITIAL_PAGE, filters));
      const searchParams = new URLSearchParams(url.search || '');

      Object.keys(filters).forEach((filterKey: string) => {
        const filterValue = filters[filterKey as keyof TaskHistoryFilters];
        if (filterValue) {
          searchParams.set(filterKey, filterValue);
        } else {
          searchParams.delete(filterKey);
        }
      });
      url.search = `?${searchParams.toString()}`;

      void router.push(format({ pathname, query }), format(url), {
        shallow: true,
      });
    }
  }, [filters, projectId]);
}

/**
 *
 * @param projectId project id
 * @param filters log filters
 * @param refreshInterval refresh interval in milliseconds, default 5000
 * @returns {boolean} returns `true` if auto refresh in progress
 */
export function useWorkflowLogAutoRefresh(
  projectId: string | undefined,
  filters: TaskHistoryFilters,
  refreshInterval: number = 5000,
): boolean {
  const [isTabFocused, setIsTabFocused] = useState<boolean>(true);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onTabFocus = () => {
    // Refreshes log immediately on tab switch, doesn't wait for interval
    setIsTabFocused(true);
    initRefreshLogs();
  };
  const onTabBlur = () => setIsTabFocused(false);

  const refreshLogs = async () => {
    if (projectId) {
      await dispatch(getWorkflowLogs(projectId, INITIAL_PAGE, filters));
      setIsRefreshing(false);
    }
  };

  const initRefreshLogs = (): void => {
    // Avoid triggering new request if one is pending
    setIsRefreshing((isRefreshInProgress) => {
      if (!isRefreshInProgress) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refreshLogs();
      }
      return true;
    });
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (projectId && isTabFocused) {
      intervalId = setInterval(initRefreshLogs, refreshInterval) as unknown as NodeJS.Timeout;
    }

    window.addEventListener('focus', onTabFocus);
    window.addEventListener('blur', onTabBlur);

    return () => {
      intervalId && clearInterval(intervalId);
      window.removeEventListener('focus', onTabFocus);
      window.removeEventListener('blur', onTabBlur);
    };
  }, [projectId, filters, isTabFocused]);

  return isRefreshing;
}

export function useIntegrationStepView(integrationId?: string): ActionStepView | undefined {
  const inferredIntegrationId = useSelector((state: State) => {
    if (integrationId) {
      return integrationId;
    }
    if (state.navigation.integrationId) {
      return state.navigation.integrationId;
    }
    if (state.navigation.workflowId) {
      return state.entities.workflows.entities[state.navigation.workflowId].integrationId;
    }
  });
  const integration = useSelector((state: State) =>
    inferredIntegrationId ? state.entities.integrations.entities[inferredIntegrationId] : undefined,
  );
  let config: ActionStepView | undefined = integration?.type
    ? ActionSteps[integration.type]
    : undefined;
  if (integration?.type === Action.CUSTOM) {
    config = {
      id: Action.CUSTOM,
      config: {} as ActionConfig,
      title: integration.customIntegration?.name ?? '',
      description: integration.configs?.[0].values.description ?? '',
      icon: integration.customIntegration?.icon ?? '',
      initialParameters: {},
      stepType: StepType.ACTION,
    };
  }
  return config;
}

/**
 * @returns current active integration
 */
export function useIntegration(): IDetailedConnectIntegration | undefined {
  const integrationId = useSelector((state: State) => state.navigation.integrationId);
  const workflow = useWorkflow();
  const targetIntegrationId: string | undefined = integrationId ?? workflow?.integrationId;
  return useSelector((state: State) =>
    targetIntegrationId ? state.entities.integrations.entities[targetIntegrationId] : undefined,
  );
}

/**
 *
 * @returns all integrations for project
 */
export function useIntegrations(): IDetailedConnectIntegration[] {
  const projectId: string | undefined = useSelector((state: State) => state.navigation.projectId);
  return useSelector((state: State) =>
    Object.values(state.entities.integrations.entities).filter(
      (integration: IDetailedConnectIntegration) => integration.projectId === projectId,
    ),
  );
}

/**
 * Check weather the given integration is enabled by the user or not
 * @param integrationName
 * @returns
 */
export const useConnectedIntegration = (
  integrationName: string,
): IDetailedConnectIntegration | undefined => {
  const integrations = useSelector((state: State) => state.entities.integrations.entities);
  return Object.values(integrations).find(
    (integration: IDetailedConnectIntegration) => integration.type === integrationName,
  );
};

export const useDynamicMapperOptionsParser = (code: string | undefined) => {
  const [parsedCode, setParsedCode] = useState<
    Partial<{
      objectType: string;
      options: DynamicMappingOptions | DynamicMappingField[];
      error: string;
    }>
  >({});

  const parseCodeStringToJson = (
    code: string,
  ): {
    objectType: string;
    options: DynamicMappingOptions | DynamicMappingField[];
  } => {
    const codeRegexp: RegExp = /(?<="mapObjectFields":|mapObjectFields:)(.*)(?=\}\))/g;
    const optionsString = code.replace(new RegExp('\r?\n', 'gm'), '').match(codeRegexp) || [''];
    const parsedCode: { [objectType: string]: DynamicMappingOptions | DynamicMappingField[] } =
      JSON5.parse(optionsString[0]);

    if (!parsedCode || !Object.keys(parsedCode).length) {
      throw new Error('No options provided');
    }
    const entries = Object.entries(parsedCode);
    const [objectType, options]: [string, DynamicMappingOptions | DynamicMappingField[]] =
      entries[0];

    const isInvalidOptions: boolean = (
      !Array.isArray(options) ? options.fields || [] : options
    ).some((item: DynamicMappingField) => !item.label || !item.value);
    if (isInvalidOptions) {
      throw new Error('Invalid options provided.');
    }

    return { objectType, options };
  };

  useEffect(() => {
    try {
      if (code !== undefined) {
        if (code.includes('paragon.connect')) {
          const result = parseCodeStringToJson(code);
          setParsedCode({ ...result, error: undefined });
        } else {
          throw new Error('Invalid code.');
        }
      }
    } catch (e) {
      setParsedCode({ ...parsedCode, error: e.message });
    }
  }, [code]);

  return { ...parsedCode };
};
