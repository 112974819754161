import { EventInput, EventStep, StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';

/**
 * validates that an event step is properly configured
 */
export default class EventStepValidator extends StepValidator<EventStep, EventInput> {
  stepType: EventStep['type'] = StepType.EVENT;

  /**
   * validates that a step is valid
   *
   * @param {EventStep} step
   * @returns {EventStep}
   * @memberof EventStepValidator
   */
  validate(step: EventStep): EventStep {
    if (!step.parameters.eventId) {
      throw new Error('You must specify an app event.');
    }

    return step;
  }
}
