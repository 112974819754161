import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import {
  ResolvedCustomIntegrationRequestParameters,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import { CustomIntegrationRequestStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import {
  getRequirdStepIdForTokenizedSource,
  getRequiredStepIdToResolveKeyedSources,
  resolveKeyedSources,
  resolveSources,
} from './resolvers.utils';

/**
 * a resolver for a delay step's variables
 *
 * @export
 * @class CustomIntegrationRequestStepResolver
 * @extends {StepResolver<CustomIntegrationRequestStep, ResolvedCustomIntegrationRequestParameters>}
 */
export class CustomIntegrationRequestStepResolver extends StepResolver<
  CustomIntegrationRequestStep,
  ResolvedCustomIntegrationRequestParameters
> {
  /**
   * resolves the input parameters for the step
   *
   * @param {ActionStepParameters} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedCustomIntegrationRequestParameters}
   * @memberof CustomIntegrationRequestStepResolver
   */
  resolveInputParameters(
    parameters: CustomIntegrationRequestStep['parameters'],
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedCustomIntegrationRequestParameters {
    const resolved: ResolvedCustomIntegrationRequestParameters = {
      ...resolveKeyedSources(
        variables,
        resolveSources(
          variables,
          parameters,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
          'rawBody',
          'url',
        ),
        secrets,
        cachedConnectCredential,
        context,
        cachedPersona,
        'params',
        'headers',
        'body',
      ),
    };

    return resolved;
  }

  /**
   *
   * @param parameters
   * @returns
   */
  getRequiredStepIdsForVariableResolution(
    parameters: CustomIntegrationRequestStep['parameters'],
  ): string[] {
    return [
      ...getRequiredStepIdToResolveKeyedSources(parameters.params),
      ...getRequiredStepIdToResolveKeyedSources(parameters.headers),
      ...getRequirdStepIdForTokenizedSource(parameters.url),
      ...getRequiredStepIdToResolveKeyedSources(parameters.body),
      ...(parameters.rawBody ? getRequirdStepIdForTokenizedSource(parameters.rawBody) : []),
    ];
  }
}
