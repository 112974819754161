import { ActionSteps, VisibleConnectAction } from '@shared/actions/sdk';
import { ActionSteps as ConnectActionSteps } from '@shared/actions/sdk/connect';
import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import {
  ActionConfig,
  ActionStep,
  ActionStepParameters,
  ActionStepView,
  ActionTriggerStep,
  SidebarInput,
  SidebarInputType,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import {
  KeyedSource,
  ResolvedActionParameters,
  Source,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import { StepType } from '@shared/types/sdk/steps';

import { isValidJSON } from '../workflow.utils';

import { StepValidator } from './abstract.validator';
import { hasEmptySource, shouldAssertValue } from './validator.utils';

/**
 * validates that an action step is properly configured
 *
 * @export
 * @class ActionStepValidor
 * @extends {StepValidator<ActionStep, ResolvedActionParameters>}
 */
export default class ActionStepValidator extends StepValidator<
  ActionStep | ActionTriggerStep,
  ResolvedActionParameters
> {
  stepType: ActionStep['type'] | ActionTriggerStep['type'] = StepType.ACTION;

  /**
   * returns all (intent and auth input) inputs for action intent
   *
   * @param {ActionConfig} config
   * @param {ActionStepParameters} parameters
   * @returns {SidebarInput[]}
   * @memberof ActionStepValidor
   */
  getIntentInputs(config: ActionConfig, parameters: ActionStepParameters): SidebarInput[] {
    const sidebarSections = config.sidebarSections || [];
    return sidebarSections
      .reduce((accumulator: SidebarInput[], section: SidebarSectionFactory) => {
        return [
          ...accumulator,
          ...(typeof section === 'function' ? section(parameters, {}) : section).inputs,
        ];
      }, [] as SidebarInput[])
      .filter((input: SidebarInput) => {
        // filter out intent and auth inputs
        switch (input.type) {
          case SidebarInputType.Intent:
          case SidebarInputType.Auth:
            return false;
        }
        return true;
      });
  }

  /**
   * validates the parameters in an action step are valid
   */
  validateActionParameters(
    parameters: ActionStepParameters,
    resolvedParameters: ResolvedActionParameters,
    config: ActionConfig,
    isConnectProject: boolean,
  ): void {
    if (!parameters.intent) {
      throw new Error('You must select an action for this step.');
    } else if (!isConnectProject && !parameters.credentials.length) {
      throw new Error('Credentials are required for this Action.');
    }

    const inputs: SidebarInput[] = this.getIntentInputs(config, parameters);

    inputs.forEach((input: SidebarInput) => {
      const inputValue = resolvedParameters.actionParameters[input.id];
      const source: Source | undefined = parameters.actionParameters.find(
        (source: KeyedSource) => source.key === input.id,
      )?.source;

      if (source && !shouldAssertValue(source, inputValue)) {
        return;
      }

      // considering emptystring , undefined , null as not provided
      const isValueProvided: boolean =
        inputValue !== undefined && inputValue !== null && inputValue !== '';
      const isOptional: boolean = input.required === undefined ? false : !input.required;
      const hasUnfilledInputs: boolean = source ? hasEmptySource(source) : true;
      // if input is optional and no value provided then valid input
      if (isOptional && !isValueProvided) {
        return;
      }

      //throw error when input is required and not provided input value
      if (!isOptional && !isValueProvided && hasUnfilledInputs) {
        throw new Error(`"${input.title}" field is required.`);
      }
      // check for input validations
      switch (input.type) {
        case SidebarInputType.Code:
          if (
            input.language === 'json' &&
            !isValidJSON(inputValue) &&
            inputValue &&
            !inputValue.includes('undefined')
          ) {
            throw new Error(`"${input.title}" field value is not a valid json.`);
          }
          break;
      }
    });
  }

  /**
   * validates that a step is valid
   *
   * @param {Step} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ActionStep}
   * @memberof StepValidator
   */
  validate(
    step: ActionStep | ActionTriggerStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona,
  ): ActionStep | ActionTriggerStep {
    const resolvedParameters: ResolvedActionParameters = this.resolveVariables(
      step,
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
    );
    const isConnectProject: boolean = Boolean(context.integrationId);
    const stepView: ActionStepView = isConnectProject
      ? ConnectActionSteps[resolvedParameters.actionType as VisibleConnectAction]
      : ActionSteps[resolvedParameters.actionType];

    this.validateActionParameters(
      step.parameters,
      resolvedParameters,
      stepView.config,
      isConnectProject,
    );

    return step as ActionStep;
  }
}
