import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { IConnectIntegration } from '@shared/entities/sdk/integration/integration.interface';

import { Action, ActionStep, ActionTriggerStep, BaseActionStepParameters } from './actions';
import { FanoutStackEntry } from './execution';
import {
  ConditionWrapper,
  DataType,
  FileValue,
  KeyedSource,
  ObjectMappingInput,
  ParsedObjectMapping,
  Source,
  TokenizedSource,
} from './resolvers';

export type BaseStep = {
  id: string;
  description: string;
  before?: string | null;
  next: string | null; // step id
  workflowId: string;
  variables?: object; // TODO make required once added to zeus step model
};

export enum StepType {
  UNSELECTED_TRIGGER = 'TRIGGER/NONE',
  ENDPOINT = 'TRIGGER/ENDPOINT',
  ACTION_TRIGGER = 'TRIGGER/ACTION',
  EVENT = 'TRIGGER/EVENT',
  CRON = 'TRIGGER/CRON',
  OAUTH = 'TRIGGER/OAUTH',
  REQUEST = 'ACTION/REQUEST',
  REDIRECT = 'ACTION/REDIRECT',
  RESPONSE = 'ACTION/RESPONSE',
  FUNCTION = 'ACTION/FUNCTION',
  IFELSE = 'TRANSITION/IFELSE',
  MAP = 'TRANSITION/MAP',
  ACTION = 'ACTION/CUSTOM',
  DELAY = 'ACTION/DELAY',
  CUSTOM_INTEGRATION_REQUEST = 'ACTION/CUSTOM_INTEGRATION_REQUEST',
  INTEGRATION_ENABLED = 'TRIGGER/INTEGRATION_ENABLED',
}

export type StepTypeView = {
  id: string;
  stepType: StepType;
  icon: string;
  title: string;
  description: string;
  initialParameters: object;
  hideFromConnect?: boolean;
};
export enum RequestData {
  PARAMS = 'PARAMS',
  HEADERS = 'HEADERS',
  BODY = 'BODY',
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const HTTP_METHODS_WITH_BODY: HttpMethod[] = [
  HttpMethod.PATCH,
  HttpMethod.POST,
  HttpMethod.PUT,
];

export enum RequestBodyType {
  JSON = 'json',
  FORM_DATA = 'form-data',
  X_WWW_FORM_URLENCODED = 'x-www-form-urlencoded',
  XML = 'xml',
  RAW = 'raw',
}

/**
 * Specify the kind of request authorization that this request should use. Affects the
 * Authorization header value.
 * @since PARA-1815
 */
export enum RequestAuthorizationType {
  NONE = 'none',
  BEARER_TOKEN = 'bearer',
  BASIC = 'basic',
  QUERY_PARAMS = 'query_params',
  AUTH_HEADER = 'auth_header',
}

export type NoAuthorization = {
  type: RequestAuthorizationType.NONE;
};

export type BearerAuthorization = {
  type: RequestAuthorizationType.BEARER_TOKEN;
  token: TokenizedSource<DataType.STRING>;
};

export type BasicAuthorization = {
  type: RequestAuthorizationType.BASIC;
  username: TokenizedSource<DataType.STRING>;
  password: TokenizedSource<DataType.STRING>;
};

export type QueryParamAuthorization = {
  type: RequestAuthorizationType.QUERY_PARAMS;
  params: KeyedSource<DataType.STRING>[];
};

export type AuthHeaderAuthorization = {
  type: RequestAuthorizationType.AUTH_HEADER;
  headers: KeyedSource<DataType.STRING>[];
};

export type RequestAuthorization =
  | NoAuthorization
  | BearerAuthorization
  | BasicAuthorization
  | QueryParamAuthorization
  | AuthHeaderAuthorization;

export type CronInput = {
  type: StepType.CRON;
};

export type EventInput = {
  type: StepType.EVENT;
  payload: Record<string, unknown>;
  objectMappingInput?: ObjectMappingInput;
};

export type EndpointInput = {
  type: StepType.ENDPOINT;
  method: HttpMethod;
  params: Record<string, string>;
  headers: Record<string, string>;
  body: object | DynamicMappingIntegrationOutput;
  awaitingPayload: boolean;
  file?: FileValue;
  objectMappingInput?: ObjectMappingInput;
};

/**
 * Dynamic Object Mapper Integration field mapped to Application field result item,
 * returned by AppEvent/Request Trigger Step
 */
export type DynamicMappingIntegrationOutput<T = Record<string, unknown>> = {
  mappedIntegrationObject: ParsedObjectMapping;
  originalPayload: T;
};

/**
 * Dynamic Object Mapper Application field mapped to Integration field result item,
 * returned by Action Triggers
 */
export type DynamicMappingApplicationOutput<T = Record<string, unknown>> = {
  mappedApplicationObject: ParsedObjectMapping;
  originalPayload: T;
};

export type CronActionTriggerInput = {
  data: any;
};

export type ActionTriggerInput = {
  type: StepType.ACTION_TRIGGER;
  input: CronActionTriggerInput;
};

export type OAuthInput = {
  type: StepType.OAUTH;
  code?: string;
  query?: { [key: string]: string };
};

export type IntegrationEnabledInput = {
  type: StepType.INTEGRATION_ENABLED;
};

export type TriggerInput =
  | CronInput
  | EndpointInput
  | ActionTriggerInput
  | OAuthInput
  | EventInput
  | IntegrationEnabledInput;

export enum Unit {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export enum Weekday {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type Time = {
  minutes: number;
  timezone: TokenizedSource<DataType.STRING> | string;
};

export type SecondsSchedule = { unit: Unit.SECONDS; seconds: number };
export type MinutesSchedule = { unit: Unit.MINUTES; minutes: number };
export type HourlySchedule = {
  unit: Unit.HOURLY;
  hours: number;
  offset: number;
};
export type DailySchedule<T = Time> = { unit: Unit.DAILY; days: number; time: T };
export type WeeklySchedule<T = Time> = { unit: Unit.WEEKLY; weekday: Weekday; time: T };

export type Schedule =
  | SecondsSchedule
  | MinutesSchedule
  | HourlySchedule
  | DailySchedule
  | WeeklySchedule;

export type TriggerStepType =
  | StepType.UNSELECTED_TRIGGER
  | StepType.CRON
  | StepType.ENDPOINT
  | StepType.OAUTH
  | StepType.ACTION_TRIGGER
  | StepType.EVENT
  | StepType.INTEGRATION_ENABLED;

/**
 * valid non-action triggers
 */
export type StaticTriggerStepType = Exclude<
  TriggerStepType,
  StepType.UNSELECTED_TRIGGER | StepType.ACTION_TRIGGER
>;

/**
 * values for valid non-action triggers
 */
export const STATIC_TRIGGER_STEPS: StaticTriggerStepType[] = [
  StepType.CRON,
  StepType.ENDPOINT,
  StepType.OAUTH,
];

/**
 * TriggerStepTypes supported for Connect workflows
 */
export const STATIC_CONNECT_TRIGGER_STEPS: TriggerStepType[] = [
  StepType.CRON,
  StepType.EVENT,
  StepType.INTEGRATION_ENABLED,
  StepType.ENDPOINT,
];

export type ParamValidation = {
  key: string;
  required: boolean;
};

export type HeaderValidation = {
  key: string;
  value: string;
};

export type BodyValidation = {
  key: string;
  dataType: DataType;
  required: boolean;
};

export enum DelayUnit {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

type BaseVariables = {
  fanoutStack: FanoutStackEntry[];
  truncated?: boolean;
};

export type ErrorVariables = BaseVariables & {
  type: 'ERROR';
  message: string;
};

export type EndpointVariables = BaseVariables & {
  type: StepType.ENDPOINT;
  request: {
    params: Record<string, string>;
    headers: Record<string, string>;
    body: object;
    file?: FileValue;
  };
};

export type EventVariables = BaseVariables & {
  type: StepType.EVENT;
  event: Record<string, unknown>;
};

export type CronVariables = BaseVariables & {
  type: StepType.CRON;
};

export type OAuthVariables = BaseVariables & {
  type: StepType.OAUTH;
  /**
   * OAuth responses may take different shapes. We type this as `any` to anticipate any provider's
   * response types, similar to ActionVariables.
   */
  auth: any;
};

export type FunctionVariables = BaseVariables & {
  type: StepType.FUNCTION;
  result: any;
};

export type RequestVariables = BaseVariables & {
  type: StepType.REQUEST;
  response: {
    body: object;
    headers: object;
    statusCode: number;
    error?: any;
  };
};

export type CustomIntegrationRequestVariables = Omit<RequestVariables, 'type'> & {
  type: StepType.CUSTOM_INTEGRATION_REQUEST;
  response: RequestVariables['response'];
};

export type RedirectVariables = BaseVariables & {
  type: StepType.REDIRECT;
  redirectUrl: string;
};

export type ResponseVariables = BaseVariables & {
  type: StepType.RESPONSE;
  statusCode: number;
  body?: object;
  file?: FileValue;
};

export type ConditionalVariables = BaseVariables & {
  type: StepType.IFELSE;
  selectedChoice: string;
};

export type MapVariables = BaseVariables & {
  type: StepType.MAP;
  instance: any;
};

export type ActionVariables = BaseVariables & {
  type: StepType.ACTION;
  result: any;
};

export type DelayVariables = BaseVariables & {
  type: StepType.DELAY;
  delay: {
    unit: string;
    value: number;
  };
};

export type ActionTriggerVariables = BaseVariables & {
  type: StepType.ACTION_TRIGGER;
  result: any;
};

export type IntegrationEnabledVariables = BaseVariables & {
  type: StepType.INTEGRATION_ENABLED;
};

export type Variables =
  | ErrorVariables
  | EndpointVariables
  | CronVariables
  | OAuthVariables
  | FunctionVariables
  | CustomIntegrationRequestVariables
  | RequestVariables
  | RedirectVariables
  | ResponseVariables
  | ConditionalVariables
  | MapVariables
  | ActionVariables
  | DelayVariables
  | ActionTriggerVariables
  | EventVariables
  | IntegrationEnabledVariables;

export type NextStepOptions = {
  delay?: number;
  pause?: boolean;

  /**
   * contains information about the fanout step stored in iterating steps
   * used to resolve the fanouts
   * the reason we need to omit the index is because only the map.processor uses this
   * and the index isn't available until the base.processor queues the next steps
   */
  pushToFanoutStack?: Omit<FanoutStackEntry, 'index'>;

  /**
   * used to specify the max number of next steps to queue
   * used in MapSteps where it's iterating over a large array
   */
  maxNextSteps?: number;

  /**
   * used to execute step using a dedicated queue
   * used by connect workflows to execute them on a dedictaed queue
   * @type {boolean}
   */
  moveToDedicatedQueue?: boolean;
};

export type NextStep = {
  truncated?: boolean;
  id: string;
  instanceId: string;
  prevInstanceId: string;

  /**
   * input variables for the next step
   * @todo remove `output` from `NextStep`
   * @deprecated since PARA-1484
   */
  output: Variables | MapVariables[];
  options?: NextStepOptions;
  fanoutStack: FanoutStackEntry[];
  queue: string;
};

type ContinuationBase<T extends Variables = Variables> = {
  type: 'NEXT_STEPS' | 'FINAL';

  /**
   * the output of the execution
   */
  output: T | MapVariables[];
};

export type NextStepsContinuation<T extends Variables = Variables> = ContinuationBase<T> & {
  type: 'NEXT_STEPS';
  nextSteps: NextStep[];

  /**
   * the type of the continuation
   * @todo instead of storing if large, store its size
   */
  isLargeOutput: boolean;

  /**
   * fanout stack for the next steps
   * @todo remove `fanoutStack` from `NextStepsContinuation`
   * @deprecated since PARA-1484
   */
  fanoutStack: FanoutStackEntry[];
};

export type FinalContinuation<T extends Variables = Variables> = ContinuationBase<T> & {
  /**
   * the type of the continuation
   * @todo add `isLargeOutput` prop to `FinalContinuation`
   */
  type: 'FINAL';

  /**
   * whether or not the output is truncated
   * @todo move `truncated` prop to `Variables` type
   */
  truncated?: boolean;
};

export type Continuation<T extends Variables = Variables> =
  | NextStepsContinuation<T>
  | FinalContinuation<T>;

export type ActionExecution = {
  action: Action;
  method: string;
  instanceId: string;
  input: object;
  startTime: number;
  endTime: number;
};

export type SerializedStepExecution = {
  stepId: string;
  instanceId: string;
  input: object;
  startTime: number;
  endTime: number;
};

export type WorkflowExecutionStartUpdate = {
  type: 'START';
  stepId: string;
  instanceId: string;
};

export type WorkflowExecutionWaitingUpdate = {
  type: 'WAITING';
  stepId: string;
  instanceId: string;
  continuation: Continuation;
};

export type WorkflowExecutionSuccessUpdate = {
  type: 'SUCCESS';
  stepExecution: SerializedStepExecution;
  continuation: Continuation;
};

export type WorkflowExecutionFailureUpdate = {
  type: 'FAILURE';
  error: any;
  stepExecution?: SerializedStepExecution;
};

export type WorkflowExecutionHeartbeat = {
  type: 'HEARTBEAT';
};

export type WorkflowExecutionUpdate =
  | WorkflowExecutionStartUpdate
  | WorkflowExecutionWaitingUpdate
  | WorkflowExecutionSuccessUpdate
  | WorkflowExecutionFailureUpdate
  | WorkflowExecutionHeartbeat;

export type Workflow = {
  id: string;
  projectId: string;
  dateCreated: Date;
  dateUpdated: Date;
  description: string;
  steps: Step[];
};

export enum ExecutionStatus {
  NOT_STARTED = 'NOT_STARTED',
  DELAYED = 'DELAYED',
  PAUSED = 'PAUSED',
  EXECUTING = 'EXECUTING',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  WAITING = 'WAITING',
}

export enum CleanupStatus {
  PENDING = 'PENDING',
  EXECUTING = 'EXECUTING',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

export type WorkflowLog = {
  id: string;
  workflow: Workflow;
  start: Date;
  end: Date;
  triggerType: TriggerStepType;
  stepInstances: StepInstance[];
  status: ExecutionStatus;
  tasks?: number;
  integration?: IConnectIntegration;
  endUserId?: string;
};

export type WorkflowLogEntity = Omit<
  WorkflowLog,
  'workflow' | 'stepInstances' | 'start' | 'end' | 'integration'
> & {
  workflowId: string;
  stepInstanceIds: string[];
  start: string;
  end: string;
  replayOf: WorkflowLogEntity | null;
};

export type StepInstance = {
  id: string;
  workflowLog: WorkflowLog;
  timestamp: Date;
  executionTime: number;
  step: Step;
  stepType: StepType;
  status: ExecutionStatus;
};

export type StepInstanceEntity = Omit<StepInstance, 'execution' | 'step'> & {
  executionId: string;
  stepId: string;
};

export type User = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
};

export type Project = {
  title: string;
  owner: User;
};

export type StepReorderParams = {
  targetParentStepId: string;
  choice?: number;
  inFanout?: boolean;
  nextStepId?: string;
};

export type StepNextReference = {
  choice?: number;
  inFanout?: boolean;
};

export enum SequenceType {
  TRIGGER = 'TRIGGER',
  MAIN = 'MAIN',
  BRANCH = 'BRANCH',
  FANOUT = 'FANOUT',
}

export type Sequence = {
  id: string;
  start?: string; // Step ids
  stepIds: string[]; // Step ids
  stepEdges: StepEdge[];
  sequenceEdges: SequenceEdge[];
  type: SequenceType;
  conditionalBranchWidth?: number;
};

export type SequenceEdge = {
  type: SequenceEdgeType;
  from: string; // sequence id
  to: string; // sequence id
};

export enum SequenceEdgeType {
  NEXT = 'NEXT',
  BRANCH = 'BRANCH',
  FANOUT = 'FANOUT',
}

export type StepEdge = {
  label?: string;
  from: string; // step id
  to: string | null; // step id
};

export type Choice = {
  conditionWrapper?: ConditionWrapper;
  isDefault: boolean;
  label: string;
  next: string | null;
};

export type StepMap = Record<string, Step>;

export type SequenceMap = Record<string, Sequence>;

export type StateMachine = {
  stepMap: StepMap;
  sequenceMap: SequenceMap;
  start: string; // beginning sequence id

  /**
   * the active step that's executing
   * should be set to the trigger id for full workflow executions
   * @since PARA-1484: epic/fan-in
   */
  activeStepId: string | undefined;

  /**
   * if provided, a workflow should stop executing after reaching this step
   * used for single step tests where `activeStepId` & `finalStepId` should be the same value
   * @since PARA-1484: epic/fan-in
   */
  finalStepId?: string;

  /**
   * steps that aren't used in the state machine
   * @todo rename to `unusedStepIds` to be explicit about objs vs strings
   */
  unusedSteps: string[];
};

export enum DeploymentStatus {
  DISABLED = 'DISABLED',
  FAILED = 'FAILED',
  READY = 'READY',
  DEPLOYING = 'DEPLOYING',
  ACTIVE = 'ACTIVE',
  SAVED = 'SAVED',
}

export interface WorkflowMigration {
  dateCreated: Date;
  workflowId: string;
  status: DeploymentStatus;
}

export interface Deployment {
  dateCreated: Date;
  workflowMigrations: WorkflowMigration[];
}

export type UnselectedTriggerStep = BaseStep & {
  type: StepType.UNSELECTED_TRIGGER;
  parameters: {};
};

export type CronStep = BaseStep & {
  type: StepType.CRON;
  parameters: {
    schedule: Schedule;
  };
};

export type EventStep = BaseStep & {
  type: StepType.EVENT;
  parameters: EventStepParameters;
};

export type EndpointStep = BaseStep & {
  type: StepType.ENDPOINT;
  parameters: EndpointStepParameters;
};

export type IntegrationEnabledStep = BaseStep & {
  type: StepType.INTEGRATION_ENABLED;
  parameters: {};
};

export type EventStepParameters = {
  eventId: string;
  objectMapping?: TokenizedSource<DataType.STRING>;
};

export type EndpointStepParameters = {
  path: string;
  httpMethod: HttpMethod;
  allowArbitraryPayload: boolean;
  paramValidations: ParamValidation[];
  headerValidations: HeaderValidation[];
  bodyValidations: BodyValidation[];
  objectMapping?: TokenizedSource<DataType.STRING>;
};

export type OAuthStep = BaseStep & {
  type: StepType.OAUTH;
  parameters: BaseActionStepParameters & {
    code?: string;
    provider?: ProviderType;
  };
};

export type FunctionStepParameters = {
  code: string;
  parameters: KeyedSource[];
  retryOnFailure?: boolean;
};

export type FunctionStep = BaseStep & {
  type: StepType.FUNCTION;
  parameters: FunctionStepParameters;
};

export type RedirectStep = BaseStep & {
  type: StepType.REDIRECT;
  parameters: {
    url: TokenizedSource<DataType.STRING>;
    params: KeyedSource<DataType.ANY>[];
  };
};

export type RequestStep = BaseStep & {
  type: StepType.REQUEST;
  parameters: {
    url: TokenizedSource<DataType.STRING>;
    httpMethod: HttpMethod;
    params: KeyedSource<DataType.STRING>[];
    headers: KeyedSource<DataType.STRING>[];
    body: KeyedSource[];

    /**
     * Specify the kind of format that the `body` object should be transmitted in. Affects the
     * Content-Type header, if it isn't already set.
     * @since PARA-1969
     */
    bodyType?: RequestBodyType;

    /**
     * If a bodyType is RequestBodyType.RAW, rawBody represents the raw contents to pass through
     * to the body of the request.
     * @since PARA-1969
     */
    rawBody?: TokenizedSource<DataType.STRING>;

    /**
     * An object to represent Authorization options in a request step.
     *
     * - For Basic-type auth, "username" and "password" keys should appear in
     * this object.
     * - For Bearer-type auth, a "token" key should appear in this object.
     * @since PARA-1815
     */
    authorization?: RequestAuthorization;

    ignoreFailure: boolean;
    retryOnFailure?: boolean;
  };
};

export type CustomIntegrationRequestStep = Omit<RequestStep, 'type'> & {
  type: StepType.CUSTOM_INTEGRATION_REQUEST;
  parameters: Omit<RequestStep['parameters'], 'authorization'> & { actionType: Action };
};

export enum ResponseTypeEnum {
  JSON = 'JSON',
  FILE = 'FILE',
}

export type ResponseStep = BaseStep & {
  type: StepType.RESPONSE;
  parameters: {
    statusCode: number;
    responseType?: ResponseTypeEnum;
    bodyData: KeyedSource[];
    bodyFile?: TokenizedSource<DataType.FILE>;
  };
};

export type ConditionalStep = BaseStep & {
  type: StepType.IFELSE;
  parameters: {
    choices: Choice[];
  };
};

export type MapStep = BaseStep & {
  type: StepType.MAP;
  parameters: {
    iterator: Source<DataType.ARRAY>;
    nextToIterate: string | null;
  };
};

export type DelayStep = BaseStep & {
  type: StepType.DELAY;
  parameters: {
    unit: DelayUnit;
    value: Source<DataType.NUMBER>;
  };
};

export type Step =
  | UnselectedTriggerStep
  | CronStep
  | EndpointStep
  | OAuthStep
  | FunctionStep
  | CustomIntegrationRequestStep
  | RequestStep
  | RedirectStep
  | ResponseStep
  | ConditionalStep
  | MapStep
  | ActionStep
  | DelayStep
  | ActionTriggerStep
  | EventStep
  | IntegrationEnabledStep;

/**
 * all steps list which dont required connect credential to execute
 */
export const StepsThatDontRequireCredentials = [
  StepType.EVENT,
  StepType.FUNCTION,
  StepType.IFELSE,
  StepType.REQUEST,
  StepType.MAP,
];

export enum RESPONSE_STATUS_CODE {
  CONTINUE = 100,
  PROCESSING = 102,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  LARGE_PAYLOAD = 413,
}
