import {
  Choice,
  ConditionalStep,
  MapStep,
  Sequence,
  SequenceEdge,
  SequenceEdgeType,
  SequenceMap,
  SequenceType,
  StateMachine,
  Step,
  StepEdge,
  StepMap,
  StepNextReference,
  StepReorderParams,
  StepType,
  Workflow,
} from '@shared/types/sdk/steps';

import {
  concatAndDedupeStepLists,
  getStepBefore,
  getStepInWorkflowById,
  isConditional,
  isFanout,
  isStepInConditional,
  isStepInFanout,
  isStepInStepList,
  isTrigger,
  stepArrayToMap,
  stepMapToArray,
} from './workflow.utils';

export function getNextStepInWorkflow(currentStep: Step): Step {
  throw new Error('getNextStepInWorkflow > not implemented' + currentStep);
}

// given a step, it sets the next step it should navigate to as the provided value
// in the case of a conditional, it finds the old value in the nested `choices` field
// and updates it with the new value
export function updateStepNext(
  step: Step,
  params: StepNextReference,
  next: string | null = null,
): Step {
  if (typeof params.choice === 'number' && step.type === StepType.IFELSE) {
    step.parameters.choices[params.choice].next = next;
  } else if (params.inFanout && step.type === StepType.MAP) {
    step.parameters.nextToIterate = next;
  } else {
    step.next = next;
  }

  return step;
}

export function getValueForStepNext(step: Step, ref: StepNextReference): string | null {
  if (typeof ref.choice === 'number' && step.type === StepType.IFELSE) {
    return step.parameters.choices[ref.choice].next;
  } else if (ref.inFanout && step.type === StepType.MAP) {
    return step.parameters.nextToIterate;
  } else {
    return step.next;
  }
}

export function getStepNextReferenceForValue(step: Step, value: string): StepNextReference {
  if (step && step.next === value) {
    return {};
  } else if (step.type === StepType.IFELSE) {
    let index: number = 0;
    for (const choice of step.parameters?.choices || []) {
      if (choice.next === value) {
        return { choice: index };
      }
      index += 1;
    }
    throw new Error(`getStepNextReferenceForValue > failed to find ifelse choice value: ${value}`);
  } else if (step.type === StepType.MAP && step.parameters.nextToIterate === value) {
    return { inFanout: true };
  } else {
    throw new Error(`getStepNextReferenceForValue > failed to find value: ${value}`);
  }
}

// tslint:disable max-line-length
// used to reorder a step in a workflow
// docs:
//   - https://github.com/useparagon/docs/blob/2a5999db391436e3cb44f4750d88bbaf4a9e3730/rearrage-steps.txt
//   - https://github.com/useparagon/docs/blob/2a5999db391436e3cb44f4750d88bbaf4a9e3730/rearrange-steps-2.txt
//   - https://github.com/useparagon/docs/blob/2a5999db391436e3cb44f4750d88bbaf4a9e3730/rearrange-logic.txt
export function reorderStepInWorkflow(
  workflow: Workflow,
  step: Step,
  ref: StepReorderParams,
): Workflow {
  const newWorkflow: Workflow = JSON.parse(JSON.stringify(workflow));
  const steps: Step[] = [...newWorkflow.steps];
  const oldStepMap: StepMap = stepArrayToMap(workflow.steps);
  const newStepMap: StepMap = stepArrayToMap(steps);
  const stepParent: Step | undefined = getStepBefore(workflow, step);
  const targetParent: Step = getStepInWorkflowById(workflow, ref.targetParentStepId);

  // 1. Update the `.next` for the new parent to this step
  updateStepNext(newStepMap[targetParent.id], ref, step.id);

  // 2. Update the `.next` for this step to the new parent's `.next`
  const targetParentNext = getValueForStepNext(targetParent, ref);
  // Prevent circular .next reference
  if (step.id !== targetParentNext) {
    newStepMap[step.id].next = targetParentNext;

    // 3. Update the `.next` for the step's previous parent to the step's `.next`
    if (stepParent) {
      const stepParentRef: StepNextReference = getStepNextReferenceForValue(stepParent, step.id);
      updateStepNext(newStepMap[stepParent.id], stepParentRef, oldStepMap[step.id].next);
    }
  }

  const newSteps: Step[] = stepMapToArray(newStepMap);

  // 4. Move through the `newSteps` and validate that no other steps point to the reordered step
  newSteps.forEach((newStep: Step) => {
    if (newStep.id !== targetParent.id) {
      try {
        const invalidNextRef = getStepNextReferenceForValue(newStep, step.id);
        updateStepNext(newStep, invalidNextRef, undefined);
      } catch (_err) {
        // If there's no nextRef to step.id, we don't update anything
      }
    }
  });

  return {
    ...newWorkflow,
    steps: newSteps,
  };
}

export function removeStepFromWorkflow(workflow: Workflow, step: Step): Workflow {
  const newWorkflow: Workflow = JSON.parse(JSON.stringify(workflow));
  const stepParent = getStepBefore(newWorkflow, step);
  if (!stepParent) {
    return workflow;
  }

  // if the step being removed has a `next` step, add it to its parent step
  const ref: StepNextReference = getStepNextReferenceForValue(stepParent, step.id);
  updateStepNext(stepParent, ref, step.next);
  newWorkflow.steps = newWorkflow.steps.filter((s: Step) => s.id !== step.id);
  return newWorkflow;
}

// given a target sequence position,
// it gets the parent step (and potentially choice) for that location
export function getStepReorderParamsFromStateMachine(
  stateMachine: StateMachine,
  targetSequenceId: string,
  targetSequencePosition: number,
): StepReorderParams {
  const targetSequence: Sequence = stateMachine.sequenceMap[targetSequenceId];

  if (targetSequencePosition > 0) {
    // in the situation where the target location has a parent
    // we can find the parent within the sequence w/ no nested choices
    // because it's in the same sequence, it's not a conditional
    return {
      targetParentStepId: targetSequence.stepIds[targetSequencePosition - 1],
    };
  } else if (targetSequencePosition === 0) {
    // find the parent sequence by searching for a sequence edge pointing to this one
    const parentSequence: Sequence = Object.values(stateMachine.sequenceMap).find(
      (sequence: Sequence) =>
        sequence.sequenceEdges.filter(
          (sequenceEdge: SequenceEdge) => sequenceEdge.to === targetSequenceId,
        ).length,
    )!;
    const parentStep: ConditionalStep = stateMachine.stepMap[
      parentSequence.stepIds[parentSequence.stepIds.length - 1]
    ] as ConditionalStep;

    // Filter out the NEXT sequence edge type and find the choiceIndex,
    // if applicable
    const choiceIndex: number | undefined = parentSequence.sequenceEdges
      .filter((sequenceEdge: SequenceEdge) => sequenceEdge.type === SequenceEdgeType.BRANCH)
      .findIndex((sequenceEdge: SequenceEdge) => sequenceEdge.to === targetSequenceId);

    return {
      targetParentStepId: parentStep.id,
      choice: choiceIndex !== -1 ? choiceIndex : undefined,
      inFanout: targetSequence.type === SequenceType.FANOUT,
    };
  } else {
    throw new Error('getStepReorderParamsFromStateMachine > unaccounted for situation!');
  }
}

// creates a sequence and adds it to the `sequence` array provided
// which is being used as a store to keep track of sequences created
function createSequence(
  withStep: Step | undefined,
  sequences: Sequence[],
  type: SequenceType,
): Sequence {
  const sequence: Sequence = {
    id: `sequence-${sequences.length}`,
    start: withStep?.id,
    stepIds: withStep ? [withStep.id] : [],
    stepEdges: [],
    sequenceEdges: [],
    type,
    conditionalBranchWidth: 0,
  };
  sequences.push(sequence);
  return sequence;
}

export function traverseStateMachineByStep(
  stateMachine: StateMachine,
  stepVisitor: (step: Step) => void,
  currentSequenceId: string = stateMachine.start,
): void {
  const currentSequence = stateMachine.sequenceMap[currentSequenceId];
  currentSequence.stepIds.forEach((stepId: string) => stepVisitor(stateMachine.stepMap[stepId]));
  currentSequence.sequenceEdges.forEach(({ to }: SequenceEdge) =>
    traverseStateMachineByStep(stateMachine, stepVisitor, to),
  );
}

export function traverseStateMachineBySequence(
  stateMachine: StateMachine,
  sequenceVisitor: (sequence: Sequence) => void,
  currentSequenceId: string = stateMachine.start,
): void {
  const currentSequence = stateMachine.sequenceMap[currentSequenceId];
  sequenceVisitor(currentSequence);
  currentSequence.sequenceEdges.forEach(({ to }: SequenceEdge) =>
    traverseStateMachineBySequence(stateMachine, sequenceVisitor, to),
  );
}

export function getLastStepInSequence(
  sequence: Sequence,
  stateMachine: StateMachine,
): Step | undefined {
  const nextSequenceEdge: SequenceEdge | undefined = sequence.sequenceEdges.find(
    (edge: SequenceEdge) => edge.type === 'NEXT',
  );
  const nextSequence = stateMachine.sequenceMap[nextSequenceEdge?.to || ''];
  if (nextSequence && nextSequence.stepIds.length > 0) {
    return getLastStepInSequence(nextSequence, stateMachine);
  }
  return stateMachine.stepMap[sequence.stepIds[sequence.stepIds.length - 1]];
}

export function getUpstreamSteps(stepId: string, stateMachine: StateMachine): Step[] {
  if (!stateMachine.stepMap[stepId]) {
    return [];
  }

  // the reason this is within `getUpstreamSteps` is so we can
  // recursively call it for different paths;
  // also, we need it this way so this inner function can update the outer `steps` function
  function recurseUp(recurseStartStep: Step | undefined): void {
    let currentStep: Step | undefined = recurseStartStep;

    // we want to save the trigger step for last so at the end when we reverse it
    // it's first in the list
    while (
      currentStep &&
      currentStep.id !== triggerStep?.id &&
      !isStepInStepList(currentStep, upstreamSteps)
    ) {
      if (
        stepId !== currentStep.id &&
        isConditional(currentStep) &&
        !isStepInConditional(initialStep, currentStep as ConditionalStep, stateMachine)
      ) {
        // in this case, we've traversed up to a conditional
        // however, the initial step isn't in either branch of the conditional
        // this means all the steps on both sides of the conditional are upstream of the initial step
        // so we need to recurse down both paths to add them
        const downstreamSteps: Step[] = getDownstreamSteps(
          currentStep.id,
          stateMachine,
          stepId,
        ).reverse();
        concatAndDedupeStepLists(upstreamSteps, downstreamSteps, false);
      } else if (
        stepId !== currentStep.id &&
        isFanout(currentStep) &&
        !isStepInFanout(initialStep, currentStep as MapStep, stateMachine)
      ) {
        // in this case, we've traversed up to a fanout that the current step is not in
        // since the current step isn't within the fanout, the fanout has steps we haven't traversed
        // so we need to recurse down the fanout to add them
        const downstreamSteps: Step[] = getDownstreamSteps(
          currentStep.id,
          stateMachine,
          stepId,
        ).reverse();
        concatAndDedupeStepLists(upstreamSteps, downstreamSteps, false);
      }
      upstreamSteps.push(currentStep);

      currentStep = getStepBefore(
        workflowSteps,
        stateMachine.stepMap[currentStep.id],
        upstreamSteps,
      );
    }
  }

  const initialStep: Step = stateMachine.stepMap[stepId];
  const upstreamSteps: Step[] = [];
  const workflowSteps: Step[] = Object.values(stateMachine.stepMap);
  const triggerStep: Step | undefined = workflowSteps.find(isTrigger);

  recurseUp(initialStep);

  if (triggerStep && !isStepInStepList(triggerStep, upstreamSteps)) {
    upstreamSteps.push(triggerStep);
  }

  upstreamSteps.reverse();

  return upstreamSteps.filter((s: Step) => s.id !== stepId);
}

export function getDownstreamSteps(
  stepId: string,
  stateMachine: StateMachine,
  stopId: string | null = null, // if provided, traversing stops upon reaching this step
): Step[] {
  if (!stateMachine.stepMap[stepId]) {
    return [];
  }

  // the reason this is within `getUpstreamSteps` is so we can
  // recursively call it for different paths while tracking the cached state
  // as well as modify the final result
  function recurseDown(initialStep: Step | undefined): void {
    let currentStep: Step | undefined = initialStep;
    while (
      currentStep &&
      // currentStep.id !== stopId &&
      !isStepInStepList(currentStep, downstreamSteps)
    ) {
      downstreamSteps.push(currentStep);

      if (isConditional(currentStep)) {
        // if the step is a conditional, then we need to recurse down two separate paths
        // we're not going to exit though because the conditional step may also have a `.next` step
        const conditionalStep: ConditionalStep = currentStep as ConditionalStep;

        const choiceId0: string | null = conditionalStep.parameters?.choices[0].next;
        choiceId0 && recurseDown(stateMachine.stepMap[choiceId0]);

        const choiceId1: string | null = conditionalStep.parameters?.choices[1].next;
        choiceId1 && recurseDown(stateMachine.stepMap[choiceId1]);
      } else if (isFanout(currentStep)) {
        // if the step is a fanout, then we need to recurse down its `.nextToIterate`
        // we're not going to exit though because the fanout step may also have a `.next` step
        const nextId: string | null = (currentStep as MapStep).parameters.nextToIterate;
        nextId && recurseDown(stateMachine.stepMap[nextId]);
      }

      currentStep = currentStep.next ? stateMachine.stepMap[currentStep.next] : undefined;
      if (currentStep && currentStep.id === stopId) {
        break;
      }
    }
  }

  const initialStep: Step = stateMachine.stepMap[stepId];
  const downstreamSteps: Step[] = [];

  recurseDown(initialStep);

  // if there's a requested stopping point for this method,
  // then we need to get the downstream steps of that stopping point
  // and filter those out of the returned items
  // this is because a workflow can have multiple paths
  // and the steps under the requested stopping point may have been traversed
  // by a different path in which the stopping point is not on,
  // such as a workflow with conditionals or fanouts
  const filterStepIds: string[] = [
    stateMachine.stepMap[stepId],
    ...(stopId ? getDownstreamSteps(stopId, stateMachine) : []),
  ].map((s: Step) => s.id);
  return downstreamSteps.filter((s: Step) => !filterStepIds.includes(s.id));
}

// recursively creates the sequences in a state machine;
// populate them with their steps
function traverseSteps(
  step: Step,
  sequence: Sequence,
  stepMap: StepMap,
  sequences: Sequence[],
  traversed: string[],
  conditionalBranchWidth: number = 0,
): number {
  let maxConditionalBranchWidth: number = conditionalBranchWidth;
  let nextStepId: string | null = step?.next;
  let nextStep: Step | undefined = nextStepId ? stepMap[nextStepId] : undefined;
  let choice: Choice;
  let newSequence: Sequence;
  let newSequenceEdge: SequenceEdge;
  const newStepEdge: StepEdge = {
    from: step.id,
    to: nextStepId,
  };

  if (traversed.includes(step.id)) {
    return conditionalBranchWidth;
  }
  traversed.push(step.id);
  sequence.stepEdges.push(newStepEdge);

  if (nextStep && !isConditional(step) && !isFanout(step)) {
    if (isTrigger(step)) {
      // Create separate sequence for steps after the trigger
      newSequence = createSequence(nextStep, sequences, SequenceType.MAIN);
      sequence.sequenceEdges.push({
        type: SequenceEdgeType.NEXT,
        from: sequence.id,
        to: newSequence.id,
      });
      traverseSteps(nextStep, newSequence, stepMap, sequences, traversed);
    } else if (isConditional(nextStep) || isFanout(nextStep)) {
      // Create separate sequence for a conditional step
      newSequence = createSequence(nextStep, sequences, sequence.type);
      sequence.sequenceEdges.push({
        type: SequenceEdgeType.NEXT,
        from: sequence.id,
        to: newSequence.id,
      });
      conditionalBranchWidth += traverseSteps(nextStep, newSequence, stepMap, sequences, traversed);
    } else {
      // Add this step to the current sequence
      sequence.stepIds.push(nextStepId!);
      conditionalBranchWidth = Math.max(
        traverseSteps(nextStep, sequence, stepMap, sequences, traversed),
        conditionalBranchWidth,
      );
    }
  }

  if (isConditional(step)) {
    conditionalBranchWidth += 1;
    // iterate through each choice in the step; create a new sequence and add the step to it
    let i: number = 0;
    const choices: Choice[] = (step as ConditionalStep)?.parameters?.choices || [];
    while (i < choices.length) {
      choice = choices[i];
      nextStepId = choice.next;
      nextStep = nextStepId ? stepMap[nextStepId] : undefined;
      newSequence = createSequence(nextStep, sequences, SequenceType.BRANCH);
      newSequenceEdge = {
        type: SequenceEdgeType.BRANCH,
        from: sequence.id,
        to: newSequence.id,
      };
      sequence.stepEdges.push({
        label: choice.label,
        from: step.id,
        to: nextStepId,
      });
      sequence.sequenceEdges.push(newSequenceEdge);
      if (nextStep) {
        conditionalBranchWidth += traverseSteps(
          nextStep,
          newSequence,
          stepMap,
          sequences,
          traversed,
        );
      }
      i++;
    }

    // Create separate sequence for resolved condition
    nextStepId = step.next;
    nextStep = stepMap[nextStepId || ''];
    newSequence = createSequence(nextStep, sequences, SequenceType.MAIN);
    sequence.sequenceEdges.push({
      type: SequenceEdgeType.NEXT,
      from: sequence.id,
      to: newSequence.id,
    });
    if (nextStep) {
      maxConditionalBranchWidth = Math.max(
        traverseSteps(nextStep, newSequence, stepMap, sequences, traversed),
        conditionalBranchWidth,
      );
    } else {
      maxConditionalBranchWidth = Math.max(maxConditionalBranchWidth, conditionalBranchWidth);
    }

    sequence.conditionalBranchWidth = conditionalBranchWidth;
  } else if (isFanout(step)) {
    sequence.conditionalBranchWidth = 0.5;
    conditionalBranchWidth += 0.5;

    nextStepId = step.parameters.nextToIterate;
    nextStep = stepMap[nextStepId || ''];
    newSequence = createSequence(nextStep, sequences, SequenceType.FANOUT);
    sequence.sequenceEdges.push({
      type: SequenceEdgeType.FANOUT,
      from: sequence.id,
      to: newSequence.id,
    });
    if (nextStep) {
      sequence.conditionalBranchWidth = Math.max(
        traverseSteps(nextStep, newSequence, stepMap, sequences, traversed),
        sequence.conditionalBranchWidth,
      );
    }

    nextStep = stepMap[step.next || ''];
    newSequence = createSequence(nextStep, sequences, SequenceType.MAIN);
    sequence.sequenceEdges.push({
      type: SequenceEdgeType.NEXT,
      from: sequence.id,
      to: newSequence.id,
    });
    if (nextStep) {
      maxConditionalBranchWidth = Math.max(
        traverseSteps(nextStep, newSequence, stepMap, sequences, traversed),
        conditionalBranchWidth,
      );
    }
  }

  return Math.max(maxConditionalBranchWidth, conditionalBranchWidth);
}

export const EMPTY_STATE_MACHINE: StateMachine = {
  stepMap: {},
  sequenceMap: {},
  start: '',
  activeStepId: undefined,
  unusedSteps: [],
};

/**
 * ensures that a state machine is valid
 *
 * @param stateMachine the state machine to validate
 */
function verifyStateMachine(stateMachine: StateMachine): void {
  const steps: Step[] = Object.values(stateMachine.stepMap);
  let message: string;

  for (const step of steps) {
    // ensure no step is pointing to itself
    if (step.next === step.id) {
      message = "StateMachine invalid: step's 'next' property is pointing to itself.";
      // TODO: once apps support importing same shared logger on dashboard + other services,
      // uncomment this to help debugging
      // error(message, {
      //   stepId: step.id,
      //   workflowId: step.workflowId,
      //   stateMachine: stateMachine,
      // });
      throw new Error(message);
    }

    const parentSteps: Step[] = getUpstreamSteps(step.id, stateMachine);
    // TODO: uncomment this check to ensure no ghost steps are in the state machine
    // ensure no dangling (ghost) steps
    // if (!parentSteps.length && !isTrigger(step)) {
    //   message = 'StateMachine invalid: step has no parent steps.';
    //   danger(message, {
    //     stepId: step.id,
    //     workflowId: step.workflowId,
    //   });
    //   throw Error(message);
    // }

    // ensure no cyclical workflows (step pointing to a parent step)
    for (const parentStep of parentSteps) {
      if (step.next === parentStep.id) {
        message = 'StateMachine invalid: Cyclical workflow detected.';
        // TODO: once apps support importing same shared logger on dashboard + other services,
        // uncomment this to help debugging
        // error(message, {
        //   stepId: step.id,
        //   cyclicalStepId: parentStep.id,
        //   workflowId: step.workflowId,
        //   stateMachine: stateMachine,
        // });
        throw new Error(message);
      }
    }
  }
}

/**
 * removes cyclical referenes, unused steps
 *
 * @param stateMachine the state machine to validate
 */
export function sanitizeStateMachine(stateMachine: StateMachine): StateMachine {
  // remove unused steps from state machine
  if (!stateMachine.unusedSteps) {
    stateMachine.unusedSteps = [];
  }

  for (const stepId of stateMachine.unusedSteps) {
    for (const step of Object.values(stateMachine.stepMap)) {
      if (stepId === step.id) {
        delete stateMachine.stepMap[step.id];
      }
    }
  }

  // TODO(PARA-1245): #test write test to ensure step never points to itself or parent step
  const steps: Step[] = Object.values(stateMachine.stepMap);

  for (const step of steps) {
    // ensure steps aren't pointing to itself
    if (step.id === step.next) {
      stateMachine.stepMap[step.id].next = null;
    }

    // ensure next step exists
    if (step.next && !stateMachine.stepMap[step.next]) {
      stateMachine.stepMap[step.id].next = null;
    }

    // if a fanout but "nextToIterate" is set but doesn't exist in the state machine,
    // set it to undefined
    if (
      isFanout(step) &&
      step.parameters.nextToIterate &&
      !stateMachine.stepMap[step.parameters.nextToIterate]
    ) {
      (stateMachine.stepMap[step.id] as MapStep).parameters.nextToIterate = null;
    }

    // if a conditional and a choice's next is set but doesn't exist in the state machine,
    // set it to undefined
    if (isConditional(step)) {
      let index: number = -1;
      for (const choice of (step as ConditionalStep).parameters.choices) {
        index += 1;
        if (choice.next && !stateMachine.stepMap[choice.next]) {
          (stateMachine.stepMap[step.id] as ConditionalStep).parameters.choices[index].next = null;
        }
      }
    }

    // ensure no cyclical workflows (step pointing to a parent step)
    const parentSteps: Step[] = getUpstreamSteps(step.id, stateMachine);
    for (const parentStep of parentSteps) {
      if (step.next === parentStep.id && step.id !== parentStep.id) {
        // TODO: once apps support importing same shared logger on dashboard + other services,
        // uncomment this to help debugging
        // warn('detected cyclical step', {
        //   stepId: step.id,
        //   cyclicalStepId: parentStep.id,
        //   workflowId: step.workflowId,
        //   stateMachine: stateMachine,
        // });
        stateMachine.stepMap[step.id].next = null;
      }
    }

    // ensure no step is pointing to itself
    if (step.next === step.id) {
      stateMachine.stepMap[step.id].next = null;
    }
  }

  return stateMachine;
}

/**
 * Creates a state machine representation of a workflow or workflow fragment
 * from an array of Step objects.
 *
 * @param steps Step entities to create a state machine representation from
 * @param connectConditionalNextSteps If true, sets `.next` properties of the last steps
 * of conditional branches to the nearest resolving step
 * @param startStepId If specified, forces the state machine representation to begin at
 * this step, even if it's not a trigger-type step
 */
export function workflowStepsToStateMachine(
  steps: Step[],
  connectConditionalNextSteps: boolean = false,
  startStepId?: string,
): StateMachine {
  if (steps.length === 0) {
    return EMPTY_STATE_MACHINE;
  }

  const stepMap: StepMap = {};
  steps.forEach((step: Step) => (stepMap[step.id] = step));

  // get the first step;
  // initialize the first sequence
  const sequences: Sequence[] = [];
  const traversedSteps: string[] = [];
  const triggerStep: Step | undefined = steps.find(isTrigger);
  if (!triggerStep) {
    throw new Error('Trigger step not found');
  }

  createSequence(triggerStep, sequences, SequenceType.TRIGGER);
  traverseSteps(triggerStep, sequences[0], stepMap, sequences, traversedSteps);

  const sequenceMap: SequenceMap = {};
  sequences.forEach((sequence: Sequence) => (sequenceMap[sequence.id] = sequence));
  const stateMachine: StateMachine = {
    stepMap,
    sequenceMap,
    start: sequences[0].id,
    activeStepId: startStepId ? startStepId : triggerStep.id,
    finalStepId: startStepId && startStepId !== triggerStep.id ? startStepId : undefined,
    unusedSteps: steps
      .map((step: Step) => step.id)
      .filter((id: string) => !traversedSteps.includes(id)),
  };

  if (connectConditionalNextSteps) {
    let lastNextFromConditional: string | null = null;
    const visitedConditionalIds: string[] = [];
    const lastStep = getLastStepInSequence(
      stateMachine.sequenceMap[stateMachine.start],
      stateMachine,
    );
    traverseStateMachineBySequence(stateMachine, (sequence: Sequence) => {
      if (
        sequence.stepIds.length === 1 &&
        stateMachine.stepMap[sequence.stepIds[0]].type === StepType.IFELSE
      ) {
        const conditionalStep = stateMachine.stepMap[sequence.stepIds[0]];
        if (visitedConditionalIds.includes(conditionalStep.id)) {
          return;
        }
        if (
          !conditionalStep.next &&
          lastStep?.id !== conditionalStep.id &&
          conditionalStep.id !== lastNextFromConditional
        ) {
          conditionalStep.next = lastNextFromConditional;
        }
        lastNextFromConditional = conditionalStep.next;

        const branches = sequence.sequenceEdges.filter(
          (edge: SequenceEdge) => edge.type === SequenceEdgeType.BRANCH,
        );
        branches.forEach(({ to }: SequenceEdge) => {
          const lastStep = getLastStepInSequence(stateMachine.sequenceMap[to], stateMachine);
          if (lastStep && lastStep.id !== conditionalStep.next) {
            lastStep.next = conditionalStep.next;
          }
        });
        visitedConditionalIds.push(conditionalStep.id);
      }
    });
  }

  // @ts-ignore
  // const output = process.browser
  //   ? stateMachine
  //   : JSON.stringify(stateMachine, null, 4);
  // console.log('workflowStepsToStateMachine > created state machine', output);

  const finalStateMachine: StateMachine = sanitizeStateMachine(stateMachine);
  verifyStateMachine(finalStateMachine);

  return finalStateMachine;
}
