import axios, { AxiosError, AxiosInstance } from 'axios';

import { generateQueryStringFromObject } from '@shared/actions/sdk/utils';
import { CredentialValues } from '@shared/entities/sdk';
import { Operator } from '@shared/types/sdk/resolvers';

import { Credentials } from '../configs';

import { PARDOT_HOST, PARDOT_SANDBOX_HOST, SALESFORCE_HOST } from './constants';
import { QueryAPIResponse } from './types';

import { Environment, UserInfoResponse } from '.';

/**
 * @summary typeGuard for axios error
 * @param error
 */
const isAxiosError = (error: Error | AxiosError): error is AxiosError =>
  (error as AxiosError).response !== undefined;

/**
 * @summary helper function to log errors
 * @param err
 * @returns formatted error object
 */
export const handleResponseError = (err: Error | AxiosError): Error => {
  return new Error(
    `Pardot API error: ${isAxiosError(err) ? err.response?.data.error : err.message}`,
  );
};

/**
 *
 * @summary return basic axios instance
 * @param baseUrl - domain url
 * @param headers - headers to pass
 */
export function getAxiosInstance(
  headers: Record<string, string>,
  baseURL: string = PARDOT_HOST,
): AxiosInstance {
  return axios.create({
    baseURL: baseURL,
    headers: headers,
  });
}

/**
 * @summary creates pardot client
 * @param access_token
 * @param businessUnitId pardot business unit id
 * @param isSandbox
 * @param headers
 * @returns axios client
 */
export function getPardotClient(
  access_token: string,
  businessUnitId: string,
  isSandbox: boolean,
  headers = {},
): AxiosInstance {
  return getAxiosInstance(
    {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
      'Pardot-Business-Unit-Id': businessUnitId,
      ...headers,
    },
    isSandbox ? PARDOT_SANDBOX_HOST : PARDOT_HOST,
  );
}

/**
 * return new access token for Pardot generated from refresh token.
 * also returns invalid
 * @param credentials
 * @param environment
 */
export async function getAccessTokenFromRefreshToken(
  credentials: Credentials,
  environment: Environment,
): Promise<{ accessToken: string; updateCredentialValues: CredentialValues | undefined }> {
  if (await isAccessTokenValid(credentials.PARDOT_ACCESS_TOKEN)) {
    return { accessToken: credentials.PARDOT_ACCESS_TOKEN, updateCredentialValues: undefined };
  } else {
    const response = await axios.post(
      `${SALESFORCE_HOST}/services/oauth2/token`,
      generateQueryStringFromObject(
        {
          grant_type: 'refresh_token',
          client_id: environment.PARDOT_CLIENT_ID,
          client_secret: environment.PARDOT_CLIENT_SECRET,
          refresh_token: credentials.PARDOT_REFRESH_TOKEN,
        },
        { urlencodedValues: true, addPrefix: false },
      ),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    return {
      accessToken: response.data.access_token,
      updateCredentialValues:
        response.data.access_token !== credentials.PARDOT_ACCESS_TOKEN
          ? { PARDOT_ACCESS_TOKEN: response.data.access_token }
          : undefined,
    };
  }
}

/**
 * @summary checks if the access token is valid
 * @param accessToken
 * @returns
 */
export const isAccessTokenValid = async (accessToken: string): Promise<boolean> => {
  try {
    await getUserInfo(accessToken);
    return true;
  } catch (err) {
    return false;
  }
};

/**
 *
 * @param firstResponse Api response from pardot api -> it has structure {total_records: number, prospects: [] or lists: [] other property }
 * @param apiClient axios client
 * @param recordPropertyName the name of property present in the query response.
 * @param uri the url that has to be used for making get request.
 * @returns pardot response with totalRecords and all accumulated data.
 */
export const accumulatePaginationResponse = async (
  firstResponse: QueryAPIResponse,
  apiClient: AxiosInstance,
  recordPropertyName: string,
  uri: string,
): Promise<Record<string, any>> => {
  // todo check if typeguards can be used here.
  if (firstResponse.result.total_results === 0) {
    return {
      total_results: 0,
      [recordPropertyName]: [],
    };
  } else if (firstResponse.result.total_results === 1) {
    return {
      total_results: 1,
      [recordPropertyName]: [firstResponse.result[recordPropertyName]],
    };
  } else {
    let allRecords = [...(firstResponse.result[recordPropertyName] as Record<string, any>[])];
    let offset: number = (firstResponse.result[recordPropertyName] as Record<string, any>[]).length;
    let fetchedRecordCount = (firstResponse.result[recordPropertyName] as Record<string, any>[])
      .length;

    const totalRecords: number = firstResponse.result.total_results;
    while (fetchedRecordCount < totalRecords) {
      const intermediateResponse = await apiClient.get<QueryAPIResponse>(`${uri}&offset=${offset}`);
      allRecords = [
        ...allRecords,
        ...(Array.isArray(intermediateResponse.data.result[recordPropertyName])
          ? (intermediateResponse.data.result[recordPropertyName] as Record<string, any>[])
          : [intermediateResponse.data.result[recordPropertyName] as Record<string, any>]),
      ];
      fetchedRecordCount += (
        intermediateResponse.data.result[recordPropertyName] as Record<string, any>[]
      ).length;
      offset += (intermediateResponse.data.result[recordPropertyName] as Record<string, any>[])
        .length;
    }

    const finalResult: Record<string, any> = {
      total_results: totalRecords,
      [recordPropertyName]: allRecords,
    };

    return finalResult;
  }
};

/**
 * @summary requests user info for salesforce user.
 * @param accessToken
 */
export const getUserInfo = async (accessToken: string): Promise<UserInfoResponse> => {
  const userInfoResponse = await axios.get<UserInfoResponse>(
    `${SALESFORCE_HOST}/services/oauth2/userinfo`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return userInfoResponse.data;
};

export const pardotSupportedOperator = [
  Operator.StringExactlyMatches,
  Operator.NumberEquals,
  Operator.BooleanTrue,
  Operator.BooleanFalse,
];
