import { WorkflowTagEntity } from '@shared/entities/sdk/workflowTag/workflowTag.entity';
import { Step } from '@shared/types/sdk/steps';

import { indexBy } from '../../../utils';
import { Action, WorkFlow, WorkflowEntities, WorkflowEntity } from '../../types';

export const initialState: WorkflowEntities = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

function workflowToEntity(workflow: WorkFlow): WorkflowEntity {
  const { steps, ...rest } = workflow;
  return {
    ...rest,
    stepIds: steps.map((step: Step) => step.id),
  };
}

export function handler(state: WorkflowEntities = initialState, action: Action): WorkflowEntities {
  switch (action.type) {
    case 'WORKFLOW_ENTITY_FETCH_START':
    case 'CLONE_WORKFLOW_START':
      return {
        ...state,
        processing: true,
      };
    case 'WORKFLOW_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: indexBy('id', action.workflows.map(workflowToEntity)),
      };
    case 'WORKFLOW_ENTITY_FETCH_FAILED':
    case 'CLONE_WORKFLOW_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'GET_ONE_WORKFLOW_REQUEST':
    case 'STEP_ENTITY_CREATE_REQUEST':
      return {
        ...state,
        processing: true,
      };
    case 'GET_ONE_WORKFLOW_SUCCESS':
    case 'STEP_ENTITY_CREATE_SUCCESS':
    case 'STEP_ENTITY_DELETE_REQUEST':
    case 'STEP_ENTITY_REORDER_REQUEST':
    case 'STEP_ENTITY_REORDER_SUCCESS':
    case 'ADD_TAG_TO_WORKFLOW_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          [action.payload.id]: workflowToEntity(action.payload),
        },
      };
    case 'GET_ONE_WORKFLOW_FAILURE':
    case 'STEP_ENTITY_REORDER_FAILURE':
    case 'STEP_ENTITY_CREATE_FAILURE':
      return {
        ...state,
        processing: false,
      };

    case 'SET_WORKFLOW_DESCRIPTION_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.workflowId]: {
            ...state.entities[action.payload.workflowId],
            description: action.payload.description,
          },
        },
      };
    case 'REMOVE_TAG_FROM_WORKFLOW_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          [action.payload.workflowId]: {
            ...state.entities[action.payload.workflowId],
            tags: (state.entities[action.payload.workflowId].tags as WorkflowTagEntity[]).filter(
              (item: WorkflowTagEntity) => item.id !== action.payload.tagId,
            ),
          },
        },
      };
    case 'WORKFLOW_ENTITY_DELETE_START':
      return {
        ...state,
        processing: true,
      };
    case 'WORKFLOW_ENTITY_DELETE_SUCCESS':
      const { [action.payload.workflowId]: value, ...rest } = state.entities;
      return {
        ...state,
        processing: false,
        entities: {
          ...rest,
        },
      };
    case 'WORKFLOW_ENTITY_DELETE_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.payload.message,
      };
    case 'WORKFLOW_ENTITY_CREATION_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          [action.payload.id]: workflowToEntity(action.payload),
        },
      };

    case 'SET_WORKFLOW_ENTITY_CREATED_WORKFLOW_ID':
      return {
        ...state,
        createdWorkflowId: action.workflowId,
      };
    case 'UNSET_WORKFLOW_ENTITY_CREATED_WORKFLOW_ID':
      return {
        ...state,
        createdWorkflowId: undefined,
      };
    case 'SET_WORKFLOW_ENTITY_DUPLICATED_WORKFLOW_ID':
      return {
        ...state,
        duplicatedWorkflowId: action.workflowId,
      };
    case 'UNSET_WORKFLOW_ENTITY_DUPLICATED_WORKFLOW_ID':
      return {
        ...state,
        duplicatedWorkflowId: undefined,
      };
    case 'COMPLETE_TUTORIAL_REQUEST_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.updatedWorkflow.id]: workflowToEntity(action.updatedWorkflow),
        },
      };
    case 'CLONE_WORKFLOW_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: workflowToEntity(action.payload),
        },
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
