import { URL } from 'url';

import { AxiosError } from 'axios';
import { getBoundary, parse as parseMultipart } from 'parse-multipart-data';

import { UTMParameter } from '@shared/types/sdk/analytics';
import { isBrowser, isDocker } from '@shared/types/sdk/environment/utils';
import { HttpStatus } from '@shared/types/sdk/errors/base';
import { DataType } from '@shared/types/sdk/resolvers';

const DOCKER_HOST: string = 'host.docker.internal';
const LOCALHOST: string = 'localhost';

/**
 * helper function for localhost support in isomorphic web and docker environments
 *
 * @param {string | undefined} host potentially undefined b/c of unit tests not sharing same env
 * @returns {string}
 */
export function getHostname(host: string | undefined): string {
  // Docker on Mac uses `host.docker.internal` as a reference to localhost.
  // This only works when running in the server + in docker + on Mac.
  return host?.includes(DOCKER_HOST) && !isDocker()
    ? host.replace(new RegExp(DOCKER_HOST, 'g'), LOCALHOST)
    : host || '';
}

/**
 * given a domain, it returns the naked domain without CNAMEs
 *
 * @example
 *   https://api.useparagon.com -> useparagon.com
 * @export
 * @param {string} host
 * @param {boolean} includePort
 * @returns {string}
 */
export function getNakedDomain(
  host: string,
  includePort: boolean,
  mapHost: boolean = true,
): string {
  const sanitizedHost: string = `https://${host.split('://').pop()}`;
  // @ts-ignore: getting incompatible types for `window.URL` and `URL` for SearchParams
  const url: URL = isBrowser() ? new window.URL(sanitizedHost) : new URL(sanitizedHost);
  let hostname: string =
    url.hostname.includes(DOCKER_HOST) && !isDocker() && mapHost
      ? url.hostname.replace(new RegExp(DOCKER_HOST, 'g'), LOCALHOST)
      : url.hostname;

  if (hostname.split('.').length > 2) {
    hostname = hostname
      .split('.')
      .slice(host.split('.').length - 2)
      .join('.');
  }

  return includePort && host.includes(url.port)
    ? `${hostname}${url.port ? `:${url.port}` : ''}`
    : hostname;
}

export type HttpProxySettings = {
  protocol: 'http' | 'https';
  host: string;
  port: number;
};

/**
 * given a url, it returns the settings for using an http proxy
 * @param url
 */
export function parseHttpProxy(url: string | undefined): HttpProxySettings | undefined {
  if (!url) {
    return undefined;
  }
  const parsedURL: URL = new URL(url);
  return {
    protocol: parsedURL.protocol.replace(/:/, '').replace(/\//, '') as 'http' | 'https',
    host: parsedURL.hostname,
    port: parseInt(parsedURL.port),
  };
}

export function shouldRetryIfNotUnauthorizedOrRedirect(e: AxiosError): boolean {
  // 2xx range are successful
  // 3xx - 4xx range are unauthorized or redirect requests so avoid retries
  return (e.response?.status ?? (e.response?.['statusCode'] as number)) >= 500;
}

export function shouldRetryIfSocketHangUp(e: AxiosError): boolean {
  // ECONNRESET code if returned when socket hang up or connection refused
  return e.code === 'ECONNRESET' || e.code === 'ECONNREFUSED';
}

export function shouldRetryIfGatewayTimeout(e: AxiosError): boolean {
  // 504 status code return on gateway timeout
  return (
    (e.response?.status ?? (e.response?.['statusCode'] as number)) === HttpStatus.GATEWAY_TIMEOUT
  );
}

/**
 * hoc to return function that will return boolean for retrying http request
 * @param param0
 * @returns
 */
export function withShouldRetryHttpRequest({
  retryIfNotUnauthorizedOrRedirect,
  retryIfGatewayTimeout,
  retryIfSocketHangUp,
}: {
  retryIfNotUnauthorizedOrRedirect: boolean;
  retryIfGatewayTimeout: boolean;
  retryIfSocketHangUp: boolean;
}): (e: AxiosError) => boolean {
  return (e: AxiosError) => {
    if (retryIfNotUnauthorizedOrRedirect && shouldRetryIfNotUnauthorizedOrRedirect(e)) {
      return true;
    }
    if (retryIfGatewayTimeout && shouldRetryIfGatewayTimeout(e)) {
      return true;
    }
    if (retryIfSocketHangUp && shouldRetryIfSocketHangUp(e)) {
      return true;
    }
    return false;
  };
}

/**
 * given an object, it creates a query parameter string
 * @example
 *   { key1: 'value1', key2: 'value2' } => 'key1=value1&key2=value2'
 *
 * @export
 * @param {Record<string, string>} input
 * @returns {string}
 */
export function objectToSearchParams(input: Record<string, string | boolean>): string {
  return Object.keys(input)
    .map((key: string) => `${key}=${encodeURIComponent(input[key])}`)
    .join('&');
}

/**
 * returns the query parameters in a url
 *
 * @export
 * @param {string} url
 * @returns {Record<string, string>}
 */
export function getQueryParameters(url: string): Record<string, string> {
  const sanitizedHost: string = `https://${url.trim().split('://').pop()}`;
  const queryParamsString: string = sanitizedHost.split('?').pop() || url;
  return queryParamsString
    .split('&')
    .reduce((hash: Record<string, string>, keyValue: string): Record<string, string> => {
      const [key, value] = keyValue.split('=');
      return key !== '' && key !== undefined && value !== '' && value !== undefined
        ? {
            ...hash,
            [key]: decodeURIComponent(value),
          }
        : hash;
    }, {});
}

/**
 * returns the UTM query parameters in a url
 *
 * @export
 * @param {string} url
 * @returns {Record<string, string>}
 */
export function getUTMParameters(url: string): Partial<Record<UTMParameter, string>> {
  const queryParameters: Record<string, string> = getQueryParameters(url);
  const validUtmParameters: string[] = Object.values(UTMParameter);
  return Object.keys(queryParameters).reduce(
    (
      hash: Partial<Record<UTMParameter, string>>,
      key: string,
    ): Partial<Record<UTMParameter, string>> =>
      validUtmParameters.includes(key)
        ? {
            ...hash,
            [key]: queryParameters[key],
          }
        : hash,
    {},
  ) as Partial<Record<UTMParameter, string>>;
}

/**
 * it will add https protocol if protocol is not added in utl
 * @param url
 * @returns
 */
export function sanitizeUrl(url: string): string {
  if (!url) {
    return url;
  }

  // if any url not contain protocol then add https
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    return `https://${url}`;
  }
  return url;
}

/**
 * Build a URLSearchParams from an object
 *
 * @export
 * @param {(Record<string, string | string[]>)} data
 * @return {*}  {URLSearchParams}
 */
export function buildQueryParams(
  data: Record<string, string | string[] | number | number[] | boolean>,
): URLSearchParams {
  const params = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.map((subval) => params.append(`${key}[]`, subval));
    } else {
      params.append(key, value.toString());
    }
  });
  return params;
}

/**
 * check if url is valid or not
 * @param url
 * @returns
 */
export const isValidUrl = (url: string): boolean => {
  const sanitizedUrl: string = sanitizeUrl(url);
  try {
    new globalThis.URL(sanitizedUrl);
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * This function check weather the provided url is absolute or not, also clip off the forward slash if present in a url
 * @param url
 * @returns
 */
export const processedActionRequestUrl = (url: string): string | undefined => {
  if (!url) {
    return undefined;
  }
  url = url.startsWith('/') ? url.slice(1) : url;
  const urlMatchPattern = /^https?:\/\//i;
  return urlMatchPattern.test(url) ? url : undefined;
};

export const ContentType = {
  json: 'application/json',
  multipart: 'multipart/form-data',
  text: 'text/plain',
  urlencoded: 'application/x-www-form-urlencoded',
  xml: 'application/xml',
} as const;

/**
 * parse endcoded string
 * @param encodedString
 * @returns
 */
export const parseUrlEncodedString = (encodedString: string): Record<string, unknown> => {
  const entries = new URLSearchParams(encodedString).entries();
  const obj: Record<string, unknown> = {};
  for (const [key, value] of entries) {
    // add values to the same key in array
    if (obj[key]) {
      obj[key] = (Array.isArray(obj[key]) ? (obj[key] as []) : [obj[key]]).concat(value);
    } else {
      obj[key] = value;
    }
  }
  return obj;
};

/**
 * match contentType with expected content type
 * @param contentType
 * @param expected
 * @returns
 */
export const matchContentType = (
  contentType: string,
  expectedContentType: typeof ContentType[keyof typeof ContentType],
): boolean => {
  let hasMatched: boolean = false;
  switch (expectedContentType) {
    case ContentType.json:
      // also for custom vendor application/json type PARA-6365
      hasMatched = Boolean(contentType.match(/application\/(.*\+)?json/));
      break;
    case ContentType.text:
      hasMatched = contentType.startsWith('text');
      break;
    case ContentType.multipart:
    case ContentType.urlencoded:
    case ContentType.xml:
    default:
      hasMatched = contentType.startsWith(expectedContentType);
      break;
  }
  return hasMatched;
};

/**
 * parse raw body buffer
 * @param body
 * @param contentType
 * @returns
 */
export const parseRawBody = (
  body: Record<string, unknown> | string | Buffer | undefined,
  contentType?: string,
): Record<string, unknown> => {
  if (!body || !contentType) {
    return {};
  } else if (typeof body !== 'string' && !Buffer.isBuffer(body)) {
    return body;
  }

  const bufferObj: Buffer = Buffer.isBuffer(body) ? body : Buffer.from(body, 'hex');

  if (matchContentType(contentType, ContentType.json)) {
    return JSON.parse(bufferObj.toString());
  } else if (matchContentType(contentType, ContentType.multipart)) {
    try {
      const boundary = getBoundary(contentType);
      const parts = parseMultipart(bufferObj, boundary);
      return Object.fromEntries(
        parts.map((part) => {
          if (part.filename) {
            return [
              part.name,
              {
                data: part.data,
                dataType: DataType.FILE,
                name: part.filename,
                mimeType: part.type,
              },
            ];
          }
          return [part.name, part.data.toString()];
        }),
      );
    } catch (err) {
      return {};
    }
  } else if (matchContentType(contentType, ContentType.urlencoded)) {
    return parseUrlEncodedString(bufferObj.toString());
  } else if (
    matchContentType(contentType, ContentType.text) ||
    matchContentType(contentType, ContentType.xml)
  ) {
    return {
      data: bufferObj.toString(),
      dataType: DataType.STRING,
      mimeType: contentType,
    };
  }

  return {
    data: bufferObj,
    dataType: DataType.FILE,
    mimeType: contentType,
  };
};
