import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MONGODB;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'MONGODB_TEST_CONNECTION',
  LIST_COLLECTIONS = 'MONGODB_LIST_COLLECTIONS',
  INFER_COLLECTION_SCHEMA = 'MONGODB_INFER_COLLECTION_SCHEMA',
  FIND_RECORDS = 'MONGODB_FIND_RECORDS',
  FIND_RECORD_BY_ID = 'MONGODB_FIND_RECORD_BY_ID',
  CREATE_RECORD = 'MONGODB_CREATE_RECORD',
  UPDATE_RECORD_BY_ID = 'MONGODB_UPDATE_RECORD_BY_ID',
  DELETE_RECORD_BY_ID = 'MONGODB_DELETE_RECORD_BY_ID',
  UPDATE_RECORDS = 'MONGODB_UPDATE_RECORDS',
  DELETE_RECORDS = 'MONGODB_DELETE_RECORDS',
  WRITE_QUERY = 'MONGODB_WRITE_QUERY',

  TRIGGER_ROW_CREATED = 'MONGODB_TRIGGER_ROW_CREATED',
  TRIGGER_ROW_UPDATED = 'MONGODB_TRIGGER_ROW_UPDATED',
}

export const TriggerIntents = [Intent.TRIGGER_ROW_CREATED, Intent.TRIGGER_ROW_UPDATED];

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_COLLECTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.INFER_COLLECTION_SCHEMA;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIND_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIND_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.WRITE_QUERY;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ROW_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ROW_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  MONGODB_DATABASE: string;
  MONGODB_HOST: string;
  MONGODB_PORT: string;
  MONGODB_USER: string;
  MONGODB_PASSWORD: string;
};
