import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SAP_S4HANA;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SAP_TEST_CONNECTION',
  // Supplier Invoice
  GET_SUPPLIER_INVOICE_BY_ID = 'SAP_GET_SUPPLIER_INVOICE_BY_ID',
  CREATE_SUPPLIER_INVOICE = 'SAP_CREATE_SUPPLIER_INVOICE',
  SEARCH_SUPPLIER_INVOICES = 'SAP_SEARCH_SUPPLIER_INVOICES',
  DELETE_SUPPLIER_INVOICE = 'SAP_DELETE_SUPPLIER_INVOICE',
  //customer
  GET_CUSTOMER_BY_ID = 'SAP_GET_CUSTOMER_BY_ID',
  SEARCH_CUSTOMERS = 'SAP_SEARCH_CUSTOMERS',
  UPDATE_CUSTOMER = 'SAP_UPDATE_CUSTOMER',
  //supplier
  GET_SUPPLIER_BY_ID = 'SAP_GET_SUPPLIER_BY_ID',
  SEARCH_SUPPLIERS = 'SAP_SEARCH_SUPPLIERS',
  UPDATE_SUPPLIER = 'SAP_UPDATE_SUPPLIER',
}

/** Combination of ActionIntent and ConnectIntent **/
type IntentMap =
  | Intent.TEST_CONNECTION
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID
  | Intent.CREATE_SUPPLIER_INVOICE
  | Intent.DELETE_SUPPLIER_INVOICE
  | Intent.UPDATE_CUSTOMER
  | Intent.GET_SUPPLIER_INVOICE_BY_ID
  | Intent.UPDATE_SUPPLIER
  | Intent.SEARCH_SUPPLIER_INVOICES
  | Intent.SEARCH_CUSTOMERS
  | Intent.SEARCH_SUPPLIERS
  | Intent.GET_CUSTOMER_BY_ID
  | Intent.GET_SUPPLIER_BY_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  HOST_URL = 'SAP_HOST_URL',
  USERNAME = 'SAP_USERNAME',
  PASSWORD = 'SAP_PASSWORD',
  API_KEY = 'SAP_API_KEY',
}

export type Credentials = {
  [CredentialKeys.HOST_URL]: string;
  [CredentialKeys.USERNAME]: string;
  [CredentialKeys.PASSWORD]: string;
  [CredentialKeys.API_KEY]?: string;
};
