import { Dispatch } from 'redux';

import { ITeam, ITeamWithProjects } from '@shared/entities/sdk/team/team.interface';
import { debug } from '@shared/logger/sdk/legacy';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { Action } from '../../types';

export const createTeamWithProject =
  (name: string, organizationId: string) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'TEAM_ENTITY_CREATE_START' });

    try {
      const response = await api.post(`/teams?organizationId=${organizationId}`, {
        projectTitle: name,
        organizationId,
        name,
      });

      if (response.ok) {
        const { projects, ...team }: ITeamWithProjects = await response.json();
        dispatch({
          type: 'TEAM_ENTITY_CREATE_SUCCESS',
          team,
        });
        dispatch({
          type: 'PROJECT_ENTITIES_CREATION_SUCCESS',
          projects,
        });
        return team;
      } else {
        dispatch({
          type: 'TEAM_ENTITY_CREATE_FAILURE',
          message: await getErrorMessage(response),
        });
      }
    } catch (error) {
      dispatch({
        type: 'TEAM_ENTITY_CREATE_FAILURE',
        message: error.message,
      });
    }
  };

export const updateTeam = (team: Partial<ITeam>) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'TEAM_ENTITY_UPDATE_START' });

  const response = await api.put(`/teams/${team.id}`, team);

  if (response.ok) {
    const team = await response.json();
    dispatch({
      type: 'TEAM_ENTITY_UPDATE_SUCCESS',
      team,
    });
  } else {
    dispatch({
      type: 'TEAM_ENTITY_UPDATE_FAILURE',
      message: await getErrorMessage(response),
    });
  }
};

export const getTeams = () => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'TEAM_ENTITY_GET_START' });

  try {
    const response = await api.get('/teams');
    debug('getTeams > response', response);
    if (response.ok) {
      const teams = await response.json();
      debug('getTeams > response', teams);
      dispatch({ type: 'TEAM_ENTITY_GET_SUCCESS', teams });
    } else {
      dispatch({
        type: 'TEAM_ENTITY_GET_FAILURE',
        message: await getErrorMessage(response),
      });
    }
  } catch (error) {
    dispatch({
      type: 'TEAM_ENTITY_GET_FAILURE',
      message: error.message,
    });
  }
};
