import { IWorkflowBase } from '@shared/entities/sdk/workflow/workflow.interface';
import { IntegrationWorkflowMeta } from '@shared/types/sdk/connect';

export default function orderIntegrationWorkflows(
  workflows: IWorkflowBase[],
  workflowMeta: { [id: string]: IntegrationWorkflowMeta },
): IWorkflowBase[] {
  return workflows.sort((a: IWorkflowBase, b: IWorkflowBase) => {
    const [aMeta, bMeta]: Array<IntegrationWorkflowMeta | undefined> = [
      workflowMeta[a.id],
      workflowMeta[b.id],
    ];
    if (aMeta?.order === undefined && bMeta?.order === undefined) {
      return Number(new Date(a.dateCreated)) - Number(new Date(b.dateCreated));
    }
    return (aMeta?.order ?? Infinity) - (bMeta?.order ?? Infinity);
  });
}
