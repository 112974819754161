import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { FilterFormulaPayload } from '../../shared/types';

/**
 * @param condition
 * @returns condition for filter adobe commerce
 */
export default function conditionToFilterAdobeCommerceExpression(
  condition: ResolvedConditionWrapper,
): any {
  if (condition.type === 'JOIN') {
    const conditions = condition.conditions.map((condition: ResolvedConditionWrapper) => {
      return conditionToFilterAdobeCommerceExpression(condition);
    });

    return createAdobeCommerceFilterConditions(conditions, condition.join);
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    const conditionFields: Omit<FilterFormulaPayload, 'condition_type'> = {
      field: innerCondition.variable,
      value: encodeURIComponent(innerCondition.argument),
      filter: 0,
      filter_group: 0,
    };

    switch (innerCondition.operator) {
      case Operator.StringContains:
        return {
          condition_type: 'in',
          ...conditionFields,
        };

      case Operator.StringExactlyMatches:
      case Operator.NumberEquals:
        return {
          condition_type: 'eq',
          ...conditionFields,
        };

      case Operator.DateTimeEquals:
        return {
          ...conditionFields,
          value: new Date(innerCondition.argument).toISOString(),
          condition_type: 'eq',
        };

      case Operator.DateTimeAfter:
        return {
          ...conditionFields,
          value: new Date(innerCondition.argument).toISOString(),
          condition_type: 'gt',
        };

      case Operator.DateTimeBefore:
        return {
          ...conditionFields,
          value: new Date(innerCondition.argument).toISOString(),
          condition_type: 'lt',
        };

      case Operator.NumberGreaterThan:
        return {
          condition_type: 'gt',
          ...conditionFields,
        };

      case Operator.NumberLessThan:
        return {
          condition_type: 'lt',
          ...conditionFields,
        };

      case Operator.NumberGreaterThanOrEqualTo:
        return {
          condition_type: 'gteq',
          ...conditionFields,
        };

      case Operator.NumberLessThanOrEqualTo:
        return {
          condition_type: 'lteq',
          ...conditionFields,
        };

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Adobe Commerce`);
    }
  }

  return '';
}

/**
 * @param conditions
 * @returns condition for filter formulas
 */
const createAdobeCommerceFilterConditions = (
  conditions: FilterFormulaPayload[] | FilterFormulaPayload[][],
  join: 'OR' | 'AND',
  dto?: { filter: number; filter_group: number },
) => {
  if (Array.isArray(conditions[0])) {
    return incrementFilterCondition(conditions as FilterFormulaPayload[][], join);
  }

  return (conditions as FilterFormulaPayload[]).map(
    (input: FilterFormulaPayload, index: number) => {
      return join === 'OR'
        ? {
            ...input,
            filter: (dto?.filter as number) || index,
          }
        : {
            ...input,
            filter_group: (dto?.filter_group as number) || index,
          };
    },
  );
};

/**
 * @param conditions
 * @param join
 * @returns condition for increment the filter & filterGroups index value
 */
export const incrementFilterCondition = (
  conditions: FilterFormulaPayload[][],
  join: 'OR' | 'AND',
) => {
  return conditions.map((inputs, index) => {
    const filter = inputs[inputs.length - 1].filter;
    const filterGroup = inputs[inputs.length - 1].filter_group;

    return index === conditions.length - 1
      ? createAdobeCommerceFilterConditions(inputs as FilterFormulaPayload[], join, {
          filter: join === 'OR' && filter === 0 ? filter + 1 : filter,
          filter_group: join === 'AND' && filterGroup === 0 ? filterGroup + 1 : filterGroup,
        })
      : inputs;
  });
};
