import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { docuSignSupportedOperators } from '../shared/constants';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Envelopes',
          items: [
            {
              value: Intent.CREATE_AN_ENVELOPE,
              label: 'Create an Envelope',
            },
            {
              value: Intent.GET_ENVELOPE_BY_ID,
              label: 'Get Envelope by ID ',
            },
            {
              value: Intent.UPDATE_ENVELOPE,
              label: 'Update Envelope',
            },
            {
              value: Intent.SEND_AN_ENVELOPE,
              label: 'Send an Envelope',
            },
            {
              value: Intent.SEARCH_ENVELOPES,
              label: 'Search Envelopes',
            },
            {
              value: Intent.ENVELOPE_CUSTOM_FIELDS,
              label: 'Envelope Custom Fields (CRUD)',
            },
          ],
        },
      ],
    },
  ],
};

export const documentsInput: SidebarInput = {
  id: 'documents',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Documents',
  required: false,
  placeholder: `[
  {
    "documentBase64": "JVBERi0xLjcKCjQgMCBvYmoKPDw...",
    "documentId": "1",
    "fileExtension": "PDF",
    "name": "TXU.pdf"
  }
]`,
  useLightTheme: true,
};

export const recipientsInput: SidebarInput = {
  id: 'recipients',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Recipients',
  required: false,
  placeholder: `{
  "signers": [
    {
      "email": "test@test.com",
      "name": "Jesse",
      "recipientId": 56011693
    }
  ]
}`,
  useLightTheme: true,
};

export const envelopeFilterInput: SidebarInput = {
  id: 'envelopeFilterSearch',
  title: 'Filter search',
  type: SidebarInputType.Conditional,
  supportedOperators: docuSignSupportedOperators,
  required: false,
  getValues: () => [
    { label: 'User ID', value: 'user_id' },
    { label: 'Email Contains Text', value: 'search_text' },
    { label: 'From Date', value: 'from_date' },
    { label: 'To Date', value: 'to_date' },
    { label: 'Envelope IDs', value: 'envelope_ids' },
    { label: 'Transaction IDs', value: 'transaction_ids' },
    { label: 'Status', value: 'status' },
    { label: 'Ac Status', value: 'ac_status' },
  ],
};

export const statusInput: SidebarInput = {
  id: 'status',
  title: 'Status',
  type: SidebarInputType.Enum,
  required: true,
  getValues: () => [
    { label: 'Created', value: 'created' },
    { label: 'Sent', value: 'sent' },
  ],
};

export const emailSubjectInput: SidebarInput = {
  id: 'emailSubject',
  title: 'Email Subject',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const emailBlurbInput: SidebarInput = {
  id: 'emailBlurb',
  title: 'Email Blurb',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const envelopeIdInput: SidebarInput = {
  id: 'envelopeId',
  title: 'Envelope ID',
  type: SidebarInputType.TextArea,
  placeholder: '93be49ab-xxxx-xxxx-xxxx-f752070d71ec',
  lines: 1,
  required: true,
};

export const envelopeCustomFieldOperationInput: SidebarInput = {
  id: 'envelopeCustomFieldOperation',
  title: 'Operation',
  type: SidebarInputType.Enum,
  subtitle: 'To perform execution based on Operation.',
  required: true,
  getValues: () => [
    { label: 'Get', value: 'get' },
    { label: 'Create', value: 'post' },
    { label: 'Update', value: 'put' },
    { label: 'Delete', value: 'delete' },
  ],
};

export const textCustomFieldsInput: SidebarInput = {
  id: 'textCustomFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  title: 'Text Custom Fields',
  required: false,
  placeholder: `[
  {
    "fieldId": "string",
    "name": "string",
    "show": "boolean"
  }
]`,
  useLightTheme: true,
};

export const listCustomFieldsInput: SidebarInput = {
  id: 'listCustomFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'List Custom Fields',
  required: false,
  placeholder: `[
  {
    "listItems": [
      "string"
    ],
    "fieldId": "string",
    "name": "string"
  }
]`,
  useLightTheme: true,
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Additional Fields',
  required: false,
  subtitle: 'Specify any additional fields that should be needed in the envelope item as JSON.',
  placeholder: `{
  "allowRecipientRecursion": "string",
  "templateId": "string",
  "password": "string"
}`,
  useLightTheme: true,
};
