import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, IntegrationConnectInput, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs/config';
import iconSvg from '../configs/icon.svg';
import { networkCodesKey } from '../shared/constants';

export const networkCodeInput: IntegrationConnectInput = {
  id: CredentialKeys.NETWORK_CODE,
  title: 'Choose a Google Ad Manager network code to use',
  placeholder: 'Network Code',
  type: SidebarInputType.DynamicEnum,
  required: true,
  sourceType: networkCodesKey,
};

const authConfig: ActionConfig = {
  actionType: Action.GOOGLE_AD_MANAGER,
  provider: ProviderType.GOOGLE_AD_MANAGER,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Google Ad Manager accounts',
  icon: iconSvg,
  description:
    "Connect to your users' Google Ad Manager account to manage their ad inventory, orders, reports, and more.",
  sidebarSections: [],
  postOAuthPrompt: [networkCodeInput],
};

export default authConfig;
