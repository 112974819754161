import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * @param condition
 * @returns condition for filter sharepoint
 */

export default function conditionsToFilterSharePointExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterSharePointExpression(condition);
      })
      .join(condition.join === 'OR' ? ' OR ' : ' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
      case Operator.NumberEquals:
        return `${innerCondition.variable} eq '${innerCondition.argument}'`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable} ne '${innerCondition.argument}'`;
      case Operator.NumberGreaterThan:
        return `${innerCondition.variable} gt '${innerCondition.argument}'`;
      case Operator.NumberLessThan:
        return `${innerCondition.variable} lt '${innerCondition.argument}'`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `${innerCondition.variable} ge '${innerCondition.argument}'`;
      case Operator.NumberLessThanOrEqualTo:
        return `${innerCondition.variable} le '${innerCondition.argument}'`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} gt '${new Date(innerCondition.argument).toISOString()}'`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} lt '${new Date(innerCondition.argument).toISOString()}'`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} eq '${new Date(innerCondition.argument).toISOString()}'`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for SharePoint`);
    }
  }
  return '';
}
