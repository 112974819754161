import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * @summary there is no support for or condition so "and" and "or" behave in a similar manner
 * @param condition
 * @returns condition for filter calendly
 */

export default function conditionsToFilterCalendlyExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterCalendlyExpression(condition);
      })
      .join('&');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
      case Operator.NumberEquals:
        return `${innerCondition.variable}=${innerCondition.argument}`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Calendly`);
    }
  }
  return '';
}
