import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Mailchimp',
  description: 'Connect to your users’ Mailchimp accounts',
  authUrl: 'https://login.mailchimp.com/oauth2/authorize',
  accessTokenUrl: 'https://login.mailchimp.com/oauth2/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/mailchimp`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: { notAllowed: true, defaultValues: ['_NO_SCOPE_ALLOWED_'], values: [] },
  documentationLink: 'https://mailchimp.com/developer/marketing/docs/fundamentals/',
};
