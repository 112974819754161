import { hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig, { authInput } from './authConfig';
import {
  accountIdInput,
  accountsFilterInputOauth,
  commonIntentInput,
  contactInput,
  contactsFilterInputOauth,
  createUpdateCustomerInputs,
  currencyConnectInput,
  customerIdInput,
  dateInput,
  dueDateInput,
  firstNameInput,
  invoiceIdInput,
  invoiceNumberInput,
  invoiceStatusInput,
  invoiceTypeInput,
  invoicesFilterInputOauth,
  lastNameInput,
  lineItemsInput,
  linkedInvoiceInput,
  nameInput,
  paymentsFilterInputOauth,
  referenceInput,
  sendToEmailInput,
  totalAmountInput,
} from './common';

const config: ActionConfig = {
  actionType: Action.XERO,
  name: 'Xero',
  description:
    'Connect your Xero account to create or update your customers, invoices, or payments in Xero.',
  icon: iconSvg,
  provider: ProviderType.XERO,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        authInput,
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.XERO,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length ? commonIntentInput : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.UPDATE_INVOICE:
        case Intent.SEND_INVOICE:
          return {
            inputs: [
              {
                ...invoiceIdInput,
                subtitle:
                  parameters.intent === Intent.UPDATE_INVOICE ?? 'The ID of the invoice to update.',
              },
            ],
          };
        case Intent.UPDATE_CUSTOMER:
          return { inputs: [customerIdInput] };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.GET_ACCOUNTS:
          return {
            inputs: [accountsFilterInputOauth],
          };
        case Intent.GET_CONTACTS:
          return {
            inputs: [contactsFilterInputOauth],
          };
        case Intent.GET_INVOICES:
          return {
            inputs: [invoicesFilterInputOauth],
          };
        case Intent.GET_PAYMENTS:
          return {
            inputs: [paymentsFilterInputOauth],
          };
        case Intent.CREATE_CUSTOMER:
          return {
            inputs: [nameInput, firstNameInput, lastNameInput, ...createUpdateCustomerInputs],
          };
        case Intent.CREATE_INVOICE:
          return {
            inputs: [
              invoiceTypeInput,
              contactInput,
              lineItemsInput,
              { ...invoiceStatusInput, required: false },
              dateInput,
              dueDateInput,
              currencyConnectInput,
              invoiceNumberInput,
              referenceInput,
            ],
          };
        case Intent.CREATE_PAYMENT:
          return {
            inputs: [totalAmountInput, linkedInvoiceInput, accountIdInput, currencyConnectInput],
          };
        case Intent.SEND_INVOICE:
          return {
            inputs: [sendToEmailInput],
          };
        case Intent.UPDATE_CUSTOMER:
          return {
            inputs: [
              { ...firstNameInput, required: false },
              { ...lastNameInput, required: false },
              ...createUpdateCustomerInputs,
            ],
          };
        case Intent.UPDATE_INVOICE:
          return { inputs: [{ ...invoiceStatusInput, required: false }, referenceInput] };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;

export { authConfig };
