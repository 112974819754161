import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_ANALYTICS;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'GOOGLE_ANALYTICS_TEST_CONNECTION',
  GET_REALTIME_DATA = 'GOOGLE_ANALYTICS_GET_REALTIME_DATA',
  RUN_REPORT = 'GOOGLE_ANALYTICS_RUN_REPORT',
  GET_ACCOUNTS = 'GOOGLE_ANALYTICS_GET_ACCOUNTS',
  GET_PROPERTIES = 'GOOGLE_ANALYTICS_GET_PROPERTIES',
  GET_VIEWS = 'GOOGLE_ANALYTICS_GET_VIEWS',
  GET_CUSTOM_METRICS = 'GOOGLE_ANALYTICS_GET_CUSTOM_METRICS',
  GET_CUSTOM_DIMENSIONS = 'GOOGLE_ANALYTICS_GET_CUSTOM_DIMENSIONS',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.RUN_REPORT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ACCOUNTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_REALTIME_DATA;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROPERTIES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_VIEWS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CUSTOM_METRICS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CUSTOM_DIMENSIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  accountId: string;
  GOOGLE_ANALYTICS_REFRESH_TOKEN: string;
  GOOGLE_ANALYTICS_ACCESS_TOKEN: string;
};
