export default `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
<path d="M63.8365 35.2548C63.9446 34.1845 64 33.0987 64 32C64 14.3269 49.6731 0 32 0C30.9013 0 29.8155 0.0553754 28.7452 0.163487C27.7265 0.538599 27 1.51779 27 2.66667V8.01752C11.9992 8.54409 0 20.8708 0 36C0 51.464 12.536 64 28 64C43.1292 64 55.4559 52.0008 55.9825 37H61.3333C62.4822 37 63.4614 36.2734 63.8365 35.2548Z" fill="url(#paint0_linear)"/>
</mask>
<g mask="url(#mask0)">
<circle cx="28" cy="36" r="28" fill="url(#paint1_linear)"/>
<g filter="url(#filter0_dd)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.8365 35.2548C63.9446 34.1845 64 33.0987 64 32C64 14.3269 49.6731 0 32 0C30.9013 0 29.8155 0.0553754 28.7452 0.163487C27.7265 0.538599 27 1.51779 27 2.66667V37H61.3333C62.4822 37 63.4614 36.2734 63.8365 35.2548Z" fill="url(#paint2_linear)"/>
</g>
<mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="27" y="0" width="37" height="37">
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.8365 35.2548C63.9446 34.1845 64 33.0987 64 32C64 14.3269 49.6731 0 32 0C30.9013 0 29.8155 0.0553754 28.7452 0.163487C27.7265 0.538599 27 1.51779 27 2.66667V37H61.3333C62.4822 37 63.4614 36.2734 63.8365 35.2548Z" fill="url(#paint3_linear)"/>
</mask>
<g mask="url(#mask1)">
<g filter="url(#filter1_dd)">
<path d="M27 20H41.3333C42.8061 20 44 21.1939 44 22.6667V37H27V20Z" fill="url(#paint4_linear)"/>
</g>
</g>
</g>
<defs>
<filter id="filter0_dd" x="21.6667" y="-4" width="47.6667" height="47.6667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="0.253333"/>
<feGaussianBlur stdDeviation="0.253333"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1.33333"/>
<feGaussianBlur stdDeviation="2.66667"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0"/>
<feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
</filter>
<filter id="filter1_dd" x="21.6667" y="16" width="27.6667" height="27.6667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="0.253333"/>
<feGaussianBlur stdDeviation="0.253333"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1.33333"/>
<feGaussianBlur stdDeviation="2.66667"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0"/>
<feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
</filter>
<linearGradient id="paint0_linear" x1="28.242" y1="20.138" x2="35.4468" y2="38.3272" gradientUnits="userSpaceOnUse">
<stop stop-color="#A7F2FF"/>
<stop offset="1" stop-color="#7CECFF"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="20.5566" y1="26.9017" x2="39.2675" y2="61.8388" gradientUnits="userSpaceOnUse">
<stop stop-color="#0078D4"/>
<stop offset="1" stop-color="#2064AE"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="37.3333" y1="6.33334" x2="72.7891" y2="38.4887" gradientUnits="userSpaceOnUse">
<stop stop-color="#40C4F5"/>
<stop offset="1" stop-color="#0095E6"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="37.3333" y1="6.33334" x2="72.7891" y2="38.4887" gradientUnits="userSpaceOnUse">
<stop stop-color="#40C4F5"/>
<stop offset="1" stop-color="#0095E6"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="28.2419" y1="20.138" x2="35.4468" y2="38.3272" gradientUnits="userSpaceOnUse">
<stop stop-color="#A7F2FF"/>
<stop offset="1" stop-color="#7CECFF"/>
</linearGradient>
</defs>
</svg>

`;
