import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class StepError extends ParagonError {
  name: string = 'StepError';
  code: ERROR_CODE;
  message: string;
  meta: { stepId: string } & Record<string, any>;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: { stepId: string } & Record<string, any>;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class StepValidationError extends StepError {
  constructor({ stepId, message }: { stepId: string; message: string }) {
    super({
      code: ERROR_CODE.INVALID_STEP_ERROR,
      httpStatus: HttpStatus.BAD_REQUEST,
      message,
      meta: { stepId },
    });
  }
}

export class TriggerStepInvalidTriggerError extends StepError {
  constructor(meta: {
    projectId: string;
    workflowId: string;
    stepId: string;
    executionId: string;
  }) {
    super({
      code: ERROR_CODE.INVALID_TRIGGER_STEP,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'A valid trigger is not selected.',
      meta: meta,
    });
  }
}
