import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs/config';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.LEVER,
  provider: ProviderType.LEVER,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Lever account',
  icon: iconSvg,
  description: 'Sync applications and candidates from Lever.',
  sidebarSections: [],
  accountTypes: [
    {
      id: 'default',
      accountDescription: 'Production Account',
      scheme: AuthenticationScheme.OAUTH,
    },
    {
      id: 'sandbox',
      accountDescription: 'Sandbox Account',
      scheme: AuthenticationScheme.OAUTH,
      oauthParameters: [
        {
          key: 'sandbox',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    },
  ],
};

export default authConfig;
