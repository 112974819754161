import { Action } from '@shared/types/sdk/actions';
import { ParsedWebhookEvent } from '@shared/types/sdk/actions/actionTrigger';

import { WebhookEventIntentMap, WebhookEvents } from '../../configs';

export type Event = {
  event: WebhookEvents;
  payload: {
    account_id: string;
    object: Record<string, any>;
  };
};

export default function parseWebhookEvent(event: Event): ParsedWebhookEvent {
  return {
    integration: Action.ZOOM,
    intent: WebhookEventIntentMap[event.event],
    providerId: event.payload.account_id,
  };
}
