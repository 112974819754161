import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

export const subjectInput: SidebarInput = {
  id: 'subject',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Subject',
  required: true,
  subtitle: 'The text of the event’s subject line.',
};

export const startTimeInput: SidebarInput = {
  id: 'startTime',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Start Time',
  required: true,
  subtitle: 'Accepts YYYY-MM-DD[T]HH:MM:SS date formats.',
};

export const endTimeInput: SidebarInput = {
  id: 'endTime',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'End Time',
  required: false,
  subtitle:
    'Accepts YYYY-MM-DD[T]HH:MM:SS date formats. Defaults to one hour after the start time if left blank.',
};

export const calendarInput: SidebarInput = {
  id: 'calendarId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Calendar',
  required: false,
  placeholder: '{{settings.calendar}}',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select which calendar the event will be added to. Defaults to the user’s primary calendar if left blank.',
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  type: SidebarInputType.TextArea,
  lines: 4,
  title: 'Description',
  subtitle: 'The message associated with the event.',
  required: false,
};

export const contentTypeInput: SidebarInput = {
  id: 'contentType',
  type: SidebarInputType.EditableEnum,
  title: 'Content Type',
  required: false,
  defaultValue: 'text',
  getValues: () => [
    { label: 'Plain Text', value: 'text' },
    { label: 'HTML', value: 'html' },
  ],
};

export const locationNameInput: SidebarInput = {
  id: 'locationName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Location Name',
  required: false,
  subtitle: 'Display name of the event location.',
};

export const locationUrlInput: SidebarInput = {
  id: 'locationUrl',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Location URL',
  required: false,
  subtitle: 'URL representing the location.',
};

export const attendeesInput: SidebarInput = {
  id: 'attendees',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Attendees',
  required: false,
  subtitle: 'Accepts an array of email addresses or email addresses separated by commas.',
};

export const eventIdInput: SidebarInput = {
  id: 'eventId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Event ID',
  required: true,
  subtitle: 'The ID of the event to update.',
};

export const mailFolderInput: SidebarInput = {
  id: 'mailFolder',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Mail Folder',
  required: false,
  placeholder: '{{settings.mailfolder}}',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select which mail folder to get messages from. Defaults to the user’s primary mail folder if left blank.',
};

export const limitResultsInput: SidebarInput = {
  id: 'limitResults',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Limit Results',
  subtitle:
    'Limit the maximum number of messages to return. Defaults to 10 if left blank. Maximum is 1,000.',
  required: false,
};

export const messageBodyInput: SidebarInput = {
  id: 'messageBody',
  type: SidebarInputType.TextArea,
  lines: 4,
  title: 'Message Body',
  required: true,
};

export const toRecipientsInput: SidebarInput = {
  id: 'toRecipients',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'To recipients',
  lines: 1,
  subtitle: 'Accepts an array of email addresses or email addresses separated by commas.',
};

export const ccRecipientsInput: SidebarInput = {
  id: 'ccRecipients',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'CC recipients',
  lines: 1,
  subtitle: 'Accepts an array of email addresses or email addresses separated by commas.',
};

export const bccRecipientsInput: SidebarInput = {
  id: 'bccRecipients',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'BCC recipients',
  lines: 1,
  subtitle: 'Accepts an array of email addresses or email addresses separated by commas.',
};

export const createEventInput = (isUpdating: boolean): SidebarInput[] => [
  { ...subjectInput, required: !isUpdating },
  { ...startTimeInput, required: !isUpdating },
  timeZoneInput,
  {
    ...endTimeInput,
    subtitle: isUpdating
      ? 'Accepts date in YYYY-MM-DD[T]HH:MM:SS format.'
      : 'Accepts date in YYYY-MM-DD[T]HH:MM:SS format. Defaults to one hour after the start time if left blank.',
  },
  calendarInput,
  descriptionInput,
  contentTypeInput,
  locationNameInput,
  locationUrlInput,
  attendeesInput,
];

export const timeZoneInput: SidebarInput = {
  id: 'timeZone',
  title: 'TimeZone',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '{{settings.timezone}}',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select which timezone the event will be added to. Defaults to the UTC timezone if left blank.',
};

export const eventsFilterInput: SidebarInput = {
  id: 'eventsFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for events that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
    Operator.BooleanFalse,
    Operator.BooleanTrue,
  ],
  getValues: () => [
    { label: 'Subject', value: 'subject' },
    { label: 'Importance', value: 'importance' },
    { label: 'Sensitivity', value: 'sensitivity' },
    { label: 'Start Date', value: 'start/dateTime' },
    { label: 'End Date', value: 'end/dateTime' },
    { label: 'Location Name', value: 'location/displayName' },
    { label: 'Organizer', value: 'organizer/emailAddress/name' },
  ],
};

export const messagesFilterInput: SidebarInput = {
  id: 'messagesFilterFormula',
  title: 'Filter search',
  subtitle: 'Filter results by search keywords.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.BooleanTrue,
    Operator.BooleanFalse,
    Operator.StringContains,
    Operator.StringEndsWith,
    Operator.StringStartsWith,
  ],
  getValues: () => [
    { label: 'Subject', value: 'subject' },
    { label: 'Sender Email', value: 'sender/emailAddress/address' },
    { label: 'Is Draft', value: 'isDraft' },
    { label: 'Is Read', value: 'isRead' },
  ],
  disableMultipleConditions: true,
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Calendar',
          items: [
            {
              value: Intent.CREATE_EVENT,
              label: 'Create Event',
            },
            {
              value: Intent.UPDATE_EVENT,
              label: 'Update Event',
            },
            {
              value: Intent.GET_EVENT_BY_ID,
              label: 'Get Event by ID',
            },
            {
              value: Intent.GET_EVENTS,
              label: 'Get Events',
            },
            {
              value: Intent.DELETE_EVENT,
              label: 'Delete Event',
            },
          ],
        },
        {
          title: 'Mail',
          items: [
            {
              value: Intent.GET_MESSAGES,
              label: 'Get Messages',
            },
            {
              value: Intent.SEND_MESSAGE,
              label: 'Send Message',
            },
          ],
        },
      ],
    },
  ],
};
