import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  activeInput,
  adFilterInput,
  adIdInput,
  adTypeInput,
  advertiserFilterInput,
  advertiserIdInput,
  archivedInput,
  billingInvoiceCodeInput,
  campaignFilterInput,
  campaignIdInput,
  commentInput,
  commonIntentInput,
  creativeAssetsInput,
  creativeAssignmentInput,
  creativeFilterInput,
  creativeGroupFilterInput,
  creativeGroupIdInput,
  creativeGroupIdsInput,
  creativeIdInput,
  creativeTypeInput,
  endDateInput,
  endTimeInput,
  fileInput,
  heightInput,
  nameInput,
  paymentSourceInput,
  placementAssignmentInput,
  placementFilterInput,
  placementIdInput,
  pricingTypeInput,
  siteIdInput,
  startDateInput,
  startTimeInput,
  videoFormatIdInput,
  widthInput,
} from './common';

const config: ActionConfig = {
  actionType: Action.GOOGLE_CAMPAIGN_MANAGER,
  name: 'Google Campaign Manager 360',
  description:
    'Connect to your customers’ Campaign Manager 360 accounts to manage their ads, creatives, and campaigns in CM360.',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    commonIntentInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_AD:
          return {
            inputs: [
              nameInput,
              campaignIdInput,
              advertiserIdInput,
              startTimeInput,
              endTimeInput,
              { ...adTypeInput, defaultValue: 'AD_SERVING_STANDARD_AD' },
              { ...activeInput, defaultValue: 'false' },
              commentInput,
              creativeAssignmentInput,
              placementAssignmentInput,
              heightInput,
              widthInput,
            ],
          };
        case Intent.UPDATE_AD:
          return {
            inputs: [
              { ...adIdInput, subtitle: 'The ID of the ad to update.' },
              ...[
                nameInput,
                campaignIdInput,
                advertiserIdInput,
                startTimeInput,
                endTimeInput,
                adTypeInput,
                activeInput,
                commentInput,
                creativeAssignmentInput,
                placementAssignmentInput,
                heightInput,
                widthInput,
              ].map((item) => ({ ...item, required: false })),
            ],
          };
        case Intent.GET_ALL_ADS:
          return {
            inputs: [adFilterInput],
          };
        case Intent.GET_AD_BY_ID:
          return {
            inputs: [adIdInput],
          };
        case Intent.GET_ALL_ADVERTISERS:
          return {
            inputs: [advertiserFilterInput],
          };
        case Intent.GET_ADVERTISER_BY_ID:
          return {
            inputs: [advertiserIdInput],
          };
        case Intent.CREATE_CAMPAIGN:
          return {
            inputs: [
              nameInput,
              advertiserIdInput,
              startDateInput,
              endDateInput,
              { ...archivedInput, defaultValue: 'false' },
              commentInput,
              creativeGroupIdsInput,
              billingInvoiceCodeInput,
            ],
          };
        case Intent.UPDATE_CAMPAIGN:
          return {
            inputs: [
              { ...campaignIdInput, subtitle: 'The ID of the campaign to update.' },
              ...[
                nameInput,
                advertiserIdInput,
                startDateInput,
                endDateInput,
                { ...archivedInput, defaultValue: '' },
                commentInput,
                creativeGroupIdsInput,
                billingInvoiceCodeInput,
              ].map((item) => ({
                ...item,
                required: false,
              })),
            ],
          };
        case Intent.GET_ALL_CAMPAIGNS:
          return {
            inputs: [campaignFilterInput],
          };
        case Intent.GET_CAMPAIGN_BY_ID:
          return {
            inputs: [campaignIdInput],
          };
        case Intent.CREATE_CREATIVE:
          return {
            inputs: [
              nameInput,
              advertiserIdInput,
              { ...activeInput, defaultValue: 'false' },
              {
                ...creativeTypeInput,
                defaultValue: 'IMAGE',
              },
              heightInput,
              widthInput,
              creativeAssetsInput,
            ],
          };
        case Intent.UPDATE_CREATIVE:
          return {
            inputs: [
              { ...creativeIdInput, subtitle: 'The ID of the creative to update.' },
              ...[
                nameInput,
                advertiserIdInput,
                { ...activeInput, defaultValue: '' },
                { ...creativeTypeInput, defaultValue: undefined },
              ].map((item) => ({
                ...item,
                required: false,
              })),
              heightInput,
              widthInput,
              creativeAssetsInput,
            ],
          };
        case Intent.GET_ALL_CREATIVES:
          return {
            inputs: [creativeFilterInput],
          };
        case Intent.GET_CREATIVE_BY_ID:
          return {
            inputs: [creativeIdInput],
          };
        case Intent.CREATE_CREATIVE_ASSET:
          return {
            inputs: [
              nameInput,
              {
                ...creativeTypeInput,
                defaultValue: 'IMAGE',
              },
              fileInput,
            ],
          };
        case Intent.CREATE_CAMPAIGN_CREATIVE_ASSOCIATION:
          return {
            inputs: [
              {
                ...campaignIdInput,
                subtitle: 'ID of the campaign to be associated with a creative.',
              },
              {
                ...creativeIdInput,
                subtitle: 'ID of the creative to associate with the campaign.',
              },
            ],
          };
        case Intent.GET_CAMPAIGN_CREATIVE_ASSOCIATION:
          return {
            inputs: [
              {
                ...campaignIdInput,
                subtitle: 'The campaign ID for which to get associated creatives.',
              },
            ],
          };
        case Intent.CREATE_CREATIVE_GROUPS:
          return {
            inputs: [nameInput, advertiserIdInput],
          };
        case Intent.UPDATE_CREATIVE_GROUPS:
          return {
            inputs: [
              { ...creativeGroupIdInput, subtitle: 'The ID of the creative group to update.' },
              { ...nameInput, required: false },
              { ...advertiserIdInput, required: false },
            ],
          };
        case Intent.GET_ALL_CREATIVE_GROUPS:
          return {
            inputs: [creativeGroupFilterInput],
          };
        case Intent.GET_CREATIVE_GROUP_BY_ID:
          return {
            inputs: [creativeGroupIdInput],
          };
        case Intent.CREATE_PLACEMENT:
          return {
            inputs: [
              nameInput,
              campaignIdInput,
              siteIdInput,
              paymentSourceInput,
              { ...heightInput, required: true },
              { ...widthInput, required: true },
              startDateInput,
              endDateInput,
              pricingTypeInput,
            ],
          };
        case Intent.UPDATE_PLACEMENT:
          return {
            inputs: [
              { ...placementIdInput, subtitle: 'The ID of the placement to update.' },
              ...[
                nameInput,
                campaignIdInput,
                heightInput,
                widthInput,
                startDateInput,
                endDateInput,
                { ...pricingTypeInput, defaultValue: '' },
              ].map((item) => ({ ...item, required: false })),
            ],
          };
        case Intent.GET_ALL_PLACEMENTS:
          return {
            inputs: [placementFilterInput],
          };
        case Intent.GET_PLACEMENT_BY_ID:
          return {
            inputs: [placementIdInput],
          };
        case Intent.GET_ALL_VIDEO_FORMATS:
          return {
            inputs: [],
          };
        case Intent.GET_VIDEO_FORMATS_BY_ID:
          return {
            inputs: [videoFormatIdInput],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
