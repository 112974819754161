import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { connectPortalListInput } from './input';

const triggerConfig: ActionConfig = {
  actionType: Action.LINEAR,
  name: 'Linear',
  description: 'Trigger when task issues are created, deleted or changed in Linear',
  icon: iconSvg,
  provider: ProviderType.LINEAR,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_ISSUE_CREATED,
              label: 'Trigger when an issue is created',
            },
            {
              value: Intent.TRIGGER_STATUS_UPDATED,
              label: 'Trigger when an issue’s status is updated',
            },
            {
              value: Intent.TRIGGER_LABEL_ADDED,
              label: 'Trigger when a certain label was added or removed from an issue',
            },
            {
              value: Intent.TRIGGER_ISSUE_DELETED,
              label: 'Trigger when an issue is deleted',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      return parameters.intent
        ? {
            inputs: [connectPortalListInput],
          }
        : { inputs: [] };
    },
  ],
};

export default triggerConfig;
