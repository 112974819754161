export default `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M55.8005 0.769531H8.1995C6.22945 0.772248 4.34086 1.55604 2.94781 2.94909C1.55476 4.34214 0.770966 6.23073 0.76825 8.20078V55.8018C0.770966 57.7718 1.55476 59.6604 2.94781 61.0536C4.34086 62.4466 6.22945 63.2304 8.1995 63.233H55.8005C57.7705 63.2304 59.6591 62.4466 61.0523 61.0536C62.4453 59.6604 63.2292 57.7718 63.2317 55.8018V8.20078C63.2292 6.23073 62.4453 4.34214 61.0523 2.94909C59.6591 1.55604 57.7705 0.772248 55.8005 0.769531ZM27.6745 45.7568C27.6745 46.412 27.4141 47.0403 26.9511 47.5036C26.4878 47.9667 25.8595 48.227 25.2042 48.227H14.7697C14.1146 48.227 13.4863 47.9667 13.023 47.5036C12.5598 47.0403 12.2995 46.412 12.2995 45.7568V14.771C12.2995 14.1159 12.5598 13.4876 13.023 13.0243C13.4863 12.5611 14.1146 12.3008 14.7697 12.3008H25.2042C25.8595 12.3008 26.4878 12.5611 26.9511 13.0243C27.4141 13.4876 27.6745 14.1159 27.6745 14.771V45.7568ZM51.7005 31.5298C51.7074 31.8585 51.6485 32.1852 51.5275 32.4909C51.4063 32.7964 51.2254 33.0749 50.9953 33.3097C50.7652 33.5446 50.4907 33.7312 50.1876 33.8586C49.8844 33.9859 49.559 34.0513 49.2302 34.0513H38.7957C38.1405 34.0513 37.5122 33.7909 37.0491 33.3279C36.5858 32.8646 36.3255 32.2362 36.3255 31.581V14.771C36.3255 14.1159 36.5858 13.4876 37.0491 13.0243C37.5122 12.5611 38.1405 12.3008 38.7957 12.3008H49.2302C49.8855 12.3008 50.5138 12.5611 50.9771 13.0243C51.4401 13.4876 51.7005 14.1159 51.7005 14.771V31.5298Z" fill="url(#paint0_linear_22064_33531)"/>
<defs>
<linearGradient id="paint0_linear_22064_33531" x1="32" y1="63.233" x2="32" y2="0.769531" gradientUnits="userSpaceOnUse">
<stop stop-color="#0052CC"/>
<stop offset="1" stop-color="#2684FF"/>
</linearGradient>
</defs>
</svg>
`;
