import axios, { AxiosInstance } from 'axios';

import {
  generateQueryStringFromObject,
  getAxiosInstance,
  parseCodeInput,
  withRefreshCredentialHandling,
} from '@shared/actions/sdk/utils';

import { CredentialKeys, Credentials, UserAccountFields } from '../configs';

import {
  AccumulatedCreateUpdateAPIPayload,
  AccumulatedCreateUpdateDTO,
  DYNAMICS_BUSINESS_CENTRAL_AUTH_BASE_URL,
  DynamicsBusinessCentralUserData,
  Environment,
  PaymentTermsCreateUpdateAPIPayload,
  PaymentTermsCreateUpdateDTO,
  PurchaseInvoiceCreateUpdateAPIPayload,
  PurchaseInvoiceCreateUpdateDTO,
  PurchaseInvoiceLineCreateUpdateAPIPayload,
  PurchaseInvoiceLineCreateUpdateDTO,
  RecordType,
  TaxGroupCreateUpdateAPIPayload,
  TaxGroupCreateUpdateDTO,
  VendorCreateUpdateAPIPayload,
  VendorCreateUpdateDTO,
} from '.';

/**
 *
 * @param credentials
 * @returns dynamics business central user info
 */
export const getDynamicsBusinessCentralUserMeta = async (
  credentials: Credentials,
): Promise<DynamicsBusinessCentralUserData> => {
  const authHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${credentials.DYNAMICS_BUSINESS_CENTRAL_ACCESS_TOKEN}`,
  };
  // user metadata url is slight different from base url
  const response = await getAxiosInstance(
    'https://api.businesscentral.dynamics.com/v2.0/production/api/microsoft/automation/v2.0/',
    authHeaders,
  ).get(`companies(${credentials.userAccountFields[CredentialKeys.COMPANY_ID]})/users`);
  return response.data;
};

/**
 * @returns Dynamcics Business Central Axios Instance with authorization header
 * @summary gets axios object that can be used for sending authorized requests to rest api
 */
export const getDynamicsBusinessCentralApiClient = (
  credentials: Credentials,
  headers = {},
): AxiosInstance => {
  return getAxiosInstance(generateBaseURL(credentials.userAccountFields), {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${credentials.DYNAMICS_BUSINESS_CENTRAL_ACCESS_TOKEN}`,
    ...headers,
  });
};

/**
 *
 * @param companyId
 * @returns base url to call api
 */
export const generateBaseURL = (userAccountFields: UserAccountFields) =>
  `https://api.businesscentral.dynamics.com/v2.0/${
    userAccountFields[CredentialKeys.ENVIRONMENT]
  }/api/v2.0/companies(${userAccountFields[CredentialKeys.COMPANY_ID]})/`;

/**
 * Check weather the access token is valid or got expired
 * @param credentials
 * @returns
 */
export const isAccessTokenValid = async (credentials: Credentials): Promise<boolean> => {
  try {
    await getDynamicsBusinessCentralUserMeta(credentials);
    return true;
  } catch {
    return false;
  }
};

export const exchangeRefreshTokenForAccessToken = async (
  credential: Credentials,
  environment: Environment,
) => {
  try {
    const { data } = await axios.post(
      `${DYNAMICS_BUSINESS_CENTRAL_AUTH_BASE_URL}/token`,
      generateQueryStringFromObject(
        {
          client_id: environment.DYNAMICS_BUSINESS_CENTRAL_CLIENT_ID,
          client_secret: environment.DYNAMICS_BUSINESS_CENTRAL_CLIENT_SECRET,
          scope: environment.DYNAMICS_BUSINESS_CENTRAL_SCOPES,
          refresh_token: credential.DYNAMICS_BUSINESS_CENTRAL_REFRESH_TOKEN,
          grant_type: 'refresh_token',
        },
        { urlencodedValues: true, addPrefix: false },
      ),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    return data;
  } catch (error) {
    throw getFormattedError(error);
  }
};

export const refreshCredentialIfExpired = async (
  credential: Credentials,
  environment: Environment,
): Promise<Credentials | undefined> => {
  if (!(await isAccessTokenValid(credential))) {
    const newCredential = await exchangeRefreshTokenForAccessToken(credential, environment);
    return {
      ...credential,
      DYNAMICS_BUSINESS_CENTRAL_ACCESS_TOKEN: newCredential.access_token,
      DYNAMICS_BUSINESS_CENTRAL_REFRESH_TOKEN: newCredential.refresh_token,
    };
  }
  return undefined;
};

/**
 * wrap intent method with refresh credential functionality
 * @param intentHandler
 * @returns
 */

export const withRefreshCredential = withRefreshCredentialHandling(refreshCredentialIfExpired);

export const getFormattedError = (error: any) =>
  new Error(
    `Dynamics Business Central ERROR: ${JSON.stringify(error.response?.data ?? error.message)}`,
  );

export const createPayload = (
  recordType: RecordType,
  allProperties: AccumulatedCreateUpdateDTO,
): { recordId?: string; payload: AccumulatedCreateUpdateAPIPayload | Record<string, unknown> } => {
  switch (recordType) {
    case RecordType.PAYMENT_TERMS: {
      const {
        paymentTermsId,
        paymentTermCode,
        paymentTermName,
        dueDateCalculation,
        discountDateCalculation,
        discountPercent,
      } = allProperties as PaymentTermsCreateUpdateDTO;
      return {
        recordId: paymentTermsId,
        payload: {
          displayName: paymentTermName,
          code: paymentTermCode,
          dueDateCalculation,
          discountDateCalculation,
          discountPercent: discountPercent ? parseFloat(discountPercent) : '',
        } as PaymentTermsCreateUpdateAPIPayload,
      };
    }
    case RecordType.PURCHASE_INVOICES: {
      const {
        vendorInvoiceNumber,
        purchaseInvoiceVendorId,
        invoiceDate,
        postingDate,
        dueDate,
        purchaseInvoiceAdditionalFields,
        purchaseInvoiceId,
      } = allProperties as PurchaseInvoiceCreateUpdateDTO;
      return {
        recordId: purchaseInvoiceId,
        payload: {
          vendorId: purchaseInvoiceVendorId,
          vendorInvoiceNumber,
          postingDate,
          invoiceDate,
          dueDate,
          ...(purchaseInvoiceAdditionalFields
            ? parseCodeInput(purchaseInvoiceAdditionalFields)
            : {}),
        } as PurchaseInvoiceCreateUpdateAPIPayload,
      };
    }
    case RecordType.PURCHASE_INVOICE_LINES: {
      const {
        purchaseInvoiceLineId,
        lineType,
        resourceNumber,
        lineDescription,
        quantity,
        unitCost,
        accountId,
        measureCodeUnit,
        // textAreaCode, // as per docs it seems like api doesn't accepting this value at the moment
        taxPercent,
        taxGroupCode,
        purchaseInvoiceLineAdditionalFields,
      } = allProperties as PurchaseInvoiceLineCreateUpdateDTO;
      return {
        recordId: purchaseInvoiceLineId,
        payload: {
          lineType,
          lineObjectNumber: resourceNumber,
          description: lineDescription,
          quantity: quantity ? parseFloat(quantity) : '',
          unitCost: unitCost ? parseFloat(unitCost) : '',
          accountId,
          unitOfMeasureCode: measureCodeUnit,
          taxCode: taxGroupCode,
          taxPercent: taxPercent ? parseFloat(taxPercent) : '',
          ...(purchaseInvoiceLineAdditionalFields
            ? parseCodeInput(purchaseInvoiceLineAdditionalFields)
            : {}),
        } as PurchaseInvoiceLineCreateUpdateAPIPayload,
      };
    }
    case RecordType.TAX_GROUPS: {
      const { taxGroupCode, taxGroupName, taxGroupId } = allProperties as TaxGroupCreateUpdateDTO;
      return {
        recordId: taxGroupId,
        payload: {
          displayName: taxGroupName,
          code: taxGroupCode,
        } as TaxGroupCreateUpdateAPIPayload,
      };
    }
    case RecordType.VENDORS: {
      const {
        vendorId,
        vendorName,
        vendorEmail,
        taxRegNumber,
        vendorNumber,
        paymentTermsId,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        country,
        vendorsAdditionalFields,
      } = allProperties as VendorCreateUpdateDTO;
      return {
        recordId: vendorId,
        payload: {
          displayName: vendorName,
          email: vendorEmail,
          taxRegistrationNumber: taxRegNumber,
          addressLine1,
          addressLine2,
          city,
          state,
          postalCode: zip,
          number: vendorNumber,
          paymentTermsId,
          country,
          ...(vendorsAdditionalFields ? parseCodeInput(vendorsAdditionalFields) : {}),
        } as VendorCreateUpdateAPIPayload,
      };
    }
    default:
      return { payload: {} };
  }
};

export async function createItemInventory(
  client: AxiosInstance,
  options: { itemNumber?: string; description?: string },
) {
  try {
    if (options.itemNumber) {
      const searchRecords = await client.get(`items?$filter=number eq '${options.itemNumber}'`);
      if (searchRecords.data.value.length === 0) {
        if (!Boolean(options.description)) {
          throw new Error('Please fill description value');
        }
        await client.post('items', {
          number: options.itemNumber,
          displayName: options.description,
          type: 'Inventory',
        });
      }
    }
  } catch (err) {
    throw err;
  }
}
