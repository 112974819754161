import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.CLICKUP;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'CLICKUP_TEST_CONNECTION',
  SEARCH_TASKS = 'CLICKUP_SEARCH_TASKS',
  GET_TASK_IN_LIST = 'CLICKUP_GET_TASK_IN_LIST',
  CREATE_TASK = 'CLICKUP_CREATE_TASK',
  UPDATE_TASK = 'CLICKUP_UPDATE_TASK',
  DELETE_TASK = 'CLICKUP_DELETE_TASK',
  TRIGGER_TASK_CREATED = 'CLICKUP_TRIGGER_TASK_CREATED',
  TRIGGER_TASK_UPDATED = 'CLICKUP_TRIGGER_TASK_UPDATED',
  GET_SPACE = 'CLICKUP_GET_SPACE',
  GET_LIST = 'CLICKUP_GET_LIST',
  GET_TEAMS = 'CLICKUP_GET_TEAMS',
  GET_MEMBER = 'CLICKUP_GET_MEMBER',
  GET_STATUS = 'CLICKUP_GET_STATUS',
}

export const TriggerIntents = [Intent.TRIGGER_TASK_CREATED, Intent.TRIGGER_TASK_UPDATED];

export type ActionStepParameters = BaseActionStepParameters &
  (
    | { intent: OAuthIntent.REFRESH_CREDENTIAL; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_TASKS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TASK_IN_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_TASK;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_TASK;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_TASK;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_TASK_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_TASK_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_SPACE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_STATUS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_MEMBER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TEAMS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  CLIENT_ID = 'CLICKUP_CLIENT_ID',
  CLIENT_SECRET = 'CLICKUP_CLIENT_SECRET',
  ACCESS_TOKEN = 'CLICKUP_ACCESS_TOKEN',
  WORKSPACE = 'CLICKUP_WORKSPACE',
}

export type Credentials = {
  name: string;
  [CredentialKeys.ACCESS_TOKEN]: string;
  teamId: string;
};
