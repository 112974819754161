import { FilterQuery } from 'mongodb';

import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

export default function conditionsToQuery(
  condition: ResolvedConditionWrapper,
): FilterQuery<object> {
  if (condition.type === 'JOIN') {
    return {
      [`$${condition.join.toLowerCase()}`]: condition.conditions.map(
        (condition: ResolvedConditionWrapper) => conditionsToQuery(condition),
      ),
    };
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      case Operator.StringContains:
        return {
          [innerCondition.variable]: {
            $regex: new RegExp(`${innerCondition.argument}`),
          },
        };
      case Operator.StringDoesNotContain:
        return {
          [innerCondition.variable]: {
            $not: new RegExp(`${innerCondition.argument}`),
          },
        };
      case Operator.StringExactlyMatches:
        return {
          [innerCondition.variable]: {
            $eq: innerCondition.argument,
          },
        };
      case Operator.StringDoesNotExactlyMatch:
        return {
          [innerCondition.variable]: {
            $ne: innerCondition.argument,
          },
        };
      case Operator.StringStartsWith:
        return {
          [innerCondition.variable]: {
            $regex: new RegExp(`^${innerCondition.argument}`),
          },
        };
      case Operator.StringDoesNotStartWith:
        return {
          [innerCondition.variable]: {
            $not: new RegExp(`^${innerCondition.argument}`),
          },
        };
      case Operator.StringEndsWith:
        return {
          [innerCondition.variable]: {
            $regex: new RegExp(`${innerCondition.argument}$`),
          },
        };
      case Operator.StringDoesNotEndWith:
        return {
          [innerCondition.variable]: {
            $not: new RegExp(`${innerCondition.argument}$`),
          },
        };
      case Operator.NumberLessThan:
        return {
          [innerCondition.variable]: {
            $lt: innerCondition.argument,
          },
        };
      case Operator.NumberEquals:
        return {
          [innerCondition.variable]: {
            $eq: innerCondition.argument,
          },
        };
      case Operator.NumberGreaterThan:
        return {
          [innerCondition.variable]: {
            $gt: innerCondition.argument,
          },
        };
      case Operator.NumberDoesNotEqual:
        return {
          [innerCondition.variable]: {
            $ne: innerCondition.argument,
          },
        };
      case Operator.NumberGreaterThanOrEqualTo:
        return {
          [innerCondition.variable]: {
            $gte: innerCondition.argument,
          },
        };
      case Operator.NumberLessThanOrEqualTo:
        return {
          [innerCondition.variable]: {
            $lte: innerCondition.argument,
          },
        };
      case Operator.DateTimeAfter:
        return {
          [innerCondition.variable]: {
            $gt: innerCondition.argument,
          },
        };
      case Operator.DateTimeBefore:
        return {
          [innerCondition.variable]: {
            $lt: innerCondition.argument,
          },
        };
      case Operator.DateTimeEquals:
        return {
          [innerCondition.variable]: {
            $eq: innerCondition.argument,
          },
        };
      case Operator.BooleanTrue:
        return {
          [innerCondition.variable]: {
            $eq: true,
          },
        };
      case Operator.BooleanFalse:
        return {
          [innerCondition.variable]: {
            $eq: false,
          },
        };
      case Operator.IsNotNull:
        return {
          [innerCondition.variable]: {
            $exists: true,
          },
        };
      case Operator.IsNull:
        return {
          [innerCondition.variable]: {
            $exists: false,
          },
        };
      case Operator.ArrayIsIn:
        return {
          [innerCondition.variable]: {
            $in: [innerCondition.argument],
          },
        };
      case Operator.ArrayIsNotIn:
        return {
          [innerCondition.variable]: {
            $nin: [innerCondition.argument],
          },
        };
      default:
        throw new Error(`${innerCondition.operator} not supported by MongoDB`);
    }
  }
  return {};
}
