import React from 'react';

import { hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  additionalFieldsInput,
  dimensionsInput,
  endDateInput,
  intentSelector,
  metricInput,
  postOAuthAccountIdInput,
  realTimeMetricInput,
  startDateInput,
  viewInput,
  webPropertyInput,
} from './common';

export const authConfig: ActionConfig = {
  actionType: Action.GOOGLE_ANALYTICS,
  provider: ProviderType.GOOGLE,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Manage goals and segments in Google Analytics',
  icon: iconSvg,
  description: 'Google Analytics',
  sidebarSections: [],
  hideOAuthApps: false,
  postOAuthPrompt: [postOAuthAccountIdInput],
};

const config: ActionConfig = {
  actionType: Action.GOOGLE_ANALYTICS,
  name: 'Google Analytics',
  description: 'Manage goals and segments in Google Analytics',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Google Analytics account',
          placeholder: 'connect to Google Analytics',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.GOOGLE,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length ? intentSelector : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.RUN_REPORT:
          return {
            inputs: [
              { ...viewInput, subtitle: 'The view to run a report on.', placeholder: '' },
              metricInput,
              startDateInput,
              endDateInput,
              dimensionsInput,
              additionalFieldsInput,
            ],
          };
        case Intent.GET_CUSTOM_METRICS:
          return {
            inputs: [
              {
                ...webPropertyInput,
                subtitle: 'Property to fetch list of custom metrics for.',
              },
            ],
          };
        case Intent.GET_CUSTOM_DIMENSIONS:
          return {
            inputs: [
              {
                ...webPropertyInput,
                subtitle: 'Property to fetch list of custom dimension for.',
              },
            ],
          };
        case Intent.GET_REALTIME_DATA:
          return {
            inputs: [
              {
                ...viewInput,
                subtitle: 'The view to get realtime data from.',
              },
              realTimeMetricInput,
              {
                ...dimensionsInput,
                placeholder: '',
                subtitle: (
                  <>
                    Characteristics about sessions or users to include in the report.{' '}
                    <a
                      href="https://developers.google.com/analytics/devguides/reporting/realtime/dimsmets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      See the docs
                    </a>{' '}
                    for more on dimensions. A comma-separated list of real time dimensions. E.g.,
                    rt:medium,rt:city.
                  </>
                ),
                lines: 1,
              } as SidebarInput,
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
