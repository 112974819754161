import JSON5 from 'json5';

import {
  CloneableWorkflowInputDependency,
  IConnectIntegrationConfig,
  IWorkflow,
  IWorkflowExecution,
} from '@shared/entities/sdk';
import { Execution, FanoutStackEntry, WorkflowType } from '@shared/types/sdk/execution';
import { ConnectCredentialSource, SecretSource } from '@shared/types/sdk/resolvers';
import {
  Choice,
  ConditionalStep,
  MapStep,
  Sequence,
  SequenceMap,
  StateMachine,
  Step,
  StepMap,
  StepType,
  Workflow,
} from '@shared/types/sdk/steps';

import { getDownstreamSteps, getUpstreamSteps } from './stateMachine';

// returns a string representation of an object with the nested keys / values visible
export function dump(obj: any): string {
  return JSON.stringify(obj, null, 4);
}

// clones an object; used to remove references to nested objects in the new object
export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export const TRIGGER_TYPES = [
  StepType.UNSELECTED_TRIGGER,
  StepType.CRON,
  StepType.ENDPOINT,
  StepType.OAUTH,
  StepType.ACTION_TRIGGER,
  StepType.EVENT,
  StepType.INTEGRATION_ENABLED,
];

export function isTrigger(step: Step): boolean {
  return TRIGGER_TYPES.includes(step.type);
}

export function getTriggerStep(steps: Step[]): Step | undefined {
  return steps.find(isTrigger);
}

export function isConditional(step: Step): boolean {
  return step.type === StepType.IFELSE;
}

export function isFanout(step: Step): step is MapStep {
  return step.type === StepType.MAP;
}

export function stepArrayToMap(steps: Step[]): StepMap {
  const stepMap: StepMap = {};
  steps.forEach((step: Step) => (stepMap[step.id] = step));
  return stepMap;
}

export function stepMapToArray(stepMap: StepMap): Step[] {
  return Object.keys(stepMap).map((stepId: string) => stepMap[stepId]);
}

export function sequenceArrayToMap(sequences: Sequence[]): SequenceMap {
  const sequenceMap: SequenceMap = {};
  sequences.forEach((sequence: Sequence) => (sequenceMap[sequence.id] = sequence));
  return sequenceMap;
}

export function getStepInWorkflowById(workflow: Workflow, stepId: string): Step {
  return workflow.steps.find((step: Step) => step.id === stepId)!;
}

// given a step, it finds the step before it in the workflow
export function getStepBefore(
  stepsOrWorkflow: Step[] | Workflow,
  step: Step,
  traversed: Step[] = [],
): Step | undefined {
  if (!Array.isArray(stepsOrWorkflow)) {
    stepsOrWorkflow = stepsOrWorkflow.steps;
  }
  return stepsOrWorkflow.find(
    (s: Step) =>
      !traversed.filter((t: Step) => t.id === s.id).length &&
      (s.next === step.id ||
        (s.type === StepType.IFELSE &&
          s.parameters?.choices?.find((choice: Choice) => choice.next === step.id)) ||
        (s.type === StepType.MAP && s.next === step.id) ||
        (s.type === StepType.MAP && s.parameters.nextToIterate === step.id)),
  );
}

export function getParentFanout(stepId: string, stateMachine: StateMachine): MapStep | undefined {
  const upstreamSteps: Step[] = getUpstreamSteps(stepId, stateMachine);
  const mapStep: MapStep | undefined = upstreamSteps.reverse().find(
    (s: Step) =>
      // we also need to verify that the step is within the fanout
      // a step may have an upstream map step but not be within the fanout
      s.type === StepType.MAP && isStepInFanout(stateMachine.stepMap[stepId], s, stateMachine),
  ) as MapStep | undefined;
  return mapStep;
}

// given a step, it finds the step after it in the workflow
export function getStepsAfter(stepsOrWorkflow: Step[] | Workflow, step: Step): Step | undefined {
  if (!Array.isArray(stepsOrWorkflow)) {
    stepsOrWorkflow = stepsOrWorkflow.steps;
  }

  return stepsOrWorkflow.find(
    (s: Step) =>
      s.next === step.id ||
      (s.type === StepType.IFELSE &&
        s.parameters?.choices?.find((choice: Choice) => choice.next === step.id)) ||
      (s.type === StepType.MAP && s.next === step.id) ||
      (s.type === StepType.MAP && s.parameters.nextToIterate === step.id),
  );
}

// given 2 steps, it checks if the steps are different
// this is used to compare steps before and after changes to a workflow
//    e.g. reordering, deleting, etc
// if this returns true, then the step needs to be resaved
export function didStepUpdate(step: Step, updated: Step): boolean {
  if (step && !updated) {
    return false;
  }
  if (step.next !== updated.next) {
    return true;
  }
  if (step.type === StepType.IFELSE) {
    const conditional: ConditionalStep = step as ConditionalStep;
    const conditionalUpdated: ConditionalStep = updated as ConditionalStep;
    const choices = conditional.parameters?.choices || [];
    const choicesUpdated = conditionalUpdated.parameters.choices;
    for (let i = 0, len = choices.length; i < len; i++) {
      if (choices[i].next !== choicesUpdated[i].next) {
        return true;
      }
    }
  }
  if (step.type === StepType.MAP) {
    const fanoutUpdated: MapStep = updated as MapStep;
    if (step.parameters.nextToIterate !== fanoutUpdated.parameters.nextToIterate) {
      return true;
    }
  }

  return false;
}

// given 2 workflows, the 2nd representing an updated version of the first
// it returns an array of steps that have changed and need to be saved
export function getUpdatedSteps(workflow: Workflow, updatedWorkflow: Workflow): Step[] {
  const queue: Step[] = [];
  const stepMap: StepMap = stepArrayToMap(workflow.steps);
  const updatedMap: StepMap = stepArrayToMap(updatedWorkflow.steps);
  let id: string;
  let step: Step;
  let compare: Step;

  for (let i = 0, len = workflow.steps.length; i < len; i++) {
    id = workflow.steps[i].id;
    step = stepMap[id];
    compare = updatedMap[id];
    if (didStepUpdate(step, compare)) {
      queue.push(compare);
    }
  }

  return queue;
}

export function isStepInConditional(
  step: Step,
  conditional: ConditionalStep,
  stateMachine: StateMachine,
): boolean {
  if (step.id === conditional.id) {
    return false;
  }

  const downstreamSteps: Step[] = getDownstreamSteps(
    conditional.id,
    stateMachine,
    conditional.next,
  );
  const result: boolean = downstreamSteps.filter((s: Step) => s.id === step.id).length > 0;
  return result;
}

export function isStepInFanout(step: Step, mapStep: MapStep, stateMachine: StateMachine): boolean {
  if (step.id === mapStep.id) {
    return true;
  }

  const downstreamSteps: Step[] = getDownstreamSteps(mapStep.id, stateMachine, mapStep.next);
  const result: boolean = downstreamSteps.filter((s: Step) => s.id === step.id).length > 0;
  return result;
}

export function isStepInStepList(step: Step | undefined, stepList: Step[]): boolean {
  return step && stepList.filter((s: Step) => s.id === step.id).length > 0 ? true : false;
}

export function concatAndDedupeStepLists(a: Step[], b: Step[], createNewArray: boolean): Step[] {
  const c: Step[] = createNewArray ? [...a] : a;
  for (const step of b) {
    if (c.filter((s: Step) => s.id === step.id).length === 0) {
      c.push(step);
    }
  }

  return c;
}

export function isChildFanoutStack(
  stack1: FanoutStackEntry[],
  stack2: FanoutStackEntry[],
): boolean {
  if (!stack1 || !stack2) {
    return false;
  } else if (stack1.length > stack2.length) {
    return false;
  }

  for (let i = 0; i < stack1.length; i++) {
    const entry1: FanoutStackEntry = stack1[i];
    const entry2: FanoutStackEntry = stack2[i];

    if (entry1.index !== entry2.index) {
      return false;
    } else if (entry1.stepId !== entry2.stepId) {
      return false;
    } else if (entry1.instanceId !== entry2.instanceId) {
      return false;
    }
  }

  return true;
}

/**
 * gets the initial step that should run in a state machine
 * @param stateMachine
 */
export function getStartStepIdFromStateMachine(stateMachine: StateMachine): string {
  try {
    // TODO: check for `activeStepId`
    const startStep: string | undefined = stateMachine.sequenceMap[stateMachine.start].start;
    if (!startStep) {
      throw new Error('Start step not found');
    }
    return startStep;
  } catch (error) {
    return 'UNKNOWN';
  }
}

/**
 * returns the kind of context workflow is execution in connect or classic
 * @param execution workflow execution object i.e bull job data
 * @returns
 */
export function resolveCurrentContextByExecution(execution: Execution): WorkflowType {
  return 'integrationId' in execution &&
    execution.integrationId !== undefined &&
    execution.integrationId !== null
    ? WorkflowType.CONNECT
    : WorkflowType.CLASSIC;
}

export const isValueWithinRange = (num: number, min: number, max: number): boolean => {
  return num >= min && num <= max;
};

export const isNumberType = (num: unknown): boolean => {
  return typeof num === 'number' && !Number.isNaN(num);
};

export const isValidJSON = (json: unknown): boolean => {
  if (!json || !(typeof json === 'string' || typeof json === 'object')) {
    return false;
  } else if (typeof json === 'string') {
    try {
      const parsedJson = JSON5.parse(json);
      if (parsedJson === null) {
        return false;
      }
    } catch {
      return false;
    }
  }
  return true;
};

/**
 * Recursively check for connect credential source being used by step
 * @param step {Step | Object}
 * @returns unfiltered connect credentials sources
 */
export const extractStepConnectCredentialsDependency = (
  step: object,
): ConnectCredentialSource[] => {
  try {
    return Object.values(step)
      .reduce<(ConnectCredentialSource | string | null)[]>(
        (values, value) =>
          values.concat(
            value && typeof value === 'object' && value.type !== 'CONNECT_CREDENTIAL_FIELD'
              ? extractStepConnectCredentialsDependency(value)
              : value,
          ),
        [],
      )
      .filter((input) => !!input && typeof input === 'object') as ConnectCredentialSource[];
  } catch (error) {
    return [];
  }
};

/**
 * Recursively check for environment credentials being used by step
 * @param step {Step | Object}
 * @returns unfiltered environment credentials
 */
export const extractStepEnvSecretsDependency = (step: object): SecretSource[] => {
  try {
    return Object.values(step)
      .reduce<(SecretSource | string | null)[]>(
        (values, value) =>
          values.concat(
            value && typeof value === 'object' && value.type !== 'ENVIRONMENT_SECRET'
              ? extractStepEnvSecretsDependency(value)
              : value,
          ),
        [],
      )
      .filter((input) => !!input && typeof input === 'object') as SecretSource[];
  } catch (error) {
    return [];
  }
};

/**
 * Filter all workflow dependency used by workflow like integration config inputs, environment credentials
 * @param workflow {}
 * @type {IWorkflow | Workflow}
 * @returns All workflow dependency
 */
export const getIntegrationConfigDependency = (
  workflow: IWorkflow | Workflow,
  integrationConfig: IConnectIntegrationConfig,
): CloneableWorkflowInputDependency => {
  return (
    workflow.steps
      .flatMap(extractStepConnectCredentialsDependency)
      // Filtering duplicate inputs
      .filter((source, index, sources) => {
        if ('inputId' in source) {
          // @ts-ignore assuming inputId exist in all other sources
          return sources.findIndex(({ inputId }) => inputId === source.inputId) === index;
        }
        return true;
      })
      // Segregation shared & workflow inputs
      .reduce(
        (inputs, source) => {
          const isSharedInput = source.fieldType === 'SHARED_WORKFLOW_SETTING';
          const inputsToFilter = isSharedInput
            ? integrationConfig.values.sharedMeta?.inputs
            : integrationConfig.values.workflowMeta?.[workflow.id]?.inputs;
          // @ts-ignore inputId exist on source
          const foundInput = inputsToFilter?.filter(({ id }) => id === source.inputId) ?? [];

          return {
            sharedInputs: [...inputs.sharedInputs, ...(isSharedInput ? foundInput : [])],
            workflowInputs: [...inputs.workflowInputs, ...(!isSharedInput ? foundInput : [])],
          };
        },
        { sharedInputs: [], workflowInputs: [] },
      )
  );
};

export const recursiveUpdateStepParameters = <T extends unknown>(
  parameters: T,
  oldValue: string,
  updatedValue: string,
): T => {
  if (!parameters) {
    return parameters;
  }
  if (['string', 'number', 'boolean'].includes(typeof parameters)) {
    return (parameters === oldValue ? updatedValue : parameters) as T;
  }
  if (Array.isArray(parameters)) {
    return parameters.map((item) =>
      recursiveUpdateStepParameters(item, oldValue, updatedValue),
    ) as T;
  }
  if (typeof parameters === 'object') {
    return Object.fromEntries(
      Object.entries(parameters as Object).map((value) =>
        recursiveUpdateStepParameters(value, oldValue, updatedValue),
      ),
    ) as T;
  }
  return parameters;
};

/**
 * it will sanitize workflowSteps
 * see https://github.com/useparagon/paragon/pull/4356
 * @param sourceSteps
 * @returns
 */
export const sanitizeWorkflowSteps = (sourceSteps: Step[]): Step[] => {
  const stepMap: Record<string, Step> = stepArrayToMap(sourceSteps);
  const sanitizeNextStepId = (next: string | null, currentStepId: string): string | null => {
    return next && next !== currentStepId && stepMap[next] ? next : null;
  };

  return sourceSteps.map((step) => {
    switch (step.type) {
      case StepType.IFELSE:
        return {
          ...step,
          parameters: {
            ...step.parameters,
            choices: step.parameters.choices.map((choice) => ({
              ...choice,
              next: sanitizeNextStepId(choice.next, step.id),
            })),
          },
          next: sanitizeNextStepId(step.next, step.id),
        };
      case StepType.MAP:
        return {
          ...step,
          parameters: {
            ...step.parameters,
            nextToIterate: sanitizeNextStepId(step.parameters.nextToIterate, step.id),
          },
          next: sanitizeNextStepId(step.next, step.id),
        };
      default:
        return {
          ...step,
          next: sanitizeNextStepId(step.next, step.id),
        };
    }
  });
};

/**
 * this type is created becuase of dashboard type and backend interface not same
 * @todo remove this after PARA-6050
 */
type PartialExecution = {
  id: IWorkflowExecution['id'];
  start: IWorkflowExecution['start'] | string;
  replayOf: PartialExecution | null | undefined;
};

/**
 *  it will return latest executions
 */
export const pickLatestExecutions = <T extends PartialExecution>(executions: T[]): T[] => {
  const executionIdToExecutionMap: Record<string, T> = Object.fromEntries(
    executions.map((execution: T) => [execution.id, execution]),
  );

  const pickRootExecutionId = (id: string): string | undefined => {
    const execution: T | undefined = executionIdToExecutionMap[id];
    if (!execution) {
      return undefined;
    }
    if (execution.replayOf) {
      // added "execution.replayOf.id" as fallback value incase if somehow base replay not present in state
      return pickRootExecutionId(execution.replayOf.id) || execution.replayOf.id;
    }
    return execution.id;
  };

  /**
   * it will contain {[oldestExecutionId]:latestExecutionId}
   */
  const executionsWithLatestReplays: Record<string, T> = executions.reduce(
    (latestExecutions: Record<string, T>, execution: T) => {
      const rootExecutionId: string = pickRootExecutionId(execution.id) as string;

      if (
        !latestExecutions[rootExecutionId] ||
        new Date(latestExecutions[rootExecutionId].start).getTime() <
          new Date(execution.start).getTime()
      ) {
        latestExecutions[rootExecutionId] = execution;
      }
      return latestExecutions;
    },
    {},
  );
  return Object.values(executionsWithLatestReplays);
};
