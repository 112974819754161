import * as process from 'process';

import * as Joi from 'joi';

import { currentPlatformEnv, getPlatformEnv } from './defaults';
import { PLATFORM_ENV } from './enums';
export type MapSchema<T extends Record<string, string>> = {
  [K in keyof T]: string;
};

export function objectToSchema<T extends Record<string, string>>(t: T): T {
  return t;
}

export { getPlatformEnv };

/**
 * Return true if platform environment is enterpise
 *
 * @export
 * @return {*}  {boolean}
 */
export function isEnterprisePlatformEnv(): boolean {
  return getPlatformEnv() == PLATFORM_ENV.ENTERPRISE;
}

/**
 * `Joi` throws an error if the default value is undefined
 * This is a helper utility for wrapping default values
 * @param schema
 * @param defaults
 */
export function wrapDefault<T extends Joi.Schema = Joi.Schema>(
  schema: Joi.Schema,
  defaults: any,
): T {
  return (defaults === undefined ? schema : schema.default(defaults)) as T;
}

/**
 * `Joi` schema wrapper for validating booleans without throwing errors on empty values
 */
export function validateBoolean(): Joi.BooleanSchema {
  return Joi.boolean().allow('').empty(['', null]);
}

/**
 * `Joi` schema wrapper for validating strings without throwing errors on empty values
 */
export function validateString(): Joi.StringSchema {
  return Joi.string().allow('').trim().empty(['', null]);
}

/**
 * `Joi` schema wrapper for validating numbers without throwing errors on empty values
 */
export function validateNumber(): Joi.NumberSchema {
  return Joi.number().allow('').empty(['', null]);
}

/**
 * `Joi` schema wrapper for validating strings and providing a fallback value
 */
export function validateStringWithFallback(
  fallback: string | undefined,
  required: boolean = true,
): Joi.StringSchema {
  const schema: Joi.StringSchema = wrapDefault<Joi.StringSchema>(validateString(), fallback);
  return fallback === undefined ? (required ? schema.required() : schema.optional()) : schema;
}

/**
 * `Joi` schema wrapper for validating numbers and providing a fallback value
 */
export function validateNumberWithFallback(fallback: number | undefined): Joi.NumberSchema {
  const schema: Joi.NumberSchema = wrapDefault<Joi.NumberSchema>(validateNumber(), fallback);
  return fallback === undefined ? schema.required() : schema;
}

/**
 * validates that an env var is required when in non-enterprise environments
 * if running in an enterprise environment can be configured to be optional
 * @param requiredEnterprise whether or not the variable is required in an enterprise environment
 * @param requiredHosted whether or not the variable is required in a hosted environment
 */
export function validateEnterpriseEnv(
  requiredEnterprise: boolean = false,
  requiredHosted: boolean | PLATFORM_ENV[] = true,
): Joi.StringSchema {
  const isEnterpriseEnvironment: boolean = currentPlatformEnv === PLATFORM_ENV.ENTERPRISE;
  if (isEnterpriseEnvironment) {
    return requiredEnterprise ? validateString().required() : validateString().optional();
  } else {
    return requiredHosted === true ||
      (Array.isArray(requiredHosted) && requiredHosted.includes(currentPlatformEnv))
      ? validateString().required()
      : validateString().optional();
  }
}

/**
 * validates that an env var is required when in non-enterprise environments
 * if running in an enterprise environment can be configured to be optional
 * @param requiredEnterprise whether or not the variable is required in an enterprise environment
 * @param requiredHosted whether or not the variable is required in a hosted environment
 * @param fallback fallback value incase env variable isn't available
 */
export function validateEnterpriseEnvWithFallback(
  requiredEnterprise: boolean = false,
  requiredHosted: boolean | PLATFORM_ENV[] = true,
  fallback: string | boolean,
): Joi.Schema {
  const isEnterpriseEnvironment: boolean = currentPlatformEnv === PLATFORM_ENV.ENTERPRISE;
  if (isEnterpriseEnvironment) {
    return requiredEnterprise
      ? validateStringWithFallback(fallback as string)
      : validateString().default(fallback).optional();
  } else {
    return requiredHosted === true ||
      (Array.isArray(requiredHosted) && requiredHosted.includes(currentPlatformEnv))
      ? validateStringWithFallback(fallback as string)
      : validateString().default(fallback).optional();
  }
}

/**
 * makes a schema only required when running in the CI
 *
 * @param schema
 * @returns
 */
export function requireOnlyInCi(schema: Joi.Schema): Joi.Schema {
  // PATCH: make all CI environment variables optional. the required variables are breaking ci's compute env variables step
  return schema.optional();
  // END PATCH

  // ORIGINAL CODE
  // const ci: boolean = pickEnvVar(ENVIRONMENT_VARIABLES.CI, false) === 'true';
  // return ci ? schema.required() : schema.optional();
  // END ORIGINAL CODE
}

/**
 * makes a schema required only when used within a microservice
 */
export function requireOnlyInMicroservice(schema: Joi.Schema): Joi.Schema {
  const inMicroservice: boolean = isDocker();
  return inMicroservice ? schema.required() : schema.optional();
}

/**
 * whether or not the current environment is running in the browser
 *
 * @export
 * @returns {boolean}
 */
export function isBrowser(): boolean {
  type Process = {
    browser?: boolean;
    // when mock process as undefined it adds default key as undefined
    default?: Record<string, string> | undefined;
  };

  return (process as Process) === undefined ||
    (process as Process)?.default === undefined ||
    (process as Process)?.browser === true
    ? true
    : false;
}

/**
 * determines whether or not the current environment is running in docker
 *
 * @export
 * @returns {boolean}
 */
export function isDocker(): boolean {
  return isBrowser() ? false : require('is-docker')();
}
