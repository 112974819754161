import { Action } from '@shared/types/sdk/actions';

import { CredentialStatus } from '.';

export enum ProviderType {
  TWILIO = 'twilio',
  SENDGRID = 'sendgrid',
  POSTGRES = 'postgres',
  MYSQL = 'mysql',
  GOOGLE = 'google',
  FIREBASE = 'firebase',
  AIRTABLE = 'airtable',
  SLACK = 'slack',
  MONGODB = 'mongodb',
  MAILGUN = 'mailgun',
  STRIPE = 'stripe',
  SALESFORCE = 'salesforce',
  ONESIGNAL = 'onesignal',
  AWS = 'aws',
  QUICKBOOKS = 'quickbooks',
  /**
   * ProviderType.CUSTOM is intended for use by workflows that use custom-created OAuth apps.
   */
  CUSTOM = 'custom',
  UNKNOWN = 'unknown',
  FTP = 'ftp',
  SHOPIFY = 'shopify',
  JIRA = 'jira',
  HUBSPOT = 'hubspot',
  KLAVIYO = 'klaviyo',
  INTERCOM = 'intercom',
  TABLEAU = 'tableau',
  ASANA = 'ASANA',
  ZENDESK = 'zendesk',
  MICROSOFT = 'microsoft',
  ZOOM = 'zoom',
  MARKETO = 'marketo',
  MICROSOFT_DYNAMICS_365 = 'microsoft dynamics 365',
  PARDOT = 'pardot',
  XERO = 'xero',
  OUTREACH = 'outreach',
  ORACLE_FINANCIALS_CLOUD = 'oraclefinancialscloud',
  TRELLO = 'trello',
  FACEBOOK = 'facebook',
  MAILCHIMP = 'mailchimp',
  AZURE_DEVOPS = 'azuredevops',
  MONDAY = 'monday',
  GONG = 'gong',
  GITHUB = 'github',
  CLICKUP = 'clickup',
  SHAREPOINT = 'sharepoint',
  OUTLOOK = 'outlook',
  SERVICENOW = 'servicenow',
  ELOQUA = 'eloqua',
  NETSUITE = 'netsuite',
  DYNAMICS_BUSINESS_CENTRAL = 'dynamicsbusinesscentral',
  PIPEDRIVE = 'pipedrive',
  SAGE_INTACCT = 'sageintacct',
  LINEAR = 'linear',
  DYNAMICS_365_FINANCE = 'dynamicsfinance',
  SAGE_ACCOUNTING = 'sageaccounting',
  WOOCOMMERCE = 'woocommerce',
  IMANAGE = 'imanage',
  BAMBOO_HR = 'bamboohr',
  GOOGLE_AD_MANAGER = 'googleadmanager',
  DROPBOX = 'dropbox',
  MAGENTO = 'magento',
  PRODUCTBOARD = 'productboard',
  DOCUSIGN = 'docusign',
  SAP_S4HANA = 'saps4hana',
  GOOGLE_ADS = 'googleads',
  GREENHOUSE = 'greenhouse',
  LEVER = 'lever',
  CALENDLY = 'calendly',
  ZOHO_CRM = 'zohocrm',
  SALESLOFT = 'salesloft',
  FRESHDESK = 'freshdesk',
  TWITTER = 'twitter',
  BIGQUERY = 'bigquery',
  MIXPANEL = 'mixpanel',
  SAP_SUCCESS_FACTORS = 'sapsuccessfactors',
  AMAZON_S3 = 'amazons3',
  SAILTHRU = 'sailthru',
  PANDA_DOC = 'pandadoc',
  SEGMENT = 'segment',
  POWER_BI = 'powerbi',
  GUSTO = 'gusto',
  CLOSE = 'Close',
}

export const oauthProvidersList = [
  ProviderType.ELOQUA,
  ProviderType.SLACK,
  ProviderType.GOOGLE,
  ProviderType.SALESFORCE,
  ProviderType.INTERCOM,
  ProviderType.QUICKBOOKS,
  ProviderType.JIRA,
  ProviderType.HUBSPOT,
  ProviderType.ASANA,
  ProviderType.SHOPIFY,
  ProviderType.ZENDESK,
  ProviderType.MICROSOFT,
  ProviderType.ZOOM,
  ProviderType.MICROSOFT_DYNAMICS_365,
  ProviderType.PARDOT,
  ProviderType.XERO,
  ProviderType.OUTREACH,
  ProviderType.MAILCHIMP,
  ProviderType.FACEBOOK,
  ProviderType.TRELLO,
  ProviderType.GONG,
  ProviderType.AZURE_DEVOPS,
  ProviderType.CLICKUP,
  ProviderType.SHAREPOINT,
  ProviderType.PIPEDRIVE,
  ProviderType.OUTLOOK,
  ProviderType.NETSUITE,
  ProviderType.DYNAMICS_BUSINESS_CENTRAL,
  ProviderType.SAGE_INTACCT,
  ProviderType.LINEAR,
  ProviderType.DYNAMICS_365_FINANCE,
  ProviderType.SAGE_ACCOUNTING,
  ProviderType.IMANAGE,
  ProviderType.BAMBOO_HR,
  ProviderType.GITHUB,
  ProviderType.GOOGLE_AD_MANAGER,
  ProviderType.DROPBOX,
  ProviderType.DOCUSIGN,
  ProviderType.GOOGLE_ADS,
  ProviderType.CALENDLY,
  ProviderType.ZOHO_CRM,
  ProviderType.SALESLOFT,
  ProviderType.BIGQUERY,
  ProviderType.TABLEAU,
  ProviderType.LEVER,
  ProviderType.POWER_BI,
  ProviderType.TWITTER,
  ProviderType.GUSTO,
] as const;

/**
 * Add to this list when the OAuth trigger supports using an OAuth App-type credential.
 */
export type SupportedOAuthAppProviders = Extract<ProviderType, typeof oauthProvidersList[number]>;

export enum AuthenticationScheme {
  BASIC = 'basic',
  OAUTH = 'oauth',
  OAUTH_CLIENT_CREDENTIAL = 'oauth_client_credential',
  SERVICE_ACCOUNT = 'service_account',
  OAUTH_APP = 'oauth_app',
  IMPERSONATED_APP = 'impersonated_app',
}

export interface IProvider {
  type: ProviderType;
  authentication: CredentialValues;
}

export type CredentialValues = Record<string, string | number | boolean | any>;

export type OAuthCredentialValues = {
  clientId: string;
  clientSecret: string;
  accessTokenUrl: string;
  scopes: string;
  authUrl: string;
  [key: string]: any;
};

/**
 * Using it for special credential values, where values are other than {OAuthCredentialValues}
 * @note Infix Basic Word in Integration credential values only if integration has basic auth
 */
export type OAuthBasicCredentialValues =
  | SageIntacctBasicCredentialValues
  | TrelloBasicCredentialValues
  | QuickBooksBasicCredentialValues
  | MicrosoftBasicCredentialValues
  | BambooHrCredentialValues
  | LeverCredentialValues
  | GoogleAdsCredentialValues
  | TableauCredentialValues
  | DocuSignCredentialValues
  | GustoCredentialValues;

export type SageIntacctBasicCredentialValues = {
  senderId: string;
  senderPassword: string;
};

export type QuickBooksBasicCredentialValues = {
  prodClientId: string;
  prodClientSecret: string;
  sandboxClientId?: string;
  sandboxClientSecret?: string;
  scopes?: string;
};

export type MicrosoftBasicCredentialValues = {
  botExternalId: string;
};

export type TrelloBasicCredentialValues = {
  name: string;
  scopes: string;
};

export type BambooHrCredentialValues = {
  applicationKey: string;
};

export type LeverCredentialValues = {
  sandboxClientId?: string;
  sandboxClientSecret?: string;
};

export type GoogleAdsCredentialValues = {
  developerToken: string;
};

export type DocuSignCredentialValues = {
  developmentIntegrationKey: string;
  developmentSecretKey: string;
};

export type TableauCredentialValues = {
  clientSecretValue: string;
};

export type GustoCredentialValues = {
  demoClientId?: string;
  demoClientSecret?: string;
};

export type EncryptedCredentialValues = string;

export interface ICredential {
  id: string;
  name: string;
  provider: ProviderType;
  projectId: string;
  integrationId?: string;
  scheme: AuthenticationScheme;
  onboardingOnly?: boolean;
  dateCreated?: Date;
  dateUpdated?: Date;
  status: CredentialStatus;
  dateValidUntil?: Date;
  dateRefreshed: Date;
}

export interface IOAuthAppCredential extends ICredential {
  provider: SupportedOAuthAppProviders;
  scheme: AuthenticationScheme.OAUTH_APP;
}

export interface ImpersonatedUserCredential extends ICredential {
  scheme: AuthenticationScheme.IMPERSONATED_APP;
}

// `baseId` is equal to `id` initially
// this will allow us to move the master keys to a different location in the future
export interface ICredentialMasterKey {
  id: string;
  baseId: string;
  encryptedId: string;
  projectId: string;
  masterKey: string;
}

// the EncryptedCredential has a different `id` than its `ICredential` base
export type EncryptedCredential = Omit<
  ICredential,
  'name' | 'provider' | 'scheme' | 'dateRefreshed' | 'dateValidUntil'
> & {
  values: EncryptedCredentialValues;
};

/**
 * DecryptedCredential shares the same `id` as its `ICredential` base.
 *
 * As of PARA-1606, credentials stored in cerberus now include `provider` and `scheme` properties.
 * Credentials saved before PARA-1606 **may not have these properties.**
 *
 * However, no credentials representing connected OAuth apps were stored before this change. The
 * existence of these properties on a ICredential entity indicates that it may be an OAuth app,
 * which can be determined by checking `scheme`.
 */
export type DecryptedCredential<T extends AuthenticationScheme = AuthenticationScheme> = Omit<
  ICredential,
  'name' | 'provider' | 'scheme' | 'dateRefreshed' | 'dateValidUntil'
> & {
  values: T extends AuthenticationScheme.OAUTH_APP | AuthenticationScheme.BASIC
    ? OAuthCredentialValues | OAuthBasicCredentialValues
    : CredentialValues;
  provider?: ICredential['provider'];
  scheme?: T;
};

export enum ConnectionStatus {
  UNKNOWN = 'UNKNOWN',
  INVALID = 'INVALID',
  VALID = 'VALID',
}

/**
 * dto needed all fields needed to refresh credential
 */
export type RefreshCredentialInfo = {
  action: Action;
  credentialId: string;
};
