import { Stripe } from 'stripe';

import { EnumInputValue } from '@shared/types/sdk/actions';
export type Credentials = {
  STRIPE_API_SECRET_KEY: string;
  STRIPE_OAUTH_REFRESH_TOKEN?: string;
  STRIPE_OAUTH_ACCESS_TOKEN?: string;
  SCOPES?: string;
};

export type TestConnectionDTO = {
  STRIPE_API_SECRET_KEY: string;
};

export const subscriptionStatus: EnumInputValue[] = [
  { value: 'trialing', label: 'Trialing' },
  { value: 'active', label: 'Active' },
  { value: 'past_due', label: 'Past due' },
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'incomplete', label: 'Incomplete' },
  { value: 'ended', label: 'Expired' },
  { value: 'all', label: 'Any of the above' },
];

export type CreateCustomerDTO = {
  emailCreateCustomer: string;
  name?: string;
  description?: string;
  metadataCreateCustomer?: string;
};

export type UpdateCustomerDTO = {
  customerId: string;
  emailUpdateCustomer?: string;
  name?: string;
  description?: string;
  metadataUpdateCustomer?: string;
};

export type GetCustomerByIdDTO = {
  idGetCustomer: string;
};

export type GetCustomersDTO = {
  emailGetCustomers?: string;
  createdAfter?: string;
  createdBefore?: string;
  limitGetCustomers?: string;
};

export type CreateSubscriptionDTO = {
  customerIdCreateSubscription: string;
  plan: string;
  metadataCreateSubscription?: string;
};

export type GetSubscriptionsDTO = {
  customerIdGetSubscriptions?: string;
  priceIdGetSubscriptions?: string;
  status?: Stripe.SubscriptionListParams.Status;
  limitGetSubscriptions?: string;
};

export type CreateProductDTO = {
  productName: string;
  description: string;
  metadataProduct: string;
};

export type GetProductByIdDTO = {
  productId: string;
};

export type GetProductsDTO = {
  createdAfter?: string;
  createdBefore?: string;
  limitGetProducts: string;
};

export type BalanceTransactionType = 'charge' | 'refund' | 'payment' | 'payment_refund';

export type GetBalanceTransactionsDTO = {
  balanceTransactionType: BalanceTransactionType;
  createdAfter?: string;
  createdBefore?: string;
};

export type GetPlansDTO = {
  isPlanActive: boolean;
};
export type Environment = {
  STRIPE_CLIENT_ID: string;
  STRIPE_CLIENT_SECRET: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
};
