import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { WorkflowType } from '@shared/types/sdk/execution';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { authInput } from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.XERO,
  name: 'Xero',
  description: 'Trigger when new Accounts, Customers, or Invoices are created',
  icon: iconSvg,
  mutedIcon: iconSvg,
  provider: ProviderType.XERO,
  sidebarSections: [
    (
      _parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      return {
        inputs: workflowType === WorkflowType.CLASSIC ? [authInput] : [],
      };
    },
    (
      parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      if (parameters.credentials.length === 0 && workflowType === WorkflowType.CLASSIC) {
        return { inputs: [] };
      }
      return {
        inputs: [
          {
            id: 'intent',
            title: 'Choose a Trigger Event',
            type: SidebarInputType.Intent,
            values: [
              {
                value: Intent.TRIGGER_ACCOUNT_CREATED,
                label: 'New Account',
                subtitle: 'Trigger when a new Xero Account is created',
              },
              {
                value: Intent.TRIGGER_CUSTOMER_CREATED,
                label: 'New Customer',
                subtitle: 'Trigger when a new Xero Customer is created',
              },
              {
                value: Intent.TRIGGER_INVOICE_CREATED,
                label: 'New Invoice',
                subtitle: 'Trigger when a new Xero Invoice is created',
              },
            ],
          },
        ],
      };
    },
  ],
};

export default connectConfig;
