import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { DataTypeValue } from '../shared';

import {
  allowMultipleLinesInput,
  allowTextEntryInput,
  apiNameInput,
  choicesInput,
  commonIntentInput,
  createItemInput,
  dataTypeInput,
  defaultValueInput,
  descriptionInput,
  displayAsInput,
  displayNameInput,
  formatInput,
  hiddenInput,
  isPictureInput,
  itemIdInput,
  itemsFilterInput,
  linesForEditingInput,
  listIdInput,
  listsFilterInput,
  maxLengthInput,
  maximumInput,
  minimumInput,
  nameInput,
  readOnlyInput,
  textTypeInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.SHAREPOINT,
  name: 'SharePoint',
  description: 'Connect your SharePoint account to manage sites and lists in SharePoint.',
  icon: iconSvg,
  provider: ProviderType.SHAREPOINT,
  sidebarSections: [
    commonIntentInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.CREATE_ITEM:
          return { inputs: createItemInput(true, false) };

        case Intent.UPDATE_ITEM:
          return {
            inputs: [itemIdInput, ...createItemInput(true, true)],
          };

        case Intent.GET_ITEM_BY_ID:
          return {
            inputs: [
              { ...itemIdInput, subtitle: 'The ID of the item to get.' },
              {
                ...listIdInput,
                subtitle:
                  'The List ID to get the item from. Use Connect Portal User Settings to allow users to select a List.',
              },
            ],
          };

        case Intent.GET_ITEMS_IN_A_LIST:
          return {
            inputs: [
              {
                ...listIdInput,
                subtitle:
                  'The List ID to get items from. Use Connect Portal User Settings to allow users to select a List.',
              },
              itemsFilterInput,
            ],
          };

        case Intent.DELETE_ITEM:
          return {
            inputs: [
              { ...itemIdInput, subtitle: 'The ID of the item to delete.' },
              {
                ...listIdInput,
                subtitle:
                  'The List ID to delete the item from. Use Connect Portal User Settings to allow users to select a List.',
              },
            ],
          };

        case Intent.CREATE_LIST:
          return {
            inputs: [nameInput, descriptionInput],
          };

        case Intent.GET_LIST_BY_ID:
        case Intent.GET_LIST_COLUMNS:
          return {
            inputs: [
              {
                ...listIdInput,
                subtitle:
                  'The ID of the list to get. Use Connect Portal User Settings to allow users to select a List.',
              },
            ],
          };

        case Intent.CREATE_LIST_COLUMN:
          return {
            inputs: [
              {
                ...listIdInput,
                subtitle:
                  'The List ID to create the column in. Use Connect Portal User Settings to allow users to select a List.',
              },
              dataTypeInput,
              displayNameInput,
              apiNameInput,
              descriptionInput,
              defaultValueInput,
              hiddenInput,
              readOnlyInput,
            ],
          };

        case Intent.GET_LISTS:
          return { inputs: [listsFilterInput] };

        default:
          return {
            inputs: [],
          };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (parameters.intent === Intent.CREATE_LIST_COLUMN) {
        const selectedDataType = pickValueSourceByKey(
          parameters.actionParameters,
          'dataType',
        )?.value;
        switch (selectedDataType) {
          case DataTypeValue.CHOICE:
            return {
              inputs: [
                allowTextEntryInput,
                choicesInput,
                displayAsInput([
                  { label: 'Checkboxes', value: 'Checkboxes' },
                  { label: 'Dropdown Menu', value: 'Dropdown Menu' },
                  { label: 'Radio Buttons', value: 'Radio Buttons' },
                ]),
              ],
            };

          case DataTypeValue.DATETIME:
            return {
              inputs: [
                displayAsInput([
                  { label: 'Default', value: 'Default' },
                  { label: 'Friendly', value: 'Friendly' },
                  { label: 'Standard', value: 'Standard' },
                ]),
                formatInput,
              ],
            };

          case DataTypeValue.NUMBER:
            return {
              inputs: [
                displayAsInput([
                  { label: 'Number', value: 'Number' },
                  { label: 'Percentage', value: 'Percentage' },
                ]),
                maximumInput,
                minimumInput,
              ],
            };

          case DataTypeValue.TEXT:
            return {
              inputs: [
                linesForEditingInput,
                maxLengthInput,
                textTypeInput,
                allowMultipleLinesInput,
              ],
            };

          case DataTypeValue.HYPERLINKORPICTURE:
            return {
              inputs: [isPictureInput],
            };

          default:
            return { inputs: [] };
        }
      } else {
        return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
