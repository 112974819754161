import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { WorkflowType } from '@shared/types/sdk/execution';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { authConfig } from './config';

const triggerConfig: ActionConfig = {
  actionType: Action.ZENDESK,
  name: 'Zendesk',
  description: 'Trigger when tickets are created or updated in Zendesk',
  icon: iconSvg,
  mutedIcon: iconSvg,
  provider: ProviderType.ZENDESK,
  sidebarSections: [
    (
      _parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType: WorkflowType,
    ): SidebarSection => {
      return workflowType === WorkflowType.CONNECT
        ? { inputs: [] }
        : {
            inputs: [
              {
                id: 'auth',
                title: 'Choose your Zendesk account',
                placeholder: 'Choose your Zendesk account',
                type: SidebarInputType.Auth,
                config: authConfig,
              },
            ],
          };
    },
    (
      parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType: WorkflowType,
    ): SidebarSection => {
      return parameters.credentials?.length || workflowType === WorkflowType.CONNECT
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose a Trigger Event',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.TRIGGER_TICKET_CREATED,
                    label: 'New Ticket',
                  },
                  {
                    value: Intent.TRIGGER_TICKET_UPDATED,
                    label: 'Ticket Updated',
                  },
                ],
              },
            ],
          }
        : { inputs: [] };
    },
  ],
};

export default triggerConfig;
