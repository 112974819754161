import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * @summary there is no support for or condition so "and" and "or" behave in a similar manner
 * @param condition
 * @returns condition for filter clickUp
 */

export default function conditionsToFilterClickUpExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterClickUpExpression(condition);
      })
      .join('&');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
      case Operator.NumberEquals:
        return `${innerCondition.variable}=${innerCondition.argument}`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable}=false`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable}=true`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable}=${new Date(
          new Date(innerCondition.argument).toISOString(),
        ).valueOf()}`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for ClickUp`);
    }
  }
  return '';
}
