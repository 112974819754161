import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';

import { ActionStep, ActionTriggerStep, TokenType } from './actions';
import { FanoutStackEntry } from './execution';
import {
  CustomIntegrationRequestStep,
  DailySchedule,
  DelayStep,
  FunctionStep,
  HourlySchedule,
  MapStep,
  MinutesSchedule,
  RedirectStep,
  RequestAuthorization,
  RequestStep,
  ResponseStep,
  Schedule,
  SecondsSchedule,
  StateMachine,
  Time,
  Variables,
  WeeklySchedule,
} from './steps';

export enum DataType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  EMAIL = 'EMAIL',
  OBJECT = 'OBJECT',
  ARRAY = 'ARRAY',
  ANY = 'ANY',
  FILE = 'FILE',
  NON_DECIMAL = 'NON_DECIMAL',
}

type FileDataType = Buffer;

export type FileValue = {
  data: FileDataType;
  dataType: DataType.FILE;
  encoding?: string;
  id?: string;
  mimeType?: string;
  name?: string;
  size?: string;
};

export type DataTypeValues = {
  [DataType.STRING]: string;
  [DataType.NUMBER]: number;
  [DataType.DATE]: Date;
  [DataType.BOOLEAN]: boolean;
  [DataType.EMAIL]: string;
  [DataType.OBJECT]: object;
  [DataType.ARRAY]: any[];
  [DataType.ANY]: any;
  [DataType.FILE]: FileValue;
  [DataType.NON_DECIMAL]: number;
};

export type ValueSource<T extends DataType = DataType> = {
  dataType?: T;
  type: 'VALUE';
  value: DataTypeValues[T];
};

export enum FanInStrategy {
  SINGLE = 'SINGLE',
  SINGLE_BY_FANOUT = 'SINGLE_BY_FANOUT',
  MULTI = 'MULTI',
  MULTI_BY_FANOUT = 'MULTI_BY_FANOUT',
}

export type ObjectValueSource<T extends DataType = DataType> = {
  type: 'OBJECT_VALUE';
  name: string;
  path: string[];
  dataType?: T;
};

export type VariableSource<T extends DataType = DataType> = {
  dataType?: T;
  type: 'VARIABLE';
  stepId: string;
  path: string[];
};

export type SecretSource = {
  type: 'ENVIRONMENT_SECRET';
  environmentSecretId: string;
};

// TODO: remove deprecated execution source
export type DeprecatedExecutionSource = {
  type: 'CACHED_EXECUTION';
  cachedEntryId: string;
};

export type UserSuppliedCredentialSource = {
  type: 'USER_SUPPLIED_CREDENTIAL';
  tokenType: TokenType;
  providerType: ProviderType;
  credential: TokenizedSource<DataType.STRING>;
};

export type ResolvedUserSuppliedCredentialSource = Omit<
  UserSuppliedCredentialSource,
  'credential'
> & {
  credential: string;
};

export type ExecutionSource = {
  type: 'EXECUTION';
  id: string; // step execution id (instance)
  stepId: string; // the base step id

  /**
   * a timestamp representing when the execution occurred
   */
  start: number;
  fanoutStack: FanoutStackEntry[];

  /**
   * This flag suggests that the content of the variable will be stored on remmote storage service
   * such as s3
   */
  remoteCached?: boolean;
};

export type ResolveContext = {
  workflowId: string;
  stepId: string;
  stateMachine: StateMachine;
  fanoutStack: FanoutStackEntry[] | undefined;
};

export type ObjectMappingInput = {
  objectType: string;
  fieldMappings?: { applicationField?: string; integrationField?: string }[];
};

export type ParsedObjectMapping = {
  objectType: string;
  fields: Record<string, unknown>;
};

// executions within fanouts are represented by a `FanoutExecutionSource`
// in redis, these are stored as a list of `ExecutionSource` types
// the `FanoutExecutionSource` is used to retrieve this list
// and a resolver determines which of the executions should be passed as variables
// depending on the context that the executing step is in
export type FanoutExecutionSource = {
  type: 'FANOUT_EXECUTION';
  stepId: string; // step id of the execution, NOT the fanout
};

export type CompletedExecution = Omit<ExecutionSource, 'type'> & {
  output: Variables;
};

// step execution id (instance) -> ResolvedExecution
export type WorkflowVariables = Record<string, CompletedExecution>;

export type TokenizedValue<T extends DataType = DataType> =
  | ValueSource<T>
  | VariableSource<T>
  | SecretSource
  | ConnectCredentialSource
  | ObjectValueSource
  | PersonaMetadataSource;

export type TokenizedSource<T extends DataType = DataType> = {
  dataType?: T;
  type: 'TOKENIZED';
  parts: TokenizedValue[];
};

export type ConditionSource = {
  type: 'CONDITION';
  condition: ConditionWrapper;
};

export type ConnectCredentialSource =
  | {
      type: 'CONNECT_CREDENTIAL_FIELD';
      fieldType: 'WORKFLOW_SETTING';
      inputId: string;
      /**
       * @since PARA-3065: ConnectInputValue can now include more than a string/number type, so a
       * path is specified similarly to VariableSource to traverse object types.
       */
      path?: string[];
    }
  | {
      type: 'CONNECT_CREDENTIAL_FIELD';
      fieldType: 'SHARED_WORKFLOW_SETTING';
      inputId: string;
      path?: string[];
    }
  | {
      type: 'CONNECT_CREDENTIAL_FIELD';
      fieldType: 'EXTERNAL_USER_ID';
    }
  | {
      type: 'CONNECT_CREDENTIAL_FIELD';
      fieldType: 'EXTERNAL_USER_PROVIDER_ID';
    }
  | {
      type: 'CONNECT_CREDENTIAL_FIELD';
      fieldType: 'OAUTH_ACCESS_TOKEN';
    }
  | {
      type: 'CONNECT_CREDENTIAL_FIELD';
      fieldType: 'EXTERNAL_USER_PROVIDER_DATA';
      dataKey: string;
    };

export type PersonaMetadataSource = {
  type: 'PERSONA_METADATA';
  path?: string[];
};

export type Source<T extends DataType = DataType> =
  | ValueSource<T>
  | VariableSource<T>
  | TokenizedSource<T>
  | ConditionSource
  | SecretSource
  | DeprecatedExecutionSource
  | ExecutionSource
  | FanoutExecutionSource
  | UserSuppliedCredentialSource
  | ConnectCredentialSource
  | ObjectValueSource
  | PersonaMetadataSource;

export type KeyedSource<T extends DataType = DataType> = {
  key: string;
  source: Source<T>;
};

export type ResolvedFunctionParameters = ResolvedKeyedSourceParameters<
  FunctionStep['parameters'],
  'parameters'
>;

export type ResolvedRequestParameters = Omit<
  ResolvedSourceParameters<RequestStep['parameters'], 'url' | 'rawBody'>,
  'params' | 'headers' | 'body' | 'authorization'
> &
  Omit<
    ResolvedKeyedSourceParameters<RequestStep['parameters'], 'params' | 'headers' | 'body'>,
    'url' | 'rawBody' | 'authorization'
  > & {
    authorization: ResolvedSourceParametersAll<RequestAuthorization>;
  };

export type ResolvedCustomIntegrationRequestParameters = Omit<
  ResolvedSourceParameters<CustomIntegrationRequestStep['parameters'], 'url' | 'rawBody'>,
  'params' | 'headers' | 'body'
> &
  Omit<
    ResolvedKeyedSourceParameters<
      CustomIntegrationRequestStep['parameters'],
      'params' | 'headers' | 'body'
    >,
    'url' | 'rawBody'
  >;

export type ResolvedRedirectParameters = Omit<
  ResolvedKeyedSourceParameters<RedirectStep['parameters'], 'params'>,
  'url'
> &
  Omit<ResolvedSourceParameters<RedirectStep['parameters'], 'url'>, 'params'>;

export type ResolvedResponseParameters = ResolvedKeyedSourceParameters<
  Omit<ResponseStep['parameters'], 'bodyFile'>,
  'bodyData'
> & { bodyFile?: FileValue };

export type ResolvedMapParameters = ResolvedSourceParameters<MapStep['parameters'], 'iterator'>;

export type ResolvedDelayParameters = ResolvedSourceParameters<DelayStep['parameters'], 'value'>;

export type ResolvedActionParameters = ResolvedKeyedSourceParameters<
  ActionStep['parameters'],
  'actionParameters'
> & { objectMapping?: ObjectMappingInput };

export type ResolvedActionTriggerParameters = ResolvedKeyedSourceParameters<
  ActionTriggerStep['parameters'],
  'actionParameters'
> & { objectMapping?: ObjectMappingInput };

export type ResolvedTime = ResolvedSourceParameters<Time, 'timezone'>;
export type ResolvedSchedule =
  | SecondsSchedule
  | MinutesSchedule
  | HourlySchedule
  | DailySchedule<ResolvedTime>
  | WeeklySchedule<ResolvedTime>;

export type ResolvedCronParameters = {
  schedule: Schedule;
};

export type ResolvedOAuthParameters = {
  authorizationCode?: string;
  query?: { [key: string]: string };
};

export enum Operator {
  'None' = '$none',
  'StringContains' = '$stringContains',
  'StringDoesNotContain' = '$stringDoesNotContain',
  'StringExactlyMatches' = '$stringExactlyMatches',
  'StringDoesNotExactlyMatch' = '$stringDoesNotExactlyMatch',
  'StringIsIn' = '$stringIsIn',
  'StringIsNotIn' = '$stringIsNotIn',
  'StringStartsWith' = '$stringStartsWith',
  'StringDoesNotStartWith' = '$stringDoesNotStartWith',
  'StringEndsWith' = '$stringEndsWith',
  'StringDoesNotEndWith' = '$stringDoesNotEndWith',
  'NumberGreaterThan' = '$numberGreaterThan',
  'NumberLessThan' = '$numberLessThan',
  'NumberEquals' = '$numberEquals',
  'NumberDoesNotEqual' = '$numberDoesNotEqual',
  'NumberLessThanOrEqualTo' = '$numberLessThanOrEqualTo',
  'NumberGreaterThanOrEqualTo' = '$numberGreaterThanOrEqualTo',
  'DateTimeAfter' = '$dateTimeAfter',
  'DateTimeBefore' = '$dateTimeBefore',
  'DateTimeEquals' = '$dateTimeEquals',
  'BooleanTrue' = '$booleanTrue',
  'BooleanFalse' = '$booleanFalse',
  /** (PARA-5551) previously operators for Does Exist / Does Not Exist do a strict check for equality to null, resulting in a bug where value is undefined
   * to handle this situation, we are adding 2 new operators IsNotNull and IsNull and changing functionality of
   * Exist and DoesNotExist operators to handle undefined values
   */
  'IsNotNull' = '$exists',
  'IsNull' = '$doesNotExist',
  'Exists' = '$isNotUndefinedOrNull',
  'DoesNotExist' = '$isUndefinedOrNull',
  'ArrayIsIn' = '$arrayIsIn',
  'ArrayIsNotIn' = '$arrayIsNotIn',
  'ArrayIsEmpty' = '$arrayIsEmpty',
  'ArrayIsNotEmpty' = '$arrayIsNotEmpty',
  'StringGreaterThan' = '$stringGreaterThan',
  'StringLessThan' = '$stringLessThan',
}
export const DEFAULT_SUPPORTED_OPERATORS: Operator[] = [
  Operator.None,
  Operator.StringContains,
  Operator.StringDoesNotContain,
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.StringIsIn,
  Operator.StringIsNotIn,
  Operator.StringStartsWith,
  Operator.StringDoesNotStartWith,
  Operator.StringEndsWith,
  Operator.StringDoesNotEndWith,
  Operator.NumberGreaterThan,
  Operator.NumberLessThan,
  Operator.NumberEquals,
  Operator.NumberDoesNotEqual,
  Operator.DateTimeAfter,
  Operator.DateTimeBefore,
  Operator.DateTimeEquals,
  Operator.BooleanTrue,
  Operator.BooleanFalse,
  Operator.IsNotNull,
  Operator.IsNull,
  Operator.Exists,
  Operator.DoesNotExist,
  Operator.ArrayIsEmpty,
  Operator.ArrayIsNotEmpty,
];

export type JoinedConditions = {
  type: 'JOIN';
  join: 'AND' | 'OR';
  conditions: ConditionWrapper[];
};

export type OperatorCondition = {
  type: 'OPERATOR';
  condition: Condition;
};

export type ConditionWrapper = JoinedConditions | OperatorCondition;

export type ResolvedConditionalParameters = {
  next: string | null;
  choices: {
    conditionWrapper: any;
    isDefault: boolean;
    label: string;
    next: string | null;
  }[];
};

export type ResolvedConditionWrapper =
  | (Omit<JoinedConditions, 'conditions'> & {
      conditions: ResolvedConditionWrapper[];
    })
  | (Omit<OperatorCondition, 'condition'> & { condition: ResolvedCondition });

// TODO: The below discriminated union might be better separated as individual
// interfaces that extend BaseCondition.
export interface BaseCondition {
  operator: Operator;
  variable: Source;
  argument?: Source;
}

export type Condition =
  | {
      operator: Operator.None;
      variable: Source<DataType.ANY>;
    }
  | {
      operator: Operator.StringContains;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringDoesNotContain;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringExactlyMatches;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringDoesNotExactlyMatch;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringIsIn;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringIsNotIn;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringStartsWith;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringDoesNotStartWith;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringEndsWith;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringDoesNotEndWith;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.NumberGreaterThan;
      variable: Source<DataType.NUMBER>;
      argument: Source<DataType.NUMBER>;
    }
  | {
      operator: Operator.NumberLessThan;
      variable: Source<DataType.NUMBER>;
      argument: Source<DataType.NUMBER>;
    }
  | {
      operator: Operator.NumberGreaterThanOrEqualTo;
      variable: Source<DataType.NUMBER>;
      argument: Source<DataType.NUMBER>;
    }
  | {
      operator: Operator.NumberLessThanOrEqualTo;
      variable: Source<DataType.NUMBER>;
      argument: Source<DataType.NUMBER>;
    }
  | {
      operator: Operator.NumberEquals;
      variable: Source<DataType.NUMBER>;
      argument: Source<DataType.NUMBER>;
    }
  | {
      operator: Operator.NumberDoesNotEqual;
      variable: Source<DataType.NUMBER>;
      argument: Source<DataType.NUMBER>;
    }
  | {
      operator: Operator.DateTimeAfter;
      variable: Source<DataType.DATE>;
      argument: Source<DataType.DATE>;
    }
  | {
      operator: Operator.DateTimeBefore;
      variable: Source<DataType.DATE>;
      argument: Source<DataType.DATE>;
    }
  | {
      operator: Operator.DateTimeEquals;
      variable: Source<DataType.DATE>;
      argument: Source<DataType.DATE>;
    }
  | {
      operator: Operator.BooleanTrue;
      variable: Source<DataType.BOOLEAN>;
    }
  | {
      operator: Operator.BooleanFalse;
      variable: Source<DataType.BOOLEAN>;
    }
  | { operator: Operator.IsNull; variable: Source }
  | {
      operator: Operator.IsNotNull;
      variable: Source;
    }
  | { operator: Operator.Exists; variable: Source }
  | {
      operator: Operator.DoesNotExist;
      variable: Source;
    }
  | {
      operator: Operator.ArrayIsEmpty;
      variable: Source<DataType.ARRAY>;
    }
  | {
      operator: Operator.ArrayIsNotEmpty;
      variable: Source<DataType.ARRAY>;
    }
  | {
      operator: Operator.StringGreaterThan;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    }
  | {
      operator: Operator.StringLessThan;
      variable: Source<DataType.STRING>;
      argument: Source<DataType.STRING>;
    };

export type OperatorInfo = {
  label: string;
  variableType: DataType;
  argumentType?: DataType;
};

export const OPERATORS: Record<Operator, OperatorInfo> = {
  [Operator.None]: {
    label: '',
    variableType: DataType.ANY,
  },
  [Operator.StringContains]: {
    label: '(String) Contains',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.StringDoesNotContain]: {
    label: '(String) Does not contain',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.StringExactlyMatches]: {
    label: '(String) Exactly matches',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.StringDoesNotExactlyMatch]: {
    label: '(String) Does not exactly match',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.StringIsIn]: {
    label: '(String) Is in',
    variableType: DataType.STRING,
    argumentType: DataType.ARRAY,
  },
  [Operator.StringIsNotIn]: {
    label: '(String) Is not in',
    variableType: DataType.STRING,
    argumentType: DataType.ARRAY,
  },
  [Operator.StringStartsWith]: {
    label: '(String) Starts with',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.StringDoesNotStartWith]: {
    label: '(String) Does not start with',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.StringEndsWith]: {
    label: '(String) Ends with',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.StringDoesNotEndWith]: {
    label: '(String) Does not end with',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.NumberGreaterThan]: {
    label: '(Number) Greater than',
    variableType: DataType.NUMBER,
    argumentType: DataType.NUMBER,
  },
  [Operator.NumberLessThan]: {
    label: '(Number) Less than',
    variableType: DataType.NUMBER,
    argumentType: DataType.NUMBER,
  },
  [Operator.NumberEquals]: {
    label: '(Number) Equals',
    variableType: DataType.NUMBER,
    argumentType: DataType.NUMBER,
  },
  [Operator.NumberDoesNotEqual]: {
    label: '(Number) Does not equal',
    variableType: DataType.NUMBER,
    argumentType: DataType.NUMBER,
  },
  [Operator.NumberGreaterThanOrEqualTo]: {
    label: '(Number) Greater than or equal to',
    variableType: DataType.NUMBER,
    argumentType: DataType.NUMBER,
  },
  [Operator.NumberLessThanOrEqualTo]: {
    label: '(Number) Less than or equal to',
    variableType: DataType.NUMBER,
    argumentType: DataType.NUMBER,
  },
  [Operator.DateTimeAfter]: {
    label: '(Date/time) After',
    variableType: DataType.DATE,
    argumentType: DataType.DATE,
  },
  [Operator.DateTimeBefore]: {
    label: '(Date/time) Before',
    variableType: DataType.DATE,
    argumentType: DataType.DATE,
  },
  [Operator.DateTimeEquals]: {
    label: '(Date/time) Equals',
    variableType: DataType.DATE,
    argumentType: DataType.DATE,
  },
  [Operator.BooleanTrue]: { label: '(Boolean) Is true', variableType: DataType.BOOLEAN },
  [Operator.BooleanFalse]: {
    label: '(Boolean) Is false',
    variableType: DataType.BOOLEAN,
  },
  [Operator.ArrayIsIn]: {
    label: '(Array) Is in',
    variableType: DataType.ARRAY,
    argumentType: DataType.STRING,
  },
  [Operator.ArrayIsNotIn]: {
    label: '(Array) Is not in',
    variableType: DataType.ARRAY,
    argumentType: DataType.STRING,
  },
  [Operator.ArrayIsEmpty]: {
    label: '(Array) Is empty',
    variableType: DataType.ARRAY,
  },
  [Operator.ArrayIsNotEmpty]: {
    label: '(Array) Is not empty',
    variableType: DataType.ARRAY,
  },
  [Operator.Exists]: { label: 'Exists', variableType: DataType.ANY },
  [Operator.DoesNotExist]: { label: 'Does not exist', variableType: DataType.ANY },
  [Operator.IsNull]: { label: 'Is Null', variableType: DataType.ANY },
  [Operator.IsNotNull]: { label: 'Is Not Null', variableType: DataType.ANY },
  [Operator.StringGreaterThan]: {
    label: '(String) Greater than',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
  [Operator.StringLessThan]: {
    label: '(String) Less than',
    variableType: DataType.STRING,
    argumentType: DataType.STRING,
  },
};

export type ResolvedCondition = {
  operator: Operator;
  variable: any;
  argument?: any;
};

type KeyedSourceDataType<T> = T extends KeyedSource<infer D>[] ? D : never;

export type ResolvedKeyedSourceParameters<P, S extends keyof P> = {
  [K in keyof P]: K extends S ? Record<string, DataTypeValues[KeyedSourceDataType<P[K]>]> : P[K];
};

type SourceDataType<T> = T extends Source<infer D> ? D : never;

/**
 * @deprecated Consider using `ResolvedSourceParametersAll` (exported from the same package) with
 * `Pick` to more declaratively specify resolved types.
 */
export type ResolvedSourceParameters<P, S extends keyof P> = {
  [K in keyof P]: K extends S ? DataTypeValues[SourceDataType<P[K]>] : P[K];
};

export type ResolvedSourceParametersAll<P> = {
  [K in keyof P]: P[K] extends Source ? DataTypeValues[SourceDataType<P[K]>] : P[K];
};

export type ResolvedParameters =
  | ResolvedFunctionParameters
  | ResolvedRequestParameters
  | ResolvedCustomIntegrationRequestParameters
  | ResolvedResponseParameters
  | ResolvedActionParameters
  | ResolvedCondition
  | ResolvedDelayParameters
  | ResolvedFunctionParameters
  | ResolvedMapParameters
  | ResolvedConditionalParameters
  | ResolvedOAuthParameters
  | ResolvedActionTriggerParameters;
