import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

export const fileIdInput: SidebarInput = {
  id: 'fileId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'File name',
  subtitle: 'The name of the file to get.',
  required: true,
  placeholder: 'video.mp4',
};

export const filePathInput = (required: boolean = false): SidebarInput => ({
  id: 'folder',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Folder',
  subtitle:
    'Use Connect Portal User Settings to allow users to select which Folder to save files to. Defaults to the user’s root folder if unspecified.',
  required,
  placeholder: '/my_folder/my_subfolder',
});

export const fileInput: SidebarInput = {
  id: 'file',
  type: SidebarInputType.Text,
  title: 'File',
  subtitle: 'File to be uploaded',
  required: true,
  dataType: DataType.FILE,
};

export const fileNameInput: SidebarInput = {
  id: 'fileName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'File Name',
  subtitle: 'Defaults to origin file name if unspecified.',
  required: false,
};
