import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class ProjectError extends ParagonError {
  name: string = 'ProjectError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class UnauthorizedProjectAccessError extends ProjectError {
  name: string = 'UnauthorizedProjectAccessError';

  constructor(meta: {
    projectId: string;
    teamId?: string;
    userId?: string;
    organizationId?: string;
    path: string;
    method: string;
  }) {
    super({
      code: ERROR_CODE.UNAUTHORIZED_PROJECT_ACCESS,
      httpStatus: HttpStatus.FORBIDDEN,
      message: "You don't have permission to do this.",
      meta,
    });
  }
}

export class ClassicProjectNotFoundForTeamError extends ProjectError {
  name: string = 'ClassicProjectNotFoundForTeamError';

  constructor(meta: { teamId: string; projectIds: string[] }) {
    super({
      code: ERROR_CODE.CLASSIC_PROJECT_NOT_FOUND_FOR_TEAM,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find Automate project.',
      meta,
    });
  }
}

export class ProjectNotFoundFromIdError extends ProjectError {
  name: string = 'ProjectNotFoundFromIdError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.PROJECT_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find project.',
      meta,
    });
  }
}

export class ProjectIdNotFoundInParamsError extends ProjectError {
  name: string = 'ProjectIdNotFoundInParamsError';

  constructor(meta: { path: string; method: string }) {
    super({
      code: ERROR_CODE.PROJECT_ID_NOT_FOUND_IN_PARAMS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Project id is required.',
      meta,
    });
  }
}
