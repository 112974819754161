export default `<svg width="46" height="54" viewBox="0 0 46 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.243 10.489L31.5164 27.1012L40.243 43.7133L43.8178 41.7157V12.4867L40.243 10.489Z" fill="#E25444"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.243 10.489L22.7897 12.4867L13.9054 27.1012L22.7897 41.7157L40.243 43.7133V10.489Z" fill="#7B1D13"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.02104 10.489L2.18225 11.7507V42.4516L5.02104 43.7133L22.7897 27.1012L5.02104 10.489Z" fill="#58150D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.98285 10.454L22.8133 15.4072V39.2389L4.98285 43.725V10.454Z" fill="#E25444"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.8226 18.0241L15.2517 16.8091L22.8226 8.21094L30.3749 16.8091L22.8226 18.0241Z" fill="#58150D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.3749 16.8091L22.8133 18.0427L15.2517 16.8091V8.21094" fill="#58150D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.8226 36.0614L15.2517 37.4633L22.8226 44.8465L30.3749 37.4633L22.8226 36.0614Z" fill="#58150D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.7897 1.76233L15.2196 5.75766V16.7974L22.8132 14.6596L22.7897 1.76233Z" fill="#7B1D13"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.8133 20.7343L15.2517 21.5568V32.5795L22.8133 33.4445V20.7343Z" fill="#7B1D13"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.8133 39.3324L15.2517 37.4364V48.2725L22.8133 52.2296V39.3324Z" fill="#7B1D13"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.3749 37.4364L22.8133 39.3329V52.2296L30.3749 48.2725V37.4364Z" fill="#E25444"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.8133 20.7343L30.3749 21.5568V32.5795L22.8133 33.4445V20.7343Z" fill="#E25444"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.7897 1.76233L30.3598 5.75766V16.7974L22.7897 14.6946V1.76233Z" fill="#E25444"/>
</svg>
`;
