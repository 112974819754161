import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.LINEAR;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'LINEAR_TEST_CONNECTION',
  GET_TEAMS = 'LINEAR_GET_TEAMS',
  GET_LABELS_BY_TEAM = 'LINEAR_GET_LABELS_BY_TEAM',
  GET_STATUS_BY_TEAM = 'LINEAR_GET_STATUS_BY_TEAM',
  CREATE_ISSUE = 'LINEAR_CREATE_ISSUE',
  UPDATE_ISSUE = 'LINEAR_UPDATE_ISSUE',
  GET_ISSUE_BY_ID = 'LINEAR_GET_ISSUE_BY_ID',
  GET_ISSUE_BY_ISSUE_IDENTIFIER = 'LINEAR_GET_ISSUE_BY_ISSUE_IDENTIFIER',
  SEARCH_ISSUE = 'LINEAR_SEARCH_ISSUE',
  DELETE_ISSUE = 'LINEAR_DELETE_ISSUE',
  ARCHIVE_ISSUE = 'LINEAR_ARCHIVE_ISSUE',
  CREATE_SUB_ISSUE = 'LINEAR_CREATE_SUB_ISSUE',
  CREATE_PROJECT = 'LINEAR_CREATE_PROJECT',
  UPDATE_PROJECT = 'LINEAR_UPDATE_PROJECT',
  GET_PROJECT_BY_ID = 'LINEAR_GET_PROJECT_BY_ID',
  DELETE_PROJECT = 'LINEAR_DELETE_PROJECT',
  SEARCH_TEAMS = 'LINEAR_SEARCH_TEAMS',

  // Triggers
  TRIGGER_ISSUE_CREATED = 'LINEAR_TRIGGER_ISSUE_CREATED',
  TRIGGER_ISSUE_DELETED = 'LINEAR_TRIGGER_ISSUE_DELETED',
  TRIGGER_STATUS_UPDATED = 'LINEAR_TRIGGER_STATUS_UPDATED',
  TRIGGER_LABEL_ADDED = 'LINEAR_TRIGGER_LABEL_ADDED',
}

/**
 * Combination of OAuthIntent, ActionIntent and ConnectIntent
 */
export type IntentMap =
  | OAuthIntent.REFRESH_CREDENTIAL
  | OAuthIntent.GET_AUTH_ESSENTIALS
  | OAuthIntent.EXCHANGE_AUTH_CREDENTIALS
  | Intent.TRIGGER_ISSUE_CREATED
  | Intent.TRIGGER_ISSUE_DELETED
  | Intent.TRIGGER_STATUS_UPDATED
  | Intent.TRIGGER_LABEL_ADDED
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap | Intent;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export type Credentials = {
  LINEAR_ACCESS_TOKEN: string;
  SCOPES?: string;
};

export enum LinearAPIActionName {
  CREATE = 'create',
  RESTORE = 'restore',
  UPDATE = 'update',
  REMOVE = 'remove',
}
