import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig, SidebarInputType } from '@shared/types/sdk/actions';

import iconSvg from '../../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.POSTGRES,
  provider: ProviderType.POSTGRES,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect to a PostgreSQL database',
  description: 'Add PostgreSQL to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name',
          placeholder: 'name this database',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'POSTGRES_DATABASE',
          title: 'Database',
          placeholder: 'database',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'POSTGRES_HOST',
          title: 'Host',
          placeholder: 'postgresql://localhost',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'POSTGRES_PORT',
          title: 'Port',
          placeholder: '5432',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'POSTGRES_USER',
          title: 'User',
          placeholder: 'user',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'POSTGRES_PASSWORD',
          title: 'Password',
          placeholder: 'password',
          type: SidebarInputType.ValueText,
          inputType: 'password',
        },
      ],
      buttons: [
        createTestConnectionButton(Action.POSTGRES, [
          'POSTGRES_DATABASE',
          'POSTGRES_HOST',
          'POSTGRES_PORT',
          'POSTGRES_USER',
          'POSTGRES_PASSWORD',
        ]),
      ],
    },
  ],
};

export default authConfig;
