import React from 'react';

import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { CredentialKeys } from '../configs/config';

export const apiKeyInput: SidebarInput = {
  id: CredentialKeys.API_KEY,
  title: 'API Key',
  subtitle: (
    <>
      To find your PandaDoc API key, see your{' '}
      <a
        href="https://app.pandadoc.com/a/#/settings/api-dashboard/configuration"
        target="_blank"
        rel="noreferrer"
      >
        dashboard configuration
      </a>
      .
    </>
  ),
  placeholder: 'f2531184818gt126...',
  type: SidebarInputType.ValueText,
  required: true,
};
