import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions/';

import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.SALESFORCE,
  provider: ProviderType.SALESFORCE,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Salesforce account',
  icon: iconSvg,
  description: 'Find, create, and update records in Salesforce.',
  sidebarSections: [],
  accountTypes: [
    {
      id: 'default',
      accountDescription: 'Production Account',
      scheme: AuthenticationScheme.OAUTH,
    },
    {
      id: 'sandbox',
      accountDescription: 'Sandbox Account',
      scheme: AuthenticationScheme.OAUTH,
      oauthParameters: [
        {
          key: 'sandbox',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    },
  ],
};

export default authConfig;

const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Salesforce account',
  placeholder: 'connect to Salesforce',
  type: SidebarInputType.Auth,
  config: authConfig,
};
export { authInput };
