import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'channels:manage'
  | 'channels:write'
  | 'channels:read'
  | 'groups:write'
  | 'groups:read'
  | 'chat:write'
  | 'chat:write.public'
  | 'mpim:write'
  | 'im:write'
  | 'users:read'
  | 'team:read'
  | 'channels:history'
  | 'groups:history'
  | 'im:history'
  | 'mpim:history'
  | 'admin'
  | 'admin.analytics:read'
  | 'admin.apps:read'
  | 'admin.apps:write'
  | 'admin.barriers:read'
  | 'admin.barriers:write'
  | 'admin.conversations:read'
  | 'admin.conversations:write'
  | 'admin.invites:read'
  | 'admin.invites:write'
  | 'admin.teams:read'
  | 'admin.teams:write'
  | 'admin.usergroups:read'
  | 'admin.usergroups:write'
  | 'admin.users:read'
  | 'admin.users:write'
  | 'app_mentions:read'
  | 'auditlogs:read'
  | 'bot'
  | 'calls:read'
  | 'calls:write'
  | 'channels:join'
  | 'chat:write.customize'
  | 'chat:write:bot'
  | 'chat:write:user'
  | 'commands'
  | 'conversations.connect:manage'
  | 'conversations.connect:read'
  | 'conversations.connect:write'
  | 'dnd:read'
  | 'dnd:write'
  | 'dnd:write:user'
  | 'email'
  | 'emoji:read'
  | 'files:read'
  | 'files:write'
  | 'files:write:user'
  | 'identify'
  | 'identity.avatar'
  | 'identity.avatar:read:user'
  | 'identity.basic'
  | 'identity.email'
  | 'identity.email:read:user'
  | 'identity.team'
  | 'identity.team:read:user'
  | 'identity:read:user'
  | 'im:read'
  | 'incoming-webhook'
  | 'links:read'
  | 'links:write'
  | 'mpim:read'
  | 'none'
  | 'openid'
  | 'pins:read'
  | 'pins:write'
  | 'profile'
  | 'reactions:read'
  | 'reactions:write'
  | 'reminders:read'
  | 'reminders:read:user'
  | 'reminders:write'
  | 'reminders:write:user'
  | 'remote_files:read'
  | 'remote_files:share'
  | 'remote_files:write'
  | 'search:read'
  | 'stars:read'
  | 'stars:write'
  | 'tokens.basic'
  | 'usergroups:read'
  | 'usergroups:write'
  | 'users.profile:read'
  | 'users.profile:write'
  | 'users.profile:write:user'
  | 'users:read.email'
  | 'users:write'
  | 'workflow.steps:execute';

export const requiredScopes: ScopeName[] = [
  'channels:read',
  'groups:read',
  'chat:write',
  'mpim:write',
  'im:write',
  'users:read',
  'team:read',
  'users:read.email',
];

export const scopes: Record<ScopeName, ScopeValue> = {
  'channels:manage': {
    name: 'channels:manage',
    description: 'Manage public channels that your Slack app has been added to and create new ones',
  },
  'channels:write': {
    name: 'channels:write',
    description: 'Manage the user’s public channels and create new ones on the user’s behalf',
  },
  'channels:read': {
    name: 'channels:read',
    description: 'View basic information about public channels in the workspace',
  },
  'channels:history': {
    name: 'channels:history',
    description:
      'View messages and other content in public channels that your slack app has been added to',
  },
  'chat:write': {
    name: 'chat:write',
    description: 'Post messages in approved channels & conversations',
  },
  'chat:write.public': {
    name: 'chat:write.public',
    description: "Send messages to channels @your_slack_app isn't a member of",
  },
  admin: {
    name: 'admin',
    description: 'Administer a workspace',
  },
  'admin.analytics:read': {
    name: 'admin.analytics:read',
    description: 'Access analytics data about the organization',
  },
  'admin.apps:read': {
    name: 'admin.apps:read',
    description: 'View apps and app requests in a workspace',
  },
  'admin.apps:write': {
    name: 'admin.apps:write',
    description: 'Manage apps in a workspace',
  },
  'admin.barriers:read': {
    name: 'admin.barriers:read',
    description: 'Read information barriers in the organization',
  },
  'admin.barriers:write': {
    name: 'admin.barriers:write',
    description: 'Manage information barriers in the organization',
  },
  'admin.conversations:read': {
    name: 'admin.conversations:read',
    description: 'View the channel’s member list, topic, purpose and channel name',
  },
  'admin.conversations:write': {
    name: 'admin.conversations:write',
    description: 'Start a new conversation, modify a conversation and modify channel details',
  },
  'admin.invites:read': {
    name: 'admin.invites:read',
    description: 'Gain information about invite requests in a Grid organization.',
  },
  'admin.invites:write': {
    name: 'admin.invites:write',
    description: 'Approve or deny invite requests in a Grid organization.',
  },
  'admin.teams:read': {
    name: 'admin.teams:read',
    description: 'Access information about a workspace',
  },
  'admin.teams:write': {
    name: 'admin.teams:write',
    description: 'Make changes to a workspace',
  },
  'admin.usergroups:read': {
    name: 'admin.usergroups:read',
    description: 'Access information about user groups',
  },
  'admin.usergroups:write': {
    name: 'admin.usergroups:write',
    description: 'Make changes to your usergroups',
  },
  'admin.users:read': {
    name: 'admin.users:read',
    description: 'Access a workspace’s profile information',
  },
  'admin.users:write': {
    name: 'admin.users:write',
    description: 'Modify account information',
  },
  'app_mentions:read': {
    name: 'app_mentions:read',
    description:
      'View messages that directly mention @your_slack_app in conversations that the app is in',
  },
  'auditlogs:read': {
    name: 'auditlogs:read',
    description: 'View events from all workspaces, channels and users (Enterprise Grid only)',
  },
  bot: {
    name: 'bot',
    description: 'Add the ability for people to direct message or mention @your_slack_app',
  },
  'calls:read': {
    name: 'calls:read',
    description: 'View information about ongoing and past calls',
  },
  'calls:write': {
    name: 'calls:write',
    description: 'Start and manage calls in a workspace',
  },
  'channels:join': {
    name: 'channels:join',
    description: 'Join public channels in a workspace',
  },
  'chat:write.customize': {
    name: 'chat:write.customize',
    description: 'Send messages as @your_slack_app with a customized username and avatar',
  },
  'chat:write:bot': {
    name: 'chat:write:bot',
    description: 'Send messages as your slack app',
  },
  'chat:write:user': {
    name: 'chat:write:user',
    description: 'Send messages on a user’s behalf',
  },
  commands: {
    name: 'commands',
    description: 'Add shortcuts and/or slash commands that people can use',
  },
  'conversations.connect:manage': {
    name: 'conversations.connect:manage',
    description: 'Allows your slack app to manage Slack Connect channels.',
  },
  'conversations.connect:read': {
    name: 'conversations.connect:read',
    description: 'Receive Slack Connect invite events sent to the channels your slack app is in',
  },
  'conversations.connect:write': {
    name: 'conversations.connect:write',
    description:
      'Create Slack Connect invitations for channels that your slack app has been added to, and accept invitations sent to your slack app',
  },
  'dnd:read': {
    name: 'dnd:read',
    description: 'View Do Not Disturb settings for people in a workspace',
  },
  'dnd:write': {
    name: 'dnd:write',
    description: 'Edit a user’s Do Not Disturb settings',
  },
  'dnd:write:user': {
    name: 'dnd:write:user',
    description: "Change the user's Do Not Disturb settings",
  },
  email: {
    name: 'email',
    description: 'View a user’s email address',
  },
  'emoji:read': {
    name: 'emoji:read',
    description: 'View custom emoji in a workspace',
  },
  'files:read': {
    name: 'files:read',
    description:
      'View files shared in channels and conversations that your slack app has been added to',
  },
  'files:write': {
    name: 'files:write',
    description: 'Upload, edit, and delete files as your slack app',
  },
  'files:write:user': {
    name: 'files:write:user',
    description: 'Upload, edit, and delete files as your slack app',
  },
  'groups:write': {
    name: 'groups:write',
    description:
      'Manage private channels that your Slack app has been added to and create new ones',
  },
  'groups:read': {
    name: 'groups:read',
    description:
      'View basic information about private channels that your slack app has been added to',
  },
  'groups:history': {
    name: 'groups:history',
    description:
      'View messages and other content in private channels that your slack app has been added to',
  },
  identify: {
    name: 'identify',
    description: 'View information about a user’s identity',
  },
  'identity.avatar': {
    name: 'identity.avatar',
    description: 'View a user’s Slack avatar',
  },
  'identity.avatar:read:user': {
    name: 'identity.avatar:read:user',
    description: "View the user's profile picture",
  },
  'identity.basic': {
    name: 'identity.basic',
    description: 'View information about a user’s identity',
  },
  'identity.email': {
    name: 'identity.email',
    description: 'View a user’s email address',
  },
  'identity.email:read:user': {
    name: 'identity.email:read:user',
    description: 'Workspace',
  },
  'identity.team': {
    name: 'identity.team',
    description: 'View a user’s Slack workspace name',
  },
  'identity.team:read:user': {
    name: 'identity.team:read:user',
    description: "View the workspace's name, domain, and icon",
  },
  'identity:read:user': {
    name: 'identity:read:user',
    description: 'Workspace',
  },
  'im:read': {
    name: 'im:read',
    description:
      'View basic information about direct messages that your slack app has been added to',
  },
  'im:write': {
    name: 'im:write',
    description: 'Start direct messages with people',
  },
  'im:history': {
    name: 'im:history',
    description:
      'View messages and other content in direct messages that your slack app has been added to',
  },
  'incoming-webhook': {
    name: 'incoming-webhook',
    description: 'Create one-way webhooks to post messages to a specific channel',
  },
  'links:read': {
    name: 'links:read',
    description: 'View URLs in messages',
  },
  'links:write': {
    name: 'links:write',
    description: 'Show previews of URLs in messages',
  },
  'mpim:read': {
    name: 'mpim:read',
    description:
      'View basic information about group direct messages that your slack app has been added to',
  },
  'mpim:write': {
    name: 'mpim:write',
    description: 'Start group direct messages with people',
  },
  'mpim:history': {
    name: 'mpim:history',
    description:
      'View messages and other content in group direct messages that your slack app has been added to',
  },
  none: {
    name: 'none',
    description: 'Execute methods without needing a scope',
  },
  openid: {
    name: 'openid',
    description: 'View information about a user’s identity',
  },
  'pins:read': {
    name: 'pins:read',
    description:
      'View pinned content in channels and conversations that your slack app has been added to',
  },
  'pins:write': {
    name: 'pins:write',
    description: 'Add and remove pinned messages and files',
  },
  profile: {
    name: 'profile',
    description: "View a user’s Slack avatar and Slack workspace's basic information",
  },
  'reactions:read': {
    name: 'reactions:read',
    description:
      'View emoji reactions and their associated content in channels and conversations that your slack app has been added to',
  },
  'reactions:write': {
    name: 'reactions:write',
    description: 'Add and edit emoji reactions',
  },
  'reminders:read': {
    name: 'reminders:read',
    description: 'View reminders created by your slack app',
  },
  'reminders:read:user': {
    name: 'reminders:read:user',
    description: 'Access reminders created by a user or for a user',
  },
  'reminders:write': {
    name: 'reminders:write',
    description: 'Add, remove, or mark reminders as complete',
  },
  'reminders:write:user': {
    name: 'reminders:write:user',
    description: 'Add, remove, or complete reminders for the user',
  },
  'remote_files:read': {
    name: 'remote_files:read',
    description: 'View remote files added by the app in a workspace',
  },
  'remote_files:share': {
    name: 'remote_files:share',
    description: 'Share remote files on a user’s behalf',
  },
  'remote_files:write': {
    name: 'remote_files:write',
    description: 'Add, edit, and delete remote files on a user’s behalf',
  },
  'search:read': {
    name: 'search:read',
    description: 'Search a workspace’s content',
  },
  'stars:read': {
    name: 'stars:read',
    description: 'View messages and files that your slack app has starred',
  },
  'stars:write': {
    name: 'stars:write',
    description: 'Add or remove stars',
  },
  'team:read': {
    name: 'team:read',
    description: 'view team information',
  },
  'tokens.basic': {
    name: 'tokens.basic',
    description: 'Execute methods without needing a scope',
  },
  'usergroups:read': {
    name: 'usergroups:read',
    description: 'View user groups in a workspace',
  },
  'usergroups:write': {
    name: 'usergroups:write',
    description: 'Create and manage user groups',
  },
  'users.profile:read': {
    name: 'users.profile:read',
    description: 'View profile details about people in a workspace',
  },
  'users.profile:write': {
    name: 'users.profile:write',
    description: 'Edit a user’s profile information and status',
  },
  'users.profile:write:user': {
    name: 'users.profile:write:user',
    description: "Change the user's profile fields",
  },
  'users:read.email': {
    name: 'users:read.email',
    description: 'View email addresses of people in a workspace',
  },
  'users:write': {
    name: 'users:write',
    description: 'Set presence for your slack app',
  },
  'users:read': {
    name: 'users:read',
    description: 'View people in the workspace',
  },
  'workflow.steps:execute': {
    name: 'workflow.steps:execute',
    description: 'Add steps that people can use in Workflow Builder',
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Slack',
  description: 'Access and send messages to a user’s Slack workspace.',
  authUrl: 'https://slack.com/oauth/v2/authorize',
  accessTokenUrl: 'https://slack.com/api/oauth.v2.access',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/slack`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
    {
      type: SidebarInputType.Switch,
      title: 'Request bot scopes only',
      id: 'requestBotScopesOnly',
    },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/slack',
};
