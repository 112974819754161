import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Pipedrive',
  description: 'Sync records with Pipedrive',
  authUrl:
    'https://pipedrive.readme.io/docs/marketplace-oauth-authorization#step-1-requesting-authorization',
  accessTokenUrl:
    'https://pipedrive.readme.io/docs/marketplace-oauth-authorization#step-4-and-step-5-getting-the-tokens',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/pipedrive`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: { notAllowed: true, defaultValues: ['_NO_SCOPE_ALLOWED_'], values: [] },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/pipedrive',
};
