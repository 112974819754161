import { indexBy } from '../../../utils';
import { Action, PersonaEntities } from '../../types';

export const initialState: PersonaEntities = {
  entities: {},
  errorMessage: undefined,
  processing: false,
  total: 0,
  usage: {
    allowedLimit: 0,
    connectedCount: 0,
  },
};

export function handler(state: PersonaEntities = initialState, action: Action): PersonaEntities {
  switch (action.type) {
    case 'PERSONA_ENTITY_RESET':
      return {
        ...state,
        entities: {},
      };

    case 'PERSONA_ENTITY_FETCH_START':
    case 'PERSONA_ENTITY_UPDATE_START':
    case 'PERSONA_WORKFLOW_LOG_FETCH_START':
    case 'PERSONA_INTEGRATIONS_FETCH_START':
    case 'PERSONA_WORKFLOW_DISABLE_START':
    case 'PERSONA_ENTITY_DISABLE_START':
    case 'PERSONA_ENTITY_ENABLE_START':
    case 'PERSONA_ENTITY_DELETE_START':
    case 'PERSONA_INTEGRATION_UNINSTALL_START':
    case 'PREVIEW_PERSONA_FETCH_START':
      return {
        ...state,
        processing: true,
      };

    case 'PERSONA_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          ...indexBy('id', action.payload.personas),
        },
        total: action.payload.total ?? state.total,
        processing: false,
        errorMessage: undefined,
      };

    case 'SET_PERSONA_ENTITY_USAGE':
      return {
        ...state,
        usage: action.payload,
      };

    case 'PERSONA_ENTITY_UPDATE_SUCCESS':
      return {
        ...state,
        processing: false,
        errorMessage: undefined,
        ...(action.isPreviewPersona
          ? { previewPersona: action.personaEntity }
          : {
              entities: {
                ...state.entities,
                [action.personaEntity.id]: {
                  ...state.entities[action.personaEntity.id],
                  ...action.personaEntity,
                },
              },
            }),
      };

    case 'PERSONA_ENTITY_DISABLE_SUCCESS':
    case 'PERSONA_ENTITY_ENABLE_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.personaEntity.id]: {
            ...state.entities[action.personaEntity.id],
            ...action.personaEntity,
          },
        },
        processing: false,
        errorMessage: undefined,
      };

    case 'PERSONA_WORKFLOW_LOG_FETCH_SUCCESS':
      const personaEntity = Object.values(state.entities).find(
        ({ endUserId }) => endUserId === action.payload.endUserId,
      );
      if (personaEntity) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [personaEntity.id]: { ...personaEntity, workflowLogs: action.payload.workflowLogs },
          },
          processing: false,
          errorMessage: undefined,
        };
      }
      return state;

    case 'PERSONA_INTEGRATIONS_FETCH_SUCCESS':
      const persona = Object.values(state.entities).find(
        ({ id }) => id === action.payload.personaId,
      );
      if (persona) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [persona.id]: {
              ...persona,
              connectCredentials: action.payload.connectCredentials,
            },
          },
          processing: false,
          errorMessage: undefined,
        };
      }
      return state;

    case 'PERSONA_WORKFLOW_DISABLE_SUCCESS':
      const updatedPersonas = Object.values(state.entities).map((persona) => {
        if (persona.id === action.payload.personaId) {
          return {
            ...persona,
            connectCredentials: persona.connectCredentials!.map((credential) => {
              if (credential.id === action.payload.connectCredential.id) {
                return action.payload.connectCredential;
              }
              return credential;
            }),
          };
        }
        return persona;
      });

      return {
        ...state,
        entities: indexBy('id', updatedPersonas),
        processing: false,
        errorMessage: undefined,
      };

    case 'PERSONA_INTEGRATION_UNINSTALL_SUCCESS':
      const personasWithUpdatedCredentials = Object.values(state.entities).map((persona) => {
        if (persona.id === action.payload.personaId) {
          return {
            ...persona,
            connectCredentials: persona.connectCredentials!.filter((credential) => {
              return credential.id !== action.payload.connectCredentialId;
            }),
          };
        }
        return persona;
      });

      return {
        ...state,
        entities: indexBy('id', personasWithUpdatedCredentials),
        processing: false,
        errorMessage: undefined,
      };

    case 'PERSONA_ENTITY_DELETE_SUCCESS':
      return {
        ...state,
        entities: indexBy(
          'id',
          Object.values(state.entities).filter(({ id }) => id !== action.personaId),
        ),
        processing: false,
        errorMessage: undefined,
      };

    case 'PERSONA_ENTITY_FETCH_FAILED':
    case 'PERSONA_ENTITY_UPDATE_FAILED':
    case 'PERSONA_WORKFLOW_LOG_FETCH_FAILED':
    case 'PERSONA_INTEGRATIONS_FETCH_FAILED':
    case 'PERSONA_WORKFLOW_DISABLE_FAILED':
    case 'PERSONA_ENTITY_DISABLE_FAILED':
    case 'PERSONA_ENTITY_ENABLE_FAILED':
    case 'PERSONA_ENTITY_DELETE_FAILED':
    case 'PERSONA_INTEGRATION_UNINSTALL_FAILED':
    case 'PREVIEW_PERSONA_FETCH_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;

    case 'PREVIEW_PERSONA_FETCH_SUCCESS':
      return {
        ...state,
        previewPersona: action.persona,
        processing: false,
        errorMessage: undefined,
      };

    default:
      return state;
  }
}
