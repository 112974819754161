import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedMapParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { MapStep, StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';
import { shouldAssertValue } from './validator.utils';

/**
 * validates that a map step is properly configured
 */
export default class MapStepValidator extends StepValidator<MapStep, ResolvedMapParameters> {
  stepType: MapStep['type'] = StepType.MAP;

  /**
   * validates that a step is valid
   *
   * @param {MapStep} step
   * @returns {Promise<MapStep>}
   * @memberof MapStepValidator
   */
  validate(
    step: MapStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona,
  ): MapStep {
    const { iterator }: ResolvedMapParameters = this.resolveVariables(
      step,
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
    );

    if (shouldAssertValue(step.parameters.iterator, iterator) && !Array.isArray(iterator)) {
      throw new Error('Fanout must be array for this step.');
    }

    return step;
  }
}
