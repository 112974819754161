import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { getBases, getTables, getViews } from './sources';

export const baseInput: SidebarInput = {
  id: 'baseId',
  type: SidebarInputType.DynamicEnum,
  title: 'Base',
  required: true,
  source: getBases,
  getValues: getValuesByCacheKey,
};

export const tableInput: SidebarInput = {
  id: 'tableName',
  type: SidebarInputType.DynamicEnum,
  title: 'Table Name',
  required: true,
  source: getTables,
  getValues: getValuesByCacheKey,
};

export const viewInput: SidebarInput = {
  id: 'viewName',
  type: SidebarInputType.DynamicEnum,
  title: 'View Name',
  subtitle: 'Choose a view of your table to filter records by.',
  required: true,
  source: getViews,
  getValues: getValuesByCacheKey,
};
