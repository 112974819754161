import { Action } from '@shared/types/sdk/actions';
import { ResolvedOAuthParameters } from '@shared/types/sdk/resolvers';
import { OAuthStep, StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';

/**
 * validates that an oauth step is properly configured
 */
export default class OAuthStepValidator extends StepValidator<OAuthStep, ResolvedOAuthParameters> {
  stepType: OAuthStep['type'] = StepType.OAUTH;

  /**
   * validates that a step is valid
   *
   * @param {OAuthStep} step
   * @returns {OAuthStep}
   * @memberof OAuthStepValidator
   */
  validate(step: OAuthStep): OAuthStep {
    const { actionType, credentials } = step.parameters;

    if (!actionType) {
      throw new Error('ActionType is not provided for oauth trigger');
    } else if (!Object.values(Action).find((action: string) => action === actionType)) {
      throw new Error(`${actionType} is not a valid action.`);
    } else if (!credentials.length) {
      throw new Error('No account connected for oauth trigger.');
    }

    return step;
  }
}
