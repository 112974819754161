import React from 'react';

import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  EnumSection,
  FieldMapperDataSource,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

const recordTypeListViewCacheKey = 'recordTypesForListView';

export const getRecordType: DynamicDataSource<EnumSection[]> = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  title: 'Record Type',
  cacheKey: 'recordTypes',
  refreshDependencies: [
    (parameters: ActionStepParameters) => parameters.credentials[0],
    (parameters: ActionStepParameters) => parameters.intent,
  ],
  mapRefreshToValues: (
    response: ActionResponse<[EnumSection, EnumSection]>,
  ): EnumSection<string>[] => {
    return response?.result.output ?? [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_RECORD_TYPES,
      actionType: Action.SALESFORCE,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getFields: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  title: 'Field',
  cacheKey: 'cachedFields',
  refreshDependencies: ['recordType'],
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const recordType =
      pickValueSourceByKey(options.actionParameters, 'recordType') ??
      // Fallback on cacheKey for getRecordType, the underlying data source
      pickValueSourceByKey(options.actionParameters, 'recordTypes');
    return {
      actionType: Action.SALESFORCE,
      intent: Intent.GET_RECORD_TYPE_FIELDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: recordType?.value,
          },
        },
        {
          key: 'getAll',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    };
  },
};

export const getOwners: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'owners',
  title: 'Account Owner',
  subtitle: 'Salesforce user that should be assigned to an account',
  mapRefreshToValues: (response: Record<string, any>): any => {
    return (
      (response?.result &&
        response.result.output?.records?.map((owner: Record<string, string>) => ({
          label: owner.Email,
          value: owner.Id,
        }))) ||
      []
    );
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.SALESFORCE,
      intent: Intent.WRITE_SOQL_QUERY,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'query',
          source: {
            type: 'VALUE',
            value: 'SELECT Id, Name, Email FROM User',
          },
        },
      ],
    };
  },
};

export const getLeadStatuses: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'leadStatuses',
  title: 'Lead Status',
  subtitle: 'Status that should be given to a lead',
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.SALESFORCE,
      intent: Intent.GET_FIELD_META_DATA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: 'Lead',
          },
        },
        {
          key: 'fieldName',
          source: {
            type: 'VALUE',
            value: 'Status',
          },
        },
      ],
    };
  },
};

export const getOpportunityStages: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'opportunityStages',
  title: 'Opportunity Stage',
  subtitle: 'Stage that an opportunity should be assigned to',
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
    return (response?.result && response.result.output) || [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.SALESFORCE,
      intent: Intent.GET_FIELD_META_DATA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: 'Opportunity',
          },
        },
        {
          key: 'fieldName',
          source: {
            type: 'VALUE',
            value: 'StageName',
          },
        },
      ],
    };
  },
};

export const mapSalesforceFields: FieldMapperDataSource = {
  id: 'customObjectMapping',
  type: DataSourceType.FIELD_MAPPER,
  title: 'Field Mapping',
  subtitle: 'Allows users to define a field mapping',
  instructionalText: (
    <>
      Let users define a field mapping. Add a label for each property that should be mapped to a
      Salesforce object field.{' '}
      <a
        target="_blank"
        href="https://docs.useparagon.com/v/connect/resources/integrations/salesforce#custom-objects"
        rel="noreferrer"
      >
        Read the docs
      </a>
    </>
  ),
  recordSource: getRecordType,
  fieldSource: getFields,
};

export const getCampaigns: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'campaign',
  title: 'Campaign',
  subtitle: 'Salesforce Campaign',
  supportPagination: true,
  mapRefreshToValues: (response: Record<string, any>): any => {
    return (
      (response?.result &&
        response.result.output?.records?.map((owner: Record<string, string>) => ({
          label: owner.Name,
          value: owner.Id,
        }))) ||
      []
    );
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.SALESFORCE,
      intent: Intent.GET_CAMPAIGNS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getRecordTypeForListView: DynamicDataSource<EnumSection[]> = {
  ...getRecordType,
  cacheKey: recordTypeListViewCacheKey,
};

export const getListViews: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'listView',
  title: 'List View',
  hideFromConnectFieldTypes: true,
  refreshDependencies: ['recordType'],
  mapRefreshToValues: (
    response: ActionResponse<{ label: string; id: string }[]>,
  ): EnumInputValue<string>[] => {
    return response?.result
      ? response.result.output.map((item) => ({ label: item.label, value: item.id }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const recordType =
      pickValueSourceByKey(options.actionParameters, 'recordType') ??
      // Fallback on cacheKey for getRecordType, the underlying data source
      pickValueSourceByKey(options.actionParameters, recordTypeListViewCacheKey);
    return {
      actionType: Action.SALESFORCE,
      intent: Intent.GET_LIST_VIEWS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: recordType?.value,
          },
        },
      ],
    };
  },
};

export const recordViewCombo: ComboInputDataSource = {
  type: DataSourceType.COMBO_INPUT,
  id: 'recordTypeViewCombo',
  title: 'List View',
  subtitle: 'A filtered view of records in Salesforce',
  mainInputSource: getRecordTypeForListView,
  dependentInputSource: getListViews,
};
