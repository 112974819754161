import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { WorkItemType, azureDevopsSupportedOperators } from '../shared';

export const projectInput: SidebarInput = {
  id: 'project',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Project',
  placeholder: '{{settings.project}}',
  subtitle:
    'Specify a project to create this work item for. Use Connect Portal Workflow Settings to allow your user to select a Project.',
};

export const projectFilterInput: SidebarInput = {
  id: 'projectFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for work items that match specified filters.',
  placeholder: 'field',
  supportedOperators: azureDevopsSupportedOperators,
  getValues: () => [
    { label: 'Activity', value: '[Microsoft.VSTS.Common.Activity]' },
    { label: 'Area Path', value: '[System.AreaPath]' },
    { label: 'Assigned To', value: '[System.AssignedTo]' },
    {
      label: 'Business Value',
      value: '[Microsoft.VSTS.Common.BusinessValue]',
    },
    { label: 'Changed Date', value: '[System.ChangedDate]' },
    { label: 'Created Date', value: '[System.CreatedDate]' },
    { label: 'Description', value: '[System.Description]' },
    { label: 'Effort', value: '[Microsoft.VSTS.Scheduling.Effort]' },
    { label: 'Iteration Path', value: '[System.IterationPath]' },
    { label: 'Priority', value: '[Microsoft.VSTS.Common.Priority]' },
    {
      label: 'Resolved Reason',
      value: '[Microsoft.VSTS.Common.ResolvedReason]',
    },
    { label: 'Risk', value: '[Microsoft.VSTS.Common.Risk]' },
    { label: 'Reason', value: '[System.Reason]' },
    {
      label: 'Start Date',
      value: '[Microsoft.VSTS.Scheduling.StartDate]',
    },
    {
      label: 'Story Points',
      value: '[Microsoft.VSTS.Scheduling.StoryPoints]',
    },
    { label: 'State', value: '[System.State]' },
    { label: 'Severity', value: '[Microsoft.VSTS.Common.Severity]' },
    { label: 'Tags', value: '[System.Tags]' },
    {
      label: 'Target Date',
      value: '[Microsoft.VSTS.Scheduling.TargetDate]',
    },
    { label: 'Team Project', value: '[System.TeamProject]' },
    { label: 'Title', value: '[System.Title]' },
    {
      label: 'Time Criticality',
      value: '[Microsoft.VSTS.Common.TimeCriticality]',
    },
    { label: 'Work Item Type', value: '[System.WorkItemType]' },
  ],
};

export const workItemTypeInput: SidebarInput = {
  id: 'workItemType',
  type: SidebarInputType.EditableEnum,
  placeholder: 'Type',
  title: 'Work Item Type',
  subtitle: 'Search for work items that match specified filters.',
  getValues: () => [
    { label: 'Bug', value: WorkItemType.BUG },
    { label: 'Epic', value: WorkItemType.EPIC },
    { label: 'Feature', value: WorkItemType.FEATURE },
    { label: 'Issue', value: WorkItemType.ISSUE },
    { label: 'Task', value: WorkItemType.TASK },
    { label: 'Test Case', value: WorkItemType.TEST_CASE },
    { label: 'User Story', value: WorkItemType.USER_STORY },
  ],
};

export const titleInput: SidebarInput = {
  id: 'title',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Title',
};

export const assigneeInput: SidebarInput = {
  id: 'assignee',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Assignee',
  required: false,
  subtitle: 'Specify a user that this work item should be assigned to.',
};

export const stateInput: SidebarInput = {
  id: 'state',
  type: SidebarInputType.EditableEnum,
  title: 'State',
  required: false,
  subtitle: 'The state of the created work item.',
  getValues: () => [
    { label: 'New', value: 'New' },
    { label: 'Active', value: 'Active' },
    { label: 'Resolved', value: 'Resolved' },
    { label: 'Closed', value: 'Closed' },
  ],
};

export const reasonInput: SidebarInput = {
  id: 'reason',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Reason',
  required: false,
  subtitle:
    'The reason for the created work item. This value may accept different values depending on the current State of the work item.',
};

export const areaInput: SidebarInput = {
  id: 'area',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Area',
  required: false,
  subtitle:
    'The area for the created work item. Use Connect Portal Workflow Settings to allow users to select an area path.',
};

export const iterationInput: SidebarInput = {
  id: 'iteration',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Iteration',
  required: false,
  subtitle:
    'The iteration for the created work item. Use Connect Portal Workflow Settings to allow users to select an iteration path.',
};

export const workItemIdInput: SidebarInput = {
  id: 'workItemId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Work Item ID',
  required: true,
  subtitle: 'The ID of the work item to update.',
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Additional Fields',
  required: false,
  subtitle: 'Specify any additional fields that should be created with this work item as JSON.',
  placeholder: `
{
  "Custom.customfield": "Value for custom field"
}`,
  useLightTheme: true,
};

export const reproStepsInput: SidebarInput = {
  id: 'reproSteps',
  type: SidebarInputType.TextArea,
  lines: 2,
  title: 'Repro Steps',
  required: false,
  subtitle: 'Description of how to see the bug.',
};

export const systemInfoInput: SidebarInput = {
  id: 'systemInfo',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'System Info',
  subtitle: 'Test context, can be automatically provided by test infrastructure.',
  lines: 2,
};

export const resolvedReasonInput: SidebarInput = {
  id: 'resolvedReason',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Resolved Reason',
  subtitle: 'The reason why the bug was resolved.',
  required: false,
};

export const storyPointsInput: SidebarInput = {
  id: 'storyPoints',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Story Points',
  subtitle: 'The size of work estimated for fixing the bug.',
  required: false,
};

export const priorityInput: SidebarInput = {
  id: 'priority',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Priority',
  lines: 1,
  subtitle: 'Business importance.',
};

export const editablePriorityDropdownInput: SidebarInput = {
  id: 'priority',
  type: SidebarInputType.EditableEnum,
  required: false,
  title: 'Priority',
  subtitle: 'Priority for completing the epic, based on business goals.',
  getValues: () => [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
  ],
};

export const severityInput: SidebarInput = {
  id: 'severity',
  type: SidebarInputType.EditableEnum,
  required: false,
  title: 'Severity',
  subtitle: 'An assessment of the effect of the bug on the project.',
  getValues: () => [
    { label: '1 - Critical', value: '1 - Critical' },
    { label: '2 - High', value: '2 - High' },
    { label: '3 - Medium', value: '3 - Medium' },
    { label: '4 - Low', value: '4 - Low' },
  ],
};

export const activityInput: SidebarInput = {
  id: 'activity',
  type: SidebarInputType.EditableEnum,
  required: false,
  title: 'Activity',
  subtitle: 'Type of work involved for this work item.',
  getValues: () => [
    { label: 'Deployment', value: 'Deployment' },
    { label: 'Design', value: 'Design' },
    { label: 'Development', value: 'Development' },
    { label: 'Documentation ', value: 'Documentation ' },
    { label: 'Requirements', value: 'Requirements' },
    { label: 'Testing  ', value: 'Testing  ' },
  ],
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  type: SidebarInputType.TextArea,
  lines: 2,
  title: 'Description',
  subtitle: 'Description or acceptance criteria for this epic to be considered complete.',
};

export const riskInput: SidebarInput = {
  id: 'risk',
  type: SidebarInputType.EditableEnum,
  title: 'Risk',
  required: false,
  subtitle: 'Uncertainty in epic.',
  getValues: () => [
    { label: '1 - High', value: '1' },
    { label: '2 - Medium', value: '2' },
    { label: '3 - Low', value: '3' },
  ],
};

export const effortInput: SidebarInput = {
  id: 'effort',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Effort',
  subtitle: 'The estimated effort to implemented the epic.',
};

export const businessValueInput: SidebarInput = {
  id: 'businessValue',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Business Value',
  subtitle: 'The business value for the customer when the epic is released.',
  required: false,
};

export const timeCriticalityInput: SidebarInput = {
  id: 'timeCriticality',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Time Criticality',
  subtitle:
    'How the business value of this epic decays over time. Higher values make the epic more time-critical.',
  required: false,
};

export const startDateInput: SidebarInput = {
  id: 'startDate',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Start Date',
  lines: 1,
  subtitle: 'The date to start the task.',
};

export const targetDateInput: SidebarInput = {
  id: 'targetDate',
  type: SidebarInputType.Text,
  required: false,
  title: 'Target Date',
  subtitle: 'The target date for completing the epic.',
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Work Items',
          items: [
            {
              value: Intent.SEARCH_WORKITEMS,
              label: 'Search Work Items',
            },

            {
              value: Intent.CREATE_WORKITEM,
              label: 'Create Work Item',
            },
            {
              value: Intent.UPDATE_WORKITEM,
              label: 'Update Work Item',
            },
            {
              value: Intent.DELETE_WORKITEM,
              label: 'Delete Work Item',
            },
          ],
        },
      ],
    },
  ],
};
