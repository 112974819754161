import React, { useEffect, useState } from 'react';
import Popup, { Props as PopupProps } from 'reactjs-popup';
import styled from 'styled-components';

import { COLORS, fadeInEntrance, popInEntrance } from '../utils/constants';

import Icon from './Icon';

export const ModalContainer = styled.div`
  border-radius: 3px;
  padding: 5px 0;
`;

export const MODAL_CONTENT_POPUP_STYLE: PopupProps['contentStyle'] = {
  animation: `${popInEntrance.getName()} 0.1s ease`,
  animationFillMode: 'forwards',
  width: '540px',
  minWidth: '540px',
  maxHeight: '100%',
  backgroundColor: 'none',
  borderRadius: '3px',
  padding: '0',
};

export const MODAL_OVERLAY_POPUP_STYLE: PopupProps['overlayStyle'] = {
  animation: `${fadeInEntrance.getName()} 0.1s ease`,
  animationFillMode: 'forwards',
};

export const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  border-top: 1px solid ${COLORS.BORDER};
  font-size: 11px;

  :first-of-type {
    border-top: 0;
  }
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
`;

export const ButtonGroupLeft = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0 30px;
  margin-bottom: 20px;
`;

export const ButtonGroupRight = styled(ButtonGroupLeft)`
  justify-content: flex-end;
`;

const Title = styled.h1`
  color: ${COLORS.TEXT};
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const Subtitle = styled.p`
  color: ${COLORS.GREY};
  font-size: 11px;
  font-weight: bold;
`;

type ModalHeaderProps = React.PropsWithChildren<{
  heading: string;
  subheading: string;
  iconSvg?: string;
}>;
export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  heading,
  subheading,
  iconSvg,
}: ModalHeaderProps) => {
  return (
    <ModalSection>
      <Subtitle>{subheading}</Subtitle>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          <Title>{heading}</Title>
        </div>
        {iconSvg && <Icon name="svg" src={iconSvg} width={30} height={30} />}
      </div>
      {children}
    </ModalSection>
  );
};

export type Props = {
  open?: boolean;
  children: Popup['props']['children'];
} & Omit<Popup['props'], 'open'>;
const Modal: React.FC<Props> = ({ children, open, ...popupProps }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setModalOpen(Boolean(open));
  }, [open]);

  return (
    <Popup
      {...popupProps}
      open={modalOpen}
      onClose={() => {
        if (popupProps.onClose) {
          popupProps.onClose();
        }
        setModalOpen(false);
      }}
      contentStyle={{ ...MODAL_CONTENT_POPUP_STYLE, ...popupProps.contentStyle }}
      overlayStyle={{ ...MODAL_OVERLAY_POPUP_STYLE, ...popupProps.overlayStyle }}
    >
      {children}
    </Popup>
  );
};

export default Modal;
