import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs';
import iconSvg from '../configs/icon.svg';

import { apiKeyInput, apiTokenInput } from './common';

const authConfig: ActionConfig = {
  actionType: Action.TRELLO,
  provider: ProviderType.TRELLO,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Trello account',
  icon: iconSvg,
  description: 'Add Trello to Paragon',
  supportsImpersonatedCredentialSource: true,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this Trello account',
          placeholder: 'eg. Production Testing etc.',
          type: SidebarInputType.ValueText,
        },
        apiKeyInput,
        apiTokenInput,
      ],
      buttons: [createTestConnectionButton(Action.TRELLO, Object.values(CredentialKeys))],
    },
  ],
};

export const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Trello account',
  placeholder: 'connect to Trello',
  type: SidebarInputType.Auth,
  config: authConfig,
};

export default authConfig;
