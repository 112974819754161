export default `<svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_601_2357" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="0" width="70" height="96">
<path d="M82 31.047C81.9999 28.9845 81.3621 26.9725 80.1739 25.2866C78.9857 23.6008 77.3053 22.3236 75.363 21.63L17.345 0.908961C14.74 -0.0210386 12 1.90896 12 4.67596V36.181C12 37.006 12.2552 37.8109 12.7305 38.4853C13.2058 39.1596 13.878 39.6705 14.655 39.948L40.655 49.234C43.26 50.164 46 48.233 46 45.467V27.377C46 27.0536 46.0784 26.7351 46.2284 26.4487C46.3785 26.1624 46.5957 25.9166 46.8616 25.7326C47.1274 25.5485 47.4339 25.4317 47.7548 25.3921C48.0757 25.3524 48.4014 25.3912 48.704 25.505L55.522 28.071C57.4253 28.7872 59.065 30.0667 60.2225 31.7389C61.3799 33.411 61.9999 35.3963 62 37.43V45.308L32.627 56.04C31.8568 56.3214 31.1918 56.8328 30.7219 57.5048C30.252 58.1768 30 58.977 30 59.797V91.28C30 94.058 32.763 95.99 35.373 95.037L75.432 80.4C77.3576 79.6964 79.0204 78.4179 80.1952 76.7378C81.37 75.0577 82.0001 73.0571 82 71.007V31.047V31.047Z" fill="white"/>
</mask>
<g mask="url(#mask0_601_2357)">
<path d="M12 -1L82 24V58.325C82 61.09 79.26 63.022 76.655 62.092L62 56.859V37.424C62 35.3894 61.3793 33.4033 60.221 31.7307C59.0626 30.058 57.4216 28.7786 55.517 28.063L48.703 25.503C48.4004 25.3894 48.0748 25.3508 47.7541 25.3906C47.4333 25.4303 47.127 25.5472 46.8613 25.7312C46.5956 25.9152 46.3784 26.1609 46.2284 26.4471C46.0784 26.7334 46.0001 27.0518 46 27.375V51.143L12 39V-1Z" fill="url(#paint0_linear_601_2357)"/>
<g filter="url(#filter0_f_601_2357)">
<path d="M82 31.3999C82 35.5999 79.376 39.3589 75.432 40.7999L30 57.3999V97.3999L82 78.3999V31.3999V31.3999Z" fill="black" fill-opacity="0.24"/>
</g>
<g filter="url(#filter1_f_601_2357)">
<path d="M82 33C82 37.2 79.376 40.959 75.432 42.4L30 59V99L82 80V33Z" fill="black" fill-opacity="0.32"/>
</g>
<path d="M82 31C82 35.2 79.376 38.959 75.432 40.4L30 57V97L82 78V31Z" fill="url(#paint1_linear_601_2357)"/>
<path opacity="0.5" d="M82 31C82 35.2 79.376 38.959 75.432 40.4L30 57V97L82 78V31Z" fill="url(#paint2_linear_601_2357)"/>
<path opacity="0.5" d="M62.0009 45.3201L45.9919 51.1771V74.6351C45.9919 74.9585 46.0703 75.2771 46.2204 75.5636C46.3705 75.8501 46.5878 76.096 46.8538 76.28C47.1198 76.4641 47.4264 76.5809 47.7474 76.6204C48.0684 76.66 48.3943 76.6211 48.6969 76.5071L55.5239 73.9371C57.4272 73.2206 59.0669 71.9409 60.2241 70.2685C61.3813 68.5962 62.0012 66.6108 62.0009 64.5771V45.3201V45.3201Z" fill="#B0ADFF"/>
</g>
<defs>
<filter id="filter0_f_601_2357" x="29.2" y="30.5999" width="53.6" height="67.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_601_2357"/>
</filter>
<filter id="filter1_f_601_2357" x="22" y="25" width="68" height="82" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_601_2357"/>
</filter>
<linearGradient id="paint0_linear_601_2357" x1="38.045" y1="-1" x2="56.658" y2="47.723" gradientUnits="userSpaceOnUse">
<stop stop-color="#0B53CE"/>
<stop offset="1" stop-color="#7252AA"/>
</linearGradient>
<linearGradient id="paint1_linear_601_2357" x1="64.138" y1="93.492" x2="64.138" y2="35.415" gradientUnits="userSpaceOnUse">
<stop stop-color="#2266E3"/>
<stop offset="1" stop-color="#AE7FE2"/>
</linearGradient>
<linearGradient id="paint2_linear_601_2357" x1="82" y1="56.786" x2="62.076" y2="56.786" gradientUnits="userSpaceOnUse">
<stop stop-color="#94B9FF"/>
<stop offset="0.288" stop-color="#94B9FF" stop-opacity="0.524"/>
<stop offset="1" stop-color="#538FFF" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>`;
