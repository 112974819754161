import axios, { AxiosError, AxiosInstance } from 'axios';
import moment from 'moment';

import {
  addProtocolInSubDomain,
  generateQueryStringFromObject,
  getArrayOfStringsFromCommaSeparatedList,
  parseCodeInput,
} from '@shared/actions/sdk/utils';
import { SidebarInput } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import searchRecords from '../backend/searchRecords';
import { Credentials } from '../configs';
import { inputsForDefaultEntitiesConnect } from '../frontend/inputs';

import { DYNAMICS_API_BASE_URL, DYNAMICS_DOMAIN } from './constants';
import {
  AssociationProperty,
  CreateRecordDTO,
  EntityInputs,
  Environment,
  MD_ENTITY_SPECIFIED_BY_OBJECT_NAME,
  MdDefaultEntities,
  ParticipationTypeMask,
  PartyActivityObject,
  TokenExchangeResponse,
  defaultEntitiesMetadata,
} from './types';

export const MICROSOFT_AUTH_URL = 'https://login.microsoftonline.com';

export const getRequiredScopes = (subdomain: string): string => {
  const defaultScopes = 'openid email profile offline_access';
  const impersonationScope =
    addProtocolInSubDomain(subdomain, DYNAMICS_DOMAIN) + DYNAMICS_DOMAIN + '/user_impersonation';
  return defaultScopes + ' ' + impersonationScope;
};

export function isAxiosError(error: Error | AxiosError): error is AxiosError {
  return (error as AxiosError).response !== undefined;
}

export const getDynamicsError = (err: Error | AxiosError): string => {
  return `Microsoft Dynamics API error: ${
    isAxiosError(err) ? JSON.stringify(err.response?.data) : err.message
  }`;
};

export const getApiBaseUrl = (subdomain: string) => {
  return subdomain + DYNAMICS_API_BASE_URL;
};

export const getApiClient = (
  baseUrl: string,
  accessToken: string,
  headers: Record<string, string> = {},
): AxiosInstance => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'OData-Version': '4.0',
      'OData-MaxVersion': '4.0',
      ...headers,
    },
  });
};

/**
 * @summary checks if accessToken is valid by making a get request for schema
 * @param accessToken
 * @returns boolean
 */
export const isAccessTokenValid = async (
  subdomain: string,
  accessToken: string,
): Promise<boolean> => {
  try {
    await getApiClient(getApiBaseUrl(subdomain), accessToken).get(
      `/EntityDefinitions(LogicalName='lead')`,
    );
    return true;
  } catch (accessTokenError) {
    return false;
  }
};

/**
 * return newly generated access token for Microsoft Dynamics from refresh token.
 * @param credentials
 * @param environment
 */
export async function getAccessTokenFromRefreshToken(
  credentials: Credentials,
  environment: Environment,
): Promise<TokenExchangeResponse> {
  try {
    if (
      await isAccessTokenValid(
        credentials.MICROSOFT_DYNAMICS_SUB_DOMAIN,
        credentials.MICROSOFT_DYNAMICS_ACCESS_TOKEN,
      )
    ) {
      return {
        accessToken: credentials.MICROSOFT_DYNAMICS_ACCESS_TOKEN,
        refreshToken: credentials.MICROSOFT_DYNAMICS_REFRESH_TOKEN,
        updateCredentialValues: undefined,
      };
    }

    const response = await axios.post(
      `${MICROSOFT_AUTH_URL}/organizations/oauth2/v2.0/token`,
      generateQueryStringFromObject(
        {
          grant_type: 'refresh_token',
          client_id: environment.MICROSOFT_DYNAMICS_CLIENT_ID,
          client_secret: environment.MICROSOFT_DYNAMICS_CLIENT_SECRET,
          refresh_token: credentials.MICROSOFT_DYNAMICS_REFRESH_TOKEN,
        },
        {
          urlencodedValues: true,
          addPrefix: false,
        },
      ),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    if (!response.data.access_token) {
      throw new Error('Microsoft Dynamics Authentication Error');
    }
    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;

    // updating both refresh and accessToken if a token exchange has happened .
    // Microsoft API provides both updated refresh tokens and accessTokens on token exchange.
    const updateCredentialValues = {
      ...credentials,
      MICROSOFT_DYNAMICS_ACCESS_TOKEN: accessToken,
      MICROSOFT_DYNAMICS_REFRESH_TOKEN: refreshToken,
    };

    return { accessToken, refreshToken, updateCredentialValues };
  } catch (err) {
    throw err;
  }
}

export const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.StringContains,
  Operator.NumberLessThan,
  Operator.DateTimeBefore,
  Operator.NumberEquals,
  Operator.DateTimeEquals,
  Operator.NumberGreaterThan,
  Operator.DateTimeAfter,
  Operator.NumberDoesNotEqual,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberLessThanOrEqualTo,
  Operator.BooleanTrue,
  Operator.BooleanFalse,
];

/**
 * @summary helper fn to extract entity name which can be either default entity or custom entity
 * and returns api resources strings.
 * @param entityInputs
 * @returns api resources
 */
export const pickEntityName = (entityInputs: EntityInputs): string =>
  entityInputs.mdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
    ? JSON.parse(entityInputs.customMdEntityName!).entitySetName
    : defaultEntitiesMetadata[entityInputs.mdEntity].entitySetName;

export const getEntityLogicalName = (entityInputs: EntityInputs): string =>
  entityInputs.mdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
    ? JSON.parse(entityInputs.customMdEntityName!).logicalName
    : defaultEntitiesMetadata[entityInputs.mdEntity].logicalName;

export const preparePayload = (dto: CreateRecordDTO): Record<string, any> => {
  const additionalFields = parseCodeInput(dto.additionalFields);

  let payload: Record<string, any> = {};
  if (dto.mdEntity !== MD_ENTITY_SPECIFIED_BY_OBJECT_NAME) {
    const inputsForRecordType: string[] = inputsForDefaultEntitiesConnect[dto.mdEntity].map(
      (input: SidebarInput) => input.id,
    );

    payload = inputsForRecordType
      .filter((inputId: string) => dto[inputId])
      .reduce((accumulator: Record<string, any>, inputId: string) => {
        accumulator[inputId] = inputId === 'estimatedvalue' ? parseInt(dto[inputId]) : dto[inputId];
        return accumulator;
      }, {});

    delete payload.additionalFields;

    // if initial payload has directioncode property then, we add it to payload by checking if record type
    // contains same directioncode
    payload =
      dto.hasOwnProperty('directioncode') && inputsForRecordType.includes('directioncode')
        ? { ...payload, directioncode: dto['directioncode'] }
        : payload;

    // handling association property
    defaultEntitiesMetadata[dto.mdEntity].associationProperties.forEach(
      (associationProperty: string) => {
        if (dto[associationProperty] === undefined) {
          return;
        }
        delete payload[associationProperty];

        const associationPropertyParsed = parseCodeInput(
          dto[associationProperty],
        ) as AssociationProperty;

        payload[
          associationProperty + '@odata.bind'
        ] = `/${associationPropertyParsed.entitySetName}(${associationPropertyParsed.id})`;
      },
    );

    // handling association property with logical name suffix
    defaultEntitiesMetadata[dto.mdEntity].logicalNameSuffixedAssociationProperties?.forEach(
      (associationProperty: string) => {
        if (!dto[associationProperty]) {
          return;
        }
        delete payload[associationProperty];
        let associationPropertyParsed: Partial<AssociationProperty>;
        // checking if input is object or json string with logicalName property
        if (
          typeof dto[associationProperty] === 'object' ||
          dto[associationProperty].includes('logicalName')
        ) {
          associationPropertyParsed = parseCodeInput(
            dto[associationProperty],
          ) as AssociationProperty;
          associationPropertyParsed = associationPropertyParsed.hasOwnProperty('logicalName')
            ? associationPropertyParsed
            : inferRecordTypeFromObject(associationPropertyParsed);
        } else {
          associationPropertyParsed = getFormattedValueForLookupField(dto[associationProperty]);
        }

        payload[
          `${associationProperty}_${associationPropertyParsed.logicalName}@odata.bind`
        ] = `/${associationPropertyParsed.entitySetName}(${associationPropertyParsed.id})`;
      },
    );

    // handling property with type party activity
    const activityPartyPropertyParsed = defaultEntitiesMetadata[
      dto.mdEntity
    ].activityPartyProperties
      ?.reduce((accumulator: PartyActivityObject[][], associationProperty: string) => {
        if (dto[associationProperty]) {
          delete payload[associationProperty];

          const parsedPartyActivityObjects = getPartyActivityObjects(
            dto[associationProperty],
            associationProperty,
          );

          return [...accumulator, parsedPartyActivityObjects];
        } else {
          return accumulator;
        }
      }, [])
      .flat(1);

    if (activityPartyPropertyParsed?.length) {
      payload[`${defaultEntitiesMetadata[dto.mdEntity].logicalName}_activity_parties`] =
        activityPartyPropertyParsed;
    }
  }

  payload = { ...payload, ...additionalFields };

  return payload;
};

/**
 * @summary this function set default values according to selected record types
 * @param entityInputs
 * @param isUpdating
 * @param credentials
 * @param environment
 * @returns updated dto with default values added
 */
export const setDefaultValues = async (
  entityInputs: EntityInputs & Record<string, any>,
  isUpdating: boolean,
  credentials: Credentials,
  environment: Environment,
) => {
  switch (entityInputs.mdEntity) {
    case 'Call':
      return isUpdating
        ? {
            ...entityInputs,
            ...(entityInputs.hasOwnProperty('directioncode')
              ? { directioncode: entityInputs.directioncode === '1' }
              : {}),
          }
        : {
            ...entityInputs,
            from: entityInputs.from || (await currentUserOwnerId(credentials, environment)),
            directioncode: entityInputs.hasOwnProperty('directioncode')
              ? entityInputs.directioncode === '1'
              : true,
            scheduledend: entityInputs.scheduledend || moment(8, 'HH').format(),
            actualdurationminutes: entityInputs.actualdurationminutes || '30',
          };

    case 'Meeting':
      return {
        ...entityInputs,
        requiredattendees: entityInputs.requiredattendees
          ? getArrayOfStringsFromCommaSeparatedList(entityInputs.requiredattendees)
          : undefined,
      };

    case 'Task':
      return isUpdating
        ? entityInputs
        : {
            ...entityInputs,
            ownerid: entityInputs.ownerid || (await currentUserOwnerId(credentials, environment)),
            scheduledend: entityInputs.scheduledend || moment(8, 'HH').format(),
          };
    default:
      return entityInputs;
  }
};

/**
 * @summary function return currently authenticated user's owner id
 * @param credentials
 * @param environment
 * @returns current user owner id
 */
export const currentUserOwnerId = async (
  credentials: Credentials,
  environment: Environment,
): Promise<string> => {
  const { data: systemUsers } = await searchRecords(
    {
      mdEntity: MD_ENTITY_SPECIFIED_BY_OBJECT_NAME,
      customMdEntityName: `{"entitySetName":"systemusers"}`,
    },
    credentials,
    environment,
  );

  const systemId = systemUsers?.find(
    (data) => data.azureactivedirectoryobjectid == credentials.userAccountId,
  )!.systemuserid;

  return JSON.stringify({
    id: systemId,
    entitySetName: 'systemusers',
    logicalName: 'systemuser',
  });
};

/**
 * @summary this function return formatted party activity objects for preparing request dto, user can
 * send either whole record object, or string in this format /systemusers(1d9e0de7-4fb2-ec11-9840-0022480b1bdf
 * or a obejct containing id,logiccalName,entitySetName
 * @param partyActivityProperty
 * @param associationProperty
 * @returns party activity object for party activity types property e.g.[to,from...]
 */
export const getPartyActivityObjects = (
  partyActivityProperty: string | Partial<AssociationProperty | string>[] | Record<string, any>,
  associationProperty: string,
): PartyActivityObject[] => {
  if (Array.isArray(partyActivityProperty)) {
    return partyActivityProperty.map(
      (data: Partial<AssociationProperty> | string | Record<string, any>) => {
        if (typeof data === 'object') {
          data = data.hasOwnProperty('logicalName') ? data : inferRecordTypeFromObject(data);
          return {
            [`partyid_${data.logicalName}@odata.bind`]: `/${data.entitySetName}(${data.id})`,
            participationtypemask: ParticipationTypeMask[associationProperty],
          };
        } else {
          const partyActivityObject = getFormattedValueForLookupField(data);
          return {
            [`partyid_${partyActivityObject.logicalName}@odata.bind`]: `/${partyActivityObject.entitySetName}(${partyActivityObject.id})`,
            participationtypemask: ParticipationTypeMask[associationProperty],
          };
        }
      },
    );
  } else if (
    typeof partyActivityProperty === 'object' ||
    partyActivityProperty.includes('logicalName')
  ) {
    let parsedProperties = parseCodeInput(partyActivityProperty) as Partial<AssociationProperty>;
    parsedProperties = parsedProperties.hasOwnProperty('logicalName')
      ? parsedProperties
      : inferRecordTypeFromObject(parsedProperties);

    return [
      {
        [`partyid_${parsedProperties.logicalName}@odata.bind`]: `/${parsedProperties.entitySetName}(${parsedProperties.id})`,
        participationtypemask: ParticipationTypeMask[associationProperty],
      },
    ];
  } else {
    const partyActivityObject = getFormattedValueForLookupField(partyActivityProperty);
    return [
      {
        [`partyid_${partyActivityObject.logicalName}@odata.bind`]: `/${partyActivityObject.entitySetName}(${partyActivityObject.id})`,
        participationtypemask: ParticipationTypeMask[associationProperty],
      },
    ];
  }
};

/**
 * @summary gives formatted object value for fields like For,Regarding,Attendees when values
 * are in this format /systemusers(1d9e0de7-4fb2-ec11-9840-0022480b1bdf, if input value is
 * not in right format then throwing error
 * @param value
 * @param associationProperty
 * @returns formatted associationproperty object for fields
 */
export const getFormattedValueForLookupField = (value: string): Partial<AssociationProperty> => {
  //regex for checking if input value is right formatted "/<record-entity>(<id>)"
  const regex = /\/.*\(.*\)/i;

  const entitySetNames = [
    defaultEntitiesMetadata[MdDefaultEntities.Account],
    defaultEntitiesMetadata[MdDefaultEntities.Opportunity],
    defaultEntitiesMetadata[MdDefaultEntities.Lead],
    defaultEntitiesMetadata[MdDefaultEntities.Contact],
    {
      entitySetName: 'systemusers',
      logicalName: 'systemuser',
    },
  ];
  const selectedEntitySetName = entitySetNames.find((entity) =>
    value.includes(entity.entitySetName),
  );

  if (selectedEntitySetName && regex.test(value)) {
    const entitySetName = selectedEntitySetName.entitySetName;
    const logicalName = selectedEntitySetName.logicalName;
    const id = value.split('(').pop()!.split(')')[0];

    return { id, logicalName, entitySetName };
  } else {
    throw new Error(
      `${value} is not a valid lookup reference. If an ID was selected, use the full record object instead.`,
    );
  }
};

/**
 * @summary this function return id,logicalName,entityName from object by looking
 * for which type of record it is, this function will handle opportunity,account,lead,contact record objects
 * Applies to From, To, and Regarding fields.
 * @param value
 * @returns association property for dto request
 */
export const inferRecordTypeFromObject = (
  value: Record<string, any>,
): Partial<AssociationProperty> => {
  if (value.opportunityid) {
    return {
      id: value.opportunityid,
      logicalName: defaultEntitiesMetadata[MdDefaultEntities.Opportunity].logicalName,
      entitySetName: defaultEntitiesMetadata[MdDefaultEntities.Opportunity].entitySetName,
    };
  } else if (value.accountid) {
    return {
      id: value.accountid,
      logicalName: defaultEntitiesMetadata[MdDefaultEntities.Account].logicalName,
      entitySetName: defaultEntitiesMetadata[MdDefaultEntities.Account].entitySetName,
    };
  } else if (value.leadid) {
    return {
      id: value.leadid,
      logicalName: defaultEntitiesMetadata[MdDefaultEntities.Lead].logicalName,
      entitySetName: defaultEntitiesMetadata[MdDefaultEntities.Lead].entitySetName,
    };
  } else {
    return {
      id: value.contactid,
      logicalName: defaultEntitiesMetadata[MdDefaultEntities.Contact].logicalName,
      entitySetName: defaultEntitiesMetadata[MdDefaultEntities.Contact].entitySetName,
    };
  }
};
