import moment from 'moment';

import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import {
  Condition,
  ConditionWrapper,
  Operator,
  ResolvedCondition,
  ResolvedConditionWrapper,
  ResolvedConditionalParameters,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import { Choice, ConditionalStep, StepType } from '@shared/types/sdk/steps';

import { ConditionalStepResolver } from '../resolvers';
import { isNumberType } from '../workflow.utils';

import { StepValidator } from './abstract.validator';
import { isEmptySource } from './validator.utils';

/**
 * validates that a conditional step is properly configured
 *
 * @export
 * @class ConditionalStepValidator
 * @extends {StepValidator<ConditionalStep, ResolvedConditionalParameters>}
 */
export default class ConditionalStepValidator extends StepValidator<
  ConditionalStep,
  ResolvedConditionalParameters
> {
  stepType: ConditionalStep['type'] = StepType.IFELSE;

  /**
   * validates that a step is valid
   *
   * @param {ConditionalStep} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ConditionalStep}
   * @memberof ConditionalStepValidator
   */
  validate(
    step: ConditionalStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ConditionalStep {
    step.parameters.choices.forEach((choice: Choice) => {
      if (!choice.conditionWrapper) {
        return;
      }

      // This validates the unresolved `Source` types
      this.validateConditionWrapper(choice.conditionWrapper);

      // This validates the resolved values for each choice
      const resolvedConditionWrapper: ResolvedConditionWrapper = (
        this.resolver as ConditionalStepResolver
      ).resolveChoiceVariables(
        choice.conditionWrapper,
        secrets,
        cachedConnectCredential,
        variables,
        context,
        cachedPersona,
      );
      this.validateResolvedConditionalWrapper(resolvedConditionWrapper);
    });

    return step;
  }

  validateConditionWrapper(conditionWrapper: ConditionWrapper): void {
    switch (conditionWrapper.type) {
      case 'JOIN':
        if (conditionWrapper.join === 'OR') {
          conditionWrapper.conditions.forEach((condition: ConditionWrapper) =>
            this.validateConditionWrapper(condition),
          );
        }
        conditionWrapper.conditions.forEach((condition: ConditionWrapper) =>
          this.validateConditionWrapper(condition),
        );
        break;
      case 'OPERATOR':
        this.validateCondition(conditionWrapper.condition);
        break;
    }
  }

  validateCondition(condition: Condition): void {
    switch (condition.operator) {
      case Operator.None:
        throw new Error('No operator type specified for this step.');
      case Operator.StringContains:
      case Operator.StringDoesNotContain:
      case Operator.StringExactlyMatches:
      case Operator.StringDoesNotExactlyMatch:
      case Operator.StringIsIn:
      case Operator.StringIsNotIn:
      case Operator.StringStartsWith:
      case Operator.StringDoesNotStartWith:
      case Operator.StringEndsWith:
      case Operator.StringDoesNotEndWith:
      case Operator.StringGreaterThan:
      case Operator.StringLessThan:
      case Operator.NumberGreaterThan:
      case Operator.NumberLessThan:
      case Operator.NumberGreaterThanOrEqualTo:
      case Operator.NumberLessThanOrEqualTo:
      case Operator.NumberEquals:
      case Operator.NumberDoesNotEqual:
      case Operator.DateTimeAfter:
      case Operator.DateTimeBefore:
      case Operator.DateTimeEquals:
        if (isEmptySource(condition.variable)) {
          throw new Error('You must select a left-hand variable for this condition.');
        } else if (isEmptySource(condition.argument)) {
          throw new Error('You must select a right-hand variable for this condition.');
        }
        break;
      case Operator.BooleanTrue:
      case Operator.BooleanFalse:
      case Operator.Exists:
      case Operator.DoesNotExist:
      case Operator.IsNull:
      case Operator.IsNotNull:
        if (isEmptySource(condition.variable)) {
          throw new Error('You must select a left-hand variable for this condition.');
        }
        break;
    }
  }

  validateResolvedCondition(condition: ResolvedCondition): void {
    switch (condition.operator) {
      case Operator.None:
        throw new Error('No operator type specified for this step.');
      case Operator.StringContains:
      case Operator.StringDoesNotContain:
      case Operator.StringExactlyMatches:
      case Operator.StringDoesNotExactlyMatch:
      case Operator.StringIsIn:
      case Operator.StringIsNotIn:
      case Operator.StringStartsWith:
      case Operator.StringDoesNotStartWith:
      case Operator.StringEndsWith:
      case Operator.StringDoesNotEndWith:
      case Operator.StringGreaterThan:
      case Operator.StringLessThan:
        if (
          !isEmptySource(condition.variable) &&
          !isEmptySource(condition.argument) &&
          (typeof condition.variable !== 'string' || typeof condition.argument !== 'string')
        ) {
          throw new Error('You can not perform string operation on non-string data type.');
        }
        break;
      case Operator.BooleanTrue:
      case Operator.BooleanFalse:
      case Operator.Exists:
      case Operator.DoesNotExist:
      case Operator.IsNull:
      case Operator.IsNotNull:
        break;
      case Operator.NumberGreaterThan:
      case Operator.NumberLessThan:
      case Operator.NumberGreaterThanOrEqualTo:
      case Operator.NumberLessThanOrEqualTo:
      case Operator.NumberEquals:
      case Operator.NumberDoesNotEqual:
        if (
          !isEmptySource(condition.variable) &&
          !isEmptySource(condition.argument) &&
          (!isNumberType(condition.variable) || !isNumberType(condition.argument))
        ) {
          throw new Error('You can not perform numeric operation on non-number data type.');
        }
        break;
      case Operator.DateTimeAfter:
      case Operator.DateTimeBefore:
      case Operator.DateTimeEquals:
        if (
          !isEmptySource(condition.variable) &&
          !isEmptySource(condition.argument) &&
          (!moment(condition.variable).isValid() || !moment(condition.argument).isValid())
        ) {
          throw new Error('Please provide a valid date in this step.');
        }
        break;
    }
  }

  validateResolvedConditionalWrapper(conditionWrapper: ResolvedConditionWrapper) {
    switch (conditionWrapper.type) {
      case 'JOIN':
        if (conditionWrapper.join === 'OR') {
          conditionWrapper.conditions.forEach((condition: ResolvedConditionWrapper) =>
            this.validateResolvedConditionalWrapper(condition),
          );
        }
        conditionWrapper.conditions.forEach((condition: ResolvedConditionWrapper) =>
          this.validateResolvedConditionalWrapper(condition),
        );
        break;
      case 'OPERATOR':
        this.validateResolvedCondition(conditionWrapper.condition);
        break;
    }
  }
}
