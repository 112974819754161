import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  ticketChannelName,
  ticketDescriptionInput,
  ticketIdInput,
  ticketNameInput,
  ticketNote,
  ticketReferredTypeInput,
  ticketRelatedPartyIdInput,
  ticketSeverityInput,
  ticketShortDescriptionInput,
  ticketStatusInput,
  ticketTypeInput,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.SERVICENOW,
  name: 'ServiceNow',
  description: "Connect to your users' ServiceNow accounts",
  icon: iconSvg,
  provider: ProviderType.SERVICENOW,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              title: 'Tickets',
              items: [
                {
                  value: Intent.SERVICENOW_GET_TICKET_BY_ID,
                  label: 'Get Ticket by ID',
                },
                {
                  value: Intent.SERVICENOW_CREATE_TICKET,
                  label: 'Create Ticket',
                },
                {
                  value: Intent.SERVICENOW_UPDATE_TICKET,
                  label: 'Update Ticket',
                },
              ],
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SERVICENOW_GET_TICKET_BY_ID:
          return {
            inputs: [ticketIdInput, ticketTypeInput],
          };
        case Intent.SERVICENOW_CREATE_TICKET:
        case Intent.SERVICENOW_UPDATE_TICKET:
          return {
            inputs: [
              ...(parameters.intent === Intent.SERVICENOW_UPDATE_TICKET ? [ticketIdInput] : []),
              ticketTypeInput,
              ticketDescriptionInput,
              ticketSeverityInput,
              ticketNameInput,
              ticketShortDescriptionInput,
              ticketStatusInput,
              ticketRelatedPartyIdInput,
              ticketReferredTypeInput,
              ticketChannelName,
              ticketNote,
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
