import { PLATFORM_ENV } from '@shared/types/sdk/environment';
import { getPlatformEnv, isBrowser } from '@shared/types/sdk/environment/utils';

export enum LEVEL {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  FATAL = 'fatal',
}

export const levels: LEVEL[] = [
  LEVEL.TRACE,
  LEVEL.DEBUG,
  LEVEL.INFO,
  LEVEL.WARN,
  LEVEL.ERROR,
  LEVEL.FATAL,
];

export type SubscriptionHandler = {
  (message: string, ...args: any[]): void;
};

export type Subscription = {
  id: string;
  handler: SubscriptionHandler;
};

export type SubscriptionLedger = Record<LEVEL, Record<string, Subscription>>;

type Environment = Record<string, string>;
let env: Environment = { ...(process.env as Environment) };

export function setEnv(obj: Environment): void {
  env = {
    ...obj,
  };
}

export function getEnv(): Environment {
  return env;
}

export function isError(e: any): boolean {
  return e && e.stack && e.message;
}

export function shouldLog(level: LEVEL): boolean {
  const platformEnv: PLATFORM_ENV = (env.PLATFORM_ENV as PLATFORM_ENV) || getPlatformEnv();
  let { LOG_LEVEL } = env;
  if (!LOG_LEVEL) {
    LOG_LEVEL = platformEnv === PLATFORM_ENV.ENTERPRISE ? LEVEL.INFO : LEVEL.TRACE;
  }

  const index: number = levels.findIndex((l: LEVEL) => l === level);
  const globalLogLevelIndex: number = levels.findIndex((l: LEVEL) => l === LOG_LEVEL);

  if (
    [PLATFORM_ENV.PRODUCTION, PLATFORM_ENV.ENTERPRISE].includes(platformEnv) &&
    (isBrowser() || env.browser)
  ) {
    return false;
  }

  return index === -1 || index >= globalLogLevelIndex ? true : false;
}
