import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

export const accountIdInput: SidebarInput = {
  id: 'accountId',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Account ID',
  subtitle: 'ID of the Account used to make the payment.',
};

export const linkedInvoiceInput: SidebarInput = {
  id: 'linkedInvoice',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Linked invoice',
  subtitle: 'Applies this payment to an existing invoice in Xero.',
};

export const totalAmountInput: SidebarInput = {
  id: 'totalAmount',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Total amount',
  placeholder: '25.0',
};

export const invoiceIdInput: SidebarInput = {
  id: 'invoiceId',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Invoice ID',
  subtitle: 'The ID of the invoice to send.',
};

export const sendToEmailInput: SidebarInput = {
  id: 'sendToEmail',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Send to email address',
  subtitle:
    'Updates the invoice’s billing email address to the email provided. If left blank, sends to the invoice’s existing billing email address.',
  placeholder: 'name@example.com',
};

export const invoiceStatusInput: SidebarInput = {
  id: 'invoiceStatus',
  type: SidebarInputType.Enum,
  required: true,
  title: 'Status',
  getValues: () => [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Submitted', value: 'SUBMITTED' },
    { label: 'Authorised', value: 'AUTHORISED' },
  ],
};

export const invoiceTypeInput: SidebarInput = {
  id: 'invoiceType',
  type: SidebarInputType.Enum,
  required: true,
  title: 'Invoice Type',
  getValues: () => [
    { label: 'Bill', value: 'ACCPAY' },
    { label: 'Sales Invoice', value: 'ACCREC' },
  ],
  defaultValue: 'ACCREC',
};

export const contactInput: SidebarInput = {
  id: 'contactId',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Contact',
  subtitle: 'The customer this invoice is associated with.',
  placeholder: 'Contact ID',
  lines: 1,
};

export const lineItemsInput: SidebarInput = {
  id: 'lineItems',
  type: SidebarInputType.Code,
  required: true,
  lines: 4,
  title: 'Line items',
  subtitle:
    'Enter an array of Line items including the description, quantity, and amount for each Line item.',
  language: 'json',
  placeholder: `[
  {
    "Description": "White T-Shirt", 
    "Quantity": "2",
    "UnitAmount": "20.00",
    "AccountCode": "200"
  }
]  
  `,
  useLightTheme: true,
};

export const dateInput: SidebarInput = {
  id: 'date',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Date',
  subtitle: 'Date the invoice was issued.',
  placeholder: 'YYYY-MM-DD',
  required: false,
};

export const dueDateInput: SidebarInput = {
  id: 'dueDate',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Due Date',
  subtitle: 'Date the invoice is due.',
  placeholder: 'YYYY-MM-DD',
  required: false,
};

export const currencyConnectInput: SidebarInput = {
  id: 'currency',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Currency',
  lines: 1,
  placeholder: 'USD',
};

export const invoiceNumberInput: SidebarInput = {
  id: 'invoiceNumber',
  type: SidebarInputType.Text,
  required: false,
  title: 'Invoice Number',
  subtitle: 'Alpha numeric code identifying the invoice',
  placeholder: 'OIT00546',
};

export const referenceInput: SidebarInput = {
  id: 'reference',
  type: SidebarInputType.Text,
  required: false,
  title: 'Reference',
};

export const customerIdInput: SidebarInput = {
  id: 'customerId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Customer ID',
  subtitle: 'The ID of the customer to update.',
  placeholder: 'Customer ID',
};

export const nameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  subtitle: 'Full name of contact/organization',
};

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'First name',
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Last name',
};

export const emailInput: SidebarInput = {
  id: 'email',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Email address',
  required: false,
};

export const phoneInput: SidebarInput = {
  id: 'phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Phone number',
  required: false,
};

export const accountNumberInput: SidebarInput = {
  id: 'accountNumber',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Account number',
  required: false,
};

export const addressLine1Input: SidebarInput = {
  id: 'addressLine1',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Billing street address',
  required: false,
};

export const addressLine2Input: SidebarInput = {
  id: 'addressLine2',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Billing street address line 2',
  required: false,
};

export const billingCityInput: SidebarInput = {
  id: 'billingCity',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Billing city',
  required: false,
};

export const stateInput: SidebarInput = {
  id: 'state',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Billing State or country subdivision code',
  required: false,
};

export const countryInput: SidebarInput = {
  id: 'country',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Billing country',
  required: false,
};

export const postalCodeInput: SidebarInput = {
  id: 'postalCode',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Billing postal code',
  required: false,
};

const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.DateTimeEquals,
  Operator.DateTimeBefore,
  Operator.DateTimeAfter,
  Operator.BooleanTrue,
  Operator.BooleanFalse,
];

export const accountsFilterInputOauth: SidebarInput = {
  id: 'accountsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get Accounts that match the specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Id', value: 'Id' },
    { label: 'Name', value: 'name' },
    { label: 'Type', value: 'type' },
    { label: 'Currency Code', value: 'CurrencyCode' },
  ],
};

export const paymentsFilterInputOauth: SidebarInput = {
  id: 'paymentsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get payments that match the specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Id', value: 'Id' },
    { label: 'PaymentType', value: 'name' },
    { label: 'Status', value: 'status' },
    { label: 'Invoice Id', value: 'Invoice.InvoiceId' },
    { label: 'Date', value: 'Date' },
    { label: 'Reference', value: 'Reference' },
  ],
};

export const contactsFilterInputOauth: SidebarInput = {
  id: 'contactsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get Customers that match the specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Id', value: 'Id' },
    { label: 'Name', value: 'name' },
    { label: 'Email Address', value: 'EmailAddress' },
    { label: 'Account Number', value: 'AccountNumber' },
    { label: 'Contact Status', value: 'ContactStatus' },
  ],
};

export const invoicesFilterInputOauth: SidebarInput = {
  id: 'invoicesFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get invoices that match the specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Id', value: 'Id' },
    { label: 'Invoice Number', value: 'invoiceNumber' },
    { label: 'Contact Name', value: 'Contact.Name' },
    { label: 'Name', value: 'name' },
    { label: 'Status', value: 'status' },
    { label: 'Reference', value: 'Reference' },
    { label: 'Date', value: 'Date' },
    { label: 'DueDate', value: 'DueDate' },
  ],
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Accounts',
          items: [
            {
              value: Intent.GET_ACCOUNTS,
              label: 'Get Accounts',
            },
          ],
        },
        {
          title: 'Contacts',
          items: [
            {
              value: Intent.GET_CONTACTS,
              label: 'Get Contacts',
            },
            {
              value: Intent.CREATE_CUSTOMER,
              label: 'Create Customer',
            },
            {
              value: Intent.UPDATE_CUSTOMER,
              label: 'Update Customer',
            },
          ],
        },
        {
          title: 'Invoices',
          items: [
            {
              value: Intent.GET_INVOICES,
              label: 'Get invoices',
            },
            {
              value: Intent.CREATE_INVOICE,
              label: 'Create invoice',
            },
            {
              value: Intent.UPDATE_INVOICE,
              label: 'Update invoice',
            },
            {
              value: Intent.SEND_INVOICE,
              label: 'Send invoice',
            },
          ],
        },
        {
          title: 'Payments',
          items: [
            {
              value: Intent.GET_PAYMENTS,
              label: 'Get payments',
            },
            {
              value: Intent.CREATE_PAYMENT,
              label: 'Create payments',
            },
          ],
        },
      ],
    },
  ],
};

export const createUpdateCustomerInputs: SidebarInput[] = [
  emailInput,
  phoneInput,
  accountNumberInput,
  addressLine1Input,
  addressLine2Input,
  billingCityInput,
  stateInput,
  countryInput,
  postalCodeInput,
];
