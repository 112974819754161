import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DynamicDataSource,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import {
  MD_ENTITY_SPECIFIED_BY_OBJECT_NAME,
  MdDefaultEntities,
  supportedOperators,
} from '../shared';

import { getCustomEntities, getCustomers, getFields, getOwners, getProcessStages } from './sources';

export const intentInput: SidebarInput[] = [
  {
    id: 'intent',
    title: 'Choose an action',
    type: SidebarInputType.Intent,
    values: [
      {
        value: Intent.CREATE_RECORD,
        label: 'Create record',
      },
      {
        value: Intent.UPDATE_RECORD,
        label: 'Update record',
      },
      {
        value: Intent.GET_RECORD_BY_ID,
        label: 'Get record by ID',
      },
      {
        value: Intent.SEARCH_RECORDS,
        label: 'Search records',
      },
      {
        value: Intent.DELETE_RECORD,
        label: 'Delete Record by ID',
      },
    ],
  },
];

export const mdEntityInput: SidebarInput = {
  id: 'mdEntity',
  title: 'Choose an entity',
  required: true,
  type: SidebarInputType.Enum,
  getValues: () => [
    ...Object.values(MdDefaultEntities).map((mdEntity) => ({
      label: mdEntity,
      value: mdEntity,
    })),
    { label: 'Custom Entity', value: MD_ENTITY_SPECIFIED_BY_OBJECT_NAME },
  ],
};

export const customMdEntityNameInput: SidebarInput = {
  id: 'customMdEntityName',
  title: 'Entity Name',
  required: true,
  type: SidebarInputType.TextArea,
  subtitle:
    'The Entity Set Name of the Object. Use Connect Portal Workflow Settings to allow users to select a Custom Object.',
  placeholder: 'entitysetname',
  lines: 1,
};

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  subtitle:
    'A subject or descriptive name, such as the expected order or company name, for the Opportunity. This field is required.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const potentialCustomerIdInput: SidebarInput = {
  id: 'customerid',
  title: 'Potential Customer ID',
  subtitle:
    'Select an account or contact to associate with this Opportunity. This field is required.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: '{{settings.customerID}}',
};

export const processStageInput: SidebarInput = {
  id: 'salesstagecode',
  title: 'Process Stage',
  subtitle: 'Use Connect Portal Workflow Settings to allow users to select a Process Stage.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '{{settings.processStage}}',
};

export const estimatedCloseDateInput: SidebarInput = {
  id: 'estimatedclosedate',
  title: 'Estimated Close Date',
  subtitle: 'The expected closing date of the Opportunity.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const actualCloseDateInput: SidebarInput = {
  id: 'actualclosedate',
  title: 'Actual Close Date',
  subtitle: 'The date and time when the Opportunity was closed or canceled.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const estimatedRevenueInput: SidebarInput = {
  id: 'estimatedvalue',
  title: 'Est. Revenue',
  subtitle:
    'The estimated revenue amount to indicate the potential sale or value of the opportunity for revenue forecasting.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  title: 'Description',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
};

export const ownerIdInput: SidebarInput = {
  id: 'ownerid',
  title: 'Owner ID',
  subtitle:
    'The Dynamics 365 user or team assigned to this Opportunity. Use Connect Portal Workflow Settings to allow users to select an Owner (Team or User).',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '{{settings.ownerID}}',
};

// todo check why placeholder is causing scrollbar to appear.
export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.TextArea,
  lines: 4,
  required: false,
  subtitle:
    'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which Fields to update.',
  placeholder: `{
    "address2_city": "Los Angeles",
    "address2_stateorprovince": "CA",
    "address2_country": "United States",
    "address2_line1": "1700 Sawtelle Blvd",
    "primarycontactid@odata.bind": "/contacts(00000000-
      0000-0000-0000-000000000001)",
}`,
};

export const websiteInput: SidebarInput = {
  id: 'websiteurl',
  title: 'Website',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const businessPhoneInput: SidebarInput = {
  id: 'telephone1',
  title: 'Business Phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const firstNameInput: SidebarInput = {
  id: 'firstname',
  title: 'First Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const lastNameInput: SidebarInput = {
  id: 'lastname',
  title: 'Last Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'This field is required.',
};

export const accountIdInput: SidebarInput = {
  id: 'accountId',
  title: 'Account ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Specify an account that the Contact is associated with.',
};

export const emailInput: SidebarInput = {
  id: 'emailaddress1',
  title: 'Email',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const jobTitleInput: SidebarInput = {
  id: 'jobtitle',
  title: 'Job Title',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Title of the lead, such as CEO or Vice President.',
};

export const topicInput: SidebarInput = {
  id: 'subject',
  title: 'Topic',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle:
    'Use a descriptive name, such as the expected order, company name, or marketing source list, to identify the lead.',
};

export const opportunityIdInput: SidebarInput = {
  id: 'opportunityId',
  title: 'Topic',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle:
    'Use a descriptive name, such as the expected order, company name, or marketing source list, to identify the lead.',
};

export const transactionCurrencyIdInput: SidebarInput = {
  id: 'transactioncurrencyid',
  title: 'Transaction Currency',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle:
    'Choose the local currency for the Opportunity. Use Connect Portal Workflow Settings to allow users to select a Transaction Currency, or the first available will be used as a default.',
  placeholder: '{{settings.transactionCurrency}}',
};

export const accountNameInput: SidebarInput = {
  id: 'name',
  title: 'Account Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The company or business name for the Account. This field is required.',
};

export const ownerDropdownInput: SidebarInput = {
  id: 'ownerid',
  type: SidebarInputType.NativeDynamicEnumInput,
  title: 'OwnerId',
  placeholder: 'Select a Record type',
  subtitle:
    'The Dynamics 365 user or team assigned to this Opportunity. Use Connect Portal Workflow Settings to allow users to select an Owner (Team or User).',
  source: getOwners,
  getValues: getValuesByCacheKey,
  required: false,
};

export const processStageDropdownInput: SidebarInput = {
  id: 'salesstagecode',
  type: SidebarInputType.NativeDynamicEnumInput,
  title: 'Process Stage',
  placeholder: 'Select a Record type',
  subtitle:
    'The Dynamics 365 user or team assigned to this Opportunity. Use Connect Portal Workflow Settings to allow users to select an Owner (Team or User).',
  source: getProcessStages,
  getValues: getValuesByCacheKey,
  required: false,
};

export const potentialCustomerIdDropdownInput: SidebarInput = {
  id: 'customerid',
  type: SidebarInputType.NativeDynamicEnumInput,
  title: 'Potential Customer ID',
  placeholder: 'Select a Record type',
  subtitle:
    'Select an account or contact to associate with this Opportunity. This field is required.',
  source: getCustomers,
  getValues: getValuesByCacheKey,
  required: false,
};

export const transactionCurrencyDropdownInput: SidebarInput = {
  id: 'transactioncurrencyid',
  type: SidebarInputType.NativeDynamicEnumInput,
  title: 'Potential Customer ID',
  placeholder: 'Select a Record type',
  subtitle:
    'Select an account or contact to associate with this Opportunity. This field is required.',
  source: getCustomers,
  getValues: getValuesByCacheKey,
  required: false,
};

export const customEntityNameDropdownInput: SidebarInput = {
  id: 'customMdEntityName',
  title: 'Entity Name',
  required: true,
  type: SidebarInputType.EditableDynamicEnum,
  subtitle: 'Use the Entity Set Name of the entity.',
  placeholder: 'entitysetname',
  source: getCustomEntities as unknown as DynamicDataSource<EnumInputValue[]>,
  getValues: getValuesByCacheKey,
};

/**
 * @summary
 * @param entityType
 * @returns
 */
export const entityIdInputFactory: (
  entityType: MdDefaultEntities | typeof MD_ENTITY_SPECIFIED_BY_OBJECT_NAME,
  operation: string,
) => SidebarInput = (entityType, operation) =>
  ({
    id: `entityId`,
    title: `${entityType === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME ? 'Entity' : entityType} ID`,
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
    subtitle: `The ID of the ${
      entityType === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME ? 'entity' : entityType
    } to ${operation}.`,
  } as SidebarInput);

export const getFilterInputConnect = (
  selectedMdEntity: MdDefaultEntities | typeof MD_ENTITY_SPECIFIED_BY_OBJECT_NAME,
): SidebarInput => {
  const filterInputConnect: SidebarInput = {
    type: SidebarInputType.Conditional,
    id: 'filterFormula',
    title: 'Filter search',
    subtitle: 'Search for records that match specified filters.',
    required: false,
    getValues:
      selectedMdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
        ? undefined
        : () =>
            inputsForDefaultEntitiesConnect[selectedMdEntity]
              .filter((input: SidebarInput) => {
                return !['additionalFields', 'from', 'to', 'requiredattendees'].includes(input.id);
              })
              .map((input: SidebarInput) => ({
                label: input.title === 'Direction' ? 'Outgoing' : input.title,
                value: getFilterSearchValue(input.id),
              })),
    supportedOperators,
  };
  return filterInputConnect;
};

export const getClassicFilterInput = (
  selectedMdEntity: MdDefaultEntities | typeof MD_ENTITY_SPECIFIED_BY_OBJECT_NAME,
): SidebarInput => {
  const filterInput: SidebarInput = {
    id: 'filterFormula',
    type: SidebarInputType.DynamicConditional,
    title: 'Filter Records',
    subtitle: 'Search for records that match specified filters.',
    required: false,
    source: {
      ...getFields,
      cacheKey: 'cachedFields',
      getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
        return {
          actionType: Action.MICROSOFT_DYNAMICS,
          intent: Intent.GET_FIELDS_FOR_ENTITY,
          credentials: options.credentials,
          actionParameters: [
            {
              key: 'mdEntity',
              source: {
                type: 'VALUE',
                value: selectedMdEntity,
              },
            },
          ],
        };
      },
    },
    getValues: getValuesByCacheKey,
    supportedOperators: supportedOperators,
  };

  return filterInput;
};

export const subjectInput: SidebarInput = {
  id: 'subject',
  title: 'Subject',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'A description of the objective or topic of the call.',
};

export const fromInput: SidebarInput = {
  id: 'from',
  title: 'From',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle:
    'The account, contact, lead, or user this call is from. Defaults to the currently authenticated user.',
};

export const toInput: SidebarInput = {
  id: 'to',
  title: 'To',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: '{{settings.lead}}',
  subtitle:
    'The account, contact, lead, or user this call is to. Use Connect Portal User Settings to allow users to specify a record.',
};

export const directionInput: SidebarInput = {
  id: 'directioncode',
  title: 'Direction',
  type: SidebarInputType.EditableEnum,
  required: false,
  subtitle: 'Defaults to Outgoing.',
  getValues: () => [
    { label: 'Outgoing', value: '1' },
    { label: 'Incoming', value: '0' },
  ],
};

export const regardingInut: SidebarInput = {
  id: 'regardingobjectid',
  title: 'Regarding',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '{{settings.opportunity}}',
  subtitle: 'The account, contact, lead or opportunity this call is about.',
};

export const phoneNumberInput: SidebarInput = {
  id: 'phonenumber',
  title: 'Phone Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const durationInput: SidebarInput = {
  id: 'actualdurationminutes',
  title: 'Duration',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Specify duration in minutes. Defaults to 30 minutes.',
};

export const dueInput: SidebarInput = {
  id: 'scheduledend',
  title: 'Due Date/Time',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Accepts Unix timestamp or ISO 8601 date formats.',
};

export const priorityInput: SidebarInput = {
  id: 'prioritycode',
  title: 'Priority',
  type: SidebarInputType.EditableEnum,
  required: false,
  subtitle: 'Defaults to Normal.',
  defaultValue: '1',
  getValues: () => [
    { label: 'Low', value: '0' },
    { label: 'Normal', value: '1' },
    { label: 'High', value: '2' },
  ],
};

export const startDateInput: SidebarInput = {
  id: 'scheduledstart',
  title: 'Start Date/Time',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Accepts Unix timestamp or ISO 8601 date formats.',
};

export const endDateInput: SidebarInput = {
  id: 'scheduledend',
  title: 'End Date/Time',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Accepts Unix timestamp or ISO 8601 date formats.',
};

export const attendeesInput: SidebarInput = {
  id: 'requiredattendees',
  title: 'Attendees',
  type: SidebarInputType.Code,
  language: 'json',
  required: false,
  lines: 4,
  useLightTheme: true,
  placeholder: `
  [
    "{{userSettings.accountId}}",
    "/systemusers(1d9e0de7-4fb2-ec11-9840-0022480b1bdf)"
  ]`,
  subtitle: 'Add accounts, contacts, leads, or users to this meeting. Specify as an array of IDs.',
};

export const locationInput: SidebarInput = {
  id: 'location',
  title: 'Location',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const titleInput: SidebarInput = {
  id: 'subject',
  title: 'Title',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'A title for the note.',
};

export const forInput: SidebarInput = {
  id: 'objectid',
  title: 'For',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The account, contact, lead, or opportunity this note is attached to.',
  placeholder: '{{settings.opportunity}}',
};

export const callIdInput: SidebarInput = {
  id: 'callId',
  title: 'call ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The account, contact, lead, or opportunity this note is attached to.',
  placeholder: '{{settings.opportunity}}',
};

export const inputsForDefaultEntitiesClassic: Partial<Record<MdDefaultEntities, SidebarInput[]>> = {
  [MdDefaultEntities.Account]: [
    accountNameInput,
    ownerDropdownInput,
    websiteInput,
    businessPhoneInput,
    {
      ...descriptionInput,
      subtitle:
        "Additional information to describe the contact, such as an excerpt from the company's website.",
    },
    additionalFieldsInput,
  ],
  [MdDefaultEntities.Contact]: [
    firstNameInput,
    lastNameInput,
    // accountIdInput,  commented out accountId input as this is a readOnly Property.
    emailInput,
    businessPhoneInput,
    jobTitleInput,
    {
      ...descriptionInput,
      subtitle:
        "Additional information to describe the contact, such as an excerpt from the company's website.",
    },
    additionalFieldsInput,
  ],
  [MdDefaultEntities.Lead]: [
    topicInput,
    firstNameInput,
    lastNameInput,
    emailInput,
    businessPhoneInput,
    websiteInput,
    jobTitleInput,
    processStageDropdownInput,
    {
      ...descriptionInput,
      subtitle:
        "Additional information to describe the lead, such as an excerpt from the company's website.",
    },
    additionalFieldsInput,
  ],
  [MdDefaultEntities.Opportunity]: [
    nameInput,
    potentialCustomerIdDropdownInput,
    processStageDropdownInput,
    estimatedCloseDateInput,
    actualCloseDateInput,
    estimatedRevenueInput,
    {
      ...descriptionInput,
      subtitle:
        'Additional information to describe the Opportunity, such as possible products to sell or past purchases from the customer.',
    },
    ownerIdInput,
    transactionCurrencyDropdownInput,
    additionalFieldsInput,
  ],
};

export const inputsForDefaultEntitiesConnect: Record<MdDefaultEntities, SidebarInput[]> = {
  [MdDefaultEntities.Account]: [
    accountNameInput,
    ownerIdInput,
    websiteInput,
    businessPhoneInput,
    {
      ...descriptionInput,
      subtitle:
        "Additional information to describe the contact, such as an excerpt from the company's website.",
    },
    additionalFieldsInput,
  ],
  [MdDefaultEntities.Contact]: [
    firstNameInput,
    lastNameInput,
    // accountIdInput,  commented out accountId input as this is a readOnly Property.
    emailInput,
    businessPhoneInput,
    jobTitleInput,
    {
      ...descriptionInput,
      subtitle:
        "Additional information to describe the contact, such as an excerpt from the company's website.",
    },
    additionalFieldsInput,
  ],
  [MdDefaultEntities.Lead]: [
    topicInput,
    firstNameInput,
    lastNameInput,
    emailInput,
    businessPhoneInput,
    websiteInput,
    jobTitleInput,
    processStageInput,
    {
      ...descriptionInput,
      subtitle:
        "Additional information to describe the lead, such as an excerpt from the company's website.",
    },
    additionalFieldsInput,
  ],
  [MdDefaultEntities.Opportunity]: [
    nameInput,
    potentialCustomerIdInput,
    processStageInput,
    estimatedCloseDateInput,
    actualCloseDateInput,
    estimatedRevenueInput,
    {
      ...descriptionInput,
      subtitle:
        'Additional information to describe the Opportunity, such as possible products to sell or past purchases from the customer.',
    },
    ownerIdInput,
    transactionCurrencyIdInput,
    additionalFieldsInput,
  ],
  [MdDefaultEntities.Calls]: [
    subjectInput,
    fromInput,
    toInput,
    directionInput,
    regardingInut,
    phoneNumberInput,
    durationInput,
    descriptionInput,
    dueInput,
    priorityInput,
  ],
  [MdDefaultEntities.Meetings]: [
    { ...subjectInput, subtitle: 'A description of the objective or topic of the meeting.' },
    startDateInput,
    { ...endDateInput, required: true },
    attendeesInput,
    {
      ...regardingInut,
      subtitle: 'The account, contact, lead or opportunity this meeting is about.',
    },
    locationInput,
    descriptionInput,
    priorityInput,
  ],
  [MdDefaultEntities.Tasks]: [
    { ...subjectInput, subtitle: 'A title for the task.' },
    { ...regardingInut, subtitle: 'The account, contact, lead or opportunity this task is about.' },
    dueInput,
    {
      ...ownerIdInput,
      title: 'Owner',
      subtitle: 'Specify a user to own this task. Defaults to the currently authenticated user.',
      placeholder: '{{settings.user}}',
    },
    priorityInput,
    descriptionInput,
  ],
  [MdDefaultEntities.Notes]: [forInput, titleInput, { ...descriptionInput, id: 'notetext' }],
};

export const getFilterSearchValue = (id: string): string => {
  switch (id) {
    case 'ownerid':
      return '_ownerid_value';

    case 'regardingobjectid':
      return '_regardingobjectid_value';

    case 'objectid':
      return '_objectid_value';

    default:
      return id;
  }
};
