import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Calls',
          items: [
            {
              value: Intent.ADD_CALL,
              label: 'Add a new Call',
            },
            {
              value: Intent.GET_CALL_BY_ID,
              label: 'Get Call by ID',
            },
            {
              value: Intent.SEARCH_CALL,
              label: 'Search for Call',
            },
          ],
        },
      ],
    },
  ],
};

export const callStartDateInput: SidebarInput = {
  id: 'actualStart',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Call Start Date',
  required: true,
  subtitle: 'Accepts ISO 8601 date format.',
  placeholder: `2022-08-01T07:00:00.000Z`,
};

export const clientUniqueIDInput: SidebarInput = {
  id: 'clientUniqueId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Client Unique ID',
  required: true,
  subtitle:
    "A call's unique identifier in the PBX or the recording system. Gong uses this identifier to prevent repeated attempts to upload the same recording.",
  placeholder: `123abc`,
};

export const directionInput: SidebarInput = {
  id: 'direction',
  type: SidebarInputType.EditableEnum,
  title: 'Direction',
  required: true,
  getValues: () => [
    {
      label: 'Conference',
      value: 'Conference',
    },
    {
      label: 'Inbound',
      value: 'Inbound',
    },
    {
      label: 'Outbound',
      value: 'Outbound',
    },
    {
      label: 'Unknown',
      value: 'Unknown',
    },
  ],
};

export const partiesInput: SidebarInput = {
  id: 'parties',
  type: SidebarInputType.TextArea,
  title: 'Parties',
  required: true,
  subtitle: (
    <>
      An array list of the call{"'"}s participants as objects. A party must be provided for the
      Primary User.{' '}
      <a
        href="https://app.gong.io/settings/api/documentation#post-/v2/callsr"
        target="_blank"
        rel="noreferrer"
      >
        See the docs
      </a>{' '}
      to view the complete parties object reference.
    </>
  ),
  placeholder: `[
    {
      "emailAddress": "test@test.com",
      "name": "Test User",
      "userId": "7387268041252473322"
    }
  ]`,
};

export const primaryUserInput: SidebarInput = {
  id: 'primaryUser',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Primary User',
  required: true,
  subtitle: 'The Gong internal user ID of the team member who hosted the call.',
  placeholder: `7347263041252417409`,
};

export const titleInput: SidebarInput = {
  id: 'title',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Title',
  required: false,
  subtitle:
    'The title of the call. This title is available in the Gong system for indexing and search.',
  placeholder: `Call with Sales`,
};

export const downloadMediaURLInput: SidebarInput = {
  id: 'downloadMediaUrl',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Download Media URL',
  required: false,
  subtitle: 'The URL from which Gong can download the media file. Up to 1.5 GB.',
};

export const purposeInput: SidebarInput = {
  id: 'purpose',
  type: SidebarInputType.TextArea,
  lines: 2,
  title: 'Purpose',
  required: false,
  subtitle: 'The purpose of the call. Limit of 255 characters.',
};

export const durationInput: SidebarInput = {
  id: 'duration',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Duration',
  required: false,
  subtitle: 'The call duration in seconds.',
  placeholder: `3600.0`,
};

export const callIDInput: SidebarInput = {
  id: 'id',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Call ID',
  required: true,
  subtitle: "Specify Gong's unique numeric identifier for the call (up to 20 digits).",
  placeholder: `16470009016529344`,
};

export const startDate: SidebarInput = {
  id: 'fromDateTime',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Start Date',
  required: true,
  subtitle: 'Accepts ISO 8601 date format.',
  placeholder: `2022-08-01T07:00:00.000Z`,
};

export const endDate: SidebarInput = {
  id: 'toDateTime',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'End Date',
  required: true,
  subtitle: 'Accepts ISO 8601 date format.',
  placeholder: `2022-08-01T07:00:00.000Z`,
};
