import React from 'react';

import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const jqlDocLink = (
  <a
    href="https://confluence.atlassian.com/jiracoreserver073/advanced-searching-861257209.html#Advancedsearching-ConstructingJQLqueries"
    target="_blank"
    rel="noreferrer"
  >
    JIRA Query Language (JQL)
  </a>
);

const connectConfig: ActionConfig = {
  actionType: Action.JIRA,
  name: 'Jira',
  description: 'Trigger when issues are created or updated in JIRA',
  icon: iconSvg,
  provider: ProviderType.JIRA,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_ISSUE_CREATED,
              label: 'New Issue',
            },
            {
              value: Intent.TRIGGER_ISSUE_UPDATED,
              label: 'Issue Updated',
            },
            {
              value: Intent.TRIGGER_COMMENT_CREATED,
              label: 'New Comment',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      switch (parameters.intent as Intent) {
        case Intent.TRIGGER_ISSUE_CREATED:
        case Intent.TRIGGER_ISSUE_UPDATED:
        case Intent.TRIGGER_COMMENT_CREATED:
          return {
            inputs: [
              {
                id: 'jqlQuery',
                title: 'Filter by JQL Query',
                type: SidebarInputType.Code,
                language: 'jql',
                lines: 4,
                useLightTheme: true,
                subtitle: (
                  <>Only trigger when new issues match the following query using {jqlDocLink}.</>
                ),
                required: false,
                placeholder: `project = {{settings.project}} 
AND status = {{settings.status}}`,
              },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
