import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import {
  DataType,
  KeyedSource,
  ResolvedRequestParameters,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import {
  HttpMethod,
  RequestAuthorizationType,
  RequestStep,
  StepType,
} from '@shared/types/sdk/steps';
import { isValidUrl } from '@shared/utils/sdk/http';

import { StepValidator } from './abstract.validator';
import { isVariableKeySource, shouldAssertValue } from './validator.utils';

/**
 * validates that a request step is properly configured
 *
 * @export
 * @class RequestStepValidator
 * @extends {StepValidator<RequestStep, ResolvedRequestParameters>}
 */
export default class RequestStepValidator extends StepValidator<
  RequestStep,
  ResolvedRequestParameters
> {
  stepType: RequestStep['type'] = StepType.REQUEST;

  /**
   * validates that a step is valid
   *
   * @param {RequestStep} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @param {boolean} validateUrl whether or not to validate the url. set to `false` for CustomIntegrationRequestStep usage
   * @returns {RequestStep}
   * @memberof RequestStepValidator
   */
  validate(
    step: RequestStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona,
    validateUrl: boolean = true,
  ): RequestStep {
    const { url, params, body, headers, httpMethod, authorization } = this.resolveVariables(
      step,
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
    );

    if (shouldAssertValue(step.parameters.url, url) && !url) {
      throw new Error('You must specify a Request URL for this step.');
    } else if (validateUrl && shouldAssertValue(step.parameters.url, url) && !isValidUrl(url)) {
      throw new Error(`${url} is not a valid url.`);
    } else if (!this.isValidUrlParameters(headers, step.parameters.headers)) {
      throw new Error('You must specify a key for each header.');
    } else if (!this.isValidUrlParameters(params, step.parameters.params)) {
      throw new Error('You must specify a key for each url parameter.');
    } else if (
      [HttpMethod.POST, HttpMethod.PUT, HttpMethod.DELETE, HttpMethod.PATCH].includes(httpMethod) &&
      !this.isValidUrlParameters(body, step.parameters.body)
    ) {
      throw new Error('You must specify a key for each body parameter.');
    } else if (step.parameters.authorization) {
      switch (authorization.type) {
        case RequestAuthorizationType.BASIC: {
          const { username, password } = authorization;
          if (shouldAssertValue(step.parameters.authorization['username'], username) && !username) {
            throw new Error('You have not added credential for authorization.');
          } else if (
            shouldAssertValue(step.parameters.authorization['password'], password) &&
            !password
          ) {
            throw new Error('You have not added credential for authorization.');
          }
          break;
        }
        case RequestAuthorizationType.BEARER_TOKEN: {
          const { token } = authorization;

          if (shouldAssertValue(step.parameters.authorization['token'], token) && !token) {
            throw new Error('You must add token for authorization.');
          }
          break;
        }
        case RequestAuthorizationType.NONE:
          break;
      }
    }

    return step;
  }

  /**
   * returns false when value is provided but key is missing
   *
   * @param obj
   * @returns
   */
  isValidUrlParameters(obj: Record<string, string>, sources: KeyedSource<DataType>[]): boolean {
    const trim = (value: string | undefined): string | undefined => value?.trim?.() || undefined;

    return Object.entries(obj).every(([key, value]: [string, string]) => {
      return isVariableKeySource(
        sources.find(
          (source: KeyedSource<DataType>) => source.key === key,
        ) as KeyedSource<DataType.ANY>,
      ) || trim(value)
        ? trim(key)
        : true;
    });
  }
}
