import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

type ScopeName =
  | 'read:jira-user'
  | 'read:jira-work'
  | 'write:jira-work'
  | 'manage:jira-project'
  | 'manage:jira-configuration'
  | 'offline_access';

export const requiredScopes: ScopeName[] = ['read:jira-work', 'read:jira-user', 'offline_access'];

export const scopes: Record<ScopeName, ScopeValue> = {
  'read:jira-user': {
    name: 'read:jira-user',
    description:
      'View user information in Jira that the user has access to, including usernames, email addresses, and avatars.',
    required: true,
  },
  'read:jira-work': {
    name: 'read:jira-work',
    description:
      'Read Jira project and issue data, search for issues and objects associated with issues like attachments and worklogs.',
  },
  'write:jira-work': {
    name: 'write:jira-work',
    description:
      'Create and edit issues in Jira, post comments as the user, create worklogs, and delete issues.',
  },
  'manage:jira-project': {
    name: 'manage:jira-project',
    description:
      'Create and edit project settings and create new project-level objects (for example, versions and components).',
  },
  'manage:jira-configuration': {
    name: 'manage:jira-configuration',
    description:
      'Take Jira administration actions (for example, create projects and custom fields, view workflows, and manage issue link types).',
  },
  offline_access: {
    name: 'offline_access',
    description: 'To get refresh tokens for offline access.',
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Jira',
  description: 'Access a user’s Jira Software issues.',
  authUrl: 'https://auth.atlassian.com',
  accessTokenUrl: 'https://auth.atlassian.com/oauth/token',
  iconSvg,
  clientIdLabel: 'App client Id',
  clientSecretLabel: 'App client secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'App Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/jira`,
      documentationLinkLabel: 'How to obtain a App Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'App Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink:
    'https://developer.atlassian.com/cloud/jira/platform/oauth-2-authorization-code-grants-3lo-for-apps/',
};
