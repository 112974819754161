import { Dispatch, MiddlewareAPI } from 'redux';

import { StepType } from '@shared/types/sdk/steps';

import { getStepValidationError, sanitizeStep } from '../../utils';
import { Action, State, WorkflowEditorState } from '../types';

const deepEqual = require('fast-deep-equal/es6');

/**
 * this middleware will again validate for particular step that has
 * stepvalidation error when it change
 * @param store
 * @returns
 */
const stepValidator =
  (store: MiddlewareAPI<Dispatch<Action>, State>) =>
  (next: Dispatch<Action>) =>
  async (action: Action) => {
    const oldState = store.getState();
    next(action);
    const newState = store.getState();

    const stepValidations = newState.workflowEditor.stepValidations;

    Object.entries(stepValidations).forEach(
      ([id, _]: [string, WorkflowEditorState['stepValidations'][number]]) => {
        const oldStep = oldState.entities.steps.entities[id];
        const newStep = newState.entities.steps.entities[id];

        const workflow = newState.entities.workflows.entities[oldStep.workflowId];

        const isStepDeleted = !workflow.stepIds.find((id: string) => id === oldStep.id) || !newStep;

        //if step is deleted then remove step validation from state
        if (isStepDeleted) {
          store.dispatch({ type: 'REMOVE_STEP_VALIDATION_ERROR', payload: { id: oldStep.id } });
        }
        //if step is updated then again assert that step and update / remove stepValidation
        else if (!deepEqual(sanitizeStep(oldStep), sanitizeStep(newStep))) {
          const stepValidationError = getStepValidationError(newStep, newState);
          if (stepValidationError) {
            store.dispatch({
              type: 'UPDATE_STEP_VALIDATION_ERROR',
              payload: { id: stepValidationError.meta.stepId, error: stepValidationError.message },
            });
          } else if (newStep.type !== StepType.UNSELECTED_TRIGGER) {
            store.dispatch({ type: 'REMOVE_STEP_VALIDATION_ERROR', payload: { id: newStep.id } });
          }
        }
      },
    );
  };

export default stepValidator;
