import { HttpProxyAgent } from 'http-proxy-agent';
import fetch from 'isomorphic-unfetch';

import { getHostname } from '@shared/utils/sdk/http';

import { getToken } from '../utils/auth';
import config from '../utils/config';

import { Zeus } from './zeus';

export type RequestConfig = RequestInit & { agent?: HttpProxyAgent | undefined };

type APIOptions = {
  sendAuthHeaders: boolean;
  sendParagonConnectPreviewHeader?: boolean;
};

const defaultAPIOptions: APIOptions = {
  sendAuthHeaders: true,
};

export function getAPIBaseURL(host: string = config.ZEUS_PUBLIC_URL): string {
  return getHostname(host);
}

export function getDashboardBaseUrl(host: string = config.DASHBOARD_PUBLIC_URL): string {
  return getHostname(host);
}

export function getProxyAgent(): HttpProxyAgent | undefined {
  const { HTTP_PROXY } = config;
  return HTTP_PROXY ? new HttpProxyAgent(HTTP_PROXY) : undefined;
}

export async function get(
  path: string,
  options: APIOptions = defaultAPIOptions,
): Promise<Response> {
  const url: string = `${getAPIBaseURL()}${path}`;
  const token: string = options.sendAuthHeaders ? getToken() : '';
  const params: RequestConfig = {
    headers: {
      ...(options.sendAuthHeaders && token ? { Authorization: `Bearer ${token}` } : {}),
      ...(options.sendParagonConnectPreviewHeader ? { 'X-Paragon-Connect-Preview-Mode': '1' } : {}),
    },
    method: 'GET',
    agent: getProxyAgent(),
  };

  return fetch(url, params);
}

export function post(path: string, body: object): Promise<Response> {
  const token = getToken();
  const api = new Zeus(token);

  return api.post(path, body);
}

export async function patch(
  path: string,
  body: object,
  options: APIOptions = defaultAPIOptions,
): Promise<Response> {
  const url: string = `${getAPIBaseURL()}${path}`;
  const token: string = options.sendAuthHeaders ? getToken() : '';
  const params: RequestConfig = {
    headers: {
      ...(options.sendAuthHeaders && token ? { Authorization: `Bearer ${token}` } : {}),
      ...(options.sendParagonConnectPreviewHeader ? { 'X-Paragon-Connect-Preview-Mode': '1' } : {}),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(body),
    agent: getProxyAgent(),
  };

  return fetch(url, params);
}

export async function del(
  path: string,
  body: object,
  options: APIOptions = defaultAPIOptions,
): Promise<Response> {
  const url: string = `${getAPIBaseURL()}${path}`;
  const token: string = options.sendAuthHeaders ? getToken() : '';
  const params: RequestConfig = {
    headers: {
      ...(options.sendAuthHeaders && token ? { Authorization: `Bearer ${token}` } : {}),
      ...(options.sendParagonConnectPreviewHeader ? { 'X-Paragon-Connect-Preview-Mode': '1' } : {}),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    body: JSON.stringify(body),
    agent: getProxyAgent(),
  };

  return fetch(url, params);
}

export async function put(
  path: string,
  body: object,
  options: APIOptions = defaultAPIOptions,
): Promise<Response> {
  const url: string = `${getAPIBaseURL()}${path}`;
  const token: string = options.sendAuthHeaders ? getToken() : '';
  const params: RequestConfig = {
    headers: {
      ...(options.sendAuthHeaders && token ? { Authorization: `Bearer ${token}` } : {}),
      ...(options.sendParagonConnectPreviewHeader ? { 'X-Paragon-Connect-Preview-Mode': '1' } : {}),
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(body),
    agent: getProxyAgent(),
  };

  return fetch(url, params);
}

export async function uploadFile(
  path: string,
  file: File,
  options: APIOptions = defaultAPIOptions,
): Promise<Response> {
  const body = new FormData();
  body.append('file', file);
  const url: string = `${getAPIBaseURL()}${path}`;
  const token: string = options.sendAuthHeaders ? getToken() : '';
  const params: RequestConfig = {
    headers: {
      ...(options.sendAuthHeaders && token ? { Authorization: `Bearer ${token}` } : {}),
      ...(options.sendParagonConnectPreviewHeader ? { 'X-Paragon-Connect-Preview-Mode': '1' } : {}),
    },
    method: 'POST',
    agent: getProxyAgent(),
    body,
  };

  return fetch(url, params);
}
