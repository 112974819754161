import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

// {
//   type: 'JOIN',
//   join: 'OR',
//   conditions: [{
//     type: 'JOIN',
//     join: 'AND',
//     conditions: [{
//       operator: '$stringContains',
//       variable: "Name",
//       argument: "boop"
//     }]
//   }]
// } -> "OR(AND(SEARCH("boop", {Name})))"
export default function conditionsToFormula(condition: ResolvedConditionWrapper): string {
  if (condition.type === 'JOIN') {
    return `${condition.join}(${condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFormula(condition);
      })
      .join(', ')})`;
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    const containsFormula = `SEARCH("${innerCondition.argument}", {${innerCondition.variable}})`;
    const stringEqualsFormula = `{${innerCondition.variable}} = "${innerCondition.argument}"`;
    const isInFormula = `SEARCH("${innerCondition.variable}", {${innerCondition.argument}})`;
    const startsWithFormula = `FIND("${innerCondition.argument}", {${innerCondition.variable}}) = 1`;
    const endsWithFormula = `FIND("${innerCondition.argument}", {${innerCondition.variable}}) = LEN({${innerCondition.variable}}) - ${innerCondition.argument?.length} + 1`;
    const dateFormula = `DATETIME_PARSE("${innerCondition.argument}")`;

    switch (innerCondition.operator) {
      case Operator.StringContains:
        return containsFormula;
      case Operator.StringDoesNotContain:
        return `NOT(${containsFormula})`;
      case Operator.StringExactlyMatches:
        return stringEqualsFormula;
      case Operator.StringDoesNotExactlyMatch:
        return `NOT(${stringEqualsFormula})`;
      case Operator.StringIsIn:
        return isInFormula;
      case Operator.StringIsNotIn:
        return `NOT(${isInFormula})`;
      case Operator.StringStartsWith:
        return startsWithFormula;
      case Operator.StringDoesNotStartWith:
        return `NOT(${startsWithFormula})`;
      case Operator.StringEndsWith:
        return endsWithFormula;
      case Operator.StringDoesNotEndWith:
        return `NOT(${endsWithFormula})`;
      case Operator.NumberLessThan:
        return `{${innerCondition.variable}} < ${innerCondition.argument}`;
      case Operator.NumberEquals:
        return `{${innerCondition.variable}} = ${innerCondition.argument}`;
      case Operator.NumberGreaterThan:
        return `{${innerCondition.variable}} > ${innerCondition.argument}`;
      case Operator.NumberDoesNotEqual:
        return `{${innerCondition.variable}} != ${innerCondition.argument}`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `{${innerCondition.variable}} >= ${innerCondition.argument}`;
      case Operator.NumberLessThanOrEqualTo:
        return `{${innerCondition.variable}} <= ${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `IS_AFTER({${innerCondition.variable}}, ${dateFormula})`;
      case Operator.DateTimeBefore:
        return `IS_BEFORE({${innerCondition.variable}}, ${dateFormula})`;
      case Operator.DateTimeEquals:
        return `DATETIME_DIFF(({${innerCondition.variable}}, ${dateFormula})) = 0`;
      case Operator.BooleanTrue:
        return `IF({${innerCondition.variable}} = TRUE(), TRUE(), FALSE())`;
      case Operator.BooleanFalse:
        return `IF({${innerCondition.variable}} = FALSE(), TRUE(), FALSE())`;
      case Operator.IsNotNull:
        return `IF({${innerCondition.variable}} = BLANK(), FALSE(), TRUE())`;
      case Operator.IsNull:
        return `IF({${innerCondition.variable}} = BLANK(), TRUE(), FALSE())`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for Airtable`);
    }
  }
  return '';
}
