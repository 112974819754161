import React from 'react';

import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { CredentialKeys, Intent } from '../configs';
import { LinkWrapperType } from '../shared';

import { getBoards, getLists } from './sources';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Cards',
          items: [
            {
              value: Intent.SEARCH_CARDS,
              label: 'Search Cards',
            },
            {
              value: Intent.GET_CARDS_IN_BOARD,
              label: 'Get Cards In Board',
            },
            {
              value: Intent.CREATE_CARD,
              label: 'Create Card',
            },
            {
              value: Intent.UPDATE_CARD,
              label: 'Update Card',
            },
            {
              value: Intent.DELETE_CARD,
              label: 'Delete Card',
            },
          ],
        },
        {
          title: 'Lists',
          items: [
            {
              value: Intent.GET_LISTS_IN_BOARD,
              label: 'Get Lists In Board',
            },
          ],
        },
        {
          title: 'Boards',
          items: [
            {
              value: Intent.SEARCH_BOARDS,
              label: 'Search Boards',
            },
          ],
        },
      ],
    },
  ],
};

export const listIdInput: SidebarInput = {
  id: 'listId',
  title: 'List',
  subtitle:
    'Select a List that this card should appear in. Use Connect Portal Workflow Settings to allow users to select a Trello List.',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: '{{settings.list}}',
  required: true,
};

export const dynamicListInput: SidebarInput = {
  ...listIdInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getLists,
  subtitle: 'Select a list of board.',
  placeholder: '',
  getValues: getValuesByCacheKey,
};

export const cardIdInput: SidebarInput = {
  id: 'cardId',
  title: 'Card ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const boardInput: SidebarInput = {
  id: 'boardId',
  title: 'Board',
  subtitle:
    'Select a Board to get cards from. Use Connect Portal Workflow Settings to allow users to select a Trello Board.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const dynamicBoardInput: SidebarInput = {
  ...boardInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getBoards,
  subtitle: 'Select a board of member.',
  placeholder: '',
  getValues: getValuesByCacheKey,
};

export const isClosedInput: SidebarInput = {
  id: 'isClosed',
  title: 'Closed',
  subtitle: 'Specify whether or not the card should be archived.',
  type: SidebarInputType.Enum,
  defaultValue: 'no',
  required: false,
  getValues: () => [
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ],
};

export const isDueDateComplete: SidebarInput = {
  id: 'isDueDateComplete',
  title: 'Due Date Complete',
  subtitle: 'Specify whether or not the card’s due date should be marked complete.',
  type: SidebarInputType.Enum,
  defaultValue: 'no',
  required: false,
  getValues: () => [
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ],
};

export const searchQuery: SidebarInput = {
  id: 'query',
  title: 'Search Query',
  subtitle: 'Search for cards by a phrase in their title, description, or other properties.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

/**
 * @param isConnectUi return boolean value
 * @param isUpdateCard retun boolean if intent is updateCard
 * @returns SidebarInput[]
 */
export const createUpdateCardFieldInputs = (
  isConnectUi: boolean,
  isUpdateCard: boolean = false,
): SidebarInput[] => {
  return [
    ...(isUpdateCard
      ? [
          { ...cardIdInput, subtitle: 'The ID of the card to update.' },
          {
            ...(isConnectUi
              ? {
                  ...boardInput,
                  subtitle:
                    'Select a Board to move this card into. Use Connect Portal Workflow Settings to allow users to select a Trello Board. If this field is specified, a List must also be specified below.',
                  placeholder: '{{settings.board}}',
                }
              : dynamicBoardInput),
            required: false,
          },
        ]
      : []),
    { ...(isConnectUi ? listIdInput : dynamicListInput), required: !isUpdateCard },
    {
      id: 'name',
      title: 'Name',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'description',
      title: 'Description',
      type: SidebarInputType.TextArea,
      lines: 2,
      required: false,
    },
    ...(isUpdateCard ? [isClosedInput] : []),
    {
      id: 'position',
      title: 'Position',
      subtitle: 'Set the position of the new card to top, bottom, or a positive number.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'dueDate',
      title: 'Due Date',
      subtitle: 'Specify a date for this card to be due.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    isDueDateComplete,
    {
      id: 'memberIds',
      title: 'Member IDs',
      subtitle: 'Specify an array of member IDs to add to the card.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'labelIds',
      title: 'Label IDs',
      subtitle: 'Specify an array of label IDs to add to the card.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'address',
      title: 'Address',
      subtitle: 'For use with Trello’s Map View.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'locationName',
      title: 'Location Name',
      subtitle: 'For use with Trello’s Map View.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'coordinates',
      title: 'Coordinates',
      subtitle: 'For use with Trello’s Map View. Use a lat,long-formatted string.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
  ];
};

export const LinkWrapper = ({ text, clickableText, href }: LinkWrapperType): JSX.Element => (
  <div>
    {text}
    <a href={href} target="_blank" rel="noreferrer">
      {clickableText}
    </a>
  </div>
);

export const apiKeyInput: SidebarInput = {
  id: CredentialKeys.API_KEY,
  title: 'API Key',
  subtitle: (
    <>
      <LinkWrapper
        text="You can copy your key by visiting your Trello "
        clickableText="key settings."
        href="https://trello.com/app-key/"
      />
    </>
  ),
  type: SidebarInputType.ValueText,
  required: true,
};

export const apiTokenInput: SidebarInput = {
  id: CredentialKeys.API_TOKEN,
  title: 'API Token',
  type: SidebarInputType.ValueText,
  required: true,
};

export const getSubtitle = (intent: Intent): { subtitle: string } => {
  switch (intent) {
    case Intent.TRIGGER_CARD_CREATED:
      return {
        subtitle:
          'Select a Board to watch for new cards. Use Connect Portal Workflow Settings to allow users to select a Trello Board.',
      };

    case Intent.TRIGGER_CARD_UPDATED:
      return {
        subtitle:
          'Select a Board to watch for updated cards. Use Connect Portal Workflow Settings to allow users to select a Trello Board.',
      };

    default:
      return {
        subtitle:
          'Select a Board to watch for card comments. Use Connect Portal Workflow Settings to allow users to select a Trello Board.',
      };
  }
};
