import { CompletedExecution } from '@shared/types/sdk/resolvers';

import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<CompletedExecution> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<CompletedExecution> = initialState,
  action: Action,
): EntitiesState<CompletedExecution> {
  switch (action.type) {
    case 'GET_VARIABLES_COMPLETE':
      const { stepId, variables } = action.payload;
      return {
        ...state,
        entities: Object.fromEntries(
          Object.values(state.entities)
            .filter((e: CompletedExecution) => e.stepId !== stepId)
            .concat(variables)
            .map((e: CompletedExecution) => [e.id, e]),
        ),
        processing: false,
      };

    case 'GET_ALL_VARIABLES_COMPLETE':
      const stepIds = action.payload.variables.map(
        (execution: CompletedExecution) => execution.stepId,
      );
      return {
        ...state,
        entities: Object.fromEntries(
          Object.values(state.entities)
            .filter((e: CompletedExecution) => !stepIds.includes(e.stepId))
            .concat(action.payload.variables)
            .map((e: CompletedExecution) => [e.id, e]),
        ),
        processing: false,
      };

    case 'GET_ALL_VARIABLES_START':
    case 'GET_VARIABLES_START':
      return { ...state, processing: true };

    case 'GET_VARIABLES_ERROR':
    case 'GET_ALL_VARIABLES_ERROR':
      return { ...state, processing: false, errorMessage: action.payload.message };

    default:
      return state;
  }
}
