import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.ZOOM;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  CREATE_MEETING = 'ZOOM_CREATE_MEETING',
  UPDATE_MEETING = 'ZOOM_UPDATE_MEETING',
  TEST_CONNECTION = 'ZOOM_TEST_CONNECTION',
  GET_MEETING = 'ZOOM_GET_MEETING',
  GET_MEETING_BY_ID = 'ZOOM_GET_MEETING_BY_ID',
  DELETE_MEETING = 'ZOOM_DELETE_MEETING',
  ADD_MEETING_REGISTRANT = 'ZOOM_ADD_MEETING_REGISTRANT',
  GET_MEETING_REGISTRANT = 'ZOOM_GET_MEETING_REGISTRANT',
  DELETE_MEETING_REGISTRANT = 'ZOOM_DELETE_MEETING_REGISTRANT',
  MOCK = 'ZOOM_MOCK_ACTION',
  GET_RECORDING_BY_MEETING_ID = 'ZOOM_GET_RECORDING_BY_MEETING_ID',
  SEARCH_RECORDING = 'ZOOM_SEARCH_RECORDING',

  //triggers intent
  TRIGGER_MEETING_CREATED = 'ZOOM_TRIGGER_MEETING_CREATED',
  TRIGGER_MEETING_UPDATED = 'ZOOM_TRIGGER_MEETING_UPDATED',
  TRIGGER_MEETING_ENDED = 'ZOOM_TRIGGER_MEETING_ENDED',
  TRIGGER_MEETING_REGISTRANT_CREATED = 'ZOOM_TRIGGER_MEETING_REGISTRANT_CREATED',
  TRIGGER_PARTICIPANT_JOINED = 'ZOOM_TRIGGER_PARTICIPANT_JOINED',
  TRIGGER_PARTICIPANT_LEFT = 'ZOOM_TRIGGER_PARTICIPANT_LEFT',
  TRIGGER_RECORDING_STARTED = 'ZOOM_TRIGGER_RECORDING_STARTED',
  TRIGGER_RECORDING_COMPLETED = 'ZOOM_TRIGGER_RECORDING_COMPLETED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_MEETING;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_MEETING;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_MEETING;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_MEETING_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_MEETING;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_MEETING_REGISTRANT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_MEETING_REGISTRANT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_MEETING_REGISTRANT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_RECORDING_BY_MEETING_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_RECORDING;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.MOCK; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_MEETING_CREATED; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_MEETING_UPDATED; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_MEETING_ENDED; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: Intent.TRIGGER_MEETING_REGISTRANT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.TRIGGER_PARTICIPANT_JOINED; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_PARTICIPANT_LEFT; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_RECORDING_STARTED; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_RECORDING_COMPLETED; actionParameters: KeyedSource<DataType.ANY>[] }
  );

export type Credentials = {
  ZOOM_OAUTH_ACCESS_TOKEN?: string;
  ZOOM_OAUTH_REFRESH_TOKEN: string;
  ZOOM_SCOPES?: string;
  IS_AUTOMATE_OAUTH_TRIGGER?: boolean;
};

export type WebhookEvents =
  | 'meeting.created'
  | 'meeting.updated'
  | 'meeting.ended'
  | 'meeting.registration_created'
  | 'meeting.participant_joined'
  | 'meeting.participant_left'
  | 'recording.started'
  | 'recording.completed';

/**
 * zoom webhook event to intent map to know webhook event is associated to which intent
 */
export const WebhookEventIntentMap: Record<WebhookEvents, Intent> = {
  'meeting.created': Intent.TRIGGER_MEETING_CREATED,
  'meeting.updated': Intent.TRIGGER_MEETING_UPDATED,
  'meeting.ended': Intent.TRIGGER_MEETING_ENDED,
  'meeting.registration_created': Intent.TRIGGER_MEETING_REGISTRANT_CREATED,
  'meeting.participant_joined': Intent.TRIGGER_PARTICIPANT_JOINED,
  'meeting.participant_left': Intent.TRIGGER_PARTICIPANT_LEFT,
  'recording.started': Intent.TRIGGER_RECORDING_STARTED,
  'recording.completed': Intent.TRIGGER_RECORDING_COMPLETED,
};
