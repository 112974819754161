import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { RecordType } from '../shared/types';

import authConfig from './authConfig';
import {
  customerFilterSearch,
  customerIdInput,
  customerInputs,
  entityIdInput,
  intentSelectorDropdown,
  orderFilterSearch,
  orderInputs,
  productFilterSearch,
  productInputs,
  recordTypeInput,
  skuInput,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.MAGENTO,
  name: 'Adobe Commerce',
  description: `Connect to your users' Adobe Commerce accounts`,
  icon: iconSvg,
  provider: ProviderType.MAGENTO,
  sidebarSections: [
    intentSelectorDropdown,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_CUSTOMER:
        case Intent.UPDATE_CUSTOMER:
          return {
            inputs: customerInputs(parameters.intent === Intent.CREATE_CUSTOMER),
          };

        case Intent.GET_CUSTOMER_BY_ID:
          return {
            inputs: [
              customerIdInput('Specify the Customer ID of the customer to fetch.'),
              recordTypeInput(RecordType.CUSTOMERS),
            ],
          };

        case Intent.SEARCH_CUSTOMERS:
          return {
            inputs: [customerFilterSearch, recordTypeInput(RecordType.CUSTOMERS)],
          };

        case Intent.DELETE_CUSTOMER:
          return {
            inputs: [
              customerIdInput('Specify the Customer ID of the customer to delete.'),
              recordTypeInput(RecordType.CUSTOMERS),
            ],
          };

        case Intent.CREATE_ORDER:
        case Intent.UPDATE_ORDER:
          return {
            inputs: orderInputs(parameters.intent === Intent.CREATE_ORDER),
          };

        case Intent.GET_ORDER_BY_ID:
          return {
            inputs: [
              entityIdInput('Order ID', 'Specify the Order ID of the order to fetch.'),
              recordTypeInput(RecordType.ORDERS),
            ],
          };

        case Intent.SEARCH_ORDERS:
          return {
            inputs: [orderFilterSearch, recordTypeInput(RecordType.ORDERS)],
          };

        case Intent.CREATE_PRODUCT:
        case Intent.UPDATE_PRODUCT:
          return {
            inputs: productInputs(parameters.intent === Intent.CREATE_PRODUCT),
          };

        case Intent.GET_PRODUCT_BY_ID:
          return {
            inputs: [
              skuInput('Specify the SKU of the product to fetch.'),
              recordTypeInput(RecordType.PRODUCTS),
            ],
          };

        case Intent.SEARCH_PRODUCTS:
          return {
            inputs: [productFilterSearch, recordTypeInput(RecordType.PRODUCTS)],
          };

        case Intent.DELETE_PRODUCT:
          return {
            inputs: [
              skuInput('Specify the SKU of the product to delete.'),
              recordTypeInput(RecordType.PRODUCTS),
            ],
          };

        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };

export default connectConfig;
