import { Connection, QueryError } from 'mysql2';

import { SqlRow } from './types';

export async function getPrimaryKeyForTable(
  table: string,
  connection: Connection,
): Promise<string> {
  return new Promise((resolve: (result: any) => void, reject: (error: any) => void) => {
    connection.query(`SHOW COLUMNS FROM ${table}`, (error: QueryError, result: SqlRow[]) => {
      if (error) {
        reject(error);
      }
      const row: SqlRow | undefined =
        result && result.find((value: SqlRow) => value['Key'] === 'PRI');

      if (row) {
        resolve(row['Field']);
      } else {
        reject(new Error('Table has no primary key'));
      }
    });
  });
}

/**
 * returns ['"words"']
 * @param words ['word1','word2']
 */
export function wrapWordInInvertedComma(words: string[]): string[] {
  return words.map((word: string) => `'${word}'`);
}

export function getSqlRow(
  cachedColumns: Record<string, string>,
  columnData: Record<string, any>,
): SqlRow {
  return Object.keys(cachedColumns)
    .filter((column: string) => columnData[`column-${column}`])
    .reduce(
      (sqlRow: SqlRow, column: string) => ({
        ...sqlRow,
        [column]: columnData[`column-${column}`],
      }),
      {},
    );
}

/**
 * Return True if the identifer (table or column name) is valid
 * Check legal identifiers MySQL
 * http://books.gigatux.nl/mirror/mysqlguide4.1-5.0/0672326736/ch02lev1sec1.html#:~:text=Identifiers%20for%20databases%2C%20tables%2C%20columns,is%2064%20bytes%2C%20not%20characters.
 */
export function isValidMySQLIdentifier(identifer: string): boolean {
  // Identifiers for databases, tables, columns, and indexes can be up to 64 characters long.
  if (identifer.length > 64) {
    return false;
  }

  // Check patterm allowed by mysql
  const pattern = /^[a-zA-Z_][a-zA-Z0-9_ -]*[\.]*[a-zA-Z_][a-zA-Z0-9_  -]*$/i;
  return pattern.test(identifer);
}

export function promisifiedSqlExecute<T>(
  query: string,
  values: any[],
  connection: Connection,
): Promise<T> {
  return new Promise((resolve: (result: T) => void, reject: (error: QueryError) => void) => {
    connection.execute(query, values, (error: QueryError, result: any): void => {
      if (error) {
        return reject(error);
      }

      return resolve(result);
    });
  });
}

export function promisifiedSqlQuery<T>(query: string, connection: Connection): Promise<T> {
  return new Promise((resolve: (result: T) => void, reject: (error: QueryError) => void) => {
    connection.execute(query, (error: QueryError, result: T): void => {
      if (error) {
        return reject(error);
      }

      return resolve(result);
    });
  });
}

/**
 * return mysql formatted datetime or timestamp string literl
 * @param dateObj date object
 */
export function buildMysqlDateTimeString(dateObj: Date) {
  if (dateObj) {
    return `${dateObj.getUTCFullYear()}-${`${dateObj.getUTCMonth() + 1}`.padStart(
      2,
      '0',
    )}-${`${dateObj.getUTCDate()}`.padStart(2, '0')}T${`${dateObj.getUTCHours()}`.padStart(
      2,
      '0',
    )}:${`${dateObj.getUTCMinutes()}`.padStart(2, '0')}:${`${dateObj.getUTCSeconds()}`.padStart(
      2,
      '0',
    )}`;
  }
  return '';
}
