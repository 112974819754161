import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.AMAZON_S3;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'AMAZON_S3_TEST_CONNECTION',
  LIST_BUCKETS = 'AMAZON_S3_LIST_BUCKETS',
}

/**
 * Combination of ActionIntent and ConnectIntent
 */
type IntentMap =
  | Intent.TEST_CONNECTION
  | Intent.LIST_BUCKETS
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  ACCESS_KEY_ID = 'AMAZON_S3_ACCESS_KEY_ID',
  SECRET_ACCESS_KEY = 'AMAZON_S3_SECRET_ACCESS_KEY',
  AWS_REGION = 'AMAZON_S3_AWS_REGION',
  BUCKET = 'AMAZON_S3_BUCKET',
}

export type Credentials = {
  [CredentialKeys.ACCESS_KEY_ID]: string;
  [CredentialKeys.SECRET_ACCESS_KEY]: string;
  [CredentialKeys.AWS_REGION]: string;
  [CredentialKeys.BUCKET]: string;
};
