import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { statusInput } from './connect';

const triggerConfig: ActionConfig = {
  actionType: Action.SHOPIFY,
  name: 'Shopify',
  description: 'Trigger when orders or product are created or updated in Shopify',
  icon: iconSvg,
  provider: ProviderType.SHOPIFY,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_ORDER_CREATED,
              label: 'New Order',
            },
            {
              value: Intent.TRIGGER_ORDER_UPDATED,
              label: 'Order Updated',
            },
            {
              value: Intent.TRIGGER_CUSTOMER_CREATED,
              label: 'New Customer',
            },
            {
              value: Intent.TRIGGER_CUSTOMER_UPDATED,
              label: 'Customer Updated',
            },
            {
              value: Intent.TRIGGER_PRODUCT_CREATED,
              label: 'New Product',
            },
            {
              value: Intent.TRIGGER_PRODUCT_UPDATED,
              label: 'Product Updated',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      return parameters.intent === Intent.TRIGGER_PRODUCT_CREATED ||
        parameters.intent === Intent.TRIGGER_PRODUCT_UPDATED
        ? {
            inputs: [statusInput],
          }
        : { inputs: [] };
    },
  ],
};

export default triggerConfig;
