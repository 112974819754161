import JSON5 from 'json5';

import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import {
  ResolvedResponseParameters,
  TokenizedSource,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import { ResponseStep, ResponseTypeEnum } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import {
  getRequirdStepIdForTokenizedSource,
  getRequiredStepIdToResolveKeyedSources,
  resolveKeyedSources,
  resolveSources,
} from './resolvers.utils';

/**
 * a resolver for a response step's variables
 *
 * @export
 * @class ResponseStepResolver
 * @extends {StepResolver<ResponseStep, ResolvedResponseParameters>}
 */
export class ResponseStepResolver extends StepResolver<ResponseStep, ResolvedResponseParameters> {
  /**
   * resolves the input parameters for the step
   *
   * @param {ResponseStep['parameters']} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedResponseParameters}
   * @memberof ResponseStepResolver
   */
  resolveInputParameters(
    parameters: ResponseStep['parameters'],
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedResponseParameters {
    let resolvedData: ResolvedResponseParameters;
    if (parameters.responseType === ResponseTypeEnum.FILE) {
      resolvedData = resolveSources(
        variables,
        parameters,
        secrets,
        cachedConnectCredential,
        context,
        cachedPersona,
        'bodyFile',
      );
      if (resolvedData.bodyFile && typeof resolvedData.bodyFile === 'string') {
        resolvedData.bodyFile = JSON5.parse(resolvedData.bodyFile);
      }
    } else {
      resolvedData = resolveKeyedSources<Omit<ResponseStep['parameters'], 'bodyFile'>, 'bodyData'>(
        variables,
        parameters,
        secrets,
        cachedConnectCredential,
        context,
        cachedPersona,
        'bodyData',
      );
    }

    return resolvedData;
  }

  getRequiredStepIdsForVariableResolution(parameters: ResponseStep['parameters']): string[] {
    return parameters.responseType === ResponseTypeEnum.FILE
      ? getRequirdStepIdForTokenizedSource(parameters.bodyFile as TokenizedSource)
      : getRequiredStepIdToResolveKeyedSources(parameters.bodyData);
  }
}
