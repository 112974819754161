import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

type ScopeName =
  | 'automation'
  | 'conversations.visitor_identification.tokens.create'
  | 'business-intelligence'
  | 'content'
  | 'crm.import'
  | 'crm.lists.read'
  | 'crm.lists.write'
  | 'crm.objects.contacts.write'
  | 'crm.objects.contacts.read'
  | 'crm.objects.companies.write'
  | 'crm.objects.companies.read'
  | 'crm.objects.deals.read'
  | 'crm.objects.deals.write'
  | 'crm.objects.owners.read'
  | 'crm.schemas.custom.read'
  | 'crm.objects.custom.read'
  | 'crm.objects.custom.write'
  | 'crm.schemas.deals.read'
  | 'crm.schemas.companies.read'
  | 'crm.schemas.contacts.read'
  | 'e-commerce'
  | 'files'
  | 'forms'
  | 'forms-uploaded-files'
  | 'hubdb'
  | 'integration-sync'
  | 'oauth'
  | 'sales-email-read'
  | 'social'
  | 'tickets'
  | 'timeline'
  | 'transactional-email';

export const requiredScopes: ScopeName[] = [
  'oauth',
  'crm.objects.companies.read',
  'crm.objects.companies.write',
  'crm.objects.contacts.read',
  'crm.objects.contacts.write',
  'crm.objects.deals.read',
  'crm.objects.deals.write',
  'crm.objects.owners.read',
  'crm.schemas.deals.read',
  'crm.schemas.companies.read',
  'crm.schemas.contacts.read',
  'e-commerce',
  'tickets',
];

export const scopes: Record<ScopeName, ScopeValue> = {
  automation: {
    name: 'automation',
    description: 'Allow access Automation API (Workflows endpoints)',
  },
  'conversations.visitor_identification.tokens.create': {
    name: 'conversations.visitor_identification.tokens.create',
    description: `Fetch identification tokens for authenticated website visitors interacting with the HubSpot chat widget.`,
  },
  'business-intelligence': {
    name: 'business-intelligence',
    description: 'Allow access Analytics API',
  },
  content: {
    name: 'content',
    description: `This includes sites, landing pages, CTA, email, blog, and campaigns.`,
  },
  'crm.import': {
    name: 'crm.import',
    description: `Allows you to import records into your CRM. This includes creating new records or modifying any of your existing records for all CRM data types (contacts, companies, deals, tickets, etc). It doesn't include archiving or deleting any data.`,
  },
  'crm.lists.write': {
    name: 'crm.lists.write',
    description: `Allows write access to Lists.`,
  },
  'crm.lists.read': {
    name: 'crm.lists.read',
    description: `Allows read access to Lists.`,
  },
  'crm.objects.contacts.write': {
    name: 'crm.objects.contacts.write',
    description: `Allows write access to Contacts.`,
  },
  'crm.objects.contacts.read': {
    name: 'crm.objects.contacts.read',
    description: `Allows read access to Contacts.`,
  },
  'crm.objects.companies.write': {
    name: 'crm.objects.companies.write',
    description: `Allows write access to Companies.`,
  },
  'crm.objects.companies.read': {
    name: 'crm.objects.companies.read',
    description: `Allows read access to Companies.`,
  },
  'crm.objects.deals.write': {
    name: 'crm.objects.deals.write',
    description: `Allows write access to Deals.`,
  },
  'crm.objects.deals.read': {
    name: 'crm.objects.deals.read',
    description: `Allows read access to Deals.`,
  },
  'crm.objects.owners.read': {
    name: 'crm.objects.owners.read',
    description: 'Allows read access to owners',
  },
  'crm.schemas.deals.read': {
    name: 'crm.schemas.deals.read',
    description: 'View details about Deals',
  },
  'crm.schemas.companies.read': {
    name: 'crm.schemas.companies.read',
    description: 'View details about property settings for Companies',
  },
  'crm.schemas.contacts.read': {
    name: 'crm.schemas.contacts.read',
    description: 'View details about property settings for Contacts',
  },
  'crm.schemas.custom.read': {
    name: 'crm.schemas.custom.read',
    description: 'View custom object definitions',
  },
  'crm.objects.custom.read': {
    name: 'crm.objects.custom.read',
    description: 'View custom object records',
  },
  'crm.objects.custom.write': {
    name: 'crm.objects.custom.write',
    description: 'Change custom object records',
  },
  'e-commerce': {
    name: 'e-commerce',
    description: `This includes access to e-commerce features.`,
  },
  files: {
    name: 'files',
    description: `This includes access to File Manager.`,
  },
  forms: {
    name: 'forms',
    description: `This includes access to the Forms endpoints.`,
  },
  'forms-uploaded-files': {
    name: 'forms-uploaded-files',
    description: `Download files submitted through a form.`,
  },
  hubdb: {
    name: 'hubdb',
    description: `This includes access to HubDB.`,
  },
  'integration-sync': {
    name: 'integration-sync',
    description: `This exposes the sync API, which allows syncing of most CRM objects. `,
  },
  oauth: {
    name: 'oauth',
    description: `Basic scope required for OAuth.`,
    required: true,
  },
  'sales-email-read': {
    name: 'sales-email-read',
    description: `Grants access to read all details of one-to-one emails sent to contacts.`,
  },
  social: {
    name: 'social',
    description: `This includes Social Inbox.`,
  },
  tickets: {
    name: 'tickets',
    description: `This includes access to tickets.`,
  },
  timeline: {
    name: 'timeline',
    description: `Grants access to manage custom events on HubSpot CRM records. This includes creating or updating records.`,
  },
  'transactional-email': {
    name: 'transactional-email',
    description: `This includes transactional emails and the transactional emails endpoints.`,
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'HubSpot',
  description: 'Access a user’s HubSpot CRM records.',
  authUrl: 'https://app.hubspot.com/oauth/authorize',
  accessTokenUrl: 'https://api.hubapi.com/oauth/v1/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/hubspot`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://developers.hubspot.com/docs/api/oauth-quickstart-guide',
};
