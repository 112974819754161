import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class EnterpriseError extends ParagonError {
  name: string = 'EnterpriseError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class MissingAuthTokenError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.MISSING_AUTH_TOKEN,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Unable to find a token in file system.',
      meta: {},
    });
  }
}

export class AuthTokenMismatchError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.AUTH_TOKEN_MISMATCH,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'The retrieved token is corrupted.',
      meta: {},
    });
  }
}

export class AuthTokenExpiredError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.AUTH_TOKEN_EXPIRED,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'The retrieved token is expired.',
      meta: {},
    });
  }
}

export class EmbassyUnavailableError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.EMBASSY_UNAVAILABLE,
      httpStatus: HttpStatus.SERVICE_UNAVAILABLE,
      message: 'Paragon is unavailable.',
      meta: {},
    });
  }
}

export class InvalidLicenseError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.INVALID_LICENSE,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Failed to authenticate. Please use a valid license.',
      meta: {},
    });
  }
}

export class InvalidRequestSignatureError extends EnterpriseError {
  constructor(meta: { subCode: number }) {
    super({
      code: ERROR_CODE.INVALID_REQUEST_SIGNATURE,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: `Invalid request signature - network manipulation detected (${meta.subCode}).`,
      meta,
    });
  }
}

export class JWTCreationFailedFromUnknownError extends EnterpriseError {
  constructor(meta: { errorMessage: string }) {
    super({
      code: ERROR_CODE.JWT_CREATION_FAILED_UNKNOWN,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Failed to create authorization token.',
      meta,
    });
  }
}

export class JWTCreationFailedUpstreamServerUnavailableError extends EnterpriseError {
  constructor(meta: { errorMessage: string }) {
    super({
      code: ERROR_CODE.JWT_CREATION_FAILED_UPSTREAM_SERVER_UNAVAILABLE,
      httpStatus: HttpStatus.SERVICE_UNAVAILABLE,
      message: 'Upstream internal server unavailable.',
      meta,
    });
  }
}

export class MissingStripeCustomerIdError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.MISSING_STRIPE_CUSTOMER_ID,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Unable to find a Stripe CustomerId.',
      meta: {},
    });
  }
}

export class LicenseNotFoundError extends EnterpriseError {
  constructor({ licenseId }: { licenseId: string }) {
    super({
      code: ERROR_CODE.LICENSE_NOT_FOUND,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'License not found.',
      meta: { licenseId },
    });
  }
}

export class LicenseNotActiveError extends EnterpriseError {
  constructor({ licenseId }: { licenseId: string }) {
    super({
      code: ERROR_CODE.LICENSE_NOT_ACTIVE,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'This license is not active.',
      meta: { licenseId },
    });
  }
}

export class InvalidAuthHeaderError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.INVALID_AUTHORIZATION_HEADER,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Authorization header is not valid.',
      meta: {},
    });
  }
}

export class TokenNotFoundError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.TOKEN_NOT_FOUND,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Token not found.',
      meta: {},
    });
  }
}

export class TokenPayloadNotFoundError extends EnterpriseError {
  constructor() {
    super({
      code: ERROR_CODE.TOKEN_PAYLOAD_NOT_FOUND,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Token payload not found.',
      meta: {},
    });
  }
}
