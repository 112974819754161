export type CategoriesResponse = {
  id: number;
  parent_id: number;
  name: string;
  is_active: boolean;
  position: number;
  level: number;
  product_count: number;
  children_data: CategoriesResponse[];
};

export type TokenDecodeResponse = {
  uid: number;
  utypid: number;
};

export type LinkWrapperType = {
  clickableText: string;
  href: string;
};

export enum RecordType {
  CUSTOMERS = 'customers',
  ORDERS = 'orders',
  PRODUCTS = 'products',
}

export type CreateOrUpdateCustomerDTO = {
  customerId: string;
  firstname: string;
  lastname: string;
  email: string;
  addresses?: string;
  custom_attributes?: string;
  customerAdditionalFields?: string;
};

export type CreateOrUpdateOrderDTO = {
  entityId: string;
  customer_email: string;
  base_grand_total: string;
  grand_total: string;
  items: string;
  payment: string;
  orderAdditionalFields?: string;
};

export type CreateOrUpdateProductDTO = {
  sku: string;
  id?: string;
  attribute_set_id?: string;
  name?: string;
  type_id?: string;
  price?: string;
  visibility?: string;
  productAdditionalFields?: string;
};

export type CreateRecordDTO =
  | CreateOrUpdateCustomerDTO
  | CreateOrUpdateOrderDTO
  | CreateOrUpdateProductDTO;

export type UpdateRecordDTO = CreateRecordDTO;

export type DeleteRecordDTO = { customerId: string } | { orderId: string } | { productId: string };

export type GetRecordByIdDTO = DeleteRecordDTO;

export type SearchRecordDTO =
  | { customerFilterSearch: string }
  | { orderFilterSearch: string }
  | { productFilterSearch: string };

export type FilterFormulaPayload = {
  field: string;
  value: string;
  condition_type: string;
  filter: number;
  filter_group: number;
};
