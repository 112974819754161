import JSON5 from 'json5';

import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { ISecret } from '@shared/entities/sdk/secret/secret.interface';
import { ActionStep } from '@shared/types/sdk/actions';
import {
  FieldMappingValue,
  IntegrationSharedMeta,
  IntegrationWorkflowMeta,
  IntegrationWorkflowState,
  SerializedConnectInput,
} from '@shared/types/sdk/connect';
import { TypeCoercionError } from '@shared/types/sdk/errors/primitives';
import { FanoutStackEntry, WorkflowExecutionContext } from '@shared/types/sdk/execution';
import {
  CompletedExecution,
  Condition,
  ConditionSource,
  ConditionWrapper,
  ConnectCredentialSource,
  DataType,
  DataTypeValues,
  FanInStrategy,
  FileValue,
  KeyedSource,
  OPERATORS,
  ObjectMappingInput,
  Operator,
  ParsedObjectMapping,
  ResolveContext,
  ResolvedCondition,
  ResolvedConditionWrapper,
  ResolvedKeyedSourceParameters,
  ResolvedSourceParameters,
  SecretSource,
  Source,
  TokenizedSource,
  TokenizedValue,
  ValueSource,
  VariableSource,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import {
  Choice,
  ConditionalStep,
  DelayStep,
  EndpointInput,
  EventInput,
  FunctionStep,
  MapStep,
  RedirectStep,
  RequestAuthorization,
  RequestAuthorizationType,
  RequestStep,
  ResponseStep,
  StateMachine,
  Step,
  StepType,
  Variables,
} from '@shared/types/sdk/steps';
import { isUUID } from '@shared/utils/sdk/generic';

import { getParentFanout, isChildFanoutStack, isStepInFanout } from '../workflow.utils';

import { isValidJSON } from './../workflow.utils';
import { StepResolver } from './abstract.resolver';
import { ActionStepResolver } from './action.resolver';
import { ActionTriggerStepResolver } from './actionTrigger.resolver';
import { ConditionalStepResolver } from './conditional.resolver';
import { CronStepResolver } from './cron.resolver';
import { CustomIntegrationRequestStepResolver } from './customIntegrationRequest.resolver';
import { DelayStepResolver } from './delay.resolver';
import { EndpointStepResolver } from './endpoint.resolver';
import { EventStepResolver } from './event.resolver';
import { FunctionStepResolver } from './function.resolver';
import { IntegrationEnabledStepResolver } from './integrationEnabled.resolver';
import { MapStepResolver } from './map.resolver';
import { OAuthStepResolver } from './oauth.resolver';
import { RedirectStepResolver } from './redirect.resolver';
import { RequestStepResolver } from './request.resolver';
import { ResponseStepResolver } from './response.resolver';
import { UnselectedTriggerStepResolver } from './unselectedTrigger.resolver';

const fromEntries = require('fromentries');
const moment = require('moment');
const validator = require('validator');

export function convertStringDataType(value: any): string | undefined {
  return typeof value === 'string' ? value : undefined;
}

export function convertNumberDataType(value: any): number | undefined {
  return !isNaN(value) ? Number(value) : undefined;
}

export function convertDateDataType(value: any): Date | undefined {
  if (typeof value !== 'string') {
    return undefined;
  }

  const date = moment(value);
  return date.isValid() ? date.toDate() : undefined;
}

export function convertBooleanDataType(value: any): boolean | undefined {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'string') {
    if (value.toLowerCase() === 'true') {
      return true;
    }

    if (value.toLowerCase() === 'false') {
      return false;
    }
  }

  return undefined;
}

export function convertEmailDataType(value: any): string | undefined {
  return typeof value === 'string' && validator.isEmail(value) ? value : undefined;
}

export function convertObjectDataType(value: any): object | undefined {
  return typeof value === 'object' ? value : undefined;
}

export function convertArrayDataType(value: any): any[] | undefined {
  return Array.isArray(value) ? value : undefined;
}

export function convertAnyDataType(value: any): any | undefined {
  return value;
}

export function convertNonDecimalDataType(value: number): number | undefined {
  return Number.isInteger(Number(value)) ? Number(value) : undefined;
}

export function isEmptyCondition(conditionWrapper: ConditionWrapper): boolean {
  if (conditionWrapper.type === 'OPERATOR') {
    return conditionWrapper.condition.operator === Operator.None;
  } else {
    return (
      conditionWrapper.conditions.length === 1 && isEmptyCondition(conditionWrapper.conditions[0])
    );
  }
}

// tslint:disable-next-line:typedef
export function resolveConditionWrapper(
  conditionWrapper: ConditionWrapper,
  variables: WorkflowVariables,
  secrets: Record<string, string>,
  cachedConnectCredential: CachedConnectCredential | null,
  context: ResolveContext | WorkflowExecutionContext,
  cachedPersona: CachedPersona | null,
): ResolvedConditionWrapper {
  switch (conditionWrapper.type) {
    case 'JOIN':
      return {
        ...conditionWrapper,
        conditions: conditionWrapper.conditions.map((condition: ConditionWrapper) =>
          resolveConditionWrapper(
            condition,
            variables,
            secrets,
            cachedConnectCredential,
            context,
            cachedPersona,
          ),
        ),
      };
    case 'OPERATOR':
      return {
        ...conditionWrapper,
        condition: resolveCondition(
          conditionWrapper.condition,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
      };
  }
}

export function resolveCondition(
  condition: Condition,
  variables: WorkflowVariables,
  secrets: Record<string, string>,
  cachedConnectCredential: CachedConnectCredential | null,
  context: ResolveContext | WorkflowExecutionContext,
  cachedPersona: CachedPersona | null,
): ResolvedCondition {
  const { argumentType, variableType } = OPERATORS[condition.operator];

  if (typeof condition.variable === 'object') {
    condition.variable = {
      ...condition.variable,
      dataType: variableType,
    } as Source<DataType.ANY>;
  }

  if ('argument' in condition && typeof condition.argument === 'object') {
    condition.argument = {
      ...condition.argument,
      dataType: argumentType,
    } as Source<DataType.STRING>;
    // The above cast is a workaround for microsoft/TypeScript#10570.
  }

  switch (condition.operator) {
    case Operator.StringContains:
    case Operator.StringDoesNotContain:
    case Operator.StringExactlyMatches:
    case Operator.StringDoesNotExactlyMatch:
    case Operator.StringIsIn:
    case Operator.StringIsNotIn:
    case Operator.StringStartsWith:
    case Operator.StringDoesNotStartWith:
    case Operator.StringEndsWith:
    case Operator.StringDoesNotEndWith:
    case Operator.StringGreaterThan:
    case Operator.StringLessThan:
      return {
        ...condition,
        variable: resolveData(
          condition.variable,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
        argument: resolveData(
          condition.argument,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
      };
    case Operator.NumberGreaterThan:
    case Operator.NumberLessThan:
    case Operator.NumberEquals:
    case Operator.NumberDoesNotEqual:
    case Operator.NumberGreaterThanOrEqualTo:
    case Operator.NumberLessThanOrEqualTo:
      return {
        ...condition,
        variable: resolveData(
          condition.variable,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
        argument: resolveData(
          condition.argument,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
      };
    case Operator.DateTimeAfter:
    case Operator.DateTimeBefore:
    case Operator.DateTimeEquals:
      return {
        ...condition,
        variable: resolveData(
          condition.variable,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
        argument: resolveData(
          condition.argument,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
      };
    case Operator.BooleanTrue:
    case Operator.BooleanFalse:
      return {
        ...condition,
        variable: resolveData(
          condition.variable,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
      };
    case Operator.ArrayIsEmpty:
    case Operator.ArrayIsNotEmpty:
      return {
        ...condition,
        variable: resolveData(
          condition.variable,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
      };
    case Operator.Exists:
    case Operator.DoesNotExist:
    case Operator.IsNull:
    case Operator.IsNotNull:
    case Operator.None:
      return {
        ...condition,
        variable: resolveData(
          condition.variable,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        ),
      };
  }
}

export const DATA_TYPE_CONVERTERS: Record<DataType, (value: any) => any | undefined> = {
  [DataType.STRING]: convertStringDataType,
  [DataType.NUMBER]: convertNumberDataType,
  [DataType.DATE]: convertDateDataType,
  [DataType.BOOLEAN]: convertBooleanDataType,
  [DataType.EMAIL]: convertEmailDataType,
  [DataType.OBJECT]: convertObjectDataType,
  [DataType.ARRAY]: convertArrayDataType,
  [DataType.ANY]: convertAnyDataType,
  [DataType.FILE]: convertObjectDataType,
  [DataType.NON_DECIMAL]: convertNonDecimalDataType,
};

function tryStringifyJSON(obj: any): string | undefined {
  try {
    return JSON.stringify(obj, null, 2);
  } catch {
    return undefined;
  }
}

export function coerceStringDataType(value: any): string | undefined {
  const stringifyAttempt = tryStringifyJSON(value);
  if (value === undefined) {
    return 'undefined';
  } else if (value === null) {
    return 'null';
  } else if (typeof value === 'string') {
    return value;
  } else if (value instanceof Date || typeof value !== 'object') {
    return value.toString();
  }

  return stringifyAttempt;
}

export function coerceNumberDataType(value: any): number | undefined {
  if (value === undefined) {
    return undefined;
  } else if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'string' && !isNaN(Number(value))) {
    return parseFloat(value);
  } else if (value instanceof Date) {
    return value.getTime();
  }

  return undefined;
}

export function coerceDateDataType(value: any): Date | undefined {
  return value instanceof Date
    ? value
    : !isNaN(new Date(value).getTime())
    ? new Date(value)
    : undefined;
}

export function coerceBooleanDataType(value: any): boolean | undefined {
  return typeof value === 'boolean' ? value : undefined;
}

export function coerceEmailDataType(value: any): string | undefined {
  return typeof value === 'string' ? value : undefined;
}

export function coerceObjectDataType(value: any): object | undefined {
  return typeof value === 'object' ? value : undefined;
}

export function coerceFileDataType(value: any): FileValue | undefined {
  return value?.dataType === 'FILE' ? value : undefined;
}

export function coerceArrayDataType(value: any): any[] | undefined {
  return Array.isArray(value) ? value : undefined;
}

export function coerceAnyDataType(value: any): any | undefined {
  if (value === undefined) {
    return null;
  }
  return value;
}

export function coerceNonDecimalDataType(value: any): number | undefined {
  if (typeof value === 'number' && Number.isInteger(value)) {
    return value;
  } else if (
    typeof value === 'string' &&
    !isNaN(Number(value)) &&
    Number.isInteger(Number(value))
  ) {
    return Number(value);
  }

  return undefined;
}

export const DATA_TYPE_COERCERS: Record<DataType, (value: any) => any | undefined> = {
  [DataType.STRING]: coerceStringDataType,
  [DataType.NUMBER]: coerceNumberDataType,
  [DataType.DATE]: coerceDateDataType,
  [DataType.BOOLEAN]: coerceBooleanDataType,
  [DataType.EMAIL]: coerceEmailDataType,
  [DataType.OBJECT]: coerceObjectDataType,
  [DataType.ARRAY]: coerceArrayDataType,
  [DataType.ANY]: coerceAnyDataType,
  [DataType.FILE]: coerceFileDataType,
  [DataType.NON_DECIMAL]: coerceNonDecimalDataType,
};

export function formatVariablePath(path: string[]): string {
  return path.join('.');
}

export function formatConnectCredentialKey(key: string): string {
  return key.replace(/\s+(.)/g, (_match: string, group: string) => {
    return group.toUpperCase();
  });
}

// input -> 1.data.value
// output -> [ "data", "value" ]
// * ignores the first key b/c it represents the step
export function stringToVariablePath(input: string): string[] {
  const split: string[] = input.split('.');
  split.splice(0, 1);
  return split;
}

export function flatten<T>(input: T[][]): T[] {
  let output: T[] = [];
  input.forEach((item: T[]) => {
    output = [...output, ...item];
  });
  return output;
}

export function findSecretSourceInKeyedSources(keyedSources: KeyedSource[]): SecretSource[] {
  // some old request steps have the incorrect source types for their url
  // in which case the `keyedSources` passed here is undefined + throws an error
  const secretSources = (keyedSources || [])
    .filter(
      (keyedSource: KeyedSource) =>
        keyedSource.source.type === 'ENVIRONMENT_SECRET' ||
        keyedSource.source.type === 'TOKENIZED' ||
        keyedSource.source.type === 'CONDITION',
    )
    .map((keyedSource: KeyedSource) =>
      keyedSource.source.type === 'ENVIRONMENT_SECRET'
        ? [keyedSource.source]
        : keyedSource.source.type === 'TOKENIZED'
        ? findSecretSourceInTokenizedSource(keyedSource.source as TokenizedSource)
        : keyedSource.source.type === 'CONDITION'
        ? findSecretSourceInConditionWrapper(keyedSource.source.condition)
        : [],
    );
  return flatten(secretSources);
}

export function findSecretSourceInConditionWrapper(
  conditionWrapper: ConditionWrapper,
): SecretSource[] {
  switch (conditionWrapper.type) {
    case 'JOIN':
      return flatten(
        conditionWrapper.conditions.map((condition: ConditionWrapper) =>
          findSecretSourceInConditionWrapper(condition),
        ),
      );
    case 'OPERATOR':
      const sources: SecretSource[] = [];
      if (conditionWrapper.condition.variable.type === 'ENVIRONMENT_SECRET') {
        sources.push(conditionWrapper.condition.variable);
      }
      if (
        'argument' in conditionWrapper.condition &&
        conditionWrapper.condition.argument.type === 'ENVIRONMENT_SECRET'
      ) {
        sources.push(conditionWrapper.condition.argument);
      }
      return sources;
  }
}

export function findSecretSourceInTokenizedSource(source: TokenizedSource): SecretSource[] {
  return source.parts
    .filter((part: TokenizedValue): part is SecretSource => part.type === 'ENVIRONMENT_SECRET')
    .map((part: SecretSource) => part);
}

export function coerceSourceOrThrow<T extends DataType>(source: ValueSource<T>): DataTypeValues[T];
export function coerceSourceOrThrow<T extends DataType>(
  source: VariableSource<T>,
  resolvedValue: any,
): DataTypeValues[T];
export function coerceSourceOrThrow<T extends DataType>(
  source: ValueSource<T> | VariableSource<T>,
  resolvedValue?: any,
): DataTypeValues[T] {
  if (resolvedValue === undefined && source.type === 'VALUE') {
    resolvedValue = source.value;
  }
  // The `dataType` attribute is a recent introduction; legacy steps don't have it, so we need
  // compatibility with them too. Once we figure out step definition migration and migrate to the
  // new schema, we can assume `dataType` is always defined here.
  if (source.dataType) {
    const coercedData = DATA_TYPE_COERCERS[source.dataType](resolvedValue);
    if (coercedData === undefined) {
      throw new TypeCoercionError(
        resolvedValue,
        source.dataType,
        'path' in source ? source.path : undefined,
      );
    }
    return coercedData;
  }
  return resolvedValue;
}

/**
 * sorts executions by fanout stack
 * executions in a fanout aren't guaranteed to resolve in the same order they were queued
 * here we sort them based on the fanout stack entry indexes
 * so that when they're resolved as variables, they're in the correct order
 * @param executions
 */
export function sortExecutionsByFanout(
  execution1: CompletedExecution,
  execution2: CompletedExecution,
): number {
  const fanoutStack1: FanoutStackEntry[] = execution1.fanoutStack || [];
  const fanoutStack2: FanoutStackEntry[] = execution2.fanoutStack || [];

  for (let i = 0; i < fanoutStack1.length; i++) {
    const entry1: FanoutStackEntry = fanoutStack1[i];
    const entry2: FanoutStackEntry = fanoutStack2[i];
    if (entry1.index < entry2.index) {
      return -1;
    } else if (entry1.index > entry2.index) {
      return 1;
    }
  }

  // in the case that the fanout stacks are identical,
  // we want to sort them by the latest execution
  // this is particularly important for step tests using `awaitingPayload`
  // we need to return the last execution as it has the populated variables
  return (execution1.start || 0) - (execution2.start || 0);
}

function traversePath(object: object, path: string[]): any {
  return path.length > 0
    ? path.reduce(
        (obj: any, pathElement: string) =>
          typeof obj === 'object' && obj !== null && pathElement in obj
            ? obj[pathElement]
            : undefined,
        object,
      )
    : object;
}

export function resolveData<T extends DataType>(
  source: Source<T>,
  variables: WorkflowVariables,
  secrets: Record<string, string>,
  cachedConnectCredential: CachedConnectCredential | null,
  context: ResolveContext | WorkflowExecutionContext,
  cachedPersona: CachedPersona | null,
  objectVariables: Record<string, Record<string, unknown>> = {},
): DataTypeValues[T] | DataTypeValues[T][] | ResolvedConditionWrapper {
  if (['number', 'boolean', 'string'].includes(typeof source)) {
    return source as DataTypeValues[T];
  } else if (source.type === 'VALUE') {
    try {
      return coerceSourceOrThrow(source);
    } catch {
      // TODO(PARA-1548): Remove this when there are no more valid mismatched ValueSources
      return source.value;
    }
  } else if (source.type === 'TOKENIZED') {
    const dataType = source.dataType || DataType.STRING;
    const parts = source.parts;
    if (parts.length === 1 && parts[0].type !== 'VALUE') {
      const [firstPart] = parts;
      return resolveData(
        firstPart.type === 'VARIABLE'
          ? ({ ...firstPart, dataType: dataType || firstPart.dataType } as TokenizedValue)
          : firstPart,
        variables,
        secrets,
        cachedConnectCredential,
        context,
        cachedPersona,
        objectVariables,
      );
    }

    const resolvedStringParts = parts.map((part: TokenizedValue) =>
      resolveData(
        part.type === 'ENVIRONMENT_SECRET' ||
          part.type === 'CONNECT_CREDENTIAL_FIELD' ||
          part.type === 'OBJECT_VALUE' ||
          part.type === 'PERSONA_METADATA'
          ? part
          : { ...part, dataType: DataType.STRING },
        variables,
        secrets,
        cachedConnectCredential,
        context,
        cachedPersona,
        objectVariables,
      ),
    );
    const resolvedString = parts.reduce((resolved: string, part: Source, index: number) => {
      const currentResolvedItem = resolvedStringParts[index];
      const [previousPart, nextPart] = [
        resolvedStringParts[index - 1],
        resolvedStringParts[index + 1],
      ];
      const wrappedInQuotes =
        typeof previousPart === 'string' &&
        typeof nextPart === 'string' &&
        previousPart.match(/['"]$/) &&
        nextPart.match(/^['"]/);
      const shouldWrapValueInQuotes =
        (!wrappedInQuotes &&
          source.dataType &&
          [DataType.ARRAY, DataType.OBJECT].includes(source.dataType) &&
          part.type === 'VARIABLE' &&
          part.dataType === DataType.STRING) ||
        typeof resolvedStringParts[index] === 'object';

      return `${resolved}${
        shouldWrapValueInQuotes ? JSON.stringify(currentResolvedItem) : currentResolvedItem
      }`;
    }, '');
    if (dataType === DataType.NUMBER && Number.isFinite(Number(resolvedString))) {
      return Number(resolvedString) as DataTypeValues[T];
    }
    if (
      dataType === DataType.BOOLEAN &&
      (resolvedString === 'true' || resolvedString === 'false')
    ) {
      return (resolvedString === 'true' ? true : false) as DataTypeValues[T];
    }
    if (
      source.dataType &&
      [DataType.ARRAY, DataType.OBJECT].includes(source.dataType) &&
      (resolvedString.startsWith('{') || resolvedString.startsWith('['))
    ) {
      try {
        const resolvedObject = JSON5.parse(resolvedString);
        return resolvedObject as DataTypeValues[T];
      } catch {}
    }
    return resolvedString as DataTypeValues[T];
  } else if (source.type === 'USER_SUPPLIED_CREDENTIAL') {
    return source.credential.parts.reduce(
      (resolved: string, part: TokenizedValue) =>
        `${resolved}${resolveData(
          part,
          variables,
          secrets,
          cachedConnectCredential,
          context,
          cachedPersona,
        )}`,
      '',
    ) as DataTypeValues[T];
  } else if (source.type === 'CONDITION') {
    return resolveConditionWrapper(
      source.condition,
      variables,
      secrets,
      cachedConnectCredential,
      context,
      cachedPersona,
    );
  } else if (source.type === 'ENVIRONMENT_SECRET') {
    if (source.environmentSecretId in secrets) {
      // Dashboard has empty secret value, returning template string in that case i.e.: `••••••••••••••••••`
      return (secrets[source.environmentSecretId] || '••••••••••••••••••') as DataTypeValues[T];
    }
    throw new Error('Environment secret not found in project');
  } else if (source.type === 'OBJECT_VALUE') {
    return traversePath(objectVariables[source.name], source.path.slice(1)) as DataTypeValues[T];
  } else if (source.type === 'CONNECT_CREDENTIAL_FIELD') {
    if (!cachedConnectCredential) {
      throw new Error(`"${source.type}" was used, but no ConnectCredential was supplied.`);
    }
    switch (source.fieldType) {
      case 'EXTERNAL_USER_ID':
        return cachedConnectCredential.endUserId as DataTypeValues[T];
      case 'EXTERNAL_USER_PROVIDER_ID':
        return cachedConnectCredential.providerId as DataTypeValues[T];
      case 'EXTERNAL_USER_PROVIDER_DATA':
        return cachedConnectCredential.providerData?.[source.dataKey] as DataTypeValues[T];
      case 'WORKFLOW_SETTING':
        return traversePath(
          (
            cachedConnectCredential.config?.configuredWorkflows as {
              [workflowId: string]: IntegrationWorkflowState;
            }
          )?.[context.workflowId]?.settings?.[source.inputId] as DataTypeValues[T],
          source.path ?? [],
        );
      case 'SHARED_WORKFLOW_SETTING':
        return traversePath(
          cachedConnectCredential.config?.sharedSettings?.[source.inputId] as DataTypeValues[T],
          source.path ?? [],
        );
      case 'OAUTH_ACCESS_TOKEN':
        // This type of source must be resolved by `resolveCustomIntegrationSource`, because
        // `resolveData` does not get access to a decrypted connect credential.
        throw new Error('OAuth access token cannot be resolved from this function');
    }
  } else if (source.type === 'PERSONA_METADATA') {
    return traversePath(cachedPersona as DataTypeValues[T], source.path as string[]);
  } else if (source.type === 'CACHED_EXECUTION') {
    // TODO: rremove deprecated references to `CACHED_EXECUTION`
    throw new Error('"CACHED_EXECUTION" not supported in data resolver.');
  } else if (source.type === 'EXECUTION') {
    // TODO: use custom error
    throw new Error('"EXECUTION" not supported in data resolver.');
  } else if (source.type === 'FANOUT_EXECUTION') {
    // TODO: use custom error
    throw new Error('"FANOUT_EXECUTION" not supported in data resolver.');
  }

  const { stateMachine } = context;

  // if stateMachine is undefined then it is used outside workflow editor
  if (!stateMachine) {
    return undefined as DataTypeValues[T];
  }

  const executingStep: Step = stateMachine.stepMap[context.stepId];
  const variableStep: Step = stateMachine.stepMap[source.stepId];

  const strategy: FanInStrategy =
    executingStep && variableStep
      ? getFanInStrategy(executingStep, variableStep, stateMachine)
      : FanInStrategy.SINGLE;

  let filteredExecutions: CompletedExecution[];
  if (strategy === FanInStrategy.SINGLE) {
    filteredExecutions = Object.values(variables)
      .filter((execution: CompletedExecution) => !!execution)
      .filter((execution: CompletedExecution): boolean => execution.stepId === source.stepId);
  } else if (strategy === FanInStrategy.SINGLE_BY_FANOUT) {
    filteredExecutions = Object.values(variables)
      .filter((execution: CompletedExecution): boolean => !!execution)
      .filter((execution: CompletedExecution): boolean => {
        if (execution.stepId !== source.stepId) {
          return false;
        } else if (
          context.fanoutStack !== undefined &&
          !isChildFanoutStack(execution.fanoutStack, context.fanoutStack)
        ) {
          return false;
        }

        return true;
      });
  } else if (strategy === FanInStrategy.MULTI) {
    filteredExecutions = Object.values(variables)
      .filter((execution: CompletedExecution): boolean => !!execution)
      .filter((execution: CompletedExecution) => execution.stepId === source.stepId);
  } else if (strategy === FanInStrategy.MULTI_BY_FANOUT) {
    filteredExecutions = Object.values(variables)
      .filter((execution: CompletedExecution): boolean => !!execution)
      .filter((execution: CompletedExecution) => {
        if (execution.stepId !== source.stepId) {
          return false;
        } else if (
          context.fanoutStack !== undefined &&
          !isChildFanoutStack(context.fanoutStack, execution.fanoutStack)
        ) {
          return false;
        }

        return true;
      });
  } else {
    throw new Error(`Unknown fan in strategy detected: ${strategy}`);
  }

  if (!filteredExecutions.length && (context as WorkflowExecutionContext).testing) {
    filteredExecutions = Object.values(variables)
      .filter((execution: CompletedExecution): boolean => !!execution)
      .filter((execution: CompletedExecution): boolean => execution.stepId === source.stepId);
  }

  const filteredVariables: Variables[] = filteredExecutions
    .sort(sortExecutionsByFanout)
    .map((e: CompletedExecution) => e.output);

  const data: any[] = filteredVariables.map((value: Variables) => {
    const instance: unknown = traversePath(value, source.path);
    return coerceSourceOrThrow(source, instance);
  });

  if (strategy === FanInStrategy.SINGLE || strategy === FanInStrategy.SINGLE_BY_FANOUT) {
    return data[data.length - 1];
  } else {
    return data;
  }
}

/**
 * this will resolve source that is used in GenericTokentizedInput
 * @param source
 * @param objectVariables
 * @returns
 */
export function resolveGenericTokenizedData<T extends DataType>(
  source: Source<T>,
  objectVariables: Record<string, Record<string, unknown>>,
): DataTypeValues[T] | DataTypeValues[T][] | ResolvedConditionWrapper {
  return resolveData(
    source,
    {},
    {},
    null,
    {} as WorkflowExecutionContext,
    { meta: null },
    objectVariables,
  );
}

export function resolveNestedData(
  root: Record<string, Source>,
  variables: WorkflowVariables,
  secrets: Record<string, string>,
  cachedConnectCredential: CachedConnectCredential | null,
  context: ResolveContext | WorkflowExecutionContext,
  cachedPersona: CachedPersona | null,
): object {
  const resolvedEntries = Object.entries(root).map(([name, source]: [string, Source]) => [
    name,
    resolveData(source, variables, secrets, cachedConnectCredential, context, cachedPersona),
  ]);
  return fromEntries(resolvedEntries);
}

// Replace the values of the keys of `sourceKeys` in `parameters` (which must be of type
// Source<D extends DataType>) with their variable-resolved equivalents.
// So if P is
// {
//   foo: Source<string>;
//   bar: Source<number>;
//   baz: boolean;
// }
// , you'll get a result of type
// {
//   foo: string;
//   bar: number;
//   baz: boolean;
// }
export function resolveSources<P, S extends keyof P>(
  variables: WorkflowVariables,
  parameters: P,
  secrets: Record<string, string>,
  cachedConnectCredential: CachedConnectCredential | null,
  context: ResolveContext | WorkflowExecutionContext,
  cachedPersona: CachedPersona | null,
  ...sourceKeys: S[]
): ResolvedSourceParameters<P, S> {
  return fromEntries(
    Object.entries(parameters).map(([key, value]: [string, any]) => [
      key,
      (sourceKeys as string[]).indexOf(key) !== -1
        ? resolveData(value, variables, secrets, cachedConnectCredential, context, cachedPersona)
        : value,
    ]),
  );
}

export function keyedSourcesToObject<T extends DataType>(
  sources: KeyedSource<T>[],
): Record<string, Source<T>> {
  return fromEntries(sources.map(({ key, source }: KeyedSource<T>) => [key, source]));
}

// Replace the values of the keys of `sourceKeys` in `parameters` (which must be of type
// KeyedSource<D extends DataType>) with their variable-resolved equivalents.
// So if P is
// {
//   foo: KeyedSource<string>[];
//   bar: KeyedSource<number>[];
//   baz: boolean;
// }
// , you'll get a result of type
// {
//   foo: Record<string, string>;
//   bar: Record<string, number>;
//   baz: boolean;
// }
export function resolveKeyedSources<P, S extends keyof P>(
  variables: WorkflowVariables,
  parameters: P,
  secrets: Record<string, string>,
  cachedConnectCredential: CachedConnectCredential | null,
  context: ResolveContext | WorkflowExecutionContext,
  cachedPersona: CachedPersona | null,
  ...sourceKeys: S[]
): ResolvedKeyedSourceParameters<P, S> {
  return fromEntries(
    Object.entries(parameters).map(([key, value]: [string, any]) => [
      key,
      (sourceKeys as string[]).indexOf(key) !== -1
        ? resolveNestedData(
            keyedSourcesToObject(value),
            variables,
            secrets,
            cachedConnectCredential,
            context,
            cachedPersona,
          )
        : value,
    ]),
  );
}

export function getFanInStrategy(
  executingStep: Step,
  variableStep: Step,
  stateMachine: StateMachine,
): FanInStrategy {
  const executingStepParentFanout: MapStep | undefined =
    executingStep.type === StepType.MAP
      ? (executingStep as MapStep)
      : getParentFanout(executingStep.id, stateMachine);
  const variableStepParentFanout: MapStep | undefined =
    variableStep.type === StepType.MAP
      ? (variableStep as MapStep)
      : getParentFanout(variableStep.id, stateMachine);

  const isExecutingStepInVariableStepParentFanout: boolean = variableStepParentFanout
    ? isStepInFanout(executingStep, variableStepParentFanout, stateMachine)
    : true;
  const isVariableStepInExecutingStepParentFanout: boolean = executingStepParentFanout
    ? isStepInFanout(variableStep, executingStepParentFanout, stateMachine)
    : true;

  if (!variableStepParentFanout) {
    // in this case neither step is in a fanout, so we only want the 1 execution
    // and there's no fanout step to to filter executions by
    return FanInStrategy.SINGLE;
  } else if (
    executingStepParentFanout &&
    variableStepParentFanout &&
    isExecutingStepInVariableStepParentFanout
  ) {
    // in this case both steps are in the same fanout
    // so we need to filter variables
    return FanInStrategy.SINGLE_BY_FANOUT;
  } else if (
    executingStepParentFanout &&
    variableStepParentFanout &&
    isExecutingStepInVariableStepParentFanout &&
    !isVariableStepInExecutingStepParentFanout
  ) {
    // in this case both steps are in the same fanout
    // so we need to filter variables
    return FanInStrategy.SINGLE_BY_FANOUT;
  } else if (!executingStepParentFanout && variableStepParentFanout) {
    // in this case the downstream step isn't in a fanout but the upstream variable step is
    // so we're going to fan in all of the executions of it without filtering by fanout execution
    return FanInStrategy.MULTI;
  } else if (
    executingStepParentFanout &&
    variableStepParentFanout &&
    !isVariableStepInExecutingStepParentFanout
  ) {
    return FanInStrategy.MULTI;
  } else if (
    executingStepParentFanout &&
    variableStepParentFanout &&
    isVariableStepInExecutingStepParentFanout &&
    !isExecutingStepInVariableStepParentFanout
  ) {
    return FanInStrategy.MULTI_BY_FANOUT;
  } else {
    // TODO: create Paragon error for building fan in strategy
    throw new Error('Unable to build fan in strategy');
  }
}

enum TokenizedIdentifiers {
  START = '__TOKENIZED_START__',
  END = '__TOKENIZED_END__',
}

// given a string, it breaks it into an array of strings with identifiers prefixing + postfixing
// the tokenized parts
// example:
//   in -> "https://dev.useparagon.com/{{ 2.result }}"
//   out -> [ "https://dev.useparagon.com/", "__TOKENIZED_START__2.result__TOKENIZED_END__" ]
export function tokenizedStringToParts(input: string): string[] {
  const parts: string[] = [];
  let c: number = 0;
  input
    .split(/({{|}})/)
    .forEach((e: string) =>
      e == '{{'
        ? c++
        : e == '}}' && c > 0
        ? c--
        : c > 0
        ? parts.push(TokenizedIdentifiers.START + e + TokenizedIdentifiers.END)
        : e && parts.push(e),
    );
  return parts;
}

// Given a string, it builds a TokenizedSource
// so if `value` is
//    "https://dev.useparagon.com/{{ 2.result }}"
// , it could return:
// {
//    dataType: DataType.STRING,
//    type: "TOKENIZED",
//    parts: [{
//      dataType: DataType.STRING,
//      type: "VALUE",
//      value: "https://dev.useparagon.com/"
//    }, {
//      dataType: DataType.STRING,
//      type: "VARIABLE",
//      stepId: "step-123",
//      path: [ "result" ]
//    }]
// }
export function tokenizedStringToTokenizedSource<T extends DataType>(
  input: string,
  upstreamSteps: Step[],
  secrets: Record<string, ISecret>,
  integrationWorkflowMeta: IntegrationWorkflowMeta,
  integrationSharedMeta: IntegrationSharedMeta,
  dataType?: DataType,
): TokenizedSource<DataTypeValues[T]> {
  const parts: string[] = tokenizedStringToParts(input);
  return {
    dataType,
    type: 'TOKENIZED',
    parts: parts.map((part: string): TokenizedValue<DataTypeValues[T]> => {
      if (part.startsWith(TokenizedIdentifiers.START)) {
        const sanitizedPart: string = part
          .replace(TokenizedIdentifiers.START, '')
          .replace(TokenizedIdentifiers.END, '');
        const unverifiedUpstreamStepIndex: string = sanitizedPart.split('.')[0];
        const upstreamStepIndex: number = !isNaN(parseInt(unverifiedUpstreamStepIndex))
          ? parseInt(unverifiedUpstreamStepIndex)
          : -1;
        const variablePath: string[] = stringToVariablePath(sanitizedPart);
        const step: Step | undefined = upstreamSteps.find(
          (_step: Step, index: number) => index === upstreamStepIndex - 1,
        );

        if (upstreamStepIndex === -1) {
          if (unverifiedUpstreamStepIndex === 'env') {
            const key: string | undefined = sanitizedPart.split('.')[1];
            const secret: ISecret | undefined = Object.values(secrets).find(
              (secret: ISecret) => secret.key === key || secret.key.split(' ').join('_') === key,
            );
            return {
              type: 'ENVIRONMENT_SECRET',
              environmentSecretId: secret ? secret.hash || secret.id : key,
            };
          } else if (unverifiedUpstreamStepIndex === 'meta') {
            const metadataPath = sanitizedPart.split('.');
            return {
              type: 'PERSONA_METADATA',
              path: metadataPath,
            };
          } else if (unverifiedUpstreamStepIndex === '$') {
            const [, ...path] = sanitizedPart.split('.');
            return {
              type: 'OBJECT_VALUE',
              path: path,
              name: path[0],
            };
          } else if (
            unverifiedUpstreamStepIndex === 'settings' ||
            unverifiedUpstreamStepIndex === 'userSettings'
          ) {
            const [, key, ...path] = sanitizedPart.split('.');
            if (key === 'userId') {
              return {
                type: 'CONNECT_CREDENTIAL_FIELD',
                fieldType: 'EXTERNAL_USER_ID',
              };
            } else if (key === 'providerId') {
              return {
                type: 'CONNECT_CREDENTIAL_FIELD',
                fieldType: 'EXTERNAL_USER_PROVIDER_ID',
              };
            } else if (key === 'oauthAccessToken') {
              return {
                type: 'CONNECT_CREDENTIAL_FIELD',
                fieldType: 'OAUTH_ACCESS_TOKEN',
              };
            } else if (key === 'providerData') {
              return {
                type: 'CONNECT_CREDENTIAL_FIELD',
                fieldType: 'EXTERNAL_USER_PROVIDER_DATA',
                dataKey: path[path.length - 1],
              };
            }

            const input: SerializedConnectInput | undefined = (
              unverifiedUpstreamStepIndex === 'userSettings'
                ? integrationSharedMeta
                : integrationWorkflowMeta
            )?.inputs?.find(
              (input: SerializedConnectInput) => formatConnectCredentialKey(input.title) === key,
            );
            return {
              type: 'CONNECT_CREDENTIAL_FIELD',
              fieldType:
                unverifiedUpstreamStepIndex === 'userSettings'
                  ? 'SHARED_WORKFLOW_SETTING'
                  : 'WORKFLOW_SETTING',
              inputId: input ? input.id : unverifiedUpstreamStepIndex,
              path,
            };
          }
        }

        return {
          type: 'VARIABLE',
          stepId: step ? step.id : unverifiedUpstreamStepIndex,
          path: variablePath,
        } as DataTypeValues[T];
      } else {
        let dataType: string = DataType.STRING;
        if (isValidJSON(part)) {
          dataType = DataType.OBJECT;
        }

        return {
          dataType,
          type: 'VALUE',
          value: part,
        } as DataTypeValues[T];
      }
    }),
  } as DataTypeValues[T];
}

export function sourceToTokenizedString(
  _source: Source | string | number | boolean,
  upstreamSteps: Step[],
  secrets: Record<string, ISecret>,
  integrationWorkflowMeta: IntegrationWorkflowMeta,
  integrationSharedMeta: IntegrationSharedMeta,
): string {
  let source: Source;
  if (typeof _source === 'string' || typeof _source === 'number' || typeof _source === 'boolean') {
    source = {
      type: 'VALUE',
      value: _source,
    };
  } else {
    source = _source;
  }

  if (source.type === 'CONDITION') {
    // ConditionSource not supported for tokenization
    return '';
  } else if (source.type === 'USER_SUPPLIED_CREDENTIAL') {
    // UserSuppliedCredentialSource should not appear in user-selectable output
    return '';
  } else if (source.type === 'TOKENIZED') {
    return tokenizedSourceToTokenizedString(
      source,
      upstreamSteps,
      secrets,
      integrationWorkflowMeta,
      integrationSharedMeta,
    );
  } else if (source.type === 'CACHED_EXECUTION') {
    // TODO: remove deprecated references to `CACHED_EXECUTION`
    throw new Error('"CACHED_EXECUTION" not supported in data resolver.');
  } else if (source.type === 'EXECUTION') {
    // TODO: use custom error
    throw new Error('"EXECUTION" not supported in data resolver.');
  } else if (source.type === 'FANOUT_EXECUTION') {
    // TODO: use custom error
    throw new Error('"FANOUT_EXECUTION" not supported in data resolver.');
  }

  const tokenizedSource: TokenizedSource = {
    type: 'TOKENIZED',
    parts: [source],
  };
  return tokenizedSourceToTokenizedString(
    tokenizedSource,
    upstreamSteps,
    secrets,
    integrationWorkflowMeta,
    integrationSharedMeta,
  );
}

// Given a TokenizedSource, it builds a tokenized string
// so if `value` is
// {
//    dataType: DataType.STRING,
//    type: "TOKENIZED",
//    parts: [{
//      dataType: DataType.STRING,
//      type: "VALUE",
//      value: "https://dev.useparagon.com/"
//    }, {
//      dataType: DataType.STRING,
//      type: "VARIABLE",
//      stepId: "step-123",
//      path: [ "result" ]
//    }]
// , it could return:
//    "https://dev.useparagon.com/{{ 2.result }}"
// }
export function tokenizedSourceToTokenizedString(
  token: TokenizedSource,
  upstreamSteps: Step[],
  secrets: Record<string, ISecret>,
  integrationWorkflowMeta: IntegrationWorkflowMeta,
  integrationSharedMeta: IntegrationSharedMeta,
): string {
  // adds support for legacy types stored as primitives
  if (['number', 'boolean', 'string'].includes(typeof token)) {
    // @ts-ignore
    return token as string;
  }

  // TODO: this shouldn't be wrapped in an array
  return ((token || {}).parts || []).reduce((joined: string, part: TokenizedValue): string => {
    if (part.type === 'VALUE') {
      return `${joined}${part.value}`;
    } else if (part.type === 'VARIABLE') {
      const upstreamStepIndex: number = upstreamSteps.findIndex(
        (step: Step) => step.id === part.stepId,
      );
      const stepId: string = upstreamStepIndex !== -1 ? `${upstreamStepIndex + 1}` : part.stepId;
      return `${joined}{{${[stepId, ...part.path].join('.')}}}`;
    } else if (part.type === 'ENVIRONMENT_SECRET') {
      const secret: ISecret | undefined = isUUID(part.environmentSecretId)
        ? secrets[part.environmentSecretId]
        : Object.values(secrets).find(({ hash }) => hash === part.environmentSecretId);
      return secret
        ? `${joined}{{env.${secret.key.split(' ').join('_')}}}`
        : `${joined}{{env.${part.environmentSecretId}}}`;
    } else if (part.type === 'OBJECT_VALUE') {
      return `${joined}{{$.${part.path.join('.')}}}`;
    } else if (part.type === 'CONNECT_CREDENTIAL_FIELD') {
      if (part.fieldType === 'EXTERNAL_USER_ID') {
        return `${joined}{{userSettings.userId}}`;
      } else if (part.fieldType === 'EXTERNAL_USER_PROVIDER_ID') {
        return `${joined}{{userSettings.providerId}}`;
      } else if (part.fieldType === 'EXTERNAL_USER_PROVIDER_DATA') {
        return `${joined}{{userSettings.providerData.${part.dataKey}}}`;
      } else if (part.fieldType === 'OAUTH_ACCESS_TOKEN') {
        return `${joined}{{settings.oauthAccessToken}}`;
      }
      const prefix = part.fieldType === 'SHARED_WORKFLOW_SETTING' ? 'userSettings' : 'settings';
      const input = (
        part.fieldType === 'SHARED_WORKFLOW_SETTING'
          ? integrationSharedMeta
          : integrationWorkflowMeta
      )?.inputs?.find((input: SerializedConnectInput) => input.id === part.inputId);

      return input
        ? `${joined}{{${prefix}.${formatConnectCredentialKey(input.title)}${[
            '',
            ...(part.path ?? []),
          ].join('.')}}}`
        : joined;
    } else if (part.type === 'PERSONA_METADATA') {
      return `${joined}{{${[...(part.path ?? [])].join('.')}}}`;
    } else {
      throw new Error(`Unknown Source type: ${part['type']}`);
    }
  }, '');
}

export type RenameVariablePayload = {
  stepId: string;
  path: string[]; // path leading to variable e.g ['request','params'] leading to these variables
  oldVariableName: string;
  newVariableName: string;
};

export const RenameVariableResolvers: Record<StepType, Function> = {
  [StepType.ACTION]: renameActionStepVariable,
  [StepType.DELAY]: renameDelayStepVariable,
  [StepType.FUNCTION]: renameFunctionStepVariable,
  [StepType.REQUEST]: renameRequestStepVariable,
  [StepType.CUSTOM_INTEGRATION_REQUEST]: renameRequestStepVariable,
  [StepType.REDIRECT]: renameRedirectStepVariable,
  [StepType.RESPONSE]: renameResponseStepVariable,
  [StepType.IFELSE]: renameConditionalStepVariable,
  [StepType.MAP]: renameMapStepVariable,
  [StepType.UNSELECTED_TRIGGER]: () => {},
  [StepType.CRON]: () => {}, // doesn't need as triggers don't have variables
  [StepType.ENDPOINT]: () => {},
  [StepType.OAUTH]: () => {},
  [StepType.ACTION_TRIGGER]: () => {},
  [StepType.EVENT]: () => {},
  [StepType.INTEGRATION_ENABLED]: () => {},
};

function renameFunctionStepVariable(
  step: FunctionStep,
  renameVariablePayload: RenameVariablePayload,
): FunctionStep {
  const params = updateKeyedSource(step.parameters.parameters, renameVariablePayload);
  return {
    ...step,
    parameters: {
      ...step.parameters,
      parameters: params,
    },
  };
}

function renameActionStepVariable(
  step: ActionStep,
  renameVariablePayload: RenameVariablePayload,
): ActionStep {
  const actionParameters = updateKeyedSource(
    step.parameters.actionParameters,
    renameVariablePayload,
  ) as KeyedSource<DataType.ANY>[];
  return {
    ...step,
    parameters: {
      ...step.parameters,
      actionParameters,
    },
  };
}

function renameDelayStepVariable(
  step: DelayStep,
  renameVariablePayload: RenameVariablePayload,
): DelayStep {
  const updatedSource = renameVariableNameInSource(step.parameters.value, renameVariablePayload);
  if (updatedSource.type === 'VARIABLE' && updatedSource.dataType === DataType.NUMBER) {
    return {
      ...step,
      parameters: {
        ...step.parameters,
        value: updatedSource as Source<DataType.NUMBER>,
      },
    };
  }
  return step;
}

function renameRequestStepVariable(
  step: RequestStep,
  renameVariablePayload: RenameVariablePayload,
): RequestStep {
  const body = updateKeyedSource(step.parameters.body, renameVariablePayload);
  const headers = updateKeyedSource(
    step.parameters.headers,
    renameVariablePayload,
  ) as KeyedSource<DataType.STRING>[];
  const params = updateKeyedSource(
    step.parameters.params,
    renameVariablePayload,
  ) as KeyedSource<DataType.STRING>[];
  const url = renameVariableNameInSource(
    step.parameters.url,
    renameVariablePayload,
  ) as TokenizedSource<DataType.STRING>;

  return {
    ...step,
    parameters: {
      ...step.parameters,
      body,
      headers,
      params,
      url,
    },
  };
}

function renameRedirectStepVariable(
  step: RedirectStep,
  renameVariablePayload: RenameVariablePayload,
): RedirectStep {
  const params = updateKeyedSource(
    step.parameters.params,
    renameVariablePayload,
  ) as KeyedSource<DataType.ANY>[];
  const url = renameVariableNameInSource(
    step.parameters.url,
    renameVariablePayload,
  ) as TokenizedSource<DataType.STRING>;
  return {
    ...step,
    parameters: {
      ...step.parameters,
      params,
      url,
    },
  };
}

function renameResponseStepVariable(
  step: ResponseStep,
  renameVariablePayload: RenameVariablePayload,
): ResponseStep {
  const bodyData = updateKeyedSource(step.parameters.bodyData, renameVariablePayload);

  return {
    ...step,
    parameters: {
      ...step.parameters,
      bodyData,
    },
  };
}

function renameMapStepVariable(
  step: MapStep,
  renameVariablePayload: RenameVariablePayload,
): MapStep {
  return {
    ...step,
    parameters: {
      ...step.parameters,
      iterator: renameVariableNameInSource(
        step.parameters.iterator,
        renameVariablePayload,
      ) as Source<DataType.ARRAY>,
    },
  };
}

function updateConditionWrapperSource(
  conditionWrapper: ConditionWrapper,
  renameVariablePayload: RenameVariablePayload,
): ConditionWrapper {
  switch (conditionWrapper.type) {
    case 'JOIN':
      return {
        ...conditionWrapper,
        conditions: conditionWrapper.conditions.map((wrapper: ConditionWrapper) =>
          updateConditionWrapperSource(wrapper, renameVariablePayload),
        ),
      };
      break;
    case 'OPERATOR':
      return {
        ...conditionWrapper,
        condition: {
          ...conditionWrapper.condition,
          variable: renameVariableNameInSource(
            conditionWrapper.condition.variable as VariableSource,
            renameVariablePayload,
          ),
          argument:
            'argument' in conditionWrapper.condition && conditionWrapper.condition.argument
              ? renameVariableNameInSource(
                  conditionWrapper.condition.argument,
                  renameVariablePayload,
                )
              : undefined,
        } as Condition,
      };
      break;
  }
}

function renameConditionalStepVariable(
  step: ConditionalStep,
  renameVariablePayload: RenameVariablePayload,
): ConditionalStep {
  return {
    ...step,
    parameters: {
      ...step.parameters,
      choices: step.parameters.choices.map((choice: Choice) => {
        if (choice.conditionWrapper) {
          return {
            ...choice,
            conditionWrapper: updateConditionWrapperSource(
              choice.conditionWrapper,
              renameVariablePayload,
            ),
          };
        } else {
          return choice;
        }
      }),
    },
  };
}

export function updateKeyedSource<T extends DataType>(
  keyedSources: KeyedSource<T>[],
  renameVariablePayload: RenameVariablePayload,
): KeyedSource<T>[] {
  return keyedSources.map((keyedSource: KeyedSource<T>) => ({
    ...keyedSource,
    source: renameVariableNameInSource(keyedSource.source, renameVariablePayload),
  }));
}

export function renameVariableNameInSource<T extends DataType>(
  source: Source<T>,
  payload: RenameVariablePayload,
): Source<T> {
  switch (source.type) {
    case 'VARIABLE':
      return renameVariableNameInVariableSource(source, payload);
    case 'VALUE':
      return source;
    case 'CONDITION':
      return renameVariableNameInConditionSource(source, payload);
    case 'USER_SUPPLIED_CREDENTIAL':
      return {
        ...source,
        credential: renameVariableNameInTokenizedSource(source.credential, payload),
      };
    case 'TOKENIZED':
      return renameVariableNameInTokenizedSource(source, payload);
    case 'ENVIRONMENT_SECRET':
    case 'CONNECT_CREDENTIAL_FIELD':
    case 'OBJECT_VALUE':
    case 'PERSONA_METADATA':
      return source;
    case 'CACHED_EXECUTION':
      // TODO: remove deprecated references to `CACHED_EXECUTION`
      throw new Error('"CACHED_EXECUTION" not supported in variable source renamer.');
    case 'EXECUTION':
      throw new Error('"EXECUTION" not supported in variable source renamer.');
    case 'FANOUT_EXECUTION':
      // TODO: use custom error
      throw new Error('"FANOUT_EXECUTION" not supported in variable source renamer.');
  }
}

// check if two paths are exact equeal ['request','params','key'] ===
// ['request','params','key','prop'],
function isPathEqual(path1: string[], path2: string[]): boolean {
  return path1.every((_path: string, index: number) => path1[index] === path2[index]);
}

export function renameVariableNameInVariableSource<T extends DataType>(
  variableSource: VariableSource<T>,
  payload: RenameVariablePayload,
): VariableSource<T> {
  const path: string[] = [...payload.path, payload.oldVariableName];
  if (variableSource.stepId === payload.stepId && isPathEqual(path, variableSource.path)) {
    return {
      ...variableSource,
      path: variableSource.path.map((value: string, index: number) =>
        value === payload.oldVariableName && path.length === index + 1
          ? payload.newVariableName
          : value,
      ),
    };
  } else {
    return variableSource;
  }
}

export function renameVariableNameInTokenizedSource<T extends DataType>(
  tokenizedSource: TokenizedSource<T>,
  payload: RenameVariablePayload,
): TokenizedSource<T> {
  return {
    ...tokenizedSource,
    parts: tokenizedSource.parts.map((part: TokenizedValue) => {
      if (part.type === 'VARIABLE') {
        return renameVariableNameInVariableSource(part, payload);
      } else {
        return part;
      }
    }),
  };
}

export function renameVariableNameInConditionSource(
  conditionSource: ConditionSource,
  payload: RenameVariablePayload,
): ConditionSource {
  return {
    ...conditionSource,
    condition: {
      ...updateConditionWrapperSource(conditionSource.condition, payload),
    },
  };
}

//------- Below methods are to update stale step id after workflow has been duplicated -------//

export function updateStepIdInKeyedSource<T extends DataType>(
  keyedSources: KeyedSource<T>[],
  updatedStepIdLedger: Record<string, string>,
  withinSameProject: boolean = true,
): KeyedSource<T>[] {
  return keyedSources.map((keyedSource: KeyedSource<T>) => ({
    ...keyedSource,
    source: updateStepIdInSource(keyedSource.source, updatedStepIdLedger, withinSameProject),
  }));
}

export function updateStepIdInSource<T extends DataType>(
  source: Source<T>,
  updatedStepIdLedger: Record<string, string>,
  withinSameProject: boolean = true,
): Source<T> {
  switch (source.type) {
    case 'VARIABLE':
      return updateStepIdInVariableSource(source, updatedStepIdLedger);
    case 'VALUE':
    case 'OBJECT_VALUE':
      return source;
    case 'CONDITION':
      return updateStepIdInConditionSource(source, updatedStepIdLedger);
    case 'USER_SUPPLIED_CREDENTIAL':
      return {
        ...source,
        credential: updateStepIdInTokenizedSource(source.credential, updatedStepIdLedger),
      };
    case 'TOKENIZED':
      return updateStepIdInTokenizedSource(source, updatedStepIdLedger);
    case 'ENVIRONMENT_SECRET':
    case 'CONNECT_CREDENTIAL_FIELD':
    case 'PERSONA_METADATA':
      return withinSameProject
        ? source
        : ({
            type: 'VALUE',
            value: '',
          } as Source<T>);
    case 'CACHED_EXECUTION':
      // TODO: remove deprecated references to `CACHED_EXECUTION`
      throw new Error('"CACHED_EXECUTION" not supported in updating step in source.');
    case 'EXECUTION':
      // TODO: use custom error
      throw new Error('"EXECUTION" not supported in updating step in source.');
    case 'FANOUT_EXECUTION':
      // TODO: use custom error
      throw new Error('"FANOUT_EXECUTION" not supported in updating step in source.');
  }
}

export function updateStepIdInVariableSource<T extends DataType>(
  variableSource: VariableSource<T>,
  updatedStepIdLedger: Record<string, string>,
): VariableSource<T> {
  if (updatedStepIdLedger[variableSource.stepId]) {
    return {
      ...variableSource,
      stepId: updatedStepIdLedger[variableSource.stepId],
    };
  } else {
    return variableSource;
  }
}

export function updateStepIdInTokenizedSource<T extends DataType>(
  tokenizedSource: TokenizedSource<T>,
  updatedStepIdLedger: Record<string, string>,
): TokenizedSource<T> {
  return {
    ...tokenizedSource,
    parts: tokenizedSource.parts.map((part: TokenizedValue) => {
      if (part.type === 'VARIABLE') {
        return updateStepIdInVariableSource(part, updatedStepIdLedger);
      } else {
        return part;
      }
    }),
  };
}

export function updateStepIdInConditionSource(
  conditionSource: ConditionSource,
  updatedStepIdLedger: Record<string, string>,
): ConditionSource {
  function updateConditionWrapperSource(conditionWrapper: ConditionWrapper): ConditionWrapper {
    switch (conditionWrapper.type) {
      case 'JOIN':
        return {
          ...conditionWrapper,
          conditions: conditionWrapper.conditions.map((wrapper: ConditionWrapper) =>
            updateConditionWrapperSource(wrapper),
          ),
        };
      case 'OPERATOR':
        return {
          ...conditionWrapper,
          condition: {
            ...conditionWrapper.condition,
            variable: updateStepIdInSource(
              conditionWrapper.condition.variable as VariableSource,
              updatedStepIdLedger,
            ),
            argument:
              'argument' in conditionWrapper.condition && conditionWrapper.condition.argument
                ? updateStepIdInSource(conditionWrapper.condition.argument, updatedStepIdLedger)
                : undefined,
          } as Condition,
        };
    }
  }

  return {
    ...conditionSource,
    condition: {
      ...updateConditionWrapperSource(conditionSource.condition),
    },
  };
}

export function updateStepIdInRequestAuthorization(
  requestAuthorization: RequestAuthorization,
  updatedStepIdLedger: Record<string, string>,
): RequestAuthorization | undefined {
  switch (requestAuthorization.type) {
    case RequestAuthorizationType.AUTH_HEADER:
      return {
        ...requestAuthorization,
        headers: updateStepIdInKeyedSource(
          requestAuthorization.headers,
          updatedStepIdLedger,
          false,
        ),
      };

    case RequestAuthorizationType.BASIC:
      return {
        ...requestAuthorization,
        username: updateStepIdInTokenizedSource(requestAuthorization.username, updatedStepIdLedger),
        password: updateStepIdInTokenizedSource(requestAuthorization.password, updatedStepIdLedger),
      };

    case RequestAuthorizationType.BEARER_TOKEN: {
      return {
        ...requestAuthorization,
        token: updateStepIdInTokenizedSource(requestAuthorization.token, updatedStepIdLedger),
      };
    }

    case RequestAuthorizationType.QUERY_PARAMS: {
      return {
        ...requestAuthorization,
        params: updateStepIdInKeyedSource(requestAuthorization.params, updatedStepIdLedger, false),
      };
    }

    case RequestAuthorizationType.NONE:
    default:
      return requestAuthorization;
  }
}

export { resolveCustomIntegrationSource } from '@shared/utils/sdk/resolvers';
/**
 * a map of resolvers for each step type
 */
export const STEP_RESOLVER_MAP: Record<StepType, () => StepResolver<Step, any>> = {
  [StepType.ACTION]: () => new ActionStepResolver(),
  [StepType.ACTION_TRIGGER]: () => new ActionTriggerStepResolver(),
  [StepType.CRON]: () => new CronStepResolver(),
  [StepType.DELAY]: () => new DelayStepResolver(),
  [StepType.ENDPOINT]: () => new EndpointStepResolver(),
  [StepType.EVENT]: () => new EventStepResolver(),
  [StepType.FUNCTION]: () => new FunctionStepResolver(),
  [StepType.IFELSE]: () => new ConditionalStepResolver(),
  [StepType.INTEGRATION_ENABLED]: () => new IntegrationEnabledStepResolver(),
  [StepType.MAP]: () => new MapStepResolver(),
  [StepType.OAUTH]: () => new OAuthStepResolver(),
  [StepType.REDIRECT]: () => new RedirectStepResolver(),
  [StepType.REQUEST]: () => new RequestStepResolver(),
  [StepType.CUSTOM_INTEGRATION_REQUEST]: () => new CustomIntegrationRequestStepResolver(),
  [StepType.RESPONSE]: () => new ResponseStepResolver(),
  [StepType.UNSELECTED_TRIGGER]: () => new UnselectedTriggerStepResolver(),
};

export function getRequiredStepIdForConditionalSource(
  conditionWrapper: ConditionWrapper,
): string[] {
  function getRequiredStepIdInCondition(condition: Condition): string[] {
    const stepIds = getRequiredStepIdsForSource(condition.variable);

    if ('argument' in condition) {
      stepIds.push(...getRequiredStepIdsForSource(condition.argument));
    }

    return stepIds;
  }

  switch (conditionWrapper.type) {
    case 'JOIN':
      return flatten(
        conditionWrapper.conditions.map((wrapper) =>
          getRequiredStepIdForConditionalSource(wrapper),
        ),
      );

    case 'OPERATOR':
      return getRequiredStepIdInCondition(conditionWrapper.condition);
  }
}

export function getRequirdStepIdForTokenizedSource(tokenizedSource: TokenizedSource): string[] {
  return tokenizedSource.parts
    .filter((source) => source.type === 'VARIABLE')
    .map((source) => (source as VariableSource).stepId);
}

export function getRequiredStepIdToResolveKeyedSources(keyedSources: KeyedSource[]): string[] {
  const unflattenedStepIds: string[][] = keyedSources
    .filter((keyedSource) =>
      ['VARIABLE', 'TOKENIZED', 'CONDITION'].includes(keyedSource.source.type),
    )
    .map((keyedSource) => getRequiredStepIdsForSource(keyedSource.source));

  return flatten(unflattenedStepIds);
}

export function getRequiredStepIdsForSource(source: Source): string[] {
  switch (source.type) {
    case 'VARIABLE':
      return [source.stepId];
    case 'TOKENIZED':
      return getRequirdStepIdForTokenizedSource(source);
    case 'CONDITION':
      return getRequiredStepIdForConditionalSource(source.condition);
    default:
      return [];
  }
}

/**
 * resolve `Apply Field Mapping` input source into simplified `object` for object mapping
 */
export const resolveObjectMappingSource = (
  objectMapperSource: TokenizedSource<DataType.STRING | DataType.ANY> | KeyedSource[],
  cachedConnectCredential: CachedConnectCredential | null,
  variables: WorkflowVariables,
  context: WorkflowExecutionContext,
  secrets: Record<string, string>,
  cachedPersona: CachedPersona | null,
): ObjectMappingInput | undefined => {
  if (!objectMapperSource) {
    return;
  }
  const source: TokenizedSource | undefined = Array.isArray(objectMapperSource)
    ? (objectMapperSource.find(
        (value) => value.key == 'objectMapping' && value.source.type == 'TOKENIZED',
      )?.source as TokenizedSource | undefined)
    : objectMapperSource;

  if (source?.parts?.[0]?.type !== 'CONNECT_CREDENTIAL_FIELD') {
    return;
  }

  const fieldMapperInputValue: FieldMappingValue | undefined = resolveData(
    // Updating the `path` property of `ConnectCredentialSource` to access all the values in credentials
    {
      ...source,
      parts: [{ ...source.parts[0], path: [] }] as ConnectCredentialSource[],
    },
    variables,
    secrets,
    cachedConnectCredential,
    context,
    cachedPersona,
  );

  let resolvedFieldMappings: ObjectMappingInput['fieldMappings'] = [];

  if (fieldMapperInputValue && fieldMapperInputValue.objectMapping) {
    const { dynamicMappings, fieldMappings } = fieldMapperInputValue;
    resolvedFieldMappings =
      fieldMapperInputValue.mappingType === 'DYNAMIC'
        ? Object.values(dynamicMappings || {})
        : Object.keys(fieldMappings || {}).map((applicationField: string) => ({
            applicationField,
            integrationField: fieldMappings?.[applicationField],
          }));

    return {
      objectType: fieldMapperInputValue.objectMapping,
      fieldMappings: resolvedFieldMappings!.length > 0 ? resolvedFieldMappings : undefined,
    };
  }
};

/**
 * @summary
 * @param payload payload object for transformation
 * @param objectMapping
 */
export const mapDynamicObjectMappingToTrigger = (
  payload: EventInput['payload'] | EndpointInput['body'],
  objectMapping: ObjectMappingInput,
): ParsedObjectMapping => {
  const { objectType, fieldMappings } = objectMapping;
  const fields: Record<string, unknown> = {};

  Object.keys(payload).forEach((key) => {
    const matchingField = fieldMappings?.find(({ applicationField }) => applicationField === key);

    if (matchingField && matchingField.integrationField && matchingField.applicationField) {
      fields[matchingField.integrationField] = payload[key];
    }
  });

  return { fields, objectType };
};
