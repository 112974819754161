export default `<svg width="817" height="551" viewBox="0 0 817 551" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5203 249.314L382.411 6.9255C396.839 -2.3085 419.346 -2.3085 433.774 6.9255L799.665 249.314C821.595 263.742 821.595 287.404 799.665 301.831L750.61 334.148C678.471 220.456 551.506 145.432 407.804 145.432C263.525 145.432 137.137 221.034 64.9983 334.148L15.9433 301.83C-5.40965 287.403 -5.40966 263.164 16.5203 249.314ZM407.804 246.427C298.729 246.427 203.505 304.139 149.257 389.552L233.516 445.531C269.297 386.665 333.357 347.422 407.228 347.422C480.522 347.422 545.158 386.666 580.94 445.531L665.199 389.552C612.68 304.139 516.879 246.427 407.804 246.427ZM407.804 448.995C369.714 448.995 336.242 470.348 318.351 501.512L374.908 538.447C384.142 545.95 395.107 550.565 407.804 550.565C420.501 550.565 432.043 545.949 440.7 538.447L497.257 501.512C480.52 470.926 446.47 448.995 407.804 448.995Z" fill="url(#paint0_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="408.056" y1="550.567" x2="408.056" y2="-0.000525062" gradientUnits="userSpaceOnUse">
<stop stop-color="#F0C21A"/>
<stop offset="0.3314" stop-color="#93C94B"/>
<stop offset="0.4859" stop-color="#70C05A"/>
<stop offset="0.7506" stop-color="#12B259"/>
<stop offset="0.903" stop-color="#22AD69"/>
<stop offset="1" stop-color="#22AD69"/>
</linearGradient>
</defs>
</svg>`;
