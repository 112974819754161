import React from 'react';

import {
  ActionResponse,
  getValuesByCacheKey,
  keyNameToFriendlyName,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  Action,
  ActionButtonStyle,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DEFAULT_SUPPORTED_OPERATORS, DataType, Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { DEFAULT_POSTGRES_SCHEMA } from '../shared/constants';
import { GetSchemaResponse, GetTableResponse } from '../shared/types';

import authConfig from './shared/authConfig';

export const fetchSchemasAction: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Schema',
  cacheKey: 'schemas',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_SCHEMAS,
      actionType: Action.POSTGRES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<GetSchemaResponse>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((schema: { schema_name: string }) => ({
          label: schema.schema_name,
          value: schema.schema_name,
        }))
      : [];
  },
};

const fetchTablesAction: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Table',
  cacheKey: 'tables',
  refreshDependencies: ['schema'],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const schema = pickValueSourceByKey(options.actionParameters, 'schema');
    return {
      intent: Intent.GET_TABLES,
      actionType: Action.POSTGRES,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'schema',
          source: {
            type: 'VALUE',
            value: schema?.value,
          },
        },
      ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<GetTableResponse>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((table: { table_name: string }) => ({
          label: table.table_name,
          value: table.table_name,
        }))
      : [];
  },
};

const fetchColumnsAction: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Column',
  cacheKey: 'cachedColumns',
  refreshDependencies: ['schema', 'table'],
  mapRefreshToValues: (response: any): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const schema = pickValueSourceByKey(options.actionParameters, 'schema');
    const table = pickValueSourceByKey(options.actionParameters, 'table');
    return {
      actionType: Action.POSTGRES,
      intent: Intent.GET_COLUMNS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'schema',
          source: {
            type: 'VALUE',
            value: schema?.value,
          },
        },
        {
          key: 'table',
          source: {
            type: 'VALUE',
            value: table?.value,
          },
        },
      ],
    };
  },
};

export { authConfig };

const config: ActionConfig = {
  actionType: Action.POSTGRES,
  name: 'PostgreSQL',
  description: 'Query or update records in your PostgreSQL database.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect to a PostgreSQL database',
          placeholder: 'connect a new database',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.WRITESQL,
                    label: 'Write SQL',
                  },
                  {
                    value: Intent.QUERY_RECORDS,
                    label: 'Find Records',
                  },
                  {
                    value: Intent.FIND_RECORD_BY_ID,
                    label: 'Find Record by ID',
                  },
                  {
                    value: Intent.CREATE_RECORD,
                    label: 'Create Record',
                  },
                  {
                    value: Intent.UPDATE_RECORD,
                    label: 'Update Records',
                  },
                  {
                    value: Intent.UPDATE_RECORD_BY_ID,
                    label: 'Update Record by ID',
                  },
                  {
                    value: Intent.DELETE_RECORD,
                    label: 'Delete Records',
                  },
                  {
                    value: Intent.DELETE_RECORD_BY_ID,
                    label: 'Delete Record by ID',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      if (parameters.intent && parameters.intent != Intent.WRITESQL) {
        return {
          inputs: [
            {
              id: 'schema',
              type: SidebarInputType.DynamicEnum,
              title: 'Schema',
              placeholder: 'Select a Schema',
              defaultValue: DEFAULT_POSTGRES_SCHEMA,
              required: true,
              source: fetchSchemasAction,
              getValues: getValuesByCacheKey,
            },
          ],
        };
      }

      return { inputs: [] };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const tablesInput: SidebarInput = {
        id: 'table',
        type: SidebarInputType.DynamicEnum,
        title: 'Table',
        placeholder: 'Select a table',
        required: true,
        source: fetchTablesAction,
        getValues: getValuesByCacheKey,
      };

      const filterInput: SidebarInput = {
        id: 'filterFormula',
        type: SidebarInputType.DynamicConditional,
        title: 'Filter records',
        subtitle:
          'You can filter which records get returned from PostgreSQL by applying one or more filters below.',
        placeholder: 'filter by field',
        source: fetchColumnsAction,
        getValues: (options: ActionStepParameters) => {
          const columns =
            pickValueSourceByKey(options.actionParameters, 'cachedColumns')?.value || {};
          return Object.entries<string>(columns).map(([columnName]: string[]) => ({
            value: columnName,
            label: columnName,
          }));
        },
        supportedOperators: [
          ...DEFAULT_SUPPORTED_OPERATORS,
          Operator.NumberLessThanOrEqualTo,
          Operator.NumberGreaterThanOrEqualTo,
        ],
        required: false,
      };

      const selectedSchema: string | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'schema',
      )?.value;

      if (!selectedSchema && parameters.intent !== Intent.WRITESQL) {
        return { inputs: [] };
      }

      switch (parameters.intent) {
        case Intent.WRITESQL:
          return {
            inputs: [
              {
                id: 'query',
                type: SidebarInputType.Code,
                language: 'sql',
                title: 'Query',
                placeholder: 'Database Query',
                required: true,
                useLightTheme: true,
              },
            ],
          };
        case Intent.QUERY_RECORDS:
          return {
            inputs: [
              tablesInput,
              filterInput,
              {
                id: 'limit',
                type: SidebarInputType.Text,
                required: false,
                title: 'Limit records',
                subtitle:
                  'You can limit the maximum number of records that get returned from PostgreSQL.',
              },
              {
                id: 'offset',
                type: SidebarInputType.Text,
                required: false,
                title: 'Offset',
              },
            ],
          };
        case Intent.FIND_RECORD_BY_ID:
          return {
            inputs: [
              tablesInput,
              {
                id: 'id',
                type: SidebarInputType.Text,
                required: true,
                title: 'Record ID',
                subtitle: 'Searches by the table’s primary key',
              },
            ],
          };
        case Intent.CREATE_RECORD:
          return {
            inputs: [tablesInput],
          };
        case Intent.UPDATE_RECORD:
          return {
            inputs: [tablesInput, filterInput],
          };
        case Intent.UPDATE_RECORD_BY_ID:
          return {
            inputs: [
              tablesInput,
              {
                id: 'id',
                type: SidebarInputType.Text,
                required: true,
                title: 'Record ID',
                subtitle: 'Updates by the table’s primary key',
              },
            ],
          };
        case Intent.DELETE_RECORD:
          return {
            inputs: [tablesInput, filterInput],
          };
        case Intent.DELETE_RECORD_BY_ID:
          return {
            inputs: [
              tablesInput,
              {
                id: 'id',
                type: SidebarInputType.Text,
                required: true,
                title: 'Record ID',
                subtitle: 'Deletes by the table’s primary key',
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedSchema: string | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'schema',
      )?.value;
      if (
        ![Intent.CREATE_RECORD, Intent.UPDATE_RECORD, Intent.UPDATE_RECORD_BY_ID].includes(
          parameters.intent as Intent,
        ) ||
        !selectedSchema
      ) {
        return { inputs: [] };
      }

      return {
        inputs: Object.entries<string>(
          pickValueSourceByKey<DataType.ANY>(parameters.actionParameters, 'cachedColumns')?.value ||
            [],
        ).map((column: string[]) => ({
          id: `column-${column[0]}`,
          title: keyNameToFriendlyName(column[0]),
          subtitle: (
            <>
              This {(column[1] || '').toLowerCase()}-type value will be saved as{' '}
              <code>{column[0]}</code> in the resulting row.
            </>
          ),
          required: false,
          type: SidebarInputType.TextArea,
          lines: 1,
        })),
        buttons: [
          {
            id: 'refreshFields',
            type: SidebarInputType.ActionButton,
            title: 'Refresh Fields',
            style: ActionButtonStyle.DEFAULT,
            source: fetchColumnsAction,
          },
        ],
      };
    },
  ],
};

export default config;
