import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.GITHUB,
  provider: ProviderType.GITHUB,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your GitHub accounts',
  icon: iconSvg,
  description:
    "Connect to your users' GitHub account to manage their issues, releases, and repositories.",
  sidebarSections: [],
};

export default authConfig;
