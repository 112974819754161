import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig, SidebarInputType } from '@shared/types/sdk/actions';

import iconSvg from '../../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.NONE,
  provider: ProviderType.MONGODB,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your MongoDB database',
  description: 'Add MongoDB to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name',
          subtitle: 'Label this database for use in your Paragon workflows.',
          placeholder: 'name this database',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_DATABASE',
          title: 'Database Name',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_HOST',
          title: 'Host',
          placeholder: 'mongodb.example.com',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_PORT',
          title: 'Port',
          placeholder: '27017',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_USER',
          title: 'User',
          subtitle: 'Specify a username and a password with access privileges to this database.',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MONGODB_PASSWORD',
          title: 'Password',
          type: SidebarInputType.ValueText,
          inputType: 'password',
        },
      ],
      buttons: [
        createTestConnectionButton(Action.MONGODB, [
          'MONGODB_DATABASE',
          'MONGODB_HOST',
          'MONGODB_PORT',
          'MONGODB_USER',
          'MONGODB_PASSWORD',
        ]),
      ],
    },
  ],
};

export default authConfig;
