import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const connectConfig: ActionConfig = {
  actionType: Action.QUICKBOOKS,
  name: 'QuickBooks',
  description: 'Trigger when new Accounts, Customers, or Invoices are created',
  icon: iconSvg,
  provider: ProviderType.QUICKBOOKS,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_ACCOUNT_CREATED,
              label: 'New Account',
            },
            {
              value: Intent.TRIGGER_CUSTOMER_CREATED,
              label: 'New Customer',
            },
            {
              value: Intent.TRIGGER_INVOICE_CREATED,
              label: 'New Invoice',
            },
          ],
        },
      ],
    },
  ],
};

export default connectConfig;
