import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'api'
  | 'id,profile,email,address,phone'
  | 'refresh_token,offline_access'
  | 'pardot_api'
  | 'chatter_api'
  | 'custom_permissions'
  | 'full'
  | 'openid'
  | 'visualforce'
  | 'web';

export const scopes: Record<ScopeName, ScopeValue> = {
  api: {
    label: 'API',
    name: 'api',
    required: true,
    description:
      'Allows access to the current, logged-in user’s account using APIs, such as REST API and Bulk API. This scope also includes chatter_api, which allows access to Connect REST API resources.',
  },
  'id,profile,email,address,phone': {
    required: true,
    label: 'Basic Profile',
    name: 'id,profile,email,address,phone',
    description:
      'Allows access to the identity URL service. You can request profile, email, address, or phone individually to get the same result as using id; they are all synonymous.',
  },
  'refresh_token,offline_access': {
    required: true,
    label: 'Offline Access',
    name: 'refresh_token,offline_access',
    description:
      'Allows a refresh token to be returned when the requesting client is eligible to receive one. With a refresh token, the app can interact with the user’s data while the user is offline. This token is synonymous with requesting offline_access.',
  },
  pardot_api: {
    label: 'Pardot Access',
    name: 'pardot_api',
    description:
      'Allows access to Pardot API services on behalf of the user. Manage the full extent of accessible services in Pardot.',
  },
  chatter_api: {
    label: 'Chatter Api',
    name: 'chatter_api',
    description: 'Allows access to Connect REST API resources on behalf of the user.',
  },
  custom_permissions: {
    label: 'Custom Permissions',
    name: 'custom_permissions',
    description:
      'Allows access to the custom permissions in an org associated with the connected app. This scope also shows whether the current user has each permission enabled.',
  },
  full: {
    label: 'Full',
    name: 'full',
    description:
      'Allows access to all data accessible by the logged-in user, and encompasses all other scopes.',
  },
  openid: {
    label: 'OpenID',
    name: 'openid',
    description:
      'Allows access to the current, logged in user’s unique identifier for OpenID Connect apps.',
  },
  visualforce: {
    label: 'Visual Force',
    name: 'visualforce',
    description:
      'Allows access to customer-created Visualforce pages only. This scope doesn’t allow access to standard Salesforce UIs.',
  },
  web: {
    label: 'Web',
    name: 'web',
    description:
      'Allows use of the access_token on the web. This scope also includes visualforce, allowing access to customer-created Visualforce pages.',
  },
};

export const requiredScopes: ScopeName[] = [
  'api',
  'refresh_token,offline_access',
  'id,profile,email,address,phone',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Salesforce',
  description: 'Access a user’s Salesforce CRM records.',
  authUrl: 'https://login.salesforce.com//services/oauth2/authorize',
  accessTokenUrl: 'https://login.salesforce.com//services/oauth2/token',
  iconSvg,
  clientIdLabel: 'Consumer Key',
  clientSecretLabel: 'Consumer Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Consumer Key',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/salesforce`,
      documentationLinkLabel: 'How to obtain a Consumer Key?',
    },
    { type: SidebarInputType.ValueText, title: 'Consumer Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/salesforce',
};
