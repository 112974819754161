import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig } from '@shared/types/sdk/actions';

import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.LINEAR,
  provider: ProviderType.LINEAR,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Linear account',
  icon: iconSvg,
  description: 'Linear',
  sidebarSections: [],
  hideOAuthApps: true,
};

export default authConfig;
