import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class WorkflowExecutionError extends ParagonError {
  name: string = 'WorkflowExecutionError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class WorkflowExecutionNotFoundFromIdError extends WorkflowExecutionError {
  name: string = 'WorkflowExecutionNotFoundFromIdError';

  constructor(meta: { workflowExecutionId: string; projectId?: string; options?: object }) {
    super({
      code: ERROR_CODE.WORKFLOW_EXECUTION_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find workflow execution.',
      meta,
    });
  }
}

export class WorkflowExecutionIdNotFoundInParamsError extends WorkflowExecutionError {
  name: string = 'WorkflowExecutionIdNotFoundInParamsError';

  constructor(meta: { path: string; method: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_EXECUTION_ID_NOT_FOUND_IN_PARAMS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Workflow execution id is required.',
      meta,
    });
  }
}
