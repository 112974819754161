import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

import { apiKeyInput } from './inputs';

const authConfig: ActionConfig = {
  actionType: Action.CLOSE,
  provider: ProviderType.CLOSE,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Close account',
  icon: iconSvg,
  description: 'Access, create and update their data in Close.',
  sidebarSections: [
    {
      inputs: [apiKeyInput],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.CLOSE,
  name: 'Close',
  description: 'Access, create and update data in Close.',
  icon: iconSvg,
  sidebarSections: [],
};

export { authConfig };

export default config;
