import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.NumberLessThan,
  Operator.NumberEquals,
  Operator.NumberGreaterThan,
  Operator.NumberDoesNotEqual,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberLessThanOrEqualTo,
];

export const campaignNameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  subtitle: 'Name of the new campaign.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const segmentInput: SidebarInput = {
  id: 'segmentId',
  title: 'Segment',
  subtitle:
    'The Segment that this campaign will be target. Use Connect Portal User Settings to allow your users to select a Segment.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const campaignStatusInput: SidebarInput = {
  id: 'campaignStatus',
  title: 'Status',
  subtitle: 'Set the initial status for the new campaign.',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Active', value: 'Active' },
    { label: 'Draft', value: 'Draft' },
    { label: 'Scheduled', value: 'Scheduled' },
    { label: 'Completed', value: 'Completed' },
  ],
};

export const campaignEmailInput: SidebarInput = {
  id: 'emailId',
  title: 'Email',
  subtitle:
    'Specify an Email to use as the first element of this campaign. Use Connect Portal User Settings to allow your users to select an Email.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const additionalCampaignElementInput: SidebarInput = {
  id: 'additionalCampaignElement',
  title: 'Additional Campaign Elements',
  type: SidebarInputType.Code,
  lines: 10,
  useLightTheme: true,
  language: 'json',
  subtitle: (
    <>
      Include additional campaign elements as JSON.{' '}
      <a href="" target="_blank">
        See the docs
      </a>{' '}
      for the full reference on available campaign elements.
    </>
  ),
  placeholder: `[
  {
    "type": "CampaignForm",
    "id": "-3",
    "name": "Form",
    "memberCount": "0",
    "memberErrorCount": "0",
    "position": {
      "type": "Position",
      "x": "167",
      "y": "335"
    }
  }
]`,
};

export const campaignIdInput: SidebarInput = {
  id: 'campaignId',
  title: 'Campaign ID',
  subtitle: 'ID of the campaign to update.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const scheduledDateInput: SidebarInput = {
  id: 'scheduledDate',
  title: 'Scheduled Date',
  subtitle:
    'Specify a date and time for this campaign to activate. If unspecified, the campaign will activate immediately.',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: '2022-01-01 12:00:00 PM',
  required: false,
};

export const emailNameInput: SidebarInput = {
  id: 'emailName',
  title: 'Name',
  subtitle: 'The name of the email, for internal reference.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const senderNameInput: SidebarInput = {
  id: 'senderName',
  title: 'Sender Name',
  subtitle: 'The name displayed for the sender of the email.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const senderEmailInput: SidebarInput = {
  id: 'senderEmail',
  title: 'Sender Email',
  subtitle: 'The email address the email is sent from.',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: 'sean@useparagon.com',
  required: true,
};

export const replyToEmailInput: SidebarInput = {
  id: 'replyToEmail',
  title: 'Reply-To Email',
  subtitle: 'An address used for replies sent to this email.',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: 'support@useparagon.com',
  required: false,
};

export const subjectLineInput: SidebarInput = {
  id: 'subject',
  title: 'Subject Line',
  subtitle: 'The subject line of the email.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const previewTextInput: SidebarInput = {
  id: 'previewText',
  title: 'Preview Text',
  subtitle: 'The preview text of the email.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const htmlContentInput: SidebarInput = {
  id: 'htmlContent',
  title: 'HTML Content',
  subtitle: 'Specify the content of the email as HTML.',
  type: SidebarInputType.TextArea,
  lines: 10,
  required: true,
};

export const emailIdInput: SidebarInput = {
  id: 'emailId',
  title: 'Email ID',
  subtitle: 'The ID of the email to update.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const emailContactsInput: SidebarInput = {
  id: 'emailContacts',
  title: 'Contacts',
  subtitle: 'Specify a contact ID (or an array of contact IDs) to send this email deployment to.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  title: 'First Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  title: 'Last Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const companyInput: SidebarInput = {
  id: 'company',
  title: 'Company',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const additionalContactFieldsInput: SidebarInput = {
  id: 'additionalContactFields',
  title: 'Additional Fields',
  type: SidebarInputType.Code,
  useLightTheme: true,
  language: 'json',
  lines: 4,
  placeholder: `{
  "C_Address1": "1700 Sawtelle Blvd",
  "{{settings.contactFieldMapping}}": "Custom field value"
}`,
  required: false,
  subtitle:
    'Specify additional contact fields as JSON. Use Connect Portal User Settings to allow your users to select a Contact Field to map.',
};

export const contactIdInput: SidebarInput = {
  id: 'contactId',
  title: 'Contact ID',
  subtitle: 'The ID of the contact to update.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const campaignFilterInput: SidebarInput = {
  id: 'campaignFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search campaigns that match specified filters.',
  placeholder: 'field',
  supportedOperators,
  getValues: () => [
    { label: 'ID', value: 'id' },
    { label: 'Name', value: 'name' },
    { label: 'Created At', value: 'createdAt' },
    { label: 'Updated At', value: 'updatedAt' },
    { label: 'Created By User', value: 'createdBy' },
    { label: 'Updated By User', value: 'updatedBy' },
    { label: 'Campaign Category', value: 'campaignCategory' },
    { label: 'Campaign End Date', value: 'endAt' },
    { label: 'CRM Identifier', value: 'crmId' },
    { label: 'Status', value: 'currentStatus' },
  ],
};

export const emailFilterInput: SidebarInput = {
  id: 'emailFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search emails that match specified filters.',
  placeholder: 'field',
  supportedOperators,
  getValues: () => [
    { label: 'Name', value: 'name' },
    { label: 'Created At', value: 'createdAt' },
    { label: 'Updated At', value: 'updatedAt' },
    { label: 'Created By User', value: 'createdBy' },
    { label: 'Updated By User', value: 'updatedBy' },
    { label: 'Email Group ID', value: 'emailGroupID' },
    { label: 'Updated by User ID', value: 'updatedByUserId' },
    { label: 'Created by User ID', value: 'createdByUserId' },
    { label: 'Content Source', value: 'contentSource' },
  ],
};

export const contactFilterInput: SidebarInput = {
  id: 'contactFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search contacts that match specified filters.',
  placeholder: 'field',
  supportedOperators,
  getValues: () => [
    { label: 'ID', value: 'id' },
    { label: 'Created At', value: 'createdAt' },
    { label: 'Name', value: 'name' },
    { label: 'Email Address', value: 'emailAddress' },
    { label: 'First Name', value: 'C_FirstName' },
    { label: 'Last Name', value: 'lastName' },
  ],
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Campaigns',
          items: [
            { label: 'Create Campaign', value: Intent.CREATE_CAMPAIGN },
            { label: 'Update Campaign', value: Intent.UPDATE_CAMPAIGN },
            { label: 'Activate Campaign', value: Intent.ACTIVATE_CAMPAIGN },
            { label: 'Search Campaigns', value: Intent.SEARCH_CAMPAIGNS },
            { label: 'Get Campaign by ID', value: Intent.GET_CAMPAIGN_BY_ID },
          ],
        },
        {
          title: 'Emails',
          items: [
            { label: 'Create Email', value: Intent.CREATE_EMAIL },
            { label: 'Update Email', value: Intent.UPDATE_EMAIL },
            { label: 'Search Emails', value: Intent.SEARCH_EMAIL },
            { label: 'Send Email Deployment', value: Intent.SEND_EMAIL_DEPLOYMENT },
          ],
        },
        {
          title: 'Contacts',
          items: [
            { label: 'Create Contact', value: Intent.CREATE_CONTACT },
            { label: 'Update Contact', value: Intent.UPDATE_CONTACT },
            { label: 'Search Contacts', value: Intent.SEARCH_CONTACTS },
          ],
        },
      ],
    },
  ],
};
