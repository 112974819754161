import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 *
 * @param condition
 * @returns
 */
export default function conditionsToPipedriveFilterExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToPipedriveFilterExpression(condition);
      })
      .join('&');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    if (innerCondition.operator === Operator.NumberEquals) {
      return `${innerCondition.variable}=${innerCondition.argument}`;
    }
    throw new Error(`${innerCondition.operator} operator not supported for Pipedrive.`);
  }
  return '';
}
