import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  accountIdInput,
  addressLine1Input,
  addressLine2Input,
  cityInput,
  countryInput,
  discountDateCalculationInput,
  discountPercentInput,
  dueDateCalculationInput,
  dueDateInput,
  hiddenInputCollection,
  idInputCollection,
  intentSelector,
  invoiceDateInput,
  lineDescriptionInput,
  lineTypeInput,
  measureCodeUnitInput,
  parentInvoiceIdInput,
  paymentTermCodeInput,
  paymentTermNameInput,
  paymentTermsIdInput,
  postingDateInput,
  purchaseInvoiceAdditionalFields,
  purchaseInvoiceLineAdditionalFields,
  purchaseInvoiceVendorIdInput,
  quantityInput,
  resourceNumberInput,
  searchInputCollection,
  stateInput,
  taxGroupCodeInput,
  taxGroupNameInput,
  taxPercentInput,
  taxRegNumberInput,
  unitCostInput,
  vendorEmailInput,
  vendorInvoiceNumberInput,
  vendorNameInput,
  vendorNumberInput,
  vendorsAdditionalFieldsInput,
  zipInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.DYNAMICS_BUSINESS_CENTRAL,
  name: 'Dynamics 365 Business Central',
  description:
    'Connect your Dynamics account to manage your accounts payable, vendors, and purchase orders in Dynamics 365 Business Central.',
  icon: iconSvg,
  provider: ProviderType.DYNAMICS_BUSINESS_CENTRAL,
  sidebarSections: [
    intentSelector,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_PAYMENT_TERM:
        case Intent.UPDATE_PAYMENT_TERM:
          return {
            inputs: [
              hiddenInputCollection[parameters.intent] as SidebarInput,
              ...(parameters.intent === Intent.UPDATE_PAYMENT_TERM
                ? [idInputCollection[parameters.intent] as SidebarInput]
                : []),
              paymentTermCodeInput,
              paymentTermNameInput,
              dueDateCalculationInput,
              discountDateCalculationInput,
              discountPercentInput,
            ],
          };
        case Intent.CREATE_PURCHASE_INVOICE:
        case Intent.UPDATE_PURCHASE_INVOICE:
          return {
            inputs: [
              hiddenInputCollection[parameters.intent] as SidebarInput,
              ...(parameters.intent === Intent.UPDATE_PURCHASE_INVOICE
                ? [idInputCollection[parameters.intent] as SidebarInput]
                : []),
              purchaseInvoiceVendorIdInput,
              vendorInvoiceNumberInput,
              invoiceDateInput,
              postingDateInput,
              dueDateInput,
              purchaseInvoiceAdditionalFields,
            ],
          };
        case Intent.CREATE_PURCHASE_INVOICE_LINE_ITEM:
        case Intent.UPDATE_PURCHASE_INVOICE_LINE_ITEM:
          return {
            inputs: [
              hiddenInputCollection[parameters.intent] as SidebarInput,
              ...(parameters.intent === Intent.UPDATE_PURCHASE_INVOICE_LINE_ITEM
                ? [idInputCollection[parameters.intent] as SidebarInput]
                : [parentInvoiceIdInput]),
              lineTypeInput,
              resourceNumberInput,
              lineDescriptionInput,
              quantityInput,
              unitCostInput,
              {
                ...accountIdInput,
                subtitle:
                  'Specify the ID of the Account that the Purchase Invoice Line is related to.',
                required: parameters.intent === Intent.CREATE_PURCHASE_INVOICE_LINE_ITEM,
              },
              measureCodeUnitInput,
              taxPercentInput,
              taxGroupCodeInput,
              purchaseInvoiceLineAdditionalFields,
            ],
          };
        case Intent.CREATE_TAX_GROUP:
        case Intent.UPDATE_TAX_GROUP:
          return {
            inputs: [
              hiddenInputCollection[parameters.intent] as SidebarInput,
              ...(parameters.intent === Intent.UPDATE_TAX_GROUP
                ? [idInputCollection[parameters.intent] as SidebarInput]
                : []),
              ...(parameters.intent === Intent.CREATE_TAX_GROUP
                ? [
                    {
                      ...taxGroupCodeInput,
                      subtitle: 'Specify the code of the Tax Group.',
                      required: true,
                    },
                  ]
                : []),
              { ...taxGroupNameInput, required: parameters.intent === Intent.CREATE_TAX_GROUP },
            ],
          };
        case Intent.CREATE_VENDOR:
        case Intent.UPDATE_VENDOR:
          return {
            inputs: [
              hiddenInputCollection[parameters.intent] as SidebarInput,
              ...(parameters.intent === Intent.UPDATE_VENDOR
                ? [idInputCollection[parameters.intent] as SidebarInput]
                : []),
              { ...vendorNumberInput, required: parameters.intent !== Intent.UPDATE_VENDOR },
              vendorNameInput(parameters.intent),
              vendorEmailInput,
              taxRegNumberInput,
              { ...paymentTermsIdInput(''), required: false },
              addressLine1Input,
              addressLine2Input,
              cityInput,
              stateInput,
              zipInput,
              countryInput,
              vendorsAdditionalFieldsInput,
            ],
          };
        case Intent.DELETE_PAYMENT_TERM:
        case Intent.DELETE_PURCHASE_INVOICE:
        case Intent.DELETE_PURCHASE_INVOICE_LINE_ITEM:
        case Intent.DELETE_TAX_GROUP:
        case Intent.DELETE_VENDOR:
        case Intent.GET_PAYMENT_TERM_BY_ID:
        case Intent.GET_PURCHASE_INVOICE_BY_ID:
        case Intent.GET_PURCHASE_INVOICE_LINE_ITEM_BY_ID:
        case Intent.GET_TAX_GROUP_BY_ID:
        case Intent.GET_VENDOR_BY_ID:
        case Intent.POST_PURCHASE_INVOICE:
          return {
            inputs: [
              hiddenInputCollection[parameters.intent] as SidebarInput,
              idInputCollection[parameters.intent] as SidebarInput,
            ],
          };
        case Intent.GET_ACCOUNTS:
        case Intent.GET_ACCOUNT_BY_ID:
          return {
            inputs: [
              hiddenInputCollection[parameters.intent] as SidebarInput,
              ...(parameters.intent === Intent.GET_ACCOUNT_BY_ID
                ? [
                    {
                      ...accountIdInput,
                      subtitle: ' Specify the record ID of the Account to fetch.',
                      placeholder: '',
                    },
                  ]
                : []),
            ],
          };
        case Intent.SEARCH_FOR_PURCHASE_INVOICE:
        case Intent.SEARCH_PAYMENT_TERM:
        case Intent.SEARCH_PURCHASE_INVOICE_LINE_ITEM:
        case Intent.SEARCH_TAX_GROUP:
        case Intent.SEARCH_VENDOR:
          return {
            inputs: [
              hiddenInputCollection[parameters.intent] as SidebarInput,
              ...(parameters.intent === Intent.SEARCH_PURCHASE_INVOICE_LINE_ITEM
                ? [parentInvoiceIdInput]
                : []),
              searchInputCollection[parameters.intent] as SidebarInput,
            ],
          };
        case Intent.GET_PURCHASE_INVOICE_LINES:
          return {
            inputs: [idInputCollection[parameters.intent] as SidebarInput],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export { authConfig };

export default connectConfig;
