import React from 'react';

import { hasOAuthAppCredential, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  EditableEnum,
  EnumInputValue,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import {
  adCreativeTypeInput,
  adCreativeTypeMetadataInputs,
} from '../shared/defaultCreativeBuildTypes';

import {
  adCreativeIdInput,
  adCreativeJsonInput,
  adCreativePageInput,
  adIdInput,
  adSetBidAmountInput,
  adSetBillingEventInput,
  adSetDailyBudgetInput,
  adSetFilterInputOauth,
  adSetIdInput,
  adSetOptimizationGoalInput,
  adSetStartTimeInput,
  adSetTargetingInput,
  adStatusInput,
  campaignDynamicInput,
  campaignFilterInputOauth,
  campaignIdInput,
  campaignObjectiveInput,
  campaignStatusInput,
  commonIntentInput,
  contextCardInput,
  eventActionSourceInput,
  eventBrowserIdInput,
  eventContentCategoryInput,
  eventContentIdInput,
  eventContentNameInput,
  eventCurrencyInput,
  eventCustomerCityInput,
  eventCustomerClickIdInput,
  eventCustomerClientIpAddressInput,
  eventCustomerClientUserAgentInput,
  eventCustomerCountryCodeInput,
  eventCustomerEmailInput,
  eventCustomerExternalId,
  eventCustomerFirstNameInput,
  eventCustomerLastNameInput,
  eventCustomerStateInput,
  eventId,
  eventNameInput,
  eventPixelDynamicInput,
  eventPixelInput,
  eventSourceUrlInput,
  eventTimeInput,
  eventValueInput,
  followUpActionTextInput,
  followUpActionUrlInput,
  nameInput,
  pageDynamicInput,
  privacyPolicyUrlInput,
  promotedObjectInput,
  questionJsonInput,
} from './common';

export const authConfig: ActionConfig = {
  provider: ProviderType.FACEBOOK,
  scheme: AuthenticationScheme.OAUTH,
  icon: iconSvg,
  actionType: Action.FACEBOOK_ADS,
  name: 'Facebook Ads',
  description: 'Connect to your user’s Facebook Ads accounts',
  sidebarSections: [
    {
      inputs: [
        {
          id: 'FACEBOOK_ADS_ACCOUNT_ID',
          title: 'Enter your Facebook Ads Account ID',
          subtitle: (
            <>
              You can copy your 16-digit ad account ID by visiting{' '}
              <a
                href="https://business.facebook.com/settings/ad-accounts"
                target="_blank"
                rel="noreferrer"
              >
                Facebook Business Settings, under Ad Accounts.
              </a>
            </>
          ),
          placeholder: '1011111111111111',
          type: SidebarInputType.ValueText,
          required: true,
        },
      ],
      buttons: [],
    },
  ],
  hideOAuthApps: false,
};

const config: ActionConfig = {
  actionType: Action.FACEBOOK_ADS,
  name: 'Facebook Ads',
  description: 'Connect to your user’s Facebook Ads accounts',
  icon: iconSvg,
  provider: ProviderType.FACEBOOK,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Facebook Ads account',
          placeholder: 'connect to Facebook Ads',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.FACEBOOK,
                supportedTokenTypes: [TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length ? commonIntentInput : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.BUILD_AD_CREATIVE_OBJECT:
          return {
            inputs: [adCreativeTypeInput],
          };
        case Intent.UPDATE_AD_SET:
          return {
            inputs: [
              {
                ...adSetIdInput,
                subtitle: 'The ID of the ad set to update.',
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent) {
        case Intent.GET_AD_SETS:
          return { inputs: [adSetFilterInputOauth] };
        case Intent.GET_AD_SET_BY_ID:
          return { inputs: [adSetIdInput] };
        case Intent.CREATE_AD_SET:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...campaignIdInput,
                    subtitle: 'The ID of the campaign this ad set should be a part of.',
                  }
                : campaignDynamicInput,
              { ...nameInput, subtitle: 'The name of the ad set.' },
              campaignStatusInput,
              adSetOptimizationGoalInput,
              adSetBillingEventInput,
              adSetBidAmountInput,
              adSetDailyBudgetInput,
              adSetStartTimeInput,
              adSetTargetingInput,
              promotedObjectInput,
            ],
          };
        case Intent.UPDATE_AD_SET:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...campaignIdInput,
                    subtitle: 'The ID of the campaign this ad set should be a part of.',
                  }
                : campaignDynamicInput,
              { ...nameInput, subtitle: 'The name of the ad set.' },
              campaignStatusInput,
              adSetOptimizationGoalInput,
              adSetBillingEventInput,
              adSetBidAmountInput,
              adSetDailyBudgetInput,
              adSetStartTimeInput,
              adSetTargetingInput,
            ].map((item) => ({ ...item, required: false })),
          };
        case Intent.GET_CAMPAIGNS:
          return { inputs: [campaignFilterInputOauth] };
        case Intent.GET_CAMPAIGN_BY_ID:
          return { inputs: [{ ...campaignIdInput, subtitle: 'The ID of the campaign to get.' }] };
        case Intent.CREATE_CAMPAIGN:
          return {
            inputs: [nameInput, campaignObjectiveInput, campaignStatusInput],
          };
        case Intent.UPDATE_CAMPAIGN:
          return {
            inputs: [
              {
                ...(hasOAuthAppCredential(parameters, credentials)
                  ? campaignIdInput
                  : campaignDynamicInput),
                subtitle: 'The ID of the campaign to update.',
              },
              { ...nameInput, required: false },
              { ...campaignObjectiveInput, required: false },
              {
                ...campaignStatusInput,
                required: false,
                getValues: (): EnumInputValue[] => [
                  { label: 'Paused', value: 'PAUSED' },
                  { label: 'Active', value: 'ACTIVE' },
                  { label: 'Archived', value: 'ARCHIVED' },
                  { label: 'Deleted', value: 'DELETED' },
                ],
              } as EditableEnum,
            ],
          };
        case Intent.CREATE_AD_CREATIVE:
          return {
            inputs: [
              {
                ...nameInput,
                subtitle:
                  'The name of the ad creative, which appears in the ad account’s library. This field is required.',
              },
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...adCreativePageInput,
                    subtitle: 'The Facebook Page that the creative will be uploaded for.',
                  }
                : pageDynamicInput,
              adCreativeJsonInput,
            ],
          };
        case Intent.BUILD_AD_CREATIVE_OBJECT:
          const selectedAdCreativeType = pickValueSourceByKey<DataType.ANY>(
            parameters.actionParameters,
            'adCreativeType',
          )?.value;
          return {
            inputs: [
              adCreativeTypeInput,
              ...(adCreativeTypeMetadataInputs[selectedAdCreativeType] ?? []),
            ],
          };
        case Intent.CREATE_LEAD_GEN_FORM:
          return {
            inputs: [
              { ...nameInput, subtitle: '' },
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...adCreativePageInput,
                    subtitle: 'The Facebook Page that the creative will be uploaded for.',
                  }
                : pageDynamicInput,
              followUpActionTextInput,
              followUpActionUrlInput,
              privacyPolicyUrlInput,
              contextCardInput,
              questionJsonInput,
            ],
          };
        case Intent.SEND_PURCHASE_EVENT:
        case Intent.SEND_LEAD_EVENT:
        case Intent.SEND_FUNNEL_EVENT:
          return {
            inputs: [
              ...(parameters.intent === Intent.SEND_FUNNEL_EVENT
                ? [{ ...eventNameInput, required: true }]
                : []),
              eventActionSourceInput,
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...eventPixelInput,
                    subtitle: 'The Facebook Pixel that will receive the event.',
                    placeholder: 'select a pixel or type {{ to insert a pixel ID',
                  }
                : eventPixelDynamicInput,
              eventTimeInput,
              eventId,
              eventSourceUrlInput,
              eventCustomerEmailInput,
              eventCustomerFirstNameInput,
              eventCustomerLastNameInput,
              eventCustomerCityInput,
              eventCustomerStateInput,
              eventCustomerCountryCodeInput,
              eventCustomerExternalId,
              eventCustomerClientIpAddressInput,
              eventCustomerClientUserAgentInput,
              eventCustomerClickIdInput,
              eventBrowserIdInput,
              ...(parameters.intent === Intent.SEND_PURCHASE_EVENT
                ? [eventValueInput, eventCurrencyInput]
                : []),
              eventContentIdInput,
              eventContentNameInput,
              eventContentCategoryInput,
            ],
          };
        case Intent.CREATE_AD:
          return {
            inputs: [
              { ...nameInput, subtitle: 'Specify a name for this ad.' },
              adStatusInput,
              { ...adSetIdInput, subtitle: 'The ID of the ad set to create this ad for.' },
              adCreativeIdInput,
            ],
          };
        case Intent.UPDATE_AD:
          return {
            inputs: [
              adIdInput,
              ...[
                { ...nameInput, subtitle: 'Specify a name for this ad.' },
                adStatusInput,
                adCreativeIdInput,
              ].map((item) => ({
                ...item,
                required: false,
              })),
            ],
          };
        case Intent.GET_AD_BY_ID:
          return { inputs: [{ ...adIdInput, subtitle: 'The ID of the ad to get.' }] };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
