import moment from 'moment';

import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';
/**
 * @param condition
 * @returns condition for filter netsuite
 */

export default function conditionsToFilterNetsuiteExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterNetsuiteExpression(condition);
      })
      .join(condition.join === 'OR' ? ' OR ' : ' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} IS "${innerCondition.argument}"`;
      case Operator.NumberEquals:
        return `${innerCondition.variable} EQUAL ${innerCondition.argument}`;
      case Operator.NumberDoesNotEqual:
        return `${innerCondition.variable} EQUAL_NOT ${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} AFTER "${moment(innerCondition.argument).format(
          'MM/DD/YYYY',
        )}"`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} BEFORE "${moment(innerCondition.argument).format(
          'MM/DD/YYYY',
        )}"`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} ON "${moment(innerCondition.argument).format(
          'MM/DD/YYYY',
        )}"`;
      case Operator.StringStartsWith:
        return `${innerCondition.variable} START_WITH "${innerCondition.argument}"`;
      case Operator.StringEndsWith:
        return `${innerCondition.variable} END_WITH "${innerCondition.argument}"`;
      case Operator.StringContains:
        return `${innerCondition.variable} CONTAIN "${innerCondition.argument}"`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable} IS true`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} IS false`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Netsuite`);
    }
  }
  return '';
}
