import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  bccRecipientsInput,
  calendarInput,
  ccRecipientsInput,
  commonIntentInput,
  contentTypeInput,
  createEventInput,
  eventIdInput,
  eventsFilterInput,
  limitResultsInput,
  mailFolderInput,
  messageBodyInput,
  messagesFilterInput,
  subjectInput,
  toRecipientsInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.OUTLOOK,
  name: 'Microsoft Outlook',
  description: 'Connect your Outlook account to manage your mail and calendar in Outlook.',
  icon: iconSvg,
  provider: ProviderType.OUTLOOK,
  sidebarSections: [
    commonIntentInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.CREATE_EVENT:
          return {
            inputs: createEventInput(false),
          };

        case Intent.UPDATE_EVENT:
          return {
            inputs: [eventIdInput, ...createEventInput(true)],
          };

        case Intent.GET_EVENT_BY_ID:
          return {
            inputs: [
              { ...eventIdInput, subtitle: 'The ID of the event to get.' },
              {
                ...calendarInput,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select calendar. Defaults to the user’s primary calendar if left blank.',
              },
            ],
          };

        case Intent.GET_EVENTS:
          return {
            inputs: [calendarInput, eventsFilterInput],
          };

        case Intent.DELETE_EVENT:
          return {
            inputs: [
              { ...eventIdInput, subtitle: 'The ID of the event to delete.' },
              {
                ...calendarInput,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which calendar event will be deleted. Defaults to the user’s primary calendar if left blank.',
              },
            ],
          };

        case Intent.GET_MESSAGES:
          return {
            inputs: [mailFolderInput, messagesFilterInput, limitResultsInput],
          };

        case Intent.SEND_MESSAGE:
          return {
            inputs: [
              subjectInput,
              messageBodyInput,
              { ...contentTypeInput, required: true },
              toRecipientsInput,
              ccRecipientsInput,
              bccRecipientsInput,
              {
                ...mailFolderInput,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which mail folder to send from. Defaults to the user’s primary mail folder if left blank.',
              },
            ],
          };

        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };

export default connectConfig;
