import {
  ConnectCredentialProviderData,
  CredentialStatus,
} from '@shared/entities/sdk/credential/connectCredential.interface';
import { PersonaMeta } from '@shared/entities/sdk/persona/persona.interface';

import { Action, DataSource, SidebarInput, SidebarInputType } from './actions';
import { ExecutionStatus } from './steps';

export const SupportedConnectInputTypes = <const>[
  SidebarInputType.ValueText,
  SidebarInputType.DynamicEnum,
  SidebarInputType.Enum,
  SidebarInputType.Number,
  SidebarInputType.Email,
  SidebarInputType.URL,
  SidebarInputType.FieldMapper,
  SidebarInputType.BooleanInput,
  SidebarInputType.ComboInput,
  SidebarInputType.Password,
  SidebarInputType.Switch,
];

export type SupportedConnectInputType = typeof SupportedConnectInputTypes[number];

export type SupportedConnectInput<T extends SidebarInput = SidebarInput> = Extract<
  T,
  {
    type: SupportedConnectInputType;
  }
>;

export type FieldMappingValue = {
  objectMapping?: string;
  fieldMappings?: {
    [label: string]: string | undefined;
  };
  dynamicMappings?: {
    [mappingKey: string]: {
      applicationField?: string;
      integrationField?: string;
    };
  };
  userCreatedFields?: string[];
  /**
   * Type of field mapper used for creating field mappings
   * @default STATIC
   */
  mappingType?: 'STATIC' | 'DYNAMIC';
};

export type ComboInputValue = {
  mainInput?: string;
  dependentInput?: string;
  fieldMappings?: {
    [label: string]: string | undefined;
  };
};

export type DynamicMappingField = {
  label: string;
  value: string;
};

export type DynamicMappingOptions = {
  fields: DynamicMappingField[];
  userCanRemoveMappings?: boolean;
  userCanCreateFields?: boolean;
  defaultFields?: string[];
};

export type ConnectInputValue =
  | string
  | number
  | boolean
  | FieldMappingValue
  | ComboInputValue
  | undefined;

/**
 * A SerializedConnectInput is one that excludes the properties of a DynamicDataSource, which are
 * loaded in at runtime rather than saved in the DB.
 */
export type SerializedConnectInput<
  TInputType extends SupportedConnectInputType = SupportedConnectInputType,
> = {
  [T in SupportedConnectInputType]: Extract<
    SupportedConnectInput,
    {
      type: T;
    }
  > extends { source: DataSource } | { getValues: Function }
    ? Omit<
        Extract<
          SupportedConnectInput,
          {
            type: T;
          }
        >,
        'source' | 'getValues'
      > & {
        /**
         * If a Connect input is dynamic and uses a DynamicDataSource, `sourceType` will match the
         * `cacheKey` property of the source specified in the action's sources module.
         *
         * If it uses a FieldMapperDataSource, `sourceType` will match the `id` property of the source.
         */
        sourceType?: string;
        // this will show error message for post oauth input
        fallbackText?: string;
      }
    : Extract<
        SupportedConnectInput,
        {
          type: T;
        }
      >;
}[TInputType] & { tooltip?: string };

export type IntegrationSharedMeta = {
  /**
   * Inputs to intake global/shared user settings, configured in the Portal Editor.
   */
  inputs?: SerializedConnectInput[];
};

export type IntegrationWorkflowMeta = {
  /**
   * The ID of the WorkflowEntity.
   */
  id: string;
  /**
   * A longer-form description for the Workflow's functionality. Acts as a "subtitle" to the
   * WorkflowEntity's `description` property.
   */
  infoText?: string;

  /**
   * Determine whether or not to enable workflow for connected user
   *  @default false
   */
  defaultEnabled?: boolean;

  /**
   * Determines whether or not the workflow displays in the Connect Portal.
   * @default false
   */
  hidden?: boolean;
  /**
   * An index to track the positional order of a workflow in the list of displayed workflows.
   * If `order` is undefined, the workflow will be sorted by its `createdAt` property (after those
   * that are sorted manually by `order`.)
   */
  order?: number;
  /**
   * Inputs to intake workflow-specific settings, configured in the Portal Editor.
   */
  inputs: SerializedConnectInput[];
};

export type ModalConfig = {
  /**
   * A primary color for the background color of the Connect modal.
   */
  accentColor?: string;
  /**
   * A boolean to show / hide paragon link in footer section. (default true)
   */
  paragonLink?: boolean;
  /**
   * A description for the integration's purpose. Appears just below the integration name.
   */
  description?: string;
  /**
   * A long-form description for the integration's purpose. Appears in the Overview tab of the
   * Connect modal.
   */
  overview?: string;
  /**
   * Inputs and metadata about this integration's workflows.
   */
  workflowMeta?: { [id: string]: IntegrationWorkflowMeta };
  /**
   * Inputs metadata for shared workflow settings
   */
  sharedMeta?: IntegrationSharedMeta;
};

export interface ConnectCredentialConfig {
  configuredWorkflows?: { [workflowId: string]: IntegrationWorkflowState | undefined };
  sharedSettings?: { [inputId: string]: ConnectInputValue | undefined };
}

/**
 * info about configured workflows for an integration for a persona
 */
export type IntegrationWorkflowState = {
  enabled: boolean;
  settings: { [inputId: string]: ConnectInputValue | undefined };
  /**
   * timestamp value for last Execution Date for workflow . Will be synced up by chronos service.
   */
  lastExecutionDate?: Date;
  /**
   *  last execution status for workflow . Will be synced up by chronos service.
   */
  lastExecutionStatus?: ExecutionStatus;
};

/**
 * The contents of the `integrations` field for an authenticated ConnectUser.
 *
 * The fields from `ConnectCredentialConfig` are stored; all other fields are computed from other
 * available entities.
 */
export type SDKIntegrationState = Partial<
  Record<
    Action,
    {
      enabled: boolean;
      credentialId?: string;
      credentialStatus?: CredentialStatus;
      providerId?: string;
      providerData?: ConnectCredentialProviderData;
    } & ConnectCredentialConfig
  >
>;

export type AuthenticatedConnectUser = {
  authenticated: true;
  token: string;
  userId: string;
  integrations: SDKIntegrationState;
  meta: PersonaMeta;
};

export type IConnectUser = Omit<AuthenticatedConnectUser, 'token'>;

export const INFER_CONTENT_TYPE_FROM_CONNECT_OPTIONS = 'auto';

export const CONNECT_INTEGRATION_PROVIDER_BASE_URL: string =
  'https://docs.useparagon.com/v/connect/resources/integrations';

/**
 * PARA-4996
 * List of all external source hosts being used by connect UI
 * needs to be defined in `Content-Security-Policy` header to prevent malicious script injection.
 */
export enum ConnectExternalSourceHost {
  CloudflareCDN = 'https://cdnjs.cloudflare.com',
  CloudflareInsights = 'https://static.cloudflareinsights.com',
  GoogleFonts = 'https://fonts.googleapis.com',
  GoogleFontStatics = '*.gstatic.com',
}
