import { Stripe } from 'stripe';

import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const getPrices: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  cacheKey: 'prices',
  title: 'Price',
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
  ],
  mapRefreshToValues: (
    response: ActionResponse<Stripe.ApiList<Stripe.Price>>,
  ): EnumInputValue[] => {
    return response.result.output.data.map((price: Stripe.Price) => ({
      label: price.nickname || '',
      value: price.id,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.STRIPE,
      intent: Intent.GET_PRICES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getPlans: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'getPlans',
  title: 'Plan',
  subtitle: 'Plan that can be used to create Subscriptions',
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
  ],
  mapRefreshToValues: (response: ActionResponse<Stripe.ApiList<Stripe.Plan>>): EnumInputValue[] => {
    return response.result.output.data.map((plan: Stripe.Plan) => ({
      label: plan.nickname || '',
      value: plan.id,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.STRIPE,
      intent: Intent.GET_PLANS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'active',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    };
  },
};
