import { Operator } from '@shared/types/sdk/resolvers';

/**
 * SAGE_INTACCT_API_BASE_URL of public use
 */
export const SAGE_INTACCT_API_BASE_URL: string = '[API Session URL]';

/**
 * HTTP POST requests to communicate via a unique endpoint. The endpoint is always this.
 */
export const SAGE_INTACCT_API_ENDPOINT_BASE_URL: string =
  'https://api.intacct.com/ia/xml/xmlgw.phtml';

export const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.StringLessThan,
  Operator.NumberLessThanOrEqualTo,
  Operator.StringGreaterThan,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.IsNotNull,
  Operator.IsNull,
  Operator.StringContains,
  Operator.StringDoesNotContain,
];
