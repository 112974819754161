import React from 'react';

import {
  ActionResponse,
  getValuesByCacheKey,
  hasOAuthAppCredential,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionButtonStyle,
  ActionConfig,
  ActionStepParameters,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { JIRA_OPERATORS, JiraFieldsAllowedValue } from '../shared/types';
import {
  getSidebarInputType,
  getTextAreaLinesForField,
  isJiraTypeSupported,
} from '../shared/utils';

import {
  getIssueFields,
  getIssueMetaData,
  getIssueStatuses,
  getIssueTypesByProject,
  getProjects,
} from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.JIRA,
  provider: ProviderType.JIRA,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Jira account',
  icon: iconSvg,
  description: 'Jira',
  sidebarSections: [],
  hideOAuthApps: false,
};

const jqlDocLink = (
  <a
    href="https://confluence.atlassian.com/jiracoreserver073/advanced-searching-861257209.html#Advancedsearching-ConstructingJQLqueries"
    target="_blank"
    rel="noreferrer"
  >
    Jira Query Language (JQL)
  </a>
);

const issueKeyInputType: SidebarInput = {
  id: 'issueKey',
  title: 'Issue Key',
  placeholder: 'TEST-1234',
  required: true,
  type: SidebarInputType.TextArea,
  lines: 1,
};

const limitResultsInput: SidebarInput = {
  id: 'limit',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Limit results',
  subtitle: 'Limit the maximum number of issues to return. Defaults to 10 if left blank.',
  required: false,
};

function getIssueTypeInput(required: boolean, type: SidebarInputType): SidebarInput {
  if (type === SidebarInputType.DynamicEnum || type === SidebarInputType.EditableDynamicEnum) {
    return {
      id: 'issueType',
      type: type,
      title: 'Issue type',
      placeholder: 'Select an Issue type',
      required: required,
      source: getIssueTypesByProject,
      getValues: getValuesByCacheKey,
    };
  } else if (type === SidebarInputType.EditableEnum) {
    return {
      id: 'issueType',
      type: type,
      title: 'Issue type',
      subtitle:
        'The issue types provided below are the default Jira issue types. Your users may have custom issue types that are different.',
      placeholder: 'choose a issue type or enter a custom issue type',
      required: required,
      defaultValue: '10004',
      getValues: () => {
        return [
          { label: 'Story', value: '10001' },
          { label: 'Task', value: '10002' },
          { label: 'Bug', value: '10004' },
          { label: 'Epic', value: '10000' },
          { label: 'Subtask', value: '10003' },
        ];
      },
    };
  } else {
    return {
      id: 'issueType',
      type: SidebarInputType.TextArea,
      lines: 1,
      title: 'Issue type',
      placeholder: 'Issue type id',
      required: required,
    };
  }
}

const config: ActionConfig = {
  actionType: Action.JIRA,
  name: 'Jira',
  description: 'Create, update, and find issues in Jira Software.',
  icon: iconSvg,
  provider: ProviderType.JIRA,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Jira account',
          subtitle: '',
          placeholder: 'connect to Jira',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.JIRA,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.QUERY_PROJECTS,
                    label: 'Get projects',
                  },
                  {
                    value: Intent.CREATE_ISSUE,
                    label: 'Create issue',
                  },
                  {
                    value: Intent.UPDATE_ISSUE,
                    label: 'Update issue',
                  },
                  {
                    value: Intent.GET_ISSUE_BY_KEY,
                    label: 'Get issue by issue key',
                  },
                  {
                    value: Intent.FILTER_ISSUES,
                    label: 'Search issues',
                  },
                  {
                    value: Intent.SEARCH_BY_JQL,
                    label: 'Search issues by JQL query',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters, credential: Record<string, ICredential>): SidebarSection => {
      const projectsInput: SidebarInput = {
        id: 'project',
        title: 'Project',
        type: SidebarInputType.DynamicEnum,
        placeholder: 'Select a Project',
        required: true,
        source: getProjects,
        getValues: getValuesByCacheKey,
      };

      const filterInput: SidebarInput = {
        id: 'jqlQuery',
        type: SidebarInputType.DynamicConditional,
        title: 'Filter search',
        subtitle: 'Search for issues that match specified filters.',
        placeholder: 'field',
        source: {
          ...getIssueFields,
          cacheKey: 'cachedFilterIssueFields',
          mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
            return response?.result.output
              .filter(
                (field: Record<string, any>) =>
                  isJiraTypeSupported(field.key, field.schema) &&
                  field.key !== 'project' &&
                  field.key !== 'issuetype',
              )
              .map((field: Record<string, any>) => ({
                label: field.name,
                value: field.name,
              }));
          },
        },
        getValues: getValuesByCacheKey,
        supportedOperators: JIRA_OPERATORS,
        required: false,
      };

      const filterInputForOauth: SidebarInput = {
        id: 'jqlQuery',
        type: SidebarInputType.Conditional,
        title: 'Filter Search',
        subtitle: 'Search for issue that match specified filters.',
        placeholder: 'field',
        supportedOperators: JIRA_OPERATORS,
        required: false,
      };

      switch (parameters.intent) {
        case Intent.QUERY_PROJECTS:
          return {
            inputs: [
              {
                id: 'projectName',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Project name',
                required: false,
              },
              {
                id: 'projectType',
                type: SidebarInputType.Enum,
                title: 'Project type',
                required: false,
                getValues: () => {
                  return [
                    { label: 'Business', value: 'business' },
                    { label: 'Service Desk', value: 'service_desk' },
                    { label: 'Software', value: 'software' },
                  ];
                },
              },
              limitResultsInput,
            ],
          };
        case Intent.GET_ISSUE_BY_KEY:
          return {
            inputs: [issueKeyInputType],
          };
        case Intent.CREATE_ISSUE:
          return {
            inputs: hasOAuthAppCredential(parameters, credential)
              ? [getIssueTypeInput(true, SidebarInputType.EditableEnum)]
              : [projectsInput, getIssueTypeInput(true, SidebarInputType.EditableDynamicEnum)],
          };
        case Intent.UPDATE_ISSUE:
          return {
            inputs: hasOAuthAppCredential(parameters, credential)
              ? [issueKeyInputType]
              : [
                  projectsInput,
                  getIssueTypeInput(true, SidebarInputType.EditableDynamicEnum),
                  issueKeyInputType,
                ],
          };
        case Intent.SEARCH_BY_JQL:
          return {
            inputs: [
              {
                id: 'jqlQuery',
                title: 'JQL Query',
                type: SidebarInputType.Code,
                language: 'jql',
                lines: 4,
                useLightTheme: true,
                subtitle: <>Write a query using {jqlDocLink}.</>,
                required: true,
                placeholder: 'project = "TEST" AND assignee = “jules”',
              },
            ],
          };
        case Intent.FILTER_ISSUES:
          return {
            inputs: hasOAuthAppCredential(parameters, credential)
              ? [
                  getIssueTypeInput(false, SidebarInputType.EditableEnum),
                  filterInputForOauth,
                  limitResultsInput,
                ]
              : [
                  projectsInput,
                  getIssueTypeInput(false, SidebarInputType.EditableDynamicEnum),
                  filterInput,
                  limitResultsInput,
                ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
    (parameters: ActionStepParameters, credential: Record<string, ICredential>): SidebarSection => {
      if (parameters.intent === Intent.UPDATE_ISSUE) {
        const jiraIssueStatusInput: SidebarInput = {
          id: 'jiraIssueStatus',
          title: 'Issue Status',
          type: SidebarInputType.DynamicEnum,
          placeholder: 'Select issue Status',
          required: false,
          source: getIssueStatuses,
          getValues: getValuesByCacheKey,
        };
        return {
          inputs: hasOAuthAppCredential(parameters, credential)
            ? [
                {
                  id: 'jiraIssueStatus',
                  type: SidebarInputType.TextArea,
                  lines: 1,
                  title: 'Issue Status Id',
                  placeholder: 'issue status id',
                  required: false,
                },
              ]
            : [jiraIssueStatusInput],
        };
      } else {
        return {
          inputs: [],
        };
      }
    },
    (parameters: ActionStepParameters, credential: Record<string, ICredential>): SidebarSection => {
      const cachedFiledName =
        parameters.intent === Intent.CREATE_ISSUE
          ? 'cachedCreateIssueFields'
          : 'cachedEditIssueFields';
      //fieldsTypeInfo contains data from create metadata jira api endpoint
      const fieldsTypeInfo: Record<string, any>[] = Object.entries<Record<string, any>>(
        pickValueSourceByKey<DataType.ANY>(parameters.actionParameters, cachedFiledName)?.value ||
          [],
      ).map(([_, field]: [string, Record<string, any>]) => field);

      const fieldsInput: SidebarInput[] = fieldsTypeInfo
        .filter(
          (field: Record<string, any>) =>
            field.key !== 'project' &&
            field.key.toLowerCase() !== 'issuetype' &&
            //skipping project and issue type as they are already filled in by the user
            //but the schema info returns them
            isJiraTypeSupported(field.key, field.schema),
        )
        // to bring mandatory fields before optional fields
        .sort((a: Record<string, any>) => (a.required ? -1 : 1))
        .map((field: Record<string, any>) => {
          const jiraInputType: SidebarInputType = getSidebarInputType(field.key, field.schema);

          switch (jiraInputType) {
            // assumption: all enums will have a allowed value field
            case SidebarInputType.Enum:
              return {
                id: `field-${field.key}`,
                title: field.name,
                required: parameters.intent === Intent.CREATE_ISSUE ? field.required : false,
                type: SidebarInputType.Enum,
                getValues: () => {
                  return field.allowedValues.map((allowedValue: JiraFieldsAllowedValue) => ({
                    label: allowedValue.name || allowedValue.value,
                    value: allowedValue.id,
                  }));
                },
              };

            case SidebarInputType.TextArea:
            default:
              return {
                id: `field-${field.key}`,
                title: field.name,
                required: parameters.intent === Intent.CREATE_ISSUE ? field.required : false,
                type: SidebarInputType.TextArea,
                lines: getTextAreaLinesForField(field.key, field.schema),
              };
          }
        });

      return parameters.intent === Intent.CREATE_ISSUE || parameters.intent === Intent.UPDATE_ISSUE
        ? {
            inputs: hasOAuthAppCredential(parameters, credential)
              ? [
                  {
                    id: 'issueFieldsJson',
                    type: SidebarInputType.Code,
                    lines: 4,
                    language: 'json',
                    placeholder:
                      parameters.intent === Intent.CREATE_ISSUE
                        ? `{
"project": { "id": "10000" },
"summary": "something's wrong",
"issuetype": { "id": "10000" },
"assignee": { "name": "homer" },
"reporter": { "name": "smithers" },
}`
                        : `{
"summary": "Summary",
"description": "Description",
"assignee":{"name":"charlie"},
"customfield_10200" : "Test 1",
"customfield_10201" : "Value 1"
}`,
                    title: 'Issue fields JSON',
                    useLightTheme: true,
                  },
                ]
              : [...fieldsInput],
            buttons: hasOAuthAppCredential(parameters, credential)
              ? []
              : parameters.intent === Intent.CREATE_ISSUE
              ? [
                  {
                    id: 'refreshFields',
                    type: SidebarInputType.ActionButton,
                    title: 'Refresh Fields',
                    style: ActionButtonStyle.DEFAULT,
                    source: {
                      ...getIssueFields,
                      cacheKey: 'cachedCreateIssueFields',
                      refreshDependencies: [
                        'issueType',
                        'project',
                        (parameters: ActionStepParameters) => parameters.credentials[0],
                        (parameters: ActionStepParameters) => parameters.intent,
                      ],
                    },

                    hidden: true,
                  },
                ]
              : [
                  {
                    id: 'refreshFields',
                    type: SidebarInputType.ActionButton,
                    title: 'Refresh Fields',
                    style: ActionButtonStyle.DEFAULT,
                    source: {
                      ...getIssueMetaData,
                      refreshDependencies: [
                        'issueType',
                        'project',
                        parameters.intent,
                        (parameters: ActionStepParameters) => parameters.credentials[0],
                        (parameters: ActionStepParameters) => parameters.intent,
                      ],
                    },

                    hidden: true,
                  },
                ],
          }
        : { inputs: [] };
    },
  ],
};

export default config;
