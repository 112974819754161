import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SAILTHRU;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SAILTHRU_TEST_CONNECTION',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  [CredentialKeys.COMPANY_KEY]: string;
  [CredentialKeys.COMPANY_SECRET]: string;
};

export enum CredentialKeys {
  COMPANY_KEY = 'SAILTHRU_COMPANY_KEY',
  COMPANY_SECRET = 'SAILTHRU_COMPANY_SECRET',
}
