export default `<svg xmlns="http://www.w3.org/2000/svg" width="300" height="104" viewBox="0 0 300 104" fill="none"> <defs xmlns="http://www.w3.org/2000/svg"><linearGradient id="a" x1="262.15" y1="198.72" x2="262.15" y2="279.05" gradientTransform="matrix(1 0 0 -1 0 302)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fab51c"/><stop offset=".8" stop-color="#f4e705"/></linearGradient><linearGradient id="b" x1="222.48" y1="208.09" x2="154.66" y2="275.91" gradientTransform="matrix(1 0 0 -1 0 302)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#0a74b2"/><stop offset=".8" stop-color="#137fc1"/></linearGradient><linearGradient id="c" x1="134.2" y1="285.68" x2="92.67" y2="213.73" gradientTransform="matrix(1 0 0 -1 0 302)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#1aa34a"/><stop offset=".8" stop-color="#008d54"/></linearGradient><linearGradient id="d" x1="46.49" y1="204.14" x2="31.88" y2="286.98" gradientTransform="matrix(1 0 0 -1 0 302)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#bd2337"/><stop offset=".8" stop-color="#dd2034"/></linearGradient></defs>
<path d="M224.98 33.8231L234.97 22.8531H299.32V93.6431L289.69 103.183H224.98V33.8231V33.8231Z" fill="url('#a')"/>
<path d="M218.56 17.063L226.99 80.7631L222 94.3031L159.02 103.183L150.89 41.1431L154.96 25.703L218.56 17.063V17.063Z" fill="url('#b')"/>
<path d="M157.39 46.1731L152.66 26.8831L95.08 0.723083L66.2 70.1331L74.25 77.5431L131.8 103.663L157.39 46.1731V46.1731Z" fill="url('#c')"/>
<path d="M299.32 22.8531H234.97L224.98 33.8231L289.65 34.0231L299.32 22.8531V22.8531Z" fill="#F4ED74"/>
<path d="M299.32 22.8531L289.65 34.0231L289.69 103.183L299.32 93.6431V22.8531Z" fill="#E89123"/>
<path d="M218.56 17.063L154.96 25.703L150.89 41.1431L213.25 32.2631L218.56 17.063Z" fill="#90C9ED"/>
<path d="M218.56 17.063L213.25 32.2631L222 94.3031L226.99 80.7631L218.56 17.063V17.063Z" fill="#1799D5"/>
<path d="M74.25 77.5431L75.96 78.3131L100.83 20.5031L95.08 0.723083L66.2 70.1331L74.25 77.5431Z" fill="#67BE6C"/>
<path d="M95.08 0.723083L100.83 20.5031L157.39 46.1731L152.66 26.8831L95.08 0.723083Z" fill="#95CF9F"/>
<path d="M0.680054 41.8831L4.99005 19.6631L67.8701 9.95306L77.1701 71.5731L73.0101 93.0931L10.5501 103.073L0.680054 41.8831Z" fill="url('#d')"/>
<path d="M67.8701 9.95306L4.99005 19.6631L0.680054 41.8831L63.8501 32.0131L67.8701 9.95306Z" fill="#EE453D"/>
<path d="M67.87 9.95306L63.85 32.0131L73.01 93.0931L77.17 71.5731L67.87 9.95306V9.95306Z" fill="#761116"/>
<path d="M256.29 42.4431C241.88 42.4431 230.22 54.5431 230.22 69.4431C230.22 84.3431 241.9 96.4431 256.29 96.4431C270.68 96.4431 282.36 84.3431 282.36 69.4431C282.36 54.5431 270.7 42.4431 256.29 42.4431V42.4431ZM256.29 86.2431C248.39 86.2431 241.98 78.7331 241.98 69.4731C241.98 60.2131 248.39 52.7031 256.29 52.7031C264.19 52.7031 270.6 60.2131 270.6 69.4731C270.6 78.7331 264.2 86.2431 256.29 86.2431ZM127.63 36.8331C114.82 31.2031 99.45 37.9631 93.31 51.9031C87.17 65.8431 92.57 81.7731 105.4 87.4031C118.23 93.0331 133.56 86.3031 139.7 72.3031C145.84 58.3031 140.45 42.4731 127.63 36.8331V36.8331ZM109.55 77.8331C102.55 74.7231 99.92 65.2031 103.72 56.5331C107.52 47.8631 115.13 42.3331 123.54 45.7531C131.95 49.1731 133.05 59.0531 129.25 67.7531C125.45 76.4531 116.64 80.9031 109.55 77.8431V77.8331ZM167.61 42.9831C169.042 42.7648 170.502 43.1233 171.67 43.98C172.838 44.8367 173.618 46.1217 173.84 47.5531L180.53 91.0831C180.752 92.5106 180.4 93.968 179.551 95.137C178.703 96.3061 177.426 97.092 176 97.3231C174.568 97.5414 173.108 97.1829 171.94 96.3262C170.772 95.4695 169.992 94.1845 169.77 92.7531L163 49.2131C162.892 48.5012 162.926 47.775 163.1 47.0761C163.273 46.3773 163.583 45.7196 164.011 45.1408C164.44 44.562 164.978 44.0734 165.596 43.7032C166.213 43.3329 166.898 43.0882 167.61 42.9831V42.9831ZM196.24 38.9031C197.672 38.6848 199.132 39.0433 200.3 39.9C201.468 40.7567 202.248 42.0417 202.47 43.4731L209.16 87.0031C209.378 88.435 209.02 89.895 208.163 91.063C207.306 92.2309 206.021 93.0113 204.59 93.2331C203.158 93.4514 201.698 93.0929 200.53 92.2362C199.362 91.3795 198.582 90.0945 198.36 88.6631L191.67 45.1331C191.452 43.7012 191.81 42.2412 192.667 41.0732C193.524 39.9053 194.809 39.1249 196.24 38.9031V38.9031Z" fill="white"/>
<path d="M199.067 60.9881L171.155 65.2781L172.591 74.6181L200.503 70.3281L199.067 60.9881Z" fill="white"/>
<path d="M40.81 83.4531C45.75 82.5631 56.81 79.8331 57.95 85.4531C59.19 91.9031 50.91 91.8231 39.54 94.2131C27.95 96.5431 20.55 99.2131 20.87 87.2131C21.13 79.0031 24.28 74.3731 27.87 69.7531C29.4196 67.613 31.0099 65.5026 32.64 63.4231L32.74 63.3031C33.32 62.5431 33.84 61.8831 34.32 61.3031C36.91 58.1331 39.56 55.1131 39.72 50.3731C39.72 50.3731 35.62 50.9631 30.67 51.8831C25.72 52.8031 17.08 55.3131 15.94 49.6931C14.75 43.9031 20.49 43.6031 31.9 41.5531C43.31 39.5031 50.57 37.0531 50.57 48.5531C50.57 57.7731 46.48 62.5231 42.33 67.7131L42.218 67.8616C40.5088 70.1274 38.8816 72.2845 37.58 74.1831C35.05 77.8831 32.4 80.4831 31.81 85.0831L40.81 83.4531Z" fill="white"/>
</svg>
`;
