import { Dispatch } from 'redux';

import { IOrganization } from '@shared/entities/sdk/';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { Action } from '../../types';

export const getOrganizations = () => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'ORGANIZATION_ENTITY_GET_START' });

  try {
    const response = await api.get('/organizations');

    if (response.ok) {
      const organizations = await response.json();
      dispatch({ type: 'ORGANIZATION_ENTITY_GET_SUCCESS', organizations });
    } else {
      dispatch({
        type: 'ORGANIZATION_ENTITY_GET_FAILURE',
        message: await getErrorMessage(response),
      });
    }
  } catch (error) {
    dispatch({
      type: 'ORGANIZATION_ENTITY_GET_FAILURE',
      message: error.message,
    });
  }
};

export const updateOrganization =
  (organization: Partial<IOrganization>) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'ORGANIZATION_ENTITY_UPDATE_START' });

    try {
      const response = await api.put(`/organizations/${organization.id}`, organization);

      if (response.ok) {
        const organization = await response.json();
        dispatch({
          type: 'ORGANIZATION_ENTITY_UPDATE_SUCCESS',
          organization,
        });
      } else {
        dispatch({
          type: 'ORGANIZATION_ENTITY_UPDATE_FAILURE',
          message: await getErrorMessage(response),
        });
      }
    } catch (error) {
      dispatch({
        type: 'ORGANIZATION_ENTITY_UPDATE_FAILURE',
        message: error.message,
      });
    }
  };
