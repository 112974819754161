import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.ORACLE_FINANCIALS_CLOUD;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.ORACLE_FINANCIALS_CLOUD;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'ORACLE_FINANCIALS_CLOUD_TEST_CONNECTION',
  CREATE_INVOICE = 'ORACLE_FINANCIALS_CLOUD_CREATE_INVOICE',
  SEARCH_INVOICES = 'ORACLE_FINANCIALS_CLOUD_SEARCH_INVOICES',
  BUILD_INVOICE_LINE_ITEM = 'ORACLE_FINANCIALS_CLOUD_BUILD_INVOICE_LINE_ITEM',
  VALIDATE_INVOICE = 'ORACLE_FINANCIALS_CLOUD_GET_LIST_SUBSCRIBER',
  UPDATE_INVOICE = 'ORACLE_FINANCIALS_CLOUD_UPDATE_INVOICE',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_INVOICES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.VALIDATE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.BUILD_INVOICE_LINE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  ORACLE_CLOUD_URL = 'ORACLE_CLOUD_URL',
  ORACLE_USERNAME = 'ORACLE_USERNAME',
  ORACLE_PASSWORD = 'ORACLE_PASSWORD',
}

export type Credentials = {
  [CredentialKeys.ORACLE_CLOUD_URL]: string;
  [CredentialKeys.ORACLE_USERNAME]: string;
  [CredentialKeys.ORACLE_PASSWORD]: string;
};
