export default `<svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2433_2541)">
<path d="M37.0312 0.0280762H7.96875C3.56773 0.0280762 0 3.59581 0 7.99683V35.9343C0 40.3353 3.56773 43.9031 7.96875 43.9031H37.0312C41.4323 43.9031 45 40.3353 45 35.9343V7.99683C45 3.59581 41.4323 0.0280762 37.0312 0.0280762Z" fill="#FA0F00"/>
<path d="M34.9907 33.0188H29.6457C29.4135 33.023 29.1852 32.9581 28.99 32.8322C28.7948 32.7064 28.6415 32.5253 28.5495 32.312L22.7469 18.7447C22.7318 18.6919 22.7 18.6454 22.6563 18.612C22.6127 18.5787 22.5594 18.5603 22.5045 18.5595C22.4495 18.5588 22.3958 18.5758 22.3513 18.608C22.3068 18.6402 22.2738 18.6859 22.2573 18.7383L18.6412 27.3526C18.6215 27.3992 18.6137 27.4499 18.6185 27.5002C18.6232 27.5506 18.6403 27.5989 18.6683 27.6411C18.6962 27.6832 18.7342 27.7177 18.7787 27.7416C18.8233 27.7656 18.8731 27.7781 18.9236 27.7781H22.8985C23.0189 27.7781 23.1366 27.8135 23.237 27.88C23.3374 27.9465 23.416 28.0411 23.463 28.152L25.2032 32.0247C25.2493 32.1333 25.2678 32.2517 25.257 32.3693C25.2462 32.4869 25.2065 32.5999 25.1413 32.6984C25.0762 32.7968 24.9877 32.8776 24.8837 32.9335C24.7798 32.9894 24.6636 33.0187 24.5455 33.0188H10.0751C9.96625 33.0181 9.85926 32.9905 9.76366 32.9385C9.66806 32.8865 9.58682 32.8116 9.52718 32.7206C9.46754 32.6295 9.43135 32.5252 9.42184 32.4167C9.41232 32.3083 9.42979 32.1992 9.47267 32.0992L18.677 10.18C18.771 9.94803 18.9329 9.74979 19.1413 9.61119C19.3498 9.47258 19.5952 9.40005 19.8455 9.40306H25.1543C25.4047 9.39975 25.6503 9.47215 25.8588 9.6108C26.0673 9.74944 26.2291 9.94785 26.323 10.18L35.591 32.0992C35.6339 32.1991 35.6514 32.308 35.642 32.4163C35.6326 32.5245 35.5966 32.6288 35.5372 32.7198C35.4778 32.8108 35.3968 32.8857 35.3014 32.9379C35.2061 32.99 35.0993 33.0178 34.9907 33.0188Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2433_2541">
<rect width="45" height="43.875" fill="white" transform="translate(0 0.0280762)"/>
</clipPath>
</defs>
</svg>

`;
