import { DEFAULT_SUPPORTED_OPERATORS, Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs/config';

export const ZOHO_CRM_API_BASE_URL = '[Zoho API Domain]/crm/v3';
export const ZOHO_CRM_AUTH_BASE_URL = 'https://accounts.zoho.com/oauth/v2';

export enum ZohoCrmStandardRecord {
  'Account' = 'Accounts',
  'Contact' = 'Contacts',
  'Lead' = 'Leads',
  'Task' = 'Tasks',
}

export const RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME = '<specified by objectName property>' as const;

export const supportedOperators: Operator[] = DEFAULT_SUPPORTED_OPERATORS.filter(
  (operator: Operator) =>
    ![
      Operator.ArrayIsIn,
      Operator.ArrayIsNotEmpty,
      Operator.ArrayIsEmpty,
      Operator.ArrayIsNotIn,
      Operator.DoesNotExist,
      Operator.Exists,
    ].includes(operator),
);

export const selectFieldMapper: Record<ZohoCrmStandardRecord, string> = {
  [ZohoCrmStandardRecord.Account]:
    'Account_Name, Website, Phone, Description, Owner, Created_Time, Modified_Time',
  [ZohoCrmStandardRecord.Contact]:
    'First_Name, Last_Name, Account_Name, Email, Title, Description, Created_Time, Modified_Time',
  [ZohoCrmStandardRecord.Lead]:
    'First_Name, Company, Email, Phone, Website, Designation, Lead_Status, Description, Created_Time, Modified_Time',
  [ZohoCrmStandardRecord.Task]: 'Subject, Description, Owner, Created_Time, Modified_Time',
};

/**
 * this is the maximum number of records that are allowed in a COQL query
 */
export const MAX_RECORDS_ALLOWED = 200;

export const subtitleMapper: Partial<Record<Intent, string>> = {
  [Intent.CREATE_RECORD]: 'create',
  [Intent.UPDATE_RECORD]: 'update',
  [Intent.GET_RECORD_BY_ID]: 'get record',
  [Intent.SEARCH_RECORDS]: 'search',
  [Intent.DELETE_RECORD]: 'delete',
  [Intent.TRIGGER_RECORD_CREATED]: 'create',
  [Intent.TRIGGER_RECORD_UPDATED]: 'update',
};
