import { ValidationError } from 'class-validator';

import { IConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import {
  AuthenticationScheme,
  DecryptedCredential,
  OAuthCredentialValues,
} from '@shared/entities/sdk/credential/credential.interface';
import { ICustomIntegration } from '@shared/entities/sdk/integration/customIntegration.interface';
import { IConnectIntegration } from '@shared/entities/sdk/integration/integration.interface';
import { IntegrationWizardDTO } from '@shared/types/sdk/integrationWizard';
import { ExecutionStatus } from '@shared/types/sdk/steps';

import { Action, IntegrationWizardState } from '../types';

export type IntegrationWizardAction<
  T extends keyof IntegrationWizardDTO = keyof IntegrationWizardDTO,
> =
  | {
      type: 'INTEGRATION_WIZARD_DRAFT_LOAD';
      payload: {
        customIntegration: ICustomIntegration;
        integration: IConnectIntegration;
        credential: DecryptedCredential<AuthenticationScheme.OAUTH_APP>;
        previewCredential?: IConnectCredential;
      };
    }
  | { type: 'INTEGRATION_WIZARD_EDIT_VALUE'; field: T; value: IntegrationWizardDTO[T] }
  | {
      type: 'INTEGRATION_WIZARD_SAVE_START';
    }
  | {
      type: 'INTEGRATION_WIZARD_SAVE_SUCCESS';
      payload: ICustomIntegration;
    }
  | {
      type: 'INTEGRATION_WIZARD_SAVE_FAILURE';
      error: { message: string | ValidationError[] };
    }
  | { type: 'INTEGRATION_WIZARD_CLEAR_GLOBAL_ERROR' }
  | {
      type: 'INTEGRATION_WIZARD_ICON_UPLOAD_START';
    }
  | {
      type: 'INTEGRATION_WIZARD_ICON_UPLOAD_SUCCESS';
    }
  | {
      type: 'INTEGRATION_WIZARD_ICON_UPLOAD_FAILURE';
      error: ValidationError | { message: string };
    }
  | {
      type: 'INTEGRATION_WIZARD_ICON_DELETE_START';
    }
  | {
      type: 'INTEGRATION_WIZARD_ICON_DELETE_SUCCESS';
    }
  | {
      type: 'INTEGRATION_WIZARD_ICON_DELETE_FAILURE';
      error: string;
    }
  | {
      type: 'INTEGRATION_WIZARD_TEST_REQUEST_START';
    }
  | {
      type: 'INTEGRATION_WIZARD_TEST_REQUEST_SUCCESS';
      response: unknown;
    }
  | {
      type: 'INTEGRATION_WIZARD_TEST_REQUEST_FAILURE';
      error: unknown;
    };

export const initialState: IntegrationWizardState = {
  values: {},
  fieldErrors: {},
  processing: false,
};

export const handler = (
  state: IntegrationWizardState = initialState,
  action: Action,
): IntegrationWizardState => {
  switch (action.type) {
    case 'INTEGRATION_WIZARD_DRAFT_LOAD': {
      const { customIntegration, integration, credential } = action.payload;
      return {
        ...state,
        customIntegrationId: customIntegration.id,
        integrationId: integration.id,
        errorMessage: undefined,
        fieldErrors: {},
        values: {
          ...state.values,
          accentColor: integration.configs[0].values.accentColor,
          apiAuthorization: customIntegration.apiAuthorization,
          apiBaseUrl: customIntegration.apiBaseUrl,
          authenticationType: customIntegration.authenticationType,
          description: integration.configs[0].values.description,
          icon: customIntegration.icon,
          inputFields: customIntegration.inputFields,
          name: customIntegration.name,
          oauthAccessTokenUrl: customIntegration.oauthAccessTokenUrl,
          oauthAuthorizationUrl: customIntegration.oauthAuthorizationUrl,
          oauthClientId: (credential.values as OAuthCredentialValues).clientId,
          oauthClientSecret: (credential.values as OAuthCredentialValues).clientSecret,
          oauthScopes: customIntegration.oauthScopes,
          oauthIncludeClientAuthorizationHeader:
            customIntegration.oauthIncludeClientAuthorizationHeader,
          usePKCEInCodeExchange: customIntegration.usePKCEInCodeExchange,
          testEndpointPath: customIntegration.testEndpointPath,
        },
      };
    }
    case 'INTEGRATION_WIZARD_SAVE_START':
      return {
        ...state,
        processing: true,
      };
    case 'INTEGRATION_WIZARD_SAVE_SUCCESS':
      return {
        ...state,
        fieldErrors: {},
        errorMessage: undefined,
        processing: false,
      };
    case 'INTEGRATION_WIZARD_EDIT_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          [action.field]: action.value,
        },
      };
    case 'INTEGRATION_WIZARD_ICON_DELETE_START':
      return {
        ...state,
        processing: true,
      };
    case 'INTEGRATION_WIZARD_ICON_DELETE_SUCCESS':
      return {
        ...state,
        processing: false,
        values: {
          ...state.values,
          icon: undefined,
        },
      };
    case 'INTEGRATION_WIZARD_ICON_DELETE_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.error,
      };
    case 'INTEGRATION_WIZARD_SAVE_FAILURE':
      if (Array.isArray(action.error.message)) {
        return {
          ...state,
          fieldErrors: Object.fromEntries(
            action.error.message.map((error: ValidationError) => [
              error.property,
              Object.values(error.constraints).join(', '),
            ]),
          ),
        };
      } else {
        return {
          ...state,
          errorMessage: action.error.message,
        };
      }
    case 'INTEGRATION_WIZARD_CLEAR_GLOBAL_ERROR':
      return {
        ...state,
        errorMessage: undefined,
      };
    case 'INTEGRATION_WIZARD_TEST_REQUEST_START':
      return {
        ...state,
        testRequestStatus: ExecutionStatus.EXECUTING,
      };
    case 'INTEGRATION_WIZARD_TEST_REQUEST_SUCCESS':
      return {
        ...state,
        testRequestStatus: ExecutionStatus.SUCCEEDED,
        testRequestData: action.response,
      };
    case 'INTEGRATION_WIZARD_TEST_REQUEST_FAILURE':
      return {
        ...state,
        testRequestStatus: ExecutionStatus.FAILED,
        testRequestData: action.error,
      };
    default:
      return state;
  }
};
