import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { DateOperator } from '../shared/types';
import { dateOperators } from '../shared/utils';

import {
  detailsInput,
  emailInput,
  externalIdInput,
  nameInput,
  notesInput,
  phoneInput,
  roleInput,
  userIdInput,
} from './shared/inputs';

const ticketIdInput: SidebarInput = {
  id: 'ticketId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Ticket ID',
  placeholder: '35436',
};

export const ticketTypeInput: SidebarInput = {
  id: 'ticketType',
  type: SidebarInputType.Enum,
  title: 'Type',
  required: false,
  getValues: (): EnumInputValue[] => [
    { label: 'Problem', value: 'problem' },
    { label: 'Incident', value: 'incident' },
    { label: 'Question', value: 'question' },
    { label: 'Task', value: 'task' },
  ],
};

export const ticketPriorityInput: SidebarInput = {
  id: 'ticketPriority',
  type: SidebarInputType.Enum,
  title: 'Priority',
  required: false,
  getValues: (): EnumInputValue[] => [
    { label: 'Urgent', value: 'urgent' },
    { label: 'High', value: 'high' },
    { label: 'Normal', value: 'normal' },
    { label: 'Low', value: 'low' },
  ],
};

export const ticketStatusInput: SidebarInput = {
  id: 'ticketStatus',
  type: SidebarInputType.Enum,
  title: 'Status',
  required: false,
  getValues: (): EnumInputValue[] => [
    { label: 'New', value: 'new' },
    { label: 'Open', value: 'open' },
    { label: 'Pending', value: 'pending' },
    { label: 'Hold', value: 'hold' },
    { label: 'Solved', value: 'solved' },
    { label: 'Closed', value: 'closed' },
  ],
};

const ticketSubjectInput: SidebarInput = {
  id: 'ticketSubject',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Subject',
};

const ticketDescriptionInput: SidebarInput = {
  id: 'ticketDescription',
  type: SidebarInputType.TextArea,
  title: 'Description',
};

const ticketTagsInput: SidebarInput = {
  id: 'ticketTags',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Tags',
};

const ticketExternalIdInput: SidebarInput = {
  id: 'ticketExternalId',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'External ID',
};

const ticketCustomFields: SidebarInput = {
  id: 'ticketCustomFields',
  title: 'Custom fields',
  type: SidebarInputType.Code,
  lines: 3,
  required: false,
  language: 'json',
  useLightTheme: true,
  placeholder: `[
    { "id":    27642, "value": "745" },
    { "id":    27648, "value": "yes" }
  ]`,
};

const assigneeInput: SidebarInput = {
  id: 'assigneeId',
  type: SidebarInputType.TextArea,
  title: 'Assignee ID',
  required: false,
  lines: 1,
};

export const createdDateInput: SidebarInput = {
  id: 'createdDate',
  type: SidebarInputType.EnumTextAreaPairInput,
  title: 'Created Date',
  subtitle: 'Filter tickets by their created date.',
  placeholder: 'YYYY-MM-DD',
  required: false,
  getValues: (): EnumInputValue[] =>
    dateOperators.map((dateOperators: DateOperator) => ({
      label: dateOperators.label,
      value: dateOperators.value,
    })),
};

export const updatedDateInput: SidebarInput = {
  id: 'updatedDate',
  type: SidebarInputType.EnumTextAreaPairInput,
  title: 'Updated Date',
  subtitle: 'Filter tickets by their updated date.',
  placeholder: 'YYYY-MM-DD',
  required: false,
  getValues: (): EnumInputValue[] =>
    dateOperators.map((dateOperators: DateOperator) => ({
      label: dateOperators.label,
      value: dateOperators.value,
    })),
};

export const dueDateInput: SidebarInput = {
  id: 'dueDate',
  type: SidebarInputType.EnumTextAreaPairInput,
  title: 'Due Date',
  subtitle: 'Filter tickets by their due date.',
  placeholder: 'YYYY-MM-DD',
  required: false,
  getValues: (): EnumInputValue[] =>
    dateOperators.map((dateOperators: DateOperator) => ({
      label: dateOperators.label,
      value: dateOperators.value,
    })),
};

const connectConfig: ActionConfig = {
  actionType: Action.ZENDESK,
  name: 'Zendesk',
  description: 'Connect your Zendesk account to sync tickets from your Zendesk Support account.',
  icon: iconSvg,
  provider: ProviderType.ZENDESK,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              title: 'Tickets',
              items: [
                {
                  value: Intent.CREATE_TICKET,
                  label: 'Create Ticket',
                },
                {
                  value: Intent.UPDATE_TICKET,
                  label: 'Update Ticket',
                },
                {
                  value: Intent.ADD_COMMENT_TO_TICKET,
                  label: 'Add Comment to Ticket',
                },
                {
                  value: Intent.SEARCH_TICKETS,
                  label: 'Search Tickets',
                },
                {
                  value: Intent.GET_TICKET_BY_ID,
                  label: 'Get Ticket by ID',
                },
              ],
            },
            {
              title: 'Users',
              items: [
                { value: Intent.CREATE_USER, label: 'Create User' },
                { value: Intent.UPDATE_USER, label: 'Update User' },
                { value: Intent.SEARCH_USERS, label: 'Search Users' },
                { value: Intent.GET_USER_BY_ID, label: 'Get User by ID' },
              ],
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.CREATE_TICKET:
        case Intent.UPDATE_TICKET:
          return {
            inputs: [
              ...(parameters.intent == Intent.UPDATE_TICKET
                ? [
                    {
                      ...ticketIdInput,
                      subtitle: 'The ID of the ticket to update.',
                      required: true,
                    },
                  ]
                : []),
              {
                ...ticketSubjectInput,
                placeholder: 'Help, my printer is on fire!',
                required: parameters.intent === Intent.CREATE_TICKET,
              },
              {
                ...ticketDescriptionInput,
                lines: 2,
                placeholder: 'The smoke is very colorful.',
                subtitle: 'The first comment that appears on the ticket.',
                required: parameters.intent === Intent.CREATE_TICKET,
              },
              {
                id: 'requesterName',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Requester Name',
                placeholder: 'Jane Customer',
                subtitle:
                  'The name of the user who requested this ticket. If you add this field, you must also specify a Request Email in the next field.',
                required: true,
              },
              {
                id: 'requesterEmail',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Requester Email',
                placeholder: 'jane@example.com',
                subtitle: 'The subtitle of the user who requested this ticket.',
                required: true,
              },
              {
                ...assigneeInput,
                placeholder: '{{settings.assignee}}',
                subtitle:
                  'The Zendesk Agent assigned to this Ticket. Use Connect Portal Workflow Settings to allow users to select an Assignee.',
              },
              ticketTypeInput,
              ticketPriorityInput,
              ticketStatusInput,
              {
                id: 'ticketDueAt',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: false,
                title: 'Due At',
                placeholder: '2019-09-15T02:06:58.147Z',
                subtitle:
                  'If this is a ticket of type "task" it has a due date (ISO 8601 timestamp).',
              },
              {
                ...ticketTagsInput,
                placeholder: '["enterprise", "other_tag"]',
                subtitle: 'The array of tags applied to this ticket.',
              },
              {
                ...ticketExternalIdInput,
                subtitle: 'An ID you can use to link Zendesk Support tickets to local records',
              },
              ticketCustomFields,
            ],
          };

        case Intent.ADD_COMMENT_TO_TICKET:
          return {
            inputs: [
              {
                ...ticketIdInput,
                required: true,
                subtitle: 'The ID of the ticket the comment will be added to.',
              },
              {
                id: 'commentBody',
                type: SidebarInputType.TextArea,
                title: 'Body',
                lines: 2,
                required: true,
                subtitle: 'The comment message. Accepts plain text or HTML.',
                placeholder: 'Thanks for your help!',
              },
              {
                id: 'isPublic',
                title: 'Public',
                subtitle: 'True if a public comment; false if an internal note.',
                type: SidebarInputType.BooleanInput,
                required: false,
              },
            ],
          };
        case Intent.SEARCH_TICKETS:
          return {
            inputs: [
              {
                ...ticketSubjectInput,
                required: false,
                subtitle: 'Filter results by the text in the ticket’s subject.',
              },
              {
                ...ticketDescriptionInput,
                lines: 1,
                required: false,
                subtitle: "Filter results by the text in the ticket's description and comments.",
              },
              ticketStatusInput,
              ticketTypeInput,
              createdDateInput,
              updatedDateInput,
              dueDateInput,
              {
                id: 'requesterId',
                title: 'Requester ID',
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle: 'Filter by the user who requested this ticket.',
              },
              { ...assigneeInput, subtitle: 'Filter by the agent assigned to the ticket.' },
              {
                id: 'recipientEmail',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Recipient',
                placeholder: 'name@example.com',
                required: false,
                subtitle: 'Filter by the original recipient e-mail address of the ticket.',
              },
              ticketPriorityInput,
              { ...ticketTagsInput, subtitle: 'Filter tickets by their tag.' },
              { ...ticketExternalIdInput, subtitle: 'Filter by tickets by their external ID.' },
            ],
          };

        case Intent.GET_TICKET_BY_ID:
          return {
            inputs: [{ ...ticketIdInput, required: true }],
          };
        case Intent.CREATE_USER:
        case Intent.UPDATE_USER:
          return {
            inputs: [
              ...(parameters.intent === Intent.CREATE_USER
                ? []
                : [{ ...userIdInput, subtitle: 'The ID of the user to update.' }]),
              { ...nameInput, required: parameters.intent === Intent.CREATE_USER },
              {
                ...emailInput,
                subtitle:
                  parameters.intent === Intent.CREATE_USER
                    ? ''
                    : 'On update, a secondary email is added.',
              },
              phoneInput,
              roleInput,
              externalIdInput,
              detailsInput,
              notesInput,
            ],
          };
        case Intent.GET_USER_BY_ID:
          return { inputs: [{ ...userIdInput, subtitle: 'The ID of the user to get.' }] };
        case Intent.SEARCH_USERS:
          return {
            inputs: [
              { ...nameInput, subtitle: 'Filter results by the user’s name.' },
              { ...emailInput, subtitle: 'Filter results by the user’s email.' },
              roleInput,
              { ...externalIdInput, subtitle: 'Filter by users by their external ID.' },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
