// TODO: Add Webpack loader and change this back to SVG file
export default `<svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_22914_35703)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 51.5625L17.1232 43.8086C22.4989 50.827 28.2077 54.0613 34.5691 54.0613C40.8953 54.0613 46.4472 50.8643 51.582 43.9014L61.8446 51.4697C54.4412 61.5088 45.2303 66.8127 34.5691 66.8127C23.9432 66.8127 14.647 61.5461 7 51.5625Z" fill="url(#paint0_linear_22914_35703)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.3414 17.8633L16.3238 33.3888L8 23.7289L34.3772 1L60.5494 23.7466L52.1846 33.3711L34.3414 17.8633Z" fill="url(#paint1_linear_22914_35703)"/>
</g>
<defs>
<linearGradient id="paint0_linear_22914_35703" x1="7" y1="70.9548" x2="61.8446" y2="70.9548" gradientUnits="userSpaceOnUse">
<stop stop-color="#8930FD"/>
<stop offset="1" stop-color="#49CCF9"/>
</linearGradient>
<linearGradient id="paint1_linear_22914_35703" x1="8" y1="39.2207" x2="60.5494" y2="39.2207" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF02F0"/>
<stop offset="1" stop-color="#FFC800"/>
</linearGradient>
<clipPath id="clip0_22914_35703">
<rect width="69" height="69" fill="white"/>
</clipPath>
</defs>
</svg>
`;
