import { EndpointInput, EndpointStep, HttpMethod, StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';

/**
 * validates that an endpoint step is properly configured
 */
export default class EndpointStepValidator extends StepValidator<EndpointStep, EndpointInput> {
  stepType: EndpointStep['type'] = StepType.ENDPOINT;

  /**
   * validates that a step is valid
   *
   * @param {EndpointStep} step
   * @returns {EndpointStep}
   * @memberof EndpointStepValidator
   */
  validate(step: EndpointStep): EndpointStep {
    const { httpMethod, path: httpPath } = step.parameters;

    if (!Object.values(HttpMethod).includes(httpMethod)) {
      throw new Error(`${httpMethod} is not a valid http method.`);
    } else if (httpPath !== step.workflowId) {
      throw new Error(`${httpPath} is not a valid path.`);
    }

    return step;
  }
}
