import { IProject } from '@shared/entities/sdk/project/project.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<IProject> = {
  entities: {},
  processing: false,
  errorMessage: undefined,
};

export function handler(
  state: EntitiesState<IProject> = initialState,
  action: Action,
): EntitiesState<IProject> {
  switch (action.type) {
    case 'PROJECT_ENTITIES_CREATION_STARTED':
      return {
        ...state,
        processing: true,
      };
    case 'PROJECT_ENTITIES_CREATION_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          ...indexBy('id', action.projects),
        },
      };
    case 'PROJECT_ENTITIES_CREATION_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'PROJECTS_ENTITY_GET_STARTED':
      return {
        ...state,
        processing: true,
      };
    case 'PROJECTS_ENTITY_GET_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: indexBy('id', action.projects),
      };
    case 'PROJECTS_ENTITY_GET_FAILED':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'LEAD_QUALIFICATION_SUBMIT_SUCCESS':
      if (!action.updatedProjects) {
        return state;
      }
      return { ...state, entities: indexBy('id', action.updatedProjects) };
    default:
      return state;
  }
}
