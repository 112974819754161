import { DataType } from '@shared/types/sdk/resolvers';

export type WriteQueryDTO = {
  collectionName: string;
  operation: Operation;
  fields?: string;
  query: string;
  updateQuery?: string;
  limit?: string;
  skip?: string;
  sort?: string;
  key?: string;
};

export type Operation =
  | 'Find'
  | 'Insert One'
  | 'Insert Many'
  | 'Update One'
  | 'Update Many'
  | 'Delete One'
  | 'Delete Many'
  | 'Aggregate'
  | 'Distinct';

export type OperationOption =
  | 'query'
  | 'limit'
  | 'skip'
  | 'sort'
  | 'fields'
  | 'update query'
  | 'key';

export const operationOptions: Record<Operation, OperationOption[]> = {
  Find: ['query', 'fields', 'limit', 'skip', 'sort'],
  'Insert One': ['query'],
  'Insert Many': ['query'],
  'Update One': ['query', 'update query'],
  'Update Many': ['query', 'update query'],
  'Delete One': ['query'],
  'Delete Many': ['query'],
  Aggregate: ['query'],
  Distinct: ['key', 'query'],
};

export const operations: Operation[] = [
  'Find',
  'Insert One',
  'Insert Many',
  'Update One',
  'Update Many',
  'Delete One',
  'Delete Many',
  'Aggregate',
  'Distinct',
];

export type QueryOptions = Omit<
  WriteQueryDTO,
  'collectionName' | 'operation' | 'query' | 'limit' | 'skip' | 'updateQuery'
> & {
  query: object;
  limit?: number;
  skip?: number;
  updateQuery?: object;
};

export type TriggerRowCreatedDTO = {
  collectionName: string;
  orderBy: string;
  queryOptions?: object;
  limit?: number;
};

export type TriggerRowUpdatedDTO = TriggerRowCreatedDTO;

export type CreateRecordDTO = {
  collectionName: string;
  columns: { [key: string]: DataType };
  [key: string]: any;
};

export type DeleteOneRecordDTO = {
  collectionName: string;
  documentId: string;
};

export type DeleteRecordsDTO = {
  collectionName: string;
  queryOptions?: object;
  limit?: number;
};

export type FindOneDTO = DeleteOneRecordDTO;

export type FindRecordsDTO = {
  collectionName: string;
  queryOptions?: object;
  limit?: number;
  orderBy?: string;
};

export type UpdateOneDTO = {
  collectionName: string;
  documentId: string;
  columns: { [key: string]: DataType };
  [key: string]: any;
};

export type UpdateRecordsDTO = {
  collectionName: string;
  queryOptions?: object;
  columns: { [key: string]: DataType };
  [key: string]: any;
};
