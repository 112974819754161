import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SENDGRID;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SENDGRID_TEST_CONNECTION',
  SEND_EMAIL = 'SENDGRID_SEND_EMAIL',
  LIST_TEMPLATES = 'SENDGRID_LIST_TEMPLATES',
  SEND_TEMPLATE_EMAIL = 'SENDGRID_SEND_TEMPLATE_EMAIL',
}

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent. SendEmailParameters should be
// defined in this file.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.SEND_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_TEMPLATES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_TEMPLATE_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  SENDGRID_API_KEY: string;
};
