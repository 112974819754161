import React from 'react';

import {
  ActionResponse,
  createTestConnectionButton,
  getValuesByCacheKey,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const mailgunApiKeysLink = (
  <a href="https://app.mailgun.com/app/account/security/api_keys" target="_blank" rel="noreferrer">
    API Keys page
  </a>
);

const mailgunSendingDomainLink = (
  <a href="https://app.mailgun.com/app/dashboard" target="_blank" rel="noreferrer">
    Dashboard
  </a>
);

const getTemplates: DynamicDataSource = {
  title: 'Template',
  type: DataSourceType.DYNAMIC,
  cacheKey: 'templates',
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
  ],
  mapRefreshToValues: (response: ActionResponse<string[]>): EnumInputValue[] => {
    return response.result.output.map((template: string) => ({
      label: template,
      value: template,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MAILGUN,
      intent: Intent.LIST_TEMPLATES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const authConfig: ActionConfig = {
  actionType: Action.MAILGUN,
  provider: ProviderType.MAILGUN,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your Mailgun account',
  description: 'Add Mailgun to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this Mailgun account',
          placeholder: 'e.g. Production, Testing, etc.',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MAILGUN_API_KEY',
          title: 'Add your Mailgun private API Key',
          subtitle: <>To find your private API key, visit your Mailgun {mailgunApiKeysLink}.</>,
          placeholder: 'Mailgun private API Key',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MAILGUN_SENDING_DOMAIN',
          title: 'Add your Mailgun Sending Domain',
          subtitle: (
            <>To find your sending domains, visit your Mailgun {mailgunSendingDomainLink}.</>
          ),
          placeholder: 'Mailgun Sending Domain',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'EU_HOSTED',
          title: 'Region',
          subtitle: <>Choose a region in which your data is processed in.</>,
          placeholder: 'Region',
          type: SidebarInputType.Enum,
          defaultValue: 'false',
          required: false,
          getValues: () => [
            {
              label: 'EU',
              value: 'true',
            },
            {
              label: 'US',
              value: 'false',
            },
          ],
        },
      ],
      buttons: [
        createTestConnectionButton(Action.MAILGUN, [
          'MAILGUN_API_KEY',
          'MAILGUN_SENDING_DOMAIN',
          'EU_HOSTED',
        ]),
      ],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.MAILGUN,
  name: 'Mailgun',
  description: 'Send emails with Mailgun.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect your Mailgun account',
          placeholder: 'choose your Mailgun account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.SEND_EMAIL,
                    label: 'Send Email',
                  },
                  {
                    value: Intent.SEND_TEMPLATE_EMAIL,
                    label: 'Send Email from Template',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      if ([Intent.SEND_EMAIL, Intent.SEND_TEMPLATE_EMAIL].includes(parameters.intent as Intent)) {
        return {
          inputs: [
            {
              id: 'from',
              type: SidebarInputType.Text,
              title: 'From Email',
              placeholder: 'type or choose from previous steps',
              required: true,
            },
            {
              id: 'to',
              type: SidebarInputType.Text,
              title: 'Recipients',
              subtitle:
                'Separate multiple email addresses with commas. You can also pass in an array of email addresses from a previous step.',
              placeholder: 'separate multiple email addresses with commas',
              required: true,
            },
          ],
        };
      } else {
        return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEND_EMAIL:
          return {
            inputs: [
              {
                id: 'subject',
                type: SidebarInputType.TextArea,
                title: 'Subject',
                required: true,
                placeholder: 'Type {{ to insert variables',
                lines: 1,
              },
              {
                id: 'html',
                type: SidebarInputType.TextArea,
                title: 'Message',
                subtitle: 'You can send messages in plain text or HTML.',
                required: true,
              },
            ],
          };
        case Intent.SEND_TEMPLATE_EMAIL:
          return {
            inputs: [
              {
                id: 'subject',
                type: SidebarInputType.Text,
                title: 'Subject',
                placeholder: 'type or choose from previous steps',
                required: true,
              },
              {
                id: 'template',
                type: SidebarInputType.DynamicEnum,
                title: 'Template',
                placeholder: 'choose a template',
                required: true,
                source: getTemplates,
                getValues: getValuesByCacheKey,
              },
              {
                id: 'dynamicTemplateData',
                type: SidebarInputType.Code,
                language: 'json',
                title: 'Template Data',
                required: true,
                placeholder: `{
  firstName: 'John',
  lastName: 'Doe',
  address: '123 Main Street',
  city: 'Los Angeles',
  state: 'California',
}`,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
