export const ASANA_BASE_URL = 'https://app.asana.com/api/1.0/';

export const ASANA_AUTH_URL = 'https://app.asana.com/';

/**
 * Cache keys
 */
export const sectionsCacheKey: string = 'asanaSections';

export const projectForSectionCacheKey: string = 'asanaProjectForSection';
