import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs/config';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.GUSTO,
  provider: ProviderType.GUSTO,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Gusto account',
  icon: iconSvg,
  description: 'Manage your employees, pay roll, and jobs in Gusto.',
  sidebarSections: [],
  accountTypes: [
    {
      id: 'default',
      accountDescription: 'Production Account',
      scheme: AuthenticationScheme.OAUTH,
    },
    {
      id: 'demo',
      accountDescription: 'Demo Account',
      scheme: AuthenticationScheme.OAUTH,
      oauthParameters: [
        {
          key: 'demo',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    },
  ],
};

export default authConfig;
