import { StepType } from '@shared/types/sdk/steps';

export enum StepProgrammingLanguage {
  UNSPECIFIED = 'UNSPECIFIED',
  JAVASCRIPT = 'JAVASCRIPT',
  TYPESCRIPT = 'TYPESCRIPT',
}

export { StepType };

export enum StepStatus {
  INVALID = 'INVALID',
  READY = 'READY',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}
