import {
  ActionResponse,
  PaginatedActionResponse,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource, ValueSource } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import {
  epicIssueCacheKey,
  projectForEpicIssueCacheKey,
  projectsCacheKey,
} from '../shared/constants';
import {
  GetEpicIssuesByProjectTransformResponse,
  JiraIssueType,
  JiraProject,
  PaginatedAssigneeResponse,
} from '../shared/types';

export const getIssueTypesByProject: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  cacheKey: 'issueTypesByProject', // making cache key different from getIssueTypes
  title: 'Issue type',
  subtitle: 'Issue type, e.g. Bug, Task, or Story',
  refreshDependencies: [
    'project', // reference to input for projects used in classic
    projectsCacheKey, // cacheKey for getProjects source
  ],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const selectedProject =
      pickValueSourceByKey(options.actionParameters, 'project') ??
      pickValueSourceByKey(options.actionParameters, projectsCacheKey);

    return {
      intent: Intent.GET_ISSUE_TYPES_BY_PROJECT,
      actionType: Action.JIRA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'project',
          source: {
            type: 'VALUE',
            value: selectedProject && selectedProject.value,
          },
        },
      ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<JiraIssueType[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((issue: JiraIssueType) => ({
        label: issue.name,
        value: issue.id,
      }))
    );
  },
};

export const getIssueFields: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  title: 'Issue field',
  cacheKey: 'cachedIssueFields',
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
    return response?.result && response.result.output;
  },
  refreshDependencies: ['issueType', 'project'],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const issueType = pickValueSourceByKey(options.actionParameters, 'issueType');
    const projectKey = pickValueSourceByKey(options.actionParameters, 'project');
    return {
      actionType: Action.JIRA,
      intent: Intent.GET_ISSUE_FIELDS_BY_ISSUE_TYPE_ID,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'issueType',
          source: {
            type: 'VALUE',
            value: issueType?.value,
          },
        },
        {
          key: 'projectKey',
          source: {
            type: 'VALUE',
            value: projectKey?.value,
          },
        },
      ],
    };
  },
};

export const getIssueMetaData: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  title: 'Issue Metadata',
  cacheKey: 'cachedEditIssueFields',
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const issueId = pickValueSourceByKey(options.actionParameters, 'issueType');
    return {
      actionType: Action.JIRA,
      intent: Intent.GET_ISSUE_EDIT_METADATA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'issueType',
          source: {
            type: 'VALUE',
            value: issueId?.value,
          },
        },
      ],
    };
  },
};

export const getAssignees: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'assignees',
  title: 'Assignee',
  subtitle: 'JIRA user to be assigned to an issue',
  hideFromConnectFieldTypes: true,
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.JIRA,
      intent: Intent.GET_FIELD_META_DATA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'fieldName',
          source: {
            type: 'VALUE',
            value: 'assignee',
          },
        },
      ],
    };
  },
};

export const getIssueTypes: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'issueTypes',
  title: 'Issue type',
  subtitle: 'Issue type, e.g. Bug, Task, or Story',
  hideFromConnectFieldTypes: true,
  mapRefreshToValues: (response: ActionResponse<JiraIssueType[]>): any => {
    return (
      response?.result &&
      response.result.output?.map((issueType) => ({ label: issueType.name, value: issueType.id }))
    );
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.JIRA,
      intent: Intent.GET_ISSUE_TYPES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getIssueStatuses: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'jiraIssueStatuses',
  title: 'Issue status',
  subtitle: 'The stage the issue is at, e.g. To Do or Done',
  hideFromConnectFieldTypes: true,
  refreshDependencies: ['project', 'intent', 'issueType'],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const actionParam = options.actionParameters?.filter(
      (value: KeyedSource<DataType.ANY>) => value.key === 'issueType',
    );
    return {
      intent: Intent.GET_ISSUE_STATUSES,
      actionType: Action.JIRA,
      credentials: options.credentials,
      actionParameters: actionParam.length
        ? actionParam
        : [
            {
              key: 'issueType',
              source: {
                type: 'VALUE',
                value: '10002', // id for task issueType because we cant provide at runtime which issueType it is
              },
            },
          ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<JiraIssueType[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((issue: JiraIssueType) => ({
        label: issue.name,
        value: issue.id,
      }))
    );
  },
};

export const getIssueStatusByProject: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  cacheKey: 'issueIssueStatusByProject',
  title: 'Issue Status',
  subtitle: 'The stage the issue is at, e.g. To Do or Done',
  refreshDependencies: ['project', 'intent', 'issueType', projectsCacheKey],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const selectedProject = pickValueSourceByKey(options.actionParameters, projectsCacheKey);

    return {
      intent: Intent.GET_ISSUE_STATUS_BY_PROJECT,
      actionType: Action.JIRA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'project',
          source: {
            type: 'VALUE',
            value: selectedProject && selectedProject.value,
          },
        },
      ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<JiraIssueType[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((issue: JiraIssueType) => ({
        label: issue.name,
        value: issue.id,
      }))
    );
  },
};

export const getProjects: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: projectsCacheKey,
  title: 'Project',
  subtitle: 'The parent project issues should be created in',
  supportPagination: true,
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_PROJECTS,
      actionType: Action.JIRA,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (
    response: ActionResponse<PaginatedActionResponse<JiraProject[]>>,
  ): EnumInputValue[] =>
    response?.result?.output?.records.map((project: JiraProject) => ({
      label: project.name,
      value: project.key,
    })),
};

export const getAssigneesByProject: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'assigneeByProject',
  title: 'Assignee',
  subtitle: 'JIRA user to be assigned to an issue',
  supportPagination: true,
  hideFromConnectFieldTypes: true,
  mapRefreshToValues: (response: ActionResponse<PaginatedAssigneeResponse>): any =>
    response?.result.output?.records,
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const project = pickValueSourceByKey(options.actionParameters, projectsCacheKey);

    return {
      actionType: Action.JIRA,
      intent: Intent.GET_ALL_ASSIGNEES_BY_PROJECT,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'project',
          source: {
            type: 'VALUE',
            value: project?.value,
          },
        },
      ],
    };
  },
};

export const projectToAssigneesInput: ComboInputDataSource = {
  id: 'projectToAssignees',
  type: DataSourceType.COMBO_INPUT,
  title: 'Project and Assignees mapping',
  subtitle: 'Select assignees based on project',
  mainInputSource: getProjects,
  dependentInputSource: getAssigneesByProject,
};

export const projectToIssueTypeInput: ComboInputDataSource = {
  id: 'projectIssueTypeCombo',
  type: DataSourceType.COMBO_INPUT,
  title: 'Project Issue type combo',
  subtitle: 'The type of issue to create',
  mainInputSource: getProjects,
  dependentInputSource: getIssueTypesByProject,
};

export const projectToIssueStatusInput: ComboInputDataSource = {
  id: 'projectIssueStatusTypeCombo',
  type: DataSourceType.COMBO_INPUT,
  title: 'Project Issue Status combo',
  subtitle: 'The stage the issue is at, e.g. To Do or Done',
  mainInputSource: getProjects,
  dependentInputSource: getIssueStatusByProject,
};

export const getIssueAllFields: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Issue field',
  subtitle: 'A field of an issue type',
  cacheKey: 'cachedAllIssueFields',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.JIRA,
      intent: Intent.GET_ALL_ISSUE_FIELDS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const projectForEpicIssues: DynamicDataSource = {
  ...getProjects,
  hideFromConnectFieldTypes: true,
  cacheKey: projectForEpicIssueCacheKey,
};

export const getEpicIssuesByProject: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: epicIssueCacheKey,
  title: 'Epic',
  subtitle: 'The Epic type issues',
  hideFromConnectFieldTypes: true,
  supportPagination: true,
  refreshDependencies: [
    projectForEpicIssueCacheKey,
    // reference to input for projects used in classic
    'project',
  ],
  mapRefreshToValues: (
    response: ActionResponse<PaginatedActionResponse<GetEpicIssuesByProjectTransformResponse[]>>,
  ): EnumInputValue[] =>
    response?.result?.output.records.map((issue: GetEpicIssuesByProjectTransformResponse) => ({
      label: issue.summary,
      value: issue.id,
    })),
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const project: ValueSource<DataType.ANY> | undefined =
      pickValueSourceByKey(options.actionParameters, projectForEpicIssueCacheKey) ||
      pickValueSourceByKey(options.actionParameters, 'project');

    return {
      actionType: Action.JIRA,
      intent: Intent.GET_EPIC_ISSUES_BY_PROJECT,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'projectKey',
          source: {
            type: 'VALUE',
            value: project?.value,
          },
        },
      ],
    };
  },
};

export const projectToEpicIssueInput: ComboInputDataSource = {
  id: 'projectToEpicIssueTypeCombo',
  type: DataSourceType.COMBO_INPUT,
  title: 'Epic',
  subtitle: 'An epic that issues can be associated with',
  mainInputSource: projectForEpicIssues,
  dependentInputSource: getEpicIssuesByProject,
};
