import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

import { apiKeyInput, hostUrlInput, passwordInput, usernameInput } from './input';

const authConfig: ActionConfig = {
  actionType: Action.SAP_SUCCESS_FACTORS,
  provider: ProviderType.SAP_SUCCESS_FACTORS,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your SAP accounts',
  icon: iconSvg,
  description: "Connect to your users' SAP SuccessFactors accounts",
  sidebarSections: [],
  accountTypes: [
    {
      id: 'default',
      accountDescription: 'Production Account',
      scheme: AuthenticationScheme.BASIC,
      oauthParameters: [
        {
          key: 'default',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
      endUserSuppliedValues: [hostUrlInput, usernameInput, passwordInput],
    },
    {
      id: 'sandbox',
      accountDescription: 'Sandbox Account',
      scheme: AuthenticationScheme.BASIC,
      oauthParameters: [
        {
          key: 'sandbox',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
      endUserSuppliedValues: [apiKeyInput],
    },
  ],
};

export default authConfig;
