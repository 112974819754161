import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.TWITTER;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'TWITTER_TEST_CONNECTION',
}

/**
 * Combination of OAuthIntent, ActionIntent and ConnectIntent
 */
type IntentMap =
  | OAuthIntent.REFRESH_CREDENTIAL
  | OAuthIntent.GET_AUTH_ESSENTIALS
  | OAuthIntent.EXCHANGE_AUTH_CREDENTIALS
  | Intent.TEST_CONNECTION
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  ACCESS_TOKEN = 'TWITTER_ACCESS_TOKEN',
  SECRET_TOKEN = 'TWITTER_SECRET_TOKEN',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.SECRET_TOKEN]: string;
};
