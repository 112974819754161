import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedFunctionParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { FunctionStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import { getRequiredStepIdToResolveKeyedSources, resolveKeyedSources } from './resolvers.utils';

/**
 * a resolver for a function step's variables
 *
 * @export
 * @class FunctionStepResolver
 * @extends {StepResolver<FunctionStep, ResolvedFunctionParameters>}
 */
export class FunctionStepResolver extends StepResolver<FunctionStep, ResolvedFunctionParameters> {
  /**
   * resolves the input parameters for the step
   *
   * @param {ActionStepParameters} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedFunctionParameters}
   * @memberof FunctionStepResolver
   */
  resolveInputParameters(
    parameters: FunctionStep['parameters'],
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedFunctionParameters {
    const resolved: ResolvedFunctionParameters = resolveKeyedSources(
      variables,
      parameters,
      secrets,
      cachedConnectCredential,
      context,
      cachedPersona,
      'parameters',
    );

    return resolved;
  }

  getRequiredStepIdsForVariableResolution(parameters: FunctionStep['parameters']): string[] {
    return getRequiredStepIdToResolveKeyedSources(parameters.parameters);
  }
}
