import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_DRIVE;
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_DRIVE;
};

export enum Intent {
  TEST_CONNECTION = 'GOOGLE_DRIVE_TEST_CONNECTION',
  SAVE_FILE = 'GOOGLE_DRIVE_SAVE_FILE',
  LIST_FOLDERS = 'GOOGLE_DRIVE_LIST_FOLDERS',
  LIST_FOLDER_PARENTS = 'GOOGLE_DRIVE_LIST_FOLDER_PARENTS',
  LIST_FILES = 'GOOGLE_DRIVE_LIST_FILES',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SAVE_FILE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_FOLDERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_FOLDER_PARENTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_FILES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  OAUTH_ACCESS_TOKEN: string;
  OAUTH_REFRESH_TOKEN?: string;
};
