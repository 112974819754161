import * as noop from './index';

export default {
  promises: {
    mkdir: noop,
    readFile: noop,
    stat: noop,
    unlink: noop,
    writeFile: noop,
  },
};
