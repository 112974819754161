import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MYSQL;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  WRITESQL = 'MYSQL_WRITESQL',
  TEST_CONNECTION = 'MYSQL_TEST_CONNECTION',
  GET_TABLES = 'MYSQL_GET_TABLES',
  GET_COLUMNS = 'MYSQL_GET_COLUMNS',
  QUERY_RECORDS = 'MYSQL_QUERY_RECORDS',
  FIND_RECORD_BY_ID = 'MYSQL_FIND_RECORD_BY_ID',
  CREATE_RECORD = 'MYSQL_CREATE_RECORD',
  UPDATE_RECORD = 'MYSQL_UPDATE_RECORD',
  UPDATE_RECORD_BY_ID = 'MYSQL_UPDATE_RECORD_BY_ID',
  DELETE_RECORD = 'MYSQL_DELETE_RECORD',
  DELETE_RECORD_BY_ID = 'MYSQL_DELETE_RECORD_BY_ID',

  TRIGGER_ROW_CREATED = 'MYSQL_TRIGGER_ROW_CREATED',
  TRIGGER_ROW_UPDATED = 'MYSQL_TRIGGER_ROW_UPDATED',
}

export const TriggerIntents = [Intent.TRIGGER_ROW_CREATED, Intent.TRIGGER_ROW_UPDATED];

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent. SendEmailParameters should be
// defined in this file.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.WRITESQL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TABLES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_COLUMNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.QUERY_RECORDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIND_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_RECORD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ROW_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ROW_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  MYSQL_HOST: string;
  MYSQL_DATABASE: string;
  MYSQL_PORT: number;
  MYSQL_USER: string;
  MYSQL_PASSWORD: string;
};
