import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.LEVER;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'LEVER_TEST_CONNECTION',

  CREATE_APPLICATION = 'LEVER_CREATE_APPLICATION',
  GET_APPLICATION_BY_ID = 'LEVER_GET_APPLICATION_BY_ID',
  GET_APPLICATIONS = 'LEVER_GET_APPLICATIONS',

  CREATE_OPPORTUNITY = 'LEVER_CREATE_OPPORTUNITY',
  GET_OPPORTUNITY_BY_ID = 'LEVER_GET_OPPORTUNITY_BY_ID',
  GET_OPPORTUNITIES = 'LEVER_GET_OPPORTUNITIES',

  UPDATE_CONTACT = 'LEVER_UPDATE_CONTACT',
  GET_CONTACT_BY_ID = 'LEVER_GET_CONTACT_BY_ID',

  CREATE_POSTING = 'LEVER_CREATE_POSTING',
  UPDATE_POSTING = 'LEVER_UPDATE_POSTING',
  GET_POSTING_BY_ID = 'LEVER_GET_POSTING_BY_ID',
  GET_POSTINGS = 'LEVER_GET_POSTINGS',
}

/**
 * Combination of OAuthIntent, ActionIntent and ConnectIntent
 */
type IntentMap =
  | OAuthIntent.REFRESH_CREDENTIAL
  | OAuthIntent.GET_AUTH_ESSENTIALS
  | OAuthIntent.EXCHANGE_AUTH_CREDENTIALS
  | Intent.TEST_CONNECTION
  | Intent.CREATE_OPPORTUNITY
  | Intent.GET_OPPORTUNITY_BY_ID
  | Intent.GET_OPPORTUNITIES
  | Intent.UPDATE_CONTACT
  | Intent.GET_CONTACT_BY_ID
  | Intent.CREATE_POSTING
  | Intent.UPDATE_POSTING
  | Intent.GET_POSTING_BY_ID
  | Intent.GET_POSTINGS
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  ACCESS_TOKEN = 'LEVER_ACCESS_TOKEN',
  REFRESH_TOKEN = 'LEVER_REFRESH_TOKEN',
  IS_SANDBOX = 'IS_LEVER_SANDBOX',
  USER_ID = 'LEVER_USER_ID',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
  [CredentialKeys.IS_SANDBOX]: boolean;
  [CredentialKeys.USER_ID]: string;
};
