import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  commonIntentInput,
  getAndDeleteInputFieldCollection,
  inputFields,
  searchFields,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.DYNAMICS_365_FINANCE,
  name: 'Dynamics 365 Finance',
  description: `Connect to your users' Dynamics 365 Finance accounts`,
  icon: iconSvg,
  provider: ProviderType.DYNAMICS_365_FINANCE,
  sidebarSections: [
    commonIntentInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_VENDOR:
        case Intent.UPDATE_VENDOR:
        case Intent.CREATE_BILL:
        case Intent.UPDATE_BILL:
        case Intent.CREATE_BILL_LINE_ITEM:
        case Intent.UPDATE_BILL_LINE_ITEM:
        case Intent.CREATE_CUSTOMER:
        case Intent.UPDATE_CUSTOMER:
        case Intent.CREATE_INVOICE:
        case Intent.UPDATE_INVOICE:
        case Intent.CREATE_PAYMENT_JOURNAL:
        case Intent.UPDATE_PAYMENT_JOURNAL:
        case Intent.CREATE_PAYMENT_JOURNAL_LINE_ITEM:
        case Intent.UPDATE_PAYMENT_JOURNAL_LINE_ITEM:
          return {
            inputs: inputFields(parameters.intent) as SidebarInput[],
          };

        case Intent.GET_ACCOUNTS:
        case Intent.GET_VENDOR_BY_ID:
        case Intent.GET_BILL_BY_ID:
        case Intent.DELETE_BILL:
        case Intent.GET_BILL_LINE_ITEM_BY_ID:
        case Intent.DELETE_BILL_LINE_ITEM:
        case Intent.GET_CUSTOMER_BY_ID:
        case Intent.DELETE_CUSTOMER:
        case Intent.GET_INVOICE_BY_ID:
        case Intent.DELETE_INVOICE:
        case Intent.DELETE_PAYMENT_JOURNAL:
        case Intent.GET_PAYMENT_JOURNAL_LINE_ITEM_BY_ID:
        case Intent.GET_PAYMENT_JOURNAL_BY_ID:
        case Intent.DELETE_PAYMENT_JOURNAL_LINE_ITEM:
          return {
            inputs: getAndDeleteInputFieldCollection[parameters.intent],
          };

        case Intent.SEARCH_VENDORS:
        case Intent.SEARCH_BILLS:
        case Intent.SEARCH_BILL_LINE_ITEMS:
        case Intent.SEARCH_CUSTOMERS:
        case Intent.SEARCH_INVOICES:
        case Intent.SEARCH_PAYMENT_JOURNALS:
        case Intent.SEARCH_PAYMENT_JOURNAL_LINE_ITEMS:
          return {
            inputs: searchFields[parameters.intent],
          };

        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };

export default connectConfig;
