import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { FilterFormula } from '../shared/types';
import {
  buildValuePlaceholder,
  isValidPostgresIdentifier,
  wrapWordInInvertedComma,
} from '../shared/utils';

export function parseCondition(condition: ResolvedConditionWrapper, filterData: any[]): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return parseCondition(condition, filterData);
      })
      .join(condition.join === 'OR' ? ' OR ' : ' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    //check if column identifier is valid
    if (!isValidPostgresIdentifier(innerCondition.variable)) {
      throw new Error(`[POSTGRESQL] Invalid column identfier: ${innerCondition.variable}`);
    }

    const valuePlaceholder: string = buildValuePlaceholder(filterData);
    switch (innerCondition.operator) {
      case Operator.StringContains:
        filterData.push(`%${innerCondition.argument}%`);
        return `"${innerCondition.variable}" LIKE ${valuePlaceholder}`;
      case Operator.StringDoesNotContain:
        filterData.push(`%${innerCondition.argument}%`);
        return `"${innerCondition.variable}" NOT LIKE ${valuePlaceholder}`;
      case Operator.StringExactlyMatches:
        filterData.push(innerCondition.argument);
        return `"${innerCondition.variable}" = ${valuePlaceholder}`;
      case Operator.StringDoesNotExactlyMatch:
        filterData.push(innerCondition.argument);
        return `"${innerCondition.variable}" != ${valuePlaceholder}`;
      case Operator.StringIsIn:
        const strintIsInArgument: string[] = innerCondition.argument as string[];
        const valPlaceholdersIsInString: any[] = wrapWordInInvertedComma(strintIsInArgument).map(
          (word) => {
            const valuePlaceholder: string = buildValuePlaceholder(filterData);
            filterData.push(word);
            return valuePlaceholder;
          },
        );
        return `"${innerCondition.variable}" in (${valPlaceholdersIsInString.join(',')})`;
      case Operator.StringIsNotIn:
        const strintIsNotInArgument: string[] = innerCondition.argument as string[];
        const valPlaceholderNotInString: any[] = wrapWordInInvertedComma(strintIsNotInArgument).map(
          (word) => {
            const valuePlaceholder: string = buildValuePlaceholder(filterData);
            filterData.push(word);
            return valuePlaceholder;
          },
        );
        return `"${innerCondition.variable}" not in (${valPlaceholderNotInString.join(',')})`;
      case Operator.StringIsNotIn:
      case Operator.StringStartsWith:
        filterData.push(`${innerCondition.argument}%`);
        return `"${innerCondition.variable}" LIKE ${valuePlaceholder}`;
      case Operator.StringDoesNotStartWith:
        filterData.push(`${innerCondition.argument}%`);
        return `"${innerCondition.variable}" NOT LIKE ${valuePlaceholder}`;
      case Operator.StringEndsWith:
        filterData.push(`%${innerCondition.argument}`);
        return `"${innerCondition.variable}" LIKE ${valuePlaceholder}`;
      case Operator.StringDoesNotEndWith:
        filterData.push(`%${innerCondition.argument}`);
        return `"${innerCondition.variable}" NOT LIKE ${valuePlaceholder}`;
      case Operator.NumberLessThan:
        filterData.push(innerCondition.argument);
        return `"${innerCondition.variable}" < ${valuePlaceholder}`;
      case Operator.NumberEquals:
        filterData.push(innerCondition.argument);
        return `"${innerCondition.variable}" = ${valuePlaceholder}`;
      case Operator.NumberGreaterThan:
        filterData.push(innerCondition.argument);
        return `"${innerCondition.variable}" > ${valuePlaceholder}`;
      case Operator.NumberDoesNotEqual:
        filterData.push(innerCondition.argument);
        return `"${innerCondition.variable}" != ${valuePlaceholder}`;
      case Operator.NumberGreaterThanOrEqualTo:
        filterData.push(innerCondition.argument);
        return `"${innerCondition.variable}" >= ${valuePlaceholder}`;
      case Operator.NumberLessThanOrEqualTo:
        filterData.push(innerCondition.argument);
        return `"${innerCondition.variable}" <= ${valuePlaceholder}`;
      case Operator.DateTimeAfter:
        filterData.push(new Date(innerCondition.argument).toUTCString());
        return `"${innerCondition.variable}" > ${valuePlaceholder}`;
      case Operator.DateTimeBefore:
        filterData.push(new Date(innerCondition.argument).toUTCString());
        return `"${innerCondition.variable}" < ${valuePlaceholder}`;
      case Operator.DateTimeEquals:
        filterData.push(new Date(innerCondition.argument).toUTCString());
        return `"${innerCondition.variable}" = ${valuePlaceholder}`;
      case Operator.BooleanTrue:
        filterData.push(true);
        return `"${innerCondition.variable}" = ${valuePlaceholder}`;
      case Operator.BooleanFalse:
        filterData.push(false);
        return `"${innerCondition.variable}" = ${valuePlaceholder}`;
      case Operator.IsNotNull:
        return `"${innerCondition.variable}" IS NOT NULL`;
      case Operator.IsNull:
        return `"${innerCondition.variable}" IS NULL`;
      case Operator.ArrayIsEmpty:
        return `array_length("${innerCondition.variable}", 1) = 0`;
      case Operator.ArrayIsNotEmpty:
        return `array_length("${innerCondition.variable}", 1) > 0`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for PostgreSQL`);
    }
  }
  return '';
}

/**
 * return filterFormula for postgres conditional object
 * @param condition
 */
export default function conditionsToSql(condition: ResolvedConditionWrapper): FilterFormula {
  const filterData: any[] = [];
  const formula: FilterFormula = {
    text: parseCondition(condition, filterData),
    values: filterData,
  };
  return formula;
}
