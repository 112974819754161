// TODO: Add Webpack loader and change this back to SVG file
export default `<svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.6875 1.46689L2.02813 18.1802C0.490625 19.7177 0.490625 22.2138 2.02813 23.7513C3.56563 25.2888 6.06172 25.2888 7.59922 23.7513L24.3125 7.09189L18.6875 1.46689Z" fill="url(#paint0_linear_2128_2368)"/>
<path d="M24.3148 7.09189C22.7586 8.64814 20.239 8.65282 18.6828 7.09657C17.1265 5.54032 17.1312 3.02079 18.6875 1.4622C20.2437 -0.0940526 22.7633 -0.09874 24.3219 1.45751C25.8804 3.01376 25.8734 5.53564 24.3148 7.09189Z" fill="#34A853"/>
<path d="M40.9742 18.1169C39.4391 16.5794 36.9523 16.5794 35.4172 18.1122L35.4148 18.1098L18.6875 34.7481L24.2867 40.3989L40.9789 23.6809L40.9766 23.6786C42.5094 22.1434 42.507 19.652 40.9742 18.1169Z" fill="#FBBC04"/>
<path d="M21.5 41.545C23.7005 41.545 25.4844 39.7611 25.4844 37.5606C25.4844 35.3601 23.7005 33.5763 21.5 33.5763C19.2995 33.5763 17.5156 35.3601 17.5156 37.5606C17.5156 39.7611 19.2995 41.545 21.5 41.545Z" fill="#34A853"/>
<path d="M32.7547 9.66766C31.1984 8.10907 28.6789 8.10907 27.1227 9.66766C27.0898 9.70048 27.0617 9.73563 27.0312 9.77079L18.6875 18.1075L24.3125 23.7325L32.6539 15.4005C32.6867 15.37 32.7219 15.3419 32.7547 15.3091C34.3109 13.7505 34.3109 11.2239 32.7547 9.66766Z" fill="#FBBC04"/>
<path d="M18.6758 18.1239L10.1937 26.5966C8.64218 28.1434 8.70312 30.6419 10.2523 32.1911C11.8016 33.738 14.2555 33.7497 15.8047 32.2028L24.2937 23.7512L18.6758 18.1239Z" fill="url(#paint1_linear_2128_2368)"/>
<path d="M24.3125 23.7325C22.7539 25.2911 20.2414 25.3239 18.6828 23.7653C17.1242 22.2067 17.1289 19.6661 18.6875 18.1075C20.2461 16.5489 22.7657 16.5653 24.3242 18.1239C25.8828 19.6825 25.8711 22.1763 24.3125 23.7325Z" fill="#34A853"/>
<defs>
<linearGradient id="paint0_linear_2128_2368" x1="21.5" y1="4.27939" x2="2.02871" y2="23.7507" gradientUnits="userSpaceOnUse">
<stop stop-color="#1A6DDD"/>
<stop offset="0.1393" stop-color="#2976E6"/>
<stop offset="0.339" stop-color="#377FEE"/>
<stop offset="0.5838" stop-color="#4084F3"/>
<stop offset="1" stop-color="#4285F4"/>
</linearGradient>
<linearGradient id="paint1_linear_2128_2368" x1="21.4837" y1="20.9339" x2="10.2379" y2="32.1797" gradientUnits="userSpaceOnUse">
<stop stop-color="#1A6DDD"/>
<stop offset="0.1326" stop-color="#2775E5"/>
<stop offset="0.3558" stop-color="#367EED"/>
<stop offset="0.6162" stop-color="#3F83F2"/>
<stop offset="1" stop-color="#4285F4"/>
</linearGradient>
</defs>
</svg>`;
