import React from 'react';

import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { CredentialKeys } from '../configs/config';

export const apiKeyInput: SidebarInput = {
  id: CredentialKeys.CLOSE_API_KEY,
  type: SidebarInputType.ValueText,
  required: true,
  title: 'Close Api Key',
  subtitle: (
    <>
      The API uses HTTP Basic authentication. API keys are per-organization and can be generated and
      deleted in the Settings page.{' '}
      <a href="https://help.close.com/docs/api-keys" target="_blank" rel="noreferrer">
        Learn more.
      </a>
    </>
  ),
};
