import { Operator } from '@shared/types/sdk/resolvers';

export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;
};

export type Environment = {
  GOOGLE_CLIENT_ID: string;
  GOOGLE_CLIENT_SECRET: string;
  GOOGLE_SCOPES?: string;
};

export type GoogleSheetColumn = {
  label: string;
  value: string;
};

export type GetAuthUrlDto = {
  redirectUrl: string;
  state: Record<string, any>;
};

export type GetSheetRowsForWorksheetDTO = {
  spreadsheetId: string;
  worksheet: string;
  range?: string;
  filterFormula: string;
  limit?: string;
};

export type UpdateSheetRowsForWorksheetDTO = {
  spreadsheetId: string;
  worksheet: string;
  filterFormula: string;
  cachedColumns?: GoogleSheetColumn[];
  additionalFields?: string;
  [key: string]: any;
};

export type InsertSheetRowsInWorkSheetDTO = {
  spreadsheetId: string;
  worksheet: string;
  cachedColumns?: (string | GoogleSheetColumn)[]; // NOTE. string[] is just for backward compatablity
  additionalFields?: string;
  [key: string]: any;
};

export type ListColumnsInWorkSheetDTO = {
  spreadsheetId: string;
  worksheet: string;
};

export type ListAllWorkSheetBySpreadSheetId = {
  spreadsheetId: string;
};

export type ExchangeCredentialDto = {
  code: string;
  redirectUrl: string;
};

export const GOOGLE_SHEET_OPERATORS: Operator[] = [
  Operator.StringContains,
  Operator.StringDoesNotContain,
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.StringStartsWith,
  Operator.StringDoesNotStartWith,
  Operator.StringEndsWith,
  Operator.StringDoesNotEndWith,
  Operator.NumberGreaterThan,
  Operator.NumberLessThan,
  Operator.NumberEquals,
  Operator.NumberDoesNotEqual,
  Operator.DateTimeAfter,
  Operator.DateTimeBefore,
  Operator.DateTimeEquals,
  Operator.BooleanTrue,
  Operator.BooleanFalse,
  Operator.IsNotNull,
  Operator.IsNull,
];

export const ALPHABETS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export type RowCreatedTriggerDTO = {
  spreadsheetId: string;
};

export type RowUpdatedTriggerDTO = RowCreatedTriggerDTO;
