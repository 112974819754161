import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class DeploymentError extends ParagonError {
  name: string = 'DeploymentError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class MissingDeployedWorkflowInfoError extends DeploymentError {
  constructor(
    meta: { workflowId: string; projectId: string; property: string },
    httpStatus: HttpStatus = HttpStatus.FAILED_DEPENDENCY,
  ) {
    super({
      code: ERROR_CODE.MISSING_DEPLOYED_WORKFLOW_INFO,
      httpStatus,
      message: `Deployed workflow missing information: ${meta.property}`,
      meta,
    });
  }
}

export class InvalidDeploymentError extends DeploymentError {
  constructor(meta: { workflowId: string; workflowMigrationId?: string; projectId: string }) {
    super({
      code: ERROR_CODE.INVALID_DEPLOYMENT,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Invalid deployment',
      meta,
    });
  }
}

export class InvalidWorkflowDeploymentError extends DeploymentError {
  constructor(meta: {
    workflowId: string;
    projectId: string;
    errors: { id: string; error: string }[];
  }) {
    super({
      code: ERROR_CODE.INVALID_DEPLOYMENT,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Could not deploy workflow',
      meta,
    });
  }
}

export class ConnectWorkflowDeploymentMissingIntegrationIdError extends DeploymentError {
  constructor(meta: { workflowId: string; projectId: string }) {
    super({
      code: ERROR_CODE.CONNECT_WORKFLOW_DEPLOYMENT_MISSING_INTEGRATION_ID,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Connect workflow missing integration.',
      meta,
    });
  }
}

export class DeploymentMissingCachedCredentialsError extends DeploymentError {
  constructor(meta: { workflowId: string; projectId: string }) {
    super({
      code: ERROR_CODE.DEPLOYMENT_MISSING_CACHED_CREDENTIALS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Deployment missing cached credentials.',
      meta,
    });
  }
}

export class PreviousDeploymentsCleanupError extends DeploymentError {
  name: string = 'PreviousDeploymentsCleanupError';

  constructor(meta: { projectId: string; workflowId: string }) {
    super({
      code: ERROR_CODE.PREVIOUS_DEPLOYMENTS_CLEANUP,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Error occured while cleaning up previuos workflow deployments.',
      meta,
    });
  }
}

export class WorkflowDeploymentError extends DeploymentError {
  name: string = 'WorkflowDeploymentError';

  constructor(meta: { projectId: string; workflowId: string }) {
    super({
      code: ERROR_CODE.WORKFLOW_DEPLOYMENT_ERROR,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Workflow Deployment failed.',
      meta,
    });
  }
}
