import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { listInput } from './common';

const triggerConfig: ActionConfig = {
  actionType: Action.CLICKUP,
  name: 'ClickUp',
  description: 'Trigger when tasks are created or updated in ClickUp',
  icon: iconSvg,
  provider: ProviderType.CLICKUP,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_TASK_CREATED,
              label: 'Task Created',
            },
            {
              value: Intent.TRIGGER_TASK_UPDATED,
              label: 'Task Updated',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      return parameters.intent
        ? {
            inputs: [listInput],
          }
        : { inputs: [] };
    },
  ],
};

export default triggerConfig;
