import React from 'react';

import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.SEGMENT,
  provider: ProviderType.SEGMENT,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Segment account',
  icon: iconSvg,
  description: 'Access, create and update their data in Segment.',
  sidebarSections: [
    {
      inputs: [
        {
          id: CredentialKeys.SEGMENT_API_KEY,
          type: SidebarInputType.ValueText,
          required: true,
          title: 'Segment Api Key',
          subtitle: (
            <>
              You can access the Config API programmatically using access tokens. When you
              authenticate with an access token, you have access to any resource and permission
              assigned to the token.{' '}
              <a
                href="https://segment.com/docs/api/config-api/authentication/"
                target="_blank"
                rel="noreferrer"
              >
                Learn more.
              </a>
            </>
          ),
        },
      ],
      buttons: [createTestConnectionButton(Action.SEGMENT, Object.values(CredentialKeys))],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.SEGMENT,
  name: 'Segment',
  description: 'Access, create and update data in Segment.',
  icon: iconSvg,
  sidebarSections: [],
};

export { authConfig };

export default config;
