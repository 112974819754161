import { editor } from 'monaco-editor';
import { transparentize } from 'polished';
import styled, { css, keyframes } from 'styled-components';

export const COLORS = {
  BACKGROUND: '#FCFCFC',
  BACKGROUND_HARD: '#FAFAFA',
  BLACK: '#333333',
  BORDER: '#E2EBF8',
  DARK_BLACK: '#1E1E1E',
  /**
   * @deprecated Use `transparentize(0.15, COLORS.BORDER)` instead.
   */
  BOX_SHADOW: 'rgba(226, 235, 248, 0.15)',
  /**
   * @deprecated Use `transparentize(0.5, COLORS.BORDER)` instead.
   */
  BOX_SHADOW_HARD: 'rgba(226, 235, 248, 0.5)',
  DANGER: '#FE4B4B',
  DELIMITER_TOKEN: '#1EB169',
  ERROR: '#FB6767',
  GREY: '#737C86',
  HOVER: '#F5F9FA',
  INVALID_HARD: '#F1DAD7',
  INVALID_SOFT: '#FEF6F4',
  KEYWORD: '#FF8282',
  LIGHT_GREY: '#8D949C',
  LINE: '#E6ECEE',
  PARAMETER_HIGHLIGHT: 'rgba(45, 208, 130, 0.2)',
  PLACEHOLDER: '#C9CFD6',
  PLACEHOLDER_ON_DARK: '#505465',
  PRIMARY: '#30409A',
  RED: '#FD4E4F',
  SECONDARY: '#2DD082',
  SELECTED: '#3B7EE3',
  LIGHT_SELECTED: 'rgba(59, 126, 227, 0.5)',
  SHADOW_GERY: '#FAFAFA',
  SUCCESS: '#2DD082',
  TEXT: '#333',
  WHITE: '#FFF',
  DANGER_SETTINGS: '#B71C1C',
  TOOLTIP_BACKGROUND: '#1c1f35',
  LIGHT_BLUE_BACKGROUND: '#F8FBFF',
  FOCUS: '#00AEFF',
  WARNING: '#FFE071',
  WARNING_HARD: '#FFCA0E',
  BLUE: '#057ACF',
  LIGHT_BLUE: 'rgba(59, 126, 227, 0.1)',
  ACTIVE_BLUE: '#3B70E3',
  LIGHT_GREEN: '#47DEAE',
  HORIZONTAL_RULE: '#E5E5E5',
  TRANSPARENTIZE_GREEN: 'rgba(45, 208, 130, 0.2)',
} as const;

export const slideDownEntrance = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fadeInEntrance = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const popInEntrance = keyframes`
  from {
    transform: scale(1.05);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const defaultShadow = css`
  box-shadow: 0 10px 15px ${transparentize(0.8, COLORS.BORDER)};
`;

export const inputFocus = css`
  border: 1px solid ${transparentize(0.2, COLORS.FOCUS)};
  box-shadow: 0 0 0 2px ${transparentize(0.6, COLORS.FOCUS)};
  z-index: 10;
`;

export const Menu = styled.ul`
  list-style: none;
  padding: 10px 1px;
  margin: 0;
  width: 200px;
  background: #ffffff;
  border: 1px solid ${COLORS.BORDER};
  border-radius: 3px;
`;

type MenuItemProps = { danger?: boolean };
export const MenuItem = styled.li<MenuItemProps>`
  display: flex;
  justify-content: flex-start;
  padding: 8px 20px;
  width: auto;
  height: 30px;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: ${(props: MenuItemProps) => (props.danger ? COLORS.DANGER : COLORS.BLACK)};
  &:hover {
    background-color: ${COLORS.HOVER};
    cursor: pointer;
  }
`;

export const MONACO_THEME_ID = 'paragonDefault';
export type Monaco = typeof import('monaco-editor');
export const MONACO_DEFAULT_CONFIG: editor.IStandaloneEditorConstructionOptions = {
  language: 'plaintext',
  theme: 'paragon',
  folding: false,
  lineDecorationsWidth: 0,
  lineNumbersMinChars: 0,
  fontFamily: 'Inter, sans-serif',
  fontSize: 11,
  fontWeight: '500',
  lineNumbers: 'off',
  renderLineHighlight: 'none',
  minimap: {
    enabled: false,
  },
  scrollbar: {
    vertical: 'hidden',
    horizontal: 'hidden',
    alwaysConsumeMouseWheel: false,
  },
  suggest: {
    showWords: false,
  },
  overviewRulerBorder: false,
  overviewRulerLanes: 0,
  renderWhitespace: 'none',
  contextmenu: false,
  revealHorizontalRightPadding: 0,
  autoClosingBrackets: 'always',
  links: false,
};

export const getMonacoDefaultCodeConfig: (
  language: string,
) => Partial<editor.IStandaloneEditorConstructionOptions> = (language: string) => ({
  language,
  theme: 'paragon-dark',
  fontFamily: 'Monaco, monospace',
  lineDecorationsWidth: 10,
  lineNumbers: 'on',
  contextmenu: true,
  revealHorizontalRightPadding: 30,
  scrollBeyondLastLine: false,
});
