import moment from 'moment';

import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';
/**
 * @param condition
 * @returns condition for filter Sap S4 Hana
 */

export default function conditionsToFilterSapS4HanaExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterSapS4HanaExpression(condition);
      })
      .join(condition.join === 'OR' ? ' or ' : ' and ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
      case Operator.NumberEquals:
        return `${innerCondition.variable} eq '${innerCondition.argument}'`;

      case Operator.NumberDoesNotEqual:
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable} ne '${innerCondition.argument}'`;

      case Operator.NumberLessThan:
        return `${innerCondition.variable} lt '${innerCondition.argument}'`;

      case Operator.NumberGreaterThan:
        return `${innerCondition.variable} gt '${innerCondition.argument}'`;

      case Operator.DateTimeAfter:
        return `${innerCondition.variable} gt datetime'${moment(innerCondition.argument).format(
          'YYYY-MM-DDTHH:MM:SS',
        )}'`;

      case Operator.DateTimeBefore:
        return `${innerCondition.variable} lt datetime'${moment(innerCondition.argument).format(
          'YYYY-MM-DDTHH:MM:SS',
        )}'`;

      case Operator.DateTimeEquals:
        return `${innerCondition.variable} eq datetime'${moment(innerCondition.argument).format(
          'YYYY-MM-DDTHH:MM:SS',
        )}'`;
      case Operator.StringStartsWith:
        return `startswith (${innerCondition.variable}, '${innerCondition.argument}')`;

      case Operator.StringEndsWith:
        return `endswith (${innerCondition.variable}, '${innerCondition.argument}')`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Sap S/4Hana`);
    }
  }
  return '';
}
