import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.DOCUSIGN;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'DOCUSIGN_TEST_CONNECTION',
  CREATE_AN_ENVELOPE = 'DOCUSIGN_CREATE_AN_ENVELOPE',
  GET_ENVELOPE_BY_ID = 'DOCUSIGN_GET_ENVELOPE_BY_ID',
  UPDATE_ENVELOPE = 'DOCUSIGN_UPDATE_ENVELOPE',
  SEND_AN_ENVELOPE = 'DOCUSIGN_SEND_AN_ENVELOPE',
  SEARCH_ENVELOPES = 'DOCUSIGN_SEARCH_ENVELOPES',
  ENVELOPE_CUSTOM_FIELDS = 'DOCUSIGN_ENVELOPE_CUSTOM_FIELD',
}

/**
 * Combination of OAuthIntent, ActionIntent and ConnectIntent
 */
type IntentMap =
  | OAuthIntent.REFRESH_CREDENTIAL
  | OAuthIntent.GET_AUTH_ESSENTIALS
  | OAuthIntent.EXCHANGE_AUTH_CREDENTIALS
  | Intent.TEST_CONNECTION
  | Intent.CREATE_AN_ENVELOPE
  | Intent.GET_ENVELOPE_BY_ID
  | Intent.UPDATE_ENVELOPE
  | Intent.SEND_AN_ENVELOPE
  | Intent.SEARCH_ENVELOPES
  | Intent.ENVELOPE_CUSTOM_FIELDS
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  ACCESS_TOKEN = 'DOCUSIGN_ACCESS_TOKEN',
  REFRESH_TOKEN = 'DOCUSIGN_REFRESH_TOKEN',
  BASE_URI = 'DOCUSIGN_BASE_URI',
  ACCOUNT_ID = 'DOCUSIGN_ACCOUNT_ID',
  IS_DEVELOPMENT_ACCOUNT = 'IS_DEVELOPMENT_ACCOUNT',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
  [CredentialKeys.BASE_URI]: string;
  [CredentialKeys.ACCOUNT_ID]: string;
  [CredentialKeys.IS_DEVELOPMENT_ACCOUNT]: boolean;
};
