import { ClassicTriggerSteps, ConnectTriggerSteps } from '@shared/actions/sdk';
import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { ActionConfig, ActionTriggerStep } from '@shared/types/sdk/actions';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedActionParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';
import ActionStepValidator from './action.validator';

/**
 * validates that an action trigger step is properly configured
 */
export default class ActionTriggerStepValidator extends StepValidator<
  ActionTriggerStep,
  ResolvedActionParameters
> {
  stepType: ActionTriggerStep['type'] = StepType.ACTION_TRIGGER;

  /**
   * validates that a step is valid
   *
   * @param {Step} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ActionTriggerStep}
   * @memberof StepValidator
   */
  validate(
    step: ActionTriggerStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona,
  ): ActionTriggerStep {
    if (!step.parameters.intent) {
      throw new Error('You must select a Trigger Event for this step.');
    }

    const actionStepValidator: ActionStepValidator = new ActionStepValidator();
    const resolvedParameters: ResolvedActionParameters = actionStepValidator.resolveVariables(
      step,
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
    );

    const isConnectProject = Boolean(context.integrationId);
    const triggerConfig: ActionConfig | undefined = isConnectProject
      ? ConnectTriggerSteps[resolvedParameters.actionType]
      : ClassicTriggerSteps[resolvedParameters.actionType];
    if (!triggerConfig) {
      throw new Error('Trigger config not found for action: ' + resolvedParameters.actionType);
    }

    actionStepValidator.validateActionParameters(
      step.parameters,
      resolvedParameters,
      triggerConfig,
      isConnectProject,
    );

    return step;
  }
}
