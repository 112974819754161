import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  amountInput,
  customFieldOptions,
  dateInput,
  employeeIdInput,
  employeeNoteInput,
  endDateInput,
  intentTypeInput,
  listFieldIdInput,
  managerNoteInput,
  noteInput,
  requestIdInput,
  startDateInput,
  statusInput,
  timeOffTypeIdInput,
  upsertEmployeeFields,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.BAMBOO_HR,
  name: 'BambooHR',
  description: "Connect to your users' BambooHR accounts",
  icon: iconSvg,
  provider: ProviderType.BAMBOO_HR,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_EMPLOYEE:
        case Intent.UPDATE_EMPLOYEE:
          return {
            inputs: upsertEmployeeFields(parameters.intent === Intent.CREATE_EMPLOYEE),
          };

        case Intent.GET_EMPLOYEE_BY_ID:
          return {
            inputs: [
              {
                ...employeeIdInput,
                subtitle: 'Specify the ID of the employee to get.',
                placeholder: '45',
              },
            ],
          };

        case Intent.CREATE_TIME_OFF_REQUEST:
          return {
            inputs: [
              {
                ...employeeIdInput,
                subtitle: 'Specify the ID of the employee associated with this time off request.',
                placeholder: '45',
              },
              {
                ...timeOffTypeIdInput,
                subtitle: 'Specify the associated time off type ID to create a time off request.',
              },
              startDateInput,
              endDateInput,
              statusInput,
              amountInput,
              employeeNoteInput,
              managerNoteInput,
            ],
          };

        case Intent.CHANGE_REQUEST_STATUS:
          return {
            inputs: [
              requestIdInput,
              statusInput,
              {
                ...managerNoteInput,
                subtitle:
                  'Specify a manager note to attach to the updated time off request in this free text space.',
              },
            ],
          };

        case Intent.ADJUST_TIME_OFF_BALANCE_FOR_EMPLOYEE:
          return {
            inputs: [
              {
                ...employeeIdInput,
                subtitle: 'Specify the ID of the employee associated with this adjustment.',
                placeholder: '45',
              },
              dateInput,
              timeOffTypeIdInput,
              {
                ...amountInput,
                subtitle:
                  'Specify the number of hours/days to adjust the balance by. The units of this amount will be determined by the time off type.',
                placeholder: '-6.0000',
                required: true,
              },
              noteInput,
            ],
          };

        case Intent.GET_TIME_OFF_REQUESTS_FOR_EMPLOYEE:
          return {
            inputs: [
              {
                ...employeeIdInput,
                subtitle: 'Specify the ID of the employee to search time off requests for.',
                placeholder: '4',
              },
              startDateInput,
              endDateInput,
            ],
          };

        case Intent.ADD_LIST_FIELD_VALUE:
          return { inputs: [listFieldIdInput, customFieldOptions] };

        case Intent.GET_EMPLOYEE_DIRECTORY:
        case Intent.GET_FIELD_LIST:
        case Intent.GET_TIME_OFF_TYPES:
        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
