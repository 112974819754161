import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@shared/ui/sdk/utils/constants';

import { State, useSelector } from '../store/types';

const ImpersonatingRoot = styled.div`
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ImpersonatingInner = styled.div.attrs({ 'data-testid': 'impersonate-text' })`
  padding: 10px;
  background: ${COLORS.DANGER};
  color: white;
  border-radius: 5px;
  font-family: monaco;
`;

const Impersonating = () => {
  const auth = useSelector((state: State) => state.auth);

  return (
    <ImpersonatingRoot>
      <ImpersonatingInner>You are impersonating {auth.user?.email} right now!</ImpersonatingInner>
    </ImpersonatingRoot>
  );
};

export default Impersonating;
