// tslint:disable: import-name
import React from 'react';

import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

type ScopeName =
  | 'ads_management'
  | 'ads_read'
  | 'business_management'
  | 'email'
  | 'public_profile'
  | 'read_insights'
  | 'user_messenger_contact'
  | 'attribution_read'
  | 'catalog_management'
  | 'gaming_user_locale'
  | 'groups_access_member_info'
  | 'instagram_basic'
  | 'instagram_content_publish'
  | 'instagram_manage_comments'
  | 'instagram_manage_insights'
  | 'leads_retrieval'
  | 'pages_events'
  | 'pages_manage_ads'
  | 'pages_manage_cta'
  | 'pages_manage_instant_articles'
  | 'pages_manage_engagement'
  | 'pages_manage_metadata'
  | 'pages_manage_posts'
  | 'pages_messaging'
  | 'pages_read_engagement'
  | 'pages_read_user_content'
  | 'pages_show_list'
  | 'pages_user_gender'
  | 'pages_user_locale'
  | 'pages_user_timezone'
  | 'publish_to_groups'
  | 'publish_video'
  | 'research_apis'
  | 'user_age_range'
  | 'user_birthday'
  | 'user_friends'
  | 'user_gender'
  | 'user_hometown'
  | 'user_likes'
  | 'user_link'
  | 'user_location'
  | 'user_messenger_contact'
  | 'user_photos'
  | 'user_posts'
  | 'user_videos';

export const requiredScopes: ScopeName[] = [
  'email',
  'ads_management',
  'business_management',
  'public_profile',
  'pages_show_list',
  'pages_manage_ads', // https://developers.facebook.com/docs/marketing-api/guides/lead-ads/create#lead_form
];

export const scopes: Record<ScopeName, ScopeValue> = {
  ads_management: {
    name: 'ads_management',
    description:
      'The ads_management permission allows your app to both read and manage the Ads account it owns, or has been granted access to, by the Ad account owner.',
  },
  ads_read: {
    name: 'ads_read',
    description:
      'The ads_read permission allows your app to access the Ads Insights API to pull Ads report information for Ad accounts you own or have been granted access to by the owner or owners of other ad accounts through this permission. ',
  },
  business_management: {
    name: 'business_management',
    description:
      'The business_management permission allows your app to read and write with the Business Manager API.',
  },
  email: {
    name: 'email',
    description: "The email permission allows your app to read a person's primary email address.",
  },
  public_profile: {
    name: 'public_profile',
    description:
      'Allows apps to read the Default Public Profile Fields on the User node. This permission is automatically granted to all apps.',
  },
  read_insights: {
    name: 'read_insights',
    description:
      'The read_insights permission allows your app to read the Insights data for Pages, apps and web domains the person owns.',
  },
  user_messenger_contact: {
    name: 'user_messenger_contact',
    description:
      "The user_messenger_contact permission allows a business to contact a person via Messenger upon their approval or initiation of a chat thread with the business's Page.",
  },
  attribution_read: {
    name: 'attribution_read',
    description:
      'The attribution_read permission grants your app access to the Attribution API to pull attribution report data for lines of business you own or have been granted access to by the owner or owners of other lines of business.',
  },
  catalog_management: {
    name: 'catalog_management',
    description:
      'The catalog_management permission allows your app to create, read, update and delete business-owned product catalogs that the user is an admin of.',
  },
  gaming_user_locale: {
    name: 'gaming_user_locale',
    description:
      "The gaming_user_locale permission allows your app to get a user's preferred language while the user plays a game on Facebook ",
  },
  groups_access_member_info: {
    name: 'groups_access_member_info',
    description:
      'The groups_access_member_info permission allows your app to read publicly available group member information like name and ID if the post author has granted your app access.',
  },
  instagram_basic: {
    name: 'instagram_basic',
    description:
      "The instagram_basic permission allows your app to read an Instagram account profile's info and media.",
  },
  instagram_content_publish: {
    name: 'instagram_content_publish',
    description:
      'The instagram_content_publish permission allows your app to create organic feed photo and video posts on behalf of a business user.',
  },
  instagram_manage_comments: {
    name: 'instagram_manage_comments',
    description:
      'The instagram_manage_comments permission allows your app to create, delete and hide comments on behalf of the Instagram account linked to a Page. ',
  },
  instagram_manage_insights: {
    name: 'instagram_manage_insights',
    description:
      'The instagram_manage_insights permission allows your app to get access to insights for the Instagram account linked to a Facebook Page.',
  },
  leads_retrieval: {
    name: 'leads_retrieval',
    description:
      'The leads_retrieval permission allows your app to retrieve and read all information captured by a lead ads form associated with an ad created in Ads Manager or the Marketing API.',
  },
  pages_events: {
    name: 'pages_events',
    description:
      'The page_events permissions allows your app permission to log events on behalf of Facebook Pages administered by people using your app and to send those events to Facebook for ads targeting, optimization and reporting.',
  },
  pages_manage_ads: {
    name: 'pages_manage_ads',
    description:
      'The pages_manage_ads permission allows your app to manage ads associated with the Page.',
  },
  pages_manage_cta: {
    name: 'pages_manage_cta',
    description:
      'The pages_manage_cta permission allows your app to carry out POST and DELETE functions on endpoints used to manage call-to-action buttons on a Facebook Page.',
  },
  pages_manage_instant_articles: {
    name: 'pages_manage_instant_articles',
    description:
      'The pages_manage_instant_articles permission allows your app to manage Instant Articles on behalf of Facebook Pages administered by people using your app.',
  },
  pages_manage_engagement: {
    name: 'pages_manage_engagement',
    description:
      'The pages_manage_engagement permission allows your app to create, edit and delete comments posted on the Page.',
  },
  pages_manage_metadata: {
    name: 'pages_manage_metadata',
    description:
      'The pages_manage_metadata permission allows your app to subscribe and receive webhooks about activity on the Page, and to update settings on the Page.',
  },
  pages_manage_posts: {
    name: 'pages_manage_posts',
    description:
      'The pages_manage_posts permission allows your app to create, edit and delete your Page posts.',
  },
  pages_messaging: {
    name: 'pages_messaging',
    description:
      'The pages_messaging permission allows your app to manage and access Page conversations in Messenger.',
  },
  pages_read_engagement: {
    name: 'pages_read_engagement',
    description:
      'The pages_read_engagement permission allows your app to read content (posts, photos, videos, events) posted by the Page, read followers data (including name, PSID), and profile picture, and read metadata and other insights about the Page.',
  },
  pages_read_user_content: {
    name: 'pages_read_user_content',
    description:
      'The pages_read_user_content permission allows your app to read user generated content on the Page, such as posts, comments, and ratings by users or other Pages, and to delete user comments on Page posts.',
  },
  pages_show_list: {
    name: 'pages_show_list',
    description:
      'The pages_show_list permission allows your app to access the list of Pages a person manages.',
  },
  pages_user_gender: {
    name: 'pages_user_gender',
    description:
      "The pages_user_gender permission allows your app to access a user's gender through the Page your app is connected to.",
  },
  pages_user_locale: {
    name: 'pages_user_locale',
    description:
      "The pages_user_locale permission allows your to app to a user's locale through the Page your app is connected to.",
  },
  pages_user_timezone: {
    name: 'pages_user_timezone',
    description:
      "The pages_user_timezone permission grants your app access to a user's time zone through the Page your app is connected to.",
  },
  publish_to_groups: {
    name: 'publish_to_groups',
    description:
      "The publish_to_groups permission allows your app to post content into a Group on behalf of a person if they've granted your app access.",
  },
  publish_video: {
    name: 'publish_video',
    description:
      "The publish_video permission allows your app to publish live videos to an app user's timeline, group, event or Page.",
  },
  research_apis: {
    name: 'research_apis',
    description:
      'The research_apis permission allows your app to access data on public Facebook Pages, Groups, and Events within the Facebook Open Research and Transparency Tool.',
  },
  user_age_range: {
    name: 'user_age_range',
    description:
      "The user_age_range permission allows your app to access a person's age range as listed in their Facebook profile.",
  },
  user_birthday: {
    name: 'user_birthday',
    description:
      "The user_birthday permission allows your app to read a person's birthday as listed in their Facebook profile.",
  },
  user_friends: {
    name: 'user_friends',
    description:
      "The user_friends permission allows your app to get a list of a person's friends using that app.",
  },
  user_gender: {
    name: 'user_gender',
    description:
      "The user_gender permission allows your app to read a person's gender as listed in their Facebook profile.",
  },
  user_hometown: {
    name: 'user_hometown',
    description:
      "The user_hometown permission allows your app to read a person's hometown location from their Facebook profile.",
  },
  user_likes: {
    name: 'user_likes',
    description:
      'The user_likes permission allows your app to read a list of all Facebook Pages that a user has liked.',
  },
  user_link: {
    name: 'user_link',
    description:
      'The user_link permission allows your app to access the Facebook profile URL of the person using your app.',
  },
  user_location: {
    name: 'user_location',
    description:
      "The user_location permission allows your app to read the city name as listed in the location field of a person's Facebook profile.",
  },
  user_photos: {
    name: 'user_photos',
    description:
      'The user_photos permission allows your app to read the photos a person has uploaded to Facebook.',
  },
  user_posts: {
    name: 'user_posts',
    description:
      'The user_posts permission allows your app to access the posts that a user has made on their timeline.',
  },
  user_videos: {
    name: 'user_videos',
    description:
      'The user_videos permission allows your app to read a list of videos uploaded by a person.',
  },
};

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Facebook Ads',
  description: 'Connect to your users’ Facebook ads accounts',
  authUrl: 'https://www.facebook.com/v12.0/dialog/oauth',
  accessTokenUrl: 'https://graph.facebook.com/v12.0/oauth/access_token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/facebook-ads`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://developers.facebook.com/docs/marketing-apis/overview',
  endUserSuppliedValues: [
    {
      type: SidebarInputType.ValueText,
      id: 'FACEBOOK_ADS_ACCOUNT_ID',
      title: 'Enter your Facebook Ads Account ID',
      subtitle: (
        <>
          You can copy your 16-digit ad account ID by visiting{' '}
          <a
            href="https://business.facebook.com/settings/ad-accounts"
            target="_blank"
            rel="noreferrer"
          >
            Facebook Business Settings, under Ad Accounts.
          </a>
        </>
      ),
      placeholder: '1011111111111111',
      required: true,
    },
  ],
};
