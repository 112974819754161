import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SLACK;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  SEND_MESSAGE = 'SLACK_SEND_MESSAGE',
  TEST_CONNECTION = 'SLACK_TEST_CONNECTION',
  LIST_CHANNELS = 'SLACK_LIST_CHANNELS',
  LIST_MEMBERS = 'SLACK_LIST_MEMBERS',
  SEND_DIRECT_MESSAGE = 'SLACK_SEND_DIRECT_MESSAGE',
  GET_USER_BY_EMAIL = 'SLACK_GET_USER_BY_EMAIL',
}

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent. SendEmailParameters should be
// defined in this file.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_MESSAGE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_CHANNELS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.LIST_MEMBERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_DIRECT_MESSAGE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_USER_BY_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

type BaseCredential = {
  /**
   * The Slack member ID of the authenticated user.
   * Optional, because this will only be defined for credentials created after PARA-2645.
   */
  userId?: string;

  /**
   * Optional, because this only applies for Slack OAuth-enabled apps
   */
  SLACK_SCOPES?: string;

  // if true then use bot token
  useBotToken?: boolean;
};

export type Credentials = BaseCredential &
  (
    | {
        SLACK_ACCESS_TOKEN: string;
        SLACK_BOT_TOKEN?: string;
        useBotToken?: false;
      }
    | {
        SLACK_BOT_TOKEN: string;
        SLACK_ACCESS_TOKEN?: string;
        useBotToken: true;
      }
  );
