import { CompletedExecution } from '@shared/types/sdk/resolvers';
import { Step, Workflow } from '@shared/types/sdk/steps';
import { RenameVariablePayload, RenameVariableResolvers } from '@shared/workflow/sdk/resolvers';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { Dispatch, State } from '../../types';

import { getWorkflowFromEntity } from './step';

export const getStepVariables =
  (projectId: string, workflowId: string, stepId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: 'GET_VARIABLES_START', payload: { stepId } });

    const response = await api.get(
      `/projects/${projectId}/workflows/${workflowId}/steps/${stepId}/variables`,
    );

    if (response.ok) {
      const executions: CompletedExecution[] = await response.json();

      dispatch({
        type: 'GET_VARIABLES_COMPLETE',
        payload: {
          stepId,
          variables: executions,
        },
      });
    } else {
      dispatch({
        type: 'GET_VARIABLES_ERROR',
        payload: { message: await getErrorMessage(response) },
      });
    }
  };

export const renameVariables =
  (workflowId: string, payload: RenameVariablePayload) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state: State = getState();

    function updateStep(step: Step): void {
      dispatch({
        type: 'UPDATE_STEP',
        step: { ...step },
      });
    }

    const workflow: Workflow = getWorkflowFromEntity(state, workflowId);

    for (const step of workflow.steps) {
      updateStep(RenameVariableResolvers[step.type](step, payload));
    }
  };

export const getAllStepVariables =
  (projectId: string, workflowId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: 'GET_ALL_VARIABLES_START' });

    try {
      const response = await api.get(
        `/projects/${projectId}/workflows/${workflowId}/steps/variables`,
      );

      if (response.ok) {
        const executions: CompletedExecution[] = await response.json();

        dispatch({
          type: 'GET_ALL_VARIABLES_COMPLETE',
          payload: {
            variables: executions,
          },
        });
      } else {
        dispatch({
          type: 'GET_ALL_VARIABLES_ERROR',
          payload: { message: await getErrorMessage(response) },
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_ALL_VARIABLES_ERROR',
        payload: { message: error.message },
      });
    }
  };
