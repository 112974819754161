import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';
import { DataType, ValueSource } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import { projectForSectionCacheKey, sectionsCacheKey } from '../shared/constants';
import { DataProperty } from '../shared/types';

export const getWorkSpaces: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'workspaces',
  title: 'Workspace',
  subtitle: 'Workspace that Tasks or Projects should be created in',
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_WORKSPACES,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<Record<string, any>>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((item: { name: string; gid: string }) => ({
        label: item.name,
        value: item.gid,
      }))
    );
  },
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
};

export const getProjects: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  cacheKey: 'projects',
  title: 'Project',
  subtitle: 'Project that Tasks should be created in',
  refreshDependencies: [
    (parameters: ActionStepParameters) => parameters.credentials[0],
    (parameters: ActionStepParameters) => parameters.intent,
    'workspace',
  ],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const workspace =
      pickValueSourceByKey(options.actionParameters, 'workspaces') ??
      pickValueSourceByKey(options.actionParameters, 'workspace');
    return {
      intent: Intent.GET_PROJECTS,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'workspace',
          source: {
            type: 'VALUE',
            value: workspace?.value,
          },
        },
        {
          key: 'allowEmptyWorkspace',
          source: {
            type: 'VALUE',
            value: false,
          },
        },
      ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<Record<string, any>>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((item: { name: string; gid: string }) => ({
        label: item.name,
        value: item.gid,
      }))
    );
  },
};

export const getWorkspaceProject: DynamicDataSource = {
  ...getProjects,
  cacheKey: 'workspaceProjects',
};

export const mapWorkspaceProjectsFields: ComboInputDataSource = {
  id: 'workspaceProjectMapping',
  type: DataSourceType.COMBO_INPUT,
  title: 'Workspace and Project to create Tasks in',
  subtitle: 'Allows users to define a workspace project mapping',
  mainInputSource: getWorkSpaces,
  dependentInputSource: getWorkspaceProject,
};

export const getProjectsForConnectSettings = {
  ...getProjects,
  subtitle: 'Deprecated. Use workspaceProjectMapping instead',
  hideFromConnectFieldTypes: true,
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_PROJECTS,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'allowEmptyWorkspace',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    };
  },
};

export const getAssignees: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'assignees',
  hideFromConnectFieldTypes: true,
  title: 'Assignee',
  subtitle: 'An Asana user to assign Tasks to',
  refreshDependencies: [
    (parameters: ActionStepParameters) => parameters.credentials[0],
    (parameters: ActionStepParameters) => parameters.intent,
    'workspace',
  ],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const workspace =
      pickValueSourceByKey(options.actionParameters, 'workspaces') ??
      pickValueSourceByKey(options.actionParameters, 'workspace');
    return {
      intent: Intent.GET_ASSIGNEE,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'workspace',
          source: {
            type: 'VALUE',
            value: workspace?.value,
          },
        },
      ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<Record<string, any>>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((item: { name: string; gid: string }) => ({
        label: item.name,
        value: item.gid,
      }))
    );
  },
};

export const getTeams: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'teams',
  title: 'Team',
  subtitle: 'Team that Projects should be shared with',
  refreshDependencies: [
    (parameters: ActionStepParameters) => parameters.credentials[0],
    (parameters: ActionStepParameters) => parameters.intent,
    'workspace',
  ],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_TEAMS,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<Record<string, any>>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((item: { name: string; gid: string }) => ({
        label: item.name,
        value: item.gid,
      }))
    );
  },
};

export const getTasks: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  cacheKey: 'tasks',
  title: 'Task',
  refreshDependencies: [
    (parameters: ActionStepParameters) => parameters.credentials[0],
    (parameters: ActionStepParameters) => parameters.intent,
    'task',
  ],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const project = pickValueSourceByKey(options.actionParameters, 'project');
    return {
      intent: Intent.GET_TASKS,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'project',
          source: {
            type: 'VALUE',
            value: project?.value,
          },
        },
      ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<Record<string, any>>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((item: { name: string; gid: string }) => ({
        label: item.name,
        value: item.gid,
      }))
    );
  },
};

export const getTags: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'tags',
  title: 'Tag',
  refreshDependencies: [],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_TAGS,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<Record<string, any>>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((item: { name: string; gid: string }) => ({
        label: item.name,
        value: item.gid,
      }))
    );
  },
};

/**
 * For getting the projects for section.
 */
export const getProjectForSections: DynamicDataSource = {
  ...getProjects,
  cacheKey: projectForSectionCacheKey,
  refreshDependencies: [],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_PROJECTS,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

/**
 * For getting the sections based on project
 */
export const getSections: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  cacheKey: sectionsCacheKey,
  title: 'Sections',
  refreshDependencies: [projectForSectionCacheKey, 'project'],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const project: ValueSource<DataType.ANY> | undefined =
      pickValueSourceByKey(options.actionParameters, projectForSectionCacheKey) ||
      pickValueSourceByKey(options.actionParameters, 'project');

    return {
      intent: Intent.GET_SECTIONS,
      actionType: Action.ASANA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'projectId',
          source: {
            type: 'VALUE',
            value: project?.value,
          },
        },
      ],
    };
  },
  mapRefreshToValues: (response: ActionResponse<Record<string, any>>): EnumInputValue[] => {
    return response?.result.output.map((section: DataProperty) => ({
      label: section.name,
      value: section.gid,
    }));
  },
};

export const projectToSectionsInput: ComboInputDataSource = {
  id: 'projectToSectionsCombo',
  type: DataSourceType.COMBO_INPUT,
  title: 'Project Section',
  subtitle: 'A section of a project that tasks can be added to',
  mainInputSource: getProjectForSections,
  dependentInputSource: getSections,
};

export const workspaceToAssigneesInput: ComboInputDataSource = {
  id: 'workspaceToAssigneeCombo',
  type: DataSourceType.COMBO_INPUT,
  title: 'Workspace Assignee',
  subtitle: 'Assignees belonging to a specific workspace',
  mainInputSource: getWorkSpaces,
  dependentInputSource: getAssignees,
};
