import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { FilterFormula } from '../shared/types';
import { buildUnixDateTimeStamp } from '../shared/utils';

/**
 * parse filter condtion and return filterFormula of type FilterFormula from condition obj (filter object)
 * @param condition
 */
export default function conditionsToFilterFormula(
  condition: ResolvedConditionWrapper,
): FilterFormula {
  if (condition.type === 'JOIN') {
    const result = condition.conditions.map((condition: ResolvedConditionWrapper) =>
      conditionsToFilterFormula(condition),
    );
    return result.length == 1
      ? result[0]
      : {
          operator: condition.join == 'OR' ? 'OR' : 'AND',
          value: result,
        };
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringContains:
        return {
          field: innerCondition.variable,
          operator: '~',
          value: innerCondition.argument,
        };
      case Operator.StringDoesNotContain:
        return {
          field: innerCondition.variable,
          operator: '!~',
          value: innerCondition.argument,
        };
      case Operator.StringExactlyMatches:
        return {
          field: innerCondition.variable,
          operator: '=',
          value: innerCondition.argument,
        };
      case Operator.StringDoesNotExactlyMatch:
        return {
          field: innerCondition.variable,
          operator: '!=',
          value: innerCondition.argument,
        };
      case Operator.StringStartsWith:
        return {
          field: innerCondition.variable,
          operator: '^',
          value: innerCondition.argument,
        };
      case Operator.StringEndsWith:
        return {
          field: innerCondition.variable,
          operator: '$',
          value: innerCondition.argument,
        };
      case Operator.NumberLessThan:
        return {
          field: innerCondition.variable,
          operator: '<',
          value: innerCondition.argument,
        };
      case Operator.NumberEquals:
        return {
          field: innerCondition.variable,
          operator: '=',
          value: innerCondition.argument,
        };
      case Operator.NumberGreaterThan:
        return {
          field: innerCondition.variable,
          operator: '>',
          value: innerCondition.argument,
        };
      case Operator.NumberDoesNotEqual:
        return {
          field: innerCondition.variable,
          operator: '!=',
          value: innerCondition.argument,
        };
      case Operator.NumberGreaterThanOrEqualTo:
        return {
          field: innerCondition.variable,
          operator: '>=',
          value: innerCondition.argument,
        };
      case Operator.NumberLessThanOrEqualTo:
        return {
          field: innerCondition.variable,
          operator: '<=',
          value: innerCondition.argument,
        };
      case Operator.DateTimeEquals:
        return {
          field: innerCondition.variable,
          operator: '=',
          value: buildUnixDateTimeStamp(innerCondition.argument),
        };
      case Operator.DateTimeBefore:
        return {
          field: innerCondition.variable,
          operator: '<',
          value: buildUnixDateTimeStamp(innerCondition.argument),
        };
      case Operator.DateTimeAfter:
        return {
          field: innerCondition.variable,
          operator: '>',
          value: buildUnixDateTimeStamp(innerCondition.argument),
        };
      case Operator.ArrayIsIn:
        return {
          field: innerCondition.variable,
          operator: 'IN',
          value: innerCondition.argument,
        };
      default:
        throw new Error(`${innerCondition.operator} not supported by Intercom`);
    }
  }
  return undefined;
}
