import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { EngagementType, RecordType, TaskEngagementStatus } from './types';

export type HubspotStandardRecords = RecordType.COMPANY | RecordType.CONTACTS | RecordType.DEALS;

const descriptionInput: SidebarInput = {
  id: 'field-description',
  title: 'Description',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
};
const lifecycleStageInput: SidebarInput = {
  id: 'field-lifecyclestage',
  title: 'Lifecycle Stage',
  subtitle:
    'Defaults to the user’s first Lifecycle Stage if not provided. Use Connect Portal Workflow Settings to allow users to select a Lifecycle Stage.',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: '{{settings.lifecycleStage}}',
  required: false,
};
const leadStatusInput: SidebarInput = {
  id: 'field-hs_lead_status',
  title: 'Lead Status',
  subtitle:
    'Defaults to the user’s first Lead Status if not provided. Use Connect Portal Workflow Settings to allow users to select a Lead Status.',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: '{{settings.leadStatus}}',
  required: false,
};

export const defaultHubspotFields: Record<HubspotStandardRecords, SidebarInput[]> = {
  [RecordType.COMPANY]: [
    {
      id: 'field-name',
      title: 'Name',
      type: SidebarInputType.TextArea,
      lines: 1,
    },
    {
      id: 'field-domain',
      title: 'Company Domain Name',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-hubspot_owner_id',
      title: 'Company Owner',
      subtitle:
        'The HubSpot user who is assigned to this Company. Use Connect Portal Workflow Settings to allow users to select an Owner.',
      type: SidebarInputType.TextArea,
      lines: 1,
      placeholder: '{{settings.owner}}',
      required: false,
    },
    lifecycleStageInput,
    leadStatusInput,
    {
      id: 'field-phone',
      title: 'Phone Number',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-numberofemployees',
      title: 'Number of Employees',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-annualrevenue',
      title: 'Annual Revenue',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    descriptionInput,
  ],
  [RecordType.CONTACTS]: [
    {
      id: 'field-email',
      title: 'Email',
      type: SidebarInputType.TextArea,
      lines: 1,
    },
    {
      id: 'field-firstname',
      title: 'First Name',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-lastname',
      title: 'Last Name',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-jobtitle',
      title: 'Job Title',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-company',
      title: 'Company Name',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-hubspot_owner_id',
      title: 'Contact Owner',
      subtitle:
        'The HubSpot user who is assigned to this contact. Use Connect Portal Workflow Settings to allow users to select an Owner.',
      type: SidebarInputType.TextArea,
      lines: 1,
      placeholder: '{{settings.owner}}',
      required: false,
    },
    lifecycleStageInput,
    leadStatusInput,
  ],
  [RecordType.DEALS]: [
    {
      id: 'field-dealname',
      title: 'Name',
      type: SidebarInputType.TextArea,
      lines: 1,
    },
    {
      id: 'field-amount',
      title: 'Deal Amount',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-hubspot_owner_id',
      title: 'Deal Owner',
      subtitle:
        'The HubSpot user who is assigned to this Deal. Use Connect Portal Workflow Settings to allow users to select an Owner.',
      type: SidebarInputType.TextArea,
      lines: 1,
      placeholder: '{{settings.owner}}',
      required: false,
    },
    {
      id: 'field-dealstage',
      title: 'Deal Stage',
      subtitle:
        'Defaults to the user’s first Deal Stage if not provided. Use Connect Portal Workflow Settings to allow users to select a Deal Stage.',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    descriptionInput,
    {
      id: 'field-closedate',
      title: 'Close Date',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
    {
      id: 'field-hs_next_step',
      title: 'Next Step',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
    },
  ],
};

// @ts-ignore
export const defaultHubspotFieldNames = Object.fromEntries(
  Object.entries(defaultHubspotFields).map(([recordType, inputs]: [string, SidebarInput[]]) => {
    return [recordType, inputs.map((input: SidebarInput) => input.id.slice('field-'.length))];
  }),
) as Record<HubspotStandardRecords, string[]>;

export const engagementTypeInput: SidebarInput = {
  id: 'engagementType',
  title: 'Type',
  required: true,
  type: SidebarInputType.Enum,
  defaultValue: EngagementType.NOTE,
  getValues: () => [
    {
      value: EngagementType.NOTE,
      label: 'Note',
    },
    {
      value: EngagementType.EMAIL,
      label: 'Email',
    },
    {
      value: EngagementType.CALL,
      label: 'Call',
    },
    {
      value: EngagementType.MEETING,
      label: 'Meeting',
    },
    {
      value: EngagementType.TASK,
      label: 'Task',
    },
  ],
};

export const contactIdInput: SidebarInput = {
  id: 'contactId',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Contact ID',
  subtitle: 'ID of the Contact the Engagement is related to.',
};

export const companyIdInput: SidebarInput = {
  id: 'companyId',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Company ID',
  subtitle: 'ID of the Company the Engagement is related to.',
};

export const engagementTypeMetadataInputs: Record<EngagementType, SidebarInput[]> = {
  [EngagementType.NOTE]: [
    {
      id: 'noteBody',
      type: SidebarInputType.TextArea,
      lines: 3,
      required: false,
      title: 'Note',
      subtitle: 'The body of the note.',
    },
  ],
  [EngagementType.EMAIL]: [
    {
      id: 'fromEmail',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'From email',
      subtitle: 'Email address of the sender.',
    },
    {
      id: 'fromFirstName',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'From first name',
      subtitle: 'First name of the sender.',
    },
    {
      id: 'fromLastName',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'From last name',
      subtitle: 'Last name of the sender.',
    },
    {
      id: 'toEmail',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'To email',
      subtitle: 'Email address of the recipient.',
    },
    {
      id: 'ccEmail',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'CC emails',
      subtitle: 'Array of email addresses for anyone cc’d on the email.',
    },
    {
      id: 'bccEmail',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'BCC emails',
      subtitle: 'Array of email addresses for anyone bcc’d on the email.',
    },
    {
      id: 'emailSubject',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'Subject',
      subtitle: 'Subject of the email.',
    },
    {
      id: 'emailText',
      type: SidebarInputType.TextArea,
      lines: 3,
      required: false,
      title: 'Text',
      subtitle: 'Body of the text-only email.',
    },
    {
      id: 'emailHtml',
      type: SidebarInputType.TextArea,
      lines: 3,
      required: false,
      title: 'HTML',
      subtitle: 'Body of the HTML email.',
    },
  ],
  [EngagementType.CALL]: [
    {
      id: 'toNumber',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'To number',
      subtitle: 'The phone number that was called.',
    },
    {
      id: 'fromNumber',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'From number',
      subtitle: 'The phone number that was used as the from number.',
    },
    {
      id: 'callNotes',
      type: SidebarInputType.TextArea,
      lines: 3,
      required: false,
      title: 'Body',
      subtitle: 'Details or notes of the call.',
    },
    {
      id: 'recordingUrl',
      type: SidebarInputType.TextArea,
      lines: 3,
      required: false,
      title: 'Recording URL',
      subtitle: 'The URL of the call recording.',
    },
  ],
  [EngagementType.MEETING]: [
    {
      id: 'meetingTitle',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'Title',
      subtitle: 'Title or subject of the meeting.',
    },
    {
      id: 'meetingNotes',
      type: SidebarInputType.TextArea,
      lines: 3,
      required: false,
      title: 'Body',
      subtitle: 'Details or notes of the meeting.',
    },
    {
      id: 'startTime',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'Start time',
      subtitle: 'Start time of the meeting (ISO or Unix timestamp).',
      placeholder: '2014-04-25T16:15:47-04:00',
    },
    {
      id: 'endTime',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'End time',
      subtitle: 'End time of the meeting (ISO or Unix timestamp).',
      placeholder: '2014-04-25T16:15:47-04:00',
    },
  ],
  [EngagementType.TASK]: [
    {
      id: 'taskSubject',
      type: SidebarInputType.TextArea,
      lines: 1,
      required: false,
      title: 'Subject',
      subtitle: 'Title or subject of the task.',
    },
    {
      id: 'taskNotes',
      type: SidebarInputType.TextArea,
      lines: 3,
      required: false,
      title: 'Body',
      subtitle: 'Details or notes of the task.',
    },
    {
      id: 'taskStatus',
      type: SidebarInputType.Enum,
      required: false,
      title: 'Status',
      defaultValue: TaskEngagementStatus.NOT_STARTED,
      getValues: () => [
        {
          label: 'Not started',
          value: TaskEngagementStatus.NOT_STARTED,
        },
        {
          label: 'Completed',
          value: TaskEngagementStatus.COMPLETED,
        },
        {
          label: 'In Progress',
          value: TaskEngagementStatus.IN_PROGRESS,
        },
        {
          label: 'Waiting',
          value: TaskEngagementStatus.WAITING,
        },
        {
          label: 'Deferred',
          value: TaskEngagementStatus.DEFERRED,
        },
      ],
    },
  ],
};

export const engagementFilterInputConnect: SidebarInput = {
  type: SidebarInputType.Conditional,
  id: 'engagementFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  required: false,
  getValues: () => [
    {
      value: 'contactIds',
      label: 'ContactId',
    },
    {
      value: 'companyIds',
      label: 'CompanyId',
    },
    {
      value: 'dealIds',
      label: 'DealId',
    },
    {
      value: 'ownerIds',
      label: 'OwnerId',
    },
    {
      value: 'ticketIds',
      label: 'TicketId',
    },
  ],
  supportedOperators: [Operator.StringExactlyMatches],
};

export const ownerIdInput: SidebarInput = {
  id: 'ownerId',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Owner ID',
  subtitle: 'ID of the owner of the Engagement.',
};

export const customObjectNameInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  id: 'objectName',
  title: 'Custom Object Name',
  subtitle:
    'The Name of the Custom Object to create. Use Connect Portal Workflow Settings to allow users to select a Custom Object Mapping.',
  placeholder: '{{settings.objectType}}',
  required: true,
  lines: 1,
};

export const customObjectPropertyInput: SidebarInput = {
  id: 'customObjectFields',
  type: SidebarInputType.Code,
  lines: 5,
  required: true,
  title: 'Record Fields',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select a Custom Object Mapping to specify which fields to update.',
  language: 'json',
  useLightTheme: true,
  placeholder: `{
  "property_number": "17",
  "property_dropdown": "choice_b",
  "property_radio": "option_1",
  "property_string": "value",
  "property_date": "1572480000000"
}`,
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFieldsJSON',
  type: SidebarInputType.TextArea,
  lines: 4,
  required: false,
  title: 'Additional Fields',
  placeholder: `{
  "property_number": "17",
  "property_dropdown": "choice_b",
  "property_radio": "option_1",
  "property_string": "value",
  "property_date": "1572480000000"
}`,
  subtitle:
    'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which Contact fields to update.',
};
