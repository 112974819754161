import { Operator } from '@shared/types/sdk/resolvers';

export const GMAIL_API_BASE_URL: string = 'https://gmail.googleapis.com';

export const supportedOperators: Operator[] = [
  Operator.StringContains,
  Operator.StringIsIn,
  Operator.StringDoesNotContain,
  Operator.StringExactlyMatches,
  Operator.DateTimeAfter,
  Operator.DateTimeBefore,
  Operator.BooleanFalse,
  Operator.BooleanTrue,
];

export const READMASK_FIELDS: string =
  'addresses,ageRanges,biographies,birthdays,calendarUrls,clientData,coverPhotos,emailAddresses,events,externalIds,genders,imClients,interests,locales,locations,memberships,metadata,miscKeywords,names,nicknames,occupations,organizations,phoneNumbers,photos,relations,sipAddresses,skills,urls,userDefined';
