import {
  filterEmptyProperties,
  getArrayOfStringsFromCommaSeparatedList,
  parseCodeInput,
} from '@shared/actions/sdk/utils';

import {
  ActivitiesCreateRecordDTO,
  BuildRecordPayloadType,
  CreateRecordDTO,
  CreateRecordTypeDTO,
  DealsCreateRecordDTO,
  LeadsCreateRecordDTO,
  NotesCreateRecordDTO,
  OrganizationsCreateRecordDTO,
  PersonsCreateRecordDTO,
  RecordType,
  UsersCreateRecordDTO,
  formatDateTime,
} from '.';

/**
 *
 * @param dto  { CreateRecordDTO & CreateRecordTypeDTO}
 * @param isUpdateRecord boolean
 * @returns {BuildRecordPayloadType}
 */
export const buildRecordPayload = (
  dto: CreateRecordDTO & CreateRecordTypeDTO,
  isUpdateRecord: boolean = false,
): BuildRecordPayloadType => {
  let payload: BuildRecordPayloadType;

  switch (dto.recordType) {
    case RecordType.ACTIVITIES:
      const activitiesDto: ActivitiesCreateRecordDTO = dto as ActivitiesCreateRecordDTO;
      payload = {
        subject: activitiesDto.subject,
        note: activitiesDto.note,
        type: activitiesDto.type ? activitiesDto.type : !isUpdateRecord ? 'call' : undefined,
        due_date: activitiesDto.dueDate && formatDateTime(activitiesDto.dueDate, 'date'),
        due_time: activitiesDto.dueDate && formatDateTime(activitiesDto.dueDate, 'time'),
        done: activitiesDto.done === 'true' ? 1 : 0,
        location: activitiesDto.location,
        user_id: activitiesDto.userId && parseInt(activitiesDto.userId),
        deal_id: activitiesDto.dealId && parseInt(activitiesDto.dealId),
        lead_id: activitiesDto.leadId,
        person_id: activitiesDto.personId && parseInt(activitiesDto.personId),
        org_id: activitiesDto.organizationId && parseInt(activitiesDto.organizationId),
      };
      break;

    case RecordType.DEALS:
      const dealsDto: DealsCreateRecordDTO = dto as DealsCreateRecordDTO;
      payload = {
        title: dealsDto.title,
        value: dealsDto.value,
        status: dealsDto.status,
        expected_close_date:
          dealsDto.expectedCloseDate && formatDateTime(dealsDto.expectedCloseDate, 'date'),
        user_id: dealsDto.userId && parseInt(dealsDto.userId),
        person_id: dealsDto.personId && parseInt(dealsDto.personId),
        org_id: dealsDto.organizationId && parseInt(dealsDto.organizationId),
        pipeline_id: dealsDto.pipelineId && parseInt(dealsDto.pipelineId),
        stage_id: dealsDto.stageId && parseInt(dealsDto.stageId),
        ...parseCodeInput(dealsDto.additionalFields),
      };
      break;

    case RecordType.LEADS:
      const leadsDto: LeadsCreateRecordDTO = dto as LeadsCreateRecordDTO;
      payload = {
        title: leadsDto.title,
        value: leadsDto.value,
        expected_close_date:
          leadsDto.expectedCloseDate && formatDateTime(leadsDto.expectedCloseDate, 'date'),
        owner_id: leadsDto.ownerId && parseInt(leadsDto.ownerId),
        person_id: leadsDto.personId && parseInt(leadsDto.personId),
        organization_id: leadsDto.organizationId && parseInt(leadsDto.organizationId),
        ...parseCodeInput(leadsDto.additionalFields),
      };
      break;

    case RecordType.NOTES:
      const notesDto: NotesCreateRecordDTO = dto as NotesCreateRecordDTO;
      payload = {
        content: notesDto.content,
        lead_id: notesDto.leadId,
        person_id: notesDto.personId && parseInt(notesDto.personId),
        org_id: notesDto.organizationId && parseInt(notesDto.organizationId),
        deal_id: notesDto.dealId && parseInt(notesDto.dealId),
      };
      break;

    case RecordType.ORGANIZATIONS:
      const organizationsDto: OrganizationsCreateRecordDTO = dto as OrganizationsCreateRecordDTO;
      payload = {
        name: organizationsDto.name,
        owner_id: organizationsDto.ownerId && parseInt(organizationsDto.ownerId),
        ...parseCodeInput(organizationsDto.additionalFields),
      };
      break;

    case RecordType.PERSONS:
      const personsDto: PersonsCreateRecordDTO = dto as PersonsCreateRecordDTO;
      payload = {
        name: personsDto.name,
        email: getArrayOfStringsFromCommaSeparatedList(personsDto.email),
        phone: getArrayOfStringsFromCommaSeparatedList(personsDto.phone),
        org_id: personsDto.organizationId && parseInt(personsDto.organizationId),
        owner_id: personsDto.ownerId && parseInt(personsDto.ownerId),
        ...parseCodeInput(personsDto.additionalFields),
      };
      break;

    case RecordType.USERS:
      const usersDto: UsersCreateRecordDTO = dto as UsersCreateRecordDTO;
      payload = {
        name: usersDto.name,
        email: usersDto.email,
        active_flag: usersDto.activeStatus === 'true',
      };
      break;

    default:
  }

  return payload && filterEmptyProperties(payload);
};
