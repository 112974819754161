import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.GOOGLE_ADS,
  name: 'Google Ads',
  description:
    "Connect to your users' Google Ads account to manage their ads, campaigns, and conversions.",
  icon: iconSvg,
  provider: ProviderType.GOOGLE_ADS,
  sidebarSections: [],
};

export { authConfig };
export default connectConfig;
