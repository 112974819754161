import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'email'
  | 'openid'
  | 'offline_access'
  | 'ChannelMessage.Send'
  | 'Chat.ReadWrite'
  | 'ChatMessage.Send'
  | 'GroupMember.Read.All'
  | 'Group.Read.All'
  | 'Directory.Read.All'
  | 'Group.ReadWrite.All'
  | 'Directory.ReadWrite.All'
  | 'Directory.AccessAsUser.All'
  | 'Team.ReadBasic.All'
  | 'TeamSettings.Read.All'
  | 'TeamSettings.ReadWrite.All'
  | 'User.Read.All'
  | 'User.ReadWrite.All'
  | 'Chat.Read'
  | 'Chat.ReadBasic'
  | 'ChannelMember.Read.All'
  | 'User.ReadBasic.All'
  | 'Channel.ReadBasic.All'
  | 'AppCatalog.Submit';

export const scopes: Record<ScopeName, ScopeValue> = {
  email: {
    name: 'email',
    description: "Provides access to the user's email",
  },
  openid: {
    name: 'openid',
    description:
      'Allows access to the current, logged in user’s unique identifier for OpenID Connect apps.',
  },
  offline_access: {
    name: 'offline_access',
    description: 'To get refresh tokens for offline access.',
  },
  'AppCatalog.Submit': {
    name: 'AppCatalog.Submit',
    description: 'Submit application packages to the catalog and cancel pending submissions',
  },
  'ChannelMessage.Send': {
    name: 'ChannelMessage.Send',
    description:
      'Allows app to send channel messages in Microsoft Teams, on behalf of the signed-in user.',
  },
  'Chat.ReadWrite': {
    name: 'Chat.ReadWrite',
    description:
      'Allows app to read and write 1 on 1 or group chats threads, on behalf of the signed-in user.',
  },
  'Chat.ReadBasic': {
    name: 'Chat.ReadBasic',
    description: 'Read names and members of user chat threads',
  },
  'Chat.Read': { name: 'Chat.Read', description: 'Read chat data' },
  'ChatMessage.Send': {
    name: 'ChatMessage.Send',
    description:
      'Allows app to send one-to-one and group chat messages in Microsoft Teams, on behalf of the signed-in user.',
  },
  'GroupMember.Read.All': {
    name: 'GroupMember.Read.All',
    description: 'Read group member data',
  },
  'Group.Read.All': {
    name: 'Group.Read.All',
    description: 'Read all groups',
  },
  'Directory.Read.All': {
    name: 'Directory.Read.All',
    description: 'Read directory data',
  },
  'Group.ReadWrite.All': {
    name: 'Group.ReadWrite.All',
    description: 'Read and write all groups',
  },
  'Directory.ReadWrite.All': {
    name: 'Directory.ReadWrite.All',
    description: 'Read and write directory data',
  },
  'Directory.AccessAsUser.All': {
    name: 'Directory.AccessAsUser.All',
    description: 'Access directory as the signed in user',
  },
  'Team.ReadBasic.All': {
    name: 'Team.ReadBasic.All',
    description: 'Read the names and descriptions of teams, on your behalf.',
  },
  'TeamSettings.ReadWrite.All': {
    name: 'TeamSettings.ReadWrite.All',
    description: "Read and change teams' settings",
  },
  'TeamSettings.Read.All': {
    name: 'TeamSettings.Read.All',
    description: "Read all teams' settings, on your behalf",
  },
  'User.Read.All': {
    name: 'User.Read.All',
    description: 'Read user information.',
  },
  'User.ReadWrite.All': {
    name: 'User.ReadWrite.All',
    description: 'Read and write user info information.',
  },
  'User.ReadBasic.All': {
    name: 'User.ReadBasic.All',
    description: "Read all users' basic profiles",
  },
  'Channel.ReadBasic.All': {
    name: 'Channel.ReadBasic.All',
    description: 'Read channel names and channel descriptions, on your behalf.',
  },
  'ChannelMember.Read.All': {
    name: 'ChannelMember.Read.All',
    description: 'Read channel members information.',
  },
};

export const requiredScopes: ScopeName[] = [
  'openid',
  'email',
  'offline_access',
  'ChannelMessage.Send',
  'ChannelMember.Read.All',
  'Chat.Read',
  'Chat.ReadWrite',
  'ChatMessage.Send',
  'Channel.ReadBasic.All',
  'Team.ReadBasic.All',
  'User.ReadBasic.All',
  'Directory.Read.All',
  'AppCatalog.Submit',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Microsoft Teams',
  description: "Access user's teams resource.",
  authUrl: 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
  accessTokenUrl: 'https://login.microsoftonline.com/organizations/oauth2/v2.0/token',
  iconSvg,
  clientIdLabel: 'App/Manifest ID',
  clientSecretLabel: 'Bot Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'App/Manifest ID',
      id: 'botExternalId',
    },
    {
      type: SidebarInputType.ValueText,
      title: 'Bot ID',
      id: 'clientId',
    },
    { type: SidebarInputType.ValueText, title: 'Bot Client Secret', id: 'clientSecret' },
  ],

  scopes: {
    values: Object.values(scopes),
  },
  documentationLink:
    'https://docs.microsoft.com/en-us/graph/api/resources/teams-api-overview?view=graph-rest-1.0',
};
