import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { BalanceTransactionType } from '../shared/types';

const generateMetadataInput = (id: string): SidebarInput => {
  return {
    id,
    title: 'Metadata',
    required: false,
    type: SidebarInputType.Code,
    lines: 1,
    language: 'json',
    useLightTheme: true,
    resizeOnOverflow: true,
    placeholder: '{ “field1”: 1, “field2”: 2}',
  };
};

const customerIdInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  title: 'Customer ID',
  lines: 1,
  id: 'customerId',
};

const emailInput: SidebarInput = {
  id: 'email',
  type: SidebarInputType.TextArea,
  title: 'Email address',
  lines: 1,
};

const createdAfterInput: SidebarInput = {
  id: 'createdAfter',
  type: SidebarInputType.TextArea,
  title: 'Created after',
  subtitle: 'Filters customers created after the provided date (Unix timestamp).',
  required: false,
  lines: 1,
};

const createdBeforeInput: SidebarInput = {
  id: 'createdBefore',
  type: SidebarInputType.TextArea,
  title: 'Created before',
  subtitle: 'Filters customers created before the provided date (Unix timestamp).',
  required: false,
  lines: 1,
};

const descriptionInput: SidebarInput = {
  id: 'description',
  type: SidebarInputType.TextArea,
  title: 'Description',
  required: false,
  lines: 1,
};

const nameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.TextArea,
  title: 'Name',
  required: false,
  lines: 1,
};

export const subscriptionStatusInput: SidebarInput = {
  type: SidebarInputType.Enum,
  id: 'subscriptionStatus',
  required: false,
  title: 'Status',
  placeholder: 'Select a Status',
  getValues: (): EnumInputValue<string>[] => [
    {
      value: 'incomplete',
      label: 'incomplete',
    },
    {
      value: 'incomplete_expired',
      label: 'incomplete expired',
    },
    {
      value: 'trialing',
      label: 'trialing',
    },
    {
      value: 'active',
      label: 'active',
    },
    {
      value: 'past_due',
      label: 'past due',
    },
    {
      value: 'canceled',
      label: 'canceled',
    },
    {
      value: 'unpaid',
      label: 'unpaid',
    },
  ],
};

const connectConfig: ActionConfig = {
  actionType: Action.STRIPE,
  name: 'Stripe',
  description: 'Connect to your users’ Stripe accounts',
  icon: iconSvg,
  provider: ProviderType.STRIPE,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              title: 'Customers',
              items: [
                {
                  value: Intent.CREATE_CUSTOMER,
                  label: 'Create Customer',
                },
                {
                  value: Intent.UPDATE_CUSTOMER,
                  label: 'Update Customer',
                },
                {
                  value: Intent.GET_CUSTOMER_BY_ID,
                  label: 'Get Customer by ID',
                },
                {
                  value: Intent.GET_CUSTOMERS,
                  label: 'List Customers',
                },
              ],
            },
            {
              title: 'Subscriptions',
              items: [
                {
                  value: Intent.CREATE_SUBSCRIPTION,
                  label: 'Create Subscription',
                },
                {
                  value: Intent.GET_SUBSCRIPTIONS,
                  label: 'List Subscriptions',
                },
              ],
            },
            {
              title: 'Products',
              items: [
                {
                  value: Intent.CREATE_PRODUCT,
                  label: 'Create Product',
                },
                {
                  value: Intent.GET_PRODUCT_BY_ID,
                  label: 'Get Product by ID',
                },
                {
                  value: Intent.GET_PRODUCTS,
                  label: 'List Products',
                },
              ],
            },
            {
              title: 'Balance Transactions',
              items: [
                {
                  value: Intent.GET_BALANCE_TRANSACTIONS,
                  label: 'List Balance Transaction',
                },
              ],
            },
            {
              title: 'Plans',
              items: [
                {
                  value: Intent.GET_PLANS,
                  label: 'List Plans',
                },
              ],
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_CUSTOMER:
          return {
            inputs: [
              {
                ...emailInput,
                id: 'emailCreateCustomer',
                required: true,
              },
              nameInput,
              descriptionInput,
              generateMetadataInput('metadataCreateCustomer'),
            ],
          };
        case Intent.UPDATE_CUSTOMER:
          return {
            inputs: [
              {
                ...customerIdInput,
                subtitle: 'The ID of the customer to update.',
                required: true,
              },
              {
                ...emailInput,
                id: 'emailUpdateCustomer',
                required: false,
              },
              nameInput,
              descriptionInput,
              generateMetadataInput('metadataUpdateCustomer'),
            ],
          };
        case Intent.GET_CUSTOMER_BY_ID:
          return {
            inputs: [
              {
                ...customerIdInput,
                id: 'idGetCustomer',
                required: true,
              },
            ],
          };
        case Intent.GET_CUSTOMERS:
          return {
            inputs: [
              {
                ...emailInput,
                id: 'emailGetCustomers',
                subtitle: 'Filters customers based on email address.',
                required: false,
              },
              createdAfterInput,
              createdBeforeInput,
              {
                id: 'limitGetCustomers',
                type: SidebarInputType.TextArea,
                title: 'Limit customers',
                subtitle:
                  'Limit the maximum number of customers to return. Defaults to 10 if left blank.',
                required: false,
                lines: 1,
              },
            ],
          };
        case Intent.CREATE_SUBSCRIPTION:
          return {
            inputs: [
              {
                ...customerIdInput,
                id: 'customerIdCreateSubscription',
                required: true,
                subtitle: 'The Customer that the Subscription will be created for.',
              },
              {
                id: 'plan',
                type: SidebarInputType.TextArea,
                title: 'Plan',
                subtitle:
                  'The Plan that the Subscription will be created with. Use Connect Portal Workflow Settings to allow users to select a Plan.',
                placeholder: '{{settings.plan}}',
                required: true,
                lines: 1,
              },
              generateMetadataInput('metadataCreateSubscription'),
            ],
          };

        case Intent.GET_SUBSCRIPTIONS:
          return {
            inputs: [
              {
                ...customerIdInput,
                id: 'customerIdGetSubscriptions',
                required: false,
                subtitle: 'The ID of the Customer whose Subscriptions to list.',
              },
              subscriptionStatusInput,
              {
                id: 'limitGetSubscriptions',
                type: SidebarInputType.TextArea,
                title: 'Limit',
                subtitle:
                  'Limit the maximum number of subscriptions to return. Defaults to 10 if left blank.',
                required: false,
                lines: 1,
              },
            ],
          };

        case Intent.CREATE_PRODUCT:
          return {
            inputs: [
              {
                id: 'productName',
                type: SidebarInputType.TextArea,
                title: 'Name',
                lines: 1,
              },
              descriptionInput,
              generateMetadataInput('metadataProduct'),
            ],
          };
        case Intent.GET_PRODUCT_BY_ID:
          return {
            inputs: [
              {
                id: 'productId',
                type: SidebarInputType.TextArea,
                title: 'Product ID',
                lines: 1,
                required: true,
              },
            ],
          };
        case Intent.GET_PRODUCTS:
          return {
            inputs: [
              createdAfterInput,
              createdBeforeInput,
              {
                id: 'limitGetProducts',
                type: SidebarInputType.TextArea,
                title: 'Limit Products',
                subtitle:
                  'Limit the maximum number of products to return. Defaults to 10 if left blank.',
                required: false,
                lines: 1,
              },
            ],
          };
        case Intent.GET_BALANCE_TRANSACTIONS:
          return {
            inputs: [
              {
                id: 'balanceTransactionType',
                title: 'Type',
                type: SidebarInputType.Enum,
                required: false,
                subtitle: 'Only returns transactions of the given type.',
                getValues: (): EnumInputValue<BalanceTransactionType>[] => [
                  {
                    value: 'charge',
                    label: 'Charge',
                  },
                  {
                    value: 'refund',
                    label: 'Refund',
                  },
                  {
                    value: 'payment',
                    label: 'Payment',
                  },
                  {
                    value: 'payment_refund',
                    label: 'Payment Refund',
                  },
                ],
              },
            ],
          };
        case Intent.GET_PLANS:
          return {
            inputs: [
              {
                id: 'isPlanActive',
                title: 'Active',
                type: SidebarInputType.BooleanInput,
                required: false,
                subtitle:
                  'Select true to list all active plans or false to list all inactive plans.',
              },
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default connectConfig;
