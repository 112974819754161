import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  IntentInputDesignType,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { EntityInputs, MD_ENTITY_SPECIFIED_BY_OBJECT_NAME } from '../shared';

import { customMdEntityNameInput, getFilterInputConnect, mdEntityInput } from './inputs';

const triggerConfig: ActionConfig = {
  actionType: Action.MICROSOFT_DYNAMICS,
  name: 'Microsoft Dynamics',
  description: 'Trigger when records are created or updated in Microsoft Dynamics',
  icon: iconSvg,
  provider: ProviderType.MICROSOFT_DYNAMICS_365,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          designType: IntentInputDesignType.WorkflowSettingsDesign,
          icon: iconSvg,
          values: [
            {
              value: Intent.TRIGGER_RECORD_CREATED,
              label: 'New Record',
              subtitle: 'Trigger when a new Dynamics 365 record is created',
            },
            {
              value: Intent.TRIGGER_RECORD_UPDATED,
              label: 'Record Updated',
              subtitle: 'Trigger when a Dynamics 365 record is updated',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedMdEntity: EntityInputs['mdEntity'] = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'mdEntity',
      )?.value;

      return {
        inputs: [
          mdEntityInput,
          ...(selectedMdEntity === MD_ENTITY_SPECIFIED_BY_OBJECT_NAME
            ? [customMdEntityNameInput]
            : []),
          ...(selectedMdEntity ? [getFilterInputConnect(selectedMdEntity)] : []),
        ],
      };
    },
  ],
};

export default triggerConfig;
