export default `<svg width="154" height="33" viewBox="0 0 154 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2720_2797)">
<path d="M28.7077 4.33313L19.6483 28.1003H25.8031L27.475 23.5108H36.1083L37.8015 28.1003H44.1121L35.0576 4.33313H28.7077ZM29.3633 18.4295L31.8023 11.7305L34.2446 18.4295H29.3633ZM44.6858 4.33313H50.9144V28.1003H44.6858V4.33313ZM59.9295 4.33313H53.7009V28.1003H68.8283L69.7577 22.8551H59.9295V4.33313Z" fill="#25252B"/>
<path d="M102.055 13.3483H92.8758V4.33319H67.7252L66.7974 9.74227H74.1161L70.8641 28.1004H77.0977L80.3497 9.74227H86.6471V28.1004H92.8758V18.7574H102.055V28.1004H108.283V4.33319H102.055V13.3483ZM14.149 13.9859L9.50377 12.9025C8.26296 12.6304 7.527 12.0502 7.527 11.006C7.527 9.69474 8.72847 8.90469 10.5102 8.90469C12.5591 8.90469 13.6458 9.90619 14.149 11.2175H20.2269C19.5712 7.11969 16.2782 3.948 10.4709 3.948C5.20601 3.948 1.33278 7.01315 1.33278 11.6518C1.33278 15.6824 3.96356 17.5035 7.4516 18.2788L12.0182 19.2491C13.5294 19.5966 14.267 20.1015 14.267 21.2161C14.267 22.6913 12.9558 23.4895 11.0527 23.4895C9.11694 23.4994 7.72041 22.6913 7.2172 21.0522H0.757446C1.60979 25.8056 5.48302 28.4938 11.174 28.4938C16.3635 28.4938 20.4678 25.7073 20.4678 20.8342C20.4613 17.156 18.2157 14.9547 14.149 13.9859V13.9859ZM130.621 12.4321C130.621 7.24426 127.097 4.33319 121.985 4.33319H111.234V28.1004H117.463V20.2326H119.623L124.58 28.1004H131.474L125.901 19.5884C128.8 18.505 130.621 16.0332 130.621 12.4321V12.4321ZM121.326 15.3153H117.463V9.57836H121.326C123.534 9.57836 124.542 10.7044 124.542 12.4468C124.542 14.1892 123.527 15.3153 121.326 15.3153ZM147.786 4.33319V17.8575C147.786 21.2259 146.18 22.9699 143.624 22.9699C141.03 22.9699 139.427 21.2259 139.427 17.8575V4.33319H133.198V18.4001C133.198 24.9451 137.271 28.5052 143.543 28.5052C149.814 28.5052 153.851 24.9451 153.851 18.4394V4.33319H147.786Z" fill="#25252B"/>
</g>
<defs>
<clipPath id="clip0_2720_2797">
<rect width="153" height="32" fill="white" transform="translate(0.5 0.539307)"/>
</clipPath>
</defs>
</svg>
`;
