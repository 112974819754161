// TODO: Add Webpack loader and change this back to SVG file
export default `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.7707 21.8395C21.9615 20.2498 21.9043 20.0167 23.0884 20.2742L23.389 20.3006C24.2995 20.342 25.4905 20.1542 26.1902 19.8291C27.6965 19.1302 28.5897 17.9633 27.1044 18.2699C23.7165 18.9688 23.4836 17.8216 23.4836 17.8216C27.061 12.5137 28.5565 5.77566 27.2657 4.12634C23.7449 -0.372419 17.6505 1.75494 17.5485 1.81017L17.516 1.81617C16.8466 1.67728 16.0975 1.59429 15.2559 1.58069C13.7228 1.55549 12.5595 1.98261 11.677 2.65194C11.677 2.65194 0.804921 -1.82721 1.31071 8.28494C1.41828 10.4361 4.39415 24.5623 7.94343 20.2955C9.24067 18.7353 10.4943 17.4161 10.4943 17.4161C11.1169 17.8297 11.8622 18.0406 12.6439 17.9649L12.7043 17.9133C12.6853 18.107 12.6937 18.2965 12.7285 18.5208C11.8143 19.5424 12.0829 19.7218 10.2549 20.0981C8.40544 20.4792 9.49198 21.1579 10.2012 21.3352C11.0612 21.5503 13.0508 21.855 14.3954 19.973L14.3419 20.1877C14.7006 20.4747 14.6763 22.2502 14.7272 23.5187C14.7781 24.7873 14.863 25.9711 15.1216 26.6691C15.3802 27.3669 15.6853 29.1647 18.0878 28.6498C20.0956 28.2195 21.6308 27.6002 21.7707 21.8395" fill="black"/>
<path d="M21.7707 21.8395C21.9615 20.2498 21.9043 20.0167 23.0884 20.2742L23.389 20.3006C24.2995 20.342 25.4905 20.1542 26.1902 19.8291C27.6965 19.1302 28.5897 17.9633 27.1044 18.2699C23.7165 18.9688 23.4836 17.8216 23.4836 17.8216C27.061 12.5137 28.5565 5.77566 27.2657 4.12634C23.7449 -0.372419 17.6505 1.75494 17.5485 1.81017L17.516 1.81617C16.8466 1.67728 16.0975 1.59429 15.2559 1.58069C13.7228 1.55549 12.5595 1.98261 11.677 2.65194C11.677 2.65194 0.804921 -1.82721 1.31071 8.28494C1.41828 10.4361 4.39415 24.5623 7.94343 20.2955C9.24067 18.7353 10.4943 17.4161 10.4943 17.4161C11.1169 17.8297 11.8622 18.0406 12.6439 17.9649L12.7043 17.9133C12.6853 18.107 12.6937 18.2965 12.7285 18.5208C11.8143 19.5424 12.0829 19.7218 10.2549 20.0981C8.40544 20.4792 9.49198 21.1579 10.2012 21.3352C11.0612 21.5503 13.0508 21.855 14.3954 19.973L14.3419 20.1877C14.7006 20.4747 14.6763 22.2502 14.7272 23.5187C14.7781 24.7873 14.863 25.9711 15.1216 26.6691C15.3802 27.3669 15.6853 29.1647 18.0878 28.6498C20.0956 28.2195 21.6308 27.6002 21.7707 21.8395" stroke="black" stroke-width="1.38472"/>
<path d="M27.1044 18.2689C23.7161 18.9678 23.4832 17.8206 23.4832 17.8206C27.0607 12.5123 28.5561 5.77417 27.2656 4.12505C23.7448 -0.373434 17.6501 1.75406 17.5484 1.80929L17.5157 1.81515C16.8463 1.6762 16.0971 1.59341 15.2551 1.57967C13.722 1.55455 12.5591 1.9816 11.6766 2.65079C11.6766 2.65079 0.804337 -1.82816 1.30999 8.28392C1.41756 10.4352 4.39336 24.5614 7.94278 20.2946C9.24009 18.7344 10.4935 17.4152 10.4935 17.4152C11.1161 17.8288 11.8615 18.0398 12.6428 17.964L12.7035 17.9124C12.6846 18.1062 12.6933 18.2956 12.7278 18.5199C11.8134 19.5416 12.0821 19.7209 10.2542 20.0972C8.40465 20.4784 9.4912 21.157 10.2006 21.3344C11.0606 21.5494 13.0503 21.8541 14.3947 19.9721L14.3411 20.1868C14.6994 20.4738 14.9509 22.0534 14.9088 23.4853C14.8666 24.9172 14.8385 25.9003 15.1208 26.6682C15.4031 27.4361 15.6844 29.1638 18.0873 28.6489C20.0952 28.2187 21.1357 27.1036 21.2804 25.2437C21.3831 23.9214 21.6156 24.1168 21.6303 22.9346L21.8167 22.375C22.0317 20.5825 21.8509 20.0042 23.0879 20.2732L23.3885 20.2996C24.299 20.341 25.4906 20.1532 26.19 19.8281C27.696 19.1292 28.5891 17.9623 27.1042 18.2689H27.1044Z" fill="#336791"/>
<path d="M14.5405 19.2969C14.4472 22.6322 14.5639 25.9907 14.8902 26.8069C15.2168 27.6232 15.9156 29.2108 18.3188 28.696C20.3264 28.2656 21.0569 27.4326 21.3739 25.5941C21.6074 24.2414 22.0574 20.4847 22.1152 19.715" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.66 2.57719C11.66 2.57719 0.780343 -1.8695 1.28613 8.24258C1.3937 10.3939 4.3697 24.5205 7.91905 20.2535C9.21609 18.6931 10.3891 17.4693 10.3891 17.4693" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.5366 1.76544C17.16 1.88352 23.5886 -0.584608 27.242 4.08369C28.5325 5.73288 27.037 12.471 23.4597 17.7795" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.4597 17.7795C23.4597 17.7795 23.6928 18.927 27.0811 18.2276C28.5657 17.921 27.6723 19.088 26.1666 19.7873C24.9309 20.3607 22.1606 20.5077 22.1153 19.7153C21.9989 17.6706 23.5734 18.2918 23.4598 17.7795C23.3571 17.3181 22.6529 16.8652 22.1871 15.736C21.7805 14.7503 16.6102 7.1917 23.621 8.31428C23.8778 8.26114 21.7925 1.64573 15.2314 1.53816C8.67172 1.43059 8.88693 9.60506 8.88693 9.60506" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="bevel"/>
<path d="M12.7041 18.4787C11.7896 19.5003 12.0585 19.6797 10.2305 20.0561C8.38093 20.4373 9.46762 21.1158 10.1768 21.2931C11.0369 21.5083 13.0265 21.8129 14.3709 19.9305C14.7803 19.3574 14.3685 18.4428 13.8061 18.2097C13.5344 18.0972 13.1711 17.9561 12.7041 18.4787V18.4787Z" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.6442 18.4609C12.552 17.8602 12.8415 17.1455 13.1518 16.3093C13.618 15.0547 14.6938 13.7999 13.8332 9.82014C13.1918 6.85444 8.88945 9.20294 8.88676 9.60506C8.88413 10.0071 9.08136 11.6433 8.81489 13.5487C8.46719 16.0351 10.397 18.1379 12.6192 17.9228" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6204 9.5451C11.601 9.68244 11.8718 10.0489 12.225 10.0979C12.5775 10.1471 12.8793 9.86067 12.8984 9.72346C12.9175 9.58612 12.647 9.43483 12.2937 9.38566C11.941 9.33642 11.6391 9.40809 11.6204 9.5451H11.6204Z" fill="white" stroke="white" stroke-width="0.153857"/>
<path d="M22.3592 9.2648C22.3783 9.40214 22.1078 9.76864 21.7546 9.81761C21.4018 9.86678 21.1 9.58037 21.0807 9.44316C21.062 9.30582 21.3326 9.15453 21.6855 9.10536C22.0384 9.05619 22.3401 9.12779 22.3592 9.26487V9.2648Z" fill="white" stroke="white" stroke-width="0.0769283"/>
<path d="M23.6211 8.31445C23.6792 9.39177 23.389 10.1256 23.3525 11.2724C23.2983 12.9394 24.1472 14.8473 22.8681 16.7577" stroke="white" stroke-width="0.461573" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
