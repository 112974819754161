import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  additionalFieldsInput,
  additionalHeadersInput,
  emailFilterInput,
  emailInput,
  familyNameInput,
  fromInput,
  givenNameInput,
  intentTypeInput,
  messageContentInput,
  messageIdInput,
  resourceNameInput,
  searchTermInput,
  subjectInput,
  toRecipientsInput,
  userIdInput,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.GMAIL,
  name: 'Gmail',
  description: 'Connect to your Gmail account to manage your emails and drafts in Gmail.',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEND_EMAIL:
          return {
            inputs: [
              toRecipientsInput,
              fromInput,
              subjectInput,
              messageContentInput,
              additionalHeadersInput,
            ],
          };

        case Intent.GET_EMAIL_BY_ID:
          return {
            inputs: [userIdInput, messageIdInput],
          };

        case Intent.SEARCH_FOR_EMAIL:
          return {
            inputs: [emailFilterInput],
          };

        case Intent.DELETE_EMAIL:
          return {
            inputs: [
              userIdInput,
              {
                ...messageIdInput,
                subtitle: 'Specify the ID of the message to trash.',
                placeholder: '',
              },
            ],
          };

        case Intent.CREATE_A_CONTACT:
          return {
            inputs: [givenNameInput, familyNameInput, emailInput, additionalFieldsInput],
          };

        case Intent.GET_CONTACT_BY_RESOURCE_NAME:
          return {
            inputs: [resourceNameInput],
          };

        case Intent.SEARCH_FOR_CONTACT:
          return {
            inputs: [searchTermInput],
          };

        case Intent.DELETE_CONTACT:
          return {
            inputs: [
              {
                ...resourceNameInput,
                subtitle: 'Specify the resource name of the contact to delete.',
              },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
