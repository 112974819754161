import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import { Base } from '../shared/types';

export const getBases: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Base',
  cacheKey: 'bases',
  subtitle: 'Base for Records',
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
  ],
  mapRefreshToValues: (response: ActionResponse<Base[]>): EnumInputValue[] =>
    response.result.output.map((base: Base) => ({
      label: base.name,
      value: base.id,
    })),
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.AIRTABLE,
      intent: Intent.FETCH_BASES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getViews: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'View Name',
  cacheKey: 'viewNames',
  hideFromConnectFieldTypes: true,
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
    'baseId',
    'tableName',
  ],
  mapRefreshToValues: (response: ActionResponse<string[]>): EnumInputValue[] => {
    return response.result.output.map((viewName: string) => ({
      label: viewName,
      value: viewName,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.AIRTABLE,
      intent: Intent.FETCH_VIEWS,
      credentials: options.credentials,
      actionParameters: options.actionParameters.filter(
        (value: KeyedSource<DataType.ANY>) => value.key === 'baseId' || value.key === 'tableName',
      ),
    };
  },
};

export const getTables: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Table Name',
  cacheKey: 'tableNames',
  hideFromConnectFieldTypes: true,
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
    'baseId',
  ],
  mapRefreshToValues: (response: ActionResponse<string[]>): EnumInputValue[] => {
    return response.result.output.map((tableName: string) => ({
      label: tableName,
      value: tableName,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.AIRTABLE,
      intent: Intent.FETCH_TABLES,
      credentials: options.credentials,
      actionParameters: options.actionParameters.filter(
        (value: KeyedSource<DataType.ANY>) => value.key === 'baseId',
      ),
    };
  },
};

export const getColumns: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Column Name',
  cacheKey: 'columnNames',
  hideFromConnectFieldTypes: true,
  refreshDependencies: ['tableName'],
  getRefreshActionParameters: (options: ActionStepParameters) => {
    const fetchAllColumns: boolean = (options.intent as Intent) === Intent.FETCH_RECORDS;

    return {
      actionType: Action.AIRTABLE,
      actionParameters: [
        ...options.actionParameters.filter(
          (value: KeyedSource<DataType.ANY>) => value.key === 'baseId' || value.key === 'tableName',
        ),
        { key: 'fetchAllColumns', source: { type: 'VALUE', value: fetchAllColumns } },
      ],
      intent: Intent.FETCH_COLUMNS_FOR_BASE,
      credentials: options.credentials,
    };
  },
  mapRefreshToValues: (response: any) => {
    return Object.keys(response.result.output).map((columnName: string) => ({
      label: columnName,
      value: columnName,
    }));
  },
};
