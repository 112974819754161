import React from 'react';

import {
  ActionResponse,
  createTestConnectionButton,
  getValuesByCacheKey,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { Template } from '../shared/types';

const sendgridApiKeysLink = (
  <a href="https://app.sendgrid.com/settings/api_keys" target="_blank" rel="noreferrer">
    API Keys page
  </a>
);

const getTemplates: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Template',

  cacheKey: 'templates',
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
  ],
  mapRefreshToValues: (response: ActionResponse<Template[]>): EnumInputValue[] => {
    return response.result.output.map((template: Template) => ({
      label: template.name,
      value: `${template.id}|${template.generation}`,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.SENDGRID,
      intent: Intent.LIST_TEMPLATES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const authConfig: ActionConfig = {
  actionType: Action.SENDGRID,
  provider: ProviderType.SENDGRID,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your SendGrid account',
  description: 'Add SendGrid to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this SendGrid account',
          placeholder: 'e.g. Production, Testing, etc.',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'SENDGRID_API_KEY',
          title: 'Add your SendGrid API Key',
          subtitle: <>To create a new API key, visit your SendGrid {sendgridApiKeysLink}.</>,
          placeholder: 'SendGrid API Key',
          type: SidebarInputType.ValueText,
        },
      ],
      buttons: [createTestConnectionButton(Action.SENDGRID, ['SENDGRID_API_KEY'])],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.SENDGRID,
  name: 'SendGrid',
  description: 'Send emails with SendGrid.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect your SendGrid account',
          placeholder: 'choose your SendGrid account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.SEND_EMAIL,
                    label: 'Send Email',
                  },
                  {
                    value: Intent.SEND_TEMPLATE_EMAIL,
                    label: 'Send Email from Template',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEND_EMAIL:
          return {
            inputs: [
              {
                id: 'from',
                type: SidebarInputType.Text,
                title: 'From Email',
                placeholder: 'type or choose from previous steps',
                required: true,
              },
              {
                id: 'to',
                type: SidebarInputType.Text,
                title: 'Recipients',
                subtitle:
                  'Separate multiple email addresses with commas. You can also pass in an array of email addresses from a previous step.',
                placeholder: 'separate multiple email addresses with commas',
                required: true,
              },
              {
                id: 'subject',
                type: SidebarInputType.TextArea,
                title: 'Subject',
                placeholder: 'Type {{ to insert variables',
                required: true,
                lines: 1,
              },
              {
                id: 'html',
                type: SidebarInputType.TextArea,
                title: 'Message',
                subtitle: 'You can send messages in plain text or HTML.',
                required: true,
              },
            ],
          };
        case Intent.SEND_TEMPLATE_EMAIL:
          return {
            inputs: [
              {
                id: 'from',
                type: SidebarInputType.Text,
                title: 'From Email',
                placeholder: 'type or choose from previous steps',
                required: true,
              },
              {
                id: 'to',
                type: SidebarInputType.Text,
                title: 'Recipients',
                subtitle:
                  'Separate multiple email addresses with commas. You can also pass in an array of email addresses from a previous step.',
                placeholder: 'separate multiple email addresses with commas',
                required: true,
              },
              {
                id: 'template',
                type: SidebarInputType.DynamicEnum,
                title: 'Template',
                placeholder: 'choose a template',
                required: true,
                source: getTemplates,
                getValues: getValuesByCacheKey,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (parameters.intent === Intent.SEND_TEMPLATE_EMAIL) {
        const templateSource = pickValueSourceByKey(parameters.actionParameters, 'template');

        if (templateSource === undefined || templateSource.value === undefined) {
          return { inputs: [] };
        }
        const template = templateSource.value as string;

        const generation: Template['generation'] = template.split('|')[1] as Template['generation'];

        switch (generation) {
          case 'dynamic':
            return {
              inputs: [
                {
                  id: 'dynamicTemplateData',
                  type: SidebarInputType.Code,
                  language: 'json',
                  title: 'Template Data',
                  required: true,
                  placeholder: `{
  firstName: 'John',
  lastName: 'Doe',
  address: '123 Main Street',
  city: 'Los Angeles',
  state: 'California',
}`,
                },
              ],
            };
          case 'legacy':
            return {
              inputs: [
                {
                  id: 'subject',
                  type: SidebarInputType.TextArea,
                  title: 'Subject',
                  placeholder: 'type or choose from previous steps',
                  required: true,
                  lines: 1,
                },
                {
                  id: 'html',
                  type: SidebarInputType.TextArea,
                  title: 'Message',
                  subtitle: 'You can send messages in plain text or HTML.',
                  required: true,
                },
              ],
            };
          default:
            return { inputs: [] };
        }
      } else {
        return { inputs: [] };
      }
    },
  ],
};

export default config;
