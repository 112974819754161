import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_CAMPAIGN_MANAGER;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'GOOGLE_CAMPAIGN_MANAGER_TEST_CONNECTION',
  CREATE_AD = 'GOOGLE_CAMPAIGN_MANAGER_CREATE_AD',
  UPDATE_AD = 'GOOGLE_CAMPAIGN_MANAGER_UPDATE_AD',
  GET_ALL_ADS = 'GOOGLE_CAMPAIGN_MANAGER_GET_ALL_ADS',
  GET_AD_BY_ID = 'GOOGLE_CAMPAIGN_MANAGER_GET_AD_BY_ID',
  GET_ALL_ADVERTISERS = 'GOOGLE_CAMPAIGN_MANAGER_GET_ALL_ADVERTISERS',
  GET_ADVERTISER_BY_ID = 'GOOGLE_CAMPAIGN_MANAGER_GET_ADVERTISER_BY_ID',
  CREATE_CAMPAIGN = 'GOOGLE_CAMPAIGN_MANAGER_CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN = 'GOOGLE_CAMPAIGN_MANAGER_UPDATE_CAMPAIGN',
  GET_ALL_CAMPAIGNS = 'GOOGLE_CAMPAIGN_MANAGER_GET_ALL_CAMPAIGNS',
  GET_CAMPAIGN_BY_ID = 'GOOGLE_CAMPAIGN_MANAGER_GET_CAMPAIGN_BY_ID',
  CREATE_CREATIVE = 'GOOGLE_CAMPAIGN_MANAGER_CREATE_CREATIVE',
  UPDATE_CREATIVE = 'GOOGLE_CAMPAIGN_MANAGER_UPDATE_CREATIVE',
  GET_ALL_CREATIVES = 'GOOGLE_CAMPAIGN_MANAGER_GET_ALL_CREATIVES',
  GET_CREATIVE_BY_ID = 'GOOGLE_CAMPAIGN_MANAGER_GET_CREATIVE_BY_ID',
  CREATE_CREATIVE_ASSET = 'GOOGLE_CAMPAIGN_MANAGER_CREATE_CREATIVE_ASSET',
  GET_CAMPAIGN_CREATIVE_ASSOCIATION = 'GOOGLE_CAMPAIGN_MANAGER_GET_CAMPAIGN_CREATIVE_ASSOCIATION',
  CREATE_CAMPAIGN_CREATIVE_ASSOCIATION = 'GOOGLE_CAMPAIGN_MANAGER_CREATE_CAMPAIGN_CREATIVE_ASSOCIATION',
  CREATE_CREATIVE_GROUPS = 'GOOGLE_CAMPAIGN_MANAGER_CREATE_CREATIVE_GROUPS',
  UPDATE_CREATIVE_GROUPS = 'GOOGLE_CAMPAIGN_MANAGER_UPDATE_CREATIVE_GROUPS',
  GET_ALL_CREATIVE_GROUPS = 'GOOGLE_CAMPAIGN_MANAGER_GET_ALL_CREATIVE_GROUPS',
  GET_CREATIVE_GROUP_BY_ID = 'GOOGLE_CAMPAIGN_MANAGER_GET_CREATIVE_GROUP_BY_ID',
  CREATE_PLACEMENT = 'GOOGLE_CAMPAIGN_MANAGER_CREATE_PLACEMENT',
  UPDATE_PLACEMENT = 'GOOGLE_CAMPAIGN_MANAGER_UPDATE_PLACEMENT',
  GET_ALL_PLACEMENTS = 'GOOGLE_CAMPAIGN_MANAGER_GET_ALL_PLACEMENTS',
  GET_PLACEMENT_BY_ID = 'GOOGLE_CAMPAIGN_MANAGER_GET_PLACEMENT_BY_ID',
  GET_ALL_VIDEO_FORMATS = 'GOOGLE_CAMPAIGN_MANAGER_GET_ALL_VIDEO_FORMATS',
  GET_VIDEO_FORMATS_BY_ID = 'GOOGLE_CAMPAIGN_MANAGER_GET_VIDEO_FORMATS_BY_ID',
  GET_ALL_USER_PROFILES = 'GOOGLE_CAMPAIGN_MANAGER_GET_ALL_USER_PROFILES',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_AD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CAMPAIGN_CREATIVE_ASSOCIATION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CREATIVE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CREATIVE_ASSET;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CREATIVE_GROUPS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PLACEMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ADVERTISER_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_AD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_ADS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_ADVERTISERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_CAMPAIGNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_CREATIVES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_CREATIVE_GROUPS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_PLACEMENTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_VIDEO_FORMATS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CAMPAIGN_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CAMPAIGN_CREATIVE_ASSOCIATION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CREATIVE_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CREATIVE_GROUP_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PLACEMENT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_VIDEO_FORMATS_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_AD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CREATIVE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CREATIVE_GROUPS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_PLACEMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_USER_PROFILES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  GCM_REFRESH_TOKEN: string;
  GCM_ACCESS_TOKEN: string;
  profileId?: string;
};
