import { DelayUnit } from '@shared/types/sdk/steps';

type GenericResolve<T = any> = {
  (value: T): void;
};

type GenericReject<T = Error> = {
  (error: T): void;
};

class GenericTimeoutError extends Error {
  name: string = 'GenericTimeoutError';
  constructor(readonly message: string) {
    super(message);
  }
}

/**
 * a helper class for creating deferred promises
 * @tutorial https://gist.github.com/GFoley83/5877f6c09fbcfd62569c51dc91444cf0
 */
export class DeferredPromise<T> implements Promise<T> {
  [Symbol.toStringTag]: 'Promise';

  private _promise: Promise<T>;
  private _resolve: (value?: T | PromiseLike<T>) => void;
  private _reject: (reason?: any) => void;
  private _state: 'pending' | 'fulfilled' | 'rejected' = 'pending';

  public get state(): 'pending' | 'fulfilled' | 'rejected' {
    return this._state;
  }

  constructor() {
    this._promise = new Promise<T>(
      (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => {
        this._resolve = resolve;
        this._reject = reject;
      },
    );
  }

  public then<TResult1, TResult2>(
    onfulfilled?: (value: T) => TResult1 | PromiseLike<TResult1>,
    onrejected?: (reason: any) => TResult2 | PromiseLike<TResult2>,
  ): Promise<TResult1 | TResult2> {
    return this._promise.then(onfulfilled, onrejected);
  }

  public catch<TResult>(
    onrejected?: (reason: any) => TResult | PromiseLike<TResult>,
  ): Promise<T | TResult> {
    return this._promise.catch(onrejected);
  }

  public resolve(value?: T | PromiseLike<T>): void {
    this._resolve(value);
    this._state = 'fulfilled';
  }

  public reject(reason?: any): void {
    this._reject(reason);
    this._state = 'rejected';
  }

  public finally(onfinally?: () => void): Promise<T> {
    onfinally?.();
    return this._promise;
  }
}

export function sleep(milliseconds: number): Promise<void> {
  return new Promise((resolve: GenericResolve) => setTimeout(resolve, milliseconds));
}

/**
 * wraps a synchronous function in a promise + adds it to the event loop for processing
 * useful for breaking up potentionally long running synchronous operations,
 * like recursion or loops, into smaller non-blocking calls
 * NOTE: the synchronous method called still synchronous + will block at time of execution
 *
 * @export
 * @template T
 * @param {(...args: any[]) => T} method
 * @returns {Promise<T>}
 */
export function runAsync<T = any>(method: (...args: any[]) => T): Promise<T> {
  return new Promise((resolve: GenericResolve) => {
    process.nextTick(() => {
      resolve(method());
    });
  });
}

/**
 * utility for running async (or sync) methods with error handling
 *
 * @param {(...args: any[]) => T} method
 * @param {(error: Error) => void} [errorHandler=() => {}]
 * @returns {Promise<T>}
 */
export function asyncTryCatch<T = any>(
  method: (...args: any[]) => T | Promise<T>,
  errorHandler: (error: Error) => void = () => {},
): Promise<T> {
  return new Promise((resolve: GenericResolve) => {
    process.nextTick(async () => {
      try {
        resolve(await method());
      } catch (e) {
        errorHandler(e);
        resolve(e);
      }
    });
  });
}

/**
 * tries a function x times until it succeeds or runs out of retries
 *
 * @param method The async function to wrap in retry logic
 * @param retries The maximum number of times to retry this function
 * @param backoff The delay between retries in milliseconds or a function that returns it
 */
export async function withRetries<T = any, U = Error>(
  method: (attempt?: number) => Promise<T>,
  retries: number,
  backoff: number | ((attempt: number) => number),
  shouldRetry: (error?: U) => boolean = () => true,
  after?: (attempt: number, error?: U | null) => void | Promise<void>,
): Promise<T> {
  let attempts: number = 0;
  let result: T;

  while (attempts < retries) {
    try {
      attempts += 1;
      result = await method(attempts);
      await after?.(attempts, null);
      return result;
    } catch (e) {
      await after?.(attempts, e);
      const retry: boolean = shouldRetry(e);
      if (attempts < retries && retry) {
        const milliseconds: number = typeof backoff === 'number' ? backoff : backoff(attempts);
        await sleep(milliseconds);
      } else {
        return Promise.reject(e);
      }
    }
  }

  // this is needed otherwise we get a TypeScript error for not returning a value
  throw new Error('Method unable to run: invalid retries.');
}

/**
 * keeps attempting to execute a method until a desired result is achieved
 *
 * @param {() => Promise<T>} method
 * @param {((result: T, attempt: number) => Promise<boolean> | boolean)} shouldRetry
 * @param {(number | ((attempt: number) => number))} backoff
 * @returns {Promise<T>}
 */
export async function tryUntil<T = any>(
  method: () => Promise<T> | T,
  shouldRetry: (
    result: T | null,
    error?: Error | null,
    attempt?: number,
  ) => Promise<boolean> | boolean,
  backoff: number | ((attempt: number) => number),
): Promise<T> {
  let attempts: number = 0;
  let tryAgain: boolean = true;

  while (tryAgain) {
    attempts += 1;
    let result: T | null = null;
    let error = null;
    try {
      result = await method();
    } catch (e) {
      error = e;
    }
    tryAgain = await shouldRetry(result, error, attempts);

    if (!tryAgain) {
      if (error) {
        throw error;
      }
      return result as T;
    } else {
      const milliseconds: number = typeof backoff === 'number' ? backoff : backoff(attempts);
      await sleep(milliseconds);
    }
  }

  // this is needed otherwise we get a TypeScript error for not returning a value
  throw new Error('Method unable to run: invalid retries.');
}

export function isNonGenericClass(item: any): boolean {
  return (
    item &&
    item.constructor &&
    item.constructor.name &&
    !['String', 'Boolean', 'Number', 'Array', 'Object'].includes(item.constructor.name)
  );
}

export async function asyncForEach(array: any[], callback: (...args: any[]) => any): Promise<void> {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export async function asyncReduce<I = any, O = any>(
  array: I[],
  callback: (hash: O, iterator: I, index?: number) => Promise<O> | O,
  initialValue: O,
): Promise<O> {
  let value: O = initialValue;
  for (let index = 0; index < array.length; index++) {
    value = await callback(value, array[index], index);
  }

  return value;
}

export async function asyncMap<I = any, O = any>(
  array: I[],
  callback: (iterator: I, index?: number) => Promise<O> | O,
): Promise<O[]> {
  const output: O[] = [];
  for (let index = 0; index < array.length; index++) {
    output.push(await callback(array[index], index));
  }

  return output;
}

export function isDate(obj: any): boolean {
  return obj && typeof obj.getMonth === 'function';
}

/*
 * ensures an object isn't a class
 * @param obj {any} object to cleanup
 * @returns {any}
 */
export function simplifyObject(obj: any): any {
  if (typeof obj === 'object') {
    return JSON.parse(JSON.stringify(obj));
  }

  return obj;
}

/**
 * generate random string
 * @param length {Number} desired length of string
 * @param useAlphabet {Boolean} whether to use alphabet characters, defaults to true
 * @param useNumbers {Boolean} whether to use numeric characters, defaults to true
 * @returns {String}
 */
export function random(
  length: number = 10,
  useAlphabet: boolean = true,
  useNumbers: boolean = true,
): string {
  let list: string = '';
  useAlphabet = useAlphabet === undefined ? true : useAlphabet ? true : false;
  useNumbers = useNumbers === undefined ? true : useNumbers ? true : false;
  list += useAlphabet ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' : '';
  list += useNumbers ? '0123456789' : '';
  return generateRandomStringFromCharacters(length, list.split(''));
}

/**
 * generates a random string of length given a list of characters to choose from
 *
 * @export
 * @param {number} length
 * @param {string[]} characters
 * @returns {string}
 */
export function generateRandomStringFromCharacters(length: number, characters: string[]): string {
  let token: string = '';
  while (token.length < length) {
    token += characters[Math.floor(Math.random() * characters.length)];
  }
  return token;
}

/**
 * returns a random integer between min (inclusive) and max (inclusive)
 * @param min {Number} minimum number
 * @param max {Number} maximum number
 * @returns {Number}
 */
export function getNumberInRange(min: number, max: number): number {
  // tslint:disable-next-line:insecure-random
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * waits for a promise to resolve within n milliseconds
 * if it doesn't resolve before then, it rejects it
 *
 * @export
 * @param {Promise<any>} promise
 * @param {number} timeout
 * @returns {Promise<any>}
 */
export function countdown<T = any>(promise: any, timeout: number): Promise<T> {
  return new Promise((resolve: GenericResolve, reject: GenericReject) => {
    let count: number = 0;
    const id = setInterval(() => {
      count += 100;
      if (timeout > 0 && count >= timeout) {
        clear();
        return reject(new GenericTimeoutError(`Timed out after ${count}ms`));
      }
    }, 100);
    const clear = () => clearTimeout(id);
    const success = (val: any) => {
      clear();
      resolve(val);
    };
    const fail = (error: Error) => {
      clear();
      reject(error);
    };

    if (promise && promise.then) {
      promise.then(success).catch(fail);
    } else {
      success(promise);
    }
  });
}

export async function safeAsync(
  val: string | object | Function | Promise<any>,
  timeout: number = 1000 * 20,
  args: any[] | null = null,
): Promise<any> {
  if (typeof val === 'function') {
    //@ts-ignore
    val = val.apply(this, args);
  }

  return countdown(val, timeout);
}

export function pickKeysFromObject<T extends Record<string, any>>(obj: T, keys: string[]): T {
  return keys.reduce(
    (queue: Partial<T>, key: keyof T): Partial<T> => ({
      ...queue,
      [key]: obj[key],
    }),
    {},
  ) as T;
}

export function isUUID(value: string): boolean {
  return (
    typeof value === 'string' &&
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
  );
}

export function timeToMilliseconds(unit: DelayUnit, value: number): number {
  switch (unit) {
    case DelayUnit.SECONDS:
      return value * 1000;
    case DelayUnit.MINUTES:
      return value * 1000 * 60;
    case DelayUnit.HOURS:
      return value * 1000 * 60 * 60;
    case DelayUnit.DAYS:
      return value * 1000 * 60 * 60 * 24;
  }
}

export function convertMillisecondsToReadableForm(milliseconds: number): string {
  const seconds: number = milliseconds / 1000;
  let interval: number = Math.floor(seconds / 31536000);

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' days';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' hours';
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes';
  }

  return Math.floor(seconds) + ' seconds';
}

export function sortObjectByKeys<T extends Record<string, any>>(input: T): T {
  return Object.keys(input)
    .sort()
    .reduce((obj: Record<string, any>, key: string): Record<string, any> => {
      obj[key] = input[key];
      return obj;
    }, {} as Record<string, any>) as T;
}

/**
 * given a type definition of a record and possible values for each key,
 * it generates an array of every permutation
 *
 * @template T
 * @param {{ [k in keyof T]: T[k][] }} input
 * @returns {T[]}
 */
export function generateMatrix<T extends Record<string, any>>(
  input: { [k in keyof T]: T[k][] },
  limit?: number,
): T[] {
  const keys: (keyof T)[] = Object.keys(input);

  // If there's more than 1 key and at least 1 key has a value,
  // then we want to push an empty item to the values that don't have any parameters
  // Otherwise an empty matrix will be generated
  const numValues: number = keys.reduce<number>(
    (sum: number, key: keyof T): number => sum + input[key].length,
    0,
  );
  if (numValues && keys.length) {
    for (const key of keys) {
      if (!input[key].length) {
        // @ts-ignore we want to push an empty item to the values that don't have any parameters
        input[key].push(undefined);
      }
    }
  }

  const params: T[] = Object.entries(input)
    .reduce((partialMatrix: any[], [, values]: [string, any[]]) => {
      if (!partialMatrix.length) {
        return values.map((v) => [v]);
      }

      return partialMatrix.reduce((reduced, current) => {
        values.forEach((val) => {
          const copy = current.slice();
          copy.push(val);
          reduced.push(copy);
        }, []);
        return reduced;
      }, []);
    }, [])
    .reduce((matrix: T[], values: any[]): T[] => {
      const param: T = values.reduce(
        (params: Partial<T>, value: any, index: number): Partial<T> => ({
          ...params,
          [keys[index]]: value,
        }),
        {},
      );
      return [...matrix, param];
    }, []) as unknown as T[];

  return limit ? params.slice(-1 * limit) : params;
}

/**
 * returns an object with the specified keys removed
 *
 * @export
 * @template T
 * @param {T} input
 * @param {string[]} keys
 * @returns {T}
 */
export function removeKeys<T extends object>(input: T, keys: string[]): T {
  const output: T = { ...input };
  for (const k of keys) {
    // @ts-ignore: getting error in connect-sdk build from this
    delete output[k];
  }
  return output;
}

export const dateToTimestampSeconds = (date: Date): number => Math.floor(date.getTime() / 1000);

/**
 * slice array in chunks
 * @param arr
 * @param chunkSize
 * @returns
 */
export function sliceIntoChunks<I = any>(arr: I[], chunkSize: number): Array<I[]> {
  const res: Array<I[]> = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

/**
 * returns a set of unique keys from an array of objects
 * @param objects
 * @returns
 */
export function getUniqueKeysInObjects<T extends Object>(objects: T[]): string[] {
  return objects.reduce((keys: string[], object: T): string[] => {
    const objectKeys: string[] = Object.keys(object);
    for (const key of objectKeys) {
      if (key !== 'id' && !keys.includes(key)) {
        keys.push(key);
      }
    }

    return keys;
  }, []);
}
