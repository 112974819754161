import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.XERO;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'XERO_TEST_CONNECTION',
  GET_ACCOUNTS = 'XERO_GET_ACCOUNTS',
  GET_CONTACTS = 'XERO_GET_CONTACTS',
  CREATE_CUSTOMER = 'XERO_CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'XERO_UPDATE_CUSTOMER',
  GET_INVOICES = 'XERO_GET_INVOICES',
  CREATE_INVOICE = 'XERO_CREATE_INVOICE',
  SEND_INVOICE = 'XERO_SEND_INVOICE',
  GET_PAYMENTS = 'XERO_GET_PAYMENTS',
  CREATE_PAYMENT = 'XERO_CREATE_PAYMENT',
  UPDATE_INVOICE = 'XERO_UPDATE_INVOICE',
  GET_CURRENCIES = 'XERO_GET_CURRENCIES',

  TRIGGER_ACCOUNT_CREATED = 'XERO_TRIGGER_ACCOUNT_CREATED',
  TRIGGER_CUSTOMER_CREATED = 'XERO_TRIGGER_CUSTOMER_CREATED',
  TRIGGER_INVOICE_CREATED = 'XERO_TRIGGER_INVOICE_CREATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.GET_ACCOUNTS; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_CONTACTS; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_INVOICES; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_PAYMENTS; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.CREATE_CUSTOMER; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.CREATE_INVOICE; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.CREATE_PAYMENT; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.SEND_INVOICE; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.UPDATE_CUSTOMER; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.UPDATE_INVOICE; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_CURRENCIES; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_ACCOUNT_CREATED; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_CUSTOMER_CREATED; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.TRIGGER_INVOICE_CREATED; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  CLIENT_ID = 'XERO_CLIENT_ID',
  CLIENT_SECRET = 'XERO_CLIENT_SECRET',
  ACCESS_TOKEN = 'XERO_ACCESS_TOKEN',
  REFRESH_TOKEN = 'XERO_REFRESH_TOKEN',
  SCOPES = 'XERO_SCOPES',
}

export type Credentials = {
  name?: string;
  tenantId: string;
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
};
