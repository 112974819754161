import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.DOCUSIGN,
  provider: ProviderType.DOCUSIGN,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your DocuSign account',
  icon: iconSvg,
  description: 'Manage signatures, agreements, and documents in DocuSign.',
  sidebarSections: [],
  accountTypes: [
    {
      id: 'default',
      accountDescription: 'Production Account',
      scheme: AuthenticationScheme.OAUTH,
    },
    {
      id: 'development',
      accountDescription: 'Development Account',
      scheme: AuthenticationScheme.OAUTH,
      oauthParameters: [
        {
          key: 'development',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    },
  ],
};

export default authConfig;
