import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  ComboInputDataSource,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

const teamsCacheKey = 'teams';

export const getTeams: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: teamsCacheKey,
  title: 'Teams',
  hideFromConnectFieldTypes: true,
  refreshDependencies: [],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return (
      response?.result.output?.map((team: any) => ({
        label: team.displayName,
        value: team.id,
      })) ?? []
    );
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_TEAMS,
      intent: Intent.TEAM_LIST,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getChannels: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'channels',
  title: 'Channel',
  subtitle: 'Channel Workspace channel to send messages in',
  hideFromConnectFieldTypes: true,
  refreshDependencies: ['teamId'],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response
      ? response.result.output.map((channel: any) => ({
          label: channel.displayName,
          value: `${channel.id}`,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const teamId =
      pickValueSourceByKey(options.actionParameters, 'teamId') ??
      // Fallback on cacheKey for getRecordType, the underlying data source
      pickValueSourceByKey(options.actionParameters, teamsCacheKey);
    return {
      actionType: Action.MICROSOFT_TEAMS,
      intent: Intent.CHANNEL_LIST,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'teamId',
          source: {
            type: 'VALUE',
            value: teamId?.value,
          },
        },
      ],
    };
  },
};

export const getChats: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'chats',
  title: 'Member',
  subtitle: 'Workspace member to send chat messages to',
  refreshDependencies: [],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response
      ? response.result.output.map((chat: any) => ({
          label: chat.topic,
          value: `${chat.chatType}|${chat.id}`,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MICROSOFT_TEAMS,
      intent: Intent.MEMBER_LIST,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const teamChannelComboInput: ComboInputDataSource = {
  type: DataSourceType.COMBO_INPUT,
  id: 'teamChannelComboInput',
  title: 'Channel',
  subtitle: 'Channel Workspace channel to send messages in',
  mainInputSource: getTeams,
  dependentInputSource: getChannels,
};
