import React from 'react';

import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { JIRA_OPERATORS } from '../shared/types';

const issueKeyInput: SidebarInput = {
  id: 'issueKey',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Issue Key',
  placeholder: 'TEST-1234',
  required: true,
};

const projectInput: SidebarInput = {
  id: 'project',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Project',
  subtitle:
    'The project which the issue belongs to. Defaults to the user’s first project if not provided. Use Connect Portal Workflow Settings to allow users to select a Project.',
  placeholder: '{{settings.project}}',
  required: false,
};

const jqlDocLink = (
  <a
    href="https://confluence.atlassian.com/jiracoreserver073/advanced-searching-861257209.html#Advancedsearching-ConstructingJQLqueries"
    target="_blank"
    rel="noreferrer"
  >
    Jira Query Language (JQL)
  </a>
);

const connectConfig: ActionConfig = {
  actionType: Action.JIRA,
  name: 'Jira',
  description:
    'Connect to your users’ Jira Software account to access, create, and update their issues or projects in Jira.',
  icon: iconSvg,
  provider: ProviderType.JIRA,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.CREATE_ISSUE,
              label: 'Create Issue',
            },
            {
              value: Intent.UPDATE_ISSUE,
              label: 'Update Issue',
            },
            {
              value: Intent.GET_ISSUE_BY_KEY,
              label: 'Get Issue',
            },
            {
              value: Intent.FILTER_ISSUES,
              label: 'Search Issues',
            },
            {
              value: Intent.SEARCH_BY_JQL,
              label: 'Search Issues by JQL Query',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_ISSUE:
        case Intent.UPDATE_ISSUE:
          return {
            inputs: [
              ...(parameters.intent === Intent.UPDATE_ISSUE ? [issueKeyInput] : []),
              {
                id: 'summary',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Summary',
                subtitle: 'A brief one-line summary of the issue.',
                placeholder: 'The printer stopped working',
                required: parameters.intent === Intent.CREATE_ISSUE,
              },
              ...(parameters.intent === Intent.CREATE_ISSUE ? [projectInput] : []),
              {
                id: 'issueType',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Issue type',
                subtitle:
                  parameters.intent === Intent.CREATE_ISSUE
                    ? 'Defaults to Task if not provided.'
                    : '' +
                      'Use Connect Portal Workflow Settings to allow users to select an Issue Type.',
                placeholder: '{{settings.issueType}}',
                required: false,
              },
              {
                id: 'jiraIssueStatus',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Status',
                subtitle:
                  parameters.intent === Intent.CREATE_ISSUE
                    ? 'Defaults to the project’s first status if not provided.'
                    : '' +
                      'Use Connect Portal Workflow Settings to allow users to select a Status.',
                placeholder: '{{settings.status}}',
                required: false,
              },
              {
                id: 'assignee',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Assignee',
                subtitle:
                  parameters.intent === Intent.CREATE_ISSUE
                    ? 'Defaults to the authenticated user if not provided.'
                    : '' +
                      'Use Connect Portal Workflow Settings to allow users to select an Assignee.',
                placeholder: '{{settings.assignee}}',
                required: false,
              },
              {
                id: 'description',
                type: SidebarInputType.TextArea,
                lines: 2,
                title: 'Description',
                subtitle: 'A detailed description of the issue.',
                required: false,
              },
              {
                id: 'additionalFields',
                title: 'Additional Fields',
                subtitle:
                  'Specify any other fields that should be included in JSON below. Use Connect Portal Workflow Settings to allow users to select which Issue Fields to update.',
                type: SidebarInputType.TextArea,
                placeholder: `{
    "{{settings.ExternalID}}": "..."
}
                `,
                lines: 4,
                required: false,
              },
            ],
          };
        case Intent.GET_ISSUE_BY_KEY:
          return {
            inputs: [issueKeyInput],
          };
        case Intent.FILTER_ISSUES:
          return {
            inputs: [
              {
                id: 'jqlQuery',
                type: SidebarInputType.Conditional,
                title: 'Filter Search',
                subtitle: 'Search for issue that match specified filters.',
                placeholder: 'field',
                supportedOperators: JIRA_OPERATORS,
                required: false,
              },
              {
                id: 'limit',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Limit results',
                subtitle:
                  'Limit the maximum number of issues to return. Defaults to 10 if left blank.',
                required: false,
              },
            ],
          };

        case Intent.SEARCH_BY_JQL:
          return {
            inputs: [
              {
                id: 'jqlQuery',
                title: 'JQL Query',
                type: SidebarInputType.Code,
                language: 'jql',
                lines: 4,
                useLightTheme: true,
                subtitle: <>Write a query using {jqlDocLink}.</>,
                required: true,
                placeholder: `project = {{settings.project}} 
AND status = {{settings.status}}`,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};
export default connectConfig;
