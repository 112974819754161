import { ILoggedInUser, OnboardingStage } from '@shared/entities/sdk/user/user.interface';

import { Action, AuthState } from '../types';

export const initialState: AuthState = {
  newUser: false,
  leadQualificationLoading: false,
  leadQualificationErrorMessage: undefined,
  startTutorialRequestLoading: false,
  startTutorialRequestErrorMessage: undefined,
  loginLoading: false,
  loginError: false,
  loginErrorMessage: undefined,
  signupLoading: false,
  signupError: false,
  signupErrorMessage: undefined,
  updateErrorMessage: undefined,
  resetPasswordError: undefined,
  accessToken: undefined,
  user: undefined,
  getMeFailed: false,
  resetPasswordLoading: false,
  usage: undefined,
  usageLoading: false,
  usageErrorMessage: undefined,
};

export function handler(state: AuthState = initialState, action: Action): AuthState {
  switch (action.type) {
    case 'LOGIN_START':
    case 'GET_ME_UPDATE_START':
      return {
        ...state,
        loginLoading: true,
      };

    case 'LOGIN_COMPLETE':
      return {
        ...state,
        loginLoading: false,
        loginError: false,
        loginErrorMessage: undefined,
        accessToken: action.accessToken,
        user: action.user,
      };

    case 'LOGIN_ERROR':
      return {
        ...state,
        loginLoading: false,
        loginError: true,
        loginErrorMessage: action.message,
      };

    case 'SIGNUP_START':
      return {
        ...state,
        signupLoading: true,
        signupError: false,
      };

    case 'SIGNUP_COMPLETE':
      return {
        ...state,
        newUser: true,
        signupLoading: false,
        signupErrorMessage: undefined,
        accessToken: action.accessToken,
        user: action.user,
      };

    case 'SIGNUP_ERROR':
      return {
        ...state,
        signupLoading: false,
        signupError: true,
        signupErrorMessage: action.message,
      };

    case 'GET_ME_COMPLETE':
    case 'GET_ME_UPDATE_COMPLETE':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
        getMeFailed: false,
      };

    case 'GET_ME_ERROR':
      return {
        ...state,
        user: undefined,
        getMeFailed: true,
      };

    case 'GET_ME_UPDATE_ERROR':
      return {
        ...state,
        updateErrorMessage: action.payload.message,
      };

    case 'LOGOUT':
      return {
        ...state,
        user: undefined,
        accessToken: undefined,
      };

    case 'RESET_PASSWORD_START':
      return {
        ...state,
        resetPasswordLoading: true,
      };

    case 'RESET_PASSWORD_ERROR':
      return {
        ...state,
        resetPasswordError: action.message,
        resetPasswordLoading: false,
      };

    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        resetPasswordError: undefined,
        resetPasswordLoading: false,
      };
    case 'GET_ACCOUNT_USAGE_START':
      return {
        ...state,
        usageLoading: true,
        usageErrorMessage: undefined,
      };

    case 'GET_ACCOUNT_USAGE_SUCCESS':
      return {
        ...state,
        usage: action.usage,
        usageLoading: false,
        usageErrorMessage: undefined,
      };

    case 'GET_ACCOUNT_USAGE_FAILURE':
      return {
        ...state,
        usageLoading: false,
        usageErrorMessage: action.message,
      };

    case 'LEAD_QUALIFICATION_SUBMIT_START':
      return {
        ...state,
        leadQualificationLoading: true,
        leadQualificationErrorMessage: undefined,
      };

    case 'LEAD_QUALIFICATION_SUBMIT_SUCCESS':
      return {
        ...state,
        leadQualificationLoading: false,
        leadQualificationErrorMessage: undefined,
        user: {
          ...state.user,
          ...action.updatedUser,
        },
      };

    case 'LEAD_QUALIFICATION_SUBMIT_FAILURE':
      return {
        ...state,
        leadQualificationLoading: false,
        leadQualificationErrorMessage: action.message,
      };

    case 'START_TUTORIAL_REQUEST_START':
      return {
        ...state,
        startTutorialRequestLoading: true,
        startTutorialRequestErrorMessage: undefined,
      };

    case 'START_TUTORIAL_REQUEST_SUCCESS':
      return {
        ...state,
        startTutorialRequestLoading: false,
        startTutorialRequestErrorMessage: undefined,
        startTutorialRequestReady: true,
        user: {
          ...(state.user as ILoggedInUser),
          onboardingStage: OnboardingStage.IN_TUTORIAL,
        },
      };

    case 'START_TUTORIAL_REQUEST_FAILURE':
      return {
        ...state,
        startTutorialRequestLoading: false,
        startTutorialRequestErrorMessage: action.message,
        startTutorialRequestReady: false,
      };

    case 'SKIP_TUTORIAL_REQUEST_SUCCESS':
      return {
        ...state,
        user: {
          ...(state.user as ILoggedInUser),
          onboardingStage: OnboardingStage.COMPLETED_SKIPPED_TUTORIAL,
        },
      };

    case 'COMPLETE_TUTORIAL_REQUEST_SUCCESS':
      return {
        ...state,
        user: {
          ...(state.user as ILoggedInUser),
          onboardingStage: OnboardingStage.COMPLETED,
        },
      };

    default:
      return state;
  }
}
