import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

import { getOpportunityStages, getSequences } from './sources';

//account Inputs
export const accountIdInput: SidebarInput = {
  id: 'accountId',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Account ID',
};

export const accountNameInput: SidebarInput = {
  id: 'accountName',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Account name',
};

export const websiteURLInput: SidebarInput = {
  id: 'websiteUrl',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Website URL',
};

export const companyTypeInput: SidebarInput = {
  id: 'companyType',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Company type',
  subtitle: 'A description of the company’s type (e.g. "Public Company").',
};

export const industryInput: SidebarInput = {
  id: 'industry',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Industry',
};

export const numberOfEmployeesInput: SidebarInput = {
  id: 'numberOfEmployees',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Number of employees',
};

export const customIdInput: SidebarInput = {
  id: 'customId',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Custom ID',
  subtitle: 'A custom ID for the account, often referencing an ID in an external system.',
};

// opportunities

export const opportunityNameInput: SidebarInput = {
  id: 'opportunityName',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Opportunity Name',
  lines: 1,
};

export const amountInput: SidebarInput = {
  id: 'amount',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Amount',
  lines: 1,
};

export const externalIdInput: SidebarInput = {
  id: 'externalId',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'External ID',
  subtitle: 'A custom ID for the prospect, often referencing an ID in an external system.',
  lines: 1,
};

export const opportunityStageInput: SidebarInput = {
  id: 'opportunityStage',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Opportunity stage',
  lines: 1,
  placeholder: '{{settings.opportunityStage}}',
  subtitle: 'Use Connect Portal Workflow Settings to allow users to select an Opportunity Stage.',
};

export const opportunityStageDropdownInput: SidebarInput = {
  id: 'opportunityStage',
  type: SidebarInputType.NativeDynamicEnumInput,
  required: false,
  title: 'Opportunity stage',
  getValues: getValuesByCacheKey,
  placeholder: 'Select an opportunity stage',
  source: getOpportunityStages,
};

export const closeDateInput: SidebarInput = {
  id: 'closeDate',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Close date',
  lines: 1,
  placeholder: 'YYYY-MM-DD',
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Description',
  lines: 1,
};

export const opportunityIdInput: SidebarInput = {
  id: 'opportunityId',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Opportunity ID',
  subtitle: 'The ID of the opportunity to update.',
  lines: 1,
};

const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.NumberEquals,
  Operator.NumberGreaterThan,
  Operator.NumberLessThan,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberLessThanOrEqualTo,
  Operator.DateTimeEquals,
  Operator.DateTimeBefore,
  Operator.DateTimeAfter,
];

export const opportunityFilterInputOauth: SidebarInput = {
  id: 'opportunityFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get opportunities that match the specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Probability', value: 'probability' },
    { label: 'Risk Score', value: 'riskScore' },
    { label: 'Name', value: 'name' },
    { label: 'Map Status', value: 'mapStatus' },
    { label: 'Created At', value: 'createdAt' },
    { label: 'Updated At', value: 'updatedAt' },
  ],
};

// prospect inputs

export const companyInput: SidebarInput = {
  id: 'company',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Company',
  lines: 1,
};

export const occupationInput: SidebarInput = {
  id: 'occupation',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Occupation',
  lines: 1,
};

export const prospectIdInput: SidebarInput = {
  id: 'prospectId',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Prospect ID',
  subtitle: 'The ID of the prospect to update.',
  lines: 1,
};

export const prospectsFilterInputOauth: SidebarInput = {
  id: 'prospectsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get prospects that match the specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Emails', value: 'emails' },
    { label: 'Engaged Score', value: 'engagedScore' },
    { label: 'Last Name', value: 'lastName' },
    { label: 'Title', value: 'title' },
    { label: 'Map Status', value: 'mapStatus' },
    { label: 'Created At', value: 'createdAt' },
    { label: 'Updated At', value: 'updatedAt' },
    { label: 'Engaged At', value: 'engagedAt' },
  ],
};

//Sequence

export const sequenceInput: SidebarInput = {
  id: 'sequence',
  type: SidebarInputType.TextArea,
  required: true,
  title: 'Sequence',
  subtitle:
    'The Sequence that the prospect should be added to. Use Connect Portal Workflow Settings to allow users to select a Sequence. ',
  placeholder: '{{settings.sequence}}',
  lines: 1,
};

export const sequenceDropdownInput: SidebarInput = {
  id: 'sequence',
  type: SidebarInputType.NativeDynamicEnumInput,
  required: true,
  title: 'Sequence',
  placeholder: 'Select a Sequence',
  source: getSequences,
  getValues: getValuesByCacheKey,
};

export const nameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  subtitle: 'Full name of contact/organization',
};

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'First name',
  required: false,
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Last name',
  required: false,
};

export const emailInput: SidebarInput = {
  id: 'email',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Email address',
  required: false,
};

export const phoneInput: SidebarInput = {
  id: 'phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Phone number',
  required: false,
};

export const accountsFilterInputOauth: SidebarInput = {
  id: 'accountsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get Accounts that match the specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Custom Id', value: 'customId' },
    { label: 'Domain', value: 'domain' },
    { label: 'Name', value: 'name' },
    { label: 'Created At', value: 'createdAt' },
    { label: 'Updated At', value: 'updatedAt' },
  ],
};

export const paymentsFilterInputOauth: SidebarInput = {
  id: 'paymentsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get payments that match the specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Id', value: 'Id' },
    { label: 'PaymentType', value: 'name' },
    { label: 'Status', value: 'status' },
    { label: 'Invoice Id', value: 'Invoice.InvoiceId' },
    { label: 'Date', value: 'Date' },
    { label: 'Reference', value: 'Reference' },
  ],
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Accounts',
          items: [
            {
              value: Intent.CREATE_ACCOUNT,
              label: 'Create Account',
            },
            {
              value: Intent.UPDATE_ACCOUNT,
              label: 'Update Account',
            },
            {
              value: Intent.GET_ACCOUNTS,
              label: 'Get Accounts',
            },
            {
              value: Intent.GET_ACCOUNT_BY_ID,
              label: 'Get Account by ID',
            },
          ],
        },
        {
          title: 'Opportunities',
          items: [
            {
              value: Intent.CREATE_OPPORTUNITY,
              label: 'Create Opportunity',
            },
            {
              value: Intent.UPDATE_OPPORTUNITY,
              label: 'Update Opportunity',
            },
            {
              value: Intent.GET_OPPORTUNITIES,
              label: 'Get Opportunities',
            },
            {
              value: Intent.GET_OPPORTUNITY_BY_ID,
              label: 'Get Opportunity by ID',
            },
          ],
        },
        {
          title: 'Prospects',
          items: [
            {
              value: Intent.CREATE_PROSPECT,
              label: 'Create Prospect',
            },
            {
              value: Intent.UPDATE_PROSPECT,
              label: 'Update Prospect',
            },
            {
              value: Intent.GET_PROSPECTS,
              label: 'Get Prospects',
            },
            {
              value: Intent.GET_PROSPECT_BY_ID,
              label: 'Get Prospect by ID',
            },
          ],
        },
        {
          title: 'Sequences',
          items: [
            {
              value: Intent.ADD_PROSPECTS_TO_SEQUENCE,
              label: 'Add Prospect to Sequence',
            },
          ],
        },
      ],
    },
  ],
};
