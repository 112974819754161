import { Stripe } from 'stripe';

export type BillingPlanMetadata = {
  plan: BillingPlan;
  tasksLimitCount: string;
  gracePeriodMinutes: string;
  integrationsLimitCount?: string;
  connectUsersLimitCount?: string;
};

export enum BillingPlan {
  // Classic
  ClassicFree = 'free',
  ClassicStarter = 'starter',
  ClassicBusiness = 'business',
  ClassicPremium = 'premium',
  ClassicEnterprise = 'enterprise',

  // Connect
  ConnectTrial = 'connect_trial',
  ConnectBasic = 'basic',
  ConnectPro = 'pro',
  ConnectEnterprise = 'connect_enterprise',
}

export type ClassicBillingPlan =
  | BillingPlan.ClassicFree
  | BillingPlan.ClassicStarter
  | BillingPlan.ClassicBusiness
  | BillingPlan.ClassicPremium
  | BillingPlan.ClassicEnterprise;

export type ConnectNonTrialBillingPlan =
  | BillingPlan.ConnectBasic
  | BillingPlan.ConnectPro
  | BillingPlan.ConnectEnterprise;

export type ConnectBillingPlan = BillingPlan.ConnectTrial | ConnectNonTrialBillingPlan;

export const ConnectBillingPlanList: ConnectBillingPlan[] = [
  BillingPlan.ConnectTrial,
  BillingPlan.ConnectBasic,
  BillingPlan.ConnectPro,
  BillingPlan.ConnectEnterprise,
];

export type ConnectBillingPlanPriceMap = Record<ConnectNonTrialBillingPlan, Stripe.Plan[]>;

/**
 * used to signify a period of task usage in months
 * for yearly billing plans, it represents the active month start & end date
 * as opposed to when the subscription started or ends
 */
export type BillingPeriod = {
  /**
   * date representing the start of the billing period
   */
  beginning: Date;

  /**
   * date representing the end of the billing period
   */
  ending: Date;
};

export type AggregatedBillingInfo = {
  customer: Stripe.Customer;
  subscription: Stripe.Subscription;
  plan: Stripe.Plan;
  product: Stripe.Product;
};

export type AggregatedClassicBillingInfo = AggregatedBillingInfo & {
  metadata: BillingPlanMetadata;
};

export type AggregatedConnectBillingInfo = AggregatedBillingInfo & {
  metadata: BillingPlanMetadata;
};

//https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses
export enum StripeSubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trialing',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

// https://stripe.com/docs/api/events/types
export enum StripeEventType {
  CUSTOMER_SUBSCRIPTION_CREATED = 'customer.subscription.created',
  CUSTOMER_SUBSCRIPTION_UPDATED = 'customer.subscription.updated',
  CUSTOMER_SUBSCRIPTION_DELETED = 'customer.subscription.deleted',
  CUSTOMER_SUBSCRIPTION_TRIAL_WILL_END = 'customer.subscription.trial_will_end',
}

// List of valid subscription status. Meaning we consider them as active
export const ActiveStripeSubscriptionStatuses: StripeSubscriptionStatus[] = [
  StripeSubscriptionStatus.ACTIVE,
  StripeSubscriptionStatus.TRIAL,
  StripeSubscriptionStatus.INCOMPLETE,
  StripeSubscriptionStatus.PAST_DUE,
];

// List of invalid subscription status. Meaning we consider them as cancelled
export const InactiveStripeSubscriptionStatuses: StripeSubscriptionStatus[] = [
  StripeSubscriptionStatus.UNPAID,
  StripeSubscriptionStatus.CANCELED,
  StripeSubscriptionStatus.INCOMPLETE_EXPIRED,
];
