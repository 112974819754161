import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * @param condition
 * @returns condition for filter dynamics finance
 */

export default function conditionsToFilterDynamicsFinanceExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterDynamicsFinanceExpression(condition);
      })
      .join(condition.join === 'OR' ? ' or ' : ' and ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} eq '${innerCondition.argument}'`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable} ne '${innerCondition.argument}'`;
      case Operator.NumberEquals:
        return `${innerCondition.variable} eq ${innerCondition.argument}`;
      case Operator.NumberDoesNotEqual:
        return `${innerCondition.variable} ne ${innerCondition.argument}`;
      case Operator.NumberGreaterThan:
        return `${innerCondition.variable} gt ${innerCondition.argument}`;
      case Operator.NumberLessThan:
        return `${innerCondition.variable} lt ${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} gt ${new Date(innerCondition.argument).toISOString()}`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} lt ${new Date(innerCondition.argument).toISOString()}`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} eq ${new Date(innerCondition.argument).toISOString()}`;
      case Operator.StringContains:
        return `${innerCondition.variable} eq '*${innerCondition.argument}*'`;
      //using boolan operator for yes or no values here in dynamics finance, in future if we want to use real boolean
      //values then we can change these values
      case Operator.BooleanTrue:
        return `${innerCondition.variable} eq Microsoft.Dynamics.DataEntities.NoYes'Yes'`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} eq Microsoft.Dynamics.DataEntities.NoYes'No'`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Dynamics Finance`);
    }
  }
  return '';
}
