import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class TeamError extends ParagonError {
  name: string = 'TeamError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class UnauthorizedTeamAccessError extends TeamError {
  name: string = 'UnauthorizedTeamAccessError';

  constructor({
    teamId,
    path,
    method,
    userId,
    organizationId,
    projectId,
  }: {
    teamId: string;
    path: string;
    method: string;
    userId?: string;
    organizationId?: string;
    projectId?: string;
  }) {
    super({
      code: ERROR_CODE.UNAUTHORIZED_TEAM_ACCESS,
      httpStatus: HttpStatus.FORBIDDEN,
      message: "You don't have permission to do this.",
      meta: {
        teamId,
        path,
        method,
        userId,
        organizationId,
        projectId,
      },
    });
  }
}

export class TeamNotFoundFromIdError extends TeamError {
  name: string = 'TeamNotFoundFromIdError';

  constructor(meta: {
    teamId: string;
    path?: string;
    method?: string;
    userId?: string;
    organizationId?: string;
    projectId?: string;
  }) {
    super({
      code: ERROR_CODE.TEAM_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find team.',
      meta,
    });
  }
}

export class TeamNotFoundForEnterpriseInstallationError extends TeamError {
  name: string = 'TeamNotFoundForEnterpriseInstallationError';

  constructor() {
    super({
      code: ERROR_CODE.TEAM_NOT_FOUND_FOR_ENTERPRISE_INSTALLATION,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find team for enterprise installation.',
      meta: {},
    });
  }
}

export class InviteNotFoundFromIdError extends TeamError {
  name: string = 'InviteNotFoundFromIdError';

  constructor(meta: { inviteId: string }) {
    super({
      code: ERROR_CODE.INVITE_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.FORBIDDEN,
      message: 'Unable to find invite.',
      meta,
    });
  }
}

export class InviteExpiredError extends TeamError {
  name: string = 'InviteExpiredError';

  constructor(meta: { inviteId: string }) {
    super({
      code: ERROR_CODE.INVITE_EXPIRED,
      httpStatus: HttpStatus.FORBIDDEN,
      message: "This invite has expired. Please contact your team's admin to request a new one.",
      meta,
    });
  }
}

export class InviteAlreadyAcceptedError extends TeamError {
  name: string = 'InviteAlreadyAcceptedError';

  constructor(meta: { inviteId: string }) {
    super({
      code: ERROR_CODE.INVITE_ALREADY_ACCEPTED,
      httpStatus: HttpStatus.FORBIDDEN,
      message:
        "This invite has already been accepted. Try logging into your account or resetting your password. Otherwise contact your team's admin to request a new one.",
      meta,
    });
  }
}

export class ExistingUserJoiningTeamError extends TeamError {
  name: string = 'ExistingUserJoiningTeamError';

  constructor(meta: { userId: string; teamId: string }) {
    super({
      code: ERROR_CODE.EXISTING_USER_JOINING_TEAM,
      httpStatus: HttpStatus.FORBIDDEN,
      message:
        'A Paragon account already exists with this email. Please request another invite with a different email or contact support.',
      meta,
    });
  }
}

export class UserInTeamHasEmailError extends TeamError {
  name: string = 'UserInTeamHasEmailError';

  constructor(meta: { organizationId: string; emails: string[] }) {
    super({
      code: ERROR_CODE.USER_IN_TEAM_HAS_EMAIL,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'A user on your team already exists with this email.',
      meta,
    });
  }
}

export class TeamMemberNotFoundFromIdError extends TeamError {
  name: string = 'TeamMemberNotFoundFromIdError';

  constructor(meta: { teamMemberId: string }) {
    super({
      code: ERROR_CODE.TEAM_MEMBER_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.FORBIDDEN,
      message: 'Unable to find team member.',
      meta,
    });
  }
}
