import { ISigningKey } from '@shared/entities/sdk/signingKey/signingKey.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<ISigningKey> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

/**
 * @summary reducer for signing keys
 * @param state
 * @param action
 */
export function handler(
  state: EntitiesState<ISigningKey> = initialState,
  action: Action,
): EntitiesState<ISigningKey> {
  switch (action.type) {
    case 'SIGNING_KEYS_FETCH_START':
    case 'SIGNING_KEY_CONFIG_CREATE_START':
    case 'SIGNING_KEY_CONFIG_UPDATE_START':
      return {
        ...state,
        processing: true,
      };
    case 'SIGNING_KEYS_FETCH_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...indexBy('id', action.payload),
        },
      };
    case 'SIGNING_KEY_CONFIG_CREATE_SUCCESS':
    case 'SIGNING_KEY_CONFIG_UPDATE_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
      };
    case 'SIGNING_KEYS_FETCH_FAILURE':
    case 'SIGNING_KEY_CONFIG_CREATE_FAILURE':
    case 'SIGNING_KEY_CONFIG_UPDATE_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    default:
      return state;
  }
}
