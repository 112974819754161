import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Stripe } from 'stripe';

import { ConnectBillingPlan } from '@shared/types/sdk/stripe';

import { pushRoute, sanitizePlan } from '..';
import {
  checkoutPayment,
  getConnectSubscription,
  getPreviewInvoice,
  updateCard,
} from '../../store/operations/billing';
import { State, useDispatch, useSelector } from '../../store/types';

export enum CardProcessingState {
  INITIAL = 'initial',
  SAVING = 'saving',
  SAVED = 'saved',
  UPDATING = 'updating',
  UPDATED = 'updated',
}

/**
 *
 * @param projectId
 */
export const useUpdateCard = (
  projectId: string | undefined,
): [
  updateCard: () => Promise<void>,
  cardProcessingState: CardProcessingState,
  cardError: string | undefined,
] => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [cardState, setCardState] = useState<CardProcessingState>(CardProcessingState.INITIAL);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const stripeElements = useElements();
  const handleUpdateCard = async () => {
    if (!projectId || !stripe || !stripeElements) {
      return;
    }
    setCardState(CardProcessingState.UPDATING);
    const cardElement = stripeElements.getElement(CardElement);
    if (!cardElement) {
      setCardState(CardProcessingState.INITIAL);
      setError('Card Element not detected.');
      return;
    }

    const { error, token } = await stripe.createToken(cardElement);
    if (!error && token) {
      try {
        await updateCard(projectId, token.id);
        setCardState(CardProcessingState.UPDATED);
        await getConnectSubscription(projectId)(dispatch);
        setTimeout(() => {
          setCardState(CardProcessingState.INITIAL);
        }, 2000);
      } catch (error) {
        setCardState(CardProcessingState.INITIAL);
        setError(error?.message);
      }
    } else {
      setCardState(CardProcessingState.INITIAL);
      setError(error?.message ?? 'Error Occured while updating Card.');
    }
  };
  return [handleUpdateCard, cardState, error];
};

export enum CheckoutProcessingState {
  INITIAL = 'initial',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

/**
 *
 * @param projectId
 * @param priceId
 * @param quantity
 * @param isCardRequired
 */
export const useCheckout = (
  projectId: string | undefined,
  priceId: string | undefined,
  quantity: number,
  isCardRequired: boolean,
): [
  handleCheckout: () => Promise<void>,
  checkoutProcessingState: CheckoutProcessingState,
  checkoutError: string | undefined,
  cardError: string | undefined,
] => {
  const [checkoutState, setCheckoutState] = useState<CheckoutProcessingState>(
    CheckoutProcessingState.INITIAL,
  );
  const [cardError, setCardError] = useState<string | undefined>(undefined);
  const [checkoutError, setCheckoutError] = useState<string | undefined>(undefined);
  const stripe = useStripe();
  const stripeElements = useElements();
  const router = useRouter();
  const dispatch = useDispatch();
  const handleCheckout = async () => {
    if (!projectId || !priceId || !stripe || !stripeElements) {
      return;
    }
    let token;
    setCheckoutState(CheckoutProcessingState.PROCESSING);
    if (isCardRequired) {
      const cardElement = stripeElements.getElement(CardElement);

      if (!cardElement) {
        setCardError('Card Element not detected.');
        setCheckoutState(CheckoutProcessingState.INITIAL);
        setCheckoutError(undefined);
        return;
      }

      const { error, token: cardToken } = await stripe.createToken(cardElement);
      if (error) {
        setCardError(error.message);
        setCheckoutState(CheckoutProcessingState.INITIAL);
        setCheckoutError(undefined);
        return;
      }
      token = cardToken;
    }
    setCardError(undefined);
    setCheckoutError(undefined);
    try {
      await checkoutPayment(projectId, priceId, quantity, token?.id);
      await getConnectSubscription(projectId)(dispatch);
      await pushRoute(router, '/settings', [
        { path: 'connect', key: 'connect' },
        { path: 'projects', key: 'projectId', value: projectId },
        { path: 'settings', key: 'settings' },
        { path: 'billing', key: 'billing' },
        { path: 'info', key: 'info' },
      ]);
    } catch (error) {
      setCheckoutError(error?.message);
    }
    setCheckoutState(CheckoutProcessingState.COMPLETED);
  };
  return [handleCheckout, checkoutState, checkoutError, cardError];
};

/**
 *
 * @param priceId
 * @param quantity
 * @param newPriceId
 * @param newQuantity
 */
export const useUpcomingInvoice = (
  projectId: string | undefined,
  priceId: string | undefined,
  quantity: number | undefined | null,
  newPriceId: string | undefined,
  newQuantity: number | undefined,
): [upcomingInvoice: Stripe.Invoice | null] => {
  const [invoice, setInvoice] = useState<Stripe.Invoice | null>(null);
  useEffect(() => {
    let effectValid = true;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (
        projectId &&
        priceId &&
        quantity &&
        newPriceId &&
        newQuantity &&
        (priceId != newPriceId || quantity != newQuantity)
      ) {
        try {
          const upcomingInvoice = await getPreviewInvoice(projectId, newPriceId, newQuantity);
          if (!effectValid) {
            return;
          }
          setInvoice(upcomingInvoice);
        } catch (error) {
          if (!effectValid) {
            return;
          }
          setInvoice(null);
        }
      } else {
        invoice != null && setInvoice(null);
      }
    })();

    return () => {
      effectValid = false;
    };
  }, [projectId, priceId, quantity, newPriceId, newQuantity]);
  return [invoice];
};

/**
 * @summary as all these are used commonly so return those from custom hook
 */
export const useConnectBillingInfo = (): [
  Stripe.Subscription | undefined,
  Stripe.Plan | null | undefined,
  ConnectBillingPlan,
] => {
  const connectPlansMap = useSelector((state: State) => state.billing.plans);
  const connectSubscription = useSelector((state: State) => state.billing.subscription);
  const currentPlan = connectSubscription?.plan && sanitizePlan(connectSubscription?.plan);

  const planName: ConnectBillingPlan | undefined =
    connectPlansMap &&
    (Object.keys(connectPlansMap) as ConnectBillingPlan[]).find((planName: ConnectBillingPlan) =>
      connectPlansMap[planName].find((plan: Stripe.Plan) => plan.id == currentPlan?.id),
    );

  return [
    connectSubscription,
    currentPlan,
    planName || ((currentPlan?.product as Stripe.Product)?.metadata['plan'] as ConnectBillingPlan),
  ];
};
