import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import { POWER_BI_OAUTH_BASE_URL } from '../shared/constants';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'App.Read.All'
  | 'Capacity.Read.All'
  | 'Capacity.ReadWrite.All'
  | 'Content.Create'
  | 'Dashboard.Read.All'
  | 'Dashboard.ReadWrite.All'
  | 'Dataflow.Read.All'
  | 'Dataflow.ReadWrite.All'
  | 'Dataset.Read.All'
  | 'Dataset.ReadWrite.All'
  | 'Gateway.Read.All'
  | 'Gateway.ReadWrite.All'
  | 'Pipeline.Deploy'
  | 'Pipeline.Read.All'
  | 'Pipeline.ReadWrite.All'
  | 'Report.Read.All'
  | 'Report.ReadWrite.All'
  | 'StorageAccount.Read.All'
  | 'StorageAccount.ReadWrite.All'
  | 'Tenant.Read.All'
  | 'Tenant.ReadWrite.All'
  | 'UserState.ReadWrite.All'
  | 'Workspace.Read.All'
  | 'Workspace.ReadWrite.All';

export const scopes: Record<ScopeName, ScopeValue> = {
  'App.Read.All': {
    label: 'App.Read.All',
    name: 'App.Read.All',
    description: 'View all Power BI apps',
  },
  'Capacity.Read.All': {
    label: 'Capacity.Read.All',
    name: 'Capacity.Read.All',
    description: 'View all capacities',
  },
  'Capacity.ReadWrite.All': {
    label: 'Capacity.ReadWrite.All',
    name: 'Capacity.ReadWrite.All',
    description: 'Read and write all capacities',
  },
  'Content.Create': {
    label: 'Content.Create',
    name: 'Content.Create',
    description: 'Create Content',
  },
  'Dashboard.Read.All': {
    label: 'Dashboard.Read.All',
    name: 'Dashboard.Read.All',
    description: 'View all dashboards',
  },
  'Dashboard.ReadWrite.All': {
    label: 'Dashboard.ReadWrite.All',
    name: 'Dashboard.ReadWrite.All',
    description: 'Read and write all dashboards',
  },
  'Dataflow.Read.All': {
    label: 'Dataflow.Read.All',
    name: 'Dataflow.Read.All',
    description: 'View all dataflows',
  },
  'Dataflow.ReadWrite.All': {
    label: 'Dataflow.ReadWrite.All',
    name: 'Dataflow.ReadWrite.All',
    description: 'Read and write all dataflows',
  },
  'Dataset.Read.All': {
    label: 'Dataset.Read.All',
    name: 'Dataset.Read.All',
    description: 'View all datasets',
  },
  'Dataset.ReadWrite.All': {
    label: 'Dataset.ReadWrite.All',
    name: 'Dataset.ReadWrite.All',
    description: 'Read and Write all datasets',
  },
  'Gateway.Read.All': {
    label: 'Gateway.Read.All',
    name: 'Gateway.Read.All',
    description: 'View All Gateways',
  },
  'Gateway.ReadWrite.All': {
    label: 'Gateway.ReadWrite.All',
    name: 'Gateway.ReadWrite.All',
    description: 'Read and write all gateways',
  },
  'Pipeline.Deploy': {
    label: 'Pipeline.Deploy',
    name: 'Pipeline.Deploy',
    description: 'Deploy in all pipelines',
  },
  'Pipeline.Read.All': {
    label: 'Pipeline.Read.All',
    name: 'Pipeline.Read.All',
    description: 'View all pipelines',
  },
  'Pipeline.ReadWrite.All': {
    label: 'Pipeline.ReadWrite.All',
    name: 'Pipeline.ReadWrite.All',
    description: 'Read and write all pipelines',
  },
  'Report.Read.All': {
    label: 'Report.Read.All',
    name: 'Report.Read.All',
    description: 'View all reports',
    required: true,
  },
  'Report.ReadWrite.All': {
    label: 'Report.ReadWrite.All',
    name: 'Report.ReadWrite.All',
    description: 'Read and write all reports',
    required: true,
  },
  'StorageAccount.Read.All': {
    label: 'StorageAccount.Read.All',
    name: 'StorageAccount.Read.All',
    description: 'View all storage accounts',
  },
  'StorageAccount.ReadWrite.All': {
    label: 'StorageAccount.ReadWrite.All',
    name: 'StorageAccount.ReadWrite.All',
    description: 'Read and write all storage accounts',
  },
  'Tenant.Read.All': {
    label: 'Tenant.Read.All',
    name: 'Tenant.Read.All',
    description: 'View all content in tenant',
  },
  'Tenant.ReadWrite.All': {
    label: 'Tenant.ReadWrite.All',
    name: 'Tenant.ReadWrite.All',
    description: 'Read and write all content in tenant',
  },
  'UserState.ReadWrite.All': {
    label: 'UserState.ReadWrite.All',
    name: 'UserState.ReadWrite.All',
    description: 'Read and write user settings and state',
  },
  'Workspace.Read.All': {
    label: 'Workspace.Read.All',
    name: 'Workspace.Read.All',
    description: 'View all workspaces',
  },
  'Workspace.ReadWrite.All': {
    label: 'Workspace.ReadWrite.All',
    name: 'Workspace.ReadWrite.All',
    description: 'Read and write all workspaces',
  },
};

export const requiredScopes: ScopeName[] = ['Report.Read.All', 'Report.ReadWrite.All'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Power BI',
  description: 'Sync account',
  authUrl: `${POWER_BI_OAUTH_BASE_URL}/authorize`,
  accessTokenUrl: `${POWER_BI_OAUTH_BASE_URL}/token`,
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/powerbi`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://learn.microsoft.com/en-us/rest/api/power-bi/',
};
