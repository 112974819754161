import React from 'react';

import { getValuesByCacheKey, hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { getChannels, getMembers } from './sources';

const slackBlockKitBuilderLink = (
  <>
    <a href="https://app.slack.com/block-kit-builder" target="_blank" rel="noreferrer">
      Block Kit Builder
    </a>
  </>
);

export const authConfig: ActionConfig = {
  actionType: Action.SLACK,
  scheme: AuthenticationScheme.OAUTH,
  provider: ProviderType.SLACK,
  name: 'Connect your Slack account',
  description: 'Add Slack to Paragon',
  icon: iconSvg,
  sidebarSections: [],
};

const config: ActionConfig = {
  actionType: Action.SLACK,
  name: 'Slack',
  description: 'Send a message.',
  icon: iconSvg,
  provider: ProviderType.SLACK,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Connect your Slack account',
          placeholder: 'choose your Slack account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.SLACK,
                supportedTokenTypes: [TokenType.BOT_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.SEND_MESSAGE,
                    label: 'Send message in channel',
                  },
                  {
                    value: Intent.SEND_DIRECT_MESSAGE,
                    label: 'Send DM',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEND_MESSAGE:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    id: 'channel',
                    type: SidebarInputType.TextArea,
                    lines: 1,
                    title: 'Channel',
                    subtitle: (
                      <>
                        Specify a channel name or ID. If the channel name you specify does not
                        exist, we will automatically attempt to create one, if your token has the{' '}
                        <a
                          href="https://api.slack.com/methods/conversations.create"
                          target="_blank"
                          rel="noreferrer"
                        >
                          necessary scopes to create a channel
                        </a>
                        .
                      </>
                    ),
                    required: true,
                  }
                : {
                    id: 'channel',
                    title: 'Channel',
                    type: SidebarInputType.DynamicEnum,
                    placeholder: 'choose a channel or direct message',
                    required: true,
                    source: getChannels,
                    getValues: getValuesByCacheKey,
                  },
            ],
          };
        case Intent.SEND_DIRECT_MESSAGE:
          return {
            inputs: [
              {
                id: 'memberId',
                title: 'Workspace Member',
                type: SidebarInputType.EditableDynamicEnum,
                placeholder: 'choose a user to DM or type {{ to insert a Slack user ID',
                required: true,
                source: getMembers,
                getValues: getValuesByCacheKey,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      if (
        ![Intent.SEND_MESSAGE, Intent.SEND_DIRECT_MESSAGE].includes(parameters.intent as Intent)
      ) {
        return { inputs: [] };
      }

      return {
        inputs: [
          {
            id: 'message',
            type: SidebarInputType.TextArea,
            title: 'Message',
            required: true,
          },
          ...(hasOAuthAppCredential(parameters, credentials)
            ? []
            : ([
                {
                  id: 'asBot',
                  type: SidebarInputType.BooleanInput,
                  title: 'Send as a bot?',
                  subtitle: 'If you choose no, the message will appear to come from you.',
                  required: true,
                  defaultValue: true,
                },
              ] as SidebarInput[])),
          {
            id: 'botName',
            type: SidebarInputType.Text,
            title: 'Bot name',
            subtitle: 'The name of the bot that sends this message. Defaults to Paragon.',
            required: false,
          },
          {
            id: 'botIcon',
            type: SidebarInputType.Text,
            title: 'Bot icon',
            subtitle: 'Can be either an image URL or an emoji available to your team (e.g. :dog:)',
            required: false,
          },
          {
            id: 'blocks',
            type: SidebarInputType.Code,
            title: 'Block Kit JSON',
            subtitle: (
              <>
                Use the Slack {slackBlockKitBuilderLink} to design your message and copy the Payload
                here.
              </>
            ),
            required: false,
            placeholder: `[
  {
    "text": "I am a test message",
    "attachments": [
      {
        "text": "And here’s an attachment!"
      }
    ]
  }
]`,
            useLightTheme: true,
            language: 'json',
          },
          ...(hasOAuthAppCredential(parameters, credentials)
            ? []
            : ([
                {
                  id: 'sendWorkflowLink',
                  type: SidebarInputType.BooleanInput,
                  title: 'Include a link to this workflow?',
                  subtitle:
                    'Adds a link to this Workflow’s Task History at the end of the message.',
                  required: true,
                  defaultValue: true,
                },
              ] as SidebarInput[])),
        ],
      };
    },
  ],
};

export default config;
