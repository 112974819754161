import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  endDateInput,
  filterGroupsInput,
  groupByInput,
  inspectionUrlInput,
  intentSelectorDropdown,
  rowLimitInput,
  searchDataTypeInput,
  siteUrlInput,
  startDateInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.GOOGLE_SEARCH_CONSOLE,
  name: 'Google Search Console',
  description:
    'Connect to your users Google Search Console account to run queries on their Google Search results data.',
  icon: iconSvg,
  sidebarSections: [
    intentSelectorDropdown,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_SITE:
          return { inputs: [siteUrlInput] };
        case Intent.INSPECT_INDEX_INFO:
          return {
            inputs: [
              {
                ...siteUrlInput,
                subtitle:
                  'The Search Console URL for this web property. Use Connect Portal Workflow Settings to allow users to select a Site.',
              },
              inspectionUrlInput,
            ],
          };
        case Intent.QUERY_SEARCH_DATA:
          return {
            inputs: [
              {
                ...siteUrlInput,
                subtitle:
                  'The URL of the site to query search data for. Specify a URL or a Domain Property (e.g., sc-domain:example.com',
              },
              startDateInput,
              endDateInput,
              searchDataTypeInput,
              groupByInput,
              filterGroupsInput,
              rowLimitInput,
            ],
          };
        case Intent.GET_ALL_SITES:
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
