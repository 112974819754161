import cookie from 'js-cookie';
import { NextPage, NextPageContext } from 'next';
import nextCookie from 'next-cookies';
import Router from 'next/router';
import React from 'react';

import { debug } from '@shared/logger/sdk/legacy';
import { UTMParameter } from '@shared/types/sdk/analytics';

import { Context } from '../pages/_app';
import { getMe } from '../store/operations/auth';
import { State } from '../store/types';
import { redirect } from '../utils';
import { REMEMBER_ME_EXPIRY_DAYS } from '../utils/constants';
import { Cookie } from '../utils/cookies';

const authRoutes = ['/login', '/signup'];
let _token: string = '';

export function getToken(): string {
  return _token || cookie.get('token') || '';
}

export async function setTokenCookie(token: string, rememberMe: boolean): Promise<void> {
  _token = token;

  cookie.set('token', token, {
    expires: rememberMe ? REMEMBER_ME_EXPIRY_DAYS : undefined,
  });
}

export async function afterLoginRedirect(url: string = '/', as?: string): Promise<void> {
  const redirectTo = cookie.get('redirectTo');

  if (redirectTo) {
    url = redirectTo;

    cookie.remove('redirectTo');
  }

  await Router.push(url, as);
}

export async function removeTokenCookie(
  ctx: NextPageContext | null,
  queryString: Record<string, any> = {},
): Promise<void> {
  _token = '';
  cookie.remove('token');

  const qs: string = Object.entries(queryString)
    .reduce(
      (qs: string[], current: string[]): string[] => [...qs, `${current[0]}= ${current[1]}`],
      [],
    )
    .join('&');

  const url: string = qs ? `/login?${qs}` : '/login';

  redirect(ctx, url);
}

type WithAuthProps = {
  token?: string;
};

export function withAuth<P extends object>(
  expectLoggedIn: boolean,
  WrappedComponent: NextPage<P>,
): NextPage<P & WithAuthProps> {
  const Wrapper: NextPage<P & WithAuthProps> = (props: P) => {
    return <WrappedComponent {...props} />;
  };

  Wrapper.getInitialProps = async (ctx: Context): Promise<P & WithAuthProps> => {
    const cookies = nextCookie(ctx);
    const { token } = cookies;
    _token = token ? token : '';
    debug('next cookie', { _token, token });

    // We cache the UTM parameters in the cookies + redux for marketing.
    // If they're available, we need to set the initial redux state via a dispatch call.
    try {
      let utmParameters: Partial<Record<UTMParameter, string>> | undefined | string;
      utmParameters = cookies[Cookie.UTMParameters];
      if (typeof utmParameters === 'string') {
        utmParameters = JSON.parse(utmParameters);
      }

      if (utmParameters && Object.keys(utmParameters).length) {
        ctx.store.dispatch({ type: 'NAVIGATE', utmParameters: utmParameters });
      }
    } catch (e) {}

    let state = ctx.store.getState() as State;

    let getMeFailed = !_token;
    if (!state.auth.user && _token && expectLoggedIn) {
      debug('withAuth > no user but token exists expectLoggedIn');
      await getMe(ctx.req)(ctx.store.dispatch);
      state = ctx.store.getState() as State;
      getMeFailed = !state.auth.user || state.auth.getMeFailed;
    }

    if (expectLoggedIn && getMeFailed && !authRoutes.includes(ctx.pathname)) {
      debug('withAuth > redirecting to login', { getMeFailed });
      await removeTokenCookie(ctx);
    } else if (
      (!expectLoggedIn || authRoutes.includes(ctx.pathname)) &&
      !getMeFailed &&
      state.auth.user
    ) {
      debug('withAuth > redirecting to dashboard', { getMeFailed });
      redirect(ctx, '/classic/dashboard');
    }

    // TODO cancel if redirecting
    const componentProps = await WrappedComponent.getInitialProps!(ctx);
    return { ...componentProps, token: _token };
  };

  return Wrapper;
}
