import { Lens } from 'monocle-ts';

import { ISecret } from '@shared/entities/sdk/secret/secret.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<ISecret> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<ISecret> = initialState,
  action: Action,
): EntitiesState<ISecret> {
  switch (action.type) {
    case 'ENVIRONMENT_SECRETS_FETCH_START':
    case 'ENVIRONMENT_SECRETS_CREATION_START':
    case 'ENVIRONMENT_SECRETS_DELETION_START':
      return {
        ...state,
        processing: true,
      };
    case 'ENVIRONMENT_SECRETS_FETCH_SUCCESS':
      return {
        ...Lens.fromProp<EntitiesState<ISecret>>()('entities').modify(
          (entities: Record<string, ISecret>) => {
            return {
              ...entities,
              ...indexBy('id', action.payload),
            };
          },
        )(state),
        processing: false,
      };
    case 'ENVIRONMENT_SECRETS_CREATION_SUCCESS':
      return {
        ...Lens.fromPath<EntitiesState<ISecret>>()(['entities', action.payload.id]).modify(
          (_secret: ISecret) => action.payload,
        )(state),
        processing: false,
      };
    case 'ENVIRONMENT_SECRETS_DELETION_SUCCESS':
      const { [action.id]: value, ...restEntities } = state.entities;
      return {
        ...state,
        processing: false,
        entities: {
          ...restEntities,
        },
      };

    case 'ENVIRONMENT_SECRETS_FETCH_FAILURE':
    case 'ENVIRONMENT_SECRETS_CREATION_FAILURE':
    case 'ENVIRONMENT_SECRETS_DELETION_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'ENVIRONMENT_SECRETS_REMOVE_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: undefined,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
