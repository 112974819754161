import { IBaseEntity } from '@shared/entities/sdk/base.interface';
import { DataType, TokenizedSource } from '@shared/types/sdk/resolvers';

import { IEventDestinationUsage } from './destinationUsage.interface';

export enum DestinationState {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  TOO_MANY_ERRORS = 'too_many_errors', // disabled due to errors
}

export enum MonitorEvent {
  WORKFLOW_FAILURE = 'workflow_failure',
}

export enum EventDestination {
  WEBHOOK = 'webhook',
  EMAIL = 'email',
}

export enum WebhookProviderTemplate {
  // for resetting purpose
  NONE = 'None',
  DATA_DOG = 'Datadog',
  NEW_RELIC = 'New Relic',
  SENTRY = 'Sentry',
  SLACK = 'Slack',
}

type BaseDestinationConfiguration = {
  events: MonitorEvent[];
};

export type EmailDestinationConfiguration = BaseDestinationConfiguration & {
  emailTo: string;
};

export type WebhookDestinationConfiguration = BaseDestinationConfiguration & {
  url: string;
  body: TokenizedSource<DataType.ANY>;
  headers: Array<{ key: string; value: string }>;
  template: WebhookProviderTemplate;
};

export type DestinationConfiguration =
  | WebhookDestinationConfiguration
  | EmailDestinationConfiguration;

export interface BaseEventDestination {
  // project that destination belongs to
  projectId: string;

  // state of desintation , treat TOO_MANY_ERRORS state as disabled in backend
  state: DestinationState;
  type: EventDestination;
  configuration: DestinationConfiguration;
  usage: IEventDestinationUsage;
}

export interface WebhookEventDestination extends BaseEventDestination {
  type: EventDestination.WEBHOOK;
  configuration: WebhookDestinationConfiguration;
}

export interface EmailEventDestination extends BaseEventDestination {
  type: EventDestination.EMAIL;
  configuration: EmailDestinationConfiguration;
}

export interface IEventDestination extends IBaseEntity, BaseEventDestination {}
