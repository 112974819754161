import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs/config';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.BIGQUERY,
  provider: ProviderType.BIGQUERY,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your BigQuery accounts',
  icon: iconSvg,
  description:
    "Connect to your users' BigQuery account to manage their datasets, tables, and jobs.",
  sidebarSections: [],
};

export default authConfig;
