import { CachedPersona } from '@shared/entities/sdk';
import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { IProject } from '@shared/entities/sdk/project/project.interface';
import { IWorkflow } from '@shared/entities/sdk/workflow/workflow.interface';
import { InvalidWorkflowDeploymentError, StepValidationError } from '@shared/types/sdk/errors';
import { WorkflowExecutionContext, WorkflowType } from '@shared/types/sdk/execution';
import {
  DataType,
  KeyedSource,
  Source,
  TokenizedSource,
  TokenizedValue,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import { StateMachine, Step, StepType } from '@shared/types/sdk/steps';
import { BillingPlan } from '@shared/types/sdk/stripe';

import { workflowStepsToStateMachine } from '../stateMachine';
import { isTrigger } from '../workflow.utils';

import { StepValidator } from './abstract.validator';
import ActionStepValidator from './action.validator';
import ActionTriggerStepValidator from './actionTrigger.validator';
import ConditionalStepValidator from './conditional.validator';
import CronStepValidator from './cron.validator';
import CustomIntegrationRequestStepValidator from './customIntegrationRequest.validator';
import DelayStepValidator from './delay.validator';
import EndpointStepValidator from './endpoint.validator';
import EventStepValidator from './event.validator';
import FunctionStepValidator from './function.validator';
import IntegrationEnabledStepValidator from './integrationEnabled.validator';
import FanOutStepValidator from './map.validator';
import OauthStepValidator from './oauth.validator';
import RedirectStepValidator from './redirect.validator';
import RequestStepValidator from './request.validator';
import ResponseStepValidator from './response.validator';
import UnselectedTriggerStepValidator from './unselectedTrigger.validator';

/**
 * checks whether or not an input has a variable source
 *
 * @param source
 * @returns
 */
export const isVariableSource = (source: TokenizedSource): boolean => {
  if (!source) {
    return false;
  }
  if (source.parts.some((part: TokenizedValue<DataType>) => part.type === 'VARIABLE')) {
    return true;
  }
  return false;
};

/**
 * checks whether or not a keyed source input has any variable sources in it
 *
 * @param input
 * @returns
 */
export const isVariableKeySource = (input?: KeyedSource<DataType.ANY>): boolean => {
  if (!input) {
    return false;
  }
  if (input.source.type === 'TOKENIZED') {
    return isVariableSource(input.source);
  }
  return false;
};

/**
 * checks whether or not a value source has an empty value
 *
 * @param source
 * @returns
 */
export const isEmptySource = (source: Source): boolean => {
  // Condition fields can have a value source set but have value set to `null` or `undefined`.
  // In this case, the user hasn't entered a value and the field is invalid.
  if (
    source === null ||
    source === undefined ||
    (source.type === 'VALUE' && (source.value === null || source.value === undefined))
  ) {
    return true;
  }

  return false;
};

/**
 * checks if a source has any values that haven't been filled
 *
 * @param source
 * @returns
 */
export const hasEmptySource = (source: Source | KeyedSource): boolean => {
  if (!source) {
    return true;
  } else if ('key' in source) {
    return hasEmptySource(source.source);
  } else if (source.type === 'VALUE') {
    return isEmptySource(source);
  } else if (source.type === 'TOKENIZED') {
    return source.parts.some((part: TokenizedValue) => isEmptySource(part));
  }

  return false;
};

/**
 * this method return if we have to perform assertion for provided input
 * if input has value from variable then dont assert this value
 * as sometimes the variables got out of sync
 *
 * @todo rename to `shouldAssertSource`
 *
 * @param input
 * @param value
 * @returns whether inputvalue needs to be asserted
 */
export const shouldAssertValue = (
  input: TokenizedSource | KeyedSource<DataType.ANY> | Source,
  value: any,
): boolean => {
  const isValueDefined: boolean = value !== undefined && value !== null;

  if (!input) {
    return true;
  } else if ('source' in input) {
    return !isVariableKeySource(input) || isValueDefined;
  } else if ('parts' in input) {
    return !isVariableSource(input) || isValueDefined;
  } else if (input.type === 'VARIABLE') {
    return isValueDefined;
  }

  return true;
};

/**
 * a map of validators for each step type
 */
export const STEPS_VALIDATIONS_MAP: Record<StepType, () => StepValidator<Step, any>> = {
  [StepType.ACTION]: () => new ActionStepValidator(),
  [StepType.ACTION_TRIGGER]: () => new ActionTriggerStepValidator(),
  [StepType.CRON]: () => new CronStepValidator(),
  [StepType.DELAY]: () => new DelayStepValidator(),
  [StepType.ENDPOINT]: () => new EndpointStepValidator(),
  [StepType.EVENT]: () => new EventStepValidator(),
  [StepType.FUNCTION]: () => new FunctionStepValidator(),
  [StepType.IFELSE]: () => new ConditionalStepValidator(),
  [StepType.INTEGRATION_ENABLED]: () => new IntegrationEnabledStepValidator(),
  [StepType.MAP]: () => new FanOutStepValidator(),
  [StepType.OAUTH]: () => new OauthStepValidator(),
  [StepType.REDIRECT]: () => new RedirectStepValidator(),
  [StepType.REQUEST]: () => new RequestStepValidator(),
  [StepType.CUSTOM_INTEGRATION_REQUEST]: () => new CustomIntegrationRequestStepValidator(),
  [StepType.RESPONSE]: () => new ResponseStepValidator(),
  [StepType.UNSELECTED_TRIGGER]: () => new UnselectedTriggerStepValidator(),
};

/**
 * this validate single workflow step
 * @param step
 * @param secrets
 * @param cachedConnectCredential
 * @param variables
 * @param context
 */
export const assertWorkflowStep = (
  step: Step,
  secrets: Record<string, string>,
  cachedConnectCredential: CachedConnectCredential | null,
  variables: WorkflowVariables,
  context: WorkflowExecutionContext,
  cachedPersona: CachedPersona | null,
): void => {
  const validatorProvider: () => StepValidator<Step, any> = STEPS_VALIDATIONS_MAP[step.type];
  if (!validatorProvider) {
    throw new StepValidationError({
      stepId: step.id,
      message: `${step.type} is not a valid step`,
    });
  }
  const validator: StepValidator<Step, any> = validatorProvider();
  validator.resolveAndValidate(
    step,
    secrets,
    cachedConnectCredential,
    variables,
    context,
    cachedPersona,
  );
};

/**
 * this will validate workflows steps
 * @param workflow
 * @param secrets
 * @param cachedConnectCredential
 * @param variables
 * @param project
 */
export const assertWorkflowDeployment = (
  workflow: IWorkflow,
  secrets: Record<string, string>,
  cachedConnectCredential: CachedConnectCredential | null,
  variables: WorkflowVariables,
  project: IProject,
  cachedPersona: CachedPersona,
  billingPlan?: BillingPlan,
): void => {
  let steps: Step[] = workflow.steps as Step[];
  const stateMachine: StateMachine = workflowStepsToStateMachine(steps);

  steps = steps
    .filter((step: Step): boolean => !stateMachine.unusedSteps.includes(step.id))
    .filter((step: Step) => !step['dateDeleted']);

  const startStepId: string = stateMachine.start;
  const sequenceStartStepId: string | undefined = stateMachine.sequenceMap[startStepId].start;
  const triggerStep: Step | undefined = sequenceStartStepId
    ? stateMachine.stepMap[sequenceStartStepId]
    : undefined;

  let stepValidationErrors: { id: string; error: string }[] = [];
  if (!triggerStep) {
    throw new Error('Trigger step not found');
  }

  if (!isTrigger(triggerStep) || triggerStep.type === StepType.UNSELECTED_TRIGGER) {
    stepValidationErrors = [
      ...stepValidationErrors,
      { id: triggerStep.id, error: 'Trigger selection is required for this step.' },
    ];
  }

  const context: WorkflowExecutionContext = {
    workflowId: workflow.id,
    projectId: project.id,
    integrationId: workflow.integrationId,
    workflowType: workflow.integrationId ? WorkflowType.CONNECT : WorkflowType.CLASSIC,
    stateMachine,
    plan: billingPlan,
  } as WorkflowExecutionContext;

  steps.forEach((step: Step) => {
    try {
      assertWorkflowStep(
        step,
        secrets,
        cachedConnectCredential,
        variables,
        {
          ...context,
          stepId: step.id,
        },
        cachedPersona,
      );
    } catch (error) {
      stepValidationErrors = [...stepValidationErrors, { id: step.id, error: error.message }];
    }
  });

  if (stepValidationErrors.length) {
    throw new InvalidWorkflowDeploymentError({
      workflowId: triggerStep.workflowId,
      projectId: project.id,
      errors: stepValidationErrors,
    });
  }
};
