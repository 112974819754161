import { Action } from '@shared/types/sdk/actions';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class IntegrationError extends ParagonError {
  name: string = 'IntegrationError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class IntegrationNotFoundError extends IntegrationError {
  name: string = 'IntegrationNotFoundError';

  constructor(meta: { integrationId: string; action?: Action; projectId?: string }) {
    super({
      code: ERROR_CODE.INTEGRATION_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find integration.',
      meta,
    });
  }
}

export class IntegrationNotEnabledForProjectError extends IntegrationError {
  name: string = 'IntegrationNotEnabledForProjectError';

  constructor(meta: {
    projectId: string;
    action: Action;
    integrationId?: string;
    personaId?: string;
  }) {
    super({
      code: ERROR_CODE.INTEGRATION_NOT_ENABLED_FOR_PROJECT,
      httpStatus: HttpStatus.FORBIDDEN,
      message: "This integration hasn't been enabled in this project.",
      meta,
    });
  }
}

export class IntegrationNotEnabledForPersonaError extends IntegrationError {
  name: string = 'IntegrationNotEnabledForPersonaError';

  constructor(meta: {
    projectId: string;
    action?: Action;
    integrationId?: string;
    personaId?: string;
  }) {
    super({
      code: ERROR_CODE.INTEGRATION_NOT_ENABLED_FOR_PERSONA,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Integration not enabled for user.',
      meta,
    });
  }
}

export class IntegrationNotFoundForCustomIntegrationError extends IntegrationError {
  name: string = 'IntegrationNotFoundForCustomIntegrationError';

  constructor(meta: { customIntegrationId: string; projectId: string }) {
    super({
      code: ERROR_CODE.INTEGRATION_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find integration.',
      meta,
    });
  }
}

export class IntegrationNotCustomIntegrationError extends IntegrationError {
  name: string = 'IntegrationNotCustomIntegrationError';

  constructor(meta: { integrationId: string; projectId: string; personaId: string }) {
    super({
      code: ERROR_CODE.INTEGRATION_NOT_CUSTOM_INTEGRATION,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'This integration is not a custom integration.',
      meta,
    });
  }
}

export class IntegrationNotActiveError extends IntegrationError {
  name: string = 'IntegrationNotActiveError';

  constructor(meta: { integrationId: string; projectId: string }) {
    super({
      code: ERROR_CODE.INTEGRATION_NOT_ACTIVE,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'This integration is not active. Please active the integration to continue.',
      meta,
    });
  }
}

export class CustomIntegrationNotFoundError extends IntegrationError {
  name: string = 'CustomIntegrationNotFoundError';

  constructor(meta: { customIntegrationId?: string; projectId: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find custom integration.',
      meta,
    });
  }
}

export class CustomIntegrationMissingTestEndpointPathError extends IntegrationError {
  name: string = 'CustomIntegrationMissingTestEndpointPathError';
  constructor(meta: { customIntegrationId: string; integrationId?: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_MISSING_TEST_ENDPOINT_PATH,
      httpStatus: HttpStatus.INTERNAL_SERVER_ERROR,
      message: 'Custom integration does not have `testEndpointPath`.',
      meta,
    });
  }
}

export class CustomIntegrationWizardIconNotFoundError extends IntegrationError {
  name: string = 'CustomIntegrationWizardIconNotFoundError';

  constructor(meta: { customIntegrationId?: string; projectId: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_WIZARD_ICON_NOT_FOUND,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: "Integration doesn't have an icon.",
      meta,
    });
  }
}

export class CustomIntegrationWizardDraftNotFoundError extends IntegrationError {
  name: string = 'CustomIntegrationWizardDraftNotFoundError';

  constructor(meta: { customIntegrationId?: string; projectId: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_WIZARD_DRAFT_NOT_FOUND,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'No custom integration draft in progress.',
      meta,
    });
  }
}

export class CustomIntegrationWizardIsNotDraftError extends IntegrationError {
  name: string = 'CustomIntegrationWizardIsNotDraftError';

  constructor(meta: { customIntegrationId: string; projectId: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_WIZARD_IS_NOT_DRAFT,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'This custom integration is already published.',
      meta,
    });
  }
}

export class CustomIntegrationWizardNameConflictError extends IntegrationError {
  name: string = 'CustomIntegrationWizardNameConflictError';

  constructor(meta: { projectId: string; name?: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_WIZARD_NAME_CONFLICT,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'You already have a custom integration with this name.',
      meta,
    });
  }
}

export class CustomIntegrationWizardPreviewCredentialNotFoundError extends IntegrationError {
  name: string = 'CustomIntegrationWizardPreviewCredentialNotFoundError';

  constructor(meta: { customIntegrationId: string; projectId: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_WIZARD_PREVIEW_CREDENTIAL_REQUIRED,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Connect a test account in the portal before continuing.',
      meta,
    });
  }
}

export class CustomIntegrationWizardUnknownSaveActionError extends IntegrationError {
  name: string = 'CustomIntegrationWizardUnknownSaveActionError';

  constructor(meta: { customIntegrationId: string; projectId: string; type: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_WIZARD_UNKNOWN_SAVE_ACTION,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Unrecognized save action in custom integration wizard.',
      meta,
    });
  }
}

export class CustomIntegrationWizardUnsupportedMimeTypeError extends IntegrationError {
  name: string = 'CustomIntegrationWizardUnsupportedMimeTypeError';

  constructor(meta: { mime: string; url: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_WIZARD_UNSUPPORTED_MIME_TYPE,
      httpStatus: HttpStatus.UNSUPPORTED_MEDIA_TYPE,
      message: 'Unsupported mime type. Only "image/svg+xml" is allowed.',
      meta,
    });
  }
}

export class CustomIntegrationWizardIconUploadFailedError extends IntegrationError {
  name: string = 'CustomIntegrationWizardIconUploadFailedError';

  constructor(meta: { projectId: string; customIntegrationId: string }) {
    super({
      code: ERROR_CODE.CUSTOM_INTEGRATION_WIZARD_UNSUPPORTED_MIME_TYPE,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Failed to upload icon.',
      meta,
    });
  }
}

export class IntegrationConfigNotFoundFromIntegrationIdError extends IntegrationError {
  name: string = 'IntegrationConfigNotFoundFromIntegrationIdError';

  constructor(meta: { integrationId: string }) {
    super({
      code: ERROR_CODE.INTEGRATION_CONFIG_NOT_FOUND_FROM_INTEGRATION_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find integration config.',
      meta,
    });
  }
}

export class IntegrationConfigNotFoundFromIdError extends IntegrationError {
  name: string = 'IntegrationConfigNotFoundFromIdError';

  constructor(meta: { integrationConfigId: string }) {
    super({
      code: ERROR_CODE.INTEGRATION_CONFIG_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find integration config.',
      meta,
    });
  }
}

export class IntegrationConfigNotFoundFromCustomIntegrationIdError extends IntegrationError {
  name: string = 'IntegrationConfigNotFoundFromCustomIntegrationIdError';

  constructor(meta: { projectId: string; customIntegrationId: string; integrationId?: string }) {
    super({
      code: ERROR_CODE.INTEGRATION_CONFIG_NOT_FOUND_FROM_CUSTOM_INTEGRATION_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find integration config.',
      meta,
    });
  }
}
