import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const nameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.TextArea,
  title: 'Name',
  lines: 1,
  required: true,
};

const workspaceInput: SidebarInput = {
  id: 'workspace',
  type: SidebarInputType.TextArea,
  title: 'Workspace',
  placeholder: '{{settings.workspace}}',
  lines: 1,
};

const notesInput: SidebarInput = {
  id: 'notes',
  type: SidebarInputType.TextArea,
  title: 'Notes',
  required: false,
};

const taskIdInput: SidebarInput = {
  id: 'taskId',
  type: SidebarInputType.TextArea,
  title: 'Task ID',
  required: true,
  lines: 1,
};

const externalIdInput: SidebarInput = {
  id: 'gid',
  type: SidebarInputType.TextArea,
  title: 'External ID',
  lines: 1,
  required: false,
};

const projectInput: SidebarInput = {
  id: 'project',
  type: SidebarInputType.TextArea,
  title: 'Project',
  placeholder: '{{settings.project}}',
  required: false,
  lines: 1,
};

const assigneeInput: SidebarInput = {
  id: 'assignee',
  type: SidebarInputType.TextArea,
  title: 'Assignee',
  placeholder: '{{settings.assignee}}',
  required: false,
  lines: 1,
};

const dueOnDateInput: SidebarInput = {
  id: 'dueOnDate',
  type: SidebarInputType.TextArea,
  title: 'Due On',
  lines: 1,
  placeholder: 'YYYY-MM-DD',
  subtitle: 'The date on which  this task is due. Cannot be used together with Due At.',
  required: false,
};

const dueAtDateInput: SidebarInput = {
  id: 'dueAtDate',
  type: SidebarInputType.TextArea,
  title: 'Due At',
  lines: 1,
  placeholder: '2019-09-15T02:06:58.147Z',
  subtitle:
    'The date and time (ISO timestamp) at which this task is due . Cannot be used together with Due On.',
  required: false,
};

const connectConfig: ActionConfig = {
  actionType: Action.ASANA,
  name: 'Asana',
  description: 'Manage tasks and projects in Asana.',
  icon: iconSvg,
  sidebarSections: [
    (): SidebarSection => ({
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              title: 'Comment',
              items: [
                {
                  value: Intent.CREATE_COMMENT,
                  label: 'Create Comment',
                },
              ],
            },
            {
              title: 'Projects',
              items: [
                {
                  value: Intent.CREATE_PROJECT,
                  label: 'Create Project',
                },
                {
                  value: Intent.GET_PROJECTS,
                  label: 'Get Projects',
                },
                {
                  value: Intent.GET_PROJECT_BY_ID,
                  label: 'Get Project by ID',
                },
              ],
            },
            {
              title: 'Tasks',
              items: [
                {
                  value: Intent.CREATE_TASK,
                  label: 'Create Task',
                },
                {
                  value: Intent.UPDATE_TASK,
                  label: 'Update Task',
                },
                {
                  value: Intent.GET_TASKS,
                  label: 'Get Tasks',
                },
                {
                  value: Intent.GET_TASKS_BY_ID,
                  label: 'Get Task by ID',
                },
                {
                  value: Intent.GET_TASK_BY_EXTERNAL_ID,
                  label: 'Get Task by External ID',
                },
              ],
            },
            {
              title: 'Teams',
              items: [
                {
                  value: Intent.GET_TEAMS,
                  label: 'Get Teams',
                },
              ],
            },
            {
              title: 'Workspaces',
              items: [
                {
                  value: Intent.GET_WORKSPACES,
                  label: 'Get Workspaces',
                },
              ],
            },
          ],
        },
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_COMMENT:
          return {
            inputs: [
              {
                ...taskIdInput,
                id: 'task',
                subtitle:
                  'The ID of the Task the comment will be added to. The comment will be authored by the currently authenticated user.',
              },
              {
                id: 'text',
                type: SidebarInputType.TextArea,
                title: 'Text',
                lines: 2,
                required: true,
                placeholder: 'This is a comment.',
              },
            ],
          };
        case Intent.CREATE_PROJECT:
          return {
            inputs: [
              { ...nameInput, placeholder: 'Stuff to buy' },
              {
                ...workspaceInput,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which Workspace to create Projects in. Defaults to the user’s first Workspace if left blank.',
              },
              {
                id: 'team',
                type: SidebarInputType.TextArea,
                title: 'Team',
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which Team to share this Project with. Defaults to the user’s first Team if left blank.',
                placeholder: '{{settings.team}}',
                required: false,
                lines: 1,
              },
              {
                ...notesInput,
                placeholder: 'These are things we need to purchase.',
                lines: 1,
              },
            ],
          };
        case Intent.GET_PROJECTS:
          return {
            inputs: [],
          };
        case Intent.GET_PROJECT_BY_ID:
          return {
            inputs: [
              {
                id: 'projectFilterId',
                type: SidebarInputType.TextArea,
                title: 'Project ID',
                lines: 1,
                required: true,
              },
            ],
          };
        case Intent.CREATE_TASK:
          return {
            inputs: [
              { ...nameInput, placeholder: 'Task Name' },
              {
                ...workspaceInput,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which Workspace to create Tasks in. Defaults to the user’s first Workspace if left blank.',
              },
              {
                ...projectInput,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which Project to create this Task in.',
              },
              { ...notesInput, lines: 2 },
              dueOnDateInput,
              dueAtDateInput,
              {
                ...assigneeInput,
                subtitle:
                  'The ID of the Asana user this task will be assigned to. Use Connect Portal Workflow Settings to allow users to select an Assignee.',
              },
              {
                ...externalIdInput,
                subtitle:
                  'An ID from your application to associate this task with. You can use this ID to sync updates to this task later.',
              },
            ],
          };
        case Intent.UPDATE_TASK:
          return {
            inputs: [
              { ...taskIdInput, subtitle: 'The ID of the Task that will be updated.' },
              {
                id: 'completeStatus',
                type: SidebarInputType.Enum,
                title: 'Completed Status',
                required: false,
                getValues: () => [
                  { label: 'True', value: 'true' },
                  { label: 'False', value: 'false' },
                ],
              },
              { ...nameInput, required: false, placeholder: 'Task Name' },
              { ...notesInput, lines: 2 },
              dueOnDateInput,
              dueAtDateInput,
              {
                ...assigneeInput,
                subtitle:
                  'The ID of the Asana user this task will be assigned to. Use Connect Portal Workflow Settings to allow users to select an Assignee.',
              },
              {
                ...externalIdInput,
                subtitle:
                  'An ID from your application to associate this task with. You can use this ID to sync updates to this task later.',
              },
            ],
          };
        case Intent.GET_TASKS:
          return {
            inputs: [
              {
                ...workspaceInput,
                subtitle:
                  'The ID of the Workspace to filter tasks on. Use Connect Portal Workflow Settings to allow users to select a Workspace.',
                required: false,
              },
              {
                ...projectInput,
                subtitle:
                  'The ID of the Project to filter tasks on. Use Connect Portal Workflow Settings to allow users to select a Project.',
              },
              {
                ...assigneeInput,
                subtitle:
                  'The ID of the assignee to filter tasks on. Use Connect Portal Workflow Settings to allow users to select an Assignee.',
              },
              {
                id: 'completedSince',
                type: SidebarInputType.TextArea,
                title: 'Completed since',
                lines: 1,
                placeholder: '2014-04-25T16:15:47-04:00',
                subtitle:
                  'Only return tasks that are either incomplete or that have been completed since this time (ISO or Unix timestamp).',
                required: false,
              },
            ],
          };
        case Intent.GET_TASKS_BY_ID:
          return {
            inputs: [taskIdInput],
          };
        case Intent.GET_TASK_BY_EXTERNAL_ID:
          return {
            inputs: [
              {
                ...externalIdInput,
                required: true,
                subtitle:
                  'The ID that this task is associated or synced with, from your application.',
              },
            ],
          };
        case Intent.GET_TEAMS:
          return {
            inputs: [
              {
                ...workspaceInput,
                subtitle: 'Returns the teams in this workspace visible to the authorized user.',
              },
            ],
          };
        case Intent.GET_WORKSPACES:
          return {
            inputs: [],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default connectConfig;
