import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import {
  ConditionWrapper,
  ResolvedConditionWrapper,
  ResolvedConditionalParameters,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';
import { Choice, ConditionalStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import {
  flatten,
  getRequiredStepIdForConditionalSource,
  resolveConditionWrapper,
} from './resolvers.utils';

/**
 * a resolver for a conditional step's variables
 *
 * @export
 * @class ConditionalStepResolver
 * @extends {StepResolver<ActionTriggerStep, ConditionalStepParameters, ActionTriggerInput>}
 */
export class ConditionalStepResolver extends StepResolver<
  ConditionalStep,
  ResolvedConditionalParameters
> {
  /**
   * resolves the input parameters for a step
   *
   * @param {S} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedConditionalParameters}
   * @memberof StepResolver
   */
  resolveInputParametersFromStep(
    step: ConditionalStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedConditionalParameters {
    const resolved: ResolvedConditionalParameters = {
      next: step.next,
      choices: step.parameters.choices.map((choice: Choice) => ({
        ...choice,
        conditionWrapper:
          choice.conditionWrapper &&
          this.resolveChoiceVariables(
            choice.conditionWrapper,
            secrets,
            cachedConnectCredential,
            variables,
            context,
            cachedPersona,
          ),
      })),
    };

    return resolved;
  }

  /**
   * resolves the variables in a choice
   *
   * @param {ConditionWrapper} conditionWrapper
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedConditionWrapper}
   * @memberof ConditionalStepResolver
   */
  resolveChoiceVariables(
    conditionWrapper: ConditionWrapper,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedConditionWrapper {
    return resolveConditionWrapper(
      conditionWrapper,
      variables,
      secrets,
      cachedConnectCredential,
      context,
      cachedPersona,
    );
  }

  /**
   * resolves the input parameters for the step
   *
   * @param {ConditionalStepParameters} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedConditionalParameters}
   * @memberof ActionStepResolver
   */
  resolveInputParameters(
    _parameters: ConditionalStep['parameters'],
    _secrets: Record<string, string>,
    _cachedConnectCredential: CachedConnectCredential | null,
    _variables: WorkflowVariables,
    _context: WorkflowExecutionContext,
  ): ResolvedConditionalParameters {
    throw new Error('Not implemented! Conditional step needs step to resolve.');
  }

  /**
   *
   * @param parameters
   * @returns
   */
  getRequiredStepIdsForVariableResolution(parameters: ConditionalStep['parameters']): string[] {
    return flatten(
      parameters.choices
        .filter((choice) => choice.conditionWrapper)
        .map((choice) => getRequiredStepIdForConditionalSource(choice.conditionWrapper!)),
    );
  }
}
