export type Environment = {
  NETSUITE_CLIENT_ID: string;
  NETSUITE_CLIENT_SECRET: string;
  NETSUITE_SCOPES: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, any>;
  NETSUITE_ACCOUNT_ID: string;
};

export type ExchangeCredentialDTO = {
  code: string;
  entity: string;
  redirectUrl: string;
  NETSUITE_ACCOUNT_ID: string;
  code_verifier: string;
};

export type GetAccountBook = {
  items: [Record<string, any>];
};

export type CreateOrUpdateVendorDTO = {
  vendorId: string;
  companyName: string;
  subsidiary?: string;
  isPerson?: string;
  email?: string;
  altEmail?: string;
  defaultTaxReg?: string;
  expenseAccount?: string;
  terms?: string;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  vendorAdditionalFields?: string;
  firstName?: string;
  lastName?: string;
};

export type UpdateVendorDTO = {
  vendorId: string;
} & CreateOrUpdateVendorDTO;

export type CreateOrUpdateBillDTO = {
  billId: string;
  entity: string;
  tranDate: string;
  postingPeriod: string;
  subsidiary?: string;
  tranId?: string;
  dueDate?: string;
  userTotal?: string;
  approvalStatus?: string;
  memo?: string;
  itemsInput?: string;
  billAdditionalFields?: string;
};

export type CreateOrUpdateAccountDTO = {
  accountId: string;
  acctNumber: string;
  acctName: string;
  subsidiaries: string;
  parent?: string;
  acctType: string;
  accountAdditionalFields?: string;
};

export type CreateOrUpdateTaxGroupDTO = {
  taxGroupId: string;
  itemId?: string;
  taxCode?: string;
  county?: string;
};

export type TokenExchangeResponse = {
  access_token: string;
  refresh_token: string;
};

export enum RecordType {
  VENDOR = 'vendor',
  BILL = 'vendorBill',
  ACCOUNTS = 'account',
  TAX_GROUP = 'taxGroup',
  PAYMENT_TERMS = 'term',
  POSTING_PERIOD = 'accountingPeriod',
  TAX_CODE = 'salesTaxItem',
}

export type CreateRecordDTO =
  | CreateOrUpdateVendorDTO
  | CreateOrUpdateAccountDTO
  | CreateOrUpdateBillDTO
  | CreateOrUpdateTaxGroupDTO;

export type GetRecordByIdDTO =
  | {
      vendorId: string;
    }
  | { billid: string }
  | {
      accountId: string;
    }
  | {
      taxGroupId: string;
    }
  | {
      termId: string;
    };

export type SearchRecordsDTO =
  | {
      billFilterFormula: string;
    }
  | {
      vendorFilterFormula: string;
    }
  | {
      accountFilterFormula: string;
    }
  | {
      taxGroupFilterFormula: string;
    }
  | {
      termFilterFormula: string;
    };

export type PreparePayload = {
  recordId?: string;
  payload: Record<string, any>;
};

export type SearchRecordResponse = {
  count: number;
  hasMore: boolean;
  items: Item[];
  offset: number;
  totalResults: number;
};

export type Item = {
  links: [
    {
      rel: string;
      href: string;
    },
  ];
  id: string;
};
