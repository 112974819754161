import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { GetCalendarResponse } from '../shared/types';

export const getCalendars: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'calendars',
  title: 'Calendar',
  subtitle: 'A user’s calendar to add events to',
  refreshDependencies: [
    (parameters: ActionStepParameters) => parameters.credentials[0],
    (parameters: ActionStepParameters) => parameters.intent,
  ],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_CALENDARS,
      actionType: Action.GOOGLE_CALENDAR,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<GetCalendarResponse>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((value) => ({ label: value.title, value: value.id }))
    );
  },
};
