import { PublicOwner } from '@hubspot/api-client/lib/codegen/crm/owners/api';
import { Property } from '@hubspot/api-client/lib/codegen/crm/properties/model/property';
import { ObjectSchema } from '@hubspot/api-client/lib/codegen/crm/schemas/model/objectSchema';
import React from 'react';

import { ActionResponse, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  FieldMapperDataSource,
  StaticEnumDataSource,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { RecordType } from '../shared/types';

export const getFields: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  hideFromConnectFieldTypes: true,
  title: 'Field name',
  cacheKey: 'cachedFields',
  mapRefreshToValues: (response: ActionResponse<EnumInputValue[]>): any => {
    return response?.result && response.result.output;
  },
  refreshDependencies: ['recordType', 'cacheAllObjectTypes'],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const recordType = pickValueSourceByKey(options.actionParameters, 'recordType');
    const otherObjectType = pickValueSourceByKey(options.actionParameters, 'cacheAllObjectTypes');

    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_RECORD_TYPE_FIELDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: otherObjectType?.value || recordType?.value,
          },
        },
      ],
    };
  },
};

export const getCustomObjectTypes: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Custom Object Types',
  cacheKey: 'customRecordTypes',
  hideFromConnectFieldTypes: true,
  subtitle: 'HubSpot custom objects',
  refreshDependencies: [
    (parameters: ActionStepParameters) => parameters.credentials[0],
    (parameters: ActionStepParameters) => parameters.intent,
  ],
  mapRefreshToValues: (response: ActionResponse<ObjectSchema[]>): any => {
    return response?.result
      ? response.result.output.map((customObject) => ({
          value: customObject.fullyQualifiedName,
          label: customObject.labels.plural,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_CUSTOM_OBJECTS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getObjectTypes: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Custom Object Types',
  cacheKey: 'cacheAllObjectTypes',
  hideFromConnectFieldTypes: true,
  subtitle: 'HubSpot all objects',
  refreshDependencies: [
    (parameters: ActionStepParameters) => parameters.credentials[0],
    (parameters: ActionStepParameters) => parameters.intent,
  ],
  mapRefreshToValues: (response: ActionResponse<{ label: string; value: string }[]>): any => {
    return response?.result?.output || [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_ALL_OBJECT_TYPES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getDealStages: StaticEnumDataSource = {
  type: DataSourceType.STATIC_ENUM,
  id: 'dealStages',
  title: 'Deal Stage',
  subtitle: 'Stage that a Deal should be assigned to',
  values: [
    { label: 'Appointment scheduled', value: 'appointmentscheduled' },
    { label: 'Qualified to buy', value: 'qualifiedtobuy' },
    { label: 'Presentation scheduled', value: 'presentationscheduled' },
    { label: 'Decision maker bought-in', value: 'decisionmakerboughtin' },
    { label: 'Contract sent', value: 'contractsent' },
    { label: 'Closed won', value: 'closedwon' },
    { label: 'Closed lost', value: 'closedlost' },
  ],
};

// company base lead status
export const getLeadStatus: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'leadStatuses',
  title: 'Company Lead Status',
  subtitle: 'Lead Status that a Company should be assigned to',
  mapRefreshToValues: (response: ActionResponse<Property>): any => {
    return response?.result?.output?.options ? response.result.output.options : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_PROPERTY_META_DATA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: RecordType.COMPANY,
          },
        },
        {
          key: 'propertyName',
          source: {
            type: 'VALUE',
            value: 'hs_lead_status',
          },
        },
      ],
    };
  },
};

export const getContactLeadStatus: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'contactLeadStatuses',
  title: 'Contact Lead Status',
  subtitle: 'Lead Status that a Contact should be assigned to',
  mapRefreshToValues: (response: ActionResponse<Property>): any => {
    return response?.result?.output?.options ? response.result.output.options : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_PROPERTY_META_DATA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: RecordType.CONTACTS,
          },
        },
        {
          key: 'propertyName',
          source: {
            type: 'VALUE',
            value: 'hs_lead_status',
          },
        },
      ],
    };
  },
};

export const getLifeCycleStatge: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'lifecyclestages',
  title: 'Lifecycle Stage',
  subtitle: 'Stage that an opportunity should be assignd to',
  mapRefreshToValues: (response: ActionResponse<Property>): any => {
    return response?.result && response.result.output?.options;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_PROPERTY_META_DATA,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: RecordType.COMPANY,
          },
        },
        {
          key: 'propertyName',
          source: {
            type: 'VALUE',
            value: 'lifecyclestage',
          },
        },
      ],
    };
  },
};

export const getOwners: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'owners',
  title: 'HubSpot Owner',
  subtitle: 'User that a Contact, Company, or Deal should be assigned to',
  mapRefreshToValues: (response: ActionResponse<PublicOwner[]>): any => {
    return (
      response?.result &&
      response.result.output.map((owner) => ({
        value: owner.id,
        label: owner.email || owner.firstName,
      }))
    );
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_OWNERS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getCustomObjectsMapper: FieldMapperDataSource = {
  id: 'customObjectMapping',
  type: DataSourceType.FIELD_MAPPER,
  title: 'Field Mapping',
  subtitle: 'Allows users to define a field mapping',
  instructionalText: (
    <>
      Let users define a field mapping. Add a label for each property that should be mapped to a
      HubSpot custom object field.
    </>
  ),
  recordSource: getObjectTypes,
  fieldSource: getFields,
};

export const getContactProperties: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'contactProperties',
  title: 'Contact Properties',
  mapRefreshToValues: (response: ActionResponse<any[]>): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_RECORD_TYPE_FIELDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: 'contact',
          },
        },
      ],
    };
  },
};

export const getDealProperties: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'dealProperties',
  title: 'Deal Properties',
  mapRefreshToValues: (response: ActionResponse<any[]>): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_RECORD_TYPE_FIELDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: 'deal',
          },
        },
      ],
    };
  },
};

export const getCompanyProperties: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'companyProperties',
  title: 'Company Properties',
  mapRefreshToValues: (response: ActionResponse<any[]>): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_RECORD_TYPE_FIELDS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'recordType',
          source: {
            type: 'VALUE',
            value: 'company',
          },
        },
      ],
    };
  },
};

export const getContactLists: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'contactLists',
  title: 'Contact Lists',
  mapRefreshToValues: (response: ActionResponse<any[]>): any => {
    return response?.result?.output
      ? response.result.output.map((item: { listId: string; name: string }) => ({
          value: item.listId,
          label: item.name,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.HUBSPOT,
      intent: Intent.GET_CONTACTS_LISTS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};
