import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type Credentials = {
  name: string;
  MONDAY_ACCESS_TOKEN: string;
};

export enum CredentialsKey {
  API_TOKEN = 'MONDAY_ACCESS_TOKEN',
}

export type ActionConfig = BaseActionConfig & {
  actionType: Action.MONDAY;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.MONDAY;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'MONDAY_TEST_CONNECTION',
  CREATE_ITEM = 'MONDAY_CREATE_ITEM',
  UPDATE_ITEM = 'MONDAY_UPDATE_ITEM',
  GET_ITEM_BY_ID = 'MONDAY_GET_ITEM_BY_ID',
  GET_ITEM_BY_EXTERNAL_ID = 'MONDAY_GET_ITEM_BY_EXTERNAL_ID',
  SEARCH_ITEMS = 'MONDAY_SEARCH_ITEMS',
  DELETE_ITEM = 'MONDAY_DELETE_ITEM',
  ARCHIVE_ITEM = 'MONDAY_ARCHIVE_ITEM',
  CREATE_SUBITEM = 'MONDAY_CREATE_SUBITEM',
  SEARCH_USERS = 'MONDAY_SEARCH_USERS',
  GET_BOARDS = 'MONDAY_GET_BOARDS',
  GET_COLUMNS = 'MONDAY_GET_COLUMNS',
  GET_MEMBERS = 'MONDAY_GET_MEMBERS',
  GET_ITEMS_BY_BOARD_ID = 'MONDAY_GET_ITEMS_BY_BOARD_ID',

  TRIGGER_ITEM_CREATED = 'MONDAY_TRIGGER_ITEM_CREATED',
  TRIGGER_ITEM_UPDATED = 'MONDAY_TRIGGER_ITEM_UPDATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ITEM_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ITEM_BY_EXTERNAL_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_ITEMS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ARCHIVE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_SUBITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_USERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_BOARDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_COLUMNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_MEMBERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ITEMS_BY_BOARD_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ITEM_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ITEM_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );
