// TODO: Add Webpack loader and change this back to SVG file
export default `<svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.4001 78.2C26.4001 85.3 20.6001 91.1001 13.5001 91.1001C6.4001 91.1001 0.600098 85.3 0.600098 78.2C0.600098 71.1 6.4001 65.3 13.5001 65.3H26.4001V78.2Z" fill="#E01E5A"/>
<path d="M32.8999 78.2C32.8999 71.1 38.6999 65.3 45.7999 65.3C52.8999 65.3 58.6999 71.1 58.6999 78.2V110.5C58.6999 117.6 52.8999 123.4 45.7999 123.4C38.6999 123.4 32.8999 117.6 32.8999 110.5V78.2Z" fill="#E01E5A"/>
<path d="M45.7999 26.4001C38.6999 26.4001 32.8999 20.6001 32.8999 13.5001C32.8999 6.4001 38.6999 0.600098 45.7999 0.600098C52.8999 0.600098 58.6999 6.4001 58.6999 13.5001V26.4001H45.7999Z" fill="#36C5F0"/>
<path d="M45.8001 32.9001C52.9001 32.9001 58.7001 38.7001 58.7001 45.8001C58.7001 52.9001 52.9001 58.7001 45.8001 58.7001H13.5001C6.4001 58.7001 0.600098 52.9001 0.600098 45.8001C0.600098 38.7001 6.4001 32.9001 13.5001 32.9001H45.8001Z" fill="#36C5F0"/>
<path d="M97.6001 45.8001C97.6001 38.7001 103.4 32.9001 110.5 32.9001C117.6 32.9001 123.4 38.7001 123.4 45.8001C123.4 52.9001 117.6 58.7001 110.5 58.7001H97.6001V45.8001Z" fill="#2EB67D"/>
<path d="M91.1003 45.8001C91.1003 52.9001 85.3003 58.7001 78.2003 58.7001C71.1003 58.7001 65.3003 52.9001 65.3003 45.8001V13.5001C65.3003 6.4001 71.1003 0.600098 78.2003 0.600098C85.3003 0.600098 91.1003 6.4001 91.1003 13.5001V45.8001Z" fill="#2EB67D"/>
<path d="M78.2003 97.6001C85.3003 97.6001 91.1003 103.4 91.1003 110.5C91.1003 117.6 85.3003 123.4 78.2003 123.4C71.1003 123.4 65.3003 117.6 65.3003 110.5V97.6001H78.2003Z" fill="#ECB22E"/>
<path d="M78.2003 91.1001C71.1003 91.1001 65.3003 85.3 65.3003 78.2C65.3003 71.1 71.1003 65.3 78.2003 65.3H110.5C117.6 65.3 123.4 71.1 123.4 78.2C123.4 85.3 117.6 91.1001 110.5 91.1001H78.2003Z" fill="#ECB22E"/>
</svg>`;
