import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { ActionStepParameters, Intent } from '../configs';
import { MailChimpList, MailChimpTemplate, PaginateResponse } from '../shared';

export const getLists: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'list',
  title: 'List',
  subtitle: 'List that prospects should be added to',
  refreshDependencies: [],
  mapRefreshToValues: (
    response: ActionResponse<PaginateResponse<MailChimpList>>,
  ): EnumInputValue[] => {
    return response?.result
      ? response.result.output.lists.map((list) => ({ label: list.name, value: list.id }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MAILCHIMP,
      intent: Intent.SEARCH_LISTS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getTemplates: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'template',
  title: 'Template',
  subtitle: 'A Mailchimp template to use for an email campaign',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<MailChimpTemplate[]>): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((template) => ({ label: template.name, value: template.id }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.MAILCHIMP,
      intent: Intent.GET_TEMPLATES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};
