import { hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  endDateInput,
  filterGroupsInput,
  groupByInput,
  inspectionUrlInput,
  intentSelectorDropdown,
  rowLimitInput,
  searchDataTypeInput,
  siteUrlDynamicInput,
  siteUrlInput,
  startDateInput,
} from './common';

export const authConfig: ActionConfig = {
  actionType: Action.GOOGLE_SEARCH_CONSOLE,
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  scheme: AuthenticationScheme.OAUTH,
  hideOAuthApps: true,
};

const config: ActionConfig = {
  actionType: Action.GOOGLE_SEARCH_CONSOLE,
  name: 'Google Search Console',
  description:
    'Connect to your users Google Search Console account to run queries on their Google Search results data.',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => ({
      inputs: [
        {
          id: 'auth',
          title: 'Connect your Google Search Console account',
          placeholder: 'choose your Google Search Console account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.GOOGLE,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length ? intentSelectorDropdown : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_SITE:
          return { inputs: [siteUrlInput] };
        case Intent.INSPECT_INDEX_INFO:
          return {
            inputs: [
              {
                ...(hasOAuthAppCredential(parameters, credentials)
                  ? siteUrlDynamicInput
                  : siteUrlInput),
                subtitle: 'The Search Console URL for this web property.',
              },
              inspectionUrlInput,
            ],
          };
        case Intent.QUERY_SEARCH_DATA:
          return {
            inputs: [
              {
                ...(hasOAuthAppCredential(parameters, credentials)
                  ? siteUrlDynamicInput
                  : siteUrlInput),
                subtitle:
                  'The URL of the site to query search data for. Specify a URL or a Domain Property (e.g., sc-domain:example.com',
              },
              startDateInput,
              endDateInput,
              searchDataTypeInput,
              groupByInput,
              filterGroupsInput,
              rowLimitInput,
            ],
          };
        case Intent.GET_ALL_SITES:
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
