import { ValidationError } from '@nestjs/common';

import { Action } from '@shared/types/sdk/actions';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class CredentialError extends ParagonError {
  name: string = 'CredentialError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class CredentialNotFoundFromIdError extends CredentialError {
  name: string = 'CredentialNotFoundFromIdError';

  constructor(meta: { credentialId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CREDENTIAL_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find credential.',
      meta,
    });
  }
}

export class CredentialNotFoundForIntegrationError extends CredentialError {
  name: string = 'CredentialNotFoundForIntegrationError';

  constructor(meta: { integrationId: string; projectId: string; customIntegrationId?: string }) {
    super({
      code: ERROR_CODE.CREDENTIAL_NOT_FOUND_FOR_INTEGRATION,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find credential.',
      meta,
    });
  }
}

export class CredentialNotFoundForProjectError extends CredentialError {
  name: string = 'CredentialNotFoundForProjectError';

  constructor(meta: { integrationId: string; projectId: string; action: Action }) {
    super({
      code: ERROR_CODE.CREDENTIAL_NOT_FOUND_FOR_RPOJECT,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find credential.',
      meta,
    });
  }
}

export class CredentialInvalidStateError extends CredentialError {
  name: string = 'CredentialInvalidStateError';
  constructor(meta: {
    credentialId: string;
    isConnect: boolean;
    projectId?: string;
    integrationId?: string;
  }) {
    super({
      code: ERROR_CODE.CREDENTIAL_INVALID_STATUS_ERROR,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: "Credential is invalid and can't be refreshed. Please reconnect your account.",
      meta,
    });
  }
}

export class CredentialVerificationError extends CredentialError {
  name: string = 'CredentialVerificationError';

  constructor(meta: {
    connectCredentialId: string;
    integrationId: string;
    projectId: string;
    action: string;
    method?: string;
    path?: string;
    status?: number;
  }) {
    super({
      code: ERROR_CODE.CREDENTIAL_VERIFICATION_ERROR,
      httpStatus: HttpStatus.FORBIDDEN,
      message: 'Credential verification failed.',
      meta,
    });
  }
}

export class OAuthProviderError extends CredentialError {
  name: string = 'OAuthProviderError';

  constructor(meta: { action: Action; message: string; integrationId?: string }) {
    super({
      code: ERROR_CODE.OAUTH_PROVIDER_ERROR,
      httpStatus: HttpStatus.FORBIDDEN,
      message: `OAuth provider returned error: ${meta.message}`,
      meta,
    });
  }
}

export class OAuthRequestMissingActionError extends CredentialError {
  name: string = 'OAuthRequestMissingActionError';

  constructor(meta: { integrationId?: string }) {
    super({
      code: ERROR_CODE.OAUTH_REQUEST_MISSING_ACTION,
      httpStatus: HttpStatus.FORBIDDEN,
      message: `OAuth request missing integration type.`,
      meta,
    });
  }
}

export class OAuthUserSuppliedValuesParsingError extends CredentialError {
  name: string = 'OAuthUserSuppliedValuesParsingError';

  constructor(meta: {
    error: string;
    integrationId?: string;
    action?: Action;
    endUserSuppliedValues?: string;
  }) {
    super({
      code: ERROR_CODE.OAUTH_PARSING_USER_SUPPLIED_VALUES,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Error parsing oauth user supplied values.`,
      meta,
    });
  }
}

export class OAuthParsingQueryParametersError extends CredentialError {
  name: string = 'OAuthParsingQueryParametersError';

  constructor(meta: {
    error: string;
    integrationId?: string;
    action?: Action;
    parameters?: string;
  }) {
    super({
      code: ERROR_CODE.OAUTH_PARSING_QUERY_PARAMETERS,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Error parsing oauth query parameters.`,
      meta,
    });
  }
}

export class OAuthRedirectToSameUrlError extends CredentialError {
  name: string = 'OAuthRedirectToSameUrlError';

  constructor(meta: { redirectUrl: string }) {
    super({
      code: ERROR_CODE.OAUTH_REDIRECT_TO_SAME_URL,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Cannot redirect to same url.`,
      meta,
    });
  }
}

export class OAuthRedirectUrlMissing extends CredentialError {
  name: string = 'OAuthRedirectUrlMissing';

  constructor(meta: { action?: string }) {
    super({
      code: ERROR_CODE.OAUTH_REDIRECT_URL_MISSING,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Redirect url is missing in query parameters.',
      meta,
    });
  }
}

export class ConnectAuthenticationNotConfiguredError extends CredentialError {
  name: string = 'ConnectAuthenticationNotConfiguredError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.NO_AUTHENTICATION_CONFIGURED,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'No authentication method has been configured for this project.',
      meta,
    });
  }
}

export class MissingJWTError extends CredentialError {
  name: string = 'MissingJWTError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.MISSING_JWT,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Missing JWT.',
      meta,
    });
  }
}

export class MissingJWTSubError extends CredentialError {
  name: string = 'MissingJWTSubError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.MISSING_JWT_SUB,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Missing sub claim in JWT.',
      meta,
    });
  }
}

export class JWTExpiredError extends CredentialError {
  name: string = 'JWTExpiredError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.JWT_EXPIRED,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: 'Your authorization token has expired.',
      meta,
    });
  }
}

export class JWTMalformedError extends CredentialError {
  name: string = 'JWTMalformedError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.JWT_MALFORMED,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message:
        "Your authorization token is malformed. If you're using a signing key, make sure the private key you used to generate the token has a new line at the end of it.",
      meta,
    });
  }
}

export class Auth0MissingConfigError extends CredentialError {
  name: string = 'Auth0MissingConfigError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.AUTH0_CONFIG_MISSING,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: "Your Auth0 authentication isn't properly configured.",
      meta,
    });
  }
}

export class Auth0InvalidTenantError extends CredentialError {
  name: string = 'Auth0InvalidTenantError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.AUTH0_CONFIG_INVALID_TENANT,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: "Your Auth0 authentication isn't properly configured. Tenant domain is missing.",
      meta,
    });
  }
}

export class FirebaseAuthMissingConfigError extends CredentialError {
  name: string = 'FirebaseAuthMissingConfigError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.FIREBASE_AUTH_MISSING,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: "Your Firebase authentication isn't properly configured.",
      meta,
    });
  }
}

export class FirebaseAuthInvalidError extends CredentialError {
  name: string = 'FirebaseAuthInvalidError';

  constructor(meta: { projectId: string }) {
    super({
      code: ERROR_CODE.FIREBASE_AUTH_MISSING,
      httpStatus: HttpStatus.UNAUTHORIZED,
      message: "Your Firebase authentication isn't properly configured.",
      meta,
    });
  }
}

export class ConnectCredentialNotFoundFromIdError extends CredentialError {
  name: string = 'ConnectCredentialNotFoundFromIdError';

  constructor(meta: { connectCredentialId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find connect credential.',
      meta,
    });
  }
}

export class ConnectCredentialNotValidError extends CredentialError {
  name: string = 'ConnectCredentialNotValidError';

  constructor(meta: { connectCredentialId: string; status: string; projectId?: string }) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_INVALID_STATUS,
      httpStatus: HttpStatus.NOT_FOUND,
      message: `connect credential with id : ${meta.connectCredentialId} is not in valid state.`,
      meta,
    });
  }
}

export class ConnectCredentialNotFoundFromPersonaError extends CredentialError {
  name: string = 'ConnectCredentialNotFoundFromPersonaError';

  constructor(meta: {
    personaId: string;
    integrationId: string;
    projectId: string;
    workflowId?: string;
  }) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_NOT_FOUND_FROM_PERSONA,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find connect credential.',
      meta,
    });
  }
}

export class ConnectCredentialMissingAccessTokenError extends CredentialError {
  name: string = 'ConnectCredentialMissingAccessTokenError';

  constructor(meta: {
    projectId: string;
    personaId: string;
    integrationId: string;
    customIntegrationId?: string;
  }) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_MISSING_ACCESS_TOKEN,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Missing access token.',
      meta,
    });
  }
}

export class ConnectCredentialWorkflowNotEnabledError extends CredentialError {
  name: string = 'ConnectCredentialWorkflowNotEnabledError';

  constructor(meta: {
    personaId: string;
    integrationId: string;
    projectId: string;
    workflowId: string;
  }) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_WORKFLOW_NOT_ENABLED,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Workflow not enabled for user.',
      meta,
    });
  }
}

export class ConnectCredentialHiddenWorkflowError extends CredentialError {
  name: string = 'ConnectCredentialHiddenWorkflowError';
  constructor(meta: {
    integrationId: string;
    projectId: string;
    workflowId: string;
    personaId?: string;
  }) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_HIDDEN_WORKFLOW_ERROR,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Cannot update hidden workflow',
      meta,
    });
  }
}

export class ConnectCredentialValidationFailedError extends CredentialError {
  name: string = 'ConnectCredentialValidationFailedError';

  constructor(
    validationErrors: ValidationError[],
    meta?: {
      personaId: string;
      integrationId: string;
      projectId: string;
      connectCredentialId?: string;
    },
  ) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_CONFIG_NOT_VALID,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: ParagonError.parseValidations(validationErrors),
      meta: meta ?? {},
    });
  }
}

export class EncryptedCredentialNotFoundFromIdError extends CredentialError {
  name: string = 'EncryptedCredentialNotFoundFromIdError';

  constructor(meta: { encryptedCredentialId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.ENCRYPTED_CREDENTIAL_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find credential.',
      meta,
    });
  }
}

export class EncryptedConnectCredentialNotFoundFromIdError extends CredentialError {
  name: string = 'EncryptedConnectCredentialNotFoundFromIdError';

  constructor(meta: { encryptedConnectCredentialId: string; projectId?: string }) {
    super({
      code: ERROR_CODE.ENCRYPTED_CONNECT_CREDENTIAL_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find credential.',
      meta,
    });
  }
}

export class ConnectCredentialWorkflowNotEnabledWorkflowIdError extends CredentialError {
  name: string = 'ConnectCredentialWorkflowIdNotEnabledError';

  constructor(meta: {
    personaId: string;
    integrationId: string;
    projectId: string;
    workflowId: string;
  }) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_WORKFLOW_ID_NOT_ENABLED,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Workflow ${meta.workflowId} is not enabled for this user`,
      meta,
    });
  }
}

export class ConnectCredentialHiddenWorkflowIdError extends CredentialError {
  name: string = 'ConnectCredentialHiddenWorkflowWithWorkflowIdError';
  constructor(meta: {
    integrationId: string;
    projectId: string;
    workflowId: string;
    personaId?: string;
  }) {
    super({
      code: ERROR_CODE.CONNECT_CREDENTIAL_HIDDEN_WORKFLOW_ID_ERROR,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: `Workflow ${meta.workflowId} cannot be disabled for this user`,
      meta,
    });
  }
}

export class EncryptedSecretsNotFoundWithIdError extends CredentialError {
  name: string = 'EncryptedSecretsNotFoundWithIdError';

  constructor(meta: { idOrHash: string; projectId?: string }) {
    super({
      code: ERROR_CODE.ENCRYPTED_SECRET_NOT_FOUND_FROM_ID,
      httpStatus: HttpStatus.NOT_FOUND,
      message: 'Unable to find secret.',
      meta,
    });
  }
}
