import * as Joi from 'joi';

import { ParagonService } from '../atlas';
import { MissingEnvironmentVariablesError } from '../errors';

import {
  currentPlatformEnv,
  defaultAWSRegion,
  defaultCdnUrl,
  defaultCerberusAccessToken,
  defaultCerberusPort,
  defaultCerberusPrivateUrl,
  defaultCerberusPublicUrl,
  defaultChronosPort,
  defaultChronosPrivateUrl,
  defaultChronosPublicUrl,
  defaultConnectPort,
  defaultConnectPrivateUrl,
  defaultConnectPublicUrl,
  defaultDashboardPort,
  defaultDashboardPrivateUrl,
  defaultDashboardPublicUrl,
  defaultEmbassyJwtSecret,
  defaultEmbassyPort,
  defaultEmbassyPrivateUrl,
  defaultEmbassyPublicUrl,
  defaultHadesAccessToken,
  defaultHadesPort,
  defaultHadesPrivateUrl,
  defaultHadesPublicUrl,
  defaultHerculesPort,
  defaultHerculesPrivateUrl,
  defaultHerculesPublicUrl,
  defaultHermesAccessToken,
  defaultHermesPort,
  defaultHermesPrivateUrl,
  defaultHermesPublicUrl,
  defaultLogLevel,
  defaultMinioMicroserviceAccessKey,
  defaultMinioMicroserviceSecretKey,
  defaultMinioMode,
  defaultMinioPort,
  defaultMinioPrivateUrl,
  defaultMinioPublicBucket,
  defaultMinioPublicUrl,
  defaultMinioRegion,
  defaultMinioRootPass,
  defaultMinioRootUser,
  defaultMinioSystemBucket,
  defaultNodeEnv,
  defaultPassportPort,
  defaultPassportPrivateUrl,
  defaultPassportProductionUrl,
  defaultPassportPublicUrl,
  defaultPhemeAccessToken,
  defaultPhemePort,
  defaultPhemePrivateUrl,
  defaultPhemePublicUrl,
  defaultPlatoPort,
  defaultPlatoPrivateUrl,
  defaultPlatoPublicUrl,
  defaultPostgresDatabase,
  defaultPostgresHost,
  defaultPostgresPassword,
  defaultPostgresPort,
  defaultPostgresUsername,
  defaultRedisClusterUrl,
  defaultRedisUrl,
  defaultResetPasswordTokenEncryptionKey,
  defaultStatusPageAdminPassword,
  defaultStatusPageAdminUser,
  defaultStatusPageApiSecret,
  defaultStatusPageName,
  defaultStatusPagePort,
  defaultVersion,
  defaultVolumePath,
  defaultZeusAccessToken,
  defaultZeusJwtSecret,
  defaultZeusPort,
  defaultZeusPrivateUrl,
  defaultZeusPublicUrl,
} from './defaults';
import {
  CI_TRIGGER_EVENT,
  ENVIRONMENT_VARIABLES,
  FORBIDDEN_ENVIRONMENT_VARIABLES,
  HOST_ENV,
  MINIO_MODE,
  NODE_ENV,
  PLATFORM_ENV,
  PRODUCTION_AND_STAGING_PLATFORM_ENVS,
  ValidateEnvironmentMode,
} from './enums';
import {
  requireOnlyInCi,
  requireOnlyInMicroservice,
  validateBoolean,
  validateEnterpriseEnv,
  validateEnterpriseEnvWithFallback,
  validateNumber,
  validateNumberWithFallback,
  validateString,
  validateStringWithFallback,
} from './utils';

export const DEFAULT_ENV_VALUES: Partial<Record<ENVIRONMENT_VARIABLES, string>> = {
  [ENVIRONMENT_VARIABLES.NODE_ENV]: NODE_ENV.PRODUCTION,
  [ENVIRONMENT_VARIABLES.CERBERUS_PORT]: defaultCerberusPort.toString(),
  [ENVIRONMENT_VARIABLES.DASHBOARD_PORT]: defaultDashboardPort.toString(),
  [ENVIRONMENT_VARIABLES.EMBASSY_PORT]: defaultEmbassyPort.toString(),
  [ENVIRONMENT_VARIABLES.HERCULES_PORT]: defaultHerculesPort.toString(),
  [ENVIRONMENT_VARIABLES.HERMES_PORT]: defaultHermesPort.toString(),
  [ENVIRONMENT_VARIABLES.PASSPORT_PORT]: defaultPassportPort.toString(),
  [ENVIRONMENT_VARIABLES.ZEUS_PORT]: defaultZeusPort.toString(),
};

export function validateSchema<T>(
  keys: ENVIRONMENT_VARIABLES[],
  params: Partial<Record<ENVIRONMENT_VARIABLES, any>>,
  validation: ValidateEnvironmentMode = ValidateEnvironmentMode.Default,
): T {
  const schema: Joi.ObjectSchema = Joi.object(
    keys.reduce(
      (
        squashed: Partial<Record<ENVIRONMENT_VARIABLES, Joi.Schema>>,
        key: ENVIRONMENT_VARIABLES,
      ): Partial<Record<ENVIRONMENT_VARIABLES, Joi.Schema>> => ({
        ...squashed,
        [key]:
          validation === ValidateEnvironmentMode.Default
            ? ENVIRONMENT_VARIABLE_VALIDATIONS[key]
            : validation === ValidateEnvironmentMode.AllRequired
            ? ENVIRONMENT_VARIABLE_VALIDATIONS[key].required()
            : ENVIRONMENT_VARIABLE_VALIDATIONS[key].optional(),
      }),
      {},
    ),
  );

  const { error, value } = schema.validate(params);
  if (error) {
    throw new Error(`Environment config validation error: ${error.message}`);
  }
  return value;
}

/**
 * returns an object with the values for an array of environment variables
 *
 * @export
 * @template T
 * @param {(Record<string, string | number | boolean>)} env
 * @param {ENVIRONMENT_VARIABLES[]} keys
 * @param {ValidateEnvironmentMode} [validation=ValidateEnvironmentMode.Default]
 * @returns {T}
 */
export function pickEnvironmentVariables<T extends object>(
  env: Record<string, string | number | boolean> | NodeJS.ProcessEnv,
  keys: ENVIRONMENT_VARIABLES[],
  validation: ValidateEnvironmentMode = ValidateEnvironmentMode.Default,
): T {
  const picked: T = keys.reduce((squashed: T, key: ENVIRONMENT_VARIABLES): T => {
    return env[key] !== undefined
      ? {
          ...squashed,
          [key]: env[key],
        }
      : DEFAULT_ENV_VALUES[key] !== undefined
      ? {
          ...squashed,
          [key]: DEFAULT_ENV_VALUES[key],
        }
      : squashed;
  }, {} as T);
  return validateSchema<T>(keys, picked, validation);
}

/**
 * gets the value of an environment variable
 *
 * @export
 * @param {ENVIRONMENT_VARIABLES} name
 * @returns {string}
 */
export function pickEnvVar<T = string>(
  name: string,
  validate: boolean = true,
  env: Record<string, string | boolean> | NodeJS.ProcessEnv = process.env,
): T | undefined {
  if (!(name in ENVIRONMENT_VARIABLES)) {
    throw new Error(`Unknown environment variable: ${name}`);
  }

  return validate
    ? pickEnvironmentVariables(
        env,
        [name as ENVIRONMENT_VARIABLES],
        ValidateEnvironmentMode.AllOptional,
      )[name]
    : env[name]
    ? (`${env[name]}`.trim() as unknown as T)
    : undefined;
}

const isClusterEnabled: boolean =
  defaultRedisUrl && defaultRedisClusterUrl && defaultRedisUrl !== defaultRedisClusterUrl
    ? true
    : false;

export const ENVIRONMENT_VARIABLE_VALIDATIONS: Record<ENVIRONMENT_VARIABLES, Joi.Schema> = {
  // used when developing locally or in the CI
  [ENVIRONMENT_VARIABLES.PARAGON_ENV_PATH]: validateString().optional().default('.env'),
  [ENVIRONMENT_VARIABLES.GITHUB_PERSONAL_TOKEN]: requireOnlyInCi(validateString()),
  // used in the ci / cd environment to build images
  [ENVIRONMENT_VARIABLES.GIT_SHA]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.ECR_REPOSITORY]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.ECR_REGISTRY]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.SERVICE]: Joi.when(ENVIRONMENT_VARIABLES.CI, {
    is: Joi.valid(true, 'true'),
    then: validateString()
      .optional()
      .valid(...Object.values(ParagonService)),
    otherwise: validateString()
      .required()
      .valid(...Object.values(ParagonService)),
  }),
  [ENVIRONMENT_VARIABLES.SERVICE]: requireOnlyInMicroservice(
    validateString().valid(...Object.values(ParagonService)),
  ),
  [ENVIRONMENT_VARIABLES.BRANCH]: validateStringWithFallback(
    pickEnvVar(ENVIRONMENT_VARIABLES.CI_SOURCE_BRANCH, false),
  ),
  [ENVIRONMENT_VARIABLES.PRIVATE_DNS_HOST]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CERBERUS_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CHRONOS_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CONNECT_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.DASHBOARD_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.EMBASSY_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.HADES_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.HERCULES_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.HERMES_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.PASSPORT_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.PLATO_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.PHEME_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.ZEUS_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.GRAFANA_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.STATUS_PAGE_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CDN_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.MINIO_DNS_TARGET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CLOUDFLARE_API_TOKEN]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CLOUDFLARE_ZONE_ID]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.PARAGON_DOMAIN]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.AWS_REGION]: validateString().default(defaultAWSRegion),

  [ENVIRONMENT_VARIABLES.CI]: validateBoolean().default(false),
  [ENVIRONMENT_VARIABLES.CI_COMMIT_AUTHOR]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_DEPLOYMENT_ID]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_TRIGGER_PAYLOAD]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_TRIGGER_EVENT]: requireOnlyInCi(
    validateString().valid(...Object.values(CI_TRIGGER_EVENT)),
  ),
  [ENVIRONMENT_VARIABLES.CI_AWS_ACCESS_KEY_ID]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_AWS_SECRET_ACCESS_KEY]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_AWS_BUCKET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_UPLOAD_DIRECTORY]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_UPLOAD_PREFIX]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_UPLOAD_DELETE_REMOVED]: requireOnlyInCi(validateBoolean()),
  [ENVIRONMENT_VARIABLES.CI_TERRAFORM_API_KEY]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_TERRAFORM_CREATE_INFRA]: requireOnlyInCi(
    validateBoolean().default(false),
  ),
  [ENVIRONMENT_VARIABLES.CI_SOURCE_BRANCH]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_TARGET_BRANCH]: requireOnlyInCi(
    validateStringWithFallback(pickEnvVar(ENVIRONMENT_VARIABLES.CI_SOURCE_BRANCH, false)),
  ),
  [ENVIRONMENT_VARIABLES.CI_GITHUB_USERNAME]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_GITHUB_TOKEN]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_JIRA_EMAIL]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_JIRA_TOKEN]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_AIRTABLE_API_KEY]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_SLACK_TOKEN]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_BUILD_SUCCESS]: requireOnlyInCi(validateBoolean()),
  [ENVIRONMENT_VARIABLES.CI_ENTERPRISE_BUILD_SUCCESS]: requireOnlyInCi(validateBoolean()),
  [ENVIRONMENT_VARIABLES.CI_COMPILE_SUCCESS]: requireOnlyInCi(validateBoolean()),
  [ENVIRONMENT_VARIABLES.CI_LINT_SUCCESS]: requireOnlyInCi(validateBoolean()),
  [ENVIRONMENT_VARIABLES.CI_TEST_SUCCESS]: requireOnlyInCi(validateBoolean()),
  [ENVIRONMENT_VARIABLES.CI_PREFLIGHT_SUCCESS]: requireOnlyInCi(validateBoolean()),
  [ENVIRONMENT_VARIABLES.CI_BASTION_TUNNEL_HOST]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_BASTION_TUNNEL_PORT]: requireOnlyInCi(validateNumberWithFallback(2222)),
  [ENVIRONMENT_VARIABLES.CI_BASTION_REMOTE_HOST]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_BASTION_PUBLIC_KEY]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_BASTION_PRIVATE_KEY]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_BASTION_PRIVATE_KEY_PASSWORD]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_IP_ADDRESSES]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_QA_DEPLOYMENT]: requireOnlyInCi(validateBoolean()),
  [ENVIRONMENT_VARIABLES.CI_RUNNER_APP_ID]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_RUNNER_CLIENT_ID]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_RUNNER_CLIENT_SECRET]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.CI_RUNNER_PRIVATE_KEY]: requireOnlyInCi(validateString()),

  // enterprise variables
  [ENVIRONMENT_VARIABLES.ORGANIZATION]: validateEnterpriseEnv(true, false),
  [ENVIRONMENT_VARIABLES.LICENSE]: validateEnterpriseEnv(true, false),
  [ENVIRONMENT_VARIABLES.ANALYTICS_ENABLED]: validateBoolean().optional().default(true),
  [ENVIRONMENT_VARIABLES.PASSWORD_VALIDATION_ENABLED]: validateBoolean().optional().default(false),

  // used for provisioning postgres instances
  [ENVIRONMENT_VARIABLES.ATLAS_PROVISION_POSTGRES_HOST]: validateString(),
  [ENVIRONMENT_VARIABLES.ATLAS_PROVISION_POSTGRES_PORT]: validateNumber(),
  [ENVIRONMENT_VARIABLES.ATLAS_PROVISION_POSTGRES_USER]: validateString(),
  [ENVIRONMENT_VARIABLES.ATLAS_PROVISION_POSTGRES_PASS]: validateString(),
  [ENVIRONMENT_VARIABLES.ATLAS_PROVISION_POSTGRES_DB]: validateString(),

  // Used for protecting all internal endpoints/data
  [ENVIRONMENT_VARIABLES.ADMIN_BASIC_AUTH_USERNAME]: Joi.when(ENVIRONMENT_VARIABLES.PLATFORM_ENV, {
    switch: [
      {
        is: Joi.valid(
          PLATFORM_ENV.PRODUCTION,
          PLATFORM_ENV.PRODUCTION_MIRROR_1,
          PLATFORM_ENV.STAGING,
        ),
        then: validateString().required(),
      },
      {
        is: PLATFORM_ENV.ENTERPRISE,
        then: validateStringWithFallback(pickEnvVar(ENVIRONMENT_VARIABLES.LICENSE, false)),
      },
      {
        is: Joi.string(),
        then: pickEnvVar(ENVIRONMENT_VARIABLES.BASIC_AUTH_USER, false)
          ? validateStringWithFallback(pickEnvVar(ENVIRONMENT_VARIABLES.BASIC_AUTH_USER, false))
          : validateString().default('admin'),
      },
    ],
    break: true,
  }),
  [ENVIRONMENT_VARIABLES.ADMIN_BASIC_AUTH_PASSWORD]: Joi.when(ENVIRONMENT_VARIABLES.PLATFORM_ENV, {
    switch: [
      {
        is: Joi.valid(
          PLATFORM_ENV.PRODUCTION,
          PLATFORM_ENV.PRODUCTION_MIRROR_1,
          PLATFORM_ENV.STAGING,
        ),
        then: validateString().required(),
      },
      {
        is: PLATFORM_ENV.ENTERPRISE,
        then: validateStringWithFallback(pickEnvVar(ENVIRONMENT_VARIABLES.LICENSE, false)),
      },
      {
        is: Joi.string(),
        then: pickEnvVar(ENVIRONMENT_VARIABLES.BASIC_AUTH_PASSWORD, false)
          ? validateStringWithFallback(pickEnvVar(ENVIRONMENT_VARIABLES.BASIC_AUTH_PASSWORD, false))
          : validateString().default('admin'),
      },
    ],
    break: true,
  }),

  // helpers for mapping to one or more env variables
  [ENVIRONMENT_VARIABLES.POSTGRES_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.POSTGRES_PORT]: validateNumber().optional(),
  [ENVIRONMENT_VARIABLES.POSTGRES_USERNAME]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.POSTGRES_PASSWORD]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.POSTGRES_DATABASE]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.REDIS_URL]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.REDIS_CLUSTER_URL]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.S3_ACCESS_KEY_ID]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.S3_SECRET_ACCESS_KEY]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.S3_BUCKET]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.DOMAIN]: validateString().optional(),

  // used in all services
  [ENVIRONMENT_VARIABLES.NODE_ENV]: validateStringWithFallback(defaultNodeEnv).valid(
    ...Object.values(NODE_ENV),
  ),
  [ENVIRONMENT_VARIABLES.PLATFORM_ENV]: validateStringWithFallback(currentPlatformEnv)
    .valid(...Object.values(PLATFORM_ENV))
    .required(),
  [ENVIRONMENT_VARIABLES.HOST_ENV]: validateString() // currently only used in hercules
    .optional()
    .valid(...Object.values(HOST_ENV))
    .default(HOST_ENV.LOCAL),
  [ENVIRONMENT_VARIABLES.VERSION]: validateStringWithFallback(defaultVersion),
  [ENVIRONMENT_VARIABLES.PORT]: validateNumber().required(),
  [ENVIRONMENT_VARIABLES.LOG_LEVEL]: validateString()
    .default(defaultLogLevel)
    .lowercase()
    .trim()
    .valid('trace', 'debug', 'warn', 'info', 'error', 'fatal'),
  [ENVIRONMENT_VARIABLES.LOG_EXPAND]: validateBoolean(),
  [ENVIRONMENT_VARIABLES.LOG_TYPEORM]: validateBoolean().default(true),
  [ENVIRONMENT_VARIABLES.VOLUME_PATH]: validateStringWithFallback(defaultVolumePath),
  [ENVIRONMENT_VARIABLES.HTTP_PROXY]: validateString(),

  // used in some services
  [ENVIRONMENT_VARIABLES.CDN_PUBLIC_URL]: Joi.when(ENVIRONMENT_VARIABLES.NODE_ENV, {
    is: NODE_ENV.DEVELOPMENT,
    then: validateString().optional(),
    otherwise: validateStringWithFallback(defaultCdnUrl, false),
  }),

  // cerberus specific config
  [ENVIRONMENT_VARIABLES.CERBERUS_PORT]: validateNumberWithFallback(defaultCerberusPort),
  [ENVIRONMENT_VARIABLES.CERBERUS_POSTGRES_HOST]: validateStringWithFallback(defaultPostgresHost),
  [ENVIRONMENT_VARIABLES.CERBERUS_POSTGRES_PORT]: validateNumberWithFallback(defaultPostgresPort),
  [ENVIRONMENT_VARIABLES.CERBERUS_POSTGRES_USERNAME]:
    validateStringWithFallback(defaultPostgresUsername),
  [ENVIRONMENT_VARIABLES.CERBERUS_POSTGRES_PASSWORD]:
    validateStringWithFallback(defaultPostgresPassword),
  [ENVIRONMENT_VARIABLES.CERBERUS_POSTGRES_DATABASE]:
    validateStringWithFallback(defaultPostgresDatabase),

  // chronos specific config
  [ENVIRONMENT_VARIABLES.CHRONOS_PORT]: validateNumberWithFallback(defaultChronosPort),
  [ENVIRONMENT_VARIABLES.CHRONOS_PUBLIC_URL]: validateStringWithFallback(defaultChronosPublicUrl),
  [ENVIRONMENT_VARIABLES.CHRONOS_PRIVATE_URL]: validateStringWithFallback(defaultChronosPrivateUrl),

  // connect specific config
  [ENVIRONMENT_VARIABLES.CONNECT_PORT]: validateNumberWithFallback(defaultConnectPort),

  // embassy specific config
  [ENVIRONMENT_VARIABLES.EMBASSY_PORT]: validateNumberWithFallback(defaultEmbassyPort),
  [ENVIRONMENT_VARIABLES.EMBASSY_JWT_SECRET]: validateStringWithFallback(defaultEmbassyJwtSecret),

  // hades specific config
  [ENVIRONMENT_VARIABLES.HADES_ACCESS_TOKEN]: validateStringWithFallback(defaultHadesAccessToken),
  [ENVIRONMENT_VARIABLES.HADES_PORT]: validateNumberWithFallback(defaultHadesPort),
  [ENVIRONMENT_VARIABLES.HADES_PUBLIC_URL]: validateStringWithFallback(defaultHadesPublicUrl),
  [ENVIRONMENT_VARIABLES.HADES_PRIVATE_URL]: validateStringWithFallback(defaultHadesPrivateUrl),

  // hercules specific config
  [ENVIRONMENT_VARIABLES.LOG_HERCULES_MONITOR]: validateBoolean().optional().default(false),
  [ENVIRONMENT_VARIABLES.HERCULES_PORT]: validateNumberWithFallback(defaultHerculesPort),
  [ENVIRONMENT_VARIABLES.HERCULES_QUEUE_CLEANUP_FREQUENCY]: validateNumber()
    .optional()
    .default(5 * 60 * 1000),
  [ENVIRONMENT_VARIABLES.HERCULES_QUEUE_ID]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.HERCULES_QUEUE_DISABLED]: validateBoolean().optional().default(false),
  [ENVIRONMENT_VARIABLES.HERCULES_LARGE_ARRAY_THRESHOLD]: validateNumberWithFallback(50),
  [ENVIRONMENT_VARIABLES.HERCULES_TESTING_ARRAY_LIMIT]: validateNumberWithFallback(20),
  [ENVIRONMENT_VARIABLES.HERCULES_CLUSTER_DISABLED]: validateBoolean().optional().default(false),
  [ENVIRONMENT_VARIABLES.HERCULES_CLUSTER_MAX_INSTANCES]: validateNumber().optional().default(-1),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_SELF_DISABLED]: validateBoolean()
    .optional()
    .default(false),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_GLOBAL_DISABLED]: validateBoolean()
    .optional()
    .default(false),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_TERMINATE_UNHEALTHY_SERVERS]: validateBoolean()
    .optional()
    .default(true),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_SHUTDOWN_ON_EMPTY_QUEUE]: validateBoolean()
    .optional()
    .default(false),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_SHUTDOWN_GRACE_MILLISECONDS]: validateNumber()
    .optional()
    .default(1000 * 30),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_HEARTBEAT_EXPIRATION_SECONDS]: validateNumber()
    .optional()
    .default(10),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_PROVISION_EXPIRATION_SECONDS]: validateNumber()
    .optional()
    .default(10),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_INSTANCE_LOCK_EXPIRATION_MILLISECONDS]: validateNumber()
    .optional()
    .default(2500),
  [ENVIRONMENT_VARIABLES.HERCULES_MONITOR_GLOBAL_LOCK_EXPIRATION_MILLISECONDS]: validateNumber()
    .optional()
    .default(2500),
  [ENVIRONMENT_VARIABLES.HERCULES_PARALLEL_PROCESSING_COUNT]: validateNumber()
    .optional()
    .default(1),
  [ENVIRONMENT_VARIABLES.PLATO_PARALLEL_PROCESSING_COUNT]: validateNumber().optional().default(1),
  [ENVIRONMENT_VARIABLES.HERCULES_FUNCTION_WORKER_ENABLED]: validateBoolean()
    .optional()
    .default(currentPlatformEnv !== PLATFORM_ENV.ENTERPRISE),

  // hermes specific config
  [ENVIRONMENT_VARIABLES.HERMES_PORT]: validateNumberWithFallback(defaultHermesPort),
  [ENVIRONMENT_VARIABLES.HERMES_ALERT_PREFIX]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_HOST]: validateStringWithFallback(defaultPostgresHost),
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_PORT]: validateNumberWithFallback(defaultPostgresPort),
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_USERNAME]:
    validateStringWithFallback(defaultPostgresUsername),
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_PASSWORD]:
    validateStringWithFallback(defaultPostgresPassword),
  [ENVIRONMENT_VARIABLES.HERMES_POSTGRES_DATABASE]:
    validateStringWithFallback(defaultPostgresDatabase),
  [ENVIRONMENT_VARIABLES.TASK_HISTORY_LOGS_DISABLED]: validateBoolean().optional().default(false),

  //passport specific config
  [ENVIRONMENT_VARIABLES.PASSPORT_PORT]: validateNumberWithFallback(defaultPassportPort),

  // plato specific config
  [ENVIRONMENT_VARIABLES.PLATO_PORT]: validateNumberWithFallback(defaultPlatoPort),
  [ENVIRONMENT_VARIABLES.PLATO_PUBLIC_URL]: validateStringWithFallback(defaultPlatoPublicUrl),
  [ENVIRONMENT_VARIABLES.PLATO_PRIVATE_URL]: validateStringWithFallback(defaultPlatoPrivateUrl),
  //pheme specific config
  [ENVIRONMENT_VARIABLES.PHEME_PORT]: validateNumberWithFallback(defaultPhemePort),
  [ENVIRONMENT_VARIABLES.PHEME_PUBLIC_URL]: validateStringWithFallback(defaultPhemePublicUrl),
  [ENVIRONMENT_VARIABLES.PHEME_PRIVATE_URL]: validateStringWithFallback(defaultPhemePrivateUrl),
  [ENVIRONMENT_VARIABLES.PHEME_ACCESS_TOKEN]: validateStringWithFallback(defaultPhemeAccessToken),

  // zeus specific config
  [ENVIRONMENT_VARIABLES.ZEUS_PORT]: validateNumberWithFallback(defaultZeusPort),
  [ENVIRONMENT_VARIABLES.ZEUS_POSTGRES_HOST]: validateStringWithFallback(defaultPostgresHost),
  [ENVIRONMENT_VARIABLES.ZEUS_POSTGRES_PORT]: validateNumberWithFallback(defaultPostgresPort),
  [ENVIRONMENT_VARIABLES.ZEUS_POSTGRES_USERNAME]:
    validateStringWithFallback(defaultPostgresUsername),
  [ENVIRONMENT_VARIABLES.ZEUS_POSTGRES_PASSWORD]:
    validateStringWithFallback(defaultPostgresPassword),
  [ENVIRONMENT_VARIABLES.ZEUS_POSTGRES_DATABASE]:
    validateStringWithFallback(defaultPostgresDatabase),
  [ENVIRONMENT_VARIABLES.ZEUS_JWT_SECRET]: validateStringWithFallback(defaultZeusJwtSecret),
  [ENVIRONMENT_VARIABLES.ONBOARDING_POSTGRES_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.ONBOARDING_POSTGRES_PORT]: validateNumber().optional(),
  [ENVIRONMENT_VARIABLES.ONBOARDING_POSTGRES_USERNAME]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.ONBOARDING_POSTGRES_PASSWORD]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.ONBOARDING_POSTGRES_DATABASE]: validateString().optional(),

  [ENVIRONMENT_VARIABLES.OPERATIONS_POSTGRES_IP_WHITELIST]: requireOnlyInCi(validateString()),

  // dashboard specific config
  [ENVIRONMENT_VARIABLES.DASHBOARD_PORT]: validateNumberWithFallback(defaultDashboardPort),
  [ENVIRONMENT_VARIABLES.BASIC_AUTH_USER]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.BASIC_AUTH_PASSWORD]: validateString().optional(),

  // cerberus networking config
  [ENVIRONMENT_VARIABLES.CERBERUS_ACCESS_TOKEN]: validateStringWithFallback(
    defaultCerberusAccessToken,
  ),
  [ENVIRONMENT_VARIABLES.CERBERUS_PUBLIC_URL]: validateStringWithFallback(defaultCerberusPublicUrl),
  [ENVIRONMENT_VARIABLES.CERBERUS_PRIVATE_URL]:
    validateStringWithFallback(defaultCerberusPrivateUrl),

  // connect networking config
  [ENVIRONMENT_VARIABLES.CONNECT_PUBLIC_URL]: validateStringWithFallback(defaultConnectPublicUrl),
  [ENVIRONMENT_VARIABLES.CONNECT_PRIVATE_URL]: validateStringWithFallback(defaultConnectPrivateUrl),

  // embassy networking config
  [ENVIRONMENT_VARIABLES.EMBASSY_PUBLIC_URL]: validateEnterpriseEnvWithFallback(
    true,
    false,
    defaultEmbassyPublicUrl,
  ),
  [ENVIRONMENT_VARIABLES.EMBASSY_PRIVATE_URL]: validateEnterpriseEnvWithFallback(
    true,
    false,
    defaultEmbassyPrivateUrl,
  ),

  // hercules networking config
  [ENVIRONMENT_VARIABLES.HERCULES_PUBLIC_URL]: validateStringWithFallback(defaultHerculesPublicUrl),
  [ENVIRONMENT_VARIABLES.HERCULES_PRIVATE_URL]:
    validateStringWithFallback(defaultHerculesPrivateUrl),

  // hermes networking config
  [ENVIRONMENT_VARIABLES.HERMES_ACCESS_TOKEN]: validateStringWithFallback(defaultHermesAccessToken),
  [ENVIRONMENT_VARIABLES.HERMES_PUBLIC_URL]: validateStringWithFallback(defaultHermesPublicUrl),
  [ENVIRONMENT_VARIABLES.HERMES_PRIVATE_URL]: validateStringWithFallback(defaultHermesPrivateUrl),

  //passport networking config
  [ENVIRONMENT_VARIABLES.PASSPORT_PUBLIC_URL]: validateStringWithFallback(defaultPassportPublicUrl),
  [ENVIRONMENT_VARIABLES.PASSPORT_PRIVATE_URL]:
    validateStringWithFallback(defaultPassportPrivateUrl),
  [ENVIRONMENT_VARIABLES.PASSPORT_PRODUCTION_URL]: Joi.when(ENVIRONMENT_VARIABLES.PLATFORM_ENV, {
    switch: [
      {
        is: PLATFORM_ENV.PRODUCTION,
        then: validateString().optional(),
      },
      {
        is: PLATFORM_ENV.PRODUCTION_MIRROR_1,
        then: validateString().optional(),
      },
      {
        is: PLATFORM_ENV.ENTERPRISE,
        then: validateString().optional(),
      },
    ],
    otherwise: validateStringWithFallback(defaultPassportProductionUrl),
  }),

  // zeus networking config
  [ENVIRONMENT_VARIABLES.ZEUS_ACCESS_TOKEN]: validateStringWithFallback(defaultZeusAccessToken),
  [ENVIRONMENT_VARIABLES.ZEUS_PUBLIC_URL]: validateStringWithFallback(defaultZeusPublicUrl),
  [ENVIRONMENT_VARIABLES.ZEUS_PRIVATE_URL]: validateStringWithFallback(defaultZeusPrivateUrl),

  // dashboard networking config
  [ENVIRONMENT_VARIABLES.DASHBOARD_PUBLIC_URL]:
    validateStringWithFallback(defaultDashboardPublicUrl),
  [ENVIRONMENT_VARIABLES.DASHBOARD_PRIVATE_URL]: validateStringWithFallback(
    defaultDashboardPrivateUrl,
  ),

  // beethoven config
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_HOST]: validateStringWithFallback(defaultPostgresHost),
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_PORT]: validateNumberWithFallback(defaultPostgresPort),
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_USERNAME]:
    validateStringWithFallback(defaultPostgresUsername),
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_PASSWORD]:
    validateStringWithFallback(defaultPostgresPassword),
  [ENVIRONMENT_VARIABLES.BEETHOVEN_POSTGRES_DATABASE]:
    validateStringWithFallback(defaultPostgresDatabase),

  // pheme config
  [ENVIRONMENT_VARIABLES.PHEME_POSTGRES_HOST]: validateStringWithFallback(defaultPostgresHost),
  [ENVIRONMENT_VARIABLES.PHEME_POSTGRES_PORT]: validateNumberWithFallback(defaultPostgresPort),
  [ENVIRONMENT_VARIABLES.PHEME_POSTGRES_USERNAME]:
    validateStringWithFallback(defaultPostgresUsername),
  [ENVIRONMENT_VARIABLES.PHEME_POSTGRES_PASSWORD]:
    validateStringWithFallback(defaultPostgresPassword),
  [ENVIRONMENT_VARIABLES.PHEME_POSTGRES_DATABASE]:
    validateStringWithFallback(defaultPostgresDatabase),

  // redis config
  [ENVIRONMENT_VARIABLES.DEBUG_REDIS]: validateBoolean().optional().default(false),
  [ENVIRONMENT_VARIABLES.CACHE_REDIS_URL]: validateStringWithFallback(defaultRedisClusterUrl),
  [ENVIRONMENT_VARIABLES.SYSTEM_REDIS_URL]: validateStringWithFallback(defaultRedisUrl),
  [ENVIRONMENT_VARIABLES.QUEUE_REDIS_URL]: validateStringWithFallback(defaultRedisUrl),
  [ENVIRONMENT_VARIABLES.WORKFLOW_REDIS_URL]: validateStringWithFallback(defaultRedisClusterUrl),
  // CACHE and QUEUE don't support cluster mode b/c of `bull` and `redlock` npm libraries
  [ENVIRONMENT_VARIABLES.CACHE_REDIS_CLUSTER_ENABLED]: validateBoolean()
    .optional()
    .default(isClusterEnabled),
  [ENVIRONMENT_VARIABLES.SYSTEM_REDIS_CLUSTER_ENABLED]: validateBoolean().optional().default(false),
  [ENVIRONMENT_VARIABLES.QUEUE_REDIS_CLUSTER_ENABLED]: validateBoolean().optional().default(false),
  [ENVIRONMENT_VARIABLES.WORKFLOW_REDIS_CLUSTER_ENABLED]: validateBoolean()
    .optional()
    .default(isClusterEnabled),

  // minio networking config
  [ENVIRONMENT_VARIABLES.MINIO_MODE]: validateStringWithFallback(defaultMinioMode).valid(
    ...Object.values(MINIO_MODE),
  ),
  [ENVIRONMENT_VARIABLES.MINIO_INSTANCE_COUNT]: validateNumberWithFallback(1),
  [ENVIRONMENT_VARIABLES.MINIO_PORT]: validateNumberWithFallback(defaultMinioPort),
  [ENVIRONMENT_VARIABLES.MINIO_BROWSER]: validateStringWithFallback('off'),
  [ENVIRONMENT_VARIABLES.MINIO_NGINX_PROXY]: validateStringWithFallback('on'),
  [ENVIRONMENT_VARIABLES.MINIO_PUBLIC_URL]: validateStringWithFallback(defaultMinioPublicUrl),
  [ENVIRONMENT_VARIABLES.MINIO_PRIVATE_URL]: validateStringWithFallback(defaultMinioPrivateUrl),
  [ENVIRONMENT_VARIABLES.MINIO_ROOT_USER]: validateStringWithFallback(defaultMinioRootUser),
  [ENVIRONMENT_VARIABLES.MINIO_ROOT_PASS]: validateStringWithFallback(defaultMinioRootPass),
  [ENVIRONMENT_VARIABLES.MINIO_SYSTEM_BUCKET]: validateStringWithFallback(defaultMinioSystemBucket),
  [ENVIRONMENT_VARIABLES.MINIO_PUBLIC_BUCKET]: validateStringWithFallback(defaultMinioPublicBucket),
  [ENVIRONMENT_VARIABLES.MINIO_MICROSERVICE_USER]: validateStringWithFallback(
    defaultMinioMicroserviceAccessKey,
  ),
  [ENVIRONMENT_VARIABLES.MINIO_MICROSERVICE_PASS]: validateStringWithFallback(
    defaultMinioMicroserviceSecretKey,
  ),
  [ENVIRONMENT_VARIABLES.MINIO_REGION]: validateStringWithFallback(defaultMinioRegion),

  // auth for 3rd party services by the microservices
  [ENVIRONMENT_VARIABLES.SEGMENT_API_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SENDGRID_API_KEY]: validateString().required(),
  [ENVIRONMENT_VARIABLES.SENDGRID_FROM_ADDRESS]: validateString().required(),
  [ENVIRONMENT_VARIABLES.RESET_PASSWORD_TEMPLATE_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.RESET_PASSWORD_TOKEN_ENCRYPTION_KEY]: validateStringWithFallback(
    defaultResetPasswordTokenEncryptionKey,
  ),
  [ENVIRONMENT_VARIABLES.SENDGRID_TEAM_INVITATION_TEMPLATE_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_ANALYTICS_TRACKING_ID]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.SENTRY_DSN]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.STRIPE_API_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.STRIPE_WEBHOOK_SIGNING_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.INTERCOM_APP_ID]: validateEnterpriseEnv(
    false,
    PRODUCTION_AND_STAGING_PLATFORM_ENVS,
  ),
  [ENVIRONMENT_VARIABLES.INTERCOM_APP_TOKEN]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.COOKIE_CONSENT_SDK_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.HEADWAY_ACCOUNT_ID]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.SEGMENT_ANALYTICS_JS_WRITE_KEY]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.STRIPE_PUBLIC_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_TAG_MANAGER_ID]: validateString().optional(),

  // auth for 3rd party services used in actions
  [ENVIRONMENT_VARIABLES.AIRTABLE_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SLACK_APP_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SLACK_APP_SECRET_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SALESFORCE_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SALESFORCE_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.HUBSPOT_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.HUBSPOT_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.INTERCOM_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.INTERCOM_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.QUICKBOOKS_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.QUICKBOOKS_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.QUICKBOOKS_CLIENT_ID_SANDBOX]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.QUICKBOOKS_CLIENT_SECRET_SANDBOX]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.JIRA_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.JIRA_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ASANA_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ASANA_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SHOPIFY_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SHOPIFY_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ZENDESK_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ZENDESK_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.MICROSOFT_TEAMS_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.MICROSOFT_TEAMS_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.MICROSOFT_TEAMS_BOT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ZOOM_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ZOOM_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.MICROSOFT_DYNAMICS_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.MICROSOFT_DYNAMICS_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.PARDOT_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.PARDOT_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.XERO_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.XERO_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.OUTREACH_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.OUTREACH_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.FACEBOOK_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.FACEBOOK_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.MAILCHIMP_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.MAILCHIMP_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.TRELLO_APP_NAME]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.AZURE_DEVOPS_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.AZURE_DEVOPS_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.CLICKUP_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.CLICKUP_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SHAREPOINT_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SHAREPOINT_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.OUTLOOK_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.OUTLOOK_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ELOQUA_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ELOQUA_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.NETSUITE_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.NETSUITE_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DYNAMICS_BUSINESS_CENTRAL_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DYNAMICS_BUSINESS_CENTRAL_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.PIPEDRIVE_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.PIPEDRIVE_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SAGE_INTACCT_SENDER_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SAGE_INTACCT_SENDER_PASSWORD]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.LINEAR_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.LINEAR_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GONG_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GONG_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DYNAMICS_365_FINANCE_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DYNAMICS_365_FINANCE_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SAGE_ACCOUNTING_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SAGE_ACCOUNTING_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.IMANAGE_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.IMANAGE_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.BAMBOO_HR_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.BAMBOO_HR_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.BAMBOO_HR_APPLICATION_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GITHUB_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GITHUB_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_AD_MANAGER_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_AD_MANAGER_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DROPBOX_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DROPBOX_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DOCUSIGN_INTEGRATION_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DOCUSIGN_SECRET_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DOCUSIGN_DEVELOPMENT_INTEGRATION_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.DOCUSIGN_DEVELOPMENT_SECRET_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_ADS_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_ADS_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GOOGLE_ADS_DEVELOPER_TOKEN]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.LEVER_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.LEVER_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.LEVER_SANDBOX_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.LEVER_SANDBOX_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.CALENDLY_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.CALENDLY_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.TABLEAU_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.TABLEAU_CLIENT_SECRET_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.TABLEAU_CLIENT_SECRET_VALUE]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ZOHO_CRM_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.ZOHO_CRM_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.TWITTER_CONSUMER_API_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.TWITTER_CONSUMER_SECRET_KEY]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SALESLOFT_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.SALESLOFT_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.BIGQUERY_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.BIGQUERY_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.POWER_BI_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.POWER_BI_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GUSTO_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GUSTO_CLIENT_SECRET]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GUSTO_DEMO_CLIENT_ID]: validateEnterpriseEnv(),
  [ENVIRONMENT_VARIABLES.GUSTO_DEMO_CLIENT_SECRET]: validateEnterpriseEnv(),

  // used for building 3rd party images for monitoring services
  [ENVIRONMENT_VARIABLES.MONITOR_PGADMIN_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.MONITOR_PGADMIN_PORT]: validateNumber().optional().default(5050),
  [ENVIRONMENT_VARIABLES.MONITOR_PGADMIN_EMAIL]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.MONITOR_PGADMIN_PASSWORD]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.MONITOR_PGADMIN_SSL_MODE]: validateString()
    .optional()
    .allow('disable', 'require', 'prefer')
    .default('require'),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_PORT]: validateNumber()
    .optional()
    // when deploying in a remote environment, we want grafana to always running on port 80
    // this is needed otherwise forwarding traffic from the load balancers causes issues on browsers
    // this might be able to be fixed by modifying the terraform config for the alb + security rules

    .default(pickEnvVar(ENVIRONMENT_VARIABLES.CI, false) === 'true' ? 80 : 4500),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SERVER_DOMAIN]: validateString(),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SECURITY_ADMIN_USER]: requireOnlyInCi(
    validateStringWithFallback('admin'),
  ),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SECURITY_ADMIN_PASSWORD]: requireOnlyInCi(
    validateString(),
  ),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_AUTH_GITHUB_CLIENT_ID]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_AUTH_GITHUB_CLIENT_SECRET]: requireOnlyInCi(
    validateString(),
  ),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SLACK_CANARY_CHANNEL]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SLACK_CANARY_BETA_CHANNEL]: requireOnlyInCi(
    validateStringWithFallback(
      pickEnvVar(ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SLACK_CANARY_CHANNEL, false),
    ),
  ),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SLACK_CANARY_WEBHOOK_URL]: requireOnlyInCi(
    validateString(),
  ),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SLACK_CANARY_BETA_WEBHOOK_URL]: requireOnlyInCi(
    validateStringWithFallback(
      pickEnvVar(ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_SLACK_CANARY_WEBHOOK_URL, false),
    ),
  ),

  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_AWS_ACCESS_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_AWS_SECRET_KEY]: validateString().required(),
  [ENVIRONMENT_VARIABLES.MONITOR_GRAFANA_ALB_ARN]: validateString(),

  [ENVIRONMENT_VARIABLES.MONITOR_PROMETHEUS_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.MONITOR_PROMETHEUS_PORT]: validateNumber().optional().default(9090),
  [ENVIRONMENT_VARIABLES.MONITOR_BULL_EXPORTER_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.MONITOR_BULL_EXPORTER_PORT]: validateNumber().optional().default(9538),
  [ENVIRONMENT_VARIABLES.MONITOR_REDIS_EXPORTER_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.MONITOR_REDIS_EXPORTER_PORT]: validateNumber().optional().default(9121),
  [ENVIRONMENT_VARIABLES.MONITOR_POSTGRES_EXPORTER_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.MONITOR_POSTGRES_EXPORTER_PORT]: validateNumber().optional().default(9187),
  [ENVIRONMENT_VARIABLES.MONITOR_POSTGRES_EXPORTER_SSL_MODE]: validateString()
    .allow('disable', 'require', 'prefer')
    .default('require'),
  [ENVIRONMENT_VARIABLES.MONITOR_REDIS_INSIGHT_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.MONITOR_REDIS_INSIGHT_PORT]: validateNumber()
    .optional()
    // when deploying in a remote environment, we want the monitors always running on port 80
    // this is needed otherwise forwarding traffic from the load balancers causes issues on browsers
    // this might be able to be fixed by modifying the terraform config for the alb + security rules
    .default(pickEnvVar(ENVIRONMENT_VARIABLES.CI, false) === 'true' ? 80 : 8500),
  [ENVIRONMENT_VARIABLES.MONITOR_BEETHOVEN_EXPORTER_HOST]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.MONITOR_BEETHOVEN_EXPORTER_PORT]: validateNumber()
    .optional()
    .default(8501),
  [ENVIRONMENT_VARIABLES.MONITOR_CACHE_REDIS_TARGETS]: validateString(),
  [ENVIRONMENT_VARIABLES.MONITOR_QUEUE_REDIS_TARGET]: validateString(),

  [ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_PORT]:
    validateNumberWithFallback(defaultStatusPagePort),
  [ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_NAME]:
    validateStringWithFallback(defaultStatusPageName),
  [ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_ADMIN_USER]: validateStringWithFallback(
    defaultStatusPageAdminUser,
  ),
  [ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_ADMIN_PASSWORD]: validateStringWithFallback(
    defaultStatusPageAdminPassword,
  ),
  [ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_SLACK_WEBHOOK_URL]: requireOnlyInCi(validateString()),
  [ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_API_SECRET]: validateStringWithFallback(
    defaultStatusPageApiSecret,
  ),
  [ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_PUBLIC_URL]: validateString(),

  // deprecated
  [ENVIRONMENT_VARIABLES.SMTP_HOST]: validateString().required(),
  [ENVIRONMENT_VARIABLES.SMTP_PORT]: validateString().required(),
  [ENVIRONMENT_VARIABLES.SMTP_USERNAME]: validateString().required(),
  [ENVIRONMENT_VARIABLES.SMTP_PASSWORD]: validateString().required(),

  [ENVIRONMENT_VARIABLES.E2E_POSTGRES_DATABASE]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_POSTGRES_HOST]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_POSTGRES_PORT]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_POSTGRES_USER]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_POSTGRES_PASSWORD]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_MYSQL_HOST]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_MYSQL_DATABASE]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_MYSQL_PORT]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_MYSQL_USER]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_MYSQL_PASSWORD]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_TWILIO_ACCOUNT_SID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_TWILIO_AUTH_TOKEN]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_TWILIO_NUMBER]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_TWILIO_SANDBOX_ACCOUNT_SID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_TWILIO_SANDBOX_AUTH_TOKEN]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_TWILIO_SANDBOX_NUMBER]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_TWILIO_RECIPIENT]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_FIREBASE_DATABASE_URL]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_FIREBASE_PROJECT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_FIREBASE_SERVICE_ACCOUNT]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_GOOGLE_CLIENT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_GOOGLE_CLIENT_SECRET]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_GOOGLE_CLIENT_REFRESH_TOKEN]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_STRIPE_SECRET_KEY]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_HUBSPOT_REFRESH_TOKEN]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_HUBSPOT_CLIENT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_HUBSPOT_CLIENT_SECRET]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_JIRA_CLIENT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_JIRA_CLIENT_SECRET]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_JIRA_CLOUD_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_JIRA_REFRESH_TOKEN]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_JIRA_PROVIDER_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_QUICKBOOKS_REALM_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_QUICKBOOKS_CLIENT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_QUICKBOOKS_CLIENT_SECRET]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_QUICKBOOKS_REFRESH_TOKEN]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_AWS_ACCESS_KEY_ID]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.E2E_AWS_SECRET_ACCESS_KEY]: validateString().optional(),
  [ENVIRONMENT_VARIABLES.E2E_AWS_REGION]: validateString().optional().default('us-east-1'),
  [ENVIRONMENT_VARIABLES.E2E_SALESFORCE_CLIENT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_SALESFORCE_CLIENT_SECRET]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_SALESFORCE_REFRESH_TOKEN]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_SALESFORCE_PROVIDER_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_GITHUB_CLIENT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_GITHUB_CLIENT_SECRET]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_GITHUB_USERNAME]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_GITHUB_PASSWORD]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_INTERCOM_CLIENT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_INTERCOM_CLIENT_SECRET]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_INTERCOM_EMAIL]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_INTERCOM_PASSWORD]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_BYO_CLIENT_ID]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_BYO_CLIENT_SECRET]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_BYO_EMAIL]: validateString().required(),
  [ENVIRONMENT_VARIABLES.E2E_BYO_PASSWORD]: validateString().required(),
};

export const FORBIDDEN_ENVIRONMENT_VARIABLE_VALIDATIONS: Record<
  FORBIDDEN_ENVIRONMENT_VARIABLES,
  Joi.Schema
> = {
  [FORBIDDEN_ENVIRONMENT_VARIABLES.AWS_ACCESS_KEY_ID]: Joi.forbidden(),
  [FORBIDDEN_ENVIRONMENT_VARIABLES.AWS_SECRET_ACCESS_KEY]: Joi.forbidden(),
};

/**
 * wrapper around pickEnvVar function to throw error if variable is undefined
 * @param name
 * @param validate
 * @param env
 * @returns
 */
export function pickRequiredEnvVar<T = string>(
  name: ENVIRONMENT_VARIABLES,
  validate: boolean = true,
  env: Record<string, string | boolean> | NodeJS.ProcessEnv = process.env,
): T {
  const value: T | undefined = pickEnvVar<T>(name, validate, env);
  if (!value) {
    throw new MissingEnvironmentVariablesError({
      variables: [name],
    });
  }
  return value;
}
