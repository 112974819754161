import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export enum CredentialKeys {
  API_TOKEN = 'TRELLO_API_TOKEN',
  API_KEY = 'TRELLO_API_KEY',
}

export type Credentials = {
  TRELLO_API_KEY: string;
  TRELLO_API_TOKEN: string;
};

export type Environment = {
  TRELLO_APP_NAME: string;
  TRELLO_SCOPES: string;
};

export type ActionConfig = BaseActionConfig & {
  actionType: Action.TRELLO;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.TRELLO;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'TRELLO_TEST_CONNECTION',
  SEARCH_CARDS = 'TRELLO_SEARCH_CARDS',
  GET_CARDS_IN_BOARD = 'TRELLO_GET_CARDS_IN_BOARD',
  CREATE_CARD = 'TRELLO_CREATE_CARD',
  UPDATE_CARD = 'TRELLO_UPDATE_CARD',
  DELETE_CARD = 'TRELLO_DELETE_CARD',
  GET_LISTS_IN_BOARD = 'TRELLO_GET_LISTS_IN_BOARD',
  SEARCH_BOARDS = 'TRELLO_SEARCH_BOARDS',
  GET_BOARDS_MEMBER_BELONG_TO = 'TRELLO_GET_BOARDS_MEMBER_BELONG_TO',

  TRIGGER_CARD_CREATED = 'TRELLO_TRIGGER_CARD_CREATED',
  TRIGGER_CARD_UPDATED = 'TRELLO_TRIGGER_CARD_UPDATED',
  TRIGGER_COMMENT_CREATED = 'TRELLO_TRIGGER_COMMENT_CREATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_CARDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CARDS_IN_BOARD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CARD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CARD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_CARD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LISTS_IN_BOARD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_BOARDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_BOARDS_MEMBER_BELONG_TO;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_CARD_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_CARD_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_COMMENT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );
