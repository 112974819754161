export const DYNAMICS_BUSINESS_CENTRAL_API_BASE_URL =
  'https://api.businesscentral.dynamics.com/v2.0/production/api/v2.0/companies([Company ID])/';
export const DYNAMICS_BUSINESS_CENTRAL_AUTH_BASE_URL =
  'https://login.microsoftonline.com/common/oauth2';
export const DYNAMICS_BUSINESS_CENTRAL_RESOURCE_URL = 'https://api.businesscentral.dynamics.com';

export const inputIdMapper = {
  vendors: 'vendorId',
  purchaseInvoices: 'purchaseInvoiceId',
  purchaseInvoiceLines: 'purchaseInvoiceLineId',
  taxGroups: 'taxGroupId',
  paymentTerms: 'paymentTermsId',
  accounts: 'accountId',
};
