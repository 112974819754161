import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.QUICKBOOKS;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'QUICKBOOKS_TEST_CONNECTION',
  GET_ACCOUNTS = 'QUICKBOOKS_GET_ACCOUNTS',
  GET_BILLS = 'QUICKBOOKS_GET_BILLS',
  CREATE_BILL = 'QUICKBOOKS_CREATE_BILL',
  GET_CUSTOMERS = 'QUICKBOOKS_GET_CUSTOMERS',
  CREATE_CUSTOMER = 'QUICKBOOKS_CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'QUICKBOOKS_UPDATE_CUSTOMER',
  GET_INVOICES = 'QUICKBOOKS_GET_INVOICES',
  CREATE_INVOICE = 'QUICKBOOKS_CREATE_INVOICE',
  SEND_INVOICE = 'QUICKBOOKS_SEND_INVOICE',
  GET_PAYMENTS = 'QUICKBOOKS_GET_PAYMENTS',
  CREATE_PAYMENT = 'QUICKBOOKS_CREATE_PAYMENT',
  GET_VENDORS = 'QUICKBOOKS_GET_VENDORS',
  UPDATE_INVOICE = 'QUICKBOOKS_UPDATE_INVOICE',

  TRIGGER_ACCOUNT_CREATED = 'QUICKBOOKS_TRIGGER_ACCOUNT_CREATED',
  TRIGGER_CUSTOMER_CREATED = 'QUICKBOOKS_TRIGGER_CUSTOMER_CREATED',
  TRIGGER_INVOICE_CREATED = 'QUICKBOOKS_TRIGGER_INVOICE_CREATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ACCOUNTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_BILLS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_BILL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CUSTOMERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CUSTOMER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CUSTOMER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_INVOICES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_INVOICE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PAYMENTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PAYMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_VENDORS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ACCOUNT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_CUSTOMER_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_INVOICE_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  QUICKBOOKS_OAUTH_ACCESS_TOKEN: string;
  QUICKBOOKS_OAUTH_REFRESH_TOKEN: string;
  QUICKBOOKS_REALM_ID: string;
  QUICKBOOKS_IS_SANDBOX: boolean;
  SCOPES?: string;
};
