import React from 'react';

import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';
import { DataType, KeyedSource, ValueSource } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const oneSignalKeysIdsDocsLink = (
  <a
    href="https://documentation.onesignal.com/docs/accounts-and-keys#section-keys-ids"
    target="_blank"
    rel="noreferrer"
  >
    Keys & IDs.
  </a>
);

const oneSignalRestAPIDocsLink = (
  <a
    href="https://documentation.onesignal.com/docs/accounts-and-keys#rest-api-key"
    target="_blank"
    rel="noreferrer"
  >
    Keys & IDs.
  </a>
);

const oneSignalFiltersDocsLink = (
  <a
    href="https://documentation.onesignal.com/reference/create-notification#send-to-users-based-on-filters"
    target="_blank"
    rel="noreferrer"
  >
    OneSignal&apos;s documentation
  </a>
);

export const authConfig: ActionConfig = {
  actionType: Action.ONESIGNAL,
  provider: ProviderType.ONESIGNAL,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your OneSignal account',
  description: 'Add OneSignal to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this OneSignal account',
          placeholder: 'eg. Production Testing etc.',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'APP_ID',
          title: 'Add your OneSignal App ID',
          subtitle: (
            <>Your OneSignal application ID, which can be found in {oneSignalKeysIdsDocsLink}</>
          ),
          placeholder: '8250eaf6-1a58-489e-b136-7c74a864b434',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'REST_API_KEY',
          title: 'Add your OneSignal REST API Key',
          subtitle: <>Found directly below your App ID in {oneSignalRestAPIDocsLink}</>,
          placeholder: 'OneSignal REST API Key',
          type: SidebarInputType.ValueText,
        },
      ],
      buttons: [createTestConnectionButton(Action.ONESIGNAL, ['APP_ID', 'REST_API_KEY'])],
    },
  ],
};

const enterContentSideBarInputs: SidebarInput[] = [
  {
    id: 'contents',
    type: SidebarInputType.Code,
    title: 'Contents',
    placeholder: '{"en": "English Message", "es": "Spanish Message"}',
    lines: 1,
    language: 'JSON',
    useLightTheme: true,
    resizeOnOverflow: true,
  },
  {
    id: 'headings',
    type: SidebarInputType.Code,
    title: 'Headings',
    placeholder: '{"en": "English Title", "es": "Spanish Title"}',
    lines: 1,
    language: 'JSON',
    useLightTheme: true,
    resizeOnOverflow: true,
  },
  {
    id: 'subtitle',
    type: SidebarInputType.Code,
    title: 'Subtitle',
    placeholder: '{"en": "English Subtitle", "es": "Spanish Subtitle"}',
    lines: 1,
    language: 'JSON',
    useLightTheme: true,
    resizeOnOverflow: true,
  },
];

const templateSidebarInputs: SidebarInput[] = [
  {
    id: 'templateId',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Template ID',
    placeholder: 'be4a8044-bbd6-11e4-a581-000c2940e62c',
    subtitle:
      'Provide the template UUID found in the URL when viewing a template in your OneSignal dashboard.',
    required: false,
  },
];
const config: ActionConfig = {
  actionType: Action.ONESIGNAL,
  name: 'OneSignal',
  description: 'Send notifications to your users with OneSignal.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Choose your OneSignal Account',
          placeholder: 'choose your OneSignal account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.SEND_TO_SEGMENT,
                    label: 'Send notification to segment',
                  },
                  {
                    value: Intent.SEND_TO_PLAYER_ID,
                    label: 'Send notification to specific devices (player IDs)',
                  },
                  {
                    value: Intent.SEND_TO_EXTERNAL_ID,
                    label: 'Send notification to specific devices (external IDs)',
                  },
                  {
                    value: Intent.SEND_USING_FILTERS,
                    label: 'Send notification to users based on filters',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      if (parameters.credentials?.length) {
        switch (parameters.intent) {
          case Intent.SEND_TO_SEGMENT:
            return {
              inputs: [
                {
                  id: 'includedSegments',
                  title: 'Included segments',
                  subtitle:
                    'Choose one or more user segments to send to. You can enter an array or a list of segments separated by commas.',
                  type: SidebarInputType.TextArea,
                  lines: 1,
                  placeholder: 'Active Users, Inactive Users',
                },
                {
                  id: 'excludedSegments',
                  type: SidebarInputType.TextArea,
                  lines: 1,
                  title: 'Excluded segments',
                  subtitle: (
                    <>
                      Users in these segments will not receive a notification, even if they were
                      included in <b>Included Segments</b>.
                    </>
                  ),
                  required: false,
                },
              ],
            };
          case Intent.SEND_TO_PLAYER_ID:
            return {
              inputs: [
                {
                  id: 'includedPlayerIds',
                  title: 'Included player IDs',
                  subtitle:
                    'Specific players to send your notification to. Limit 2,000 entries per request.',
                  type: SidebarInputType.TextArea,
                  lines: 1,
                  placeholder: '["1dd608f2-c6a1-11e3-851d-000c2940e62c"]',
                },
              ],
            };
          case Intent.SEND_TO_EXTERNAL_ID:
            return {
              inputs: [
                {
                  id: 'includedExternalIds',
                  title: 'Included external user IDs',
                  subtitle:
                    'Target specific devices by custom user IDs assigned via API. Limit 2,000 entries per request.',
                  type: SidebarInputType.TextArea,
                  lines: 1,
                  placeholder: '["1dd608f2-c6a1-11e3-851d-000c2940e62c"]',
                },
              ],
            };
          case Intent.SEND_USING_FILTERS:
            return {
              inputs: [
                {
                  id: 'filters',
                  title: 'Filters',
                  subtitle: (
                    <>
                      Targets notifications recipients based on filter conditions. See{' '}
                      {oneSignalFiltersDocsLink} for a list of filter field options.
                    </>
                  ),
                  type: SidebarInputType.Code,
                  lines: 4,
                  language: 'json',
                  useLightTheme: true,
                  placeholder: `[
{"field": "tag", "key": "level", "relation": ">", "value": "10"},
{"field": "amount_spent", "relation": ">","value": "0"}
]`,
                },
              ],
            };
          default:
            return { inputs: [] };
        }
      } else {
        return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const notificationContentSource = parameters.actionParameters.find(
        (ap: KeyedSource<DataType.ANY>) => ap.key === 'notificationContent',
      );
      const selectedNotificationContent = notificationContentSource
        ? (notificationContentSource.source as ValueSource).value
        : undefined;
      return parameters.credentials?.length && parameters.intent
        ? {
            inputs: [
              {
                id: 'notificationContent',
                type: SidebarInputType.Enum,
                title: 'Notification content',
                subtitle: 'Enter content below or use a template from your OneSignal dashboard.',
                defaultValue: 'ENTER_CONTENT',
                getValues: () => {
                  return [
                    { label: 'Enter content', value: 'ENTER_CONTENT' },
                    { label: 'Use template', value: 'USE_TEMPLATE' },
                  ];
                },
              },
              ...(selectedNotificationContent === 'ENTER_CONTENT'
                ? enterContentSideBarInputs
                : templateSidebarInputs),
              {
                id: 'contentAvailable',
                type: SidebarInputType.Enum,
                title: 'Content available',
                subtitle:
                  'Sending true wakes your app from background to run custom native code (iOS only).',
                defaultValue: 'false',
                getValues: () => {
                  return [
                    { label: 'true', value: 'true' },
                    { label: 'false', value: 'false' },
                  ];
                },
                required: false,
              },
              {
                id: 'additionalData',
                title: 'Additional data',
                subtitle: 'A custom map of data that is passed back to your app.',
                type: SidebarInputType.Code,
                placeholder: '{"key": "value"}',
                lines: 1,
                language: 'JSON',
                useLightTheme: true,
                required: false,
                resizeOnOverflow: true,
              },
              {
                id: 'url',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'URL',
                subtitle: 'The URL to open in the browser when a user clicks on the notification.',
                placeholder: 'http://www.example.com',
                required: false,
              },
              {
                id: 'buttons',
                title: 'Buttons',
                subtitle: 'Buttons to add to the notification. Icon only works for Android.',
                type: SidebarInputType.Code,
                placeholder: `[
{"id": "id1", "text": "button1", "icon": "ic_menu_share"},
{"id": "id2", "text": "button2", "icon": "ic_menu_send"}
]`,
                lines: 4,
                language: 'JSON',
                useLightTheme: true,
                required: false,
              },
            ],
          }
        : { inputs: [] };
    },
  ],
};

export default config;
