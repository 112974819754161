import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.GUSTO,
  name: 'Gusto',
  description: "Connect to your users' Gusto accounts",
  icon: iconSvg,
  provider: ProviderType.GUSTO,
  sidebarSections: [],
};
export { authConfig };
export default connectConfig;
