import { IConnectCredentialWithPersona } from '@shared/entities/sdk/credential/connectCredential.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<IConnectCredentialWithPersona> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<IConnectCredentialWithPersona> = initialState,
  action: Action,
): EntitiesState<IConnectCredentialWithPersona> {
  switch (action.type) {
    case 'CONNECT_CREDENTIAL_ENTITY_FETCH_START':
      return {
        ...state,
        processing: true,
      };
    case 'CONNECT_CREDENTIAL_ENTITY_FETCH_SUCCESS':
      return {
        ...state,
        entities: indexBy('id', action.connectCredentials),
        processing: false,
        errorMessage: undefined,
      };
    case 'CONNECT_CREDENTIAL_ENTITY_FETCH_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'INTEGRATION_WIZARD_DRAFT_LOAD':
      if (!action.payload.previewCredential) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.previewCredential.id]: {
            persona: {
              id: action.payload.previewCredential.personaId,
              endUserId: action.payload.previewCredential.projectId,
              projectId: action.payload.previewCredential.projectId,
              meta: {},
              dateCreated: new Date(),
              dateUpdated: new Date(),
              dateLastActive: new Date(),
              hasIntegrationErrors: false,
              hasWorkflowErrors: false,
              active: true,
            },
            ...action.payload.previewCredential,
          },
        },
      };
    default:
      return state;
  }
}
