export enum ParagonService {
  CERBERUS = 'cerberus',
  CHRONOS = 'chronos',
  CONNECT = 'connect',
  DASHBOARD = 'dashboard',
  EMBASSY = 'embassy',
  HADES = 'hades',
  HERCULES = 'hercules',
  HERMES = 'hermes',
  PASSPORT = 'passport',
  PLATO = 'plato',
  PHEME = 'pheme',
  ZEUS = 'zeus',
}

export enum ParagonPostgres {
  Cerberus = 'cerberus',
  Hermes = 'hermes',
  Pheme = 'pheme',
  Zeus = 'zeus',
}
export enum ParagonDatastore {
  REDIS_CACHE = 'redis-cache',
  MINIO = 'minio',
}

export enum ParagonHelperModules {
  ACTION = 'actions',
  BILLING = 'billing',
  WORKFLOW = 'workflow',
}

export enum ParagonMonitor {
  BEETHOVEN_EXPORTER = 'beethoven-exporter',
  PGADMIN = 'pgadmin',
  GRAFANA = 'grafana',
  PROMETHEUS = 'prometheus',
  REDIS_EXPORTER = 'redis-exporter',
  BULL_EXPORTER = 'bull-exporter',
  POSTGRES_EXPORTER = 'postgres-exporter',
  REDIS_INSIGHT = 'redis-insight',
  STATUS_PAGE = 'status-page',
}

export type DockerBuildMonitorOptions = {
  runFromRoot: boolean;
};

export type ParagonMonitorOptions = Record<
  Partial<ParagonMonitor>,
  Partial<DockerBuildMonitorOptions>
>;

export const ParagonMonitorOptionsMap: ParagonMonitorOptions = {
  [ParagonMonitor.PGADMIN]: {
    runFromRoot: false,
  },
  [ParagonMonitor.GRAFANA]: {
    runFromRoot: true,
  },
  [ParagonMonitor.PROMETHEUS]: {
    runFromRoot: false,
  },
  [ParagonMonitor.REDIS_EXPORTER]: {
    runFromRoot: false,
  },
  [ParagonMonitor.BULL_EXPORTER]: {
    runFromRoot: false,
  },
  [ParagonMonitor.POSTGRES_EXPORTER]: {
    runFromRoot: false,
  },
  [ParagonMonitor.REDIS_INSIGHT]: {
    runFromRoot: false,
  },
  [ParagonMonitor.BEETHOVEN_EXPORTER]: {
    runFromRoot: false,
  },
  [ParagonMonitor.STATUS_PAGE]: {
    runFromRoot: false,
  },
};

export const ParagonServicesEnumerated = [
  ParagonService.CERBERUS,
  ParagonService.CHRONOS,
  ParagonService.CONNECT,
  ParagonService.DASHBOARD,
  ParagonService.EMBASSY,
  ParagonService.HADES,
  ParagonService.HERCULES,
  ParagonService.HERMES,
  ParagonService.PASSPORT,
  ParagonService.PLATO,
  ParagonService.PHEME,
  ParagonService.ZEUS,
];

export const ParagonMonitorsEnumerated = [
  ParagonMonitor.BEETHOVEN_EXPORTER,
  ParagonMonitor.PGADMIN,
  ParagonMonitor.GRAFANA,
  ParagonMonitor.PROMETHEUS,
  ParagonMonitor.REDIS_INSIGHT,
  ParagonMonitor.BULL_EXPORTER,
  ParagonMonitor.POSTGRES_EXPORTER,
  ParagonMonitor.REDIS_INSIGHT,
  ParagonMonitor.STATUS_PAGE,
];

export enum ParagonDatabase {
  CERBERUS_POSTGRES = 'databases/cerberus/postgres',
  HERMES_POSTGRES = 'databases/hermes/postgres',
  HERMES_REDIS = 'databases/hermes/redis',
  PHEME_POSTGRES = 'databases/pheme/postgres',
  ZEUS_POSTGRES = 'databases/zeus/postgres',
}

export enum HermesRedis {
  /**
   * used for caching non-workflow / execution data, e.g. http / db calls
   */
  CACHE = 'redis/cache',

  /**
   * dedicate redis instance for bull queue
   */
  QUEUE = 'redis/queue',

  /**
   * cache for workflow executions (both live and test mode)
   */
  WORKFLOW = 'redis/workflow',

  /**
   * used for internal system messaging and info
   * NOTE: don't store large information in this db, as the `KEYS` command is used often
   */
  SYSTEM = 'redis/system',
}

export enum HermesQueue {
  // hercules / hermes
  ALERTS = 'alerts',
  CRON = 'hermes:cron',
  PROCESSOR = 'hermes:processor',
  CRON_ACTION = 'hermes:cron:action',
  CONNECT_CRON_ACTION = 'hermes:connect:cron:action',
  CONNECT_CRON_SIMPLE = 'hermes:connect:cron:simple',
  REFRESH_CREDENTIAL_PROCESSOR = 'hermes:credential:processor:refresh',

  // plato
  PLATO = 'plato:processor',

  // hades
  HADES_STEP_EXECUTION_BATCH_UPDATE = 'hades:execution:step:update',
  HADES_WORKFLOW_EXECUTION_BATCH_UPDATE = 'hades:execution:workflow:update',
  HADES_WORKFLOW_EXECUTION_COMPLETE = 'hades:execution:workflow:complete',
  HADES_STEP_EXECUTION_DATA_BATCH_UPLOAD = 'hades:execution:step:upload',
  HADES_STEP_EXECUTION_DATA_BATCH_UPLOAD_ACCUMLATOR = 'hades:execution:step:upload:accumlator',
  // chronos
  // TODO: separate these into new types later
  REFRESH_CREDENTIAL_JOB_ACCUMULATOR = 'chronos:credentials:accumulator',
  SUBSCRIPTION_SYNC = 'chronos:subscriptions:sync',
  MONITOR_EVENT_ACCUMULATOR = 'pheme:monitor_events:accumulator',
  MONITOR_EVENT_PROCESSOR = 'pheme:monitor_events:processor',
}
