import { Dispatch, MiddlewareAPI } from 'redux';

import { Step } from '@shared/types/sdk/steps';
import { workflowStepsToStateMachine } from '@shared/workflow/sdk';

import { sanitizeStep } from '../../utils';
import { NO_OP_ACTION_STEP_ID } from '../reducers/entities/step';
import { Action, State } from '../types';

const deepEqual = require('fast-deep-equal/es6');

const stateMachineUpdater =
  (store: MiddlewareAPI<Dispatch<Action>, State>) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const oldState = store.getState();
    next(action);
    const newState = store.getState();
    const workflowId = newState.navigation.workflowId;

    if (!workflowId) {
      return;
    }

    const updateStateMachine = (workflowId: string) => {
      const workflow = newState.entities.workflows.entities[workflowId];

      const stepMap = newState.entities.steps.entities;
      const allSteps: Step[] = workflow.stepIds.map((stepId: string) => stepMap[stepId]);

      // checking if any step is null or undefined for safety
      if (allSteps.some((step) => !step)) {
        return;
      }

      store.dispatch({
        type: 'SET_STATE_MACHINE',
        stateMachine: workflowStepsToStateMachine(allSteps),
      });
    };

    const oldWorkflow = oldState.entities.workflows.entities[workflowId];
    const newWorkflow = newState.entities.workflows.entities[workflowId];

    if (!newWorkflow) {
      return;
    }

    // if step entities not changed but only workflow.stepIds updated
    if (!deepEqual(oldWorkflow, newWorkflow)) {
      updateStateMachine(workflowId);
      // if workflow state changes then no need to check step entities
      return;
    }

    let sanitizedOldStepEntities = oldState.entities.steps.entities;
    let sanitizedNesStepEntities = newState.entities.steps.entities;

    for (const stepId of Object.keys(newState.entities.steps.entities)) {
      if (stepId === NO_OP_ACTION_STEP_ID) {
        continue;
      }
      const oldStep = oldState.entities.steps.entities[stepId];
      const newStep = newState.entities.steps.entities[stepId];

      sanitizedOldStepEntities = {
        ...sanitizedOldStepEntities,
        [stepId]: sanitizeStep(oldStep) as Step,
      };

      sanitizedNesStepEntities = {
        ...sanitizedNesStepEntities,
        [stepId]: sanitizeStep(newStep) as Step,
      };
    }

    if (!deepEqual(sanitizedOldStepEntities, sanitizedNesStepEntities)) {
      updateStateMachine(workflowId);
    }
  };

export default stateMachineUpdater;
