import { CredentialValues } from '@shared/entities/sdk/credential/credential.interface';

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, string>;
  DYNAMICS_365_FINANCE_BASE_URI: string;
};

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
  DYNAMICS_365_FINANCE_BASE_URI: string;
};

export type TokenExchangeResponse = {
  accessToken: string;
  refreshToken: string;
  updateCredentialValues: CredentialValues | undefined;
};

export type TokenResponse = {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
};

export type IdTokenDecodeData = {
  oid: string;
  name: string;
  email: string;
};

export type GetCustomersResponse = {
  '@odata.context': string;
  value: Record<string, any>[];
};

export type GetDefaultCompanyResponse = {
  '@odata.context': string;
  value: DefaultCompany[];
};

export type DefaultCompany = {
  UserID: string;
  Alias: string;
  Company: string;
  [x: string]: any;
};

export enum RecordType {
  ACCOUNTS = 'MainAccounts',
  VENDOR = 'Vendors',
  BILL = 'VendorInvoiceHeaders',
  BILL_LINE_ITEM = 'VendorInvoiceLines',
  CUSTOMER = 'Customers',
  INVOICE = 'FreeTextInvoiceHeaders',
  INVOICE_LINE_ITEM = 'FreeTextInvoiceLines',
  PAYMENTS_JOURNAL = 'CustomerPaymentJournalHeaders',
  PAYMENTS_JOURNAL_LINE_ITEM = 'CustomerPaymentJournalLines',
}

export type CreateOrUpdateVendorDTO = {
  vendorId: string;
  VendorAccountNumber: string;
  VendorName: string;
  VendorGroupId: string;
  OrganizationNumber?: string;
  VendorPartyType?: string;
  vendorAdditionalFields?: string;
};

export type CreateOrUpdateBillDTO = {
  billId: string;
  dataAreaId: string;
  InvoiceAccount: string;
  InvoiceNumber: string;
  InvoiceDescription?: string;
  InvoiceDate?: string;
  Date?: string;
  DueDate?: string;
  IsOnHold?: string;
  billAdditionalFields?: string;
};

export type CreateOrUpdateBillLineDTO = {
  InvoiceLineNumber: string;
  HeaderReference: string;
  ItemNumber: string;
  ProcurementCategoryName: string;
  ReceiveNow?: string;
  Unit?: string;
};

export type CreateOrUpdateCustomerDTO = {
  customerId: string;
  CustomerAccount: string;
  Name: string;
  CustomerGroupId: string;
  PartyType?: string;
  OrganizationNumber?: string;
  SalesCurrencyCode?: string;
  PartyCountry?: string;
  AddressStreet?: string;
  AddressCity?: string;
  AddressZipCode?: string;
  PrimaryContactPhone?: string;
  PrimaryContactEmail?: string;
  customerAdditionalFields?: string;
};

export type CreateOrUpdateInvoiceDTO = {
  InvoiceIdentifier: string;
  CustomerAccount: string;
  InvoiceAccount: string;
  CurrencyCode: string;
  InvoiceDate: string;
  DueDate?: string;
  MethodOfPayment?: string;
  TermsOfPayment?: string;
  IsOneTimeCustomer?: string;
};

export type CreateOrUpdateInvoiceLineDTO = {
  ParentRecId: string;
  LineNumber: string;
  MainAccountDisplayValue: string;
  Description: string;
  SalesTaxGroupId: string;
  Quantity: string;
  UnitPrice: string;
};

export type CreateOrUpdatePaymentJournalDTO = {
  JournalName: string;
  JournalBatchNumber: string;
  Description: string;
};

export type CreateOrUpdatePaymentJournalLineDTO = {
  JournalBatchNumber: string;
  LineNumber: string;
  TransactionDate: string;
  Voucher: string;
  AccountDisplayValue: string;
  MarkedInvoice: string;
  CreditAmount: string;
  CurrencyCode: string;
};

export type PreparePayload = {
  recordId: string;
  payload: Record<string, any>;
};

export type CreateRecordDTO =
  | CreateOrUpdateVendorDTO
  | CreateOrUpdateBillDTO
  | CreateOrUpdateBillLineDTO
  | CreateOrUpdateCustomerDTO
  | CreateOrUpdateInvoiceDTO
  | CreateOrUpdateInvoiceLineDTO
  | CreateOrUpdatePaymentJournalDTO
  | CreateOrUpdatePaymentJournalLineDTO;
