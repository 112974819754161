import { IntegrationEnabledInput, IntegrationEnabledStep, StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';

/**
 * validates that an integration enabled step is properly configured
 */
export default class IntegrationEnabledStepValidator extends StepValidator<
  IntegrationEnabledStep,
  IntegrationEnabledInput
> {
  stepType: IntegrationEnabledStep['type'] = StepType.INTEGRATION_ENABLED;

  /**
   * validates that a step is valid
   *
   * @param {IntegrationEnabledStep} step
   * @returns {IntegrationEnabledStep}
   * @memberof IntegrationEnabledStepValidator
   */
  validate(step: IntegrationEnabledStep): IntegrationEnabledStep {
    return step;
  }
}
