import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  campaignFieldsInputs,
  campaignFilterInput,
  campaignIdInput,
  contactEmailInput,
  contactFieldsInput,
  contactIdentifierInput,
  intentTypeInput,
  listContactFieldsInput,
  listFieldsInput,
  listFilterInput,
  listIdInput,
  listNameInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.MAILCHIMP,
  name: 'Mailchimp',
  description:
    'Connect your Mailchimp account to manage their campaigns and contacts in Mailchimp.',
  icon: iconSvg,
  provider: ProviderType.MAILCHIMP,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_CAMPAIGN:
          return { inputs: campaignFieldsInputs(true) };

        case Intent.UPDATE_CAMPAIGN:
          return {
            inputs: [
              { ...campaignIdInput, subtitle: 'The ID of the campaign to update.' },
              ...campaignFieldsInputs(true, true),
            ],
          };

        case Intent.SEND_CAMPAIGN:
          return {
            inputs: [{ ...campaignIdInput, subtitle: 'The ID of the campaign to send.' }],
          };

        case Intent.SEARCH_CAMPAIGNS:
          return {
            inputs: [campaignFilterInput],
          };

        case Intent.GET_CAMPAIGN_BY_ID:
          return {
            inputs: [{ ...campaignIdInput, subtitle: 'The ID of the campaign to get.' }],
          };

        case Intent.DELETE_CAMPAIGN_BY_ID:
          return {
            inputs: [{ ...campaignIdInput, subtitle: 'The ID of the campaign to delete.' }],
          };

        case Intent.CREATE_LIST:
          return { inputs: [listNameInput, ...listFieldsInput, ...listContactFieldsInput] };

        case Intent.SEARCH_LISTS:
          return { inputs: [listFilterInput] };

        case Intent.GET_LIST_BY_ID:
          return { inputs: [listIdInput] };

        case Intent.ADD_CONTACT_TO_LIST:
          return {
            inputs: [
              { ...listIdInput, subtitle: 'The ID of the list to add a contact to.' },
              contactEmailInput,
              ...contactFieldsInput(),
            ],
          };

        case Intent.UPDATE_CONTACT_TO_LIST:
          return {
            inputs: [
              { ...listIdInput, subtitle: 'The ID of the list to add a contact to.' },
              contactIdentifierInput,
              {
                ...contactEmailInput,
                title: 'Update Email Address',
                subtitle: 'Specify a new email address for this contact.',
                required: false,
              },
              ...contactFieldsInput(true),
            ],
          };

        case Intent.GET_CONTACTS_FROM_LIST:
          return {
            inputs: [{ ...listIdInput, subtitle: 'The ID of the list to get contacts from.' }],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
