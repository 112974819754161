import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

import { FilterFormula } from './types';

/**
 * parse filter condtion and return filterFormula of type FilterFormula from condition obj (filter object)
 * @param condition
 */
const parseCondition = (condition: ResolvedConditionWrapper) => {
  if (condition.type === 'JOIN') {
    const result = condition.conditions.map((condition: ResolvedConditionWrapper) =>
      parseCondition(condition),
    );
    return condition.join == 'OR'
      ? result.map((resultItem: any) => ({ filters: resultItem }))
      : result;
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return {
          propertyName: innerCondition.variable,
          operator: 'EQ',
          value: innerCondition.argument,
        };
      case Operator.StringDoesNotExactlyMatch:
        return {
          propertyName: innerCondition.variable,
          operator: 'NEQ',
          value: innerCondition.argument,
        };
      case Operator.NumberLessThan:
        return {
          propertyName: innerCondition.variable,
          operator: 'LT',
          value: innerCondition.argument,
        };
      case Operator.NumberEquals:
        return {
          propertyName: innerCondition.variable,
          operator: 'EQ',
          value: innerCondition.argument,
        };
      case Operator.NumberGreaterThan:
        return {
          propertyName: innerCondition.variable,
          operator: 'GT',
          value: innerCondition.argument,
        };
      case Operator.NumberDoesNotEqual:
        return {
          propertyName: innerCondition.variable,
          operator: 'NEQ',
          value: innerCondition.argument,
        };
      case Operator.NumberGreaterThanOrEqualTo:
        return {
          propertyName: innerCondition.variable,
          operator: 'GTE',
          value: innerCondition.argument,
        };
      case Operator.NumberLessThanOrEqualTo:
        return {
          propertyName: innerCondition.variable,
          operator: 'LTE',
          value: innerCondition.argument,
        };
      case Operator.IsNotNull:
        return {
          propertyName: innerCondition.variable,
          operator: 'HAS_PROPERTY',
        };
      case Operator.IsNull:
        return {
          propertyName: innerCondition.variable,
          operator: 'NOT_HAS_PROPERTY',
        };
      default:
        throw new Error(`${innerCondition.operator} not supported by HubSpot`);
    }
  }
};

/**
 * return filterFormula of type FilterFormula from condition obj (filter object)
 * @param condition
 */
export default function conditionsToFilterFormula(
  condition: ResolvedConditionWrapper,
): FilterFormula {
  const filterFormula: FilterFormula = parseCondition(condition);
  return filterFormula;
}
