import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedCronParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { CustomIntegrationRequestStep, StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';
import RequestStepValidator from './request.validator';

/**
 * validates that an custom integration request step is properly configured
 *
 * @export
 * @class CustomIntegrationRequestStepValidator
 * @extends {StepValidator<CustomIntegrationRequestStep, ResolvedCronParameters>}
 */
export default class CustomIntegrationRequestStepValidator extends StepValidator<
  CustomIntegrationRequestStep,
  ResolvedCronParameters
> {
  /**
   * the step type that the validator validates
   *
   * @type {StepType}
   * @memberof CustomIntegrationRequestStepValidator
   */
  stepType: CustomIntegrationRequestStep['type'] = StepType.CUSTOM_INTEGRATION_REQUEST;

  /**
   * validates that a step is valid
   *
   * @param {CustomIntegrationRequestStep} step
   * @returns {CustomIntegrationRequestStep}
   * @memberof CustomIntegrationRequestStepValidator
   */
  validate(
    step: CustomIntegrationRequestStep,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona,
  ): CustomIntegrationRequestStep {
    const requestStepValidator: RequestStepValidator = new RequestStepValidator();
    requestStepValidator.validate(
      {
        ...step,
        type: StepType.REQUEST,
      },
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
      false,
    );

    if (step.parameters.authorization) {
      throw new Error(
        `Unexpected authorization method found: ${step.parameters.authorization.type}`,
      );
    }

    return step;
  }
}
