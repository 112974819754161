import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';
import { DataType } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

export const authConfig: ActionConfig = {
  actionType: Action.GOOGLE_DRIVE,
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  scheme: AuthenticationScheme.OAUTH,
  hideOAuthApps: true,
};

const config: ActionConfig = {
  actionType: Action.GOOGLE_DRIVE,
  name: 'Google Drive',
  description: 'Interact with files on Google Drive.',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect your Google Drive account',
          placeholder: 'choose your Google Drive account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.SAVE_FILE,
                    label: 'Save File',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SAVE_FILE:
          return {
            inputs: [
              {
                id: 'file',
                type: SidebarInputType.Text,
                title: 'File',
                required: true,
                dataType: DataType.FILE,
              },
              {
                action: Action.GOOGLE_DRIVE,
                getChildrenIntent: Intent.LIST_FOLDERS,
                getParentsIntent: Intent.LIST_FOLDER_PARENTS,
                id: 'parentId',
                required: false,
                title: 'Folder',
                type: SidebarInputType.NestedList,
              },
              {
                id: 'filename',
                type: SidebarInputType.Text,
                title: 'File name',
                subtitle: 'Defaults to origin file name if left blank.',
                required: false,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
