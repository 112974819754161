import { array } from 'fp-ts/lib/Array';
import { Lens, Optional, Prism, Traversal, fromTraversable } from 'monocle-ts';
import { indexArray } from 'monocle-ts/lib/Index/Array';

import { ActionStep, ActionStepParameters, ActionTriggerStep } from '@shared/types/sdk/actions';
import {
  ConditionSource,
  ConditionWrapper,
  DataType,
  JoinedConditions,
  KeyedSource,
  OperatorCondition,
  Source,
} from '@shared/types/sdk/resolvers';
import {
  Choice,
  ConditionalStep,
  CronStep,
  CustomIntegrationRequestStep,
  DelayStep,
  EndpointStep,
  EventStep,
  FunctionStep,
  MapStep,
  RedirectStep,
  RequestStep,
  ResponseStep,
  Schedule,
  Step,
  StepType,
  Unit,
} from '@shared/types/sdk/steps';

import { fromDiscriminatedUnion } from '../utils';

import {
  EntitiesState,
  StepStatus,
  Testing,
  TestingStepInstance,
  WorkflowEditorState,
} from './types';

// https://app.gethyperdoc.com/t/IHpNFRUwTfAnZWqnQLNz
export function testingLens(): Optional<WorkflowEditorState, Testing> {
  return Optional.fromNullableProp<WorkflowEditorState>()('testing');
}

export function testingStepInstanceStatusLens(
  stepId: string,
  instanceId: string,
): Traversal<WorkflowEditorState, StepStatus> {
  return testingLens()
    .compose(Lens.fromProp<Testing>()('stepInstances').asOptional())
    .composeTraversal(fromTraversable(array)<TestingStepInstance>())
    .composePrism(
      Prism.fromPredicate(
        (stepInstance: TestingStepInstance) =>
          stepInstance.stepId === stepId && stepInstance.instanceId === instanceId,
      ),
    )
    .composeOptional(Lens.fromProp<TestingStepInstance>()('status').asOptional());
}

export function stepLens(stepId: string): Lens<EntitiesState<Step>, Step> {
  return Lens.fromPath<EntitiesState<Step>>()(['entities', stepId]);
}

// tslint:disable-next-line:typedef // TODO figure out return type
export function stepTypePrism<T extends StepType, TRefineFromStep extends Step = Step>(
  stepType?: T,
) {
  return fromDiscriminatedUnion<TRefineFromStep>()<'type', T>('type', stepType);
}

export function cronLens(stepId: string): Optional<EntitiesState<Step>, CronStep> {
  // TODO stepTypePrism function
  return stepLens(stepId).composePrism(stepTypePrism(StepType.CRON));
}

export function scheduleLens(stepId: string): Optional<EntitiesState<Step>, Schedule> {
  return cronLens(stepId).composeLens(Lens.fromPath<CronStep>()(['parameters', 'schedule']));
}

export function scheduleTypeLens<V extends Unit>(
  stepId: string,
  unit: V,
): Optional<EntitiesState<Step>, Extract<Schedule, { unit: V }>> {
  return scheduleLens(stepId).composePrism(fromDiscriminatedUnion<Schedule>()('unit', unit));
}

export function endpointLens(stepId: string): Optional<EntitiesState<Step>, EndpointStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.ENDPOINT));
}

export function appEventLens(stepId: string): Optional<EntitiesState<Step>, EventStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.EVENT));
}

export function endpointParametersLens(
  stepId: string,
): Optional<EntitiesState<Step>, EndpointStep['parameters']> {
  return endpointLens(stepId).composeLens(Lens.fromProp<EndpointStep>()('parameters'));
}

export function functionLens(stepId: string): Optional<EntitiesState<Step>, FunctionStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.FUNCTION));
}

export function parametersLens(stepId: string): Optional<EntitiesState<Step>, KeyedSource[]> {
  return functionLens(stepId).composeLens(
    Lens.fromPath<FunctionStep>()(['parameters', 'parameters']),
  );
}

export function parameterLens(
  stepId: string,
  index: number,
): Optional<EntitiesState<Step>, KeyedSource> {
  return parametersLens(stepId).composeOptional(indexArray<KeyedSource>().index(index));
}

//-------------------------- Request lens --------------------------------------------//
export function requestLens(
  stepId: string,
): Optional<EntitiesState<Step>, RequestStep | CustomIntegrationRequestStep> {
  return stepLens(stepId).composePrism(
    Prism.fromPredicate<Step, RequestStep | CustomIntegrationRequestStep>(
      (s: Step): s is RequestStep | CustomIntegrationRequestStep =>
        s.type === StepType.REQUEST || s.type === StepType.CUSTOM_INTEGRATION_REQUEST,
    ),
  );
}

export function requestHeadersLens(
  stepId: string,
): Optional<EntitiesState<Step>, KeyedSource<DataType.STRING>[]> {
  return requestLens(stepId).composeLens(
    Lens.fromPath<RequestStep | CustomIntegrationRequestStep>()(['parameters', 'headers']),
  );
}

export function requestHeaderLens(
  stepId: string,
  index: number,
): Optional<EntitiesState<Step>, KeyedSource<DataType.STRING>> {
  return requestHeadersLens(stepId).composeOptional(
    indexArray<KeyedSource<DataType.STRING>>().index(index),
  );
}

export function requestBodyLens(stepId: string): Optional<EntitiesState<Step>, KeyedSource[]> {
  return requestLens(stepId).composeLens(
    Lens.fromPath<RequestStep | CustomIntegrationRequestStep>()(['parameters', 'body']),
  );
}

export function requestBodyLensByIndex(
  stepId: string,
  index: number,
): Optional<EntitiesState<Step>, KeyedSource> {
  return requestBodyLens(stepId).composeOptional(indexArray<KeyedSource>().index(index));
}

export function requestParamsLens(
  stepId: string,
): Optional<EntitiesState<Step>, KeyedSource<DataType.STRING>[]> {
  return requestLens(stepId).composeLens(
    Lens.fromPath<RequestStep | CustomIntegrationRequestStep>()(['parameters', 'params']),
  );
}

export function requestParamLens(
  stepId: string,
  index: number,
): Optional<EntitiesState<Step>, KeyedSource<DataType.STRING>> {
  return requestParamsLens(stepId).composeOptional(
    indexArray<KeyedSource<DataType.STRING>>().index(index),
  );
}

//--------------------------------REquest lens -------------------------------------------//

export function redirectLens(stepId: string): Optional<EntitiesState<Step>, RedirectStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.REDIRECT));
}

// --------------------------------Response lens ----------------------------------------//
export function responseLens(stepId: string): Optional<EntitiesState<Step>, ResponseStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.RESPONSE));
}

export function responseBodyDataLens(stepId: string): Optional<EntitiesState<Step>, KeyedSource[]> {
  return responseLens(stepId).composeLens(
    Lens.fromPath<ResponseStep>()(['parameters', 'bodyData']),
  );
}

export function responseBodyDataLensById(
  stepId: string,
  index: number,
): Optional<EntitiesState<Step>, KeyedSource> {
  return responseBodyDataLens(stepId).composeOptional(indexArray<KeyedSource>().index(index));
}
//------------------------------------Response lens --------------------------------------//

//-------------------------------------conditional lens -------------------------------------//
export const joinConditionsPrism: Prism<ConditionWrapper, JoinedConditions> =
  fromDiscriminatedUnion<ConditionWrapper>()('type', 'JOIN');
export const operatorConditionsPrism: Prism<ConditionWrapper, OperatorCondition> =
  fromDiscriminatedUnion<ConditionWrapper>()('type', 'OPERATOR');

export function conditionalLens(stepId: string): Optional<EntitiesState<Step>, ConditionalStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.IFELSE));
}

export const choicesLens: Lens<ConditionalStep, Choice[]> = Lens.fromPath<ConditionalStep>()([
  'parameters',
  'choices',
]);

export const conditionWrapperLens: Optional<Choice, JoinedConditions> =
  Optional.fromNullableProp<Choice>()('conditionWrapper').composePrism(joinConditionsPrism);

export function conditionOrLens(
  stepId: string,
  choiceIndex: number,
): Optional<EntitiesState<Step>, JoinedConditions> {
  return conditionalLens(stepId)
    .compose<Choice[]>(choicesLens.asOptional())
    .composeOptional(indexArray<Choice>().index(choiceIndex))
    .composeOptional(conditionWrapperLens);
}

export function conditionalAndLens(
  stepId: string,
  choiceIndex: number,
): Optional<EntitiesState<Step>, ConditionWrapper[]> {
  return conditionOrLens(stepId, choiceIndex).composeLens(
    Lens.fromProp<JoinedConditions>()('conditions'),
  );
}

export function conditionalAndLensByIndex(
  stepId: string,
  choiceIndex: number,
  andIndex: number,
): Optional<EntitiesState<Step>, JoinedConditions> {
  return conditionalAndLens(stepId, choiceIndex).composeOptional(joinConditionsByIndex(andIndex));
}

export function joinConditionsByIndex(
  index: number,
): Optional<ConditionWrapper[], JoinedConditions> {
  return indexArray<ConditionWrapper>().index(index).composePrism(joinConditionsPrism);
}

export function conditionalConditionsLens(
  stepId: string,
  choiceIndex: number,
  andIndex: number,
): Optional<EntitiesState<Step>, ConditionWrapper[]> {
  return conditionalAndLensByIndex(stepId, choiceIndex, andIndex).composeLens(
    Lens.fromProp<JoinedConditions>()('conditions'),
  );
}

export function conditionalConditionLensByIndex(
  stepId: string,
  choiceIndex: number,
  andIndex: number,
  conditionIndex: number,
): Optional<EntitiesState<Step>, OperatorCondition> {
  return conditionalConditionsLens(stepId, choiceIndex, andIndex).composeOptional(
    indexArray<ConditionWrapper>().index(conditionIndex).composePrism(operatorConditionsPrism),
  );
}

export const conditionSourceLens: Optional<
  KeyedSource<DataType.ANY>,
  ConditionWrapper
> = Lens.fromProp<KeyedSource<DataType.ANY>>()('source')
  .composePrism(fromDiscriminatedUnion<Source<DataType.ANY>>()('type', 'CONDITION'))
  //@ts-ignore TODO: FIX THIS
  .composeLens(Lens.fromProp<ConditionSource>()('condition'));

//--------------------------------------conditional lens -----------------------------------//

//---------------------------- Map lens -------------------------------//

export function mapLens(stepId: string): Optional<EntitiesState<Step>, MapStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.MAP));
}

// ------------------------- Action Step lenses ---------------------------- //

export function actionLens(stepId: string): Optional<EntitiesState<Step>, ActionStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.ACTION));
}

export function actionTriggerLens(
  stepId: string,
): Optional<EntitiesState<Step>, ActionTriggerStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.ACTION_TRIGGER));
}

export function actionParametersLens(
  stepId: string,
): Optional<EntitiesState<Step>, ActionStepParameters> {
  return actionLens(stepId).composeLens(Lens.fromProp<ActionStep>()('parameters'));
}

export function actionTriggerParameterLens(
  stepId: string,
): Optional<EntitiesState<Step>, ActionStepParameters> {
  return actionTriggerLens(stepId).composeLens(Lens.fromProp<ActionTriggerStep>()('parameters'));
}

export function actionConditionsByKey(
  stepId: string,
  key: string,
): Traversal<EntitiesState<Step>, ConditionWrapper> {
  return actionParametersLens(stepId)
    .composeLens<KeyedSource<DataType.ANY>[]>(
      Lens.fromProp<ActionStepParameters>()('actionParameters'),
    )
    .composeTraversal(keyedSourceByKeyTraversal(key))
    .composeOptional(conditionSourceLens);
}

export function actionTriggerConditionByKey(
  stepId: string,
  key: string,
): Traversal<EntitiesState<Step>, ConditionWrapper> {
  return actionTriggerParameterLens(stepId)
    .composeLens<KeyedSource<DataType.ANY>[]>(
      Lens.fromProp<ActionStepParameters>()('actionParameters'),
    )
    .composeTraversal(keyedSourceByKeyTraversal(key))
    .composeOptional(conditionSourceLens);
}

export function keyedSourceByKeyTraversal(
  key: string,
): Traversal<KeyedSource<DataType.ANY>[], KeyedSource<DataType.ANY>> {
  return fromTraversable(array)<KeyedSource<DataType.ANY>>().filter(
    (item: KeyedSource<DataType.ANY>) => item.key === key,
  );
}

// ------------------------- Delay Step lenses ---------------------------- //

export function delayLens(stepId: string): Optional<EntitiesState<Step>, DelayStep> {
  return stepLens(stepId).composePrism(stepTypePrism(StepType.DELAY));
}

export function delayParametersLens(
  stepId: string,
): Optional<EntitiesState<Step>, DelayStep['parameters']> {
  return delayLens(stepId).composeLens(Lens.fromProp<DelayStep>()('parameters'));
}
