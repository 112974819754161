import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * @param condition
 * @returns condition for filter woo commerce
 */

export default function conditionsToFilterDynamicsFinanceExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterDynamicsFinanceExpression(condition);
      })
      .join('&');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable}=${innerCondition.argument}`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for WooCommerce`);
    }
  }
  return '';
}
