import { getValuesByCacheKey, hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { MessageTemplateType, MessageType, Role } from '../shared/types';
import { contactFields, roleValues } from '../shared/utils';

import { getOwners } from './sources';
import supportedOperators from './supportedOperators';

export const authConfig: ActionConfig = {
  actionType: Action.INTERCOM,
  provider: ProviderType.INTERCOM,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Intercom account',
  icon: iconSvg,
  description: 'Manage contacts and send messages in Intercom.',
  sidebarSections: [],
};

const contactIdInput: SidebarInput = {
  id: 'contactId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Contact ID',
};

const roleInput: SidebarInput = {
  id: 'role',
  type: SidebarInputType.Enum,
  getValues: () => roleValues,
  title: 'Role',
  defaultValue: Role.USER,
};

const emailInput: SidebarInput = {
  id: 'email',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Email',
  placeholder: 'name@example.com',
};

const nameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  required: false,
};

const externalIdInput: SidebarInput = {
  id: 'externalId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'External ID',
  subtitle: 'A unique external identifier given to the contact (e.g. from your app)',
  required: false,
  placeholder: '25',
};

const phoneInput: SidebarInput = {
  id: 'phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Phone',
  placeholder: '+1123456789',
  required: false,
};
const avatarInput: SidebarInput = {
  id: 'avatar',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Avatar',
  placeholder: 'https://example.com/avatar.jpeg',
  required: false,
};

const signedUpAtDateInput: SidebarInput = {
  id: 'signedUpDate',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Signed up at',
  subtitle: 'The time specified for when a contact signed up (ISO or Unix timestamp).',
  placeholder: '2014-04-25T16:15:47-04:00',
  required: false,
};

const lastSeenDateInput: SidebarInput = {
  id: 'lastSeenDate',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Last seen at',
  subtitle: 'The time when the contact was last seen (ISO or Unix timestamp).',
  placeholder: '2014-04-25T16:15:47-04:00',
  required: false,
};

const ownerIdInput: SidebarInput = {
  id: 'ownerId',
  type: SidebarInputType.EditableDynamicEnum,
  title: 'Admin',
  subtitle: 'The id of an admin that has been assigned account ownership of the contact.',
  placeholder: 'choose a ownerId or type {{ to insert a owner id',
  source: getOwners,
  getValues: getValuesByCacheKey,
};

const ownerIdOauthInput: SidebarInput = {
  id: 'ownerId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Owner ID',
  subtitle: 'The id of an admin that has been assigned account ownership of the contact.',
  placeholder: '127',
};

const unsubscribeFromEmailInput: SidebarInput = {
  id: 'unsubscribeFromEmail',
  type: SidebarInputType.BooleanInput,
  title: 'Unsubscribed from emails',
  subtitle: 'Whether the contact is unsubscribed from emails.',
  required: false,
};

const customAttributesInput: SidebarInput = {
  id: 'customAttribute',
  type: SidebarInputType.Code,
  title: 'Custom attributes',
  language: 'json',
  placeholder: `{
"paid_subscriber": true,
"monthly_spend": 155.5,
"team_mates": 1
}`,
  useLightTheme: true,
  required: false,
};

const config: ActionConfig = {
  actionType: Action.INTERCOM,
  name: 'Intercom',
  description: 'Manage contacts and send messages in Intercom.',
  icon: iconSvg,
  provider: ProviderType.INTERCOM,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Intercom account',
          placeholder: 'connect to Intercom account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.INTERCOM,
                supportedTokenTypes: [TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection => {
      return parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    title: 'Contacts',
                    items: [
                      {
                        label: 'Create contact',
                        value: Intent.CREATE_CONTACT,
                      },
                      {
                        label: 'Update contact',
                        value: Intent.UPDATE_CONTACT,
                      },
                      {
                        label: 'Get contact by ID',
                        value: Intent.GET_CONTACT_BY_ID,
                      },
                      {
                        label: 'Search contacts',
                        value: Intent.SEARCH_CONTACTS,
                      },
                    ],
                  },
                  {
                    title: 'Messages',
                    items: [
                      {
                        label: 'Send message',
                        value: Intent.SEND_MESSAGE,
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : {
            inputs: [],
          };
    },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      if (!parameters.credentials?.length) {
        return { inputs: [] };
      }
      switch (parameters.intent) {
        case Intent.CREATE_CONTACT:
          return {
            inputs: [
              roleInput,
              emailInput,
              nameInput,
              externalIdInput,
              phoneInput,
              avatarInput,
              signedUpAtDateInput,
              lastSeenDateInput,
              {
                ...(hasOAuthAppCredential(parameters, credentials)
                  ? ownerIdOauthInput
                  : ownerIdInput),
                required: false,
              },
              unsubscribeFromEmailInput,
              customAttributesInput,
            ],
          };
        case Intent.UPDATE_CONTACT:
          return {
            inputs: [
              { ...contactIdInput, subtitle: 'The ID of the contact to update.' },
              { ...roleInput, required: false },
              { ...emailInput, required: false },
              nameInput,
              externalIdInput,
              phoneInput,
              avatarInput,
              signedUpAtDateInput,
              lastSeenDateInput,
              {
                ...(hasOAuthAppCredential(parameters, credentials)
                  ? ownerIdOauthInput
                  : ownerIdInput),
                required: false,
              },
              unsubscribeFromEmailInput,
              customAttributesInput,
            ],
          };
        case Intent.GET_CONTACT_BY_ID:
          return {
            inputs: [contactIdInput],
          };
        case Intent.SEARCH_CONTACTS:
          return {
            inputs: [
              {
                ...{
                  id: 'filterFormula',
                  type: SidebarInputType.Conditional,
                  title: 'Filter search',
                  subtitle: 'Get contacts that match the specified filters.',
                  supportedOperators,
                  required: false,
                },
                ...(hasOAuthAppCredential(parameters, credentials)
                  ? {}
                  : {
                      placeholder: 'filter by field',
                      getValues: () => contactFields,
                    }),
              },
            ],
          };
        case Intent.SEND_MESSAGE:
          return {
            inputs: [
              {
                id: 'recipientContactType',
                type: SidebarInputType.Enum,
                getValues: () => roleValues,
                title: 'Recipient contact type',
              },
              {
                id: 'recepientContactId',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Recipient contact ID',
                subtitle: 'The ID of the contact to send the message to.',
              },
              {
                id: 'messageType',
                type: SidebarInputType.Enum,
                getValues: () => [
                  { label: 'In-app', value: MessageType.IN_APP },
                  { label: 'Email', value: MessageType.EMAIL },
                ],
                title: 'Message type',
              },
              {
                ...(hasOAuthAppCredential(parameters, credentials)
                  ? ownerIdOauthInput
                  : ownerIdInput),
                ...{
                  id: 'fromId',
                  title: 'From',
                  subtitle: 'The intercom admin the message will be sent from.',
                  required: true,
                },
              },
              {
                id: 'subject',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Subject',
                subtitle: 'Required if sending an email.',
                required: false,
              },
              {
                id: 'body',
                type: SidebarInputType.TextArea,
                title: 'Body',
                subtitle: 'The content of the message. HTML and plaintext are supported.',
              },
              {
                id: 'template',
                type: SidebarInputType.Enum,
                getValues: () => [
                  { label: 'Personal', value: MessageTemplateType.PERSONAL },
                  { label: 'Plain', value: MessageTemplateType.PLAIN },
                ],
                title: 'Template',
                subtitle: 'The style of the outgoing message. Required if sending an email.',
                required: false,
              },
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
