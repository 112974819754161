import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

export const getFormattedDate = (date: Date): string =>
  `DateTime(${date.getFullYear()}, ${date.getMonth() + 1}, ${date.getDate()})`;

const getFormattedString = (variable: string, value: string) => {
  const urlEscapedValue = encodeURIComponent((value || '').trim());
  return variable.toLowerCase().indexOf('id') > -1 ? `guid("${value}")` : `"${urlEscapedValue}"`;
};

export default function conditionsToFilterXeroExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToFilterXeroExpression(condition);
      })
      .join(condition.join === 'OR' ? ' OR ' : ' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} = ${getFormattedString(
          innerCondition.variable,
          innerCondition.argument,
        )}`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable} != ${getFormattedString(
          innerCondition.variable,
          innerCondition.argument,
        )}`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} > ${getFormattedDate(
          new Date(innerCondition.argument),
        )}`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} < ${getFormattedDate(
          new Date(innerCondition.argument),
        )}`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} = ${getFormattedDate(
          new Date(innerCondition.argument),
        )}`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable} = true`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} = false`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for xero`);
    }
  }
  return '';
}
