import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { WorkItemType } from '../shared';

import {
  activityInput,
  additionalFieldsInput,
  areaInput,
  assigneeInput,
  businessValueInput,
  commonIntentInput,
  descriptionInput,
  effortInput,
  iterationInput,
  priorityInput,
  projectFilterInput,
  projectInput,
  reasonInput,
  reproStepsInput,
  resolvedReasonInput,
  riskInput,
  severityInput,
  startDateInput,
  stateInput,
  storyPointsInput,
  systemInfoInput,
  targetDateInput,
  timeCriticalityInput,
  titleInput,
  workItemIdInput,
  workItemTypeInput,
} from './common';

const config: ActionConfig = {
  actionType: Action.AZURE_DEVOPS,
  name: 'Azure DevOps',
  description: 'Manage boards and work items in Azure DevOps',
  icon: iconSvg,
  provider: ProviderType.AZURE_DEVOPS,
  sidebarSections: [
    commonIntentInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.CREATE_WORKITEM:
        case Intent.UPDATE_WORKITEM:
          return {
            inputs:
              parameters.intent === Intent.UPDATE_WORKITEM
                ? [
                    {
                      ...projectInput,
                      subtitle:
                        'Specify a project to update this work item in. Use Connect Portal Workflow Settings to allow your user to select a Project.',
                    },
                    workItemIdInput,
                  ]
                : [{ ...projectInput }, workItemTypeInput],
          };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.CREATE_WORKITEM:
          return {
            inputs: [titleInput, assigneeInput, stateInput, reasonInput, areaInput, iterationInput],
          };
        case Intent.UPDATE_WORKITEM:
          return {
            inputs: [
              titleInput,
              assigneeInput,
              stateInput,
              reasonInput,
              areaInput,
              iterationInput,
            ].map((item) => ({ ...item, required: false })),
          };
        case Intent.DELETE_WORKITEM:
          return {
            inputs: [
              {
                ...projectInput,
                subtitle:
                  'Specify a project to delete this work item from. Use Connect Portal Workflow Settings to allow your user to select a Project.',
              },
              { ...workItemIdInput, subtitle: 'The ID of the work item to delete.' },
            ],
          };
        case Intent.SEARCH_WORKITEMS:
          return {
            inputs: [projectFilterInput],
          };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.CREATE_WORKITEM:
          const selectedAdCreativeType: WorkItemType | undefined =
            pickValueSourceByKey<DataType.ANY>(parameters.actionParameters, 'workItemType')?.value;

          if (selectedAdCreativeType === WorkItemType.BUG) {
            return {
              inputs: [
                reproStepsInput,
                systemInfoInput,
                resolvedReasonInput,
                storyPointsInput,
                priorityInput,
                severityInput,
                activityInput,
              ],
            };
          } else if (
            selectedAdCreativeType === WorkItemType.EPIC ||
            selectedAdCreativeType === WorkItemType.FEATURE
          ) {
            return {
              inputs: [
                descriptionInput,
                priorityInput,
                riskInput,
                effortInput,
                businessValueInput,
                timeCriticalityInput,
                startDateInput,
                targetDateInput,
              ],
            };
          } else {
            return { inputs: [] };
          }
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.CREATE_WORKITEM:
        case Intent.UPDATE_WORKITEM:
          return {
            inputs: [
              parameters.intent === Intent.UPDATE_WORKITEM
                ? additionalFieldsInput
                : { ...additionalFieldsInput, subtitle: '' },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
