import { hasImpersonatedUserCredential } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig, { authInput } from './authConfig';
import {
  apiTokenInput,
  columnValuesInput,
  dynamicBoardInput,
  dynamicUserInput,
  externalIdInput,
  getItemFieldInputsAutomate,
  intentTypeInput,
  itemIdInput,
  nameInput,
} from './common';

const config: ActionConfig = {
  actionType: Action.MONDAY,
  name: 'Monday.com',
  description: 'Connect your Monday.com account to manage their items and boards in Monday.com.',
  icon: iconSvg,
  provider: ProviderType.MONDAY,
  supportsImpersonatedCredentialSource: true,
  sidebarSections: [
    (parameters: ActionStepParameters) => {
      return {
        inputs: [
          authInput,
          ...(hasImpersonatedUserCredential(parameters)
            ? ([
                {
                  ...apiTokenInput,
                  type: SidebarInputType.UserSuppliedCredential,
                  supportedTokenTypes: [TokenType.MONDAY_API_TOKEN],
                  providerType: ProviderType.MONDAY,
                  hideTokenDropDown: true,
                },
              ] as UserSuppliedCredentialInput[])
            : []),
        ],
      };
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length ? intentTypeInput : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_ITEM:
        case Intent.UPDATE_ITEM:
          return {
            inputs: getItemFieldInputsAutomate(parameters.intent === Intent.UPDATE_ITEM),
          };

        case Intent.GET_ITEM_BY_ID:
          return { inputs: [itemIdInput] };

        case Intent.GET_ITEM_BY_EXTERNAL_ID:
          return {
            inputs: [
              { ...dynamicBoardInput, subtitle: 'Select a board to get items in' },
              {
                ...externalIdInput,
                subtitle:
                  'The ID that this task is associated or synced with, from your application.',
                required: true,
              },
            ],
          };

        case Intent.SEARCH_ITEMS:
          return {
            inputs: [
              {
                ...dynamicBoardInput,
                required: false,
                subtitle: 'Select a board to search items in',
              },
            ],
          };

        case Intent.DELETE_ITEM:
          return { inputs: [{ ...itemIdInput, subtitle: 'The ID of the item to delete.' }] };

        case Intent.ARCHIVE_ITEM:
          return {
            inputs: [
              {
                ...itemIdInput,
                subtitle: 'The ID of the item to archive.',
              },
            ],
          };

        case Intent.CREATE_SUBITEM:
          return {
            inputs: [
              nameInput,
              { ...itemIdInput, id: 'parentItemId', title: 'Parent Item ID', subtitle: '' },
              { ...columnValuesInput, subtitle: 'The column values of the new item.' },
            ],
          };

        case Intent.SEARCH_USERS:
          return { inputs: [dynamicUserInput] };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;

export { authConfig };
