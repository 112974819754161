import { Action, WorkflowDashboardState } from '../types';

export const initialState: WorkflowDashboardState = {
  createWorkflowProcessing: false,
};

export function handler(
  state: WorkflowDashboardState = initialState,
  action: Action,
): WorkflowDashboardState {
  switch (action.type) {
    case 'SET_WORKFLOW_CREATE_PROCESSING':
      return { ...state, createWorkflowProcessing: action.processing };
    default:
      return state;
  }
}
