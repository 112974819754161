import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import { fileIdInput, fileInput, fileNameInput, filePathInput } from './inputs';
import { getFolders } from './sources';

const connectConfig: ActionConfig = {
  actionType: Action.DROPBOX,
  name: 'Dropbox',
  description: "Connect to your users' Dropbox accounts",
  icon: iconSvg,
  provider: ProviderType.DROPBOX,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              title: 'Files',
              items: [
                {
                  value: Intent.GET_FILE_BY_ID,
                  label: 'Get File by ID',
                },
                {
                  value: Intent.SAVE_FILE,
                  label: 'Save file',
                },
              ],
            },
            {
              title: 'Folders',
              items: [
                {
                  value: Intent.LIST_FILES,
                  label: 'List files',
                },
              ],
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.GET_FILE_BY_ID:
          return {
            inputs: [fileIdInput],
          };
        case Intent.LIST_FILES:
          return {
            inputs: [filePathInput(true)],
          };
        case Intent.SAVE_FILE:
          return {
            inputs: [fileInput, filePathInput(), fileNameInput],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.LIST_FOLDERS:
          return {
            inputs: [
              {
                id: 'folderId',
                type: SidebarInputType.DynamicEnum,
                title: 'Folder',
                required: true,
                source: getFolders,
                getValues: getValuesByCacheKey,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
