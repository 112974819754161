import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Intercom',
  description: 'Access a user’s Intercom contacts and send messages.',
  authUrl: 'https://app.intercom.com/oauth',
  accessTokenUrl: 'https://api.intercom.io/auth/eagle/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/intercom`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    notAllowed: true,
    defaultValues: ['_NO_SCOPE_ALLOWED_'],
    values: [],
    helperText:
      'Specify the permission scopes you need on your Authorization settings page in the Intercom developers hub.',
  },
  documentationLink: 'https://developers.intercom.com/building-apps/docs/setting-up-oauth',
};
