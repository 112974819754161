import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class DeploymentError extends ParagonError {
  name: string = 'DeploymentError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class AdminDeploymentFilterConflictError extends DeploymentError {
  name: string = 'AdminDeploymentFilterConflictError';

  constructor(meta: {
    projectId?: string;
    workflowId?: string;
    verify: boolean;
    onlyConnectWorkflows: boolean;
    onlyAutomateWorkflows: boolean;
  }) {
    super({
      code: ERROR_CODE.ADMIN_DEPLOYMENT_FILTER_CONFLICT,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Invalid deployment filter parameters. Please inspect your request.',
      meta,
    });
  }
}
