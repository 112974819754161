import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.NETSUITE;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'NETSUITE_TEST_CONNECTION',
  CREATE_VENDOR = 'NETSUITE_CREATE_VENDOR',
  UPDATE_VENDOR = 'NETSUITE_UPDATE_VENDOR',
  GET_VENDOR_BY_ID = 'NETSUITE_GET_VENDOR_BY_ID',
  SEARCH_VENDORS = 'NETSUITE_SEARCH_VENDORS',
  DELETE_VENDOR = 'NETSUITE_DELETE_VENDOR',
  CREATE_BILL = 'NETSUITE_CREATE_BILL',
  UPDATE_BILL = 'NETSUITE_UPDATE_BILL',
  GET_BILL_BY_ID = 'NETSUITE_GET_BILL_BY_ID',
  SEARCH_BILLS = 'NETSUITE_SEARCH_BILLS',
  DELETE_BILL = 'NETSUITE_DELETE_BILL',
  CREATE_ACCOUNT = 'NETSUITE_CREATE_ACCOUNT',
  UPDATE_ACCOUNT = 'NETSUITE_UPDATE_ACCOUNT',
  GET_ACCOUNT_BY_ID = 'NETSUITE_GET_ACCOUNT_BY_ID',
  SEARCH_ACCOUNTS = 'NETSUITE_SEARCH_ACCOUNTS',
  DELETE_ACCOUNT = 'NETSUITE_DELETE_ACCOUNT',
  CREATE_TAX_GROUP = 'NETSUITE_CREATE_TAX_GROUP',
  UPDATE_TAX_GROUP = 'NETSUITE_UPDATE_TAX_GROUP',
  GET_TAX_GROUP_BY_ID = 'NETSUITE_GET_TAX_GROUP_BY_ID',
  DELETE_TAX_GROUP = 'NETSUITE_DELETE_TAX_GROUP',
  SEARCH_PAYMENT_TERMS = 'NETSUITE_SEARCH_PAYMENT_TERMS',
  GET_PAYMENT_TERM_BY_ID = 'NETSUITE_GET_PAYMENT_TERM_BY_ID',
  SEARCH_POSTING_PERIODS = 'NETSUITE_SEARCH_POSTING_PERIODS',
  SEARCH_TAX_CODES = 'NETSUITE_SEARCH_TAX_CODES',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_VENDOR_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_VENDORS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_BILL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_BILL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_BILL_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_BILLS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_BILL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_ACCOUNT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_ACCOUNT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ACCOUNT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_ACCOUNTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_ACCOUNT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_TAX_GROUP;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_TAX_GROUP;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TAX_GROUP_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_TAX_GROUP;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_PAYMENT_TERMS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PAYMENT_TERM_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_POSTING_PERIODS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_TAX_CODES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  userId: string;
  NETSUITE_ACCOUNT_ID: string;
  NETSUITE_ACCESS_TOKEN: string;
  NETSUITE_REFRESH_TOKEN: string;
};
