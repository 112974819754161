import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { SidebarInputType } from '@shared/types/sdk/actions';

import { Credentials } from '../configs';

import { ATLASSIAN_API_BASE_URL, AUTH_BASE_URL } from './constants';
import {
  DefaultProjectData,
  Environment,
  GetCreateIssueMetaDataResponse,
  GetProjectsResponse,
  JQLResponse,
  JiraAPIRequestData,
  JiraAuthResponse,
  JiraRequestPayloadForField,
  JiraSupportedPayload,
  JiraTransition,
  JiraTransitionResponse,
  JiraTypeSchema,
  LoggedInUserResources,
  PaginationParams,
  TokenExchangeResponse,
  UserIdentityResponse,
} from './types';

const LONG_TEXT_FIELD_LINES = 4;
/**
 * @returns Axios instance
 * @summary gets axios object that can be used for sending requests to JIRA auth api
 */
export const getJiraAuthClient = (): AxiosInstance => {
  return getAxiosInstance(AUTH_BASE_URL);
};

/**
 * @returns Axios instance
 * @summary gets axios object that can be used for sending requests to JIRA rest api
 */
export const getJiraApiClient = (accessToken: string): AxiosInstance => {
  return getAxiosInstance(ATLASSIAN_API_BASE_URL, accessToken);
};

/**
 * @summary helper function to create axios instances for consuming atlassian API
 * @param baseUrl for axios
 * @param accessToken [optional] for setting accessToken in Authorization header
 * @returns Axios instance
 */
function getAxiosInstance(baseUrl: string, accessToken?: string): AxiosInstance {
  const accessTokenHeader: { Authorization?: string } = {};

  if (accessToken) {
    accessTokenHeader.Authorization = `Bearer ${accessToken}`;
  }

  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      ...accessTokenHeader,
    },
  });
}

/**
 * @summary
 * @param clientId
 * @param clientSecret
 * @param refreshToken
 * @returns
 */
export const exchangeJiraRefreshTokenForAccessToken = async (
  clientId: string,
  clientSecret: string,
  credentials: Omit<Credentials, 'SCOPES' | 'JIRA_CLOUD_ID'>,
): Promise<TokenExchangeResponse> => {
  const availableAccessToken: string = credentials.JIRA_OAUTH_ACCESS_TOKEN;
  if (await isAccessTokenValid(availableAccessToken)) {
    return {
      accessToken: availableAccessToken,
      refreshToken: credentials.JIRA_OAUTH_REFRESH_TOKEN,
      updateCredentialValues: undefined,
    };
  }

  const authResponse = await getJiraAuthClient().post<JiraAuthResponse>('oauth/token', {
    grant_type: 'refresh_token',
    client_id: clientId,
    client_secret: clientSecret,
    refresh_token: credentials.JIRA_OAUTH_REFRESH_TOKEN,
  });

  if (authResponse.data.refresh_token) {
    // rotating refresh tokens
    const updateCredentialValues = {
      ...credentials,
      JIRA_OAUTH_ACCESS_TOKEN: authResponse.data.access_token,
      JIRA_OAUTH_REFRESH_TOKEN: authResponse.data.refresh_token,
    };

    return {
      accessToken: authResponse.data.access_token,
      refreshToken: authResponse.data.refresh_token,
      updateCredentialValues,
    };
  } else {
    const updateCredentialValues = {
      ...credentials,
      JIRA_OAUTH_ACCESS_TOKEN: authResponse.data.access_token,
      JIRA_OAUTH_REFRESH_TOKEN: credentials.JIRA_OAUTH_REFRESH_TOKEN,
    };

    // never expiring refresh tokens
    return {
      accessToken: authResponse.data.access_token,
      refreshToken: credentials.JIRA_OAUTH_REFRESH_TOKEN,
      updateCredentialValues,
    };
  }
};

/*
 jira fields have been divided into 2 parts
 - commonly used jira fields
 - supported jira types. (can be used to create new custom jira fields)
*/

// maps common filed keys with expected payload input to jira api
export const commonFieldsToPayloadMap: JiraSupportedPayload = {
  summary: (value: string): JiraRequestPayloadForField => {
    return value;
  },
  issuetype: (value: string): JiraRequestPayloadForField => {
    return { id: value };
  },
  parent: (value: string): JiraRequestPayloadForField => {
    return { key: value };
  },
  description: (value: string = ''): JiraRequestPayloadForField => {
    return {
      version: 1,
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: value,
            },
          ],
        },
      ],
    };
  },
  project: (value: string): JiraRequestPayloadForField => {
    return {
      key: value,
    };
  },
  reporter: (value: string): JiraRequestPayloadForField => {
    return {
      id: value,
    };
  },
  labels: (value: string): JiraRequestPayloadForField => {
    return value.split(',');
  },
  assignee: (value: string): JiraRequestPayloadForField => {
    return {
      id: value,
    };
  },
  environment: (value: string): JiraRequestPayloadForField => {
    return {
      version: 1,
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: value,
            },
          ],
        },
      ],
    };
  },
  priority: (value: string): JiraRequestPayloadForField => {
    return {
      id: value,
    };
  },
};

//Maps supported jira types to payload expected by jira api
// here supported types are mainly for custom types that a user can create
const supportedJiraTypesToPayloadMap: JiraSupportedPayload = {
  string: (value: string): JiraRequestPayloadForField => {
    return value;
  },
  atlassianDoc: (value: string): JiraRequestPayloadForField => {
    return {
      version: 1,
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: value,
            },
          ],
        },
      ],
    };
  },
  date: (value: string): JiraRequestPayloadForField => {
    return value;
  },
  number: (value: string): JiraRequestPayloadForField => {
    return parseInt(value);
  },
  unixTimestamp: (value: string): JiraRequestPayloadForField => {
    return parseInt(value);
  },
  isoString: (value: string): JiraRequestPayloadForField => {
    return value;
  },
  labels: (value: string): JiraRequestPayloadForField => {
    // handles dropdown values.
    return value.split(',');
  },
  option: (value: string): JiraRequestPayloadForField => {
    return { id: value };
  },
  user: (value: string): JiraRequestPayloadForField => {
    return { id: value };
  },
  checkbox: (value: string): JiraRequestPayloadForField => {
    return value
      .split(',')
      .map((selectedCheckboxValue: string) => ({ value: selectedCheckboxValue.trim() }));
  },
};

/**
 * @summary using field schema , provides a field type string .
 * This string is consumed in commonFieldsToPayloadMap to get the payload for jira api.
 * @param jiraSchema schema for a field from jira api
 * @returns the field type string for consumption in commonFieldsToPayloadMap.
 */
const getFieldTypeFromSchema = (jiraSchema: JiraTypeSchema | undefined): string | null => {
  if (!jiraSchema) {
    return null;
  } else if (
    jiraSchema.type === 'string' &&
    jiraSchema.custom &&
    jiraSchema.custom.indexOf('textarea') > -1
  ) {
    return 'atlassianDoc';
  } else if (
    jiraSchema.type === 'string' &&
    jiraSchema.custom &&
    jiraSchema.custom.indexOf('textfield') > -1
  ) {
    return 'string';
  } else if (
    jiraSchema.type === 'date' &&
    jiraSchema.custom &&
    jiraSchema.custom.indexOf('datepicker') > -1
  ) {
    return 'date';
  } else if (
    jiraSchema.type === 'number' &&
    jiraSchema.custom &&
    jiraSchema.custom.indexOf('float') > -1
  ) {
    return 'number';
  } else if (
    jiraSchema.type === 'datetime' &&
    jiraSchema.custom &&
    jiraSchema.custom.indexOf('datetime') > -1
  ) {
    return 'isoString';
  } else if (
    jiraSchema.type === 'datetime' &&
    jiraSchema.custom &&
    jiraSchema.custom.indexOf('charting') > -1
  ) {
    return 'unixTimestamp';
  } else if (jiraSchema.custom && jiraSchema.custom.indexOf('multicheckboxes') > -1) {
    return 'checkbox';
  } else if (jiraSchema.type === 'option' && jiraSchema.custom) {
    return 'option';
  }
  // custom single value dropDowns
  else if (jiraSchema.type === 'user' && jiraSchema.custom) {
    return 'user';
  } else if (
    jiraSchema.type === 'string' &&
    jiraSchema.custom &&
    jiraSchema.custom.indexOf('epic-label') > -1
  ) {
    return 'string';
  } else {
    return null;
  }
};

/**
 * @summary gets the sidebar input type from key names of common jira fields.
 * @param fieldJiraKey
 * @returns sidebar input type
 */
const getSideBarInputTypeByFieldJiraKey = (fieldJiraKey: string): SidebarInputType => {
  return fieldJiraKey === 'reporter' || fieldJiraKey === 'assignee' || fieldJiraKey === 'priority'
    ? SidebarInputType.Enum
    : SidebarInputType.TextArea;
};

/**
 * @summary gets the sidebar input type from key names of common jira input types.
 * Mainly for supported jira types that are used to create custom fields.
 * @param supportedFieldType field type returned from getFieldTypeFromSchema function
 * @returns side bar input type
 */
const getSideBarInputTypeBySupportedFieldType = (supportedFieldType: string): SidebarInputType => {
  // todo see if you can increase textarea rows in atlassianDoc types
  return supportedFieldType == 'user' || supportedFieldType == 'option'
    ? SidebarInputType.Enum
    : SidebarInputType.TextArea;
};

/**
 * @summary tells if a jira type is supported. If not logs a warning. used to render ui.
 * @param jiraFieldKey
 * @param jiraTypeSchema
 * @returns boolean
 */
export function isJiraTypeSupported(jiraFieldKey: string, jiraTypeSchema: JiraTypeSchema): boolean {
  if (commonFieldsToPayloadMap[jiraFieldKey]) {
    return true;
  } else if (getFieldTypeFromSchema(jiraTypeSchema)) {
    return true;
  } else {
    console.warn(`field name ${jiraFieldKey} is not supported type ${jiraTypeSchema.type}`);
    return false;
  }
}

/**
 * @summary gets the sidebar input type for a common jira field as well as supported jira types.
 *  It is used to render ui.
 * @param jiraFieldKey
 * @param jiraTypeSchema
 * @returns sidebar input type
 */
export function getSidebarInputType(
  jiraFieldKey: string,
  jiraTypeSchema: JiraTypeSchema,
): SidebarInputType {
  if (commonFieldsToPayloadMap[jiraFieldKey]) {
    return getSideBarInputTypeByFieldJiraKey(jiraFieldKey);
  } else {
    const fieldTypeSchema = getFieldTypeFromSchema(jiraTypeSchema);
    if (!fieldTypeSchema) {
      throw new Error('Unsupported jira field type ' + jiraTypeSchema);
    }

    return getSideBarInputTypeBySupportedFieldType(fieldTypeSchema);
  }
}

/**
 * @summary computes the number of lines for a text area field.
 * @param jiraFieldKey
 * @param jiraTypeSchema
 * @returns number of lines to be rendered on UI for text area
 */
export function getTextAreaLinesForField(
  jiraFieldKey: string,
  jiraTypeSchema: JiraTypeSchema,
): number {
  if (commonFieldsToPayloadMap[jiraFieldKey]) {
    return jiraFieldKey === 'description' || jiraFieldKey === 'environment'
      ? LONG_TEXT_FIELD_LINES
      : 1;
  } else {
    return getFieldTypeFromSchema(jiraTypeSchema) === 'atlassianDoc' ? LONG_TEXT_FIELD_LINES : 1;
  }
}

/**
 * @summary gets the payload info for both common jira fields and supported jira types.
 * @param jiraFieldKey
 * @param jiraTypeSchema
 * @param fieldValue
 * @returns JiraRequestPayloadForField
 */
export const getPayloadForField = (
  jiraFieldKey: string,
  jiraTypeSchema?: JiraTypeSchema,
  fieldValue?: string,
): JiraRequestPayloadForField => {
  try {
    if (commonFieldsToPayloadMap[jiraFieldKey]) {
      return commonFieldsToPayloadMap[jiraFieldKey](fieldValue!);
    } else {
      const fieldTypeSchema = getFieldTypeFromSchema(jiraTypeSchema);
      if (!fieldTypeSchema || !(fieldTypeSchema in supportedJiraTypesToPayloadMap)) {
        throw new Error('Unsupported jira field type ' + jiraTypeSchema);
      }
      return supportedJiraTypesToPayloadMap[fieldTypeSchema](fieldValue!);
    }
  } catch (err) {
    throw new Error('unable to determine payload for ' + jiraFieldKey);
  }
};

/**
 * @summary sanitizes limit value
 * @param limit : string
 * @returns sanitized number for limit
 */
export function sanitizeLimit(limit: string): number {
  try {
    limit = limit ? limit : '10';
    const result: number = parseInt(limit);
    return result < 1 ? 10 : result;
  } catch (error) {
    return 10;
  }
}

/**
 * @summary helper function for creating a custom error.
 * @param msg message string to log into the console
 * @param error Error object
 * @returns a custom error object
 */
export function createJiraError(msg: string, error: any): string {
  return `Jira API error. ${msg} ${
    error.response ? (error.response.data ? JSON.stringify(error.response.data) : error) : error
  }`;
}

/**
 * @summary performs single request to get data for JQL.
 * @param paginationParams
 * - maxResults
 * - startAt
 * @param dataForAPIRequest
 * - jiraCloudId
 * -accessToken
 * - jqlQuery
 * @returns JQL response
 * startAt , maxResults, issues
 */
export async function getJqlData(
  paginationParams: PaginationParams,
  dataForAPIRequest: JiraAPIRequestData,
  fields?: string,
): Promise<JQLResponse> {
  const response: AxiosResponse = await getJiraApiClient(dataForAPIRequest.accessToken).get(
    `ex/jira/${dataForAPIRequest.jiraCloudId}/rest/api/3/search?jql=${encodeURI(
      dataForAPIRequest.jqlQuery,
    )}&validateQuery=strict&startAt=${paginationParams.startAt}&maxResults=${
      paginationParams.maxResults
    }&fields=${fields || '*all'}`,
  );
  return response.data;
}

/**
 * convert the unit timestamp to jira acceptable date time format in utc yyyy-mm-dd hh:mm
 * @param timeStamp
 */
export const convertTimeStampToJiraFormat = (timeStamp: number): string => {
  const date: Date = new Date(timeStamp);
  return `${date.getUTCFullYear()}-${
    date.getUTCMonth() + 1
  }-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}`;
};

/**
 * @summary gets the default project fields of project Id and issue type Id if they are not passed.
 * @param cloudId
 * @param accessToken
 * @returns - default project and issue type
 */
export async function getDefaultProjectData(
  cloudId: string,
  accessToken: string,
  projectKey?: string,
): Promise<DefaultProjectData> {
  let projectId: string;
  const client = getJiraApiClient(accessToken);

  if (!projectKey) {
    const projectsResponse: AxiosResponse<GetProjectsResponse> = await client.get(
      `ex/jira/${cloudId}/rest/api/3/project/search`,
    );
    if (!projectsResponse.data.total) {
      throw new Error('No projects are visible to the authenticated user');
    }
    projectId = projectsResponse.data.values[0].id;
  } else {
    const projectByKey: AxiosResponse<GetProjectsResponse> = await client.get<GetProjectsResponse>(
      `ex/jira/${cloudId}/rest/api/3/project/search?keys=${projectKey}`,
    );
    if (!projectByKey.data.values.length) {
      throw new Error(`Project with key "${projectKey}" is not visible to the authenticated user`);
    }
    projectId = projectByKey.data.values[0].id;
  }

  const createMetaDataResponse: AxiosResponse<GetCreateIssueMetaDataResponse> =
    await getJiraApiClient(accessToken).get(`ex/jira/${cloudId}/rest/api/3/issue/createmeta`, {
      params: {
        projectIds: projectId,
        expand: 'projects.issuetypes.fields',
      },
    });

  const foundProject = createMetaDataResponse.data.projects[0];
  if (!foundProject) {
    throw new Error(`No project found for ID: ${projectId}`);
  }
  if (foundProject.issuetypes?.length === 0) {
    throw new Error(`No issue types found in project ID "${projectId}"`);
  }
  const taskIssueType = foundProject.issuetypes.find(
    (issueType) => issueType.name.toLowerCase() === 'task',
  );
  if (!taskIssueType) {
    throw new Error(`No Task issue type found in project ID "${projectId}"`);
  }

  return {
    projectKey: foundProject.key,
    issueType: taskIssueType.id,
  };
}

/**
 * @summary gets the account id for logged in user.
 * @param cloudId
 * @param accessToken
 * @returns account Id
 */
export async function getLoggedInUserId(cloudId: string, accessToken: string): Promise<string> {
  const userInfoResponse: AxiosResponse<UserIdentityResponse> = await getJiraApiClient(
    accessToken,
  ).get<UserIdentityResponse>(`ex/jira/${cloudId}/rest/api/3/myself`);
  return userInfoResponse.data.accountId;
}

/**
 * delete a jira issue
 * @param credentials credentials
 * @param environment environment
 * @param issue issue key to delete
 */
export async function deleteIssue(
  credentials: Credentials,
  environment: Environment,
  issue: string,
) {
  const { accessToken: newAccessToken } = await exchangeJiraRefreshTokenForAccessToken(
    environment.JIRA_CLIENT_ID,
    environment.JIRA_CLIENT_SECRET,
    credentials,
  );
  await getJiraApiClient(newAccessToken).delete(
    `ex/jira/${credentials.JIRA_CLOUD_ID}/rest/api/3/issue/${issue}`,
  );
}

/**
 * @summary removes jql query
 * @param jql user input jql query
 * @returns corrected jql query string
 */
export function stripJqlDateOperators(jql: string = '', stringToStrip: 'created' | 'updated') {
  const operatorsPresent = [' and ', ' AND ', ' or ', ' OR '].filter((operator: string) => {
    return jql.indexOf(operator) > -1;
  });

  if (operatorsPresent.length === 0) {
    return jql.indexOf(stringToStrip) > -1 ? '' : jql;
  }

  const correctedJql = operatorsPresent.reduce((jqlString: string, operator: string) => {
    let jqlQueryArray: string[] = jqlString.split(operator);

    jqlQueryArray = jqlQueryArray.filter(
      (jqlFragment: string) => jqlFragment.indexOf(stringToStrip) === -1,
    );

    return jqlQueryArray.join(operator);
  }, jql);

  return correctedJql;
}

export const isAccessTokenValid = async (accessToken: string): Promise<boolean> => {
  try {
    await getJiraApiClient(accessToken).get<LoggedInUserResources>(
      'oauth/token/accessible-resources',
    );
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Create transition issue with transition id
 * @param accessToken accesstoken
 * @param issueKey issue key
 * @param jiraIssueStatus  issue status
 * @param JIRA_CLOUD_ID
 */
export const createTransitionIssue = async (
  accessToken: string,
  issueKey: string,
  jiraIssueStatus: string,
  JIRA_CLOUD_ID: string,
): Promise<void> => {
  const transitions: AxiosResponse<JiraTransitionResponse> = await getJiraApiClient(
    accessToken,
  ).get(`ex/jira/${JIRA_CLOUD_ID}/rest/api/3/issue/${issueKey}/transitions`);
  const transitionId: string | undefined = transitions.data.transitions.find(
    (transition: JiraTransition) => transition.to.id === jiraIssueStatus,
  )?.id;

  if (!transitionId) {
    throw new Error('Transition id or Issue status id is incorrect');
  }

  await getJiraApiClient(accessToken).post(
    `ex/jira/${JIRA_CLOUD_ID}/rest/api/3/issue/${issueKey}/transitions`,
    {
      transition: {
        id: transitionId,
      },
    },
  );
};

/**
 * fetch selected records for paginated source or return object containing error
 * @param client
 * @param url
 * @returns
 */
export const getSelectedRecord = async <T>(
  client: AxiosInstance,
  url: string,
): Promise<{ data: T | undefined; err?: string }> => {
  try {
    const selectedRecord = (await client.get<T>(url)).data;

    return { data: selectedRecord };
  } catch (err) {
    return { data: undefined, err: createJiraError('Unable to get selected record', err) };
  }
};
