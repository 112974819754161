import * as Sentry from '@sentry/node';
import { init } from '@sentry/node';

import { ParagonService } from '@shared/types/sdk/atlas';
import { PLATFORM_ENV } from '@shared/types/sdk/environment';
import { StepFailure } from '@shared/types/sdk/errors';
import { hash } from '@shared/utils/sdk/crypto';
import { CacheMode, CacheThrottle } from '@shared/utils/sdk/throttle';

// PARA-3374: This throttle rate-limits calls to Sentry.
// Unique events (key being the message) won't be sent more than once per 30 seconds per process.
// While 30 seconds is on the higher side, we do usually have 20+ Hercules instances and at least 3 of other microservices running.
// This won't limit the calls across all processes but will allow us to get enough context and know if there's a high frequency event.
// This was needed because we regularly deplete our Sentry quota when just a few errors send hundreds of thousands or millions of events.
export const throttle: CacheThrottle = new CacheThrottle({
  mode: CacheMode.Throttle,
  ttl: 1000 * 30,
  serializeKey: (key: string) => hash(key),
});

export type SentryConfig = {
  dsn: string;
  release: string;
  environment: PLATFORM_ENV;
  serverName: ParagonService;
  // @ts-ignore isn't support integrations from @sentry/node
  integrations?: any[];
};

export default function initialize(config: SentryConfig): void {
  const { dsn, environment } = config;
  if (!dsn) {
    if (environment === PLATFORM_ENV.PRODUCTION) {
      console.error('`SENTRY_DSN` not provided! Errors not being reported to sentry.');
    }

    return;
  }

  // The `released passed to the config has the branch name and commit, e.g.
  // `refs/heads/master/a8a9d25fddda05e68b6f1d9d7919a5e477bd1185`
  // We want to get just the commit.
  // UPDATE: 2021-07-21 - we switched to semver versioning but the unit tests show this still works fine.
  // Keeping it in here so it can support both commit and semver versions
  const commit: string = config.release.split('/').reverse()[0];
  const release: string = `paragon@${commit}`;

  init({
    ...config,
    release,
    debug: environment !== PLATFORM_ENV.PRODUCTION,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    tracesSampleRate: environment === PLATFORM_ENV.PRODUCTION ? 0.25 : 1.0,
    integrations: config.integrations ?? [],
  });
}

export function report(...args: any[]): void {
  const [message, ...meta] = args;

  if (!message || typeof message !== 'string' || !message.trim()) {
    return;
  } else if (meta && meta instanceof StepFailure) {
    return;
  }

  void throttle.do(message, () => {
    Sentry.captureEvent({
      level: Sentry.Severity.Error,
      message,
      extra: {
        meta,
      },
    });
  });
}
