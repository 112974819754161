import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import authConfig, { authInput } from '../shared/authConfig';

import {
  accountIdInput,
  accountNameInput,
  accountsFilterInputOauth,
  amountInput,
  closeDateInput,
  commonIntentInput,
  companyInput,
  companyTypeInput,
  customIdInput,
  descriptionInput,
  emailInput,
  externalIdInput,
  firstNameInput,
  industryInput,
  lastNameInput,
  numberOfEmployeesInput,
  occupationInput,
  opportunityFilterInputOauth,
  opportunityIdInput,
  opportunityNameInput,
  opportunityStageDropdownInput,
  phoneInput,
  prospectIdInput,
  prospectsFilterInputOauth,
  sequenceDropdownInput,
  websiteURLInput,
} from './common';

const config: ActionConfig = {
  actionType: Action.OUTREACH,
  name: 'Outreach',
  description: 'Find, create, and update records in Outreach.',
  icon: iconSvg,
  provider: ProviderType.OUTREACH,
  sidebarSections: [
    (_parameters: ActionStepParameters, _credentials: Record<string, ICredential>) => ({
      inputs: [authInput],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length ? commonIntentInput : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_ACCOUNT:
        case Intent.UPDATE_ACCOUNT:
          return {
            inputs: [
              ...(parameters.intent === Intent.UPDATE_ACCOUNT
                ? [{ ...accountIdInput, subtitle: 'The ID of the account to update.' }]
                : []),
              accountNameInput,
              websiteURLInput,
              descriptionInput,
              companyTypeInput,
              industryInput,
              numberOfEmployeesInput,
              customIdInput,
            ],
          };

        case Intent.GET_ACCOUNTS:
          return {
            inputs: [accountsFilterInputOauth],
          };
        case Intent.GET_ACCOUNT_BY_ID:
          return {
            inputs: [{ ...accountIdInput, subtitle: '' }],
          };
        case Intent.CREATE_OPPORTUNITY:
        case Intent.UPDATE_OPPORTUNITY:
          return {
            inputs: [
              ...(parameters.intent === Intent.UPDATE_OPPORTUNITY ? [opportunityIdInput] : []),
              {
                ...opportunityNameInput,
                required: parameters.intent === Intent.CREATE_OPPORTUNITY,
              },
              amountInput,
              {
                ...accountIdInput,
                required: false,
                subtitle: 'The Account that the opportunity belongs to.',
              },
              opportunityStageDropdownInput,
              closeDateInput,
              descriptionInput,
            ],
          };
        case Intent.GET_OPPORTUNITIES:
          return {
            inputs: [opportunityFilterInputOauth],
          };
        case Intent.GET_OPPORTUNITY_BY_ID:
          return { inputs: [{ ...opportunityIdInput, subtitle: '' }] };

        case Intent.CREATE_PROSPECT:
        case Intent.UPDATE_PROSPECT:
          return {
            inputs: [
              ...(parameters.intent === Intent.UPDATE_PROSPECT ? [prospectIdInput] : []),
              { ...emailInput, required: parameters.intent === Intent.CREATE_PROSPECT },
              firstNameInput,
              lastNameInput,
              {
                ...accountIdInput,
                required: false,
                subtitle: 'The Account that the prospect belongs to.',
              },
              phoneInput,
              companyInput,
              occupationInput,
              websiteURLInput,
              externalIdInput,
            ],
          };
        case Intent.GET_PROSPECTS:
          return { inputs: [prospectsFilterInputOauth] };
        case Intent.GET_PROSPECT_BY_ID:
          return { inputs: [{ ...prospectIdInput, subtitle: '' }] };
        case Intent.ADD_PROSPECTS_TO_SEQUENCE:
          return {
            inputs: [
              sequenceDropdownInput,
              { ...prospectIdInput, subtitle: 'ID of prospect to be added to the Sequence.' },
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };

export default config;
