import { UTMParameter } from '@shared/types/sdk/analytics';

import cookies from '../../utils/cookies';
import { Action, NavigationState } from '../types';

export const initialState: NavigationState = {
  utmParameters: cookies.utm,
};

export function handler(state: NavigationState = initialState, action: Action): NavigationState {
  switch (action.type) {
    case 'NAVIGATE':
      const utmParameters: Partial<Record<UTMParameter, string>> = {
        ...state.utmParameters,
        ...action.utmParameters,
      };
      cookies.utm = utmParameters;
      return {
        ...state,
        projectId: action.projectId,
        workflowId: action.workflowId,
        memberId: action.memberId,
        versionId: action.versionId,
        executionId: action.executionId,
        isConnectUi: action.isConnectUi,
        integrationId: action.integrationId,
        utmParameters,
      };
    case 'NAVIGATE_SETTINGS_TAB':
      return {
        ...state,
        activeSettingsTab: action.payload,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return action.projectId ? { ...state, projectId: action.projectId } : state;
    default:
      return state;
  }
}
