import { AxiosError, AxiosRequestConfig } from 'axios';
import { ReactNode } from 'react';

import {
  AuthenticationScheme,
  CredentialValues,
  ICredential,
  OAuthBasicCredentialValues,
  OAuthCredentialValues,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  ActionStepParameters as AirtableActionStepParameters,
  AuthConfig as AirtableAuthConfig,
  ActionConfig as AirtableConfig,
  Credentials as AirtableCredentials,
  Intent as AirtableIntent,
} from '@shared/integrations/airtable/configs';
import {
  ActionStepParameters as AmazonS3ActionStepParameters,
  AuthConfig as AmazonS3AuthConfig,
  ActionConfig as AmazonS3Config,
  Credentials as AmazonS3Credentials,
  Intent as AmazonS3Intent,
} from '@shared/integrations/amazons3/configs';
import {
  ActionStepParameters as AsanaActionStepParameters,
  AuthConfig as AsanaAuthConfig,
  ActionConfig as AsanaConfig,
  Credentials as AsanaCredentials,
  Intent as AsanaIntent,
} from '@shared/integrations/asana/configs';
import {
  ActionStepParameters as AzureDevOpsActionStepParameters,
  AuthConfig as AzureDevOpsAuthConfig,
  ActionConfig as AzureDevOpsConfig,
  Credentials as AzureDevOpsCredentials,
  Intent as AzureDevOpsIntent,
} from '@shared/integrations/azuredevops/configs';
import {
  ActionStepParameters as BambooHrActionStepParameters,
  AuthConfig as BambooHrAuthConfig,
  ActionConfig as BambooHrConfig,
  Credentials as BambooHrCredentials,
  Intent as BambooHrIntent,
} from '@shared/integrations/bamboohr/configs';
import {
  ActionStepParameters as BigQueryActionStepParameters,
  AuthConfig as BigQueryAuthConfig,
  ActionConfig as BigQueryConfig,
  Credentials as BigQueryCredentials,
  Intent as BigQueryIntent,
} from '@shared/integrations/bigquery/configs';
import {
  ActionStepParameters as CalendlyActionStepParameters,
  AuthConfig as CalendlyAuthConfig,
  ActionConfig as CalendlyConfig,
  Credentials as CalendlyCredentials,
  Intent as CalendlyIntent,
} from '@shared/integrations/calendly/configs';
import {
  ActionStepParameters as ClickUpActionStepParameters,
  AuthConfig as ClickUpAuthConfig,
  ActionConfig as ClickUpConfig,
  Credentials as ClickUpCredentials,
  Intent as ClickUpIntent,
} from '@shared/integrations/clickup/configs';
import {
  ActionStepParameters as CloseActionStepParameters,
  AuthConfig as CloseAuthConfig,
  ActionConfig as CloseConfig,
  Credentials as CloseCredentials,
  Intent as CloseIntent,
} from '@shared/integrations/close/configs';
import {
  ActionStepParameters as DocuSignActionStepParameters,
  AuthConfig as DocuSignAuthConfig,
  ActionConfig as DocuSignConfig,
  Credentials as DocuSignCredentials,
  Intent as DocuSignIntent,
} from '@shared/integrations/docusign/configs';
import {
  ActionStepParameters as DropboxActionStepParameters,
  AuthConfig as DropboxAuthConfig,
  ActionConfig as DropboxConfig,
  Credentials as DropboxCredentials,
  Intent as DropboxIntent,
} from '@shared/integrations/dropbox/configs';
import {
  ActionStepParameters as DynamicsBusinessCentralActionStepParameters,
  AuthConfig as DynamicsBusinessCentralAuthConfig,
  ActionConfig as DynamicsBusinessCentralConfig,
  Credentials as DynamicsBusinessCentralCredentials,
  Intent as DynamicsBusinessCentralIntent,
} from '@shared/integrations/dynamicsbusinesscentral/configs';
import {
  Credentials as MicrosoftDynamicFinanceCredentials,
  ActionStepParameters as MicrosoftDynamicsFinanceActionStepParameters,
  AuthConfig as MicrosoftDynamicsFinanceAuthConfig,
  ActionConfig as MicrosoftDynamicsFinanceConfig,
  Intent as MicrosoftDynamicsFinanceIntent,
} from '@shared/integrations/dynamicsfinance/configs';
import {
  ActionStepParameters as DynamoDBActionStepParameters,
  AuthConfig as DynamoDBAuthConfig,
  ActionConfig as DynamoDBConfig,
  Credentials as DynamoDBCredentials,
  Intent as DynamoDBIntent,
} from '@shared/integrations/dynamodb/configs';
import {
  ActionStepParameters as EloquaActionStepParameters,
  AuthConfig as EloquaAuthConfig,
  ActionConfig as EloquaConfig,
  Credentials as EloquaCredentials,
  Intent as EloquaIntent,
} from '@shared/integrations/eloqua/configs';
import {
  ActionStepParameters as FacebookAdsActionStepParameters,
  AuthConfig as FacebookAdsAuthConfig,
  ActionConfig as FacebookAdsConfig,
  Credentials as FacebookAdsCredentials,
  Intent as FacebookAdsIntent,
} from '@shared/integrations/facebookAds/configs';
import {
  ActionStepParameters as FirebaseActionStepParameters,
  AuthConfig as FirebaseAuthConfig,
  ActionConfig as FirebaseConfig,
  Credentials as FirebaseCredentials,
  Intent as FirebaseIntent,
} from '@shared/integrations/firebase/configs';
import {
  ActionStepParameters as FreshdeskActionStepParameters,
  AuthConfig as FreshdeskAuthConfig,
  ActionConfig as FreshdeskConfig,
  Credentials as FreshdeskCredentials,
  Intent as FreshdeskIntent,
} from '@shared/integrations/freshdesk/configs';
import {
  ActionStepParameters as FtpActionStepParameters,
  AuthConfig as FtpAuthConfig,
  ActionConfig as FtpConfig,
  Credentials as FtpCredentials,
  Intent as FtpIntent,
} from '@shared/integrations/ftp/configs';
import {
  ActionStepParameters as GithubActionStepParameters,
  AuthConfig as GithubAuthConfig,
  ActionConfig as GithubConfig,
  Credentials as GithubCredentials,
  Intent as GithubIntent,
} from '@shared/integrations/github/configs';
import {
  ActionStepParameters as GmailActionStepParameters,
  AuthConfig as GmailAuthConfig,
  ActionConfig as GmailConfig,
  Credentials as GmailCredentials,
  Intent as GmailIntent,
} from '@shared/integrations/gmail/configs';
import {
  ActionStepParameters as GongActionStepParameters,
  AuthConfig as GongAuthConfig,
  ActionConfig as GongConfig,
  Credentials as GongCredentials,
  Intent as GongIntent,
} from '@shared/integrations/gong/configs';
import {
  AuthConfig as GoogleAdManagerAuthConfig,
  ActionConfig as GoogleAdManagerConfig,
  Credentials as GoogleAdManagerCredentials,
  Intent as GoogleAdManagerIntent,
  ActionStepParameters as GoogleAdManagerStepParameters,
} from '@shared/integrations/googleadmanager/configs';
import {
  ActionStepParameters as GoogleAdsActionStepParameters,
  AuthConfig as GoogleAdsAuthConfig,
  ActionConfig as GoogleAdsConfig,
  Credentials as GoogleAdsCredentials,
  Intent as GoogleAdsIntent,
} from '@shared/integrations/googleads/configs';
import {
  AuthConfig as GoogleAnalyticsAuthConfig,
  ActionConfig as GoogleAnalyticsConfig,
  Credentials as GoogleAnalyticsCredentials,
  Intent as GoogleAnalyticsIntent,
  ActionStepParameters as GoogleAnalyticsStepParameters,
} from '@shared/integrations/googleanalytics/configs';
import {
  AuthConfig as GoogleCalendarAuthConfig,
  ActionConfig as GoogleCalendarConfig,
  Credentials as GoogleCalendarCredentials,
  Intent as GoogleCalendarIntent,
  ActionStepParameters as GoogleCalendarStepParameters,
} from '@shared/integrations/googleCalendar/configs';
import {
  ActionStepParameters as GoogleCampaignManagerActionStepParameters,
  AuthConfig as GoogleCampaignManagerAuthConfig,
  ActionConfig as GoogleCampaignManagerConfig,
  Credentials as GoogleCampaignManagerCredentials,
  Intent as GoogleCampaignManagerIntent,
} from '@shared/integrations/googlecampaignmanager/configs';
import {
  ActionStepParameters as GoogledriveActionStepParameters,
  AuthConfig as GoogledriveAuthConfig,
  ActionConfig as GoogledriveConfig,
  Credentials as GoogledriveCredentials,
  Intent as GoogledriveIntent,
} from '@shared/integrations/googledrive/configs';
import {
  ActionStepParameters as GoogleSearchConsoleActionStepParameters,
  AuthConfig as GoogleSearchConsoleAuthConfig,
  ActionConfig as GoogleSearchConsoleConfig,
  Credentials as GoogleSearchConsoleCredentials,
  Intent as GoogleSearchConsoleIntent,
} from '@shared/integrations/googlesearchconsole/configs';
import {
  AuthConfig as GooglesheetsAuthConfig,
  ActionConfig as GooglesheetsConfig,
  Credentials as GooglesheetsCredentials,
  Intent as GooglesheetsIntent,
  ActionStepParameters as GooglesheetsStepParameters,
} from '@shared/integrations/googlesheets/configs';
import {
  ActionStepParameters as GreenhouseActionStepParameters,
  AuthConfig as GreenhouseAuthConfig,
  ActionConfig as GreenhouseConfig,
  Credentials as GreenhouseCredentials,
  Intent as GreenhouseIntent,
} from '@shared/integrations/greenhouse/configs';
import {
  ActionStepParameters as GustoActionStepParameters,
  AuthConfig as GustoAuthConfig,
  ActionConfig as GustoConfig,
  Credentials as GustoCredentials,
  Intent as GustoIntent,
} from '@shared/integrations/gusto/configs';
import {
  ActionStepParameters as HubspotActionStepParameters,
  AuthConfig as HubspotAuthConfig,
  ActionConfig as HubspotConfig,
  Credentials as HubspotCredentials,
  Intent as HubspotIntent,
} from '@shared/integrations/hubspot/configs';
import {
  ActionStepParameters as ImanageActionStepParameters,
  AuthConfig as ImanageAuthConfig,
  ActionConfig as ImanageConfig,
  Credentials as ImanageCredentials,
  Intent as ImanageIntent,
} from '@shared/integrations/imanage/configs';
import {
  ActionStepParameters as IntercomActionStepParameters,
  AuthConfig as IntercomAuthConfig,
  ActionConfig as IntercomConfig,
  Credentials as IntercomCredentials,
  Intent as IntercomIntent,
} from '@shared/integrations/intercom/configs';
import {
  ActionStepParameters as JiraActionStepParameters,
  AuthConfig as JiraAuthConfig,
  ActionConfig as JiraConfig,
  Credentials as JiraCredentials,
  Intent as JiraIntent,
} from '@shared/integrations/jira/configs';
import {
  ActionStepParameters as KlaviyoActionStepParameters,
  AuthConfig as KlaviyoAuthConfig,
  ActionConfig as KlaviyoConfig,
  Credentials as KlaviyoCredentials,
  Intent as KlaviyoIntent,
} from '@shared/integrations/klaviyo/configs';
import {
  ActionStepParameters as LeverActionStepParameters,
  AuthConfig as LeverAuthConfig,
  ActionConfig as LeverConfig,
  Credentials as LeverCredentials,
  Intent as LeverIntent,
} from '@shared/integrations/lever/configs';
import {
  ActionStepParameters as LinearActionStepParameters,
  AuthConfig as LinearAuthConfig,
  ActionConfig as LinearConfig,
  Credentials as LinearCredentials,
  Intent as LinearIntent,
} from '@shared/integrations/linear/configs';
import {
  ActionStepParameters as MagentoActionStepParameters,
  AuthConfig as MagentoAuthConfig,
  ActionConfig as MagentoConfig,
  Credentials as MagentoCredentials,
  Intent as MagentoIntent,
} from '@shared/integrations/magento/configs';
import {
  ActionStepParameters as MailChimpActionStepParameters,
  AuthConfig as MailChimpAuthConfig,
  ActionConfig as MailChimpConfig,
  Credentials as MailChimpCredentials,
  Intent as MailChimpIntent,
} from '@shared/integrations/mailchimp/configs';
import {
  ActionStepParameters as MailgunActionStepParameters,
  AuthConfig as MailgunAuthConfig,
  ActionConfig as MailgunConfig,
  Credentials as MailgunCredentials,
  Intent as MailgunIntent,
} from '@shared/integrations/mailgun/configs';
import {
  ActionStepParameters as MarketoActionStepParameters,
  AuthConfig as MarketoAuthConfig,
  ActionConfig as MarketoConfig,
  Credentials as MarketoCredentials,
  Intent as MarketoIntent,
} from '@shared/integrations/marketo/configs';
import {
  Credentials as MicrosoftDynamicCredentials,
  ActionStepParameters as MicrosoftDynamicsActionStepParameters,
  AuthConfig as MicrosoftDynamicsAuthConfig,
  ActionConfig as MicrosoftDynamicsConfig,
  Intent as MicrosoftDynamicsIntent,
} from '@shared/integrations/microsoftDynamics/configs';
import {
  ActionStepParameters as MicrosoftTeamsActionStepParameters,
  AuthConfig as MicrosoftTeamsAuthConfig,
  ActionConfig as MicrosoftTeamsConfig,
  Credentials as MicrosoftTeamsCredentials,
  Intent as MicrosoftTeamsIntent,
} from '@shared/integrations/microsoftTeams/configs';
import {
  ActionStepParameters as MixpanelActionStepParameters,
  AuthConfig as MixpanelAuthConfig,
  ActionConfig as MixpanelConfig,
  Credentials as MixpanelCredentials,
  Intent as MixpanelIntent,
} from '@shared/integrations/mixpanel/configs';
import {
  ActionStepParameters as MondayActionStepParameters,
  AuthConfig as MondayAuthConfig,
  ActionConfig as MondayConfig,
  Credentials as MondayCredentials,
  Intent as MondayIntent,
} from '@shared/integrations/monday/configs';
import {
  ActionStepParameters as MongoDBActionStepParameters,
  AuthConfig as MongoDBAuthConfig,
  ActionConfig as MongoDBConfig,
  Credentials as MongoDBCredentials,
  Intent as MongoDBIntent,
} from '@shared/integrations/mongodb/configs';
import {
  ActionStepParameters as MySQLActionStepParameters,
  AuthConfig as MySQLAuthConfig,
  ActionConfig as MySQLConfig,
  Credentials as MySQLCredentials,
  Intent as MySQLIntent,
} from '@shared/integrations/mysql/configs';
import {
  ActionStepParameters as NetSuiteActionStepParameters,
  AuthConfig as NetSuiteAuthConfig,
  ActionConfig as NetSuiteConfig,
  Credentials as NetSuiteCredentials,
  Intent as NetSuiteIntent,
} from '@shared/integrations/netsuite/configs';
import {
  ActionStepParameters as OneSignalActionStepParameters,
  AuthConfig as OneSignalAuthConfig,
  ActionConfig as OneSignalConfig,
  Credentials as OneSignalCredentials,
  Intent as OneSignalIntent,
} from '@shared/integrations/onesignal/configs';
import {
  ActionStepParameters as OracleFinancialsCloudActionStepParameters,
  AuthConfig as OracleFinancialsCloudAuthConfig,
  ActionConfig as OracleFinancialsCloudConfig,
  Credentials as OracleFinancialsCloudCredentials,
  Intent as OracleFinancialsCloudIntent,
} from '@shared/integrations/oraclefinancialscloud/configs';
import {
  ActionStepParameters as MicrosoftOutlookActionStepParameters,
  AuthConfig as MicrosoftOutlookAuthConfig,
  ActionConfig as MicrosoftOutlookConfig,
  Credentials as MicrosoftOutlookCredentials,
  Intent as MicrosoftOutlookIntent,
} from '@shared/integrations/outlook/configs';
import {
  ActionStepParameters as OutreachActionStepParameters,
  AuthConfig as OutreachAuthConfig,
  ActionConfig as OutreachConfig,
  Credentials as OutreachCredentials,
  Intent as OutreachIntent,
} from '@shared/integrations/outreach/configs';
import {
  ActionStepParameters as PandaDocActionStepParameters,
  AuthConfig as PandaDocAuthConfig,
  ActionConfig as PandaDocConfig,
  Credentials as PandaDocCredentials,
  Intent as PandaDocIntent,
} from '@shared/integrations/pandadoc/configs';
import {
  ActionStepParameters as PardotActionStepParameters,
  AuthConfig as PardotAuthConfig,
  ActionConfig as PardotConfig,
  Credentials as PardotCredentials,
  Intent as PardotIntent,
} from '@shared/integrations/pardot/configs';
import {
  ActionStepParameters as PipedriveActionStepParameters,
  AuthConfig as PipedriveAuthConfig,
  ActionConfig as PipedriveConfig,
  Credentials as PipedriveCredentials,
  Intent as PipedriveIntent,
} from '@shared/integrations/pipedrive/configs';
import {
  ActionStepParameters as PostgresActionStepParameters,
  AuthConfig as PostgresAuthConfig,
  ActionConfig as PostgresConfig,
  Credentials as PostgresCredentials,
  Intent as PostgresIntent,
} from '@shared/integrations/postgres/configs';
import {
  AuthConfig as PowerBiAuthConfig,
  ActionConfig as PowerBiConfig,
  Credentials as PowerBiCredentials,
  Intent as PowerBiIntent,
  ActionStepParameters as PowerBiStepParameters,
} from '@shared/integrations/powerbi/configs';
import {
  ActionStepParameters as ProductboardActionStepParameters,
  AuthConfig as ProductboardAuthConfig,
  ActionConfig as ProductboardConfig,
  Credentials as ProductboardCredentials,
  Intent as ProductboardIntent,
} from '@shared/integrations/productboard/configs';
import {
  AuthConfig as QuickbooksAuthConfig,
  ActionConfig as QuickbooksConfig,
  Credentials as QuickbooksCredentials,
  Intent as QuickbooksIntent,
  ActionStepParameters as QuickbooksStepParameters,
} from '@shared/integrations/quickbooks/configs';
import {
  ActionStepParameters as SageAccountingActionStepParameters,
  AuthConfig as SageAccountingAuthConfig,
  ActionConfig as SageAccountingConfig,
  Credentials as SageAccountingCredentials,
  Intent as SageAccountingIntent,
} from '@shared/integrations/sageaccounting/configs';
import {
  ActionStepParameters as SageIntacctActionStepParameters,
  AuthConfig as SageIntacctAuthConfig,
  ActionConfig as SageIntacctConfig,
  Credentials as SageIntacctCredentials,
  Intent as SageIntacctIntent,
} from '@shared/integrations/sageintacct/configs';
import {
  ActionStepParameters as SailthruActionStepParameters,
  AuthConfig as SailthruAuthConfig,
  ActionConfig as SailthruConfig,
  Credentials as SailthruCredentials,
  Intent as SailthruIntent,
} from '@shared/integrations/sailthru/configs';
import {
  ActionStepParameters as SalesForceActionStepParameters,
  AuthConfig as SalesForceAuthConfig,
  ActionConfig as SalesForceConfig,
  Credentials as SalesForceCredentials,
  Intent as SalesForceIntent,
} from '@shared/integrations/salesforce/configs';
import {
  ActionStepParameters as SalesloftActionStepParameters,
  AuthConfig as SalesloftAuthConfig,
  ActionConfig as SalesloftConfig,
  Credentials as SalesloftCredentials,
  Intent as SalesloftIntent,
} from '@shared/integrations/salesloft/configs';
import {
  ActionStepParameters as SapActionStepParameters,
  AuthConfig as SapAuthConfig,
  ActionConfig as SapConfig,
  Credentials as SapCredentials,
  Intent as SapIntent,
} from '@shared/integrations/saps4hana/configs';
import {
  ActionStepParameters as SapSuccessFactorsActionStepParameters,
  AuthConfig as SapSuccessFactorsAuthConfig,
  ActionConfig as SapSuccessFactorsConfig,
  Credentials as SapSuccessFactorsCredentials,
  Intent as SapSuccessFactorsIntent,
} from '@shared/integrations/sapsuccessfactors/configs';
import {
  ActionStepParameters as SegmentActionStepParameters,
  AuthConfig as SegmentAuthConfig,
  ActionConfig as SegmentConfig,
  Credentials as SegmentCredentials,
  Intent as SegmentIntent,
} from '@shared/integrations/segment/configs';
import {
  ActionStepParameters as SendgridActionStepParameters,
  AuthConfig as SendgridAuthConfig,
  ActionConfig as SendgridConfig,
  Credentials as SendgridCredentials,
  Intent as SendgridIntent,
} from '@shared/integrations/sendgrid/configs';
import {
  ActionStepParameters as ServiceNowActionStepParameters,
  AuthConfig as ServiceNowAuthConfig,
  ActionConfig as ServiceNowConfig,
  Credentials as ServiceNowCredentials,
  Intent as ServiceNowIntent,
} from '@shared/integrations/servicenow/configs';
import {
  ActionStepParameters as SharePointActionStepParameters,
  AuthConfig as SharePointAuthConfig,
  ActionConfig as SharePointConfig,
  Credentials as SharePointCredentials,
  Intent as SharePointIntent,
} from '@shared/integrations/sharepoint/configs';
import {
  ActionConfig as ShopifyActionConfig,
  ActionStepParameters as ShopifyActionStepParameters,
  AuthConfig as ShopifyAuthConfig,
  Credentials as ShopifyCredentials,
  Intent as ShopifyIntent,
} from '@shared/integrations/shopify/configs';
import {
  ActionStepParameters as SlackActionStepParameters,
  AuthConfig as SlackAuthConfig,
  ActionConfig as SlackConfig,
  Credentials as SlackCredentials,
  Intent as SlackIntent,
} from '@shared/integrations/slack/configs';
import {
  ActionStepParameters as StripeActionStepParameters,
  AuthConfig as StripeAuthConfig,
  ActionConfig as StripeConfig,
  Credentials as StripeCredentials,
  Intent as StripeIntent,
} from '@shared/integrations/stripe/configs';
import {
  ActionStepParameters as TableauActionStepParameters,
  AuthConfig as TableauAuthConfig,
  ActionConfig as TableauConfig,
  Credentials as TableauCredentials,
  Intent as TableauIntent,
} from '@shared/integrations/tableau/configs';
import {
  ActionStepParameters as TrelloActionStepParameters,
  AuthConfig as TrelloAuthConfig,
  ActionConfig as TrelloConfig,
  Credentials as TrelloCredentials,
  Intent as TrelloIntent,
} from '@shared/integrations/trello/configs';
import {
  ActionStepParameters as TwilioActionStepParameters,
  AuthConfig as TwilioAuthConfig,
  ActionConfig as TwilioConfig,
  Credentials as TwilioCredentials,
  Intent as TwilioIntent,
} from '@shared/integrations/twilio/configs';
import {
  ActionStepParameters as TwitterActionStepParameters,
  AuthConfig as TwitterAuthConfig,
  ActionConfig as TwitterConfig,
  Credentials as TwitterCredentials,
  Intent as TwitterIntent,
} from '@shared/integrations/twitter/configs';
import {
  ActionStepParameters as WooCommerceActionStepParameters,
  AuthConfig as WooCommerceAuthConfig,
  ActionConfig as WooCommerceConfig,
  Credentials as WooCommerceCredentials,
  Intent as WooCommerceIntent,
} from '@shared/integrations/woocommerce/configs';
import {
  ActionStepParameters as XeroActionStepParameters,
  AuthConfig as XeroAuthConfig,
  ActionConfig as XeroConfig,
  Credentials as XeroCredentials,
  Intent as XeroIntent,
} from '@shared/integrations/xero/configs';
import {
  ActionStepParameters as ZendeskActionStepParameters,
  AuthConfig as ZendeskAuthConfig,
  ActionConfig as ZendeskConfig,
  Credentials as ZendeskCredentials,
  Intent as ZendeskIntent,
} from '@shared/integrations/zendesk/configs';
import {
  ActionStepParameters as ZohoCrmActionStepParameters,
  AuthConfig as ZohoCrmAuthConfig,
  ActionConfig as ZohoCrmConfig,
  Credentials as ZohoCrmCredentials,
  Intent as ZohoCrmIntent,
} from '@shared/integrations/zohocrm/configs';
import {
  ActionStepParameters as ZoomActionStepParameters,
  AuthConfig as ZoomAuthConfig,
  ActionConfig as ZoomConfig,
  Credentials as ZoomCredentials,
  Intent as ZoomIntent,
} from '@shared/integrations/zoom/configs';

import { DynamicMappingField, DynamicMappingOptions, ModalConfig } from '../connect';
import { WorkflowType } from '../execution';
import {
  DataType,
  KeyedSource,
  Operator,
  ResolvedUserSuppliedCredentialSource,
  Source,
} from '../resolvers';
import { BaseStep, DelayUnit, HttpMethod, StepType, StepTypeView } from '../steps';

export enum Action {
  /**
   * For custom integrations only.
   */
  CUSTOM = 'custom',
  ELOQUA = 'eloqua',
  GOOGLE_DRIVE = 'googledrive',
  TWILIO = 'twilio',
  SENDGRID = 'sendgrid',
  POSTGRES = 'postgres',
  MYSQL = 'mysql',
  GOOGLE_SHEETS = 'googlesheets',
  FIREBASE = 'firebase',
  AIRTABLE = 'airtable',
  SLACK = 'slack',
  MONGODB = 'mongodb',
  MAILGUN = 'mailgun',
  STRIPE = 'stripe',
  DYNAMODB = 'dynamodb',
  NONE = 'none',
  SALESFORCE = 'salesforce',
  ONESIGNAL = 'onesignal',
  GOOGLE_CALENDAR = 'googleCalendar',
  FTP = 'ftp',
  QUICKBOOKS = 'quickbooks',
  SHOPIFY = 'shopify',
  JIRA = 'jira',
  HUBSPOT = 'hubspot',
  KLAVIYO = 'klaviyo',
  INTERCOM = 'intercom',
  TABLEAU = 'tableau',
  ASANA = 'asana',
  ZENDESK = 'zendesk',
  MICROSOFT_TEAMS = 'microsoftTeams',
  ZOOM = 'zoom',
  MARKETO = 'marketo',
  MICROSOFT_DYNAMICS = 'microsoftDynamics',
  PARDOT = 'pardot',
  XERO = 'xero',
  OUTREACH = 'outreach',
  GOOGLE_CAMPAIGN_MANAGER = 'googlecampaignmanager',
  ORACLE_FINANCIALS_CLOUD = 'oraclefinancialscloud',
  TRELLO = 'trello',
  FACEBOOK_ADS = 'facebookAds',
  MAILCHIMP = 'mailchimp',
  AZURE_DEVOPS = 'azuredevops',
  MONDAY = 'monday.com',
  GOOGLE_SEARCH_CONSOLE = 'googlesearchconsole',
  CLICKUP = 'clickup',
  GOOGLE_ANALYTICS = 'googleanalytics',
  SHAREPOINT = 'sharepoint',
  OUTLOOK = 'outlook',
  SERVICENOW = 'servicenow',
  NETSUITE = 'netsuite',
  DYNAMICS_BUSINESS_CENTRAL = 'dynamicsbusinesscentral',
  PIPEDRIVE = 'pipedrive',
  SAGE_INTACCT = 'sageintacct',
  LINEAR = 'linear',
  GONG = 'gong',
  DYNAMICS_365_FINANCE = 'dynamicsfinance',
  SAGE_ACCOUNTING = 'sageaccounting',
  WOOCOMMERCE = 'woocommerce',
  IMANAGE = 'imanage',
  BAMBOO_HR = 'bamboohr',
  GMAIL = 'gmail',
  GOOGLE_AD_MANAGER = 'googleadmanager',
  DROPBOX = 'dropbox',
  // Magento is Adobe Commerce now as per changes to PARA-1642
  MAGENTO = 'magento',
  GITHUB = 'github',
  PRODUCTBOARD = 'productboard',
  DOCUSIGN = 'docusign',
  SAP_S4HANA = 'saps4hana',
  GOOGLE_ADS = 'googleads',
  GREENHOUSE = 'greenhouse',
  LEVER = 'lever',
  CALENDLY = 'calendly',
  ZOHO_CRM = 'zohocrm',
  SALESLOFT = 'salesloft',
  FRESHDESK = 'freshdesk',
  TWITTER = 'twitter',
  MIXPANEL = 'mixpanel',
  BIGQUERY = 'bigquery',
  POWER_BI = 'powerbi',
  SAP_SUCCESS_FACTORS = 'sapsuccessfactors',
  SEGMENT = 'segment',
  AMAZON_S3 = 'amazons3',
  SAILTHRU = 'sailthru',
  CLOSE = 'close',
  PANDA_DOC = 'pandadoc',
  GUSTO = 'gusto',
}

/**
 * disable action list that will not shown in the integration and sidebarsection for Paragon Automate
 */
export const DisabledActions: Action[] = [
  Action.SHOPIFY,
  Action.CLICKUP,
  Action.SHAREPOINT,
  Action.OUTLOOK,
  Action.SERVICENOW,
  Action.PIPEDRIVE,
  Action.SAGE_INTACCT,
  Action.DYNAMICS_365_FINANCE,
  Action.WOOCOMMERCE,
  Action.LINEAR,
  Action.NETSUITE,
  Action.DYNAMICS_BUSINESS_CENTRAL,
  Action.SAGE_ACCOUNTING,
  Action.IMANAGE,
  Action.BAMBOO_HR,
  Action.GMAIL,
  Action.MAGENTO,
  Action.GOOGLE_AD_MANAGER,
  Action.PRODUCTBOARD,
  Action.DROPBOX,
  Action.DOCUSIGN,
  Action.SAP_S4HANA,
  Action.GOOGLE_ADS,
  Action.GREENHOUSE,
  Action.LEVER,
  Action.ZOHO_CRM,
  Action.SALESLOFT,
  Action.FRESHDESK,
  Action.TWITTER,
  Action.BIGQUERY,
  Action.POWER_BI,
  Action.SAP_SUCCESS_FACTORS,
  Action.AMAZON_S3,
  Action.SAILTHRU,
  Action.PANDA_DOC,
  Action.GUSTO,
];

/*
 * disable action list that will not shown in Oauth Triggers
 * for zendesk and microsoft dynamics , a subdomain is required. user supplied credentials cannot handle that right now.
 */
export const OauthTriggerDisabledActions: Action[] = [
  Action.SHOPIFY,
  Action.ZENDESK,
  Action.MICROSOFT_DYNAMICS,
];

/**
 * add action here for which a ip whitelisting message has to be shown on frontend.
 */
export const WhiteListableActions: Action[] = [
  Action.POSTGRES,
  Action.MONGODB,
  Action.MYSQL,
  Action.FTP,
  Action.DYNAMODB,
];

// ? generally label should be fetched from action config since backend
// ? should not use `ActionConfig` as it include react specific code
export const ActionLabels: Record<Action, string> = {
  postgres: 'PostgreSQL database',
  googledrive: 'Google Drive',
  twilio: 'Twilio',
  sendgrid: 'Sendgrid',
  mysql: 'MySQL database',
  googlesheets: 'Google Sheets',
  firebase: 'Firebase',
  airtable: 'Airtable',
  slack: 'Slack',
  mongodb: 'MongoDB',
  mailgun: 'Mailgun',
  stripe: 'Stripe',
  dynamodb: 'DynamoDB',
  salesforce: 'Salesforce',
  onesignal: 'OneSignal',
  none: 'Invalid',
  googleCalendar: 'Google Calendar',
  ftp: '(S)FTP',
  intercom: 'Intercom',
  quickbooks: 'QuickBooks',
  hubspot: 'HubSpot',
  jira: 'Jira',
  tableau: 'Tableau',
  shopify: 'Shopify',
  asana: 'Asana',
  klaviyo: 'Klaviyo',
  zendesk: 'Zendesk',
  microsoftTeams: 'Microsoft Teams',
  zoom: 'Zoom',
  marketo: 'Marketo',
  microsoftDynamics: 'Micorsoft Dynamics 365',
  custom: 'Custom Integration',
  pardot: 'Pardot',
  xero: 'Xero',
  outreach: 'Outreach',
  googlecampaignmanager: 'Google Campaign Manager 360',
  oraclefinancialscloud: 'Oracle Financials Cloud',
  trello: 'Trello',
  facebookAds: 'Facebook Ads',
  mailchimp: 'Mailchimp',
  azuredevops: 'Azure DevOps',
  'monday.com': 'Monday.com',
  googlesearchconsole: 'Google Search Console',
  clickup: 'ClickUp',
  googleanalytics: 'Google Analytics',
  sharepoint: 'SharePoint',
  outlook: 'Microsoft Outlook',
  servicenow: 'ServiceNow',
  eloqua: 'Oracle Eloqua',
  netsuite: 'NetSuite',
  dynamicsbusinesscentral: 'Dynamics 365 Business Central',
  pipedrive: 'Pipedrive',
  sageintacct: 'Sage Intacct',
  linear: 'Linear',
  gong: 'Gong',
  dynamicsfinance: 'Dynamics 365 Finance',
  sageaccounting: 'Sage Accounting',
  woocommerce: 'WooCommerce',
  imanage: 'iManage',
  bamboohr: 'BambooHR',
  gmail: 'Gmail',
  googleadmanager: 'Google Ad Manager',
  dropbox: 'Dropbox',
  magento: 'Adobe Commerce',
  github: 'GitHub',
  productboard: 'Productboard',
  docusign: 'DocuSign',
  saps4hana: 'SAP S/4HANA',
  googleads: 'Google Ads',
  greenhouse: 'Greenhouse',
  lever: 'Lever',
  calendly: 'Calendly',
  zohocrm: 'Zoho CRM',
  salesloft: 'Salesloft',
  freshdesk: 'Freshdesk',
  twitter: 'Twitter',
  bigquery: 'BigQuery',
  powerbi: 'Power BI',
  mixpanel: 'Mixpanel',
  sapsuccessfactors: 'SAP SuccessFactors',
  amazons3: 'Amazon S3',
  sailthru: 'Sailthru',
  pandadoc: 'PandaDoc',
  segment: 'Segment',
  gusto: 'Gusto',
  close: 'Close',
};

export enum ActionCategory {
  POPULAR = 'popular',
  NEW_AND_NOTEWORTHY = 'new_and_noteworthy',
  DATABASES = 'databases',
  UNCATEGORIZED = 'uncategorized',
  MESSAGING = 'messaging',
  CRM = 'crm',
  SALES = 'sales',
  MARKETING_AUTOMATION = 'marketing_automation',
  ANALYTICS = 'analytics',
  ADVERTISING = 'advertising',
  E_COMMERCE = 'e-commerce',
  TEAM_COMMUNICATION = 'team_communication',
  OFFICE_SUITE = 'office_suite',
  PROJECT_MANAGEMENT = 'project_management',
  SUPPORT_TICKETING = 'support_ticketing',
  ACCOUNTING = 'accounting',
  PAYMENTS = 'payments',
  HR = 'hr',
  APPLICANT_TRACKING_SYSTEM = 'applicant_tracking_system',
  BUSINESS_INTELLIGENCE = 'business_intelligence',
  SOCIAL_MEDIA = 'social_media',
}

export type ConnectActionCategory = Exclude<
  ActionCategory,
  ActionCategory.DATABASES | ActionCategory.UNCATEGORIZED | ActionCategory.MESSAGING
>;

export type ActionCategoryDetails = {
  label: string;
};

export type ActionConfig =
  | EloquaAuthConfig
  | EloquaConfig
  | GoogleAnalyticsAuthConfig
  | GoogleAnalyticsConfig
  | GoogledriveConfig
  | GoogledriveAuthConfig
  | TwilioConfig
  | TwilioAuthConfig
  | SendgridConfig
  | SendgridAuthConfig
  | PostgresConfig
  | PostgresAuthConfig
  | MySQLConfig
  | MySQLAuthConfig
  | GooglesheetsConfig
  | GooglesheetsAuthConfig
  | FirebaseConfig
  | FirebaseAuthConfig
  | AirtableConfig
  | AirtableAuthConfig
  | SlackAuthConfig
  | SlackConfig
  | MongoDBAuthConfig
  | MongoDBConfig
  | MailChimpAuthConfig
  | MailChimpConfig
  | MailgunAuthConfig
  | MailgunConfig
  | StripeAuthConfig
  | StripeConfig
  | SalesForceAuthConfig
  | SalesForceConfig
  | OneSignalAuthConfig
  | OneSignalConfig
  | DynamoDBConfig
  | DynamoDBAuthConfig
  | GoogleCalendarAuthConfig
  | GoogleCalendarConfig
  | FtpAuthConfig
  | FtpConfig
  | QuickbooksAuthConfig
  | QuickbooksConfig
  | ShopifyActionConfig
  | ShopifyAuthConfig
  | JiraAuthConfig
  | JiraConfig
  | HubspotConfig
  | HubspotAuthConfig
  | KlaviyoConfig
  | KlaviyoAuthConfig
  | IntercomConfig
  | IntercomAuthConfig
  | TableauConfig
  | TableauAuthConfig
  | AsanaAuthConfig
  | AsanaConfig
  | ZendeskAuthConfig
  | ZendeskConfig
  | MicrosoftTeamsAuthConfig
  | MicrosoftTeamsConfig
  | ZoomAuthConfig
  | ZoomConfig
  | MarketoAuthConfig
  | MarketoConfig
  | MicrosoftDynamicsConfig
  | MicrosoftDynamicsAuthConfig
  | PardotConfig
  | PardotAuthConfig
  | XeroAuthConfig
  | XeroConfig
  | OutreachAuthConfig
  | OutreachConfig
  | GoogleCampaignManagerAuthConfig
  | GoogleCampaignManagerConfig
  | OracleFinancialsCloudAuthConfig
  | OracleFinancialsCloudConfig
  | TrelloAuthConfig
  | TrelloConfig
  | AzureDevOpsAuthConfig
  | AzureDevOpsConfig
  | FacebookAdsAuthConfig
  | FacebookAdsConfig
  | MondayAuthConfig
  | MondayConfig
  | GoogleSearchConsoleAuthConfig
  | GoogleSearchConsoleConfig
  | ClickUpConfig
  | ClickUpAuthConfig
  | SharePointAuthConfig
  | SharePointConfig
  | MicrosoftOutlookAuthConfig
  | MicrosoftOutlookConfig
  | ServiceNowAuthConfig
  | ServiceNowConfig
  | NetSuiteAuthConfig
  | NetSuiteConfig
  | DynamicsBusinessCentralConfig
  | DynamicsBusinessCentralAuthConfig
  | PipedriveAuthConfig
  | PipedriveConfig
  | SageIntacctAuthConfig
  | SageIntacctConfig
  | LinearAuthConfig
  | LinearConfig
  | MicrosoftDynamicsFinanceAuthConfig
  | MicrosoftDynamicsFinanceConfig
  | SageAccountingAuthConfig
  | SageAccountingConfig
  | GongAuthConfig
  | GongConfig
  | WooCommerceAuthConfig
  | WooCommerceConfig
  | ImanageAuthConfig
  | ImanageConfig
  | BambooHrAuthConfig
  | BambooHrConfig
  | GmailAuthConfig
  | GmailConfig
  | GoogleAdManagerAuthConfig
  | GoogleAdManagerConfig
  | DropboxAuthConfig
  | DropboxConfig
  | MagentoAuthConfig
  | MagentoConfig
  | GithubAuthConfig
  | GithubConfig
  | ProductboardAuthConfig
  | ProductboardConfig
  | DocuSignAuthConfig
  | DocuSignConfig
  | SapAuthConfig
  | SapConfig
  | GoogleAdsAuthConfig
  | GoogleAdsConfig
  | GreenhouseAuthConfig
  | GreenhouseConfig
  | LeverAuthConfig
  | LeverConfig
  | CalendlyConfig
  | CalendlyAuthConfig
  | ZohoCrmAuthConfig
  | ZohoCrmConfig
  | SalesloftAuthConfig
  | SalesloftConfig
  | FreshdeskAuthConfig
  | FreshdeskConfig
  | TwitterAuthConfig
  | TwitterConfig
  | BigQueryAuthConfig
  | BigQueryConfig
  | PowerBiAuthConfig
  | PowerBiConfig
  | MixpanelAuthConfig
  | MixpanelConfig
  | SapSuccessFactorsAuthConfig
  | SapSuccessFactorsConfig
  | AmazonS3AuthConfig
  | AmazonS3Config
  | SailthruAuthConfig
  | SailthruConfig
  | PandaDocAuthConfig
  | PandaDocConfig
  | SegmentAuthConfig
  | SegmentConfig
  | GustoAuthConfig
  | GustoConfig
  | CloseAuthConfig
  | CloseConfig;

export type ActionCredentials =
  | EloquaCredentials
  | GoogleAnalyticsCredentials
  | GoogledriveCredentials
  | TwilioCredentials
  | SendgridCredentials
  | PostgresCredentials
  | MySQLCredentials
  | FirebaseCredentials
  | AirtableCredentials
  | GooglesheetsCredentials
  | SlackCredentials
  | MongoDBCredentials
  | MailChimpCredentials
  | MailgunCredentials
  | StripeCredentials
  | SalesForceCredentials
  | OneSignalCredentials
  | DynamoDBCredentials
  | GoogleCalendarCredentials
  | FtpActionStepParameters
  | FtpCredentials
  | IntercomCredentials
  | QuickbooksCredentials
  | ShopifyCredentials
  | JiraCredentials
  | HubspotCredentials
  | AsanaCredentials
  | KlaviyoCredentials
  | TableauCredentials
  | ZendeskCredentials
  | MicrosoftTeamsCredentials
  | ZoomCredentials
  | MarketoCredentials
  | MicrosoftDynamicCredentials
  | PardotCredentials
  | XeroCredentials
  | OutreachCredentials
  | GoogleCampaignManagerCredentials
  | OracleFinancialsCloudCredentials
  | TrelloCredentials
  | AzureDevOpsCredentials
  | FacebookAdsCredentials
  | ClickUpCredentials
  | SharePointCredentials
  | GoogleSearchConsoleCredentials
  | MondayCredentials
  | ServiceNowCredentials
  | PipedriveCredentials
  | MicrosoftOutlookCredentials
  | ServiceNowCredentials
  | NetSuiteCredentials
  | DynamicsBusinessCentralCredentials
  | SageIntacctCredentials
  | MicrosoftDynamicFinanceCredentials
  | SageAccountingCredentials
  | WooCommerceCredentials
  | GongCredentials
  | LinearCredentials
  | ImanageCredentials
  | BambooHrCredentials
  | GmailCredentials
  | GoogleAdManagerCredentials
  | DropboxCredentials
  | MagentoCredentials
  | GithubCredentials
  | ProductboardCredentials
  | GoogleAdsCredentials
  | LeverCredentials
  | ZohoCrmCredentials
  | DocuSignCredentials
  | SapCredentials
  | GoogleAdsCredentials
  | SalesloftCredentials
  | GreenhouseCredentials
  | FreshdeskCredentials
  | CalendlyCredentials
  | TwitterCredentials
  | BigQueryCredentials
  | PowerBiCredentials
  | AmazonS3Credentials
  | MixpanelCredentials
  | SapSuccessFactorsCredentials
  | AmazonS3Credentials
  | SailthruCredentials
  | PandaDocCredentials
  | SegmentCredentials
  | GustoCredentials
  | CloseCredentials;

export enum OAuthIntent {
  GET_AUTH_ESSENTIALS = 'GET_AUTH_ESSENTIALS',
  EXCHANGE_AUTH_CREDENTIALS = 'EXCHANGE_AUTH_CREDENTIALS',
  REFRESH_CREDENTIAL = 'REFRESH_CREDENTIAL',
}

export enum ConnectIntent {
  GET_CONNECT_REQUEST_OPTIONS = 'GET_CONNECT_REQUEST_OPTIONS',
  GET_PROVIDER_ID = 'GET_PROVIDER_ID',
}

export type TestConnectionResponse = { success: boolean; error?: string };

export type ExchangeCredentialOutput = {
  name: string;
} & CredentialValues;

export type ProviderInfoResponse = { providerData?: Record<string, string>; id: string } | string;

export type BaseActionStepParameters = {
  actionType: Action;
  credentials: string[];
  retryOnFailure?: boolean;
  ignoreFailure?: boolean;
};

export type ActionStepParameters =
  | EloquaActionStepParameters
  | GoogleAnalyticsStepParameters
  | GoogledriveActionStepParameters
  | TwilioActionStepParameters
  | SendgridActionStepParameters
  | PostgresActionStepParameters
  | MySQLActionStepParameters
  | FirebaseActionStepParameters
  | AirtableActionStepParameters
  | GooglesheetsStepParameters
  | SlackActionStepParameters
  | MongoDBActionStepParameters
  | MailChimpActionStepParameters
  | MailgunActionStepParameters
  | StripeActionStepParameters
  | SalesForceActionStepParameters
  | OneSignalActionStepParameters
  | DynamoDBActionStepParameters
  | GoogleCalendarStepParameters
  | FtpActionStepParameters
  | IntercomActionStepParameters
  | QuickbooksStepParameters
  | ShopifyActionStepParameters
  | JiraActionStepParameters
  | HubspotActionStepParameters
  | AsanaActionStepParameters
  | KlaviyoActionStepParameters
  | TableauActionStepParameters
  | ZendeskActionStepParameters
  | MicrosoftTeamsActionStepParameters
  | ZoomActionStepParameters
  | MarketoActionStepParameters
  | MicrosoftDynamicsActionStepParameters
  | PardotActionStepParameters
  | XeroActionStepParameters
  | OutreachActionStepParameters
  | GoogleCampaignManagerActionStepParameters
  | OracleFinancialsCloudActionStepParameters
  | TrelloActionStepParameters
  | AzureDevOpsActionStepParameters
  | FacebookAdsActionStepParameters
  | MondayActionStepParameters
  | GoogleSearchConsoleActionStepParameters
  | ClickUpActionStepParameters
  | SharePointActionStepParameters
  | ServiceNowActionStepParameters
  | PipedriveActionStepParameters
  | MicrosoftOutlookActionStepParameters
  | ServiceNowActionStepParameters
  | NetSuiteActionStepParameters
  | DynamicsBusinessCentralActionStepParameters
  | SageIntacctActionStepParameters
  | MicrosoftDynamicsFinanceActionStepParameters
  | SageAccountingActionStepParameters
  | WooCommerceActionStepParameters
  | GongActionStepParameters
  | LinearActionStepParameters
  | ImanageActionStepParameters
  | BambooHrActionStepParameters
  | GmailActionStepParameters
  | GoogleAdManagerStepParameters
  | MagentoActionStepParameters
  | GithubActionStepParameters
  | DropboxActionStepParameters
  | ProductboardActionStepParameters
  | GoogleAdsActionStepParameters
  | LeverActionStepParameters
  | ZohoCrmActionStepParameters
  | DocuSignActionStepParameters
  | SapActionStepParameters
  | GoogleAdsActionStepParameters
  | SalesloftActionStepParameters
  | GreenhouseActionStepParameters
  | FreshdeskActionStepParameters
  | CalendlyActionStepParameters
  | TwitterActionStepParameters
  | BigQueryActionStepParameters
  | AmazonS3ActionStepParameters
  | MixpanelActionStepParameters
  | SapSuccessFactorsActionStepParameters
  | AmazonS3ActionStepParameters
  | SailthruActionStepParameters
  | PandaDocActionStepParameters
  | SegmentActionStepParameters
  | PowerBiStepParameters
  | GustoActionStepParameters
  | CloseActionStepParameters;

export type ActionIntent = ActionStepParameters['intent'];

export type ActionStep = BaseStep & {
  action: Action;
  type: StepType.ACTION;
  parameters: ActionStepParameters;
};

export type ActionTriggerStep = BaseStep & {
  type: StepType.ACTION_TRIGGER;
  parameters: ActionStepParameters;
};

export type ActionStepView = StepTypeView & {
  config: ActionConfig;
  initialParameters: Partial<ActionStepParameters>;
  providerDataKeyLabelMap?: Record<string, string>;
};

export type ComposeSidebarSection = (
  parameters: ActionStepParameters,
  credentialEntities: Record<string, ICredential>,
  workflowType?: WorkflowType,
) => SidebarSection;

export type SidebarSectionFactory = SidebarSection | ComposeSidebarSection;

export type ComposeSideBarInputs = (parameters: ActionStepParameters) => SidebarInput[];

export type BaseActionConfig = {
  actionType: Action;
  name?: string;
  description?: string;
  icon: string;
  mutedIcon?: string;
  provider?: ProviderType;

  /**
   * Key ~ Label map for integration provider data, to show formatted labels in variable menu
   * @since PARA-6274
   */
  providerDataKeyLabelMap?: Record<string, string>;

  scheme?: AuthenticationScheme;
  sidebarSections?: SidebarSectionFactory[];

  /**
   * Set to true for an auth config if it should support the "Connect your user's <service name>
   * account" option. This allows the credentials to be fully specified by parameters, without any
   * additional credential info required.
   * @since PARA-1957
   */
  supportsImpersonatedCredentialSource?: boolean;

  /**
   * Only applicable for AuthConfigs. Set to true if OAuth apps should not appear for this auth
   * config's matching ProviderTypes.
   */
  hideOAuthApps?: boolean;

  /**
   * Specify `accountTypes` to allow multiple different provider/scheme-typed creentials for an
   * action.
   */
  accountTypes?: AccountType[];

  /**
   * Specify a genric base url
   */
  publicBaseUrl?: string;

  /**
   * End user supplied inputs after oauth flow
   */
  postOAuthPrompt?: IntegrationConnectInput[];
};

export type AccountType = {
  /**
   * Provide a unique identifier for this account type, so that the relevant `sidebarSections`
   * or `oauthParameters` will be consumed by the AuthSelector accordingly.
   */
  id: string;

  /**
   * Specify an icon SVG string to be associated with this account type. Defaults to this config's
   * top-level icon.
   */
  icon?: string;

  /**
   * Specify a string to be interpolated into the "Add new {accountDescription} for
   * {providerType}" button. Should be a descriptor for the account, in lowercase. The default
   * value is "account".
   *
   * @example "sandbox account"
   * @example "service account"
   * @default "account"
   */
  accountDescription?: string;

  scheme: AuthenticationScheme;

  /**
   * @todo PARA-1680: **This property is currently unused by AuthSelector.**
   *
   * For AuthenticationScheme.BASIC-schemed credentials, specify a custom sidebarSections set for
   * the modal associated with this account type.
   */
  sidebarSections?: SidebarSectionFactory[];

  /**
   * For AuthenticationScheme.OAUTH-schemed credentials, specify value sources to be passed
   * through to the getAuthEssentials action.
   */
  oauthParameters?: KeyedSource<DataType.ANY>[];
  /**
   * Specify additional user inputs that are required to start the OAuth flow, i.e. a name
   * or subdomain prefix for a service that is a part of the OAuth authorization URL.
   */
  endUserSuppliedValues?: IntegrationConnectInput[];
};

export type SidebarSection = {
  inputs: SidebarInput[];
  buttons?: SidebarInput[];
};

export type SidebarInput =
  | EnumInput
  | DynamicEnumInput<any>
  | TextInput
  | TextAreaInput
  | AuthInput
  | IntentInput
  | ValueTextInput
  | ValueTextAreaInput
  | CodeInput
  | ActionButtonInput
  | ConditionalInput
  | DynamicConditionalInput<any>
  | NestedListInput
  | FileInput
  | EditableDynamicEnum
  | EditableEnum
  | BooleanInput
  | UserSuppliedCredentialInput
  | NativeDynamicEnumInput<any>
  | TimeConstraintInput
  | LinesListInput
  | LinesListDynamicInput
  | EmailInput
  | NumberInput
  | URLInput
  | EnumTextAreaPairInput
  | FieldMapperInput
  | ComboInput
  | PasswordInput
  | SwitchInput;

export enum SidebarInputType {
  Auth = 'AUTH',
  Enum = 'ENUM',
  DynamicEnum = 'DYNAMIC_ENUM',
  Intent = 'INTENT',
  Text = 'TEXT',
  TextArea = 'TEXTAREA',
  ValueText = 'TEXT_NO_VARS',
  ValueTextArea = 'TEXTAREA_NO_VARS',
  Code = 'CODE',
  ActionButton = 'ACTION_BUTTON',
  Conditional = 'CONDITIONAL',
  DynamicConditional = 'DYNAMIC_CONDITIONAL',
  NestedList = 'NESTED_LIST',
  File = 'FILE',
  EditableDynamicEnum = 'EDITABLE_DYNAMIC_ENUM',
  EditableEnum = 'EDITABLE_ENUM',
  BooleanInput = 'BOOLEAN_INPUT',
  UserSuppliedCredential = 'USER_SUPPLIED_CREDENTIAL',
  NativeDynamicEnumInput = 'NATIVE_DYNAMIC_INPUT',
  TimeConstraintInput = 'TIME_CONSTRAINT_INPUT',
  LinesListInput = 'LinesListInput',
  LinesListDynamicInput = 'LinesListDynamicInput',
  Number = 'NUMBER',
  Email = 'EMAIL',
  URL = 'URL',
  EnumTextAreaPairInput = 'EnumTextAreaPairInput',
  FieldMapper = 'FIELD_MAPPER',
  ComboInput = 'COMBO_INPUT',
  Password = 'PASSWORD',
  Switch = 'SWITCH',
}

interface BaseInput {
  id: string;
  title: string;
  subtitle?: ReactNode | string;
  placeholder?: string;
  /**
   * if not provided then treated as true
   */
  required?: boolean;
  defaultValue?: any;
  documentationLink?: string;
  documentationLinkLabel?: string;
  /**
   * hide input in sidebarsection
   */
  hidden?: boolean;
  /**
   * can not change value if readOnly
   */
  readOnly?: boolean;
}

interface AuthInput extends BaseInput {
  type: SidebarInputType.Auth;
  config: ActionConfig;
}

export type EnumSection<T = any> = {
  title: string;
  items: EnumInputValue<T>[];
};

export type EnumInputValue<T = any> = {
  value: T;
  label: string;
  /**
   * subtitle property is for displaying a little helper text to explain the item in dropdown
   */
  subtitle?: string;
};

export enum DynamicInputRefreshStatus {
  PRISTINE = 'PRISTINE',
  REFRESHING = 'REFRESHING',
  NEXT_LOADING = 'NEXT_LOADING',
  FAILED = 'FAILED',
  DONE = 'DONE',
}

export enum DataSourceType {
  DYNAMIC = 'DYNAMIC_DATA_SOURCE',
  STATIC_ENUM = 'STATIC_ENUM_DATA_SOURCE',
  FIELD_MAPPER = 'FIELD_MAPPER_DATA_SOURCE',
  COMBO_INPUT = 'COMBO_INPUT_DATA_SOURCE',
}

export type BasicDataSource = {
  /**
   * title for data source
   */
  title: string;

  /**
   * description for data source
   */
  subtitle?: ReactNode;

  /**
   * If specified, instructionalText replaces the default text explaining the input under the selection,
   * e.g. "Let your users select <input name> from their <integration name> account."
   */
  instructionalText?: ReactNode;

  /**
   * If true, this property hides this data source as a Connect field type.
   * @default false
   */
  hideFromConnectFieldTypes?: boolean;

  /**
   * If true, dataSource will be fetched in pagination format
   * @default false
   */
  supportPagination?: boolean;
};

export type DynamicDataSource<TValues = EnumInputValue[]> = BasicDataSource & {
  type: DataSourceType.DYNAMIC;

  /**
   * If provided, the response from the refresh method will be saved into the step.
   */
  cacheKey?: string;

  /**
   * This array is used to determine whether or not the input's values should be
   * refreshed. Strings can be used to specify a KeyedSource dependency within
   * `options.actionParameters`, and a function can be used to specify any other
   * dependency within the step's parameters.
   *
   * If `refreshDependencies` is an empty array, the input will refresh on mount.
   * If `refreshDependencies` is not provided, the input will not refresh unless
   * manually invoked (i.e., by a button).
   */
  refreshDependencies?: (string | ((options: ActionStepParameters) => any))[];

  /**
   * When the data comes back from the intent requested by the `refresh` method,
   * this is the method used to map the data into the input + cache
   */
  mapRefreshToValues: (response: any) => TValues;

  /**
   * This method is responsible for specifying the action, intent and parameters
   * for loading the data into the input
   */
  getRefreshActionParameters: (
    options: ActionStepParameters,
    inputs: SidebarInput[],
    activeInput: SidebarInput,
  ) => ActionStepParameters;

  /**
   * called after the input refreshes w/ the values mapped from `mapRefreshToValues`
   */
  onRefresh?: (value: any) => ActionResponse;
};

export type StaticEnumDataSource = BasicDataSource & {
  type: DataSourceType.STATIC_ENUM;
  /**
   * id for data source
   */
  id: string;
  values: EnumInputValue[] | EnumSection[];
};

/**
 * A FieldMapperDataSource refers to a source that is able to drive an input that is responsible
 * for mapping remote/integration fields to local/project fields.
 */
export type FieldMapperDataSource = BasicDataSource & {
  id: string;
  type: DataSourceType.FIELD_MAPPER;
  recordSource: DynamicDataSource<(EnumSection | EnumInputValue)[]>;
  fieldSource: DynamicDataSource<(EnumSection | EnumInputValue)[]>;
};

/**
 * A ComboInputDataSource refers to a source that is able to drive an input that is responsible
 * for combining the remote/integration fields to local/project fields.
 */
export type ComboInputDataSource = BasicDataSource & {
  id: string;
  type: DataSourceType.COMBO_INPUT;
  mainInputSource: DynamicDataSource<(EnumSection | EnumInputValue)[]>;
  dependentInputSource: DynamicDataSource<(EnumSection | EnumInputValue)[]>;
  fieldSource?: DynamicDataSource<(EnumSection | EnumInputValue)[]>;
};

/**
 * input source for Connect portal
 */
export type DataSource =
  | DynamicDataSource<any>
  | StaticEnumDataSource
  | FieldMapperDataSource
  | ComboInputDataSource;

export type DynamicInput<TValues = EnumInputValue[]> = BaseInput & {
  source: DynamicDataSource<TValues>;
};

export interface EnumInput extends BaseInput {
  type: SidebarInputType.Enum;
  getValues: (
    options: ActionStepParameters,
    inputs: SidebarInput[],
    activeInput: SidebarInput,
  ) => EnumInputValue[];
}

export interface EditableEnum extends BaseInput {
  type: SidebarInputType.EditableEnum;
  hideSubtitle?: boolean;
  enableMultiSelect?: boolean;
  getValues: (
    options: ActionStepParameters,
    inputs: SidebarInput[],
    activeInput: SidebarInput,
  ) => EnumInputValue[];
}

interface BooleanInput extends BaseInput {
  type: SidebarInputType.BooleanInput;
}

interface TimeConstraintInput extends BaseInput {
  type: SidebarInputType.TimeConstraintInput;
  units: DelayUnit[];
}

export type LinesListInput = BaseInput & {
  type: SidebarInputType.LinesListInput;
  leftInput?: {
    placeholder?: string;
  };
  rightInput: {
    isDropdown: boolean;
    placeholder?: string;
  };
};

export type LinesListDynamicInput = Omit<LinesListInput, 'type'> &
  DynamicInput<EnumInputValue[]> & {
    type: SidebarInputType.LinesListDynamicInput;
    getValues: (
      options: ActionStepParameters,
      inputs: SidebarInput[],
      activeInput: SidebarInput,
    ) => EnumInputValue[];
  };

export interface DynamicEnumInput<TValues = EnumInputValue[]>
  extends Omit<EnumInput, 'type'>,
    DynamicInput<TValues> {
  type: SidebarInputType.DynamicEnum;
  source: DynamicDataSource<TValues>;
}

export type NativeDynamicEnumInput<TValues = (EnumSection | EnumInputValue)[]> = Omit<
  EnumInput,
  'type'
> &
  DynamicInput<TValues> & {
    type: SidebarInputType.NativeDynamicEnumInput;
    placeholder: string;
  };

type EditableDynamicEnum = Omit<DynamicEnumInput, 'type'> & {
  type: SidebarInputType.EditableDynamicEnum;
  hideSubtitle?: boolean;
  enableMultiSelect?: boolean;
};

export enum IntentInputDesignType {
  WorkflowSettingsDesign = 'workflowSettings',
  ClassicIntentDesign = 'classicIntents',
}
export type IntentInputDesign =
  | IntentInputDesignType.WorkflowSettingsDesign
  | IntentInputDesignType.ClassicIntentDesign;

interface IntentInput extends BaseInput {
  type: SidebarInputType.Intent;
  values: EnumInputValue<ActionIntent>[] | EnumSection<ActionIntent>[];
  /**
   * the css style type of intent input.
   * Defaults to the classic Intents design
   */
  designType?: IntentInputDesignType;
  /**
   * icon is only relevant for designType workflowSettings. It is the icon that comes beside the intent in dropdown
   */
  icon?: string;
}

interface TextInput extends BaseInput {
  dataType?: DataType;
  type: SidebarInputType.Text;
  overrideValue?: string;
}

interface TextAreaInput extends BaseInput {
  type: SidebarInputType.TextArea;
  lines?: number;
}

interface PasswordInput extends BaseInput {
  type: SidebarInputType.Password;
}

interface SwitchInput extends BaseInput {
  type: SidebarInputType.Switch;
}

interface ValueTextInput extends BaseInput {
  type: SidebarInputType.ValueText;
  inputType?: string;
  /**
   * prefixLabel will be placed in the beggining of an input
   * @example "dev.azure.com/{organisationId}"
   */
  prefixLabel?: string;
  /**
   * Specify a suffixLabel as an input for suggesting the input of a subdomain, or describing
   * a noun for what the input is counting.
   *
   * @example ".myshopify.com" // Suggesting a subdomain
   * @example "minutes" // Suggesting a label for what the input represents
   */
  suffixLabel?: string;
  /**
   * this will be used to hide inputs in connect that are required for automate integrations.
   */
  hiddenInConnect?: boolean;
  /**
   * this will be used to hide inputs in connect that are required for automate integrations.
   */
  hiddenInClassic?: boolean;
}

export interface IntegrationConnectInput extends BaseInput {
  type:
    | SidebarInputType.ValueText
    | SidebarInputType.Password
    | SidebarInputType.DynamicEnum
    | SidebarInputType.ComboInput
    | SidebarInputType.Enum
    | SidebarInputType.Switch;
  inputType?: string;
  prefixLabel?: string;
  suffixLabel?: string;
  sourceType?: string;
  fallbackText?: string;
  hiddenInConnect?: boolean;
}

interface ValueTextAreaInput extends BaseInput {
  type: SidebarInputType.ValueTextArea;
  lines?: number;
}

interface CodeInput extends BaseInput {
  type: SidebarInputType.Code;
  lines?: number;
  language: string;
  useLightTheme?: boolean;
  resizeOnOverflow?: boolean;
}

interface ValueTextInput extends BaseInput {
  type: SidebarInputType.ValueText;
}

export interface ConditionalInput extends BaseInput {
  type: SidebarInputType.Conditional;
  disableMultipleConditions?: boolean;
  supportedOperators: Operator[];
  getValues?: () => EnumInputValue[];
}

export interface DynamicConditionalInput<TValues = EnumInputValue[]>
  extends DynamicInput<TValues>,
    Omit<ConditionalInput, 'type' | 'getValues'>,
    Omit<EnumInput, 'type'> {
  type: SidebarInputType.DynamicConditional;
}

interface FileInput extends BaseInput {
  type: SidebarInputType.File;
}

export interface NestedListInput extends BaseInput {
  action: Action;
  getChildrenIntent: ActionIntent;
  getParentsIntent: ActionIntent;
  type: SidebarInputType.NestedList;
}

export interface EnumTextAreaPairInput extends BaseInput {
  type: SidebarInputType.EnumTextAreaPairInput;
  revertInputOrder?: boolean;
  getValues: () => EnumInputValue[];
}

export interface UserSuppliedCredentialInput extends BaseInput {
  type: SidebarInputType.UserSuppliedCredential;
  providerType: ProviderType;
  supportedTokenTypes: TokenType[];
  hideTokenDropDown?: boolean;
}

export interface EmailInput extends BaseInput {
  type: SidebarInputType.Email;
}

export interface NumberInput extends BaseInput {
  type: SidebarInputType.Number;
}

export interface URLInput extends BaseInput {
  type: SidebarInputType.URL;
}

export interface FieldMapperInput extends BaseInput {
  type: SidebarInputType.FieldMapper;
  source: FieldMapperDataSource;
  useDynamicMapper?: boolean;
  dynamicObjectName?: string;
  dynamicObjectOptions?: DynamicMappingOptions | DynamicMappingField[];

  /**
   * Represents the array of field mappings that this input should be used for. For every field
   * mapping included in this array, a nested input will be included that is backed by the
   * `source.fieldSource`.
   */
  savedFieldMappings: { label: string }[];
}

export interface ComboInput extends BaseInput {
  type: SidebarInputType.ComboInput;
  source: ComboInputDataSource;
  savedFieldMappings?: { label: string }[];
}

export enum ActionButtonStyle {
  DEFAULT = 'DEFAULT',
  DANGER = 'DANGER',
  PRIMARY = 'PRIMARY',
}

export type ComposeActionButtonStyle = (
  parameters: ActionStepParameters,
  inputs: SidebarInput[],
  activeInput: SidebarInput,
) => ActionButtonStyle;

export type ActionButtonStyleFactory = ActionButtonStyle | ComposeActionButtonStyle;

export type ComposeActionButtonDisableResult = (
  refreshStatus: DynamicInputRefreshStatus,
) => boolean;

export interface ActionButtonInput<TValues = any> extends DynamicInput<TValues> {
  type: SidebarInputType.ActionButton;
  style: ActionButtonStyleFactory;
  getValue?: (
    options: ActionStepParameters,
    inputs: SidebarInput[],
    activeInput: ActionButtonInput<TValues>,
  ) => any;
  disabled?: boolean | ComposeActionButtonDisableResult;
  hidden?: boolean;
  source: DynamicDataSource<TValues>;
}

export enum ActionResponseType {
  NONE = 'NONE',
  RE_AUTHENTICATE = 'RE_AUTHENTICATE',
  ALERT = 'ALERT',
  DISPATCH = 'DISPATCH',
}

export enum ActionResponseStatus {
  INFO = 'INFO',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export type AlertActionResponse = {
  type: ActionResponseType.ALERT;
  status: ActionResponseStatus;
  message: string;
};

export type DispatchActionResponse = {
  type: ActionResponseType.DISPATCH;
  status: ActionResponseStatus;
  id: string;
  source: Source;
};

export type ActionResponse = AlertActionResponse | DispatchActionResponse;

export const TEST_CONNECTION_INTENT_MAP: Record<Action, ActionIntent | undefined> = {
  [Action.AIRTABLE]: AirtableIntent.TEST_CONNECTION,
  [Action.ASANA]: AsanaIntent.TEST_CONNECTION,
  [Action.AZURE_DEVOPS]: AzureDevOpsIntent.TEST_CONNECTION,
  [Action.DYNAMODB]: DynamoDBIntent.TEST_CONNECTION,
  [Action.FIREBASE]: FirebaseIntent.TEST_CONNECTION,
  [Action.FTP]: FtpIntent.TEST_CONNECTION,
  [Action.GOOGLE_ANALYTICS]: GoogleAnalyticsIntent.TEST_CONNECTION,
  [Action.GOOGLE_CALENDAR]: GoogleCalendarIntent.TEST_CONNECTION,
  [Action.GOOGLE_CAMPAIGN_MANAGER]: GoogleCampaignManagerIntent.TEST_CONNECTION,
  [Action.GOOGLE_DRIVE]: GoogledriveIntent.TEST_CONNECTION,
  [Action.GOOGLE_SEARCH_CONSOLE]: GoogleSearchConsoleIntent.TEST_CONNECTION,
  [Action.GOOGLE_SHEETS]: GooglesheetsIntent.TEST_CONNECTION,
  [Action.PARDOT]: PardotIntent.TEST_CONNECTION,
  [Action.POSTGRES]: PostgresIntent.TEST_CONNECTION,
  [Action.SALESFORCE]: SalesForceIntent.TEST_CONNECTION,
  [Action.SENDGRID]: SendgridIntent.TEST_CONNECTION,
  [Action.SHOPIFY]: ShopifyIntent.TEST_CONNECTION,
  [Action.SLACK]: SlackIntent.TEST_CONNECTION,
  [Action.STRIPE]: StripeIntent.TEST_CONNECTION,
  [Action.TABLEAU]: TableauIntent.TEST_CONNECTION,
  [Action.TWILIO]: TwilioIntent.TEST_CONNECTION,
  [Action.ONESIGNAL]: OneSignalIntent.TEST_CONNECTION,
  [Action.NONE]: undefined,
  [Action.INTERCOM]: IntercomIntent.TEST_CONNECTION,
  [Action.QUICKBOOKS]: QuickbooksIntent.TEST_CONNECTION,
  [Action.JIRA]: JiraIntent.TEST_CONNECTION,
  [Action.HUBSPOT]: HubspotIntent.TEST_CONNECTION,
  [Action.KLAVIYO]: KlaviyoIntent.TEST_CONNECTION,
  [Action.MAILCHIMP]: MailChimpIntent.TEST_CONNECTION,
  [Action.MAILGUN]: MailgunIntent.TEST_CONNECTION,
  [Action.MARKETO]: MarketoIntent.TEST_CONNECTION,
  [Action.MICROSOFT_DYNAMICS]: MicrosoftDynamicsIntent.TEST_CONNECTION,
  [Action.MICROSOFT_TEAMS]: MicrosoftTeamsIntent.TEST_CONNECTION,
  [Action.MONGODB]: MongoDBIntent.TEST_CONNECTION,
  [Action.MYSQL]: MySQLIntent.TEST_CONNECTION,
  [Action.CUSTOM]: undefined,
  [Action.GONG]: GongIntent.TEST_CONNECTION,
  [Action.OUTREACH]: OutreachIntent.TEST_CONNECTION,
  [Action.ELOQUA]: EloquaIntent.TEST_CONNECTION,
  [Action.ORACLE_FINANCIALS_CLOUD]: OracleFinancialsCloudIntent.TEST_CONNECTION,
  [Action.GOOGLE_CAMPAIGN_MANAGER]: GoogleCampaignManagerIntent.TEST_CONNECTION,
  [Action.TRELLO]: TrelloIntent.TEST_CONNECTION,
  [Action.XERO]: XeroIntent.TEST_CONNECTION,
  [Action.ZENDESK]: ZendeskIntent.TEST_CONNECTION,
  [Action.ZOOM]: ZoomIntent.TEST_CONNECTION,
  [Action.FACEBOOK_ADS]: FacebookAdsIntent.TEST_CONNECTION,
  [Action.MONDAY]: MondayIntent.TEST_CONNECTION,
  [Action.CLICKUP]: ClickUpIntent.TEST_CONNECTION,
  [Action.SHAREPOINT]: SharePointIntent.TEST_CONNECTION,
  [Action.OUTLOOK]: MicrosoftOutlookIntent.TEST_CONNECTION,
  [Action.SERVICENOW]: ServiceNowIntent.TEST_CONNECTION,
  [Action.NETSUITE]: NetSuiteIntent.TEST_CONNECTION,
  [Action.DYNAMICS_BUSINESS_CENTRAL]: DynamicsBusinessCentralIntent.TEST_CONNECTION,
  [Action.PIPEDRIVE]: PipedriveIntent.TEST_CONNECTION,
  [Action.SAGE_INTACCT]: SageIntacctIntent.TEST_CONNECTION,
  [Action.LINEAR]: LinearIntent.TEST_CONNECTION,
  [Action.DYNAMICS_365_FINANCE]: MicrosoftDynamicsFinanceIntent.TEST_CONNECTION,
  [Action.SAGE_ACCOUNTING]: SageAccountingIntent.TEST_CONNECTION,
  [Action.WOOCOMMERCE]: WooCommerceIntent.TEST_CONNECTION,
  [Action.IMANAGE]: ImanageIntent.TEST_CONNECTION,
  [Action.BAMBOO_HR]: BambooHrIntent.TEST_CONNECTION,
  [Action.GMAIL]: GmailIntent.TEST_CONNECTION,
  [Action.GOOGLE_AD_MANAGER]: GoogleAdManagerIntent.TEST_CONNECTION,
  [Action.DROPBOX]: DropboxIntent.TEST_CONNECTION,
  [Action.MAGENTO]: MagentoIntent.TEST_CONNECTION,
  [Action.GITHUB]: GithubIntent.TEST_CONNECTION,
  [Action.PRODUCTBOARD]: ProductboardIntent.TEST_CONNECTION,
  [Action.DOCUSIGN]: DocuSignIntent.TEST_CONNECTION,
  [Action.SAP_S4HANA]: SapIntent.TEST_CONNECTION,
  [Action.GOOGLE_ADS]: GoogleAdsIntent.TEST_CONNECTION,
  [Action.GREENHOUSE]: GreenhouseIntent.TEST_CONNECTION,
  [Action.LEVER]: LeverIntent.TEST_CONNECTION,
  [Action.CALENDLY]: CalendlyIntent.TEST_CONNECTION,
  [Action.ZOHO_CRM]: ZohoCrmIntent.TEST_CONNECTION,
  [Action.SALESLOFT]: SalesloftIntent.TEST_CONNECTION,
  [Action.FRESHDESK]: FreshdeskIntent.TEST_CONNECTION,
  [Action.TWITTER]: TwitterIntent.TEST_CONNECTION,
  [Action.BIGQUERY]: BigQueryIntent.TEST_CONNECTION,
  [Action.POWER_BI]: PowerBiIntent.TEST_CONNECTION,
  [Action.MIXPANEL]: MixpanelIntent.TEST_CONNECTION,
  [Action.SAP_SUCCESS_FACTORS]: SapSuccessFactorsIntent.TEST_CONNECTION,
  [Action.AMAZON_S3]: AmazonS3Intent.TEST_CONNECTION,
  [Action.SAILTHRU]: SailthruIntent.TEST_CONNECTION,
  [Action.PANDA_DOC]: PandaDocIntent.TEST_CONNECTION,
  [Action.SEGMENT]: SegmentIntent.TEST_CONNECTION,
  [Action.GUSTO]: GustoIntent.TEST_CONNECTION,
  [Action.CLOSE]: CloseIntent.TEST_CONNECTION,
};

export type Context = {
  projectId?: string; // project id is undefined when workflow is not deployed
  workflowId: string;
  executionId: string;
  testing: boolean;
};

export enum TokenType {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  BOT_TOKEN = 'BOT_TOKEN',
  TABLEAU_PERSONAL_ACCESS_TOKEN_NAME = 'TABLEAU_PERSONAL_ACCESS_TOKEN_NAME',
  TABLEAU_PERSONAL_ACCESS_TOKEN_SECRET = 'TABLEAU_PERSONAL_ACCESS_TOKEN_SECRET',
  TABLEAU_SITE_ID = 'TABLEAU_SITE_ID',
  TABLEAU_SERVER_NAME = 'TABLEAU_SERVER_NAME',
  KLAVIYO_API_KEY = 'KLAVIYO_API_KEY',
  MARKETO_ENDPOINT = 'MARKETO_ENDPOINT',
  MARKETO_IDENTITY = 'MARKETO_IDENTITY',
  MARKETO_CLIENT_ID = 'MARKETO_CLIENT_ID',
  MARKETO_CLIENT_SECRET = 'MARKETO_CLIENT_SECRETI',
  TRELLO_API_KEY = 'TRELLO_API_KEY',
  TRELLO_API_TOKEN = 'TRELLO_API_TOKEN',
  ORACLE_CLOUD_URL = 'ORACLE_CLOUD_URL',
  ORACLE_PASSWORD = 'ORACLE_PASSWORD',
  ORACLE_USERNAME = 'ORACLE_USERNAME',
  MONDAY_API_TOKEN = 'MONDAY_API_TOKEN',
  SERVICENOW_SUBDOMAIN = 'SERVICENOW_SUBDOMAIN',
  SERVICENOW_USERNAME = 'SERVICENOW_USERNAME',
  SERVICENOW_PASSWORD = 'SERVICENOW_PASSWORD',
  SAGE_INTACCT_COMPANY_ID = 'SAGE_INTACCT_COMPANY_ID',
  SAGE_INTACCT_USER_ID = 'SAGE_INTACCT_USER_ID',
  SAGE_INTACCT_USER_PASSWORD = 'SAGE_INTACCT_USER_PASSWORD',
  WOOCOMMERCE_CONSUMER_KEY = 'WOOCOMMERCE_CONSUMER_KEY',
  WOOCOMMERCE_CONSUMER_SECRET = 'WOOCOMMERCE_CONSUMER_SECRET',
  WOOCOMMERCE_STORE_DOMAIN = 'WOOCOMMERCE_STORE_DOMAIN',
  ZOHO_CRM_API_DOMAIN = 'ZOHO_CRM_API_DOMAIN',
  ZOHO_CRM_ACCOUNTS_SERVER = 'ZOHO_CRM_ACCOUNTS_SERVER',
  SAILTHRU_COMPANY_KEY = 'SAILTHRU_COMPANY_KEY',
  SAILTHRU_COMPANY_SECRET = 'SAILTHRU_COMPANY_SECRET',
}

/**
 * Created badge flag enum
 */
export enum LabelBadgeFlag {
  API = 'API',
  BETA = 'Beta',
  ADDED = 'Added',
  NEW = 'New',
}

export type SampleCodeType = {
  title: string;
  body: string;
};

export type UserSuppliedCredentialTransformer = (
  token: ResolvedUserSuppliedCredentialSource,
  appCredential?: OAuthCredentialValues | OAuthBasicCredentialValues,
) => Promise<CredentialValues>;

export type AssumedEnvironmentTransformer = (
  appCredential: OAuthCredentialValues | OAuthBasicCredentialValues,
) => Record<string, string>;

export type UnknownRecordsType = Record<string, unknown> | string;

export type ExchangeCredentialResponseTransformer = (
  exchangeCredentialResponse: ExchangeCredentialOutput,
) => UnknownRecordsType;

export type ResponseExchangeTransformer = (
  responseData: Record<string, unknown> | string,
) => UnknownRecordsType | UnknownRecordsType[];

export type ResponseErrorExchangeTransformer = (
  responseData: AxiosError | string,
) => string | undefined;

/**
 * mocked credential id for impersonate user credential
 */
export const MOCK_IMPERSONATED_USER_CREDENTIAL_ID = 'MOCK_IMPERSONATED_USER_CREDENTIAL_ID';

export type ActionDescriptionConfig<T extends Action> = {
  /**
   * Action Type represent which action description
   */
  actionType: T;
  /**
   * Name given to that action
   */
  name: string;
  /**
   * Icon of action
   */
  icon: string;
  /**
   * Short Heading for action
   */
  title: string;
  /**
   * Long description for action
   */
  description: string;
  /**
   * paragon Documentation link
   */
  documentationLink: string;
  /**
   * Sample code for particular action on how to use connect integration
   */
  sampleCode: SampleCodeType[];
  /**
   * Action official Api Reference
   */
  apiReferenceLink: string;
  /**
   * Branc color for action
   */
  brandColor: string;
  /**
   * ModalConfig for action
   */
  portalConfig: Partial<ModalConfig>;
  /**
   * Action Public Api base url for display
   */
  publicApiBaseUrl?: string;
  /**
   *  This is a list of hosts that are allowed to be used in a iRequest step or proxy request,
   *  if not present then we are using publicApiBaseUrl for request url domain verification.
   */
  allowedHosts?: string[];

  /**
   * Adding the badge over the ui for integrations as label
   */
  badge?: LabelBadgeFlag;

  /**
   * Flag to hide the integration from the catalog.
   */
  isUnreleased?: Boolean;
};

export type ConnectRequestOptionsResult = {
  requestConfig: AxiosRequestConfig;
};

export type ConnectRequestOptionsFactory<P, C, E> = {
  (
    parameters: P,
    credentials: C,
    environment: E,
    additionalRequestInfo?: Record<string, any>,
  ): Promise<OAuthActionResponse<ConnectRequestOptionsResult> | ConnectRequestOptionsResult>;
};

export type RequestOptionsFactoryPayload = {
  body: any;
  path: string;
  headers: Record<string, string>;
  method: HttpMethod;
  params: Record<string, string>;
};

/* PARA-6442 Note: OAuthOrActionTriggerResponse is only for migrating to the new interface and should only be used in
 * the trigger base class, individual integrations should use OAuthActionResponse or their specific reponse T.
 * once all triggers are migrated to OAuthActionResponse this can be removed.  We may want to consider
 * renaming type down the road to a more generic ActionResponse<T>
 */
export type OAuthOrActionTriggerResponse<T> = T | OAuthActionResponse<T>;

export type OAuthActionResponse<T> = {
  data: T;
  updateCredentialValues: CredentialValues | undefined;
  error?: string;
};

/**
 * Return true is the output passed is of type OAuthActionResponse
 *
 * @export
 * @template T
 * @param {T} output
 * @param {(CredentialValues | undefined)} updateCredentialValues
 * @return {*}  {OAuthActionResponse<T>}
 */
export const isOAuthActionResponse = (output: any): boolean => {
  return (
    typeof output === 'object' &&
    output.hasOwnProperty('data') &&
    output.hasOwnProperty('updateCredentialValues')
  );
};

/**
 * This return an OAuthActionResponse
 *
 * @export
 * @template T
 * @param {T} output
 * @param {(CredentialValues | undefined)} updateCredentialValues
 * @param errorMessage
 * @return {*}  {OAuthActionResponse<T>}
 */
export function createOAuthActionResponse<T>(
  output: T,
  updateCredentialValues: CredentialValues | undefined,
  errorMessage?: string,
): OAuthActionResponse<T> {
  return {
    data: output,
    updateCredentialValues,
    error: errorMessage,
  };
}

export type ScopeValue = {
  name: string;
  description: string;
  label?: string;
  required?: boolean;
};

export type ScopeName = ScopeValue['name'];

export type OAuthCredentialValueDefaults = Omit<
  OAuthCredentialValues,
  'clientId' | 'clientSecret' | 'scopes'
> & {
  name: string;
  description: string;
  iconSvg: string;
  credentials: IntegrationConnectInput[];
  scopes: {
    values: ScopeValue[];
    required?: boolean;
    notAllowed?: boolean;
    defaultValues?: string[];
    helperText?: string;
  };
  documentationLink: string;
  clientIdLabel: string;
  clientSecretLabel: string;
  /**
   * Specify additional user inputs that are required to start the OAuth flow, i.e. a name
   * or subdomain prefix for a service that is a part of the OAuth authorization URL.
   */
  endUserSuppliedValues?: IntegrationConnectInput[];
};

export enum RefreshCredentialStatus {
  Success = 'SUCCESS',
  RetriableFailure = 'RETRIABLE_FAILURE',
  PermanentFailure = 'PERMANENT_FAILURE',
}

/**
 * failed action result type of REFRESH_CREDENTIAL intentss
 */
type FailedRefreshCredentialResult = {
  status: RefreshCredentialStatus.RetriableFailure | RefreshCredentialStatus.PermanentFailure;
  message: string;
};

/**
 * success action result type of REFRESH_CREDENTIAL intentss
 */
type SuccessfulRefreshCredentialResult = {
  status: RefreshCredentialStatus.Success;
  values: Record<string, any>;
};

export type RefreshCredentialResult =
  | FailedRefreshCredentialResult
  | SuccessfulRefreshCredentialResult;

/**
 * method signature for RefreshCredential function
 */
export type RefreshCredentialAction<C = Record<string, any>, E = Record<string, any>> = (
  parameters: Record<string, any>,
  credentials: C,
  environment: E,
) => Promise<RefreshCredentialResult>;

/**
 * It will override the action alias for making files name more cleaner
 */
export const overrideActionAlias: Partial<Record<Action, string>> = {
  [Action.MONDAY]: 'monday',
};

/**
 * It will return resolved path of integration's action
 * @param action Actions
 * @returns resolved path of interations
 */
export function resolvePath(action: Action): string {
  return `/../../../shared/integrations/${overrideActionAlias[action] || action}/`;
}

/**
 * refresh credential cron job interval
 */
export const REFRESH_CREDENTIAL_JOB_INTERVAL_MINUTES = 10;

/**
 * this defines error status codes when appeared in refresh_credential processing
 * after which credentials will be invalidated
 */
export const PERMANENT_CREDENTIAL_FAILURE_HTTP_STATUS_CODES = [400, 401, 403];
