import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'calendly',
  description: 'Sync events and invites in Calendly',
  authUrl: '	https://auth.calendly.com/oauth/authorize',
  accessTokenUrl: 'https://auth.calendly.com/oauth/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/calendly`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    notAllowed: true,
    required: false,
    values: [],
  },
  documentationLink: 'https://developer.calendly.com/api-docs/4b402d5ab3edd-calendly-developer',
};
