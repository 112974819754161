import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

export function joinOrConditions(conditions: string[]): string {
  return conditions
    .map((condition: string) => (condition.includes(' AND ') ? `(${condition})` : condition))
    .join(' OR ');
}

export default function conditionsToFilterGmailFormula(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    const conditions: string[] = condition.conditions.map((condition: ResolvedConditionWrapper) => {
      return conditionsToFilterGmailFormula(condition);
    });

    return condition.join === 'OR' ? joinOrConditions(conditions) : conditions.join(' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      case Operator.StringContains:
        return `${innerCondition.variable}:${innerCondition.argument}`;

      case Operator.StringIsIn:
        return `${innerCondition.variable}: (${innerCondition.argument
          .map((argument: string) => argument)
          .join(' ')})`;

      case Operator.StringDoesNotContain:
        return `${innerCondition.variable} -${innerCondition.argument}`;

      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} +${innerCondition.argument}`;

      case Operator.DateTimeAfter:
        return `after:${new Date(innerCondition.argument).toLocaleDateString('en-US')}`;

      case Operator.DateTimeBefore:
        return `before:${new Date(innerCondition.argument).toLocaleDateString('en-US')}`;

      case Operator.BooleanTrue:
        return innerCondition.variable;

      case Operator.BooleanFalse:
        return `-${innerCondition.variable}`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Gmail`);
    }
  }
  return '';
}
