import { Action, ConnectBillingState } from '../types';

export const initialState = {};

export function handler(
  state: ConnectBillingState = initialState,
  action: Action,
): ConnectBillingState {
  switch (action.type) {
    case 'CONNECT_BILLING_PLANS_FETCH_SUCCESS':
      return {
        ...state,
        plans: action.payload,
      };
    case 'CONNECT_BILLING_SUBSCRIPTION_FETCH_SUCCESS':
      return {
        ...state,
        subscription: action.payload,
      };
    default:
      return state;
  }
}
