import { hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig, { authInput } from './authConfig';
import {
  createTaskInputs,
  intentTypeInput,
  listInput,
  taskFilterInput,
  taskIdInput,
} from './common';

const config: ActionConfig = {
  actionType: Action.CLICKUP,
  name: 'ClickUp',
  description: 'Connect to your users ClickUp accounts.',
  icon: iconSvg,
  provider: ProviderType.CLICKUP,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        authInput,
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.CLICKUP,
                supportedTokenTypes: [TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials.length ? intentTypeInput : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEARCH_TASKS:
          return { inputs: [taskFilterInput] };

        case Intent.GET_TASK_IN_LIST:
          return {
            inputs: [
              {
                ...listInput,
                subtitle:
                  'Select a List to get tasks from. Use Connect Portal User Settings to allow users to select a ClickUp List.',
              },
              taskFilterInput,
            ],
          };

        case Intent.CREATE_TASK:
          return {
            inputs: [...createTaskInputs(hasOAuthAppCredential(parameters, credentials), false)],
          };

        case Intent.UPDATE_TASK:
          return {
            inputs: [
              taskIdInput,
              ...createTaskInputs(hasOAuthAppCredential(parameters, credentials), true),
            ],
          };

        case Intent.DELETE_TASK:
          return {
            inputs: [taskIdInput],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;

export { authConfig };
