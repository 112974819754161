import { PaginatedActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';
import { KeyedSource } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

export const getChannels: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'channels',
  title: 'Channel',
  subtitle: 'Channel that message should be sent to',
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
    'asBot',
  ],
  supportPagination: true,
  mapRefreshToValues: (response: {
    result: { output: PaginatedActionResponse<string[]> };
  }): EnumInputValue[] => {
    return response.result.output.records.map((channel: any) => ({
      label: channel,
      value: channel,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.SLACK,
      intent: Intent.LIST_CHANNELS,
      credentials: options.credentials,
      actionParameters: options.actionParameters.filter(
        (value: KeyedSource) => value.key === 'asBot',
      ),
    };
  },
};

export const getMembers: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'members',
  title: 'Workspace Member',
  subtitle: 'Workspace member to DM or @mention',
  instructionalText: 'Let your users select a Workspace Member from their Slack account.',
  refreshDependencies: [
    (options: ActionStepParameters) => options.credentials && options.credentials[0],
  ],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response.result.output.map((member: any) => ({
      label: member.name,
      value: member.id,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.SLACK,
      intent: Intent.LIST_MEMBERS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};
