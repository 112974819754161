import { EventDestination } from '@shared/postgres-pheme/entities/eventDestination/eventDestination.interface';
import { BillingPlan } from '@shared/types/sdk/stripe';

import { useCurrentBillingPlan } from './hooks';

/**
 * @summary check if destination can be used based on plan
 * @param destinationType
 * @returns
 */
export const useIsDestinationAvailableForUserPlan = (
  destinationType: EventDestination | undefined,
): boolean => {
  const plan: BillingPlan = useCurrentBillingPlan();

  let canUseDestination: boolean = false;

  switch (destinationType) {
    case EventDestination.WEBHOOK:
      if ([BillingPlan.ConnectPro, BillingPlan.ConnectEnterprise].includes(plan)) {
        canUseDestination = true;
      }
      break;
    case EventDestination.EMAIL:
    default:
      canUseDestination = true;
      break;
  }

  return canUseDestination;
};
