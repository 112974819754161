import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { ActionStepParameters, ActionTriggerStep } from '@shared/types/sdk/actions';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { WorkflowVariables } from '@shared/types/sdk/resolvers';
import { ActionTriggerInput } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import { getRequiredStepIdToResolveKeyedSources } from './resolvers.utils';

/**
 * a resolver for an action trigger step's variables
 *
 * @export
 * @class ActionTriggerStepResolver
 * @extends {StepResolver<ActionTriggerStep, ActionStepParameters, ActionTriggerInput>}
 */
export class ActionTriggerStepResolver extends StepResolver<ActionTriggerStep, ActionTriggerInput> {
  /**
   * resolves the input parameters for the step
   *
   * @param {ActionStepParameters} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ActionTriggerInput}
   * @memberof ActionStepResolver
   */
  resolveInputParameters(
    _parameters: ActionStepParameters,
    _secrets: Record<string, string>,
    _cachedConnectCredential: CachedConnectCredential | null,
    _variables: WorkflowVariables,
    context: WorkflowExecutionContext,
  ): ActionTriggerInput {
    return context.triggerInput as ActionTriggerInput;
  }

  /**
   *
   * @param parameters
   * @returns
   */
  getRequiredStepIdsForVariableResolution(parameters: ActionStepParameters): string[] {
    return getRequiredStepIdToResolveKeyedSources(parameters.actionParameters);
  }
}
