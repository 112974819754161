export default `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
width="900.000000pt" height="590.000000pt" viewBox="0 0 900.000000 590.000000"
preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,590.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M5305 3690 c-33 -15 -91 -37 -130 -50 -38 -12 -71 -24 -73 -25 -1 -1
5 -21 13 -45 29 -81 37 -266 33 -742 -4 -437 -6 -467 -26 -542 -12 -44 -22
-81 -22 -83 0 -1 77 -3 171 -3 159 0 171 1 164 18 -4 9 -19 49 -34 87 l-26 70
-5 671 -5 671 -60 -27z"/>
<path d="M6794 3703 c-137 -40 -233 -178 -251 -360 l-6 -63 -58 0 c-59 0 -59
0 -59 -30 l0 -30 61 0 61 0 -4 -432 c-4 -365 -7 -442 -21 -488 -9 -30 -19 -65
-23 -77 l-6 -23 161 0 161 0 -11 28 c-33 85 -39 175 -39 583 l0 409 180 0 179
0 3 -432 c4 -482 6 -495 73 -563 89 -88 261 -60 345 57 29 40 57 115 46 125
-3 3 -17 -4 -33 -15 -15 -11 -47 -25 -69 -32 -49 -15 -85 -2 -118 43 -20 28
-21 38 -21 420 l0 392 153 3 152 3 0 24 0 24 -152 3 -153 3 -3 146 -3 145
-114 -55 -114 -56 2 -90 2 -90 -182 -3 -182 -2 -21 66 c-30 98 -37 192 -16
232 19 37 55 55 92 46 40 -10 91 -80 122 -168 16 -44 36 -82 44 -84 11 -2 21
33 42 150 15 84 28 160 29 168 5 37 -153 52 -249 23z"/>
<path d="M3115 3686 c-28 -13 -82 -33 -120 -46 -39 -12 -71 -24 -73 -25 -2 -2
5 -27 14 -57 16 -47 18 -116 23 -593 3 -297 10 -546 14 -553 16 -24 -3 -62
-45 -92 -48 -35 -89 -38 -134 -11 -60 36 -64 60 -64 353 -1 295 -9 388 -43
462 -52 112 -129 158 -281 164 -161 7 -274 -37 -345 -134 -34 -46 -61 -121
-61 -167 0 -32 3 -34 83 -64 45 -17 90 -34 100 -38 15 -6 17 0 17 48 0 122 39
235 94 268 39 24 99 24 137 2 51 -31 70 -88 77 -228 4 -113 3 -127 -14 -146
-24 -27 -103 -67 -224 -113 -212 -82 -297 -156 -316 -274 -18 -112 57 -225
171 -258 109 -31 235 7 333 102 l53 51 13 -31 c22 -51 95 -111 154 -125 93
-23 199 14 279 96 24 25 45 44 46 42 70 -107 125 -142 222 -143 79 0 125 22
201 100 l61 63 48 -45 c90 -83 232 -134 338 -120 113 15 219 78 294 174 l37
47 66 -66 c183 -183 441 -208 604 -58 76 69 101 129 101 244 0 73 -4 98 -22
130 -31 59 -96 114 -209 180 -240 139 -274 171 -274 257 0 82 69 128 176 115
92 -10 173 -96 196 -209 12 -60 33 -77 43 -35 15 71 54 302 51 305 -15 15
-128 32 -211 32 -266 0 -426 -122 -425 -324 0 -72 23 -126 71 -175 45 -45 90
-74 249 -164 74 -42 149 -91 167 -109 56 -58 68 -128 33 -185 -32 -52 -65 -68
-145 -68 -58 0 -78 5 -116 27 -85 50 -149 136 -189 253 -9 27 -22 50 -28 50
-6 0 -33 -30 -61 -66 -56 -74 -113 -125 -181 -160 -61 -31 -205 -39 -278 -15
-122 41 -194 151 -217 334 l-7 52 286 0 286 0 0 113 c0 220 -59 340 -199 406
-49 22 -69 26 -156 26 -81 -1 -111 -5 -158 -24 -83 -33 -168 -115 -212 -205
-54 -111 -69 -182 -69 -331 -1 -139 13 -203 59 -296 l24 -46 -27 -25 c-59 -56
-143 -75 -188 -43 -55 38 -54 29 -54 739 0 518 -3 656 -12 655 -7 0 -36 -10
-63 -23z m719 -480 c68 -28 103 -112 113 -267 5 -89 4 -98 -16 -118 -20 -20
-31 -21 -171 -21 -177 0 -168 -9 -151 143 17 153 59 237 131 266 35 14 56 13
94 -3z m-1350 -536 l26 -10 0 -134 0 -135 -42 -25 c-66 -37 -120 -49 -170 -35
-78 20 -118 77 -118 169 0 119 71 170 247 179 17 0 43 -3 57 -9z"/>
<path d="M1475 3604 c-87 -21 -117 -31 -169 -56 -82 -41 -150 -108 -189 -187
-29 -59 -32 -74 -32 -156 0 -75 4 -98 24 -140 37 -76 131 -160 281 -250 223
-134 310 -233 310 -353 0 -59 -15 -94 -57 -134 -39 -37 -73 -48 -152 -48 -93
0 -155 23 -211 79 -70 71 -111 175 -126 324 -5 54 -10 67 -22 65 -12 -2 -26
-53 -59 -213 -23 -116 -42 -217 -43 -226 0 -10 24 -27 68 -47 216 -98 421
-109 576 -30 61 31 149 121 179 182 57 116 59 204 7 314 -41 88 -115 157 -301
282 -234 158 -292 222 -293 322 0 151 227 234 361 132 60 -46 109 -158 134
-309 12 -67 28 -99 44 -83 2 2 13 103 25 223 11 121 22 232 25 247 4 25 1 29
-48 44 -51 16 -287 29 -332 18z"/>
<path d="M5823 3272 c-192 -69 -303 -266 -303 -542 0 -201 48 -339 154 -446
194 -194 514 -129 641 130 52 105 68 195 62 351 -6 199 -56 325 -168 430 -73
68 -125 88 -239 92 -73 3 -107 -1 -147 -15z m175 -78 c90 -59 152 -318 139
-584 -9 -185 -35 -271 -97 -324 -44 -36 -106 -36 -150 0 -80 68 -120 212 -127
460 -6 194 8 307 47 383 42 84 119 110 188 65z"/>
</g>
</svg>`;
