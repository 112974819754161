import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { CredentialKeys, Intent } from '../configs';
import { RecordType } from '../shared/types';

export const LinkWrapper: React.FC<{ text: string; href: string }> = ({
  text,
  href,
}: {
  text: string;
  href: string;
}) => (
  <a href={href} target="_blank" rel="noreferrer">
    {text}
  </a>
);

export const domainInput: SidebarInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.STORE_DOMAIN,
  title: 'Enter your WooCommerce store domain name',
  subtitle: (
    <>
      Your store domain name can be identified from your{' '}
      <LinkWrapper text="Wordpress domain" href="https://wordpress.com/support/domains/" />.
    </>
  ),
  placeholder: 'example.com',
  required: true,
};

export const consumerKeyInput: SidebarInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.CONSUMER_KEY,
  title: 'Consumer Key',
  subtitle: (
    <>
      You can generate your Consumer Key and Secret in WooCommerce Advanced Settings. Ensure your
      keys are given a read_write permission. Refer to{' '}
      <LinkWrapper
        text="these instructions"
        href="https://woocommerce.github.io/woocommerce-rest-api-docs/#rest-api-keys"
      />{' '}
      for more information.
    </>
  ),
  required: true,
};

export const consumerSecretInput: SidebarInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.CONSUMER_SECRET,
  title: 'Consumer Secret',
  required: true,
};

export const intentSelector: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Customer',
          items: [
            {
              value: Intent.CREATE_CUSTOMER,
              label: 'Create Customer',
            },
            {
              value: Intent.GET_CUSTOMER_BY_ID,
              label: 'Get Customer by ID',
            },
            {
              value: Intent.SEARCH_CUSTOMERS,
              label: 'Search Customers',
            },
            {
              value: Intent.UPDATE_CUSTOMER,
              label: 'Update Customer',
            },
            {
              value: Intent.DELETE_CUSTOMER,
              label: 'Delete Customer',
            },
          ],
        },
        {
          title: 'Order',
          items: [
            {
              value: Intent.CREATE_ORDER,
              label: 'Create Order',
            },
            {
              value: Intent.GET_ORDER_BY_ID,
              label: 'Get Order by ID',
            },
            {
              value: Intent.SEARCH_ORDERS,
              label: 'Search Orders',
            },
            {
              value: Intent.UPDATE_ORDER,
              label: 'Update Order',
            },
            {
              value: Intent.DELETE_ORDER,
              label: 'Delete Order',
            },
          ],
        },
        {
          title: 'Product',
          items: [
            {
              value: Intent.CREATE_PRODUCT,
              label: 'Create Product',
            },
            {
              value: Intent.GET_PRODUCT_BY_ID,
              label: 'Get Product by ID',
            },
            {
              value: Intent.SEARCH_PRODUCTS,
              label: 'Search Products',
            },
            {
              value: Intent.UPDATE_PRODUCT,
              label: 'Update Product',
            },
            {
              value: Intent.DELETE_PRODUCT,
              label: 'Delete Product',
            },
          ],
        },
      ],
    },
  ],
};

const supportedOperators: Operator[] = [Operator.StringExactlyMatches];

const recordTypeInput = (overrideValue: string): SidebarInput => ({
  id: 'recordType',
  title: 'RecordType',
  type: SidebarInputType.Text,
  required: false,
  overrideValue,
  hidden: true,
});

export const customerId: SidebarInput = {
  id: 'customerId',
  title: 'Customer ID',
  subtitle: 'Specify the record ID of the Customer to Update.',
  type: SidebarInputType.TextArea,
  placeholder: '25',
  lines: 1,
  required: true,
};

export const orderId: SidebarInput = {
  id: 'orderId',
  title: 'Order ID',
  subtitle: 'Specify the record ID of the Order to Update.',
  type: SidebarInputType.TextArea,
  placeholder: '25',
  lines: 1,
  required: true,
};

export const paymentId: SidebarInput = {
  id: 'paymentId',
  title: 'Payment Method ID',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select payment method to use for this order.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '{{settings.paymentMethod}}',
};

export const productId: SidebarInput = {
  id: 'productId',
  title: 'Product ID',
  subtitle: 'Specify the record ID of the Product to Update.',
  type: SidebarInputType.TextArea,
  placeholder: '25',
  lines: 1,
  required: true,
};

export const responseOrder: SidebarInput = {
  id: 'responseOrder',
  title: 'Order',
  subtitle: 'Default is asc', // depend upon entity asc for customer, desc for products,order
  type: SidebarInputType.Enum,
  getValues: () => [
    { label: 'ASC', value: 'asc' },
    { label: 'DESC', value: 'desc' },
  ],
  required: false,
  placeholder: 'desc',
};

export const email: SidebarInput = {
  id: 'email',
  title: 'Email',
  subtitle: 'The email address for the customer.',
  placeholder: 'john.doe@example.com',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const firstName: SidebarInput = {
  id: 'firstName',
  title: 'First Name',
  subtitle: 'Customer first name.',
  placeholder: 'John',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const lastName: SidebarInput = {
  id: 'lastName',
  title: 'Last Name',
  subtitle: 'Customer last name.',
  placeholder: 'Doe',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const username: SidebarInput = {
  id: 'username',
  title: 'Username',
  subtitle: 'Customer login name.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const password: SidebarInput = {
  id: 'password',
  title: 'Password',
  subtitle: 'Customer password.',
  type: SidebarInputType.Password,
  required: false,
};

export const productName: SidebarInput = {
  id: 'productName',
  title: 'Product Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const productDescription: SidebarInput = {
  id: 'productDescription',
  title: 'Product Description',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const productType: SidebarInput = {
  id: 'productType',
  title: 'Type',
  subtitle: 'Default is simple.',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Simple', value: 'simple' },
    { label: 'Grouped', value: 'grouped' },
    { label: 'External', value: 'external' },
    { label: 'Variable', value: 'variable' },
  ],
};

export const billingInfo: SidebarInput = {
  id: 'billingInfo',
  title: 'Billing Info',
  subtitle: (
    <>
      Specify billing info for customer as JSON.{' '}
      <a href="https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#customer-billing-properties">
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
  type: SidebarInputType.Code,
  language: 'json',
  required: false,
  useLightTheme: true,
  lines: 7,
  placeholder: `{     
  first_name: "John",     
  last_name: "Doe",     
  company: "",     
  address_1: "969 Market",     
  address_2: "",     
  city: "San Francisco",     
  state: "CA",     
  postcode: "94103",     
  country: "US",     
  email: "john.doe@example.com",     
  phone: "(555) 555-5555"   
}`,
};

export const orderLineItems: SidebarInput = {
  id: 'lineItems',
  title: 'Line Items',
  subtitle: (
    <>
      Specify Line Items for this order as JSON.{' '}
      <a href="https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#order-properties">
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
  type: SidebarInputType.Code,
  language: 'json',
  required: true,
  useLightTheme: true,
  lines: 6,
  placeholder: `[
  {
    product_id: 93,
    quantity: 2
  },
  {
    product_id: 22,
    variation_id: 23,
    quantity: 1
  }
]`,
};

export const setAsPaid: SidebarInput = {
  id: 'setPaid',
  title: 'Set as paid',
  subtitle:
    'If as paid it will set the status to processing and reduce stock items. Default is false.',
  type: SidebarInputType.BooleanInput,
  required: false,
};

export const orderStatus: SidebarInput = {
  id: 'orderStatus',
  title: 'Status',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Pending', value: 'pending' },
    { label: 'Processing', value: 'processing' },
    { label: 'On hold', value: 'on-hold' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Refunded', value: 'refunded' },
    { label: 'Failed', value: 'failed' },
    { label: 'Trash', value: 'trash' },
  ],
};

export const productStatus: SidebarInput = {
  id: 'productStatus',
  title: 'Status',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Draft', value: 'draft' },
    { label: 'Pending', value: 'pending' },
    { label: 'Private', value: 'private' },
    { label: 'Publish', value: 'publish' },
  ],
};

export const orderAdditionalFields: SidebarInput = {
  id: 'orderAdditionalFields',
  title: 'Additional Fields',
  subtitle: (
    <>
      <a href="https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#order-properties">
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  useLightTheme: true,
  required: false,
  placeholder: `{     
  transaction_id: "transaction_id",     
  parent_id: "Parent order ID",   
  currency:"USD"  
}`,
};

export const productAdditionalFields: SidebarInput = {
  id: 'productAdditionalFields',
  title: 'Additional Fields',
  subtitle: (
    <>
      <a href="https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#product-properties">
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  useLightTheme: true,
  required: false,
  placeholder: `{     
  regular_price: "regular_price",     
  sale_price: "sale_price",   
  dimensions: {
    "length": "",
    "width": "",
    "height": ""
  }, 
}`,
};

export const shippingInfo: SidebarInput = {
  id: 'shippingInfo',
  title: 'Shipping Info',
  subtitle: (
    <>
      Specify shipping info for customer as JSON.{' '}
      <a href="https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#customer-shipping-properties">
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
  type: SidebarInputType.Code,
  language: 'json',
  required: false,
  useLightTheme: true,
  lines: 5,
  placeholder: `{     
  first_name: "John",     
  last_name: "Doe",     
  company: "",     
  address_1: "969 Market",     
  address_2: "",     
  city: "San Francisco",     
  state: "CA",     
  postcode: "94103"  
}`,
};

const customerFilterFormula: SidebarInput = {
  id: 'customersFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: (
    <>
      Search for customer records that match specified filters.{' '}
      <a href="https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#list-all-customers">
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Email', value: 'email' },
    { label: 'Role', value: 'role' },
  ],
};

const orderFilterFormula: SidebarInput = {
  id: 'ordersFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: (
    <>
      Search for order records that match specified filters.{' '}
      <a href="https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#list-all-orders">
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Status', value: 'status' },
    { label: 'Parent', value: 'parent' },
    { label: 'Customer', value: 'customer' },
    { label: 'Product', value: 'product' },
  ],
};

const productFilterFormula: SidebarInput = {
  id: 'productsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: (
    <>
      Search for product records that match specified filters.{' '}
      <a href="https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#list-all-products">
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Status', value: 'status' },
    { label: 'Search', value: 'search' },
    { label: 'Parent', value: 'parent' },
    { label: 'Slug', value: 'slug' },
    { label: 'Type', value: 'type' },
    { label: 'Category', value: 'category' },
    { label: 'Stock Status', value: 'stock_status' },
    { label: 'Created Date', value: 'created' },
  ],
};

export const hiddenInputMapper: { [key in Intent]?: SidebarInput } = {
  [Intent.CREATE_CUSTOMER]: recordTypeInput(RecordType.CUSTOMER),
  [Intent.CREATE_ORDER]: recordTypeInput(RecordType.ORDER),
  [Intent.CREATE_PRODUCT]: recordTypeInput(RecordType.PRODUCT),
  [Intent.SEARCH_CUSTOMERS]: recordTypeInput(RecordType.CUSTOMER),
  [Intent.SEARCH_ORDERS]: recordTypeInput(RecordType.ORDER),
  [Intent.SEARCH_PRODUCTS]: recordTypeInput(RecordType.PRODUCT),
  [Intent.GET_CUSTOMER_BY_ID]: recordTypeInput(RecordType.CUSTOMER),
  [Intent.GET_ORDER_BY_ID]: recordTypeInput(RecordType.ORDER),
  [Intent.GET_PRODUCT_BY_ID]: recordTypeInput(RecordType.PRODUCT),
  [Intent.UPDATE_CUSTOMER]: recordTypeInput(RecordType.CUSTOMER),
  [Intent.UPDATE_ORDER]: recordTypeInput(RecordType.ORDER),
  [Intent.UPDATE_PRODUCT]: recordTypeInput(RecordType.PRODUCT),
  [Intent.DELETE_CUSTOMER]: recordTypeInput(RecordType.CUSTOMER),
  [Intent.DELETE_ORDER]: recordTypeInput(RecordType.ORDER),
  [Intent.DELETE_PRODUCT]: recordTypeInput(RecordType.PRODUCT),
};

export const conditionalInputMapper: { [key in Intent]?: SidebarInput } = {
  [Intent.SEARCH_CUSTOMERS]: customerFilterFormula,
  [Intent.SEARCH_ORDERS]: orderFilterFormula,
  [Intent.SEARCH_PRODUCTS]: productFilterFormula,
};
