import { getValuesByCacheKey, hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { getChannels, getChats } from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.MICROSOFT_TEAMS,
  provider: ProviderType.MICROSOFT,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Teams account',
  icon: iconSvg,
  description: 'Microsoft Teams',
  sidebarSections: [],
};

const summaryInput: SidebarInput = {
  id: 'summary',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Summary',
  subtitle:
    'Summary text of the chat message that could be used for push notifications and summary views or fall back views. ',
};

const messageInput: SidebarInput = {
  id: 'message',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 3,
  title: 'Message',
  subtitle: 'Plaintext/HTML representation of the content of the chat message.',
};

const subjectInput: SidebarInput = {
  id: 'subject',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'Subject',
  subtitle: 'The subject of the chat message, in plaintext.',
};

const channelInput: SidebarInput = {
  id: 'channel',
  title: 'Channel',
  type: SidebarInputType.DynamicEnum,
  required: true,
  source: getChannels,
  getValues: getValuesByCacheKey,
  subtitle:
    'Select a channel, or enter a channel name to create a new channel in the user’s workspace.',
};

const chatRecipientInput: SidebarInput = {
  id: 'chatRecipient',
  type: SidebarInputType.DynamicEnum,
  required: true,
  source: getChats,
  getValues: getValuesByCacheKey,
  title: 'Chat Recipient',
  subtitle: 'Select a workspace member.',
};

const oauthChannelInput: SidebarInput = {
  id: 'channel',
  title: 'Channel',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle:
    'Enter channel id or enter a channel name to create a new channel in the user’s workspace.',
};

const oauthChatRecipientInput: SidebarInput = {
  id: 'chatRecipient',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  title: 'Chat Recipient',
  subtitle: 'Enter name or id of workspace member.',
};

const config: ActionConfig = {
  actionType: Action.MICROSOFT_TEAMS,
  name: 'Microsoft Teams',
  description: 'Connect your Teams account to sync tickets from your Teams Support account.',
  icon: iconSvg,
  provider: ProviderType.MICROSOFT,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Teams account',
          placeholder: 'connect to Teams',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.MICROSOFT,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    title: 'Messages',
                    items: [
                      {
                        value: Intent.SEND_MESSAGE_IN_CHANNEL,
                        label: 'Send message in channel',
                      },
                      {
                        value: Intent.SEND_MESSAGE_IN_CHAT,
                        label: 'Send message in chat',
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.SEND_MESSAGE_IN_CHANNEL:
          return {
            inputs: [
              ...(hasOAuthAppCredential(parameters, credentials)
                ? [{ ...oauthChannelInput }]
                : [{ ...channelInput }]),
              { ...messageInput },
              { ...subjectInput },
              { ...summaryInput },
            ],
          };
        case Intent.SEND_MESSAGE_IN_CHAT:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? { ...oauthChatRecipientInput }
                : { ...chatRecipientInput },
              { ...messageInput },
              { ...subjectInput },
              { ...summaryInput },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
