import { HermesQueue } from '@shared/types/sdk/atlas';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class JobError extends ParagonError {
  name: string = 'JobError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class InvalidJobAddedInQueueError extends JobError {
  constructor(meta: {
    jobId: string;
    queue: HermesQueue;
    type?: string;
    workflowId?: string;
    projectId?: string;
  }) {
    super({
      code: ERROR_CODE.INVALID_JOB_IN_QUEUE,
      httpStatus: HttpStatus.BAD_REQUEST,
      message: 'Invalid job added to queue.',
      meta,
    });
  }
}
