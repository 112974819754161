import { Client, QueryResult } from 'pg';

import { DATA_TYPE_CONVERTERS } from '@shared/actions/sdk/utils';
import { DataType } from '@shared/types/sdk/resolvers';

import { ColumnTypeMap, SqlRow } from './types';

/**
 * This query will give the primary key column name of table
 * if primary key is more then one
 * @param table table name
 * @param client pg client
 */
export async function getPrimaryKeyOfTable(
  schema: string,
  table: string,
  client: Client,
): Promise<string> {
  const QUERY = `SELECT tc.table_schema, tc.table_name, kc.column_name
    FROM information_schema.table_constraints tc
    JOIN information_schema.key_column_usage kc
      ON kc.table_name = tc.table_name
        AND kc.table_schema = tc.table_schema
        AND kc.constraint_name = tc.constraint_name
    WHERE tc.constraint_type = 'PRIMARY KEY'
      AND tc.table_schema = '${schema}'
      AND tc.table_name ='${table}'
      AND kc.ordinal_position IS NOT null
    ORDER BY tc.table_schema, tc.table_name, kc.position_in_unique_constraint;`;

  const result: QueryResult = await client.query(QUERY);

  if (result.rows.length > 1) {
    throw new Error('Table has composite keys');
  }
  return result.rows[0].column_name;
}

export async function getColumnWithType(
  schema: string,
  table: string,
  client: Client,
): Promise<ColumnTypeMap> {
  const QUERY: string = `SELECT column_name,udt_name
  FROM information_schema.columns
  WHERE table_schema = '${schema}'
   AND table_name   = '${table}'
     ;`;
  const result: QueryResult = await client.query(QUERY);
  return result.rows.reduce(
    (accumlater: ColumnTypeMap, row: any) => ({
      ...accumlater,
      [row.column_name]: describeDataType(row.udt_name),
    }),
    {},
  );
}

export function describeDataType(udt_name: string): DataType {
  switch (udt_name) {
    case 'timestamp':
    case 'date':
    case 'time':
      return DataType.DATE;
    case 'bigint':
    case 'integer':
    case 'numeric':
    case 'float8':
    case 'decimal':
    case 'int4':
      return DataType.NUMBER;
    case 'bool':
      return DataType.BOOLEAN;
    case 'json':
      return DataType.OBJECT;
    case 'char':
    case 'varchar':
    case 'uuid':
      return DataType.STRING;
    default:
      return DataType.ANY;
  }
}

export function coerceColumns(sqlRow: SqlRow, columnTypeMap: ColumnTypeMap): SqlRow {
  return Object.keys(sqlRow).reduce((row: SqlRow, column: string) => {
    return {
      ...row,
      [column]: DATA_TYPE_CONVERTERS[columnTypeMap[column]](sqlRow[column]),
    };
  }, {});
}

export async function getCoercedValuesForSql(
  schema: string,
  table: string,
  row: SqlRow,
  client: Client,
): Promise<SqlRow> {
  const columns: ColumnTypeMap = await getColumnWithType(schema, table, client);
  return coerceColumns(row, columns);
}

export function sanitizeValueForSql(value: any): any {
  if (typeof value === 'string') {
    return `${value}`;
  } else if (value instanceof Date) {
    return `${value.toUTCString()}`;
  } else {
    return value;
  }
}

/**
 * returns ['"words"']
 * @param words ['word1','word2']
 */
export function wrapWordInInvertedComma(words: string[]): string[] {
  return words.map((word: string) => `'${word}'`);
}

export function getSqlRow(
  cachedColumns: Record<string, string>,
  columnData: Record<string, any>,
): SqlRow {
  return Object.keys(cachedColumns)
    .filter((column: string) => {
      const columnKey = `column-${column}`;
      return columnKey in columnData;
    })
    .reduce(
      (sqlRow: SqlRow, column: string) => ({
        ...sqlRow,
        [column]: columnData[`column-${column}`],
      }),
      {},
    );
}

/**
 * return placeholder string for postgres queries based on element of data array
 * @param data
 */
export function buildValuePlaceholder(data: any[]): string {
  return `$${data.length + 1}`;
}

/**
 * Return True if the identifier (table or column name) is valid
 * Check legal identifiers Postgres
 * https://www.sqlmaestro.com/products/postgresql/maestro/help/_appendix_identifiers/
 * @param identifier:string
 */
export function isValidPostgresIdentifier(identifier: string): boolean {
  if (!identifier) {
    return false;
  }
  // Identifiers can be upto 63 characters long.
  if (identifier.length > 63) {
    return false;
  }
  // all identifier are valid because we are adding double quotes around it when we create query text
  // so no need to check extra reserved keywords or not-valid keywords
  return true;
}
