import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.ELOQUA;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'ELOQUA_TEST_CONNECTION',
  CREATE_CAMPAIGN = 'ELOQUA_CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN = 'ELOQUA_UPDATE_CAMPAIGN',
  ACTIVATE_CAMPAIGN = 'ELOQUA_ACTIVATE_CAMPAIGN',
  SEARCH_CAMPAIGNS = 'ELOQUA_SEARCH_CAMPAIGNS',
  GET_CAMPAIGN_BY_ID = 'ELOQUA_GET_CAMPAIGN_BY_ID',
  CREATE_EMAIL = 'ELOQUA_CREATE_EMAIL',
  UPDATE_EMAIL = 'ELOQUA_UPDATE_EMAIL',
  SEARCH_EMAIL = 'ELOQUA_SEARCH_EMAIL',
  SEND_EMAIL_DEPLOYMENT = 'ELOQUA_SEND_EMAIL_DEPLOYMENT',
  CREATE_CONTACT = 'ELOQUA_CREATE_CONTACT',
  UPDATE_CONTACT = 'ELOQUA_UPDATE_CONTACT',
  SEARCH_CONTACTS = 'ELOQUA_SEARCH_CONTACTS',
  GET_ALL_SEGMENTS = 'ELOQUA_GET_ALL_SEGMENTS',
  GET_ALL_CONTACT_FIELDS = 'ELOQUA_GET_ALL_CONTACT_FIELDS',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ACTIVATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CONTACT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_CONTACT_FIELDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_SEGMENTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CAMPAIGN_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_CAMPAIGNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_CONTACTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_EMAIL_DEPLOYMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CONTACT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  ELOQUA_REFRESH_TOKEN: string;
  ELOQUA_ACCESS_TOKEN: string;
  ELOQUA_SCOPES: string;
  podUrl: string;
  redirectUrl: string;
};
