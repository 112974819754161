/**
 * Base url for mailchimp api
 */
export const MAILCHIMP_API_BASE_URL = 'https://[DataCenter].api.mailchimp.com/3.0';
export const MAILCHIMP_AUTH_BASE_URL = 'https://login.mailchimp.com';

/**
 * Mailchimp Date filters identifier, as Mailchimp doesn't support >=/<= operator
 * Mailchimp provides separate filter keys for this kind of use for example: `before_create_time`|`since_create_time`
 */
export const CAMPAIGN_CREATE_DATE_FILTER = 'CAMPAIGN_CREATION_DATE';
export const CAMPAIGN_SENT_DATE_FILTER = 'CAMPAIGN_SENT_DATE';

export const LIST_CREATE_DATE_FILTER = 'LIST_CREATE_DATE';
export const LIST_CAMPAIGN_DATE_FILTER = 'LIST_CAMPAIGN_DATE';
