import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

export default function conditionsToAzureDevopsFilterExpression(
  condition: ResolvedConditionWrapper,
): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToAzureDevopsFilterExpression(condition);
      })
      .join(condition.join === 'OR' ? ' or ' : ' and ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
      case Operator.NumberEquals:
        return `${innerCondition.variable} = '${innerCondition.argument}'`;
      case Operator.StringDoesNotExactlyMatch:
      case Operator.NumberDoesNotEqual:
        return `${innerCondition.variable} <> '${innerCondition.argument}'`;
      case Operator.StringContains:
        return `${innerCondition.variable} Contains '${innerCondition.argument}'`;
      case Operator.StringDoesNotContain:
        return `${innerCondition.variable} Does Not Contain '${innerCondition.argument}'`;
      case Operator.StringIsIn:
        return `${innerCondition.variable} in (${innerCondition.argument
          .map((argument: string) => `'${argument}'`)
          .join(',')})`;
      case Operator.StringIsNotIn:
        return `${innerCondition.variable} not in (${innerCondition.argument
          .map((argument: string) => `'${argument}'`)
          .join(',')})`;
      case Operator.NumberLessThan:
      case Operator.StringLessThan:
        return `${innerCondition.variable} < '${innerCondition.argument}'`;
      case Operator.NumberGreaterThan:
      case Operator.StringGreaterThan:
        return `${innerCondition.variable} > '${innerCondition.argument}'`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `${innerCondition.variable} >= '${innerCondition.argument}'`;
      case Operator.NumberLessThanOrEqualTo:
        return `${innerCondition.variable} <= '${innerCondition.argument}'`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} = false`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable} = true`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for Azure Devops.`);
    }
  }
  return '';
}
