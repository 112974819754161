import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  additionalFieldsInput,
  companyInput,
  emailInput,
  firstNameInput,
  intentInput,
  jobTitleInput,
  lastNameInput,
  listInput,
  phoneInput,
  prospectFilterInputStatic,
  prospectId,
  websiteInput,
} from './inputs';

export const authConfig: ActionConfig = {
  actionType: Action.PARDOT,
  provider: ProviderType.PARDOT,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Pardot account',
  icon: iconSvg,
  description: 'Pardot',
  sidebarSections: [],
};

const config: ActionConfig = {
  actionType: Action.PARDOT,
  name: 'Pardot',
  description: 'Sync prospects from Pardot',
  icon: iconSvg,
  provider: ProviderType.SALESFORCE,
  sidebarSections: [
    {
      inputs: [intentInput],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.ADD_PROSPECT_TO_LIST:
          return {
            inputs: [listInput, prospectId],
          };
        case Intent.CREATE_OR_UPDATE_PROSPECT:
          return {
            inputs: [
              {
                ...prospectId,
                subtitle: 'Id of prospect to be updated. If left blank, creates a new prospect',
              },
              emailInput,
              firstNameInput,
              lastNameInput,
              companyInput,
              jobTitleInput,
              phoneInput,
              websiteInput,
              additionalFieldsInput,
            ],
          };
        case Intent.DELETE_PROSPECT_BY_ID:
          return { inputs: [{ ...prospectId, subtitle: '' }] };
        case Intent.GET_PROSPECT_BY_ID:
          return { inputs: [{ ...prospectId, subtitle: '' }] };
        case Intent.REMOVE_PROSPECT_FROM_LIST:
          return {
            inputs: [
              listInput,
              { ...prospectId, subtitle: 'ID of prospect to be removed to the List.' },
            ],
          };
        case Intent.SEARCH_PROSPECTS:
          return {
            inputs: [prospectFilterInputStatic],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
