import {
  IntegrationConnectInput,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const campaignIdInput: SidebarInput = {
  id: 'campaignId',
  title: 'Campaign ID',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const creativeIdInput: SidebarInput = {
  id: 'creativeId',
  title: 'Creative ID',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  subtitle: 'ID of the creative to associate with the campaign.',
};

export const advertiserIdInput: SidebarInput = {
  id: 'advertiserId',
  title: 'Advertiser ID',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const creativeGroupIdInput: SidebarInput = {
  id: 'creativeGroupId',
  title: 'Creative Group ID',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const siteIdInput: SidebarInput = {
  id: 'siteId',
  title: 'Site ID',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const placementIdInput: SidebarInput = {
  id: 'placementId',
  title: 'Placement ID',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const videoFormatIdInput: SidebarInput = {
  id: 'videoFormatId',
  title: 'Video Format ID',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const startTimeInput: SidebarInput = {
  id: 'startTime',
  title: 'Start time',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const endTimeInput: SidebarInput = {
  id: 'endTime',
  title: 'End time',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const startDateInput: SidebarInput = {
  id: 'startDate',
  title: 'Start date',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const endDateInput: SidebarInput = {
  id: 'endDate',
  title: 'End date',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const adTypeInput: SidebarInput = {
  id: 'adType',
  title: 'Type',
  type: SidebarInputType.Enum,
  required: true,
  getValues: () => [
    { label: 'Standard Ad', value: 'AD_SERVING_STANDARD_AD' },
    { label: 'Default Ad', value: 'AD_SERVING_DEFAULT_AD' },
    { label: 'Click Tracker', value: 'AD_SERVING_CLICK_TRACKER' },
    { label: 'Tracking', value: 'AD_SERVING_TRACKING' },
    { label: 'Brand Safe Ad', value: 'AD_SERVING_BRAND_SAFE_AD' },
  ],
};

export const activeInput: SidebarInput = {
  id: 'active',
  title: 'Active',
  type: SidebarInputType.Enum,
  required: true,
  getValues: () => [
    { label: 'False', value: 'false' },
    { label: 'True', value: 'true' },
  ],
};

export const commentInput: SidebarInput = {
  id: 'comments',
  title: 'Comments',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const creativeAssignmentInput: SidebarInput = {
  id: 'creativeAssignment',
  title: 'Creative Assignment',
  type: SidebarInputType.TextArea,
  required: false,
  subtitle: 'The ID of the creative to be assigned to the ad.',
  lines: 1,
};

export const placementAssignmentInput: SidebarInput = {
  id: 'placementAssignments',
  title: 'Placement assignments',
  type: SidebarInputType.Code,
  language: 'json',
  required: false,
  subtitle:
    'Placement assignments for this ad. Enter an array of Placement Assignment objects including placementId and active.',
  lines: 3,
  useLightTheme: true,
  placeholder: `
  {
    "placementId": "1234", 
    "active": true,
  }
]`,
};

export const heightInput: SidebarInput = {
  id: 'height',
  title: 'Height',
  type: SidebarInputType.TextArea,
  required: false,
  subtitle: 'Height of the ad. Must be less than 32768.',
  lines: 1,
};

export const widthInput: SidebarInput = {
  id: 'width',
  title: 'Width',
  type: SidebarInputType.TextArea,
  required: false,
  subtitle: 'Width of the ad. Must be less than 32768.',
  lines: 1,
};

export const adIdInput: SidebarInput = {
  id: 'adId',
  title: 'Ad ID',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const archivedInput: SidebarInput = {
  id: 'archived',
  title: 'Archived',
  type: SidebarInputType.Enum,
  required: true,
  getValues: () => [
    { label: 'False', value: 'false' },
    { label: 'True', value: 'true' },
  ],
};

export const creativeGroupIdsInput: SidebarInput = {
  id: 'creativeGroup',
  title: 'Creative Group IDs',
  type: SidebarInputType.TextArea,
  required: false,
  subtitle: 'Array or comma-separated list of creative group IDs assigned to this campaign.',
  lines: 1,
};

export const billingInvoiceCodeInput: SidebarInput = {
  id: 'billingInvoiceCode',
  title: 'Billing Invoice Code',
  type: SidebarInputType.TextArea,
  required: false,
  subtitle:
    'Billing invoice code included in the Campaign Manager client billing invoices associated with the campaign.',
  lines: 1,
};

export const postOAuthProfileIdInput: IntegrationConnectInput = {
  id: 'profileId',
  title: 'Choose a Google Campaign Manager Profile to use',
  placeholder: 'Profile',
  type: SidebarInputType.DynamicEnum,
  required: true,
  sourceType: 'userProfiles',
};

export const creativeTypeInput: SidebarInput = {
  id: 'creativeType',
  title: 'Type',
  type: SidebarInputType.Enum,
  required: true,
  getValues: () => [
    { label: 'Image', value: 'IMAGE' },
    { label: 'Display Redirect', value: 'DISPLAY_REDIRECT' },
    { label: 'Custom Display', value: 'CUSTOM_DISPLAY' },
    { label: 'Internal Redirect', value: 'INTERNAL_REDIRECT' },
    { label: 'Custom Display Interstitial', value: 'CUSTOM_DISPLAY_INTERSTITIAL' },
    { label: 'Interstitial Internal Redirect', value: 'INTERSTITIAL_INTERNAL_REDIRECT' },
    { label: 'Tracking Text', value: 'TRACKING_TEXT' },
    { label: 'Rich Media Display Banner', value: 'RICH_MEDIA_DISPLAY_BANNER' },
    { label: 'Rich Media Inpage Floating', value: 'RICH_MEDIA_INPAGE_FLOATING' },
    { label: 'Rich Media Im Expand', value: 'RICH_MEDIA_IM_EXPAND' },
    { label: 'Rich Media Display Expanding', value: 'RICH_MEDIA_DISPLAY_EXPANDING' },
    { label: 'Rich Media Display Interstitial', value: 'RICH_MEDIA_DISPLAY_INTERSTITIAL' },
    {
      label: 'Rich Media Display Multi Floating Interstitial',
      value: 'RICH_MEDIA_DISPLAY_MULTI_FLOATING_INTERSTITIAL',
    },
    { label: 'Rich Media Mobile in App', value: 'RICH_MEDIA_MOBILE_IN_APP' },
    { label: 'Flash Inpage', value: 'FLASH_INPAGE' },
    { label: 'Instream Video', value: 'INSTREAM_VIDEO' },
    { label: 'Vpaid Linear Video', value: 'VPAID_LINEAR_VIDEO' },
    { label: 'Vpaid Non Linear Video', value: 'VPAID_NON_LINEAR_VIDEO' },
    { label: 'Instream Video Redirect', value: 'INSTREAM_VIDEO_REDIRECT' },
    { label: 'Rich Media Peel Down', value: 'RICH_MEDIA_PEEL_DOWN' },
    { label: 'HTML5 Banner', value: 'HTML5_BANNER' },
    { label: 'Display', value: 'DISPLAY' },
    { label: 'Display Image Gallery', value: 'DISPLAY_IMAGE_GALLERY' },
    { label: 'Brand Safe Instream Video', value: 'BRAND_SAFE_DEFAULT_INSTREAM_VIDEO' },
    { label: 'Instream Audio', value: 'INSTREAM_AUDIO' },
  ],
};

export const creativeAssetsInput: SidebarInput = {
  id: 'creativeAssets',
  title: 'Creative Assets',
  type: SidebarInputType.Code,
  language: 'json',
  required: false,
  subtitle:
    'Use the Create Creative Asset step to upload asset(s), and include an array of Creative Asset objects below.',
  lines: 3,
  useLightTheme: true,
  placeholder: `[
  {
    "id": "1234", 
    "assetIdentifier": ...
  }
]`,
};

export const fileInput: SidebarInput = {
  id: 'file',
  title: 'File',
  type: SidebarInputType.TextArea,
  required: true,
  subtitle: 'Asset file to upload. See our docs for instructions on how to upload file data.',
  lines: 1,
};

export const creativeAssetTypeInput: SidebarInput = {
  id: 'creativeAssetType',
  title: 'Type',
  type: SidebarInputType.Enum,
  required: true,
  getValues: () => [
    { label: 'Image', value: 'IMAGE' },
    { label: 'Flash', value: 'FLASH' },
    { label: 'Video', value: 'VIDEO' },
    { label: 'HTML', value: 'HTML' },
    { label: 'HTML Image', value: 'HTML_IMAGE' },
    { label: 'Audio', value: 'AUDIO' },
  ],
};

export const paymentSourceInput: SidebarInput = {
  id: 'paymentSource',
  title: 'Payment Source',
  type: SidebarInputType.Enum,
  required: true,
  defaultValue: 'AGENCY_PAID',
  getValues: () => [
    { label: 'Agency Paid', value: 'AGENCY_PAID' },
    { label: 'Publisher Paid', value: 'PUBLISHER_PAID' },
  ],
};

export const pricingTypeInput: SidebarInput = {
  id: 'pricingType',
  title: 'Pricing Type',
  type: SidebarInputType.Enum,
  required: true,
  defaultValue: 'PRICING_TYPE_CPM',
  getValues: () => [
    { label: 'CPM', value: 'PRICING_TYPE_CPM' },
    { label: 'CPC', value: 'PRICING_TYPE_CPC' },
    { label: 'CPA', value: 'PRICING_TYPE_CPA' },
    { label: 'Flat Rate Impressions', value: 'PRICING_TYPE_FLAT_RATE_IMPRESSIONS' },
    { label: 'Flat Rate Clicks', value: 'PRICING_TYPE_FLAT_RATE_CLICKS' },
    { label: 'CPM ActiveView', value: 'PRICING_TYPE_CPM_ACTIVEVIEW' },
  ],
};

const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.BooleanTrue,
  Operator.BooleanFalse,
];

export const adFilterInput: SidebarInput = {
  id: 'adFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for ads that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Ids', value: 'ids' },
    {
      label: 'Campaign Ids',
      value: 'campaignIds',
    },
    { label: 'Advertiser Id', value: 'advertiserId' },
    {
      label: 'Creative Id',
      value: 'creativeIds',
    },
    {
      label: 'Placement Id',
      value: 'placementIds',
    },
    { label: 'Active', value: 'active' },
    { label: 'Type', value: 'type' },
    {
      label: 'Search String',
      value: 'searchString',
    },
  ],
};

export const advertiserFilterInput: SidebarInput = {
  id: 'advertiserFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for advertisers that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Ids', value: 'ids' },
    { label: 'Status', value: 'status' },
    {
      label: 'Search String',
      value: 'searchString',
    },
  ],
};

export const campaignFilterInput: SidebarInput = {
  id: 'campaignFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for campaigns that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Ids', value: 'ids' },
    {
      label: 'Advertiser Ids',
      value: 'advertiserIds',
    },
    { label: 'Archived', value: 'archived' },
    {
      label: 'Search String',
      value: 'searchString',
    },
  ],
};

export const creativeFilterInput: SidebarInput = {
  id: 'creativeFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for creatives that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Ids', value: 'ids' },
    {
      label: 'Advertiser Id',
      value: 'advertiserId',
    },
    { label: 'Archived', value: 'archived' },
    { label: 'Active', value: 'active' },
    { label: 'Types', value: 'types' },
    {
      label: 'Creative Field Ids',
      value: 'creativeFieldIds',
    },
    {
      label: 'Search String',
      value: 'searchString',
    },
  ],
};

export const creativeGroupFilterInput: SidebarInput = {
  id: 'creativeGroupFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for creative groups that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Ids', value: 'ids' },
    { label: 'Group Number', value: 'groupNumber' },
    {
      label: 'Advertiser Ids',
      value: 'advertiserIds',
    },
    {
      label: 'Search String',
      value: 'searchString',
    },
  ],
};

export const placementFilterInput: SidebarInput = {
  id: 'placementFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get placements that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Ids', value: 'ids' },
    {
      label: 'Advertiser Ids',
      value: 'advertiserIds',
    },
    {
      label: 'Campaign Ids',
      value: 'campaignIds',
    },
    {
      label: 'Site Ids',
      value: 'siteIds',
    },
    { label: 'Archived', value: 'archived' },
    {
      label: 'Pricing Types',
      value: 'pricingTypes',
    },
    {
      label: 'Groups Ids',
      value: 'groupIds',
    },
    {
      label: 'Max End Date',
      value: 'maxEndDate',
    },
    {
      label: 'Max Start Date',
      value: 'maxStartDate',
    },
    {
      label: 'Min End Date',
      value: 'minEndDate',
    },
    {
      label: 'Min Start Date',
      value: 'minStartDate',
    },
    {
      label: 'Search String',
      value: 'searchString',
    },
  ],
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Ad',
          items: [
            {
              value: Intent.CREATE_AD,
              label: 'Create Ad',
            },
            {
              value: Intent.UPDATE_AD,
              label: 'Update Ad',
            },
            {
              value: Intent.GET_ALL_ADS,
              label: 'Get Ads',
            },
            {
              value: Intent.GET_AD_BY_ID,
              label: 'Get Ad by ID',
            },
          ],
        },
        {
          title: 'Advertiser',
          items: [
            {
              value: Intent.GET_ALL_ADVERTISERS,
              label: 'Get Advertisers',
            },
            {
              value: Intent.GET_ADVERTISER_BY_ID,
              label: 'Get Advertiser by ID',
            },
          ],
        },
        {
          title: 'Campaign',
          items: [
            {
              value: Intent.CREATE_CAMPAIGN,
              label: 'Create Campaign',
            },
            {
              value: Intent.UPDATE_CAMPAIGN,
              label: 'Update Campaign',
            },
            {
              value: Intent.GET_ALL_CAMPAIGNS,
              label: 'Get Campaigns',
            },
            {
              value: Intent.GET_CAMPAIGN_BY_ID,
              label: 'Get Campaign by ID',
            },
          ],
        },
        {
          title: 'Creative',
          items: [
            {
              value: Intent.CREATE_CREATIVE,
              label: 'Create Creative',
            },
            {
              value: Intent.UPDATE_CREATIVE,
              label: 'Update Creative',
            },
            {
              value: Intent.GET_ALL_CREATIVES,
              label: 'Get Creatives',
            },
            {
              value: Intent.GET_CREATIVE_BY_ID,
              label: 'Get Creative by ID',
            },
          ],
        },
        {
          title: 'Creative Asset',
          items: [
            {
              value: Intent.CREATE_CREATIVE_ASSET,
              label: 'Create Creative Asset',
            },
          ],
        },
        {
          title: 'Campaign Creative Association',
          items: [
            {
              value: Intent.CREATE_CAMPAIGN_CREATIVE_ASSOCIATION,
              label: 'Create Campaign Creative Association',
            },
            {
              value: Intent.GET_CAMPAIGN_CREATIVE_ASSOCIATION,
              label: 'Get Campaign Creative Associations',
            },
          ],
        },
        {
          title: 'Creative Group',
          items: [
            {
              value: Intent.CREATE_CREATIVE_GROUPS,
              label: 'Create Creative Group',
            },
            {
              value: Intent.UPDATE_CREATIVE_GROUPS,
              label: 'Update Creative Group',
            },
            {
              value: Intent.GET_ALL_CREATIVE_GROUPS,
              label: 'Get Creative Groups',
            },
            {
              value: Intent.GET_CREATIVE_GROUP_BY_ID,
              label: 'Get Creative Group by ID',
            },
          ],
        },
        {
          title: 'Placement',
          items: [
            {
              value: Intent.CREATE_PLACEMENT,
              label: 'Create Placement',
            },
            {
              value: Intent.UPDATE_PLACEMENT,
              label: 'Update Placement',
            },
            {
              value: Intent.GET_ALL_PLACEMENTS,
              label: 'Get Placements',
            },
            {
              value: Intent.GET_PLACEMENT_BY_ID,
              label: 'Get Placement by ID',
            },
          ],
        },
        {
          title: 'Video Format',
          items: [
            {
              value: Intent.GET_ALL_VIDEO_FORMATS,
              label: 'Get Video Formats',
            },
            {
              value: Intent.GET_VIDEO_FORMATS_BY_ID,
              label: 'Get Video Format by ID',
            },
          ],
        },
      ],
    },
  ],
};
