import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  buildInvoiceLineItemInputs,
  businessUnitInput,
  createInvoiceInputs,
  intentInput,
  invoiceIdInput,
  invoiceNumberInput,
  searchFilterInput,
  supplierInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.ORACLE_FINANCIALS_CLOUD,
  name: 'Oracle Financials Cloud',
  description: 'Choose Oracle Financials Cloud account',
  icon: iconSvg,
  provider: ProviderType.ORACLE_FINANCIALS_CLOUD,
  sidebarSections: [
    { inputs: [intentInput] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEARCH_INVOICES:
          return {
            inputs: [searchFilterInput],
          };
        case Intent.CREATE_INVOICE:
        case Intent.UPDATE_INVOICE:
          return {
            inputs: [
              ...(parameters.intent === Intent.UPDATE_INVOICE
                ? [invoiceIdInput, ...createInvoiceInputs(false)]
                : createInvoiceInputs(true)),
            ],
          };
        case Intent.VALIDATE_INVOICE:
          return {
            inputs: [{ ...invoiceNumberInput, required: true }, businessUnitInput, supplierInput],
          };
        case Intent.BUILD_INVOICE_LINE_ITEM:
          return {
            inputs: buildInvoiceLineItemInputs,
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
