import { IInvite } from '@shared/entities/sdk/invite/invite.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<IInvite> = {
  entities: {},
  processing: false,
  errorMessage: undefined,
};

export function handler(
  state: EntitiesState<IInvite> = initialState,
  action: Action,
): EntitiesState<IInvite> {
  switch (action.type) {
    case 'INVITE_ENTITY_GET_START':
    case 'INVITE_ENTITY_REMOVE_START':
      return {
        ...state,
        processing: true,
      };
    case 'INVITE_ENTITY_GET_FAILURE':
    case 'INVITE_ENTITY_REMOVE_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };

    case 'INVITE_ENTITY_GET_SUCCESS':
      return {
        ...state,
        entities: { ...indexBy('id', action.invites) },
        processing: false,
        errorMessage: undefined,
      };

    case 'INVITE_ENTITY_REMOVE_SUCCESS':
      return {
        ...state,
        entities: { ...indexBy('id', action.invites) },
        processing: false,
        errorMessage: undefined,
      };

    case 'INVITE_ENTITY_REMOVE_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: undefined,
      };

    default:
      return state;
  }
}
