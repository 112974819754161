import { Action, ActionIntent } from './actions';
import { HttpStatus, StepFailure } from './errors';
import { DataType, KeyedSource, WorkflowVariables } from './resolvers';
import {
  Continuation,
  DynamicMappingApplicationOutput,
  ExecutionStatus,
  HttpMethod,
  StateMachine,
  Step,
  StepType,
  TriggerInput,
  Variables,
  WorkflowExecutionHeartbeat,
  WorkflowExecutionStartUpdate,
  WorkflowExecutionSuccessUpdate,
  WorkflowExecutionUpdate,
  WorkflowExecutionWaitingUpdate,
} from './steps';
import { BillingPlan } from './stripe';

export type FanoutStackEntry = {
  stepId: string;
  instanceId: string;
  index: number;
};

export type Execution =
  | WorkflowExecution
  | WorkflowCompleteExecution
  | ActionExecution
  | ActionTriggerExecution
  | ConnectActionExecution
  | ConnectProxyExecution;

/**
 * @type ExecutionMetaData
 * Additional data about execution which can be related to bull specific things which doesn't directly
 * belong to execution data
 */
export type ExecutionMetaData = {
  startJobId?: string;
};

type BaseExecution = {
  id: string;
  type: ExecutionType;
  ownerId: string;
  startTime: number;
  endTime?: number;
  retryCount?: number;
  meta?: ExecutionMetaData;
};

export enum QueueType {
  MAIN = 'MAIN',
  DEDICATED = 'DEDICATED',
}

export type CronTriggerExecution = {
  projectId: string;
  workflowId: string;
  workflowMigrationId: string;
};

export type ConnectCronSimpleExecution = CronTriggerExecution & {
  executionGroupId: string;
  integrationId: string;
  connectCredentialId: string;
  credentialId?: string;
};

export type WorkflowQueueRequirements = {
  type: QueueType;
  count?: number;
};

export type WorkflowExecution = BaseExecution & {
  projectId: string;
  workflowId: string;
  activeStepId: string;
  activeInstanceId: string;
  prevInstanceId?: string;
  activeFanoutStack: FanoutStackEntry[];
  // PARA-4276 now we will not add trigger input in bull job payload the key is still here since existing execution will still have the input in bull
  triggerInput: TriggerInput | undefined;
  alertOnFailure: boolean;
  testing: boolean;
  secretIds: string[];
  variables: KeyedSource[]; // TODO - remove after all jobs are migrated to use variables from execution data
  queue: string;
  executionGroupId?: string;
  integrationId?: string;
  customIntegrationId?: string;
  connectCredentialId?: string;
  cachedCredentialId?: string;
  providerId?: string;
  credentialId?: string;
  personaId?: string;
};

export enum WorkflowType {
  CLASSIC,
  CONNECT,
}

// TODO: dedupe with `WorkflowExecution`
//       currently needed for state machine
export type WorkflowExecutionContext = {
  workflowType: WorkflowType;
  projectId: string;
  workflowId: string;
  integrationId?: string;
  customIntegrationId?: string;
  providerId?: string;
  connectCredentialId?: string;
  credentialId?: string;
  stepId: string;
  executionId: string;
  instanceId: string;
  testing: boolean;
  startTime: number;
  queue: string;
  triggerInput: TriggerInput;
  /**
   * used to keep track of fanouts steps are executing in
   * it can be set to undefined when executing single step tests
   */
  fanoutStack: FanoutStackEntry[] | undefined;
  prevInstanceId?: string;
  stateMachine: StateMachine;
  plan: BillingPlan | undefined;
  personaId?: string;
};

export type WorkflowCompleteExecution = WorkflowExecution & {
  status: ExecutionStatus;
  plan: BillingPlan;
};

export type ActionExecution = BaseExecution & {
  action: Action;
  intent: ActionIntent;
  parameters: KeyedSource<DataType.ANY>[];
  credentials: string[];
};

export type CronActionAccumulatorExecution = {
  workflowId: string;
  projectId: string;
  workflowMigrationId: string;
  action: Action;
  intent: ActionIntent;
  parameters: KeyedSource<DataType.ANY>[];
  credentials: string[];
  testing: boolean;
};

export type ConnectTriggerCronExecution = Omit<CronActionAccumulatorExecution, 'credentials'> & {
  integrationId: string;
  executionGroupId: string;
  credentialId?: string;
  connectCredentialId: string;
};

export enum ActionTriggerExecutionType {
  FETCH_NEW_RECORDS = 'FETCH_NEW_RECORDS',
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  PROCESS_WEBHOOK_RECORD = 'PROCESS_WEBHOOK_RECORD',
}

export type ActionTriggerExecution = BaseExecution & {
  operationType: ActionTriggerExecutionType;
  workflowId: string;
  projectId: string;
  workflowMigrationId: string;
  action: Action;
  intent: ActionIntent;
  parameters: KeyedSource<DataType.ANY>[];
  credentials: string[];
  testing: boolean;
  checkpoint?: number;
  integrationId?: string;
  connectCredentialId?: string;
  providerId?: string;
  credentialId?: string;
  customIntegrationId?: string;
};

export type ConnectActionExecution = Omit<BaseExecution, 'ownerId'> & {
  action: Action;
  intent: ActionIntent;
  parameters: Record<string, unknown>;
  credentialId: string;
  connectCredentialId?: string;
  integrationId?: string;
};

export type ConnectProxyExecution = Omit<BaseExecution, 'ownerId'> & {
  body: string | Record<string, unknown>;
  headers: Record<string, string>;
  path: string;
  credentialId?: string;
  connectCredentialId?: string;
  method: HttpMethod;
  action: Action;
  integrationId?: string;
};
export enum ExecutionType {
  WORKFLOW_START = 'WORKFLOW_START',
  WORKFLOW_END = 'WORKFLOW_END',
  WORKFLOW_STEP_START = 'WORKFLOW_STEP_START',
  WORKFLOW_STEP_EXECUTE = 'WORKFLOW',
  WORKFLOW_STEP_END = 'WORKFLOW_STEP_END',
  ACTION = 'ACTION',
  CRON_TRIGGER = 'CRON/ACTION',
  CONNECT_ACTION = 'CONNECT/ACTION',
  CONNECT_PROXY = 'CONNECT/PROXY',
}

export type ExecutionResult = ActionResult | StepResult | ConnectResult | ConnectProxyResult;

export type ActionResult = {
  input: object;
  output: any;
  success: boolean;
  startTime: number;
  endTime: number;
};

export type StepResult = {
  input: object;
  inputObfuscated: object;
  continuation: Continuation;
  continuationObfuscated: Continuation;
  startTime: number;
  endTime: number;
};

export type ConnectResult = {
  output: any;
};

export type ConnectError = {
  message: string;
  statusCode: HttpStatus;
};

export type ResponseConfig = {
  responseType: string;
};

export type ConnectProxyResult = {
  output: any;
  status: number;
  headers?: any;
  error?: Error;
};

export type ProcessorResult<T extends Variables = Variables> = {
  input: object;
  continuation: Continuation<T>;
};

export type TruncatedOutput<T = any> = {
  truncated: boolean;
  output: T;
};

export enum WorkflowSubscriptionUpdateStatus {
  ERROR = 'error',
  NEXT = 'next',
  COMPLETE = 'complete',
}

export type NonFailureWorkflowExecutionUpdate =
  | WorkflowExecutionStartUpdate
  | WorkflowExecutionSuccessUpdate
  | WorkflowExecutionWaitingUpdate
  | WorkflowExecutionHeartbeat;

export type ExecutionUpdate =
  | ActionExecutionUpdate
  | WorkflowExecutionUpdate
  | ActionTriggerExecutionUpdate
  | ConnectActionSubscriptionUpdate
  | ConnectProxySubscriptionUpdate;

export type ActionExecutionStartUpdate = {
  type: 'START';
  action: Action;
  intent: ActionIntent;
};

export type ActionExecutionSuccessUpdate = {
  type: 'SUCCESS';
  action: Action;
  intent: ActionIntent;
  result: ActionResult;
};

export type ActionExecutionFailureUpdate = {
  type: 'FAILURE';
  action: Action;
  intent: ActionIntent;
  error: Error;
};

export type ActionExecutionUpdate =
  | ActionExecutionStartUpdate
  | ActionExecutionSuccessUpdate
  | ActionExecutionFailureUpdate;

/**
 * trigger result returned from actions
 */
export type ActionTriggerBackendResult = {
  output: ActionTriggerOutput[];
};

export type ActionTriggerOutputWithHash = {
  hash: string;
  data: ActionTriggerOutput;
};

export type ActionTriggerOutput = {
  date: number;
  record: any;
};

export type ActionTriggerObjectMapperOutput = {
  hash: string;
  data: {
    date: number;
    record: DynamicMappingApplicationOutput;
  };
};

export type ActionTriggerFetchRecordResult = {
  type: ActionTriggerExecutionType.FETCH_NEW_RECORDS;
  input: object;
  output: ActionTriggerOutputWithHash[];
  success: boolean;
};

export type ActionTriggerSubscribeResult = {
  type: ActionTriggerExecutionType.SUBSCRIBE;
  output: { webhookId: string };
  connectCredentialId?: string;
  providerId?: string;
};

export type ActionTriggerUnSubscribeResult = {
  type: ActionTriggerExecutionType.UNSUBSCRIBE;
  output: object;
};

export type ActionTriggerProcessWebhookRecordResult<T = unknown> = {
  type: ActionTriggerExecutionType.PROCESS_WEBHOOK_RECORD;
  input: object;
  output: {
    [providerId: string]: T[];
  };
  success: boolean;
};

export type ActionTriggerResult =
  | ActionTriggerSubscribeResult
  | ActionTriggerUnSubscribeResult
  | ActionTriggerProcessWebhookRecordResult
  | ActionTriggerFetchRecordResult;

export type ActionTriggerExecutionUpdate =
  | ActionTriggerExecutionStartUpdate
  | ActionTriggerExecutionSuccessUpdate
  | ActionTriggerExecutionFailureUpdate;

export type ActionTriggerExecutionStartUpdate = {
  type: 'START';
  action: Action;
  intent: ActionIntent;
};

export type ActionTriggerExecutionSuccessUpdate = {
  type: 'SUCCESS';
  action: Action;
  intent: ActionIntent;
  result: ActionTriggerResult;
};

export type ActionTriggerExecutionFailureUpdate = {
  type: 'FAILURE';
  action: Action;
  intent: ActionIntent;
  error: Error;
};

export type ConnectActionSubscriptionUpdate =
  | ConnectActionSubscriptionStartUpdate
  | ConnectActionSubscriptionSuccessUpdate
  | ConnectActionSubscriptionFailureUpdate;

export type ConnectActionSubscriptionStartUpdate = {
  type: 'START';
  action: Action;
  intent: ActionIntent;
};

export type ConnectActionSubscriptionSuccessUpdate = {
  type: 'SUCCESS';
  action: Action;
  intent: ActionIntent;
  result: ConnectResult | ConnectProxyResult;
};
export type ConnectActionSubscriptionFailureUpdate = {
  type: 'FAILURE';
  action: Action;
  intent: ActionIntent;
  error: Error;
};

export type ConnectProxySubscriptionUpdate =
  | ConnectProxySubscriptionStartUpdate
  | ConnectProxySubscriptionSuccessUpdate
  | ConnectProxySubscriptionFailureUpdate;

export type ConnectProxySubscriptionStartUpdate = {
  type: 'START';
};

export type ConnectProxySubscriptionSuccessUpdate = {
  type: 'SUCCESS';
  action: Action;
  result: ConnectResult | ConnectProxyResult;
};
export type ConnectProxySubscriptionFailureUpdate = {
  type: 'FAILURE';
  error: Error;
};

export type WorkflowSubscriptionUpdate = {
  status: WorkflowSubscriptionUpdateStatus;
  executionId: string;
  payload: string | undefined;
  s3Path: string | undefined;
};

export type ExecutionHandler = {
  (update: WorkflowSubscriptionUpdate): void;
};

export const TrackableExecutions = [StepType.ACTION, StepType.FUNCTION, StepType.REQUEST];

/**
 * this type represents trigger input in redis
 */
export type StoredTriggerInput = {
  /**
   * whether the trigger input is available remotely (s3) or in local
   */
  remoteCached: boolean;
  input: TriggerInput | undefined;
};

/**
 * continuation cached in redis (or s3 if large)
 */
export type StoredContinuation =
  | {
      /**
       * whether or not the continuation is stored in s3
       */
      remoteCached: true;
    }
  | {
      /**
       * whether or not the continuation is stored in s3
       */
      remoteCached: false;

      /**
       * stringified + encrypted `SerializableDetailedProcessorResult`
       * the encryption key is the id of the execution
       */
      value: string;
    };

/**
 * this type represents the job data required for workflow execution
 */
export type SubmitWorkflowDTO = {
  ownerId: string;
  workflowId: string;
  workflowMigrationId: string;
  stateMachine: StateMachine;
  testing: boolean;
  triggerInput: TriggerInput;
  credentialId?: string;
  connectCredentialId?: string;
  secretIds: string[];
  integrationId?: string;
  customIntegrationId?: string;
  endUserId?: string;
  providerId?: string;
  executionId?: string;
  executionGroupId?: string;
  replayOf?: string;
  alertOnFailure: boolean;
  oneOfMany?: boolean;
  personaId?: string;
};

/**
 * a processor result from Hercules with additional metadata
 */
export type DetailedProcessorResult<T extends Variables = Variables> = ProcessorResult<T> & {
  error?: Error;
  testing: boolean;
  step: Step;
  triggerInput: TriggerInput;
  secrets: Record<string, string>;
  variables: WorkflowVariables;
  status: ExecutionStatus;
  stateMachine: StateMachine;
  startTime: number;
  endTime: number;
};

/**
 * detailed processor result prepared for transmission
 */
export type SerializableDetailedProcessorResult<T extends Variables = Variables> = Omit<
  DetailedProcessorResult<T>,
  'stateMachine' | 'triggerInput' | 'step'
> & {
  projectId: string;
};

/**
 * detailed processor result with obfuscated data for external transmission
 */
export type ObfuscatedDetailedProcessorResult<T extends Variables = Variables> =
  DetailedProcessorResult<T> & {
    projectId: string;
    error?: StepFailure;
    errorObfuscated?: string;
    inputObfuscated: object;
    continuationObfuscated: Continuation;
  };
