import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { Dispatch } from 'redux';

import { error as logError } from '@shared/logger/sdk/legacy';
import { ExecutionStatus } from '@shared/types/sdk/steps';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { Action } from '../../types';

const WORKFLOW_LOG_PAGE_SIZE = 50;
const TASK_HISTORY_FILTER_OPERATORS: Record<keyof TaskHistoryFilters, CondOperator> = {
  workflowId: CondOperator.EQUALS,
  status: CondOperator.EQUALS,
  start: CondOperator.GREATER_THAN_EQUALS,
  end: CondOperator.LOWER_THAN_EQUALS,
  integrationId: CondOperator.EQUALS,
  endUserId: CondOperator.EQUALS,
};

export const INITIAL_PAGE = 1;

export type TaskHistoryFilters = {
  workflowId?: string;
  status?: ExecutionStatus;
  /**
   * Represents the start of a date range query, in ISO datestring format.
   */
  start?: string;
  /**
   * Represents the end of a date range query, in ISO datestring format.
   */
  end?: string;
  integrationId?: string;
  /**
   * Represent connect User Persona id
   */
  endUserId?: string;
};

export const getWorkflowLogs =
  (projectId: string, page: number, filters: TaskHistoryFilters) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'WORKFLOW_LOG_ENTITY_FETCH_START' });

    let queryBuilder = RequestQueryBuilder.create({
      sort: [{ field: 'start', order: 'DESC' }],
      filter: [{ field: 'isTest', operator: CondOperator.EQUALS, value: false }],
      page,
      limit: WORKFLOW_LOG_PAGE_SIZE,
    });

    Object.keys(filters)
      .filter((filterKey: string) => filters[filterKey as keyof TaskHistoryFilters])
      .forEach((filterKey: string) => {
        const filter = filterKey as keyof TaskHistoryFilters;
        queryBuilder = queryBuilder.setFilter({
          field: filterKey,
          operator: TASK_HISTORY_FILTER_OPERATORS[filter],
          value: filters[filter],
        });
      });

    let response: Response;
    try {
      response = await api.get(
        // timestamp is added as it was getting response from cache PARA-5545
        `/projects/${projectId}/executions?${queryBuilder.query()}&timestamp=${Date.now()}`,
      );
      if (response.ok) {
        const workflowLogResponse = await response.json();
        dispatch({
          type: 'WORKFLOW_LOG_ENTITY_SET_TOTAL_COUNT',
          pageCount: workflowLogResponse.pageCount,
        });
        dispatch({
          type: 'WORKFLOW_LOG_ENTITY_FETCH_SUCCESS',
          workflowLogs:
            Array.isArray(workflowLogResponse) && workflowLogResponse.length === 0
              ? []
              : workflowLogResponse.data,
        });
      } else {
        dispatch({
          type: 'WORKFLOW_LOG_ENTITY_FETCH_FAILURE',
          message: await getErrorMessage(response),
        });
      }
    } catch (err) {
      dispatch({
        type: 'WORKFLOW_LOG_ENTITY_FETCH_FAILURE',
        message: err.message,
      });
    }
  };

export const trackViewTaskHistory =
  (projectId: string, userId: string, workflowExecutionId?: string) => async (): Promise<void> => {
    try {
      await api.post('/analytics/track/task-history-viewed', {
        projectId,
        userId,
        workflowExecutionId,
      });
    } catch (err) {
      logError('Failed analytics task history.', err);
    }
  };
