import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { eventIdInput } from './config';

const intentToEventIdSubtitleMap: Partial<Record<Intent, string>> = {
  [Intent.UPDATE_EVENT]: 'The ID of the event to update.',
  [Intent.DELETE_EVENT]: 'The ID of the calendar event to be deleted.',
  [Intent.GET_EVENT_BY_ID]: '',
};

const config: ActionConfig = {
  actionType: Action.GOOGLE_CALENDAR,
  name: 'Google Calendar',
  description: 'Create, update, and access Google Calendar events.',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.CREATE_EVENT,
              label: 'Create event',
            },
            {
              value: Intent.UPDATE_EVENT,
              label: 'Update event',
            },
            {
              value: Intent.LIST_EVENTS,
              label: 'List events',
            },
            {
              value: Intent.GET_EVENT_BY_ID,
              label: 'Get event by ID',
            },
            {
              value: Intent.DELETE_EVENT,
              label: 'Delete event',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (
        [Intent.UPDATE_EVENT, Intent.DELETE_EVENT, Intent.GET_EVENT_BY_ID].includes(
          parameters.intent as Intent,
        )
      ) {
        return {
          inputs: [
            {
              id: 'eventId',
              title: 'Event ID',
              required: true,
              type: SidebarInputType.TextArea,
              lines: 1,
              subtitle: intentToEventIdSubtitleMap[parameters.intent as Intent],
            },
          ],
        };
      }
      return { inputs: [] };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_EVENT:
        case Intent.UPDATE_EVENT:
          return {
            inputs: [
              {
                id: 'eventName',
                title: 'Event name',
                required: parameters.intent === Intent.CREATE_EVENT,
                type: SidebarInputType.TextArea,
                lines: 1,
              },
              {
                id: 'startTime',
                title: 'Start time',
                required: parameters.intent === Intent.CREATE_EVENT,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle: 'Accepts Unix timestamp or ISO8601 date formats.',
              },
              {
                id: 'endTime',
                title: 'End time',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle: 'Defaults to one hour after the start time if left blank.',
              },
              {
                id: 'calendar',
                title: 'Calendar',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which calendar the event will be added to. Defaults to the user’s primary calendar if left blank.',
              },
              {
                id: 'attendees',
                title: 'Attendees',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle:
                  'Accepts an array of email addresses or email addresses separated by commas.',
              },
              {
                id: 'eventLocation',
                title: 'Event location',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
              },
              {
                id: 'eventDescription',
                title: 'Event description',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
              },
              ...(parameters.intent === Intent.CREATE_EVENT
                ? [
                    eventIdInput,
                    {
                      id: 'includeMeetLink',
                      title: 'Include Google Meet link?',
                      subtitle: 'Automatically creates Google Meet conference link for this event.',
                      type: SidebarInputType.BooleanInput,
                      defaultValue: false,
                      required: false,
                    } as SidebarInput,
                  ]
                : []),
            ],
          };
        case Intent.LIST_EVENTS:
          return {
            inputs: [
              {
                id: 'calendar',
                title: 'Calendar',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which calendar the event will be added to. Defaults to the user’s primary calendar if left blank.',
              },
              {
                id: 'after',
                title: 'After',
                required: false,
                subtitle: 'Filters events that start after the provided date (Unix timestamp).',
                type: SidebarInputType.TextArea,
                lines: 1,
              },
              {
                id: 'before',
                title: 'Before',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle: 'Filters events that end before the provided date (Unix timestamp).',
              },
            ],
          };
        case Intent.GET_EVENT_BY_ID:
        case Intent.DELETE_EVENT:
          return {
            inputs: [
              {
                id: 'calendar',
                title: 'Calendar',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle:
                  'Use Connect Portal Workflow Settings to allow users to select which calendar the event will be added to. Defaults to the user’s primary calendar if left blank.',
              },
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
