import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'ClickUp',
  description: 'Manage lists and tasks in ClickUp',
  authUrl: 'https://app.clickup.com/api',
  accessTokenUrl: `https://api.clickup.com/api/v2/oauth/token`,
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/clickup`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: { notAllowed: true, defaultValues: ['_NO_SCOPE_ALLOWED_'], values: [] },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/clickup',
};
