import {
  getValuesByCacheKey,
  hasOAuthAppCredential,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { currency } from '../shared/currency';
import { quickBooksSupportedOperators } from '../shared/utils';

import { getBillLines, getVendors } from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.QUICKBOOKS,
  provider: ProviderType.QUICKBOOKS,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your QuickBooks account',
  icon: iconSvg,
  description: 'Create, update, and find customers, invoices, payments, and more in QuickBooks.',
  sidebarSections: [],
  hideOAuthApps: false,
  accountTypes: [
    {
      id: 'default',
      accountDescription: 'Production Account',
      scheme: AuthenticationScheme.OAUTH,
    },
    {
      id: 'sandbox',
      accountDescription: 'Sandbox Account',
      scheme: AuthenticationScheme.OAUTH,
      oauthParameters: [
        {
          key: 'sandbox',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    },
  ],
};

const customerInputsForCreateUpdate: SidebarInput[] = [
  {
    id: 'givenName',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
    title: 'Given name',
  },
  {
    id: 'familyName',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: true,
    title: 'Family name',
  },
  {
    id: 'displayName',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Display name',
  },
  {
    id: 'email',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Email address',
  },
  {
    id: 'phoneNumber',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Phone number',
  },
  {
    id: 'billingAddressLine1',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Billing street address',
  },
  {
    id: 'billingAddressLine2',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Billing street address line 2',
  },
  {
    id: 'billingCity',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Billing city',
  },
  {
    id: 'billingStateSubdivisionCode',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Billing State or country subdivision code',
  },
  {
    id: 'billingCountry',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Billing country',
  },
  {
    id: 'billingPostalCode',
    type: SidebarInputType.TextArea,
    lines: 1,
    required: false,
    title: 'Billing postal code',
  },
];

const billsFilterInputOauth: SidebarInput = {
  id: 'billsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get Bills that match the specified filters.',
  placeholder: 'field',
  supportedOperators: quickBooksSupportedOperators,
};

const billsFilterInput: SidebarInput = Object.assign(billsFilterInputOauth, {
  getValues: () => [
    { label: 'Vendor Reference', value: 'VendorRef' },
    { label: 'Sales Term Reference', value: 'SalesTermRef' },
    { label: 'Id', value: 'Id' },
    { label: 'AP Account', value: 'APAccountRef' },
    { label: 'Balance', value: 'Balance' },
    { label: 'DueDate', value: 'DueDate' },
    { label: 'Transaction Date', value: 'TxnDate' },
    { label: 'Total Amount', value: 'TotalAmt' },
    { label: 'Last Updated Time', value: 'MetaData.LastUpdatedTime' },
  ],
});

const accountsFilterInputOauth: SidebarInput = {
  id: 'accountsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get accounts that match the specified filters.',
  placeholder: 'field',
  supportedOperators: quickBooksSupportedOperators,
};

const accountsFilterInput: SidebarInput = Object.assign(accountsFilterInputOauth, {
  getValues: () => [
    { label: 'Id', value: 'Id' },
    { label: 'Name', value: 'name' },
    { label: 'Sub Account', value: 'SubAccount' },
    { label: 'Fully Qualified Name', value: 'FullyQualifiedName' },
    { label: 'Parent Reference', value: 'ParentRef' },
    { label: 'Active', value: 'Active' },
    { label: 'Classification', value: 'Classification' },
    { label: 'Account Type', value: 'AccountType' },
    { label: 'Account Sub Type', value: 'AccountSubType' },
    { label: 'Current Balance', value: 'CurrentBalance' },
    { label: 'Current Balance With Sub Accounts', value: 'CurrentBalanceWithSubAccounts' },
    { label: 'Last Updated Time', value: 'MetaData.LastUpdatedTime' },
    { label: 'Created Time', value: 'MetaData.CreateTime' },
  ],
});

const customersFilterInputOauth: SidebarInput = {
  id: 'customersFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get customers that match the specified filters.',
  placeholder: 'field',
  supportedOperators: quickBooksSupportedOperators,
};

const customersFilterInput: SidebarInput = Object.assign(customersFilterInputOauth, {
  getValues: () => [
    { label: 'Active', value: 'Active' },
    { label: 'Id', value: 'Id' },
    { label: 'Job', value: 'Job' },
    { label: 'Balance', value: 'Balance' },
    { label: 'Balance With Jobs', value: 'BalanceWithJobs' },
    { label: 'Given Name', value: 'GivenName' },
    { label: 'Family Name', value: 'FamilyName' },
    { label: 'Fully Qualified Name', value: 'FullyQualifiedName' },
    { label: 'Company Name', value: 'CompanyName' },
    { label: 'Display Name', value: 'DisplayName' },
    { label: 'Print On Check Name', value: 'PrintOnCheckName' },
    { label: 'Last Updated Time', value: 'MetaData.LastUpdatedTime' },
    { label: 'Created Time', value: 'MetaData.CreateTime' },
    { label: 'Primary Email', value: 'PrimaryEmailAddr' },
  ],
});

const invoicesFilterInputOauth: SidebarInput = {
  id: 'invoicesFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get invoices that match the specified filters.',
  placeholder: 'field',
  supportedOperators: quickBooksSupportedOperators,
};

const invoicesFilterInput: SidebarInput = Object.assign(invoicesFilterInputOauth, {
  getValues: () => [
    { label: 'Id', value: 'Id' },
    { label: 'Customer Reference', value: 'CustomerRef' },
    { label: 'Balance', value: 'Balance' },
    { label: 'Total Amount', value: 'TotalAmt' },
    { label: 'Transaction Date', value: 'TxnDate' },
    { label: 'Document Number', value: 'DocNumber' },
    { label: 'Sales Term Reference', value: 'SalesTermRef' },
    { label: 'DueDate', value: 'DueDate' },
    { label: 'Last Updated Time', value: 'MetaData.LastUpdatedTime' },
    { label: 'Created Time', value: 'MetaData.CreateTime' },
  ],
});

const paymentsFilterInputOauth: SidebarInput = {
  id: 'paymentsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Get payments that match the specified filters.',
  placeholder: 'field',
  supportedOperators: quickBooksSupportedOperators,
};

const paymentsFilterInput: SidebarInput = Object.assign(paymentsFilterInputOauth, {
  getValues: () => [
    { label: 'Id', value: 'Id' },
    { label: 'Customer Reference', value: 'CustomerRef' },
    { label: 'Total Amount', value: 'TotalAmt' },
    { label: 'Transaction Date', value: 'TxnDate' },
    { label: 'Last Updated Time', value: 'MetaData.LastUpdatedTime' },
    { label: 'Created Time', value: 'MetaData.CreateTime' },
  ],
});

const currencyInput: SidebarInput = {
  id: 'currencyId',
  type: SidebarInputType.Enum,
  required: false,
  title: 'Currency',
  getValues: () => {
    return currency;
  },
};

const config: ActionConfig = {
  actionType: Action.QUICKBOOKS,
  name: 'QuickBooks',
  description: 'Create, update, and find customers, invoices, payments, and more in QuickBooks.',
  icon: iconSvg,
  provider: ProviderType.QUICKBOOKS,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your QuickBooks account',
          placeholder: 'connect to QuickBooks',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'User authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.QUICKBOOKS,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>): SidebarSection =>
      hasOAuthAppCredential(parameters, credentials)
        ? {
            inputs: [
              {
                id: 'realmId',
                type: SidebarInputType.TextArea,
                placeholder: '{{1.auth.realmId}}',
                title: 'Realm Id',
                lines: 1,
                required: true,
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    title: 'Accounts',
                    items: [
                      {
                        value: Intent.GET_ACCOUNTS,
                        label: 'Get accounts',
                      },
                    ],
                  },
                  {
                    title: 'Bills',
                    items: [
                      {
                        value: Intent.GET_BILLS,
                        label: 'Get bills',
                      },
                      {
                        value: Intent.CREATE_BILL,
                        label: 'Create bill',
                      },
                    ],
                  },
                  {
                    title: 'Customers',
                    items: [
                      { value: Intent.GET_CUSTOMERS, label: 'Get customers' },
                      {
                        value: Intent.CREATE_CUSTOMER,
                        label: 'Create customer',
                      },
                      {
                        value: Intent.UPDATE_CUSTOMER,
                        label: 'Update customer',
                      },
                    ],
                  },
                  {
                    title: 'Invoices',
                    items: [
                      {
                        value: Intent.GET_INVOICES,
                        label: 'Get invoices',
                      },
                      {
                        value: Intent.CREATE_INVOICE,
                        label: 'Create invoice',
                      },
                      {
                        value: Intent.UPDATE_INVOICE,
                        label: 'Update invoice',
                      },
                      {
                        value: Intent.SEND_INVOICE,
                        label: 'Send invoice',
                      },
                    ],
                  },
                  {
                    title: 'Payments',
                    items: [
                      {
                        value: Intent.GET_PAYMENTS,
                        label: 'Get payments',
                      },
                      {
                        value: Intent.CREATE_PAYMENT,
                        label: 'Create payments',
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent) {
        case Intent.GET_BILLS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? billsFilterInputOauth
                : billsFilterInput,
            ],
          };
        case Intent.GET_ACCOUNTS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? accountsFilterInputOauth
                : accountsFilterInput,
            ],
          };
        case Intent.GET_CUSTOMERS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? customersFilterInputOauth
                : customersFilterInput,
            ],
          };
        case Intent.GET_INVOICES:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? invoicesFilterInputOauth
                : invoicesFilterInput,
            ],
          };
        case Intent.GET_PAYMENTS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? paymentsFilterInputOauth
                : paymentsFilterInput,
            ],
          };

        case Intent.CREATE_CUSTOMER:
        case Intent.UPDATE_CUSTOMER:
          return {
            inputs:
              parameters.intent === Intent.UPDATE_CUSTOMER
                ? [
                    {
                      id: 'updateCustomerId',
                      title: 'Customer',
                      placeholder: 'Customer ID',
                      subtitle: 'The ID of the customer to update.',
                      type: SidebarInputType.TextArea,
                      lines: 1,
                      required: true,
                    },
                    ...customerInputsForCreateUpdate,
                    {
                      id: 'syncToken',
                      type: SidebarInputType.TextArea,
                      lines: 1,
                      required: false,
                      subtitle:
                        'Current version number of the object, which will be incremented for the update. The Sync Token value can be retrieved from any QuickBooks object. Defaults to incrementing the previous Sync Token value.',
                      title: 'Sync Token',
                    },
                  ]
                : [...customerInputsForCreateUpdate],
          };
        case Intent.SEND_INVOICE:
          return {
            inputs: [
              {
                id: 'invoiceId',
                title: 'Invoice ID',
                placeholder: 'Enter invoice ID',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: true,
              },
              {
                id: 'email',
                title: 'Send to email address',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: false,
                subtitle:
                  'Updates the invoice’s billing email address to the email provided. If left blank, sends to the invoice’s existing billing email adddress.',
                placeholder: 'name@example.com',
              },
            ],
          };
        case Intent.CREATE_PAYMENT:
          return {
            inputs: [
              {
                id: 'totalAmount',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: true,
                title: 'Total Amount',
                placeholder: '25.0',
              },
              {
                id: 'customerId',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: true,
                title: 'Customer',
                placeholder: 'Customer ID',
              },
              {
                id: 'invoiceId',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Linked invoice',
                required: false,
                subtitle: 'Applies this payment to an existing invoice in QuickBooks.',
                placeholder: 'Invoice ID',
              },
              currencyInput,
            ],
          };

        case Intent.CREATE_BILL:
          const vendorInput: SidebarInput = {
            id: 'vendorId',
            title: 'Vendor',
            type: SidebarInputType.DynamicEnum,
            placeholder: 'Choose a vendor or enter a vendor ID',
            required: true,
            source: getVendors,
            getValues: getValuesByCacheKey,
          };

          const billLineInput: SidebarInput = {
            id: 'billLines',
            title: 'Line items',
            subtitle: 'Enter the value and expense account or each line item.',
            type: SidebarInputType.LinesListDynamicInput,
            leftInput: {
              placeholder: 'value',
            },
            rightInput: {
              placeholder: 'Choose expense account or enter account ID',
              isDropdown: true,
            },
            source: getBillLines,
            getValues: getValuesByCacheKey,
          };

          const billLineInputOauth: SidebarInput = {
            id: 'billLines',
            title: 'Line items',
            subtitle: 'Enter the value and expense account or each line item.',
            type: SidebarInputType.LinesListInput,
            leftInput: {
              placeholder: 'value',
            },
            rightInput: {
              placeholder: 'Account ID',
              isDropdown: false,
            },
          };

          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    id: 'vendorId',
                    title: 'Vendor',
                    placeholder: 'Vendor ID',
                    required: true,
                    type: SidebarInputType.TextArea,
                    lines: 1,
                  }
                : vendorInput,
              hasOAuthAppCredential(parameters, credentials) ? billLineInputOauth : billLineInput,
              currencyInput,
            ],
          };

        case Intent.CREATE_INVOICE:
          const oldInvoiceLineObj = pickValueSourceByKey(
            parameters.actionParameters,
            'invoiceLines',
          );
          const hasOldInvoiceLines =
            oldInvoiceLineObj && oldInvoiceLineObj.value && oldInvoiceLineObj.value.length > 0;

          return {
            inputs: [
              {
                id: 'CustomerId',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Customer',
                placeholder: 'Customer ID',
                required: true,
              },
              hasOldInvoiceLines
                ? {
                    id: 'invoiceLines',
                    title: 'Line items',
                    type: SidebarInputType.LinesListInput,
                    subtitle: 'Enter the amount and description or each line item.',
                    leftInput: {
                      placeholder: 'amount',
                    },
                    rightInput: {
                      placeholder: 'description',
                      isDropdown: false,
                    },
                  }
                : {
                    id: 'invoiceLinesV1',
                    title: 'Line items',
                    type: SidebarInputType.Code,
                    language: 'json',
                    useLightTheme: true,
                    subtitle:
                      'Enter an array of Line items including the description, quantity, and amount for each Line item.',
                    placeholder: `[
  {
    "description": "White T-Shirt", 
    "quantity": 2,
    "amount": 20.0
  }
]`,
                  },
              {
                id: 'customerMemo',
                type: SidebarInputType.TextArea,
                lines: 2,
                title: 'Customer memo ',
                subtitle: 'Message to the customer that appears on their invoice.',
                required: false,
              },
              currencyInput,
            ],
          };

        case Intent.UPDATE_INVOICE:
          return {
            inputs: [
              {
                id: 'invoiceId',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Invoice ID',
                placeholder: 'Invoice ID',
                subtitle: 'The ID of the invoice to update.',
                required: true,
              },
              {
                id: 'customerMemo',
                type: SidebarInputType.TextArea,
                lines: 2,
                title: 'Customer memo ',
                subtitle: 'Message to the customer that appears on their invoice.',
                required: false,
              },
              {
                id: 'syncToken',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: false,
                subtitle:
                  'Current version number of the object, which will be incremented for the update. The Sync Token value can be retrieved from any QuickBooks object. Defaults to incrementing the previous Sync Token value.',
                title: 'Sync Token',
              },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
