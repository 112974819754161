import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'full_access';

export const scopes: Record<ScopeName, ScopeValue> = {
  full_access: {
    label: 'full_access',
    name: 'full_access',
    required: true,
    description: 'Access full access',
  },
};

export const requiredScopes: ScopeName[] = ['full_access'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Sage Accounting',
  description: 'Sync accounts with Sage Accounting',
  authUrl: 'https://www.sageone.com/oauth2/auth/central?filter=apiv3.1',
  accessTokenUrl: 'https://oauth.accounting.sage.com/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/sage-accounting`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/sage-accounting',
};
