import { HttpException, ValidationError } from '@nestjs/common';

import { ERROR_CODE } from './codes';

/**
 * http status codes surfaced to users
 * copied from `@nestjs/common` and needs to be identical to compile
 */
export enum HttpStatus {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  EARLYHINTS = 103,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  AMBIGUOUS = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED = 421,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
}

export class ParagonError extends HttpException {
  constructor(
    public readonly code: ERROR_CODE,
    public readonly httpStatus: HttpStatus,
    public readonly message: string,
    public readonly meta: object = {},
    public readonly logOnInitialization: boolean = true,
  ) {
    super(
      {
        message,
        code,
        status: httpStatus,
      },
      httpStatus,
    );

    if (this.logOnInitialization) {
      // TODO(PARA-1563): log Paragon errors on initialiation + save them in pino context
      //      we currently can't do this without resolving dashboard logger resolution
      //      using the logger here breaks builds on the web app
      // setContext({ errorCode: code });
      // logError(`ERROR ${code} - ${message}`);
      // setContext({ errorCode: '' });
    }
  }

  static fromJSON(json: object): ParagonError {
    const code: ERROR_CODE = (json['code'] as ERROR_CODE) ?? ERROR_CODE.STEP_FAILURE;
    const httpStatus: HttpStatus = json['httpStatus'] ?? HttpStatus.INTERNAL_SERVER_ERROR;
    const message: string = json['message'] ?? 'Something went wrong.';
    const meta: object = json['meta'] ?? {};
    return new ParagonError(code, httpStatus, message, meta);
  }

  static parseValidations(errors: ValidationError[]): string {
    const stringifiedErrors: string[] = errors.reduce<string[]>(
      (allErrors: string[], error: ValidationError) => {
        let failedConstraints: string[] = allErrors;
        if (error) {
          error.constraints
            ? (failedConstraints = failedConstraints.concat(Object.values(error.constraints)))
            : error.children && failedConstraints.push(this.parseValidations(error.children));
        }
        return failedConstraints;
      },
      [],
    );

    return stringifiedErrors.join(',');
  }

  toJSON(): object {
    return {
      ...this,
      name: this.constructor.name,
    };
  }

  toString(): string {
    return `ERROR ${this.code} - ${this.message}`;
  }
}
