import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  additionalFieldsInput,
  contactIdInput,
  emailsInput,
  headlineInput,
  intentTypeInput,
  locationInput,
  nameInput,
  opportunityIdInput,
  originInput,
  performAsInput,
  phonesInput,
  postingIdInput,
  stageInput,
  upsertPostingFields,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.LEVER,
  name: 'Lever',
  description: "Connect to your users' Lever accounts",
  icon: iconSvg,
  provider: ProviderType.LEVER,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_OPPORTUNITY:
          return {
            inputs: [
              performAsInput,
              nameInput,
              headlineInput,
              stageInput,
              locationInput,
              phonesInput,
              emailsInput,
              originInput,
              {
                ...additionalFieldsInput,
                placeholder: `{
  "archived": {
    "reason": "63dd55b2-a99f-4e7b-985f-22c7bf80ab42"
  },
  "postings": ["f2f01e16-27f8-4711-a728-7d49499795a0"]                
}`,
              },
            ],
          };

        case Intent.GET_OPPORTUNITY_BY_ID:
          return {
            inputs: [opportunityIdInput],
          };

        case Intent.GET_POSTINGS:
          return {
            inputs: [],
          };

        case Intent.GET_POSTING_BY_ID:
          return {
            inputs: [postingIdInput],
          };

        case Intent.CREATE_POSTING:
        case Intent.UPDATE_POSTING:
          return {
            inputs: upsertPostingFields(parameters.intent === Intent.CREATE_POSTING),
          };

        case Intent.GET_CONTACT_BY_ID:
          return {
            inputs: [contactIdInput],
          };

        case Intent.UPDATE_CONTACT:
          return {
            inputs: [
              contactIdInput,
              nameInput,
              headlineInput,
              locationInput,
              emailsInput,
              {
                ...phonesInput,
                placeholder: `[{
  "type": "mobile",
  "value": "123-456-7890"
}]`,
              },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};
export { authConfig };
export default connectConfig;
