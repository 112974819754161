import axios, { AxiosInstance } from 'axios';

import { Credentials } from '../configs';

import { MARKETO_API_VERSION } from './constants';
import { MarketoError, TokenResult } from './types';

/**
 *
 * @summary return basic axios instance
 * @param baseUrl
 * @param headers
 */
export const getAxiosInstance = (
  baseUrl: string,
  headers: Record<string, string>,
): AxiosInstance => {
  return axios.create({
    baseURL: baseUrl,
    headers: headers,
  });
};

export const getMarketoAccessToken = async (credentials: Credentials): Promise<TokenResult> => {
  const client = getAxiosInstance(credentials.MARKETO_IDENTITY, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
  const result = await client.get<TokenResult>(
    `/oauth/token?grant_type=client_credentials&client_id=${credentials.MARKETO_CLIENT_ID}&client_secret=${credentials.MARKETO_CLIENT_SECRET}`,
  );
  return result.data;
};

export const getMarketoApiClient = (baseURL: string, accessToken: string): AxiosInstance =>
  getAxiosInstance(`${baseURL}/${MARKETO_API_VERSION}/`, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });

export const getErrorMessage = (errors: MarketoError[]): string =>
  errors.map((error: MarketoError) => error.message).join('');

export const SearchableLeadFields = [
  { label: 'Cookies', value: 'cookies' },
  { label: 'Department', value: 'department' },
  { label: 'Email', value: 'email' },
  { label: 'Full Name', value: 'mktoName' },
  { label: 'Lead Id', value: 'id' },
  { label: 'Lead Partition Id', value: 'leadPartitionId' },
];
