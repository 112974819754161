import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.JIRA;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'JIRA_TEST_CONNECTION',
  GET_ISSUE_BY_KEY = 'JIRA_GET_ISSUE_BY_KEY',
  CREATE_ISSUE = 'JIRA_CREATE_ISSUE',
  GET_PROJECTS = 'JIRA_GET_PROJECTS',
  GET_ISSUE_TYPES_BY_PROJECT = 'JIRA_GET_ISSUE_TYPES_BY_PROJECT',
  GET_ISSUE_STATUS_BY_PROJECT = 'JIRA_GET_ISSUE_STATUS_BY_PROJECT',
  GET_ISSUE_FIELDS_BY_ISSUE_TYPE_ID = 'JIRA_GET_ISSUE_FIELDS_BY_ISSUE_TYPE_ID',
  UPDATE_ISSUE = 'JIRA_UPDATE_ISSUE',
  GET_ISSUE_STATUSES = 'JIRA_GET_ISSUE_STATUSES',
  GET_ISSUE_EDIT_METADATA = 'JIRA_GET_ISSUE_EDIT_METADATA',
  SEARCH_BY_JQL = 'JIRA_SEARCH_BY_JQL',
  FILTER_ISSUES = 'JIRA_FILTER_ISSUES',
  QUERY_PROJECTS = 'JIRA_QUERY_PROJECTS',
  GET_FIELD_META_DATA = 'JIRA_GET_FIELD_META_DATA',
  GET_ISSUE_TYPES = 'JIRA_GET_ISSUE_TYPES',
  GET_ALL_ISSUE_FIELDS = 'JIRA_GET_ALL_ISSUE_FIELDS',
  GET_ALL_ASSIGNEES_BY_PROJECT = 'JIRA_GET_ALL_ASSIGNEES_BY_PROJECT',
  GET_EPIC_ISSUES_BY_PROJECT = 'JIRA_GET_EPIC_ISSUES_BY_PROJECT',

  TRIGGER_ISSUE_CREATED = 'JIRA_TRIGGER_ISSUE_CREATED',
  TRIGGER_ISSUE_UPDATED = 'JIRA_TRIGGER_ISSUE_UPDATED',
  TRIGGER_COMMENT_CREATED = 'JIRA_TRIGGER_COMMENT_CREATED',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ISSUE_BY_KEY;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_ISSUE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROJECTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ISSUE_TYPES_BY_PROJECT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ISSUE_STATUS_BY_PROJECT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ISSUE_FIELDS_BY_ISSUE_TYPE_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_ISSUE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ISSUE_STATUSES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ISSUE_EDIT_METADATA;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_BY_JQL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FILTER_ISSUES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.QUERY_PROJECTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.GET_EPIC_ISSUES_BY_PROJECT; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_FIELD_META_DATA; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_ISSUE_TYPES; actionParameters: KeyedSource<DataType.ANY>[] }
    | { intent: Intent.GET_ALL_ASSIGNEES_BY_PROJECT; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: Intent.TRIGGER_ISSUE_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ISSUE_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ALL_ISSUE_FIELDS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_COMMENT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  JIRA_CLOUD_ID: string;
  JIRA_OAUTH_REFRESH_TOKEN: string;
  JIRA_OAUTH_ACCESS_TOKEN: string;
  SCOPES?: string;
};
