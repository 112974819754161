import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ResolvedMapParameters, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { MapStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import { getRequiredStepIdsForSource, resolveSources } from './resolvers.utils';

/**
 * a resolver for a map step's variables
 *
 * @export
 * @class MapStepResolver
 * @extends {StepResolver<MapStep, ResolvedMapParameters>}
 */
export class MapStepResolver extends StepResolver<MapStep, ResolvedMapParameters> {
  /**
   * resolves the input parameters for the step
   *
   * @param {MapStep['parameters']} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedMapParameters}
   * @memberof MapStepResolver
   */
  resolveInputParameters(
    parameters: MapStep['parameters'],
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedMapParameters {
    const resolved: ResolvedMapParameters = resolveSources(
      variables,
      parameters,
      secrets,
      cachedConnectCredential,
      context,
      cachedPersona,
      'iterator',
    );

    return resolved;
  }

  getRequiredStepIdsForVariableResolution(parameters: MapStep['parameters']): string[] {
    return getRequiredStepIdsForSource(parameters.iterator);
  }
}
