import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { UserRole } from '../../shared/types';

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};
export const emailInput: SidebarInput = {
  id: 'email',
  title: 'Email',
  type: SidebarInputType.TextArea,
  placeholder: 'jane@example.com',
  lines: 1,
  required: false,
};
export const phoneInput: SidebarInput = {
  id: 'phone',
  title: 'Phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const roleInput: SidebarInput = {
  id: 'role',
  title: 'Role',
  type: SidebarInputType.Enum,
  getValues: () => [
    {
      label: 'Admin',
      value: UserRole.ADMIN,
    },
    {
      label: 'Agent',
      value: UserRole.AGENT,
    },
    {
      label: 'End User',
      value: UserRole.END_USER,
    },
  ],
  required: false,
};

export const externalIdInput: SidebarInput = {
  id: 'externalId',
  title: 'External ID',
  subtitle: 'A unique identifier from another system.',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const detailsInput: SidebarInput = {
  id: 'details',
  title: 'Details',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
};
export const notesInput: SidebarInput = {
  id: 'notes',
  title: 'Notes',
  type: SidebarInputType.TextArea,
  lines: 3,
  required: false,
};

export const userIdInput: SidebarInput = {
  id: 'userId',
  title: 'User ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};
