import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.OUTREACH;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'OUTREACH_TEST_CONNECTION',
  CREATE_ACCOUNT = 'OUTREACH_CREATE_ACCOUNT',
  UPDATE_ACCOUNT = 'OUTREACH_UPDATE_ACCOUNT',
  GET_ACCOUNTS = 'OUTREACH_GET_ACCOUNTS',
  GET_ACCOUNT_BY_ID = 'OUTREACH_GET_ACCOUNT_BY_ID',
  CREATE_OPPORTUNITY = 'OUTREACH_CREATE_OPPORTUNITY',
  UPDATE_OPPORTUNITY = 'OUTREACH_UPDATE_OPPORTUNITY',
  GET_OPPORTUNITIES = 'OUTREACH_GET_OPPORTUNITIES',
  GET_OPPORTUNITY_BY_ID = 'OUTREACH_GET_OPPORTUNITY_BY_ID',
  CREATE_PROSPECT = 'OUTREACH_CREATE_PROSPECT',
  UPDATE_PROSPECT = 'OUTREACH_UPDATE_PROSPECT',
  GET_PROSPECTS = 'OUTREACH_GET_PROSPECTS',
  GET_PROSPECT_BY_ID = 'OUTREACH_GET_PROSPECT_BY_ID',
  ADD_PROSPECTS_TO_SEQUENCE = 'OUTREACH_ADD_PROSPECT_TO_SEQUENCE',
  GET_OPPORTUNITY_STAGES = 'OUTREACH_GET_OPPORTUNITY_STAGES',
  GET_SEQUENCES = 'OUTREACH_GET_SEQUENCES',
  // triggers
  TRIGGER_ACCOUNT_CREATED = 'OUTREACH_TRIGGER_ACCOUNT_CREATED',
  TRIGGER_ACCOUNT_UPDATED = 'OUTREACH_TRIGGER_ACCOUNT_UPDATED',
  TRIGGER_OPPORTUNITY_CREATED = 'OUTREACH_TRIGGER_OPPORTUNITY_CREATED',
  TRIGGER_OPPORTUNITY_UPDATED = 'OUTREACH_TRIGGER_OPPORTUNITY_UPDATED',
  TRIGGER_PROSPECT_CREATED = 'OUTREACH_TRIGGER_PROSPECT_CREATED',
  TRIGGER_PROSPECT_UPDATED = 'OUTREACH_TRIGGER_PROSPECT_UPDATED',
}

export const TriggerIntents = [
  Intent.TRIGGER_ACCOUNT_CREATED,
  Intent.TRIGGER_ACCOUNT_UPDATED,
  Intent.TRIGGER_OPPORTUNITY_CREATED,
  Intent.TRIGGER_OPPORTUNITY_UPDATED,
  Intent.TRIGGER_PROSPECT_CREATED,
  Intent.TRIGGER_PROSPECT_UPDATED,
];

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_ACCOUNT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_ACCOUNT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ACCOUNTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ACCOUNT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_OPPORTUNITY;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_OPPORTUNITY;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_OPPORTUNITY_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_PROSPECT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_PROSPECT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_OPPORTUNITIES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROSPECTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROSPECT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_PROSPECTS_TO_SEQUENCE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_OPPORTUNITY_STAGES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_SEQUENCES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ACCOUNT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_ACCOUNT_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_OPPORTUNITY_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_OPPORTUNITY_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_PROSPECT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_PROSPECT_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  OUTREACH_REFRESH_TOKEN: string;
  OUTREACH_ACCESS_TOKEN: string;
  OUTREACH_SCOPES?: string;
  userId: string;
};
