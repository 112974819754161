import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SHAREPOINT;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SHAREPOINT_TEST_CONNECTION',
  CREATE_ITEM = 'SHAREPOINT_CREATE_ITEM',
  UPDATE_ITEM = 'SHAREPOINT_UPDATE_ITEM',
  GET_ITEM_BY_ID = 'SHAREPOINT_GET_ITEM_BY_ID',
  GET_ITEMS_IN_A_LIST = 'SHAREPOINT_GET_ITEMS_IN_A_LIST',
  DELETE_ITEM = 'SHAREPOINT_DELETE_ITEM',
  CREATE_LIST = 'SHAREPOINT_CREATE_LIST',
  GET_LIST_BY_ID = 'SHAREPOINT_GET_LIST_BY_ID',
  GET_LISTS = 'SHAREPOINT_GET_LISTS',
  CREATE_LIST_COLUMN = 'SHAREPOINT_CREATE_LIST_COLUMN',
  GET_LIST_COLUMNS = 'SHAREPOINT_GET_LIST_COLUMNS',
  GET_SITES = 'SHAREPOINT_GET_SITES',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ITEM_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ITEMS_IN_A_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_ITEM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LIST_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LISTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_LIST_COLUMN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LIST_COLUMNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_SITES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  CLIENT_ID = 'SHAREPOINT_CLIENT_ID',
  CLIENT_SECRET = 'SHAREPOINT_CLIENT_SECRET',
  ACCESS_TOKEN = 'SHAREPOINT_ACCESS_TOKEN',
  REFRESH_TOKEN = 'SHAREPOINT_REFRESH_TOKEN',
  SCOPES = 'SHAREPOINT_SCOPES',
}

export type Credentials = {
  userId: string;
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
  [CredentialKeys.SCOPES]?: string;
  siteId: string;
  redirectUrl: string;
};
