import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 *
 * @param condition
 * @returns condition to filter for oracle
 */

export function joinOrConditions(conditions: string[]): string {
  return conditions
    .map((condition: string) => (condition.includes(' and ') ? `(${condition})` : condition))
    .join(' or ');
}

export default function conditionsToFilterExpression(condition: ResolvedConditionWrapper): string {
  if (condition.type === 'JOIN') {
    const conditions = condition.conditions.map((condition: ResolvedConditionWrapper) => {
      return conditionsToFilterExpression(condition);
    });
    return condition.join === 'OR' ? joinOrConditions(conditions) : conditions.join(' and ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;
    switch (innerCondition.operator) {
      case Operator.NumberEquals:
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} = ${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} > ${new Date(innerCondition.argument).toISOString()}`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} < ${new Date(innerCondition.argument).toISOString()}`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} = ${new Date(innerCondition.argument).toISOString()}`;
      case Operator.NumberLessThan:
        return `${innerCondition.variable} < ${innerCondition.argument}`;
      case Operator.NumberGreaterThan:
        return `${innerCondition.variable} > ${innerCondition.argument}`;
      case Operator.NumberDoesNotEqual:
        return `${innerCondition.variable} <> ${innerCondition.argument}`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `${innerCondition.variable} >= ${innerCondition.argument}`;
      case Operator.NumberLessThanOrEqualTo:
        return `${innerCondition.variable} <= ${innerCondition.argument}`;
      case Operator.StringContains:
        return `UPPER(${innerCondition.variable}) LIKE UPPER('%${innerCondition.argument}%')`;
      case Operator.StringDoesNotContain:
        return `UPPER(${innerCondition.variable}) NOT LIKE UPPER('%${innerCondition.argument}%')`;
      case Operator.StringStartsWith:
        return `${innerCondition.variable} LIKE '${innerCondition.argument}%'`;
      case Operator.StringEndsWith:
        return `${innerCondition.variable} LIKE '%${innerCondition.argument}'`;
      case Operator.StringIsIn:
        return `${innerCondition.variable} IN (${innerCondition.argument
          .map((argument: string) => `'${argument}'`)
          .join(',')})`;
      case Operator.StringIsNotIn:
        return `${innerCondition.variable} NOT IN (${innerCondition.argument
          .map((argument: string) => `'${argument}'`)
          .join(',')})`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable} = true`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} = false`;
      default:
        throw new Error(
          `${innerCondition.operator} operator not supported for Oracle Financials Cloud`,
        );
    }
  }
  return '';
}
