import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SAGE_INTACCT;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SAGE_INTACCT_TEST_CONNECTION',
  CREATE_VENDOR = 'SAGE_INTACCT_CREATE_VENDOR',
  UPDATE_VENDOR = 'SAGE_INTACCT_UPDATE_VENDOR',
  DELETE_VENDOR = 'SAGE_INTACCT_DELETE_VENDOR',
  SEARCH_VENDORS = 'SAGE_INTACCT_SEARCH_VENDORS',
  GET_VENDOR_BY_ID = 'SAGE_INTACCT_GET_VENDOR_BY_ID',
  SEARCH_VENDOR_TYPES = 'SAGE_INTACCT_SEARCH_VENDOR_TYPES',
  CREATE_BILL = 'SAGE_INTACCT_CREATE_BILL',
  UPDATE_BILL = 'SAGE_INTACCT_UPDATE_BILL',
  DELETE_BILL = 'SAGE_INTACCT_DELETE_BILL',
  SEARCH_BILLS = 'SAGE_INTACCT_SEARCH_BILLS',
  GET_BILL_BY_ID = 'SAGE_INTACCT_GET_BILLS_BY_ID',
  SEARCH_PAYMENT_TERMS = 'SAGE_INTACCT_SEARCH_PAYMENT_TERMS',
  GET_PAYMENT_TERM_BY_ID = 'SAGE_INTACCT_GET_PAYMENT_TERM_BY_ID',
  SEARCH_ACCOUNTS = 'SAGE_INTACCT_SEARCH_ACCOUNTS',
  GET_ACCOUNT_BY_ID = 'SAGE_INTACCT_GET_ACCOUNT_BY_ID',
  GET_DIMENSIONS = 'SAGE_INTACCT_GET_DIMENSIONS',
  GET_VENDOR_TYPES = 'SAGE_INTACCT_GET_VENDOR_TYPES',
  GET_PAYMENT_TERM = 'SAGE_INTACCT_PAYMENT_TERM',
  GET_GENERAL_LEDGER_ACCOUNT = 'SAGE_INTACCT_GET_GENERAL_LEDGER_ACCOUNT',
  GET_ENTITIES = 'SAGE_INTACCT_GET_ENTITIES',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_VENDOR;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_VENDORS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_VENDOR_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_VENDOR_TYPES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_BILL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_BILL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_BILL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_BILLS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_BILL_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_PAYMENT_TERMS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PAYMENT_TERM_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEARCH_ACCOUNTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ACCOUNT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_DIMENSIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_VENDOR_TYPES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_GENERAL_LEDGER_ACCOUNT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PAYMENT_TERM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_ENTITIES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  COMPANY_ID = 'SAGE_INTACCT_COMPANY_ID',
  USER_ID = 'SAGE_INTACCT_USER_ID',
  USER_PASSWORD = 'SAGE_INTACCT_USER_PASSWORD',
  SESSION_ID = 'SAGE_INTACCT_SESSION_ID',
  SESSION_ENDPOINT = 'SAGE_INTACCT_SESSION_ENDPOINT',
}

export type Credentials = {
  [CredentialKeys.COMPANY_ID]: string;
  [CredentialKeys.USER_ID]: string;
  [CredentialKeys.USER_PASSWORD]: string;
  [CredentialKeys.SESSION_ID]?: string;
  [CredentialKeys.SESSION_ENDPOINT]?: string;
};

export enum EnvironmentKeys {
  SENDER_ID = 'SAGE_INTACCT_SENDER_ID',
  SENDER_PASSWORD = 'SAGE_INTACCT_SENDER_PASSWORD',
}

export type Environment = {
  [EnvironmentKeys.SENDER_ID]: string;
  [EnvironmentKeys.SENDER_PASSWORD]: string;
};
