import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'applications:read:admin'
  | 'opportunities:read:admin'
  | 'opportunities:write:admin'
  | 'contact:read:admin'
  | 'contact:write:admin'
  | 'postings:read:admin'
  | 'postings:write:admin'
  | 'webhooks:write:admin'
  | 'webhooks:read:admin'
  | 'offline_access';

export const scopes: Record<ScopeName, ScopeValue> = {
  'applications:read:admin': {
    label: 'applications:read:admin',
    name: 'applications:read:admin',
    description: 'View all opportunity applications',
    required: true,
  },
  'opportunities:read:admin': {
    label: 'opportunities:read:admin',
    name: 'opportunities:read:admin',
    description: 'View all of an opportunities',
    required: true,
  },
  'opportunities:write:admin': {
    label: 'opportunities:write:admin',
    name: 'opportunities:write:admin',
    description: 'View and manage all of an opportunities',
    required: true,
  },
  'contact:read:admin': {
    label: 'contact:read:admin',
    name: 'contact:read:admin',
    description: `View an opportunity's contact`,
    required: true,
  },
  'contact:write:admin': {
    label: 'contact:write:admin',
    name: 'contact:write:admin',
    description: `View and manage an opportunity's contact`,
    required: true,
  },
  'postings:read:admin': {
    label: 'postings:read:admin',
    name: 'postings:read:admin',
    description: 'View all postings',
    required: true,
  },
  'postings:write:admin': {
    label: 'postings:write:admin',
    name: 'postings:write:admin',
    description: 'View and manage all postings',
    required: true,
  },
  'webhooks:read:admin': {
    label: 'webhooks:read:admin',
    name: 'webhooks:read:admin',
    description: 'View all webhooks',
    required: true,
  },
  'webhooks:write:admin': {
    label: 'webhooks:write:admin',
    name: 'webhooks:write:admin',
    description: 'View and manage all webhooks',
    required: true,
  },
  offline_access: {
    label: 'offline_access',
    name: 'offline_access',
    description:
      'Include this scope in the authorize call in order to get a refresh token during the token exchange.',
    required: true,
  },
};

export const requiredScopes: ScopeName[] = [
  'offline_access',
  'applications:read:admin',
  'opportunities:write:admin',
  'postings:write:admin',
  'webhooks:write:admin',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Lever',
  description: 'Sync applications and candidates from Lever',
  authUrl: 'https://auth.lever.co/authorize',
  accessTokenUrl: 'https://auth.lever.co/oauth/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/lever`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
    {
      type: SidebarInputType.ValueText,
      title: 'Sandbox Client ID',
      id: 'sandboxClientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/lever`,
      documentationLinkLabel: 'How to obtain a Sandbox Client ID?',
      placeholder: 'Optional',
      required: false,
    },
    {
      type: SidebarInputType.ValueText,
      title: 'Sandbox Client Secret',
      id: 'sandboxClientSecret',
      placeholder: 'Optional',
      required: false,
    },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/lever',
};
