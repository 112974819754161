import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.TWILIO;
  sidebarSections: (SidebarSection | ((parameters: ActionStepParameters) => SidebarSection))[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  SEND_SMS = 'TWILIO_SEND_SMS',
  FETCH_ALL_NUMBERS = 'TWILIO_FETCH_ALL_NUMBERS',
  MOCK = 'TWILIO_MOCK_ACTION',
  TEST_CONNECTION = 'TWILIO_TEST_CONNECTION',
}

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent. SendSMSParameters should be
// defined in this file.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.SEND_SMS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FETCH_ALL_NUMBERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | { intent: Intent.MOCK; actionParameters: KeyedSource<DataType.ANY>[] }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  TWILIO_ACCOUNT_SID: string;
  TWILIO_AUTH_TOKEN: string;
};
