import { IWorkflowTag } from '@shared/entities/sdk/workflowTag/workflowTag.interface';

import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<IWorkflowTag> = {
  entities: {},
  processing: false,
};

export function handler(
  state: EntitiesState<IWorkflowTag> = initialState,
  action: Action,
): EntitiesState<IWorkflowTag> {
  switch (action.type) {
    case 'WORKFLOW_TAG_FETCH_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: action.payload,
      };
    case 'WORKFLOW_TAG_CREATION_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: { ...state.entities, [action.payload.id]: action.payload },
      };
    case 'WORKFLOW_TAG_CREATION_FAILED':
    case 'WORKFLOW_TAG_FETCH_FAILED':
      return { ...state, processing: false };
    case 'WORKFLOW_TAG_FETCH_START':
    case 'WORKFLOW_TAG_CREATION_START':
      return {
        ...state,
        processing: true,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
