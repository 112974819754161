import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const getPages: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'pages',
  title: 'Page',
  subtitle: 'A Facebook page managed by a business account',
  refreshDependencies: [(options: ActionStepParameters) => options.credentials[0]],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((page: any) => ({
          label: `${page.name}`,
          value: `${page.id}`,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.FACEBOOK_ADS,
      intent: Intent.GET_PAGES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getPixels: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'pixels',
  title: 'Pixel',
  subtitle: 'A Facebook pixel to send events for',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((pixel: any) => ({
          label: `${pixel.name}`,
          value: `${pixel.id}`,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.FACEBOOK_ADS,
      intent: Intent.GET_PIXELS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getCampaigns: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'campaigns',
  title: 'Campaign',
  subtitle: 'Ads account to manage ads in',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response?.result
      ? response.result.output.map((campaign: any) => ({
          label: `${campaign.name}`,
          value: `${campaign.id}`,
        }))
      : [];
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.FACEBOOK_ADS,
      intent: Intent.GET_CAMPAIGNS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};
