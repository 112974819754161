import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.FIREBASE;
  sidebarSections: (SidebarSection | ((parameters: ActionStepParameters) => SidebarSection))[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'FIREBASE_TEST_CONNECTION',
  FIRESTORE_GET_USER_BY_UID = 'FIREBASE_FIRESTORE_GET_USER_BY_UID',
  FIRESTORE_GET_USER_BY_EMAIL = 'FIREBASE_FIRESTORE_GET_USER_BY_EMAIL',
  FIRESTORE_LIST_USERS = 'FIREBASE_FIRESTORE_LIST_USERS',
  FIRESTORE_CREATE_USER = 'FIREBASE_FIRESTORE_CREATE_USER',
  FIRESTORE_UPDATE_USER = 'FIREBASE_FIRESTORE_UPDATE_USER',
  FIRESTORE_DELETE_USER = 'FIREBASE_FIRESTORE_DELETE_USER',
  FIRESTORE_LIST_COLLECTIONS = 'FIREBASE_FIRESTORE_LIST_COLLECTIONS',
  FIRESTORE_QUERY_DOCUMENTS = 'FIREBASE_FIRESTORE_QUERY_DOCUMENTS',
  FIRESTORE_INSERT_DOCUMENT = 'FIREBASE_FIRESTORE_INSERT_DOCUMENT',
  FIRESTORE_UPDATE_DOCUMENT = 'FIREBASE_FIRESTORE_UPDATE_DOCUMENT',
  FIRESTORE_GET_DOCUMENT_BY_ID = 'FIREBASE_FIRESTORE_GET_DOCUMENT_BY_ID',
  FIRESTORE_DELETE_DOCUMENT_BY_ID = 'FIREBASE_FIRESTORE_DELETE_DOCUMENT_BY_ID',
}

// TODO: actionParameters should specify *which* KeyedSources
// are allowed based on intent.
export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_GET_USER_BY_UID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_GET_USER_BY_EMAIL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_LIST_USERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_CREATE_USER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_UPDATE_USER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_DELETE_USER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_LIST_COLLECTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_QUERY_DOCUMENTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_INSERT_DOCUMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_UPDATE_DOCUMENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_GET_DOCUMENT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.FIRESTORE_DELETE_DOCUMENT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  FIREBASE_DATABASE_URL: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_SERVICE_ACCOUNT: string;
};
