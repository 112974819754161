import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.SERVICENOW;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'SERVICENOW_TEST_CONNECTION',
  SERVICENOW_GET_TICKET_BY_ID = 'SERVICENOW_GET_TICKET_BY_ID',
  SERVICENOW_CREATE_TICKET = 'SERVICENOW_CREATE_TICKET',
  SERVICENOW_UPDATE_TICKET = 'SERVICENOW_UPDATE_TICKET',
}

type IntentMap =
  | Intent.TEST_CONNECTION
  | ConnectIntent.GET_PROVIDER_ID
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | Intent.SERVICENOW_GET_TICKET_BY_ID
  | Intent.SERVICENOW_CREATE_TICKET
  | Intent.SERVICENOW_UPDATE_TICKET;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  SUBDOMAIN = 'SERVICENOW_SUBDOMAIN',
  USERNAME = 'SERVICENOW_USERNAME',
  PASSWORD = 'SERVICENOW_PASSWORD',
}

export type Credentials = {
  [CredentialKeys.SUBDOMAIN]: string;
  [CredentialKeys.USERNAME]: string;
  [CredentialKeys.PASSWORD]: string;
};
