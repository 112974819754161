import { ResolvedCronParameters } from '@shared/types/sdk/resolvers';
import { CronStep, StepType, Unit, Weekday } from '@shared/types/sdk/steps';

import { isNumberType, isValueWithinRange } from '../workflow.utils';

import { StepValidator } from './abstract.validator';

/**
 * validates that a cron step is properly configured
 *
 * @export
 * @class CronStepValidator
 * @extends {StepValidator<CronStep, ResolvedCronParameters>}
 */
export default class CronStepValidator extends StepValidator<CronStep, ResolvedCronParameters> {
  /**
   * the step type that the validator validates
   *
   * @type {StepType}
   * @memberof CronStepValidator
   */
  stepType: CronStep['type'] = StepType.CRON;

  /**
   * validates that a step is valid
   *
   * @param {CronStep} step
   * @returns {CronStep}
   * @memberof CronStepValidator
   */
  validate(step: CronStep): CronStep {
    const schedule = step.parameters.schedule;
    switch (schedule.unit) {
      case Unit.WEEKLY:
        if (!Object.values(Weekday).includes(schedule.weekday)) {
          throw new Error(`${schedule.weekday} is not valid weekday.`);
        } else if (!isNumberType(schedule.time.minutes)) {
          throw new Error('You cannot specify minutes as non-number type.');
        } else if (!schedule.time.timezone) {
          throw new Error('You must specify timezone for this step.');
        }
        break;
      case Unit.DAILY:
        if (!schedule.days) {
          throw new Error('You must specify days for this step.');
        } else if (!isNumberType(schedule.days)) {
          throw new Error('You cannot specify days as non-number type.');
        } else if (!isNumberType(schedule.time.minutes)) {
          throw new Error('You cannot specify minutes as non-number type.');
        } else if (!schedule.time.timezone) {
          throw new Error('You must specify timezone for this step.');
        }
        break;
      case Unit.HOURLY:
        if (!isNumberType(schedule.hours)) {
          throw new Error('You cannot specify hours as non-number type.');
        } else if (!isNumberType(schedule.offset)) {
          throw new Error('You cannot specify offset as non-number type.');
        } else if (!isValueWithinRange(schedule.hours, 1, 24)) {
          throw new Error('hours value must be between 1 and 24');
        }
        //this range is taken from frontend input field
        else if (!isValueWithinRange(schedule.offset, 0, 55)) {
          throw new Error('offset value must be between 0 and 55');
        }
        break;
      case Unit.MINUTES:
        if (!isNumberType(schedule.minutes)) {
          throw new Error('You cannot specify minutes as non-number type.');
        } else if (schedule.minutes <= 0) {
          throw new Error('You cannot specify a negative value for minutes.');
        }
        break;
      case Unit.SECONDS:
        if (!isNumberType(schedule.seconds)) {
          throw new Error('You cannot specify seconds as non-number type.');
        } else if (schedule.seconds <= 0) {
          throw new Error('You cannot specify a negative value for seconds.');
        }
        break;
      default:
        throw new Error('Invalid schedule added for Cron Trigger Step.');
    }

    return step;
  }
}
