import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GREENHOUSE;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'GREENHOUSE_TEST_CONNECTION',

  CREATE_CANDIDATE = 'GREENHOUSE_CREATE_CANDIDATE',
  UPDATE_CANDIDATE = 'GREENHOUSE_UPDATE_CANDIDATE',
  GET_CANDIDATE_BY_ID = 'GREENHOUSE_GET_CANDIDIDATE_BY_ID',
  DELETE_CANDIDATE = 'GREENHOUSE_DELETE_CANDIDATE',

  CREATE_JOB_OPENING = 'GREENHOUSE_CREATE_JOB_OPENING',
  UPDATE_JOB_OPENING = 'GREENHOUSE_UPDATE_JOB_OPENING',
  GET_JOB_OPENING_BY_ID = 'GREENHOUSE_GET_JOB_OPENING_BY_ID',

  CREATE_APPLICATION = 'GREENHOUSE_CREATE_APPLICATION',
  UPDATE_APPLICATION = 'GREENHOUSE_UPDATE_APPLICATION',
  GET_APPLICATION_BY_ID = 'GREENHOUSE_GET_APPLICATION_BY_ID',
  DELETE_APPLICATION = 'GREENHOUSE_DELETE_APPLICATION',

  GET_USERS = 'GREENHOUSE_GET_USERS',
}

type IntentMap =
  | Intent.TEST_CONNECTION
  | Intent.CREATE_APPLICATION
  | Intent.CREATE_CANDIDATE
  | Intent.CREATE_JOB_OPENING
  | Intent.DELETE_APPLICATION
  | Intent.DELETE_CANDIDATE
  | Intent.GET_APPLICATION_BY_ID
  | Intent.GET_CANDIDATE_BY_ID
  | Intent.GET_JOB_OPENING_BY_ID
  | Intent.UPDATE_APPLICATION
  | Intent.UPDATE_CANDIDATE
  | Intent.UPDATE_JOB_OPENING
  | Intent.GET_USERS
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  GREENHOUSE_API_KEY = 'GREENHOUSE_API_KEY',
  GREENHOUSE_USER_ID = 'GREENHOUSE_USER_ID',
}

export type Credentials = {
  [CredentialKeys.GREENHOUSE_API_KEY]: string;
  [CredentialKeys.GREENHOUSE_USER_ID]: string;
};
