import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Event Types',
          items: [
            {
              value: Intent.GET_EVENT_TYPES,
              label: 'Get Event Type Details',
            },
            {
              value: Intent.GET_AVAILABLE_TIMES_FOR_EVENT_TYPE,
              label: 'Get Available Times for Event Type',
            },
          ],
        },
        {
          title: 'Event',
          items: [
            {
              value: Intent.SEARCH_EVENTS,
              label: 'Search Events',
            },
            {
              value: Intent.GET_EVENT_BY_ID,
              label: 'Get Event by ID',
            },
            {
              value: Intent.GET_EVENT_INVITEES,
              label: 'Get Event Invitees',
            },
            {
              value: Intent.CANCEL_EVENT,
              label: 'Cancel Event',
            },
          ],
        },
      ],
    },
  ],
};

export const uuidInput: SidebarInput = {
  id: 'uuid',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Event UUID',
  required: true,
  subtitle: 'Specify the Event UUID of the event type to fetch.',
  placeholder: `23dd4622-4dbe-42ad-bee0-1a8092e1772a`,
};

export const eventTypeInput: SidebarInput = {
  id: 'event_type',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Event Type',
  required: true,
  subtitle: 'Specify the event type to fetch.',
  placeholder: 'https://api.calendly.com/event_types/AAAAAAAAAAAAAAAA',
};

export const startTimeInput: SidebarInput = {
  id: 'start_time',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Start Time',
  required: true,
  placeholder: `2022-01-02T24:00:00.000000Z`,
  subtitle: 'Specify a start time for the event availability to fetch.',
};

export const endTimeInput: SidebarInput = {
  id: 'end_time',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'End Time',
  required: true,
  placeholder: `2022-01-07T24:00:00.000000Z`,
  subtitle: 'Specify an end time for the event availability to fetch.',
};

export const searchFilterInput: SidebarInput = {
  id: 'searchFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for tasks that match specified filters.',
  type: SidebarInputType.Conditional,
  required: true,
  supportedOperators: [Operator.StringExactlyMatches],
  getValues: () => [
    { label: 'Invitee Email', value: 'invitee_email' },
    { label: 'Organization', value: 'organization' },
    { label: 'Status', value: 'status' },
  ],
};

export const inviteeEmailInput: SidebarInput = {
  id: 'invitee_email',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Invitee Email',
  required: false,
  placeholder: `cloud@useparagon.com`,
  subtitle: 'Invitee Email',
};

export const organizationInput: SidebarInput = {
  id: 'organization',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Organization',
  required: true,
  placeholder: 'https://api.calendly.com/organizations/BBBBBBBBBBBBB',
  subtitle: 'Organization',
};

export const userInput: SidebarInput = {
  id: 'user',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'User',
  required: true,
  placeholder: 'https://api.calendly.com/users/BBBBBBBBBBBBB',
  subtitle: 'User',
};

export const scopeInput: SidebarInput = {
  id: 'scope',
  type: SidebarInputType.Enum,
  title: 'Scope',
  required: true,
  defaultValue: 'user',
  getValues: () => [
    { value: 'organization', label: 'Organization' },
    { value: 'user', label: 'User' },
  ],
  subtitle:
    'Specify whether to only trigger for newly created invitees for all events that are associated with this user or for all newly scheduled events in the organization.',
};

export const statusInput: SidebarInput = {
  id: 'status',
  type: SidebarInputType.Enum,
  title: 'Status',
  required: false,
  getValues: () => [
    { value: 'active', label: 'Active' },
    { value: 'canceled', label: 'Canceled' },
  ],
  subtitle: 'Status',
};

export const reasonInput: SidebarInput = {
  id: 'reason',
  type: SidebarInputType.TextArea,
  lines: 2,
  title: 'Reason',
  required: false,
  subtitle: 'Specify a reason for the cancellation of this event.',
};
