import { Currency } from './types';

export const currency: Currency[] = [
  { value: 'AED', label: 'UAE Dirham' },
  { value: 'AFN', label: 'Afghani' },
  { value: 'ALL', label: 'Lek' },
  { value: 'AMD', label: 'Armenian Dram' },
  { value: 'ANG', label: 'Netherlands Antillean Guilder' },
  { value: 'AOA', label: 'Kwanza' },
  { value: 'ARS', label: 'Argentine Peso' },
  { value: 'AUD', label: 'Australian Dollar' },
  { value: 'AWG', label: 'Aruban Florin' },
  { value: 'AZN', label: 'Azerbaijanian Manat' },
  { value: 'BAM', label: 'Convertible Mark' },
  { value: 'BBD', label: 'Barbados Dollar' },
  { value: 'BDT', label: 'Taka' },
  { value: 'BGN', label: 'Bulgarian Lev' },
  { value: 'BHD', label: 'Bahraini Dinar' },
  { value: 'BIF', label: 'Burundi Franc' },
  { value: 'BMD', label: 'Bermudian Dollar' },
  { value: 'BND', label: 'Brunei Dollar' },
  { value: 'BOB', label: 'Boliviano' },
  { value: 'BRL', label: 'Brazilian Real' },
  { value: 'BSD', label: 'Bahamian Dollar' },
  { value: 'BTC', label: 'Bitcoin' },
  { value: 'BTN', label: 'Ngultrum' },
  { value: 'BWP', label: 'Pula' },
  { value: 'BYR', label: 'Belarussian Ruble' },
  { value: 'BZD', label: 'Belize Dollar' },
  { value: 'CAD', label: 'Canadian Dollar' },
  { value: 'CDF', label: 'Congolese Franc' },
  { value: 'CHF', label: 'Swiss Franc' },
  { value: 'CLP', label: 'Chilean Peso' },
  { value: 'CNY', label: 'Yuan Renminbi' },
  { value: 'COP', label: 'Colombian Peso' },
  { value: 'CRC', label: 'Costa Rican Colon' },
  { value: 'CUP', label: 'Cuban Peso' },
  { value: 'CVE', label: 'Cabo Verde Escudo' },
  { value: 'CZK', label: 'Czech Koruna' },
  { value: 'DJF', label: 'Djibouti Franc' },
  { value: 'DKK', label: 'Danish Krone' },
  { value: 'DOP', label: 'Dominican Peso' },
  { value: 'DZD', label: 'Algerian Dinar' },
  { value: 'EGP', label: 'Egyptian Pound' },
  { value: 'ERN', label: 'Nakfa' },
  { value: 'ETB', label: 'Ethiopian Birr' },
  { value: 'EUR', label: 'Euro' },
  { value: 'FJD', label: 'Fiji Dollar' },
  { value: 'FKP', label: 'Falkland Islands Pound' },
  { value: 'GBP', label: 'Pound Sterling' },
  { value: 'GEL', label: 'Lari' },
  { value: 'GHS', label: 'Ghana Cedi' },
  { value: 'GIP', label: 'Gibraltar Pound' },
  { value: 'GMD', label: 'Dalasi' },
  { value: 'GNF', label: 'Guinea Franc' },
  { value: 'GTQ', label: 'Quetzal' },
  { value: 'GYD', label: 'Guyana Dollar' },
  { value: 'HKD', label: 'Hong Kong Dollar' },
  { value: 'HNL', label: 'Lempira' },
  { value: 'HRK', label: 'Kuna' },
  { value: 'HTG', label: 'Gourde' },
  { value: 'HUF', label: 'Forint' },
  { value: 'IDR', label: 'Rupiah' },
  { value: 'ILS', label: 'New Israeli Sheqel' },
  { value: 'INR', label: 'Indian Rupee' },
  { value: 'IQD', label: 'Iraqi Dinar' },
  { value: 'IRR', label: 'Iranian Rial' },
  { value: 'ISK', label: 'Iceland Krona' },
  { value: 'JMD', label: 'Jamaican Dollar' },
  { value: 'JOD', label: 'Jordanian Dinar' },
  { value: 'JPY', label: 'Yen' },
  { value: 'KES', label: 'Kenyan Shilling' },
  { value: 'KHR', label: 'Riel' },
  { value: 'KMF', label: 'Comoro Franc' },
  { value: 'KPW', label: 'North Korean Won' },
  { value: 'KRW', label: 'Won' },
  { value: 'KWD', label: 'Kuwaiti Dinar' },
  { value: 'KYD', label: 'Cayman Islands Dollar' },
  { value: 'KZT', label: 'Tenge' },
  { value: 'LAK', label: 'Kip' },
  { value: 'LBP', label: 'Lebanese Pound' },
  { value: 'LKR', label: 'Sri Lanka Rupee' },
  { value: 'LRD', label: 'Liberian Dollar' },
  { value: 'LSL', label: 'Loti' },
  { value: 'LTL', label: 'Lithuanian Litas' },
  { value: 'LVL', label: 'Latvian Lats' },
  { value: 'LYD', label: 'Libyan Dinar' },
  { value: 'MAD', label: 'Moroccan Dirham' },
  { value: 'MDL', label: 'Moldovan Leu' },
  { value: 'MGA', label: 'Malagasy Ariary' },
  { value: 'MKD', label: 'Denar' },
  { value: 'MMK', label: 'Kyat' },
  { value: 'MNT', label: 'Tugrik' },
  { value: 'MOP', label: 'Pataca' },
  { value: 'MRO', label: 'Ouguiya' },
  { value: 'MUR', label: 'Mauritius Rupee' },
  { value: 'MVR', label: 'Rufiyaa' },
  { value: 'MWK', label: 'Kwacha' },
  { value: 'MXN', label: 'Mexican Peso' },
  { value: 'MYR', label: 'Malaysian Ringgit' },
  { value: 'MZN', label: 'Mozambique Metical' },
  { value: 'NAD', label: 'Namibia Dollar' },
  { value: 'NGN', label: 'Naira' },
  { value: 'NIO', label: 'Cordoba Oro' },
  { value: 'NOK', label: 'Norwegian Krone' },
  { value: 'NPR', label: 'Nepalese Rupee' },
  { value: 'NZD', label: 'New Zealand Dollar' },
  { value: 'OMR', label: 'Rial Omani' },
  { value: 'PAB', label: 'Balboa' },
  { value: 'PEN', label: 'Nuevo Sol' },
  { value: 'PGK', label: 'Kina' },
  { value: 'PHP', label: 'Philippine Peso' },
  { value: 'PKR', label: 'Pakistan Rupee' },
  { value: 'PLN', label: 'Zloty' },
  { value: 'PYG', label: 'Guarani' },
  { value: 'QAR', label: 'Qatari Rial' },
  { value: 'RON', label: 'Romanian Leu' },
  { value: 'RSD', label: 'Serbian Dinar' },
  { value: 'RUB', label: 'Russian Ruble' },
  { value: 'RWF', label: 'Rwanda Franc' },
  { value: 'SAR', label: 'Saudi Riyal' },
  { value: 'SBD', label: 'Solomon Islands Dollar' },
  { value: 'SCR', label: 'Seychelles Rupee' },
  { value: 'SDG', label: 'Sudanese Pound' },
  { value: 'SEK', label: 'Swedish Krona' },
  { value: 'SGD', label: 'Singapore Dollar' },
  { value: 'SHP', label: 'Saint Helena Pound' },
  { value: 'SLL', label: 'Leone' },
  { value: 'SOS', label: 'Somali Shilling' },
  { value: 'STD', label: 'Dobra' },
  { value: 'SVC', label: 'El Salvador Colon' },
  { value: 'SYP', label: 'Syrian Pound' },
  { value: 'SZL', label: 'Lilangeni' },
  { value: 'THB', label: 'Baht' },
  { value: 'TJS', label: 'Somoni' },
  { value: 'TMT', label: 'Turkmenistan New Manat' },
  { value: 'TND', label: 'Tunisian Dinar' },
  { value: 'TOP', label: 'Pa’anga' },
  { value: 'TRY', label: 'Turkish Lira' },
  { value: 'TTD', label: 'Trinidad and Tobago Dollar' },
  { value: 'TWD', label: 'New Taiwan Dollar' },
  { value: 'TZS', label: 'Tanzanian Shilling' },
  { value: 'UAH', label: 'Ukranian Hryvnia' },
  { value: 'UGX', label: 'Uganda Shilling' },
  { value: 'USD', label: 'US Dollar' },
  { value: 'UYU', label: 'Peso Uruguayo' },
  { value: 'UZS', label: 'Uzbekistan Sum' },
  { value: 'VEF', label: 'Bolivar' },
  { value: 'VND', label: 'Vietnam Dong' },
  { value: 'VUV', label: 'Vatu' },
  { value: 'WST', label: 'Tala' },
  { value: 'XAF', label: 'CFA Franc BEAC' },
  { value: 'XCD', label: 'East Caribbean Dollar' },
  { value: 'XOF', label: 'CFA Franc BCEAO' },
  { value: 'XPF', label: 'CFP Franc' },
  { value: 'YER', label: 'Yemeni Rial' },
  { value: 'ZAR', label: 'Rand' },
  { value: 'ZMW', label: 'Zambian Kwacha' },
];
