import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import {
  RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
  ZohoCrmStandardRecord,
  subtitleMapper,
} from '../shared/constants';

export const intentSelectorDropdown: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          value: Intent.CREATE_RECORD,
          label: 'Create record',
        },
        {
          value: Intent.UPDATE_RECORD,
          label: 'Update record',
        },
        {
          value: Intent.GET_RECORD_BY_ID,
          label: 'Get record by ID',
        },
        {
          value: Intent.SEARCH_RECORDS,
          label: 'Search Records',
        },
        {
          value: Intent.WRITE_COQL_QUERY,
          label: 'Search records by COQL query',
        },
        {
          value: Intent.DELETE_RECORD,
          label: 'Delete Record',
        },
      ],
    },
  ],
};

export const recordTypeInput: SidebarInput = {
  id: 'recordType',
  title: 'Record type',
  type: SidebarInputType.Enum,
  getValues: () => [
    ...Object.values(ZohoCrmStandardRecord).map((recordType: ZohoCrmStandardRecord) => ({
      label: recordType,
      value: recordType,
    })),
    { label: 'Custom Object', value: RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME },
  ],
};

export const firstNameInput: SidebarInput = {
  id: 'First_Name',
  title: 'First Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const lastNameInput = (required: boolean): SidebarInput => ({
  id: 'Last_Name',
  title: 'Last Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required,
});

export const companyInput: SidebarInput = {
  id: 'Company',
  title: 'Company',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const emailInput: SidebarInput = {
  id: 'Email',
  title: 'Email',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const phoneInput: SidebarInput = {
  id: 'Phone',
  title: 'Phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const accountNameInput = (required: boolean): SidebarInput => ({
  id: 'Account_Name',
  title: 'Accout Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required,
  subtitle: 'Specify the Account Name.',
});

export const websiteInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  id: 'Website',
  title: 'Website',
  lines: 1,
  required: false,
};

export const descriptionInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  id: 'Description',
  title: 'Description',
  lines: 3,
  required: false,
};

export const titleInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  id: 'Title',
  title: 'Title',
  subtitle: 'Title of the contact, such as CEO or Vice President.',
  lines: 1,
  required: false,
};

export const leadStatusInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  id: 'Lead_Status',
  title: 'Lead Status',
  lines: 1,
  required: false,
};

export const subjectInput = (required: boolean): SidebarInput => ({
  id: 'Subject',
  title: 'Subject',
  type: SidebarInputType.TextArea,
  lines: 1,
  required,
});

export const nameIdInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  id: 'Who_Id',
  title: 'Name ID',
  subtitle: 'The ID of the Contact or Lead this Task is related to.',
  lines: 1,
  required: false,
};

export const whatIdInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  id: 'What_Id',
  title: 'Related to ID',
  subtitle:
    'The ID of the account the contact is associated with. Specify this only when the Name ID is a contact.',
  lines: 1,
  required: false,
};

export const seModuleInput: SidebarInput = {
  type: SidebarInputType.TextArea,
  id: '$se_module',
  title: 'Associated Module Name',
  subtitle:
    'The API name of the parent module that the contact is associated with. This key is mandatory when you include Who_id and What_Id.',
  lines: 1,
  required: false,
  placeholder: 'Accounts',
};

export const statusInput: SidebarInput = {
  type: SidebarInputType.Enum,
  id: 'Status',
  title: 'Status',
  getValues: () => [
    {
      value: 'Not Started',
      label: 'Not Started',
    },
    {
      value: 'In Progress',
      label: 'In Progress',
    },
    {
      value: 'Completed',
      label: 'Completed',
    },
  ],
  required: false,
};

export const ownerInput: SidebarInput = {
  id: 'Owner',
  title: 'Task Owner',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const recordIdInput = (subtitle: string): SidebarInput => ({
  type: SidebarInputType.TextArea,
  lines: 1,
  id: 'recordId',
  title: 'Record ID',
  required: true,
  subtitle: `Specify the record ID to ${subtitle}.`,
});

const accountAdditionalFieldsInput: SidebarInput = {
  id: 'accountAdditionalFields',
  type: SidebarInputType.Code,
  title: 'Additional Fields',
  subtitle:
    'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which Account fields to update.',
  required: false,
  language: 'json',
  placeholder: `{
  "Annual_Revenue": 850000,
  "Shipping_Street": null,
  "Ownership": "Partnership",
}`,
  lines: 4,
  useLightTheme: true,
};

const contactAdditionalFieldsInput: SidebarInput = {
  id: 'contactAdditionalFields',
  type: SidebarInputType.Code,
  title: 'Additional Fields',
  subtitle:
    'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which Contact fields to update.',
  required: false,
  language: 'json',
  placeholder: `{
  "Twitter": "krismarrier_sample",
  "Other_Zip": null,
  "Mailing_Street": "228 Runamuck Pl #2808",
}`,
  lines: 4,
  useLightTheme: true,
};

const leadAdditionalFieldsInput: SidebarInput = {
  id: 'leadAdditionalFields',
  type: SidebarInputType.Code,
  title: 'Additional Fields',
  subtitle:
    'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which Lead fields to update.',
  required: false,
  language: 'json',
  placeholder: `{
  "State": C.A,
  "Skype_ID": skype_12id,
}`,
  lines: 4,
  useLightTheme: true,
};

const taskAdditionalFieldsInput: SidebarInput = {
  id: 'taskAdditionalFields',
  type: SidebarInputType.Code,
  title: 'Additional Fields',
  subtitle:
    'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which Task fields to update.',
  required: false,
  language: 'json',
  placeholder: `{
  "Due_Date": "2022-03-18",
  "Priority": "Highest",
}`,
  lines: 4,
  useLightTheme: true,
};

export const objectNameInput = (intent: Intent): SidebarInput => ({
  type: SidebarInputType.TextArea,
  id: 'objectName',
  title: 'Object Name',
  subtitle: `The API Name of the Object to ${subtitleMapper[intent]}. Use Connect Portal Workflow Settings to allow users to select a Field Mapping.`,
  placeholder: '{{settings.objectType}}',
  required: true,
  lines: 1,
});

export const coqlQueryInput: SidebarInput = {
  id: 'query',
  type: SidebarInputType.Code,
  language: 'sql',
  title: 'COQL Query',
  subtitle: (
    <>
      Write a query using Zoho CRM Object Query Language (COQL). Supports{' '}
      <a
        href="https://www.zoho.com/crm/developer/docs/api/v3/COQL-Overview.html"
        target="_blank"
        rel="noreferrer"
      >
        COQL SELECT Syntax.
      </a>
    </>
  ),
  placeholder: `SELECT Account_Name, Description
FROM Accounts
WHERE Name = 'Ethan'`,
  required: true,
  useLightTheme: true,
  lines: 3,
};

export const defaultZohoCrmFields: Record<
  ZohoCrmStandardRecord | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
  (createRecordIntent?: boolean) => SidebarInput[]
> = {
  Accounts: (createRecordIntent: boolean) => [
    ...(createRecordIntent ? [] : [recordIdInput('update')]),
    accountNameInput(createRecordIntent),
    websiteInput,
    phoneInput,
    descriptionInput,
    { ...ownerInput, title: 'Owner ID' },
    accountAdditionalFieldsInput,
  ],

  Contacts: (createRecordIntent: boolean) => [
    ...(createRecordIntent ? [] : [recordIdInput('update')]),
    firstNameInput,
    lastNameInput(createRecordIntent),
    accountNameInput(false),
    emailInput,
    titleInput,
    descriptionInput,
    contactAdditionalFieldsInput,
  ],

  Leads: (createRecordIntent: boolean) => [
    ...(createRecordIntent ? [] : [recordIdInput('update')]),
    firstNameInput,
    lastNameInput(createRecordIntent),
    companyInput,
    emailInput,
    phoneInput,
    websiteInput,
    { ...titleInput, id: 'Designation' },
    leadStatusInput,
    descriptionInput,
    leadAdditionalFieldsInput,
  ],

  Tasks: (createRecordIntent: boolean) => [
    ...(createRecordIntent ? [] : [recordIdInput('update')]),
    nameIdInput,
    whatIdInput,
    seModuleInput,
    subjectInput(createRecordIntent),
    descriptionInput,
    statusInput,
    taskAdditionalFieldsInput,
  ],

  '<specified by objectName property>': (createRecordIntent: boolean) => [
    ...(createRecordIntent ? [] : [recordIdInput('update')]),
    {
      ...accountAdditionalFieldsInput,
      id: 'additionalFields',
      title: 'Record Fields',
      subtitle:
        'Use Connect Portal Workflow Settings to allow users to select a Field Mapping to specify which fields to update.',
    },
  ],
};
