import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

export const getFormattedDate = (date: Date): string => date.toISOString();

//TODO : FIND WHY DASHBOARD BUILD FAIL WHEN WE IMORT THIS FROM UTILS
export function wrapWordInInvertedComma(words: string[]): string[] {
  return words.map((word: string) => `'${word}'`);
}

export function joinOrConditions(conditions: string[]): string {
  return conditions
    .map((condition: string) => (condition.includes(' AND ') ? `(${condition})` : condition))
    .join(' OR ');
}

export default function conditionsToSOQL(condition: ResolvedConditionWrapper): string {
  if (condition.type === 'JOIN') {
    const conditions: string[] = condition.conditions.map((condition: ResolvedConditionWrapper) => {
      return conditionsToSOQL(condition);
    });
    return condition.join === 'OR' ? joinOrConditions(conditions) : conditions.join(' AND ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      case Operator.StringContains:
        return `${innerCondition.variable} LIKE '%${innerCondition.argument}%'`;
      case Operator.StringDoesNotContain:
        return `NOT ${innerCondition.variable} LIKE '%${innerCondition.argument}%'`;
      case Operator.StringExactlyMatches:
        return `${innerCondition.variable} = '${innerCondition.argument}'`;
      case Operator.StringDoesNotExactlyMatch:
        return `${innerCondition.variable} != '${innerCondition.argument}'`;
      case Operator.StringIsIn:
        const strintIsInArgument: string[] = innerCondition.argument as string[];
        return `${innerCondition.variable} in (${wrapWordInInvertedComma(strintIsInArgument).join(
          ',',
        )})`;
      case Operator.StringIsNotIn:
        const strintIsNotInArgument: string[] = innerCondition.argument as string[];
        return `${innerCondition.variable} not in (${wrapWordInInvertedComma(
          strintIsNotInArgument,
        ).join(',')})`;
      case Operator.StringStartsWith:
        return `${innerCondition.variable} LIKE '${innerCondition.argument}%'`;
      case Operator.StringDoesNotStartWith:
        return `NOT ${innerCondition.variable} LIKE '${innerCondition.argument}%'`;
      case Operator.StringEndsWith:
        return `${innerCondition.variable} LIKE '%${innerCondition.argument}'`;
      case Operator.StringDoesNotEndWith:
        return `NOT ${innerCondition.variable} LIKE '%${innerCondition.argument}'`;
      case Operator.NumberLessThan:
        return `${innerCondition.variable} < ${innerCondition.argument}`;
      case Operator.NumberEquals:
        return `${innerCondition.variable} = ${innerCondition.argument}`;
      case Operator.NumberGreaterThan:
        return `${innerCondition.variable} > ${innerCondition.argument}`;
      case Operator.NumberDoesNotEqual:
        return `${innerCondition.variable} != ${innerCondition.argument}`;
      case Operator.NumberGreaterThanOrEqualTo:
        return `${innerCondition.variable} >= ${innerCondition.argument}`;
      case Operator.NumberLessThanOrEqualTo:
        return `${innerCondition.variable} <= ${innerCondition.argument}`;
      case Operator.DateTimeAfter:
        return `${innerCondition.variable} >${getFormattedDate(new Date(innerCondition.argument))}`;
      case Operator.DateTimeBefore:
        return `${innerCondition.variable} <${getFormattedDate(new Date(innerCondition.argument))}`;
      case Operator.DateTimeEquals:
        return `${innerCondition.variable} =${getFormattedDate(new Date(innerCondition.argument))}`;
      case Operator.BooleanTrue:
        return `${innerCondition.variable} = true`;
      case Operator.BooleanFalse:
        return `${innerCondition.variable} = false`;
      case Operator.IsNotNull:
        return `${innerCondition.variable} != null`;
      case Operator.IsNull:
        return `${innerCondition.variable} = null`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for salesforce`);
    }
  }
  return '';
}
