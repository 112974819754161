import { HermesRedis } from '../atlas';

import { HttpStatus, ParagonError } from './base';
import { ERROR_CODE } from './codes';

class RedisError extends ParagonError {
  name: string = 'RedisError';
  code: ERROR_CODE;
  message: string;
  meta: object;

  constructor({
    code,
    httpStatus,
    message,
    meta,
  }: {
    code: ERROR_CODE;
    httpStatus: HttpStatus;
    message: string;
    meta: object;
  }) {
    super(code, httpStatus, message, meta);
    this.message = message;
    this.meta = meta;
  }
}

export class InvalidRedisClusterConnectionInfoError extends RedisError {
  constructor(url: string, clusterEnabled: boolean) {
    super({
      code: ERROR_CODE.INVALID_REDIS_CLUSTER_CONNECTION,
      httpStatus: HttpStatus.PRECONDITION_FAILED,
      message: 'Invalid Redis connection info: clusterEnabled',
      meta: {
        url,
        clusterEnabled,
      },
    });
  }
}

export class RedisClusterNotSupportedError extends RedisError {
  constructor(url: string, clusterEnabled: boolean, database: HermesRedis) {
    super({
      code: ERROR_CODE.REDIS_CLUSTER_NOT_SUPPORTED,
      httpStatus: HttpStatus.PRECONDITION_FAILED,
      message: "This redis instance doesn't support cluster mode.",
      meta: {
        url,
        clusterEnabled,
        database,
      },
    });
  }
}
