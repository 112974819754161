import { DeferredPromise } from './generic';

/**
 * whether to debounce or throttle cached items
 * defaults to `Debounce` behavior
 */
export enum CacheMode {
  Debounce = 'debounce',
  Throttle = 'throttle',
}

/**
 * used by a CacheThrottle instance to keep track of its state
 * this is to prevent race conditions
 *
 * @enum {number}
 */
enum CacheThrottleState {
  Closed = 'closed',
  Idle = 'idle',
  Processing = 'processing',
}

/**
 * an entry within a CacheThrottle instance's cache
 */
type CacheThrottleEntry<T> = {
  mode?: CacheMode;
  timeout: number;
  ttl: number;
  value: T;
};

/**
 * the in-memory cache a CacheThrottle instance uses
 */
type CacheThrottleStore<T> = Record<string, CacheThrottleEntry<T>>;

/**
 * configuration parameters for a CacheThrottle instance
 */
export type CacheThrottleConfig<T = any> = Partial<{
  /**
   * when enabled, this clears pending `del` calls from the queue when `get`, `set`, or `getOrSet` is called
   * this can be used to prevent queued delete calls from deleting recently retrieved stateful values, like db connections
   */
  clearPendingDeletes: boolean;

  /**
   * whether to operate in Throttle or Debounce mode
   */
  mode: CacheMode;

  /**
   * an optional callback for handling items when they get deregistered from the cache
   */
  onDeregister: (value: T) => Promise<void> | void;

  /**
   * an optional callback for serializing keys
   * in the case that keys are particularly long, this can be used to shorten them
   */
  serializeKey: (key: string) => string;

  /**
   * how long items should remain in the cache
   * the ttl is reset every time a key is accessed
   */
  ttl: number;
}>;

/**
 * the operations that can internally be queued by a CacheThrottle instance
 */
enum CacheThrottleOperation {
  Get,
  Set,
  GetOrSet,
  Del,
  Do,
}

/**
 * queue item, consists of a key, method name, callback and a promise for when the method resolves
 */
export type QueueItem<T> = [string, CacheThrottleOperation, () => Promise<T>, DeferredPromise<T>];

/**
 * A helper class for caching items in memory and throttling / debouncing function calls
 * methods for getters, setters, and executors are queued to prevent race conditions between calls.
 * It provides helper methods for handling deregistering of items.
 * Useful for caching database connections or rate-limiting outgoing Sentry calls.
 */
export class CacheThrottle<T = any> {
  #state = CacheThrottleState.Idle;

  /**
   * in memory cache
   */
  #store: CacheThrottleStore<T> = {};

  /**
   * the configuration for the class
   */
  #config: CacheThrottleConfig<T>;

  /**
   * a queue of methods to be called. consists of a key, method name, and callback and a promise for when the method resolves
   * [serializedKey: string, callback: () => Promise<T>, promise: Promise<T>]
   */
  #queue: Array<QueueItem<T>> = [];

  constructor(config: CacheThrottleConfig<T> = {}) {
    // Setting default values for `mode` and `ttl`
    this.#config = {
      mode: CacheMode.Debounce,
      ttl: 0,
      ...config,
    };
  }

  /**
   * attempts to retrieve a key from the cache
   *
   * @param {string} key
   * @param {boolean} [asap] asap = as soon as possible. should this be queued or execute immediately
   * @returns {(Promise<T | undefined>)}
   * @memberof CacheThrottle
   */
  async get(key: string, asap?: boolean): Promise<T | undefined> {
    const serializedKey: string = this.serializeKey(key);
    const callback: () => Promise<T> = async (): Promise<T> => {
      const entry: CacheThrottleEntry<T> | undefined = this.#store[serializedKey];
      const { mode, ttl, value } = entry ?? {};

      if (entry && ttl && (!mode || mode === CacheMode.Debounce)) {
        this.refreshTimeout(key, serializedKey, ttl);
      }

      if (this.#config.clearPendingDeletes) {
        this.clearPendingDeletesOnKey(serializedKey, value);
      }

      return value;
    };

    return asap ? callback() : this.enqueue(serializedKey, CacheThrottleOperation.Get, callback);
  }

  /**
   * stores an item in the cache
   *
   * @param {string} key
   * @param {T} value
   * @param {number} [ttl=this.]
   * @param {*} config
   * @param {*} ttl
   * @param {CacheMode} [mode]
   * @param {boolean} [asap] asap = as soon as possible. should this be queued or execute immediately
   * @returns {Promise<T>}
   * @memberof CacheThrottle
   */
  async set(
    key: string,
    value: T,
    ttl: number = this.#config.ttl as number,
    mode?: CacheMode,
    asap?: boolean,
  ): Promise<T> {
    const serializedKey: string = this.serializeKey(key);
    const callback: () => Promise<T> = async (): Promise<T> => {
      const entry: CacheThrottleEntry<T> | undefined = this.#store[serializedKey];
      const didPreviouslyExist: boolean = entry ? true : false;
      this.#store[serializedKey] = {
        ...entry,
        mode,
        ttl,
        value,
      };

      if (!didPreviouslyExist || mode !== CacheMode.Throttle) {
        this.refreshTimeout(key, serializedKey, ttl);
      }

      if (this.#config.clearPendingDeletes) {
        this.clearPendingDeletesOnKey(serializedKey, value);
      }

      return value;
    };

    return asap ? callback() : this.enqueue(serializedKey, CacheThrottleOperation.Set, callback);
  }

  /**
   * attempts to retrieve a value from the cache
   * if it's not available, it sets the value using the provided generator method
   */
  async getOrSet(
    key: string,
    generator: () => T | Promise<T>,
    ttl: number = this.#config.ttl as number,
    mode: CacheMode = this.#config.mode as CacheMode,
    asap?: boolean,
  ): Promise<T> {
    const serializedKey: string = this.serializeKey(key);
    const callback: () => Promise<T> = async (): Promise<T> => {
      if (this.#store[serializedKey]) {
        return this.#store[serializedKey].value;
      }

      const value: T = await generator();
      await this.set(key, value, ttl, mode, true);

      if (this.#config.clearPendingDeletes) {
        this.clearPendingDeletesOnKey(serializedKey, value);
      }

      return value;
    };

    return asap
      ? callback()
      : this.enqueue(serializedKey, CacheThrottleOperation.GetOrSet, callback);
  }

  /**
   * deletes an item from the cache
   *
   * @param {string} key
   * @param {boolean} [isKeySerialized=false]
   * @param {boolean} [asap] asap = as soon as possible. should this be queued or execute immediately
   * @returns {(Promise<T | undefined>)}
   * @memberof CacheThrottle
   */
  async del(key: string, isKeySerialized: boolean = false, asap?: boolean): Promise<T | undefined> {
    const serializedKey: string = isKeySerialized ? key : this.serializeKey(key);
    const callback: () => Promise<T> = async (): Promise<T> => {
      const entry: CacheThrottleEntry<T> | undefined = this.#store[serializedKey];
      let value: T | undefined;

      if (entry?.timeout) {
        clearTimeout(entry.timeout as unknown as NodeJS.Timeout);
      }

      if (entry) {
        value = entry.value;
        delete this.#store[serializedKey];

        // TODO: log `onDeregiser` errors
        // The `@shared/utils/sdk` library that this file is stored in is shared with the dashboard.
        // Unfortunately we currently don't have support for using `@shared/logger/sdk` in any files used by it.
        // That means we've got to silently fail this for now 😅
        //
        // Additionally, this is messy because onDeregister may be synchronous and not return a promise.
        // We had to wrap it in an asynchronous call to get the promise.
        // `setImmediate` or `process.nextTick` wasn't great because we can't reliably test that it worked.
        await (async () => this.#config.onDeregister?.(value))().catch((_e: Error) => {});
      }

      return value as T;
    };

    return asap ? callback() : this.enqueue(serializedKey, CacheThrottleOperation.Del, callback);
  }

  /**
   * returns the keys stored in the cache
   */
  keys(): string[] {
    return Object.keys(this.#store);
  }

  /**
   * executes a method. the `key` is used as a unique identifier for rate-limiting calls
   */
  async do(
    key: string,
    method: () => T | Promise<T>,
    ttl: number = this.#config.ttl as number,
    mode: CacheMode = this.#config.mode as CacheMode,
  ): Promise<T> {
    const serializedKey: string = this.serializeKey(key);
    return this.enqueue(serializedKey, CacheThrottleOperation.Do, async () => {
      if (this.#store[serializedKey]) {
        return this.#store[serializedKey].value;
      }

      const result: T = await method();
      const output = await this.set(key, result, ttl, mode, true);
      return output;
    });
  }

  /**
   * removes all the items from the cache, clears timeouts, and calls deregister methods
   */
  async close(): Promise<void> {
    this.#state = CacheThrottleState.Closed;
    await Promise.all(Object.keys(this.#store).map((key: string) => this.del(key, true, true)));
  }

  /**
   * refreshes a set interval for clearing an item from the cache
   * if an existing interval exists, it removes it
   */
  private refreshTimeout(key: string, serializedKey: string, ttl: number): number | undefined {
    if (this.#state === CacheThrottleState.Closed) {
      return undefined;
    }

    const entry: CacheThrottleEntry<T> | undefined = this.#store[serializedKey];

    if (ttl && entry?.timeout !== undefined) {
      clearTimeout(entry.timeout as unknown as NodeJS.Timeout);
    }

    if (ttl) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      entry.timeout = setTimeout(() => this.del(key), ttl) as unknown as number;
      return entry.timeout;
    } else {
      return undefined;
    }
  }

  /**
   * removes any pending delete calls on a key
   * @param key
   */
  private clearPendingDeletesOnKey(key: string, currentValue: T): void {
    this.#queue = this.#queue.filter(([_key, operation, _method, promise]) => {
      if (key === _key && operation === CacheThrottleOperation.Del) {
        promise.resolve(currentValue);
        return false;
      }

      return true;
    });
  }

  /**
   * generates the key used for storing items in the cache
   */
  private serializeKey(key: string): string {
    return this.#config.serializeKey?.(key) ?? key;
  }

  /**
   * queues a method for processing
   */
  private async enqueue(
    serializedKey: string,
    operation: CacheThrottleOperation,
    method: () => Promise<T>,
  ): Promise<T> {
    const promise: DeferredPromise<T> = new DeferredPromise<T>();
    this.#queue.push([serializedKey, operation, method, promise]);

    if (this.#state === CacheThrottleState.Idle) {
      this.flush();
    }

    return promise;
  }

  /**
   * calls the methods in the queue
   */
  private flush(): void {
    this.#state = CacheThrottleState.Processing;

    process.nextTick(async () => {
      while (this.#queue.length && this.#state !== CacheThrottleState.Closed) {
        const currentItem: QueueItem<T> | undefined = this.#queue.shift();
        if (currentItem) {
          const [, , currentMethod, currentPromise] = currentItem;
          try {
            const output: T = await currentMethod();
            currentPromise.resolve(output);
          } catch (e) {
            currentPromise.reject(e);
          }
        }
      }

      this.#state = CacheThrottleState.Idle;
    });
  }
}
