export type TestDataResponse = {
  slug: string;
  description: string;
  _links: Record<string, any>;
};

export enum RecordType {
  CUSTOMER = 'customers',
  ORDER = 'orders',
  PRODUCT = 'products',
}

export type CreateUpdateCustomerDTO = {
  recordType: RecordType.CUSTOMER;
  customerId?: string;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  customerRole: string;
  billingInfo: string;
  shippingInfo: string;
};

export type CreateUpdateOrderDTO = {
  recordType: RecordType.ORDER;
  orderId?: string;
  setPaid: string;
  orderStatus: string;
  customerRelatedWithOrder: string;
  paymentId: string;
  lineItems: string;
  orderAdditionalFields: string;
};

export type CreateUpdateProductDTO = {
  recordType: RecordType.PRODUCT;
  productId?: string;
  productName: string;
  productType: string;
  productStatus: string;
  price: string;
  productDescription: string;
  productAdditionalFields: string;
};

type CommonActionDTO = {
  recordType: RecordType;
};

export type SearchRecordsDTO = CommonActionDTO & {
  customersFilterFormula: string;
  productsFilterFormula: string;
  ordersFilterFormula: string;
  responseOrder: string;
};

export type CreateUpdateRecordDTO =
  | CreateUpdateCustomerDTO
  | CreateUpdateOrderDTO
  | CreateUpdateProductDTO;

export type OperationByIdType = CommonActionDTO & {
  productId: string;
  orderId: string;
  customerId: string;
};

export type PreparedPayload = { id?: string; payload: Record<string, string | number | object> };

export type Gateway = {
  id: string;
  title: string;
};
