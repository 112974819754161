import { hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';
import {
  additionalFieldsInput,
  authInput,
  companyInput,
  emailInput,
  firstNameInput,
  intentInput,
  jobTitleInput,
  lastNameInput,
  listDynamicInput,
  listInput,
  phoneInput,
  prospectFilterInputStatic,
  prospectId,
  websiteInput,
} from './inputs';

const config: ActionConfig = {
  actionType: Action.PARDOT,
  name: 'Pardot',
  description: 'Sync prospects from Pardot',
  icon: iconSvg,
  provider: ProviderType.SALESFORCE,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        authInput,
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.SALESFORCE,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length ? { inputs: [intentInput] } : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent as Intent) {
        case Intent.ADD_PROSPECT_TO_LIST:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials) ? listInput : listDynamicInput,
              prospectId,
            ],
          };
        case Intent.CREATE_OR_UPDATE_PROSPECT:
          return {
            inputs: [
              {
                ...prospectId,
                subtitle: 'Id of prospect to be updated. If left blank, creates a new prospect',
              },
              emailInput,
              firstNameInput,
              lastNameInput,
              companyInput,
              jobTitleInput,
              phoneInput,
              websiteInput,
              additionalFieldsInput,
            ],
          };
        case Intent.DELETE_PROSPECT_BY_ID:
          return { inputs: [{ ...prospectId, subtitle: '' }] };
        case Intent.GET_PROSPECT_BY_ID:
          return { inputs: [{ ...prospectId, subtitle: '' }] };
        case Intent.REMOVE_PROSPECT_FROM_LIST:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials) ? listInput : listDynamicInput,
              { ...prospectId, subtitle: 'ID of prospect to be removed to the List.' },
            ],
          };
        case Intent.SEARCH_PROSPECTS:
          return {
            inputs: [prospectFilterInputStatic],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;

export { authConfig };
