import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

// {
//   type: 'JOIN',
//   join: 'OR',
//   conditions: [{
//     type: 'JOIN',
//     join: 'AND',
//     conditions: [{
//       operator: '$stringExactlyMatches',
//       variable: "Name",
//       argument: "boop"
//     }]
//   }]
// } -> "Name = boop"
export default function conditionsToJQL(condition: ResolvedConditionWrapper): string {
  if (condition.type === 'JOIN') {
    return condition.conditions
      .map((condition: ResolvedConditionWrapper) => {
        return conditionsToJQL(condition);
      })
      .join(condition.join === 'OR' ? ' or ' : ' and ');
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `"${innerCondition.variable}" = "${innerCondition.argument}"`;
      case Operator.StringDoesNotExactlyMatch:
        return `"${innerCondition.variable}" != "${innerCondition.argument}"`;
      case Operator.StringContains:
        return `"${innerCondition.variable}" ~ "${innerCondition.argument}"`;
      case Operator.StringDoesNotContain:
        return `"${innerCondition.variable}" !~ "${innerCondition.argument}"`;
      case Operator.StringIsIn:
        return `"${innerCondition.variable}" in (${innerCondition.argument
          .map((argumentValue: string) => `"${argumentValue}"`)
          .join(',')})`;
      case Operator.StringIsNotIn:
        return `"${innerCondition.variable}" not in (${innerCondition.argument
          .map((argumentValue: string) => `"${argumentValue}"`)
          .join(',')})`;
      case Operator.StringGreaterThan:
        return `"${innerCondition.variable}" > "${innerCondition.argument}"`;
      case Operator.StringLessThan:
        return `"${innerCondition.variable}" < "${innerCondition.argument}"`;
      case Operator.StringGreaterThan:
        return `"${innerCondition.variable}" > "${innerCondition.argument}"`;

      default:
        throw new Error(`${innerCondition.operator} operator not supported for Jira`);
    }
  }
  return '';
}
