import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

import { ActionStepParameters, Intent } from '../configs';

export const assetItems: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Content ID',
  cacheKey: 'assetItemList',
  refreshDependencies: ['contentType'],
  hideFromConnectFieldTypes: true,
  getRefreshActionParameters: (options: ActionStepParameters) => ({
    actionType: Action.TABLEAU,
    actionParameters: [
      {
        key: 'contentType',
        source: pickValueSourceByKey(options.actionParameters, 'contentType'),
      },
    ] as KeyedSource<DataType.ANY>[],
    credentials: options.credentials,
    intent: Intent.LIST_ASSET_ITEMS,
  }),
  mapRefreshToValues: (response: any) => {
    return response.result.output.map((item: EnumInputValue) => ({
      label: item.label,
      value: item.value,
    }));
  },
};
