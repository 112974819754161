import { Operator, ResolvedCondition, ResolvedConditionWrapper } from '@shared/types/sdk/resolvers';

/**
 * {
 *   type: 'JOIN',
 *   join: 'OR',
 *   conditions: [{
 *     type: 'JOIN',
 *     join: 'AND',
 *     conditions: [{
 *       operator: '$stringContains',
 *       variable: "Name",
 *       argument: "mark"
 *     }]
 *   }]
 * } -> "OR(AND(SEARCH("mark", {Name})))"
 * @summary converts conditional input to filter formula for pardot
 * @param condition
 * @returns
 */

export default function conditionsToPardotFormula(condition: ResolvedConditionWrapper): string {
  if (condition.type === 'JOIN') {
    return (
      condition.conditions
        .map((condition: ResolvedConditionWrapper) => {
          return conditionsToPardotFormula(condition);
        })
        // the filter formula has to be passed in as query parameters . So using separator , which
        // later would be converted to different properties on a javascript object
        .join(',')
    );
  } else if (condition.type === 'OPERATOR') {
    const { condition: innerCondition }: { condition: ResolvedCondition } = condition;

    switch (innerCondition.operator) {
      case Operator.StringExactlyMatches:
        return `"${innerCondition.variable}":"${innerCondition.argument}"`;
      case Operator.NumberEquals:
        return `"${innerCondition.variable}":${innerCondition.argument}`;
      case Operator.BooleanTrue:
        return `"${innerCondition.variable}":true`;
      case Operator.BooleanFalse:
        return `"${innerCondition.variable}":false`;
      default:
        throw new Error(`${innerCondition.operator} operator not supported for Pardot`);
    }
  }
  return '';
}
