import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.PRODUCTBOARD,
  provider: ProviderType.PRODUCTBOARD,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Productboard accounts',
  icon: iconSvg,
  description: "Connect to your users' Productboard accounts",
  sidebarSections: [
    {
      inputs: [
        {
          type: SidebarInputType.ValueText,
          id: CredentialKeys.ACCESS_TOKEN,
          title: 'Enter your Productboard Access Token',
          required: true,
        },
      ],
    },
  ],
};

export default authConfig;
