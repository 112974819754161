import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.FACEBOOK_ADS;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'FACEBOOK_ADS_TEST_CONNECTION',
  CREATE_CAMPAIGN = 'FACEBOOK_ADS_CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN = 'FACEBOOK_ADS_UPDATE_CAMPAIGN',
  GET_CAMPAIGNS = 'FACEBOOK_ADS_GET_CAMPAIGNS',
  GET_CAMPAIGN_BY_ID = 'FACEBOOK_ADS_GET_CAMPAIGN_BY_ID',
  CREATE_AD = 'FACEBOOK_ADS_CREATE_AD',
  UPDATE_AD = 'FACEBOOK_ADS_UPDATE_AD',
  GET_AD_BY_ID = 'FACEBOOK_ADS_GET_AD_BY_ID',
  CREATE_AD_SET = 'FACEBOOK_ADS_CREATE_AD_SET',
  UPDATE_AD_SET = 'FACEBOOK_ADS_UPDATE_AD_SET',
  GET_AD_SETS = 'FACEBOOK_ADS_GET_AD_SETS',
  GET_AD_SET_BY_ID = 'FACEBOOK_ADS_GET_AD_SET_BY_ID',
  BUILD_AD_CREATIVE_OBJECT = 'FACEBOOK_ADS_BUILD_AD_CREATIVE_OBJECT',
  CREATE_AD_CREATIVE = 'FACEBOOK_ADS_CREATE_AD_CREATIVE',
  CREATE_LEAD_GEN_FORM = 'FACEBOOK_ADS_CREATE_LEAD_GEN_FORM',
  SEND_PURCHASE_EVENT = 'FACEBOOK_ADS_SEND_PURCHASE_EVENT',
  SEND_LEAD_EVENT = 'FACEBOOK_ADS_SEND_LEAD_EVENT',
  SEND_FUNNEL_EVENT = 'FACEBOOK_ADS_SEND_FUNNEL_EVENT',
  GET_PAGES = 'FACEBOOK_ADS_GET_ALL_PAGES',
  GET_PIXELS = 'FACEBOOK_ADS_GET_ALL_PIXELS',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.BUILD_AD_CREATIVE_OBJECT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_AD_CREATIVE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_AD_SET;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_LEAD_GEN_FORM;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_AD_SETS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_AD_SET_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CAMPAIGNS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CAMPAIGN_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_LEAD_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_PURCHASE_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_FUNNEL_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_AD_SET;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PAGES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PIXELS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_AD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_AD;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_AD_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  CLIENT_ID = 'FACEBOOK_CLIENT_ID',
  CLIENT_SECRET = 'FACEBOOK_CLIENT_SECRET',
  ACCESS_TOKEN = 'FACEBOOK_ADS_ACCESS_TOKEN',
  SCOPES = 'FACEBOOK_ADS_SCOPES',
  ACCOUNT_ID = 'FACEBOOK_ADS_ACCOUNT_ID',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.ACCOUNT_ID]: string;
};
