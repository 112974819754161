import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs';
import iconSvg from '../configs/icon.svg';

import { passwordInput, subdomainInput, usernameInput } from './common';

const authConfig: ActionConfig = {
  actionType: Action.SERVICENOW,
  provider: ProviderType.SERVICENOW,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your ServiceNow account',
  icon: iconSvg,
  description: "Connect to your users' ServiceNow accounts",
  sidebarSections: [
    {
      inputs: [subdomainInput, usernameInput, passwordInput],
      buttons: [createTestConnectionButton(Action.SERVICENOW, Object.values(CredentialKeys))],
    },
  ],
};

export default authConfig;
