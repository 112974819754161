import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'full';

export const scopes: Record<ScopeName, ScopeValue> = {
  full: {
    required: true,
    label: 'Full access',
    name: 'full',
    description: 'Full access',
  },
};

export const requiredScopes: ScopeName[] = ['full'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Oracle Eloqua',
  description: "Connect to your users' Eloqua accounts",
  authUrl: 'https://login.eloqua.com/auth/oauth2/authorize',
  accessTokenUrl: 'https://login.eloqua.com/auth/oauth2/token',
  iconSvg,
  clientIdLabel: 'Client id',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client Id',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/eloqua`,
      documentationLinkLabel: 'How to obtain a client Key?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/eloqua',
};
