import React from 'react';

import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { SalesforceStandardRecord, defaultSalesforceFields } from '../shared/defaultRecordTypes';
import { RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME, SalesforceCustomFieldTypes } from '../shared/types';

import supportedOperators from './supportedOperators';

const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  title: 'Additional Fields',
  subtitle:
    'Specify any other fields that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to select which Opportunity fields to update.',
  required: false,
  language: 'json',
  placeholder: `{
  "property_number": "17",
  "property_dropdown": "choice_b",
  "property_radio": "option_1",
  "property_string": "value",
  "property_date": "1572480000000"
}`,
  lines: 5,
  useLightTheme: true,
};

const lengthInput: SidebarInput = {
  id: 'length',
  type: SidebarInputType.TextArea,
  title: 'Length',
  subtitle: 'The maximum length of the text field.',
  required: true,
  lines: 1,
};

const precisionInput: SidebarInput = {
  ...lengthInput,
  subtitle: 'Number of digits to the left of the decimal point',
};

const decimalPlaceInput: SidebarInput = {
  id: 'decimalPlace',
  type: SidebarInputType.TextArea,
  title: 'Decimal Places',
  subtitle: 'Number of digits to the right of the decimal point',
  lines: 1,
  required: true,
};

const pickListValuesInput: SidebarInput = {
  id: 'pickListValues',
  type: SidebarInputType.TextArea,
  title: 'Values',
  subtitle: 'Enter values, with each value separated by a new line',
  required: true,
  lines: 3,
};

const visibleLinesInput: SidebarInput = {
  id: 'visibleLines',
  type: SidebarInputType.TextArea,
  title: 'Visible Lines',
  required: true,
  lines: 1,
};

export const fieldTypesInput: SidebarInput = {
  id: 'type',
  type: SidebarInputType.Enum,
  title: 'Field Type',
  getValues: () => [
    {
      label: 'Checkbox',
      value: SalesforceCustomFieldTypes.CHECKBOX,
    },
    {
      label: 'Currency',
      value: SalesforceCustomFieldTypes.CURRENCY,
    },
    {
      label: 'Date',
      value: SalesforceCustomFieldTypes.DATE,
    },
    {
      label: 'Email',
      value: SalesforceCustomFieldTypes.EMAIL,
    },
    {
      label: 'Number',
      value: SalesforceCustomFieldTypes.NUMBER,
    },
    {
      label: 'Percent',
      value: SalesforceCustomFieldTypes.PERCENT,
    },
    {
      label: 'Phone',
      value: SalesforceCustomFieldTypes.PHONE,
    },
    {
      label: 'Picklist',
      value: SalesforceCustomFieldTypes.PICK_LIST,
    },
    {
      label: 'Picklist (Multi-select)',
      value: SalesforceCustomFieldTypes.MULTI_PICK_LIST,
    },
    {
      label: 'Text',
      value: SalesforceCustomFieldTypes.TEXT,
    },
    {
      label: 'TextArea',
      value: SalesforceCustomFieldTypes.TEXTAREA,
    },
    {
      label: 'Text Area (Long)',
      value: SalesforceCustomFieldTypes.TEXTAREA_LONG,
    },
    {
      label: 'Text Area (Rich)',
      value: SalesforceCustomFieldTypes.TEXTAREA_RICH,
    },
    {
      label: 'Time',
      value: SalesforceCustomFieldTypes.TIME,
    },
    {
      label: 'URL',
      value: SalesforceCustomFieldTypes.URL,
    },
  ],
  defaultValue: SalesforceCustomFieldTypes.TEXT,
  required: true,
};

const CustomFieldTypesInputMap: Record<SalesforceCustomFieldTypes, SidebarInput[]> = {
  [SalesforceCustomFieldTypes.CHECKBOX]: [],
  [SalesforceCustomFieldTypes.CURRENCY]: [precisionInput, decimalPlaceInput],
  [SalesforceCustomFieldTypes.DATE]: [],
  [SalesforceCustomFieldTypes.EMAIL]: [],
  [SalesforceCustomFieldTypes.MULTI_PICK_LIST]: [pickListValuesInput, visibleLinesInput],
  [SalesforceCustomFieldTypes.NUMBER]: [precisionInput, decimalPlaceInput],
  [SalesforceCustomFieldTypes.PERCENT]: [precisionInput, decimalPlaceInput],
  [SalesforceCustomFieldTypes.PHONE]: [],
  [SalesforceCustomFieldTypes.PICK_LIST]: [pickListValuesInput],
  [SalesforceCustomFieldTypes.TEXT]: [lengthInput],
  [SalesforceCustomFieldTypes.TEXTAREA]: [lengthInput, visibleLinesInput],
  [SalesforceCustomFieldTypes.TEXTAREA_LONG]: [lengthInput, visibleLinesInput],
  [SalesforceCustomFieldTypes.TEXTAREA_RICH]: [
    { ...lengthInput, subtitle: lengthInput.subtitle + ' Minimum length 256' },
    { ...visibleLinesInput, subtitle: visibleLinesInput.subtitle + ' Minimum 10' },
  ],
  [SalesforceCustomFieldTypes.TIME]: [],
  [SalesforceCustomFieldTypes.URL]: [],
};

const connectConfig: ActionConfig = {
  actionType: Action.SALESFORCE,
  name: 'Salesforce',
  description: 'Find, create, and update records in Salesforce.',
  icon: iconSvg,
  provider: ProviderType.SALESFORCE,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.CREATE_RECORD,
              label: 'Create record',
            },
            {
              value: Intent.UPDATE_RECORD,
              label: 'Update record',
            },
            {
              value: Intent.GET_RECORD_BY_ID,
              label: 'Get record by ID',
            },
            {
              value: Intent.SEARCH_RECORDS,
              label: 'Search records',
            },
            {
              value: Intent.WRITE_SOQL_QUERY,
              label: 'Search records by SOQL query',
            },
            { value: Intent.CREATE_CUSTOM_FIELD, label: 'Deploy custom field' },
            { value: Intent.CREATE_CUSTOM_OBJECT, label: 'Deploy custom object' },
            { value: Intent.GET_CONTACT_BY_VIEW_ID, label: 'Get records by List View' },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedRecordType:
        | SalesforceStandardRecord
        | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'recordType',
      )?.value;

      if (
        ![
          Intent.WRITE_SOQL_QUERY,
          Intent.CREATE_CUSTOM_OBJECT,
          Intent.GET_CONTACT_BY_VIEW_ID,
        ].includes(parameters.intent as Intent)
      ) {
        return {
          inputs: [
            {
              id: 'recordType',
              title: 'Record type',
              type: SidebarInputType.Enum,
              getValues: () => [
                ...Object.values(SalesforceStandardRecord).map(
                  (recordType: SalesforceStandardRecord) => ({
                    label: recordType,
                    value: recordType,
                  }),
                ),
                { label: 'Custom Object', value: RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME },
              ],
            },
            ...(selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
              ? [
                  {
                    type: SidebarInputType.TextArea,
                    id: 'objectName',
                    title: 'Object Name',
                    subtitle:
                      'The API Name of the Object to create. Use Connect Portal Workflow Settings to allow users to select a Field Mapping.',
                    placeholder: '{{settings.objectType}}',
                    required: true,
                    lines: 1,
                  } as SidebarInput,
                ]
              : []),
          ],
        };
      }
      return { inputs: [] };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedRecordType:
        | SalesforceStandardRecord
        | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'recordType',
      )?.value;

      if (
        !selectedRecordType &&
        ![
          Intent.WRITE_SOQL_QUERY,
          Intent.CREATE_CUSTOM_OBJECT,
          Intent.GET_CONTACT_BY_VIEW_ID,
        ].includes(parameters.intent as Intent)
      ) {
        return { inputs: [] };
      }

      let fieldInputs: SidebarInput[] = [];
      let jsonFieldInput: SidebarInput = additionalFieldsInput;
      if (selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME) {
        jsonFieldInput = {
          ...additionalFieldsInput,
          title: 'Record Fields',
          subtitle:
            'Use Connect Portal Workflow Settings to allow users to select a Field Mapping to specify which fields to update.',
        };
      } else if (selectedRecordType && selectedRecordType in defaultSalesforceFields) {
        fieldInputs = [
          ...(defaultSalesforceFields[selectedRecordType]?.(parameters.intent as Intent) ?? []),
        ];
      }

      switch (parameters.intent as Intent) {
        case Intent.CREATE_RECORD:
          return {
            inputs: [...fieldInputs, jsonFieldInput],
          };
        case Intent.UPDATE_RECORD:
          const recordIdInput: SidebarInput = {
            type: SidebarInputType.TextArea,
            id: 'recordId',
            title: 'Record ID',
            subtitle: 'The ID of the record to update.',
            required: true,
            lines: 1,
          };
          return {
            inputs: [
              recordIdInput,
              ...fieldInputs.map((input: SidebarInput) => ({
                ...input,
                required: false,
              })),
              jsonFieldInput,
            ],
          };
        case Intent.GET_RECORD_BY_ID:
          return {
            inputs: [
              ...(selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME ? fieldInputs : []),
              {
                type: SidebarInputType.TextArea,
                lines: 1,
                id: 'recordId',
                title: 'Record ID',
                required: true,
              },
            ],
          };
        case Intent.SEARCH_RECORDS:
          return {
            inputs: [
              {
                type: SidebarInputType.Conditional,
                id: 'filterFormula',
                title: 'Filter search',
                subtitle: 'Search for records that match specified filters.',
                getValues:
                  selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
                    ? undefined
                    : () =>
                        fieldInputs.map((input: SidebarInput) => ({
                          label: input.title,
                          value: input.id.slice('field-'.length),
                        })),
                supportedOperators,
                required: false,
              },
            ],
          };
        case Intent.WRITE_SOQL_QUERY:
          return {
            inputs: [
              {
                id: 'query',
                type: SidebarInputType.Code,
                language: 'sql',
                title: 'SOQL Query',
                subtitle: (
                  <>
                    Write a query using Salesforce Object Query Language (SOQL). Supports{' '}
                    <a
                      href="https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql.htm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SOQL SELECT Syntax.
                    </a>
                  </>
                ),
                placeholder: `SELECT Id, Name
FROM Account
WHERE Name = 'Ethan'`,
                required: true,
                useLightTheme: true,
                lines: 3,
              },
            ],
          };
        case Intent.CREATE_CUSTOM_FIELD:
          const selectedFieldType: SalesforceCustomFieldTypes = pickValueSourceByKey<DataType.ANY>(
            parameters.actionParameters,
            'type',
          )?.value;
          return {
            inputs: [
              {
                id: 'label',
                type: SidebarInputType.TextArea,
                title: 'Field Label',
                subtitle:
                  'Enter a label to be used on displays, page layouts, reports, and list views. Also determines the Field Name used for internal reference. For example, if the Field Label is “Email”, the Field Name will be “Email__c”.',
                lines: 1,
              },
              fieldTypesInput,
              ...(selectedFieldType ? CustomFieldTypesInputMap[selectedFieldType] ?? [] : []),
              {
                id: 'description',
                type: SidebarInputType.TextArea,
                title: 'Description',
                required: false,
                lines: 3,
              },
              {
                id: 'helperText',
                type: SidebarInputType.TextArea,
                title: 'Helper Text',
                subtitle:
                  'This text displays on detail and edit pages when users hover over the Info icon next to this field.',
                required: false,
                lines: 3,
              },
              selectedFieldType === SalesforceCustomFieldTypes.CHECKBOX
                ? {
                    id: 'defaultCheckboxValue',
                    type: SidebarInputType.BooleanInput,
                    title: 'Default Value',
                    defaultValue: false,
                    required: false,
                  }
                : {
                    id: 'defaultFieldValue',
                    type: SidebarInputType.TextArea,
                    title: 'Default Value',
                    required: false,
                    lines: 1,
                  },
            ],
          };
        case Intent.CREATE_CUSTOM_OBJECT:
          return {
            inputs: [
              {
                id: 'label',
                type: SidebarInputType.TextArea,
                title: 'Label',
                subtitle:
                  'Object Label to be used in tabs, page layouts, and reports. Also determines the Object Name used for internal reference. For example, if the Label is “Account”, the Object Name will be “Account__c”.',
                placeholder: 'Account',
                lines: 1,
                required: true,
              },
              {
                id: 'pluralLabel',
                type: SidebarInputType.TextArea,
                title: 'Plural Label',
                placeholder: 'Accounts',
                lines: 1,
                required: true,
              },
              {
                id: 'description',
                type: SidebarInputType.TextArea,
                title: 'Description',
                subtitle: 'A description of the Custom Object.',
                lines: 1,
                required: false,
              },
              {
                id: 'recordName',
                type: SidebarInputType.TextArea,
                title: 'Record Name',
                subtitle:
                  'The Record Name appears in page layouts, key lists, related lists, lookups, and search results. For example, the Record Name for Account is "Account Name" and for Case it is "Case Number".',
                placeholder: 'Account Name',
                lines: 1,
                required: true,
              },
            ],
          };
        case Intent.GET_CONTACT_BY_VIEW_ID:
          return {
            inputs: [
              {
                id: 'recordType',
                title: 'Record type',
                type: SidebarInputType.EditableEnum,
                required: true,
                subtitle:
                  'Use Connect Portal User Settings to allow users to select a Record Type.',
                getValues: () => [
                  ...Object.values(SalesforceStandardRecord).map(
                    (recordType: SalesforceStandardRecord) => ({
                      label: recordType,
                      value: recordType,
                    }),
                  ),
                ],
              },
              {
                id: 'listViewId',
                type: SidebarInputType.TextArea,
                title: 'List View ID',
                lines: 1,
                required: true,
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
