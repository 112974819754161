import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'meeting:read'
  | 'meeting:write'
  | 'webinar:read'
  | 'webinar:write'
  | 'recording:read'
  | 'recording:write'
  | 'user:read'
  | 'user:write'
  | 'user_zak:read'
  | 'chat_channel:read'
  | 'chat_channel:write'
  | 'chat_contact:read'
  | 'chat_message:read'
  | 'chat_message:write'
  | 'contact:read'
  | 'pac:read'
  | 'pac:write'
  | 'phone:read'
  | 'phone:write'
  | 'phone_call_control:read'
  | 'phone_call_control:write'
  | 'phone_e911:read'
  | 'phone_sms:read'
  | 'phone_sms:write'
  | 'tsp:read'
  | 'tsp:write';

export const scopes: Record<ScopeName, ScopeValue> = {
  'meeting:read': {
    name: 'meeting:read',
    description:
      '	This scope allows an app to view information related to the user’s meetings such as meeting reports, meeting participants, meeting polls and meeting registrants.',
    required: true,
  },
  'meeting:write': {
    name: 'meeting:write',
    description:
      'This scope allows an app to view and manage user’s meetings by performing actions such as scheduling meetings, updating meetings, deleting meetings and updating live streaming options.',
    required: true,
  },
  'user:read': {
    name: 'user:read',
    description:
      'This scope allows an app to view individual user’s profile information including user settings details, permissions that are associated with the user, user token that can be used to allow the user to join a ClientSDK meeting and user’s scheduling privilege information.',
    required: true,
  },
  'user:write': {
    name: 'user:write',
    description:
      'This scope allows an app to view individual user’s profile information including user settings details, permissions that are associated with the user, user token that can be used to allow the user to join a ClientSDK meeting and user’s scheduling privilege information.',
  },
  'user_zak:read': {
    name: 'user_zak:read',
    description: "View user's zak token",
  },
  'chat_channel:read': {
    name: 'chat_channel:read',
    description: 'This scope allows the app to view details of the chat channels joined by a user.',
  },
  'chat_channel:write': {
    name: 'chat_channel:write',
    description:
      "This scope allows the app to view and manage details of the chat channels joined by a user by performing actions on user's behalf such as creating a channel, updating channel details, inviting members to the channel, joining a channel, leaving a channel, removing members from a channel and removing the channel itself.",
  },
  'chat_contact:read': {
    name: 'chat_contact:read',
    description: 'This scope allows the app to retrieve details on chat contacts of a user.',
  },
  'chat_message:read': {
    name: 'chat_message:read',
    description:
      'This scope allows an app to view user’s chat messages and related information such as date and time of when it was sent, email address of the sender etc',
  },
  'chat_message:write': {
    name: 'chat_message:write',
    description:
      'This scope allows the app to send chat messages, update and delete previously sent messages on behalf of a user.',
  },
  'contact:read': {
    name: 'contact:read',
    description: "view user's contacts",
  },
  'pac:read': {
    name: 'pac:read',
    description:
      'This scope allows an app to view Personal Audio Conferencing(https://support.zoom.us/hc/en-us/articles/204517069-Getting-started-with-Personal-Audio-Conference) details of all users in an account such as dedicated dial-in numbers and global dial-in numbers associated with the account, conference ID and password(s) assigned to the account that are used to join the conference.',
  },
  'pac:write': {
    name: 'pac:write',
    description:
      'This scope allows the app to view and manage Personal Audio Conferencing details of all the users in an account.',
  },
  'phone:read': {
    name: 'phone:read',
    description:
      'This scope allows an app to view details related to Zoom Phone of a user such as phone numbers associated with the user, user’s Zoom Phone profile details including calling plans enabled for the user, email address of the user, site details, Zoom Phone voicemails, recordings of user’s Zoom phone calls etc.',
  },
  'phone:write': {
    name: 'phone:write',
    description: 'This scope allows an app to update a user’s Zoom Phone profile.',
  },
  'phone_call_control:read': {
    name: 'phone_call_control:read',
    description: 'view call control related information',
  },
  'phone_call_control:write': {
    name: 'phone_call_control:write',
    description: 'view and manage call control related information',
  },
  'phone_e911:read': {
    name: 'phone_e911:read',
    description: 'view your emergency call alert information',
  },
  'phone_sms:read': {
    name: 'phone_sms:read',
    description: 'view your Zoom Phone SMS information',
  },
  'phone_sms:write': {
    name: 'phone_sms:write',
    description: 'view and manage your Zoom Phone SMS information',
  },
  'tsp:read': {
    name: 'tsp:read',
    description:
      'This scope allows an app to view user’s TSP account information such as name of the Telephony Service Provider, dial in numbers that were set up for the user, conference code etc.',
  },
  'tsp:write': {
    name: 'tsp:write',
    description:
      'This scope allows an app to view and manage TSP information on behalf of a user. This scope allows the app to update a user’s TSP account information such as conference code, TSP bridge, dial-in numbers etc., set global dial-in URL as well delete a user’s TSP account.',
  },
  'webinar:read': {
    name: 'webinar:read',
    description:
      "	This scope allows an app to view user's Webinars details including information on polls, registrants, absentees, and panelists.",
  },
  'webinar:write': {
    name: 'webinar:write',
    description:
      'This scope allows an app to view and manage user’s Webinars by performing actions such as scheduling new Webinars for the user, updating Webinar details, deleting Webinars and managing Webinar registrants on the users’ behalf.',
  },
  'recording:read': {
    name: 'recording:read',
    description: 'View your recordings',
  },
  'recording:write': {
    name: 'recording:write',
    description: 'View and manage your recordings',
  },
};

export const requiredScopes: ScopeName[] = ['meeting:read', 'meeting:write', 'user:read'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Zoom',
  description: 'Sync meetings with Zoom',
  authUrl: 'https://zoom.us/oauth/authorize',
  accessTokenUrl: 'https://zoom.us/oauth/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/zoom`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
    {
      type: SidebarInputType.ValueText,
      title: 'Webhook Client Secret',
      id: 'webhookClientSecret',
      required: false,
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/zoom#add-your-zoom-app-to-paragon`,
      documentationLinkLabel: 'How to find Webhook Client Secret?',
    },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://marketplace.zoom.us/docs/api-reference/zoom-api',
};
