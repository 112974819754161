import conditionSerializers, {
  ConditionSerializer,
} from '@shared/actions/sdk/conditionSerializers';
import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { ActionStep, ActionStepParameters } from '@shared/types/sdk/actions';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import {
  DataType,
  KeyedSource,
  ResolvedActionParameters,
  WorkflowVariables,
} from '@shared/types/sdk/resolvers';

import { StepResolver } from './abstract.resolver';
import {
  getRequiredStepIdToResolveKeyedSources,
  isEmptyCondition,
  resolveConditionWrapper,
  resolveKeyedSources,
  resolveObjectMappingSource,
} from './resolvers.utils';

/**
 * a resolver for an action step's variables
 *
 * @export
 * @class ActionStepResolver
 * @extends {StepResolver<ActionStep, ActionStepParameters, ResolvedActionParameters>}
 */
export class ActionStepResolver extends StepResolver<ActionStep, ResolvedActionParameters> {
  /**
   * resolves the input parameters for the step
   *
   * @param {ActionStepParameters} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {ResolvedActionParameters}
   * @memberof ActionStepResolver
   */
  resolveInputParameters(
    parameters: ActionStepParameters,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): ResolvedActionParameters {
    if (conditionSerializers[parameters.actionType]) {
      parameters.actionParameters = parameters.actionParameters
        .filter((value: KeyedSource<DataType.ANY>) => {
          if (value.source.type === 'CONDITION' && isEmptyCondition(value.source.condition)) {
            return false;
          }

          return true;
        })
        .map((value: KeyedSource<DataType.ANY>) => {
          if (value.source.type !== 'CONDITION') {
            return value;
          }

          const actionConditionSerializer: ConditionSerializer<any> | undefined =
            conditionSerializers[parameters.actionType];
          return {
            ...value,
            source: {
              type: 'VALUE',
              value: actionConditionSerializer?.(
                resolveConditionWrapper(
                  value.source.condition,
                  variables,
                  secrets,
                  cachedConnectCredential,
                  context,
                  cachedPersona,
                ),
                value.key,
              ),
            },
          };
        });
    }

    const objectMapping = resolveObjectMappingSource(
      parameters.actionParameters,
      cachedConnectCredential,
      variables,
      context,
      secrets,
      cachedPersona,
    );

    const input: ResolvedActionParameters = resolveKeyedSources(
      variables,
      parameters,
      secrets,
      cachedConnectCredential,
      context,
      cachedPersona,
      'actionParameters',
    );

    return { ...input, objectMapping };
  }

  /**
   *
   * @param parameters
   * @returns
   */
  getRequiredStepIdsForVariableResolution(parameters: ActionStepParameters): string[] {
    return getRequiredStepIdToResolveKeyedSources(parameters.actionParameters);
  }
}
