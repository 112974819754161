import fetch from 'isomorphic-unfetch';

import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import {
  AuthenticationScheme,
  CredentialValues,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { debug } from '@shared/logger/sdk/legacy';
import { Action, ActionIntent, TEST_CONNECTION_INTENT_MAP } from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

import { RequestConfig, getAPIBaseURL, getProxyAgent } from '../services/api';

export class Zeus {
  constructor(private token: string) {}

  async createCredentials(
    scheme: AuthenticationScheme = AuthenticationScheme.BASIC,
    values: CredentialValues,
    name: string,
    projectId: string,
    provider: ProviderType,
  ): Promise<Response> {
    const path: string = `/projects/${projectId}/credentials`;

    return this.post(path, {
      name,
      values,
      provider,
      scheme,
    });
  }

  async updateCredentials(
    scheme: AuthenticationScheme = AuthenticationScheme.BASIC,
    values: CredentialValues,
    name: string,
    projectId: string,
    provider: ProviderType,
    credentialId: string,
  ): Promise<Response> {
    const path: string = `/projects/${projectId}/credentials/${credentialId}`;
    return this.patch(path, {
      name,
      values,
      provider,
      scheme,
    });
  }

  async get(path: string, data: { [key: string]: any } = {}): Promise<Response> {
    const params = new URLSearchParams();
    Object.entries(data).map(([key, value]: [string, string]) => params.append(key, value));

    const url: string = `${getAPIBaseURL()}${path}?${params.toString()}`;
    const config: RequestConfig = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
      },
      agent: getProxyAgent(),
    };

    return fetch(url, config);
  }

  async post(path: string, body: any = {}): Promise<Response> {
    const url: string = `${getAPIBaseURL()}${path}`;
    const config: RequestConfig = {
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        'X-Paragon-Fanout-Sequences': '1',
      },
      method: 'POST',
      agent: getProxyAgent(),
    };

    debug('zeus > post >', body);

    const response: Response = await fetch(url, config);

    return response;
  }

  async patch(path: string, body: any = {}): Promise<Response> {
    const url: string = `${getAPIBaseURL()}${path}`;
    const config: RequestConfig = {
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      agent: getProxyAgent(),
    };

    debug('zeus > patch >', body);

    const response: Response = await fetch(url, config);

    return response;
  }

  async triggerAction(
    action: Action,
    credentials: string[],
    intent: ActionIntent,
    parameters: KeyedSource<DataType.ANY>[],
    workflowId: string,
    ownerId: string,
  ): Promise<Response> {
    const path: string = `/triggers/${workflowId}/actions`;

    return this.post(path, {
      action,
      credentials,
      intent,
      parameters,
      workflowId,
      ownerId,
    });
  }

  async getActionTriggerInputs(
    action: Action,
    credentials: string[],
    intent: ActionIntent,
    parameters: KeyedSource<DataType.ANY>[],
    workflowId: string,
    ownerId: string,
    isConnectWorkflow?: boolean,
    integrationId?: string,
    cachedConnectCredential?: CachedConnectCredential,
    credentialId?: string,
    cachedPersona?: CachedPersona,
  ): Promise<Response> {
    const path: string = `/triggers/${workflowId}/actions`;

    return this.post(path, {
      action,
      credentials,
      intent,
      parameters,
      workflowId,
      isTrigger: true,
      isConnectWorkflow,
      integrationId,
      cachedConnectCredential,
      credentialId,
      ownerId,
      cachedPersona,
    });
  }

  async validateCredentials(
    action: Action,
    credentials: CredentialValues,
    workflowId: string,
    ownerId: string,
  ): Promise<Response> {
    const intent: ActionIntent | undefined = TEST_CONNECTION_INTENT_MAP[action];
    if (!intent) {
      throw new Error(`No test connection intent defined for ${action}`);
    }

    const credentialsSource: KeyedSource<DataType.ANY>[] = Object.entries(credentials).map(
      ([key, value]: [string, string | number | boolean]) => ({
        key,
        source: { type: 'VALUE', value: value },
      }),
    );

    return this.triggerAction(action, [], intent, credentialsSource, workflowId, ownerId);
  }
}
