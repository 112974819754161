import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import { CredentialKeys } from './config';
import iconSvg from './icon.svg';

export type ScopeName = 'openid' | 'email';

export const scopes: Record<ScopeName, ScopeValue> = {
  openid: {
    name: 'openid',
    required: true,
    description:
      'Provides access to OpenID Connect ID tokens and the OpenID Connect user info endpoint.',
  },
  email: {
    name: 'email',
    required: true,
    description:
      "Provides access to the user's email through the OpenID Connect user info endpoint.",
  },
};

export const requiredScopes: ScopeName[] = ['openid', 'email'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'BambooHR',
  description: 'Manage employees, time off, and benefits in BambooHR',
  authUrl:
    'https://documentation.bamboohr.com/page/single-sign-on-sso-with-openid-connect#auth-url',
  accessTokenUrl:
    'https://documentation.bamboohr.com/page/single-sign-on-sso-with-openid-connect#token-url',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/bamboo-hr`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
    {
      type: SidebarInputType.ValueText,
      title: 'Application Key',
      id: 'applicationKey',
    },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://documentation.bamboohr.com/page/authenticate-integration',
  endUserSuppliedValues: [
    {
      id: CredentialKeys.DOMAIN,
      title: 'BambooHR Domain',
      subtitle: 'Enter your BambooHR login domain name.',
      placeholder: 'domain',
      type: SidebarInputType.ValueText,
      suffixLabel: '.bamboohr.com',
      required: true,
    },
  ],
};
