import React from 'react';

import {
  createTestConnectionButton,
  getValuesByCacheKey,
  hasOAuthAppCredential,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { BalanceTransactionType, subscriptionStatus } from '../shared/types';

import { getPrices } from './sources';

const apiKeysPageLink = (
  <a href="https://dashboard.stripe.com/apikeys" target="_blank" rel="noreferrer">
    API Keys page
  </a>
);

export const authConfig: ActionConfig = {
  actionType: Action.STRIPE,
  provider: ProviderType.STRIPE,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your Stripe account',
  description: 'Add Stripe to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this Stripe account',
          placeholder: 'e.g. Production, Testing, etc.',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'STRIPE_API_SECRET_KEY',
          title: 'Add your Stripe secret API Key',
          subtitle: <>To create a new API key, visit your Stripe {apiKeysPageLink}.</>,
          placeholder: 'Stripe secret API Key',
          type: SidebarInputType.ValueText,
          required: true,
        },
      ],
      buttons: [createTestConnectionButton(Action.STRIPE, ['STRIPE_API_SECRET_KEY'])],
    },
  ],
  hideOAuthApps: false,
};

const generateMetadataInput = (id: string): SidebarInput => {
  return {
    id,
    title: 'Metadata',
    required: false,
    type: SidebarInputType.Code,
    lines: 1,
    language: 'json',
    useLightTheme: true,
    resizeOnOverflow: true,
    placeholder: '{ “field1”: 1, “field2”: 2}',
  };
};

const priceDynamicInput: SidebarInput = {
  id: 'plan',
  type: SidebarInputType.EditableDynamicEnum,
  title: 'Price',
  placeholder: 'select a price or type {{ to insert a price ID',
  required: true,
  source: getPrices,
  getValues: getValuesByCacheKey,
  hideSubtitle: false,
};

const priceInputTextarea: SidebarInput = {
  id: 'plan',
  type: SidebarInputType.TextArea,
  title: 'Price Id',
  lines: 1,
  required: true,
  placeholder: 'type {{ to insert a price ID',
};

const descriptionInput: SidebarInput = {
  id: 'description',
  type: SidebarInputType.TextArea,
  title: 'Description',
  required: false,
  lines: 1,
};

const createdAfterInput: SidebarInput = {
  id: 'createdAfter',
  type: SidebarInputType.TextArea,
  title: 'Created after',
  subtitle: 'Filters customers created after the provided date (Unix timestamp).',
  required: false,
  lines: 1,
};

const createdBeforeInput: SidebarInput = {
  id: 'createdBefore',
  type: SidebarInputType.TextArea,
  title: 'Created before',
  subtitle: 'Filters customers created before the provided date (Unix timestamp).',
  required: false,
  lines: 1,
};

const config: ActionConfig = {
  actionType: Action.STRIPE,
  name: 'Stripe',
  description: 'Find or create Stripe customers and subscriptions.',
  icon: iconSvg,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Connect your Stripe account',
          placeholder: 'choose your Stripe account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.STRIPE,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    title: 'Customers',
                    items: [
                      {
                        value: Intent.CREATE_CUSTOMER,
                        label: 'Create Customer',
                      },
                      {
                        value: Intent.UPDATE_CUSTOMER,
                        label: 'Update Customer',
                      },
                      {
                        value: Intent.GET_CUSTOMER_BY_ID,
                        label: 'Get Customer by ID',
                      },
                      {
                        value: Intent.GET_CUSTOMERS,
                        label: 'List Customers',
                      },
                    ],
                  },
                  {
                    title: 'Subscriptions',
                    items: [
                      {
                        value: Intent.CREATE_SUBSCRIPTION,
                        label: 'Create Subscription',
                      },
                      {
                        value: Intent.GET_SUBSCRIPTIONS,
                        label: 'List Subscriptions',
                      },
                    ],
                  },
                  {
                    title: 'Products',
                    items: [
                      {
                        value: Intent.CREATE_PRODUCT,
                        label: 'Create Product',
                      },
                      {
                        value: Intent.GET_PRODUCT_BY_ID,
                        label: 'Get Product by ID',
                      },
                      {
                        value: Intent.GET_PRODUCTS,
                        label: 'List Products',
                      },
                    ],
                  },
                  {
                    title: 'Balance Transactions',
                    items: [
                      {
                        value: Intent.GET_BALANCE_TRANSACTIONS,
                        label: 'List Balance Transaction',
                      },
                    ],
                  },
                  {
                    title: 'Plans',
                    items: [
                      {
                        value: Intent.GET_PLANS,
                        label: 'List Plans',
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_CUSTOMER:
          return {
            inputs: [
              {
                id: 'emailCreateCustomer',
                type: SidebarInputType.TextArea,
                title: 'Email address',
                required: true,
                lines: 1,
              },
              {
                id: 'name',
                type: SidebarInputType.TextArea,
                title: 'Name',
                required: false,
                lines: 1,
              },
              {
                id: 'description',
                type: SidebarInputType.TextArea,
                title: 'Description',
                required: false,
                lines: 1,
              },
              generateMetadataInput('metadataCreateCustomer'),
            ],
          };
        case Intent.UPDATE_CUSTOMER:
          return {
            inputs: [
              {
                id: 'customerId',
                type: SidebarInputType.TextArea,
                title: 'Customer ID',
                required: true,
                lines: 1,
              },
              {
                id: 'emailCreateCustomer',
                type: SidebarInputType.TextArea,
                title: 'Email address',
                required: false,
                lines: 1,
              },
              {
                id: 'name',
                type: SidebarInputType.TextArea,
                title: 'Name',
                required: false,
                lines: 1,
              },
              {
                id: 'description',
                type: SidebarInputType.TextArea,
                title: 'Description',
                required: false,
                lines: 1,
              },
              generateMetadataInput('metadataCreateCustomer'),
            ],
          };
        case Intent.GET_CUSTOMER_BY_ID:
          return {
            inputs: [
              {
                id: 'idGetCustomer',
                type: SidebarInputType.TextArea,
                title: 'Customer Id',
                required: true,
                lines: 1,
              },
            ],
          };
        case Intent.GET_CUSTOMERS:
          return {
            inputs: [
              {
                id: 'emailGetCustomers',
                type: SidebarInputType.TextArea,
                title: 'Email address',
                required: false,
                lines: 1,
              },
              {
                id: 'createdAfter',
                type: SidebarInputType.TextArea,
                title: 'Created after',
                subtitle: 'Filters customers created after the provided date (Unix timestamp).',
                required: false,
                lines: 1,
              },
              {
                id: 'createdBefore',
                type: SidebarInputType.TextArea,
                title: 'Created before',
                subtitle: 'Filters customers created before the provided date (Unix timestamp).',
                required: false,
                lines: 1,
              },
              {
                id: 'limitGetCustomers',
                type: SidebarInputType.TextArea,
                title: 'Limit customers',
                subtitle:
                  'Limit the maximum number of customers to return. Defaults to 10 if left blank.',
                required: false,
                lines: 1,
              },
            ],
          };
        case Intent.CREATE_SUBSCRIPTION:
          return {
            inputs: [
              {
                id: 'customerIdCreateSubscription',
                type: SidebarInputType.TextArea,
                title: 'Customer Id',
                required: true,
                lines: 1,
              },
              hasOAuthAppCredential(parameters, credentials)
                ? priceInputTextarea
                : priceDynamicInput,
              generateMetadataInput('metadataCreateSubscription'),
            ],
          };
        case Intent.GET_SUBSCRIPTIONS:
          return {
            inputs: [
              {
                id: 'customerIdGetSubscriptions',
                type: SidebarInputType.TextArea,
                title: 'Customer Id',
                required: false,
                lines: 1,
              },
              hasOAuthAppCredential(parameters, credentials)
                ? priceInputTextarea
                : priceDynamicInput,
              {
                id: 'status',
                type: SidebarInputType.EditableEnum,
                title: 'Status',
                required: false,
                placeholder: 'Select a status',
                getValues: (): EnumInputValue[] => subscriptionStatus,
                hideSubtitle: true,
              },
              {
                id: 'limitGetSubscriptions',
                type: SidebarInputType.TextArea,
                title: 'Limit subscriptions',
                subtitle:
                  'Limit the maximum number of subscriptions to return. Defaults to 10 if left blank.',
                required: false,
                lines: 1,
              },
            ],
          };
        case Intent.CREATE_PRODUCT:
          return {
            inputs: [
              {
                id: 'productName',
                type: SidebarInputType.TextArea,
                title: 'Name',
                lines: 1,
              },
              descriptionInput,
              generateMetadataInput('metadataProduct'),
            ],
          };
        case Intent.GET_PRODUCT_BY_ID:
          return {
            inputs: [
              {
                id: 'productId',
                type: SidebarInputType.TextArea,
                title: 'Product ID',
                lines: 1,
                required: true,
              },
            ],
          };
        case Intent.GET_PRODUCTS:
          return {
            inputs: [
              createdAfterInput,
              createdBeforeInput,
              {
                id: 'limitGetProducts',
                type: SidebarInputType.TextArea,
                title: 'Limit Products',
                subtitle:
                  'Limit the maximum number of products to return. Defaults to 10 if left blank.',
                required: false,
                lines: 1,
              },
            ],
          };
        case Intent.GET_BALANCE_TRANSACTIONS:
          return {
            inputs: [
              {
                id: 'balanceTransactionType',
                title: 'Type',
                type: SidebarInputType.Enum,
                required: false,
                subtitle: 'Only returns transactions of the given type.',
                getValues: (): EnumInputValue<BalanceTransactionType>[] => [
                  {
                    value: 'charge',
                    label: 'Charge',
                  },
                  {
                    value: 'refund',
                    label: 'Refund',
                  },
                  {
                    value: 'payment',
                    label: 'Payment',
                  },
                  {
                    value: 'payment_refund',
                    label: 'Payment Refund',
                  },
                ],
              },
            ],
          };
        case Intent.GET_PLANS:
          return {
            inputs: [
              {
                id: 'isPlanActive',
                title: 'Active',
                type: SidebarInputType.BooleanInput,
                required: false,
                subtitle:
                  'Select true to list all active plans or false to list all inactive plans.',
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
