import { ICredential, ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  IntentInputDesignType,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { WorkflowType } from '@shared/types/sdk/execution';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { authInput, listInput, timezoneInput } from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.PARDOT,
  name: 'Pardot',
  description: 'Trigger when records are created Pardot',
  icon: iconSvg,
  provider: ProviderType.PARDOT,
  sidebarSections: [
    (
      _parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      return {
        inputs: workflowType === WorkflowType.CLASSIC ? [authInput] : [],
      };
    },
    (
      parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      if (parameters.credentials.length === 0 && workflowType === WorkflowType.CLASSIC) {
        return { inputs: [] };
      }

      return {
        inputs: [
          {
            id: 'intent',
            title: 'Choose a Trigger Event',
            type: SidebarInputType.Intent,
            designType: IntentInputDesignType.WorkflowSettingsDesign,
            icon: iconSvg,
            values: [
              {
                value: Intent.TRIGGER_PROSPECT_CREATED,
                label: 'Prospect Created',
                subtitle: 'Trigger when a new prospect is created in Pardot',
              },
              {
                value: Intent.TRIGGER_LIST_MEMBERSHIP_CREATED,
                label: 'List Membership Created',
                subtitle: 'Trigger when a prospect is added to a list in Pardot',
              },
            ],
          },
        ],
      };
    },
    (
      parameters: ActionStepParameters,
      _credentials: Record<string, ICredential>,
      workflowType?: WorkflowType,
    ): SidebarSection => {
      if (parameters.credentials.length === 0 && workflowType === WorkflowType.CLASSIC) {
        return { inputs: [] };
      }
      return {
        inputs: [
          timezoneInput,
          ...(parameters.intent === Intent.TRIGGER_LIST_MEMBERSHIP_CREATED ? [listInput] : []),
        ],
      };
    },
  ],
};

export default connectConfig;
