import axios, { AxiosInstance } from 'axios';

import { DateOperator, SearchResponse, ZendeskPaginationResponse } from './types';

/**
 * @summary creates an axios client for api calls to zendesk.
 * Also sets the headers for authorization
 * @param subdomain subdomain for zendesk ex- https://usepargon.zendesk.com
 * @param accessToken
 * @returns axiosInstance
 */
export function getZendeskClient(subdomain: string, accessToken?: string): AxiosInstance {
  return getAxiosInstance(`https://${subdomain}.zendesk.com`, accessToken);
}

/**
 * @summary creates an axios instance with auth headers and baseurl
 * @param baseUrl
 * @param accessToken
 * @returns AxiosInstance
 */
function getAxiosInstance(baseUrl?: string, accessToken?: string): AxiosInstance {
  const accessTokenHeader: { Authorization?: string } = {};
  if (accessToken) {
    accessTokenHeader.Authorization = `Bearer ${accessToken}`;
  }

  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      ...accessTokenHeader,
    },
  });
}

/**
 * scopes required for zendesk to work
 */
export const zendeskScopes: string[] = ['read', 'write'];

/**
 * used in getting users
 */
export const MAX_RESULTS = 100;

function isZendeskPaginationResponse(
  response: ZendeskPaginationResponse | SearchResponse<Record<string, any>>,
): response is ZendeskPaginationResponse {
  return (response as ZendeskPaginationResponse).data.meta !== undefined;
}

/**
 * @summary helper function to handle cursor pagination from zendesk api. Keeps on getting response
 * until there are no more results.
 * @param zendeskResponse response from api call to zendesk api for getting resources
 * @param accessToken
 * @returns Array of zendesk api responses.
 */
export const fetchMoreResults = async <
  T extends ZendeskPaginationResponse | SearchResponse<Record<string, any>>,
>(
  zendeskResponse: T,
  accessToken: string,
): Promise<T[]> => {
  let allResults: T[] = [];
  if (isZendeskPaginationResponse(zendeskResponse)) {
    // if type is Zendesk Pagination response in case of get users
    while (isZendeskPaginationResponse(zendeskResponse) && zendeskResponse.data.meta.has_more) {
      zendeskResponse = await getAxiosInstance('', accessToken).get(
        zendeskResponse.data.links.next as string,
      );
      allResults = [...allResults, zendeskResponse];
    }
  } else {
    // type is SearchResponse in case of search Tickets
    while (zendeskResponse.data.next_page) {
      zendeskResponse = await getAxiosInstance('', accessToken).get(zendeskResponse.data.next_page);
      allResults = [...allResults, zendeskResponse];
    }
  }

  return allResults;
};

/**
 * @summary helper function to log errors
 * @param error Error object
 * @param message string custom message to be logged with error
 * @returns formatted error object
 */
export const getFormattedError = (error: Record<string, any>, message?: string): Error => {
  return new Error(
    `Zendesk API error: ${message}: ${
      error.response ? JSON.stringify(error.response.data) : error.message
    }`,
  );
};

/**
 * @summary parses a string into javascript objects and arrays. Throws appropriate errors
 * @param value json string
 * @param key name of the field to display in error.
 */
export function parseJson(jsonString: string, key: string): any {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    throw new Error(`Incorrect JSON. Unable to parse ${key}`);
  }
}

/**
 * date operators used in UI config and search Tickets function
 */
export const dateOperators: DateOperator[] = [
  {
    label: 'On',
    value: 'EQUALS',
    symbol: ':',
  },
  {
    label: 'On or before',
    value: 'LESS_THAN_EQUALS',
    symbol: '<',
  },
  {
    label: 'On or after',
    value: 'GREATER_THAN_EQUALS',
    symbol: '>',
  },
];
