import React from 'react';

import { getValuesByCacheKey, pickValueSourceByKey } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicConditionalInput,
  DynamicDataSource,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import {
  DEFAULT_SUPPORTED_OPERATORS,
  DataType,
  KeyedSource,
  Operator,
} from '@shared/types/sdk/resolvers';

import { Intent } from '../../configs';
import { OperationOption } from '../../shared/types';

export const getEnumValues = (
  columns:
    | {
        [key: string]: DataType;
      }
    | undefined,
): EnumInputValue[] => {
  if (!columns) {
    return [];
  }
  return Object.keys(columns).map((fieldName: string) => ({
    label: fieldName,
    value: fieldName,
  }));
};

const fetchCollectionsAction: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Collection',
  cacheKey: 'collections',
  refreshDependencies: [(options: ActionStepParameters) => options.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => ({
    actionType: Action.MONGODB,
    intent: Intent.LIST_COLLECTIONS,
    credentials: options.credentials,
    actionParameters: [],
  }),
  mapRefreshToValues: (response: any): EnumInputValue[] =>
    response?.result?.output.map((collectionName: string) => ({
      label: collectionName,
      value: collectionName,
    })),
};

export const fetchColumnsAction: DynamicDataSource<{ [key: string]: DataType }> = {
  type: DataSourceType.DYNAMIC,
  title: 'Column',
  cacheKey: 'columns',
  refreshDependencies: ['collectionName'],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => ({
    actionType: Action.MONGODB,
    intent: Intent.INFER_COLLECTION_SCHEMA,
    credentials: options.credentials,
    actionParameters: options.actionParameters.filter(
      (value: KeyedSource<DataType.ANY>) => value.key === 'collectionName',
    ),
  }),
  mapRefreshToValues: (response: any): { [key: string]: DataType } => {
    return response?.result?.output || {};
  },
};

const link = (
  <a
    href="https://docs.mongodb.com/manual/reference/mongodb-extended-json/"
    target="_blank"
    rel="noreferrer"
  >
    {' '}
    MongoDB Extended JSON syntax
  </a>
);
export const queryInput: SidebarInput = {
  id: 'query',
  title: 'Query',
  subtitle: <>Write your MongoDB query. Supports {link}</>,
  type: SidebarInputType.Code,
  placeholder: '{fieldname: "fieldvalue"}',
  language: 'json',
  lines: 3,
  useLightTheme: true,
};

export const fieldsInput: SidebarInput = {
  id: 'fields',
  title: 'Fields',
  subtitle: 'Choose which fields should be included in the response. Defaults to all.',
  placeholder: '{ “field1”: 1, “field2”: 2}',
  required: false,
  type: SidebarInputType.Code,
  lines: 1,
  language: 'json',
  useLightTheme: true,
  resizeOnOverflow: true,
};

export const limitInput: SidebarInput = {
  id: 'limit',
  title: 'Limit',
  required: false,
  type: SidebarInputType.TextArea,
  lines: 1,
};

export const skipInput: SidebarInput = {
  id: 'skip',
  title: 'Skip',
  required: false,
  type: SidebarInputType.TextArea,
  lines: 1,
};

export const sortInput: SidebarInput = {
  id: 'sort',
  title: 'Sort',
  required: false,
  type: SidebarInputType.Code,
  lines: 1,
  language: 'json',
  useLightTheme: true,
  resizeOnOverflow: true,
};

const keyInput: SidebarInput = {
  id: 'key',
  title: 'Key',
  subtitle: 'The key for which to return distinct values.',
  required: true,
  type: SidebarInputType.TextArea,
  lines: 1,
};
export const updateQueryInput: SidebarInput = {
  id: 'updateQuery',
  title: 'Update Query',
  subtitle: <>Write your MongoDB update query. Supports {link}</>,
  type: SidebarInputType.Code,
  placeholder: '{ $set: {fieldname: "fieldvalue"} }',
  language: 'json',
  lines: 3,
  useLightTheme: true,
};

export const OperationOptionInputs: Record<OperationOption, SidebarInput> = {
  query: queryInput,
  skip: skipInput,
  limit: limitInput,
  sort: sortInput,
  fields: fieldsInput,
  key: keyInput,
  'update query': updateQueryInput,
};

export const collectionNameInput: SidebarInput = {
  id: 'collectionName',
  title: 'Collection',
  subtitle: 'Choose a collection in your MongoDB database to use.',
  type: SidebarInputType.DynamicEnum,
  source: fetchCollectionsAction,
  getValues: getValuesByCacheKey,
  required: true,
};

export const queryOptionsInput: DynamicConditionalInput<{ [key: string]: DataType }> = {
  id: 'queryOptions',
  title: 'Filter Records',
  subtitle:
    'You can filter which documents get returned from MongoDB by applying one or more filters below.',
  type: SidebarInputType.DynamicConditional,
  supportedOperators: [
    ...DEFAULT_SUPPORTED_OPERATORS.filter(
      (op: Operator) =>
        // TODO: (PARA-1411) add MongoDB support for `ArrayIsEmpty` and `ArrayIsNotEmpty` operators
        ![
          Operator.ArrayIsEmpty,
          Operator.ArrayIsNotEmpty,
          Operator.StringIsIn,
          Operator.StringIsNotIn,
          Operator.Exists,
          Operator.DoesNotExist,
        ].includes(op),
    ),
    Operator.ArrayIsIn,
    Operator.ArrayIsNotIn,
  ],
  source: fetchColumnsAction,
  getValues: (options: ActionStepParameters): EnumInputValue[] => {
    const columns = pickValueSourceByKey(options.actionParameters, 'columns');
    return getEnumValues(columns?.value);
  },
  required: false,
};
