import { css } from 'styled-components';

import { COLORS } from './constants';

export const FontPrimary = css`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 15px;
`;

export const FontGrey = css`
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: ${COLORS.GREY};
  padding: 10px;
`;
