import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { ObjectMappingInput, WorkflowVariables } from '@shared/types/sdk/resolvers';
import { EventInput, EventStep } from '@shared/types/sdk/steps';

import { StepResolver } from './abstract.resolver';
import { resolveObjectMappingSource } from './resolvers.utils';

/**
 * a resolver for an event step's variables
 *
 * @export
 * @class EventStepResolver
 * @extends {StepResolver<EventStep, EventInput>}
 */
export class EventStepResolver extends StepResolver<EventStep, EventInput> {
  /**
   * resolves the input parameters for the step
   *
   * @param {ActionStepParameters} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {EventInput}
   * @memberof EventStepResolver
   */
  resolveInputParameters(
    parameters: EventStep['parameters'],
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): EventInput {
    const objectMappingInput: ObjectMappingInput | undefined =
      parameters.objectMapping &&
      resolveObjectMappingSource(
        parameters.objectMapping,
        cachedConnectCredential,
        variables,
        context,
        secrets,
        cachedPersona,
      );

    return { ...context.triggerInput, objectMappingInput } as EventInput;
  }

  getRequiredStepIdsForVariableResolution(_: EventStep['parameters']): string[] {
    return [];
  }
}
