import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../../configs';
import { SearchableLeadFields } from '../../shared/utils';
import { getFields, getLists } from '../sources';

export const leadIdInput: SidebarInput = {
  id: 'leadId',
  type: SidebarInputType.TextArea,
  title: 'Lead ID',
  lines: 1,
};

export const emailInput: SidebarInput = {
  id: 'email',
  type: SidebarInputType.TextArea,
  title: 'Email',
  subtitle: 'If there already exists a lead with this email, it will be updated.',
  lines: 1,
};

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  type: SidebarInputType.TextArea,
  title: 'First Name',
  lines: 1,
  required: false,
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  type: SidebarInputType.TextArea,
  title: 'Last Name',
  lines: 1,
  required: false,
};

export const companyInput: SidebarInput = {
  id: 'company',
  type: SidebarInputType.TextArea,
  title: 'Company',
  lines: 1,
  required: false,
};

export const titleInput: SidebarInput = {
  id: 'title',
  type: SidebarInputType.TextArea,
  title: 'Title',
  lines: 1,
  required: false,
};

export const phoneInput: SidebarInput = {
  id: 'phone',
  type: SidebarInputType.TextArea,
  title: 'Phone',
  lines: 1,
  required: false,
};

export const websiteInput: SidebarInput = {
  id: 'website',
  type: SidebarInputType.TextArea,
  title: 'Website',
  lines: 1,
  required: false,
};

export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  title: 'Additional Fields',
  subtitle: 'Specify any other fields that should be updated in JSON below.',
  placeholder: `{
"property_number": "17",
"property_dropdown": "choice_b",
"property_radio": "option_1",
"property_string": "value",
"property_date": "1572480000000"
}`,
  useLightTheme: true,
  lines: 5,
  required: false,
};

export const listInput: SidebarInput = {
  id: 'listId',
  type: SidebarInputType.TextArea,
  title: 'List',
  lines: 1,
  required: false,
};

export const leadIdsInput: SidebarInput = {
  id: 'leadIds',
  type: SidebarInputType.TextArea,
  title: 'Lead IDs',
  subtitle:
    'IDs of leads to be added to the List (up to 300). You can enter a single ID or an array of IDs.',
  lines: 1,
};

export const leadFilterInput: SidebarInput = {
  id: 'filterFormula',
  type: SidebarInputType.Conditional,
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  getValues: () => SearchableLeadFields,
  supportedOperators: [Operator.StringIsIn],
  disableMultipleConditions: true,
  required: false,
};

export const leadDynamicFilterInput: SidebarInput = {
  ...leadFilterInput,
  type: SidebarInputType.DynamicConditional,
  source: getFields,
  getValues: getValuesByCacheKey,
};

export const dynamicListInput: SidebarInput = {
  ...listInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getLists,
  getValues: getValuesByCacheKey,
};

export const customObjectNameInput: SidebarInput = {
  id: 'customObjectName',
  type: SidebarInputType.TextArea,
  title: 'Object Name',
  subtitle:
    'The API Name of the Object to create. Use Connect Portal Workflow Settings to allow users to select a Custom Object Mapping.',
  placeholder: '{{settings.objectType}}',
  lines: 1,
};

export const cutomObjectRecordFieldsInput: SidebarInput = {
  id: 'recordFields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  useLightTheme: true,
  title: 'Record Fields',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select a Custom Object Mapping to specify which fields to update.',
  placeholder: `{
"property_number": "17",
"property_dropdown": "choice_b",
"property_radio": "option_1",
"property_string": "value",
"property_date": "1572480000000"
}`,
};

export const intentInput: SidebarInput = {
  id: 'intent',
  title: 'Choose an action',
  type: SidebarInputType.Intent,
  values: [
    {
      title: 'Leads',
      items: [
        {
          value: Intent.CREATE_OR_UPDATE_LEAD,
          label: 'Create or Update Lead',
        },
        {
          value: Intent.GET_LEADS,
          label: 'Get Leads',
        },
        {
          value: Intent.GET_LEAD_BY_ID,
          label: 'Get Lead by ID',
        },
      ],
    },
    {
      title: 'Lists',
      items: [
        {
          value: Intent.ADD_LEADS_TO_LIST,
          label: 'Add Leads to List',
        },
      ],
    },
    {
      title: 'Custom Objects',
      items: [
        {
          value: Intent.CREATE_CUSTOM_OBJECT,
          label: 'Create Custom Object',
        },
      ],
    },
  ],
};
