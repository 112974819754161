import React from 'react';

import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { CredentialsKey, Intent } from '../configs';

import { getBoards, getMembers } from './sources';

export const intentTypeInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Items',
          items: [
            {
              value: Intent.CREATE_ITEM,
              label: 'Create Item',
            },
            {
              value: Intent.UPDATE_ITEM,
              label: 'Update Item',
            },
            {
              value: Intent.GET_ITEM_BY_ID,
              label: 'Get Item By ID',
            },
            {
              value: Intent.GET_ITEM_BY_EXTERNAL_ID,
              label: 'Get Item By External ID',
            },
            {
              value: Intent.SEARCH_ITEMS,
              label: 'Search Items',
            },
            {
              value: Intent.DELETE_ITEM,
              label: 'Delete Item',
            },
            {
              value: Intent.ARCHIVE_ITEM,
              label: 'Archive Item',
            },
            {
              value: Intent.CREATE_SUBITEM,
              label: 'Create Subitem',
            },
          ],
        },
        {
          title: 'Users',
          items: [
            {
              value: Intent.SEARCH_USERS,
              label: 'Search Users',
            },
          ],
        },
      ],
    },
  ],
};

export const nameInput: SidebarInput = {
  id: 'name',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const boardInput: SidebarInput = {
  id: 'boardId',
  title: 'Board ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const itemIdInput: SidebarInput = {
  id: 'itemId',
  title: 'Item ID',
  type: SidebarInputType.TextArea,
  subtitle: 'The ID of the item to get.',
  lines: 1,
  required: true,
};

export const groupIdInput: SidebarInput = {
  id: 'groupId',
  title: 'Group ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const memberIdInput: SidebarInput = {
  id: 'userId',
  title: 'User ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const externalIdInput: SidebarInput = {
  id: 'externalId',
  title: 'External ID',
  type: SidebarInputType.TextArea,
  subtitle:
    'An ID from your application to associate this item with. You can use this ID to sync updates to this task later.',
  lines: 1,
  required: false,
};

export const columnValuesInput: SidebarInput = {
  id: 'columnValues',
  title: 'Column Values',
  subtitle:
    'The column values of the new item. Use Connect Portal Workflow Settings to allow users to select which item columns to update.',
  type: SidebarInputType.Code,
  required: false,
  language: 'json',
  placeholder: `{
  "status": "Done",
  "person": {
    "personsAndTeams": [
      {
        "id": 12345678,
        "kind": "person"
      }
    ]
  }
}`,
  lines: 6,
  useLightTheme: true,
};

export const dynamicBoardInput: SidebarInput = {
  ...boardInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getBoards,
  subtitle: 'Select a board to create items in',
  getValues: getValuesByCacheKey,
};

export const dynamicUserInput: SidebarInput = {
  ...memberIdInput,
  subtitle: 'Select a user to assign tasks to',
  type: SidebarInputType.EditableDynamicEnum,
  source: getMembers,
  getValues: getValuesByCacheKey,
  required: false,
};

export const getItemFieldInputsConnect = (isUpdated: boolean): SidebarInput[] => {
  return [
    ...(isUpdated
      ? [
          { ...itemIdInput, subtitle: 'The ID of the item to update.' },
          { ...nameInput, required: false },
        ]
      : [nameInput]),
    boardInput,
    ...(!isUpdated ? [groupIdInput] : []),
    externalIdInput,
    {
      ...columnValuesInput,
      subtitle: isUpdated
        ? 'The column values of the update item. Use Connect Portal Workflow Settings to allow users to select which item columns to update.'
        : columnValuesInput.subtitle,
    },
  ];
};

export const getItemFieldInputsAutomate = (isUpdated: boolean): SidebarInput[] => {
  return [
    ...(isUpdated
      ? [
          { ...itemIdInput, subtitle: 'The ID of the item to update.' },
          { ...nameInput, required: false },
        ]
      : [nameInput]),
    {
      ...dynamicBoardInput,
      subtitle: isUpdated ? 'Select a board to update items in' : dynamicBoardInput.subtitle,
    },
    ...(!isUpdated ? [groupIdInput] : []),
    externalIdInput,
    {
      ...columnValuesInput,
      subtitle: isUpdated
        ? 'The column values of the update item.'
        : 'The column values of the new item.',
    },
  ];
};

export const LinkWrapper = ({ text }: { text: string }) => (
  <a href="http://monday.com" target="_blank" rel="noreferrer">
    {text}
  </a>
);

export const apiTokenInput: SidebarInput = {
  id: CredentialsKey.API_TOKEN,
  title: 'Add your Monday.com API token',
  subtitle: (
    <>
      You can find your API token in the Admin tab of your <LinkWrapper text="Monday.com" /> account
      under the API section.
    </>
  ),
  type: SidebarInputType.ValueText,
  placeholder: 'Monday API Token',
  required: true,
};
