import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import { RecordType } from '../shared/types';

export const vendorAccountInput: SidebarInput = {
  id: 'VendorAccountNumber',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Vendor Account',
  required: true,
  subtitle: 'Specify a unique identifier for the Vendor Account.',
};

export const NameInput: SidebarInput = {
  id: 'VendorName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  required: true,
  subtitle: 'Specify the display name of the Vendor.',
};

export const groupInput: SidebarInput = {
  id: 'VendorGroupId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Group',
  required: true,
  subtitle: 'Specify the Vendor Group for the new vendor.',
};

export const organizationNumberInput: SidebarInput = {
  id: 'OrganizationNumber',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Organization Number',
  required: false,
};

export const typeInput = (defaultValue?: string): SidebarInput => {
  return {
    id: 'VendorPartyType',
    type: SidebarInputType.EditableEnum,
    title: 'Type',
    required: false,
    subtitle: 'Specify the type of vendor.',
    defaultValue,
    getValues: () => [
      { label: 'Organization', value: 'Organization' },
      { label: 'Person', value: 'Person' },
    ],
  };
};

export const additionalFieldsInput: SidebarInput = {
  id: 'vendorAdditionalFields',
  type: SidebarInputType.Code,
  lines: 4,
  title: 'Additional Fields',
  language: 'json',
  useLightTheme: true,
  required: false,
  subtitle:
    'Specify additional fields to include on this vendor as JSON. For example, you may specify a different billing address here. See the metadata for available fields.',
  placeholder: `[
    {
      "AddressCity": "Seattle",
      "AddressZipCode": "98104",
    },
  ]`,
};

export const billAdditionalFieldsInput: SidebarInput = {
  id: 'billAdditionalFields',
  type: SidebarInputType.Code,
  lines: 4,
  title: 'Additional Fields',
  language: 'json',
  useLightTheme: true,
  required: false,
  subtitle:
    'Specify additional fields to include on this bill as JSON. For example, you may specify a cash discount date here. See the metadata for available fields.',
  placeholder: `[
    {
      "CashDiscountDate": "2022-10-01",
      "PrimaryContactTwitter": "iamjohn@twitter"
    },
  ]`,
};

export const customerAdditionalFieldsInput: SidebarInput = {
  id: 'customerAdditionalFields',
  type: SidebarInputType.Code,
  lines: 4,
  title: 'Additional Fields',
  language: 'json',
  useLightTheme: true,
  required: false,
  subtitle:
    'Specify additional fields to include on this customer as JSON. For example, you may specify a different billing address here. See the metadata for available fields.',
  placeholder: `[
    {
      "InvoiceAddressCity": "N.Y",
      "PrimaryContactTwitter": "iamjohn@twitter"
    },
  ]`,
};

export const vendorFilterInput: SidebarInput = {
  id: 'vendorFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.StringContains,
  ],
  getValues: () => [
    { label: 'Vendor Account', value: 'VendorAccountNumber' },
    { label: 'Vendor Name', value: 'VendorName' },
    { label: 'Vendor Group', value: 'VendorGroupId' },
    { label: 'Organization Number', value: 'OrganizationNumber' },
    { label: 'Email', value: 'PrimaryEmailAddress' },
  ],
};

export const companyInput: SidebarInput = {
  id: 'dataAreaId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Company',
  required: true,
  subtitle: 'Specify the company code of the company incurring the bill.',
  placeholder: 'dat',
};

export const invoiceAccountInput: SidebarInput = {
  id: 'InvoiceAccount',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Invoice Account',
  required: true,
  subtitle: 'Specify the vendor account sending the bill.',
};

export const invoiceNumberInput: SidebarInput = {
  id: 'InvoiceNumber',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Invoice Number',
  required: true,
  subtitle: 'Specify an internal invoice identification number.',
  placeholder: '001',
};

export const invoiceDescriptionInput: SidebarInput = {
  id: 'InvoiceDescription',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Invoice Description',
  required: false,
};

export const invoiceDateInput: SidebarInput = {
  id: 'InvoiceDate',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Invoice Date',
  required: false,
  placeholder: '2022-01-01',
};

export const postingDateInput: SidebarInput = {
  id: 'Date',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Posting Date',
  required: false,
  placeholder: '2022-01-01',
};

export const dueDateInput: SidebarInput = {
  id: 'DueDate',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Due Date',
  required: false,
  placeholder: '2022-01-01',
};

export const onHoldInput: SidebarInput = {
  id: 'IsOnHold',
  type: SidebarInputType.EditableEnum,
  title: 'Invoice On Hold Status',
  required: false,
  getValues: () => [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ],
};

export const billIdInput = (subtitle: string): SidebarInput => {
  return {
    id: 'billId',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Bill Header Reference',
    required: true,
    subtitle,
    placeholder: 'DAT-000055',
  };
};
export const billFilterInput: SidebarInput = {
  id: 'billFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.StringContains,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
    Operator.BooleanFalse,
    Operator.BooleanTrue,
  ],
  getValues: () => [
    { label: 'Invoice Account', value: 'InvoiceAccount' },
    { label: 'Invoice Number', value: 'InvoiceNumber' },
    { label: 'Invoice Date', value: 'InvoiceDate' },
    { label: 'Posting Date', value: 'Date' },
    { label: 'Due Date', value: 'DueDate' },
    { label: 'On hold status', value: 'IsOnHold' },
  ],
};

export const parentBillIDInput: SidebarInput = {
  id: 'HeaderReference',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Parent Bill Header Reference',
  required: true,
  placeholder: 'DAT-000052',
  subtitle: 'Specify the Header Reference of the Parent Bill.',
};

export const itemNumberInput: SidebarInput = {
  id: 'ItemNumber',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Item Number',
  required: true,
};

export const procurementCategoryInput: SidebarInput = {
  id: 'ProcurementCategoryName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Procurement Category',
  required: false,
  placeholder: 'CORP PROCUREMENT CATEGORIES',
};

export const quantityInput: SidebarInput = {
  id: 'ReceiveNow',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Quantity',
  required: false,
  placeholder: '1',
};

export const unitInput: SidebarInput = {
  id: 'Unit',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Unit',
  required: false,
};

export const billLineItemIdInput = (subtitle: string): SidebarInput => {
  return {
    id: 'InvoiceLineNumber',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Invoice Line Number',
    required: true,
    subtitle,
    placeholder: '2',
  };
};

export const billLineItemFilterInput: SidebarInput = {
  id: 'billLineItemFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.StringContains,
    Operator.NumberEquals,
    Operator.NumberDoesNotEqual,
    Operator.NumberGreaterThan,
    Operator.NumberLessThan,
  ],
  getValues: () => [
    { label: 'Item Number', value: 'ItemNumber' },
    { label: 'Item name', value: 'ItemName' },
    { label: 'Procurement category', value: 'ProcurementCategoryName' },
    { label: 'Unit price', value: 'UnitPrice' },
    { label: 'Parent Bill Header Reference', value: 'HeaderReference' },
  ],
};

export const customerAccountInput: SidebarInput = {
  id: 'CustomerAccount',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Customer Account',
  required: true,
  subtitle: 'Specify a unique identifier for the Customer Account.',
};

export const customerNameInput: SidebarInput = {
  id: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  required: true,
};

export const customerGroupInput: SidebarInput = {
  id: 'CustomerGroupId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Customer Group',
  required: true,
  subtitle: 'Specify the Customer Group for the new customer.',
};

export const currencyInput = (defaultValue?: string): SidebarInput => {
  return {
    id: 'SalesCurrencyCode',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Currency',
    required: false,
    defaultValue,
    subtitle: 'The three-letter currency code associated with this customer.',
  };
};

export const countryInput = (defaultValue?: string): SidebarInput => {
  return {
    id: 'PartyCountry',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Country',
    required: false,
    defaultValue,
  };
};

export const streetAddressInput: SidebarInput = {
  id: 'AddressStreet',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Street Address',
  required: false,
};

export const cityInput: SidebarInput = {
  id: 'AddressCity',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'City',
  required: false,
};

export const zipCodeInput: SidebarInput = {
  id: 'AddressZipCode',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Zip Code',
  required: false,
};

export const phoneNumberInput: SidebarInput = {
  id: 'PrimaryContactPhone',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Phone Number',
  required: false,
};

export const emailInput: SidebarInput = {
  id: 'PrimaryContactEmail',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Email Address',
  required: false,
};

export const customerFilterInput: SidebarInput = {
  id: 'customerFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.StringContains,
  ],
  getValues: () => [
    { label: 'Customer Account', value: 'CustomerAccount' },
    { label: 'Customer Name', value: 'Name' },
    { label: 'Customer Group', value: 'CustomerGroupId' },
    { label: 'Organization Number', value: 'OrganizationNumber' },
    { label: 'Customer Type', value: 'PartyType' },
  ],
};

export const invoiceNameInput: SidebarInput = {
  //see refrernce in ui
  id: 'InvoiceName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  required: false,
};

export const methodoFPaymentInput: SidebarInput = {
  id: 'MethodOfPayment',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Method of Payment',
  required: false,
};

export const termsOfPaymentInput: SidebarInput = {
  id: 'TermsOfPayment',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Terms of Payment',
  required: false,
};

export const oneTimeCustomerInput: SidebarInput = {
  id: 'IsOneTimeCustomer',
  type: SidebarInputType.EditableEnum,
  title: 'One-time customer',
  required: false,
  getValues: () => [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ],
};

export const invoiceIdInput = (subtitle: string): SidebarInput => {
  return {
    id: 'InvoiceIdentifier',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Invoice ID',
    required: true,
    subtitle,
  };
};

export const invoiceFilterInput: SidebarInput = {
  id: 'invoiceFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.StringContains,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
  ],
  getValues: () => [
    { label: 'Customer Account', value: 'CustomerAccount' },
    { label: 'Invoice Date', value: 'InvoiceDate' },
    { label: 'Due Date', value: 'DueDate' },
  ],
};

export const parentInvoiceIdInput = (subtitle: string): SidebarInput => {
  return {
    id: 'ParentRecId',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Parent Invoice ID',
    required: true,
    subtitle,
    placeholder: '5637146829',
  };
};

export const lineNumberInput = (subtitle: string): SidebarInput => {
  return {
    id: 'LineNumber',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Invoice Line Item ID',
    required: true,
    subtitle,
    placeholder: '1',
  };
};

export const mainAccountInput: SidebarInput = {
  id: 'MainAccountDisplayValue',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Main Account',
  required: true,
  subtitle: 'Specify the revenue account to use to post to the general ledger.',
};

export const descriptionInput: SidebarInput = {
  id: 'Description',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Description',
  required: false,
};

export const salesTaxGroupInput: SidebarInput = {
  id: 'SalesTaxGroupId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Sales Tax Group',
  required: false,
  subtitle: 'Specify the sales tax group for this line item',
};

export const unitPriceInput: SidebarInput = {
  id: 'UnitPrice',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Unit Price',
  required: false,
};

export const paymentJournalNameInput: SidebarInput = {
  id: 'JournalName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  required: true,
  subtitle: 'Specify the name of the Customer Payment Journal to use.',
};

export const journalDescriptionInput: SidebarInput = {
  id: 'Description',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Description',
  required: false,
};

export const journalBatchInput = (subtitle: string): SidebarInput => {
  return {
    id: 'JournalBatchNumber',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Journal Batch Number',
    required: true,
    subtitle,
    placeholder: 'DAT-000001',
  };
};

export const paymentJournalLineItemInput = (subtitle: string): SidebarInput => {
  return {
    id: 'LineNumber',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Payment Journal Line Item ID',
    required: true,
    subtitle,
    placeholder: '1',
  };
};

export const transactionDateInput: SidebarInput = {
  id: 'TransactionDate',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Date',
  required: true,
  subtitle: 'Accepts ISO 8601 date format.',
  placeholder: '2022-01-01',
};

export const voucherInput: SidebarInput = {
  id: 'Voucher',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Voucher',
  required: true,
  subtitle:
    'Specify the voucher number for the transaction. Voucher series are set up in the Number sequences page in Dynamics Finance.',
};

export const creditInput: SidebarInput = {
  id: 'CreditAmount',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Credit',
  required: false,
  subtitle: 'Specify a credit amount in the currency that is selected for the journal line.',
};

export const paymentStatusInput: SidebarInput = {
  //see refernce in ui
  id: '',
  type: SidebarInputType.EditableEnum,
  title: 'Payment status',
  required: false,
  getValues: () => [{ label: 'Sent', value: 'Sent' }],
};

export const paymentJournaInvoiceInput: SidebarInput = {
  id: 'MarkedInvoice',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Invoice ID',
  required: false,
};

export const vendorIdInput = (subtitle: string): SidebarInput => {
  return {
    id: 'vendorId',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Vendor ID',
    required: true,
    subtitle,
  };
};

export const customerIdInput = (subtitle: string): SidebarInput => {
  return {
    id: 'customerId',
    type: SidebarInputType.TextArea,
    lines: 1,
    title: 'Customer Account Code',
    required: true,
    subtitle,
    placeholder: 'DAK',
  };
};

export const paymentJournalLineFilterInput: SidebarInput = {
  id: 'paymentJournalLineFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.StringContains,
    Operator.NumberEquals,
    Operator.NumberDoesNotEqual,
    Operator.NumberGreaterThan,
    Operator.NumberLessThan,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
  ],
  getValues: () => [
    { label: 'Date', value: 'TransactionDate' },
    {
      label: 'Payment Journal ID',
      value: 'LineNumber',
    },
  ],
};

export const paymentJournalFilterInput: SidebarInput = {
  id: 'paymentJournalFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringDoesNotExactlyMatch,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
    Operator.BooleanFalse,
    Operator.BooleanTrue,
  ],
  getValues: () => [
    {
      label: 'Name',
      value: 'JournalName',
    },
    { label: 'Journal Batch Number', value: 'JournalBatchNumber' },
  ],
};

export const currencyCodeInput = (required: boolean, defaultValue?: string): SidebarInput => {
  return {
    ...currencyInput(defaultValue),
    id: 'CurrencyCode',
    subtitle: 'The three-letter currency code associated with this invoice.',
    required,
  };
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Accounts',
          items: [
            {
              value: Intent.GET_ACCOUNTS,
              label: 'Get Accounts',
            },
          ],
        },
        {
          title: 'Vendors',
          items: [
            {
              value: Intent.CREATE_VENDOR,
              label: 'Create Vendor',
            },
            {
              value: Intent.UPDATE_VENDOR,
              label: 'Update Vendor',
            },
            {
              value: Intent.GET_VENDOR_BY_ID,
              label: 'Get Vendor by ID',
            },
            {
              value: Intent.SEARCH_VENDORS,
              label: 'Search for Vendor',
            },
          ],
        },
        {
          title: 'Bills',
          items: [
            {
              value: Intent.CREATE_BILL,
              label: 'Create Bill',
            },
            {
              value: Intent.UPDATE_BILL,
              label: 'Update Bill',
            },
            {
              value: Intent.GET_BILL_BY_ID,
              label: 'Get Bill by ID',
            },
            {
              value: Intent.SEARCH_BILLS,
              label: 'Search for Bill',
            },
            {
              value: Intent.DELETE_BILL,
              label: 'Delete Bill',
            },
          ],
        },
        {
          title: 'Bill Line Item',
          items: [
            {
              value: Intent.CREATE_BILL_LINE_ITEM,
              label: 'Create Bill Line Item',
            },
            {
              value: Intent.UPDATE_BILL_LINE_ITEM,
              label: 'Update Bill Line Item',
            },
            {
              value: Intent.GET_BILL_LINE_ITEM_BY_ID,
              label: 'Get Bill Line Item by ID',
            },
            {
              value: Intent.SEARCH_BILL_LINE_ITEMS,
              label: 'Search for Bill Line Item',
            },
            {
              value: Intent.DELETE_BILL_LINE_ITEM,
              label: 'Delete Bill Line Item',
            },
          ],
        },
        {
          title: 'Customers',
          items: [
            {
              value: Intent.CREATE_CUSTOMER,
              label: 'Create Customer',
            },
            {
              value: Intent.UPDATE_CUSTOMER,
              label: 'Update Customer',
            },
            {
              value: Intent.GET_CUSTOMER_BY_ID,
              label: 'Get Customer by ID',
            },
            {
              value: Intent.SEARCH_CUSTOMERS,
              label: 'Search for Customer',
            },
            {
              value: Intent.DELETE_CUSTOMER,
              label: 'Delete Customer',
            },
          ],
        },
        {
          title: 'Invoices',
          items: [
            {
              value: Intent.CREATE_INVOICE,
              label: 'Create Invoice',
            },
            {
              value: Intent.UPDATE_INVOICE,
              label: 'Update Invoice',
            },
            {
              value: Intent.GET_INVOICE_BY_ID,
              label: 'Get Invoice by ID',
            },
            {
              value: Intent.SEARCH_INVOICES,
              label: 'Search for Invoice',
            },
            {
              value: Intent.DELETE_INVOICE,
              label: 'Delete Invoice',
            },
          ],
        },
        {
          title: 'Payments',
          items: [
            {
              value: Intent.CREATE_PAYMENT_JOURNAL,
              label: 'Create Payment Journal',
            },
            {
              value: Intent.UPDATE_PAYMENT_JOURNAL,
              label: 'Update Payment Journal',
            },
            {
              value: Intent.GET_PAYMENT_JOURNAL_BY_ID,
              label: 'Get Payment Journal by ID',
            },
            {
              value: Intent.SEARCH_PAYMENT_JOURNALS,
              label: 'Search for Payment Journal',
            },
            {
              value: Intent.DELETE_PAYMENT_JOURNAL,
              label: 'Delete Payment Journal',
            },
          ],
        },
        {
          title: 'Payment Invoice Line Item',
          items: [
            {
              value: Intent.CREATE_PAYMENT_JOURNAL_LINE_ITEM,
              label: 'Create Payment Journal Line Item',
            },
            {
              value: Intent.UPDATE_PAYMENT_JOURNAL_LINE_ITEM,
              label: 'Update Payment Journal Line Item',
            },
            {
              value: Intent.GET_PAYMENT_JOURNAL_LINE_ITEM_BY_ID,
              label: 'Get Payment Journal Line Item by ID',
            },
            {
              value: Intent.SEARCH_PAYMENT_JOURNAL_LINE_ITEMS,
              label: 'Search for Payment Journal Line Item',
            },
            {
              value: Intent.DELETE_PAYMENT_JOURNAL_LINE_ITEM,
              label: 'Delete Payment Journal Line Item',
            },
          ],
        },
      ],
    },
  ],
};

export const recordTypeInput = (record: RecordType): SidebarInput => ({
  id: 'recordType',
  title: 'Record Type',
  type: SidebarInputType.Text,
  required: false,
  overrideValue: record,
  hidden: true,
});

export const inputFields = (intent: Intent): SidebarInput[] | undefined => {
  switch (intent) {
    case Intent.CREATE_VENDOR:
    case Intent.UPDATE_VENDOR: {
      const isCreateIntent = intent === Intent.CREATE_VENDOR;
      return [
        recordTypeInput(RecordType.VENDOR),
        ...(intent === Intent.UPDATE_VENDOR
          ? [vendorIdInput('Specify the VendorAccountNumber of the Vendor to update.')]
          : [vendorAccountInput]),
        { ...NameInput, required: isCreateIntent },
        { ...groupInput, required: isCreateIntent },
        organizationNumberInput,
        ...(intent === Intent.UPDATE_VENDOR ? [typeInput()] : [typeInput('Organization')]),
        additionalFieldsInput,
      ];
    }

    case Intent.CREATE_BILL:
    case Intent.UPDATE_BILL: {
      const isCreateIntent = intent === Intent.CREATE_BILL;
      return [
        recordTypeInput(RecordType.BILL),
        ...(intent === Intent.UPDATE_BILL
          ? [billIdInput('Specify the bill header reference of the bill to update.')]
          : [companyInput]),
        { ...invoiceAccountInput, required: isCreateIntent },
        { ...invoiceNumberInput, required: isCreateIntent },
        invoiceDescriptionInput,
        invoiceDateInput,
        postingDateInput,
        dueDateInput,
        onHoldInput,
        billAdditionalFieldsInput,
      ];
    }

    case Intent.CREATE_BILL_LINE_ITEM:
    case Intent.UPDATE_BILL_LINE_ITEM: {
      return [
        recordTypeInput(RecordType.BILL_LINE_ITEM),
        parentBillIDInput,
        ...(intent === Intent.UPDATE_BILL_LINE_ITEM
          ? [billLineItemIdInput('Specify the record ID of the Bill Line Item to update.')]
          : [itemNumberInput]),
        procurementCategoryInput,
        quantityInput,
        unitInput,
      ];
    }

    case Intent.CREATE_CUSTOMER:
    case Intent.UPDATE_CUSTOMER: {
      const isCreateIntent = intent === Intent.CREATE_CUSTOMER;
      return [
        recordTypeInput(RecordType.CUSTOMER),
        ...(intent === Intent.UPDATE_CUSTOMER
          ? [customerIdInput('Specify the customer account code of the customer to update.')]
          : [customerAccountInput]),
        { ...customerNameInput, required: isCreateIntent },
        { ...customerGroupInput, required: isCreateIntent },
        organizationNumberInput,
        ...(intent === Intent.UPDATE_CUSTOMER
          ? [
              {
                ...typeInput(),
                id: 'PartyType',
                subtitle: 'Specify the type of customer.',
              },
              currencyInput(),
              countryInput(),
            ]
          : [
              {
                ...typeInput('Organization'),
                id: 'PartyType',
                subtitle: 'Specify the type of customer.',
              },
              currencyInput('USD'),
              countryInput('USA'),
            ]),
        streetAddressInput,
        cityInput,
        zipCodeInput,
        phoneNumberInput,
        emailInput,
        customerAdditionalFieldsInput,
      ];
    }

    case Intent.CREATE_INVOICE:
    case Intent.UPDATE_INVOICE: {
      const isCreateIntent = intent === Intent.CREATE_INVOICE;
      return [
        recordTypeInput(RecordType.INVOICE),
        ...(intent === Intent.UPDATE_INVOICE
          ? [invoiceIdInput('Specify the record ID of the Invoice to update.')]
          : []),
        {
          ...customerAccountInput,
          subtitle: 'Specify the Customer Account of the recipient of this invoice.',
          required: isCreateIntent,
        },
        { ...invoiceAccountInput, required: isCreateIntent, subtitle: '' },
        ...(intent === Intent.UPDATE_INVOICE
          ? [currencyCodeInput(isCreateIntent)]
          : [currencyCodeInput(isCreateIntent, 'USD')]),
        {
          ...invoiceDateInput,
          subtitle: 'Accepts ISO 8601 date format.',
          required: isCreateIntent,
        },
        dueDateInput,
        methodoFPaymentInput,
        termsOfPaymentInput,
        oneTimeCustomerInput,
      ];
    }

    case Intent.CREATE_PAYMENT_JOURNAL:
    case Intent.UPDATE_PAYMENT_JOURNAL: {
      return [
        recordTypeInput(RecordType.PAYMENTS_JOURNAL),
        ...(intent === Intent.UPDATE_PAYMENT_JOURNAL
          ? [
              journalBatchInput(
                'Specify the Journal Batch Number of the Payment Journal to update.',
              ),
            ]
          : [{ ...NameInput, id: 'JournalName', subtitle: 'Specify a Journal Name to use.' }]),
        journalDescriptionInput,
      ];
    }

    case Intent.CREATE_PAYMENT_JOURNAL_LINE_ITEM:
    case Intent.UPDATE_PAYMENT_JOURNAL_LINE_ITEM: {
      const isCreateIntent = intent === Intent.CREATE_PAYMENT_JOURNAL_LINE_ITEM;
      return [
        recordTypeInput(RecordType.PAYMENTS_JOURNAL_LINE_ITEM),
        ...(intent === Intent.UPDATE_PAYMENT_JOURNAL_LINE_ITEM
          ? [
              journalBatchInput(
                'Specify the batch number of the Parent Payment Journal to update its child line item.',
              ),
              paymentJournalLineItemInput(
                'Specify Line Number of the Payment Journal Line Item to update.',
              ),
            ]
          : [
              journalBatchInput(
                'Specify the batch number of the Parent Payment Journal to fetch all associated Payments.',
              ),
              paymentJournalLineItemInput('Specify Line Number for the Payment Journal Line Item.'),
            ]),
        { ...transactionDateInput, required: isCreateIntent },
        { ...voucherInput, required: isCreateIntent },
        paymentJournaInvoiceInput,
        creditInput,
        ...(intent === Intent.UPDATE_PAYMENT_JOURNAL_LINE_ITEM
          ? [currencyCodeInput(isCreateIntent)]
          : [currencyCodeInput(isCreateIntent, 'USD')]),
        // paymentStatusInput,//todo find this input refernce in ui
      ];
    }
  }
};

export const getAndDeleteInputFieldCollection: {
  [x: string]: SidebarInput[];
} = {
  [Intent.GET_ACCOUNTS]: [recordTypeInput(RecordType.ACCOUNTS)],
  [Intent.GET_VENDOR_BY_ID]: [
    vendorIdInput('Specify the VendorAccountNumber of the Vendor to Search.'),
    recordTypeInput(RecordType.VENDOR),
  ],
  [Intent.GET_BILL_BY_ID]: [
    billIdInput('Specify the bill header reference of the Bill to fetch.'),
    recordTypeInput(RecordType.BILL),
  ],
  [Intent.DELETE_BILL]: [
    billIdInput('Specify the bill header reference of the Bill to delete.'),
    recordTypeInput(RecordType.BILL),
  ],
  [Intent.GET_BILL_LINE_ITEM_BY_ID]: [
    parentBillIDInput,
    billLineItemIdInput('Specify the invoice line number of the Bill Line Item to fetch.'),
    recordTypeInput(RecordType.BILL_LINE_ITEM),
  ],
  [Intent.DELETE_BILL_LINE_ITEM]: [
    {
      ...parentBillIDInput,
      subtitle: 'Specify the record ID of the parent Bill of the Bill Line Item to delete.',
    },
    billLineItemIdInput('Specify the invoice line number of the Bill Line Item to delete.'),
    recordTypeInput(RecordType.BILL_LINE_ITEM),
  ],
  [Intent.GET_CUSTOMER_BY_ID]: [
    customerIdInput('Specify the customer account code of the customer to fetch.'),
    recordTypeInput(RecordType.CUSTOMER),
  ],
  [Intent.DELETE_CUSTOMER]: [
    customerIdInput('Specify the customer account code of the customer to delete.'),
    recordTypeInput(RecordType.CUSTOMER),
  ],
  [Intent.GET_INVOICE_BY_ID]: [
    invoiceIdInput('Specify the record ID of the Invoice to fetch.'),
    recordTypeInput(RecordType.INVOICE),
  ],
  [Intent.DELETE_INVOICE]: [
    invoiceIdInput('Specify the record ID of the Invoice to delete.'),
    recordTypeInput(RecordType.INVOICE),
  ],

  [Intent.GET_PAYMENT_JOURNAL_BY_ID]: [
    journalBatchInput(
      'Specify the record ID of the Payment Journal to fetch all associated Payments.',
    ),
    recordTypeInput(RecordType.PAYMENTS_JOURNAL),
  ],
  [Intent.DELETE_PAYMENT_JOURNAL]: [
    journalBatchInput(
      'Specify the record ID of the Payment Journal to delete all associated Payments.',
    ),
    recordTypeInput(RecordType.PAYMENTS_JOURNAL),
  ],
  [Intent.GET_PAYMENT_JOURNAL_LINE_ITEM_BY_ID]: [
    journalBatchInput(
      'Specify the record ID of the Payment Journal to fetch all associated Payments.',
    ),
    paymentJournalLineItemInput(
      'Specify the Line Number of the Payment Journal Line Item to fetch.',
    ),
    recordTypeInput(RecordType.PAYMENTS_JOURNAL_LINE_ITEM),
  ],
  [Intent.DELETE_PAYMENT_JOURNAL_LINE_ITEM]: [
    journalBatchInput(
      'Specify the batch number of the Parent Payment Journal to delete its child line item.',
    ),
    paymentJournalLineItemInput(
      'Specify the Line Number of the Payment Journal Line Item to delete.',
    ),
    recordTypeInput(RecordType.PAYMENTS_JOURNAL_LINE_ITEM),
  ],
};

export const searchFields: { [x: string]: SidebarInput[] } = {
  [Intent.SEARCH_VENDORS]: [vendorFilterInput, recordTypeInput(RecordType.VENDOR)],
  [Intent.SEARCH_BILLS]: [billFilterInput, recordTypeInput(RecordType.BILL)],
  [Intent.SEARCH_BILL_LINE_ITEMS]: [
    billLineItemFilterInput,
    recordTypeInput(RecordType.BILL_LINE_ITEM),
  ],
  [Intent.SEARCH_CUSTOMERS]: [customerFilterInput, recordTypeInput(RecordType.CUSTOMER)],
  [Intent.SEARCH_INVOICES]: [invoiceFilterInput, recordTypeInput(RecordType.INVOICE)],
  [Intent.SEARCH_PAYMENT_JOURNALS]: [
    paymentJournalFilterInput,
    recordTypeInput(RecordType.PAYMENTS_JOURNAL),
  ],
  [Intent.SEARCH_PAYMENT_JOURNAL_LINE_ITEMS]: [
    paymentJournalLineFilterInput,
    recordTypeInput(RecordType.PAYMENTS_JOURNAL_LINE_ITEM),
  ],
};
