import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.DYNAMICS_365_FINANCE,
  provider: ProviderType.DYNAMICS_365_FINANCE,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Microsoft Dynamics 365 Finance account',
  icon: iconSvg,
  description: 'Dynamics 365 Finance',
  sidebarSections: [],
};

export const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Microsoft Dynamics 365 Finance account',
  placeholder: 'connect to Microsoft Dynamics 365 finance',
  type: SidebarInputType.Auth,
  config: authConfig,
};

export default authConfig;
