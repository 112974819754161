import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { DEFAULT_SUPPORTED_OPERATORS } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';

export const meetingIdInput: SidebarInput = {
  id: 'meetingId',
  type: SidebarInputType.TextArea,
  title: 'Meeting ID',
  lines: 1,
  required: true,
};

export const intentSection: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Meetings',
          items: [
            {
              value: Intent.CREATE_MEETING,
              label: 'Create Meeting',
            },
            {
              value: Intent.UPDATE_MEETING,
              label: 'Update Meeting',
            },
            {
              value: Intent.GET_MEETING,
              label: 'Get Meetings',
            },
            {
              value: Intent.GET_MEETING_BY_ID,
              label: 'Get Meeting by ID',
            },
            {
              value: Intent.DELETE_MEETING,
              label: 'Delete Meeting',
            },
          ],
        },
        {
          title: 'Registrants',
          items: [
            {
              value: Intent.ADD_MEETING_REGISTRANT,
              label: 'Add Meeting Registrant',
            },
            {
              value: Intent.GET_MEETING_REGISTRANT,
              label: 'Get Meeting Registrants',
            },
            {
              value: Intent.DELETE_MEETING_REGISTRANT,
              label: 'Delete Meeting Registrant',
            },
          ],
        },
        {
          title: 'Recordings',
          items: [
            {
              value: Intent.GET_RECORDING_BY_MEETING_ID,
              label: 'Get Recording by Meeting ID',
            },
            {
              value: Intent.SEARCH_RECORDING,
              label: 'Search Recording',
            },
          ],
        },
      ],
    },
  ],
};

export const meetingNameInput: SidebarInput = {
  id: 'topic',
  type: SidebarInputType.TextArea,
  title: 'Topic',
  lines: 1,
  required: true,
  placeholder: 'Meeting name',
};

export const startTimeInput: SidebarInput = {
  id: 'startTime',
  type: SidebarInputType.TextArea,
  title: 'Start Time',
  subtitle: 'Meeting start time (ISO 8601 timestamp).',
  lines: 1,
  required: true,
  placeholder: '2020-03-31T12:02:00Z',
};

export const durationInput: SidebarInput = {
  id: 'duration',
  type: SidebarInputType.TextArea,
  title: 'Duration',
  subtitle: 'Meeting duration in minutes.',
  lines: 1,
  required: true,
  placeholder: '60',
};

export const agendaInput: SidebarInput = {
  id: 'agenda',
  type: SidebarInputType.TextArea,
  title: 'Agenda',
  subtitle: 'Meeting description.',
  lines: 2,
  required: false,
};

export const timezoneInput: SidebarInput = {
  id: 'timezone',
  type: SidebarInputType.TextArea,
  title: 'Timezone',
  subtitle: (
    <>
      Timezone for the meeting’s start time. See Zoom’s{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://marketplace.zoom.us/docs/api-reference/other-references/abbreviation-lists#timezones"
      >
        timezone
      </a>{' '}
      list for reference.
    </>
  ),
  lines: 1,
  required: true,
  placeholder: 'America/Los_Angeles',
};
export const emailInput: SidebarInput = {
  id: 'email',
  title: 'Email',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
  placeholder: 'name@example.com',
};

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  title: 'First name',
  type: SidebarInputType.TextArea,
  required: true,
  lines: 1,
};

export const addressInput: SidebarInput = {
  id: 'address',
  title: 'Address',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  title: 'Last Name',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const cityInput: SidebarInput = {
  id: 'city',
  title: 'City',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const countryInput: SidebarInput = {
  id: 'country',
  title: 'Country',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const zipInput: SidebarInput = {
  id: 'zip',
  title: 'Zip',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const stateInput: SidebarInput = {
  id: 'state',
  title: 'State',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const phoneInput: SidebarInput = {
  id: 'phone',
  title: 'Phone',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const organizationInput: SidebarInput = {
  id: 'organization',
  title: 'Organization',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const jobTitleInput: SidebarInput = {
  id: 'jobTitle',
  title: 'Job Title',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
};

export const registrantStatusInput: SidebarInput = {
  id: 'registrantStatus',
  title: 'Registrant Status',
  type: SidebarInputType.Enum,
  placeholder: 'choose a user to DM or type {{ to insert a Slack user ID',
  required: true,
  getValues: () => [
    {
      value: 'approved',
      label: 'Approved',
    },
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'denied',
      label: 'Denied',
    },
  ],
};

export const registrantIdInput: SidebarInput = {
  id: 'registrantId',
  title: 'Registrant ID',
  type: SidebarInputType.TextArea,
  required: false,
  subtitle: 'The ID of the Registrant to delete.',
  lines: 1,
};

export const passwordInput: SidebarInput = {
  id: 'password',
  title: 'Password',
  type: SidebarInputType.TextArea,
  required: false,
  subtitle:
    'Passcode to join the meeting. By default, passcode may only contain the following characters: [a-z A-Z 0-9 @ - _ *] and have a maximum of 10 characters.',
  lines: 1,
};

export const enableWaitingRoomInput: SidebarInput = {
  id: 'enableWaitingRoom',
  title: 'Enable Waiting Room',
  type: SidebarInputType.BooleanInput,
  required: false,
};

export const sendConfirmationMailInput: SidebarInput = {
  id: 'sendRegistrantConfirmation',
  title: 'Send Registrants Confirmation Email',
  type: SidebarInputType.BooleanInput,
  required: false,
};

export const searchFromInput: SidebarInput = {
  id: 'searchFrom',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 1,
  title: 'From Date',
  subtitle: `Start date in 'yyyy-mm-dd' UTC format`,
  placeholder: '2022-10-22',
};

export const searchUptoInput: SidebarInput = {
  id: 'searchUpto',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'To Date',
  subtitle: `End date in 'yyyy-mm-dd' UTC format`,
  placeholder: '2022-11-21',
};

export const trashInput: SidebarInput = {
  id: 'trash',
  type: SidebarInputType.BooleanInput,
  required: false,
  title: 'Trash',
  subtitle:
    'Use in combination with Trash Type to indicate type of Cloud Recording you need to retrieve',
};

export const trashTypeInput: SidebarInput = {
  id: 'trashType',
  type: SidebarInputType.Enum,
  required: false,
  title: 'Trash Type',
  subtitle: 'The type of Cloud recording that you would like to retrieve from the trash',
  getValues: () => [
    {
      label: 'Meeting Recordings',
      value: 'meeting_recordings',
    },
    {
      label: 'Recording File',
      value: 'recording_file',
    },
  ],
};

export const conditionalInput: SidebarInput = {
  id: 'recordsFilterFormula',
  title: 'Filter Records',
  subtitle: 'Only trigger for meeting recordings that match these filters',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: DEFAULT_SUPPORTED_OPERATORS,
  getValues: () => [
    { label: 'Account ID', value: 'Account ID' },
    { label: 'Start Date', value: 'Start Date' },
    { label: 'End Date', value: 'End Date' },
  ],
};
