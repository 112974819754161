import React from 'react';

import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.AZURE_DEVOPS,
  provider: ProviderType.AZURE_DEVOPS,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Azure DevOps account',
  icon: iconSvg,
  description: 'Azure DevOps',
  sidebarSections: [],
  accountTypes: [
    {
      id: 'service',
      accountDescription: 'Service',
      scheme: AuthenticationScheme.OAUTH,
      oauthParameters: [
        {
          key: 'service',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
      endUserSuppliedValues: [
        {
          type: SidebarInputType.ValueText,
          id: 'AZURE_DEVOPS_ORGANIZATION_ID',
          title: 'Organization ID',
          subtitle: `You can find your organization ID in the URL you use to login to Azure DevOps.`,
          prefixLabel: 'https://dev.azure.com/',
          required: true,
        },
      ],
    },
    {
      id: 'server',
      accountDescription: 'Server (TFS)',
      scheme: AuthenticationScheme.BASIC,
      oauthParameters: [
        {
          key: 'server',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
      endUserSuppliedValues: [
        {
          type: SidebarInputType.ValueText,
          id: 'AZURE_DEVOPS_SERVER_URL',
          title: 'Server URL',
          subtitle: `The base URL to login to your Azure DevOps server.`,
          required: true,
          placeholder: 'https://example-server.com:8080',
        },
        {
          type: SidebarInputType.ValueText,
          id: 'AZURE_DEVOPS_ACCESS_TOKEN',
          title: 'Personal Access Token',
          subtitle: (
            <>
              You can generate a Personal Access Token in the User Settings page, from Azure DevOps.{' '}
              <a
                href="https://docs.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate"
                target="_blank"
                rel="noreferrer"
              >
                See the documentation
              </a>{' '}
              for more details.
            </>
          ),
          required: true,
        },
      ],
    },
  ],
};

export const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Azure DevOps account',
  placeholder: 'connect to Azure DevOps',
  type: SidebarInputType.Auth,
  config: authConfig,
};

export default authConfig;
