import { CachedConnectCredential } from '@shared/entities/sdk/credential/connectCredential.interface';
import { CachedPersona } from '@shared/entities/sdk/persona/persona.interface';
import { WorkflowExecutionContext } from '@shared/types/sdk/execution';
import { WorkflowVariables } from '@shared/types/sdk/resolvers';
import { Step } from '@shared/types/sdk/steps';

/**
 * a variable resolver for a step
 *
 * @export
 * @abstract
 * @class StepResolver
 * @template S
 * @template O
 */
export abstract class StepResolver<S extends Step, O> {
  /**
   * resolves the input parameters for a step
   *
   * @param {S} step
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {O}
   * @memberof StepResolver
   */
  resolveInputParametersFromStep(
    step: S,
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): O {
    const copy: S = JSON.parse(JSON.stringify(step));
    return this.resolveInputParameters(
      copy.parameters,
      secrets,
      cachedConnectCredential,
      variables,
      context,
      cachedPersona,
    );
  }

  /**
   * resolves the input parameters for a step directly from its parameters
   * primarily used by action API requests which aren't ran in the context of a workflow execution
   *
   * @abstract
   * @param {S['parameters']} parameters
   * @param {Record<string, string>} secrets
   * @param {(CachedConnectCredential | null)} cachedConnectCredential
   * @param {WorkflowVariables} variables
   * @param {WorkflowExecutionContext} context
   * @returns {O}
   * @memberof StepResolver
   */
  abstract resolveInputParameters(
    parameters: S['parameters'],
    secrets: Record<string, string>,
    cachedConnectCredential: CachedConnectCredential | null,
    variables: WorkflowVariables,
    context: WorkflowExecutionContext,
    cachedPersona: CachedPersona | null,
  ): O;

  /**
   * returns stepids used in the variables for step , which will be needed to complete the execution
   * @param parameters parameter
   */
  abstract getRequiredStepIdsForVariableResolution(parameters: S['parameters']): string[];
}
