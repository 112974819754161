import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.IMANAGE,
  provider: ProviderType.IMANAGE,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your iManage accounts',
  icon: iconSvg,
  description: 'iManage account to manage your files and documents in iManage.',
  sidebarSections: [],
};

export const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your iManage accounts',
  placeholder: 'connect to iManage accounts',
  type: SidebarInputType.Auth,
  config: authConfig,
};

export default authConfig;
