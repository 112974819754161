import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const getCustomerProperties: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Customer Property',
  cacheKey: 'customerProperties',
  refreshDependencies: [],
  getRefreshActionParameters: (options: ActionStepParameters) => ({
    actionType: Action.SHOPIFY,
    actionParameters: [],
    credentials: options.credentials,
    intent: Intent.GET_CUSTOMERS_PROPERTIES,
  }),
  mapRefreshToValues: (response: any): EnumInputValue[] => {
    return response?.result?.output.map((property: EnumInputValue) => ({
      label: property.label,
      value: property.value,
    }));
  },
};
