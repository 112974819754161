export type Credentials = {
  OAUTH_ACCESS_TOKEN: string;
};

export type Environment = {
  INTERCOM_CLIENT_ID: string;
  INTERCOM_CLIENT_SECRET: string;
};
export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, any>;
};

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
};

export type IntercomAuthResponse = {
  token: string;
  access_token: string;
  token_type: string;
};

export type Admin = {
  type: string;
  id: string;
  email: string;
  name: string;
};

export type AdminListResponse = {
  type: string;
  admins: Admin[];
};

export type ContactDTO = {
  id: string;
  role: string;
  email: string;
  phone: string;
  name: string;
  avatar: string;
  owner_id: string;
  external_id: string;
  created_at: string;
  updated_at: string;
  signed_up_at: number;
  last_seen_at: number;
  unsubscribed_from_emails: boolean;
  custom_attributes: Record<string, any>;
};

export type MessageDTO = {
  id: string;
  type: 'message';
  message_type: string;
  subject: string;
  body: string;
  template: string;
  from: {
    type: 'admin';
    id: string;
  };
  to: {
    type: string;
    id: string;
  };
};

export type CreateContactDTO = {
  role: string;
  email: string;
  name?: string;
  externalId?: string;
  phone?: string;
  avatar?: string;
  signedUpDate?: string;
  lastSeenDate?: string;
  ownerId?: string;
  unsubscribeFromEmail?: boolean;
  customAttribute?: string;
};

export type UpdateContactDTO = Omit<CreateContactDTO, 'role' | 'email'> & {
  contactId: string;
  role?: string;
  email?: string;
};

export type GetContactByIdDTO = {
  contactId: string;
};

export type FilterFormula = Record<string, any> | undefined;

export type SearchContactsDTO = {
  filterFormula: FilterFormula;
};

export type SearchContactsRequestDTO = {
  query: Record<string, any>;
};

export type SearchContactsResponseDTO = {
  type: 'list';
  data: ContactDTO[];
  total_count: number;
  pages: Record<string, string>;
};

export type SendMessageDTO = {
  recipientContactType: string;
  recepientContactId: string;
  messageType: string;
  fromId: string;
  subject?: string;
  body: string;
  template?: string;
};

export enum Role {
  USER = 'user',
  LEAD = 'lead',
}

export enum MessageType {
  IN_APP = 'inapp',
  EMAIL = 'email',
}

export enum MessageTemplateType {
  PLAIN = 'plain',
  PERSONAL = 'personal',
}
