import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.OUTLOOK;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'OUTLOOK_TEST_CONNECTION',
  CREATE_EVENT = 'OUTLOOK_CREATE_EVENT',
  UPDATE_EVENT = 'OUTLOOK_UPDATE_EVENT',
  GET_EVENT_BY_ID = 'OUTLOOK_GET_EVENT_BY_ID',
  GET_EVENTS = 'OUTLOOK_GET_EVENTS',
  DELETE_EVENT = 'OUTLOOK_DELETE_EVENT',
  GET_MESSAGES = 'OUTLOOK_GET_MESSAGES',
  SEND_MESSAGE = 'OUTLOOK_SEND_MESSAGE',
  GET_CALENDARS = 'OUTLOOK_GET_CALENDARS',
  GET_MAIL_FOLDERS = 'OUTLOOK_GET_MAIL_FOLDERS',
  TRIGGER_EVENT_CREATED = 'OUTLOOK_TRIGGER_EVENT_CREATED',
  TRIGGER_EVENT_UPDATED = 'OUTLOOK_TRIGGER_EVENT_UPDATED',
  TRIGGER_MESSAGE_RECEIVED = 'OUTLOOK_TRIGGER_MESSAGE_RECEIVED',
  GET_SUPPORTED_TIMEZONES = 'OUTLOOK_GET_SUPPORTED_TIMEZONES',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: OAuthIntent.EXCHANGE_AUTH_CREDENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.REFRESH_CREDENTIAL;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: OAuthIntent.GET_AUTH_ESSENTIALS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_EVENT_BY_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_EVENTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.DELETE_EVENT;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_MESSAGES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_MESSAGE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CALENDARS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_MAIL_FOLDERS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_SUPPORTED_TIMEZONES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_EVENT_CREATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_EVENT_UPDATED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.TRIGGER_MESSAGE_RECEIVED;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_CONNECT_REQUEST_OPTIONS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export enum CredentialKeys {
  CLIENT_ID = 'OUTLOOK_CLIENT_ID',
  CLIENT_SECRET = 'OUTLOOK_CLIENT_SECRET',
  ACCESS_TOKEN = 'OUTLOOK_ACCESS_TOKEN',
  REFRESH_TOKEN = 'OUTLOOK_REFRESH_TOKEN',
  SCOPES = 'OUTLOOK_SCOPES',
}

export type Credentials = {
  userId: string;
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]?: string;
  [CredentialKeys.SCOPES]?: string;
};
