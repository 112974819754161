export enum ERROR_CODE {
  // ---------------------------------------- //
  // ---------------------------------------- //

  // execution errors
  STEP_FAILURE = '0001',
  ACTION_FAILURE = '0002',
  STEP_EXECUTION_TIMEOUT = '0003',
  INVALID_EXECUTION_STATE = '0004',
  BAD_REQUEST_FOR_EXECUTION = '0005',
  INVALID_STATE_MACHINE = '0006',
  ACTION_TRIGGER_FAILURE = '0007',
  ACTION_TRIGGER_MISSING_CREDENTIALS = '0008',
  ACTION_TRIGGER_MISSING_EVENT = '0009',
  TYPE_COERCION_ERROR = '0010',
  CONNECT_ACTION_FAILURE = '0011',
  CONNECT_PROXY_FAILURE = '0012',
  CUSTOM_INTEGRATION_REQUEST_FAILURE = '0013',
  CLASSIC_WEBHOOK_ACTION_SUBMIT_ERROR = '0014',
  ACTION_TRIGGER_NOT_VALID = '0015',
  UNKNOWN_EXECUTION_ERROR = '0016',
  UNKNOWN_ACTION_ERROR = '0017',
  ACTION_CONFIG_NOT_FOUND = '0018',
  ACTION_INTENT_NOT_FOUND = '0019',
  ACTION_USER_SUPPLIED_CREDENTIAL_NOT_SUPPORTED = '0020',
  ACTION_PROCESSOR_NOT_SUPPORTED_CUSTOM_INTEGRATION = '0021',
  ACTION_TRIGGER_REQUEST_FAILURE = '0022',
  ACTION_NOT_SUPPORTED_TRIGGER = '0023',
  ACTION_TRIGGER_SUBMIT_FAILED = '0024',

  // test execution errors
  TEST_WORKFLOW_EMPTY_STEPS = '0100',
  TEST_WORKFLOW_MISSING_CONNECT_INTEGRAITON = '0101',
  TEST_WORKFLOW_MISSING_CONNECT_PERSONA_META = '0102',

  // trigger errors
  INTEGRATION_DOES_NOT_SUPPORT_TRIGGERS = '0200',
  INTEGRATION_DOES_NOT_SUPPORT_WEBHOOKS = '0201',

  // endpoint processor errors
  ENDPOINT_PROCESSOR_INVALID_TYPE = '0300',
  ENDPOINT_PROCESSOR_INVALID_HTTP_METHOD = '0301',
  ENDPOINT_PROCESSOR_MISSING_PAYLOAD = '0302',
  ENDPOINT_PROCESSOR_MISSING_QUERY_PARAMETER = '0303',
  ENDPOINT_PROCESSOR_INVALID_HEADER_PARAMETER = '0304',
  ENDPOINT_PROCESSOR_MISSING_HEADER_PARAMETER = '0305',
  ENDPOINT_PROCESSOR_MISSING_BODY_PARAMETER = '0306',
  ENDPOINT_PROCESSOR_INVALID_BODY_PARAMETER = '0307',
  ENDPOINT_PROCESSOR_FEATURE_NOT_IMPLEMENTED = '0308',

  // integration enabled processor errors
  INTEGRATION_ENABLED_FEATURE_NOT_IMPLEMENTED = '0400',

  // event processor errors
  EVENT_PROCESSOR_FEATURE_NOT_IMPLEMENTED = '0500',

  // function processor errors
  FUNCTION_STEP_LINE_ERROR = '0600',

  // custom integration processor
  CUSTOM_INTEGRATION_MISSING_INTEGRATION_ID = '0700',
  CUSTOM_INTEGRATION_MISSING_CREDENTIALS = '0701',
  CUSTOM_INTEGRATION_INVALID_REQUEST_URL_OVERRIDE = '0702',

  // cron processor errors
  CRON_PROCESSOR_FEATURE_NOT_IMPLEMENTED = '0800',

  // conditional processor error
  CONDITIONAL_PROCESSOR_OPERATOR_NOT_SPECIFIED = '0900',

  //connectAction processor errors
  CONNECT_ACTION_PROCESSOR_MISSING_INTEGRATION_ID = '01000',
  CONNECT_ACTION_PROCESSOR_DECRYPT_CONNECT_CREDENTIAL_NOT_FOUND = '01001',

  //connectProxy processor errors
  CONNECT_PROXY_PROCESSOR_MISSING_INTEGRATION_ID = '01100',
  CONNECT_PROXY_PROCESSOR_DECRYPT_CONNECT_CREDENTIAL_NOT_FOUND = '01101',
  CONNECT_PROXY_PROCESSOR_INVALID_DOMAIN = '01102',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // redis errors
  INVALID_REDIS_CLUSTER_CONNECTION = '1001',
  REDIS_CLUSTER_NOT_SUPPORTED = '1002',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // deployment errors
  MISSING_DEPLOYED_WORKFLOW_INFO = '2001',
  INVALID_DEPLOYMENT = '2002',
  CONNECT_WORKFLOW_DEPLOYMENT_MISSING_INTEGRATION_ID = '2003',
  DEPLOYMENT_MISSING_CACHED_CREDENTIALS = '2004',
  PREVIOUS_DEPLOYMENTS_CLEANUP = '2005',
  WORKFLOW_DEPLOYMENT_ERROR = '2006',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // workflow errors
  WORKFLOW_NOT_FOUND_FROM_ID = '3000',
  WORKFLOW_MISSING_TRIGGER = '3001',
  WORKFLOW_ONLY_HAS_TRIGGER = '3002',
  UNAUTHORIZED_WORKFLOW_ACCESS = '3003',
  WORKFLOW_MISSING_INTEGRATION = '3004',

  // workflow migration errors
  WORKFLOW_MIGRATION_NOT_FOUND = '3100',

  // workflow version errors
  WORKFLOW_VERSION_NOT_FOUND = '3200',

  // workflow step errors
  WORKFLOW_STEP_NOT_FOUND_FROM_ID = '3300',
  WORKFLOW_STEP_MISSING_DESCRIPTION = '3301',
  WORKFLOW_STEP_MISSING_PARAMETERS = '3302',
  WORKFLOW_STEP_MISSING_TYPE = '3303',
  WORKFLOW_STEP_MISSING_TARGET_PARENT_STEP_ID = '3304',
  WORKFLOW_STEP_INVALID_CONDITIONAL_CHOICE = '3305',
  WORKFLOW_STEP_UNKNOWN_STEP_ID = '3306',
  WORKFLOW_OWNER_ID_NOT_FOUND = '3307',
  WORKFLOW_OAUTH_TRIGGER_FAILED_ERROR = '3308',

  // workflow tag errors
  WORKFLOW_TAG_NOT_FOUND_FROM_ID = '3400',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // product limitation errors
  PRODUCT_LIMITATION_DATA_SIZE_EXCEEDED = '4001',
  PRODUCT_LIMITATION_TIMEOUT_EXCEEDED = '4002',
  PRODUCT_LIMITATION_ACTION_TRIGGER_NOT_AVAILABLE = '4003',
  PRODUCT_LIMITATION_DELAY_LIMIT_EXCEEDED = '4004',
  PRODUCT_LIMITATION_FANOUT_LIMITATION_EXCEEDED = '4005',
  PRODUCT_LIMITATION_WORKFLOWS_LIMIT_EXCEEDED = '4006',
  PRODUCT_LIMITATION_STEPS_PER_WORKFLOW_LIMIT_EXCEEDED = '4007',
  PRODUCT_LIMITATION_INTEGRATION_LIMIT_EXCEEDED = '4008',
  PRODUCT_LIMITATION_ACTIVE_CONNECT_INTEGRATION_LIMIT_EXCEEDED = '4009',
  PRODUCT_LIMITATION_CONNECT_USER_LIMIT_EXCEEDED = '4010',
  PRODUCT_LIMITATION_CRON_SCHEDULE_LIMIT_EXCEEDED = '4011',
  PRODUCT_LIMITATIONS_DEV_CREDENTIALS_EXCEEDED = '4012',
  PRODUCT_LIMITATIONS_WORKFLOW_FEATURE_RESTRICTIONS = '4013',
  PRODUCT_LIMITATIONS_TASK_LIMIT_REACHED = '4014',
  PRODUCT_LIMITATION_TEAM_MEMBER_LIMIT_EXCEEDED = '4015',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // embassy (enterprise) errors
  MISSING_AUTH_TOKEN = '5000',
  AUTH_TOKEN_EXPIRED = '5001',
  AUTH_TOKEN_MISMATCH = '5002',
  EMBASSY_UNAVAILABLE = '5003',
  INVALID_LICENSE = '5004',
  INVALID_REQUEST_SIGNATURE = '5005',
  JWT_CREATION_FAILED_UNKNOWN = '5006',
  JWT_CREATION_FAILED_UPSTREAM_SERVER_UNAVAILABLE = '5007',
  MISSING_STRIPE_CUSTOMER_ID = '5008',
  LICENSE_NOT_FOUND = '5009',
  LICENSE_NOT_ACTIVE = '5010',
  INVALID_AUTHORIZATION_HEADER = '5011',
  TOKEN_NOT_FOUND = '5012',
  TOKEN_PAYLOAD_NOT_FOUND = '5013',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // step errors (TODO: move under workflow errors)
  INVALID_STEP_ERROR = '6000',
  INVALID_TRIGGER_STEP = '6001',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // credential errors
  CREDENTIAL_NOT_FOUND_FROM_ID = '7000',
  CREDENTIAL_NOT_FOUND_FOR_INTEGRATION = '7001',
  CREDENTIAL_NOT_FOUND_FOR_RPOJECT = '7002',
  CREDENTIAL_VERIFICATION_ERROR = '7003',
  CREDENTIAL_INVALID_STATUS_ERROR = '7004',

  // oauth errors
  OAUTH_PROVIDER_ERROR = '7100',
  OAUTH_REQUEST_MISSING_ACTION = '7101',
  OAUTH_PARSING_USER_SUPPLIED_VALUES = '7102',
  OAUTH_PARSING_QUERY_PARAMETERS = '7103',
  OAUTH_REDIRECT_TO_SAME_URL = '7104',
  OAUTH_REDIRECT_URL_MISSING = '7105',

  // jwt errors
  NO_AUTHENTICATION_CONFIGURED = '7200',
  MISSING_JWT = '7201',
  MISSING_JWT_SUB = '7202',
  JWT_EXPIRED = '7203',
  JWT_MALFORMED = '7204',
  AUTH0_CONFIG_MISSING = '7205',
  AUTH0_CONFIG_INVALID_TENANT = '7206',
  FIREBASE_AUTH_MISSING = '7207',
  FIREBASE_AUTH_INVALID = '7208',

  // connect credential errors
  CONNECT_CREDENTIAL_NOT_FOUND_FROM_ID = '7300',
  CONNECT_CREDENTIAL_NOT_FOUND_FROM_PERSONA = '7301',
  CONNECT_CREDENTIAL_MISSING_ACCESS_TOKEN = '7302',
  CONNECT_CREDENTIAL_INVALID_STATUS = '7303',

  // connect credential config errors
  CONNECT_CREDENTIAL_WORKFLOW_NOT_ENABLED = '7400',
  CONNECT_CREDENTIAL_WORKFLOW_ID_NOT_ENABLED = '7403',
  CONNECT_CREDENTIAL_HIDDEN_WORKFLOW_ERROR = '7402',
  CONNECT_CREDENTIAL_HIDDEN_WORKFLOW_ID_ERROR = '7404',

  // connect credential config validation error
  CONNECT_CREDENTIAL_CONFIG_NOT_VALID = '7401',

  // decrypted credential errors
  ENCRYPTED_CREDENTIAL_NOT_FOUND_FROM_ID = '7500',
  ENCRYPTED_SECRET_NOT_FOUND_FROM_ID = '7510',

  // encrypted credential errors
  ENCRYPTED_CONNECT_CREDENTIAL_NOT_FOUND_FROM_ID = '7600',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // config errors
  MISSING_ENVIRONMENT_VARIABLES_ERROR = '8000',
  ONBOARDING_NOT_SUPPORTED = '8001',
  MISSING_CLI_OPTIONS = '8002',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // user errors
  USER_WITH_EMAIL_NOT_FOUND_ERROR = '9000',
  USER_WITH_ID_NOT_FOUND_ERROR = '9001',
  USER_WITH_EMAIL_ALREADY_EXISTS_ERROR = '9002',
  USER_SIGNUP_WITH_EMAIL_FAILED = '9003',
  INVALID_LOGIN_CREDENTIALS = '9004',
  USER_MISSING_TEAM = '9005', // todo: move to team errors
  USER_ACCOUNT_DISABLED = '9006',
  USER_SIGNUP_WITH_EMAIL_ENTERPRISE_FAILED = '9007',
  'USER_SIGNUP_MISSING_REQUIRED_FIELDS' = '9008',

  // reset password errors
  RESET_PASSWORD_INVALID = '9105',
  RESET_PASSWORD_LINK_EXPIRED = '9106',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // billing sync errors
  GLOBAL_BILLING_SYNC = '10000',

  // stripe errors
  STRIPE_CUSTOMER_NOT_FOUND = '10101',
  STRIPE_CUSTOMER_DELETED = '10102',
  STRIPE_PRODUCT_DELETED = '10103',
  STRIPE_WEBHOOK_MISSING_AUTHORIZATION_HEADER = '10104',
  PREFERRED_STRIPE_SUBSCRIPTION_NOT_FOUND = '10105',
  INTEGRATION_CHECKOUT_MINIMUM = '10106',

  // subscription errors
  SUBSCRIPTION_NOT_FOUND_FOR_PROJECT = '10200',
  SUBSCRIPTION_NOT_FOUND_FROM_ID = '10201',
  SUBSCRIPTION_PLAN_UNSUPPORTED_FEATURE = '10202',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // integration errors
  INTEGRATION_NOT_FOUND = '11000',
  INTEGRATION_NOT_ENABLED_FOR_PROJECT = '11001',
  INTEGRATION_NOT_ENABLED_FOR_PERSONA = '11002',
  INTEGRATION_NOT_FOUND_FOR_CUSTOM_INTEGRATION = '11003',
  INTEGRATION_NOT_CUSTOM_INTEGRATION = '11004',
  INTEGRATION_NOT_ACTIVE = '11005',

  // custom integration errors
  CUSTOM_INTEGRATION_NOT_FOUND = '11100',
  CUSTOM_INTEGRATION_MISSING_TEST_ENDPOINT_PATH = '11101',

  // custom integration wizard errors
  CUSTOM_INTEGRATION_WIZARD_ICON_NOT_FOUND = '11201',
  CUSTOM_INTEGRATION_WIZARD_DRAFT_NOT_FOUND = '11202',
  CUSTOM_INTEGRATION_WIZARD_IS_NOT_DRAFT = '11203',
  CUSTOM_INTEGRATION_WIZARD_NAME_CONFLICT = '11204',
  CUSTOM_INTEGRATION_WIZARD_PREVIEW_CREDENTIAL_REQUIRED = '11205',
  CUSTOM_INTEGRATION_WIZARD_UNKNOWN_SAVE_ACTION = '11206',
  CUSTOM_INTEGRATION_WIZARD_UNSUPPORTED_MIME_TYPE = '11207',
  CUSTOM_INTEGRATION_WIZARD_ICON_UPLOAD_FAILED = '11208',

  // integration config errors
  INTEGRATION_CONFIG_NOT_FOUND_FROM_INTEGRATION_ID = '11300',
  INTEGRATION_CONFIG_NOT_FOUND_FROM_ID = '11301',
  INTEGRATION_CONFIG_NOT_FOUND_FROM_CUSTOM_INTEGRATION_ID = '11302',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // secret errors
  SECRET_NOT_FOUND = '12000',
  DELETE_SECRET = '12001',
  SECRET_MISMATCH = '12002',
  DELETE_ACTIVE_SECRET = '12003',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // team errors
  UNAUTHORIZED_TEAM_ACCESS = '13000',
  TEAM_NOT_FOUND_FROM_ID = '13001',
  TEAM_NOT_FOUND_FOR_ENTERPRISE_INSTALLATION = '13002',

  // invite errors
  INVITE_NOT_FOUND_FROM_ID = '13101',
  EXISTING_USER_JOINING_TEAM = '13102',
  INVITE_EXPIRED = '13103',
  INVITE_ALREADY_ACCEPTED = '13104',
  USER_IN_TEAM_HAS_EMAIL = '13105',

  // team member errors
  TEAM_MEMBER_NOT_FOUND_FROM_ID = '13200',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // project errors
  UNAUTHORIZED_PROJECT_ACCESS = '14000',
  CLASSIC_PROJECT_NOT_FOUND_FOR_TEAM = '14001',
  PROJECT_NOT_FOUND_FROM_ID = '14002',
  PROJECT_ID_NOT_FOUND_IN_PARAMS = '14003',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // event errors
  CONNECT_EVENT_NOT_FOUND_FROM_ID = '15000',
  CONNECT_EVENT_NOT_FOUND_FROM_NAME = '15001',
  CONNECT_EVENT_ARRAY_EMPTY = '15002',
  CONNECT_EVENT_ARRAY_TOO_LARGE = '15003',
  CONNECT_DUPLICATE_EVENT = '15004',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // deprecated entity errors
  MODEL_NOT_FOUND_FROM_ID = '16000',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // organization errors
  ORGANIZATION_FOUND_FOR_USER = '17000',
  ORGANIZATION_FOUND_FROM_ID = '17001',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // persona errors
  PERSONA_NOT_FOUND_FROM_ID = '18000',
  PERSONA_NOT_FOUND_FROM_END_USER_ID = '18001',
  PERSONA_AUTH_FAILED_NO_SIGNING_KEY = '18002',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // signing key errors
  SIGNING_KEY_NOT_FOUND_FROM_ID = '19000',
  SIGNING_KEY_NOT_FOUND_FOR_PROJECT = '19001',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // deployment errors
  ADMIN_DEPLOYMENT_FILTER_CONFLICT = '20000',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // workflow execution errors
  WORKFLOW_EXECUTION_NOT_FOUND_FROM_ID = '21000',
  WORKFLOW_EXECUTION_ID_NOT_FOUND_IN_PARAMS = '21001',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // master key errors
  MASTER_KEY_NOT_FOUND_FROM_BASE_ID = '23000',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // cache errors
  CACHED_STATE_MACHINE_NOT_FOUND = '24000',
  CACHED_STATE_MACHINE_TRIGGER_MISMATCH = '24001',
  CACHE_MISSING_OWNER_ID = '24002',
  CACHE_MISSING_PROJECT_ID = '24003',
  CACHE_MISSING_WORKFLOW_MIGRATION_ID = '24004',
  CACHE_MISSING_CONNECT_CREDENTIAL_ID = '24005',
  CACHE_MISSING_CONNECT_CREDENTIAL = '24006',
  CACHE_MISSING_TRIGGER_INPUT = '24007',
  CACHE_MISSING_INTEGRATION_ID = '24008',
  CACHE_MISSING_PERSONA_META = '24009',

  // ---------------------------------------- //
  // ---------------------------------------- //

  // microservice http communication errors
  HERMES_ACCESS_TOKEN_INVALID = '25000',
  RATE_LIMIT_TOO_MANY_REQUESTS = '25001',
  HERCULES_ALERT_SENDER_NOT_CONFIGURED = '25002',
  HERCULES_AWS_CREDENTIALS_NOT_CONFIGURED = '25003',
  HERCULES_ECS_SERVICE_ERROR = '25004',
  HERCULES_PROTECTED_QUEUE_DEREGISTER_ERROR = '25005',

  // destinations error codes
  DESTINATION_NOT_FOUND_ERROR = '26000',
  DESTINATION_TEST_ERROR = '26001',
  DESTINAITON_STATE_ALREADY_SET_ERROR = '26002',
  DESTINAITON_ID_NOT_FOUND_IN_PARAMS = '26003',

  //destination Usages error codes
  DESTINATION_USAGE_NOT_FOUND_BY_DESTINATION_ID_ERROR = '27000',
  DESTINATION_USAGE_ALREADY_PRESENT_FOR_DESTINATION_ERROR = '27001',

  //jobs error
  INVALID_JOB_IN_QUEUE = '28000',
}
