import { Lens } from 'monocle-ts';

import { ICredential } from '@shared/entities/sdk/credential/credential.interface';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<ICredential> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<ICredential> = initialState,
  action: Action,
): EntitiesState<ICredential> {
  switch (action.type) {
    case 'CREDENTIALS_ENTITY_FETCH_START':
    case 'CREDENTIALS_ENTITY_CREATION_START':
    case 'CREDENTIALS_ENTITY_UPDATE_START':
    case 'CREDENTIALS_ENTITY_DELETE_START':
    case 'CREDENTIALS_ENTITY_UPSERT_START':
      return {
        ...state,
        processing: true,
      };

    case 'CREDENTIALS_ENTITY_FETCH_SUCCESS':
      const newState = Lens.fromProp<EntitiesState<ICredential>>()('entities').modify(
        (entities: Record<string, ICredential>) => {
          return {
            ...entities,
            ...indexBy('id', action.credentials),
          };
        },
      )(state);
      return {
        ...newState,
        processing: false,
      };

    case 'CREDENTIALS_ENTITY_CREATION_SUCCESS':
    case 'CREDENTIALS_ENTITY_UPDATE_SUCCESS':
    case 'CREDENTIALS_ENTITY_UPSERT_SUCCESS':
      return Lens.fromPath<EntitiesState<ICredential>>()(['entities', action.credential.id]).modify(
        (_credential: ICredential) => action.credential,
      )({ ...state, processing: false });

    case 'CREDENTIALS_ENTITY_DELETE_SUCCESS':
      const { [action.id]: deletedCredential, ...remainingCredentials } = state.entities;
      return Lens.fromPath<EntitiesState<ICredential>>()(['entities']).modify(
        () => remainingCredentials,
      )({
        ...state,
        processing: false,
      });

    case 'CREDENTIALS_ENTITY_FETCH_FAILURE':
    case 'CREDENTIALS_ENTITY_CREATION_FAILURE':
    case 'CREDENTIALS_ENTITY_UPDATE_FAILURE':
    case 'CREDENTIALS_ENTITY_UPSERT_FAILURE':
    case 'CREDENTIALS_ENTITY_DELETE_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'CLEAR_ENTITY_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: undefined,
      };
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
