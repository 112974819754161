import { ENVIRONMENT_VARIABLES, MINIO_MODE, NODE_ENV, PLATFORM_ENV } from './enums';

/**
 * return raw env value from `NodeJS.ProcessEnv`,
 * we cannot use `pickEnvVar` here as both files are inter-related
 * @param name - ENVIRONMENT_VARIABLES key
 */
function pickRawEnvVar<T = string>(name: ENVIRONMENT_VARIABLES): T | undefined {
  return !!process.env[name] ? (process.env[name]?.toString().trim() as unknown as T) : undefined;
}

export function getPlatformEnv(
  env: Record<string, string | boolean> | NodeJS.ProcessEnv = process.env,
): PLATFORM_ENV {
  return env[ENVIRONMENT_VARIABLES.PLATFORM_ENV]
    ? (`${env[ENVIRONMENT_VARIABLES.PLATFORM_ENV]}`.trim().toLowerCase() as PLATFORM_ENV)
    : PLATFORM_ENV.ENTERPRISE;
}

export const currentPlatformEnv: PLATFORM_ENV = getPlatformEnv();

export const defaultNodeEnv: NODE_ENV =
  pickRawEnvVar<NODE_ENV>(ENVIRONMENT_VARIABLES.NODE_ENV) ||
  (currentPlatformEnv === PLATFORM_ENV.SANDBOX ? NODE_ENV.DEVELOPMENT : NODE_ENV.PRODUCTION);

export const defaultVersion: string = 'latest';

export const defaultLogLevel: string =
  pickRawEnvVar<NODE_ENV>(ENVIRONMENT_VARIABLES.LOG_LEVEL) ||
  (currentPlatformEnv === PLATFORM_ENV.ENTERPRISE ? 'info' : 'trace');

export const defaultVolumePath: string = '/var/paragon';

export const defaultPostgresHost: string | undefined = pickRawEnvVar(
  ENVIRONMENT_VARIABLES.POSTGRES_HOST,
);

export const defaultPostgresPort: number | undefined =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.POSTGRES_PORT) as string) || undefined;

export const defaultPostgresUsername: string | undefined = pickRawEnvVar(
  ENVIRONMENT_VARIABLES.POSTGRES_USERNAME,
);

export const defaultPostgresPassword: string | undefined = pickRawEnvVar(
  ENVIRONMENT_VARIABLES.POSTGRES_PASSWORD,
);

export const defaultPostgresDatabase: string | undefined = pickRawEnvVar(
  ENVIRONMENT_VARIABLES.POSTGRES_DATABASE,
);

export const defaultRedisUrl: string | undefined = pickRawEnvVar(ENVIRONMENT_VARIABLES.REDIS_URL);

export const defaultRedisClusterUrl: string | undefined = pickRawEnvVar(
  ENVIRONMENT_VARIABLES.REDIS_URL,
);

export const defaultS3AccessKeyId: string | undefined = pickRawEnvVar(
  ENVIRONMENT_VARIABLES.S3_ACCESS_KEY_ID,
);

export const defaultS3SecretAccessKey: string | undefined = pickRawEnvVar(
  ENVIRONMENT_VARIABLES.S3_SECRET_ACCESS_KEY,
);

export const defaultS3Bucket: string | undefined = pickRawEnvVar(ENVIRONMENT_VARIABLES.S3_BUCKET);

export const defaultAWSRegion: string = 'us-east-1';

const defaultLicense: string | undefined = pickRawEnvVar(ENVIRONMENT_VARIABLES.LICENSE);

export const DOMAIN: string | undefined =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.DOMAIN) ?? 'http://localhost';

export const defaultCerberusPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.CERBERUS_PORT) as string) || 1700;

export const defaultDashboardPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.DASHBOARD_PORT) as string) || 1704;

export const defaultHerculesPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.HERCULES_PORT) as string) || 1701;

export const defaultEmbassyPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.EMBASSY_PORT) as string) || 1705;

export const defaultHermesPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.HERMES_PORT) as string) || 1702;

export const defaultPassportPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.PASSPORT_PORT) as string) || 1706;

export const defaultZeusPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.ZEUS_PORT) as string) || 1703;

export const defaultConnectPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.CONNECT_PORT) as string) || 1707;

export const defaultCdnUrl: string | undefined =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.CDN_PUBLIC_URL) ||
  ([PLATFORM_ENV.PRODUCTION, PLATFORM_ENV.PRODUCTION_MIRROR_1].includes(currentPlatformEnv)
    ? 'https://cdn.useparagon.com'
    : undefined);

export const defaultCerberusPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.CERBERUS_PUBLIC_URL) || `${DOMAIN}:${defaultCerberusPort}`;

export const defaultCerberusPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.CERBERUS_PRIVATE_URL) || defaultCerberusPublicUrl;

export const defaultConnectPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.CONNECT_PUBLIC_URL) || `${DOMAIN}:${defaultConnectPort}`;

export const defaultConnectPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.CONNECT_PRIVATE_URL) || defaultConnectPublicUrl;

export const defaultDashboardPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.DASHBOARD_PUBLIC_URL) || `${DOMAIN}:${defaultDashboardPort}`;

export const defaultDashboardPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.DASHBOARD_PRIVATE_URL) || defaultDashboardPublicUrl;

export const defaultEmbassyPublicUrl: string =
  // TODO: generate embassy urls for qa envs
  pickRawEnvVar(ENVIRONMENT_VARIABLES.EMBASSY_PUBLIC_URL) || 'https://embassy.useparagon.com';

export const defaultEmbassyPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.EMBASSY_PRIVATE_URL) || defaultEmbassyPublicUrl;

export const defaultHerculesPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.HERCULES_PUBLIC_URL) || `${DOMAIN}:${defaultHerculesPort}`;

export const defaultHerculesPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.HERCULES_PRIVATE_URL) || defaultHerculesPublicUrl;

export const defaultHermesPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.HERMES_PUBLIC_URL) || `${DOMAIN}:${defaultHermesPort}`;

export const defaultHermesPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.HERMES_PRIVATE_URL) || defaultHermesPublicUrl;

export const defaultPassportPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.PASSPORT_PUBLIC_URL) || `${DOMAIN}:${defaultPassportPort}`;

export const defaultPassportPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.PASSPORT_PRIVATE_URL) || defaultPassportPublicUrl;

export const defaultPassportProductionUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.PASSPORT_PRODUCTION_URL) ||
  // PARA-2800: we only want to redirect apps to use the production passport url when in non-production + enterprise environments
  (![PLATFORM_ENV.PRODUCTION, PLATFORM_ENV.PRODUCTION_MIRROR_1, PLATFORM_ENV.ENTERPRISE].includes(
    currentPlatformEnv,
  )
    ? 'https://passport.useparagon.com'
    : defaultPassportPublicUrl);

export const defaultZeusPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.ZEUS_PUBLIC_URL) || `${DOMAIN}:${defaultZeusPort}`;

export const defaultZeusPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.ZEUS_PRIVATE_URL) || defaultZeusPublicUrl;

const fallbackJWTToken: string = 'paragon-jwt-token-dc6c511a-8e10-44db-9f4b-6deb52870aef';

export const defaultCerberusAccessToken: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.CERBERUS_ACCESS_TOKEN) || defaultLicense || fallbackJWTToken;

export const defaultHermesAccessToken: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.HERMES_ACCESS_TOKEN) || defaultLicense || fallbackJWTToken;

export const defaultZeusAccessToken: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.ZEUS_ACCESS_TOKEN) || defaultLicense || fallbackJWTToken;

export const defaultZeusJwtSecret: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.ZEUS_JWT_SECRET) || defaultLicense || fallbackJWTToken;

export const defaultEmbassyJwtSecret: string | undefined =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.EMBASSY_JWT_SECRET) ||
  // we don't want a fallback value on prdocution for security
  (currentPlatformEnv === PLATFORM_ENV.PRODUCTION ? undefined : fallbackJWTToken);

export const defaultResetPasswordTokenEncryptionKey =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.RESET_PASSWORD_TOKEN_ENCRYPTION_KEY) || defaultLicense;

export const defaultMinioMode: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_MODE) || MINIO_MODE.SERVER;

export const defaultMinioPort: number = parseInt(
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_PORT) || '9000',
);

export const defaultMinioPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_PUBLIC_URL) || `${DOMAIN}:${defaultMinioPort}`;

export const defaultMinioPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_PRIVATE_URL) || defaultMinioPublicUrl;

export const defaultMinioRootUser: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_ROOT_USER) || defaultS3AccessKeyId || 'minio_root_user';

export const defaultMinioRootPass: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_ROOT_PASS) ||
  defaultS3SecretAccessKey ||
  'minio_root_pass';

export const defaultMinioMicroserviceAccessKey: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_MICROSERVICE_USER) || defaultMinioRootUser;

export const defaultMinioMicroserviceSecretKey: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_MICROSERVICE_PASS) || defaultMinioRootPass;

export const defaultMinioRegion: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_REGION) || defaultAWSRegion;

export const defaultMinioSystemBucket: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_SYSTEM_BUCKET) ?? defaultS3Bucket ?? 'paragon-private';

export const defaultMinioPublicBucket: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MINIO_PUBLIC_BUCKET) || 'paragon-public';

export const defaultPlatoPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.PLATO_PORT) as string) || 1711;

export const defaultPlatoPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.PLATO_PUBLIC_URL) || `${DOMAIN}:${defaultPlatoPort}`;

export const defaultPlatoPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.PLATO_PRIVATE_URL) || defaultPlatoPublicUrl;

export const defaultChronosPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.CHRONOS_PORT) as string) || 1708;

export const defaultChronosPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.CHRONOS_PUBLIC_URL) || `${DOMAIN}:${defaultChronosPort}`;

export const defaultChronosPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.CHRONOS_PRIVATE_URL) || defaultChronosPublicUrl;

export const defaultHadesPort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.HADES_PORT) as string) || 1710;

export const defaultHadesPublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.HADES_PUBLIC_URL) || `${DOMAIN}:${defaultHadesPort}`;

export const defaultHadesPrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.HADES_PRIVATE_URL) || defaultHadesPublicUrl;

export const defaultHadesAccessToken: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.HADES_ACCESS_TOKEN) || defaultLicense || fallbackJWTToken;

export const defaultStatusPagePort: number = parseInt(
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_PORT) || '2727',
);
export const defaultStatusPageName: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_NAME) || 'Paragon Status Page';

export const defaultStatusPageAdminUser: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_ADMIN_USER) || 'services@useparagon.com';

export const defaultStatusPageAdminPassword =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_ADMIN_PASSWORD) ||
  'DefaultParagonStatusPagePassword@123!';

export const defaultStatusPageApiSecret =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.MONITOR_STATUS_PAGE_API_SECRET) ||
  'DefaultParagonApiToken@123$';

export const defaultPhemePort: number =
  parseInt(pickRawEnvVar<string>(ENVIRONMENT_VARIABLES.PHEME_PORT) as string) || 1709;

export const defaultPhemePublicUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.PHEME_PUBLIC_URL) || `${DOMAIN}:${defaultPhemePort}`;

export const defaultPhemePrivateUrl: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.PHEME_PRIVATE_URL) || defaultPhemePublicUrl;

export const defaultPhemeAccessToken: string =
  pickRawEnvVar(ENVIRONMENT_VARIABLES.PHEME_ACCESS_TOKEN) || defaultLicense || fallbackJWTToken;
