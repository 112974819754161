import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { CredentialKeys } from '../configs';

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [],
    },
  ],
};

export const subdomainInput: SidebarInput = {
  id: CredentialKeys.SUBDOMAIN,
  type: SidebarInputType.ValueText,
  title: 'Subdomain',
  subtitle: 'The subdomain in your ServiceNow instance URL.',
  suffixLabel: '.service-now.com',
  required: true,
};

export const usernameInput: SidebarInput = {
  id: CredentialKeys.USERNAME,
  type: SidebarInputType.ValueText,
  title: 'Username',
  subtitle: 'Your ServiceNow username.',
  required: true,
};
export const passwordInput: SidebarInput = {
  id: CredentialKeys.PASSWORD,
  type: SidebarInputType.ValueText,
  title: 'Password',
  subtitle: 'Your ServiceNow password.',
  required: true,
};
