import { OnboardingStage, ProjectType } from '@shared/entities/sdk';
export const ANALYTICS_ANONYMOUS_ID: string = 'paragon-server';

export type EventName =
  | 'Task Executed'
  | 'Account Usage Summary'
  | 'User Login'
  | 'User Logout'
  | 'User Signup'
  | 'Workflow Created'
  | 'Workflow Deployed'
  | 'Workflow Undeployed'
  | 'Workflow Executed'
  | 'Workflow Step Created'
  | 'Workflow Step Deleted'
  | 'Workflow Step Updated'
  | 'Workflow Tested'
  | 'Workflow Deleted'
  | 'Workflow Replay'
  | 'Workflow Version Saved'
  | 'Workflow Version Restored'
  | 'Task History Viewed'
  | 'Environment Secret Created'
  | 'Integration Added'
  | 'Team Member Invited'
  | 'Team Member Deleted'
  | 'Connect Integration Added'
  | 'Connect Integration Deleted'
  | 'Connect Portal Edited'
  | 'Connect Portal Deployed'
  | 'Connect Portal Displayed'
  | 'Connect User Connected'
  | 'Connect API Request Made'
  | 'Connect Event Triggered'
  | 'pageview';

enum GoogleAnalyticsCategory {
  ENGAGEMENT = 'engagement',
}

enum GoogleAnalyticsAction {
  SIGNUP = 'sign_up',
}

type GoogleAnalyticsEvent = {
  category: GoogleAnalyticsCategory;
  action: GoogleAnalyticsAction;
};

export const SignupGoogleAnalyticsEvent: GoogleAnalyticsEvent = {
  category: GoogleAnalyticsCategory.ENGAGEMENT,
  action: GoogleAnalyticsAction.SIGNUP,
};

/**
 * An enum of destination types for Segment. These names **are not** arbitrary, please see
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#managing-data-flow-with-the-integrations-object
 * for info on how to find the name for the destination (generally, it corresponds to the title
 * cased name that appears in the Segment dashboard/docs).
 */
export enum Destination {
  All = 'All',
  Salesforce = 'Salesforce',
  FacebookPixel = 'Facebook Pixel Server Side',
}

export type Integrations = { [destName in Destination]: boolean };
export const DEFAULT_INTEGRATION_DESTINATIONS: Integrations = {
  // https://segment.com/docs/connections/spec/common/#integrations
  [Destination.All]: true,
  [Destination.Salesforce]: false,
  // Our overrides:
  [Destination.FacebookPixel]: false,
};

export interface IAnalyticsClient {
  track(data: object): void;
  identify(data: object): void;
}

/**
 * used by the analytics service to determine which method to call
 * only used for on-prem installations
 *
 * @export
 * @enum {number}
 */
export enum ProxiedEventType {
  IDENTIFY = 'IDENTIFY',
  TRACK = 'TRACK',
}

/**
 * an event that happened on an on-prem installation
 */
export type ProxiedEvent = {
  type: ProxiedEventType;
  payload: object;
};

/**
 * a payload consisting of an array of events from an on-prem installation
 */
export type ProxiedEventDTO = {
  events: ProxiedEvent[];
};

export enum UTMParameter {
  SOURCE = 'utm_source',
  MEDIUM = 'utm_medium',
  CAMPAIGN_NAME = 'utm_campaign',
  CAMPAIGN_TERM = 'utm_term',
  CAMPAIGN_CONTENT = 'utm_content',
}

type IntercomCommonAttributes = {
  'Project Type': ProjectType;
  'Project Use Case': string;
  'Classic Project Id': string;
  'Connect Project Id': string;
};

export type IntercomCompanyAttributes = IntercomCommonAttributes & {
  'Subscription Period Start Date': number;
  'Subscription Period End Date': number;
  'Subscription Trial Start Date': number;
  'Subscription Trial End Date': number;
};

export type IntercomUserAttributes = IntercomCommonAttributes & {
  user_id: string;
  email: string;
  name: string;
  company: {
    company_id: string;
    name: string;
  };
  'Onboarding Stage': OnboardingStage;
} & Record<UTMParameter, string>;

export type GoogleTagManagerEvent = { [key: string]: string };
