import React from 'react';

import {
  ActionResponse,
  getValuesByCacheKey,
  keyNameToFriendlyName,
  pickValueSourceByKey,
} from '@shared/actions/sdk/utils';
import {
  Action,
  ActionButtonStyle,
  ActionConfig,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';
import { DEFAULT_SUPPORTED_OPERATORS, DataType, Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { ColumnMetaData } from '../shared/types';

import authConfig from './shared/authConfig';

// TODO: (PARA-1410) add support for `ArrayIsEmpty` and `ArrayIsNotEmpty` operators for MySQL
const operators: Operator[] = DEFAULT_SUPPORTED_OPERATORS.filter(
  (o: Operator) => ![Operator.ArrayIsEmpty, Operator.ArrayIsNotEmpty, Operator.None].includes(o),
);

const fetchTablesAction: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Table Name',
  cacheKey: 'tables',
  refreshDependencies: [(parameters: ActionStepParameters) => parameters.credentials[0]],
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_TABLES,
      actionType: Action.MYSQL,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
  mapRefreshToValues: (response: ActionResponse<string[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((table: string) => ({
        label: table,
        value: table,
      }))
    );
  },
};

const fetchColumnsAction: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  title: 'Table Column',
  cacheKey: 'cachedColumns',
  refreshDependencies: ['table'],
  mapRefreshToValues: (response: any): any => {
    return response?.result && response.result.output;
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    const table = pickValueSourceByKey(options.actionParameters, 'table');
    return {
      actionType: Action.MYSQL,
      intent: Intent.GET_COLUMNS,
      credentials: options.credentials,
      actionParameters: [
        {
          key: 'table',
          source: {
            type: 'VALUE',
            value: table?.value,
          },
        },
      ],
    };
  },
};

export { authConfig };

const config: ActionConfig = {
  actionType: Action.MYSQL,
  name: 'MySQL',
  description: 'Query or update records in your MySQL database.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Connect to a MySQL database',
          placeholder: 'connect a new database',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.WRITESQL,
                    label: 'Write SQL',
                  },
                  {
                    value: Intent.QUERY_RECORDS,
                    label: 'Find Records',
                  },
                  {
                    value: Intent.FIND_RECORD_BY_ID,
                    label: 'Find Record by ID',
                  },
                  {
                    value: Intent.CREATE_RECORD,
                    label: 'Create Record',
                  },
                  {
                    value: Intent.UPDATE_RECORD,
                    label: 'Update Records',
                  },
                  {
                    value: Intent.UPDATE_RECORD_BY_ID,
                    label: 'Update Record by ID',
                  },
                  {
                    value: Intent.DELETE_RECORD,
                    label: 'Delete Records',
                  },
                  {
                    value: Intent.DELETE_RECORD_BY_ID,
                    label: 'Delete Record by ID',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      const tableInput: SidebarInput = {
        id: 'table',
        type: SidebarInputType.DynamicEnum,
        title: 'Table',
        placeholder: 'Select a table',
        required: true,
        source: fetchTablesAction,
        getValues: getValuesByCacheKey,
      };

      const filterInput: SidebarInput = {
        id: 'filterFormula',
        type: SidebarInputType.DynamicConditional,
        title: 'Filter records',
        subtitle:
          'You can filter which records get returned from MySQL by applying one or more filters below.',
        placeholder: 'filter by field',
        source: {
          ...fetchColumnsAction,
          cacheKey: 'columnNames',
          mapRefreshToValues: (response: any) => {
            return Object.keys(response.result.output).map((columnName: string) => ({
              label: columnName,
              value: columnName,
            }));
          },
        },
        getValues: getValuesByCacheKey,
        supportedOperators: [
          ...operators,
          Operator.NumberLessThanOrEqualTo,
          Operator.NumberGreaterThanOrEqualTo,
        ],
        required: false,
      };

      switch (parameters.intent) {
        case Intent.WRITESQL:
          return {
            inputs: [
              {
                id: 'query',
                type: SidebarInputType.Code,
                language: 'sql',
                title: 'Query',
                placeholder: 'Database Query',
                required: true,
                useLightTheme: true,
              },
            ],
          };
        case Intent.QUERY_RECORDS:
          return {
            inputs: [
              tableInput,
              filterInput,
              {
                id: 'limit',
                type: SidebarInputType.Text,
                required: false,
                title: 'Limit records',
                subtitle:
                  'You can limit the maximum number of records that get returned from MySQL.',
              },
              {
                id: 'offset',
                type: SidebarInputType.Text,
                required: false,
                title: 'Offset',
              },
            ],
          };
        case Intent.FIND_RECORD_BY_ID:
          return {
            inputs: [
              tableInput,
              {
                id: 'id',
                type: SidebarInputType.Text,
                required: true,
                title: 'Record ID',
                subtitle: 'Searches by the table’s primary key',
              },
            ],
          };
        case Intent.CREATE_RECORD:
          return {
            inputs: [tableInput],
          };
        case Intent.UPDATE_RECORD:
          return {
            inputs: [tableInput, filterInput],
          };
        case Intent.UPDATE_RECORD_BY_ID:
          return {
            inputs: [
              tableInput,
              {
                id: 'id',
                type: SidebarInputType.Text,
                required: true,
                title: 'Record ID',
                subtitle: 'Searches by the table’s primary key',
              },
            ],
          };
        case Intent.DELETE_RECORD:
          return {
            inputs: [tableInput, filterInput],
          };
        case Intent.DELETE_RECORD_BY_ID:
          return {
            inputs: [
              tableInput,
              {
                id: 'id',
                type: SidebarInputType.Text,
                required: true,
                title: 'Record ID',
                subtitle: 'Deletes by the table’s primary key',
              },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (
        ![Intent.CREATE_RECORD, Intent.UPDATE_RECORD, Intent.UPDATE_RECORD_BY_ID].includes(
          parameters.intent as Intent,
        )
      ) {
        return { inputs: [] };
      }

      return {
        inputs: Object.entries<ColumnMetaData>(
          pickValueSourceByKey<DataType.ANY>(parameters.actionParameters, 'cachedColumns')?.value ||
            [],
        ).map(([columnName, columnInfo]: [string, ColumnMetaData]) => ({
          id: `column-${columnName}`,
          title: keyNameToFriendlyName(columnName),
          subtitle: (
            <>
              This {(columnInfo.type || '').toLowerCase()}-type value will be saved as{' '}
              <code>{columnName}</code> in the resulting row.
            </>
          ),
          required: columnInfo.required,
          type: SidebarInputType.TextArea,
          lines: 1,
        })),
        buttons: [
          {
            id: 'refreshFields',
            type: SidebarInputType.ActionButton,
            title: 'Refresh Fields',
            style: ActionButtonStyle.DEFAULT,
            source: fetchColumnsAction,
          },
        ],
      };
    },
  ],
};

export default config;
