import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';

export const getLists: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'lists',
  title: 'List',
  subtitle: 'List that prospects should be added to',
  refreshDependencies: [],
  mapRefreshToValues: (response: Record<string, any>): EnumInputValue[] => {
    return response.result.output.list.map((list: { name: string; id: string }) => ({
      label: list.name,
      value: list.id,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.PARDOT,
      intent: Intent.GET_ALL_LISTS,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getTimezones: DynamicDataSource = {
  type: DataSourceType.DYNAMIC,
  cacheKey: 'timezones',
  title: 'Timezone',
  subtitle: 'Timezone of the user’s Pardot account.',
  refreshDependencies: [],
  mapRefreshToValues: (response: Record<string, any>): EnumInputValue[] => {
    return response.result.output.map((timezone: string) => ({
      label: timezone,
      value: timezone,
    }));
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      actionType: Action.PARDOT,
      intent: Intent.GET_ALL_TIMEZONES,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};
