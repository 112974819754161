import { getValuesByCacheKey, hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ICredential,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { getCalendars } from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.GOOGLE_CALENDAR,
  provider: ProviderType.GOOGLE,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Create, update, and access Google Calendar events.',
  icon: iconSvg,
  description: 'Google Calendar',
  sidebarSections: [],
  hideOAuthApps: false,
};

const calendarInput: SidebarInput = {
  id: 'calendar',
  title: 'Calendar',
  type: SidebarInputType.NativeDynamicEnumInput,
  subtitle: 'Defaults to your primary calendar if left blank.',
  required: false,
  placeholder: 'Choose a calendar',
  source: getCalendars,
  getValues: getValuesByCacheKey,
};

export const eventIdInput: SidebarInput = {
  id: 'eventId',
  title: 'Event ID',
  required: false,
  type: SidebarInputType.TextArea,
  lines: 1,
  subtitle:
    'An ID from your application to associate this event with. You can use this ID to sync updates to this event later.',
};

const config: ActionConfig = {
  actionType: Action.GOOGLE_CALENDAR,
  name: 'Google Calendar',
  description: 'Create, update, and access Google Calendar events.',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Google Calendar account',
          placeholder: 'connect to Google Calendar',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.GOOGLE,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.CREATE_EVENT,
                    label: 'Create event',
                  },
                  {
                    value: Intent.UPDATE_EVENT,
                    label: 'Update event',
                  },
                  {
                    value: Intent.LIST_EVENTS,
                    label: 'List events',
                  },
                  {
                    value: Intent.GET_EVENT_BY_ID,
                    label: 'Get event by ID',
                  },
                  {
                    value: Intent.DELETE_EVENT,
                    label: 'Delete event',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      if (
        [Intent.UPDATE_EVENT, Intent.DELETE_EVENT, Intent.GET_EVENT_BY_ID].includes(
          parameters.intent as Intent,
        )
      ) {
        return {
          inputs: [
            {
              id: 'eventId',
              title: 'Event ID',
              required: true,
              type: SidebarInputType.TextArea,
              lines: 1,
              subtitle:
                parameters.intent == Intent.UPDATE_EVENT
                  ? 'You can use a Get Events Google Calendar step to get the ID of the event you want to update.'
                  : '',
            },
          ],
        };
      }
      return { inputs: [] };
    },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_EVENT:
        case Intent.UPDATE_EVENT:
          return {
            inputs: [
              {
                id: 'eventName',
                title: 'Event name',
                required: true,
                type: SidebarInputType.TextArea,
                lines: 1,
              },
              {
                id: 'startTime',
                title: 'Start time',
                required: true,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle: 'Accepts Unix timestamp or ISO8601 date formats.',
              },
              {
                id: 'endTime',
                title: 'End time',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle: 'Defaults to one hour after the start time if left blank.',
              },
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    id: 'calendar',
                    title: 'Calendar',
                    required: false,
                    type: SidebarInputType.TextArea,
                    lines: 1,
                    subtitle:
                      'The email address or ID of the calendar this event will be added to. Defaults to the user’s primary calendar if left blank.',
                  }
                : calendarInput,
              {
                id: 'attendees',
                title: 'Attendees',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle:
                  'Accepts an array of email addresses or email addresses separated by commas.',
              },
              {
                id: 'eventLocation',
                title: 'Event location',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
              },
              {
                id: 'eventDescription',
                title: 'Event description',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
              },
              ...(parameters.intent === Intent.CREATE_EVENT ? [eventIdInput] : []),
              {
                id: 'includeMeetLink',
                title: 'Include Google Meet link?',
                subtitle: 'Automatically creates Google Meet conference link for this event.',
                type: SidebarInputType.BooleanInput,
                defaultValue: false,
                required: false,
              },
            ],
          };
        case Intent.LIST_EVENTS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    id: 'calendar',
                    title: 'Calendar',
                    required: false,
                    type: SidebarInputType.TextArea,
                    lines: 1,
                    subtitle:
                      'The email address or ID of the calendar this event will be added to. Defaults to the user’s primary calendar if left blank.',
                  }
                : calendarInput,
              {
                id: 'after',
                title: 'After',
                required: false,
                subtitle: 'Filters events that start after the provided date (Unix timestamp).',
                type: SidebarInputType.TextArea,
                lines: 1,
              },
              {
                id: 'before',
                title: 'Before',
                required: false,
                type: SidebarInputType.TextArea,
                lines: 1,
                subtitle: 'Filters events that end before the provided date (Unix timestamp).',
              },
            ],
          };
        case Intent.GET_EVENT_BY_ID:
        case Intent.DELETE_EVENT:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    id: 'calendar',
                    title: 'Calendar',
                    required: false,
                    type: SidebarInputType.TextArea,
                    lines: 1,
                    subtitle:
                      'The email address or ID of the calendar this event will be added to. Defaults to the user’s primary calendar if left blank.',
                  }
                : calendarInput,
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
