import { ExecutionStatus, WorkflowExecutionUpdate } from '@shared/types/sdk/steps';

import { indexBy } from '../../../utils';
import {
  Action,
  EntitiesState,
  LiveStepExecution,
  PastStepExecution,
  StepExecution,
} from '../../types';

export const initialState: EntitiesState<StepExecution> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<StepExecution> = initialState,
  action: Action,
): EntitiesState<StepExecution> {
  switch (action.type) {
    case 'WORKFLOW_EXECUTION_STEP_DATA_GET_START':
    case 'STEP_EXECUTION_ENTITY_FETCH_START':
      return {
        ...state,
        processing: true,
        errorMessage: undefined,
      };
    case 'WORKFLOW_EXECUTION_STEP_DATA_GET_SUCCESS':
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          [action.stepId]: {
            ...(state.entities[action.stepId] || {}),
            incomplete: false,
            output: action.stepData.output,
          } as PastStepExecution,
        },
      };
    case 'STEP_EXECUTION_ENTITY_FETCH_SUCCESS':
      const stepExecutionsWithPriorOutputs = action.stepExecutions.map(
        (stepExecution: PastStepExecution) => {
          if (stepExecution.id === state.entities[stepExecution.stepId]?.id) {
            return {
              ...state.entities[stepExecution.stepId],
              ...stepExecution,
            };
          }
          return stepExecution;
        },
      );
      return {
        ...state,
        processing: false,
        entities: {
          ...state.entities,
          ...(indexBy('stepId', stepExecutionsWithPriorOutputs) as Record<
            string,
            PastStepExecution
          >),
        },
      };
    case 'WORKFLOW_EXECUTION_STEP_DATA_GET_FAILED':
    case 'STEP_EXECUTION_ENTITY_FETCH_FAILURE':
      return {
        ...state,
        processing: false,
        errorMessage: action.message,
      };
    case 'TEST_STEP_START': {
      const stepExecutionUpdates = action.stepIds.reduce(
        (updates: EntitiesState<StepExecution>['entities'], stepId: string) => {
          updates = {
            ...updates,
            [stepId]: {
              incomplete: true,
              status: ExecutionStatus.EXECUTING,
              abortRequest: action.abortRequest,
            },
          };
          return updates;
        },
        {},
      );
      return {
        ...state,
        errorMessage: undefined,
        entities: {
          ...state.entities,
          ...stepExecutionUpdates,
        },
      };
    }
    case 'TEST_WORKFLOW_UPDATE':
    case 'TEST_STEP_UPDATE': {
      const stepExecutionUpdates: EntitiesState<StepExecution>['entities'] = action.payload.reduce(
        (updates: EntitiesState<StepExecution>['entities'], update: WorkflowExecutionUpdate) => {
          let stepId: string = '';
          let stepExecution: Partial<StepExecution> = {};
          if (update.type === 'WAITING') {
            stepId = update.stepId;
            stepExecution = { status: ExecutionStatus.PAUSED };
          }
          if (update.type === 'SUCCESS') {
            stepId = update.stepExecution.stepId;

            const {
              stepExecution: { startTime, endTime },
            } = update;

            stepExecution = {
              status: ExecutionStatus.SUCCEEDED,
              start: new Date(startTime),
              end: new Date(endTime),
              output: update.continuation.output,
            };
          }
          if (update.type === 'FAILURE') {
            stepExecution = {
              status: ExecutionStatus.FAILED,
              output: update.error,
            };
            if (update.stepExecution) {
              stepId = update.stepExecution.stepId;
            } else if (action.type === 'TEST_STEP_UPDATE') {
              // For step test failures not associated with an explicit step, associate the
              // failure with all the steps that were tested
              return action.stepIds.reduce(
                (updates: EntitiesState<StepExecution>['entities'], stepId: string) => {
                  return {
                    ...updates,
                    [stepId]: { ...updates[stepId], ...stepExecution },
                  } as EntitiesState<StepExecution>['entities'];
                },
                updates,
              );
            }
          }

          if (!stepId) {
            return updates;
          }
          return {
            ...updates,
            [stepId]: { ...updates[stepId], ...stepExecution },
          } as EntitiesState<StepExecution>['entities'];
        },
        state.entities,
      );
      return {
        ...state,
        entities: {
          ...state.entities,
          ...stepExecutionUpdates,
        },
      };
    }
    case 'TEST_STEP_COMPLETE': {
      const stepExecutionUpdates = action.stepIds.reduce(
        (updates: EntitiesState<StepExecution>['entities'], stepId: string) => {
          updates = {
            ...updates,
            [stepId]: {
              ...state.entities[stepId],
              incomplete: true,
              status:
                state.entities[stepId].status === ExecutionStatus.EXECUTING
                  ? ExecutionStatus.SUCCEEDED
                  : state.entities[stepId].status,
            } as LiveStepExecution,
          };
          return updates;
        },
        {},
      );
      return {
        ...state,
        entities: {
          ...state.entities,
          ...stepExecutionUpdates,
        },
      };
    }
    case 'TEST_STEP_CANCEL': {
      const stepExecutionUpdates = { ...state.entities };
      action.stepIds.forEach((stepId: string) => {
        // Remove the paused step execution from store if no payload was received
        if (
          stepExecutionUpdates[stepId] &&
          stepExecutionUpdates[stepId].incomplete &&
          !stepExecutionUpdates[stepId].output
        ) {
          delete stepExecutionUpdates[stepId];
        }
      });
      return {
        ...state,
        entities: stepExecutionUpdates,
      };
    }
    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;
    default:
      return state;
  }
}
