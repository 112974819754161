import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions/';
import { DataType, KeyedSource, Source } from '@shared/types/sdk/resolvers';
import { DelayUnit } from '@shared/types/sdk/steps';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

export const authConfig: ActionConfig = {
  actionType: Action.FTP,
  provider: ProviderType.FTP,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect your FTP server',
  description: 'Add ftp server to Paragon',
  icon: iconSvg,
  sidebarSections: [
    (parameters: ActionStepParameters): SidebarSection => {
      const protocolSource: Source | undefined = parameters.actionParameters.find(
        (keySource: KeyedSource<DataType.ANY>) => keySource.key === 'PROTOCOL',
      )?.source;

      const optionalInputs: SidebarInput[] =
        protocolSource && protocolSource.type === 'VALUE' && protocolSource.value === 'SFTP'
          ? [
              {
                id: 'PRIVATE_KEY',
                title: 'Private Key',
                required: false,
                placeholder: 'private key',
                type: SidebarInputType.ValueTextArea,
              },
              {
                id: 'PRIVATE_KEY_PASSWORD',
                title: 'Private Key Password',
                placeholder: 'password',
                inputType: 'password',
                type: SidebarInputType.ValueText,
              },
            ]
          : [];

      return {
        inputs: [
          {
            id: 'name',
            title: 'Name this FTP server',
            placeholder: 'e.g. Production, Testing, etc.',
            type: SidebarInputType.ValueText,
          },
          {
            id: 'PROTOCOL',
            title: 'Protocol',
            type: SidebarInputType.Enum,
            defaultValue: 'FTP',
            getValues: () => {
              return [
                { value: 'FTP', label: 'FTP' },
                { value: 'SFTP', label: 'SFTP' },
              ];
            },
          },
          {
            id: 'HOST',
            title: 'Host',
            placeholder: 'example.server.com',
            type: SidebarInputType.ValueText,
          },
          {
            id: 'PORT',
            title: 'Port',
            placeholder: '21',
            type: SidebarInputType.ValueText,
          },
          {
            id: 'USERNAME',
            title: 'Username',
            placeholder: 'username',
            type: SidebarInputType.ValueText,
          },
          {
            id: 'PASSWORD',
            title: 'Password',
            placeholder: 'password',
            type: SidebarInputType.ValueText,
            inputType: 'password',
          },
          ...optionalInputs,
        ],
        buttons: [
          createTestConnectionButton(Action.FTP, [
            'PROTOCOL',
            'HOST',
            'PORT',
            'USERNAME',
            'PASSWORD',
            'PRIVATE_KEY',
            'PRIVATE_KEY_PASSWORD',
          ]),
        ],
      };
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.FTP,
  name: 'FTP',
  description: 'Download or upload files via FTP or SFTP.',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Choose your FTP server',
          placeholder: 'Choose your FTP server',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    value: Intent.LIST_FILES,
                    label: 'List files',
                  },
                  {
                    value: Intent.DOWNLOAD_FILE,
                    label: 'Download file',
                  },
                  {
                    value: Intent.UPLOAD_FILE,
                    label: 'Upload file',
                  },
                  {
                    value: Intent.DELETE_FILE,
                    label: 'Delete file',
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.LIST_FILES:
          return {
            inputs: [
              {
                id: 'path',
                type: SidebarInputType.TextArea,
                title: 'Path',
                required: true,
                lines: 1,
              },
              {
                id: 'timeConstraint',
                type: SidebarInputType.TimeConstraintInput,
                title: 'Filter by time constraint ',
                subtitle:
                  'Only return files that were created or modified within the provided time constraint.',
                required: false,
                units: [DelayUnit.DAYS, DelayUnit.HOURS, DelayUnit.MINUTES, DelayUnit.SECONDS],
              },
            ],
          };
        case Intent.DOWNLOAD_FILE:
        case Intent.DELETE_FILE:
          return {
            inputs: [
              {
                id: 'path',
                type: SidebarInputType.TextArea,
                title: 'Path',
                subtitle: 'Include the filename in the file path.',
                required: true,
                lines: 1,
              },
            ],
          };
        case Intent.UPLOAD_FILE:
          return {
            inputs: [
              {
                id: 'file',
                type: SidebarInputType.Text,
                title: 'File',
                required: true,
                dataType: DataType.FILE,
              },
              {
                id: 'path',
                type: SidebarInputType.TextArea,
                title: 'Path',
                subtitle: 'Include the filename in the file path.',
                required: true,
                lines: 1,
              },
              {
                id: 'append',
                type: SidebarInputType.BooleanInput,
                title: 'Append to existing file',
                subtitle: 'If set to "Yes", data will be appended to a file if it exists.',
                required: false,
                defaultValue: false,
              },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
