export type { Credentials } from '../configs/config';

export type Environment = {
  TABLEAU_CLIENT_ID: string;
  TABLEAU_CLIENT_SECRET_ID: string;
  TABLEAU_CLIENT_SECRET_VALUE: string;
  TABLEAU_SCOPES: string;
};

export enum DataQualityWarning {
  DEPRECATED = 'DEPRECATED',
  WARNING = 'WARNING',
  STALE = 'STALE',
  SENSITIVE_DATA = 'SENSITIVE_DATA',
  MAINTENANCE = 'MAINTENANCE',
}

export enum AssetType {
  DATABASE = 'database',
  TABLE = 'table',
  DATA_SOURCE = 'datasource',
  FLOW = 'flow',
}

export type TableauSiteDTO = {
  id: string;
  contentUrl: string;
};

export type CredentialsLoginDTO = {
  personalAccessTokenName: string;
  personalAccessTokenSecret: string;
  site: TableauSiteDTO;
};

export type CredentialsLoginResponse = {
  site: TableauSiteDTO;
  token: string;
  user: Record<string, any>;
};

export type LoginRequestDTO = Record<string, any>;

export type LoginResponseDTO = Record<string, any>;

export type CreateDataQualityWarningDTO = {
  contentType: string;
  contentId: string;
  warningType: DataQualityWarning;
  status?: boolean;
  message?: string;
  isSevere?: boolean;
};

export type UpdateDataQualityWarningDTO = {
  warningId: string;
  contentType?: string;
  contentId?: string;
  warningType?: DataQualityWarning;
  status?: boolean;
  message?: string;
  isSevere?: boolean;
};

export type GetDataQualityWarningByIdDTO = {
  warningId: string;
};

export type DeleteDataQualityWarningByIdDTO = GetDataQualityWarningByIdDTO;

export type GetDataQualityWarningByContentDTO = {
  contentId: string;
  contentType: string;
};

export type DeleteDataQualityWarningByContentDTO = GetDataQualityWarningByContentDTO;

export type ListAssetItemsDTO = {
  contentType: string;
};

export type DataQualityWarningDTO = {
  type: DataQualityWarning;
  message?: string;
  isActive?: boolean;
  isSevere?: boolean;
};

export type DataQualityWarningRequestDTO = {
  dataQualityWarning: DataQualityWarningDTO;
};

export type DataQualityWarningResponseDTO = DataQualityWarningRequestDTO;

export type AssetItemDTO = {
  name: string;
  id: string;
  type: string;
};

export type AssetItemListDTO = {
  [key: string]: {
    [key: string]: AssetItemDTO[];
  };
};

export type DataQualityWarningListResponseDTO = {
  dataQualityWarningList: {
    dataQualityWarning: DataQualityWarningResponseDTO[];
  };
};
