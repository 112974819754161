import { OAuthCredentialValueDefaults, SidebarInputType } from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import { TWITTER_AUTH_BASE_URL, TWITTER_TOKEN_API_URL } from '../shared/constants';

import iconSvg from './icon.svg';

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Twitter',
  description: 'Connect to your users Twitter accounts',
  authUrl: TWITTER_AUTH_BASE_URL,
  accessTokenUrl: TWITTER_TOKEN_API_URL,
  iconSvg,
  clientIdLabel: 'Consumer API Key',
  clientSecretLabel: 'Consumer Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Consumer API Key',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/twitter`,
      documentationLinkLabel: 'How to obtain a Consumer API Key?',
    },
    { type: SidebarInputType.ValueText, title: 'Consumer Secret', id: 'clientSecret' },
  ],
  scopes: { values: [] },
  documentationLink: 'https://developer.twitter.com/en/docs/twitter-api',
};
