import { getValuesByCacheKey, hasOAuthAppCredential } from '@shared/actions/sdk/utils';
import { ICredential } from '@shared/entities/sdk/credential';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { getAssignees, getProjects, getTeams, getWorkSpaces } from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.ASANA,
  provider: ProviderType.ASANA,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Asana Manage tasks and projects in Asana.',
  icon: iconSvg,
  description: 'Asana',
  sidebarSections: [],
  hideOAuthApps: false,
};

const workspaceTextInput: SidebarInput = {
  id: 'workspace',
  type: SidebarInputType.TextArea,
  title: 'Workspace',
  lines: 1,
};

const workSpaceInput: SidebarInput = {
  id: 'workspace',
  type: SidebarInputType.DynamicEnum,
  title: 'Workspace',
  required: true,
  source: getWorkSpaces,
  getValues: getValuesByCacheKey,
};

const teamTextInput: SidebarInput = {
  id: 'team',
  type: SidebarInputType.TextArea,
  title: 'Team',
  required: false,
  lines: 1,
};

const teamInput: SidebarInput = {
  id: 'team',
  type: SidebarInputType.DynamicEnum,
  title: 'Team',
  required: false,
  source: getTeams,
  getValues: getValuesByCacheKey,
};

const nameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.TextArea,
  title: 'Name',
  lines: 1,
  required: true,
};

const projectTextInput: SidebarInput = {
  id: 'project',
  type: SidebarInputType.TextArea,
  title: 'Project',
  required: false,
  lines: 1,
};

const assigneeInput: SidebarInput = {
  id: 'assignee',
  type: SidebarInputType.DynamicEnum,
  title: 'Assignee',
  required: false,
  source: getAssignees,
  getValues: getValuesByCacheKey,
};

const projectInput: SidebarInput = {
  id: 'project',
  type: SidebarInputType.DynamicEnum,
  title: 'Project',
  required: true,
  source: getProjects,
  getValues: getValuesByCacheKey,
};

const notesInput: SidebarInput = {
  id: 'notes',
  type: SidebarInputType.TextArea,
  title: 'Notes',
  required: false,
};

const externalIdInput: SidebarInput = {
  id: 'gid',
  type: SidebarInputType.TextArea,
  title: 'External ID',
  lines: 1,
  required: false,
};

const assigneeTextInput: SidebarInput = {
  id: 'assignee',
  type: SidebarInputType.TextArea,
  title: 'Assignee',

  required: false,
  lines: 1,
};

const config: ActionConfig = {
  actionType: Action.ASANA,
  name: 'Asana',
  description: 'Asana Manage tasks and projects in Asana.',
  icon: iconSvg,
  sidebarSections: [
    (parameters: ActionStepParameters, credentials: Record<string, ICredential>) => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Asana account',
          placeholder: 'connect to Asana',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasOAuthAppCredential(parameters, credentials)
          ? [
              {
                id: 'token',
                title: 'App authentication',
                type: SidebarInputType.UserSuppliedCredential,
                providerType: ProviderType.ASANA,
                supportedTokenTypes: [TokenType.REFRESH_TOKEN, TokenType.ACCESS_TOKEN],
              } as UserSuppliedCredentialInput,
            ]
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    title: 'Comment',
                    items: [
                      {
                        value: Intent.CREATE_COMMENT,
                        label: 'Create Comment',
                      },
                    ],
                  },
                  {
                    title: 'Projects',
                    items: [
                      {
                        value: Intent.CREATE_PROJECT,
                        label: 'Create Project',
                      },
                      {
                        value: Intent.GET_PROJECTS,
                        label: 'Get Projects',
                      },
                      {
                        value: Intent.GET_PROJECT_BY_ID,
                        label: 'Get Project by ID',
                      },
                    ],
                  },
                  {
                    title: 'Tasks',
                    items: [
                      {
                        value: Intent.CREATE_TASK,
                        label: 'Create Task',
                      },
                      {
                        value: Intent.UPDATE_TASK,
                        label: 'Update Task',
                      },
                      {
                        value: Intent.GET_TASKS,
                        label: 'Get Tasks',
                      },
                      {
                        value: Intent.GET_TASKS_BY_ID,
                        label: 'Get Task by ID',
                      },
                      {
                        value: Intent.GET_TASK_BY_EXTERNAL_ID,
                        label: 'Get Task by External ID',
                      },
                    ],
                  },
                  {
                    title: 'Teams',
                    items: [
                      {
                        value: Intent.GET_TEAMS,
                        label: 'Get Teams',
                      },
                    ],
                  },
                  {
                    title: 'Workspaces',
                    items: [
                      {
                        value: Intent.GET_WORKSPACES,
                        label: 'Get Workspaces',
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (
      parameters: ActionStepParameters,
      credentials: Record<string, ICredential>,
    ): SidebarSection => {
      switch (parameters.intent) {
        case Intent.CREATE_COMMENT:
          return {
            inputs: [
              ...(hasOAuthAppCredential(parameters, credentials)
                ? []
                : [workSpaceInput, projectInput]),
              {
                id: 'task',
                type: SidebarInputType.TextArea,
                title: 'Task ID',
                subtitle:
                  'The ID of the task the comment will be added to. The comment will be authored by the currently authenticated user.',
                required: true,
                lines: 1,
              },
              {
                id: 'text',
                type: SidebarInputType.TextArea,
                title: 'Text',
                lines: 2,
                required: true,
                placeholder: 'This is a comment.',
              },
            ],
          };
        case Intent.CREATE_PROJECT:
          return {
            inputs: [
              { ...nameInput, placeholder: 'Stuff to buy' },
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...workspaceTextInput,
                    subtitle: 'The ID of the workspace that this project will be created in.',
                  }
                : workSpaceInput,
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...teamTextInput,
                    subtitle: 'The ID of the team that this project will be shared with.',
                  }
                : teamInput,
              {
                ...notesInput,
                placeholder: 'These are things we need to purchase.',
                lines: 1,
              },
            ],
          };
        case Intent.GET_PROJECTS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? { ...workspaceTextInput, required: false }
                : {
                    ...workSpaceInput,
                    required: false,
                    subtitle:
                      'Select only those workspaces that are organizations and contain teams.',
                  },
              hasOAuthAppCredential(parameters, credentials)
                ? teamTextInput
                : { ...teamInput, required: false },
            ],
          };
        case Intent.GET_PROJECT_BY_ID:
          return {
            inputs: [
              {
                id: 'projectFilterId',
                type: SidebarInputType.TextArea,
                title: 'Project ID',
                lines: 1,
                required: true,
              },
            ],
          };
        case Intent.CREATE_TASK:
          return {
            inputs: [
              { ...nameInput, placeholder: 'Task Name' },
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...workspaceTextInput,
                    subtitle: 'The ID of the Workspace that this Task will be created in.',
                  }
                : workSpaceInput,
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...projectTextInput,
                    subtitle: 'The ID of the Project that this Task will be created in.',
                  }
                : projectInput,
              { ...notesInput, lines: 2 },
              {
                id: 'dueOnDate',
                type: SidebarInputType.TextArea,
                title: 'Due On',
                lines: 1,
                placeholder: 'YYYY-MM-DD',
                subtitle:
                  'The date on which  this task is due. Cannot be used together with Due At.',
                required: false,
              },
              {
                id: 'dueAtDate',
                type: SidebarInputType.TextArea,
                title: 'Due At',
                lines: 1,
                placeholder: '2019-09-15T02:06:58.147Z',
                subtitle:
                  'The date and time (ISO timestamp) at which this task is due . Cannot be used together with Due On.',
                required: false,
              },
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...assigneeTextInput,
                    subtitle: 'The ID of the Asana user this task will be assigned to.',
                  }
                : assigneeInput,
              {
                ...externalIdInput,
                subtitle:
                  'An ID from your application to associate this task with. You can use this ID to sync updates to this task later.',
              },
            ],
          };
        case Intent.UPDATE_TASK:
          return {
            inputs: [
              {
                id: 'taskId',
                type: SidebarInputType.TextArea,
                title: 'Task ID',
                lines: 1,
                subtitle: 'The ID of the Task that will be updated.',
                required: true,
              },
              {
                id: 'completeStatus',
                type: SidebarInputType.Enum,
                title: 'Completed Status',
                required: false,
                getValues: () => {
                  return [
                    { label: 'True', value: 'true' },
                    { label: 'False', value: 'false' },
                  ];
                },
              },
              { ...nameInput, required: false, placeholder: 'Task Name' },
              { ...notesInput, lines: 2 },
              {
                id: 'dueOnDate',
                type: SidebarInputType.TextArea,
                title: 'Due On',
                lines: 1,
                placeholder: 'YYYY-MM-DD',
                subtitle:
                  'The date on which  this task is due. Cannot be used together with Due At.',
                required: false,
              },
              {
                id: 'dueAtDate',
                type: SidebarInputType.TextArea,
                title: 'Due At',
                lines: 1,
                placeholder: '2019-09-15T02:06:58.147Z',
                subtitle:
                  'The date and time (ISO timestamp) at which this task is due . Cannot be used together with Due On.',
                required: false,
              },
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...assigneeTextInput,
                    subtitle: 'The ID of the Asana user this task will be assigned to.',
                  }
                : assigneeInput,
              {
                ...externalIdInput,
                subtitle:
                  'An ID from your application to associate this task with. You can use this ID to sync updates to this task later.',
              },
            ],
          };
        case Intent.GET_TASKS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...workspaceTextInput,
                    subtitle: 'The ID of the workspace to filter tasks on.',
                    required: false,
                  }
                : {
                    ...workSpaceInput,
                    subtitle: 'The workspace to filter tasks on.',
                    required: false,
                  },
              hasOAuthAppCredential(parameters, credentials)
                ? { ...projectTextInput, subtitle: 'The ID of the project to filter tasks on.' }
                : projectInput,
              hasOAuthAppCredential(parameters, credentials)
                ? { ...assigneeTextInput, subtitle: 'The ID of the assignee to filter tasks on.' }
                : {
                    ...assigneeInput,
                    subtitle: 'The assignee to filter tasks on.',
                  },
              {
                id: 'completedSince',
                type: SidebarInputType.TextArea,
                title: 'Completed since',
                lines: 1,
                placeholder: '2014-04-25T16:15:47-04:00',
                subtitle:
                  'Only return tasks that are either incomplete or that have been completed since this time (ISO or Unix timestamp).',
                required: false,
              },
            ],
          };
        case Intent.GET_TASKS_BY_ID:
          return {
            inputs: [
              {
                id: 'taskId',
                type: SidebarInputType.TextArea,
                title: 'Task ID',
                required: true,
                lines: 1,
              },
            ],
          };
        case Intent.GET_TASK_BY_EXTERNAL_ID:
          return {
            inputs: [
              {
                ...externalIdInput,
                required: true,
                subtitle:
                  'The ID that this task is associated or synced with, from your application.',
              },
            ],
          };
        case Intent.GET_TEAMS:
          return {
            inputs: [
              hasOAuthAppCredential(parameters, credentials)
                ? {
                    ...workspaceTextInput,
                    subtitle: 'Returns the teams in this workspace visible to the authorized user.',
                  }
                : {
                    ...workSpaceInput,
                    subtitle: 'Returns the teams in this workspace visible to the authorized user.',
                  },
            ],
          };
        case Intent.GET_WORKSPACES:
          return {
            inputs: [],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
