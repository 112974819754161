import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs/config';
import iconSvg from '../configs/icon.svg';

import { accessKeyIdInput, regionInput, secretAccessKeyInput } from './inputs';

const authConfig: ActionConfig = {
  actionType: Action.AMAZON_S3,
  provider: ProviderType.AMAZON_S3,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Amazon S3 account',
  icon: iconSvg,
  description: "Connect to your users' Amazon S3 accounts",
  sidebarSections: [
    {
      inputs: [accessKeyIdInput, secretAccessKeyInput, regionInput],
      buttons: [createTestConnectionButton(Action.AMAZON_S3, Object.values(CredentialKeys))],
    },
  ],
};

export default authConfig;
