import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

import authConfig from './authConfig';

const connectConfig: ActionConfig = {
  actionType: Action.IMANAGE,
  name: 'iManage',
  description: `Connect to your users' iManage accounts`,
  icon: iconSvg,
  provider: ProviderType.IMANAGE,
  sidebarSections: [],
};
export { authConfig };

export default connectConfig;
