import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.CALENDLY,
  provider: ProviderType.CALENDLY,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Calendly accounts',
  icon: iconSvg,
  description: "Connect to your users' Calendly account to manage their events and invites.",
  sidebarSections: [],
};

export default authConfig;
