import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig, SidebarInputType } from '@shared/types/sdk/actions';

import iconSvg from '../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.PARDOT,
  provider: ProviderType.PARDOT,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Pardot account',
  icon: iconSvg,
  description: 'Pardot',
  sidebarSections: [
    {
      inputs: [
        {
          type: SidebarInputType.ValueText,
          id: 'PARDOT_BUSINESS_UNIT_ID',
          title: 'Enter your Pardot Business Unit ID',
          subtitle: `Your Business Unit ID can be found in your Salesforce Pardot setup.`,
          placeholder: 'Pardot Business Unit ID',
          required: true,
        },
      ],
      buttons: [],
    },
  ],
  hideOAuthApps: true,
  accountTypes: [
    {
      id: 'default',
      accountDescription: 'Production Account',
      scheme: AuthenticationScheme.OAUTH,
      oauthParameters: [
        {
          key: 'sandbox',
          source: {
            type: 'VALUE',
            value: false,
          },
        },
      ],
    },
    {
      id: 'sandbox',
      accountDescription: 'Sandbox Account',
      scheme: AuthenticationScheme.OAUTH,
      oauthParameters: [
        {
          key: 'sandbox',
          source: {
            type: 'VALUE',
            value: true,
          },
        },
      ],
    },
  ],
};

export default authConfig;
