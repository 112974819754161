import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.KLAVIYO;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.KLAVIYO;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'KLAVIYO_TEST_CONNECTION',
  CREATE_LIST = 'KLAVIYO_CREATE_LIST',
  GET_LISTS = 'KLAVIYO_GET_LISTS',
  ADD_SUBSCRIBER_TO_LIST = 'KLAVIYO_ADD_SUBSCRIBER_TO_LIST',
  GET_LIST_SUBSCRIBER = 'KLAVIYO_GET_LIST_SUBSCRIBER',
  UNSUBSCRIBE_FROM_LIST = 'KLAVIYO_UNSUBSCRIBE_FROM_LIST',
  CREATE_CAMPAIGN = 'KLAVIYO_CREATE_CAMPAIGN',
  GET_CAMPAIGN = 'KLAVIYO_GET_CAMPAIGN',
  SEND_CAMPAIGN = 'KLAVIYO_SEND_CAMPAIGN',
  CREATE_TEMPLATE = 'KLAVIYO_CREATE_TEMPLATE',
  GET_TEMPLATES = 'KLAVIYO_GET_TEMPLATES',
  GET_PROFILE = 'KLAVIYO_GET_PROFILE',
  UPDATE_PROFILE = 'KLAVIYO_UPDATE_PROFILE',
}

export type ActionStepParameters = BaseActionStepParameters &
  (
    | {
        intent: Intent.TEST_CONNECTION;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LISTS;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_LIST_SUBSCRIBER;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UNSUBSCRIBE_FROM_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.ADD_SUBSCRIBER_TO_LIST;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.SEND_CAMPAIGN;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.CREATE_TEMPLATE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_TEMPLATES;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.GET_PROFILE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: Intent.UPDATE_PROFILE;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
    | {
        intent: ConnectIntent.GET_PROVIDER_ID;
        actionParameters: KeyedSource<DataType.ANY>[];
      }
  );

export type Credentials = {
  KLAVIYO_API_KEY: string;
};
