import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'signature'
  | 'organization_read'
  | 'permission_read'
  | 'user_read'
  | 'user_write'
  | 'account_read'
  | 'domain_read'
  | 'identity_provider_read';

export const scopes: Record<ScopeName, ScopeValue> = {
  account_read: {
    label: 'account_read',
    name: 'account_read',
    description: 'Get account data.',
    required: true,
  },
  domain_read: {
    label: 'domain_read',
    name: 'domain_read',
    description: 'Get data on claimed domains for the organization.',
    required: true,
  },
  identity_provider_read: {
    label: 'identity_provider_read',
    name: 'identity_provider_read',
    description: 'Get data on already-defined identity providers for an organization.',
    required: true,
  },
  organization_read: {
    label: 'organization_read',
    name: 'organization_read',
    description: 'Get lists of organizations and organization data.',
    required: true,
  },
  permission_read: {
    label: 'permission_read',
    name: 'permission_read',
    description: 'Get lists of permission profiles associated with an account.',
    required: true,
  },
  signature: {
    label: 'signature',
    name: 'signature',
    description: 'Call most eSignature REST API endpoints.',
    required: true,
  },
  user_read: {
    label: 'user_read',
    name: 'user_read',
    description: 'Read user data.',
    required: true,
  },
  user_write: {
    label: 'user_write',
    name: 'user_write',
    description: 'Update, create, or delete users.',
    required: true,
  },
};

export const requiredScopes: ScopeName[] = [
  'account_read',
  'domain_read',
  'identity_provider_read',
  'organization_read',
  'permission_read',
  'signature',
  'user_read',
  'user_write',
];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'DocuSign',
  description: 'Manage signatures, agreements, and documents in DocuSign',
  authUrl: 'https://account.docusign.com/oauth/auth',
  accessTokenUrl: 'https://account.docusign.com/oauth/token',
  iconSvg,
  clientIdLabel: 'Integration Key',
  clientSecretLabel: 'Secret Key',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Integration Key',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/docusign`,
      documentationLinkLabel: 'How to obtain a Integration Key?',
    },
    { type: SidebarInputType.ValueText, title: 'Secret Key', id: 'clientSecret' },
    {
      type: SidebarInputType.ValueText,
      title: 'Development Integration Key',
      id: 'developmentIntegrationKey',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/docusign`,
      documentationLinkLabel: 'How to obtain a Development Integration Key?',
      placeholder: 'Optional',
      required: false,
    },
    {
      type: SidebarInputType.ValueText,
      title: 'Development Secret Key',
      id: 'developmentSecretKey',
      placeholder: 'Optional',
      required: false,
    },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/docusign',
};
