import { Action } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';

const config: ActionConfig = {
  actionType: Action.AIRTABLE,
  name: 'Airtable',
  description: 'Find, create, and update records from your Airtable bases.',
  icon: iconSvg,
  sidebarSections: [],
};

export default config;
