import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import { recordTypeSelector } from './inputs';

const triggerConfig: ActionConfig = {
  actionType: Action.PIPEDRIVE,
  name: 'Pipedrive',
  description: 'Trigger when records are created or updated in Pipedrive',
  icon: iconSvg,
  provider: ProviderType.PIPEDRIVE,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_RECORD_CREATED,
              label: 'Record Created',
            },
            {
              value: Intent.TRIGGER_RECORD_UPDATED,
              label: 'Record Updated',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      if (!parameters.intent) {
        return { inputs: [] };
      }

      return {
        inputs: [recordTypeSelector(parameters.intent as Intent)],
      };
    },
  ],
};

export default triggerConfig;
