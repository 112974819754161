import { Dispatch } from 'redux';

import { debug } from '@shared/logger/sdk/legacy';
import { Step, StepReorderParams } from '@shared/types/sdk/steps';
import {
  getStepInWorkflowById,
  removeStepFromWorkflow,
  reorderStepInWorkflow,
  workflowStepsToStateMachine,
} from '@shared/workflow/sdk';

import * as api from '../../../services/api';
import { getErrorMessage } from '../../../utils';
import { flushPendingUpdates } from '../../index';
import { Action, State, WorkFlow, WorkflowEntity } from '../../types';

export function getWorkflowFromEntity(state: State, workflowId: string): WorkFlow {
  const { stepIds, ...rest }: WorkflowEntity = state.entities.workflows.entities[workflowId];
  return {
    ...rest,
    steps: stepIds.map((stepId: string) => state.entities.steps.entities[stepId]),
  };
}

export const getSteps =
  (projectId: string, workflowId: string) => async (dispatch: Dispatch<Action>) => {
    dispatch({ type: 'STEP_ENTITY_FETCH_START' });

    const response = await api.get(`/projects/${projectId}/workflows/${workflowId}/steps`);
    debug('got steps for workflow', response);

    if (response.ok) {
      const steps = await response.json();

      dispatch({
        type: 'STEP_ENTITY_FETCH_SUCCESS',
        payload: [...steps.map((step: any) => ({ ...step }))],
      });
      dispatch({ type: 'SET_STATE_MACHINE', stateMachine: workflowStepsToStateMachine(steps) });
    } else {
      dispatch({
        type: 'STEP_ENTITY_FETCH_FAILED',
        message: await getErrorMessage(response),
      });
    }
  };

export const getAllActionSteps = (projectId: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({ type: 'STEP_ENTITY_FETCH_START' });

  const response = await api.get(`/projects/${projectId}/action-steps`);
  debug('got action steps for workflow', response);

  if (response.ok) {
    const steps = await response.json();

    dispatch({
      type: 'STEP_ENTITY_FETCH_SUCCESS',
      payload: steps,
    });
  } else {
    dispatch({
      type: 'STEP_ENTITY_FETCH_FAILED',
      message: await getErrorMessage(response),
    });
  }
};

export const addStep =
  (projectId: string, workflowId: string, step: object, parent: StepReorderParams) =>
  async (dispatch: Dispatch<Action>, getState: () => State) => {
    debug('adding step', { projectId, workflowId, step });
    dispatch({ type: 'STEP_ENTITY_CREATE_REQUEST' });
    await flushPendingUpdates();

    const response = await api.post(`/projects/${projectId}/workflows/${workflowId}/steps`, {
      step,
      parent,
    });

    if (response.ok) {
      const workflow: WorkFlow = await response.json();
      debug('addStep > success', workflow);

      let stepsFromStore = getState().entities.steps.entities;
      const stepIdsBeforeRequest: string[] = Object.keys(stepsFromStore);
      dispatch({
        type: 'STEP_ENTITY_CREATE_SUCCESS',
        payload: workflow,
      });
      dispatch({
        type: 'STEP_ENTITY_FETCH_SUCCESS',
        payload: workflow.steps,
      });

      stepsFromStore = getState().entities.steps.entities;
      const stepIdAfterRequest: string[] = Object.keys(stepsFromStore);
      const createdStepId: string = stepIdAfterRequest.filter(
        (id: string) => !stepIdsBeforeRequest.includes(id),
      )[0];
      debug('createdStepId >', createdStepId);
      dispatch({ type: 'SET_ADD_STEP_CREATED_STEP_ID', createdStepId });
    } else {
      dispatch({
        type: 'STEP_ENTITY_CREATE_FAILURE',
        message: await getErrorMessage(response),
      });
    }
  };

export const deleteStep =
  (projectId: string, workflowId: string, stepId: string) =>
  async (dispatch: Dispatch<Action>, getState: () => State) => {
    debug('deleteStep', { projectId, workflowId, stepId });

    // to make the app responsive, we optimistically update the state
    // with the updated workflow
    // when the API call finishes, it'll also return the updated workflow
    const state: State = getState();
    const workflow: WorkFlow = getWorkflowFromEntity(state, workflowId);
    const step: Step = getStepInWorkflowById(workflow, stepId);
    const newWorkflow: WorkFlow = removeStepFromWorkflow(workflow, step);
    dispatch({ type: 'STEP_ENTITY_DELETE_REQUEST', payload: newWorkflow });
    await flushPendingUpdates();

    const response = await api.del(
      `/projects/${projectId}/workflows/${workflowId}/steps/${step.id}`,
      {},
    );
    if (response.ok) {
      const workflow: WorkFlow = await response.json();
      debug('success', step);
      dispatch({ type: 'STEP_ENTITY_DELETE_SUCCESS', payload: workflow });
    } else {
      dispatch({
        type: 'STEP_ENTITY_DELETE_FAILURE',
        message: await getErrorMessage(response),
      });
    }
  };

export const reorderStep =
  (projectId: string, workflowId: string, stepId: string, params: StepReorderParams) =>
  async (dispatch: Dispatch<Action>, getState: () => State) => {
    // to make the app responsive, we optimistically update the state
    // with the reordered workflow
    // when the API call finishes, it'll also return the updated workflow
    const state: State = getState();
    const workflow: WorkFlow = getWorkflowFromEntity(state, workflowId);
    const step: Step = getStepInWorkflowById(workflow, stepId);
    const newWorkflow: WorkFlow = reorderStepInWorkflow(workflow, step, params);
    dispatch({ type: 'STEP_ENTITY_REORDER_REQUEST', payload: newWorkflow });

    debug('step::reorderStep >', { step, params });

    const response = await api.post(
      `/projects/${projectId}/workflows/${workflowId}/steps/${step.id}/reorder`,
      params,
    );
    if (response.ok) {
      const workflow: WorkFlow = await response.json();
      debug('success', step);
      dispatch({ type: 'STEP_ENTITY_REORDER_SUCCESS', payload: workflow });
    } else {
      dispatch({
        type: 'STEP_ENTITY_REORDER_FAILURE',
        message: await getErrorMessage(response),
      });
    }
  };

export const updateStep =
  (projectId: string, workflowId: string, step: Step) => async (dispatch: Dispatch<Action>) => {
    debug('updateStep >', projectId, workflowId, step);
    dispatch({ type: 'STEP_ENTITY_UPDATE_START' });
    const response = await api.patch(
      `/projects/${projectId}/workflows/${workflowId}/steps/${step.id}`,
      step,
    );
    if (response.ok) {
      const step: Step = await response.json();
      debug('success', step);
      dispatch({ type: 'STEP_ENTITY_UPDATE_SUCCESS' });
    } else {
      dispatch({
        type: 'STEP_ENTITY_UPDATE_FAILED',
        message: await getErrorMessage(response),
      });
    }
  };
