import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { DateOperator } from '../shared/types';
import { dateOperators } from '../shared/utils';

import { ticketPriorityInput, ticketStatusInput, ticketTypeInput } from './connect';
import {
  detailsInput,
  emailInput,
  externalIdInput,
  nameInput,
  notesInput,
  phoneInput,
  roleInput,
  userIdInput,
} from './shared/inputs';
import { getAssignees, getEndUsers } from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.ZENDESK,
  provider: ProviderType.ZENDESK,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Choose your Zendesk account',
  icon: iconSvg,
  description: 'Zendesk',
  sidebarSections: [
    {
      inputs: [
        {
          id: 'ZENDESK_SUB_DOMAIN',
          title: 'Enter your Zendesk subdomain',
          subtitle: 'Enter your zendesk subdomain, e.g. https://<subdomain>.zendesk.com.',
          placeholder: 'subdomain',
          type: SidebarInputType.ValueText,
        },
      ],
      buttons: [],
    },
  ],
  hideOAuthApps: true,
};

const ticketIdInput: SidebarInput = {
  id: 'ticketId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Ticket ID',
  placeholder: '35436',
  subtitle: 'The ID of the ticket the comment will be added to.',
};

const recipientInput: SidebarInput = {
  id: 'recipientEmail',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Recipient',
  placeholder: 'name@example.com',
  required: false,
};

const ticketSubjectInput: SidebarInput = {
  id: 'ticketSubject',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Subject',
};

const ticketDescriptionInput: SidebarInput = {
  id: 'ticketDescription',
  type: SidebarInputType.TextArea,
  title: 'Description',
};

const ticketDueAtInput: SidebarInput = {
  id: 'ticketDueAt',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Due At',
  placeholder: '2019-09-15T02:06:58.147Z',
  subtitle: 'If this is a ticket of type "task" it has a due date (ISO 8601 timestamp).',
};

const ticketTagsInput: SidebarInput = {
  id: 'ticketTags',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'Tags',
};

const ticketExternalIdInput: SidebarInput = {
  id: 'ticketExternalId',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  title: 'External ID',
};

const ticketCustomFields: SidebarInput = {
  id: 'ticketCustomFields',
  title: 'Custom fields',
  type: SidebarInputType.Code,
  lines: 3,
  required: false,
  language: 'json',
  useLightTheme: true,
  placeholder: `[
  { "id":    27642, "value": "745" },
  { "id":    27648, "value": "yes" }
]`,
};

const config: ActionConfig = {
  actionType: Action.ZENDESK,
  name: 'Zendesk',
  description: 'Manage tickets in Zendesk Support.',
  providerDataKeyLabelMap: { subDomain: 'Subdomain' },
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Zendesk account',
          placeholder: 'connect to Zendesk',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    title: 'Tickets',
                    items: [
                      { value: Intent.CREATE_TICKET, label: 'Create Ticket' },
                      { value: Intent.UPDATE_TICKET, label: 'Update Ticket' },
                      { value: Intent.ADD_COMMENT_TO_TICKET, label: 'Add Comment to Ticket' },
                      { value: Intent.SEARCH_TICKETS, label: 'Search Tickets' },
                      { value: Intent.GET_TICKET_BY_ID, label: 'Get Ticket by ID' },
                    ],
                  },
                  {
                    title: 'Users',
                    items: [
                      { value: Intent.CREATE_USER, label: 'Create User' },
                      { value: Intent.UPDATE_USER, label: 'Update User' },
                      { value: Intent.SEARCH_USERS, label: 'Search Users' },
                      { value: Intent.GET_USER_BY_ID, label: 'Get User by ID' },
                      { value: Intent.GET_USERS, label: 'Get Users' },
                    ],
                  },
                ],
              },
            ],
          }
        : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      const requesterInput: SidebarInput = {
        id: 'requesterId',
        type: SidebarInputType.DynamicEnum,
        title: 'Requester',
        required: true,
        source: getEndUsers,
        getValues: getValuesByCacheKey,
      };

      const assigneeInput: SidebarInput = {
        id: 'assigneeId',
        type: SidebarInputType.DynamicEnum,
        title: 'Assignee',
        subtitle: 'The agent currently assigned to the ticket',
        required: false,
        source: getAssignees,
        getValues: getValuesByCacheKey,
      };

      switch (parameters.intent) {
        case Intent.GET_USERS:
          return {
            inputs: [
              {
                id: 'getUserQuery',
                type: SidebarInputType.TextArea,
                lines: 1,
                required: false,
                title: 'Query',
                subtitle: 'Filter users by their name or email address.',
              },
            ],
          };

        case Intent.GET_TICKET_BY_ID:
          return {
            inputs: [{ ...ticketIdInput, required: false }],
          };

        case Intent.ADD_COMMENT_TO_TICKET:
          return {
            inputs: [
              { ...ticketIdInput, required: true },
              {
                id: 'commentBody',
                type: SidebarInputType.TextArea,
                title: 'Body',
                lines: 2,
                required: true,
                subtitle: 'The comment message. Accepts plain text or HTML.',
                placeholder: 'Thanks for your help!',
              },
            ],
          };

        case Intent.CREATE_TICKET:
        case Intent.UPDATE_TICKET:
          return {
            inputs: [
              ...(parameters.intent === Intent.UPDATE_TICKET ? [ticketIdInput] : []),
              {
                ...ticketSubjectInput,
                placeholder: 'Help, my printer is on fire!',
                required: parameters.intent === Intent.CREATE_TICKET,
              },
              {
                ...ticketDescriptionInput,
                lines: 2,
                placeholder: 'The smoke is very colorful.',
                subtitle: 'The first comment that appears on the ticket.',
                required: parameters.intent === Intent.CREATE_TICKET,
              },
              {
                ...requesterInput,
                subtitle: 'The user who requested this ticket.',
                required: parameters.intent === Intent.CREATE_TICKET,
              },
              {
                ...recipientInput,
                subtitle: 'The original recipient e-mail address of the ticket.',
              },
              ticketTypeInput,
              ticketPriorityInput,
              ticketStatusInput,
              { ...assigneeInput, subtitle: 'The agent currently assigned to the ticket.' },
              ticketDueAtInput,
              {
                ...ticketTagsInput,
                placeholder: '["enterprise", "other_tag"]',
                subtitle: 'The array of tags applied to this ticket.',
              },
              {
                ...ticketExternalIdInput,
                subtitle: 'An ID you can use to link Zendesk Support tickets to local records',
              },
              ticketCustomFields,
            ].filter((input: SidebarInput | null) => input),
          };

        case Intent.SEARCH_TICKETS:
          return {
            inputs: [
              {
                ...ticketSubjectInput,
                required: false,
                subtitle: 'Filter results by the text in the ticket’s subject.',
              },
              {
                ...ticketDescriptionInput,
                lines: 1,
                required: false,
                subtitle: "Filter results by the text in the ticket's description and comments.",
              },
              {
                id: 'createdDate',
                type: SidebarInputType.EnumTextAreaPairInput,
                title: 'Created Date',
                subtitle: 'Filter tickets by their created date.',
                placeholder: 'YYYY-MM-DD',
                required: false,
                getValues: (): EnumInputValue[] =>
                  dateOperators.map((dateOperators: DateOperator) => ({
                    label: dateOperators.label,
                    value: dateOperators.value,
                  })),
              },
              {
                id: 'updatedDate',
                type: SidebarInputType.EnumTextAreaPairInput,
                title: 'Updated Date',
                subtitle: 'Filter tickets by their updated date.',
                placeholder: 'YYYY-MM-DD',
                required: false,
                getValues: (): EnumInputValue[] =>
                  dateOperators.map((dateOperators: DateOperator) => ({
                    label: dateOperators.label,
                    value: dateOperators.value,
                  })),
              },
              {
                id: 'dueDate',
                type: SidebarInputType.EnumTextAreaPairInput,
                title: 'Due Date',
                subtitle: 'Filter tickets by their due date.',
                placeholder: 'YYYY-MM-DD',
                required: false,
                getValues: (): EnumInputValue[] =>
                  dateOperators.map((dateOperators: DateOperator) => ({
                    label: dateOperators.label,
                    value: dateOperators.value,
                  })),
              },
              ticketStatusInput,
              ticketTypeInput,
              { ...assigneeInput, subtitle: 'Filter by the agent assigned to the ticket.' },
              { ...requesterInput, subtitle: 'Filter by the user who requested this ticket.' },
              {
                ...recipientInput,
                subtitle: 'Filter by the original recipient e-mail address of the ticket.',
              },
              ticketPriorityInput,
              { ...ticketTagsInput, subtitle: 'Filter tickets by their tag.' },
              { ...ticketExternalIdInput, subtitle: 'Filter by tickets by their external ID.' },
            ],
          };
        case Intent.CREATE_USER:
        case Intent.UPDATE_USER:
          return {
            inputs: [
              ...(parameters.intent === Intent.CREATE_USER
                ? []
                : [{ ...userIdInput, subtitle: 'The ID of the user to update.' }]),
              { ...nameInput, required: parameters.intent === Intent.CREATE_USER },
              {
                ...emailInput,
                subtitle:
                  parameters.intent === Intent.CREATE_USER
                    ? ''
                    : 'On update, a secondary email is added.',
              },
              phoneInput,
              roleInput,
              externalIdInput,
              detailsInput,
              notesInput,
            ],
          };
        case Intent.GET_USER_BY_ID:
          return { inputs: [{ ...userIdInput, subtitle: 'The ID of the user to get.' }] };
        case Intent.SEARCH_USERS:
          return {
            inputs: [
              { ...nameInput, subtitle: 'Filter results by the user’s name.' },
              { ...emailInput, subtitle: 'Filter results by the user’s email.' },
              roleInput,
              { ...externalIdInput, subtitle: 'Filter by users by their external ID.' },
            ],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
