import {
  createTestConnectionButton,
  hasImpersonatedUserCredential,
} from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
  TokenType,
  UserSuppliedCredentialInput,
} from '@shared/types/sdk/actions';

import { CredentialKeys, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  buildInvoiceLineItemInputs,
  businessUnitInput,
  createInvoiceInputs,
  intentInput,
  invoiceIdInput,
  invoiceNumberInput,
  searchFilterInput,
  supplierInput,
} from './common';

const cloudUrlInput: SidebarInput = {
  id: CredentialKeys.ORACLE_CLOUD_URL,
  title: 'Server URL',
  subtitle: 'The URL of your Oracle Cloud service.',
  type: SidebarInputType.ValueText,
  placeholder: 'https://servername.fa.us2.oraclecloud.com',
  required: true,
};

const usernameInput: SidebarInput = {
  id: CredentialKeys.ORACLE_USERNAME,
  title: 'Username',
  type: SidebarInputType.ValueText,
  placeholder: 'Oracle Username',
  required: true,
};

const passwordInput: SidebarInput = {
  id: CredentialKeys.ORACLE_PASSWORD,
  title: 'Password',
  type: SidebarInputType.Password,
  placeholder: 'Oracle Password',
  required: true,
};

export const authConfig: ActionConfig = {
  actionType: Action.ORACLE_FINANCIALS_CLOUD,
  scheme: AuthenticationScheme.BASIC,
  provider: ProviderType.ORACLE_FINANCIALS_CLOUD,
  name: 'Connect your Oracle Financials Cloud',
  description: 'Add Oracle financials Cloud to Paragon',
  icon: iconSvg,
  supportsImpersonatedCredentialSource: true,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name this Oracle Financials Cloud account',
          placeholder: 'eg. Production Testing etc.',
          type: SidebarInputType.ValueText,
        },
        cloudUrlInput,
        usernameInput,
        passwordInput,
      ],
      buttons: [
        createTestConnectionButton(Action.ORACLE_FINANCIALS_CLOUD, Object.values(CredentialKeys)),
      ],
    },
  ],
};

const config: ActionConfig = {
  actionType: Action.ORACLE_FINANCIALS_CLOUD,
  name: 'Oracle Financials Cloud',
  description: 'Choose Oracle Financials Cloud account',
  scheme: AuthenticationScheme.BASIC,
  icon: iconSvg,
  provider: ProviderType.ORACLE_FINANCIALS_CLOUD,
  sidebarSections: [
    (parameters: ActionStepParameters): SidebarSection => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Oracle Financials Cloud account',
          placeholder: 'Add new account for Oracle Financials Cloud',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
        ...(hasImpersonatedUserCredential(parameters)
          ? ([
              {
                ...cloudUrlInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.ORACLE_CLOUD_URL],
                providerType: ProviderType.ORACLE_FINANCIALS_CLOUD,
                hideTokenDropDown: true,
              },
              {
                ...usernameInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.ORACLE_USERNAME],
                providerType: ProviderType.ORACLE_FINANCIALS_CLOUD,
                hideTokenDropDown: true,
              },
              {
                ...passwordInput,
                type: SidebarInputType.UserSuppliedCredential,
                supportedTokenTypes: [TokenType.ORACLE_PASSWORD],
                providerType: ProviderType.ORACLE_FINANCIALS_CLOUD,
                hideTokenDropDown: true,
              },
            ] as UserSuppliedCredentialInput[])
          : []),
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials.length ? { inputs: [intentInput] } : { inputs: [] },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEARCH_INVOICES:
          return {
            inputs: [searchFilterInput],
          };
        case Intent.CREATE_INVOICE:
        case Intent.UPDATE_INVOICE:
          return {
            inputs: [
              ...(parameters.intent === Intent.UPDATE_INVOICE
                ? [invoiceIdInput, ...createInvoiceInputs(false)]
                : createInvoiceInputs(true)),
            ],
          };
        case Intent.VALIDATE_INVOICE:
          return {
            inputs: [{ ...invoiceNumberInput, required: true }, businessUnitInput, supplierInput],
          };
        case Intent.BUILD_INVOICE_LINE_ITEM:
          return {
            inputs: buildInvoiceLineItemInputs,
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default config;
