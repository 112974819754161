import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName = 'Odata.FullAccess' | 'AX.FullAccess';

export const scopes: Record<ScopeName, ScopeValue> = {
  'Odata.FullAccess': {
    label: 'Odata.FullAccess',
    name: 'Odata.FullAccess',
    required: true,
    description: 'Access Dynamics AX data',
  },
  'AX.FullAccess': {
    label: 'AX.FullAccess',
    name: 'AX.FullAccess',
    required: true,
    description: 'Access Dynamics AX online as organization users',
  },
};

export const requiredScopes: ScopeName[] = ['Odata.FullAccess', 'AX.FullAccess'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'Dynamics 365 Finance',
  description: 'Sync payments with Dynamics 365 Finance',
  authUrl: 'https://login.microsoftonline.com/common/oauth2/authorize',
  accessTokenUrl: 'https://login.microsoftonline.com/common/oauth2/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/dynamics-finance`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/dynamics-finance',
  endUserSuppliedValues: [
    {
      id: 'DYNAMICS_365_FINANCE_BASE_URI',
      title: 'Dynamics Base URI',
      subtitle: 'Specify the login URL for your Dynamics 365 Finance account.',
      placeholder: 'https://example.operations.dynamics.com',
      type: SidebarInputType.ValueText,
      required: true,
    },
  ],
};
