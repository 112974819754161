import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  ActionIntent,
  EnumSection,
  SidebarInput,
  SidebarInputType,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { pardotSupportedOperator } from '../shared';

import authConfig from './authConfig';
import { getLists } from './sources';

export const listInput: SidebarInput = {
  id: 'listId',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  title: 'List',
  subtitle:
    'The List that the prospect should be added to. Use Connect Portal Workflow Settings to allow users to select a List.',
  placeholder: '{{settings.list}}',
};

export const prospectId: SidebarInput = {
  id: 'prospectId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Prospect ID',
  subtitle: 'ID of prospect to be added to the List.',
  required: true,
};

export const intentInput: SidebarInput = {
  id: 'intent',
  title: 'Choose an action',
  type: SidebarInputType.Intent,
  values: [
    {
      title: 'Prospects',
      items: [
        {
          value: Intent.CREATE_OR_UPDATE_PROSPECT,
          label: 'Create or Update Prospect',
        },
        {
          value: Intent.SEARCH_PROSPECTS,
          label: 'Search Prospects',
        },
        {
          value: Intent.GET_PROSPECT_BY_ID,
          label: 'Get Prospect by ID',
        },
        {
          value: Intent.DELETE_PROSPECT_BY_ID,
          label: 'Delete Prospect by ID',
        },
      ],
    },
    {
      title: 'Lists',
      items: [
        {
          value: Intent.ADD_PROSPECT_TO_LIST,
          label: 'Add Prospect to List',
        },
        {
          value: Intent.REMOVE_PROSPECT_FROM_LIST,
          label: 'Remove Prospect from List',
        },
      ],
    },
  ] as EnumSection<ActionIntent>[],
};

export const emailInput: SidebarInput = {
  id: 'email',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Email',
  subtitle: 'If there already exists a lead with this email, it will be updated.',
  required: true,
};
export const firstNameInput: SidebarInput = {
  id: 'firstName',
  type: SidebarInputType.TextArea,
  lines: 1,

  title: 'First Name',
  required: false,
};
export const lastNameInput: SidebarInput = {
  id: 'lastName',
  type: SidebarInputType.TextArea,
  lines: 1,

  title: 'Last Name',
  required: false,
};
export const companyInput: SidebarInput = {
  id: 'company',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Company',
  required: false,
};
export const jobTitleInput: SidebarInput = {
  id: 'jobTitle',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Job Title',
  subtitle: 'Lead’s job title.',
  required: false,
};
export const phoneInput: SidebarInput = {
  id: 'phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Phone',
  required: false,
};
export const websiteInput: SidebarInput = {
  id: 'website',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Website',
  required: false,
};
export const additionalFieldsInput: SidebarInput = {
  id: 'additionalFields',
  type: SidebarInputType.Code,
  language: 'json',
  useLightTheme: true,
  title: 'Additional Fields',
  required: false,
  lines: 4,
  subtitle:
    'Specify any other fields, that should be updated in JSON below. Use Connect Portal Workflow Settings to allow users to specify a Custom Field.',
  placeholder: `{
"property_number": "17",
"property_dropdown": "choice_b",
"property_radio": "option_1",
"property_string": "value",
"property_date": "1572480000000"
}
`,
};

export const prospectFilterInputStatic: SidebarInput = {
  id: 'prospectFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  placeholder: 'field',
  supportedOperators: pardotSupportedOperator,
  getValues: () => [
    { label: 'assigned', value: 'assigned' },
    { label: 'assigned_to_user', value: 'assigned_to_user' },
    { label: 'created_after', value: 'created_after' },
    { label: 'created_before', value: 'created_before' },
    { label: 'deleted', value: 'deleted' },
    { label: 'grade_equal_to', value: 'grade_equal_to' },
    { label: 'grade_greater_than', value: 'grade_greater_than' },
    { label: 'grade_less_than', value: 'grade_less_than' },
    { label: 'id_greater_than', value: 'id_greater_than' },
    { label: 'id_less_than', value: 'id_less_than' },
    { label: 'is_starred', value: 'is_starred' },
    { label: 'last_activity_before', value: 'last_activity_before' },
    { label: 'last_activity_after', value: 'last_activity_after' },
    { label: 'last_activity_never', value: 'last_activity_never' },
    { label: 'list_id', value: 'list_id' },
    { label: 'new', value: 'new' },
    { label: 'score_equal_to', value: 'score_equal_to' },
    { label: 'score_greater_than', value: 'score_greater_than' },
    { label: 'score_less_than', value: 'score_less_than' },
    { label: 'updated_after', value: 'updated_after' },
    { label: 'updated_before', value: 'updated_before' },
  ],
};

export const listDynamicInput: SidebarInput = {
  id: 'listId',
  type: SidebarInputType.NativeDynamicEnumInput,
  title: 'List',
  required: true,
  subtitle:
    'The List that the prospect should be added to. Use Connect Portal Workflow Settings to allow users to select a List.',
  placeholder: '{{settings.list}}',
  source: getLists,
  getValues: getValuesByCacheKey,
};

export const timezoneInput: SidebarInput = {
  id: 'timezone',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  title: 'Timezone',
  subtitle:
    'Timezone of the user’s Pardot account. Use Connect Portal Workflow Settings to allow users to select a List.',
  placeholder: '{{setting.timezone}}',
};

export const authInput: SidebarInput = {
  id: 'auth',
  title: 'Choose your Pardot account',
  placeholder: 'connect to Pardot',
  type: SidebarInputType.Auth,
  config: authConfig,
};
