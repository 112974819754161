import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { ActionStepParameters, Intent } from '@shared/integrations/saps4hana/configs';
import { Action, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig } from '../configs';
import iconSvg from '../configs/icon.svg';
import { RecordType } from '../shared/types';

import authConfig from './authConfig';
import {
  VATRegistrationInput,
  alternativePayeeAccountNumberInput,
  authorizationGroupInput,
  companyCodeInput,
  customerAccountGroupInput,
  customerAdditionalFieldsInput,
  customerClassificationInput,
  customerCorporateGroupInput,
  customerFilterInput,
  customerNumberInput,
  documentCurrencyInput,
  documentDateInput,
  fiscalAddressInput,
  fiscalYearInput,
  intentSelectorDropdown,
  invoiceGrossAmountInput,
  postingDateInput,
  recordTypeInput,
  supplierAccountNumber,
  supplierAdditionalFieldsInput,
  supplierFilterInput,
  supplierInvoiceAdditionalFieldsInput,
  supplierInvoiceFilterInput,
  supplierInvoiceInput,
} from './input';

const connectConfig: ActionConfig = {
  actionType: Action.SAP_S4HANA,
  name: 'SAP S/4HANA',
  description: `Connect to your users' SAP S/4HANA systems`,
  icon: iconSvg,
  provider: ProviderType.SAP_S4HANA,
  sidebarSections: [
    intentSelectorDropdown,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEARCH_CUSTOMERS:
          return {
            inputs: [customerFilterInput, recordTypeInput(RecordType.CUSTOMER)],
          };

        case Intent.SEARCH_SUPPLIERS:
          return {
            inputs: [supplierFilterInput, recordTypeInput(RecordType.SUPPLIER)],
          };

        case Intent.SEARCH_SUPPLIER_INVOICES:
          return {
            inputs: [supplierInvoiceFilterInput, recordTypeInput(RecordType.SUPPLIER_INVOICE)],
          };

        case Intent.CREATE_SUPPLIER_INVOICE:
          return {
            inputs: [
              companyCodeInput,
              documentDateInput,
              postingDateInput,
              documentCurrencyInput,
              invoiceGrossAmountInput,
              supplierInvoiceAdditionalFieldsInput,
              recordTypeInput(RecordType.SUPPLIER_INVOICE),
            ],
          };

        case Intent.GET_SUPPLIER_INVOICE_BY_ID:
        case Intent.DELETE_SUPPLIER_INVOICE:
          return {
            inputs: [
              supplierInvoiceInput(
                `The ID of the Supplier Invoice to ${
                  parameters.intent === Intent.GET_SUPPLIER_INVOICE_BY_ID ? 'fetch' : 'delete'
                }.`,
              ),
              fiscalYearInput(
                `Specify the Fiscal Year to ${
                  parameters.intent === Intent.GET_SUPPLIER_INVOICE_BY_ID ? 'fetch' : 'delete'
                }.`,
              ),
              recordTypeInput(RecordType.SUPPLIER_INVOICE),
            ],
          };

        case Intent.UPDATE_CUSTOMER:
          return {
            inputs: [
              customerNumberInput('The ID of the Customer to update.', true),
              authorizationGroupInput,
              customerClassificationInput,
              supplierAccountNumber('The ID of the Supplier to update.', false),
              customerCorporateGroupInput,
              customerAccountGroupInput,
              customerAdditionalFieldsInput,
              recordTypeInput(RecordType.CUSTOMER),
            ],
          };

        case Intent.GET_CUSTOMER_BY_ID:
          return {
            inputs: [
              customerNumberInput('The ID of the Customer to fetch.', true),
              recordTypeInput(RecordType.CUSTOMER),
            ],
          };

        case Intent.UPDATE_SUPPLIER:
          return {
            inputs: [
              supplierAccountNumber('The ID of the Supplier to update.', true),
              alternativePayeeAccountNumberInput,
              authorizationGroupInput,
              customerNumberInput('The ID of the Customer to update.', false),
              VATRegistrationInput,
              fiscalAddressInput,
              supplierAdditionalFieldsInput,
              recordTypeInput(RecordType.SUPPLIER),
            ],
          };

        case Intent.GET_SUPPLIER_BY_ID:
          return {
            inputs: [
              supplierAccountNumber('The ID of the Supplier to fetch.', true),
              recordTypeInput(RecordType.SUPPLIER),
            ],
          };

        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};
export { authConfig };

export default connectConfig;
