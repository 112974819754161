import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { GOOGLE_SHEET_OPERATORS } from '../shared/types';

const worksheetInput: SidebarInput = {
  id: 'worksheet',
  title: 'Worksheet',
  type: SidebarInputType.TextArea,
  subtitle: 'Your worksheet must have column headers.',
  placeholder: '{{settings.worksheet}}',
  required: true,
  lines: 1,
};

const spreadsheetInput: SidebarInput = {
  title: 'Spreadsheet',
  id: 'spreadsheetId',
  type: SidebarInputType.TextArea,
  lines: 1,
  placeholder: '{{settings.spreadsheet}}',
  subtitle:
    'Use Connect Portal Workflow Settings to allow users to select a spreadsheet. Defaults to using the first worksheet in the selected spreadsheet.',
  required: true,
};

const additionFieldsInput: SidebarInput = {
  id: 'additionalFields',
  title: 'Fields',
  required: true,
  subtitle:
    'JSON representation of row as columnName and columValue pairs. Use Connect workflow settings to fill in column names',
  type: SidebarInputType.TextArea,
  lines: 4,
  placeholder: `{
    columnName1: "columnValue1",
    columnName2: "columnValue2",
    columnName3: "columnValue3",
    columnName4: "columnValue4",
  }`,
};

const filterInput: SidebarInput = {
  id: 'filterFormula',
  type: SidebarInputType.Conditional,
  title: 'Filter records',
  subtitle:
    'Specify which records should get updated using the filters below. Use Connect Portal Workflow Settings to allow users to select a column.',
  placeholder: '{{settings.column}}',
  supportedOperators: GOOGLE_SHEET_OPERATORS,
  required: false,
};

const connectConfig: ActionConfig = {
  actionType: Action.GOOGLE_SHEETS,
  name: 'Google Sheets',
  description:
    'Connect to your users’ Google account to access their spreadsheets in Google Sheets.',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose an action',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.GET_ROW,
              label: 'Get rows from spreadsheet',
            },
            {
              value: Intent.CREATE_ROW,
              label: 'Create row in spreadsheet',
            },
            {
              value: Intent.UPDATE_ROW,
              label: 'Update row in spreadsheet',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      switch (parameters.intent as Intent) {
        case Intent.GET_ROW:
          return {
            inputs: [
              spreadsheetInput,
              {
                id: 'limit',
                title: 'Limit rows',
                required: false,
                subtitle: 'Limit the maximum number of rows to return.',
                type: SidebarInputType.TextArea,
                lines: 1,
              },
            ],
          };

        case Intent.CREATE_ROW:
          return {
            inputs: [
              spreadsheetInput,
              worksheetInput,
              {
                ...additionFieldsInput,
                subtitle:
                  'Include fields to create this row with, as an object with keys of Column Names. Use Connect Portal Workflow Settings to allow users to select a Column Mapping',
              },
            ],
          };
        case Intent.UPDATE_ROW:
          return {
            inputs: [
              spreadsheetInput,
              worksheetInput,
              filterInput,
              {
                ...additionFieldsInput,
                subtitle:
                  'Include fields to update, as an object with keys of Column Names. Use Connect Portal Workflow Settings to allow users to select a Column Mapping.',
              },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
