import { pickValueSourceByKey } from '@shared/actions/sdk/utils';
import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { DataType } from '@shared/types/sdk/resolvers';

import { ActionConfig, ActionStepParameters, Intent } from '../configs/config';
import iconSvg from '../configs/icon.svg';
import {
  RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME,
  ZohoCrmStandardRecord,
  supportedOperators,
} from '../shared/constants';

import authConfig from './authConfig';
import {
  coqlQueryInput,
  defaultZohoCrmFields,
  intentSelectorDropdown,
  objectNameInput,
  recordIdInput,
  recordTypeInput,
} from './inputs';

const connectConfig: ActionConfig = {
  actionType: Action.ZOHO_CRM,
  name: 'Zoho CRM',
  description: "Connect to your users' Zoho CRM accounts",
  icon: iconSvg,
  provider: ProviderType.ZOHO_CRM,
  sidebarSections: [
    intentSelectorDropdown,
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedRecordType:
        | ZohoCrmStandardRecord
        | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'recordType',
      )?.value;

      return {
        inputs:
          parameters.intent === Intent.WRITE_COQL_QUERY
            ? []
            : [
                recordTypeInput,
                ...(selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
                  ? [objectNameInput(parameters.intent as Intent)]
                  : []),
              ],
      };
    },
    (parameters: ActionStepParameters): SidebarSection => {
      const selectedRecordType:
        | ZohoCrmStandardRecord
        | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
        | undefined = pickValueSourceByKey<DataType.ANY>(
        parameters.actionParameters,
        'recordType',
      )?.value;

      let fieldInputs: SidebarInput[] = [];
      if (selectedRecordType && selectedRecordType in defaultZohoCrmFields) {
        fieldInputs = [
          ...defaultZohoCrmFields[selectedRecordType]?.(parameters.intent === Intent.CREATE_RECORD),
        ];
      }

      switch (parameters.intent) {
        case Intent.CREATE_RECORD:
        case Intent.UPDATE_RECORD:
          return {
            inputs: fieldInputs,
          };

        case Intent.GET_RECORD_BY_ID:
          return {
            inputs: [recordIdInput('fetch')],
          };

        case Intent.SEARCH_RECORDS:
          const searchInputs = fieldInputs.filter(
            (input: SidebarInput) =>
              !['AdditionalFields', 'recordId'].some((value: string) => input.id.includes(value)),
          );
          return {
            inputs: [
              {
                type: SidebarInputType.Conditional,
                id: 'filterFormula',
                title: 'Filter search',
                subtitle: 'Search for records that match specified filters.',
                getValues:
                  selectedRecordType === RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME
                    ? undefined
                    : () =>
                        searchInputs.map((input: SidebarInput) => ({
                          label: input.title,
                          value: input.id,
                        })),
                supportedOperators,
                required: false,
              },
            ],
          };

        case Intent.DELETE_RECORD:
          return {
            inputs: [recordIdInput('delete')],
          };

        case Intent.WRITE_COQL_QUERY:
          return {
            inputs: [coqlQueryInput],
          };

        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export { authConfig };
export default connectConfig;
