import React from 'react';

import { SidebarInput, SidebarInputType } from '@shared/types/sdk/actions';

import { CredentialKeys } from '../configs';

export const companyKeyInput: SidebarInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.COMPANY_KEY,
  title: 'Company Key',
  subtitle: (
    <>
      You can obtain your company specific unique Sailthru API key and secret on the
      <a href="https://my.sailthru.com/settings/api_postbacks"> API & Postbacks </a>Settings page in
      My Sailthru. Click the lock icon to display these credentials.
    </>
  ),
  required: true,
};

export const companySecretInput: SidebarInput = {
  type: SidebarInputType.ValueText,
  id: CredentialKeys.COMPANY_SECRET,
  title: 'Company Secret',
  required: true,
};
