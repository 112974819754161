import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionStepParameters,
  SidebarInput,
  SidebarSection,
} from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  additionalCampaignElementInput,
  additionalContactFieldsInput,
  campaignEmailInput,
  campaignFilterInput,
  campaignIdInput,
  campaignNameInput,
  campaignStatusInput,
  commonIntentInput,
  companyInput,
  contactFilterInput,
  contactIdInput,
  emailContactsInput,
  emailFilterInput,
  emailIdInput,
  emailNameInput,
  firstNameInput,
  htmlContentInput,
  lastNameInput,
  previewTextInput,
  replyToEmailInput,
  scheduledDateInput,
  segmentInput,
  senderEmailInput,
  senderNameInput,
  subjectLineInput,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.ELOQUA,
  name: 'Oracle Eloqua',
  description: "Connect to your users' Eloqua accounts",
  icon: iconSvg,
  provider: ProviderType.ELOQUA,
  sidebarSections: [
    (_parameters: ActionStepParameters): SidebarSection => commonIntentInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.ACTIVATE_CAMPAIGN:
          return {
            inputs: [
              { ...campaignIdInput, subtitle: 'ID of the campaign to activate.' },
              scheduledDateInput,
            ],
          };
        case Intent.CREATE_CAMPAIGN:
          return {
            inputs: [
              campaignNameInput,
              segmentInput,
              campaignStatusInput,
              campaignEmailInput,
              additionalCampaignElementInput,
            ],
          };
        case Intent.CREATE_CONTACT:
          return {
            inputs: [
              { ...campaignEmailInput, subtitle: '', required: true },
              firstNameInput,
              lastNameInput,
              companyInput,
              additionalContactFieldsInput,
            ],
          };
        case Intent.CREATE_EMAIL:
          return {
            inputs: [
              emailNameInput,
              senderNameInput,
              senderEmailInput,
              replyToEmailInput,
              subjectLineInput,
              previewTextInput,
              htmlContentInput,
            ],
          };
        case Intent.GET_CAMPAIGN_BY_ID:
          return {
            inputs: [{ ...campaignIdInput, subtitle: 'ID of the campaign to get.' }],
          };
        case Intent.SEARCH_CAMPAIGNS:
          return { inputs: [campaignFilterInput] };
        case Intent.SEARCH_CONTACTS:
          return { inputs: [contactFilterInput] };
        case Intent.SEARCH_EMAIL:
          return { inputs: [emailFilterInput] };
        case Intent.SEND_EMAIL_DEPLOYMENT:
          return {
            inputs: [
              { ...emailNameInput, subtitle: 'The name of this email deployment.' },
              emailContactsInput,
              {
                ...campaignEmailInput,
                required: true,
                subtitle:
                  'The Email to send. Use Connect Portal User Settings to allow your users to select an Email.',
              },
            ],
          };
        case Intent.UPDATE_CAMPAIGN:
          return {
            inputs: [
              campaignIdInput,
              ...[
                campaignNameInput,
                segmentInput,
                campaignStatusInput,
                campaignEmailInput,
                additionalCampaignElementInput,
              ].map((item: SidebarInput) => ({ ...item, required: false })),
            ],
          };
        case Intent.UPDATE_CONTACT:
          return {
            inputs: [
              contactIdInput,
              ...[
                { ...campaignEmailInput, subtitle: '', required: true },
                firstNameInput,
                lastNameInput,
                companyInput,
                additionalContactFieldsInput,
              ].map((item: SidebarInput) => ({ ...item, required: false })),
            ],
          };
        case Intent.UPDATE_EMAIL:
          return {
            inputs: [
              emailIdInput,
              ...[
                emailNameInput,
                senderNameInput,
                senderEmailInput,
                replyToEmailInput,
                subjectLineInput,
                previewTextInput,
                htmlContentInput,
              ].map((item: SidebarInput) => ({ ...item, required: false })),
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default connectConfig;
