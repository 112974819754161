import { createHash, createHmac } from 'crypto';

import axios, { AxiosError } from 'axios';
import JSON5 from 'json5';

import {
  AuthenticationScheme,
  ConnectionStatus,
  CredentialValues,
  ICredential,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionButtonInput,
  ActionButtonStyle,
  ActionIntent,
  ActionLabels,
  ActionResponseStatus,
  ActionResponseType,
  ActionStepParameters,
  AlertActionResponse,
  DataSourceType,
  DynamicInputRefreshStatus,
  EnumInputValue,
  MOCK_IMPERSONATED_USER_CREDENTIAL_ID,
  OAuthActionResponse,
  SidebarInput,
  SidebarInputType,
  TEST_CONNECTION_INTENT_MAP,
  createOAuthActionResponse,
} from '@shared/types/sdk/actions/';
import { ConnectInputValue } from '@shared/types/sdk/connect';
import { DataType, KeyedSource, ValueSource } from '@shared/types/sdk/resolvers';

const format = require('xml-formatter');
const xml2js = require('xml2js');

export type ActionResponse<T> = {
  result: {
    output: T;
  };
  error: any;
  success: boolean;
};

type QueryOptions = {
  urlencodedValues?: boolean;
  addPrefix?: boolean;
};

type TestConnectionResponse = {
  status: ConnectionStatus;
  message: string;
};

export type RecordType = {
  [key: string]: any;
};

export type PaginateQuery = {
  pageSize?: number;
  offset?: number | string;
  nextPageUrl?: string;
  dataKey?: string;
  pageNo?: number;
};

export type PaginateResponse = {
  data: { [x: string]: any }[];
  offset?: number | string;
  nextPageUrl?: string;
  hasMore?: boolean;
  pageNo?: number;
};

export type ActionPaginationParameters<T extends ConnectInputValue = ConnectInputValue> = Partial<{
  /**
   * Offset number for pagination or `pageCursor` string provided by `Action`
   */
  pageCursor: string | number | false;
  search: string;
  selectedItem: T;
  noPaginate?: true;
}>;

export type PaginatedActionDTO<T extends ConnectInputValue, P extends Object = {}> = P & {
  paginationParameters?: ActionPaginationParameters<T>;
};

export type PaginatedActionResponse<T extends unknown = unknown> = {
  records: T;
  nextPageCursor: string | number | false;
};

export const DEFAULT_PAGINATION_SIZE: number = 30;

export const calculatePaginationNextCursor = (
  total: number,
  currentCursor: number,
): number | false => {
  if (currentCursor + DEFAULT_PAGINATION_SIZE >= total) {
    return false;
  }
  const currentOffset: number = currentCursor || DEFAULT_PAGINATION_SIZE;
  const reamingItems: number = total - currentOffset;
  return reamingItems > DEFAULT_PAGINATION_SIZE
    ? currentCursor + DEFAULT_PAGINATION_SIZE
    : currentCursor + reamingItems;
};

export const createTestConnectionButton = (
  actionType: Action,
  credentialKeys: string[],
): ActionButtonInput => {
  return {
    id: 'TEST_CONNECTION',

    title: 'Test Connection',
    type: SidebarInputType.ActionButton,
    // TODO: determine style based off of `connection-status` type
    style: ActionButtonStyle.DEFAULT,
    disabled: (refreshStatus: DynamicInputRefreshStatus) =>
      refreshStatus === DynamicInputRefreshStatus.REFRESHING,
    source: {
      type: DataSourceType.DYNAMIC,
      title: 'Test Connection',
      cacheKey: 'connection-status',
      mapRefreshToValues: (response: any): TestConnectionResponse => {
        const message: string = response?.result?.output?.error || undefined;
        const success: boolean | undefined =
          typeof response?.result?.output?.success === 'boolean'
            ? response.result.output.success
            : undefined;
        switch (success) {
          case true:
            return {
              status: ConnectionStatus.VALID,
              message: 'This connection is valid!',
            };
          case false:
            return {
              status: ConnectionStatus.INVALID,
              message: message || 'The connection information you entered is invalid.',
            };
          default:
            return {
              status: ConnectionStatus.UNKNOWN,
              message: message || 'Something went wrong trying to validate the connection.',
            };
        }
      },
      getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
        if (!TEST_CONNECTION_INTENT_MAP[actionType]) {
          throw new Error('Intent not supported for action: ' + actionType);
        }

        // @ts-ignore TODO: Fix this return type
        return {
          actionType,
          intent: TEST_CONNECTION_INTENT_MAP[actionType] as ActionIntent,
          credentials: [],
          actionParameters: options.actionParameters.filter((value: KeyedSource<DataType.ANY>) =>
            credentialKeys.includes(value.key),
          ),
        };
      },
      onRefresh: (result: TestConnectionResponse): AlertActionResponse => {
        const { status, message } = result;
        switch (status) {
          case ConnectionStatus.VALID:
            return {
              type: ActionResponseType.ALERT,
              status: ActionResponseStatus.SUCCESS,
              message: 'This connection is valid!',
            };
          case ConnectionStatus.INVALID:
            return {
              type: ActionResponseType.ALERT,
              status: ActionResponseStatus.ERROR,
              message: message || 'The connection information you entered is invalid.',
            };
          case ConnectionStatus.UNKNOWN:
            return {
              type: ActionResponseType.ALERT,
              status: ActionResponseStatus.ERROR,
              message: message || 'Something went wrong trying to validate the connection.',
            };
        }
      },
    },
  };
};

export const pickValueSourceByKey = <T extends DataType>(
  sources: KeyedSource<T>[],
  key: string,
): ValueSource<T> | undefined => {
  const cache: KeyedSource<T> | undefined = sources.find(
    (value: KeyedSource<T>) => value.key === key,
  );
  if (cache?.source.type === 'VALUE') {
    return cache.source;
  }
  return undefined;
};

export const getValuesByCacheKey = (
  options: ActionStepParameters,
  _inputs: SidebarInput[],
  activeInput: SidebarInput,
): EnumInputValue[] => {
  if (!('source' in activeInput) || !('cacheKey' in activeInput.source)) {
    return [];
  }
  const source: ValueSource<DataType.ANY> | undefined = pickValueSourceByKey<DataType.ANY>(
    options.actionParameters,
    activeInput.source.cacheKey as string,
  );
  return source?.value || [];
};

export const describeRecordDataTypes = (record: RecordType = {}): { [key: string]: DataType } => {
  const schema: ReturnType<typeof describeRecordDataTypes> = {};
  Object.keys(record).forEach((key: string) => {
    switch (typeof record[key]) {
      case 'string':
        schema[key] = DataType.STRING;
        break;
      case 'number':
        schema[key] = DataType.NUMBER;
        break;
      case 'boolean':
        schema[key] = DataType.BOOLEAN;
        break;
      case 'object':
        if (Array.isArray(record[key])) {
          schema[key] = DataType.ARRAY;
          break;
        }
        if (record[key] instanceof Date) {
          schema[key] = DataType.DATE;
          break;
        }
        schema[key] = DataType.OBJECT;
        break;
      default:
        schema[key] = DataType.ANY;
    }
  });
  return schema;
};

export const convertRecordDataTypes = (
  record: RecordType,
  schema: { [key: string]: DataType },
): RecordType => {
  const newRecord = { ...record };
  Object.keys(schema).forEach((key: string) => {
    if (record[key]) {
      newRecord[key] = DATA_TYPE_CONVERTERS[schema[key]](newRecord[key]);
    }
  });
  return newRecord;
};

export const FIELDS_PREFIX = 'fields.';

export const buildFieldsFromNestedKeys = (keys: string[], fullObject: RecordType): RecordType => {
  const finalObject: ReturnType<typeof buildFieldsFromNestedKeys> = {};
  keys.forEach((fieldKey: string) => {
    if (fullObject[`${FIELDS_PREFIX}${fieldKey}`]) {
      finalObject[fieldKey] = fullObject[`${FIELDS_PREFIX}${fieldKey}`];
    }
  });
  return finalObject;
};

export const keyNameToFriendlyName = (name: string): string => {
  return name
    .split(/(?=[A-Z])/)
    .filter((word: string) => word.length > 0)
    .map((word: string) => `${word[0].toUpperCase()}${word.substring(1)}`)
    .map((word: string) => (word === 'Id' ? 'ID' : word))
    .join(' ');
};

// TODO: Dedupe from '@shared/workflow/sdk/resolvers'
export function convertStringDataType(value: any): string | undefined {
  return typeof value === 'string' || typeof value === 'number' ? value.toString() : undefined;
}

export function convertNumberDataType(value: any): number | undefined {
  return !isNaN(value) ? Number(value) : undefined;
}

function tryAndGetDateFromTimeStamp(value: any): Date | undefined {
  const timestamp: number = parseInt(value);
  if (isNaN(timestamp)) {
    return undefined;
  }
  return new Date(timestamp);
}

export function convertDateDataType(value: any): Date | undefined {
  if (typeof value !== 'string') {
    return undefined;
  }

  const date = Date.parse(value);
  return !isNaN(date) ? new Date(date) : tryAndGetDateFromTimeStamp(value);
}

export function convertBooleanDataType(value: any): boolean | undefined {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'string') {
    if (value.toLowerCase() === 'true') {
      return true;
    }

    if (value.toLowerCase() === 'false') {
      return false;
    }
  }

  return undefined;
}

export function convertObjectDataType(value: any): object | undefined {
  return typeof value === 'object' ? value : undefined;
}

export function convertArrayDataType(value: any): any[] | undefined {
  return Array.isArray(value) ? value : undefined;
}

export function convertAnyDataType(value: any): any | undefined {
  return value;
}

export function convertNonDecimalDataType(value: any): number | undefined {
  if (typeof value === 'number' && Number.isInteger(value)) {
    return value;
  } else if (
    typeof value === 'string' &&
    !isNaN(Number(value)) &&
    Number.isInteger(Number(value))
  ) {
    return Number(value);
  }

  return undefined;
}

export const DATA_TYPE_CONVERTERS: Record<DataType, (value: any) => any | undefined> = {
  [DataType.STRING]: convertStringDataType,
  [DataType.NUMBER]: convertNumberDataType,
  [DataType.DATE]: convertDateDataType,
  [DataType.BOOLEAN]: convertBooleanDataType,
  [DataType.EMAIL]: convertStringDataType,
  [DataType.OBJECT]: convertObjectDataType,
  [DataType.ARRAY]: convertArrayDataType,
  [DataType.ANY]: convertAnyDataType,
  [DataType.FILE]: convertObjectDataType,
  [DataType.NON_DECIMAL]: convertNonDecimalDataType,
};

export function hasOAuthAppCredential(
  parameters: ActionStepParameters,
  credentials: Record<string, ICredential>,
): boolean {
  const [firstCredentialId] = parameters.credentials;
  return (
    firstCredentialId !== undefined &&
    credentials[firstCredentialId]?.scheme === AuthenticationScheme.OAUTH_APP
  );
}

/**
 * @summary converts the given input list usually from UI into javscript array of strings
 * @param listOfVals a string containing some values separated by comma or in the form of a stringified array
 * @returns array of strings containing the values
 */
export function getArrayOfStringsFromCommaSeparatedList(listOfVals?: string): string[] {
  if (!listOfVals) {
    return [];
  }
  // parse into js array if the input is a js array as a string
  if (listOfVals.indexOf('[') === 0) {
    try {
      const result = JSON5.parse(listOfVals);
      if (!Array.isArray(result)) {
        throw new Error('');
      }
      return result;
    } catch (error) {
      throw new Error(`Invalid JSON. Unable to parse ${listOfVals}`);
    }
  }

  // if string is a comma seperated list . Also works if each value is enclosed with double quotes
  return listOfVals
    .split(',')
    .map((individualVal: string) => individualVal.replace(/"/g, '').trim());
}

/**
 * check if credential id is MOCK_IMPERSONATED_USER_CREDENTIAL_ID or not
 * @param parameters
 */
export function hasImpersonatedUserCredential(parameters: ActionStepParameters): boolean {
  return (
    !!parameters.credentials?.length &&
    parameters.credentials[0] === MOCK_IMPERSONATED_USER_CREDENTIAL_ID
  );
}

export const CONNECT_INTEGRATION_PROVIDER_BASE_URL: string =
  'https://docs.useparagon.com/v/connect/resources/integrations';

/**
 * @summary parses code input data. if field mapping is used , then the object keys dont have
 * quotes around them so using json5 to parse.
 * @param inputContent string of input or object value from previous step
 * @returns Fields as an object
 */
export function parseCodeInput(
  inputContent?: string | { [key: string]: any },
  processArray = false,
): { [key: string]: any } | Record<string, any>[] | undefined {
  if (!inputContent) {
    return undefined;
  } else if (typeof inputContent === 'object') {
    // skipping parsing in case the input is object
    return inputContent;
  } else {
    let inputFields: unknown;
    try {
      // this change is done as per customer requirement. For more info check below ticket
      // https://team-1579225222999.atlassian.net/browse/PARA-5643
      inputFields = JSON5.parse(inputContent.replace("\\'", "'"));
      if (
        typeof inputFields !== 'object' ||
        (!processArray && Array.isArray(inputFields)) ||
        inputFields === null
      ) {
        throw new Error('input is not an object');
      }
    } catch (err) {
      throw new Error(`input must be specified as an object in JSON (${err.message})`);
    }
    return inputFields;
  }
}

/**
 * @summary arranges query parameters into query string.
 * Taking into consideration that some of the keys may be undefined.
 * Also takes of appending ? at the begining
 * @param array of queryParameters as { key: string; value: string | number | undefined }[]
 */
type QueryParameter = { key: string; value: string | number | undefined };
export const generateQueryString = (
  queryParameters: QueryParameter[],
  options: QueryOptions = { urlencodedValues: false, addPrefix: true },
): string => {
  const resultingString: string = queryParameters
    .filter((qp: QueryParameter) => qp.value || typeof qp.value === 'number')
    .map(
      (qp: QueryParameter) =>
        `${qp.key}=${
          options.urlencodedValues && qp.value ? encodeURIComponent(qp.value) : qp.value
        }`,
    )
    .join('&');

  return resultingString.length > 0 ? (options.addPrefix ? '?' : '') + resultingString : '';
};

/**
 * @summary returns base64encoded credentials for Authorization header
 * @param clientId
 * @param clientSecret
 */
export function getBasicAuthString(clientId: string, clientSecret: string): string {
  return `Basic ${Buffer.from(`${clientId}:${clientSecret}`).toString('base64')}`;
}

/**
 * strip or filter undefined / empty properties from a object.
 * @param properties object that contains fields with undefined values
 * @returns strip or filter undefined / empty properties from a object.
 */
export const filterEmptyProperties = (object: RecordType = {}): RecordType => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]: [string, unknown]) =>
      typeof value === 'number' || typeof value === 'boolean' ? true : Boolean(value),
    ),
  );
};

/**
 *
 * @summary return basic axios instance
 * @param baseUrl - domain url
 * @param headers - headers to pass
 */
export const getAxiosInstance = (baseURL: string, headers: Record<string, string>) => {
  return axios.create({
    baseURL,
    headers,
  });
};

/* @summary typeGuard for axios error
 * @param
 * error
 */
export const isAxiosError = (error: Error | AxiosError): boolean =>
  (error as AxiosError)?.response !== undefined;

/**
 * @summary helper function to log errors
 * @param err
 * @param action {Action}
 * @param message
 * @returns formatted error
 */
export const handleResponseError = (
  err: Error | AxiosError | any,
  action?: Action,
  message?: string,
): Error => {
  return new Error(
    `${action ? `${ActionLabels[action]} API Error: ` : ''}${
      isAxiosError(err) && err.response.data
        ? JSON.stringify(err.response.data)
        : err?.message || JSON.stringify(err) || ''
    } ${message || ''}`.trim(),
  );
};

/**
 * convert hex encoded string into readable string
 * @param value passing value
 * @returns readable string
 */
export const parseBufferString = (value: string): string => Buffer.from(value, 'hex').toString();

/**
 * Check value is alpha-numeric or not
 * @param value value that wants to check
 * @returns boolean value
 */
export const isAlphaNumeric = (value: string): boolean => !/[^a-z0-9]/gi.test(value);

/*
 * with this hof curry function no need to add refresh logic in each intent
 * @param refreshCredential
 * @returns
 */
export const withRefreshCredentialHandling =
  <C, E, W>(
    refreshCredential: (
      credential: C,
      environment: E,
      workflowContext?: W,
    ) => Promise<CredentialValues | undefined>,
  ) =>
  <
    I extends (
      params: Record<string, unknown>,
      credentials: C,
      environment: E,
      workflowContext?: W,
    ) => Promise<any>,
  >(
    intentHandler: I,
  ) => {
    return async (
      params: Record<string, unknown>,
      credentials: C,
      environment: E,
      workflowContext?: W,
    ): Promise<OAuthActionResponse<ReturnType<I> | null>> => {
      const updatedCredential = await refreshCredential(credentials, environment, workflowContext);

      const newCredentials = { ...credentials, ...(updatedCredential ? updatedCredential : {}) };

      let result: ReturnType<I> | null = null;
      let error: string | undefined;
      try {
        result = await intentHandler(params, newCredentials, environment, workflowContext);
      } catch (err) {
        error = err.message;
      }

      return createOAuthActionResponse(result, updatedCredential, error);
    };
  };

/**
 * This function accept a request logic and action name, and return a new function that contain your logic wrapped in a try catch block
 * this is specifically designed for action backend file to reduce test volume.
 * @param func
 * @param action
 * @returns
 */
export const tryCatchWrapper = <P, C, E>(
  func: (requestDTO: P, credentials: C, environment?: E) => Promise<any>,
  action: Action,
) => {
  return async function (
    requestDTO: P,
    credentials: C,
    environment?: E,
  ): Promise<Record<string, any>> {
    try {
      return await func.apply(this, [requestDTO, credentials, environment]);
    } catch (e) {
      throw handleResponseError(e, action);
    }
  };
};

/*
 * Generate querystring from a object
 * stringify() your object before sending in
 * @param arg
 * @returns
 */
export const generateQueryStringFromObject = (
  arg: Record<string, unknown>,
  options?: QueryOptions,
): string => {
  const keyValPair: QueryParameter[] = Object.entries(arg).map((item: [string, any]) => ({
    key: item[0],
    value: item[1],
  }));

  return generateQueryString(keyValPair, options);
};

// return parsed querystring, param:- 'list=1&status=valid'
export const parseQueryString = (queryParam: string): Record<string, any> => {
  if (queryParam) {
    const entries = queryParam?.split('&');
    return entries.reduce((accumulator, curr) => {
      const [key, value] = curr?.split('=');
      if (accumulator[key]) {
        accumulator[key] = accumulator[key] ? [...accumulator[key], value] : [value];
      } else {
        accumulator[key] = value;
      }
      return accumulator;
    }, {});
  } else {
    return {};
  }
};

/**
 * TODO make it more generic that other integrations can use it
 */

/**
 * Utility function to fetch data with pagesize and offset
 * @param doRequest API request function from which we need to fetch data
 * @param params API query params
 * @param dataKey Response key in which data will be received
 * @param maxLimit Max record limit for per API request, default 100
 * @returns
 */
export const paginateRequest = async <T = Record<string, any>[]>(
  doRequest: (params: PaginateQuery) => Promise<PaginateResponse>,
  params: PaginateQuery,
  maxLimit: number = 100,
): Promise<T> => {
  const recordsToBeFetched: number = params.pageSize ?? maxLimit;
  const queryParams: PaginateQuery = {
    ...params,
    pageSize: recordsToBeFetched,
    offset: params.offset ?? 0,
  };
  let response = await doRequest(queryParams);
  let responseData = response.data;
  let hasMore: boolean =
    response.hasMore !== undefined
      ? response.hasMore
      : responseData.length === recordsToBeFetched && maxLimit === recordsToBeFetched;

  while (hasMore) {
    const updatedQueryParams = {
      ...queryParams,
      pageNo: response.pageNo,
      offset: response.offset || responseData.length,
      nextPageUrl: response.nextPageUrl,
    } as PaginateQuery;
    response = await doRequest(updatedQueryParams);
    responseData = responseData.concat(response.data);
    hasMore =
      response.hasMore !== undefined
        ? response.hasMore
        : response.data.length === recordsToBeFetched;
  }

  return responseData as any;
};

/**
 * Add correct protocol and domain in URI if not exist ex: siete.useparagon.com
 * @param subDomain subDomain of url ex: siete
 * @param domain url domain ex: .useparagon.com
 * @returns correct protocol and domain in URI if required
 */
export const addProtocolInSubDomain = (subDomain: string, domain?: string): string => {
  subDomain = subDomain.indexOf('http://') > -1 ? subDomain.replace('http://', '') : subDomain;
  subDomain = subDomain.indexOf('https://') > -1 ? subDomain : `https://${subDomain}`;
  if (domain) {
    subDomain = subDomain.indexOf(domain) > -1 ? subDomain.replace(domain, '') : subDomain;
  }

  return subDomain;
};

/**
 * @summary this function check if access token is valid or not
 * @param verifierFunction function which use the token to verify if token is still valid or not
 * @param params parameters for verifierfunction example-credentials
 * @returns true if access token is valid
 */
export const isAccessTokenValid = async (
  verifierFunction: Function,
  params: any[] | string,
): Promise<boolean> => {
  try {
    await verifierFunction.apply(this, params);
    return true;
  } catch {
    return false;
  }
};

/**
 * Prepare string as base 64
 * @param input normal string
 * @returns
 */
export const prepareBase64ForUrl = (input: string): string => {
  return Buffer.from(input)
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/\=/g, '');
};

/**
 * Check input is string html or not
 * @param input normal string
 * @returns boolean value
 */
export const isStringHTML = (input: string): boolean =>
  /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(input);

/**
 * this will return true if string contain target as ending subsctring
 * @param str
 * @param target
 * @returns
 */
export const confirmEnding = (text: string, endingString: string): boolean => {
  const endSubstring: string = text.substr(-endingString.length);
  if (endSubstring === endingString) {
    return true;
  }
  return false;
};

/**
 * @summary get base64 encoded value for code_verifier in oauth according to PKCE specifications
 * @param value
 * @returns
 */
export const base64URLEncode = (value: any) =>
  value.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

/**
 * @summary Apply SHA256 on the code_verifier parameter
 * @param buffer
 * @returns sha256 of code_verifier for oauth code_challenge parameter
 */
export const sha256 = (buffer: any) => createHash('sha256').update(buffer).digest();

/**
 * Converted xml into json format
 * @param xml xml
 * @returns json result
 */
export const convertXmlToJson = (
  xml: string,
  ignoreAttrs: boolean = false,
): RecordType | undefined => {
  let jsonResult: RecordType | undefined;
  // handling self close tag
  const formatXml: string = format(xml, {
    indentation: '',
    lineSeparator: '',
  });
  xml2js.parseString(
    formatXml,
    { explicitArray: false, ignoreAttrs },
    (error: Error | null, result: RecordType): void => {
      if (error) {
        return undefined;
      }

      jsonResult = result;
    },
  );
  return jsonResult;
};

/**
 * Returns the records using search Key
 * @param data provided object for manipulation
 * @param searchKey wants to search
 * @returns Returns the record using search Key
 */
export const recursiveSearch = (data: RecordType, searchKey: string): RecordType | undefined => {
  let value: RecordType | undefined;

  Object.keys(data).some((propertyKey: string) => {
    if (propertyKey === searchKey) {
      value = data[propertyKey];
      return true;
    }

    if (data[propertyKey] && typeof data[propertyKey] === 'object') {
      value = recursiveSearch(data[propertyKey], searchKey);
      return value !== undefined;
    }
  });

  return value;
};

/**
 * Generating the sha1 hash string and convert it in base64
 * @param baseString base string
 * @param key secret key
 * @returns hashed base64 string
 */
export const getHashSha1String = (baseString: string, key: string): string =>
  createHmac('sha1', key).update(baseString).digest('base64');

/**
 * Get encoded string with handling the exception special characters
 * @param value
 * @returns encoded string
 */
export const getEncodeURIComponent = (value: string): string =>
  // encodeURIComponent() does not encode - _ . ! ~ * ' ( ), so need to replace
  encodeURIComponent(value).replace(
    /[!'()*]/g,
    (subString) => '%' + subString.charCodeAt(0).toString(16),
  );

/**
 * Transform body for xml
 * @param body passed body
 * @returns parsed body as string
 */
export const transformBodyForXml = (body: string | Buffer | any = ''): string => {
  let parsedBody: string = typeof body === 'object' ? '' : body;
  parsedBody =
    parsedBody && isAlphaNumeric(parsedBody) ? parseBufferString(parsedBody) : parsedBody;

  return parsedBody.trim();
};
