import React from 'react';

import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarSection,
} from '@shared/types/sdk/actions/';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  additionalFieldsInput,
  dimensionsInput,
  endDateInput,
  intentSelector,
  metricInput,
  realTimeMetricInput,
  startDateInput,
  viewInput,
  webPropertyInput,
} from './common';

const config: ActionConfig = {
  actionType: Action.GOOGLE_ANALYTICS,
  name: 'Google Analytics',
  description: 'Manage goals and segments in Google Analytics',
  icon: iconSvg,
  provider: ProviderType.GOOGLE,
  sidebarSections: [
    intentSelector,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.RUN_REPORT:
          return {
            inputs: [
              viewInput,
              metricInput,
              startDateInput,
              endDateInput,
              dimensionsInput,
              additionalFieldsInput,
            ],
          };
        case Intent.GET_CUSTOM_METRICS:
          return {
            inputs: [
              {
                ...webPropertyInput,
                subtitle:
                  'Property to fetch list of custom metrics for. Use Connect Portal User Settings to allow users to select a Google Analytics Property.',
              },
            ],
          };
        case Intent.GET_CUSTOM_DIMENSIONS:
          return {
            inputs: [webPropertyInput],
          };
        case Intent.GET_REALTIME_DATA:
          return {
            inputs: [
              {
                ...viewInput,
                subtitle:
                  'The view to get realtime data from. Use Connect Portal User Settings to allow users to select a Google Analytics View.',
              },
              realTimeMetricInput,
              {
                ...dimensionsInput,
                placeholder: '',
                subtitle: (
                  <>
                    Characteristics about sessions or users to include in the report.{' '}
                    <a
                      href="https://developers.google.com/analytics/devguides/reporting/realtime/dimsmets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      See the docs
                    </a>{' '}
                    for more on dimensions. A comma-separated list of real time dimensions. E.g.,
                    rt:medium,rt:city.
                  </>
                ),
                lines: 1,
              } as SidebarInput,
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
