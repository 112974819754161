// TODO: Add Webpack loader and change this back to SVG file
export default `<svg width="97" height="133" viewBox="0 0 97 133" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.833496 106.719L16.9657 3.49055C17.1629 2.22455 18.1475 1.22655 19.4107 1.01195C20.6739 0.797353 21.9327 1.41475 22.5367 2.54465L39.2202 33.662L45.8702 21.0005C46.3852 20.0215 47.4005 19.4085 48.5068 19.4085C49.6131 19.4085 50.6283 20.0215 51.1434 21.0005L96.1661 106.718H0.833496V106.719Z" fill="#FFA000"/>
<path d="M56.5661 66.5074L39.2125 33.6472L0.833496 106.719L56.5661 66.5074Z" fill="#F57C00"/>
<path d="M96.1667 106.719L83.8107 30.2583C83.624 29.1603 82.8404 28.2583 81.7792 27.9183C80.718 27.5783 79.5566 27.8613 78.7685 28.6485L0.833496 106.717L43.9569 130.901C46.6649 132.413 49.9629 132.413 52.6709 130.901L96.1667 106.717V106.719Z" fill="#FFCA28"/>
<path d="M83.8105 30.2584C83.6238 29.1604 82.8401 28.2584 81.7789 27.9184C80.7177 27.5784 79.5563 27.8614 78.7682 28.6486L63.3 44.1562L51.1364 21C50.6214 20.021 49.6062 19.408 48.4999 19.408C47.3936 19.408 46.3783 20.021 45.8632 21L39.2132 33.6615L22.5289 2.54467C21.9239 1.41447 20.6636 0.798066 19.3989 1.01427C18.1342 1.23047 17.153 2.23097 16.958 3.49797L0.83325 106.719H0.78125L0.83325 106.778L1.25775 106.987L78.7457 29.4093C79.5334 28.6178 80.6977 28.3333 81.7617 28.6723C82.8257 29.0113 83.6107 29.9168 83.7955 31.018L96.0473 106.793L96.1665 106.718L83.8105 30.2582L83.8105 30.2584ZM0.974751 106.577L16.9655 4.22797C17.1603 2.96097 18.1439 1.96047 19.4075 1.74427C20.6711 1.52807 21.9314 2.14427 22.5374 3.27467L39.22 34.392L45.87 21.7305C46.385 20.7515 47.4002 20.1385 48.5065 20.1385C49.6128 20.1385 50.6281 20.7515 51.1432 21.7305L63.0598 44.3945L0.974051 106.577H0.974751Z" fill="white" fill-opacity="0.2"/>
<path opacity="0.2" d="M52.671 130.172C49.963 131.684 46.665 131.684 43.957 130.172L0.937796 106.056L0.833496 106.719L43.9569 130.895C46.6649 132.407 49.9629 132.407 52.6709 130.895L96.1667 106.719L96.055 106.034L52.671 130.172V130.172Z" fill="#A52714"/>
<path d="M83.687 30.2742C83.501 29.1765 82.72 28.2742 81.6626 27.9362C80.6052 27.5982 79.4478 27.8792 78.6624 28.6662L63.2473 44.166L51.1273 21.0205C50.614 20.0419 49.6023 19.4291 48.5 19.4291C47.3977 19.4291 46.386 20.0419 45.8727 21.0205L39.245 33.6765L22.62 2.57257C22.017 1.44287 20.7612 0.826571 19.502 1.04287C18.2428 1.25917 17.2626 2.25887 17.0685 3.52557L1 106.701L43.9727 130.866C46.6712 132.378 49.9577 132.378 52.6563 130.866L96 106.701L83.687 30.274V30.2742Z" fill="url(#paint0_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="-2.3155" y1="23.2962" x2="116.236" y2="85.8307" gradientUnits="userSpaceOnUse">
<stop stop-color="white" stop-opacity="0.1"/>
<stop offset="0.14" stop-color="white" stop-opacity="0.08"/>
<stop offset="0.61" stop-color="white" stop-opacity="0.02"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>`;
