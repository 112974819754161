import { StepType, UnselectedTriggerStep } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';

/**
 * validates that an unselected trigger step is properly configured
 */
export default class UnselectedTriggerStepValidator extends StepValidator<
  UnselectedTriggerStep,
  undefined
> {
  stepType: UnselectedTriggerStep['type'] = StepType.UNSELECTED_TRIGGER;

  /**
   * validates that a step is valid
   *
   * @param {UnselectedTriggerStep} step
   * @returns {UnselectedTriggerStep}
   * @memberof UnselectedTriggerStepValidator
   */
  validate(step: UnselectedTriggerStep): UnselectedTriggerStep {
    return step;
  }
}
