import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, ActionStepParameters, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

const triggerConfig: ActionConfig = {
  actionType: Action.ASANA,
  name: 'Asana',
  description: 'Trigger when Tasks are created or updated',
  icon: iconSvg,
  provider: ProviderType.ASANA,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_TASK_CREATED,
              label: 'New Task',
            },
            {
              value: Intent.TRIGGER_TASK_UPDATED,
              label: 'Task Updated',
            },
            {
              value: Intent.TRIGGER_TASK_COMPLETED,
              label: 'Task Completed',
            },
            {
              value: Intent.TRIGGER_TAG_ADDED,
              label: 'Tag Added',
            },
            {
              value: Intent.TRIGGER_PROJECT_CREATED,
              label: 'New Project',
            },
            {
              value: Intent.TRIGGER_COMMENT_CREATED,
              label: 'New Comment',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.TRIGGER_PROJECT_CREATED:
          return {
            inputs: [
              {
                id: 'workspaceId',
                type: SidebarInputType.TextArea,
                title: 'Workspace ID',
                lines: 1,
                required: true,
              },
            ],
          };
        case Intent.TRIGGER_TAG_ADDED:
          return {
            inputs: [
              {
                id: 'tagId',
                type: SidebarInputType.TextArea,
                title: 'Tag ID',
                lines: 1,
                required: true,
              },
            ],
          };
        case Intent.TRIGGER_TASK_CREATED:
        case Intent.TRIGGER_TASK_UPDATED:
        case Intent.TRIGGER_TASK_COMPLETED:
        case Intent.TRIGGER_COMMENT_CREATED:
          return {
            inputs: [
              {
                id: 'projectId',
                type: SidebarInputType.TextArea,
                title: 'Project ID',
                lines: 1,
                required: true,
                ...(parameters.intent === Intent.TRIGGER_COMMENT_CREATED
                  ? {
                      subtitle:
                        'Select a Project to watch for comments. Use Connect Portal Workflow Settings to allow users to select an Asana Project.',
                    }
                  : {}),
              },
            ],
          };

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default triggerConfig;
