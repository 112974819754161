import {
  OAuthCredentialValueDefaults,
  ScopeValue,
  SidebarInputType,
} from '@shared/types/sdk/actions';
import { CONNECT_INTEGRATION_PROVIDER_BASE_URL } from '@shared/types/sdk/connect';

import iconSvg from './icon.svg';

export type ScopeName =
  | 'email'
  | 'https://www.googleapis.com/auth/bigquery'
  | 'https://www.googleapis.com/auth/bigquery.insertdata'
  | 'https://www.googleapis.com/auth/cloud-platform'
  | 'https://www.googleapis.com/auth/cloud-platform.read-only'
  | 'https://www.googleapis.com/auth/devstorage.full_control'
  | 'https://www.googleapis.com/auth/devstorage.read_only'
  | 'https://www.googleapis.com/auth/devstorage.read_write';

export const scopes: Record<ScopeName, ScopeValue> = {
  email: {
    label: 'email',
    name: 'email',
    description: 'See the email address for your Google Account.',
    required: true,
  },
  'https://www.googleapis.com/auth/bigquery': {
    label: 'https://www.googleapis.com/auth/bigquery',
    name: 'https://www.googleapis.com/auth/bigquery',
    description:
      'View and manage your data in Google BigQuery and see the email address for your Google Account',
  },
  'https://www.googleapis.com/auth/bigquery.insertdata': {
    label: 'https://www.googleapis.com/auth/bigquery.insertdata',
    name: 'https://www.googleapis.com/auth/bigquery.insertdata',
    description: 'Insert data into Google BigQuery',
  },
  'https://www.googleapis.com/auth/cloud-platform': {
    label: 'https://www.googleapis.com/auth/cloud-platform',
    name: 'https://www.googleapis.com/auth/cloud-platform',
    description:
      'See, edit, configure, and delete your Google Cloud data and see the email address for your Google Account.',
  },
  'https://www.googleapis.com/auth/cloud-platform.read-only': {
    label: 'https://www.googleapis.com/auth/cloud-platform.read-only',
    name: 'https://www.googleapis.com/auth/cloud-platform.read-only',
    description:
      'View your data across Google Cloud services and see the email address of your Google Account',
  },
  'https://www.googleapis.com/auth/devstorage.full_control': {
    label: 'https://www.googleapis.com/auth/devstorage.full_control',
    name: 'https://www.googleapis.com/auth/devstorage.full_control',
    description:
      'Manage your data and permissions in Cloud Storage and see the email address for your Google Account',
  },
  'https://www.googleapis.com/auth/devstorage.read_only': {
    label: 'https://www.googleapis.com/auth/devstorage.read_only',
    name: 'https://www.googleapis.com/auth/devstorage.read_only',
    description: 'View your data in Google Cloud Storage',
  },
  'https://www.googleapis.com/auth/devstorage.read_write': {
    label: 'https://www.googleapis.com/auth/devstorage.read_write',
    name: 'https://www.googleapis.com/auth/devstorage.read_write',
    description:
      'Manage your data in Cloud Storage and see the email address of your Google Account',
  },
};

export const requiredScopes: ScopeName[] = ['email', 'https://www.googleapis.com/auth/bigquery'];

export const oauthConfig: OAuthCredentialValueDefaults = {
  name: 'BigQuery',
  description: 'Manage datasets, tables, and jobs with BigQuery Account',
  authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
  accessTokenUrl: 'https://oauth2.googleapis.com/token',
  iconSvg,
  clientIdLabel: 'Client ID',
  clientSecretLabel: 'Client Secret',
  credentials: [
    {
      type: SidebarInputType.ValueText,
      title: 'Client ID',
      id: 'clientId',
      documentationLink: `${CONNECT_INTEGRATION_PROVIDER_BASE_URL}/bigquery`,
      documentationLinkLabel: 'How to obtain a Client ID?',
    },
    { type: SidebarInputType.ValueText, title: 'Client Secret', id: 'clientSecret' },
  ],
  scopes: {
    values: Object.values(scopes),
  },
  documentationLink: 'https://docs.useparagon.com/resources/oauth/bigquery',
};
