import { ResolvedResponseParameters } from '@shared/types/sdk/resolvers';
import { ResponseStep, StepType } from '@shared/types/sdk/steps';

import { StepValidator } from './abstract.validator';

/**
 * validates that a response step is properly configured
 */
export default class ResponseStepValidator extends StepValidator<
  ResponseStep,
  ResolvedResponseParameters
> {
  stepType: ResponseStep['type'] = StepType.RESPONSE;

  /**
   * validates that a step is valid
   *
   * @param {ResponseStep} step
   * @returns {ResponseStep}
   * @memberof ResponseStepValidator
   */
  validate(step: ResponseStep): ResponseStep {
    return step;
  }
}
