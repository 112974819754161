import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { ActionStepParameters } from '@shared/integrations/airtable/configs';
import { Action, SidebarInputType } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import { conditionalInput } from '../frontend/commonInputs';

const triggerConfig: ActionConfig = {
  actionType: Action.ZOOM,
  name: 'Zoom',
  description: 'Trigger when meetings are created or updated in Zoom',
  icon: iconSvg,
  provider: ProviderType.ZOOM,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'intent',
          title: 'Choose a Trigger Event',
          type: SidebarInputType.Intent,
          values: [
            {
              value: Intent.TRIGGER_MEETING_CREATED,
              label: 'New Meeting',
            },
            {
              value: Intent.TRIGGER_MEETING_UPDATED,
              label: 'Meeting Updated',
            },
            {
              value: Intent.TRIGGER_MEETING_ENDED,
              label: 'Meeting Ended',
            },
            {
              value: Intent.TRIGGER_MEETING_REGISTRANT_CREATED,
              label: 'New Meeting Registrant',
            },
            {
              value: Intent.TRIGGER_PARTICIPANT_JOINED,
              label: 'Meeting Participant Joined',
            },
            {
              value: Intent.TRIGGER_PARTICIPANT_LEFT,
              label: 'Meeting Participant Left',
            },
            {
              value: Intent.TRIGGER_RECORDING_STARTED,
              label: 'New Recording',
            },
            {
              value: Intent.TRIGGER_RECORDING_COMPLETED,
              label: 'Complete Recording',
              subtitle: 'Trigger when a meeting recording is ready to download',
            },
          ],
        },
      ],
    },
    (parameters: ActionStepParameters) => {
      switch (parameters.intent as unknown as Intent) {
        case Intent.TRIGGER_RECORDING_COMPLETED:
          return {
            inputs: [conditionalInput],
          };
        default:
          return { inputs: [] };
      }
    },
  ],
};

export default triggerConfig;
