import { DeploymentStatus, WorkflowMigration } from '@shared/types/sdk/steps';

import { indexBy } from '../../../utils';
import { Action, EntitiesState } from '../../types';

export const initialState: EntitiesState<WorkflowMigration | null> = {
  entities: {},
  errorMessage: undefined,
  processing: false,
};

export function handler(
  state: EntitiesState<WorkflowMigration | null> = initialState,
  action: Action,
): EntitiesState<WorkflowMigration | null> {
  switch (action.type) {
    case 'GET_LAST_DEPLOYMENT_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.workflowId]: action.payload.migration || null,
        },
      };

    case 'DEPLOY_WORKFLOW_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.workflowId]: action.payload.deployment.workflowMigrations[0], // TODO will there always be exactly one?
        },
      };

    case 'UNDEPLOY_WORKFLOW_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.workflowId]: {
            ...state.entities[action.payload.workflowId]!,
            status: DeploymentStatus.DISABLED,
          },
        },
      };

    case 'GET_PROJECT_MIGRATIONS_SUCCESS':
      return {
        ...state,
        entities: indexBy('workflowId', action.payload.migrations),
      };

    case 'PROJECT_SWITCH_BUTTON_CLICKED':
      return initialState;

    default:
      return state;
  }
}
