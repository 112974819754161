import axios, { AxiosError, AxiosInstance } from 'axios';

import { generateQueryStringFromObject } from '@shared/actions/sdk/utils';
import { Operator } from '@shared/types/sdk/resolvers';

import { Credentials } from '../configs';

import {
  API_BASE_URL,
  AUTH_BASE_URL,
  SANDBOX_API_BASE_URL,
  SANDBOX_USER_INFO_BASE_URL,
  USER_INFO_BASE_URL,
} from './constants';
import {
  AuthTokenResponse,
  Environment,
  QuickBooksApiErrorType,
  RenewRefreshTokenResponse,
  UserInfoResponse,
} from './types';

/**
 *@summary return axios instance to authenticate client
 */
export function getQuickbooksAuthClient(): AxiosInstance {
  return getAxiosInstance(AUTH_BASE_URL, {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  });
}

/**
 *@summary return axios instance to get client info
 * @param accessToken
 */
export function getQuickbooksUserInfoClient(
  accessToken: string,
  isSandbox: boolean = false,
): AxiosInstance {
  return getAxiosInstance(isSandbox ? SANDBOX_USER_INFO_BASE_URL : USER_INFO_BASE_URL, {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });
}

/**
 * @summary used to connect with Quickbooks database via web requests
 *  by passing SQL queries as params
 * @param accessToken
 * @returns axios instance
 */
export function getQuickbooksApiClient(
  accessToken: string,
  isSandbox: boolean = false,
): AxiosInstance {
  return getAxiosInstance(isSandbox ? SANDBOX_API_BASE_URL : API_BASE_URL, {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });
}

/**
 *
 * @summary return basic axios instance
 * @param baseUrl - domain url
 * @param headers - headers to pass
 */
function getAxiosInstance(baseUrl: string, headers: Record<string, string>): AxiosInstance {
  return axios.create({
    baseURL: baseUrl,
    headers: headers,
  });
}

/**
 * returns value for Authorization header
 */
export function getBasicAuthString(clientId: string, clientSecret: string): string {
  return 'Basic ' + Buffer.from(clientId + ':' + clientSecret).toString('base64');
}

/**
 *
 * @summary - exchange Quickbooks Refresh Token For Access Token
 * @param clientId
 * @param clientSecret
 * @param refreshToken
 */
export async function exchangeQuickbooksRefreshTokenForAccessToken(
  clientId: string,
  clientSecret: string,
  refreshToken: string,
): Promise<AuthTokenResponse> {
  const tokenResponse = await getQuickbooksAuthClient().post<AuthTokenResponse>(
    'oauth2/v1/tokens/bearer',
    generateQueryStringFromObject(
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
      { urlencodedValues: true, addPrefix: false },
    ),
    { headers: { Authorization: getBasicAuthString(clientId, clientSecret) } },
  );

  return tokenResponse.data;
}

/**
 *
 * @summary  whenever query response return a error this function will get call
 * @param err error response from Quickbooks API
 * @returns error instance
 */
export function handleResponseError(err: any, msg: string = ''): Error {
  return new Error(
    `QuickBooks API Error: ${msg} ${err.response ? JSON.stringify(err.response?.data) : err} `,
  );
}

/**
 *
 * @param error
 * @param msg
 * @returns
 */
export const getFormattedErrorMessage = (
  error: AxiosError | QuickBooksApiErrorType,
  msg: string = '',
): string => {
  return `QuickBooks API Error: ${msg} ${
    error.response
      ? JSON.stringify(
          error.response.data?.Fault?.Error[0]?.Detail ||
            error.response.data?.Fault?.Error[0]?.Message ||
            error.response.data,
        )
      : error
  }`;
};

/**
 *
 * @summary generate a SQL query prefix
 * @param resourceType Quickbooks table name i.e. Account, Bill, Customer
 */
export function getQueryPrefix(resourceType: string): string {
  return `select * from ${resourceType}`;
}

export const quickBooksSupportedOperators: Operator[] = [
  Operator.NumberLessThan,
  Operator.NumberEquals,
  Operator.NumberGreaterThan,
  Operator.NumberDoesNotEqual,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberLessThanOrEqualTo,
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.StringContains,
  Operator.DateTimeEquals,
  Operator.DateTimeBefore,
  Operator.DateTimeAfter,
  Operator.StringIsIn,
  Operator.StringGreaterThan,
  Operator.StringLessThan,
  Operator.BooleanTrue,
  Operator.BooleanFalse,
];

/**
 *
 * @summary this function will check weather the token valid token is available
 * if not it will provide a new access token
 * @param credentials
 * @param environment
 * @returns object containing new tokens
 */
export async function renewRefreshToken(
  credentials: Credentials,
  environment: Environment,
): Promise<RenewRefreshTokenResponse> {
  let accessToken = credentials.QUICKBOOKS_OAUTH_ACCESS_TOKEN;
  let updatedCredential: Credentials | undefined;
  if (!accessToken || !(await isAccessTokenValid(accessToken, credentials.QUICKBOOKS_IS_SANDBOX))) {
    const tokenResult = await exchangeQuickbooksRefreshTokenForAccessToken(
      credentials.QUICKBOOKS_IS_SANDBOX
        ? environment.QUICKBOOKS_CLIENT_ID_SANDBOX
        : environment.QUICKBOOKS_CLIENT_ID,
      credentials.QUICKBOOKS_IS_SANDBOX
        ? environment.QUICKBOOKS_CLIENT_SECRET_SANDBOX
        : environment.QUICKBOOKS_CLIENT_SECRET,
      credentials.QUICKBOOKS_OAUTH_REFRESH_TOKEN,
    );
    updatedCredential =
      tokenResult.refresh_token != credentials.QUICKBOOKS_OAUTH_REFRESH_TOKEN
        ? {
            ...credentials,
            ...{ QUICKBOOKS_OAUTH_REFRESH_TOKEN: tokenResult.refresh_token },
          }
        : undefined;
    accessToken = tokenResult.access_token;
  }

  return {
    accessToken,
    updatedCredential,
    hasCredentialUpdated: updatedCredential !== undefined,
  };
}

/**
 * Get Sync token from response object
 * @param data
 * @returns syncToken as string
 */
export function getSyncToken(data): string | undefined {
  return data.QueryResponse.Invoice?.[0]?.SyncToken;
}

/**
 * @summary checks if access token is valid
 * @param accessToken
 */
export const isAccessTokenValid = async (
  accessToken: string,
  isSandbox: boolean = false,
): Promise<boolean> => {
  try {
    await getQuickbooksUserInfoClient(accessToken, isSandbox).get<UserInfoResponse>(
      'openid_connect/userinfo',
    );
    return true;
  } catch (err) {
    return false;
  }
};
