import {
  Action,
  BaseActionConfig,
  BaseActionStepParameters,
  ConnectIntent,
  OAuthIntent,
  SidebarSection,
  SidebarSectionFactory,
} from '@shared/types/sdk/actions';
import { DataType, KeyedSource } from '@shared/types/sdk/resolvers';

export type ActionConfig = BaseActionConfig & {
  actionType: Action.GOOGLE_AD_MANAGER;
  sidebarSections: SidebarSectionFactory[];
};

export type AuthConfig = BaseActionConfig & {
  actionType: Action.NONE;
  sidebarSections: SidebarSection[];
};

export enum Intent {
  TEST_CONNECTION = 'GOOGLE_AD_MANAGER_TEST_CONNECTION',
  GET_NETWORK_CODE = 'GOOGLE_AD_MANAGER_GET_NETWORK_CODE',
}

/**
 * Combination of OAuthIntent, ActionIntent and ConnectIntent
 */
type IntentMap =
  | OAuthIntent.REFRESH_CREDENTIAL
  | OAuthIntent.GET_AUTH_ESSENTIALS
  | OAuthIntent.EXCHANGE_AUTH_CREDENTIALS
  | Intent.TEST_CONNECTION
  | Intent.GET_NETWORK_CODE
  | ConnectIntent.GET_CONNECT_REQUEST_OPTIONS
  | ConnectIntent.GET_PROVIDER_ID;

export type ActionStepParameters = BaseActionStepParameters & {
  intent: IntentMap;
  actionParameters: KeyedSource<DataType.ANY>[];
};

export enum CredentialKeys {
  REFRESH_TOKEN = 'GOOGLE_AD_MANAGER_REFRESH_TOKEN',
  ACCESS_TOKEN = 'GOOGLE_AD_MANAGER_ACCESS_TOKEN',
  NETWORK_CODE = 'GOOGLE_AD_MANAGER_NETWORK_CODE',
  USER_ID = 'GOOGLE_AD_MANAGER_USER_ID',
}

export type Credentials = {
  [CredentialKeys.ACCESS_TOKEN]: string;
  [CredentialKeys.REFRESH_TOKEN]: string;
  [CredentialKeys.NETWORK_CODE]: string;
  [CredentialKeys.USER_ID]: string;
};
