export type Credentials = {
  OAUTH_REFRESH_TOKEN: string;
  OAUTH_ACCESS_TOKEN: string;
};

export type Environment = {
  HUBSPOT_CLIENT_ID: string;
  HUBSPOT_CLIENT_SECRET: string;
  HUBSPOT_SCOPES?: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, any>;
};

export type ExchangeCredentialDTO = {
  code: string;
  redirectUrl: string;
};

export type RecordTypeClient = {
  [key: string]: any;
};

/**
 * A constant that represents a value for the `recordType` property recorded in the UI as
 * "Custom Object".
 *
 * If this field is found in the `recordType` property, the `objectName` property
 * in the step should be used as the name of the object being created/updated/deleted/searched.
 */
export const RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME = '<specified by objectName property>' as const;

/**
 * hubspot doesn't provide oauth api  support for quote recordtype so didnt include it
 * reference - https://community.hubspot.com/t5/APIs-Integrations/API-Quotes-endpoints-no-longer-exist/m-p/340911/highlight/true#M33316
 * reference - https://developers.hubspot.com/docs/api/crm/quotes
 */
export enum RecordType {
  COMPANY = 'companies',
  CONTACTS = 'contacts',
  DEALS = 'deals',
  LINEITEMS = 'lineItems',
  PRODUCTS = 'products',
  TICKETS = 'tickets',
}

//these are not actual record type as per hubspot
// but in paragon we are treating these as generic record type
export enum ExtraRecordType {
  ENGAGEMENTS = 'engagements',
}

export type SupportedRecordType = RecordType | 'engagements';

export type UserInfoResponse = {
  user: string;
  hub_domain: string;
  hub_id: string;
  scopes: string[];
  user_id: number;
};

export type RecordTypeField = {
  label: string;
  name: string;
  calculated?: boolean;
};

export type GetRecordFieldResponse = {
  label: string;
  value: string;
  required: boolean;
  type?: string;
  options?: { label: string; value: string }[];
  fieldType?: string;
};

export type DealCreatedDTO = {
  dealname?: string; // This field is not in product spec, we have included this to solve concurrent ci problems
};

export type ContactCreatedDTO = {
  email?: string; // This field is not in product spec, we have included this to solve concurrent ci problems
};

export type CompanyCreatedDTO = {
  name?: string; // This field is not in product spec, we have included this to solve concurrent ci problems
};

export type ResponseProperty = { [key: string]: string };

export enum EngagementType {
  NOTE = 'NOTE',
  EMAIL = 'EMAIL',
  CALL = 'CALL',
  MEETING = 'MEETING',
  TASK = 'TASK',
}

export enum TaskEngagementStatus {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING',
  DEFERRED = 'DEFERRED',
}

export type NoteMetadata = {
  body: string;
};

export type EmailMetadata = {
  from: {
    email: string;
    firstName: string;
    lastName: string;
  };
  to: {
    email: string;
  }[];
  cc: {
    email: string;
  }[];
  bcc: {
    email: string;
  }[];
  subject: string;
  html: string;
  text: string;
};

export type TaskMetadata = {
  body: string;
  subject: string;
  status:
    | TaskEngagementStatus.COMPLETED
    | TaskEngagementStatus.DEFERRED
    | TaskEngagementStatus.IN_PROGRESS
    | TaskEngagementStatus.NOT_STARTED
    | TaskEngagementStatus.WAITING;
  forObjectType: 'CONTACT';
};

export type MeetingMetadata = {
  body: string;
  startTime?: number;
  endTime: number;
  title: string;
  internalMeetingNotes: string;
};

export type CallMetadata = {
  toNumber: string;
  fromNumber: string;
  status: 'COMPLETED';
  durationMilliseconds?: number;
  recordingUrl: string;
  body: string;
};

export type Engagement = {
  engagement: {
    id?: string;
    portalId?: string;
    active: boolean;
    createdAt?: number;
    lastUpdated?: number;
    timestamp?: number;
    ownerId?: number;
    type:
      | EngagementType.CALL
      | EngagementType.EMAIL
      | EngagementType.MEETING
      | EngagementType.NOTE
      | EngagementType.TASK;
  };
  associations: {
    contactIds: number[];
    companyIds: number[];
  };
  metadata: EmailMetadata | NoteMetadata | CallMetadata | MeetingMetadata | TaskMetadata;
};

type CommonCreateEngagementProperties = {
  recordType: ExtraRecordType;
  engagementType: EngagementType;
  contactId?: string;
  companyId?: string;
  ownerId?: number;
};

export type NoteCreateEngagementDTO = CommonCreateEngagementProperties & {
  noteBody: string;
};

export type EmailCreateEngagementDTO = CommonCreateEngagementProperties & {
  fromEmail: string;
  fromFirstName: string;
  fromLastName: string;
  toEmail: string;
  ccEmail: string;
  bccEmail: string;
  emailSubject: string;
  emailText: string;
  emailHtml: string;
};

export type CallCreateEngagementDTO = CommonCreateEngagementProperties & {
  toNumber: string;
  fromNumber: string;
  callNotes: string;
  recordingUrl: string;
};

export type MeetingCreateEngagementDTO = CommonCreateEngagementProperties & {
  meetingTitle: string;
  meetingNotes: string;
  startTime: string;
  endTime: string;
};

export type TaskCreateEngagementDTO = CommonCreateEngagementProperties & {
  taskSubject: string;
  taskNotes: string;
  taskStatus:
    | TaskEngagementStatus.COMPLETED
    | TaskEngagementStatus.DEFERRED
    | TaskEngagementStatus.IN_PROGRESS
    | TaskEngagementStatus.NOT_STARTED
    | TaskEngagementStatus.WAITING;
};

export type CreateEngagementDTO =
  | NoteCreateEngagementDTO
  | EmailCreateEngagementDTO
  | CallCreateEngagementDTO
  | TaskCreateEngagementDTO
  | MeetingCreateEngagementDTO;

export type UpdateEngagementDTO = CreateEngagementDTO & { recordId: string };

export type CreateCustomObjectRecordDTO = {
  recordType: typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME;
  objectName: string;
  customObjectFields: string | object;
};

export type UpdateCustomObjectRecordDTO = CreateCustomObjectRecordDTO & { recordId: string };

export type HubspotFilter = { propertyName: string; operator: 'EQ'; value: string };
export type SearchEngagementDTO = {
  recordType: SupportedRecordType;
  engagementFilterFormula?: { filters: HubspotFilter[] }[];
};

export type SearchEngagementResponse = {
  offset: number;
  hasMore: boolean;
  results: Engagement[];
};

export type RecordCreatedDTO = {
  recordType: RecordType | typeof RECORD_TYPE_SPECIFIED_BY_OBJECT_NAME;
  objectName?: string;
};

export type PaginateResponseType = {
  offset: number;
  'has-more': boolean;
  'vid-offset': number;
};

export type GetContactsRequestResponse = {
  contacts: Record<string, any>[];
} & PaginateResponseType;

export type GetContactListRequestResponse = {
  lists: Record<string, any>[];
} & PaginateResponseType;

export type RecordUpdatedDTO = RecordCreatedDTO;
