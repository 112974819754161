import React from 'react';

import {
  EnumInputValue,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import { LinkWrapper } from '../configs/common';
import { RecordType } from '../shared';

export const companyNameInput: SidebarInput = {
  id: 'companyName',
  title: 'Company Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const primarySubsidiaryInput: SidebarInput = {
  id: 'subsidiary',
  title: 'Primary Subsidiary',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const typeInput: SidebarInput = {
  id: 'isPerson',
  title: 'Type',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Company', value: 'company' },
    { label: 'Individual', value: 'individual' },
  ],
  defaultValue: 'company',
};

export const accountTypeInput = (options: EnumInputValue[]): SidebarInput => {
  return {
    id: 'acctType',
    title: 'Type',
    type: SidebarInputType.EditableEnum,
    required: true,
    getValues: () => options,
    subtitle: 'Select a GAAP category for the type of account you are creating.',
  };
};

export const emailInput: SidebarInput = {
  id: 'email',
  title: 'Email',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: 'primary@vendor.com',
};

export const altEmailInput: SidebarInput = {
  id: 'altEmail',
  title: 'Alternative Email',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: 'secondary@vendor.com',
};

export const defaultTaxRegInput: SidebarInput = {
  id: 'defaultTaxReg',
  title: 'Default Tax Registration Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const expenseAccountInput: SidebarInput = {
  id: 'expenseAccount',
  title: 'Expense Account',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Specify an account associated with this vendor.',
};

export const termInput: SidebarInput = {
  id: 'terms',
  title: 'Term',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'The ID of the payment term for this vendor.',
};

export const addressLine1Input: SidebarInput = {
  id: 'addr1',
  title: 'Address Line 1',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const addressLine2Input: SidebarInput = {
  id: 'addr2',
  title: 'Address Line 2',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const cityInput: SidebarInput = {
  id: 'city',
  title: 'City',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
};

export const stateInput: SidebarInput = {
  id: 'state',
  title: 'State',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Use state or province.',
};

export const zipInput: SidebarInput = {
  id: 'zip',
  title: 'Zip',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Use ZIP or postal code, if applicable.',
};

export const countryInput: SidebarInput = {
  id: 'country',
  title: 'Country',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Accepts ISO-3166 country codes.',
  placeholder: 'US',
};

export const vendorAdditionalFieldsInput: SidebarInput = {
  id: 'vendorAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.TextArea,
  required: false,
  lines: 8,
  subtitle: (
    <>
      Specify additional fields to include on this vendor as JSON. For example, you may specify a
      different billing address here.{' '}
      <LinkWrapper
        text="See the docs"
        href="https://system.netsuite.com/help/helpcenter/en_US/APIs/REST_API_Browser/record/v1/2021.2/index.html#/definitions/vendor"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `[
    {
      "addressBookAddress": {
        "addr1": "2500 Durant Ave",
        "addr2": "Unit A",
        "addrPhone": "5101112222",
        "addrText": "2500 Durant Ave Unit A, Berkeley, CA, 94704",
        "addressee": "John Appleseed",
        "city": "Berkeley",
        "state": "CA",
        "defaultBilling": true,
        "defaultShipping": true
      }
    },
  ]`,
};

export const vendorIdInput: SidebarInput = {
  id: 'vendorId',
  title: 'Vendor ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The ID of the vendor to update.',
};

export const vendorFilterInput: SidebarInput = {
  id: 'vendorFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringContains,
    Operator.NumberEquals,
    Operator.NumberDoesNotEqual,
    Operator.StringStartsWith,
    Operator.StringEndsWith,
    Operator.BooleanTrue,
    Operator.BooleanFalse,
  ],
  required: true,
  getValues: () => [
    { label: 'Vendor ID', value: 'id' },
    { label: 'Company Name', value: 'companyName' },
    { label: 'Individual', value: 'isPerson' },
    { label: 'Email', value: 'email' },
    { label: 'Default Tax Registration Number', value: 'defaultTaxReg' },
  ],
};

export const vendorInput: SidebarInput = {
  id: 'entity',
  title: 'Vendor',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The vendor ID this bill is associated with.',
};

export const dateInput: SidebarInput = {
  id: 'tranDate',
  title: 'Date',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Accepts ISO 8601 date format.',
  placeholder: '2022-01-31',
};

export const postingPeriodInput: SidebarInput = {
  id: 'postingPeriod',
  title: 'Posting Period',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Select the period to which you want to post this transaction.',
};

export const subsidiaryInput: SidebarInput = {
  id: 'subsidiary',
  title: 'Subsidiary',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle:
    'Enter the subsidiary ID to associate with this transaction. By default, the bill will use the Parent Company subsidiary.',
};

export const referenceNumberInput: SidebarInput = {
  id: 'tranId',
  title: 'Reference Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle:
    "Enter additional information to identify this transaction, such as the vendor's invoice number. This reference appears on reports such as the Accounts Payable Register.",
};

export const dueDateInput: SidebarInput = {
  id: 'dueDate',
  title: 'Due Date',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Accepts ISO 8601 date format.',
  placeholder: '2022-01-31',
};

export const amountInput: SidebarInput = {
  id: 'userTotal',
  title: 'Amount',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '25.00',
};

export const approvalStatusInput: SidebarInput = {
  id: 'approvalStatus',
  title: 'Approval Status',
  type: SidebarInputType.EditableEnum,
  required: false,
  placeholder: 'Approved',
  getValues: () => [
    { label: 'Approved', value: '2' },
    { label: 'Pending Approval', value: '1' },
  ],
};

export const memoInput: SidebarInput = {
  id: 'memo',
  title: 'Memo',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle:
    'Enter a memo that will appear on such reports as the 2-line Accounts Payable Register.',
};

export const expensesAndItemsInput: SidebarInput = {
  id: 'itemsInput',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 4,
  title: 'Expenses and Items',
  required: true,
  subtitle: (
    <>
      Enter an array of Expenses including the description, quantity, and amount for each Line item.{' '}
      <LinkWrapper
        text="See the docs"
        href="https://system.netsuite.com/help/helpcenter/en_US/APIs/REST_API_Browser/record/v1/2021.2/index.html#/definitions/vendorBill-expenseCollection"
      />{' '}
      for available fields.
    </>
  ),
  placeholder: `[
    {
      "account:"58",
      "amount": 30.99,
      "memo":"test"
    }
  ]`,
  useLightTheme: true,
};

export const billIdInput: SidebarInput = {
  id: 'billId',
  title: 'Bill ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The ID of the bill to update.',
};

export const billFilterInput: SidebarInput = {
  id: 'billFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringContains,
    Operator.NumberEquals,
    Operator.NumberDoesNotEqual,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
    Operator.StringStartsWith,
    Operator.StringEndsWith,
    Operator.BooleanTrue,
    Operator.BooleanFalse,
  ],
  required: true,
  getValues: () => [
    { label: 'Vendor ID', value: 'entity' },
    { label: 'Bill ID', value: 'id' },
    { label: 'Reference Number', value: 'tranId' },
    { label: 'Date', value: 'tranDate' },
    { label: 'Due Date', value: 'dueDate' },
    { label: 'Approval Status', value: 'approvalStatus' },
    { label: 'Memo', value: 'memo' },
  ],
};

export const numberInput: SidebarInput = {
  id: 'acctNumber',
  title: 'Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Enter the number to identify this account.',
  placeholder: '6000',
};

export const nameInput: SidebarInput = {
  id: 'acctName',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Enter up to 31 characters for an account name that will appear on all reports.',
  placeholder: 'Expenses',
};

export const subsidiariesInput: SidebarInput = {
  id: 'subsidiaries',
  title: 'Subsidiaries',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Select one or more subsidiaries by subsidiary ID to restrict the use of this account.',
};

export const subAccountOfInput: SidebarInput = {
  id: 'parent',
  title: 'Sub-Account Of',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'Select the parent account if this account is a sub-account of another.',
};

export const accountIdInput: SidebarInput = {
  id: 'accountId',
  title: 'Account ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The ID of the account to update.',
};

export const accountFilterInput: SidebarInput = {
  id: 'accountFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringContains,
    Operator.StringStartsWith,
    Operator.StringEndsWith,
    Operator.NumberEquals,
    Operator.NumberDoesNotEqual,
    Operator.BooleanTrue,
    Operator.BooleanFalse,
  ],
  required: true,
  getValues: () => [
    { label: 'Account Number', value: 'acctNumber' },
    { label: 'Type', value: 'acctType' },
  ],
};

export const taxGroupNameInput: SidebarInput = {
  id: 'itemId',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Enter a name for this Tax Group. You select this name on transactions.',
};

export const taxCodeInput: SidebarInput = {
  id: 'taxCode',
  title: 'Tax Code',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'Specify the ID of the Tax Code to associate with this Tax Group.',
};

export const taxGroupIdInput: SidebarInput = {
  id: 'taxGroupId',
  title: 'Tax Group ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The ID of the Tax Group to fetch.',
};

export const termIdInput: SidebarInput = {
  id: 'termId',
  title: 'Terms',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The ID of the term to get.',
};

export const termFilterInput: SidebarInput = {
  id: 'termFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.StringContains,
    Operator.NumberEquals,
    Operator.NumberDoesNotEqual,
    Operator.StringStartsWith,
    Operator.StringEndsWith,
  ],
  required: true,
  getValues: () => [
    { label: 'Name', value: 'name' },
    { label: 'Discount Percent', value: 'discountPercent' },
  ],
};

export const taxCodeFilterInput: SidebarInput = {
  id: 'taxCodeFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators: [Operator.NumberEquals, Operator.NumberDoesNotEqual],
  required: true,
  getValues: () => [{ label: 'Tax Code ID', value: 'id' }],
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Vendors',
          items: [
            {
              value: Intent.CREATE_VENDOR,
              label: 'Create Vendor',
            },
            {
              value: Intent.UPDATE_VENDOR,
              label: 'Update Vendor',
            },
            {
              value: Intent.GET_VENDOR_BY_ID,
              label: 'Get Vendor by ID',
            },
            {
              value: Intent.SEARCH_VENDORS,
              label: 'Search Vendors',
            },
            {
              value: Intent.DELETE_VENDOR,
              label: 'Delete Vendor',
            },
          ],
        },
        {
          title: 'Bills',
          items: [
            {
              value: Intent.CREATE_BILL,
              label: 'Create Bill',
            },
            {
              value: Intent.UPDATE_BILL,
              label: 'Update Bill',
            },
            {
              value: Intent.GET_BILL_BY_ID,
              label: 'Get Bill by ID',
            },
            {
              value: Intent.SEARCH_BILLS,
              label: 'Search Bills',
            },
            {
              value: Intent.DELETE_BILL,
              label: 'Delete Bill',
            },
          ],
        },
        {
          title: 'Accounts',
          items: [
            {
              value: Intent.CREATE_ACCOUNT,
              label: 'Create Account',
            },
            {
              value: Intent.UPDATE_ACCOUNT,
              label: 'Update Account',
            },
            {
              value: Intent.GET_ACCOUNT_BY_ID,
              label: 'Get Account by ID',
            },
            {
              value: Intent.SEARCH_ACCOUNTS,
              label: 'Search Accounts',
            },
            {
              value: Intent.DELETE_ACCOUNT,
              label: 'Delete Account',
            },
          ],
        },
        {
          title: 'Tax Groups',
          items: [
            {
              value: Intent.CREATE_TAX_GROUP,
              label: 'Create Tax Group',
            },
            {
              value: Intent.UPDATE_TAX_GROUP,
              label: 'Update Tax Group',
            },
            {
              value: Intent.GET_TAX_GROUP_BY_ID,
              label: 'Get Tax Group by ID',
            },
            {
              value: Intent.DELETE_TAX_GROUP,
              label: 'Delete Tax Group',
            },
          ],
        },
        {
          title: 'Tax Code',
          items: [
            {
              value: Intent.SEARCH_TAX_CODES,
              label: 'Search Tax Codes',
            },
          ],
        },
        {
          title: 'Payment Terms',
          items: [
            {
              value: Intent.SEARCH_PAYMENT_TERMS,
              label: 'Search Payment Terms',
            },
            {
              value: Intent.GET_PAYMENT_TERM_BY_ID,
              label: 'Get Payment Term by ID',
            },
          ],
        },
        {
          title: 'Posting Periods',
          items: [
            {
              value: Intent.SEARCH_POSTING_PERIODS,
              label: 'Search Posting Periods',
            },
          ],
        },
      ],
    },
  ],
};

export const vendorFields = (
  isCreateVendorIntent: boolean,
  selectedType: string,
): SidebarInput[] => [
  recordTypeInput(RecordType.VENDOR),
  ...(isCreateVendorIntent ? [] : [vendorIdInput]),
  { ...companyNameInput, required: isCreateVendorIntent },
  primarySubsidiaryInput,
  typeInput,
  ...(selectedType === 'individual'
    ? [
        { ...firstNameInput, required: isCreateVendorIntent },
        { ...lastNameInput, required: isCreateVendorIntent },
      ]
    : []),
  emailInput,
  altEmailInput,
  defaultTaxRegInput,
  expenseAccountInput,
  termInput,
  addressLine1Input,
  addressLine2Input,
  cityInput,
  stateInput,
  zipInput,
  countryInput,
  vendorAdditionalFieldsInput,
];

export const billFields = (isCreateBillIntent: boolean): SidebarInput[] => [
  recordTypeInput(RecordType.BILL),
  ...(isCreateBillIntent ? [] : [billIdInput]),
  { ...vendorInput, required: isCreateBillIntent },
  { ...dateInput, required: isCreateBillIntent },
  { ...postingPeriodInput, required: isCreateBillIntent },
  subsidiaryInput,
  referenceNumberInput,
  dueDateInput,
  amountInput,
  approvalStatusInput,
  memoInput,
  { ...expensesAndItemsInput, required: isCreateBillIntent },
  billAdditionalFields,
];

export const accountFields = (isCreateAccountField: boolean): SidebarInput[] => [
  recordTypeInput(RecordType.ACCOUNTS),
  ...(isCreateAccountField ? [] : [accountIdInput]),
  { ...numberInput, required: isCreateAccountField },
  { ...nameInput, required: isCreateAccountField },
  { ...subsidiariesInput, required: isCreateAccountField },
  ...(isCreateAccountField
    ? [
        {
          ...accountTypeInput([
            { label: 'Bank', value: 'Bank' },
            { label: 'Accounts Receivable', value: 'AcctRec' },
            { label: 'Other Current Asset', value: 'OthCurrAsset' },
            { label: 'Fixed Asset', value: 'FixedAsset' },
            { label: 'Other Asset', value: 'OthAsset' },
            { label: 'Accounts Payable', value: 'AcctPay' },
            { label: 'Credit Card', value: 'CredCard' },
          ]),
        },
      ]
    : [
        {
          ...accountTypeInput([
            { label: 'Bank', value: 'Bank' },
            { label: 'Other Current Asset', value: 'OthCurrAsset' },
            { label: 'Fixed Asset', value: 'FixedAsset' },
            { label: 'Other Asset', value: 'OthAsset' },
            { label: 'Credit Card', value: 'CredCard' },
          ]),
          required: isCreateAccountField,
        },
      ]),
  subAccountOfInput,
  accountAdditionalFields,
];

export const taxGroupFields = (isCreateTaxGroupField: boolean): SidebarInput[] => [
  recordTypeInput(RecordType.TAX_GROUP),
  ...(isCreateTaxGroupField ? [] : [taxGroupIdInput]),
  { ...taxGroupNameInput, required: isCreateTaxGroupField },
  { ...taxCodeInput, required: isCreateTaxGroupField },
  {
    ...countryInput,
    id: 'county',
    subtitle:
      'Enter the county where this tax should be applied. This value is used by NetSuite to automatically determine the correct tax when an order is entered or a customer places an order on your website.',
  },
];

export const recordTypeInput = (record: RecordType): SidebarInput => ({
  id: 'recordType',
  title: 'Record Type',
  type: SidebarInputType.Text,
  required: false,
  overrideValue: record,
  hidden: true,
});

export const firstNameInput: SidebarInput = {
  id: 'firstName',
  title: 'First Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const lastNameInput: SidebarInput = {
  id: 'lastName',
  title: 'Last Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const billAdditionalFields: SidebarInput = {
  id: 'billAdditionalFields',
  title: 'Additional Fields',
  placeholder: `[
    {
      "vendorTaxItem": 10
    },
  ]`,
  type: SidebarInputType.TextArea,
  lines: 4,
  required: false,
  subtitle: (
    <>
      Specify additional fields to include on this Bill as JSON.{' '}
      <LinkWrapper
        text="See the docs"
        href="https://system.netsuite.com/help/helpcenter/en_US/APIs/REST_API_Browser/record/v1/2021.2/index.html#/definitions/vendorBill"
      />{' '}
      for available fields.
    </>
  ),
};

export const accountAdditionalFields: SidebarInput = {
  id: 'accountAdditionalFields',
  title: 'Additional Fields',
  placeholder: `[
    {
      "availableBalance": 10
    }
  ]`,
  type: SidebarInputType.TextArea,
  lines: 4,
  required: false,
  subtitle: (
    <>
      Specify additional fields to include on this Account as JSON.{' '}
      <LinkWrapper
        text="See the docs"
        href="https://system.netsuite.com/help/helpcenter/en_US/APIs/REST_API_Browser/record/v1/2021.2/index.html#/definitions/account"
      />{' '}
      for available fields.
    </>
  ),
};

export const postingPeriodFilterInput: SidebarInput = {
  id: 'postingPeriodFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  type: SidebarInputType.Conditional,
  supportedOperators: [Operator.DateTimeEquals, Operator.DateTimeAfter, Operator.DateTimeBefore],
  required: false,
  getValues: () => [
    { label: 'Start Date', value: 'startDate' },
    { label: 'End Date', value: 'endDate' },
  ],
};
