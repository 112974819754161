import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionConfig, SidebarInputType } from '@shared/types/sdk/actions/';

import iconSvg from '../../configs/icon.svg';

const authConfig: ActionConfig = {
  actionType: Action.MYSQL,
  provider: ProviderType.MYSQL,
  scheme: AuthenticationScheme.BASIC,
  name: 'Connect to a MySQL database',
  description: 'Add MySQL to Paragon',
  icon: iconSvg,
  sidebarSections: [
    {
      inputs: [
        {
          id: 'name',
          title: 'Name',
          placeholder: 'name this database',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MYSQL_DATABASE',
          title: 'Database',
          placeholder: 'database',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MYSQL_HOST',
          title: 'Host',
          placeholder: 'localhost',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MYSQL_PORT',
          title: 'Port',
          placeholder: '3306',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MYSQL_USER',
          title: 'User',
          placeholder: 'user',
          type: SidebarInputType.ValueText,
        },
        {
          id: 'MYSQL_PASSWORD',
          title: 'Password',
          placeholder: 'password',
          type: SidebarInputType.ValueText,
          inputType: 'password',
        },
      ],
      buttons: [
        createTestConnectionButton(Action.MYSQL, [
          'MYSQL_HOST',
          'MYSQL_DATABASE',
          'MYSQL_PORT',
          'MYSQL_USER',
          'MYSQL_PASSWORD',
        ]),
      ],
    },
  ],
};

export default authConfig;
