import { Action } from '@shared/types/sdk/actions';
import { Step, StepType, Workflow } from '@shared/types/sdk/steps';
import { BillingPlan } from '@shared/types/sdk/stripe';

export type WorkflowFeatureRestriction = {
  name: string;
  minimumBillingPlan: BillingPlan;
  featureDocumentationLink: string;
};

export type WorkflowFeatureValidator = (
  steps: Step[],
  workflow: Workflow,
  billingPlan: BillingPlan,
) => WorkflowFeatureRestriction | undefined;

export const OAUTH_TRIGGER_DOCUMENTATION_LINK =
  'https://docs.useparagon.com/building-workflows/triggers#oauth';
export const OAUTH_CUSTOM_SETUP_DOCUMENTATION_LINK =
  'https://docs.useparagon.com/resources/oauth/custom';

export const SALESFORCE_DOCUMENTATION_LINK =
  'https://docs.useparagon.com/resources/integrations-catalog/salesforce';

const oauthTriggerRestriction: WorkflowFeatureRestriction = {
  name: 'OAuth Trigger',
  minimumBillingPlan: BillingPlan.ClassicBusiness,
  featureDocumentationLink: OAUTH_TRIGGER_DOCUMENTATION_LINK,
};

const eventTriggerRestriction: WorkflowFeatureRestriction = {
  name: 'Event Trigger',
  minimumBillingPlan: BillingPlan.ClassicStarter,
  featureDocumentationLink: 'https://docs.useparagon.com/building-workflows/triggers#event-based',
};

const salesforceActionRestriction: WorkflowFeatureRestriction = {
  name: 'Salesforce Integration',
  minimumBillingPlan: BillingPlan.ClassicBusiness,
  featureDocumentationLink: SALESFORCE_DOCUMENTATION_LINK,
};

export const eventTriggerFeatureValidator: WorkflowFeatureValidator = (
  steps: Step[],
  _workflow: Workflow,
  billingPlan: BillingPlan,
) => {
  if (billingPlan !== BillingPlan.ClassicFree) {
    return undefined;
  } else if (steps.some((step: Step) => step.type === StepType.ACTION_TRIGGER)) {
    return eventTriggerRestriction;
  }

  return undefined;
};

export const oauthTriggerFeatureValidator: WorkflowFeatureValidator = (
  steps: Step[],
  _workflow: Workflow,
  billingPlan: BillingPlan,
) => {
  if (billingPlan !== BillingPlan.ClassicFree && billingPlan !== BillingPlan.ClassicStarter) {
    return undefined;
  } else if (steps.some((step: Step) => step.type === StepType.OAUTH)) {
    return oauthTriggerRestriction;
  }

  return undefined;
};

export const salesforceActionFeatureValidator: WorkflowFeatureValidator = (
  steps: Step[],
  _workflow: Workflow,
  billingPlan: BillingPlan,
) => {
  if (billingPlan !== BillingPlan.ClassicFree && billingPlan !== BillingPlan.ClassicStarter) {
    return undefined;
  } else if (
    steps.some(
      (step: Step) =>
        step.type === StepType.ACTION && step.parameters.actionType === Action.SALESFORCE,
    )
  ) {
    return salesforceActionRestriction;
  }

  return undefined;
};

export const ACTIVE_FEATURE_RESTRICTIONS: WorkflowFeatureValidator[] = [
  eventTriggerFeatureValidator,
  oauthTriggerFeatureValidator,
  salesforceActionFeatureValidator,
];
export const checkAllFeatureRestrictions = (
  steps: Step[],
  workflow: Workflow,
  billingPlan: BillingPlan,
): WorkflowFeatureRestriction[] => {
  return ACTIVE_FEATURE_RESTRICTIONS.map((validator: WorkflowFeatureValidator) =>
    validator(steps, workflow, billingPlan),
  ).filter(
    (result: WorkflowFeatureRestriction | undefined): result is WorkflowFeatureRestriction =>
      result !== undefined,
  );
};

export default checkAllFeatureRestrictions;
