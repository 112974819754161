import { combineReducers } from 'redux';

import { EntitiesContainerState } from '../../types';

import * as connectCredentials from './connectCredentials';
import * as connectEvent from './connectEvents';
import * as connectWorkflows from './connectWorkflows';
import * as credentials from './credentials';
import * as customIntegrations from './customIntegrations';
import * as decryptedCredentials from './decryptedCredential';
import * as destinations from './destinations';
import * as integrationConfig from './integrationConfigs';
import * as integration from './integrations';
import * as invite from './invite';
import * as migrations from './migrations';
import * as organizations from './organizations';
import * as persona from './personas';
import * as projects from './project';
import * as secrets from './secrets';
import * as signingKeys from './signingKeys';
import * as steps from './step';
import * as stepExecutions from './stepExecution';
import * as teamMembers from './teamMembers';
import * as teams from './teams';
import * as variables from './variables';
import * as version from './version';
import * as workflows from './workflow';
import * as workflowLogs from './workflowLog';
import * as workflowTag from './workflowTag';

export const initialState: EntitiesContainerState = {
  connectCredentials: connectCredentials.initialState,
  credentials: credentials.initialState,
  migrations: migrations.initialState,
  projects: projects.initialState,
  steps: steps.initialState,
  stepExecutions: stepExecutions.initialState,
  variables: variables.initialState,
  workflows: workflows.initialState,
  workflowLogs: workflowLogs.initialState,
  secrets: secrets.initialState,
  teams: teams.initialState,
  teamMembers: teamMembers.initialState,
  invites: invite.initialState,
  versions: version.initialState,
  signingKeys: signingKeys.initialState,
  integrations: integration.initialState,
  integrationConfigs: integrationConfig.initialState,
  decryptedCredentials: decryptedCredentials.initialState,
  workflowTag: workflowTag.initialState,
  connectEvent: connectEvent.initialState,
  connectWorkflows: connectWorkflows.initialState,
  organizations: organizations.initialState,
  customIntegrations: customIntegrations.initialState,
  personas: persona.initialState,
  destinations: destinations.initialState,
};

export const handler = combineReducers({
  connectCredentials: connectCredentials.handler,
  credentials: credentials.handler,
  migrations: migrations.handler,
  projects: projects.handler,
  steps: steps.handler,
  stepExecutions: stepExecutions.handler,
  variables: variables.handler,
  workflows: workflows.handler,
  workflowLogs: workflowLogs.handler,
  secrets: secrets.handler,
  teams: teams.handler,
  teamMembers: teamMembers.handler,
  invites: invite.handler,
  versions: version.handler,
  signingKeys: signingKeys.handler,
  integrations: integration.handler,
  integrationConfigs: integrationConfig.handler,
  decryptedCredentials: decryptedCredentials.handler,
  workflowTag: workflowTag.handler,
  connectEvent: connectEvent.handler,
  connectWorkflows: connectWorkflows.handler,
  organizations: organizations.handler,
  customIntegrations: customIntegrations.handler,
  personas: persona.handler,
  destinations: destinations.handler,
});
