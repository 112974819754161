import { createTestConnectionButton } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import { Action } from '@shared/types/sdk/actions';

import { ActionConfig, CredentialKeys } from '../configs';
import iconSvg from '../configs/icon.svg';

import { companyIdInput, userIdInput, userPasswordInput } from './inputs';

const authConfig: ActionConfig = {
  actionType: Action.SAGE_INTACCT,
  provider: ProviderType.SAGE_INTACCT,
  scheme: AuthenticationScheme.BASIC,
  name: 'Choose your Sage Intacct account',
  icon: iconSvg,
  description: "Connect to your users' Sage Intacct accounts",
  sidebarSections: [
    {
      inputs: [companyIdInput, userIdInput, userPasswordInput],
      buttons: [createTestConnectionButton(Action.SAGE_INTACCT, Object.values(CredentialKeys))],
    },
  ],
};

export default authConfig;
