import React from 'react';

import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  AuthenticationScheme,
  ProviderType,
} from '@shared/entities/sdk/credential/credential.interface';
import {
  Action,
  ActionConfig,
  ActionStepParameters,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import iconSvg from '../configs/icon.svg';
import {
  FinancialStatus,
  FulFillmentStatus,
  InventoryBehaviour,
  InventoryPolicy,
} from '../shared/types';

import {
  abandonedCartStatusInput,
  createdAfterIdInput,
  createdWithInLastInput,
  statusInput,
} from './connect';
import { getCustomerProperties } from './sources';

export const authConfig: ActionConfig = {
  actionType: Action.SHOPIFY,
  provider: ProviderType.SHOPIFY,
  scheme: AuthenticationScheme.OAUTH,
  name: 'Connect your Shopify account',
  icon: iconSvg,
  description: 'Add Shopify to Paragon',
  sidebarSections: [
    {
      inputs: [
        {
          id: 'SHOP_NAME',
          title: 'Enter your Shopify username',
          subtitle: 'Enter your Shopify username, e.g. https://<username>.myshopify.com.',
          placeholder: 'username',
          type: SidebarInputType.ValueText,
        },
      ],
      buttons: [],
    },
  ],
};

const createdAtMinInput: SidebarInput = {
  id: 'createdAtMin',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Created after',
  subtitle: 'Only return customers created after the provided date (ISO or Unix timestamp).',
  placeholder: '2014-04-25T16:15:47-04:00',
  required: false,
};

const createdAtMaxInput: SidebarInput = {
  id: 'createdAtMax',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Created before',
  placeholder: '2014-04-25T16:15:47-04:00',
  required: false,
};

const updatedAtMinInput: SidebarInput = {
  id: 'updatedAtMin',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Updated after',
  placeholder: '2014-04-25T16:15:47-04:00',
  required: false,
};

const updatedAtMaxInput: SidebarInput = {
  id: 'updatedAtMax',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Updated before',
  placeholder: '2014-04-25T16:15:47-04:00',
  required: false,
};

const subtitleForMinMaxDateInput = (placeholderString: string): string =>
  `Only return ${placeholderString} the provided date (ISO or Unix timestamp).`;

const limitInput: SidebarInput = {
  id: 'limit',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Limit results',
  placeholder: '',
  required: false,
};

const subtitleForLimitInput = (objectType: string) =>
  `Limit the maximum number of ${objectType} to return. Defaults to 50 if left blank.`;

const customerFirstNameInput: SidebarInput = {
  id: 'firstName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'First name',
  required: true,
};

const customerLastNameInput: SidebarInput = {
  id: 'lastName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Last name',
  required: true,
};

const emailInput: SidebarInput = {
  id: 'email',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Email address',
  required: true,
};

const companyNameInput: SidebarInput = {
  id: 'company',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Company name',
  required: false,
};

const streetAddressLine1Input: SidebarInput = {
  id: 'streetAddressLine1',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Street address',
  required: false,
};

const streetAddressLine2Input: SidebarInput = {
  id: 'streetAddressLine2',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Street address line 2',
  required: false,
};

const cityNameInput: SidebarInput = {
  id: 'city',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'City',
  required: false,
};

const stateNameInput: SidebarInput = {
  id: 'state',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'State or province code',
  required: false,
};

const countryNameInput: SidebarInput = {
  id: 'country',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Country',
  required: false,
};

const zipCodeInput: SidebarInput = {
  id: 'zipCode',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Zip code',
  required: false,
};

const phoneInput: SidebarInput = {
  id: 'phone',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Phone',
  required: false,
};

const noteInput: SidebarInput = {
  id: 'note',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Note',
  required: false,
};

const sendEmailInviteInput: SidebarInput = {
  id: 'sendEmailInvite',
  type: SidebarInputType.BooleanInput,
  title: 'Send email invite',
  required: false,
};

const metafieldsInput: SidebarInput = {
  id: 'metafields',
  type: SidebarInputType.Code,
  title: 'Metafields',
  language: 'json',
  placeholder: `[
{
"key": "new",
"value": "newvalue",
"value_type": "string",
"namespace": "global"
}
]`,
  useLightTheme: true,
  required: false,
};

const lineItemsInput: SidebarInput = {
  id: 'lineItems',
  type: SidebarInputType.Code,
  title: 'Line items',
  subtitle: (
    <>
      View{' '}
      <a
        href="https://shopify.dev/docs/themes/liquid/reference/objects/line_item#line_item-properties"
        target="_blank"
        rel="noreferrer"
      >
        Shopify’s docs
      </a>{' '}
      for a list of line item properties.
    </>
  ),
  language: 'json',
  placeholder: `[
{
"title": "Big Brown Bear Boots",
"price": 74.99,
"quantity": 3,
"variant_id": 447654529
}
]`,
  useLightTheme: true,
  required: true,
};

const fullFillmentStatusInput: SidebarInput = {
  id: 'fulfillmentStatus',
  type: SidebarInputType.Enum,
  getValues: () => [
    { label: 'FulFilled', value: FulFillmentStatus.FULFILLED },
    { label: 'Null', value: FulFillmentStatus.NULL },
    { label: 'Partial', value: FulFillmentStatus.PARTIAL },
    { label: 'Restocked', value: FulFillmentStatus.RESTOCKED },
  ],
  title: 'Fulfillment status',
  required: false,
};

const financialStatusInput: SidebarInput = {
  id: 'financialStatus',
  type: SidebarInputType.Enum,
  title: 'Financial status',
  getValues: () => [
    { label: 'Pending', value: FinancialStatus.PENDING },
    { label: 'Authorized', value: FinancialStatus.AUTHORIZED },
    { label: 'Partially paid', value: FinancialStatus.PARTIALLY_PAID },
    { label: 'Paid', value: FinancialStatus.PAID },
    { label: 'Partially refunded', value: FinancialStatus.PARTIALLY_REFUNDED },
    { label: 'Refunded', value: FinancialStatus.REFUNDED },
    { label: 'Voided', value: FinancialStatus.VOIDED },
  ],
  required: false,
};

const inventoryBehaviourInput: SidebarInput = {
  id: 'inventoryBehaviour',
  type: SidebarInputType.Enum,
  title: 'Inventory behavior',
  getValues: () => [
    { label: 'Bypass', value: InventoryBehaviour.BYPASS },
    { label: 'Decrement ignoring policy', value: InventoryBehaviour.DECREMENT_IGNORING_POLICY },
    { label: 'Decrement obeying policy', value: InventoryBehaviour.DECREMENT_OBEYING_POLICY },
  ],
  required: false,
};

const inventoryPolicyInput: SidebarInput = {
  id: 'inventoryPolicy',
  type: SidebarInputType.Enum,
  title: 'Inventory policy',
  getValues: () => [
    { label: 'Deny', value: InventoryPolicy.DENY },
    { label: 'Continue', value: InventoryPolicy.CONTINUE },
  ],
  required: false,
};

const productTitleInput: SidebarInput = {
  id: 'title',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Title',
  required: true,
};

const productTypeInput: SidebarInput = {
  id: 'productType',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Product Type',
  required: true,
};
const productVendorInput: SidebarInput = {
  id: 'vendor',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Vendor',
  required: true,
};

const productDescriptionInput: SidebarInput = {
  id: 'productDescription',
  type: SidebarInputType.TextArea,
  title: 'Product Description',
  subtitle: 'Accepts plain text or HTML.',
  required: false,
};

const tagsInput: SidebarInput = {
  id: 'tags',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Tags',
  subtitle: 'Accepts an array or comma-separated list of tags.',
  required: false,
};

const productPriceInput: SidebarInput = {
  id: 'price',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Price',
  required: false,
};

const imageUrlInput: SidebarInput = {
  id: 'imageUrl',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Image URL',
  required: false,
};

const isPublishedInput: SidebarInput = {
  id: 'isPublished',
  type: SidebarInputType.BooleanInput,
  title: 'Is published',
  required: false,
};

const publishToPointToSaleInput: SidebarInput = {
  id: 'publishToPointToSale',
  type: SidebarInputType.BooleanInput,
  title: 'Publish to point of sale',
  required: false,
};

const config: ActionConfig = {
  actionType: Action.SHOPIFY,
  name: 'Shopify',
  description: 'Find, create, and update records in Shopify.',
  icon: iconSvg,
  provider: ProviderType.SHOPIFY,
  sidebarSections: [
    (): SidebarSection => ({
      inputs: [
        {
          id: 'auth',
          title: 'Choose your Shopify account',
          placeholder: 'connect to Shopify account',
          type: SidebarInputType.Auth,
          config: authConfig,
        },
      ],
    }),
    (parameters: ActionStepParameters): SidebarSection =>
      parameters.credentials?.length
        ? {
            inputs: [
              {
                id: 'intent',
                title: 'Choose an action',
                type: SidebarInputType.Intent,
                values: [
                  {
                    title: 'Customers',
                    items: [
                      {
                        value: Intent.GET_CUSTOMERS,
                        label: 'Get customers',
                      },
                      {
                        value: Intent.SEARCH_CUSTOMERS,
                        label: 'Search customers',
                      },
                      {
                        value: Intent.CREATE_CUSTOMER,
                        label: 'Create customer',
                      },
                      {
                        value: Intent.UPDATE_CUSTOMER,
                        label: 'Update customer',
                      },
                    ],
                  },
                  {
                    title: 'Orders',
                    items: [
                      {
                        value: Intent.GET_ORDERS,
                        label: 'Get orders',
                      },
                      {
                        value: Intent.CREATE_ORDER,
                        label: 'Create order',
                      },
                      {
                        value: Intent.UPDATE_ORDER,
                        label: 'Update order',
                      },
                      {
                        value: Intent.GET_ABANDONED_CARTS,
                        label: 'Get abandoned carts',
                      },
                    ],
                  },
                  {
                    title: 'Products',
                    items: [
                      {
                        value: Intent.GET_PRODUCTS,
                        label: 'Get products',
                      },
                      {
                        value: Intent.CREATE_PRODUCT,
                        label: 'Create product',
                      },
                      {
                        value: Intent.UPDATE_PRODUCT,
                        label: 'Update product',
                      },
                    ],
                  },
                ],
              },
            ],
          }
        : {
            inputs: [],
          },
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.GET_CUSTOMERS:
          return {
            inputs: [
              {
                id: 'customerIds',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Customer IDs',
                subtitle:
                  'Only return customers matching the IDs provided. You can enter an array or a comma-separated list of IDs.',
                placeholder: '207119551, 207119552',
                required: false,
              },
              {
                ...createdAtMinInput,
                ...{ subtitle: subtitleForMinMaxDateInput('customers created after') },
              },
              {
                ...createdAtMaxInput,
                ...{ subtitle: subtitleForMinMaxDateInput('customers created before') },
              },
              {
                ...updatedAtMinInput,
                ...{ subtitle: subtitleForMinMaxDateInput('customers updated after') },
              },
              {
                ...updatedAtMaxInput,
                ...{ subtitle: subtitleForMinMaxDateInput('customers updated before') },
              },
              { ...limitInput, ...{ subtitle: subtitleForLimitInput('customers') } },
            ],
          };
        case Intent.SEARCH_CUSTOMERS:
          return {
            inputs: [
              {
                id: 'filterFormula',
                type: SidebarInputType.DynamicConditional,
                required: false,
                title: 'Filter search',
                subtitle: 'Search for customers that match specified filters.',
                placeholder: 'filter by field',
                source: getCustomerProperties,
                getValues: getValuesByCacheKey,
                supportedOperators: [
                  Operator.StringContains,
                  Operator.StringDoesNotContain,
                  Operator.StringStartsWith,
                  Operator.StringDoesNotStartWith,
                  Operator.NumberLessThan,
                  Operator.NumberEquals,
                  Operator.NumberGreaterThan,
                  Operator.NumberDoesNotEqual,
                  Operator.NumberGreaterThanOrEqualTo,
                  Operator.NumberLessThanOrEqualTo,
                  Operator.BooleanTrue,
                  Operator.BooleanFalse,
                  Operator.IsNotNull,
                  Operator.IsNull,
                ],
              },
              { ...limitInput, ...{ subtitle: subtitleForLimitInput('customers') } },
            ],
          };
        case Intent.CREATE_CUSTOMER:
          return {
            inputs: [
              customerFirstNameInput,
              customerLastNameInput,
              emailInput,
              companyNameInput,
              streetAddressLine1Input,
              streetAddressLine2Input,
              cityNameInput,
              stateNameInput,
              countryNameInput,
              zipCodeInput,
              phoneInput,
              tagsInput,
              noteInput,
              sendEmailInviteInput,
              metafieldsInput,
            ],
          };
        case Intent.UPDATE_CUSTOMER:
          return {
            inputs: [
              {
                id: 'customerId',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Customer ID',
                required: true,
              },
              { ...customerFirstNameInput, ...{ required: false } },
              { ...customerLastNameInput, ...{ required: false } },
              { ...emailInput, ...{ required: false } },
              companyNameInput,
              streetAddressLine1Input,
              streetAddressLine2Input,
              cityNameInput,
              stateNameInput,
              countryNameInput,
              zipCodeInput,
              phoneInput,
              tagsInput,
              noteInput,
              sendEmailInviteInput,
              metafieldsInput,
            ],
          };
        case Intent.GET_ORDERS:
          return {
            inputs: [
              {
                id: 'orderIds',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Order IDs',
                subtitle:
                  'Only return orders matching the IDs provided. You can enter an array or a comma-separated list of IDs.',
                placeholder: '450789469, 450789470,',
                required: false,
              },
              {
                ...createdAtMinInput,
                ...{ subtitle: subtitleForMinMaxDateInput('orders created after') },
              },
              {
                ...createdAtMaxInput,
                ...{ subtitle: subtitleForMinMaxDateInput('orders created before') },
              },
              {
                ...updatedAtMinInput,
                ...{ subtitle: subtitleForMinMaxDateInput('orders updated after') },
              },
              {
                ...updatedAtMaxInput,
                ...{ subtitle: subtitleForMinMaxDateInput('orders updated before') },
              },
              { ...limitInput, ...{ subtitle: subtitleForLimitInput('orders') } },
            ],
          };
        case Intent.CREATE_ORDER:
          return {
            inputs: [
              { ...emailInput, ...{ title: 'Customer email' } },
              lineItemsInput,
              {
                id: 'sendReceipt',
                type: SidebarInputType.BooleanInput,
                title: 'Send receipt',
                required: false,
              },
              fullFillmentStatusInput,
              financialStatusInput,
              inventoryBehaviourInput,
              noteInput,
            ],
          };
        case Intent.UPDATE_ORDER:
          return {
            inputs: [
              {
                id: 'orderId',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Order ID',
                required: true,
              },
              { ...emailInput, ...{ title: 'Customer email', required: false } },
              { ...lineItemsInput, ...{ required: false } },
              {
                id: 'sendReceipt',
                type: SidebarInputType.BooleanInput,
                title: 'Send receipt',
                required: false,
              },
              fullFillmentStatusInput,
              financialStatusInput,
              inventoryBehaviourInput,
              noteInput,
            ],
          };
        case Intent.GET_ABANDONED_CARTS:
          return {
            inputs: [
              createdWithInLastInput,
              createdAfterIdInput,
              abandonedCartStatusInput,
              {
                ...createdAtMinInput,
                ...{ subtitle: subtitleForMinMaxDateInput('Created After') },
              },
              {
                ...createdAtMaxInput,
                ...{ subtitle: subtitleForMinMaxDateInput('Created Before') },
              },
              { ...limitInput, ...{ subtitle: subtitleForLimitInput('abandoned carts') } },
            ],
          };
        case Intent.GET_PRODUCTS:
          return {
            inputs: [
              {
                id: 'productIds',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Product IDs',
                subtitle:
                  'Only return products matching the IDs provided. You can enter an array or a comma-separated list of IDs.',
                placeholder: '632910392, 632910393',
                required: false,
              },
              { ...productTitleInput, ...{ required: false } },
              { ...productTypeInput, ...{ required: false } },
              { ...productVendorInput, ...{ required: false } },
              statusInput,
              {
                ...createdAtMinInput,
                ...{ subtitle: subtitleForMinMaxDateInput('products created after') },
              },
              {
                ...createdAtMaxInput,
                ...{ subtitle: subtitleForMinMaxDateInput('products created before') },
              },
              {
                ...updatedAtMinInput,
                ...{ subtitle: subtitleForMinMaxDateInput('products updated after') },
              },
              {
                ...updatedAtMaxInput,
                ...{ subtitle: subtitleForMinMaxDateInput('products updated before') },
              },
              { ...limitInput, ...{ subtitle: subtitleForLimitInput('products') } },
            ],
          };
        case Intent.CREATE_PRODUCT:
          return {
            inputs: [
              productTitleInput,
              productTypeInput,
              productVendorInput,
              productDescriptionInput,
              tagsInput,
              productPriceInput,
              inventoryPolicyInput,
              imageUrlInput,
              isPublishedInput,
              publishToPointToSaleInput,
            ],
          };
        case Intent.UPDATE_PRODUCT:
          return {
            inputs: [
              {
                id: 'productId',
                type: SidebarInputType.TextArea,
                lines: 1,
                title: 'Product ID',
                required: true,
              },
              { ...productTitleInput, ...{ required: false } },
              { ...productTypeInput, ...{ required: false } },
              { ...productVendorInput, ...{ required: false } },
              productDescriptionInput,
              tagsInput,
              productPriceInput,
              inventoryPolicyInput,
              imageUrlInput,
              isPublishedInput,
              publishToPointToSaleInput,
            ],
          };
        default:
          return {
            inputs: [],
          };
      }
    },
  ],
};

export default config;
