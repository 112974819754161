import { ActionResponse } from '@shared/actions/sdk/utils';
import {
  Action,
  ActionStepParameters,
  DataSourceType,
  DynamicDataSource,
  EnumInputValue,
} from '@shared/types/sdk/actions';

import { Intent } from '../configs';
import { OpportunityStage, Sequence } from '../shared/types';

export const getOpportunityStages: DynamicDataSource<EnumInputValue[]> = {
  type: DataSourceType.DYNAMIC,
  title: 'Opportunity Stage',
  subtitle: 'Stage that an opportunity should be assigned to',
  cacheKey: 'opportunityStages',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<OpportunityStage[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((opportunityStage: OpportunityStage) => ({
        label: opportunityStage.attributes.name,
        value: opportunityStage.id,
      }))
    );
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_OPPORTUNITY_STAGES,
      actionType: Action.OUTREACH,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};

export const getSequences: DynamicDataSource<EnumInputValue[]> = {
  type: DataSourceType.DYNAMIC,
  title: 'Sequence',
  subtitle: 'Sequence that a prospect should be added to',
  cacheKey: 'sequences',
  refreshDependencies: [],
  mapRefreshToValues: (response: ActionResponse<Sequence[]>): EnumInputValue[] => {
    return (
      response?.result &&
      response.result.output.map((sequence: Sequence) => ({
        label: sequence.attributes.name,
        value: sequence.id,
      }))
    );
  },
  getRefreshActionParameters: (options: ActionStepParameters): ActionStepParameters => {
    return {
      intent: Intent.GET_SEQUENCES,
      actionType: Action.OUTREACH,
      credentials: options.credentials,
      actionParameters: [],
    };
  },
};
