import { ProviderType } from '@shared/entities/sdk/credential/credential.interface';
import { Action, ActionStepParameters, SidebarSection } from '@shared/types/sdk/actions';

import { ActionConfig, Intent } from '../configs';
import iconSvg from '../configs/icon.svg';

import {
  boardInput,
  cardIdInput,
  createUpdateCardFieldInputs,
  intentTypeInput,
  searchQuery,
} from './common';

const connectConfig: ActionConfig = {
  actionType: Action.TRELLO,
  name: 'Trello',
  description: 'Connect your Trello account and manage your boards, list, and cards.',
  icon: iconSvg,
  provider: ProviderType.TRELLO,
  sidebarSections: [
    intentTypeInput,
    (parameters: ActionStepParameters): SidebarSection => {
      switch (parameters.intent) {
        case Intent.SEARCH_CARDS: {
          return { inputs: [searchQuery] };
        }

        case Intent.GET_CARDS_IN_BOARD: {
          return {
            inputs: [boardInput],
          };
        }

        case Intent.CREATE_CARD:
        case Intent.UPDATE_CARD:
          return {
            inputs: [
              ...(parameters.intent === Intent.CREATE_CARD
                ? createUpdateCardFieldInputs(true)
                : createUpdateCardFieldInputs(true, true)),
            ],
          };

        case Intent.DELETE_CARD: {
          return { inputs: [{ ...cardIdInput, subtitle: 'The ID of the card to delete.' }] };
        }

        case Intent.SEARCH_BOARDS: {
          return {
            inputs: [
              {
                ...searchQuery,
                subtitle: 'Search for boards by a phrase in their title or other properties.',
              },
            ],
          };
        }

        case Intent.GET_LISTS_IN_BOARD: {
          return {
            inputs: [
              {
                ...boardInput,
                subtitle:
                  'Select a Board to get lists from. Use Connect Portal Workflow Settings to allow users to select a Trello Board.',
              },
            ],
          };
        }

        default:
          return { inputs: [] };
      }
    },
  ],
};

export default connectConfig;
