import React from 'react';

import { SidebarInput, SidebarInputType, SidebarSection } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import { RecordType } from '../shared';

export const vendorNumberInput: SidebarInput = {
  id: 'vendorNumber',
  title: 'Vendor Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
};

export const vendorNameInput = (intent: Intent): SidebarInput => ({
  id: 'vendorName',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: intent === Intent.CREATE_PURCHASE_INVOICE,
});

export const purchaseInvoiceVendorIdInput: SidebarInput = {
  id: 'purchaseInvoiceVendorId',
  title: 'Vendor Id',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle: 'The Id of the vendor who delivers the products.',
};

export const vendorEmailInput: SidebarInput = {
  id: 'vendorEmail',
  title: 'Email',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: 'primary@vendor.com',
};

export const recordTypeInput = (overrideValue: string): SidebarInput => ({
  id: 'recordType',
  title: 'RecordType',
  type: SidebarInputType.Text,
  required: false,
  overrideValue,
  hidden: true,
});

export const taxRegNumberInput: SidebarInput = {
  id: 'taxRegNumber',
  title: 'Tax Registration Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: "The vendor's tax registration number.",
};

export const paymentTermsCodeInput: SidebarInput = {
  id: 'paymentTermsCode',
  title: 'Payment Terms Code',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '30 DAYS',
  subtitle: 'The code of the associated Payment Terms for this vendor.',
};

export const vendorInvoiceNumberInput: SidebarInput = {
  id: 'vendorInvoiceNumber',
  title: 'Vendor Invoice Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  subtitle: 'An internal number for the invoice.',
};

export const invoiceDateInput: SidebarInput = {
  id: 'invoiceDate',
  title: 'Invoice Date',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '2022-01-01',
  subtitle: 'Accepts ISO 8601 date format.',
};

export const addressLine1Input: SidebarInput = {
  id: 'addressLine1',
  title: 'Address Line 1',
  type: SidebarInputType.Text,
  required: false,
};

export const addressLine2Input: SidebarInput = {
  id: 'addressLine2',
  title: 'Address Line 2',
  type: SidebarInputType.Text,
  required: false,
};

export const cityInput: SidebarInput = {
  id: 'city',
  title: 'City',
  type: SidebarInputType.Text,
  required: false,
};

export const stateInput: SidebarInput = {
  id: 'state',
  title: 'State',
  type: SidebarInputType.Text,
  required: false,
  placeholder: 'Use state or province.',
};

export const zipInput: SidebarInput = {
  id: 'zip',
  title: 'ZIP',
  type: SidebarInputType.Text,
  required: false,
  placeholder: 'Use ZIP or postal code, if applicable.',
};

export const parentInvoiceIdInput: SidebarInput = {
  id: 'parentInvoiceId',
  title: 'Parent Purchase Invoice Id',
  type: SidebarInputType.Text,
  required: true,
};

export const countryInput: SidebarInput = {
  id: 'country',
  title: 'Country',
  type: SidebarInputType.Text,
  required: false,
};

export const vendorsAdditionalFieldsInput: SidebarInput = {
  id: 'vendorsAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.Code,
  lines: 5,
  required: false,
  language: 'json',
  useLightTheme: true,
  placeholder: `{
  "phoneNumber": "string",
  "website": "string",
  "currencyCode": "string",
  "irs1099Code": "string",
  "taxLiable": "boolean",
  "balance": "decimal",
  "lastModifiedDateTime": "datetime"
}`,
  subtitle: (
    <>
      Specify additional fields to include on this vendor as JSON. For example, you may specify a
      different billing address here.{' '}
      <a
        href="https://docs.microsoft.com/en-us/dynamics365/business-central/dev-itpro/api-reference/v2.0/resources/dynamics_vendor"
        target="_blank"
        rel="noreferrer"
      >
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
};

export const vendorIdInput = (subtitle: string): SidebarInput => ({
  id: 'vendorId',
  title: 'Vendor ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle,
});

export const purchaseInvoiceIdInput = (subtitle: string): SidebarInput => ({
  id: 'purchaseInvoiceId',
  title: 'Purchase Invoice ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle,
});

export const paymentTermsIdInput = (subtitle: string): SidebarInput => ({
  id: 'paymentTermsId',
  title: 'Payment Term ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle,
});

export const taxGroupIdInput = (subtitle: string): SidebarInput => ({
  id: 'taxGroupId',
  title: 'Tax Group ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle,
});

export const purchaseInvoiceLineIdInput = (subtitle: string): SidebarInput => ({
  id: 'purchaseInvoiceLineId',
  title: 'Purchase Invoice Line ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  subtitle,
});

export const postingDateInput: SidebarInput = {
  id: 'postingDate',
  title: 'Posting Date',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '2022-01-02',
  subtitle: 'Accepts ISO 8601 date format.',
};

export const dueDateInput: SidebarInput = {
  id: 'dueDate',
  title: 'Due Date',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '2022-02-03',
  subtitle: 'Accepts ISO 8601 date format.',
};

export const invoiceStatusInput: SidebarInput = {
  id: 'invoiceStatus',
  title: 'Status',
  type: SidebarInputType.EditableEnum,
  required: false,
  subtitle: 'The status of the Purchase Invoice.',
  getValues: () => [
    { label: 'Draft', value: 'Draft' },
    { label: 'In Review', value: 'In Review' },
    { label: 'Open', value: 'Open' },
    { label: 'Paid', value: 'Paid' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Corrective', value: 'Corrective' },
  ],
};

export const lineTypeInput: SidebarInput = {
  id: 'lineType',
  title: 'Line Type',
  type: SidebarInputType.EditableEnum,
  required: true,
  getValues: () => [
    { label: 'Item', value: 'Item' },
    { label: 'Resource', value: 'Resource' },
    { label: 'Comment', value: 'Comment' },
    { label: 'Account', value: 'Account' },
    { label: 'Fixed Asset', value: 'Fixed Asset' },
    { label: 'Charge', value: 'Charge' },
  ],
};

export const taxGroupTypeInput: SidebarInput = {
  id: 'taxGroupType',
  title: 'Type',
  type: SidebarInputType.EditableEnum,
  required: false,
  getValues: () => [
    { label: 'Sales Tax', value: 'Sales Tax' },
    { label: 'VAT', value: 'VAT' },
  ],
};

export const resourceNumberInput: SidebarInput = {
  id: 'resourceNumber',
  title: 'Number',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: '1896-S',
  subtitle:
    'Specify the number of the item, account, resource, charge, or fixed asset associated with the Purchase Invoice Line. Items and resources will be created if they do not exist.',
};

export const lineDescriptionInput: SidebarInput = {
  id: 'lineDescription',
  title: 'Description',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: 'ATHENS Desk',
};

export const quantityInput: SidebarInput = {
  id: 'quantity',
  title: 'Quantity',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: '20',
};

export const unitCostInput: SidebarInput = {
  id: 'unitCost',
  title: 'Unit Cost',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: '10.99',
};

export const accountIdInput: SidebarInput = {
  id: 'accountId',
  title: 'Account ID',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: '00000000-0000-0000-0000-000000000000',
  subtitle: 'Specify the ID of the Account that the Purchase Invoice Line is related to.',
};

export const measureCodeUnitInput: SidebarInput = {
  id: 'measureCodeUnit',
  title: 'Unit of Measure Code',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: 'PCS',
};

export const textAreaCodeInput: SidebarInput = {
  id: 'textAreaCode',
  title: 'Tax Area Code',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: 'CHICAGO, IL',
  subtitle: 'The Tax Area used to calculate and post sales tax. ',
};

export const taxGroupNameInput: SidebarInput = {
  id: 'taxGroupName',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: true,
  placeholder: 'Taxable Olympic Furniture',
};

export const taxPercentInput: SidebarInput = {
  id: 'taxPercent',
  title: 'Tax Percent',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '6.00004',
};

export const paymentTermCodeInput: SidebarInput = {
  id: 'paymentTermCode',
  title: 'Payment Term Code',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '10 DAYS',
  subtitle: 'The code to identify this set of payment terms.',
};

export const paymentTermNameInput: SidebarInput = {
  id: 'paymentTermName',
  title: 'Name',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: 'Net 10 days',
  subtitle: 'This name will appear on all sales documents for the payment term.',
};

export const dueDateCalculationInput: SidebarInput = {
  id: 'dueDateCalculation',
  title: 'Due Date Calculation',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '10D',
};

export const discountDateCalculationInput: SidebarInput = {
  id: 'discountDateCalculation',
  title: 'Discount Date Calculation',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '8D',
};

export const discountPercentInput: SidebarInput = {
  id: 'discountPercent',
  title: 'Discount Percent',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: '2',
  subtitle:
    'Specify the percentage of the invoice amount (amount including tax is the default setting) that will constitute a possible payment discount.',
};

export const taxGroupCodeInput: SidebarInput = {
  id: 'taxGroupCode',
  title: 'Tax Group Code',
  type: SidebarInputType.TextArea,
  lines: 1,
  required: false,
  placeholder: 'FURNITURE',
};

export const purchaseInvoiceLineAdditionalFields: SidebarInput = {
  id: 'purchaseInvoiceLineAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.Code,
  lines: 4,
  required: false,
  language: 'json',
  useLightTheme: true,
  placeholder: `{
  documentId: "5d115c9c-44e3-ea11-bb43-000d3a2feca1",
}`,
  subtitle: (
    <>
      Specify additional fields to include on this Purchase Invoice Line as JSON.{' '}
      <a
        href="https://docs.microsoft.com/en-us/dynamics365/business-central/dev-itpro/api-reference/v2.0/resources/dynamics_purchaseinvoiceline"
        target="_blank"
        rel="noreferrer"
      >
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
};

const supportedOperators: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.NumberEquals,
  Operator.NumberGreaterThan,
  Operator.NumberLessThan,
  Operator.NumberGreaterThanOrEqualTo,
  Operator.NumberDoesNotEqual,
  Operator.DateTimeEquals,
  Operator.DateTimeBefore,
  Operator.DateTimeAfter,
];

export const vendorFilterInputOauth: SidebarInput = {
  id: 'vendorsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Vendor ID', value: 'id' },
    { label: 'Vendor Name', value: 'displayName' },
    { label: 'Email', value: 'email' },
    { label: 'Tax Registration Number', value: 'taxRegistrationNumber' },
    { label: 'Payment Terms Id', value: 'paymentTermsId' },
  ],
};

export const purchaseInvoiceFilterInputOauth: SidebarInput = {
  id: 'purchaseInvoicesFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Vendor Name', value: 'vendorName' },
    { label: 'Vendor Invoice Number', value: 'vendorInvoiceNumber' },
    { label: 'Due Date', value: 'dueDate' },
    { label: 'Posting Date', value: 'postingDate' },
    { label: 'Status', value: 'status' },
  ],
};

export const purchaseInvoiceLineFilterInputOauth: SidebarInput = {
  id: 'purchaseInvoiceLinesFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Tax Group Code', value: 'taxCode' },
    { label: 'Description', value: 'description' },
    { label: 'Type', value: 'lineType' },
  ],
};

export const taxGroupFilterInputOauth: SidebarInput = {
  id: 'taxGroupsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Tax Group Code', value: 'code' },
    { label: 'Name', value: 'displayName' },
    { label: 'Type', value: 'taxType' },
  ],
};

export const paymentTermsFilterInputOauth: SidebarInput = {
  id: 'paymentTermsFilterFormula',
  type: SidebarInputType.Conditional,
  required: false,
  title: 'Filter search',
  subtitle: 'Search for records that match specified filters.',
  placeholder: 'field',
  supportedOperators: supportedOperators,
  getValues: () => [
    { label: 'Payment Term Code', value: 'code' },
    { label: 'Name', value: 'displayName' },
    { label: 'Discount Percent', value: 'discountPercent' },
  ],
};

export const idInputCollection: { [key in Intent]?: SidebarInput } = {
  [Intent.UPDATE_VENDOR]: vendorIdInput('Specify the record ID of the Vendor to update.'),
  [Intent.GET_VENDOR_BY_ID]: vendorIdInput('Specify the record ID of the Vendor to fetch.'),
  [Intent.DELETE_VENDOR]: vendorIdInput('Specify the record ID of the Vendor to delete.'),
  [Intent.UPDATE_PURCHASE_INVOICE]: purchaseInvoiceIdInput(
    'Specify the record ID of the Purchase Invoice to update.',
  ),
  [Intent.POST_PURCHASE_INVOICE]: purchaseInvoiceIdInput(
    'Specify the record ID of the Purchase Invoice to post to the ledger.',
  ),
  [Intent.GET_PURCHASE_INVOICE_BY_ID]: purchaseInvoiceIdInput(
    'Specify the record ID of the Purchase Invoice to fetch.',
  ),
  [Intent.DELETE_PURCHASE_INVOICE]: purchaseInvoiceIdInput(
    'Specify the record ID of the Purchase Invoice to delete.',
  ),
  [Intent.UPDATE_PURCHASE_INVOICE_LINE_ITEM]: purchaseInvoiceLineIdInput(
    'The record ID of the Purchase Invoice Line to update.',
  ),
  [Intent.GET_PURCHASE_INVOICE_LINES]: purchaseInvoiceIdInput(
    'Specify the record ID of the Purchase Invoice ID to fetch all associated Purchase Invoice Lines.',
  ),
  [Intent.GET_PURCHASE_INVOICE_LINE_ITEM_BY_ID]: purchaseInvoiceLineIdInput(
    'Specify the record ID of the Purchase Invoice Line to fetch.',
  ),
  [Intent.DELETE_PURCHASE_INVOICE_LINE_ITEM]: purchaseInvoiceLineIdInput(
    'The record ID of the Purchase Invoice Line to delete.',
  ),
  [Intent.UPDATE_TAX_GROUP]: taxGroupIdInput('The record ID of the Tax Group to update.'),
  [Intent.GET_TAX_GROUP_BY_ID]: taxGroupIdInput('Specify the record ID of the Tax Group to fetch.'),
  [Intent.DELETE_TAX_GROUP]: taxGroupIdInput('The record ID of the Tax Group to delete.'),
  [Intent.UPDATE_PAYMENT_TERM]: paymentTermsIdInput('The record ID of the Payment Term to update.'),
  [Intent.GET_PAYMENT_TERM_BY_ID]: paymentTermsIdInput(
    'Specify the record ID of the Payment Term to fetch.',
  ),
  [Intent.DELETE_PAYMENT_TERM]: paymentTermsIdInput('The record ID of the Payment Term to delete.'),
};

export const hiddenInputCollection: { [key in Intent]?: SidebarInput } = {
  [Intent.DELETE_PAYMENT_TERM]: recordTypeInput(RecordType.PAYMENT_TERMS),
  [Intent.CREATE_PAYMENT_TERM]: recordTypeInput(RecordType.PAYMENT_TERMS),
  [Intent.UPDATE_PAYMENT_TERM]: recordTypeInput(RecordType.PAYMENT_TERMS),
  [Intent.SEARCH_PAYMENT_TERM]: recordTypeInput(RecordType.PAYMENT_TERMS),
  [Intent.DELETE_PURCHASE_INVOICE]: recordTypeInput(RecordType.PURCHASE_INVOICES),
  [Intent.UPDATE_PURCHASE_INVOICE]: recordTypeInput(RecordType.PURCHASE_INVOICES),
  [Intent.CREATE_PURCHASE_INVOICE]: recordTypeInput(RecordType.PURCHASE_INVOICES),
  [Intent.SEARCH_FOR_PURCHASE_INVOICE]: recordTypeInput(RecordType.PURCHASE_INVOICES),
  [Intent.DELETE_PURCHASE_INVOICE_LINE_ITEM]: recordTypeInput(RecordType.PURCHASE_INVOICE_LINES),
  [Intent.CREATE_PURCHASE_INVOICE_LINE_ITEM]: recordTypeInput(RecordType.PURCHASE_INVOICE_LINES),
  [Intent.UPDATE_PURCHASE_INVOICE_LINE_ITEM]: recordTypeInput(RecordType.PURCHASE_INVOICE_LINES),
  [Intent.SEARCH_PURCHASE_INVOICE_LINE_ITEM]: recordTypeInput(RecordType.PURCHASE_INVOICE_LINES),
  [Intent.DELETE_TAX_GROUP]: recordTypeInput(RecordType.TAX_GROUPS),
  [Intent.CREATE_TAX_GROUP]: recordTypeInput(RecordType.TAX_GROUPS),
  [Intent.UPDATE_TAX_GROUP]: recordTypeInput(RecordType.TAX_GROUPS),
  [Intent.SEARCH_TAX_GROUP]: recordTypeInput(RecordType.TAX_GROUPS),
  [Intent.GET_PAYMENT_TERM_BY_ID]: recordTypeInput(RecordType.PAYMENT_TERMS),
  [Intent.GET_PURCHASE_INVOICE_BY_ID]: recordTypeInput(RecordType.PURCHASE_INVOICES),
  [Intent.GET_PURCHASE_INVOICE_LINE_ITEM_BY_ID]: recordTypeInput(RecordType.PURCHASE_INVOICE_LINES),
  [Intent.GET_TAX_GROUP_BY_ID]: recordTypeInput(RecordType.TAX_GROUPS),
  [Intent.GET_VENDOR_BY_ID]: recordTypeInput(RecordType.VENDORS),
  [Intent.DELETE_VENDOR]: recordTypeInput(RecordType.VENDORS),
  [Intent.CREATE_VENDOR]: recordTypeInput(RecordType.VENDORS),
  [Intent.UPDATE_VENDOR]: recordTypeInput(RecordType.VENDORS),
  [Intent.SEARCH_VENDOR]: recordTypeInput(RecordType.VENDORS),
  [Intent.POST_PURCHASE_INVOICE]: recordTypeInput(RecordType.PURCHASE_INVOICES),
  [Intent.GET_ACCOUNTS]: recordTypeInput(RecordType.ACCOUNTS),
  [Intent.GET_ACCOUNT_BY_ID]: recordTypeInput(RecordType.ACCOUNTS),
};

export const searchInputCollection: { [key in Intent]?: SidebarInput } = {
  [Intent.SEARCH_FOR_PURCHASE_INVOICE]: purchaseInvoiceFilterInputOauth as SidebarInput,
  [Intent.SEARCH_PAYMENT_TERM]: paymentTermsFilterInputOauth as SidebarInput,
  [Intent.SEARCH_PURCHASE_INVOICE_LINE_ITEM]: purchaseInvoiceLineFilterInputOauth as SidebarInput,
  [Intent.SEARCH_TAX_GROUP]: taxGroupFilterInputOauth as SidebarInput,
  [Intent.SEARCH_VENDOR]: vendorFilterInputOauth as SidebarInput,
};

export const purchaseInvoiceAdditionalFields: SidebarInput = {
  id: 'purchaseInvoiceAdditionalFields',
  title: 'Additional Fields',
  type: SidebarInputType.Code,
  lines: 4,
  required: false,
  language: 'json',
  useLightTheme: true,
  placeholder: `{
  shipToName: "John Apple",
  shipToAddressLine1: "2000 Durant Ave."
}`,
  subtitle: (
    <>
      Specify additional fields to include on this Purchase Invoice as JSON.{' '}
      <a
        href="https://docs.microsoft.com/en-us/dynamics365/business-central/dev-itpro/api-reference/v2.0/resources/dynamics_purchaseinvoice"
        target="_blank"
        rel="noreferrer"
      >
        See the docs
      </a>{' '}
      for available fields.
    </>
  ),
};

export const intentSelector: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Vendors',
          items: [
            { label: 'Create Vendor', value: Intent.CREATE_VENDOR },
            { label: 'Update Vendor', value: Intent.UPDATE_VENDOR },
            { label: 'Get Vendor by ID', value: Intent.GET_VENDOR_BY_ID },
            { label: 'Search for Vendor', value: Intent.SEARCH_VENDOR },
            { label: 'Delete Vendor', value: Intent.DELETE_VENDOR },
          ],
        },
        {
          title: 'Purchase Invoice',
          items: [
            { label: 'Create Purchase Invoice', value: Intent.CREATE_PURCHASE_INVOICE },
            { label: 'Update Purchase Invoice', value: Intent.UPDATE_PURCHASE_INVOICE },
            { label: 'Post a Purchase Invoice', value: Intent.POST_PURCHASE_INVOICE },
            { label: 'Get Purchase Invoice by ID', value: Intent.GET_PURCHASE_INVOICE_BY_ID },
            { label: 'Search for Purchase Invoice', value: Intent.SEARCH_FOR_PURCHASE_INVOICE },
            { label: 'Delete Purchase Invoice', value: Intent.DELETE_PURCHASE_INVOICE },
          ],
        },
        {
          title: 'Purchase Invoice Line',
          items: [
            {
              label: 'Create Purchase Invoice Line Item',
              value: Intent.CREATE_PURCHASE_INVOICE_LINE_ITEM,
            },
            {
              label: 'Update Purchase Invoice Line Item',
              value: Intent.UPDATE_PURCHASE_INVOICE_LINE_ITEM,
            },
            { label: 'Get Purchase Invoice Lines', value: Intent.GET_PURCHASE_INVOICE_LINES },
            {
              label: 'Get Purchase Invoice Line Item by ID',
              value: Intent.GET_PURCHASE_INVOICE_LINE_ITEM_BY_ID,
            },
            {
              label: 'Search for Purchase Invoice Line Item',
              value: Intent.SEARCH_PURCHASE_INVOICE_LINE_ITEM,
            },
            {
              label: 'Delete Purchase Invoice Line Item',
              value: Intent.DELETE_PURCHASE_INVOICE_LINE_ITEM,
            },
          ],
        },
        {
          title: 'Accounts',
          items: [
            { label: 'Get Accounts', value: Intent.GET_ACCOUNTS },
            { label: 'Get Account by ID', value: Intent.GET_ACCOUNT_BY_ID },
          ],
        },
        {
          title: 'Tax Groups',
          items: [
            { label: 'Create Tax Group', value: Intent.CREATE_TAX_GROUP },
            { label: 'Update Tax Group', value: Intent.UPDATE_TAX_GROUP },
            { label: 'Get Tax Group by ID', value: Intent.GET_TAX_GROUP_BY_ID },
            { label: 'Search for Tax Group', value: Intent.SEARCH_TAX_GROUP },
            { label: 'Delete Tax Group', value: Intent.DELETE_TAX_GROUP },
          ],
        },
        {
          title: 'Payment Terms',
          items: [
            { label: 'Create Payment Term', value: Intent.CREATE_PAYMENT_TERM },
            { label: 'Update Payment Term', value: Intent.UPDATE_PAYMENT_TERM },
            { label: 'Get Payment Term by ID', value: Intent.GET_PAYMENT_TERM_BY_ID },
            { label: 'Search for Payment Term', value: Intent.SEARCH_PAYMENT_TERM },
            { label: 'Delete Payment Term', value: Intent.DELETE_PAYMENT_TERM },
          ],
        },
      ],
    },
  ],
};
