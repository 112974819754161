import { getValuesByCacheKey } from '@shared/actions/sdk/utils';
import {
  IntegrationConnectInput,
  SidebarInput,
  SidebarInputType,
  SidebarSection,
} from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

import { Intent } from '../configs';
import { DataType, DataTypeValue } from '../shared';

import { getLists } from './sources';

export const titleInput: SidebarInput = {
  id: 'title',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Title',
  required: true,
};

export const nameInput: SidebarInput = {
  id: 'name',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Name',
  required: true,
};

export const listIdInput: SidebarInput = {
  id: 'listId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'List ID',
  required: true,
};

export const listDynamicInput: SidebarInput = {
  ...listIdInput,
  type: SidebarInputType.EditableDynamicEnum,
  source: getLists,
  getValues: getValuesByCacheKey,
};

export const descriptionInput: SidebarInput = {
  id: 'description',
  type: SidebarInputType.TextArea,
  lines: 2,
  title: 'Description',
  subtitle: 'Description or acceptance criteria for this epic to be considered complete.',
  required: false,
};

export const fieldsInput: SidebarInput = {
  id: 'fields',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 5,
  title: 'Fields',
  required: false,
  subtitle:
    'The column values of the new item.  Use Connect Portal Workflow Settings to allow users to select which item columns to update.',

  useLightTheme: true,
};

export const itemIdInput: SidebarInput = {
  id: 'itemId',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Item ID',
  required: true,
  subtitle: 'The ID of the item to update.',
};

export const dataTypeInput: SidebarInput = {
  id: 'dataType',
  type: SidebarInputType.EditableEnum,
  title: 'Data Type',
  required: true,
  subtitle: 'Determines what type of data the column stores',
  getValues: () => [
    { label: DataType.BOOLEAN, value: DataTypeValue.BOOLEAN },
    { label: DataType.CHOICE, value: DataTypeValue.CHOICE },
    { label: DataType.DATETIME, value: DataTypeValue.DATETIME },
    { label: DataType.NUMBER, value: DataTypeValue.NUMBER },
    { label: DataType.TEXT, value: DataTypeValue.TEXT },
  ],
};

export const displayNameInput: SidebarInput = {
  id: 'displayName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Display Name',
  required: true,
  subtitle: 'The user-facing name of the column.',
};

export const apiNameInput: SidebarInput = {
  id: 'apiName',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Api Name',
  required: true,
  subtitle: 'The API-facing name of the column as it appears in the fields on a List Item.',
};

export const allowTextEntryInput: SidebarInput = {
  id: 'allowTextEntry',
  type: SidebarInputType.BooleanInput,
  title: 'Allow Text Entry',
  required: false,
  subtitle: 'If true, allows custom values that aren’t in the configured choices.',
  defaultValue: false,
};

export const choicesInput: SidebarInput = {
  id: 'choices',
  type: SidebarInputType.Code,
  language: 'json',
  lines: 1,
  title: 'Choices',
  required: false,
  placeholder: '["red", "blue", "green"]',
  subtitle: 'The list of values available for this column.',
  useLightTheme: true,
};

export const maximumInput: SidebarInput = {
  id: 'maximum',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Maximum',
  required: false,
  subtitle: 'The maximum permitted value.',
};

export const minimumInput: SidebarInput = {
  id: 'minimum',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Minimum',
  required: false,
  subtitle: 'The minimum permitted value.',
};
export const linesForEditingInput: SidebarInput = {
  id: 'linesForEditing',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Lines for Editing',
  subtitle: 'The size of the text box.',
  required: false,
};

export const maxLengthInput: SidebarInput = {
  id: 'maxLength',
  type: SidebarInputType.TextArea,
  lines: 1,
  title: 'Max Length',
  subtitle: 'The maximum number of characters for the value.',
  required: false,
};

export const defaultValueInput: SidebarInput = {
  id: 'defaultValue',
  type: SidebarInputType.TextArea,
  required: false,
  title: 'Default Value',
  lines: 1,
  subtitle: 'The default value for this column.',
};

export const displayAsInput = (options: any): SidebarInput => {
  return {
    id: 'displayAs',
    type: SidebarInputType.Enum,
    required: false,
    title: 'Display As',
    subtitle: 'How the choices are to be presented in the UX.',
    getValues: () => options,
  };
};

export const formatInput: SidebarInput = {
  id: 'format',
  type: SidebarInputType.Enum,
  required: false,
  title: 'Format',
  subtitle: 'Indicates whether the value should be presented as a date only or a date and time.',
  getValues: () => [
    { label: 'Date Only', value: 'Date Only' },
    { label: 'Date Time', value: 'Date Time' },
  ],
};

export const textTypeInput: SidebarInput = {
  id: 'textType',
  type: SidebarInputType.Enum,
  required: true,
  title: 'Text Type',
  defaultValue: 'Plain Text',
  getValues: () => [
    { label: 'Plain Text', value: 'Plain Text' },
    { label: 'Rich Text', value: 'Rich Text' },
  ],
};

export const allowMultipleLinesInput: SidebarInput = {
  id: 'allowMultipleLines',
  type: SidebarInputType.BooleanInput,
  required: false,
  title: 'Allow Multiple Lines',
  defaultValue: true,
  subtitle: 'Whether to allow multiple lines of text.',
};

export const isPictureInput: SidebarInput = {
  id: 'isPicture',
  type: SidebarInputType.BooleanInput,
  required: true,
  title: 'Is Picture',
  defaultValue: false,
  subtitle: 'Specifies whether the display format used for URL columns is an image or a hyperlink.',
};

export const hiddenInput: SidebarInput = {
  id: 'hidden',
  type: SidebarInputType.BooleanInput,
  required: false,
  title: 'Hidden',
  defaultValue: false,
};

export const readOnlyInput: SidebarInput = {
  id: 'readOnly',
  type: SidebarInputType.BooleanInput,
  required: false,
  title: 'Read-only',
  defaultValue: false,
};

export const createItemInput = (isConnectUi: boolean, isUpdating: boolean): SidebarInput[] => [
  titleInput,
  isConnectUi
    ? {
        ...listIdInput,
        subtitle: isUpdating
          ? 'The List ID to update the item in. Use Connect Portal User Settings to allow users to select a List.'
          : 'The List ID to create the item in. Use Connect Portal User Settings to allow users to select a List.',
      }
    : listDynamicInput,
  isConnectUi ? fieldsInput : { ...fieldsInput, subtitle: '' },
];

export const siteInput: IntegrationConnectInput = {
  id: 'siteId',
  title: 'Choose a SharePoint site to use',
  placeholder: 'Site',
  type: SidebarInputType.DynamicEnum,
  required: true,
  sourceType: 'getSites',
};

export const itemsFilterInput: SidebarInput = {
  id: 'itemsFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for items that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [
    Operator.StringExactlyMatches,
    Operator.NumberEquals,
    Operator.StringDoesNotExactlyMatch,
    Operator.NumberGreaterThan,
    Operator.NumberLessThan,
    Operator.NumberGreaterThanOrEqualTo,
    Operator.NumberLessThanOrEqualTo,
    Operator.DateTimeAfter,
    Operator.DateTimeBefore,
    Operator.DateTimeEquals,
  ],
  getValues: () => [
    { label: 'Title', value: 'fields/Title' },
    { label: 'Modified', value: 'fields/Modified' },
    { label: 'Created', value: 'fields/Created' },
    { label: 'AuthorLookupId', value: 'fields/AuthorLookupId' },
    { label: 'EditorLookupId', value: 'fields/EditorLookupId' },
    { label: 'LinkTitle', value: 'fields/LinkTitle' },
  ],
};

export const listsFilterInput: SidebarInput = {
  id: 'listsFilterFormula',
  title: 'Filter search',
  subtitle: 'Search for items that match specified filters.',
  type: SidebarInputType.Conditional,
  required: false,
  supportedOperators: [Operator.StringExactlyMatches],
  getValues: () => [{ label: 'DisplayName', value: 'displayName' }],
  disableMultipleConditions: true,
};

export const commonIntentInput: SidebarSection = {
  inputs: [
    {
      id: 'intent',
      title: 'Choose an action',
      type: SidebarInputType.Intent,
      values: [
        {
          title: 'Items',
          items: [
            {
              value: Intent.CREATE_ITEM,
              label: 'Create Item',
            },
            {
              value: Intent.UPDATE_ITEM,
              label: 'Update Item',
            },
            {
              value: Intent.GET_ITEM_BY_ID,
              label: 'Get Item by ID',
            },
            {
              value: Intent.GET_ITEMS_IN_A_LIST,
              label: 'Get Items in a List',
            },
            {
              value: Intent.DELETE_ITEM,
              label: 'Delete Item',
            },
          ],
        },
        {
          title: 'Lists',
          items: [
            {
              value: Intent.CREATE_LIST,
              label: 'Create List',
            },
            {
              value: Intent.GET_LIST_BY_ID,
              label: 'Get List by ID',
            },
            {
              value: Intent.GET_LISTS,
              label: 'Get Lists',
            },
            {
              value: Intent.CREATE_LIST_COLUMN,
              label: 'Create List column',
            },
            {
              value: Intent.GET_LIST_COLUMNS,
              label: 'Get List columns',
            },
          ],
        },
      ],
    },
  ],
};
