import { PaginatedActionResponse } from '@shared/actions/sdk/utils';
import { CredentialValues } from '@shared/entities/sdk';
import { EnumInputValue } from '@shared/types/sdk/actions';
import { Operator } from '@shared/types/sdk/resolvers';

export type Environment = {
  JIRA_CLIENT_ID: string;
  JIRA_CLIENT_SECRET: string;
  JIRA_SCOPES?: string;
};

export type GetAuthUrlDTO = {
  redirectUrl: string;
  state: Record<string, any>;
};

export type ExchangeCredentialDTO = {
  redirectUrl: string;
  code: string;
};

export type LoggedInUserResources = {
  id: string;
  name: string;
}[];

export type GetIssueByKeyDTO = {
  issueKey: string;
};

export type GetIssueStatusesDTO = {
  issueType: string;
};

export type JiraProject = {
  self?: string;
  name: string;
  id: string;
  key: string;
};

export type ProjectResponse = { values: JiraProject[]; isLast: boolean };

export type GetIssueTypesByProjectDTO = {
  project: string;
};

export type GetIssueStatusByProjectDTO = {
  project: string;
};

export type JiraIssueType = Record<string, any>;

export type GetIssueFieldsByIssueTypeDTO = {
  issueType: string;
  projectKey: string;
};

export type JiraIssueDetails = {
  key: string;
  name: string;
  required: boolean;
  schema: {
    type: string;
  };
  allowedValues?: JiraFieldsAllowedValue[];
};

export type JiraFieldsAllowedValue = {
  id: string;
  name: string;
  value?: string;
};

export type CreateIssueDTO = {
  project?: string;
  summary?: string;
  issueType?: string;
  jiraIssueStatus?: string;
  assignee?: string;
  description?: string;
  issueFieldsJson?: string;
  cachedCreateIssueFields: Record<string, any>[];
  additionalFields?: string;
  [key: string]: any;
};

export type UpdateIssueDTO = {
  issueKey: string;
  issueType: string;
  jiraIssueStatus?: string;
  issueFieldsJson?: string;
  summary: string;
  assignee?: string;
  description: string;
  cachedEditIssueFields: Record<string, any>[];
  additionalFields?: string;
  [key: string]: any;
};

export type JiraTypeSchema = {
  type: string;
  items: string;
  system: string;
  custom?: string;
};

export type JiraRequestPayloadForField =
  | { key: string }
  | { id: string }
  | string
  | string[]
  | number
  | { value: string }
  | { value: string }[]
  | AtlassianDocFormat;

// abstracted atlassian doc format to suit simple useCases
export type AtlassianDocFormat = {
  version: number;
  type: string;
  content: [
    {
      type: string;
      content: [
        {
          type: string;
          text: string;
        },
      ];
    },
  ];
};

export type JiraSupportedPayload = Record<string, (value: string) => JiraRequestPayloadForField>;

export type JiraTransition = {
  id: string;
  to: { id: string };
  name: string;
};

export type JiraTransitionResponse = { transitions: JiraTransition[] };

export type IssueStatuses = {
  name: string;
  id: string;
};

export type IssueStatusesResponse = IssueStatuses[];

export type GetEditIssueMetadataDTO = {
  issueType: string;
};

export type GetEditIssueMetadataResponse = {
  fields: Record<string, any>;
};

export type JiraUser = {
  displayName: string;
  accountId: string;
  accountType?: string;
};

export type JiraProjectMetaData = {
  key: string;
  issuetypes: Record<string, any>[];
};

export type SearchByJQLDTO = {
  jqlQuery: string;
};

export type FilterIssuesDTO = {
  project: string;
  issueType: string;
  jqlQuery: string;
  limit: string;
};

type PaginatedJiraResponse = {
  total: number;
  maxResults: number;
  startAt: number;
};

export type JQLResponse = PaginatedJiraResponse & {
  issues: JQLIssue[];
};

export type QueryProjectsResponse = PaginatedJiraResponse & {
  values: JiraProject[];
};

export type JQLIssue = {
  fields: {
    created: string;
    updated: string;
    [key: string]: any;
    comment: {
      comments: JQLCommentField[];
    };
  };
  [key: string]: any;
};

export type JQLCommentField = {
  created: string;
  updated: string;
  [key: string]: any;
};

export type JiraAPIRequestData = {
  accessToken: string;
  jiraCloudId: string;
  [key: string]: any;
};

export type PaginationParams = {
  startAt: number;
  maxResults?: number;
};

export type QueryProjectsDTO = {
  projectName: string;
  projectType: string;
  limit: string;
};

export type FieldMetadata = {
  value: string;
  displayName: string;
};

export type FieldMetadataResponse = {
  results: FieldMetadata[];
};

export const JIRA_OPERATORS: Operator[] = [
  Operator.StringExactlyMatches,
  Operator.StringDoesNotExactlyMatch,
  Operator.StringIsIn,
  Operator.StringIsNotIn,
  Operator.StringContains,
  Operator.StringDoesNotContain,
  Operator.StringGreaterThan,
  Operator.StringLessThan,
];

export type DefaultProjectData = {
  projectKey: string;
  issueType: string;
};

export type CreateIssueResponse = {
  id: string;
  key: string;
  self: string;
};

export type UpdateIssueResponse = string;

export type UserIdentityResponse = {
  accountId: string;
};

export type IssueCreatedDTO = {
  jqlQuery?: string;
};

export type IssueUpdatedDTO = IssueCreatedDTO;

export type TokenExchangeResponse = {
  accessToken: string;
  refreshToken: string;
  updateCredentialValues: CredentialValues | undefined;
};

export type JiraAuthResponse = {
  access_token: string;
  refresh_token?: string;
  expires_in: number;
};

export type JiraField = {
  id: string;
  key: string;
  name: string;
  custom: boolean;
  orderable: boolean;
  navigable: boolean;
  searchable: boolean;
  untranslatedName?: string;
};

// https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-projects/#api-rest-api-3-project-search-get
export type GetProjectsResponse = {
  self: string;
  nextPage: string;
  maxResults: number;
  startAt: number;
  total: number;
  isLast: boolean;
  values: JiraProject[];
};

// https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issues/#api-rest-api-3-issue-createmeta-get
export type GetCreateIssueMetaDataResponse = {
  projects: Array<
    JiraProject & {
      issuetypes: Array<{
        self: string;
        id: string;
        description: string;
        iconUrl: string;
        name: string;
        subtask: boolean;
        fields: {
          issuetype: {
            required: boolean;
            name: string;
            key: string;
            hasDefaultValue: boolean;
          };
        };
      }>;
    }
  >;
};

export type GetCreateIssueMetaDataWithFieldsResponse = {
  projects: Array<
    JiraProject & {
      issuetypes: Array<{
        self: string;
        id: string;
        description: string;
        iconUrl: string;
        name: string;
        subtask: boolean;
        fields: Record<string, JiraIssueDetails>;
      }>;
    }
  >;
};

export type StatusDetails = {
  description: string;
  iconUrl: string;
  name: string;
  id: string;
};

export type IssueTypeWithStatus = {
  statuses: StatusDetails[];
};

export type GetEpicIssuesByProjectAPIResponse = {
  maxResults: number;
  total: number;
  startAt: number;
  issues: IssueProperty[];
};

export type IssueProperty = {
  id: string;
  key: string;
  fields: {
    summary: string;
  };
};

export type GetEpicIssuesByProjectTransformResponse = {
  id: string;
  key: string;
  summary: string;
};

export type GetEpicIssuesByProjectDTO = { projectKey: string };

export type PaginatedAssigneeResponse = PaginatedActionResponse<EnumInputValue[]>;
