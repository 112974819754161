import { Action } from '../actions';

import { ENVIRONMENT_VARIABLES } from './enums';

/**
 *  environment dependency for action to be loaded in enterprise environment.
 */
export const ActionEnvironmentDependency: Record<Action, ENVIRONMENT_VARIABLES[] | undefined> = {
  [Action.NONE]: undefined,
  [Action.CUSTOM]: undefined,
  [Action.FIREBASE]: [],
  [Action.GOOGLE_DRIVE]: [
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_SECRET,
  ],
  [Action.MYSQL]: [],
  [Action.POSTGRES]: [],
  [Action.SENDGRID]: [],
  [Action.TWILIO]: [],
  [Action.SLACK]: [
    ENVIRONMENT_VARIABLES.SLACK_APP_CLIENT_ID,
    ENVIRONMENT_VARIABLES.SLACK_APP_SECRET_ID,
  ],
  [Action.GOOGLE_SHEETS]: [
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_SECRET,
  ],
  [Action.AIRTABLE]: [ENVIRONMENT_VARIABLES.AIRTABLE_CLIENT_SECRET],
  [Action.MONGODB]: [],
  [Action.MAILGUN]: [],
  [Action.STRIPE]: [],
  [Action.FTP]: [],
  [Action.ONESIGNAL]: [],
  [Action.DYNAMODB]: [],
  [Action.SALESFORCE]: [
    ENVIRONMENT_VARIABLES.SALESFORCE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.SALESFORCE_CLIENT_SECRET,
  ],
  [Action.QUICKBOOKS]: [
    ENVIRONMENT_VARIABLES.QUICKBOOKS_CLIENT_ID,
    ENVIRONMENT_VARIABLES.QUICKBOOKS_CLIENT_SECRET,
    ENVIRONMENT_VARIABLES.QUICKBOOKS_CLIENT_ID_SANDBOX,
    ENVIRONMENT_VARIABLES.QUICKBOOKS_CLIENT_SECRET_SANDBOX,
  ],
  [Action.GOOGLE_CALENDAR]: [],
  [Action.SHOPIFY]: [
    ENVIRONMENT_VARIABLES.SHOPIFY_CLIENT_ID,
    ENVIRONMENT_VARIABLES.SHOPIFY_CLIENT_SECRET,
  ],
  [Action.JIRA]: [ENVIRONMENT_VARIABLES.JIRA_CLIENT_ID, ENVIRONMENT_VARIABLES.JIRA_CLIENT_SECRET],
  [Action.HUBSPOT]: [
    ENVIRONMENT_VARIABLES.HUBSPOT_CLIENT_ID,
    ENVIRONMENT_VARIABLES.HUBSPOT_CLIENT_SECRET,
  ],
  [Action.KLAVIYO]: [],
  [Action.INTERCOM]: [
    ENVIRONMENT_VARIABLES.INTERCOM_CLIENT_ID,
    ENVIRONMENT_VARIABLES.INTERCOM_CLIENT_SECRET,
  ],
  [Action.ASANA]: [
    ENVIRONMENT_VARIABLES.ASANA_CLIENT_ID,
    ENVIRONMENT_VARIABLES.ASANA_CLIENT_SECRET,
  ],
  [Action.TABLEAU]: [],
  [Action.ZENDESK]: [
    ENVIRONMENT_VARIABLES.ZENDESK_CLIENT_ID,
    ENVIRONMENT_VARIABLES.ZENDESK_CLIENT_SECRET,
  ],
  [Action.MICROSOFT_TEAMS]: [
    ENVIRONMENT_VARIABLES.MICROSOFT_TEAMS_CLIENT_ID,
    ENVIRONMENT_VARIABLES.MICROSOFT_TEAMS_CLIENT_SECRET,
    ENVIRONMENT_VARIABLES.MICROSOFT_TEAMS_BOT_ID,
  ],
  [Action.ZOOM]: [ENVIRONMENT_VARIABLES.ZOOM_CLIENT_ID, ENVIRONMENT_VARIABLES.ZOOM_CLIENT_SECRET],
  [Action.MARKETO]: [],
  [Action.MICROSOFT_DYNAMICS]: [
    ENVIRONMENT_VARIABLES.MICROSOFT_DYNAMICS_CLIENT_ID,
    ENVIRONMENT_VARIABLES.MICROSOFT_DYNAMICS_CLIENT_SECRET,
  ],
  [Action.CUSTOM]: [],
  [Action.PARDOT]: [
    ENVIRONMENT_VARIABLES.PARDOT_CLIENT_ID,
    ENVIRONMENT_VARIABLES.PARDOT_CLIENT_SECRET,
  ],
  [Action.XERO]: [ENVIRONMENT_VARIABLES.XERO_CLIENT_ID, ENVIRONMENT_VARIABLES.XERO_CLIENT_SECRET],
  [Action.OUTREACH]: [
    ENVIRONMENT_VARIABLES.OUTREACH_CLIENT_ID,
    ENVIRONMENT_VARIABLES.OUTREACH_CLIENT_SECRET,
  ],
  [Action.GOOGLE_CAMPAIGN_MANAGER]: [
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_SECRET,
  ],
  [Action.GOOGLE_SEARCH_CONSOLE]: [
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_SECRET,
  ],
  [Action.ORACLE_FINANCIALS_CLOUD]: [],
  [Action.TRELLO]: [ENVIRONMENT_VARIABLES.TRELLO_APP_NAME],
  [Action.FACEBOOK_ADS]: [
    ENVIRONMENT_VARIABLES.FACEBOOK_CLIENT_ID,
    ENVIRONMENT_VARIABLES.FACEBOOK_CLIENT_SECRET,
  ],
  [Action.MAILCHIMP]: [
    ENVIRONMENT_VARIABLES.MAILCHIMP_CLIENT_ID,
    ENVIRONMENT_VARIABLES.MAILCHIMP_CLIENT_SECRET,
  ],
  [Action.AZURE_DEVOPS]: [
    ENVIRONMENT_VARIABLES.AZURE_DEVOPS_CLIENT_ID,
    ENVIRONMENT_VARIABLES.AZURE_DEVOPS_CLIENT_SECRET,
  ],
  [Action.MONDAY]: [],
  [Action.CLICKUP]: [
    ENVIRONMENT_VARIABLES.CLICKUP_CLIENT_ID,
    ENVIRONMENT_VARIABLES.CLICKUP_CLIENT_SECRET,
  ],
  [Action.GOOGLE_ANALYTICS]: [
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_SECRET,
  ],
  [Action.SHAREPOINT]: [
    ENVIRONMENT_VARIABLES.SHAREPOINT_CLIENT_ID,
    ENVIRONMENT_VARIABLES.SHAREPOINT_CLIENT_SECRET,
  ],
  [Action.OUTLOOK]: [
    ENVIRONMENT_VARIABLES.OUTLOOK_CLIENT_ID,
    ENVIRONMENT_VARIABLES.OUTLOOK_CLIENT_SECRET,
  ],
  [Action.SERVICENOW]: [],
  [Action.ELOQUA]: [
    ENVIRONMENT_VARIABLES.ELOQUA_CLIENT_ID,
    ENVIRONMENT_VARIABLES.ELOQUA_CLIENT_SECRET,
  ],
  [Action.NETSUITE]: [
    ENVIRONMENT_VARIABLES.NETSUITE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.NETSUITE_CLIENT_SECRET,
  ],
  [Action.DYNAMICS_BUSINESS_CENTRAL]: [
    ENVIRONMENT_VARIABLES.ELOQUA_CLIENT_ID,
    ENVIRONMENT_VARIABLES.ELOQUA_CLIENT_SECRET,
  ],
  [Action.PIPEDRIVE]: [
    ENVIRONMENT_VARIABLES.PIPEDRIVE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.PIPEDRIVE_CLIENT_SECRET,
  ],
  [Action.SAGE_INTACCT]: [
    ENVIRONMENT_VARIABLES.SAGE_INTACCT_SENDER_ID,
    ENVIRONMENT_VARIABLES.SAGE_INTACCT_SENDER_PASSWORD,
  ],
  [Action.LINEAR]: [
    ENVIRONMENT_VARIABLES.LINEAR_CLIENT_ID,
    ENVIRONMENT_VARIABLES.LINEAR_CLIENT_SECRET,
  ],
  [Action.GITHUB]: [
    ENVIRONMENT_VARIABLES.GITHUB_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GITHUB_CLIENT_SECRET,
  ],
  [Action.GONG]: [ENVIRONMENT_VARIABLES.GONG_CLIENT_ID, ENVIRONMENT_VARIABLES.GONG_CLIENT_SECRET],
  [Action.DYNAMICS_365_FINANCE]: [
    ENVIRONMENT_VARIABLES.DYNAMICS_365_FINANCE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.DYNAMICS_365_FINANCE_CLIENT_SECRET,
  ],
  [Action.SAGE_ACCOUNTING]: [
    ENVIRONMENT_VARIABLES.SAGE_ACCOUNTING_CLIENT_ID,
    ENVIRONMENT_VARIABLES.SAGE_ACCOUNTING_CLIENT_SECRET,
  ],
  [Action.WOOCOMMERCE]: [],
  [Action.IMANAGE]: [
    ENVIRONMENT_VARIABLES.IMANAGE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.IMANAGE_CLIENT_SECRET,
  ],
  [Action.BAMBOO_HR]: [
    ENVIRONMENT_VARIABLES.BAMBOO_HR_CLIENT_ID,
    ENVIRONMENT_VARIABLES.BAMBOO_HR_CLIENT_SECRET,
    ENVIRONMENT_VARIABLES.BAMBOO_HR_APPLICATION_KEY,
  ],
  [Action.GMAIL]: [
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GOOGLE_CLIENT_SECRET,
  ],
  [Action.GOOGLE_AD_MANAGER]: [
    ENVIRONMENT_VARIABLES.GOOGLE_AD_MANAGER_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GOOGLE_AD_MANAGER_CLIENT_SECRET,
  ],
  [Action.DROPBOX]: [
    ENVIRONMENT_VARIABLES.DROPBOX_CLIENT_ID,
    ENVIRONMENT_VARIABLES.DROPBOX_CLIENT_SECRET,
  ],
  [Action.MAGENTO]: [],
  [Action.PRODUCTBOARD]: [],
  [Action.DOCUSIGN]: [
    ENVIRONMENT_VARIABLES.DOCUSIGN_INTEGRATION_KEY,
    ENVIRONMENT_VARIABLES.DOCUSIGN_SECRET_KEY,
    ENVIRONMENT_VARIABLES.DOCUSIGN_DEVELOPMENT_INTEGRATION_KEY,
    ENVIRONMENT_VARIABLES.DOCUSIGN_DEVELOPMENT_SECRET_KEY,
  ],
  [Action.SAP_S4HANA]: [],
  [Action.GOOGLE_ADS]: [
    ENVIRONMENT_VARIABLES.GOOGLE_ADS_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GOOGLE_ADS_CLIENT_SECRET,
    ENVIRONMENT_VARIABLES.GOOGLE_ADS_DEVELOPER_TOKEN,
  ],
  [Action.GREENHOUSE]: [],
  [Action.LEVER]: [
    ENVIRONMENT_VARIABLES.LEVER_CLIENT_ID,
    ENVIRONMENT_VARIABLES.LEVER_CLIENT_SECRET,
    ENVIRONMENT_VARIABLES.LEVER_SANDBOX_CLIENT_ID,
    ENVIRONMENT_VARIABLES.LEVER_SANDBOX_CLIENT_SECRET,
  ],
  [Action.CALENDLY]: [
    ENVIRONMENT_VARIABLES.CALENDLY_CLIENT_ID,
    ENVIRONMENT_VARIABLES.CALENDLY_CLIENT_SECRET,
  ],
  [Action.ZOHO_CRM]: [
    ENVIRONMENT_VARIABLES.ZOHO_CRM_CLIENT_ID,
    ENVIRONMENT_VARIABLES.ZOHO_CRM_CLIENT_SECRET,
  ],
  [Action.TWITTER]: [
    ENVIRONMENT_VARIABLES.TWITTER_CONSUMER_API_KEY,
    ENVIRONMENT_VARIABLES.TWITTER_CONSUMER_SECRET_KEY,
  ],
  [Action.SALESLOFT]: [
    ENVIRONMENT_VARIABLES.SALESLOFT_CLIENT_ID,
    ENVIRONMENT_VARIABLES.SALESLOFT_CLIENT_SECRET,
  ],
  [Action.TABLEAU]: [
    ENVIRONMENT_VARIABLES.TABLEAU_CLIENT_ID,
    ENVIRONMENT_VARIABLES.TABLEAU_CLIENT_SECRET_ID,
    ENVIRONMENT_VARIABLES.TABLEAU_CLIENT_SECRET_VALUE,
  ],
  [Action.FRESHDESK]: [],
  [Action.BIGQUERY]: [
    ENVIRONMENT_VARIABLES.BIGQUERY_CLIENT_ID,
    ENVIRONMENT_VARIABLES.BIGQUERY_CLIENT_SECRET,
  ],
  [Action.POWER_BI]: [
    ENVIRONMENT_VARIABLES.POWER_BI_CLIENT_ID,
    ENVIRONMENT_VARIABLES.POWER_BI_CLIENT_SECRET,
  ],
  [Action.MIXPANEL]: [],
  [Action.SAP_SUCCESS_FACTORS]: [],
  [Action.AMAZON_S3]: [],
  [Action.PANDA_DOC]: [],
  [Action.SEGMENT]: [],
  [Action.GUSTO]: [
    ENVIRONMENT_VARIABLES.GUSTO_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GUSTO_CLIENT_SECRET,
    ENVIRONMENT_VARIABLES.GUSTO_DEMO_CLIENT_ID,
    ENVIRONMENT_VARIABLES.GUSTO_DEMO_CLIENT_SECRET,
  ],
  [Action.SAILTHRU]: [],
  [Action.CLOSE]: [],
};
